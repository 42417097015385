import { Group, Title, Text, Stack } from "@mantine/core";
import {
  ProductAccessInfoNeededFromCreatorType,
  ProductAccessInfoToProvideToCreatorType,
  ProductAccessStatus,
} from "components/contracts/common/Common";
import ProductAccessCard from "components/contracts/product_access/ProductAccessCard";
import { Container } from "gestalt";
import React from "react";

export default function ProductAccessSection({
  brandName,
  productAccessStatus,
  productAccessDueDate,
  productAccessDescription,
  productAccessInfoNeededFromCreator,
  productAccessOtherInfoNeededFromCreator,
  productAccessInfoToProvideToCreator,
  productAccessOtherInfoToProvideToCreator,
  productAccessBrandDisputeReason,
  productAccessCreatorInput,
  setProductAccessCreatorInput,
  productAccessBrandOutput,
  handleSaveCreatorProductAccessInfo,
  showAdminOptions = false,
}: {
  brandName: string;
  productAccessStatus: ProductAccessStatus;
  productAccessDueDate: Date;
  productAccessDescription: string;
  productAccessInfoNeededFromCreator: ProductAccessInfoNeededFromCreatorType;
  productAccessOtherInfoNeededFromCreator: string;
  productAccessInfoToProvideToCreator: ProductAccessInfoToProvideToCreatorType;
  productAccessOtherInfoToProvideToCreator: string;
  productAccessBrandDisputeReason: string;
  productAccessCreatorInput: string;
  setProductAccessCreatorInput: (productAccessInput: string) => void;
  productAccessBrandOutput: string;
  handleSaveCreatorProductAccessInfo: (productAccessInput: string | object) => void;
  showAdminOptions?: boolean;
}) {
  return (
    <>
      <Group gap="lg" align="center">
        <Title order={2} mt="lg" mb="sm" fw="500">
          Receive Product
        </Title>
      </Group>
      <Text>
        {brandName} would like to provide you with free product before you begin working on your
        contract.
        <br />
        Product to be provided: <strong>{productAccessDescription}</strong>
      </Text>
      <Stack mt="sm" gap="sm">
        <ProductAccessCard
          brandName={brandName}
          productAccessStatus={productAccessStatus}
          productAccessDueDate={productAccessDueDate}
          productAccessDescription={productAccessDescription}
          productAccessInfoNeededFromCreator={productAccessInfoNeededFromCreator}
          productAccessOtherInfoNeededFromCreator={productAccessOtherInfoNeededFromCreator}
          productAccessInfoToProvideToCreator={productAccessInfoToProvideToCreator}
          productAccessOtherInfoToProvideToCreator={productAccessOtherInfoToProvideToCreator}
          productAccessCreatorInput={productAccessCreatorInput}
          productAccessBrandDisputeReason={productAccessBrandDisputeReason}
          setProductAccessCreatorInput={setProductAccessCreatorInput}
          productAccessBrandOutput={productAccessBrandOutput}
          showAdminOptions={showAdminOptions}
          handleSaveCreatorProductAccessInfo={handleSaveCreatorProductAccessInfo}
        />
      </Stack>
    </>
  );
}
