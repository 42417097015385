import { API_URL } from "configs/Configs";
import { User } from "firebase/auth";
import { handleResult } from "utils/ApiUtils";
import { fromISODateString } from "utils/DateUtils";
import { Campaign } from "models/Campaign";

export const CAMPAIGN_URL = `${API_URL}/api/models/campaigns/`;
export const CREATOR_CAMPAIGN_URL = `${API_URL}/api/models/creator_campaigns/`;

export interface CreatorCampaign {
  creator: string;
  campaign: string;
  stage: number;
  date_created?: Date;
  last_updated?: Date;
}

export const getCampaign = async (campaignId: number) => {
  const request = new Request(`${CAMPAIGN_URL}${campaignId}/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const response = await handleResult(request);
  return response;
};

export const getAndSetCampaigns = async (
  user: User,
  setCampaigns: (campaigns: Campaign[]) => void,
  abortController: AbortController,
) => {
  const firebaseToken = await user.getIdToken();
  const request = new Request(CAMPAIGN_URL, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
    signal: abortController.signal,
  });
  const response = await handleResult(request, abortController);
  if (response == null || response?.results == null) {
    return;
  }
  // TODO(andrew): add a more sophisticated helper method to convert the date strings to dates
  const { results } = response;
  for (let i = 0; i < results.length; i += 1) {
    results[i].date_created = fromISODateString(results[i].date_created);
    results[i].brand_name = results[i].brand.brand_name;
  }
  setCampaigns(results);
};

// stage is defined in Django in the CreatorCampaign.Stage in campaign.models
export const applyForCampaign = async (user: User, campaignId: number, stage?: number) => {
  const campaignStage = stage || 1;
  const firebaseToken = await user.getIdToken();
  const request = new Request(CREATOR_CAMPAIGN_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
    body: JSON.stringify({ campaign_id: campaignId, stage: campaignStage }),
  });
  const response = await handleResult(request);
  return response;
};

export const fetchCampaignProgressData = async (user: User) => {
  const firebaseToken = await user.getIdToken();
  const requestUrl = new URL(`${API_URL}/api/campaigns/get_all_campaign_progress/`);
  const request = new Request(requestUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
  });

  const response = await handleResult(request);
  return response;
};

export const fetchOpsProgressData = async (user: User) => {
  const firebaseToken = await user.getIdToken();
  const requestUrl = new URL(`${API_URL}/api/campaigns/get_all_ops_progress/`);

  const request = new Request(requestUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
  });

  const response = await handleResult(request);
  return response;
};

export default null;
