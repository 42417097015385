import React from "react";

import {
  ActionIcon,
  Anchor,
  Container,
  List,
  Modal,
  Space,
  Text,
  Title,
} from "@mantine/core";

import { useDisclosure } from "@mantine/hooks";

import { IconInfoCircle } from "@tabler/icons-react";
import { SupportedPlatform } from "models/Common";

function TikTokSparkCodeEducation() {
  const [opened, { open, close }] = useDisclosure();

  return (
    <>
      <Modal withCloseButton={false} opened={opened} onClose={close} size="xl">
        <Container px="xs">
          <Title fw="600" order={3}>
            TikTok Spark Code Guide
          </Title>
          <Space h="md" />
          <Text size="md">
            A TikTok &ldquo;Spark code&rdquo; is what allows your brand partner to run your video as
            an ad (also called &ldquo;boosting&rdquo; your video). When you give your brand partner
            a &ldquo;Spark code&rdquo; for your TikTok video, they can enter it into their ads
            manager, load up some money, and get your video to appear in ad slots across TikTok. The
            video is still attributed to your account and your TikTok handle—users tapping on it
            will be taken to your profile. It&apos;s a win-win situation: It&apos;s a great way to
            drive engagement for your video, and the brand also gets visibility for their brand.
          </Text>
          <Space h="md" />
          <Text size="md">There are two steps to generate a Spark code.</Text>
          <Space h="md" />
          <Text fw="500" size="md">
            Step 1: Enable ad authorization on your TikTok account
          </Text>
          <List>
            <List.Item>
              <Text size="md">
                In your TikTok app (on your phone), tap &ldquo;Me&rdquo; (in the bottom right), then
                tap &ldquo;Privacy&rdquo;
              </Text>
            </List.Item>
            <List.Item>
              <Text size="md">
                You will see a toggle for &ldquo;Ad authorization&rdquo;. Make sure it&apos;s
                enabled.
              </Text>
            </List.Item>
          </List>
          <Space h="md" />
          <Text fw="500" size="md">
            Step 2: Enable ad authorization for your sponsored post
          </Text>
          <List>
            <List.Item>
              <Text size="md">
                Go to your sponsored video, tap the three dots icon, and then go to &ldquo;Ad
                settings&rdquo;. (It&apos;s probably all the way to the right, in the row of icons
                below the share-icons).
              </Text>
            </List.Item>
            <List.Item>
              <Text size="md">
                If it pops up, agree to the advertising terms of service that appear. Also, enable
                the &ldquo;Ad authorization&rdquo; toggle.
              </Text>
            </List.Item>
            <List.Item>
              <Text size="md">
                There should now be a button that says &ldquo;Generate code&rdquo;. Tap that, and
                select an authorization period. (The length of the authorization period depends on
                how long the usage rights were for in your agreement. If you&apos;re not sure, email
                your 1stCollab contact for help).
              </Text>
            </List.Item>
            <List.Item>
              <Text size="md">Tap &ldquo;Copy code&rdquo;.</Text>
            </List.Item>
          </List>
          <Space h="md" />
          <Text size="md">
            You can see more details in the TikTok help center,{" "}
            <Anchor
              href="https://ads.tiktok.com/help/article/spark-ads-creation-guide?lang=en#anchor-6"
              target="_blank">
              here.
            </Anchor>
          </Text>
        </Container>
      </Modal>
      <ActionIcon variant="subtle" radius="xl" size="1.2rem" onClick={open}>
        <IconInfoCircle />
      </ActionIcon>
    </>
  );
}

function InstagramAdCodeEducation() {
  const [opened, { open, close }] = useDisclosure();

  return (
    <>
      <Modal withCloseButton={false} opened={opened} onClose={close} size="xl">
        <Container px="xs">
          <Title fw="600" order={3}>
            Instagram Ad Code Guide
          </Title>
          <Space h="md" />
          <Text size="md">
            An Instagram partnership code is what allows your brand partner to run your video as an
            ad on Instagram. When you give your brand partner the partnership ad code for your
            Instagram post, they can enter it into their ads manager, load up some money, and get
            your video to appear in ad slots across Instagram. The video is still attributed to you,
            and has your handle—users tapping on it will be taken to your profile. It&apos;s a
            win-win situation: It&apos;s a great way to drive engagement for your video, and the
            brand also gets visibility for their brand.
          </Text>
          <Space h="md" />
          <Text size="md">
            To learn how to generate an Instagram partnership code, please follow the steps{" "}
            <Anchor
              target="_blank"
              href="https://help.instagram.com/797201308253238/?cms_platform=www&helpref=platform_switcher">
              here
            </Anchor>
            .
          </Text>
        </Container>
      </Modal>
      <ActionIcon variant="subtle" radius="xl" size="1.2rem" onClick={open}>
        <IconInfoCircle />
      </ActionIcon>
    </>
  );
}

export default function AdCodeEducation({
  platform,
  disabled,
}: {
  platform: SupportedPlatform;
  disabled: boolean;
}) {
  if (disabled) {
    return null;
  }

  if (platform === SupportedPlatform.TIKTOK) {
    return <TikTokSparkCodeEducation />;
  } else if (platform === SupportedPlatform.INSTAGRAM) {
    return <InstagramAdCodeEducation />;
  }

  return null;
}
