/* eslint-disable react/no-array-index-key */
import React from "react";

import { Box, Group, Stack, Spoiler, Text, Title } from "@mantine/core";

import ReactPlayer from "react-player";

import { toMediumDateString } from "utils/DateUtils";
import EngagementMetrics from "components/contracts/content/EngagementMetrics";

function NewlineText({ deliverableId, text }: { deliverableId: string; text: string }) {
  const textArray = text.split("\n");

  return (
    <>
      {textArray.map((line: string, index: number) => (
        <Text key={`${deliverableId}-${index}`}>
          {line}
          <br />
        </Text>
      ))}
    </>
  );
}

function TitleAndCaption({
  deliverableId,
  title,
  caption,
  width,
}: {
  deliverableId: string;
  title: string;
  caption: string;
  width: number;
}) {
  if (!title && !caption) {
    return null;
  }

  return (
    <Stack gap="0.2rem" w={width}>
      {title && (
        <Title order={5} p={0} fw="500">
          {title}
        </Title>
      )}
      {caption && (
        <Spoiler
          showLabel={<Text size={title ? "xs" : "sm"}>...more</Text>}
          hideLabel={<Text size={title ? "xs" : "sm"}>Hide</Text>}>
          <Text size={title ? "xs" : "sm"} c={title ? "dimmed" : ""}>
            <NewlineText deliverableId={deliverableId} text={caption} />
          </Text>
        </Spoiler>
      )}
    </Stack>
  );
}

function SubHeading({
  publishedAt,
  numViews,
  numLikes,
  numClicks,
  disableClicks,
}: {
  publishedAt: Date;
  numViews: number;
  numClicks: number;
  numLikes: number;
  disableClicks?: boolean;
}) {
  return (
    <Group align="center" justify="space-between">
      <Text size="xs">Posted on {toMediumDateString(publishedAt)}</Text>
      <EngagementMetrics
        numViews={numViews}
        numClicks={numClicks}
        numLikes={numLikes}
        disableClicks={disableClicks}
      />
    </Group>
  );
}

export default function DisplayContent({
  deliverableId,
  playableVideoUrl,
  publishedAt,
  numViews,
  numClicks,
  numLikes,
  title,
  caption,
  contentWidth,
  paddingWidth,
  disableClicks,
}: {
  deliverableId: string;
  playableVideoUrl: string;
  publishedAt: Date;
  numViews: number;
  numClicks: number;
  numLikes: number;
  title: string;
  caption: string;
  contentWidth: number;
  paddingWidth: number;
  disableClicks?: boolean;
}) {
  return (
    <Box w={contentWidth}>
      <ReactPlayer width="100%" height="100%" url={playableVideoUrl} controls />
      <Box px="xs">
        <Stack gap="0.2rem">
          <SubHeading
            publishedAt={publishedAt}
            numViews={numViews}
            numClicks={numClicks}
            numLikes={numLikes}
            disableClicks={disableClicks}
          />
          <TitleAndCaption
            deliverableId={deliverableId}
            title={title}
            caption={caption}
            width={contentWidth - paddingWidth}
          />
        </Stack>
      </Box>
    </Box>
  );
}
