import React from "react";
import { Flex, Box, SideNavigation } from "gestalt";
import "gestalt/dist/gestalt.css";
import { useOutletContext, Outlet, useLocation } from "react-router-dom";
import { User } from "firebase/auth";

import { AuthContext } from "auth/AuthContext";
import { CreatorProvider } from "components/creator/CreatorContext";
import LayoutBase from "components/LayoutBase";
import { getLoggedInUser } from "auth/AuthLayout";

const Menu = ({ verifiedEmail }: { verifiedEmail: boolean }) => {
  const location = useLocation();

  const settingsPathBase = "/settings";
  const accountPath = "/settings/account";
  const aboutPath = "/settings/about";
  const socialPath = "/settings/social";
  const verificationPath = "/settings/verification";

  const setActive = (pathnames: Array<string>): "page" | undefined =>
    pathnames.includes(location.pathname) ? "page" : undefined;

  return (
    <Box width={280} overflow="scroll" flex="none">
      <SideNavigation accessibilityLabel="">
        <SideNavigation.Section label="Account Information">
          {/* Why do relative paths only some times work here? */}
          <SideNavigation.TopItem
            href={accountPath}
            label="Account Settings"
            active={setActive([accountPath, settingsPathBase])}
          />
          <SideNavigation.TopItem
            href={aboutPath}
            label="Creator Information"
            active={setActive([aboutPath])}
          />
          <SideNavigation.TopItem
            href={socialPath}
            label="Social Accounts"
            active={setActive([socialPath])}
          />
          {verifiedEmail ? (
            <SideNavigation.TopItem
              href={verificationPath}
              label="Verify Email"
              active={setActive([verificationPath])}
            />
          ) : (
            <SideNavigation.TopItem
              href={verificationPath}
              label="Verify Email"
              notificationAccessibilityLabel="Verify Email"
              active={setActive([verificationPath])}
            />
          )}
        </SideNavigation.Section>
      </SideNavigation>
    </Box>
  );
};

const Settings = () => {
  const user: User = getLoggedInUser();

  return (
    <Flex flex="grow">
      <Menu verifiedEmail={user.emailVerified} />
      <CreatorProvider>
        <Flex direction="column" gap={4} flex="grow">
          <LayoutBase>
            <Outlet context={{ user }} />
          </LayoutBase>
        </Flex>
      </CreatorProvider>
    </Flex>
  );
};

export function getSettingsUser() {
  return useOutletContext<AuthContext>().user;
}

export default Settings;
