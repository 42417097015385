import { RootButtonName } from "campaigns/navbar/main/RootButton";

export enum MainNavButtonType {
  MAIN,
  CREATORS,
  CREATOR_SETS,
  CAMPAIGN,
  AD_GROUP,
  CREATIVE_BRIEFS,
  CONTRACTS_OVERVIEW,
}

export interface SelectedNavButton {
  type: MainNavButtonType;
  rootButtonName: RootButtonName | null;
  campaignHashId: number | null;
  adGroupId: number | null;
  creatorSetId?: number | null;
}

// ================== NAV BAR IS_SELECTED HELPERS ==================
function isNavButtonSelected(
  currentSelected: SelectedNavButton,
  buttonType: MainNavButtonType,
  rootButtonName?: RootButtonName,
  campaignHashId?: number,
  adGroupId?: number,
  creatorSetId?: number,
): boolean {
  if (buttonType !== currentSelected.type) return false;
  switch (buttonType) {
    case MainNavButtonType.MAIN:
      if (rootButtonName === null || currentSelected.rootButtonName === null) return false;
      return rootButtonName === currentSelected.rootButtonName;
    case MainNavButtonType.CAMPAIGN:
      if (campaignHashId === null || currentSelected.campaignHashId === null) return false;
      return campaignHashId === currentSelected.campaignHashId;
    case MainNavButtonType.AD_GROUP:
      if (campaignHashId === null || currentSelected.campaignHashId === null) return false;
      if (adGroupId === null || currentSelected.adGroupId === null) return false;
      return (
        campaignHashId === currentSelected.campaignHashId && adGroupId === currentSelected.adGroupId
      );
    case MainNavButtonType.CREATORS:
      if (rootButtonName === null || currentSelected.rootButtonName === null) return false;
      return rootButtonName === currentSelected.rootButtonName;
    case MainNavButtonType.CREATOR_SETS:
      if (creatorSetId === null || currentSelected.creatorSetId === null) return false;
      return creatorSetId === currentSelected.creatorSetId;
    default:
      return false;
  }
}

export function isAdGroupSelected(
  currentSelected: SelectedNavButton,
  campaignHashId: number,
  adGroupId: number,
): boolean {
  return isNavButtonSelected(
    currentSelected,
    MainNavButtonType.AD_GROUP,
    null,
    campaignHashId,
    adGroupId,
  );
}

export function isCampaignSelected(
  currentSelected: SelectedNavButton,
  campaignHashId: number,
): boolean {
  return isNavButtonSelected(
    currentSelected,
    MainNavButtonType.CAMPAIGN,
    null,
    campaignHashId,
    null,
  );
}

export function isCreatorNavButtonSelected(currentSelected: SelectedNavButton): boolean {
  return isNavButtonSelected(
    currentSelected,
    MainNavButtonType.CREATORS,
    RootButtonName.CREATORS,
    null,
    null,
  );
}

export function isCreatorSetNavButtonSelected(
  currentSelected: SelectedNavButton,
  creatorSetId: number,
): boolean {
  return isNavButtonSelected(
    currentSelected,
    MainNavButtonType.CREATOR_SETS,
    null,
    null,
    null,
    creatorSetId,
  );
}

export function isMainNavButtonSelected(
  currentSelected: SelectedNavButton,
  rootButtonName: RootButtonName,
): boolean {
  return isNavButtonSelected(currentSelected, MainNavButtonType.MAIN, rootButtonName, null, null);
}

// ================== NAV BAR SELECT HELPERS ==================
export function selectAdGroup(
  onSelect: (value: React.SetStateAction<SelectedNavButton>) => void,
  campaignHashId: number,
  adGroupId: number,
) {
  onSelect({
    type: MainNavButtonType.AD_GROUP,
    rootButtonName: null,
    campaignHashId,
    adGroupId,
  });
}

export function selectCampaign(
  onSelect: (value: React.SetStateAction<SelectedNavButton>) => void,
  campaignHashId: number,
) {
  onSelect({
    type: MainNavButtonType.CAMPAIGN,
    rootButtonName: null,
    campaignHashId,
    adGroupId: null,
  });
}

export function selectMainNavButton(
  onSelect: (value: React.SetStateAction<SelectedNavButton>) => void,
  rootButtonName: RootButtonName,
) {
  onSelect({
    type: MainNavButtonType.MAIN,
    rootButtonName,
    campaignHashId: null,
    adGroupId: null,
  });
}

export function selectCreatorsMainNavButton(
  onSelect: (value: React.SetStateAction<SelectedNavButton>) => void,
) {
  onSelect({
    type: MainNavButtonType.CREATORS,
    rootButtonName: RootButtonName.CREATORS,
    campaignHashId: null,
    adGroupId: null,
  });
}

export function selectCreatorsSetNavButton(
  onSelect: (value: React.SetStateAction<SelectedNavButton>) => void,
  creatorSetId: number,
) {
  onSelect({
    type: MainNavButtonType.CREATOR_SETS,
    rootButtonName: null,
    campaignHashId: null,
    adGroupId: null,
    creatorSetId,
  });
}

export function getTextWidth(text: string) {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  context.font = getComputedStyle(document.body).font;

  return context.measureText(text).width;
}

export default null;
