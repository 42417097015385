import { useCampaignAppContext } from "campaigns/CampaignAppShell";
import React from "react";
import CreateCampaign from "campaigns/create/CreateCampaign";
import { useParams } from "react-router-dom";

const EditCampaign = () => {
  // TODO (victoria): pull to app shell
  const urlParams = useParams();
  const urlCampaignId = urlParams.campaignId ? parseInt(urlParams.campaignId, 10) : null;
  return <CreateCampaign campaignHashId={urlCampaignId} />;
};

export default EditCampaign;
