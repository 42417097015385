import React from "react";

import {
  Badge,
  Button,
  Flex,
  Group,
  HoverCard,
  Modal,
  Stack,
  Table,
  Text,
  ThemeIcon,
  Title,
} from "@mantine/core";

import { IconExternalLink, IconNotes } from "@tabler/icons-react";

import { getFormattedCurrency } from "components/contracts/dashboard/Utils";
import { Payment, PaymentType } from "components/contracts/models/Payment";

function MemoHoverCard({ memo }: { memo: string }) {
  const hasMemo = memo && memo.length > 0;

  if (!hasMemo) {
    return null;
  }

  return (
    <HoverCard shadow="md">
      <HoverCard.Target>
        <Flex justify="center">
          <ThemeIcon variant="transparent" color="gray" style={{ cursor: "pointer" }}>
            <IconNotes size="1rem" />
          </ThemeIcon>
        </Flex>
      </HoverCard.Target>
      <HoverCard.Dropdown>
        <Text>{memo}</Text>
      </HoverCard.Dropdown>
    </HoverCard>
  );
}

function PaymentMethodBadge({ isManualPayment }: { isManualPayment: boolean }) {
  if (isManualPayment) {
    return (
      <Flex justify="center">
        <Badge size="sm" variant="light" color="red">
          Manual
        </Badge>
      </Flex>
    );
  }

  return (
    <Flex justify="center">
      <Badge size="sm" variant="light" color="teal">
        Automatic
      </Badge>
    </Flex>
  );
}

function PaymentTypeBadge({ paymentType }: { paymentType: PaymentType }) {
  if (paymentType === PaymentType.BASE) {
    return (
      <Flex justify="center">
        <Badge size="sm" variant="light" color="cyan">
          Base
        </Badge>
      </Flex>
    );
  } else if (paymentType === PaymentType.BONUS) {
    return (
      <Flex justify="center">
        <Badge size="sm" variant="light" color="teal">
          Bonus
        </Badge>
      </Flex>
    );
  }

  return null;
}

function PaymentRow({
  payment,
  showBrandName = false,
  forBrandView = false,
}: {
  payment: Payment;
  showBrandName?: boolean;
  forBrandView?: boolean;
}) {
  const {
    brandName,
    name,
    createdAt,
    contractId,
    amount,
    isManualPayment,
    paymentType,
    memo,
    receiptUrl,
  } = payment;

  return (
    <Table.Tr>
      {showBrandName && <Table.Td>{brandName}</Table.Td>}
      <Table.Td>{createdAt.toLocaleDateString()}</Table.Td>
      <Table.Td>{name}</Table.Td>
      <Table.Td>{getFormattedCurrency(amount)}</Table.Td>
      <Table.Td>
        <PaymentTypeBadge paymentType={paymentType} />
      </Table.Td>
      <Table.Td>
        <PaymentMethodBadge isManualPayment={isManualPayment} />
      </Table.Td>
      <Table.Td>
        <Button
          component="a"
          leftSection={<IconExternalLink size="0.7rem" />}
          size="compact-sm"
          variant="transparent"
          href={
            forBrandView
              ? `https://www.1stcollab.com/contracts/${contractId}`
              : `https://www.1stcollab.com/admin/contracts/${contractId}`
          }
          target="_blank"
          disabled={!contractId}>
          View Contract
        </Button>
      </Table.Td>
      {forBrandView || (
        <Table.Td>
          <Button
            component="a"
            leftSection={<IconExternalLink size="0.7rem" />}
            size="compact-sm"
            variant="transparent"
            href={receiptUrl}
            target="_blank">
            View Receipt
          </Button>
        </Table.Td>
      )}
      {forBrandView || (
        <Table.Td>
          <MemoHoverCard memo={memo} />
        </Table.Td>
      )}
    </Table.Tr>
  );
}

function PaymentsTable({
  payments,
  campaignName,
  hideCampaignName = false,
  showBrandName = false,
  forBrandView = false,
}: {
  payments: Payment[];
  campaignName?: string;
  hideCampaignName?: boolean;
  showBrandName?: boolean;
  forBrandView?: boolean;
}) {
  const rows = payments
    .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
    .map((payment) => (
      <PaymentRow
        key={`payment-${payment.id}`}
        payment={payment}
        showBrandName={showBrandName}
        forBrandView={forBrandView}
      />
    ));

  const totalPaid = payments.reduce((total, payment) => total + payment.amount, 0);

  const totalPaidElement = (
    <Flex justify="right" mr="xs">
      <Text fw="600">
        Total: <Text span>{getFormattedCurrency(totalPaid)}</Text>
      </Text>
    </Flex>
  );

  return (
    <Stack gap="xs">
      {!hideCampaignName && (
        <Group justify="space-between">
          <Title order={4} fw="500">
            {campaignName} Payments
          </Title>
          {totalPaidElement}
        </Group>
      )}
      <Table withTableBorder withColumnBorders highlightOnHover>
        <Table.Thead>
          <Table.Tr>
            {showBrandName && <Table.Th>Brand</Table.Th>}
            <Table.Th>Date</Table.Th>
            <Table.Th>Payee</Table.Th>
            <Table.Th>Amount</Table.Th>
            <Table.Th>Type</Table.Th>
            <Table.Th>Method</Table.Th>
            <Table.Th>Contract</Table.Th>
            {forBrandView || <Table.Th>Receipt</Table.Th>}
            {forBrandView || <Table.Th>Memo</Table.Th>}
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
      </Table>
      {hideCampaignName && totalPaidElement}
    </Stack>
  );
}

export default function ViewPaymentsModal({
  payments,
  opened,
  close,
  campaignName,
  hideCampaignName = false,
  showBrandName = false,
  forBrandView = false,
}: {
  payments: Payment[];
  opened: boolean;
  close: () => void;
  campaignName?: string;
  hideCampaignName?: boolean;
  showBrandName?: boolean;
  forBrandView?: boolean;
}) {
  return (
    <Modal opened={opened} onClose={close} withCloseButton={false} size="auto">
      <PaymentsTable
        payments={payments}
        campaignName={campaignName}
        hideCampaignName={hideCampaignName}
        showBrandName={showBrandName}
        forBrandView={forBrandView}
      />
    </Modal>
  );
}
