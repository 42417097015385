import { Group, Select } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { CampaignRecords } from "admin/AdminUtils";
import { Platform, SeedType } from "components/discovery/Datamodels";
import React, { useEffect, useState } from "react";

interface SelectData {
  value: string;
  label: string;
}

type PlatformStrategies = Partial<Record<SeedType, SelectData[]>>;

// A map of platform x seed type to search strategy
// NOTE(kevin): Disable all the BFS strategies for now.
export const SEARCH_STRATEGIES: Record<Platform, PlatformStrategies> = {
  tiktok: {
    creator: [
      { value: "tiktok_precrawled_blended_hashtags", label: "Precrawled Blended Hashtags" },
      { value: "tiktok_negative_labels", label: "Negative Labels" },
      { value: "tiktok_user_hashtags", label: "Users hashtags" },
      // { value: "tiktok_user_hashtags_bfs", label: "Expanded users hashtags" },
      // { value: "tiktok_user_comments", label: "Comments" },
      { value: "tiktok_user_following", label: "Following" },
      { value: "temp_locket_2024_07", label: "Locket 2024-07" },
      { value: "temp_locket_2024_07_expanded", label: "Locket 2024-07 Expanded" },
      { value: "temp_locket_college", label: "Locket college" },
      { value: "temp_general_locket", label: "Locket general" },
    ],
    hashtags: [
      { value: "tiktok_precrawled_blended_hashtags", label: "Precrawled Blended Hashtags" },
      // { value: "tiktok_raw_hashtags", label: "Hashtags" },
      { value: "tiktok_raw_hashtags_ranked", label: "Hashtags (TF-IDF)" },
      { value: "tiktok_expanded_hashtags_ranked", label: "Expanded Hashtags (TF-IDF)" },
      // { value: "tiktok_raw_hashtags_bfs", label: "Expanded hashtags" },
      // { value: "tiktok_video_hashtags", label: "Video hashtags" },
    ],
  },
  youtube: {
    creator: [
      { value: "youtube_precrawled_blended", label: "Precrawled Blended" },
      { value: "youtube_negative_labels", label: "Negative Labels" },
      { value: "youtube_related_videos", label: "Related channels" },
    ],
    hashtags: [{ value: "youtube_raw_hashtags", label: "Hashtags" }],
    keywords: [{ value: "youtube_raw_keywords", label: "Keywords" }],
  },
  instagram: {
    creator: [
      {
        value: "instagram_suggested_users",
        label: "Suggested users",
      },
    ],
  },
  any: {},
};

export const PlatformSelector = ({
  platforms,
  setPlatform,
  platform,
  setIsUserActionChange,
}: {
  platforms: Platform[];
  setPlatform: React.Dispatch<React.SetStateAction<string>>;
  platform: Platform;
  setIsUserActionChange: (isUserActionChange: boolean) => void;
}) => (
  <Select
    id="platforms"
    onChange={(value) => {
      // Split the string by commas
      if (value === null) {
        return;
      }
      setPlatform(value.toLowerCase());
      setIsUserActionChange(true);
    }}
    label="Platform"
    data={platforms.map((p) => ({
      label: p,
      value: p,
    }))}
    value={platform}
    searchable
  />
);

export const EmailUserSelector = ({
  availableUsers,
  emailUser,
  setEmailUser,
}: {
  availableUsers: string[];
  emailUser: string;
  setEmailUser: React.Dispatch<React.SetStateAction<string>>;
}) => (
  <Select
    id="emailUser"
    onChange={(value) => {
      // Split the string by commas
      if (value === null) {
        return;
      }
      setEmailUser(value.toLowerCase());
    }}
    label="User"
    data={availableUsers.map((u) => ({
      label: u,
      value: u,
    }))}
    value={emailUser}
    searchable
  />
);

export const DateSelector = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}: {
  startDate: Date | null;
  endDate: Date | null;
  setStartDate: (startDate: Date | null) => void;
  setEndDate: (endDate: Date | null) => void;
}) => (
  <Group>
    <DateInput
      value={startDate}
      onChange={setStartDate}
      label="Start date"
      placeholder="Start date"
    />
    <DateInput value={endDate} onChange={setEndDate} label="End date" placeholder="End date" />
  </Group>
);

export const CampaignPlatformArchetypeSelector = ({
  campaignData,
  platforms,
  campaignId,
  archetypeId,
  platform,
  setCampaignId,
  setArchetypeId,
  setPlatform,
  setIsUserActionChange,
}: {
  campaignData: CampaignRecords;
  platforms: Platform[];
  campaignId: number;
  archetypeId: number;
  platform: Platform;
  setCampaignId: (campaignId: number) => void;
  setArchetypeId: (archetype: number) => void;
  setPlatform: (platform: Platform) => void;
  setIsUserActionChange: (isUserActionChange: boolean) => void;
}) => {
  const [availableArchetypes, setAvailableArchetypes] = useState([]);
  const campaignDataValid =
    campaignData !== null && campaignData !== undefined && Object.keys(campaignData).length > 0;

  useEffect(() => {
    if (
      !campaignDataValid ||
      Object.keys(campaignData).length === 0 ||
      !campaignId == null ||
      !campaignData[campaignId] ||
      campaignData[campaignId].archetypes === undefined
    ) {
      setAvailableArchetypes([]);
      return;
    }
    setAvailableArchetypes(campaignData[campaignId].archetypes);
  }, [campaignId, campaignData]);

  return (
    <div>
      {campaignDataValid ? (
        <Group>
          <Select
            id="campaign_name"
            onChange={(value) => {
              // Split the string by commas
              setCampaignId(Number(value));
              // set the archetypeId to be the first archetype.
              if (availableArchetypes.length > 0) {
                setArchetypeId(availableArchetypes[0].id);
              } else {
                setArchetypeId(null);
              }
              setIsUserActionChange(true);
            }}
            label="Campaign Name"
            value={String(campaignId)}
            data={Object.keys(campaignData).map((key) => {
              const campaign = campaignData[Number(key)];
              return {
                label: campaign.campaign_title,
                value: String(campaign.campaign_id),
              };
            })}
            searchable
          />
          <Select
            id="archetype_name"
            onChange={(value) => {
              // Split the string by commas
              setArchetypeId(Number(value));
              setIsUserActionChange(true);
            }}
            label="Archetype Name"
            data={availableArchetypes.map((archetype) => ({
              label: archetype.name,
              value: String(archetype.id),
            }))}
            value={String(archetypeId)}
            searchable
          />
          <PlatformSelector
            platforms={platforms}
            setPlatform={setPlatform}
            platform={platform}
            setIsUserActionChange={setIsUserActionChange}
          />
        </Group>
      ) : null}
    </div>
  );
};
