import React, { useEffect, useState } from "react";
import { Button, Flex, Link, Text } from "gestalt";
import "gestalt/dist/gestalt.css";
import { User } from "firebase/auth";

import LayoutBase from "components/LayoutBase";
import LoadingScreen from "components/LoadingScreen";
import { getInstagramProfiles, InstagramProfileResponse } from "social/fb/InstagramUtils";
import { getAdminUser } from "admin/AdminLayout";

const getAndSetInstagramProfiles = async (
  user: User,
  setProfiles: (profiles: InstagramProfileResponse[]) => void,
) => {
  const firebaseToken = await user.getIdToken();
  // we loaded a lot of instagram profiles in so don't load everything
  const profiles = await getInstagramProfiles(firebaseToken, false);
  console.log(profiles);
  setProfiles(profiles);
};

const InstagramProfilesDisplay = ({ profiles }: { profiles: InstagramProfileResponse[] }) => {
  const profilesList = profiles.map((profile) => (
    <Link
      key={`Link-${profile.socialUsername}`}
      href={`/admin/instagram_profile/${profile.socialUsername}`}>
      {profile.socialUsername}
    </Link>
  ));
  return (
    <Flex direction="column" gap={4}>
      {profilesList}{" "}
    </Flex>
  );
};

const InstagramProfiles = () => {
  const user: User = getAdminUser();
  const [profiles, setProfiles] = useState(null);

  useEffect(() => {
    getAndSetInstagramProfiles(user, setProfiles);
  }, []);

  return (
    <Flex direction="column" gap={4}>
      <LayoutBase>
        {profiles ? <InstagramProfilesDisplay profiles={profiles} /> : <LoadingScreen />}
      </LayoutBase>
    </Flex>
  );
};

export default InstagramProfiles;
