export interface AudienceEntryResponse {
  // The profile we are looking at the audience split for.
  profile: string;
  // Metric we care about (Gender, Age, Country)
  metric: string;
  // Audience we care about - specialization in the metric (i.e. M/F/U for gender, 10-20/20+ for age)
  audience: string;
  date: Date;
  last_update: Date;
  date_created: Date;
  value: number;
  // The percentage when you look at the value divided by the sum of
  // all other audiences in the same metric
  percentage: number;
}

export interface AudienceResponse {
  audience_city?: AudienceEntryResponse[];
  // TODO: merge audience country and country fields
  audience_country?: AudienceEntryResponse[];
  country?: AudienceEntryResponse[];
  audience_gender_age?: AudienceEntryResponse[];
  audience_locale?: AudienceEntryResponse[];
  gender?: AudienceEntryResponse[];
  age_bucket?: AudienceEntryResponse[];
}

export interface StatsEntryResponse {
  date: Date;
  date_created: Date;
  last_updated: Date;
  metric: string;
  period: string;
  profile: string;
  value: number;
}

export type PlatformInsightsResponse = {
  audience: AudienceResponse;
  stats: StatsEntryResponse[];
};

export const getLatestMediaUrl = (profile_url: string, profile_username: string) =>
  `${profile_url}${profile_username}/get_latest_media/`;
