import React from "react";

import { useAdminAppContext } from "admin/app/AdminAppShell";
import { CreatorSetTable } from "campaigns/main/campaignPage/creator_sets/CreatorSetTable";

export const OpsUserCreatorSetsTable = ({ creatorSetIds }: { creatorSetIds: number[] }) => {
  // // metadata is joined on campaign from context
  const { creatorSets } = useAdminAppContext();

  const filteredCreatorSets = creatorSets.filter((creatorSet) =>
    creatorSetIds.includes(creatorSet.id),
  );

  return <CreatorSetTable creatorSets={filteredCreatorSets} readOnly showBrandDetails />;
};

export default OpsUserCreatorSetsTable;
