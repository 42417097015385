import React from "react";
import { Status } from "gestalt";
import "gestalt/dist/gestalt.css";

export enum AuthenticationState {
  LOADING = 1,
  NOT_AUTHENTICATED = 2,
  AUTHENTICATED = 3,
  ERROR = 4, // this doesn't seem to be actually used (not returned from backend)
  IN_DEVELOPMENT = 5, // state for social networks that don't currently support authentication but for which we'll build it in the future.
  CANCELLED = 6, // if the user cancels the authentication process
}

export interface SetAuthState {
  (loginStatus: AuthenticationState): void;
}

export const AuthenticationStatus = ({ authStep }: { authStep: AuthenticationState }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  switch (authStep) {
    case AuthenticationState.LOADING:
      return <Status type="inProgress" title="Getting Status" />;
    case AuthenticationState.NOT_AUTHENTICATED:
      return <Status type="unstarted" title="Unauthenticated" />;
    case AuthenticationState.AUTHENTICATED:
      return <Status type="ok" title="Authenticated!" />;
    case AuthenticationState.ERROR:
      return <Status type="problem" title="Internal Error" subtext="Please refresh." />;
    case AuthenticationState.IN_DEVELOPMENT:
      return <Status type="halted" title="In Development" subtext="Check back soon!" />;
    case AuthenticationState.CANCELLED:
      return <Status type="unstarted" title="Cancelled" subtext="Permissions not granted." />;
    default:
      return <Status type="unstarted" />;
  }
};
