import React from "react";
import { Accordion, Card, Group, Stack, Table, Text, Title } from "@mantine/core";
import { IconBrandTiktok, IconBrandInstagram, IconBrandYoutubeFilled } from "@tabler/icons-react";

import { TikTokStats, YoutubeStats, InstagramStats } from "creators/api/fetchExtendedStats";
import { getAbbreviatedNumber } from "utils/AnalyticsUtils";

const AccordionMetrics = ({
  tikTokStats,
  youtubeLongStats,
  youtubeShortsStats,
  instagramStats,
}: {
  tikTokStats: TikTokStats;
  youtubeLongStats: YoutubeStats;
  youtubeShortsStats: YoutubeStats;
  instagramStats: InstagramStats;
}) => {
  const PLATFORM_MAP = {
    tiktok: tikTokStats,
    "youtube-long": youtubeLongStats,
    "youtube-shorts": youtubeShortsStats,
    "ig-reels": instagramStats,
  };
  const accordionItems = Object.entries(PLATFORM_MAP)
    .filter(([platform, stats]) => stats)
    .map(([platform, stats]) => {
      let platformIcon = null;
      let variantColor = "";
      let title = "";
      switch (platform) {
        case "tiktok":
          variantColor = "var(--mantine-color-cyan-4";
          platformIcon = <IconBrandTiktok color={variantColor} size={24} />;
          title = "TikTok";
          break;
        case "youtube-long":
          variantColor = "var(--mantine-color-red-6";
          platformIcon = <IconBrandYoutubeFilled color={variantColor} size={24} />;
          title = "YouTube Long";
          break;
        case "youtube-shorts":
          variantColor = "var(--mantine-color-red-6";
          platformIcon = <IconBrandYoutubeFilled color={variantColor} size={24} />;
          title = "YouTube Shorts";
          break;
        case "ig-reels":
          variantColor = "var(--mantine-color-grape-6";
          platformIcon = <IconBrandInstagram color={variantColor} size={24} />;
          title = "Instagram Reels";
          break;
        default:
          platformIcon = null;
          variantColor = "light";
      }
      return (
        <Accordion.Item key={platform} value={platform}>
          <Accordion.Control icon={platformIcon}>{title}</Accordion.Control>
          <Accordion.Panel>
            <Table highlightOnHover>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>Metric</Table.Th>
                  <Table.Th>Value</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {Object.entries(stats).map(([metric, value]) => (
                  <Table.Tr key={metric}>
                    <Table.Td>{metric}</Table.Td>
                    <Table.Td>{!value ? "N/A" : value.toLocaleString()}</Table.Td>
                  </Table.Tr>
                ))}
              </Table.Tbody>
            </Table>
          </Accordion.Panel>
        </Accordion.Item>
      );
    });
  return <Accordion variant="separated">{accordionItems.map((platform) => platform)}</Accordion>;
};

const CreatorMetricsView = ({
  tikTokStats,
  youtubeLongStats,
  youtubeIntegratedStats,
  youtubeShortsStats,
  instagramStats,
}: {
  tikTokStats: TikTokStats;
  youtubeLongStats: YoutubeStats;
  youtubeIntegratedStats: YoutubeStats;
  youtubeShortsStats: YoutubeStats;
  instagramStats: InstagramStats;
}) => {
  const activePlatformStats: Record<string, TikTokStats | YoutubeStats | InstagramStats> = {
    tiktok: tikTokStats,
    "youtube-long": youtubeLongStats,
    "youtube-integrated": youtubeIntegratedStats,
    "youtube-shorts": youtubeShortsStats,
    "ig-reels": instagramStats,
  };
  const activePlatformPredictedViews = Object.entries(activePlatformStats)
    .filter(([platformType, stats]) => stats && stats.predicted_views_upper)
    .map(([platform, stats]) => {
      const predictedUpper = getAbbreviatedNumber(stats.predicted_views_upper);
      const predictedLower = getAbbreviatedNumber(stats.predicted_views_lower);
      let platformIcon = null;
      let variantColor = "";
      let title = "";
      switch (platform) {
        case "tiktok":
          variantColor = "var(--mantine-color-cyan-4";
          platformIcon = <IconBrandTiktok color={variantColor} size={24} />;
          title = "TikTok";
          break;
        case "youtube-long":
          variantColor = "var(--mantine-color-red-6";
          platformIcon = <IconBrandYoutubeFilled color={variantColor} size={24} />;
          title = "YouTube Long Dedicated";
          break;
        case "youtube-integrated":
          variantColor = "var(--mantine-color-red-6";
          platformIcon = <IconBrandYoutubeFilled color={variantColor} size={24} />;
          title = "YouTube Long Integrated";
          break;
        case "youtube-shorts":
          variantColor = "var(--mantine-color-red-6";
          platformIcon = <IconBrandYoutubeFilled color={variantColor} size={24} />;
          title = "YouTube Shorts";
          break;
        case "ig-reels":
          variantColor = "var(--mantine-color-grape-6";
          platformIcon = <IconBrandInstagram color={variantColor} size={24} />;
          title = "Instagram Reels";
          break;
        default:
          platformIcon = null;
          variantColor = "light";
      }
      return (
        <Group key={platform}>
          {platformIcon}
          Predicted {title} Views:
          <Text size="md" fw={500}>
            {predictedUpper.toLocaleString()}
          </Text>
        </Group>
      );
    });

  return (
    <Card shadow="sm" mx="lg" padding="lg" radius="md" withBorder>
      <Stack>
        <Title order={4}>Creator Metrics</Title>
        <Card withBorder>{activePlatformPredictedViews.map((platform) => platform)}</Card>
        <AccordionMetrics
          tikTokStats={tikTokStats}
          youtubeLongStats={youtubeLongStats}
          youtubeShortsStats={youtubeShortsStats}
          instagramStats={instagramStats}
        />
      </Stack>
    </Card>
  );
};

export default CreatorMetricsView;
