import { createSelector } from "@reduxjs/toolkit";
import { BrandStatus } from "models/Brand";
import { RootState } from "reduxStore/store";
import { selectMyLaunchedCampaigns } from "reduxStore/campaignsSelectors";

const selectMe = (state: RootState) => state.me.user;
const selectMyBrand = (state: RootState) => state.me.brand;

const selectFetchAllCreativeBriefsList = (state: RootState) => state.creativeBriefs.fetchAllOrdered;
const selectNumActivatedCreators = (state: RootState) =>
  state.creatorSets.allActivatedEntryItemsIdList.length;

// returns true if user needs to go through campaign onboarding according to their user settings
export const selectUserNeedsCampaignOnboarding = createSelector(
  [
    selectMe,
    selectMyBrand,
    selectFetchAllCreativeBriefsList,
    selectNumActivatedCreators,
    selectMyLaunchedCampaigns,
    (state: RootState, { checkLaunchedCampaigns }: { checkLaunchedCampaigns: boolean }) =>
      checkLaunchedCampaigns,
  ],
  (me, brand, fetchAllIdList, numActivatedCreators, launchedCampaigns, checkLaunchedCampaigns) => {
    const skipOnboarding = brand?.skip_onboarding ?? false;
    const brandIsActive = brand?.status === BrandStatus.Active;
    const brandHasEnoughActivatedCreators =
      numActivatedCreators >= (brand?.onboarding_creator_activation_target ?? 100);
    const brandHasCreativeBriefs = fetchAllIdList.length > 0;
    const brandSettingsCompleted =
      brand &&
      brand.brand_name &&
      brand.company_website &&
      brand.company_blurb &&
      brand.contact_email &&
      brand.billing_email &&
      brand.outreach_email_configured;
    const userHasAcceptedBrandTOS = me?.accepted_brand_tos ?? false;

    let launchedCampaignsCheckPassed = false;
    if (!checkLaunchedCampaigns) {
      launchedCampaignsCheckPassed = true;
    } else {
      launchedCampaignsCheckPassed = launchedCampaigns.length > 0;
    }

    const shouldShowOnboarding =
      !brandIsActive ||
      !brandHasEnoughActivatedCreators ||
      !brandHasCreativeBriefs ||
      !brandSettingsCompleted ||
      !userHasAcceptedBrandTOS ||
      !launchedCampaignsCheckPassed;
    return !skipOnboarding && shouldShowOnboarding;
  },
);

export default null;
