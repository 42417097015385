import React, { useEffect, useState } from "react";

import {
  ActionIcon,
  Anchor,
  Badge,
  Box,
  Button,
  Center,
  Container,
  CopyButton,
  Drawer,
  Flex,
  Group,
  Loader,
  Paper,
  Progress,
  Stack,
  Table,
  Text,
  TextInput,
  Title,
  Tooltip,
} from "@mantine/core";

import {
  IconCheck,
  IconCopy,
  IconEdit,
  IconExternalLink,
  IconPencil,
  IconPlus,
} from "@tabler/icons-react";

import { User } from "models/User";
import { CreativeBriefTableRow } from "campaigns/main/campaignPage/creative_briefs/CreativeBriefTableRow";
import { CreativeBrief } from "models/CreativeBrief";
import { useAppDispatch, useAppSelector } from "reduxStore/hooks";
import ProductGuideLink from "campaigns/create/common/ProductGuideLink";
import { useDisclosure } from "@mantine/hooks";
import { fetchAllCreativeBriefs } from "reduxStore/creativeBriefsSlice";
import { selectAllCreativeBriefs } from "reduxStore/creativeBriefsSelectors";
import { useCampaignAppContext } from "campaigns/CampaignAppShell";
import EmptyState from "campaigns/main/campaignPage/EmptyState";
import CreativeBriefDrawer from "campaigns/main/campaignPage/creative_briefs/CreativeBriefDrawer";
import CreativeBriefGuide from "campaigns/main/campaignPage/creative_briefs/CreativeBriefGuide";

// TODO(andrew): make this extendable

function EditToggleableTextInput({
  value,
  onChange,
  isEditing,
  setIsEditing,
  ...props
}: {
  value: string;
  onChange: (newValue: string) => void;
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
  [x: string]: any;
}) {
  const [initialValue, setInitialValue] = useState(value);
  if (isEditing) {
    return (
      <Group grow>
        <TextInput
          value={value}
          onChange={(event) => onChange(event.currentTarget.value)}
          onBlur={() => setIsEditing(false)}
          {...props}
        />
        {/* <Tooltip label="Save">
          <ActionIcon
            variant="subtle"
            component="button"
            size={32}
            onClick={() => setIsEditing(false)}
          >
            <IconCheck size="1.5rem"/>
          </ActionIcon>
        </Tooltip> */}
      </Group>
    );
  }
  return (
    <Group grow>
      <Text size="sm">{value}</Text>
      {/* <TextInput
        variant="unstyled"
        value={value}
        onChange={(event) => {
          console.log(initialValue, value, initialValue !== value);
          onChange(event.currentTarget.value)
        }
        }
        styles={{
          input: (initialValue !== value)
            ? {
                outline: "2px solid #FFEFCC",
                outlineOffset: "-2px",
                // backgroundColor: "#D2F9E0",
              }
            : null,
        }}
      /> */}
      {/* {initialValue !== value ? (
        <Tooltip label="Save">
          <ActionIcon
            variant="subtle"
            component="button"
            size={32}
            onClick={() => setIsEditing(false)}
          >
            <IconCheck size="1.5rem" />
          </ActionIcon>
        </Tooltip>) : null} */}
    </Group>
  );
}

function CreativeBriefRow({
  userProfile,
  creativeBrief,
}: {
  userProfile: User;
  creativeBrief: CreativeBrief;
}) {
  const [name, setName] = useState(creativeBrief.name);
  const [isEditingName, setIsEditingName] = useState(false);
  const [description, setDescription] = useState(creativeBrief.description);
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [internalBriefUrl, setInternalBriefUrl] = useState(creativeBrief.internal_brief_url);
  const [isEditingInternalBriefUrl, setIsEditingInternalBriefUrl] = useState(false);
  const [externalBriefUrl, setExternalBriefUrl] = useState(creativeBrief.external_brief_url);
  const [isEditingExternalBriefUrl, setIsEditingExternalBriefUrl] = useState(false);

  return (
    <Table.Tr key={creativeBrief.id}>
      <Table.Td>
        <Text size="sm">{name}</Text>
      </Table.Td>
      <Table.Td>
        <Flex direction="row" justify="space-between" align="center" gap="md">
          <Flex maw="80%" direction="row" justify="flex-start">
            <Text size="sm" truncate="end">
              {internalBriefUrl}
            </Text>
          </Flex>
          <Flex direction="row" justify="flex-end" align="center">
            <ActionIcon
              variant="subtle"
              color="blue"
              component="a"
              href={internalBriefUrl}
              target="_blank"
              size="1.3rem">
              <IconExternalLink size="1.3rem" />
            </ActionIcon>
            <CopyButton value={internalBriefUrl} timeout={1000}>
              {({ copied, copy }) => (
                <Tooltip label={copied ? "Copied" : "Copy"} withArrow position="right">
                  <ActionIcon color={copied ? "teal" : "blue"} variant="subtle" onClick={copy}>
                    {copied ? <IconCheck size="1.2rem" /> : <IconCopy size="1.2rem" />}
                  </ActionIcon>
                </Tooltip>
              )}
            </CopyButton>
          </Flex>
        </Flex>
      </Table.Td>
      <Table.Td>
        <Flex direction="row" justify="space-between" align="center" gap="md">
          <Flex maw="80%" direction="row" justify="flex-start">
            <Text size="sm" truncate="end">
              {externalBriefUrl}
            </Text>
          </Flex>
          <Flex direction="row" justify="flex-end" align="center">
            <ActionIcon
              variant="subtle"
              color="blue"
              component="a"
              href={externalBriefUrl}
              target="_blank"
              size="1.3rem">
              <IconExternalLink size="1.3rem" />
            </ActionIcon>
            <CopyButton value={externalBriefUrl} timeout={1000}>
              {({ copied, copy }) => (
                <Tooltip label={copied ? "Copied" : "Copy"} withArrow position="right">
                  <ActionIcon color={copied ? "teal" : "blue"} variant="subtle" onClick={copy}>
                    {copied ? <IconCheck size="1.2rem" /> : <IconCopy size="1.2rem" />}
                  </ActionIcon>
                </Tooltip>
              )}
            </CopyButton>
          </Flex>
        </Flex>
      </Table.Td>
      <Table.Td maw={400}>
        <Flex gap={4} wrap="wrap">
          {creativeBrief.ad_groups?.length > 0 &&
            creativeBrief.ad_groups.map((adGroup) => (
              <Badge variant="light" key={`creative-brief-adgroup=${adGroup.id}`}>
                {adGroup.name}
              </Badge>
            ))}
        </Flex>
      </Table.Td>
    </Table.Tr>
  );
}

const CreativeBriefTable = ({
  onEditClicked,
}: {
  onEditClicked: (selectedBrief: CreativeBrief) => void;
}) => {
  const creativeBriefs = useAppSelector((state) => selectAllCreativeBriefs(state));

  const rows = creativeBriefs.map((brief) => (
    <Table.Tr key={brief.id}>
      <Table.Td>{brief.name}</Table.Td>
      <Table.Td>
        <Anchor
          href={brief.internal_brief_url}
          target="_blank"
          size="sm"
          style={{ wordWrap: "break-word" }}>
          {brief.internal_brief_url}
        </Anchor>
      </Table.Td>
      <Table.Td>
        <Anchor
          href={brief.external_brief_url}
          target="_blank"
          size="sm"
          style={{ wordWrap: "break-word" }}>
          {brief.external_brief_url}
        </Anchor>
      </Table.Td>
      <Table.Td maw={400}>
        <Flex gap={4} wrap="wrap">
          {brief.ad_groups?.length > 0 &&
            brief.ad_groups.map((adGroup) => (
              <Tooltip
                key={`creative-brief-adgroup=${adGroup.id}-tooltip`}
                label={`(${adGroup.campaign_name}) ${adGroup.name}`}>
                <Badge variant="light" key={`creative-brief-adgroup=${adGroup.id}`}>
                  {adGroup.name}
                </Badge>
              </Tooltip>
            ))}
        </Flex>
      </Table.Td>
      <Table.Td>
        <ActionIcon
          variant="transparent"
          onClick={() => onEditClicked(brief)}
          color="var(--mantine-color-gray-6)">
          <IconPencil size={20} />
        </ActionIcon>
      </Table.Td>
    </Table.Tr>
  ));

  return (
    // <Table.ScrollContainer minWidth={400}>
    <Container fluid>
      <Table horizontalSpacing="sm" striped highlightOnHover withTableBorder layout="fixed">
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Name</Table.Th>
            <Table.Th>Edit URL</Table.Th>
            <Table.Th>Published URL</Table.Th>
            <Table.Th>Linked Ad Groups</Table.Th>
            <Table.Th w={50}>Edit</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
      </Table>
    </Container>
    // </Table.ScrollContainer>
  );
};

const CreateNewBriefButton = ({ onClick }: { onClick: () => void }) => (
  <Button size="sm" variant="filled" leftSection={<IconPlus size={16} />} onClick={onClick}>
    New Creative Brief
  </Button>
);

const CreativeBriefEmptyState = ({
  onCreateNewBriefButtonClicked,
}: {
  onCreateNewBriefButtonClicked: () => void;
}) => {
  return (
    <EmptyState
      ctaButton={<CreateNewBriefButton onClick={onCreateNewBriefButtonClicked} />}
      title="Create your first creative brief"
      description="Creative briefs are how brands offer creative direction to creators. We send these creative briefs to creators your activate into your campaign. Creative briefs provide creators information about the brand, talking points, example content and more."
      secondDescription={
        <>
          For more information about creative briefs, check out our <ProductGuideLink />.
        </>
      }
    />
  );
};

const BrandCreativeBriefHome = () => {
  const { mainAppShellHeight } = useCampaignAppContext();
  const [
    isBriefCreationDrawerOpen,
    { open: openBriefCreationDrawer, close: closeBriefCreationDrawer },
  ] = useDisclosure(false);
  const dispatch = useAppDispatch();
  const creativeBriefs = useAppSelector((state) => selectAllCreativeBriefs(state));
  const [showEmptyState, setShowEmptyState] = useState<boolean>(false);
  const [selectedBrief, setSelectedBrief] = useState<CreativeBrief | null>(null);
  const allowBriefCreation = true;

  const [isLoadingBriefs, setIsLoadingBriefs] = useState<boolean>(false);
  useEffect(() => {
    const abortController = new AbortController();
    setIsLoadingBriefs(true);

    dispatch(fetchAllCreativeBriefs({ abortController }))
      .unwrap()
      .then(() => {
        setIsLoadingBriefs(false);
      });

    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    if (creativeBriefs.length === 0) {
      if (!isLoadingBriefs) {
        setShowEmptyState(true);
      }
    } else {
      setShowEmptyState(false);
    }
  }, [isLoadingBriefs, creativeBriefs]);

  return (
    <Paper
      p="md"
      style={{
        height: showEmptyState ? mainAppShellHeight : undefined,
      }}>
      <Stack
        style={{
          height: showEmptyState ? "100%" : undefined,
        }}>
        {allowBriefCreation ? (
          <>
            <CreativeBriefGuide />
            <Group style={{ justifyContent: "space-between" }}>
              <Title order={1}>Creative Briefs</Title>
              <CreateNewBriefButton onClick={openBriefCreationDrawer} />
            </Group>
          </>
        ) : (
          <Center>
            <Title order={1}>Creative Briefs</Title>
          </Center>
        )}
        {isLoadingBriefs ? (
          <Center>
            <Group>
              <Loader size={30} />
              <Text c="var(--mantine-primary-color-filled)">Fetching latest briefs...</Text>
            </Group>
          </Center>
        ) : null}
        {showEmptyState ? (
          <Box style={{ flexGrow: 1 }}>
            <CreativeBriefEmptyState onCreateNewBriefButtonClicked={openBriefCreationDrawer} />
          </Box>
        ) : (
          <CreativeBriefTable
            onEditClicked={(brief) => {
              setSelectedBrief(brief);
              openBriefCreationDrawer();
            }}
          />
        )}
      </Stack>
      <Drawer
        size="xl"
        position="right"
        opened={isBriefCreationDrawerOpen}
        onClose={closeBriefCreationDrawer}
        withCloseButton={false}>
        <CreativeBriefDrawer
          brief={selectedBrief}
          onCancelClicked={() => {
            closeBriefCreationDrawer();
            setSelectedBrief(null);
          }}
          onBriefCreated={closeBriefCreationDrawer}
          onBriefUpdated={() => {
            closeBriefCreationDrawer();
            setSelectedBrief(null);
          }}
        />
      </Drawer>
    </Paper>
  );
};

export default BrandCreativeBriefHome;
