import React from "react";
import { Button } from "gestalt";
import "gestalt/dist/gestalt.css";

import "pages/home/styles/light-styles.css";
import { useNavigate } from "react-router-dom";

export const CreatorSignupButton = ({ buttonText }: { buttonText?: string }) => {
  const navigate = useNavigate();
  const text = buttonText || "Get Started";
  return <Button text={text} color="blue" onClick={() => navigate("/signup")} />;
};

export default null;
