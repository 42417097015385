import React, { useEffect, useState } from "react";

import {
  Center,
  Container,
  Loader,
  LoadingOverlay,
  Paper,
  SimpleGrid,
  Space,
  Stack,
  Title,
} from "@mantine/core";
import { AreaChart, LineChart } from "@mantine/charts";

import { getCoreMetrics, getGMVForecast } from "components/metrics/Api";

interface ContractTimeSeriesItem {
  date: string;
  created_contracts: number;
  signed_contracts: number;
  completed_contracts: number;
  created_contracts_14_day_avg: number;
  signed_contracts_14_day_avg: number;
  completed_contracts_14_day_avg: number;
}

interface FunnelVolumeTimeSeriesItem {
  date: string;
  num_ops_accepted_14_day_avg: number;
  num_internal_accepted_14_day_avg: number;
  num_brand_accepted_14_day_avg: number;
  num_email_subscribed_14_day_avg: number;
  brand_accept_rate_14_day_window: number;
  internal_accept_rate_14_day_window: number;
}

interface DiscoveryTimeSeriesItem {
  date: string;
  num_queries_14_day_avg: number;
  num_page_views_14_day_avg: number;
  num_activations_14_day_avg: number;
  num_saves_14_day_avg: number;
}

interface AverageContractValueTimeSeriesItem {
  date: string;
  contract_value_14_day_avg: number;
}

interface CurrentMonthGmvForecastTimeSeriesItem {
  date: string;
  cumulative_completed_gmv: number;
  cumulative_overdue_gmv: number;
  cumulative_forecasted_gmv: number;
}

interface NextMonthGmvForecastTimeSeriesItem {
  date: string;
  cumulative_forecasted_gmv: number;
}

interface RecurringTimeSeriesItem {
  date: Date;
  gmv: number;
  revenue: number;
}

const NINETY_DAYS_AGO = new Date();
NINETY_DAYS_AGO.setDate(NINETY_DAYS_AGO.getDate() - 90);

// Simulated Mantine colors object
const colors = {
  red: ["#ff0000"],
  blue: ["#0000ff"],
  green: ["#00ff00"],
  yellow: ["#ffff00"],
  purple: ["#800080"],
  orange: ["#ff8c00"],
  teal: ["#008080"],
  pink: ["#ff1493"],
  cyan: ["#00ffff"],
  lime: ["#00ff00"],
  gray: ["#808080"],
  brown: ["#a52a2a"],
};

// Simple hash function for a string
function hashString({ str }: { str: string }) {
  let hash = 0;
  for (let i = 0; i < str.length; i += 1) {
    const char = str.charCodeAt(i);
    hash = hash * 31 + char;
    hash %= 2147483647;
  }
  return Math.abs(hash);
}

const currentDate = new Date().toLocaleDateString("en-US", {
  year: "numeric",
  month: "short",
  day: "numeric",
});

const currentMonthName = new Date().toLocaleDateString("en-US", {
  month: "long",
});

const nextMonthName = new Date(new Date().setMonth(new Date().getMonth() + 1)).toLocaleDateString(
  "en-US",
  {
    month: "long",
  },
);

function getDeterministicColor({ str }: { str: string }) {
  // Get all color keys (e.g., 'red', 'blue', 'green')
  const colorKeys = Object.keys(colors);

  // Hash the input string to get a numerical value
  const hash = hashString({ str });

  // Use the hash to deterministically select a color key
  const colorKey = colorKeys[hash % colorKeys.length];

  // Use the hash to deterministically select a shade for the chosen color
  const shades = colors[colorKey as keyof typeof colors];
  const shade = shades[hash % shades.length];

  return shade;
}

function formatDollarAmount(amount: number): string {
  if (amount < 1e3) {
    // For amounts less than $1k, format as $0.Xk
    return `$${(amount / 1e3).toFixed(1)}k`;
  }

  // For amounts $1k and above, format as $Xk
  const units = [
    { value: 1e9, symbol: "b" },
    { value: 1e6, symbol: "m" },
    { value: 1e3, symbol: "k" }, // This now applies only for $1k and above
  ];

  // Find the appropriate unit
  const unit = units.find((u) => amount >= u.value);

  // Format the amount with the unit
  return unit ? `$${(amount / unit.value).toFixed(1)}${unit.symbol}` : `$${amount.toFixed(2)}`;
}

export function GMVForecast({ campaignId }: { campaignId?: string }) {
  const [loading, setLoading] = useState(false);
  const [currentMonthGmvForecastTimeSeries, setCurrentMonthGmvForecastTimeSeries] = useState<
    CurrentMonthGmvForecastTimeSeriesItem[]
  >([]);
  const [nextMonthGmvForecastTimeSeries, setNextMonthGmvForecastTimeSeries] = useState<
    NextMonthGmvForecastTimeSeriesItem[]
  >([]);
  const [adjustedBudgetState, setAdjustedBudgetState] = useState<number | null>(null);
  const [isOneTimeBudget, setIsOneTimeBudget] = useState<boolean>(null);

  useEffect(() => {
    setLoading(true);
    getGMVForecast({ campaignId })
      .then((response) => {
        const { success, currentMonthForecast, nextMonthForecast, adjustedBudget, oneTimeBudget } = response;

        if (success) {
          setCurrentMonthGmvForecastTimeSeries(JSON.parse(currentMonthForecast));
          setNextMonthGmvForecastTimeSeries(JSON.parse(nextMonthForecast));
          setAdjustedBudgetState(adjustedBudget);
          setIsOneTimeBudget(oneTimeBudget);
        }
      })
      .finally(() => setLoading(false));
  }, [campaignId]);


  const getSumForMaxDate = (data: CurrentMonthGmvForecastTimeSeriesItem[]): number => {
    if (data.length === 0) {
      return 0; // Return 0 or throw an error if the array is empty
    }

    // Find the maximum date
    const maxDate = data.reduce((max, item) => (item.date > max ? item.date : max), data[0].date);

    // Find the item with the maximum date
    const maxDateItem = data.find((item) => item.date === maxDate);

    if (!maxDateItem) {
      return 0; // This should never happen if the data is consistent
    }

    // Sum the three values
    const sum =
      maxDateItem.cumulative_completed_gmv +
      maxDateItem.cumulative_overdue_gmv +
      maxDateItem.cumulative_forecasted_gmv;

    return sum;
  };

  // Calculate the maximum y-axis value for the chart and round to nearest $1k
  const yMax =
    Math.ceil(
      (Math.max(adjustedBudgetState, getSumForMaxDate(currentMonthGmvForecastTimeSeries)) * 1.1) /
        100000,
    ) * 100000;


  if (loading) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  return (
    <SimpleGrid cols={2} spacing="xl">
      <Stack>
        <Title order={2} fw="500" py="sm" px="xl">
          {currentMonthName} GMV Forecast
        </Title>
        <AreaChart
          h={300}
          data={currentMonthGmvForecastTimeSeries}
          dataKey="date"
          type="stacked"
          valueFormatter={(value) =>
            (value / 100).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })
          }
          series={[
            {
              name: "cumulative_completed_gmv",
              label: "Completed",
              color: "green",
            },
            {
              name: "cumulative_overdue_gmv",
              label: "Overdue",
              color: "red",
            },
            {
              name: "cumulative_forecasted_gmv",
              label: "Forecasted",
              color: "blue",
              strokeDasharray: "6 4",
            },
          ]}
          withGradient
          withDots={false}
          referenceLines={
            adjustedBudgetState
              ? [
                  { x: currentDate, label: "Today" },
                  {
                    y: Number(adjustedBudgetState),
                    label: ` ${Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(adjustedBudgetState / 100)} - ${
                      isOneTimeBudget ? "Remaining Lifetime Budget" : "Monthly Budget"
                    }`,
                    color: "red",
                  },
                ]
              : [{ x: currentDate, label: "Today" }]
          }
          yAxisProps={adjustedBudgetState && { domain: [0, yMax] }}
        />
      </Stack>
      <Stack>
        <Title order={2} fw="500" py="sm" px="xl">
          {nextMonthName} GMV Forecast
        </Title>
        <AreaChart
          h={300}
          data={nextMonthGmvForecastTimeSeries}
          dataKey="date"
          valueFormatter={(value) =>
            (value / 100).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })
          }
          series={[
            {
              name: "cumulative_forecasted_gmv",
              label: "Forecasted",
              color: "blue",
              strokeDasharray: "6 4",
            },
          ]}
          withGradient
          withDots={false}
        />
      </Stack>
    </SimpleGrid>
  );
}

export default function Metrics() {
  const [loading, setLoading] = useState(false);

  const [contractTimeSeries, setContractTimeSeries] = useState<ContractTimeSeriesItem[]>([]);
  const [cohortGmvTimeSeries, setCohortGmvTimeSeries] = useState([]);
  const [cohortLabels, setCohortLabels] = useState<string[]>([]);
  const [averageContractValueTimeSeries, setAverageContractValueTimeSeries] = useState<
    AverageContractValueTimeSeriesItem[]
  >([]);
  const [funnelVolumeTimeSeries, setFunnelVolumeTimeSeries] = useState<
    FunnelVolumeTimeSeriesItem[]
  >([]);
  const [discoveryMetricsTimeSeries, setDiscoveryMetricsTimeSeries] = useState<
    DiscoveryTimeSeriesItem[]
  >([]);
  const [recurringMetrics, setRecurringMetrics] = useState<RecurringTimeSeriesItem[]>([]);

  useEffect(() => {
    const abortController = new AbortController();
    setLoading(true);
    getCoreMetrics()
      .then((response) => {
        const {
          success,
          contractMetrics,
          cohortGmvMetrics,
          cohortNames,
          averageContractValueMetrics,
          funnelVolumeMetrics,
          discoveryMetrics,
          recurringContractsMetrics,
        } = response;

        if (success) {
          setContractTimeSeries(JSON.parse(contractMetrics));
          setCohortGmvTimeSeries(JSON.parse(cohortGmvMetrics));
          setCohortLabels(cohortNames);
          setAverageContractValueTimeSeries(JSON.parse(averageContractValueMetrics));
          setFunnelVolumeTimeSeries(JSON.parse(funnelVolumeMetrics));
          setDiscoveryMetricsTimeSeries(JSON.parse(discoveryMetrics));
          setRecurringMetrics(JSON.parse(recurringContractsMetrics));
        }
      })
      .finally(() => setLoading(false));

    return () => {
      abortController.abort();
    };
  }, []);

  const cohortSeries = cohortLabels.map((cohortName) => ({
    name: cohortName,
    label: `${cohortName} Cohort`,
    color: getDeterministicColor({ str: cohortName }),
  }));

  return (
    <Paper radius="sm" p="xl">
      <Container>
        <LoadingOverlay visible={loading} />
        <Title order={2} fw="500" py="sm" px="xl">
          Sourcing Funnel Volume
        </Title>
        <LineChart
          h={300}
          data={funnelVolumeTimeSeries}
          dataKey="date"
          valueFormatter={(value) => value.toFixed(2)}
          withLegend
          series={[
            {
              name: "num_ops_accepted_14_day_avg",
              label: "Ops Approved (14 Day Avg)",
              color: "red",
            },
            {
              name: "num_internal_accepted_14_day_avg",
              label: "Internal Approved (14 Day Avg)",
              color: "yellow",
            },
            {
              name: "num_brand_accepted_14_day_avg",
              label: "Brand Approved (14 Day Avg)",
              color: "blue",
            },
            {
              name: "num_email_subscribed_14_day_avg",
              label: "Emails Sent (14 Day Avg)",
              color: "green",
            },
          ]}
          curveType="natural"
          withDots={false}
          lineChartProps={{ syncId: "sourcing-charts" }}
        />
        <Title order={2} fw="500" py="sm" px="xl">
          Internal vs. Brand Approval Rate
        </Title>
        <LineChart
          h={300}
          data={funnelVolumeTimeSeries}
          dataKey="date"
          valueFormatter={(value) => `${(value * 100).toFixed(2)}%`}
          withLegend
          series={[
            {
              name: "brand_accept_rate_14_day_window",
              label: "Brand Approval Rate (14 Day)",
              color: "cyan",
            },
            {
              name: "internal_accept_rate_14_day_window",
              label: "Internal Approval Rate (14 Day)",
              color: "grape",
            },
          ]}
          curveType="natural"
          withDots={false}
          lineChartProps={{ syncId: "sourcing-charts" }}
        />
        <Space my="sm" />
        <Title order={2} fw="500" py="sm" px="xl">
          Self-Serve Discovery
        </Title>
        <LineChart
          h={300}
          data={discoveryMetricsTimeSeries}
          dataKey="date"
          valueFormatter={(value) => value.toFixed(2)}
          withLegend
          series={[
            {
              name: "num_queries_14_day_avg",
              label: "Queries (14 Day Avg)",
              color: "red",
            },
            {
              name: "num_page_views_14_day_avg",
              label: "Page Views (14 Day Avg)",
              color: "yellow",
            },
            {
              name: "num_activations_14_day_avg",
              label: "Activations (14 Day Avg)",
              color: "blue",
            },
            {
              name: "num_saves_14_day_avg",
              label: "Saves (14 Day Avg)",
              color: "green",
            },
          ]}
          curveType="natural"
          withDots={false}
        />
        <Space my="sm" />
        <Title order={2} fw="500" py="sm" px="xl">
          Signed vs. Completed Contracts
        </Title>
        <LineChart
          h={300}
          data={contractTimeSeries}
          dataKey="date"
          valueFormatter={(value) => value.toFixed(2)}
          withLegend
          series={[
            { name: "created_contracts_14_day_avg", label: "Created (14 Day Avg)", color: "red" },
            { name: "signed_contracts_14_day_avg", label: "Signed (14 Day Avg)", color: "blue" },
            {
              name: "completed_contracts_14_day_avg",
              label: "Completed (14 Day Avg)",
              color: "green",
            },
          ]}
          curveType="natural"
          withDots={false}
          lineChartProps={{ syncId: "contract-charts" }}
        />
        <Space my="sm" />
        <Title order={2} fw="500" py="sm" px="xl">
          Average Contract Value
        </Title>
        <LineChart
          h={300}
          data={averageContractValueTimeSeries}
          dataKey="date"
          valueFormatter={(value) =>
            (value / 100).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })
          }
          withLegend
          series={[
            {
              name: "contract_value_14_day_avg",
              label: "Average Contract Value (14 Day Avg)",
              color: "cyan",
            },
          ]}
          curveType="natural"
          withDots={false}
          lineChartProps={{ syncId: "contract-charts" }}
        />
        <Space my="sm" />
        <Title order={2} fw="500" py="sm" px="xl">
          Signed vs. Completed Contracts (GMV)
        </Title>
        <LineChart
          h={300}
          data={contractTimeSeries}
          dataKey="date"
          valueFormatter={(value) =>
            (value / 100).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })
          }
          withLegend
          series={[
            {
              name: "created_contracts_gmv_14_day_avg",
              label: "Created GMV (14 Day Avg)",
              color: "red",
            },
            {
              name: "signed_contracts_gmv_14_day_avg",
              label: "Signed GMV (14 Day Avg)",
              color: "blue",
            },
            {
              name: "completed_contracts_gmv_14_day_avg",
              label: "Completed GMV (14 Day Avg)",
              color: "green",
            },
          ]}
          curveType="natural"
          withDots={false}
          lineChartProps={{ syncId: "contract-charts" }}
        />
        <Space my="sm" />
        <Title order={2} fw="500" py="sm" px="xl">
          Booked Recurring GMV and Revenue
        </Title>
        <AreaChart
          h={300}
          data={recurringMetrics}
          dataKey="date"
          valueFormatter={(value) => {
            const amountInDollars = value / 100;
            const formatter = new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 2,
            });
            return formatter.format(amountInDollars);
          }}
          withLegend
          series={[
            {
              name: "gmv",
              label: "Recurring GMV",
              color: "blue",
            },
            {
              name: "revenue",
              label: "Recurring Revenue",
              color: "teal",
            },
          ]}
        />
        <Space my="sm" />
        <Title order={2} fw="500" py="sm" px="xl">
          Monthly GMV by Cohort
        </Title>
        <LineChart
          h={300}
          data={cohortGmvTimeSeries}
          dataKey="date"
          valueFormatter={(value) => {
            const amountInDollars = value / 100;
            const formatter = new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 2,
            });
            return formatter.format(amountInDollars);
          }}
          withLegend
          series={cohortSeries}
          curveType="natural"
        />
        {/* <Space my="sm" />
        <Title order={2} fw="500" py="sm" px="xl">
          Signed Contracts by Outreach Date
          <Text size="sm" c="dimmed">
            Only started tracking outreach date on 1/24/2024.
          </Text>
          <Text size="sm" c="dimmed">
            Smoothing window set to {CURRENT_SMOOTHING_WINDOW} days.
          </Text>
        </Title>
        <LineChart
          h={300}
          data={contractSignedTimeSeries}
          dataKey="date"
          valueFormatter={(value) => formatPercentage(value)}
          withLegend
          series={[
            { name: "value", label: "Contract Signed Rate by Outreach Date", color: "green" },
          ]}
          curveType="monotone"
          lineChartProps={{ syncId: "contract-charts" }}
        /> */}
      </Container>
    </Paper>
  );
}
