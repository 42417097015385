import React from "react";

import { Stack } from "@mantine/core";

import { MessageParty } from "components/contracts/models/Message";
import Script from "components/contracts/models/Script";
import PreviousScriptCard from "components/contracts/common/PreviousScriptCard";

export default function ViewPreviousScripts({
  scripts,
  messageParty,
}: {
  scripts: Script[];
  messageParty: MessageParty;
}) {
  const previousScriptCards = scripts.map((script, index) => (
    <PreviousScriptCard
      key={script.scriptId}
      script={script}
      messageParty={messageParty}
      versionNumber={scripts.length - index}
    />
  ));

  return <Stack>{previousScriptCards}</Stack>;
}
