import React, { useEffect, useState } from "react";

import { useSearchParams } from "react-router-dom";

import {
  Button,
  Center,
  Container,
  Flex,
  Grid,
  Group,
  Loader,
  Stack,
  TextInput,
  Title,
} from "@mantine/core";
import { useDebouncedValue, useInputState } from "@mantine/hooks";

import { TikTokVideoDebugCard } from "admin/app/ops/labeling/debug/TikTokVideoDebugCard";
import {
  GET_ALL_HASHTAG_VIDEOS_URL,
  GET_HASHTAG_VIDEOS_URL,
  GET_LOCKET_HASHTAG_VIDEOS_URL,
  getHashtagVideosFromEndpoint,
  getRecommendedHashtags,
} from "admin/api/feedApi";

function RecommendedHashtags({
  hashtags,
  setHashtag,
  setLoading,
}: {
  hashtags: any[];
  setHashtag: (hashtag: string) => void;
  setLoading: (value: boolean) => void;
}) {
  return (
    <Flex gap="xs">
      {hashtags.map((hashtag) => (
        <Button
          key={hashtag.related_hashtag}
          variant="light"
          size="compact-xs"
          onClick={() => {
            setLoading(true);
            setHashtag(hashtag.related_hashtag);
          }}>
          #{hashtag.related_hashtag}
        </Button>
      ))}
    </Flex>
  );
}

export const HashtagVideoFeed = ({ endpoint = "sponsored" }: { endpoint?: string }) => {
  let url = GET_HASHTAG_VIDEOS_URL;
  if (endpoint === "locket") {
    url = GET_LOCKET_HASHTAG_VIDEOS_URL;
  } else if (endpoint === "all") {
    url = GET_ALL_HASHTAG_VIDEOS_URL;
  }
  const [searchParams, setSearchParams] = useSearchParams();
  const [hashtag, setHashtag] = useInputState(null);
  const [limit, setLimit] = useInputState(25);
  const [debouncedHashtag] = useDebouncedValue(hashtag, 500);
  const [debouncedLimit] = useDebouncedValue(limit, 500);
  const [videoIds, setVideoIds] = useState([]);
  const [broadHashtags, setBroadHashtags] = useState([]);
  const [narrowHashtags, setNarrowHashtags] = useState([]);
  const [loading, setLoading] = useState(false);

  const [cards, setCards] = useState(null);

  // sync hashtag with searchparams
  useEffect(() => {
    // parse hashtag from search params
    const parsedHashtag = searchParams.get("hashtag");
    if (parsedHashtag) {
      if (parsedHashtag !== hashtag) {
        setHashtag(parsedHashtag);
      }
    } else {
      setHashtag("");
    }

    // parse limit from search params
    const parsedLimit = searchParams.get("limit");
    if (parsedLimit) {
      const intLimit = Number(parsedLimit);
      if (intLimit !== limit) {
        setLimit(intLimit);
      }
    } else {
      setLimit(25);
    }
  }, [searchParams]);

  // Sync search params with hashtag
  useEffect(() => {
    // update search params based on query changes
    const newSearchParams = new URLSearchParams(searchParams);
    let changed = false;
    if (hashtag) {
      if (hashtag !== searchParams.get("hashtag")) {
        newSearchParams.set("hashtag", hashtag);
        changed = true;
      }
    } else if (hashtag === "") {
      newSearchParams.delete("hashtag");
      changed = true;
    }

    if (limit) {
      if (
        limit.toString() !== searchParams.get("limit") &&
        !(limit === 25 && !searchParams.get("limit"))
      ) {
        newSearchParams.set("limit", limit.toString());
        changed = true;
      }
    } else {
      newSearchParams.delete("limit");
      changed = true;
    }
    if (changed) {
      setSearchParams(newSearchParams);
    }
  }, [hashtag, limit]);

  useEffect(() => {
    const abortController = new AbortController();
    if (debouncedHashtag) {
      setLoading(true);
      // get the hashtag videos
      getHashtagVideosFromEndpoint(
        debouncedHashtag,
        url,
        debouncedLimit,
        setVideoIds,
        abortController,
      ).finally(() => setLoading(false));
      if (endpoint === "all") {
        getRecommendedHashtags(debouncedHashtag, abortController).then((response) => {
          const { results } = response;
          const broadHashtagResults = results
            .filter((res: any) => res.related_occurrences > res.source_occurrences)
            .slice(0, 10);
          const narrowHashtagResults = results
            .filter((res: any) => res.related_occurrences <= res.source_occurrences)
            .slice(0, 10);
          setBroadHashtags(broadHashtagResults);
          setNarrowHashtags(narrowHashtagResults);
        });
      }
    } else {
      setVideoIds([]);
      if (endpoint === "all") {
        setBroadHashtags([]);
        setNarrowHashtags([]);
      }
    }
    return () => {
      abortController.abort();
    };
  }, [debouncedHashtag, debouncedLimit]);

  useEffect(() => {
    if (videoIds) {
      setCards(
        videoIds.map((id) => (
          <Grid.Col key={id.trim()} span="content">
            <TikTokVideoDebugCard tikTokVideoId={id.trim()} />
          </Grid.Col>
        )),
      );
    }
  }, [videoIds]);

  return (
    <Stack gap="md">
      <Group justify="center">
        <TextInput w={300} description="Hashtag" value={hashtag} onChange={setHashtag} />{" "}
        <TextInput w={300} description="Limit" value={limit} onChange={setLimit} />
      </Group>
      {loading && (
        <Center>
          <Loader />
        </Center>
      )}
      {!loading && (
        <>
          {broadHashtags.length > 0 && (
            <>
              <Title order={5}>Broader Hashtags</Title>
              <RecommendedHashtags
                hashtags={broadHashtags}
                setHashtag={setHashtag}
                setLoading={setLoading}
              />
            </>
          )}
          {narrowHashtags.length > 0 && (
            <>
              <Title order={5}>Narrower Hashtags</Title>
              <RecommendedHashtags
                hashtags={narrowHashtags}
                setHashtag={setHashtag}
                setLoading={setLoading}
              />
            </>
          )}
          <Container fluid my="md">
            <Grid gutter="sm">{cards}</Grid>
          </Container>
        </>
      )}
    </Stack>
  );
};

export default HashtagVideoFeed;
