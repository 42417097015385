import { ActionIcon, Badge } from "@mantine/core";
import { Icon, IconProps, IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import BasicNavButton from "campaigns/navbar/BasicNavButton";
import React, { useEffect } from "react";

export enum SecondaryNavBarMainButtonName {
  RESULTS,
  CREATOR_PROGRESS,
  LIVE,
  CREATORS,
  CONTRACT_APPROVAL,
  CONTRACTS,
  CONTRACTS_PROMO_CODES_AND_LINKS,
  CONTRACT_PRODUCT_ACCESS,
  CONTRACTS_REVIEW_CONTENT,
  CONTRACTS_LIVE_VERIFICATION,
  MANAGE_CREATORSETS,
  REVIEW_CREATORSETS,
  EXISTING_PARTNERS,
  ALL_CREATORS,
  VIEW_CREATORS,
  REVIEW_CREATORS,
  EDIT_CAMPAIGN,
}

const SecondaryNavBarButton = ({
  IconName,
  isSelected,
  onButtonClick,
  text,
  numTodos,
  hasChildren,
  toggleShowChildren,
  showChildren,
  isChild,
}: {
  IconName: React.ForwardRefExoticComponent<Omit<IconProps, "ref"> & React.RefAttributes<Icon>>;
  isSelected: boolean;
  onButtonClick: () => void;
  text: string;
  numTodos: number;
  hasChildren?: boolean;
  toggleShowChildren?: (name: string, show: boolean) => void;
  showChildren?: boolean;
  isChild?: boolean;
}) => {
  const ChevronIconName: React.ForwardRefExoticComponent<
    Omit<IconProps, "ref"> & React.RefAttributes<Icon>
  > = showChildren ? IconChevronUp : IconChevronDown;
  function getLeftIcon() {
    if (isChild) {
      return <ActionIcon component="div" variant="transparent" size={26} />;
    }
    if (hasChildren) {
      return (
        <ActionIcon
          variant="transparent"
          component="div"
          size={26}
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            event.stopPropagation(); // allow toggle of showing ad groups to be separate from selecting campaign button
            toggleShowChildren(text, !showChildren);
          }}>
          <ChevronIconName height={14} width={14} color="var(--mantine-color-black)" stroke={3} />
        </ActionIcon>
      );
    }

    return (
      <ActionIcon component="div" variant="filled" color="var(--mantine-color-blue-0" size={26}>
        <IconName height={14} width={14} color="var(--mantine-color-blue-6)" />
      </ActionIcon>
    );
  }
  function getRootStyle() {
    if (isChild) {
      return {
        color: isSelected ? "black" : "white",
        backgroundColor: isSelected ? "white" : undefined,

        backgroundImage:
          "linear-gradient(to right, var(--mantine-color-blue-6) 62.5%, white 62.5% 67.5%, var(--mantine-color-blue-6) 67.5%)",
        backgroundSize: "40px 105%", // button seems to be a bit bigger than root where we are adding these props
        backgroundRepeat: "no-repeat",
        backgroundPosition: "-1px", // again button seems a bit larger than root so moving this over -1px so we don't see additional background color from button when selected
        borderRadius: 0,
      };
    }
    if (hasChildren) {
      return {
        color: isSelected ? "black" : "white",
        backgroundColor: isSelected ? "white" : undefined,
      };
    }

    return {
      color: isSelected ? "black" : "white",
      backgroundColor: isSelected ? "white" : undefined,
      borderStartStartRadius: "var(--mantine-radius-default)",
      borderStartEndRadius: 0,
      borderEndStartRadius: "var(--mantine-radius-default)",
      borderEndEndRadius: 0,
    };
  }
  useEffect(() => {
    if (hasChildren) {
      toggleShowChildren(text, true);
    }
  }, []);

  return (
    <BasicNavButton
      isSelected={isSelected}
      text={text}
      onButtonClick={onButtonClick}
      leftSection={getLeftIcon()}
      rootStyles={getRootStyle()}
      rightSection={
        numTodos ? (
          <Badge size="sm" color="red" style={{ marginRight: "10px" }}>
            {numTodos}
          </Badge>
        ) : null
      }
    />
  );
};

export default SecondaryNavBarButton;
