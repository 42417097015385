// Helper methods for making API calls
import { getAuth } from "firebase/auth";

export interface ErrorInfo {
  status: string; // response code
  text: string; // text for response code
  result: any; // error message
}

export const handleResult = async (
  request: Request,
  abortController: AbortController | null = null,
) => {
  let response = null;
  try {
    response = await fetch(request, { signal: abortController?.signal });
  } catch (error) {
    if (
      (abortController != null && abortController.signal.aborted) ||
      error.name === "AbortError"
    ) {
      // if the request was aborted, we don't want to show an error
      return null;
    }
    throw error;
  }

  if (response != null && response.ok) {
    if (response.status === 204) {
      return response;
    }
    try {
      const responseJson = await response.json();
      return responseJson;
    } catch {
      // default to response if response.json errors out
      return response;
    }
  }
  let resultText = "";
  try {
    // clone is used so we can read the stream with a different types
    // incase the response isn't valid json.
    resultText = await response.clone().json();
    // try to parse the result as json
    const resultJson = JSON.parse(resultText);
    resultText = resultJson;
    return resultText;
  } catch (error) {
    const resp = {
      status: response.status.toString(),
      text: response.statusText.toString(),
      result: resultText,
      ok: false,
    };
    return resp;
  }
  throw new Error(
    JSON.stringify({
      status: response.status.toString(),
      text: response.statusText.toString(),
      result: resultText,
    }),
  );
};

export const createRequest = ({
  url,
  method = "GET",
  firebaseToken,
  body = null,
}: {
  url: string | URL;
  method?: "DELETE" | "GET" | "PATCH" | "POST" | "PUT";
  firebaseToken?: string;
  body?: string | null;
}) => {
  const headers: HeadersInit = { "Content-Type": "application/json" };
  if (firebaseToken) {
    headers.Authorization = `Bearer ${firebaseToken}`;
  }

  return new Request(url, {
    method,
    headers,
    ...(body !== null && { body }),
  });
};

export const createRequestWithFirebaseToken = async ({
  url,
  method = "GET",
  body = null,
  skipRequestIfNoFirebaseToken = false,
}: {
  url: string | URL;
  method?: "DELETE" | "GET" | "PATCH" | "POST" | "PUT";
  body?: string | null;
  skipRequestIfNoFirebaseToken?: boolean;
}) => {
  const firebaseToken = await getAuth().currentUser?.getIdToken();
  if (!firebaseToken && skipRequestIfNoFirebaseToken) {
    return null;
  }
  return createRequest({ url, method, firebaseToken, body });
};

export const createRequestBlob = ({
  url,
  method = "GET",
  firebaseToken,
}: {
  url: string | URL;
  method?: "GET" | "POST" | "PUT";
  firebaseToken?: string;
}) => {
  const headers: HeadersInit = {};
  if (firebaseToken) {
    headers.Authorization = `Bearer ${firebaseToken}`;
  }

  return new Request(url, {
    method,
    headers,
  });
};

export const createRequestBlobWithFirebaseToken = async ({
  url,
  method = "GET",
}: {
  url: string | URL;
  method?: "GET" | "POST" | "PUT";
}) => {
  const firebaseToken = await getAuth().currentUser?.getIdToken();
  return createRequestBlob({ url, method, firebaseToken });
};

export const downloadFileFromBlob = ({ blob, filename }: { blob: Blob; filename: string }) => {
  const downloadUrl = window.URL.createObjectURL(blob);
  const anchorElement = document.createElement("a");
  anchorElement.href = downloadUrl;
  anchorElement.download = filename;
  document.body.appendChild(anchorElement);
  anchorElement.click();
  anchorElement.remove();
  window.URL.revokeObjectURL(downloadUrl);
};

export default null;
