import React, { useEffect, useState } from "react";

import { useSearchParams } from "react-router-dom";

import { Flex, Group, Stack } from "@mantine/core";

import { useAdminAppContext } from "admin/app/AdminAppShell";
import { AdminCreatorSetImportTools } from "admin/app/creator_sets/AdminCreatorSetImportTools";
import { fetchAllCreatorSets, fetchCreatorSetById } from "campaigns/api/fetchCreatorSets";
import CreatorSetSelector from "campaigns/main/campaignPage/creator_sets/CreatorSetSelector";
import { CreatorSetSettings } from "components/creator_sets/CreatorSetSettings";
import { CreatorSet } from "components/discovery/Datamodels";

export const AdminCreatorSetSettings = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { user, userProfile, creatorSets } = useAdminAppContext();

  const [creatorSetId, setCreatorSetId] = useState<number | null>(null);
  const [creatorSet, setCreatorSet] = useState<CreatorSet | null>(null);

  useEffect(() => {
    // Whenever the selectedCreatorSetId changes, we should update the creatorSet
    const abortController = new AbortController();
    if (creatorSetId != null && creatorSet?.id !== creatorSetId) {
      fetchCreatorSetById(creatorSetId, abortController).then((fetchedCreatorSet) => {
        setCreatorSet(fetchedCreatorSet);
      });
    }
    // if the creatorSetId has been cleared, we should clear the creatorSet
    if (creatorSetId == null && creatorSet != null) {
      setCreatorSet(null);
    }
    return () => {
      abortController.abort();
    };
  }, [creatorSetId]);

  return (
    <Stack gap={16}>
      <header
        style={{
          height: "rem(56px)",
          marginBottom: "rem(120px)",
          // backgroundColor: "var(--mantine-color-body)",
          borderBottom:
            "rem(1px) solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4))",
          paddingLeft: "var(--mantine-spacing-md)",
          paddingRight: "var(--mantine-spacing-md)",
        }}>
        <Flex justify="flex-end" align="center" h="rem(56px)">
          <Group>
            <CreatorSetSelector
              creatorSets={creatorSets}
              selectedCreatorSetId={creatorSetId}
              setSelectedCreatorSetId={setCreatorSetId}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
          </Group>
        </Flex>
      </header>
      {creatorSet != null && <CreatorSetSettings creatorSet={creatorSet} />}
      {creatorSet !== null && <AdminCreatorSetImportTools creatorSet={creatorSet} />}
    </Stack>
  );
};

export default AdminCreatorSetSettings;
