import React from "react";
import { Tooltip } from "@mantine/core";
import { IconCircleCheck, IconCircleX } from "@tabler/icons-react";

export default function UsageRightsIndicator({
  usageRightsEndDate,
  size,
}: {
  usageRightsEndDate: Date;
  size?: string | number;
}) {
  if (usageRightsEndDate) {
    if (usageRightsEndDate < new Date()) {
      return (
        <Tooltip label={`Usage Rights Expired on ${usageRightsEndDate.toLocaleDateString()}`}>
          <IconCircleX size={size || "1rem"} color="red" stroke={2} />
        </Tooltip>
      );
    }
    return (
      <Tooltip label={`Usage Rights until ${usageRightsEndDate.toLocaleDateString()}`}>
        <IconCircleCheck size={size || "1rem"} color="green" stroke={2} />
      </Tooltip>
    );
  }
  return (
    <Tooltip label="No Usage Rights">
      <IconCircleX size={size || "1rem"} color="red" stroke={2} />
    </Tooltip>
  );
}
