import React, { useState } from "react";
import { Box, Text, Icon, TapArea } from "gestalt";
import "gestalt/dist/gestalt.css";

export const truncateText = (text: string, maxLength: number) => {
  if (text.length <= maxLength) return text;
  return `${text.slice(0, maxLength - "...".length)}...`;
};

export const ExpandingBox = ({
  text,
  width = 600,
  truncateLength = 125,
}: {
  text: string;
  width?: any;
  truncateLength?: number;
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [mouseOver, setMouseOver] = useState(false);

  if (!text) return null;

  return (
    <TapArea
      onMouseEnter={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
      onTap={() => setIsExpanded(true)}>
      <Box
        display="flex"
        direction="row"
        width={width}
        justifyContent="between"
        color={mouseOver ? "lightWash" : "transparent"}>
        {isExpanded ? (
          <Text size="200">{text}</Text>
        ) : (
          <Text size="200">{truncateText(text, truncateLength)}</Text>
        )}
        <Icon accessibilityLabel="Expand" icon="arrow-down" />
      </Box>
    </TapArea>
  );
};

export default null;
