import React, { useEffect } from "react";

import { Stack, Text } from "@mantine/core";
import { RichTextEditor, Link } from "@mantine/tiptap";
import { useEditor } from "@tiptap/react";
import Highlight from "@tiptap/extension-highlight";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import Superscript from "@tiptap/extension-superscript";
import SubScript from "@tiptap/extension-subscript";

function safeParseJson(jsonStr: string) {
  let json = {};
  try {
    json = JSON.parse(jsonStr);
  } catch (e) {
    return jsonStr;
  }
  return json;
}

function getRawTextFromJsonNode(node: any) {
  if (!node) {
    return "";
  }
  if (node?.text) {
    return node.text;
  }
  const content = node?.content;
  if (content) {
    return content.map((childNode: any) => getRawTextFromJsonNode(childNode)).join(" ");
  }
  return "";
}

export function getRawTextFromJsonStr(jsonStr: string) {
  if (!jsonStr) {
    return "";
  }
  let json = {};
  try {
    json = JSON.parse(jsonStr);
  } catch (e) {
    return jsonStr;
  }
  const text = getRawTextFromJsonNode(json);
  return text;
}

export const BaseRichTextEditor = ({
  value,
  editorKey,
  onChange,
  label,
  ...props
}: {
  value: string;
  editorKey: string;
  onChange: (value?: string) => void;
  label?: string;
  [x: string]: any;
}) => {
  const editor = useEditor(
    {
      extensions: [
        StarterKit as any,
        Underline,
        Link,
        Superscript,
        SubScript,
        Highlight,
        TextAlign.configure({ types: ["heading", "paragraph"] }),
      ],
      onUpdate() {
        onChange(JSON.stringify(editor.getJSON()));
      },
      content: safeParseJson(value),
    },
    [editorKey],
  );

  return (
    <Stack gap={0}>
      {label && (
        <Text size="sm" fw={500}>
          {label}
        </Text>
      )}
      <RichTextEditor editor={editor}>
        <RichTextEditor.Toolbar sticky stickyOffset={60}>
          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Bold />
            <RichTextEditor.Italic />
            <RichTextEditor.Underline />
            <RichTextEditor.Strikethrough />
            <RichTextEditor.ClearFormatting />
            <RichTextEditor.Highlight />
            <RichTextEditor.Code />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.H1 />
            <RichTextEditor.H2 />
            <RichTextEditor.H3 />
            <RichTextEditor.H4 />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Blockquote />
            <RichTextEditor.Hr />
            <RichTextEditor.BulletList />
            <RichTextEditor.OrderedList />
            <RichTextEditor.Subscript />
            <RichTextEditor.Superscript />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Link />
            <RichTextEditor.Unlink />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.AlignLeft />
            <RichTextEditor.AlignCenter />
            <RichTextEditor.AlignJustify />
            <RichTextEditor.AlignRight />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Undo />
            <RichTextEditor.Redo />
          </RichTextEditor.ControlsGroup>
        </RichTextEditor.Toolbar>

        <RichTextEditor.Content />
      </RichTextEditor>
    </Stack>
  );
};

export default null;
