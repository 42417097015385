import React, { useState } from "react";
import { User } from "firebase/auth";

import { ActionIcon, Tooltip } from "@mantine/core";

import { IconExternalLink } from "@tabler/icons-react";

import { ContentStatus } from "components/contracts/common/Common";

import { SupportedFormat, SupportedPlatform } from "models/Common";

import RevertReviewStatus from "components/contracts/review/RevertReviewStatus";
import {
  ContractDeliverableStatus,
  ContractDeliverableStatusMapping,
} from "components/contracts/models/Deliverable";
import EditReferralLinkAndPromoCode from "components/contracts/review/EditReferralLinkAndPromoCode";

export default function ContentReviewCardControls({
  deliverableId,
  platform,
  format,
  creatorHandle,
  deliverableStatus,
  reviewStatus,
  liveContentUrl,
  requiresReferralLink,
  requiresPromoCode,
  isMissingReferralLink,
  isMissingPromoCode,
  setIsMissingReferralLink,
  setIsMissingPromoCode,
  referralLink,
  promoCode,
  updateCardState,
  scriptId,
  videoId,
  showAdminOptions,
}: {
  deliverableId: string;
  platform: SupportedPlatform;
  format: SupportedFormat;
  creatorHandle: string;
  deliverableStatus: ContractDeliverableStatus;
  reviewStatus: ContentStatus;
  liveContentUrl: string;
  requiresReferralLink: boolean;
  requiresPromoCode: boolean;
  isMissingReferralLink: boolean;
  isMissingPromoCode: boolean;
  setIsMissingReferralLink: (value: boolean) => void;
  setIsMissingPromoCode: (value: boolean) => void;
  referralLink: string;
  promoCode: string;
  updateCardState: (reviewStatus: ContentStatus, reviewDate: Date) => void;
  scriptId?: string;
  videoId?: string;
  showAdminOptions?: boolean;
}) {
  const isLive =
    ContractDeliverableStatusMapping[deliverableStatus] >=
    ContractDeliverableStatusMapping[ContractDeliverableStatus.LIVE_CONTENT_APPROVED];
  const liveSubmitted =
    ContractDeliverableStatusMapping[deliverableStatus] >=
    ContractDeliverableStatusMapping[ContractDeliverableStatus.LIVE_CONTENT_SUBMITTED];
  const approvedToGoLive =
    ContractDeliverableStatusMapping[deliverableStatus] >=
    ContractDeliverableStatusMapping[ContractDeliverableStatus.VIDEO_DRAFT_APPROVED];

  return (
    <ActionIcon.Group>
      <EditReferralLinkAndPromoCode
        deliverableId={deliverableId}
        platform={platform}
        format={format}
        creatorHandle={creatorHandle}
        requiresReferralLink={requiresReferralLink}
        requiresPromoCode={requiresPromoCode}
        isMissingReferralLink={isMissingReferralLink}
        setIsMissingReferralLink={setIsMissingReferralLink}
        isMissingPromoCode={isMissingPromoCode}
        setIsMissingPromoCode={setIsMissingPromoCode}
        referralLink={referralLink}
        promoCode={promoCode}
        disableEditing={approvedToGoLive}
      />
      {isLive && liveContentUrl && (
        <Tooltip label="View Live Content">
          <ActionIcon
            component="a"
            href={liveContentUrl}
            target="_blank"
            variant="subtle"
            color="gray">
            <IconExternalLink color="gray" size="1rem" />
          </ActionIcon>
        </Tooltip>
      )}
      {!liveSubmitted && (
        <RevertReviewStatus
          showAdminOptions={showAdminOptions}
          contentReviewStatus={reviewStatus}
          updateCardState={updateCardState}
          deliverableId={deliverableId}
          scriptId={scriptId}
          videoId={videoId}
        />
      )}
    </ActionIcon.Group>
  );
}
