import brandImage from "pages/home/assets/images/brandImage.svg";
import creatorHint from "pages/home/assets/images/creatorHint.svg";
import creatorHintMobile from "pages/home/assets/images/creatorHintMobile.svg";
import creatorImage from "pages/home/assets/images/creatorImage.svg";
import favicon from "pages/home/assets/images/favicon.svg";

/* eslint-disable @typescript-eslint/no-var-requires */
const heroImage = require("./heroImage.png");
const heroImageMobile = require("./heroImageMobile.png");
const humphrey = require("./humphrey.png");
const lexie = require("./lexie.png");
const mediaKitHero = require("./mediaKitHero.png");
const linkImage = require("./linkImage.png");
const realTimeMetrics = require("./realTimeMetrics.png");
const heroSunglasses = require("./heroSunglasses.png");
const plant = require("./plant.png");
const astronaut = require("./astronaut.png");
const phoneDiscover = require("./phoneDiscover.png");
/* eslint-enable @typescript-eslint/no-var-requires */

const IMAGES = {
  brandImage,
  creatorHint,
  creatorHintMobile,
  creatorImage,
  favicon,
  heroImage,
  heroImageMobile,
  humphrey,
  lexie,
  mediaKitHero,
  linkImage,
  realTimeMetrics,
  heroSunglasses,
  plant,
  astronaut,
  phoneDiscover,
};

export default IMAGES;
