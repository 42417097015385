import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Checkbox,
  ComboboxItem,
  Group,
  Select,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core";
import { IconInfoCircle, IconReceipt2 } from "@tabler/icons-react";

import HistoricalContracts from "components/creator/HistoricalContracts";
import { InternalCardTitle } from "components/creator/InternalCardTitle";
import SuggestedPrice from "components/creator/SuggestedPrice";
import OfferCreation from "components/creator/OfferCreation";
import { CreatorV2Info } from "models/CreatorV2Info";
import { Opportunity } from "models/Opportunity";
import { ActivePlatformInfo } from "components/creator/CreatorUtils";
import { memoizedFetchPricing, PriceMap } from "creators/api/creatorApiUtils";
import { SupportedFormat } from "models/Common";
import { Contract } from "components/contracts/dashboard/Utils";
import Deliverable from "components/contracts/models/Deliverable";
import CreatorOpportunityDebugView from "components/creator/CreatorOpportunityDebugView";
import { fetchContractReviewData } from "components/contracts/common/Api";
import { ContractBrandReview } from "components/contracts/brand_review/types";
import { Campaign } from "models/Campaign";

interface SumViews {
  [key: string]: Record<string, number>;
}

const DEFAULT_CPM = 100;

const PricingCalculator = ({
  activePlatforms,
  creatorInfo,
  opportunities,
  activeOpportunities,
  campaigns,
  activeCampaigns,
  setOpportunities,
  completedContracts,
}: {
  activePlatforms: ActivePlatformInfo;
  creatorInfo: CreatorV2Info;
  opportunities: Opportunity[];
  activeOpportunities: Opportunity[];
  campaigns: Campaign[];
  activeCampaigns: ComboboxItem[];
  setOpportunities: (opps: Opportunity[]) => void;
  completedContracts: Contract[];
}) => {
  // check if we have duplicate active campaigns
  const campaignArr = activeCampaigns.map((campaign) => campaign.value);
  const hasDuplicateCampaign = new Set(campaignArr).size !== campaignArr.length;
  // opportunity fields to pass to OfferCreation
  const senderFirstName = opportunities.filter((opp) => opp.active_state === 2)[0]
    ?.sender_first_name;

  const activePlatformsList = Object.keys(activePlatforms).filter(
    (key: keyof ActivePlatformInfo) => activePlatforms[key] !== null,
  );

  const firstActiveAdGroupId =
    activeOpportunities.length > 0 ? activeOpportunities[0]?.ad_group?.id.toString() : "";
  const firstActiveBrandName =
    activeOpportunities.length > 0 ? activeOpportunities[0]?.brand_name : "";
  const firstActiveCampaignName =
    activeOpportunities.length > 0 ? activeOpportunities[0]?.ad_group.campaign_name : null;
  const firstActiveCampaignId =
    activeOpportunities.length > 0 ? activeOpportunities[0]?.ad_group.campaign_id : null;
  const firstActiveCampaign =
    campaigns && campaigns.filter((campaign) => campaign.id === firstActiveCampaignId)[0];
  const firstActiveCPM =
    activeOpportunities.length > 0 ? activeOpportunities[0]?.ad_group.target_cpm : DEFAULT_CPM;

  const [buttonLoading, setButtonLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const [adGroupId, setAdGroupId] = useState<string | null>(firstActiveAdGroupId);
  const [campaignId, setCampaignId] = useState<number | null>(firstActiveCampaignId);
  const [brandName, setBrandName] = useState<string | null>(firstActiveBrandName);
  const [value, setValue] = useState<ComboboxItem | null>({
    value: firstActiveCampaignId ? firstActiveCampaignId.toString() : null,
    label: firstActiveCampaignName,
  });
  const [selectedCampaign, setSelectedCampaign] = useState<Campaign | null>(firstActiveCampaign);

  const [contractReview, setContractReview] = useState<ContractBrandReview[]>([]);

  const [tiktokChecked, setTiktokChecked] = useState(false);
  const [youtubeLongChecked, setYoutubeLongChecked] = useState(false);
  const [youtube30IntegrationChecked, setYoutube30IntegrationChecked] = useState(false);
  const [youtube60IntegrationChecked, setYoutube60IntegrationChecked] = useState(false);
  const [youtube90IntegrationChecked, setYoutube90IntegrationChecked] = useState(false);
  const [youtubeShortsChecked, setYoutubeShortsChecked] = useState(false);
  const [igReelChecked, setIgReelChecked] = useState(false);
  const [checkboxStatus, setCheckboxStatus] = useState<Record<string, boolean>>({
    [SupportedFormat.TIKTOK_DEDICATED_VIDEO]: tiktokChecked,
    [SupportedFormat.YOUTUBE_DEDICATED_VIDEO]: youtubeLongChecked,
    [SupportedFormat.YOUTUBE_30S_INTEGRATED_VIDEO]: youtube30IntegrationChecked,
    [SupportedFormat.YOUTUBE_60S_INTEGRATED_VIDEO]: youtube60IntegrationChecked,
    [SupportedFormat.YOUTUBE_90S_INTEGRATED_VIDEO]: youtube90IntegrationChecked,
    [SupportedFormat.YOUTUBE_SHORT]: youtubeShortsChecked,
    [SupportedFormat.INSTAGRAM_DEDICATED_REEL]: igReelChecked,
  });

  const [cpm, setCPM] = useState<number | null>(firstActiveCPM);
  const [upperPrice, setUpperPrice] = useState<number>(0);
  const [lowerPrice, setLowerPrice] = useState<number>(0);
  const [priceMap, setPriceMap] = useState<PriceMap>({
    [SupportedFormat.TIKTOK_DEDICATED_VIDEO]: null,
    [SupportedFormat.YOUTUBE_DEDICATED_VIDEO]: null,
    [SupportedFormat.YOUTUBE_30S_INTEGRATED_VIDEO]: null,
    [SupportedFormat.YOUTUBE_60S_INTEGRATED_VIDEO]: null,
    [SupportedFormat.YOUTUBE_90S_INTEGRATED_VIDEO]: null,
    [SupportedFormat.YOUTUBE_SHORT]: null,
    [SupportedFormat.INSTAGRAM_DEDICATED_REEL]: null,
  });
  const [hasCountryDiscount, setCountryDiscount] = useState<boolean>(false);
  const [discountFactor, setDiscountFactor] = useState<number>(1.0);

  const [fixed, setFixed] = useState<boolean>(false);
  const [priceGenerated, setPriceGenerated] = useState<boolean>(false);
  const [hideOffer, setHideOffer] = useState<boolean>(true);

  const [totalViewsRange, setTotalViewsRange] = useState({});

  // if no active campaigns, dropdown is optional
  const defaultCPMSet = activeCampaigns.length !== 0;

  // get keys of checked boxes
  const checkedIndices = Object.keys(checkboxStatus).filter(
    (platform) => checkboxStatus[platform] === true,
  );
  const checkedPlatformList: Array<SupportedFormat> = checkedIndices.map(
    (index) => index as SupportedFormat,
  );

  const calculatePrice = async () => {
    const selectedPlatformViews: SumViews = Object.keys(priceMap)
      .filter((key) => checkedIndices.includes(key))
      .reduce(
        (views, key) => ({
          ...views,
          [key]: priceMap[key as keyof PriceMap],
        }),
        {},
      );

    // iterate through array of objects, get only the upper view count and sum
    const totalViewsUpper = Object.values(selectedPlatformViews).map(
      ({ upper_views }) => upper_views,
    );
    const totalViewsLower = Object.values(selectedPlatformViews).map(
      ({ lower_views }) => lower_views,
    );
    const adjustedTotalViewsUpper = Object.values(selectedPlatformViews).map(
      ({ adjusted_upper_views }) => adjusted_upper_views,
    );
    const adjustedTotalViewsLower = Object.values(selectedPlatformViews).map(
      ({ adjusted_lower_views }) => adjusted_lower_views,
    );
    // add up prices for each format selected and get price in not minor units
    const roundUpperPrice = Object.values(selectedPlatformViews).reduce((acc, { upper_price }) => {
      return (acc + upper_price / 100) as number;
    }, 0);
    const roundLowerPrice = Object.values(selectedPlatformViews).reduce((acc, { lower_price }) => {
      return (acc + lower_price / 100) as number;
    }, 0);

    setUpperPrice(roundUpperPrice);
    setLowerPrice(roundLowerPrice);
    setTotalViewsRange({
      ...totalViewsRange,
      adjusted_total_upper: adjustedTotalViewsUpper,
      adjusted_total_lower: adjustedTotalViewsLower,
      total_upper: totalViewsUpper,
      total_lower: totalViewsLower,
    });
  };

  // fetch pricing
  useEffect(() => {
    if (creatorInfo) {
      const creatorId = creatorInfo.id;
      const channelId = creatorInfo.youtube_channel_id;
      const tiktokId = creatorInfo.tiktok_uuid;
      const instagramId = creatorInfo.instagram_id;
      const abortController = new AbortController();
      const idList = Object.entries({ channelId, tiktokId, instagramId })
        .filter(([id, val]) => val !== null)
        .reduce((acc, [key, val]) => {
          return { ...acc, [key]: val.toString() };
        }, {});

      // if we only want to fetch select deliverabes
      // prob better to hit the endpoint once with all deliverable options
      // than refetching each time checkbox states change
      const deliverables = Object.keys(checkboxStatus)
        .filter((key) => checkboxStatus[key])
        .map((key) => {
          return { format: key } as Deliverable;
        });

      memoizedFetchPricing(campaignId, creatorId, cpm, idList, abortController).then((res) => {
        if (res.success) {
          setPriceMap(res.price_map);
          if (res.country_discount === "true") {
            setCountryDiscount(true);
            setDiscountFactor(res.country_discount_factor);
          } else {
            setCountryDiscount(false);
            setDiscountFactor(1.0);
          }
        }
      });
    }
  }, [creatorInfo, cpm]);

  // fetch contract review data
  useEffect(() => {
    if (campaignId && campaigns) {
      fetchContractReviewData(campaignId.toString(), adGroupId, true).then((data) => {
        setContractReview(data.contract_reviews);
      });
      setSelectedCampaign(campaigns.filter((campaign) => campaign.id === campaignId)[0]);
    }
  }, [campaigns, campaignId, adGroupId]);

  // handle validation for generate price button
  useEffect(() => {
    if (Object.values(checkboxStatus).includes(true)) {
      setButtonDisabled(false);
    } else if (Object.values(checkboxStatus).every((box) => box === false)) {
      setButtonDisabled(true);
    }

    if (activeCampaigns.length > 0 && campaignId === null) {
      setButtonDisabled(true);
    }
    setHideOffer(true);
  }, [checkboxStatus, campaignId]);

  // if priceMap (cpm) or selected deliverables change, recalculate price
  useEffect(() => {
    if (Object.values(priceMap).every((val) => !val)) {
      setButtonDisabled(true);
    }
    if (Object.values(priceMap).some((val) => val) && Object.keys(priceMap).length > 0) {
      setPriceGenerated(false);
      calculatePrice().then(() => {
        setPriceGenerated(true);
      });
    }
  }, [priceMap, checkboxStatus]);

  const buttonHandler = (index: number | string) => {
    const status = { ...checkboxStatus };
    status[index] = !status[index];
    setCheckboxStatus({ ...status });
  };

  const generatePrice = () => {
    setButtonLoading(true);
    if (priceGenerated) {
      setTimeout(() => {
        setHideOffer(false);
        setButtonLoading(false);
      }, 400);
    }
  };

  // TODO: refactor checkboxes component to automatically render only active platforms
  // do a better check for deliverable seletion on active plats only and for platforms with predicted views
  return (
    <Card shadow="xs" mx="lg" padding="lg" radius="md" withBorder>
      <Stack>
        <InternalCardTitle
          title="Pricing Calculator"
          icon={<IconReceipt2 size={24} color="var(--mantine-color-blue-filled)" />}
        />
        {hasDuplicateCampaign ? (
          <Text c="red">
            Cannot load pricing calculator because this creator has more than 1 active opp for same
            campaign. Please fix the duplicate opportunity in the Opportunities Debug section.
          </Text>
        ) : (
          <Card withBorder padding="lg">
            <Stack p="xs" gap="md">
              <Group justify="space-between">
                <Group gap={3}>
                  <Text c="gray.9" fw={500}>
                    Deliverables
                  </Text>
                  <Tooltip
                    label="Only active platforms with predicted views will be available as deliverable types."
                    position="right-start"
                    arrowSize={4}>
                    <IconInfoCircle size={16} color="var(--mantine-color-blue-7" />
                  </Tooltip>
                </Group>
                <Group align="end">
                  {activeCampaigns.length > 0 ? (
                    <Select
                      required={defaultCPMSet}
                      data={activeCampaigns}
                      // placeholder="Default CPM $25"
                      value={value ? value.value : null}
                      label="Select Campaign CPM"
                      allowDeselect={false}
                      onChange={(_value, option) => {
                        const selectedCampaignId = Number(_value);
                        const selectedOpp = activeOpportunities.filter(
                          (opp) => opp.ad_group.campaign_id === selectedCampaignId,
                        )[0];
                        setCPM(Number(selectedOpp.ad_group.target_cpm));
                        setBrandName(selectedOpp.brand_name);
                        setCampaignId(selectedOpp.ad_group.campaign_id);
                        setAdGroupId(selectedOpp.ad_group.id.toString());
                        setValue(option);
                      }}
                    />
                  ) : (
                    <Select
                      required={defaultCPMSet}
                      data={[{ value: DEFAULT_CPM.toString(), label: "Default CPM $100" }]}
                      value={DEFAULT_CPM.toString()}
                      placeholder="Default CPM $100"
                      label="Select Campaign CPM"
                      allowDeselect={false}
                      onChange={(_value, _option) => {
                        setCPM(Number(_value));
                      }}
                    />
                  )}
                </Group>
              </Group>
              <Checkbox.Group label="Select deliverable type(s)" withAsterisk>
                <Group mt="xs">
                  {activePlatformsList.includes("tiktok_profile") ? (
                    <Checkbox
                      checked={tiktokChecked}
                      onChange={(e) => {
                        setTiktokChecked(e.currentTarget.checked);
                        buttonHandler(SupportedFormat.TIKTOK_DEDICATED_VIDEO);
                      }}
                      value={SupportedFormat.TIKTOK_DEDICATED_VIDEO}
                      label="TikTok"
                    />
                  ) : (
                    <Tooltip.Group openDelay={500} closeDelay={100}>
                      <Tooltip
                        label="Not available as deliverable type because there are no TikTok predicted views for this creator."
                        position="right-start"
                        arrowSize={4}>
                        <Checkbox
                          checked={tiktokChecked}
                          disabled
                          onChange={(e) => {
                            setTiktokChecked(e.currentTarget.checked);
                            buttonHandler(SupportedFormat.TIKTOK_DEDICATED_VIDEO);
                          }}
                          value={SupportedFormat.TIKTOK_DEDICATED_VIDEO}
                          label="TikTok"
                        />
                      </Tooltip>
                    </Tooltip.Group>
                  )}
                  <Group>
                    {activePlatformsList.includes("youtube_channel") ? (
                      <Checkbox
                        checked={youtubeLongChecked}
                        onChange={(e) => {
                          setYoutubeLongChecked(e.currentTarget.checked);
                          buttonHandler(SupportedFormat.YOUTUBE_DEDICATED_VIDEO);
                        }}
                        value={SupportedFormat.YOUTUBE_DEDICATED_VIDEO}
                        label="YouTube Long Dedicated"
                      />
                    ) : (
                      <Tooltip.Group openDelay={500} closeDelay={100}>
                        <Tooltip
                          label="Not available as deliverable type because there are no YouTube Long predicted views for this creator."
                          position="right-start"
                          arrowSize={4}>
                          <Checkbox
                            checked={youtubeLongChecked}
                            disabled
                            onChange={(e) => {
                              setYoutubeLongChecked(e.currentTarget.checked);
                              buttonHandler(SupportedFormat.YOUTUBE_DEDICATED_VIDEO);
                            }}
                            value={SupportedFormat.YOUTUBE_DEDICATED_VIDEO}
                            label="YouTube Long Dedicated"
                          />
                        </Tooltip>
                      </Tooltip.Group>
                    )}
                    {activePlatformsList.includes("youtube_channel") ? (
                      <Checkbox
                        checked={youtube30IntegrationChecked}
                        onChange={(e) => {
                          setYoutube30IntegrationChecked(e.currentTarget.checked);
                          buttonHandler(SupportedFormat.YOUTUBE_30S_INTEGRATED_VIDEO);
                        }}
                        value={SupportedFormat.YOUTUBE_30S_INTEGRATED_VIDEO}
                        label="YouTube 30s Integration"
                      />
                    ) : (
                      <Tooltip.Group openDelay={500} closeDelay={100}>
                        <Tooltip
                          label="Not available as deliverable type because there are no YouTube Long predicted views for this creator."
                          position="right-start"
                          arrowSize={4}>
                          <Checkbox
                            checked={youtube30IntegrationChecked}
                            disabled
                            onChange={(e) => {
                              setYoutube30IntegrationChecked(e.currentTarget.checked);
                              buttonHandler(SupportedFormat.YOUTUBE_30S_INTEGRATED_VIDEO);
                            }}
                            value={SupportedFormat.YOUTUBE_30S_INTEGRATED_VIDEO}
                            label="YouTube 30s Integration"
                          />
                        </Tooltip>
                      </Tooltip.Group>
                    )}
                    {activePlatformsList.includes("youtube_channel") ? (
                      <Checkbox
                        checked={youtube60IntegrationChecked}
                        onChange={(e) => {
                          setYoutube60IntegrationChecked(e.currentTarget.checked);
                          buttonHandler(SupportedFormat.YOUTUBE_60S_INTEGRATED_VIDEO);
                        }}
                        value={SupportedFormat.YOUTUBE_60S_INTEGRATED_VIDEO}
                        label="YouTube 60s Integration"
                      />
                    ) : (
                      <Tooltip.Group openDelay={500} closeDelay={100}>
                        <Tooltip
                          label="Not available as deliverable type because there are no YouTube Long predicted views for this creator."
                          position="right-start"
                          arrowSize={4}>
                          <Checkbox
                            checked={youtube60IntegrationChecked}
                            disabled
                            onChange={(e) => {
                              setYoutube60IntegrationChecked(e.currentTarget.checked);
                              buttonHandler(SupportedFormat.YOUTUBE_60S_INTEGRATED_VIDEO);
                            }}
                            value={SupportedFormat.YOUTUBE_60S_INTEGRATED_VIDEO}
                            label="YouTube 60s Integration"
                          />
                        </Tooltip>
                      </Tooltip.Group>
                    )}
                    {activePlatformsList.includes("youtube_channel") ? (
                      <Checkbox
                        checked={youtube90IntegrationChecked}
                        onChange={(e) => {
                          setYoutube90IntegrationChecked(e.currentTarget.checked);
                          buttonHandler(SupportedFormat.YOUTUBE_90S_INTEGRATED_VIDEO);
                        }}
                        value={SupportedFormat.YOUTUBE_90S_INTEGRATED_VIDEO}
                        label="YouTube 90s Integration"
                      />
                    ) : (
                      <Tooltip.Group openDelay={500} closeDelay={100}>
                        <Tooltip
                          label="Not available as deliverable type because there are no YouTube Long predicted views for this creator."
                          position="right-start"
                          arrowSize={4}>
                          <Checkbox
                            checked={youtube90IntegrationChecked}
                            disabled
                            onChange={(e) => {
                              setYoutube90IntegrationChecked(e.currentTarget.checked);
                              buttonHandler(SupportedFormat.YOUTUBE_90S_INTEGRATED_VIDEO);
                            }}
                            value={SupportedFormat.YOUTUBE_90S_INTEGRATED_VIDEO}
                            label="YouTube 90s Integration"
                          />
                        </Tooltip>
                      </Tooltip.Group>
                    )}
                    {activePlatformsList.includes("youtube_channel") ? (
                      <Checkbox
                        checked={youtubeShortsChecked}
                        onChange={(e) => {
                          setYoutubeShortsChecked(e.currentTarget.checked);
                          buttonHandler(SupportedFormat.YOUTUBE_SHORT);
                        }}
                        value="youtube-short"
                        label="YouTube Shorts"
                      />
                    ) : (
                      <Tooltip.Group openDelay={500} closeDelay={100}>
                        <Tooltip
                          label="Not available as deliverable type because there are no YouTube Shorts predicted views for this creator."
                          position="right-start"
                          arrowSize={4}>
                          <Checkbox
                            checked={youtubeShortsChecked}
                            disabled
                            onChange={(e) => {
                              setYoutubeShortsChecked(e.currentTarget.checked);
                              buttonHandler(SupportedFormat.YOUTUBE_SHORT);
                            }}
                            value={SupportedFormat.YOUTUBE_SHORT}
                            label="YouTube Shorts"
                          />
                        </Tooltip>
                      </Tooltip.Group>
                    )}
                  </Group>
                  {activePlatformsList.includes("instagram_profile") ? (
                    <Checkbox
                      checked={igReelChecked}
                      onChange={(e) => {
                        setIgReelChecked(e.currentTarget.checked);
                        buttonHandler(SupportedFormat.INSTAGRAM_DEDICATED_REEL);
                      }}
                      value={SupportedFormat.INSTAGRAM_DEDICATED_REEL}
                      label="IG Reels"
                    />
                  ) : (
                    <Tooltip.Group openDelay={500} closeDelay={100}>
                      <Tooltip
                        label="Not available as deliverable type because there are no IG Reels predicted views for this creator."
                        position="right-start"
                        arrowSize={4}>
                        <Checkbox
                          checked={igReelChecked}
                          disabled
                          onChange={(e) => {
                            setIgReelChecked(e.currentTarget.checked);
                            buttonHandler(SupportedFormat.INSTAGRAM_DEDICATED_REEL);
                          }}
                          value={SupportedFormat.INSTAGRAM_DEDICATED_REEL}
                          label="IG Reels"
                        />
                      </Tooltip>
                    </Tooltip.Group>
                  )}
                </Group>
              </Checkbox.Group>
              <Button disabled={buttonDisabled} loading={buttonLoading} onClick={generatePrice}>
                Generate Price
              </Button>
            </Stack>
          </Card>
        )}
        <Card withBorder padding="lg">
          {priceGenerated ? (
            <SuggestedPrice
              cpm={cpm}
              upperPrice={upperPrice}
              lowerPrice={lowerPrice}
              platformList={checkedPlatformList}
              totalViewsRange={totalViewsRange}
              platformPricing={priceMap}
              isFixedPrice={fixed}
              hasDiscount={hasCountryDiscount}
              discountFactor={discountFactor}
              hidden={hideOffer}
            />
          ) : (
            <Text>Suggested Price</Text>
          )}
          <Box>
            <OfferCreation
              hidden={hideOffer}
              activePlatforms={activePlatforms}
              activeOpportunities={activeOpportunities}
              contractReview={contractReview}
              creatorInfo={creatorInfo}
              closeLeadName={senderFirstName}
              campaignId={campaignId}
              campaign={selectedCampaign}
              brandName={brandName}
              checkedPlatformList={checkedPlatformList}
              upperPrice={upperPrice}
              lowerPrice={lowerPrice}
            />
          </Box>
        </Card>
        <CreatorOpportunityDebugView
          creatorInfo={creatorInfo}
          opportunities={opportunities}
          setOpportunities={setOpportunities}
        />
      </Stack>
    </Card>
  );
};

export default PricingCalculator;
