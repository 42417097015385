import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "reduxStore/store";
import { CreatorSetsState } from "reduxStore/creatorSetsSlice";

// Selector to monitor creator set names for changes
const selectCreatorSetItems = (state: { creatorSets: CreatorSetsState }) =>
  state.creatorSets.creatorSetItems;
const selectCreatorSetIdToEntryItemIds = (state: RootState) =>
  state.creatorSets.creatorSetIdToEntryItemIds;
const selectEntryItems = (state: RootState) => state.creatorSets.entryItems;
const selectCreatorSetId = (state: RootState, creatorSetId: number) => creatorSetId;

export const selectEntriesByCreatorSetId = createSelector(
  [selectCreatorSetIdToEntryItemIds, selectEntryItems, selectCreatorSetId],
  (creatorSetIdToEntryItemIds, entryItems, creatorSetId) => {
    const entryItemIds = creatorSetIdToEntryItemIds[creatorSetId] ?? [];
    return entryItemIds.map((id) => entryItems[id]);
  },
);

const selectAllEntryItemsIdList = (state: RootState) => state.creatorSets.allEntryItemsIdList;
export const selectAllEntryItems = createSelector(
  [selectEntryItems, selectAllEntryItemsIdList],
  (entryItems, allEntryItemsIdList) => {
    return allEntryItemsIdList.map((id) => entryItems[id]).filter((entryItem) => entryItem != null);
  },
);

// Memoized selector to get creator set names
export const selectCreatorSetNames = createSelector([selectCreatorSetItems], (creatorSetItems) => {
  if (!creatorSetItems) {
    return [];
  }

  // Return a map of Creator Set ID to Name
  return Object.fromEntries(
    Object.entries(creatorSetItems)
      .filter((item) => item !== null && item !== undefined)
      .map(([id, creatorSetItem]) => [Number(id), creatorSetItem.name]),
  );
});

export const selectCreatorSets = createSelector([selectCreatorSetItems], (creatorSetItems) => {
  if (!creatorSetItems) {
    return [];
  }

  return Object.values(creatorSetItems);
});

export default null;
