/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useState } from "react";

import {
  Alert,
  Anchor,
  Box,
  Container,
  Divider,
  Flex,
  HoverCard,
  Indicator,
  List,
  Stack,
  Space,
  Text,
  ThemeIcon,
  Title,
  TitleOrder,
} from "@mantine/core";

import { DatePickerInput } from "@mantine/dates";

import { IconCalendar, IconInfoCircle, IconRepeat } from "@tabler/icons-react";

import {
  contractPendingSignature,
  ContractStatus,
  SHORT_FORM_FORMATS,
  SUPPORTED_FORMATS_TO_CONTRACT_STRINGS,
  SUPPORTED_FORMATS_TO_LABELS,
} from "components/contracts/common/Common";

import header from "components/contracts/contract/TitleHeader";
import Deliverable from "components/contracts/models/Deliverable";
import DeliverableTimeline from "components/contracts/contract/DeliverableTimeline";
import {
  SupportedFormat,
  SUPPORTED_PLATFORMS_TO_DISPLAY_NAMES,
  SupportedPlatform,
  RecurrenceFrequency,
  RecurrenceType,
} from "models/Common";

const SUPPORTED_PLATFORMS_TO_ANALYTICS_NAMES: { [value in SupportedPlatform]: string } = {
  [SupportedPlatform.INSTAGRAM]: "Instagram",
  [SupportedPlatform.TIKTOK]: "TikTok",
  [SupportedPlatform.YOUTUBE]: "YouTube Studio",
};

const DEFAULT_SECTION_PADDING = 1;
const DEFAULT_SECTION_SPACING = "xs";
const DEFAULT_LIST_SPACING = 1;
const FW_BOLD = 600;

export function RepeatCondition({
  repeatInterval,
  repeatFrequency,
  repeatType,
  repeatNumOccurrences,
}: {
  repeatInterval: number;
  repeatFrequency: RecurrenceFrequency;
  repeatType: RecurrenceType;
  repeatNumOccurrences: number;
}) {
  if (
    !repeatInterval ||
    !repeatFrequency ||
    !repeatType ||
    ((!repeatNumOccurrences || repeatNumOccurrences <= 1) && repeatType === RecurrenceType.FIXED)
  ) {
    return null;
  }

  let repeatTypeText = null;
  if (repeatType === RecurrenceType.FIXED) {
    repeatTypeText = (
      <Text span>
        {" "}
        for a total of{" "}
        <Text span fw="500">
          {repeatNumOccurrences - 1} additional occurrence
          {repeatNumOccurrences - 1 === 1 ? "" : "s"}
        </Text>
      </Text>
    );
  }

  return (
    <Alert
      variant="light"
      color="blue"
      title="Recurring Partnership"
      radius="md"
      icon={<IconRepeat size="1rem" />}>
      <Text>
        This partnership will repeat every{" "}
        <Text span fw="500">
          {repeatInterval === 1 ? "" : `${repeatInterval} `}
          {repeatFrequency}
          {repeatInterval > 1 ? "s" : ""}
        </Text>
        {repeatTypeText}.
      </Text>
    </Alert>
  );
}

export function BonusCondition({
  bonusAmount,
  bonusCondition,
}: {
  bonusAmount: number;
  bonusCondition: string;
}) {
  if (!bonusAmount || !bonusCondition) {
    return null;
  }
  return (
    <Text>
      Additional bonus of{" "}
      <Text span fw="500">
        {bonusAmount.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        })}{" "}
        US
      </Text>{" "}
      if {bonusCondition}, to be paid within seven (7) days after the bonus condition is met.
    </Text>
  );
}

export function SpacedDivider() {
  return (
    <Box mt={DEFAULT_SECTION_SPACING}>
      <Divider />
    </Box>
  );
}

function DateSelectorInfo({ brandName }: { brandName: string }) {
  return (
    <>
      <Text>
        Choose a{" "}
        <Text span fw="bold">
          Live Date
        </Text>{" "}
        that works best for you.{" "}
      </Text>
      <Space my="sm" />
      <Text>
        If you&apos;re not sure yet, don&apos;t worry, just use your best estimate! Your{" "}
        <Text span fw="bold">
          Live Date
        </Text>{" "}
        helps{" "}
        <Text span fw="bold">
          {brandName}
        </Text>{" "}
        plan their campaign, but it&apos;s not set in stone.
      </Text>
    </>
  );
}

function SingleDeliverableLiveDate({
  contractStatus,
  brandName,
  deliverable,
  updateDeliverableTimeline,
}: {
  contractStatus: ContractStatus;
  brandName: string;
  deliverable: Deliverable;
  updateDeliverableTimeline: (deliverableId: string, newTimeline: DeliverableTimeline) => void;
}) {
  const [clicked, setClicked] = useState(false);

  const handleLiveDateChange = (newLiveDate: Date) => {
    const newTimeline = deliverable.timeline.adjustTimeline({
      baseLiveDate: newLiveDate,
      isShortForm: SHORT_FORM_FORMATS.includes(deliverable.format),
      staticLiveDateWindow: true,
    });
    updateDeliverableTimeline(deliverable.id, newTimeline);
  };

  const editableLiveDate =
    deliverable.timeline.editableLiveDate &&
    contractPendingSignature(contractStatus) &&
    updateDeliverableTimeline;

  if (!editableLiveDate) {
    return (
      <Text>
        <Text span fw={FW_BOLD}>
          Live Date:{" "}
        </Text>
        <Text span>{deliverable.timeline.liveDate.toDateString()}</Text>
      </Text>
    );
  }

  return (
    <Flex gap="xs" align="center">
      <Text span fw={FW_BOLD}>
        Live Date:
      </Text>
      <HoverCard width={400} shadow="md" withArrow position="top">
        <HoverCard.Target>
          <Indicator color="red" size={7} processing disabled={clicked}>
            <DatePickerInput
              disabled={!deliverable.timeline.editableLiveDate}
              size="xs"
              firstDayOfWeek={0}
              minDate={deliverable.timeline.minLiveDate}
              maxDate={deliverable.timeline.maxLiveDate}
              leftSection={<IconCalendar size="1rem" />}
              value={deliverable.timeline.liveDate}
              onChange={handleLiveDateChange}
              onClick={() => setClicked(true)}
            />
          </Indicator>
        </HoverCard.Target>
        <HoverCard.Dropdown>
          <DateSelectorInfo brandName={brandName} />
        </HoverCard.Dropdown>
      </HoverCard>
    </Flex>
  );
}

function MultiDeliverableLiveDates({
  contractStatus,
  brandName,
  sortOrder,
  deliverables,
  updateDeliverableTimeline,
}: {
  contractStatus: ContractStatus;
  brandName: string;
  sortOrder: number[];
  deliverables: Deliverable[];
  updateDeliverableTimeline: (deliverableId: string, newTimeline: DeliverableTimeline) => void;
}) {
  const hasEditableLiveDate = (editableLiveDate: boolean) =>
    editableLiveDate && contractPendingSignature(contractStatus) && updateDeliverableTimeline;

  const [clicked, setClicked] = useState<Record<string, boolean>>(
    Object.values(deliverables).reduce((acc, deliverable) => {
      acc[deliverable.id] = false;
      return acc;
    }, {} as Record<string, boolean>),
  );

  const liveDateListItems = sortOrder.map((idx) => {
    const deliverable = deliverables[idx];
    const editableLiveDate = hasEditableLiveDate(deliverable.timeline.editableLiveDate);

    if (!editableLiveDate) {
      return (
        <List.Item key={`${deliverable.timeline.liveDate}-${deliverable.format}`}>
          <Text>
            <Text span fw={FW_BOLD} td="underline">
              {SUPPORTED_FORMATS_TO_LABELS[deliverable.format]}
            </Text>
            <Text span>: {deliverable.timeline.liveDate.toDateString()}</Text>
          </Text>
        </List.Item>
      );
    }

    return (
      <List.Item key={`${deliverable.timeline.liveDate}-${deliverable.format}`}>
        <Flex gap="xs" align="center">
          <Text>
            <Text fw={FW_BOLD} td="underline" span>
              {SUPPORTED_FORMATS_TO_LABELS[deliverable.format]}
            </Text>
            :
          </Text>
          <Indicator color="red" size={7} processing disabled={clicked[deliverable.id]}>
            <DatePickerInput
              size="xs"
              disabled={!deliverable.timeline.editableLiveDate}
              firstDayOfWeek={0}
              minDate={deliverable.timeline.minLiveDate}
              maxDate={deliverable.timeline.maxLiveDate}
              leftSection={<IconCalendar size="1rem" />}
              value={deliverable.timeline.liveDate}
              onChange={(newLiveDate) => {
                const newTimeline = deliverable.timeline.adjustTimeline({
                  baseLiveDate: newLiveDate,
                  isShortForm: SHORT_FORM_FORMATS.includes(deliverable.format),
                });
                updateDeliverableTimeline(deliverable.id, newTimeline);
              }}
              onClick={() => {
                const newClicked = { ...clicked };
                newClicked[deliverable.id] = true;
                setClicked(newClicked);
              }}
            />
          </Indicator>
        </Flex>
      </List.Item>
    );
  });

  const anyEditableLiveDate = deliverables.some((deliverable) =>
    hasEditableLiveDate(deliverable.timeline.editableLiveDate),
  );

  return (
    <>
      <Flex gap="0.3rem" align="center">
        <Text span fw={FW_BOLD}>
          Live Dates
        </Text>
        {anyEditableLiveDate && (
          <HoverCard width={400} shadow="md" withArrow position="top">
            <HoverCard.Target>
              <ThemeIcon variant="subtle" size="xs">
                <IconInfoCircle />
              </ThemeIcon>
            </HoverCard.Target>
            <HoverCard.Dropdown>
              <DateSelectorInfo brandName={brandName} />
            </HoverCard.Dropdown>
          </HoverCard>
        )}
      </Flex>
      <List type="unordered" spacing={DEFAULT_LIST_SPACING}>
        {liveDateListItems}
      </List>
    </>
  );
}

function CampaignSection({
  contractStatus,
  brandName,
  sortOrder,
  deliverables,
  updateDeliverableTimeline,
}: {
  contractStatus: ContractStatus;
  brandName: string;
  sortOrder: number[];
  deliverables: Deliverable[];
  updateDeliverableTimeline: (deliverableId: string, newTimeline: DeliverableTimeline) => void;
}) {
  const liveDateText =
    deliverables.length > 1 ? (
      <MultiDeliverableLiveDates
        contractStatus={contractStatus}
        brandName={brandName}
        sortOrder={sortOrder}
        deliverables={deliverables}
        updateDeliverableTimeline={updateDeliverableTimeline}
      />
    ) : (
      <SingleDeliverableLiveDate
        contractStatus={contractStatus}
        brandName={brandName}
        deliverable={deliverables[sortOrder[0]]}
        updateDeliverableTimeline={updateDeliverableTimeline}
      />
    );

  return (
    <Box>
      {header("Campaign", 3)}
      <Container mx="xs">
        <List type="unordered" spacing={DEFAULT_LIST_SPACING}>
          <List.Item>
            <Text span fw={FW_BOLD}>
              Brand:{" "}
            </Text>
            <Text span>{brandName}</Text>
          </List.Item>
          <List.Item>{liveDateText}</List.Item>
        </List>
      </Container>
    </Box>
  );
}

function CreativeBriefLink({ creativeBriefUrl }: { creativeBriefUrl: string }) {
  if (!creativeBriefUrl || creativeBriefUrl.length === 0) {
    return <Text span>Creative Brief (to be provided)</Text>;
  }

  return (
    <Anchor href={creativeBriefUrl} target="_blank">
      Creative Brief
    </Anchor>
  );
}

function DeliverableDetails({
  deliverable,
  brandName,
  creatorFirstName,
  index,
}: {
  deliverable: Deliverable;
  brandName: string;
  creatorFirstName: string;
  index?: number;
}) {
  const campaignFormatContractString = SUPPORTED_FORMATS_TO_CONTRACT_STRINGS[deliverable.format];

  return (
    <>
      <Box p={DEFAULT_SECTION_PADDING}>
        {index === null ? (
          header("Deliverables", 3)
        ) : (
          <Text td="underline">
            {header(
              `Deliverable #${index + 1} - ${SUPPORTED_FORMATS_TO_LABELS[deliverable.format]}`,
              4,
            )}
          </Text>
        )}
        <Container mx="xs">
          <List type="unordered" spacing={DEFAULT_LIST_SPACING}>
            <List.Item>
              <Text>
                {deliverable.platform === SupportedPlatform.INSTAGRAM && (
                  <Text span>
                    {campaignFormatContractString} on {deliverable.creatorHandle} Instagram account{" "}
                    (
                    <Anchor inline href={deliverable.profileLink}>
                      https://www.instagram.com/{deliverable.creatorHandle}
                    </Anchor>
                    ), live for at least 6 months
                    {deliverable.usageRightsDays > 0 && <Text span>, plus Organic O&O usage</Text>}
                  </Text>
                )}
                {deliverable.platform === SupportedPlatform.TIKTOK && (
                  <Text span>
                    {campaignFormatContractString} on {deliverable.creatorHandle} TikTok account (
                    <Anchor inline href={deliverable.profileLink}>
                      https://www.tiktok.com/{deliverable.creatorHandle}
                    </Anchor>
                    ), live for at least 6 months
                    {deliverable.usageRightsDays > 0 && <Text span>, plus Organic O&O usage</Text>}
                  </Text>
                )}
                {deliverable.platform === SupportedPlatform.YOUTUBE &&
                  deliverable.format === SupportedFormat.YOUTUBE_SHORT && (
                    <Text span>
                      {campaignFormatContractString} on {deliverable.creatorHandle} YouTube Channel{" "}
                      (
                      <Anchor inline href={deliverable.profileLink}>
                        https://www.youtube.com/{deliverable.creatorHandle}
                      </Anchor>
                      ), live for at least 6 months
                      {deliverable.usageRightsDays > 0 && (
                        <Text span>, plus Organic O&O usage</Text>
                      )}
                    </Text>
                  )}
                {deliverable.platform === SupportedPlatform.YOUTUBE &&
                  deliverable.format !== SupportedFormat.YOUTUBE_SHORT && (
                    <Text span>
                      {campaignFormatContractString} on {deliverable.creatorHandle} YouTube Channel{" "}
                      (
                      <Anchor inline href={deliverable.profileLink}>
                        https://www.youtube.com/{deliverable.creatorHandle}
                      </Anchor>
                      ), video w/ sponsorship live for at least a year
                      {deliverable.usageRightsDays > 0 && (
                        <Text span>, plus Organic O&O usage</Text>
                      )}
                    </Text>
                  )}
                {deliverable.usageRightsDays > 0 && (
                  <List type="unordered" spacing={DEFAULT_LIST_SPACING}>
                    <List.Item>
                      <Text fs="italic" span>
                        (&ldquo;O&O&rdquo; = &ldquo;Owned & Operated&rdquo;. It means {brandName}{" "}
                        can repost your content on social media accounts that they own)
                      </Text>
                    </List.Item>
                  </List>
                )}
              </Text>
            </List.Item>
            {(deliverable.requiresPromoCode || deliverable.requiresReferralLink) && (
              <List.Item>
                <Text>
                  {creatorFirstName.length > 0 ? creatorFirstName : deliverable.creatorHandle}{" "}
                  agrees to{" "}
                  {deliverable.requiresPromoCode && (
                    <Text span>
                      <Text span>
                        display their Promo Code (to be provided later) in accordance with the
                        instructions provided in the Creative Brief
                      </Text>
                    </Text>
                  )}
                  {deliverable.requiresPromoCode && deliverable.requiresReferralLink && " and "}
                  {deliverable.requiresReferralLink && (
                    <Text span>
                      maintain their Referral Link (to be provided later) in{" "}
                      {deliverable.platform === SupportedPlatform.INSTAGRAM && (
                        <Text span>their bio / link-in-bio for at least 7 days</Text>
                      )}
                      {deliverable.platform === SupportedPlatform.TIKTOK && (
                        <Text span>their bio / link-in-bio for at least 7 days</Text>
                      )}
                      {deliverable.platform === SupportedPlatform.YOUTUBE &&
                        deliverable.format === SupportedFormat.YOUTUBE_SHORT && (
                          <Text span>their bio / link-in-bio for at least 7 days</Text>
                        )}
                      {deliverable.platform === SupportedPlatform.YOUTUBE &&
                        deliverable.format !== SupportedFormat.YOUTUBE_SHORT && (
                          <Text span>
                            the video description, above the fold, for at least a year
                          </Text>
                        )}{" "}
                      after posting the video
                    </Text>
                  )}
                </Text>
              </List.Item>
            )}
            {deliverable.usageRightsDays > 0 && (
              <List.Item>
                <Text>
                  Usage rights for {deliverable.usageRightsDays} days after video goes live{" "}
                  <Text span fs="italic">
                    (see below)
                  </Text>
                </Text>
              </List.Item>
            )}
            {deliverable.platform === SupportedPlatform.YOUTUBE &&
              deliverable.format !== SupportedFormat.YOUTUBE_SHORT && (
                <List.Item>
                  <Text span fs="italic">
                    If you&apos;re unsure what these terms mean, we&apos;ve got an explainer over at{" "}
                    <Anchor inline href="https://campaigns.1stcollab.com/help">
                      campaigns.1stcollab.com/help
                    </Anchor>
                  </Text>
                </List.Item>
              )}
          </List>
        </Container>
      </Box>
      <Box p={DEFAULT_SECTION_PADDING}>
        {index === null ? header("Timeline", 3) : header("Timeline", 4)}
        <Container mx="xs">
          <List type="unordered" spacing={DEFAULT_LIST_SPACING}>
            {deliverable.timeline.requiresScriptReview && (
              <List.Item>
                <Text span>
                  {creatorFirstName.length > 0 ? creatorFirstName : deliverable.creatorHandle}{" "}
                  submits video concept {"(< 200 words) "}
                  by:{" "}
                  <Text span fw={FW_BOLD}>
                    {deliverable.timeline.scriptDate.toDateString()}
                  </Text>
                </Text>
              </List.Item>
            )}
            {deliverable.timeline.requiresScriptReview && (
              <List.Item>
                <Text span>
                  {brandName} provides concept feedback by:{" "}
                  <Text span fw={FW_BOLD}>
                    {deliverable.timeline.scriptRevisionDate.toDateString()}
                  </Text>
                </Text>
              </List.Item>
            )}
            {deliverable.timeline.requiresVideoReview && (
              <List.Item>
                <Text span>
                  {creatorFirstName.length > 0 ? creatorFirstName : deliverable.creatorHandle}{" "}
                  submits {SUPPORTED_PLATFORMS_TO_DISPLAY_NAMES[deliverable.platform]} video draft
                  by:{" "}
                  <Text span fw={FW_BOLD}>
                    {deliverable.timeline.videoDraftDate.toDateString()}
                  </Text>
                </Text>
              </List.Item>
            )}
            {deliverable.timeline.requiresVideoReview && (
              <List.Item>
                <Text span>
                  {brandName} approval received by:{" "}
                  <Text span fw={FW_BOLD}>
                    {deliverable.timeline.approvalDate.toDateString()}
                  </Text>
                </Text>
              </List.Item>
            )}
            <List.Item>
              <Text span>
                Video live by:{" "}
                <Text span fw={FW_BOLD}>
                  {deliverable.timeline.liveDate.toDateString()}
                </Text>
                <List type="unordered" spacing={DEFAULT_LIST_SPACING}>
                  <List.Item>
                    <Text fs="italic" span>
                      Posted video must incorporate feedback/revisions.
                    </Text>
                  </List.Item>
                </List>
              </Text>
            </List.Item>
            <List.Item>
              <Text span>
                {creatorFirstName.length > 0 ? creatorFirstName : deliverable.creatorHandle} to{" "}
                {deliverable.platform === SupportedPlatform.INSTAGRAM && (
                  <Text span>submit screenshots & content analytics</Text>
                )}{" "}
                {deliverable.platform === SupportedPlatform.TIKTOK && (
                  <Text span>submit screenshots & content analytics</Text>
                )}{" "}
                {deliverable.platform === SupportedPlatform.YOUTUBE && (
                  <Text span>provide analytics</Text>
                )}{" "}
                <Text span fs="italic">
                  (see below)
                </Text>{" "}
                by:{" "}
                <Text span fw={FW_BOLD}>
                  {deliverable.timeline.analyticsDate.toDateString()}
                </Text>
              </Text>
            </List.Item>
          </List>
        </Container>
      </Box>
    </>
  );
}

function DeliverablesSection({
  sortOrder,
  deliverables,
  brandName,
  creatorFirstName,
  creativeBriefUrl,
}: {
  sortOrder: number[];
  deliverables: Deliverable[];
  brandName: string;
  creatorFirstName: string;
  creativeBriefUrl: string;
}) {
  if (deliverables.length === 1) {
    return (
      <DeliverableDetails
        deliverable={deliverables[sortOrder[0]]}
        brandName={brandName}
        creatorFirstName={creatorFirstName}
        index={null}
      />
    );
  }

  const deliverableListItems = sortOrder.map((sortIndex, index) => {
    const deliverable = deliverables[sortIndex];
    return (
      <DeliverableDetails
        key={`${deliverable.timeline.liveDate} - ${deliverable.format}`}
        deliverable={deliverable}
        brandName={brandName}
        creatorFirstName={creatorFirstName}
        index={index}
      />
    );
  });

  return (
    <>
      {header("Deliverables", 3)}
      <Text>
        {creatorFirstName.length > 0 ? `${creatorFirstName} agrees ` : "You agree "}
        to follow the guidelines set forth in the{" "}
        <CreativeBriefLink creativeBriefUrl={creativeBriefUrl} /> for all deliverables referenced
        below.
      </Text>
      {deliverableListItems}
    </>
  );
}

function TermsSection() {
  return (
    <Box p={DEFAULT_SECTION_PADDING}>
      {header("Terms", 3)}
      <Text>
        By agreeing to this brand sponsorship, you agree to the{" "}
        <Anchor inline href="https://www.1stcollab.com/terms-of-service">
          1stCollab Terms & Conditions
        </Anchor>
      </Text>
    </Box>
  );
}

function CompensationSection({
  contractAmount,
  bonusAmount,
  bonusCondition,
}: {
  contractAmount: number;
  bonusAmount: number;
  bonusCondition: string;
}) {
  return (
    <Box p={DEFAULT_SECTION_PADDING}>
      {header("Compensation", 3)}
      <Container mx="xs">
        <List type="unordered" spacing={DEFAULT_LIST_SPACING}>
          <List.Item>
            <HoverCard width={280} shadow="md">
              <Text>
                <Text span fw={FW_BOLD}>
                  {contractAmount.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}{" "}
                  US
                </Text>{" "}
                flat-fee, to be{" "}
                <HoverCard.Target>
                  <Anchor>paid</Anchor>
                </HoverCard.Target>{" "}
                within seven (7) days after all deliverables are live.
              </Text>
              <HoverCard.Dropdown>
                <Stack gap="xs">
                  <Text fw={FW_BOLD}>How will I get paid?</Text>
                  <Text>
                    Through 1stCollab&apos;s partnership with Stripe, creators on our platform
                    receive payments with no transaction fees in USD, or 1% for non-USD, compared to
                    ~2-5% through other processors like PayPal.
                  </Text>
                  <Text>
                    Submitting your payment details via Stripe is quick and secure, and takes only a
                    few minutes!{" "}
                  </Text>
                </Stack>
              </HoverCard.Dropdown>
            </HoverCard>
          </List.Item>
          {bonusAmount > 0 && (
            <List.Item>
              <BonusCondition bonusAmount={bonusAmount} bonusCondition={bonusCondition} />
            </List.Item>
          )}
        </List>
      </Container>
    </Box>
  );
}

function UsageRightsSection({
  usageRightsDeliverables,
  brandName,
}: {
  usageRightsDeliverables: Deliverable[];
  brandName: string;
}) {
  const usageRightsPlatforms = usageRightsDeliverables.reduce((acc, deliverable) => {
    if (deliverable.usageRightsDays > 0) {
      return acc.add(deliverable.platform);
    }
    return acc;
  }, new Set<SupportedPlatform>());

  const multiDeliverable = usageRightsDeliverables.length > 1;

  return (
    <Box p={DEFAULT_SECTION_PADDING}>
      {header("Usage Rights", 3)}
      <Container mx="xs">
        <List type="unordered" spacing={DEFAULT_LIST_SPACING}>
          <List.Item>
            <Text>
              Usage rights give {brandName} the right to re-use your content after it goes live.{" "}
              (Most often, they&apos;ll use the content in and/or recut it for social media paid
              ads).
            </Text>
          </List.Item>
          <List.Item>
            <Text>
              As a part of this, you will be asked to send us a high-quality copy of the sponsored{" "}
              portion of your {multiDeliverable ? "videos" : "video"}. Don&apos;t worry; you still
              own the rights to the {multiDeliverable ? "videos" : "video"}! This is just so{" "}
              {brandName} can run {multiDeliverable ? "them as ads" : "it as an ad"}, if they so
              choose.
            </Text>
          </List.Item>
          {usageRightsPlatforms.has(SupportedPlatform.TIKTOK) && (
            <List.Item>
              <Text>
                You will also be required to create and provide us with a Spark authorization code{" "}
                {multiDeliverable && "for your TikTok content"} when your content goes live. If
                you&apos;re not sure how to do this, we&apos;ll help you out.
              </Text>
            </List.Item>
          )}
        </List>
      </Container>
    </Box>
  );
}

function AnalyticsSection({ deliverables }: { deliverables: Deliverable[] }) {
  // get a sorted list of the set of all platforms from the deliverables
  const supportedPlatforms = Array.from(
    deliverables.reduce((acc, deliverable) => {
      if (deliverable?.platform) {
        acc.add(deliverable.platform);
      }
      return acc;
    }, new Set<SupportedPlatform>()),
  ).sort((a, b) => a.localeCompare(b));

  const supportedPlatformsToDisplayNames = supportedPlatforms.map(
    (platform) => SUPPORTED_PLATFORMS_TO_DISPLAY_NAMES[platform],
  );

  const supportedPlatformsToAnalyticsNames = supportedPlatforms.map(
    (platform) => SUPPORTED_PLATFORMS_TO_ANALYTICS_NAMES[platform],
  );

  const getJoinedString = (items: string[]) =>
    items.reduce((acc, item, index) => {
      if (index === 0) {
        return item;
      } else if (index === supportedPlatforms.length - 1) {
        if (supportedPlatforms.length === 2) {
          return `${acc} and ${item}`;
        }
        return `${acc}, and ${item}`;
      }
      return `${acc}, ${item}`;
    }, "");

  const selectedPlatformsString = getJoinedString(supportedPlatformsToDisplayNames);
  const analyticsNamesString = getJoinedString(supportedPlatformsToAnalyticsNames);

  return (
    <Box p={DEFAULT_SECTION_PADDING}>
      {header("Analytics", 3)}
      <Text>
        We ask that you send video analytics a month after the video goes live, so we can check out
        how the video performed. You can either make an account on{" "}
        <Anchor inline href="https://www.1stcollab.com">
          www.1stCollab.com
        </Anchor>{" "}
        and connect your {selectedPlatformsString}{" "}
        {supportedPlatforms.length > 1 ? "accounts" : "account"} and we&apos;ll do it automatically,
        or you can submit screenshots of your {analyticsNamesString} analytics; we&apos;ll send
        detailed instructions after your video goes live.
      </Text>
    </Box>
  );
}

function Addendum({ addendum }: { addendum: string }) {
  if (!addendum || addendum.length === 0) {
    return null;
  }
  return (
    <Box p={DEFAULT_SECTION_PADDING}>
      {header("Addendum", 3)}
      <Flex mt="-md">
        <div dangerouslySetInnerHTML={{ __html: addendum }} />
      </Flex>
    </Box>
  );
}

export default function CampaignContract({
  contractStatus,
  creatorFirstName,
  brandName,
  contractAmount,
  bonusAmount,
  bonusCondition,
  deliverables,
  updateDeliverableTimeline,
  addendum,
  creativeBriefUrl,
  repeatContract,
  repeatInterval,
  repeatFrequency,
  repeatType,
  repeatNumOccurrences,
}: {
  contractStatus: ContractStatus;
  creatorFirstName: string;
  brandName: string;
  contractAmount: number;
  bonusAmount: number;
  bonusCondition: string;
  deliverables: Deliverable[];
  updateDeliverableTimeline: (deliverableId: string, timeline: DeliverableTimeline) => void;
  addendum: string;
  creativeBriefUrl: string;
  repeatContract?: boolean;
  repeatInterval?: number;
  repeatFrequency?: RecurrenceFrequency;
  repeatType?: RecurrenceType;
  repeatNumOccurrences?: number;
}) {
  if (deliverables === null || deliverables.length === 0) {
    return null;
  }

  // Get all deliverables with usage rights
  const usageRightsDeliverables = deliverables.filter(
    (deliverable) => deliverable.usageRightsDays > 0,
  );

  // Sort Order for Deliverables
  const sortOrder = useRef(
    Array.from({ length: deliverables.length }, (_, i) => i).sort((a, b) => {
      const aLiveDate = deliverables[a].timeline.liveDate;
      const bLiveDate = deliverables[b].timeline.liveDate;

      if (aLiveDate < bLiveDate) {
        return -1;
      } else if (aLiveDate > bLiveDate) {
        return 1;
      }
      return 0;
    }),
  );

  return (
    <Box>
      {repeatContract && (
        <RepeatCondition
          repeatInterval={repeatInterval}
          repeatFrequency={repeatFrequency}
          repeatType={repeatType}
          repeatNumOccurrences={repeatNumOccurrences}
        />
      )}
      <CampaignSection
        contractStatus={contractStatus}
        brandName={brandName}
        sortOrder={sortOrder.current}
        deliverables={deliverables}
        updateDeliverableTimeline={updateDeliverableTimeline}
      />
      <SpacedDivider />
      <DeliverablesSection
        sortOrder={sortOrder.current}
        deliverables={deliverables}
        brandName={brandName}
        creatorFirstName={creatorFirstName}
        creativeBriefUrl={creativeBriefUrl}
      />
      <SpacedDivider />
      <TermsSection />
      <SpacedDivider />
      <CompensationSection
        contractAmount={contractAmount}
        bonusAmount={bonusAmount}
        bonusCondition={bonusCondition}
      />
      <SpacedDivider />
      {usageRightsDeliverables.length > 0 && (
        <>
          <UsageRightsSection
            usageRightsDeliverables={usageRightsDeliverables}
            brandName={brandName}
          />
          <SpacedDivider />
        </>
      )}
      <AnalyticsSection deliverables={deliverables} />
      {addendum !== null && addendum.length > 0 && (
        <>
          <SpacedDivider />
          <Addendum addendum={addendum} />
        </>
      )}
    </Box>
  );
}
