// contains all routes and resources used for the admin app.

import React from "react";
import { Route, Navigate, useSearchParams } from "react-router-dom";

import OpsLayout from "ops_team/OpsLayout";
import OpsCampaignDataset from "ops_team/OpsCampaignDataset";
import OpsAdmin from "ops_team/OpsHome";
import OpsInternalProgress from "components/campaign/dashboard/OpsInternalProgress";

import CreatorProfile from "components/creator/CreatorProfilePage";
import { CreatorSearchPage } from "components/creator/search/CreatorSearchPage";

function opsRoutes() {
  const [searchParams] = useSearchParams();
  return (
    <Route path="/ops_home" element={<OpsLayout />}>
      <Route index element={<OpsAdmin />} />
      <Route path="campaign_datasets" element={<OpsCampaignDataset />} />
      <Route
        path="creator"
        element={
          <Navigate to={`/admin/creator/?${new URLSearchParams(searchParams).toString()}`} />
        }
      />
      <Route path="ops_progress" element={<OpsInternalProgress />} />
    </Route>
  );
}

export default opsRoutes;
