import React, { useEffect, useState } from "react";

import { Card, Group, SegmentedControl, Stack, Switch } from "@mantine/core";

import { User } from "models/User";

import { OpsTask } from "models/OpsTask";
import { useAdminAppContext } from "admin/app/AdminAppShell";
import { OpsBrandTasks } from "admin/app/ops/OpsBrandTasks";
import { OpsTaskCard } from "admin/app/ops/OpsTaskCard";
import { OpsCreatorSetTasks } from "admin/app/ops/OpsCreatorSetTasks";
import { OpsUserTasks } from "admin/app/ops/OpsUserTasks";

enum TaskGrouping {
  ALL = "all",
  ASSIGNEE = "assignee",
  CREATOR_SET = "creator_set",
  CAMPAIGN = "campaign",
  BRAND = "brand",
}

export const OpsTaskList = ({
  opsUsers,
  opsTasks,
  searchParams,
  setSearchParams,
}: {
  opsUsers: User[];
  opsTasks: OpsTask[];
  searchParams: URLSearchParams | null;
  setSearchParams: (searchParams: URLSearchParams) => void;
}) => {
  const { brands, creatorSets } = useAdminAppContext();

  const [filterOpen, setFilterOpen] = useState(false);
  const [taskGrouping, setTaskGrouping] = useState(null);
  const [sortedOpsUsers, setSortedOpsUsers] = useState([]);
  const [assignedCreatorSets, setAssignedCreatorSets] = useState([]);
  const [unassignedCreatorSets, setUnassignedCreatorSets] = useState([]);
  const [sortedBrands, setSortedBrands] = useState([]);

  // parse search params
  useEffect(() => {
    // First check if there are any changes to the searchParams
    const searchTaskGrouping = searchParams.get("taskGrouping");
    if (searchTaskGrouping !== null) {
      if (searchTaskGrouping !== taskGrouping) {
        setTaskGrouping(searchTaskGrouping);
      }
    } else if (searchTaskGrouping === null || searchTaskGrouping === "") {
      // Fallback to assignee
      setTaskGrouping(TaskGrouping.ASSIGNEE.valueOf());
    }
  }, [searchParams]);

  // set search params based on nav item
  useEffect(() => {
    const currSearchParamTaskGrouping = searchParams.get("taskGrouping");
    let changed = false;
    // update the searchParams
    if (
      taskGrouping === TaskGrouping.ASSIGNEE.valueOf() &&
      // ok to be null
      (currSearchParamTaskGrouping !== null ||
        currSearchParamTaskGrouping !== TaskGrouping.ASSIGNEE.valueOf())
    ) {
      searchParams.delete("taskGrouping");
      changed = true;
    } else if (
      taskGrouping === TaskGrouping.CREATOR_SET.valueOf() &&
      currSearchParamTaskGrouping !== TaskGrouping.CREATOR_SET.valueOf()
    ) {
      searchParams.set("taskGrouping", TaskGrouping.CREATOR_SET.valueOf());
      changed = true;
    } else if (
      taskGrouping === TaskGrouping.CAMPAIGN.valueOf() &&
      currSearchParamTaskGrouping !== TaskGrouping.CAMPAIGN.valueOf()
    ) {
      searchParams.set("taskGrouping", TaskGrouping.CAMPAIGN.valueOf());
      changed = true;
    } else if (
      taskGrouping === TaskGrouping.BRAND.valueOf() &&
      currSearchParamTaskGrouping !== TaskGrouping.BRAND.valueOf()
    ) {
      searchParams.set("taskGrouping", TaskGrouping.BRAND.valueOf());
      changed = true;
    }
    if (changed) {
      setSearchParams(searchParams);
    }
  }, [taskGrouping]);

  // sort ops users by name
  useEffect(() => {
    const tmpOpsUsers = [...opsUsers];
    tmpOpsUsers.sort((a, b) => a.name.localeCompare(b.name));
    setSortedOpsUsers(tmpOpsUsers);
  }, [opsUsers]);

  // sort brands by name
  useEffect(() => {
    const tmpBrands = [...brands];
    tmpBrands.sort((a, b) => a.brand_name.localeCompare(b.brand_name));
    setSortedBrands(tmpBrands);
  }, [brands]);

  const opsUserTaskComponents = sortedOpsUsers.map((opsUser) => (
    <OpsUserTasks key={opsUser.key} opsUser={opsUser} opsTasks={opsTasks} />
  ));

  // separate out creator sets with tasks and without
  // sort alphabetically for each
  useEffect(() => {
    const tmpAssignedCreatorSetIds: number[] = [];
    opsTasks.forEach((task) => {
      if (task?.creator_set_details.id) {
        tmpAssignedCreatorSetIds.push(task?.creator_set_details.id);
      }
    });
    // get unique
    const uniqueAssignedCreatorSetIds = new Set(tmpAssignedCreatorSetIds);
    // map ids to creator sets
    const tmpAssignedCreatorSets = creatorSets.filter((creatorSet) =>
      uniqueAssignedCreatorSetIds.has(creatorSet.id),
    );
    const tmpUnassignedCreatorSets = creatorSets.filter(
      (creatorSet) => !uniqueAssignedCreatorSetIds.has(creatorSet.id),
    );
    // sort alphabetically
    tmpAssignedCreatorSets.sort((a, b) => a.name.localeCompare(b.name));
    tmpUnassignedCreatorSets.sort((a, b) => a.name.localeCompare(b.name));
    setAssignedCreatorSets(tmpAssignedCreatorSets);
    setUnassignedCreatorSets(tmpUnassignedCreatorSets);
  }, [creatorSets, opsTasks]);

  const opsAssignedCreatorSetTaskComponents = assignedCreatorSets.map((creatorSet) => (
    <OpsCreatorSetTasks
      key={`task-assigned-${creatorSet.id}`}
      creatorSet={creatorSet}
      opsTasks={opsTasks}
    />
  ));

  const opsUnassignedCreatorSetTaskComponents = unassignedCreatorSets.map((creatorSet) => (
    <OpsCreatorSetTasks
      key={`task-unassigned-${creatorSet.id}`}
      creatorSet={creatorSet}
      opsTasks={opsTasks}
    />
  ));

  const opsBrandTaskComponents = sortedBrands.map((brand) => (
    <OpsBrandTasks key={brand.id} brand={brand} opsTasks={opsTasks} />
  ));

  return (
    <Card shadow="xs" padding="md" radius="md" withBorder>
      <Card.Section inheritPadding py="sm">
        <Group justify="center">
          <SegmentedControl
            color="blue"
            value={taskGrouping}
            onChange={(value) => setTaskGrouping(value)}
            data={[
              { label: "By Assignee", value: TaskGrouping.ASSIGNEE.valueOf() },
              { label: "By Creator Set", value: TaskGrouping.CREATOR_SET.valueOf() },
              // { label: "By Campaign", value: TaskGrouping.CAMPAIGN.valueOf() },
              { label: "By Brand", value: TaskGrouping.BRAND.valueOf() },
              { label: "All", value: TaskGrouping.ALL.valueOf() },
            ]}
          />
        </Group>
      </Card.Section>
      <Card.Section inheritPadding withBorder py="sm">
        <Stack gap="md" align="center">
          {taskGrouping === TaskGrouping.ALL.valueOf() &&
            opsTasks.map((task) => <OpsTaskCard key={task.id} opsTask={task} />)}
          {taskGrouping === TaskGrouping.ASSIGNEE.valueOf() && (
            <>
              {opsUserTaskComponents}
              <OpsUserTasks key="unassigned-tasks" opsUser={null} opsTasks={opsTasks} />
            </>
          )}
          {taskGrouping === TaskGrouping.CREATOR_SET.valueOf() && (
            <>
              {opsAssignedCreatorSetTaskComponents}
              {opsUnassignedCreatorSetTaskComponents}
            </>
          )}
          {taskGrouping === TaskGrouping.BRAND.valueOf() && opsBrandTaskComponents}
        </Stack>
      </Card.Section>
    </Card>
  );
};

export default OpsTaskList;
