import React, { KeyboardEvent, useState } from "react";
import { ComboBox, ComboBoxItemType, Tag } from "gestalt";
import "gestalt/dist/gestalt.css";

import { VERTICALS } from "models/Verticals";

const VerticalsComboBox = ({
  selected,
  setSelected,
  onSelected,
  displayLabel,
  helperText,
}: {
  selected: string[];
  setSelected: (selected: string[]) => void;
  onSelected: () => void;
  displayLabel?: string;
  helperText?: string;
}) => {
  const options = VERTICALS.map((vertical) => ({
    label: vertical.name,
    value: vertical.value,
    subtext: vertical.subtext,
  }));

  const MAX_VERTICALS = 3;
  const [searchTerm, setSearchTerm] = useState("");
  const [suggested, setSuggested] = useState(
    options.filter((vertical) => !selected.includes(vertical.value)),
  );
  const [errorMessage, setErrorMessage] = useState("");

  const comboBoxLabel = displayLabel || `Pick up to ${MAX_VERTICALS} verticals`;
  const comboBoxHelpText =
    helperText ||
    "Select verticals that match your content or represent content you want to make in the future. You can edit these at any time on your profile.";

  // sets the new set of selected tags and removes the selected tag from the suggested list
  const updateSelected = (newSelected: string[]) => {
    setSelected(newSelected);
    setSuggested(options.filter((vertical) => !newSelected.includes(vertical.label)));
    if (newSelected.length <= MAX_VERTICALS) {
      setErrorMessage("");
    }
  };

  const handleOnSelect = ({ item: { label } }: { item: ComboBoxItemType }) => {
    if (!selected.includes(label) && selected.length < MAX_VERTICALS) {
      updateSelected([...selected, label]);
      setSearchTerm("");
      if (onSelected) onSelected();
    } else if (selected.length >= MAX_VERTICALS) {
      setErrorMessage(
        `Only ${MAX_VERTICALS} verticals can be selected. Please remove a vertical to select new ones.`,
      );
    }
  };

  const handleOnChange = ({ value }: { value: string }) => {
    setSearchTerm(value);

    const suggestedVerticals = options.filter(
      (item) =>
        item.label.toLocaleLowerCase().includes(value.toLocaleLowerCase()) &&
        !selected.includes(item.label),
    );

    setSuggested(suggestedVerticals);
  };

  const handleClear = () => {
    setSelected([]);
    setSuggested(options);
    setErrorMessage("");
  };

  const handleOnKeyDown = ({
    event: { code, target },
    value,
  }: {
    event: KeyboardEvent<HTMLInputElement>;
    value: string;
  }) => {
    if (target instanceof HTMLInputElement) {
      if (code === "Backspace" && target.selectionEnd === 0)
        updateSelected([...selected.slice(0, -1)]);
    }
  };

  const renderTags = selected.map((vertical) => (
    <Tag
      key={vertical}
      text={vertical}
      onRemove={() => updateSelected(selected.filter((tagValue) => tagValue !== vertical))}
    />
  ));

  return (
    <ComboBox
      accessibilityClearButtonLabel="Clear the current value"
      label={comboBoxLabel}
      helperText={comboBoxHelpText}
      id="verticalsCombo"
      errorMessage={errorMessage}
      inputValue={searchTerm}
      noResultText="No verticals matching your input"
      options={suggested}
      onSelect={handleOnSelect}
      onChange={handleOnChange}
      onClear={handleClear}
      onKeyDown={handleOnKeyDown}
      tags={renderTags}
      placeholder="Add your verticals"
    />
  );
};

export default VerticalsComboBox;
