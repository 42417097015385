import { AuthenticationState } from "onboarding/OnboardingUtils";
import SocialAuthButton from "onboarding/v2/SocialAuthButton";
import React, { useEffect, useState } from "react";
import { connectTikTok, getTiktokLoginStatus, logoutTikTok } from "social/tiktok/TikTokUtils";
import tiktok_auth_icon from "social/tiktok/assets/tiktok_auth_button_icon.svg";

const TiktokConnectV2 = () => {
  const [authState, setAuthState] = useState(AuthenticationState.LOADING);
  const [externalPopup, setExternalPopup] = useState(null);

  useEffect(() => {
    // TODO: check authentication state and set it appropriately. See FBLogin.tsx for example
    getTiktokLoginStatus().then((authStatus) => {
      setAuthState(authStatus);
    });
  }, []);

  useEffect(() => {
    // Uses a timer that polls every 0.5s to tracks the state of the externalPopup that is created in connectClick
    // Once it is closed, we query the backend to check whether or not the authorization credentials
    // were saved correctly.
    if (!externalPopup) {
      return;
    }
    const timer = setInterval(() => {
      if (!externalPopup) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        timer && clearInterval(timer);
      } else if (externalPopup !== null && externalPopup.closed) {
        getTiktokLoginStatus().then((authStatus) => {
          setAuthState(authStatus);
        });
        setExternalPopup(null);
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        timer && clearInterval(timer);
      }
    }, 500);
  }, [externalPopup]);

  return (
    <SocialAuthButton
      label={
        authState === AuthenticationState.AUTHENTICATED ? "Disconnect TikTok" : "Connect TikTok"
      }
      authState={authState}
      iconSrc={tiktok_auth_icon}
      onClick={() => {
        if (authState === AuthenticationState.NOT_AUTHENTICATED) {
          connectTikTok().then((popup) => {
            setExternalPopup(popup);
          });
        } else if (authState === AuthenticationState.AUTHENTICATED) {
          logoutTikTok().then(() => {
            setAuthState(AuthenticationState.NOT_AUTHENTICATED);
          });
        }
      }}
    />
  );
};

export default TiktokConnectV2;
