import React, { useEffect, useState } from "react";

import {
  Autocomplete,
  AutocompleteProps,
  Avatar,
  Box,
  Button,
  Flex,
  Group,
  Text,
  ThemeIcon,
} from "@mantine/core";

import { useDebouncedValue } from "@mantine/hooks";

import {
  IconBrandInstagram,
  IconBrandTiktok,
  IconBrandYoutubeFilled,
  IconHash,
  IconSearch,
} from "@tabler/icons-react";

import { SupportedPlatform } from "models/Common";

import { getAutocompleteOptions } from "components/discovery/search/Api";

import { SearchMethod } from "components/discovery/search/Common";

interface AutoCompleteOption {
  searchMethod: SearchMethod;
  value: string;
  platform: SupportedPlatform;
  numResults?: number;
  numFollowers?: number;
  avatarUrl?: string;
}

function formatNumber(amount: number): string {
  if (!amount) {
    return "0";
  }

  let formattedAmount: string;

  if (amount < 1e3) {
    formattedAmount = `< 1K`;
  } else if (amount < 1e6) {
    // For amounts 1k to 1m, format as Xk
    formattedAmount = `${(amount / 1e3).toFixed(0)}K`;
  } else {
    // For amounts 1m and above, format as X.XXm
    formattedAmount = `${(amount / 1e6).toFixed(1)}M`;
  }

  return formattedAmount;
}

function validateQuery(query: string): boolean {
  if (!query || query.length === 0 || query === "#" || query === "@") {
    return false;
  }
  return true;
}

export function parseQuery(rawQuery: string): [string, SupportedPlatform | null] {
  if (!rawQuery) {
    return ["", null];
  }

  let query = rawQuery;
  let platform = null;

  if (rawQuery.startsWith("#")) {
    platform = SupportedPlatform.TIKTOK;
  } else if (rawQuery.startsWith("@")) {
    const parts = rawQuery.split(" ");
    if (parts.length > 1) {
      [query, platform] = parts;
    }
  } else {
    // Keyword search, which is only supported for YouTube for now.
    platform = SupportedPlatform.YOUTUBE;
  }

  return [query, platform as SupportedPlatform];
}

function normalizeQuery(rawQuery: string): string {
  if (!rawQuery) {
    return "";
  }

  if (rawQuery.startsWith("#")) {
    return rawQuery.slice(1);
  } else if (rawQuery.startsWith("@")) {
    const parts = rawQuery.split(" ");
    return parts[0].slice(1);
  }
  return rawQuery;
}

function PlatformIcon({ platform }: { platform: SupportedPlatform }) {
  const SIZE = "1.1rem";
  if (platform === "youtube") {
    return <IconBrandYoutubeFilled size={SIZE} />;
  } else if (platform === "tiktok") {
    return <IconBrandTiktok size={SIZE} />;
  } else if (platform === "instagram") {
    return <IconBrandInstagram size={SIZE} />;
  }
  return null;
}

function AutocompleteOption({
  optionValue,
  keywordSearchOption,
  userOptions,
  hashtagOptions,
  query,
}: {
  optionValue: string;
  keywordSearchOption: AutoCompleteOption;
  userOptions: AutoCompleteOption[];
  hashtagOptions: AutoCompleteOption[];
  query: string;
}) {
  let searchMethod = null;
  let selectedOption = null;

  if (optionValue.startsWith("#")) {
    searchMethod = SearchMethod.HASHTAG;
    selectedOption = hashtagOptions.find((option) => option.value === optionValue.slice(1));
  } else if (optionValue.startsWith("@")) {
    searchMethod = SearchMethod.PLATFORM;
    selectedOption = userOptions.find((option) => option.value === optionValue.slice(1));
  } else {
    return (
      <Box w="100%">
        <Group align="center" justify="space-between" gap="xl">
          <Flex gap="xs" justify="left" align="center">
            <ThemeIcon
              style={{
                backgroundColor: "transparent",
              }}
              size="sm"
              variant="white"
              color="gray"
              radius="xl">
              <IconSearch />
            </ThemeIcon>
            <Text>
              Search for{" "}
              <Text span fw="700">
                {keywordSearchOption.value.trim()}
              </Text>
              ...
            </Text>
          </Flex>
        </Group>
      </Box>
    );
  }

  const displayName = optionValue.split(" ")[0];
  const substringStart =
    optionValue?.length > 0 ? optionValue?.toLowerCase()?.indexOf(query?.toLowerCase()) : 0;
  const substringEnd = substringStart + (query?.length || 0);

  const highlightedText = (
    <Text>
      {displayName.substring(0, substringStart)}
      <Text span fw="700">
        {displayName.substring(substringStart, substringEnd)}
      </Text>
      {displayName.substring(substringEnd)}
    </Text>
  );

  return (
    <Box w="100%">
      <Group align="center" justify="space-between" gap="xl">
        {searchMethod === SearchMethod.PLATFORM && (
          <Flex gap="xs" justify="left" align="center">
            <Avatar src={selectedOption.avatarUrl} size="sm" radius="xl" />
            {highlightedText}
          </Flex>
        )}
        {searchMethod === SearchMethod.HASHTAG && (
          <Flex gap="xs" justify="left" align="center">
            <ThemeIcon
              style={{
                backgroundColor: "transparent",
              }}
              size="sm"
              variant="white"
              color="gray"
              radius="xl">
              <IconHash />
            </ThemeIcon>
            {highlightedText}
          </Flex>
        )}
        <Text size="sm" c="dimmed" fs="italic">
          {searchMethod === SearchMethod.HASHTAG && (
            <>{formatNumber(selectedOption?.numResults || 0)} Creators</>
          )}
          {searchMethod === SearchMethod.PLATFORM && (
            <Flex gap="xs" align="center">
              <PlatformIcon platform={selectedOption.platform} />
              <Text>
                {formatNumber(selectedOption.numFollowers)}{" "}
                {selectedOption.platform === "youtube" ? "Subscribers" : "Followers"}
              </Text>
            </Flex>
          )}
        </Text>
      </Group>
    </Box>
  );
}

export default function AutocompleteInput({
  loadingResults,
  searchBoxValue,
  setSearchBoxValue,
  handleSubmit,
}: {
  loadingResults: boolean;
  searchBoxValue: string;
  setSearchBoxValue: (value: string) => void;
  handleSubmit: (value: string) => void;
}) {
  // Autocomplete Options
  const [userAutoCompleteOptions, setUserAutoCompleteOptions] = useState<AutoCompleteOption[]>([]);
  const [hashtagAutoCompleteOptions, setHashtagAutoCompleteOptions] = useState<
    AutoCompleteOption[]
  >([]);
  const [debouncedQuery] = useDebouncedValue(searchBoxValue, 100);

  const keywordSearchOption = {
    searchMethod: SearchMethod.KEYWORD,
    value: normalizeQuery(debouncedQuery),
    platform: SupportedPlatform.YOUTUBE,
  };

  const autoCompleteOptions = [
    keywordSearchOption,
    ...hashtagAutoCompleteOptions.map((option) => `#${option.value}`),
    ...userAutoCompleteOptions.map((option) => `@${option.value}`),
  ];

  // Error Messaging
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmitWithErrorHandling = (rawQuery: string) => {
    const [query] = parseQuery(rawQuery);

    if (!validateQuery(query)) {
      setShowError(true);
      setErrorMessage(
        "Invalid search query. Please enter a valid #hashtag, @username, or search query.",
      );
    } else {
      setShowError(false);
      setErrorMessage("");
      handleSubmit(rawQuery);
    }
  };

  // Populate Autocomplete Options
  useEffect(() => {
    if (debouncedQuery && debouncedQuery.length > 0) {
      getAutocompleteOptions(debouncedQuery).then((response) => {
        const { success, users, hashtags } = response;
        if (success) {
          const userOptions = users.map(
            (user: any) =>
              ({
                searchMethod: SearchMethod.PLATFORM,
                value: `${user.option} ${user.platform}`,
                platform: user.platform,
                numFollowers: user.numFollowers,
                avatarUrl: user.avatarUrl,
              } as AutoCompleteOption),
          );

          const hashtagOptions = hashtags.map(
            (hashtag: any) =>
              ({
                searchMethod: SearchMethod.HASHTAG,
                value: hashtag.option,
                platform: hashtag.platform,
                numResults: hashtag.numResults,
              } as AutoCompleteOption),
          );

          setUserAutoCompleteOptions(userOptions);
          setHashtagAutoCompleteOptions(hashtagOptions);
        }
      });
    } else {
      setUserAutoCompleteOptions([]);
      setHashtagAutoCompleteOptions([]);
    }
  }, [debouncedQuery]);

  const renderAutoCompleteOption: AutocompleteProps["renderOption"] = ({ option }) => {
    return (
      <AutocompleteOption
        optionValue={option.value}
        keywordSearchOption={keywordSearchOption}
        userOptions={userAutoCompleteOptions}
        hashtagOptions={hashtagAutoCompleteOptions}
        query={debouncedQuery}
      />
    );
  };

  return (
    <Flex gap="xs" align="flex-start">
      <Autocomplete
        placeholder="Search for a #hashtag, @username, or keywords..."
        renderOption={renderAutoCompleteOption}
        data={searchBoxValue && autoCompleteOptions}
        onChange={(value) => {
          const [query] = parseQuery(value);
          setSearchBoxValue(query);
        }}
        value={searchBoxValue}
        w={500}
        onOptionSubmit={(value) => handleSubmitWithErrorHandling(value)}
        error={showError && errorMessage}
      />
      <Button
        loading={loadingResults}
        disabled={!searchBoxValue}
        onClick={() => handleSubmitWithErrorHandling(searchBoxValue)}>
        Search
      </Button>
    </Flex>
  );
}
