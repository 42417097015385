import React from "react";

import { IconCircleCheck, IconCircleX, IconExclamationCircle } from "@tabler/icons-react";

import { ThemeIcon, Tooltip } from "@mantine/core";

export default function LinkAndPromoStatus({
  requiresReferralLink,
  missingReferralLink,
  requiresPromoCode,
  missingPromoCode,
}: {
  requiresReferralLink: boolean;
  missingReferralLink: boolean;
  requiresPromoCode: boolean;
  missingPromoCode: boolean;
}) {
  if (!requiresReferralLink && !requiresPromoCode) {
    return (
      <Tooltip label="No Referral Link or Promo Code Required">
        <ThemeIcon variant="light" color="gray" size="sm" radius="xl">
          <IconCircleX />
        </ThemeIcon>
      </Tooltip>
    );
  }

  let label = "";

  if (requiresReferralLink && requiresPromoCode) {
    if (missingReferralLink && missingPromoCode) {
      label = "Missing Referral Link and Promo Code";
    } else if (missingReferralLink) {
      label = "Missing Referral Link";
    } else if (missingPromoCode) {
      label = "Missing Promo Code";
    }
  } else if (requiresReferralLink && missingReferralLink) {
    label = "Missing Referral Link";
  } else if (requiresPromoCode && missingPromoCode) {
    label = "Missing Promo Code";
  }

  if (label.length > 0) {
    return (
      <Tooltip label={label}>
        <ThemeIcon variant="light" color="red" size="sm" radius="xl">
          <IconExclamationCircle />
        </ThemeIcon>
      </Tooltip>
    );
  }

  return (
    <ThemeIcon variant="light" color="green" size="sm" radius="xl">
      <IconCircleCheck />
    </ThemeIcon>
  );
}
