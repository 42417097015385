import React from "react";

import { Card } from "@mantine/core";

import { User } from "models/User";

import { OpsTask } from "models/OpsTask";
import { OpsCreateTask } from "admin/app/ops/OpsCreateTask";
import { OpsTaskList } from "admin/app/ops/OpsTaskList";

export const OpsTaskHome = ({
  opsUsers,
  opsTasks,
  setOpsTasks,
  searchParams,
  setSearchParams,
}: {
  opsUsers: User[];
  opsTasks: OpsTask[];
  setOpsTasks: (opsTasks: OpsTask[]) => void;
  searchParams: URLSearchParams | null;
  setSearchParams: (searchParams: URLSearchParams) => void;
}) => {
  const hasTasks = opsTasks.length > 0;
  return (
    <>
      <Card shadow="xs" padding="md" radius="md" withBorder>
        <Card.Section inheritPadding withBorder py="sm">
          <OpsCreateTask opsUsers={opsUsers} opsTasks={opsTasks} setOpsTasks={setOpsTasks} />
        </Card.Section>
      </Card>
      {hasTasks && (
        <OpsTaskList
          opsUsers={opsUsers}
          opsTasks={opsTasks}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
        />
      )}
    </>
  );
};

export default OpsTaskHome;
