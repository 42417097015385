import React from "react";
import { useNavigate } from "react-router-dom";

import { Anchor, Badge, Table } from "@mantine/core";

import { OpsTask } from "models/OpsTask";
import { IconCheck, IconX } from "@tabler/icons-react";

export default function OpsUserTasksTableRow({ opsTask }: { opsTask: OpsTask }) {
  const navigate = useNavigate();
  return (
    <Table.Tr>
      <Table.Td>
        <Anchor size="sm" href={`/admin/ops/task/${opsTask.id}`}>
          {opsTask.name}
        </Anchor>
      </Table.Td>
      <Table.Td>
        {opsTask.completed ? (
          <IconCheck size={24} color="green" />
        ) : (
          <IconX size={24} color="red" />
        )}
      </Table.Td>
      <Table.Td>
        <Badge
          variant="light"
          onClick={() =>
            navigate(`/admin/creator_sets?creatorSetId=${opsTask.creator_set_details.id}`)
          }>
          {opsTask.creator_set_details.name}
        </Badge>
      </Table.Td>
      <Table.Td>{opsTask.value}</Table.Td>
      <Table.Td>{opsTask.creator_set_details.sourcing_difficulty}</Table.Td>
    </Table.Tr>
  );
}
