import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import "gestalt/dist/gestalt.css";

import { Paper, Stack } from "@mantine/core";

import AdminCreateBrand from "admin/AdminCreateBrand";
import { AdminBrandList } from "admin/AdminBrandList";
import AdminBrandSettings from "admin/AdminBrandSettings";
import { BrandHeader } from "admin/app/brand/BrandHeader";
import { getAndSetBrands } from "models/Brand";
import { useAdminAppContext } from "admin/app/AdminAppShell";

export default function AdminBrands() {
  const { user, userProfile } = useAdminAppContext();

  const [searchParams, setSearchParams] = useSearchParams();

  const [brandId, setBrandId] = useState(null);

  const [brands, setBrands] = useState([]);

  useEffect(() => {
    const abortController = new AbortController();
    getAndSetBrands(user, setBrands, abortController);
    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <Stack gap={16}>
      <BrandHeader
        brands={brands}
        selectedBrandId={brandId}
        setSelectedBrandId={setBrandId}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
      />
      {brandId ? (
        <AdminBrandSettings
          user={user}
          brandId={brandId}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
        />
      ) : (
        <Paper p="md">
          <Stack gap={24}>
            {userProfile && userProfile.is_staff && <AdminCreateBrand user={user} />}
            <AdminBrandList user={user} brands={brands} setSelectedBrandId={setBrandId} />
          </Stack>
        </Paper>
      )}
    </Stack>
  );
}
