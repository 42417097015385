import { User } from "firebase/auth";

import { API_URL } from "configs/Configs";
import { createRequestWithFirebaseToken, handleResult } from "utils/ApiUtils";

const FACEBOOK_CREDENTIALS_URL = `${API_URL}/authentication/facebook/`;

export interface FBAPIResponse extends fb.FacebookStatic {
  error?: Error; // TODO: figure out what the actual type of this is
}

export interface FacebookCredentials {
  accessToken: string;
  userId: string;
  expiresOn: number;
}

interface UpdateCredentialsResponse {
  num_pages: number;
  num_profiles: number;
}

const toExpiresOn = (expiresIn: number) => Math.floor(new Date().getTime() / 1000 + expiresIn);

const updateIgProfiles = async (fbCredentials: FacebookCredentials) => {
  const request = await createRequestWithFirebaseToken({
    url: FACEBOOK_CREDENTIALS_URL,
    method: "POST",
    body: JSON.stringify(fbCredentials),
  });
  const response: UpdateCredentialsResponse = await handleResult(request);
  return response;
};

const deleteFbCredentials = async () => {
  const request = await createRequestWithFirebaseToken({
    url: FACEBOOK_CREDENTIALS_URL,
    method: "DELETE",
  });
  const response = await handleResult(request);
  return response;
};

export const getFbCredentialsStatus = async (user: User) => {
  const firebaseToken = await user.getIdToken();
  const request = new Request(FACEBOOK_CREDENTIALS_URL, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
  });
  const response = await handleResult(request);
  return response;
};

// Updates backend FB data for a user after the user logs in and provides an AuthResponse object
export const processAuthResponse = async (authResponse: fb.AuthResponse) => {
  if (!authResponse) {
    deleteFbCredentials();
    return null;
  }
  const expiresOn = toExpiresOn(Number(authResponse.expiresIn));
  const updateResponse = await updateIgProfiles({
    accessToken: authResponse.accessToken,
    userId: authResponse.userID,
    expiresOn,
  });
  return updateResponse;
};
