import React from "react";

import { Anchor, Avatar, Group, Table, Text } from "@mantine/core";

import { OpsTask } from "models/OpsTask";
import { User } from "models/User";

export default function OpsUserTableRow({
  opsUser,
  opsTasks,
}: {
  opsUser: User;
  opsTasks: OpsTask[];
}) {
  // filter tasks to only the ops user
  const userTasks = opsUser
    ? opsTasks.filter((task) => task?.assigned_user_details?.key === opsUser.key)
    : opsTasks.filter((task) => task?.assigned_user_details?.key === null);

  const estimatedWorkload = userTasks.reduce((acc, task) => acc + task.estimated_workload, 0);

  const getInitials = (name: string) => {
    if (!name) return "";
    const names = name.split(" ");
    return names[0].charAt(0) + names[names.length - 1].charAt(0);
  };

  return (
    <Table.Tr>
      <Table.Td>
        <Group grow preventGrowOverflow={false} wrap="nowrap">
          {opsUser?.avatar_url ? (
            <Avatar w={40} h={40} src={opsUser?.avatar_url} style={{ flex: "none" }} />
          ) : (
            <Avatar w={40} h={40} style={{ flex: "none" }}>
              {getInitials(opsUser?.name)}
            </Avatar>
          )}
          <Anchor size="sm" href={`/admin/ops/user/?key=${opsUser.key}`}>
            <Text fw={500}>{opsUser?.name || "Unassigned"}</Text>
          </Anchor>
        </Group>
      </Table.Td>
      <Table.Td>{estimatedWorkload}</Table.Td>
    </Table.Tr>
  );
}
