import { Box } from "gestalt";
import React from "react";
import "gestalt/dist/gestalt.css";

type Props = {
  height?: number;
  width?: number;
};

const Spacer = ({ height, width }: Props) => (
  <Box
    height={height}
    width={width}
    dangerouslySetInlineStyle={{
      __style: { flexShrink: "0" },
    }}
  />
);

export default Spacer;
