import { Layer } from "gestalt";
import { AuthenticationState } from "onboarding/OnboardingUtils";
import SocialAuthButton from "onboarding/v2/SocialAuthButton";
import React, { useEffect, useState } from "react";
import instagramIcon from "social/fb/assets/instagram_icon.svg";
import InstructionModal from "social/fb/InstagramInstructionModal";
import { fbLogout, getLoginStatus } from "social/fb/InstagramUtils";

const InstagramConnectV2 = () => {
  const [authState, setAuthState] = useState(AuthenticationState.LOADING);
  const [showInstructions, setShowInstructions] = useState(false);

  useEffect(() => {
    if (authState === AuthenticationState.LOADING) {
      getLoginStatus(
        () => {
          setAuthState(AuthenticationState.AUTHENTICATED);
        },
        () => {
          setAuthState(AuthenticationState.NOT_AUTHENTICATED);
        },
      );
    }
  }, []);

  return (
    <>
      <SocialAuthButton
        label={
          authState === AuthenticationState.AUTHENTICATED
            ? "Disconnect Instagram"
            : "Connect Instagram"
        }
        authState={authState}
        iconSrc={instagramIcon}
        onClick={() => {
          if (authState === AuthenticationState.NOT_AUTHENTICATED) {
            setShowInstructions(true);
          } else if (authState === AuthenticationState.AUTHENTICATED) {
            fbLogout(() => {
              setAuthState(AuthenticationState.NOT_AUTHENTICATED);
            });
          }
        }}
      />
      {showInstructions ? (
        <Layer>
          <InstructionModal setAuth={setAuthState} setShowInstructions={setShowInstructions} />
        </Layer>
      ) : null}
    </>
  );
};

export default InstagramConnectV2;
