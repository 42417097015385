import { Button, Flex } from "gestalt";
import "gestalt/dist/gestalt.css";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { User } from "firebase/auth";

import {
  refreshInstagramStats,
  getInstagramProfileInsights,
  getInstagramProfile,
  InstagramProfileResponse,
  InstagramAudienceResponse,
  InstagramDailyStatsEntryResponse,
} from "social/fb/InstagramUtils";
import LayoutBase from "components/LayoutBase";
import LoadingScreen from "components/LoadingScreen";
import AudienceStats from "pages/profile/InstagramProfileStatsView";
import { ProfileSummary } from "social/fb/InstagramProfile";
import StatsCard from "components/StatsCard";
import { getAdminUser } from "admin/AdminLayout";

const refreshInstagramProfile = async (username: string) => {
  refreshInstagramStats(username);
};

const getInstagramProfileStats = async (
  username: string,
  user: User,
  setProfile: (profile: InstagramProfileResponse) => void,
  setAudience: (audience: InstagramAudienceResponse) => void,
  setDailyStats: (audience: InstagramDailyStatsEntryResponse[]) => void,
) => {
  const firebaseToken = await user.getIdToken();
  const profile = await getInstagramProfile(username, firebaseToken);
  const audienceStats = await getInstagramProfileInsights(username, firebaseToken);
  setProfile(profile);
  setAudience(audienceStats.audience);
  setDailyStats(audienceStats.stats);
};

const AdminInstagramProfile = () => {
  const user: User = getAdminUser();
  const { username } = useParams();
  const [profile, setProfile] = useState(null);
  const [audience, setAudience] = useState(null);
  const [dailyStats, setDailyStats] = useState(null);

  useEffect(() => {
    getInstagramProfileStats(username, user, setProfile, setAudience, setDailyStats);
  }, []);

  return (
    <LayoutBase>
      {profile && audience && dailyStats ? (
        <Flex direction="column" gap={6} alignItems="center">
          <Button text="Refresh Stats" onClick={() => refreshInstagramProfile(username)} />
          <ProfileSummary profile={profile} />
          <StatsCard
            stats={dailyStats}
            metric_names={["impressions", "profile_views", "reach", "website_clicks"]}
          />
          <AudienceStats audience={audience} />
        </Flex>
      ) : (
        <LoadingScreen />
      )}
    </LayoutBase>
  );
};

export default AdminInstagramProfile;
