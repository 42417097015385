import { API_URL } from "configs/Configs";
import { Campaign } from "models/Campaign";
import { createRequestWithFirebaseToken, handleResult } from "utils/ApiUtils";

export const updateCampaign = async (
  campaignHashId: number,
  updatedFields: { [key: string]: any },
) => {
  const updatedFieldsForCampaign = {
    ...updatedFields,
    campaignId: campaignHashId,
  };
  const request = await createRequestWithFirebaseToken({
    url: `${API_URL}/api/models/campaigns/${campaignHashId}/`,
    method: "PATCH",
    body: JSON.stringify(updatedFieldsForCampaign),
  });
  const result = await handleResult(request);

  return result;
};

export default null;
