import React, { useEffect, useState } from "react";
import { User } from "firebase/auth";

import { Badge, Button, Card, Flex, Group, Stack, Text, TextInput } from "@mantine/core";

import { IconCheck } from "@tabler/icons-react";

import { SUPPORTED_FORMATS_TO_LABELS } from "components/contracts/common/Common";

import {
  SupportedFormat,
  SupportedPlatform,
  SUPPORTED_PLATFORMS_TO_DISPLAY_NAMES,
} from "models/Common";

import {
  addPromoCodeToDeliverable,
  addReferralLinkToDeliverable,
} from "components/contracts/common/Api";

import {
  showFailureNotification,
  showSuccessNotification,
} from "components/common/Notifications";

import Deliverable from "components/contracts/models/Deliverable";

import CreatorAvatar from "components/contracts/common/CreatorAvatar";
import DeliverableCardHeader from "components/contracts/common/DeliverableCardHeader";
import SocialHandleButton from "components/contracts/common/SocialHandleButton";

import { toLongDateString } from "utils/DateUtils";
import ViewContractLink from "components/contracts/common/ViewContractLink";

function isValidUrl(url: string) {
  const regex = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
  return regex.test(url);
}

function isValidPromoCode(promoCode: string) {
  return promoCode.length > 0 && promoCode.length <= 255;
}

function ReferralLinkAndPromoCodeStatusBadge({
  requiresReferralLink,
  requiresPromoCode,
  hasMissingReferralLink,
  hasMissingPromoCode,
  numDeliverables,
}: {
  requiresReferralLink: boolean;
  requiresPromoCode: boolean;
  hasMissingReferralLink: boolean;
  hasMissingPromoCode: boolean;
  numDeliverables: number;
}) {
  const referralLinkRequirementText = numDeliverables > 1 ? "Referral Links" : "Referral Link";
  const promoCodeRequirementText = numDeliverables > 1 ? "Promo Codes" : "Promo Code";
  const combinedText = `${
    requiresReferralLink && hasMissingReferralLink ? referralLinkRequirementText : ""
  }${
    requiresReferralLink && hasMissingReferralLink && requiresPromoCode && hasMissingPromoCode
      ? " & "
      : ""
  }${requiresPromoCode && hasMissingPromoCode ? promoCodeRequirementText : ""}`;

  if (
    (requiresReferralLink && hasMissingReferralLink) ||
    (requiresPromoCode && hasMissingPromoCode)
  ) {
    return (
      <Badge variant="light" color="red">
        {combinedText} Required
      </Badge>
    );
  }

  return (
    <Badge variant="light" color="teal">
      {`${requiresReferralLink ? referralLinkRequirementText : ""}${
        requiresReferralLink && requiresPromoCode ? " & " : ""
      }${requiresPromoCode ? promoCodeRequirementText : ""}`}{" "}
      Submitted
    </Badge>
  );
}

export function ReferralLinkInput({
  deliverableId,
  platform,
  format,
  creatorHandle,
  url,
  setUrl,
  isMissingReferralLink,
  setIsMissingReferralLink,
  disabled,
}: {
  deliverableId: string;
  platform: SupportedPlatform;
  format: SupportedFormat;
  creatorHandle: string;
  url: string;
  setUrl: (value: string) => void;
  isMissingReferralLink: boolean;
  setIsMissingReferralLink: (value: boolean) => void;
  disabled?: boolean;
}) {
  const [loading, setLoading] = useState<boolean>(false);

  const handleAddReferralLink = () => {
    setLoading(true);
    addReferralLinkToDeliverable({ deliverableId, url })
      .then((response: any) => {
        const { success, error } = response;

        if (success) {
          setLoading(false);
          setIsMissingReferralLink(false);
          showSuccessNotification({
            message: (
              <Text>
                Successfully added referral link to{" "}
                <Text span fw="500">
                  {creatorHandle}&apos;s
                </Text>{" "}
                {SUPPORTED_FORMATS_TO_LABELS[format]}!
              </Text>
            ),
          });
        } else {
          setLoading(false);
          showFailureNotification(error);
        }
      })
      .catch((error) => {
        setLoading(false);
        showFailureNotification(error);
      });
  };

  return (
    <Flex gap="xs" align="flex-start" w="100%">
      <TextInput
        leftSection={isMissingReferralLink ? null : <IconCheck size="1rem" />}
        label={`${SUPPORTED_PLATFORMS_TO_DISPLAY_NAMES[platform]} Referral Link`}
        value={url}
        onChange={(event) => setUrl(event.currentTarget.value)}
        error={url && !isValidUrl(url) ? "Please enter a valid URL." : null}
        size="xs"
        placeholder="https://www.example.com"
        w="100%"
        disabled={!isMissingReferralLink || disabled}
      />
      {!disabled && (
        <Button
          mt={25}
          size="xs"
          w={80}
          disabled={!url || !isValidUrl(url)}
          loading={loading}
          onClick={
            isMissingReferralLink
              ? handleAddReferralLink
              : () => {
                  setIsMissingReferralLink(true);
                }
          }
          color={isMissingReferralLink ? "blue" : "gray"}>
          {isMissingReferralLink ? "Submit" : "Edit"}
        </Button>
      )}
    </Flex>
  );
}

export function PromoCodeInput({
  deliverableId,
  platform,
  format,
  creatorHandle,
  promoCode,
  setPromoCode,
  isMissingPromoCode,
  setIsMissingPromoCode,
  disabled,
}: {
  deliverableId: string;
  platform: SupportedPlatform;
  format: SupportedFormat;
  creatorHandle: string;
  promoCode: string;
  setPromoCode: (value: string) => void;
  isMissingPromoCode: boolean;
  setIsMissingPromoCode: (value: boolean) => void;
  disabled?: boolean;
}) {
  const [loading, setLoading] = useState<boolean>(false);

  const handleAddPromoCode = () => {
    setLoading(true);
    addPromoCodeToDeliverable({ deliverableId, promoCode })
      .then((response: any) => {
        const { success, error } = response;

        if (success) {
          setLoading(false);
          setIsMissingPromoCode(false);
          showSuccessNotification({
            message: (
              <Text>
                Successfully added promo code to{" "}
                <Text span fw="500">
                  {creatorHandle}&apos;s
                </Text>{" "}
                {SUPPORTED_FORMATS_TO_LABELS[format]}!
              </Text>
            ),
          });
        } else {
          setLoading(false);
          showFailureNotification(error);
        }
      })
      .catch((error) => {
        setLoading(false);
        showFailureNotification(error);
      });
  };

  return (
    <Flex gap="xs" align="flex-start" w="100%">
      <TextInput
        leftSection={isMissingPromoCode ? null : <IconCheck size="1rem" />}
        label={`${SUPPORTED_PLATFORMS_TO_DISPLAY_NAMES[platform]} Promo Code`}
        value={promoCode}
        onChange={(event) => setPromoCode(event.currentTarget.value)}
        error={promoCode && !isValidPromoCode(promoCode) ? "Promo Code is too long." : null}
        size="xs"
        placeholder="EXAMPLE10"
        w="100%"
        disabled={!isMissingPromoCode || disabled}
      />
      {!disabled && (
        <Button
          mt={25}
          size="xs"
          w={80}
          disabled={!promoCode || !isValidPromoCode(promoCode)}
          loading={loading}
          onClick={
            isMissingPromoCode
              ? handleAddPromoCode
              : () => {
                  setIsMissingPromoCode(true);
                }
          }
          color={isMissingPromoCode ? "blue" : "gray"}>
          {isMissingPromoCode ? "Submit" : "Edit"}
        </Button>
      )}
    </Flex>
  );
}

function DeliverableInfo({
  deliverableId,
  platform,
  format,
  creatorHandle,
  profileLink,
  liveDate,
  referralLinkRedirectUrl,
  currentPromoCode,
  requiresReferralLink,
  requiresPromoCode,
  isMissingReferralLink,
  setIsMissingReferralLink,
  isMissingPromoCode,
  setIsMissingPromoCode,
}: {
  deliverableId: string;
  platform: SupportedPlatform;
  format: SupportedFormat;
  creatorHandle: string;
  profileLink: string;
  liveDate: Date;
  referralLinkRedirectUrl: string;
  currentPromoCode: string;
  requiresReferralLink: boolean;
  requiresPromoCode: boolean;
  isMissingReferralLink: boolean;
  setIsMissingReferralLink: (value: boolean) => void;
  isMissingPromoCode: boolean;
  setIsMissingPromoCode: (value: boolean) => void;
}) {
  const [urlState, setUrlState] = useState(referralLinkRedirectUrl);
  const [promoCodeState, setPromoCodeState] = useState(currentPromoCode);

  return (
    <Card withBorder radius="md" py="sm" px="lg">
      <Stack gap={4}>
        <Group justify="space-between">
          <DeliverableCardHeader
            platform={platform}
            format={format}
            subHeading={
              <Text size="sm" c="dimmed">
                Live by {toLongDateString(liveDate)} on
                <SocialHandleButton
                  platform={platform}
                  handle={creatorHandle}
                  profileLink={profileLink}
                  showIcon={false}
                />
              </Text>
            }
          />
          <ReferralLinkAndPromoCodeStatusBadge
            requiresReferralLink={requiresReferralLink}
            requiresPromoCode={requiresPromoCode}
            hasMissingReferralLink={isMissingReferralLink}
            hasMissingPromoCode={isMissingPromoCode}
            numDeliverables={1}
          />
        </Group>
        {requiresReferralLink && (
          <ReferralLinkInput
            deliverableId={deliverableId}
            platform={platform}
            format={format}
            creatorHandle={creatorHandle}
            url={urlState}
            setUrl={setUrlState}
            isMissingReferralLink={isMissingReferralLink}
            setIsMissingReferralLink={setIsMissingReferralLink}
          />
        )}
        {requiresPromoCode && (
          <PromoCodeInput
            deliverableId={deliverableId}
            platform={platform}
            format={format}
            creatorHandle={creatorHandle}
            promoCode={promoCodeState}
            setPromoCode={setPromoCodeState}
            isMissingPromoCode={isMissingPromoCode}
            setIsMissingPromoCode={setIsMissingPromoCode}
          />
        )}
      </Stack>
    </Card>
  );
}

export default function ReferralLinkAndPromoCodeInput({
  contractId,
  signatureFirstName,
  signatureLastName,
  avatarUrl,
  amount,
  deliverables,
  showAdminOptions,
}: {
  contractId: string;
  signatureFirstName: string;
  signatureLastName: string;
  avatarUrl: string;
  amount: number;
  deliverables: Deliverable[];
  showAdminOptions?: boolean;
}) {
  const displayName = `${signatureFirstName} ${signatureLastName}`;

  const [missingReferralLinkStates, setMissingReferralLinkStates] = useState<
    Record<string, boolean>
  >({});
  const [missingPromoCodeStates, setMissingPromoCodeStates] = useState<Record<string, boolean>>({});

  useEffect(() => {
    setMissingReferralLinkStates(
      deliverables.reduce((acc, deliverable) => {
        acc[deliverable.id] = deliverable.missingReferralLink;
        return acc;
      }, {} as Record<string, boolean>),
    );

    setMissingPromoCodeStates(
      deliverables.reduce((acc, deliverable) => {
        acc[deliverable.id] = deliverable.missingPromoCode;
        return acc;
      }, {} as Record<string, boolean>),
    );
  }, [deliverables]);

  const requiresReferralLink = deliverables.some((deliverable) => deliverable.requiresReferralLink);
  const requiresPromoCode = deliverables.some((deliverable) => deliverable.requiresPromoCode);

  const hasMissingReferralLink = Object.values(missingReferralLinkStates).some(
    (missingReferralLink) => missingReferralLink,
  );
  const hasMissingPromoCode = Object.values(missingPromoCodeStates).some(
    (missingPromoCode) => missingPromoCode,
  );

  return (
    <Card radius="md" withBorder bg="#F8F9FA" px="lg">
      <Stack gap="xs">
        <Group justify="space-between">
          <CreatorAvatar displayName={displayName} avatarUrl={avatarUrl} contractAmount={amount} />
          <Flex justify="right" align="center" gap="xs">
            <ReferralLinkAndPromoCodeStatusBadge
              requiresReferralLink={requiresReferralLink}
              requiresPromoCode={requiresPromoCode}
              hasMissingReferralLink={hasMissingReferralLink}
              hasMissingPromoCode={hasMissingPromoCode}
              numDeliverables={deliverables.length}
            />
            {showAdminOptions && <ViewContractLink contractId={contractId} />}
          </Flex>
        </Group>
        <Stack gap="xs">
          {deliverables.map((deliverable) => (
            <DeliverableInfo
              key={`${deliverable.id}-deliverable-info}`}
              deliverableId={deliverable.id}
              platform={deliverable.platform}
              format={deliverable.format}
              creatorHandle={deliverable.creatorHandle}
              profileLink={deliverable.profileLink}
              liveDate={deliverable.timeline.liveDate}
              referralLinkRedirectUrl={deliverable.referralLinkRedirectUrl}
              currentPromoCode={deliverable.promoCode}
              requiresReferralLink={deliverable.requiresReferralLink}
              requiresPromoCode={deliverable.requiresPromoCode}
              isMissingReferralLink={missingReferralLinkStates[deliverable.id]}
              setIsMissingReferralLink={(value: boolean) => {
                setMissingReferralLinkStates({
                  ...missingReferralLinkStates,
                  [deliverable.id]: value,
                });
              }}
              isMissingPromoCode={missingPromoCodeStates[deliverable.id]}
              setIsMissingPromoCode={(value: boolean) => {
                setMissingPromoCodeStates({
                  ...missingPromoCodeStates,
                  [deliverable.id]: value,
                });
              }}
            />
          ))}
        </Stack>
      </Stack>
    </Card>
  );
}
