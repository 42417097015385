export const invalidEmail = (email: string) =>
  !email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i);

export function validateEmailList(emails: string[]): boolean {
  // Regular expression for an email
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  // Check if every email in the list is valid
  return emails.every((email) => emailRegex.test(email));
}
