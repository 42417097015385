import { useNavigate } from "react-router-dom";
import {
  Flex,
  Image,
  ActionIcon,
  AppShell,
  MantineStyleProp,
  ScrollArea,
  Container,
  Stack,
  Divider,
  Button,
} from "@mantine/core";
import {
  Icon,
  IconProps,
  IconMinus,
  IconPlus,
  IconHome2,
  IconGitPullRequest,
  IconHelp,
  IconUserSearch,
  IconUsers,
  IconVideo,
  IconVocabulary,
  IconLogin,
  IconFileText,
} from "@tabler/icons-react";
import AdGroupNavButton from "campaigns/navbar/main/AdGroupNavButton";
import CampaignNavButton from "campaigns/navbar/main/CampaignNavButton";
import {
  isAdGroupSelected,
  isCampaignSelected,
  isCreatorNavButtonSelected,
  isCreatorSetNavButtonSelected,
  isMainNavButtonSelected,
  MainNavButtonType,
  selectAdGroup,
  selectCampaign,
  selectCreatorsMainNavButton,
  selectCreatorsSetNavButton,
  SelectedNavButton,
  selectMainNavButton,
} from "campaigns/helpers/mainNavButtonHelpers";
import RootButton, { RootButtonName } from "campaigns/navbar/main/RootButton";
import { Campaign, BrandToDoSummary } from "models/Campaign";
import React, { useEffect, useState } from "react";
import CompanyAndUserHeader from "campaigns/navbar/main/CompanyAndUserHeader";
import { User } from "models/User";
import logo from "1stcollab_logo.svg";
import { CreatorSet } from "components/discovery/Datamodels";
import CreatorSetNavButton from "campaigns/navbar/main/CreatorSetNavButton";
import { logout } from "auth/firebaseAuthHelpers";

const getToDoCount = (toDoSummary: BrandToDoSummary | null, campaignHashId: number) => {
  const total = toDoSummary?.campaigns[campaignHashId]?.total || 0;
  return total;
};

const getCreatorSetToDoCount = (toDoSummary: BrandToDoSummary | null, creatorSetId: number) =>
  toDoSummary?.creatorsets?.[creatorSetId]?.creator_review.total || 0;

const getAdGroupToDoCount = (
  toDoSummary: BrandToDoSummary | null,
  campaignHashId: number,
  adGroupId: number,
) => toDoSummary?.campaigns?.[campaignHashId]?.ad_groups?.[adGroupId]?.total || 0;

const MinorButton = ({
  IconName,
  onClick,
  paddingStart,
  title,
}: {
  IconName?: React.ForwardRefExoticComponent<Omit<IconProps, "ref"> & React.RefAttributes<Icon>>;
  onClick: () => void;
  paddingStart?: number;
  title: string;
}) => (
  <Button
    style={{
      alignSelf: "flex-start",
      paddingLeft: paddingStart,
    }}
    onClick={onClick}
    variant="transparent"
    color="var(--mantine-color-dark-2)"
    size="sm"
    leftSection={IconName ? <IconName size={24} /> : null}>
    {title}
  </Button>
);

const MainNavBar = ({
  campaigns,
  onCollapseNavBar,
  user,
  selectedButton,
  setSelectedButton,
  style,
  creatorSets,
  toDoSummary,
}: {
  campaigns: Campaign[] | null;
  onCollapseNavBar: () => void;
  selectedButton: SelectedNavButton;
  setSelectedButton: (selectedButton: SelectedNavButton) => void;
  user: User;
  style?: MantineStyleProp;
  creatorSets: CreatorSet[] | null;
  toDoSummary: BrandToDoSummary | null;
}) => {
  // navigator
  const navigate = useNavigate();
  const [isCampaignsExpanded, setIsCampaignsExpanded] = useState<boolean>(false);
  const [isCreatorSetsExpanded, setIsCreatorSetsExpanded] = useState<boolean>(false);
  const [campaignAdGroupsVisibilityMap, setCampaignAdGroupsVisibilityMap] = useState<
    Map<number, boolean> // campaign hash id to boolean
  >(new Map());

  const updateCampaignAdGroupVisibilityMap = (
    campaignHashId: number,
    isAdGroupVisible: boolean,
  ) => {
    const newMap = new Map(campaignAdGroupsVisibilityMap);
    newMap.set(campaignHashId, isAdGroupVisible);
    setCampaignAdGroupsVisibilityMap(newMap);
  };

  useEffect(() => {
    if (campaigns?.length > 0) {
      setIsCampaignsExpanded(true); // default show list of campaigns if exists
    }
  }, [campaigns]);

  useEffect(() => {
    if (creatorSets?.length > 0) {
      // Only expand the creators by default if the link is hyperlinked to have it
      setIsCreatorSetsExpanded(true);
    }
  }, [creatorSets]);

  useEffect(() => {
    if (selectedButton.type === MainNavButtonType.AD_GROUP) {
      // force show ad groups for campaign if ad group is selected
      updateCampaignAdGroupVisibilityMap(selectedButton.campaignHashId, true);
    }
  }, [selectedButton]);

  const isCampaignsButtonSelected = isMainNavButtonSelected(
    selectedButton,
    RootButtonName.CAMPAIGNS,
  );
  const isContentLibraryButtonSelected = isMainNavButtonSelected(
    selectedButton,
    RootButtonName.CONTENT_LIBRARY,
  );
  const isCreatorsButtonSelected = isCreatorNavButtonSelected(selectedButton);
  const isDiscoverButtonSelected = isMainNavButtonSelected(selectedButton, RootButtonName.DISCOVER);
  const isContractsButtonSelected = isMainNavButtonSelected(
    selectedButton,
    RootButtonName.CONTRACTS_OVERVIEW,
  );
  const CampaignsRightIcon: React.ForwardRefExoticComponent<
    Omit<IconProps, "ref"> & React.RefAttributes<Icon>
  > = isCampaignsExpanded ? IconMinus : IconPlus;
  const CreatorSetsRightIcon: React.ForwardRefExoticComponent<
    Omit<IconProps, "ref"> & React.RefAttributes<Icon>
  > = isCreatorSetsExpanded ? IconMinus : IconPlus;

  return (
    <Flex
      direction="column"
      justify="space-between"
      h="100%"
      style={{
        paddingTop: "var(--mantine-spacing-md)", // p={md}
        paddingBottom: "var(--mantine-spacing-md)", // p={md}
        paddingLeft: "var(--mantine-spacing-md)", // p={md}
        paddingRight: "0px",
        ...style,
      }}>
      <AppShell.Section pr="var(--mantine-spacing-md)">
        <CompanyAndUserHeader user={user} onCollapseIconClick={onCollapseNavBar} />
      </AppShell.Section>
      <Flex direction="column" style={{ flexGrow: "1", overflowY: "scroll" }}>
        <Container px={0} pr="var(--mantine-spacing-md)" w="100%">
          <RootButton
            isSelected={isMainNavButtonSelected(selectedButton, RootButtonName.HOME)}
            LeftIconName={IconHome2}
            text="Home"
            onButtonClick={() => {
              selectMainNavButton(setSelectedButton, RootButtonName.HOME);
              navigate("/campaigns");
            }}
          />
        </Container>
        <Container px={0} pr="var(--mantine-spacing-md)" w="100%">
          <RootButton
            isSelected={isDiscoverButtonSelected}
            LeftIconName={IconUserSearch}
            text="Discover"
            onButtonClick={() => {
              selectMainNavButton(setSelectedButton, RootButtonName.DISCOVER);
              navigate("discover");
            }}
          />
        </Container>
        <Container px={0} pr="var(--mantine-spacing-md)" w="100%">
          <RootButton
            isSelected={isContractsButtonSelected}
            LeftIconName={IconFileText}
            text="Contracts"
            onButtonClick={() => {
              selectMainNavButton(setSelectedButton, RootButtonName.CONTRACTS_OVERVIEW);
              navigate("contracts_overview");
            }}
          />
        </Container>
        <Container px={0} pr="var(--mantine-spacing-md)" w="100%">
          <RootButton
            isSelected={isContentLibraryButtonSelected}
            LeftIconName={IconVideo}
            text="Content Library"
            onButtonClick={() => {
              selectMainNavButton(setSelectedButton, RootButtonName.CONTENT_LIBRARY);
              navigate("content_library");
            }}
          />
        </Container>
        <Container px={0} pr="var(--mantine-spacing-md)" w="100%">
          <RootButton
            isSelected={isMainNavButtonSelected(selectedButton, RootButtonName.CREATIVE_BRIEFS)}
            LeftIconName={IconVocabulary}
            text="Creative Briefs"
            onButtonClick={() => {
              selectMainNavButton(setSelectedButton, RootButtonName.CREATIVE_BRIEFS);
              navigate("creative_briefs");
            }}
          />
        </Container>
        <>
          <Container px={0} pr="var(--mantine-spacing-md)" w="100%">
            <RootButton
              isSelected={isCreatorsButtonSelected}
              LeftIconName={IconUsers}
              text="Creator Sets"
              onButtonClick={() => {
                selectCreatorsMainNavButton(setSelectedButton);
                // Need to toggle the AdGroupNavBar with the right buttons.
                navigate("creator_sets/all");
              }}
              rightSection={
                creatorSets?.length > 0 ? (
                  <ActionIcon
                    variant="transparent"
                    component="div"
                    size={26}
                    onClick={(event: React.MouseEvent<HTMLElement>) => {
                      setIsCreatorSetsExpanded(!isCreatorSetsExpanded);
                    }}>
                    <CreatorSetsRightIcon
                      height={14}
                      width={14}
                      color={
                        isCreatorsButtonSelected
                          ? "var(--mantine-color-white)"
                          : "var(--mantine-color-black)"
                      }
                      stroke={3}
                    />
                  </ActionIcon>
                ) : null
              }
            />
          </Container>
          {isCreatorSetsExpanded && creatorSets?.length > 0
            ? creatorSets.map((creatorSet) => {
                const isCurrentCreatorSetSelected = isCreatorSetNavButtonSelected(
                  selectedButton,
                  creatorSet.id,
                );
                const toDoCount = getCreatorSetToDoCount(toDoSummary, creatorSet.id);

                return (
                  <React.Fragment key={`creatorSet-${creatorSet.id}`}>
                    <Container px={0} pr="var(--mantine-spacing-md)" w="100%">
                      <CreatorSetNavButton
                        key={`${creatorSet.id}-${creatorSet.name}`}
                        isSelected={isCurrentCreatorSetSelected}
                        creatorSet={creatorSet}
                        onClick={(selectedCreatorSet) => {
                          // only navigate to new url if creatorset actually changed
                          const creatorSetDidChange =
                            selectedCreatorSet.id !== selectedButton.creatorSetId;
                          selectCreatorsSetNavButton(setSelectedButton, selectedCreatorSet.id);
                          if (creatorSetDidChange) {
                            navigate(`creator_sets/${selectedCreatorSet.id}/recommended`);
                          }
                        }}
                        numTodos={toDoCount}
                      />
                    </Container>
                  </React.Fragment>
                );
              })
            : null}
        </>
        <Container px={0} pr="var(--mantine-spacing-md)" w="100%">
          <RootButton
            isSelected={isCampaignsButtonSelected}
            LeftIconName={IconGitPullRequest}
            text="Campaigns"
            onButtonClick={() => {
              selectMainNavButton(setSelectedButton, RootButtonName.CAMPAIGNS);
              navigate("/campaigns/overview");
            }}
            rightSection={
              campaigns?.length > 0 ? (
                <ActionIcon
                  component="div"
                  variant="transparent"
                  size={26}
                  onClick={(event: React.MouseEvent<HTMLElement>) => {
                    setIsCampaignsExpanded(!isCampaignsExpanded);
                  }}>
                  <CampaignsRightIcon
                    height={14}
                    width={14}
                    color={
                      isCampaignsButtonSelected
                        ? "var(--mantine-color-white)"
                        : "var(--mantine-color-black)"
                    }
                    stroke={3}
                  />
                </ActionIcon>
              ) : null
            }
          />
        </Container>
        {isCampaignsExpanded
          ? campaigns.map((campaign) => {
              const isCurrentCampaignSelected = isCampaignSelected(
                selectedButton,
                campaign.hash_id,
              );
              const shouldShowAdGroups =
                campaignAdGroupsVisibilityMap.get(campaign.hash_id) || false;
              const toDoCount = getToDoCount(toDoSummary, campaign.hash_id);
              return (
                <React.Fragment key={`campaignBlock-${campaign.hash_id}`}>
                  <Container px={0} pr="var(--mantine-spacing-md)" w="100%">
                    <CampaignNavButton
                      key={campaign.hash_id}
                      isSelected={isCurrentCampaignSelected}
                      campaign={campaign}
                      onClick={(selectedCampaign) => {
                        // only navigate to new url if campaign actually changed
                        // OR previously ad group was selected
                        const campaignDidChange =
                          selectedCampaign.hash_id !== selectedButton.campaignHashId ||
                          selectedButton.adGroupId != null;
                        selectCampaign(setSelectedButton, selectedCampaign.hash_id);
                        // always show ad groups when selecting the campaign
                        updateCampaignAdGroupVisibilityMap(selectedCampaign.hash_id, true);

                        if (campaignDidChange) {
                          // only navigate to new url if campaign actually changed
                          // TODO(andrew): change this to go to /results by default
                          navigate(`${campaign.hash_id}/`);
                        }
                      }}
                      shouldShowAdGroups={shouldShowAdGroups}
                      onToggleAdGroups={(campaignHashId, newToggleValue) => {
                        updateCampaignAdGroupVisibilityMap(campaignHashId, newToggleValue);
                      }}
                      numTodos={toDoCount}
                    />
                  </Container>
                  {shouldShowAdGroups && campaign.ad_groups?.length > 0
                    ? campaign.ad_groups?.map((adGroup) => (
                        <AdGroupNavButton
                          key={adGroup.id}
                          campaign={campaign}
                          adGroup={adGroup}
                          isSelected={isAdGroupSelected(
                            selectedButton,
                            campaign.hash_id,
                            adGroup.id,
                          )}
                          onClick={(selectedCampaign, adGroupId) => {
                            const adGroupDidChange =
                              selectedCampaign.hash_id !== selectedButton.campaignHashId ||
                              adGroupId !== selectedButton.adGroupId;

                            selectAdGroup(setSelectedButton, selectedCampaign.hash_id, adGroupId);
                            if (adGroupDidChange) {
                              navigate(`${selectedCampaign.hash_id}/${adGroupId}/`);
                            }
                          }}
                          numTodos={getAdGroupToDoCount(toDoSummary, campaign.hash_id, adGroup.id)}
                        />
                      ))
                    : null}
                </React.Fragment>
              );
            })
          : null}
        <Container
          px={0}
          pr="var(--mantine-spacing-md)"
          w="100%"
          style={{
            display: "flex",
            flexDirection: "column",
          }}>
          <MinorButton
            paddingStart={12}
            title="Product Guide"
            onClick={() => {
              window.open(
                "https://1stcollab.notion.site/1stCollab-Brand-Product-Guide-c260996ea008436c9837f6078b83bdab",
                "_blank",
              );
            }}
          />
          <MinorButton
            paddingStart={12}
            title="Settings"
            onClick={() => {
              navigate("/campaigns/settings");
            }}
          />
        </Container>
      </Flex>
      <AppShell.Section>
        <Stack style={{ "--stack-gap": "12px" }}>
          <Divider />
          <MinorButton
            IconName={IconLogin}
            title="Logout"
            paddingStart={0}
            onClick={() => {
              logout(() => {
                navigate("/");
              });
            }}
          />
          <Image w={100} src={logo} />
        </Stack>
      </AppShell.Section>
    </Flex>
  );
};

export default MainNavBar;
