import React, { useEffect, useState } from "react";
import {
  ActionIcon,
  Badge,
  Button,
  Card,
  Divider,
  Group,
  MantineColor,
  Menu,
  Paper,
  Select,
  Space,
  Stack,
  Table,
  Text,
  ThemeIcon,
  Tooltip,
  rem,
} from "@mantine/core";
import { modals } from "@mantine/modals";
import { notifications, Notifications } from "@mantine/notifications";

import {
  IconActivity,
  IconCheck,
  IconMenu2,
  IconPlus,
  IconQuestionMark,
  IconTrash,
  IconWorldUpload,
  IconZoomMoney,
} from "@tabler/icons-react";

import Opportunity, {
  OpportunityActiveState,
  OPPORTUNITY_STATES,
  OPPORTUNITY_COLORS,
} from "models/Opportunity";
import { CampaignRecords, fetchCampaignData } from "admin/AdminUtils";
import { manualUploadOpportunityToClose } from "admin/api/opportunityApi";
import {
  addCreatorOpportunity,
  fetchCampaignStatusMapping,
  fetchCreatorOpportunities,
  updateOpportunityState,
  deleteOpporunity,
} from "campaigns/api/fetchOpportunities";
import { InternalCardSubtitle } from "components/creator/InternalCardTitle";
import { CreatorV2Info } from "models/CreatorV2Info";
import { CampaignAdGroup } from "models/Campaign";

interface CampaignStatusPair {
  emailFunnelStatusId: number;
  campaignStatus: string;
}

const OpportunityStatusDisplay = ({
  opportunity,
  opportunityStatus,
  icon,
  color = "blue",
}: {
  opportunity: Opportunity[];
  opportunityStatus: OpportunityActiveState;
  icon: React.ReactNode;
  color?: MantineColor;
}) => {
  return (
    <Paper>
      <Stack align="center" gap={2}>
        <ThemeIcon variant="light" size={rem(50)} radius="xl" color={color}>
          {icon}
        </ThemeIcon>
        <Space h="sm" />
        <Text size="md" span fw={600}>
          {opportunity.length}
        </Text>
        <Text size="sm" c="dimmed">
          {opportunityStatus === OpportunityActiveState.UNKNOWN
            ? "Inactive/Unknown"
            : OPPORTUNITY_STATES[opportunityStatus]}{" "}
          Opportunities
        </Text>
      </Stack>
    </Paper>
  );
};

const AddOpportunity = ({
  creatorId,
  campaignData,
  setOpportunities,
}: {
  creatorId: number;
  campaignData: CampaignRecords;
  setOpportunities: (opportunities: Opportunity[]) => void;
}) => {
  const [campaignId, setCampaignId] = useState(null);
  const [adGroups, setAdGroups] = useState<CampaignAdGroup[]>(null);
  const [adGroupId, setAdGroupId] = useState(null);
  const [buttonSpinner, setButtonSpinner] = useState(false);
  // Pick the campaign and then pick the adgroup
  if (!campaignData) {
    return null;
  }
  return (
    <Stack>
      <Notifications />
      <Text size="sm" fw={500}>
        Add Opportunity (no auto-email send)
      </Text>
      <Group>
        <Select
          id="campaign_name"
          placeholder="Select Campaign"
          onChange={(value) => {
            setCampaignId(Number(value));
            setAdGroups(campaignData[Number(value)].ad_groups);
          }}
          data={Object.keys(campaignData).map((key) => {
            const campaign = campaignData[Number(key)];
            return {
              label: campaign.campaign_title,
              value: String(campaign.campaign_id),
            };
          })}
          searchable
        />
        <Select
          id="adgroup_name"
          placeholder="Select Ad Group"
          disabled={!adGroups}
          onChange={(value) => {
            setAdGroupId(Number(value));
          }}
          data={
            adGroups &&
            adGroups.map((adGroup) => {
              return {
                label: adGroup.name,
                value: String(adGroup.id),
              };
            })
          }
          searchable
        />
        <Button
          variant="outline"
          leftSection={<IconPlus size={20} />}
          loading={buttonSpinner}
          onClick={() => {
            const abortController = new AbortController();
            setButtonSpinner(true);
            addCreatorOpportunity(creatorId, campaignId, adGroupId, abortController).then(
              (opportunity) => {
                if (opportunity?.success) {
                  notifications.show({
                    title: "Opportunity added.",
                    message: `Opportunity added for campaign id: ${campaignId}`,
                    autoClose: false,
                  });
                  fetchCreatorOpportunities(creatorId, abortController).then((opps) => {
                    setOpportunities(opps);
                    setButtonSpinner(false);
                  });
                } else {
                  setButtonSpinner(false);
                  notifications.show({
                    color: "red",
                    title: "Error with adding the opportunity!",
                    message: opportunity
                      ? opportunity?.message || JSON.stringify(opportunity)
                      : "Unknown error.",
                    autoClose: false,
                  });
                }
              },
            );
          }}>
          Add Opportunity (Manual Outreach)
        </Button>
      </Group>
    </Stack>
  );
};

const OpportunityOverflowMenu = ({
  opportunity,
  setOpportunities,
}: {
  opportunity: Opportunity;
  setOpportunities: (opportunities: Opportunity[]) => void;
}) => {
  const openManualUploadToCloseConfirmationModal = () =>
    modals.openConfirmModal({
      title: "Please confirm manual upload to Close.",
      centered: true,
      children: (
        <Text size="sm">
          This will not override the Lead properties and will not link them to a sequence. You will
          need to manually subscribe the opportunity to a sequence or send a manual email.
        </Text>
      ),
      labels: { confirm: "Upload to Close", cancel: "Cancel" },
      // confirmProps: { color: "red" },
      onConfirm: () => {
        manualUploadOpportunityToClose(opportunity.id).then((response) => {
          if (response?.opportunity) {
            notifications.show({
              title: "Opportunity uploaded to Close.",
              message: response.message,
              color: "green",
              autoClose: false,
            });
            const abortController = new AbortController();
            fetchCreatorOpportunities(opportunity.creator_id, abortController).then((oppResp) => {
              setOpportunities(oppResp);
            });
          } else {
            notifications.show({
              title: "Error uploading opportunity to Close.",
              message: response?.error,
              color: "red",
            });
          }
        });
      },
    });

  return (
    <Menu width={300} withinPortal>
      <Menu.Target>
        <ActionIcon variant="subtle" color="gray" component="button" size="sm">
          <IconMenu2 size={18} />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item
          // Disabled unless the opportunity is in the correct state
          disabled={
            !(
              opportunity.email_funnel_state === 0 ||
              opportunity.email_funnel_state === 44 ||
              opportunity.email_funnel_state === 45
            )
          }
          leftSection={
            <IconWorldUpload
              style={{ width: rem(16), height: rem(16) }}
              color="teal"
              stroke={1.5}
            />
          }
          onClick={openManualUploadToCloseConfirmationModal}>
          Upload to Close (Manual Outreach)
        </Menu.Item>
        <Menu.Item
          leftSection={
            <IconTrash style={{ width: rem(16), height: rem(16) }} color="red" stroke={1.5} />
          }
          onClick={() => {
            const abortController = new AbortController();
            deleteOpporunity(opportunity.id, abortController).then((opp) => {
              notifications.show({ title: "Opportunity Deleted", message: opp.message });
              fetchCreatorOpportunities(opportunity.creator_id, abortController).then((oppResp) => {
                setOpportunities(oppResp);
              });
            });
          }}>
          Delete
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

const OpportunityUpdater = ({
  opportunity,
  campaignStatusMap,
}: {
  opportunity: Opportunity;
  campaignStatusMap: CampaignStatusPair[];
}) => {
  const [loading, setLoading] = useState(false);
  const [opportunityState, setOpportunityState] = useState(opportunity.email_funnel_state);
  return (
    campaignStatusMap && (
      <Group justify="center" gap="sm" wrap="nowrap">
        <Select
          size="xs"
          id="campaign_status"
          value={String(opportunityState)}
          onChange={(value) => {
            setOpportunityState(Number(value));
          }}
          data={campaignStatusMap.map((status) => {
            return {
              label: status.campaignStatus,
              value: String(status.emailFunnelStatusId),
            };
          })}
          searchable
        />
        {opportunity.email_funnel_state === 0 ? (
          <Tooltip label="Opportunity not in Close.">
            <Button
              size="xs"
              variant="light"
              data-disabled
              onClick={(event: { preventDefault: () => void }) => event.preventDefault()}>
              Set Campaign Status
            </Button>
          </Tooltip>
        ) : (
          <Button
            size="xs"
            variant="light"
            loading={loading}
            onClick={() => {
              const abortController = new AbortController();
              setLoading(true);
              updateOpportunityState(opportunity.id, opportunityState, abortController)
                .then((opp) => {
                  if (opp.success) {
                    notifications.show({
                      title: "Opportunity State Updated.",
                      message: opp.message,
                      color: "green",
                      autoClose: false,
                    });
                  } else {
                    notifications.show({
                      title: "Error updating opportunity state",
                      color: "red",
                      message: opp.message,
                    });
                  }
                })
                .catch((err) => {
                  notifications.show({
                    title: "Error updating opportunity state",
                    message: err.message,
                  });
                })
                .finally(() => {
                  setLoading(false);
                });
            }}>
            Set Campaign Status
          </Button>
        )}
      </Group>
    )
  );
};

const OpportunityRow = ({
  opportunity,
  setOpportunities,
  campaignStatusMap,
}: {
  opportunity: Opportunity;
  setOpportunities: (opportunities: Opportunity[]) => void;
  campaignStatusMap: CampaignStatusPair[];
}) => (
  <Table.Tr>
    <Table.Td>
      <Badge variant="light" color={OPPORTUNITY_COLORS[opportunity.active_state]}>
        {OPPORTUNITY_STATES[opportunity.active_state]}
      </Badge>
    </Table.Td>
    <Table.Td>{opportunity.id}</Table.Td>
    <Table.Td>{opportunity.ad_group.name}</Table.Td>
    <Table.Td>{opportunity.ad_group.campaign_name}</Table.Td>
    <Table.Td>{opportunity.ad_group.campaign_id}</Table.Td>
    <Table.Td>${opportunity.ad_group.target_cpm}</Table.Td>
    <Table.Td>
      <OpportunityUpdater opportunity={opportunity} campaignStatusMap={campaignStatusMap} />
    </Table.Td>
    <Table.Td>
      <OpportunityOverflowMenu opportunity={opportunity} setOpportunities={setOpportunities} />
    </Table.Td>
  </Table.Tr>
);

const CreatorOpportunityDebugView = ({
  creatorInfo,
  opportunities,
  setOpportunities,
}: {
  creatorInfo: CreatorV2Info;
  opportunities: Opportunity[];
  setOpportunities: (opportunities: Opportunity[]) => void;
}) => {
  const activeOpportunities = opportunities.filter(
    (opp) => opp.active_state === OpportunityActiveState.ACTIVE,
  );
  const completeOpportunities = opportunities.filter(
    (opp) => opp.active_state === OpportunityActiveState.COMPLETE,
  );
  const unknownOpportunities = opportunities.filter((opp) =>
    [OpportunityActiveState.INACTIVE, OpportunityActiveState.UNKNOWN].includes(opp.active_state),
  );

  const [campaignStatusMap, setCampaignStatusMap] = useState<CampaignStatusPair[]>(null);
  const [campaignData, setCampaignData] = useState(null);

  useEffect(() => {
    const abortController = new AbortController();
    fetchCampaignData(abortController).then((data) => {
      setCampaignData(data.campaigns);
    });

    fetchCampaignStatusMapping(abortController).then((data) => {
      setCampaignStatusMap(data.campaignStatuses);
    });
  }, []);

  return (
    <Card withBorder padding="lg">
      <InternalCardSubtitle
        title="Opportunities"
        icon={<IconZoomMoney size={24} color="var(--mantine-color-gray-9)" />}
      />
      <Space h="md" />
      <AddOpportunity
        creatorId={creatorInfo.id}
        campaignData={campaignData}
        setOpportunities={setOpportunities}
      />
      {opportunities.length > 0 && (
        <Stack gap="sm">
          <Space h="sm" />
          <Card withBorder shadow="none">
            <Group align="center" justify="space-evenly">
              <OpportunityStatusDisplay
                opportunity={activeOpportunities}
                opportunityStatus={OpportunityActiveState.ACTIVE}
                icon={<IconActivity size={24} />}
                color="blue.4"
              />
              <Divider orientation="vertical" />
              <OpportunityStatusDisplay
                opportunity={completeOpportunities}
                opportunityStatus={OpportunityActiveState.COMPLETE}
                icon={<IconCheck size={24} />}
                color="green.4"
              />
              <Divider orientation="vertical" />
              <OpportunityStatusDisplay
                opportunity={unknownOpportunities}
                opportunityStatus={OpportunityActiveState.UNKNOWN}
                icon={<IconQuestionMark size={24} />}
                color="gray"
              />
            </Group>
          </Card>
          <Space h="sm" />
          <Table highlightOnHover withTableBorder>
            <Table.Thead>
              <Table.Tr>
                <Table.Th>Opportunity State</Table.Th>
                <Table.Th>Opportunity ID</Table.Th>
                <Table.Th>Ad Group Name</Table.Th>
                <Table.Th>Campaign Name</Table.Th>
                <Table.Th>Campaign ID</Table.Th>
                <Table.Th>CPM</Table.Th>
                <Table.Th>
                  <Space w="md" />
                </Table.Th>
                <Table.Th>
                  <Space w="md" />
                </Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {opportunities.map((opp) => (
                <OpportunityRow
                  key={opp.id}
                  opportunity={opp}
                  setOpportunities={setOpportunities}
                  campaignStatusMap={campaignStatusMap}
                />
              ))}
            </Table.Tbody>
          </Table>
        </Stack>
      )}
    </Card>
  );
};

export default CreatorOpportunityDebugView;
