// Takes in a number and returns it abbreviated metric format (eg 1,000,000 => 1M)
export const getAbbreviatedNumber = (input: number, decimals = 1) => {
  if (typeof input === "undefined" || input === null || Number.isNaN(input)) {
    return `N/A`;
  }
  if (input < 10 ** 3) return `${Number.isInteger(input) ? input : input.toFixed(decimals)}`;
  const numToLetter = [
    { num: 10 ** 3, letter: "K" },
    { num: 10 ** 6, letter: "M" },
    { num: 10 ** 9, letter: "B" },
    { num: 10 ** 12, letter: "T" },
  ];
  const matchingRule = numToLetter.find((rule) => input >= rule.num && input < rule.num * 10 ** 3);
  if (matchingRule) {
    return `${Math.round((input * 10) / matchingRule.num) / 10}${matchingRule.letter}`;
  }
  return `${input}Z`; // Z is for zillion. Because it's a ridiculous number that's being input
};

export function formatCurrencyAmount(amountInMinorUnits: number) {
  return (amountInMinorUnits / 100).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
}

// returns a string with a % at the end.
export const getPercentage = (num: number) => `${Math.round(num * 1000) / 10}%`;

// takes in a string number and returns local specific string representation of a string or the original
// string if the input can't be converted into a number.
export const toLocaleString = (num: string) => {
  const value = parseInt(num.replace(/,/g, ""), 10);
  if (Number.isNaN(value)) {
    return num;
  }
  return value.toLocaleString();
};

export default null;
