import React from "react";
import { Container, Flex } from "@mantine/core";
import Header from "pages/home/v2/Header";
import Logos from "pages/home/v2/Logos";
import ProductOverview from "pages/home/v2/ProductOverview";
import ManagersAndAgencies from "pages/home/v2/ManagersAndAgencies";
import HowItWorks from "pages/home/v2/HowItWorks";
import Testimonials from "pages/home/v2/Testimonials";
import JoinNowCTA from "pages/home/v2/JoinNowCTA";
import MediaKitCTA from "pages/home/v2/MediaKitCTA";
import FAQ from "pages/home/v2/FAQ";

const NewHomePage = () => (
  <Flex
    direction="column"
    p={0}
    style={{
      background: "#F8F7F4",
      width: "calc(100% + 32px)", // App has 16px right left padding by default
      marginLeft: -16,
      marginBottom: -16,
    }}>
    <Header />
    <Logos />
    <ProductOverview />
    <Testimonials />
    <ManagersAndAgencies />
    <HowItWorks />
    <JoinNowCTA />
    <MediaKitCTA />
    <FAQ />
  </Flex>
);

export default NewHomePage;
