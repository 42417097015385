import { createSelector } from "@reduxjs/toolkit";
import { CampaignStatus } from "models/Campaign";
import { RootState } from "reduxStore/store";

const selectAllCampaigns = (state: RootState) => state.campaigns.allCampaigns;
const selectMyBrandId = (state: RootState) => state.me.brandId;

export const selectMyLaunchedCampaigns = createSelector(
  [selectAllCampaigns, selectMyBrandId],
  (campaigns, brandId) => {
    return Object.entries(campaigns).filter(([, campaign]) => {
      if (brandId) {
        return campaign.status === CampaignStatus.Active && campaign.brand?.id === brandId;
      }
      return false;
    });
  },
);

export default null;
