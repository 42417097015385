import { YoutubeProfileResponse } from "social/google/YoutubeUtils";

export interface UnclaimedProfileInfo {
  display_name: string;
  username: string;
  avatar_url: string;
}

export const getUnclaimedProfileInfo = (youtubeProfile: YoutubeProfileResponse) => {
  if (youtubeProfile) {
    const customUrlNoAt = youtubeProfile.custom_url?.replace("@", "");
    return {
      display_name: youtubeProfile.title,
      username: customUrlNoAt,
      avatar_url: youtubeProfile.avatar_url,
    };
  }
  return {
    display_name: "",
    username: "",
    avatar_url: "",
  };
};
