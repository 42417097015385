import React, { useEffect, useState } from "react";

import { ActionIcon, Button, Flex, Stack, Table, Text, Title, Tooltip } from "@mantine/core";

import { useDisclosure } from "@mantine/hooks";

import { IconDownload, IconExternalLink } from "@tabler/icons-react";

import { getFormattedCurrency } from "components/contracts/dashboard/Utils";
import { Payment } from "components/contracts/models/Payment";
import { fromISODateString, toISODateString } from "utils/DateUtils";
import { GMVData } from "components/contracts/models/GMVData";
import ViewPaymentsModal from "components/contracts/dashboard/ViewPaymentsModal";

export interface MonthAccountingData {
  pendingSignature: number;
  inProgress: number;
  inProgressOverdue: number;
  realizedTake: number;
  complete: number;
  total: number;
  paid: number;
  paidThroughStripe: number;
  paidManually: number;
  paidOffPlatform: number;
  payments: Payment[];
}

function formatPercentage(value: number, decimalPlaces = 2): string {
  const percentage = (value * 100).toFixed(decimalPlaces);
  return `${percentage}%`;
}

function getMonthString(monthNumber: number): string | undefined {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Check if the monthNumber is within a valid range (1 to 12)
  if (monthNumber >= 1 && monthNumber <= 12) {
    return monthNames[monthNumber - 1]; // Subtract 1 because arrays are 0-indexed
  }
  return undefined; // Invalid month number
}

function generatePaymentsCSV({ payments }: { payments: Payment[] }) {
  const sortedPayments = payments.sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime());

  const csvRows = [];
  const headers = Object.keys(sortedPayments[0]);
  csvRows.push(headers.join(","));

  // Convert each payment object to a CSV row
  sortedPayments.forEach((payment) => {
    const row = headers.map((header) => {
      const property = payment[header as keyof Payment];
      let value = property;

      if (property instanceof Date) {
        // Format the Date object to a string
        value = toISODateString(property);
      } else if (typeof property === "string") {
        // Escape double quotes and wrap the value in double quotes
        value = `"${property.replace(/"/g, '""')}"`;
      }

      return value;
    });

    csvRows.push(row.join(","));
  });

  // Combine all rows into a single CSV string
  return csvRows.join("\n");
}

function toSnakeCase(str: string) {
  return str
    .replace(/\s+/g, "_") // Replace spaces with _
    .replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`) // Convert camelCase to snake_case
    .toLowerCase() // Convert to lowercase
    .replace(/[^a-z0-9_]/gi, "_") // Replace any non-alphanumeric character (except underscore) with _
    .replace(/^_|_$/g, ""); // Remove leading and trailing underscores
}

function downloadPaymentsCSV({
  payments,
  campaignName,
  monthString,
}: {
  payments: Payment[];
  campaignName: string;
  monthString: string;
}) {
  const csvString = generatePaymentsCSV({ payments });
  const blob = new Blob([csvString], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");

  a.href = url;
  a.download = toSnakeCase(`${campaignName}${monthString}_payments`);
  a.click();
  a.remove();
  window.URL.revokeObjectURL(url);
}

function AccountingRow({
  campaignName,
  month,
  year,
  accountingData,
  showBrandNameInPayment = false,
}: {
  campaignName: string;
  month: number;
  year: number;
  accountingData: MonthAccountingData;
  showBrandNameInPayment?: boolean;
}) {
  const {
    pendingSignature,
    inProgress,
    inProgressOverdue,
    complete,
    total,
    paid,
    paidThroughStripe,
    realizedTake,
    payments,
  } = accountingData;
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();

  const [opened, { open, close }] = useDisclosure(false);

  const monthString = `${getMonthString(month)} ${year}`;

  return (
    <Table.Tr
      key={`${campaignName}-${month}-${year}-table`}
      bg={month === currentMonth && year === currentYear ? "var(--mantine-color-blue-0)" : ""}>
      <Table.Td>
        <Text size="sm" fw="600">
          {monthString}
        </Text>
      </Table.Td>
      <Table.Td>{getFormattedCurrency(pendingSignature)}</Table.Td>
      <Table.Td>{getFormattedCurrency(inProgress)}</Table.Td>
      <Table.Td>{getFormattedCurrency(inProgressOverdue)}</Table.Td>
      <Table.Td>{getFormattedCurrency(complete)}</Table.Td>
      <Table.Td>{getFormattedCurrency(total)}</Table.Td>
      <Table.Td>{getFormattedCurrency(paid)}</Table.Td>
      <Table.Td>{formatPercentage(paid === 0 ? 0 : paidThroughStripe / paid)}</Table.Td>
      <Table.Td>{getFormattedCurrency(realizedTake)}</Table.Td>
      <Table.Td>
        <ViewPaymentsModal
          payments={payments}
          opened={opened}
          close={close}
          campaignName={campaignName}
          showBrandName={showBrandNameInPayment}
        />
        <Button
          leftSection={<IconExternalLink size="0.7rem" />}
          size="compact-sm"
          variant="transparent"
          onClick={open}
          disabled={payments.length === 0}>
          View {payments.length} Payment{payments.length === 1 ? "" : "s"}
        </Button>
      </Table.Td>
      <Table.Td>
        <Flex justify="center">
          <Tooltip label={`Download ${monthString} Payments`}>
            <ActionIcon
              disabled={payments.length === 0}
              variant="subtle"
              color="gray"
              onClick={() => downloadPaymentsCSV({ payments, campaignName, monthString })}>
              <IconDownload size="1rem" />
            </ActionIcon>
          </Tooltip>
        </Flex>
      </Table.Td>
    </Table.Tr>
  );
}

export function initializeYearMonth({
  yearMonth,
  monthlyAccountingData,
}: {
  yearMonth: string;
  monthlyAccountingData: Record<string, MonthAccountingData>;
}) {
  if (!monthlyAccountingData[yearMonth]) {
    const updatedMonthlyAccountingData = { ...monthlyAccountingData };

    updatedMonthlyAccountingData[yearMonth] = {
      pendingSignature: 0,
      inProgress: 0,
      inProgressOverdue: 0,
      complete: 0,
      total: 0,
      paid: 0,
      paidThroughStripe: 0,
      realizedTake: 0,
      paidManually: 0,
      paidOffPlatform: 0,
      payments: [],
    } as MonthAccountingData;

    return updatedMonthlyAccountingData;
  }

  return monthlyAccountingData;
}

export function getUTCYearMonth(date: Date) {
  const month = date.getUTCMonth() + 1;
  const year = date.getUTCFullYear();
  return `${year}-${month < 10 ? `0${month}` : month}`;
}

export default function BudgetSummary({
  gmvData,
  campaignName,
  showBrandNameInPayment = false,
}: {
  gmvData: GMVData;
  campaignName: string;
  showBrandNameInPayment?: boolean;
}) {
  if (!gmvData) {
    return null;
  }

  const [aggregateAccountingData, setAggregateAccountingData] = useState<
    Record<string, MonthAccountingData>
  >({});

  useEffect(() => {
    let monthlyAccountingData = {} as Record<string, MonthAccountingData>;

    // Contracts Pending Signature
    if (gmvData.pendingSignatureEntries) {
      gmvData.pendingSignatureEntries.forEach((entry) => {
        const yearMonth = getUTCYearMonth(fromISODateString(entry[1]));
        monthlyAccountingData = initializeYearMonth({ yearMonth, monthlyAccountingData });

        monthlyAccountingData[yearMonth].pendingSignature += entry[0];
        monthlyAccountingData[yearMonth].total += entry[0];
      });
    }

    // Contracts In Progress
    if (gmvData.inProgressEntries) {
      gmvData.inProgressEntries.forEach((entry) => {
        const yearMonth = getUTCYearMonth(fromISODateString(entry[1]));
        monthlyAccountingData = initializeYearMonth({ yearMonth, monthlyAccountingData });

        monthlyAccountingData[yearMonth].inProgress += entry[0];
        monthlyAccountingData[yearMonth].total += entry[0];
      });
    }

    // Contracts In Progress Overdue
    if (gmvData.inProgressOverdueEntries) {
      gmvData.inProgressOverdueEntries.forEach((entry) => {
        const yearMonth = getUTCYearMonth(fromISODateString(entry[1]));
        monthlyAccountingData = initializeYearMonth({ yearMonth, monthlyAccountingData });

        monthlyAccountingData[yearMonth].inProgressOverdue += entry[0];
        monthlyAccountingData[yearMonth].total += entry[0];
      });
    }

    // Contract Completed
    if (gmvData.completeEntries) {
      gmvData.completeEntries.forEach((entry) => {
        const yearMonth = getUTCYearMonth(fromISODateString(entry[1]));
        monthlyAccountingData = initializeYearMonth({ yearMonth, monthlyAccountingData });

        monthlyAccountingData[yearMonth].complete += entry[0];
        monthlyAccountingData[yearMonth].total += entry[0];
      });
    }

    // Realized Take Rate
    if (gmvData.realizedTakeEntries) {
      gmvData.realizedTakeEntries.forEach((entry) => {
        const yearMonth = getUTCYearMonth(fromISODateString(entry[1]));
        monthlyAccountingData = initializeYearMonth({ yearMonth, monthlyAccountingData });

        monthlyAccountingData[yearMonth].realizedTake += entry[0];
      });
    }

    // Automatic Payments
    if (gmvData.automaticPayments) {
      gmvData.automaticPayments.forEach((payment) => {
        const yearMonth = toISODateString(payment.createdAt).substring(0, 7);
        monthlyAccountingData = initializeYearMonth({ yearMonth, monthlyAccountingData });

        monthlyAccountingData[yearMonth].paid += payment.amount;
        monthlyAccountingData[yearMonth].paidThroughStripe += payment.amount;
        monthlyAccountingData[yearMonth].payments.push(payment);
      });
    }

    // Manual Payments
    if (gmvData.manualPayments) {
      gmvData.manualPayments.forEach((payment) => {
        const yearMonth = toISODateString(payment.createdAt).substring(0, 7);
        monthlyAccountingData = initializeYearMonth({ yearMonth, monthlyAccountingData });

        monthlyAccountingData[yearMonth].paid += payment.amount;
        monthlyAccountingData[yearMonth].paidManually += payment.amount;
        monthlyAccountingData[yearMonth].payments.push(payment);
      });
    }

    // Off Platform Payments
    if (gmvData.offPlatformPayments) {
      gmvData.offPlatformPayments.forEach((payment) => {
        const yearMonth = toISODateString(payment.createdAt).substring(0, 7);
        monthlyAccountingData = initializeYearMonth({ yearMonth, monthlyAccountingData });

        monthlyAccountingData[yearMonth].paid += payment.amount;
        monthlyAccountingData[yearMonth].paidOffPlatform += payment.amount;
        monthlyAccountingData[yearMonth].payments.push(payment);
      });
    }

    setAggregateAccountingData(monthlyAccountingData);
  }, [gmvData]);

  const rows = Object.entries(aggregateAccountingData)
    .sort((a, b) => b[0].localeCompare(a[0]))
    .map(([yearMonthString, accountingData]) => {
      const [year, month] = yearMonthString.split("-");
      return (
        <AccountingRow
          key={`${campaignName}-${yearMonthString}`}
          campaignName={campaignName}
          month={parseInt(month, 10)}
          year={parseInt(year, 10)}
          accountingData={accountingData}
          showBrandNameInPayment={showBrandNameInPayment}
        />
      );
    });

  return (
    <Stack>
      <Title order={4} fw="500">
        {campaignName} Spend
      </Title>
      <Table withTableBorder highlightOnHover withColumnBorders>
        <Table.Thead>
          <Table.Tr>
            <Table.Th />
            <Table.Th>Pending Signature</Table.Th>
            <Table.Th>In Progress</Table.Th>
            <Table.Th>Overdue</Table.Th>
            <Table.Th>Complete</Table.Th>
            <Table.Th>Total</Table.Th>
            <Table.Th>Paid</Table.Th>
            <Table.Th>Paid via Stripe</Table.Th>
            <Table.Th>Realized Take</Table.Th>
            <Table.Th />
            <Table.Th />
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
      </Table>
    </Stack>
  );
}
