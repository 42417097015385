import { ActionIcon, Tooltip } from "@mantine/core";
import React from "react";
import { Icon, IconProps } from "@tabler/icons-react";

const ShowHideNavBarIcon = ({
  IconName,
  onIconClick,
  tooltipText,
}: {
  IconName: React.ForwardRefExoticComponent<Omit<IconProps, "ref"> & React.RefAttributes<Icon>>;
  onIconClick: () => void;
  tooltipText: string;
}) => (
  <Tooltip label={tooltipText}>
    <ActionIcon variant="transparent" size={32} onClick={onIconClick}>
      <IconName size={24} color="var(--mantine-color-gray-6)" />
    </ActionIcon>
  </Tooltip>
);

export default ShowHideNavBarIcon;
