import React, { useEffect, useState } from "react";
import { User } from "firebase/auth";
import { getOpsUser } from "ops_team/OpsLayout";

import { Container, LoadingOverlay, MultiSelect, Stack, Table, Paper, Text } from "@mantine/core";

import { fetchOpsProgressData } from "components/campaign/CampaignUtils";
import { getAbbreviatedNumber } from "utils/AnalyticsUtils";

interface OpsLabelingStats {
  ops_email: string;
  brand: string;
  campaigns: string;
  creator_set_name: string;
  creator_set_id: number;
  num_ops_labelled: number;
  num_ops_accept: number;
  num_ops_brand_accept: number;
  num_ops_brand_reject: number;
  num_internal_accept: number;
  num_internal_reject: number;
  internal_approve_rate: number;
}

interface OpsProgressResponse {
  success: boolean;
  thisWeekOpsProgress: OpsLabelingStats[];
  lastWeekOpsProgress: OpsLabelingStats[];
}

const OpsProgressStatsDisplay = ({
  thisWeekEntries,
  lastWeekEntries,
}: {
  thisWeekEntries: OpsLabelingStats[];
  lastWeekEntries: OpsLabelingStats[];
}) => (
  <Paper style={{ overflowX: "auto" }}>
    <Table striped highlightOnHover withTableBorder withColumnBorders>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Operations Email</Table.Th>
          <Table.Th>Brand</Table.Th>
          <Table.Th>Campaigns Applicable</Table.Th>
          <Table.Th>Creator Set Name</Table.Th>
          <Table.Th># Ops Accepted</Table.Th>
          <Table.Th># Ops Rejected</Table.Th>
          <Table.Th># Internal Accept</Table.Th>
          <Table.Th># Internal Reject</Table.Th>
          <Table.Th>Internal Approval Rate</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {thisWeekEntries.map((entry, index) => {
          const lastWeekEntry = lastWeekEntries[index];
          const getColor = (thisWeekStat: number, lastWeekStat: number) => {
            if (thisWeekStat === lastWeekStat) {
              return "gray";
            }
            return thisWeekStat >= lastWeekStat ? "#2F9E44" : "red";
          };
          const lastWeekOpsRejected = Math.max(
            lastWeekEntry.num_ops_labelled -
              lastWeekEntry.num_ops_accept +
              lastWeekEntry.num_ops_brand_reject,
            0,
          );
          const opsRejected = Math.max(
            entry.num_ops_labelled - entry.num_ops_accept + entry.num_ops_brand_reject,
            0,
          );
          const safeInternalApproveRate = getAbbreviatedNumber(entry.internal_approve_rate, 2);
          const safeLastWeekInternalApproveRate = getAbbreviatedNumber(
            lastWeekEntry.internal_approve_rate,
            2,
          );

          return (
            <Table.Tr key={`${entry.ops_email}-${entry.creator_set_id}`}>
              <Table.Td>{entry.ops_email}</Table.Td>
              <Table.Td>{entry.brand}</Table.Td>
              <Table.Td>{entry.campaigns}</Table.Td>
              <Table.Td>{entry.creator_set_name}</Table.Td>
              <Table.Td
                style={{ color: getColor(entry.num_ops_accept, lastWeekEntry.num_ops_accept) }}>
                {entry.num_ops_accept} ({lastWeekEntry.num_ops_accept})
              </Table.Td>
              <Table.Td style={{ color: getColor(opsRejected, lastWeekOpsRejected) }}>
                {opsRejected} ({lastWeekOpsRejected})
              </Table.Td>
              <Table.Td
                style={{
                  color: getColor(entry.num_internal_accept, lastWeekEntry.num_internal_accept),
                }}>
                {entry.num_internal_accept} ({lastWeekEntry.num_internal_accept})
              </Table.Td>
              <Table.Td
                style={{
                  color: getColor(entry.num_internal_reject, lastWeekEntry.num_internal_reject),
                }}>
                {entry.num_internal_reject} ({lastWeekEntry.num_internal_reject})
              </Table.Td>
              <Table.Td
                style={{
                  color: getColor(entry.internal_approve_rate, lastWeekEntry.internal_approve_rate),
                }}>
                {safeInternalApproveRate} ({safeLastWeekInternalApproveRate})
              </Table.Td>
            </Table.Tr>
          );
        })}
      </Table.Tbody>
    </Table>
  </Paper>
);

export default function OpsInternalProgress({ user = getOpsUser() }: { user?: User }) {
  const [isLoading, setIsLoading] = useState(false);
  const [thisWeekOpsEntries, setThisWeekOpsEntries] = useState<OpsLabelingStats[]>([]);
  const [lastWeekOpsEntries, setLastWeekOpsEntries] = useState<OpsLabelingStats[]>([]);

  useEffect(() => {
    setIsLoading(true);
    fetchOpsProgressData(user)
      .then((response: OpsProgressResponse) => {
        const { success, thisWeekOpsProgress, lastWeekOpsProgress } = response;
        if (success) {
          setThisWeekOpsEntries(thisWeekOpsProgress);
          setLastWeekOpsEntries(lastWeekOpsProgress);
        }
      })
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <>
      <LoadingOverlay visible={isLoading} />
      {!isLoading && (
        <Container fluid>
          <Stack>
            <OpsProgressStatsDisplay
              thisWeekEntries={thisWeekOpsEntries}
              lastWeekEntries={lastWeekOpsEntries}
            />
          </Stack>
        </Container>
      )}
    </>
  );
}
