import LoadingScreen from "components/LoadingScreen";
import { Masonry, Image, Video, Flex, Text } from "gestalt";
import "gestalt/dist/gestalt.css";
import React, { MutableRefObject, useEffect, useRef, useState } from "react";

import {
  getInstagramMedia,
  InstagramMediaResponse,
  refreshInstagramMedia,
  InstagramMediaType,
} from "social/fb/InstagramUtils";
import { MediaStats } from "pages/profile/InstagramMedia";
import Spacer from "components/Spacer";

enum LoadingState {
  LOADING = 1,
  SUCCESS = 2,
  ERROR = 3,
  RELOADING = 4, // this happens we need to reload data
  EMPTY = 5, // no results returned
}

interface SetMedia {
  (media: InstagramMediaResponse[]): void;
}

interface SetLoadingState {
  (state: LoadingState): void;
}

const fetchAndSetMedia = async (
  username: string,
  setMedia: SetMedia,
  setLoading: SetLoadingState,
  platform?: string,
  format?: number,
  showSponsored?: boolean,
  useDiscover?: boolean,
  useApi?: boolean,
  oembed?: boolean,
  orderBy?: string,
) => {
  // TODO: figure out how to page results
  const results = await getInstagramMedia(
    username,
    platform,
    format,
    showSponsored,
    useDiscover,
    useApi,
    oembed,
    orderBy,
  );
  setMedia(results);
  if (results.length > 0) {
    // TODO (leon): change this so that success is only loaded once all the media
    // has successfully loaded / or given an error?
    setLoading(LoadingState.SUCCESS);
  } else {
    setLoading(LoadingState.EMPTY);
  }
  return results;
};

// this is primarily needed to refresh the media_url field. Facebook's CDN
// links refresh very frequently and when these CDN links have expired,
// we make a call to our server to refresh the links from Facebook's API
// and refetch a new set of links. this is more or less a janky for of caching.
const refreshAndLoad = async (
  username: string,
  setMedia: SetMedia,
  setLoading: SetLoadingState,
  platform?: string,
  format?: number,
  showSponsored?: boolean,
  useDiscover?: boolean,
  useApi?: boolean,
  oembed?: boolean,
  orderBy?: string,
) => {
  await refreshInstagramMedia(username);
  fetchAndSetMedia(
    username,
    setMedia,
    setLoading,
    platform,
    format,
    showSponsored,
    useDiscover,
    useApi,
    oembed,
    orderBy,
  );
};

// run the very first time we attempt to fetch data. will run a refresh
// if the resulting media array is empty.
const initialFetchAndSetMedia = async (
  username: string,
  setMedia: SetMedia,
  setLoading: SetLoadingState,
  hasReloaded: MutableRefObject<boolean>,
  platform?: string,
  format?: number,
  showSponsored?: boolean,
  useDiscover?: boolean,
  useApi?: boolean,
  oembed?: boolean,
  orderBy?: string,
) => {
  setLoading(LoadingState.LOADING);
  const media = await fetchAndSetMedia(
    username,
    setMedia,
    setLoading,
    platform,
    format,
    showSponsored,
    useDiscover,
    useApi,
    oembed,
    orderBy,
  );
  // if we fetch an empty result with no filtering then this might be the first time we fetch this profile
  // and we do an initial fetch of data
  if (media.length === 0 && !platform && !format && !showSponsored) {
    setLoading(LoadingState.RELOADING);
    // eslint-disable-next-line no-param-reassign
    hasReloaded.current = true; // modifying ref
    await refreshInstagramMedia(username);
    fetchAndSetMedia(
      username,
      setMedia,
      setLoading,
      platform,
      format,
      showSponsored,
      useDiscover,
      useApi,
      oembed,
      orderBy,
    );
  }
};

const ShowItem = ({
  data,
}: {
  data: {
    media: InstagramMediaResponse;
    setLoading: SetLoadingState;
    hasReloaded: MutableRefObject<boolean>;
    setMedia: SetMedia;
    username: string;
    platform?: string;
    format?: number;
    showSponsored?: boolean;
    useDiscover?: boolean;
    useApi?: boolean;
    oembed?: boolean;
    orderBy?: string;
  };
}) => {
  const [playing, setPlaying] = useState(false);

  const onError = () => {
    if (data.hasReloaded.current) {
      return; // TODO: figure out how to properly display error
      // data.setLoading(LoadingState.ERROR)
    }
    // eslint-disable-next-line no-param-reassign
    data.hasReloaded.current = true; // set to true to prevent other items from issuing a reload
    data.setLoading(LoadingState.RELOADING);
    refreshAndLoad(
      data.username,
      data.setMedia,
      data.setLoading,
      data.platform,
      data.format,
      data.showSponsored,
      data.useDiscover,
      data.useApi,
      data.oembed,
      data.orderBy,
    );
  };

  const onReady = () => {};

  const media =
    data.media.media_type === InstagramMediaType.VIDEO ? (
      <Video
        accessibilityMaximizeLabel="Maximize"
        accessibilityMinimizeLabel="Minimize"
        accessibilityMuteLabel="Mute"
        accessibilityPauseLabel="Pause"
        accessibilityPlayLabel="Play"
        accessibilityUnmuteLabel="Unmute"
        aspectRatio={540 / 960}
        controls
        captions=""
        onPlay={() => setPlaying(true)}
        // onPlay={() => setPlaying(true)}
        // onControlsPause={() => setPlaying(false)}
        onReady={onReady}
        onEnded={() => setPlaying(false)}
        onError={onError}
        playing={playing}
        loop
        src={data.media.media_url}
      />
    ) : (
      // else return an image
      <Image
        color="transparent"
        alt={data.media.permalink}
        naturalHeight={400}
        naturalWidth={300}
        src={data.media.media_url}
        onLoad={onReady}
        onError={onError}
      />
    );
  return (
    <Flex direction="column">
      {media}
      {/* {data.media.oembed_html} */}
      <MediaStats mediaResponse={data.media} />
    </Flex>
  );
};

const GridView = ({
  username,
  platform,
  format,
  showSponsored,
  useDiscover,
  useApi,
  oembed,
  orderBy,
}: {
  username: string;
  platform?: string;
  format?: number;
  showSponsored?: boolean;
  useDiscover?: boolean;
  useApi?: boolean;
  oembed?: boolean;
  orderBy?: string;
}) => {
  const [media, setMedia] = useState([]);
  const [loadingState, setLoadingState] = useState(LoadingState.LOADING);
  const hasReloaded = useRef(false); // used to keep track of whether we have attempted to reload FB data.

  useEffect(() => {
    initialFetchAndSetMedia(
      username,
      setMedia,
      setLoadingState,
      hasReloaded,
      platform,
      format,
      showSponsored,
      useDiscover,
      useApi,
      oembed,
      orderBy,
    );
  }, [username, platform, format, showSponsored, orderBy]);

  if (loadingState in [LoadingState.LOADING, LoadingState.RELOADING]) {
    return <LoadingScreen />;
  }

  if (loadingState === LoadingState.EMPTY)
    return (
      <>
        <Spacer height={50} />
        <Flex justifyContent="center">
          <Text>No results found</Text>
        </Flex>
      </>
    );

  const dataItems = media.map((mediaItem) => ({
    media: mediaItem,
    setLoading: setLoadingState,
    hasReloaded,
    setMedia,
    username,
    platform,
    format,
    showSponsored,
    orderBy,
  }));

  return <Masonry Item={ShowItem} items={dataItems} layout="basicCentered" />;
};

export default GridView;
