import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import {
  Stack,
  Box,
  Container,
  Loader,
  Center,
  Button,
  Flex,
  Modal,
  Group,
  Text,
} from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import { useDisclosure } from "@mantine/hooks";

import "gestalt/dist/gestalt.css";
import {
  CreatorDetails,
  CreatorSetEntryWithDetail,
  CreatorSetEntryItem,
  CreatorSetEntryState,
} from "components/discovery/Datamodels";
import { CreatorSetStateIndex } from "components/creator_sets/CreatorSetUtils";
import UnifiedCreatorRep from "components/creator_lists/unified_creator_rep/UnifiedCreatorRep";
import { useCampaignAppContext } from "campaigns/CampaignAppShell";
import CreatorSetBanner from "components/creator_sets/CreatorSetsBanner";
import { CreatorSetReviewActionRows } from "components/creator_lists/unified_creator_rep/ActionRows";
import { CreatorSetTodoList } from "campaigns/main/campaignPage/CreatorSetTodoList";
import Spacer from "components/Spacer";
import {
  useCreatorSetPaginate,
  useCreatorSetStateSelector,
} from "components/creator_sets/CreatorSetCommons";
import { useUser } from "utils/UserContext";
import { useAppDispatch, useAppSelector } from "reduxStore/hooks";
import {
  getAllCreatorSetReviewEntries,
  getEntriesForCreatorSet,
  updateEntriesForCreatorSetId,
} from "reduxStore/creatorSetsSlice";
import { selectAllEntryItems, selectEntriesByCreatorSetId } from "reduxStore/creatorSetsSelectors";

import { activateMultipleCreators } from "components/creator_sets/CreatorSetUtils";
import { showFailureNotification, showSuccessNotification } from "components/common/Notifications";

const CreatorSetEntryResults = ({
  creatorSetId,
  creatorEntriesWithDetails,
  creatorSetEntries,
  selectedPlatforms,
}: {
  creatorSetId?: number;
  creatorEntriesWithDetails: CreatorSetEntryWithDetail[];
  creatorSetEntries: CreatorSetEntryItem[];
  selectedPlatforms: string[];
}) => {
  const dispatch = useAppDispatch();
  const creatorSetMap = useAppSelector((state) => state.creatorSets.creatorSetItems);
  const [currentUser] = useUser();
  const isStaff = currentUser?.is_staff ?? false;

  if (!creatorEntriesWithDetails) {
    return null;
  }
  return (
    <Box>
      {creatorEntriesWithDetails.map((entryWithDetail, index) => {
        const entry = entryWithDetail.creator_set_entry;
        const actionRow = (
          <CreatorSetReviewActionRows
            isStaff={isStaff}
            creatorSetId={entry.creator_set_id}
            creatorId={entry.creator_id}
            initialNotes={entry.notes}
            onCreatorStateChanged={(creatorId, entryState, notes) => {
              const matchingEntry = creatorSetEntries.find((e) => e.creator_id === creatorId);
              const selectedEntry = structuredClone(matchingEntry);
              selectedEntry.state = entryState;
              selectedEntry.notes = notes;
              // selectedEntry.worked_with = workedWith;

              const entryUpdateIndex = creatorSetEntries.findIndex(
                (item) => item.creator_id === creatorId,
              );
              const updatedCreatorSetEntries = [...creatorSetEntries];
              if (entryUpdateIndex !== -1) {
                updatedCreatorSetEntries[entryUpdateIndex] = {
                  ...updatedCreatorSetEntries[entryUpdateIndex],
                  ...selectedEntry,
                };
              }
              dispatch(
                updateEntriesForCreatorSetId({ creatorSetId, entries: updatedCreatorSetEntries }),
              );
            }}
          />
        );
        return (
          <Box key={`entry-${entry.creator_id}`}>
            <div style={{ height: 1, width: "100%", background: "lightgray" }} />
            {index > 0 ? <Spacer height={12} /> : null}
            <UnifiedCreatorRep
              creator={entryWithDetail.creator_details}
              key={`entry-${entry.creator_id}`}
              defaultExpandedPlatforms={selectedPlatforms}
              actionRow={actionRow}
              archetype={creatorSetMap[entry.creator_set_id]?.name}
            />
          </Box>
        );
      })}
    </Box>
  );
};

const CreatorSetBannerPaginateReviewView = ({
  viewableCreatorSetEntryItems,
  creatorSetEntryItems,
  creatorSetId,
  setCreatorSetId,
  creatorSetStateIndex,
  setCreatorSetStateIndex,
  useCreatorSetSelector = false,
}: {
  viewableCreatorSetEntryItems: CreatorSetEntryItem[];
  creatorSetEntryItems: CreatorSetEntryItem[];
  creatorSetId: number;
  setCreatorSetId: (creatorSetId: number) => void;
  creatorSetStateIndex: CreatorSetStateIndex;
  setCreatorSetStateIndex: (creatorSetStateIndex: CreatorSetStateIndex) => void;
  useCreatorSetSelector?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const [opened, { open, close }] = useDisclosure();
  const [creatorSetDetailsMap, setCreatorSetDetailsMap] = useState<Record<number, CreatorDetails>>(
    {},
  );
  const [selectedPlatforms, setSelectedPlatforms] = useState<string[]>([]);
  const { creatorSetPageDetails, EntryPagniator, TopOfEntries } = useCreatorSetPaginate(
    viewableCreatorSetEntryItems,
    creatorSetDetailsMap,
    setCreatorSetDetailsMap,
  );

  const [activateLoading, setActivateLoading] = useState(false);
  const activateAllCreatorsOnPage = () => {
    setActivateLoading(true);
    const creatorIds = creatorSetPageDetails.map((entry) => entry.creator_details.creator_id);
    activateMultipleCreators({
      creatorSetId,
      creatorIds,
    })
      .then((response) => {
        const { success, activatedCreatorIds } = response;
        if (success) {
          const updatedCreatorSetEntries = [...creatorSetEntryItems];
          activatedCreatorIds.forEach((creatorId: number) => {
            const matchingEntry = creatorSetEntryItems.find((e) => e.creator_id === creatorId);
            const selectedEntry = structuredClone(matchingEntry);
            selectedEntry.state = CreatorSetEntryState.ACCEPTED;

            const entryUpdateIndex = creatorSetEntryItems.findIndex(
              (item) => item.creator_id === creatorId,
            );
            if (entryUpdateIndex !== -1) {
              updatedCreatorSetEntries[entryUpdateIndex] = {
                ...updatedCreatorSetEntries[entryUpdateIndex],
                ...selectedEntry,
              };
            }
          });
          dispatch(
            updateEntriesForCreatorSetId({ creatorSetId, entries: updatedCreatorSetEntries }),
          );
          showSuccessNotification({
            message: `Successfully activated ${activatedCreatorIds.length} creator${
              activatedCreatorIds.length === 0 ? "" : "s"
            }!`,
          });
        } else {
          showFailureNotification({ message: "Failed to activate creators" });
        }
      })
      .finally(() => {
        setActivateLoading(false);
        close();
      });
  };

  return (
    <Stack>
      {/*
        Buttons to add: 1 button that duplicates creatorSets
      */}
      <>
        <Modal
          opened={opened}
          onClose={close}
          title={
            <Text size="xl" fw="500">
              Activate Creators
            </Text>
          }
          size="md">
          <Stack>
            <Text>
              Are you sure you want to activate {creatorSetPageDetails.length === 1 ? "the" : "all"}{" "}
              <Text span fw="500">
                {creatorSetPageDetails.length} creator
                {creatorSetPageDetails.length === 1 ? "" : "s"}
              </Text>{" "}
              on this page?
            </Text>
            <Group grow>
              <Button variant="default" onClick={close}>
                Cancel
              </Button>
              <Button onClick={activateAllCreatorsOnPage} loading={activateLoading}>
                Confirm
              </Button>
            </Group>
          </Stack>
        </Modal>
        <TopOfEntries />
        <Flex justify="center" align="flex-end" gap="md">
          <CreatorSetBanner
            creatorSetEntries={creatorSetEntryItems}
            creatorSetId={creatorSetId}
            setCreatorSetId={setCreatorSetId}
            creatorSetStateIndex={creatorSetStateIndex}
            setCreatorSetStateIndex={setCreatorSetStateIndex}
            multiSelectedPlatforms={selectedPlatforms}
            onMultiSelectedPlatformsChange={(platformList) => setSelectedPlatforms(platformList)}
            useCreatorSetSelector={useCreatorSetSelector}
          />
          {creatorSetPageDetails.length > 0 && (
            <Button leftSection={<IconPlus size="1rem" />} loading={activateLoading} onClick={open}>
              Activate {creatorSetPageDetails.length} Creator
              {creatorSetPageDetails.length === 1 ? "" : "s"}
            </Button>
          )}
        </Flex>
        {creatorSetPageDetails &&
        creatorSetPageDetails?.length === 0 &&
        viewableCreatorSetEntryItems.length !== 0 ? (
          <Container fluid h="100vh">
            <Center h="100%">
              <Loader color="blue" />
            </Center>
          </Container>
        ) : (
          <CreatorSetEntryResults
            creatorSetId={creatorSetId}
            creatorEntriesWithDetails={creatorSetPageDetails}
            creatorSetEntries={creatorSetEntryItems}
            selectedPlatforms={selectedPlatforms}
          />
        )}
        <EntryPagniator />
      </>
    </Stack>
  );
};

export const SelectAndReviewCreatorSetView = () => {
  // Like CreatorSetReviewView, but with a dropdown to select which creator sets to review first, then
  // displays the CreatorSetReviewView for that creator set.
  const { toDoSummary } = useCampaignAppContext();
  const [creatorSetId, setCreatorSetId] = useState(-1);
  const dispatch = useAppDispatch();

  const creatorSetEntryItems = useAppSelector((state) => selectAllEntryItems(state));

  const [searchParams, setSearchParams] = useSearchParams();
  const paramsStateIndex = parseInt(searchParams.get("state") || "0", 10);
  const [creatorSetStateIndex, setCreatorSetStateIndex] = useState(
    paramsStateIndex as CreatorSetStateIndex,
  );
  const [bannerCreatorSetEntryItems, setBannerCreatorSetEntryItems] = useState<
    CreatorSetEntryItem[]
  >([]);
  const [viewableCreatorSetEntryItems, setViewableCreatorSetEntryItems] = useState<
    CreatorSetEntryItem[]
  >([]);

  useCreatorSetStateSelector(
    bannerCreatorSetEntryItems,
    creatorSetStateIndex,
    setViewableCreatorSetEntryItems,
  );

  useEffect(() => {
    const entriesAbortController = new AbortController();
    dispatch(getAllCreatorSetReviewEntries(entriesAbortController));

    return () => {
      entriesAbortController.abort();
    };
  }, []);

  useEffect(() => {
    if (creatorSetEntryItems.length > 0) {
      const filteredCreatorSetEntryItems = creatorSetEntryItems.filter(
        (entry) => entry.creator_set_id === creatorSetId || creatorSetId === -1,
      );
      setBannerCreatorSetEntryItems(filteredCreatorSetEntryItems);
    }
  }, [creatorSetId, creatorSetEntryItems]);

  return (
    <Stack>
      <CreatorSetTodoList brandToDoSummary={toDoSummary} />
      <CreatorSetBannerPaginateReviewView
        viewableCreatorSetEntryItems={viewableCreatorSetEntryItems}
        creatorSetEntryItems={bannerCreatorSetEntryItems}
        creatorSetId={creatorSetId}
        setCreatorSetId={setCreatorSetId}
        creatorSetStateIndex={creatorSetStateIndex}
        setCreatorSetStateIndex={setCreatorSetStateIndex}
        useCreatorSetSelector
      />
    </Stack>
  );
};

export const CreatorSetReviewView = () => {
  // only reviews creators for one particular creator set
  const { creatorSetId } = useParams<{ creatorSetId: string }>();

  const dispatch = useAppDispatch();
  // fetch creators for current creator set id
  const creatorSetEntryItems = useAppSelector((state) =>
    selectEntriesByCreatorSetId(state, Number(creatorSetId)),
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const paramsStateIndex = parseInt(searchParams.get("state") || "0", 10);
  const [creatorSetStateIndex, setCreatorSetStateIndex] = useState(
    paramsStateIndex as CreatorSetStateIndex,
  );

  // Filtered to the selected state
  const [viewableCreatorSetEntryItems, setViewableCreatorSetEntryItems] = useState<
    CreatorSetEntryItem[]
  >([]);

  useEffect(() => {
    const abortController = new AbortController();
    dispatch(getEntriesForCreatorSet({ creatorSetId: Number(creatorSetId), abortController }));

    return () => {
      abortController.abort();
    };
  }, [creatorSetId]);

  useCreatorSetStateSelector(
    creatorSetEntryItems,
    creatorSetStateIndex,
    setViewableCreatorSetEntryItems,
  );

  return (
    <CreatorSetBannerPaginateReviewView
      viewableCreatorSetEntryItems={viewableCreatorSetEntryItems}
      creatorSetEntryItems={creatorSetEntryItems}
      creatorSetId={Number(creatorSetId)}
      setCreatorSetId={() => {}}
      creatorSetStateIndex={creatorSetStateIndex}
      setCreatorSetStateIndex={setCreatorSetStateIndex}
      useCreatorSetSelector={false}
    />
  );
};
