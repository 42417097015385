import React, { useEffect, useState } from "react";
import { Button, Group, Paper, Stack, Table, Text, ThemeIcon, Tooltip } from "@mantine/core";
import { User } from "firebase/auth";
import "gestalt/dist/gestalt.css";
import { useOutletContext } from "react-router-dom";

import {
  IconCircleCheck,
  IconCircleDot,
  IconEditCircle,
  IconReload,
  IconRotateClockwise2,
} from "@tabler/icons-react";
import { fetchAdminBrandData, fetchSearchersData } from "admin/AdminUtils";
import { AuthContext } from "auth/AuthContext";
import { LABELING_DATASETS_URL } from "components/creator_lists/LabelingUtils";
import { Platform, SearchSummaryItem } from "components/discovery/Datamodels";
import { BrandCreatorSetPlatformFilterSelector } from "components/search/BrandCreatorSetPlatformFilterSelector";
import { DateSelector, EmailUserSelector } from "components/search/SelectorComponents";
import { handleResult } from "utils/ApiUtils";

type SearchSummaryItemsResponse = {
  summaries: SearchSummaryItem[];
};

const fetchSearchSummaryItems = async (
  requestUser: User,
  brandId: number,
  campaignParameterId: number,
  creatorSetId: number,
  platform: string,
  startDate: Date | null,
  endDate: Date | null,
  searcher: string,
  setSearchSummaryItems: (searchSummaryItems: SearchSummaryItem[]) => void,
) => {
  const firebaseToken = await requestUser.getIdToken();
  const requestData = {
    brandId,
    campaignParameterId,
    creatorSetId,
    platform,
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
    searcher,
  };

  const searchSummaryEndpoint = `${LABELING_DATASETS_URL}get_search_summary/`;
  const request = new Request(searchSummaryEndpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`, // Assuming you are sending the token in the Authorization header
    },
    body: JSON.stringify(requestData),
  });

  const response: SearchSummaryItemsResponse = await handleResult(request);
  setSearchSummaryItems(response.summaries);
};

const SearchSummaryButton = ({
  requestUser,
  brandId,
  campaignParameterId,
  creatorSetId,
  platform,
  startDate,
  endDate,
  searcher,
  setSearchSummaryItems,
}: {
  requestUser: User;
  brandId: number;
  campaignParameterId: number;
  creatorSetId: number;
  platform: string;
  startDate: Date | null;
  endDate: Date | null;
  searcher: string;
  setSearchSummaryItems: (searchSummaryItems: SearchSummaryItem[]) => void;
}) => (
  // TODO: Do not allow unless date ranges are not NULL.
  <Group>
    <Button
      variant="filled"
      disabled={
        brandId === null ||
        creatorSetId === null ||
        platform === null ||
        startDate === null ||
        endDate === null ||
        searcher === null
      }
      onClick={() => {
        fetchSearchSummaryItems(
          requestUser,
          brandId,
          campaignParameterId,
          creatorSetId,
          platform,
          startDate,
          endDate,
          searcher,
          setSearchSummaryItems,
        );
      }}>
      Get searches
    </Button>
  </Group>
);

const CrawlStatusIcon = ({ crawlStatus }: { crawlStatus: string }) => {
  if (crawlStatus === "Crawl complete") {
    return (
      <Tooltip label={crawlStatus}>
        <ThemeIcon variant="light" color="teal" size="sm" radius="xl">
          <IconCircleCheck />
        </ThemeIcon>
      </Tooltip>
    );
  } else if (crawlStatus === "Still crawling") {
    return (
      <Tooltip label={crawlStatus}>
        <ThemeIcon variant="light" color="yellow" size="sm" radius="xl">
          <IconRotateClockwise2 />
        </ThemeIcon>
      </Tooltip>
    );
  }
  return (
    <Tooltip label={crawlStatus}>
      <ThemeIcon variant="light" color="grey" size="sm" radius="xl">
        <IconCircleDot />
      </ThemeIcon>
    </Tooltip>
  );
};

const AdviceIcon = ({
  recommendationType,
  recommendation,
}: {
  recommendationType: string;
  recommendation: string;
}) => {
  if (recommendationType === "Retry") {
    return (
      <Tooltip label={recommendation}>
        <ThemeIcon variant="light" color="yellow" size="sm" radius="xl">
          <IconReload />
        </ThemeIcon>
      </Tooltip>
    );
  } else if (recommendationType === "Edit") {
    return (
      <Tooltip label={recommendation}>
        <ThemeIcon variant="light" color="red" size="sm" radius="xl">
          <IconEditCircle />
        </ThemeIcon>
      </Tooltip>
    );
  }
  return null;
};

const SearchSummaryResults = ({
  searchSummaryItems,
}: {
  searchSummaryItems: SearchSummaryItem[];
}) => (
  <Paper style={{ overflowX: "auto" }}>
    <Table striped highlightOnHover withTableBorder withColumnBorders>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Crawl Status</Table.Th>
          <Table.Th>Date Created</Table.Th>
          <Table.Th>Platform</Table.Th>
          <Table.Th>Campaign Name</Table.Th>
          <Table.Th>Creator Set Name</Table.Th>
          <Table.Th>Job Strategy</Table.Th>
          <Table.Th>Seeds</Table.Th>
          <Table.Th>Initial Expansion</Table.Th>
          <Table.Th>New creators</Table.Th>
          <Table.Th># 1st page searches</Table.Th>
          <Table.Th># searches</Table.Th>
          <Table.Th>Advice</Table.Th>
          <Table.Th>Search Link</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {searchSummaryItems.map((item, index) => (
          <Table.Tr key={`${item.seeds}-${item.platform}-${item.job_strategy}`}>
            <Table.Td>
              <CrawlStatusIcon crawlStatus={item.crawl_status} />
            </Table.Td>
            <Table.Td>{item.date_created}</Table.Td>
            <Table.Td>{item.platform}</Table.Td>
            <Table.Td>{item.campaign_name}</Table.Td>
            <Table.Td>{item.creator_set_name}</Table.Td>
            <Table.Td>{item.job_strategy}</Table.Td>
            <Table.Td>
              <Text size="xs">{item.seeds}</Text>
            </Table.Td>
            <Table.Td>{String(item.initial_expansion_size)}</Table.Td>
            <Table.Td>{String(item.new_creators)}</Table.Td>
            <Table.Td>{String(item.num_first_page_search)}</Table.Td>
            <Table.Td>{String(item.total_searches)}</Table.Td>
            <Table.Td>
              <AdviceIcon
                recommendationType={item.recommendation_type}
                recommendation={item.recommendation}
              />
            </Table.Td>
            <Table.Td>
              <a href={item.search_link} target="_blank" rel="noreferrer">
                Link
              </a>
            </Table.Td>
          </Table.Tr>
        ))}
      </Table.Tbody>
    </Table>
  </Paper>
);

const SearchSummaryView = () => {
  const requestUser: User = useOutletContext<AuthContext>()?.user;
  const [brandData, setBrandData] = useState(null);
  const [emailUsers, setEmailUsers] = useState([]);
  const [brandId, setBrandId] = useState(0);
  const [creatorSetId, setCreatorSetId] = useState(null);
  const [platform, setPlatform] = useState("any" as Platform);
  const [campaignParameterId, setCampaignParameterId] = useState(0);

  const currentDate = new Date();
  const defaultStart = new Date(currentDate.getTime() - 24 * 60 * 60 * 1000 * 14);
  const defaultEnd = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000 * 1);
  const [startDate, setStartDate] = useState(defaultStart);
  const [endDate, setEndDate] = useState(defaultEnd);

  const [emailUser, setEmailUser] = useState(null);
  const [searchSummaryItems, setSearchSummaryItems] = useState<SearchSummaryItem[]>([]);
  const [isUserActionChange, setIsUserActionChange] = useState(false);

  useEffect(() => {
    const abortController = new AbortController();
    fetchAdminBrandData(abortController, true).then((data) => {
      if (data && data.brands) {
        setBrandData(data.brands);
      }
    });

    fetchSearchersData(requestUser).then((data) => {
      setEmailUsers(data.emails);
    });

    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <Stack>
      <Group>
        <DateSelector
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
        <BrandCreatorSetPlatformFilterSelector
          brandData={brandData}
          brandId={brandId}
          creatorSetId={creatorSetId}
          campaignParameterId={campaignParameterId}
          platform={platform}
          setBrandId={setBrandId}
          setCampaignParameterId={setCampaignParameterId}
          setPlatform={setPlatform}
          setIsUserActionChange={setIsUserActionChange}
          setCreatorSetId={setCreatorSetId}
        />
        <EmailUserSelector
          availableUsers={emailUsers}
          emailUser={emailUser}
          setEmailUser={setEmailUser}
        />
      </Group>
      <SearchSummaryButton
        requestUser={requestUser}
        brandId={brandId}
        campaignParameterId={campaignParameterId}
        creatorSetId={creatorSetId}
        platform={platform}
        startDate={startDate}
        endDate={endDate}
        searcher={emailUser}
        setSearchSummaryItems={setSearchSummaryItems}
      />
      <SearchSummaryResults searchSummaryItems={searchSummaryItems} />
    </Stack>
  );
  // Display Table.The search summary request results
};

export default SearchSummaryView;
