import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { brands } from "@fortawesome/fontawesome-svg-core/import.macro";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";

import instagram_icon from "social/fb/assets/simple_bw_instagram_icon.svg";
import youtube_icon from "social/google/assets/youtube_black_white_icon.svg";
import tiktok_icon from "social/tiktok/assets/tiktok_auth_button_icon.svg";
import collab_icon from "components/assets/favicon_bw.svg";
import google_color_icon from "social/google/assets/google_color_icon.svg";

type Props = {
  platform: string;
  size: SizeProp;
};

// needed because FontAwesomeIcon takes in string literals because imported icons need to be defined at compile time
export const BrandIconsFontAwesome = ({ platform, size }: Props) => {
  switch (platform) {
    case "ig":
      return <FontAwesomeIcon icon={brands("instagram")} size={size} />;
    case "tiktok":
      return <FontAwesomeIcon icon={brands("tiktok")} size={size} />;
    case "youtube":
      return <FontAwesomeIcon icon={brands("youtube")} size={size} />;
    case "pinterest":
      return <FontAwesomeIcon icon={brands("pinterest")} size={size} />;
    default:
      // should never reach this. indicator that we need to add support for a platform
      return <FontAwesomeIcon icon={brands("wizards-of-the-coast")} size={size} />;
  }
};

export type Platforms = "instagram" | "youtube" | "tiktok" | "googleColor";
export const PLATFORM_TO_PLATFORM_NAME = {
  tiktok: "TikTok",
  youtube: "YouTube",
  instagram: "Instagram",
  googleColor: "Google",
};
export type Sizes = "xs" | "s" | "m" | "l" | "xl" | "xxl";

interface BrandIconProps {
  platform: Platforms;
  size?: Sizes;
  href?: string;
}

export const BrandIcons = ({ platform, size, href }: BrandIconProps) => {
  // sizes based off of https://gestalt.pinterest.systems/web/icon#Size
  const convertIconSize = () => {
    switch (size) {
      case "xs":
        return 12;
      case "s":
        return 14;
      case "m":
        return 16;
      case "l":
        return 24;
      case "xl":
        return 32;
      case "xxl":
        return 40;
      default:
        return 16;
    }
  };
  const getSvg = () => {
    switch (platform) {
      case "instagram":
        return instagram_icon;
      case "youtube":
        return youtube_icon;
      case "tiktok":
        return tiktok_icon;
      case "googleColor":
        return google_color_icon;
      default:
        return collab_icon;
    }
  };

  const iconSize = size ? convertIconSize() : 16;

  const iconStyle = {
    verticalAlign: "middle",
    height: iconSize,
    width: iconSize,
  };

  const iconSrc = getSvg();

  if (href) {
    return (
      <a href={href} target="_blank" rel="noreferrer noopener">
        <img alt="inst" src={iconSrc} style={iconStyle} />
      </a>
    );
  }
  return <img alt="inst" src={iconSrc} style={iconStyle} />;
};
