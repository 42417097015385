/* eslint-disable */
import * as _m0 from "protobufjs/minimal";

export const protobufPackage = "discovery";

export interface ArchetypeFeedInfo {
  feedId: number;
  feedName: string;
  archetypeId: number;
  archetypeName: string;
  campaignId: number;
  campaignName: string;
  hashtags: string[];
}

export interface ArchetypeFeedInfos {
  feedInfos: ArchetypeFeedInfo[];
}

function createBaseArchetypeFeedInfo(): ArchetypeFeedInfo {
  return { feedId: 0, feedName: "", archetypeId: 0, archetypeName: "", campaignId: 0, campaignName: "", hashtags: [] };
}

export const ArchetypeFeedInfo = {
  encode(message: ArchetypeFeedInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.feedId !== 0) {
      writer.uint32(8).int32(message.feedId);
    }
    if (message.feedName !== "") {
      writer.uint32(18).string(message.feedName);
    }
    if (message.archetypeId !== 0) {
      writer.uint32(24).int32(message.archetypeId);
    }
    if (message.archetypeName !== "") {
      writer.uint32(34).string(message.archetypeName);
    }
    if (message.campaignId !== 0) {
      writer.uint32(40).int32(message.campaignId);
    }
    if (message.campaignName !== "") {
      writer.uint32(50).string(message.campaignName);
    }
    for (const v of message.hashtags) {
      writer.uint32(58).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ArchetypeFeedInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseArchetypeFeedInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.feedId = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.feedName = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.archetypeId = reader.int32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.archetypeName = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.campaignId = reader.int32();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.campaignName = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.hashtags.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ArchetypeFeedInfo {
    return {
      feedId: isSet(object.feedId) ? Number(object.feedId) : 0,
      feedName: isSet(object.feedName) ? String(object.feedName) : "",
      archetypeId: isSet(object.archetypeId) ? Number(object.archetypeId) : 0,
      archetypeName: isSet(object.archetypeName) ? String(object.archetypeName) : "",
      campaignId: isSet(object.campaignId) ? Number(object.campaignId) : 0,
      campaignName: isSet(object.campaignName) ? String(object.campaignName) : "",
      hashtags: Array.isArray(object?.hashtags) ? object.hashtags.map((e: any) => String(e)) : [],
    };
  },

  toJSON(message: ArchetypeFeedInfo): unknown {
    const obj: any = {};
    if (message.feedId !== 0) {
      obj.feedId = Math.round(message.feedId);
    }
    if (message.feedName !== "") {
      obj.feedName = message.feedName;
    }
    if (message.archetypeId !== 0) {
      obj.archetypeId = Math.round(message.archetypeId);
    }
    if (message.archetypeName !== "") {
      obj.archetypeName = message.archetypeName;
    }
    if (message.campaignId !== 0) {
      obj.campaignId = Math.round(message.campaignId);
    }
    if (message.campaignName !== "") {
      obj.campaignName = message.campaignName;
    }
    if (message.hashtags?.length) {
      obj.hashtags = message.hashtags;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ArchetypeFeedInfo>, I>>(base?: I): ArchetypeFeedInfo {
    return ArchetypeFeedInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ArchetypeFeedInfo>, I>>(object: I): ArchetypeFeedInfo {
    const message = createBaseArchetypeFeedInfo();
    message.feedId = object.feedId ?? 0;
    message.feedName = object.feedName ?? "";
    message.archetypeId = object.archetypeId ?? 0;
    message.archetypeName = object.archetypeName ?? "";
    message.campaignId = object.campaignId ?? 0;
    message.campaignName = object.campaignName ?? "";
    message.hashtags = object.hashtags?.map((e) => e) || [];
    return message;
  },
};

function createBaseArchetypeFeedInfos(): ArchetypeFeedInfos {
  return { feedInfos: [] };
}

export const ArchetypeFeedInfos = {
  encode(message: ArchetypeFeedInfos, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.feedInfos) {
      ArchetypeFeedInfo.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ArchetypeFeedInfos {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseArchetypeFeedInfos();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.feedInfos.push(ArchetypeFeedInfo.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ArchetypeFeedInfos {
    return {
      feedInfos: Array.isArray(object?.feedInfos)
        ? object.feedInfos.map((e: any) => ArchetypeFeedInfo.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ArchetypeFeedInfos): unknown {
    const obj: any = {};
    if (message.feedInfos?.length) {
      obj.feedInfos = message.feedInfos.map((e) => ArchetypeFeedInfo.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ArchetypeFeedInfos>, I>>(base?: I): ArchetypeFeedInfos {
    return ArchetypeFeedInfos.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ArchetypeFeedInfos>, I>>(object: I): ArchetypeFeedInfos {
    const message = createBaseArchetypeFeedInfos();
    message.feedInfos = object.feedInfos?.map((e) => ArchetypeFeedInfo.fromPartial(e)) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
