import { Box } from "gestalt";
import "gestalt/dist/gestalt.css";
import React from "react";
import { BarChart, Bar, XAxis, YAxis, LabelList, PieChart, Pie, Cell, Legend } from "recharts";
import { AudienceEntryResponse } from "social/utils";
import Spacer from "components/Spacer";

const barLabel = (props: any, graphWidth: number) => {
  const { x, y, width, height, value } = props;
  const percentage = Math.round(1000 * value) / 10;
  return (
    <g>
      <text
        x={x + graphWidth}
        y={y + height / 2}
        fill="#666"
        textAnchor="start"
        dominantBaseline="middle">
        {`${percentage}%`}
      </text>
    </g>
  );
};

export const AudienceGraph = ({
  audienceSegment,
}: {
  audienceSegment: AudienceEntryResponse[];
}) => {
  const graphWidth = 250;
  return (
    <BarChart
      data={audienceSegment}
      width={graphWidth}
      height={200}
      layout="vertical"
      barCategoryGap={10}
      margin={{ right: 60 }}>
      <XAxis type="number" hide />
      <YAxis dataKey="audience" type="category" />
      {/* need to set isAnimationActive={false} because there is a bug that will
      cause labels to not render otherwise: https://github.com/recharts/recharts/issues/829 */}
      <Bar dataKey="percentage" fill="#8884d8" isAnimationActive={false}>
        <LabelList
          dataKey="percentage"
          position="right"
          content={(props) => barLabel(props, graphWidth - 120)}
        />
      </Bar>
    </BarChart>
  );
};

const pieChartLegend = (key: string, entry: any) => {
  const percentage = Math.round(entry.payload.percentage * 100);
  const percentageText = percentage < 1.0 ? "<1" : percentage.toString();
  return (
    <>
      <span
        style={{
          color: "#111",
          fontWeight: 400,
          display: "inline-block",
          width: 50,
          textAlign: "center",
        }}>{`${percentageText}%`}</span>
      <span style={{ color: "#111", fontWeight: 400 }}>{key}</span>
      <Spacer height={4} />
    </>
  );
};

export const AudiencePieGraph = ({
  audienceSegment,
  width = 360,
}: {
  audienceSegment: AudienceEntryResponse[];
  width?: number;
}) => {
  const legendsWidth =
    width - 124 /* outer radius of pie chart x 2 for diameter */ - 32; /* paddingLeft */
  const COLORS = ["#0074E8", "#FCB0B3", "#F9DC5C", "#C2EABD", "#011936", "#94B4DB"];
  return (
    <PieChart width={width} height={175}>
      <Pie
        data={audienceSegment}
        nameKey="audience"
        dataKey="value"
        innerRadius={50}
        outerRadius={62}>
        {audienceSegment.map((entry, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      <Legend
        layout="vertical"
        iconType="circle"
        width={legendsWidth > width / 2 ? undefined : legendsWidth}
        chartWidth={width}
        iconSize={6}
        align="right"
        verticalAlign="middle"
        formatter={pieChartLegend}
        wrapperStyle={{
          paddingLeft: "32px",
        }}
      />
    </PieChart>
  );
};
