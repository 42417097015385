import React from "react";

import { Select, Tooltip } from "@mantine/core";

import { IconSortDescending } from "@tabler/icons-react";

export enum SortBy {
  RELEVANCE = "relevance",
  FREQUENCY = "frequency",
}

export default function SortSelector({
  sortBy,
  setSortBy,
}: {
  sortBy: SortBy;
  setSortBy: (value: SortBy) => void;
}) {
  return (
    <Tooltip label={`Sort by ${sortBy === SortBy.RELEVANCE ? "Relevance" : "Frequency"}`}>
      <Select
        leftSection={<IconSortDescending color="black" size="1rem" />}
        data={[
          { value: SortBy.RELEVANCE, label: "Relevance" },
          { value: SortBy.FREQUENCY, label: "Frequency" },
        ]}
        value={sortBy}
        onChange={setSortBy}
        styles={{
          input: {
            fontWeight: 600,
          },
        }}
        w={145}
      />
    </Tooltip>
  );
}
