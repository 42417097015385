import React, { useEffect, useRef, useState } from "react";
import { Anchor, Image, Stack, Text, Skeleton } from "@mantine/core";

import { useInViewport } from "@mantine/hooks";

import Spacer from "components/Spacer";
import { getAbbreviatedNumber } from "utils/AnalyticsUtils";
import { MediaEntry } from "components/MediaBlockCard";
import { timeAgo, isNotValidTimeAgo } from "utils/TimeUtils";

import { API_URL } from "configs/Configs";
import { createRequestWithFirebaseToken, handleResult } from "utils/ApiUtils";

const fetchVideoThumbnail = async (
  videoId: string,
  platform: string,
  abortController: AbortController,
) => {
  const requestUrl = new URL(`${API_URL}/api/social/fetch_video_thumbnail`);
  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      videoId,
      platform,
    }),
  });

  const response = await handleResult(request, abortController);
  return response;
};

interface DisplayInfo {
  height?: number;
  width?: number;
  maxVideosToDisplay: number;
}

const PLATFORM_DISPLAY_INFO_MAP: Record<string, DisplayInfo> = {
  youtube: {
    // assets seem to be 4:3
    width: 295,
    height: 217.5,
    maxVideosToDisplay: 8,
  },
  tiktok: {
    // assets seem to be 3:4
    width: 217.5,
    height: 295,
    maxVideosToDisplay: 8,
  },
  instagram: {
    // assets seem to be 9:16
    width: 165,
    height: 294,
    maxVideosToDisplay: 8,
  },
};

export default function Video({
  video,
  refetchThumbnails,
}: {
  video: MediaEntry;
  refetchThumbnails: boolean;
}) {
  const { ref, inViewport } = useInViewport();
  const abortControllerRef = useRef(null);

  const videoHasViews = (video.views || 0) > 0;
  const videoHasTimeStamp = video.creation_timestamp !== null;

  const [refetchLoading, setRefetchLoading] = useState(false);

  const [thumbnailError, setThumbnailError] = useState(false);
  const [refetchSuccessful, setRefetchSuccessful] = useState(false);

  const showSkeleton = thumbnailError && !refetchSuccessful;

  const handleFetchThumbnail = async (abortController: AbortController, retryCount = 0) => {
    setRefetchLoading(true);

    // Maximum number of retry attempts
    const maxRetries = 5;
    // Base delay time in milliseconds
    const baseDelay = 1000;
    // Randomization factor (jitter)
    const jitter = 0.5; // 50% of the delay

    fetchVideoThumbnail(video.video_id, video.platform, abortController)
      .then((response: any) => {
        const { success } = response;
        if (success) {
          setRefetchSuccessful(true);
          setThumbnailError(false);
          setRefetchLoading(false);
        } else if (retryCount < maxRetries) {
          // Calculate exponential backoff delay with random jitter
          const delay = baseDelay * 2 ** retryCount;
          const randomJitter = delay * jitter * (Math.random() - 0.5) * 2;
          setTimeout(() => {
            handleFetchThumbnail(abortController, retryCount + 1);
          }, delay + randomJitter);
        } else {
          setRefetchLoading(false);
        }
      })
      .catch(() => {
        if (retryCount < maxRetries) {
          const delay = baseDelay * 2 ** retryCount;
          const randomJitter = delay * jitter * (Math.random() - 0.5) * 2;
          setTimeout(() => {
            handleFetchThumbnail(abortController, retryCount + 1);
          }, delay + randomJitter);
        } else {
          setRefetchLoading(false);
        }
      });
  };

  useEffect(() => {
    // Create a new AbortController only when needed
    if (!abortControllerRef.current) {
      abortControllerRef.current = new AbortController();
    }

    // Start fetch if conditions are met
    if (refetchThumbnails && thumbnailError && inViewport && !refetchLoading) {
      handleFetchThumbnail(abortControllerRef.current);
    }

    // Check if the video is out of the viewport to abort fetching
    if (!inViewport) {
      abortControllerRef.current.abort();
      abortControllerRef.current = null;
    }

    // Cleanup function to abort fetch on unmount
    return () => {
      abortControllerRef.current?.abort();
      abortControllerRef.current = null;
    };
  }, [video, thumbnailError, inViewport]);

  return (
    <Stack
      ref={ref}
      gap={0}
      align="flex-start"
      justify="flex-start"
      style={{
        flexBasis: "100%",
        maxWidth: `${PLATFORM_DISPLAY_INFO_MAP[video.platform].width}px`,
        minWidth: `${PLATFORM_DISPLAY_INFO_MAP[video.platform].width}px`,
      }}>
      <Anchor href={video.video_url} target="_blank">
        {showSkeleton ? (
          <Skeleton
            height={PLATFORM_DISPLAY_INFO_MAP[video.platform].height}
            width={PLATFORM_DISPLAY_INFO_MAP[video.platform].width}
            animate={refetchThumbnails && refetchLoading}
          />
        ) : (
          <Image
            style={{
              width: PLATFORM_DISPLAY_INFO_MAP[video.platform].width,
              height: PLATFORM_DISPLAY_INFO_MAP[video.platform].height,
              borderRadius: 8,
            }}
            src={video.thumbnail}
            onError={() => setThumbnailError(true)}
          />
        )}
      </Anchor>
      <>
        <Spacer height={8} />
        <Text
          lineClamp={2}
          style={{
            width: "100%",
            fontSize: "12px",
            fontWeight: "400",
            lineHeight: "155%",
          }}>
          {video.title}
        </Text>
        {videoHasViews || videoHasTimeStamp ? (
          <Text
            style={{
              width: "100%",
              fontSize: "12px",
              fontWeight: "400",
              lineHeight: "155%",
              color: "var(--mantine-color-gray-6)",
            }}>
            {videoHasViews ? `${getAbbreviatedNumber(video.views)} Views` : null}
            {videoHasViews && videoHasTimeStamp && !isNotValidTimeAgo(video.creation_timestamp) ? (
              <>&nbsp;&bull;&nbsp;</>
            ) : null}
            {videoHasTimeStamp ? timeAgo(video.creation_timestamp) : null}
          </Text>
        ) : null}
      </>
    </Stack>
  );
}
