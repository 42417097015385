import React, { useEffect, useState } from "react";
import { User } from "firebase/auth";

import {
  Container,
  LoadingOverlay,
  Stack,
  Table,
  Text,
} from "@mantine/core";

import { API_URL } from "configs/Configs";
import { handleResult } from "utils/ApiUtils";

interface TransitionsEntry {
  brandId: number;
  brandName: string;
  // {from_action: {to_action: {stat_name: stat_value}}}.
  // to_action may be the special "EndsWith" indicating that there is no action.
  transitions: Record<string, Record<string, Record<string, number>>>;
}

interface TransitionsResponse {
  success: boolean;
  transitions: TransitionsEntry[];
  all: TransitionsEntry;
}

function roundToDecimalPlace(num: number, places: number): number {
  const multiplier = 10 ** places;
  return Math.round(num * multiplier) / multiplier;
}

/**
 * 
 * @param seconds The number of elapsed seconds
 * @returns A string that's way better than seconds
 */
function printableDelta(seconds: number): string {
  let num = seconds;
  if (num < 60) {
    return `${roundToDecimalPlace(num, 2)}s`;
  }
  num /= 60;
  if (num < 60) {
    return `${roundToDecimalPlace(num, 2)}m`;
  }
  num /= 60;
  if (num < 24) {
    return `${roundToDecimalPlace(num, 2)}h`;
  }
  num /= 24;
  return `${roundToDecimalPlace(num, 2)}d`;
}

/**
 * Canonical action order for the table and transitions within cells.
 */
const actionOrder = [
  ["ops_accept", "Ops Approved"],
  ["ops_reject", "Ops Rejected"],
  ["email_init_request", "Email Search Requested"],
  ["email_attempt", "Email Find Attempted"],
  ["email_found", "Email Found"],
  ["internal_accept", "Internal Approved"],
  ["internal_reject", "Internal Rejected"],
  ["brand_accept", "Client Approved"],
  ["brand_reject", "Client Rejected"],
  ["email_init_sent", "Emails Sent"],
  ["email_init_respond", "Discussion Start"],
  ["email_init_negotiation", "Negotiation Start"],
  ["contract_accepted", "Contracts Accepted"],
  ["contract_rejected", "Contracts Rejected"],
  ["contract_complete", "Contracts Completed"],
] as const;

/**
 * Order the transitions out of this action according to the canonical action order
 * 
 * @param transitionStats {to-action : {stat_name: stat_value}}
 * @returns 
 */
function orderTransitions(transitionStats: Record<string, Record<string, number>>) : [string, Record<string, number>][] {
  const transitions: [string, Record<string, number>][] = [];
  actionOrder.forEach((action) => {
    if (transitionStats[action[0]] !== undefined) {
      transitions.push([action[1], transitionStats[action[0]]]);
    }
  })
  // Replace the marker EndsHere with the | symbol for eventual "-> |"
  if (transitionStats.EndsHere !== undefined) {
    transitions.push(["|", transitionStats.EndsHere]);
  }
  return transitions;
}

const TransitionDetail = ({
  transitionStats,
} : {
  transitionStats: Record<string, Record<string, number>>;
}) => {
  // Sum the entries for the main cell value
  let summedActions = 0;
  if (transitionStats !== undefined) {
    Object.entries(transitionStats).forEach(([action, inner]) =>{
      summedActions += inner.num;
    });
}
  return (
    <Stack>
      <Text size="xl">{summedActions}</Text>
      {transitionStats !== undefined && orderTransitions(transitionStats).map(
        ([toAction, statsContainer]) => (
        <Text key={toAction}>
          {`-> ${toAction} : ${statsContainer.num} (${printableDelta(statsContainer.p50)}/${printableDelta(statsContainer.p90)})`}
        </Text>   
      ))}
    </Stack>
  );
};

const TransitionRow = ({
  entry,
} : {
  entry: TransitionsEntry;
}) => {
  return (
    <Table.Tr key={entry.brandId}>
      <Table.Td>
        <Text>{entry.brandName}</Text>
      </Table.Td>
      {actionOrder.map((action, _) => {
          return (
            <Table.Td key={`${entry.brandId}-=-${action}`}>
                <TransitionDetail 
                  transitionStats={entry.transitions[action[0]]}
                />
            </Table.Td>
          );
        })
      }
    </Table.Tr>
  )
};

const TransitionTable = ({
  entries,
  all,
} : {
  entries: TransitionsEntry[]
  all: TransitionsEntry
}) => (
    <Table
      stickyHeader
      stickyHeaderOffset={0}
      striped
      highlightOnHover
      withTableBorder
      withColumnBorders>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Brand</Table.Th>
          {actionOrder.map((action, index) => {
            return (
              <Table.Th key={action[0]}>
                {action[1]}
              </Table.Th>
            );
          })}
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {entries.map((entry, index) => {
          return (
            <TransitionRow
              key={entry.brandId}
              entry={entry}
            />
          );
        })}
        { 
        all && <TransitionRow entry={all} />
        }
      </Table.Tbody>
    </Table>
);


const fetchTransitionGraph = async (user: User) => {
  const firebaseToken = await user.getIdToken();
  const requestUrl = new URL(`${API_URL}/api/campaigns/get_all_brand_transitions/`);
  const request = new Request(requestUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
  });

  const response = await handleResult(request);
  return response;
};

export default function Transitions({ user }: { user: User }) {
  const [isLoading, setIsLoading] = useState(false);
  const [transitionEntries, setTransitionEntries] = useState<TransitionsEntry[]>([]);
  const [allEntry, setAllEntry] = useState<TransitionsEntry>();

  useEffect(() => {
    setIsLoading(true);
    const abortController = new AbortController();
    fetchTransitionGraph(user)
      .then((response: TransitionsResponse) => {
        const { success, transitions, all} = response;
        if (success) {
          setTransitionEntries(transitions);
          setAllEntry(all);
        }
      })
      .finally(() => setIsLoading(false));
    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <>
      <LoadingOverlay visible={isLoading} />
      {!isLoading && (
        <Container fluid>
          <TransitionTable
            entries = {transitionEntries}
            all = {allEntry}
          />
        </Container>
      )}
    </>
  );
}
