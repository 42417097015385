import React, { useState } from "react";

import {
  Modal,
  Text,
  Layer,
  Box,
  Flex,
  Button,
  TextField,
  Spinner,
  IconButton,
  Heading,
  FixedZIndex,
} from "gestalt";
import "gestalt/dist/gestalt.css";
import Spacer from "components/Spacer";
import { auth } from "auth/firebaseAuthHelpers";
import { sendPasswordResetEmail } from "firebase/auth";

enum ModalStates {
  INSTRUCTIONS = 1,
  LOADING = 2,
  SUCCESS = 3,
}

const sendPasswordReset = async (
  email: string,
  setModalState: (modalState: ModalStates) => void,
  setErrorMessage: (errorMessage: string) => void,
) => {
  setModalState(ModalStates.LOADING);
  try {
    await sendPasswordResetEmail(auth, email);
    setModalState(ModalStates.SUCCESS);
  } catch (err) {
    if (err.code === "auth/missing-email") setErrorMessage("Please enter an email address.");
    else if (err.code === "auth/user-not-found")
      setErrorMessage("Email not found. Please try again or sign up for an account.");
    else if (err.code === "auth/invalid-email")
      setErrorMessage("Please enter a valid email address");
    setModalState(ModalStates.INSTRUCTIONS);
  }
};

const header = (modalState: ModalStates) => {
  switch (modalState) {
    case ModalStates.INSTRUCTIONS:
      return "Forgot your password?";
    case ModalStates.LOADING:
      return " ";
    case ModalStates.SUCCESS:
    default:
      return "Email sent!";
  }
};

const Body = ({
  modalState,
  setEmail,
  errorMessage,
  email,
}: {
  modalState: ModalStates;
  setEmail: (email: string) => void;
  errorMessage: string;
  email: string;
}) => {
  switch (modalState) {
    case ModalStates.INSTRUCTIONS:
      return (
        <Box padding={9}>
          <Text>Enter your account email to get a link to reset your password</Text>
          <Spacer height={16} />
          <Box width="70%">
            <TextField
              id="emailTextField"
              onChange={({ value }) => setEmail(value)}
              placeholder="Email"
              size="md"
              value={email}
            />
          </Box>
          {errorMessage && (
            <>
              <Spacer height={8} />
              <Text color="error" size="200">
                {errorMessage}
              </Text>
            </>
          )}
        </Box>
      );
    case ModalStates.LOADING:
      return <Spinner show accessibilityLabel="Loading password recovery" />;
    case ModalStates.SUCCESS:
    default:
      return (
        <Box padding={9}>
          <Text>
            Email sent to{" "}
            <Text inline weight="bold">
              {email}
            </Text>
            . Click on the link in the email to reset your password.
          </Text>
          <Spacer height={32} />
          <Text>Please check your spam folder if you do not see the email.</Text>
        </Box>
      );
  }
};

const Footer = ({
  modalState,
  setModalState,
  setErrorMessage,
  setShowModal,
  email,
}: {
  modalState: ModalStates;
  setModalState: (modalState: ModalStates) => void;
  setErrorMessage: (errorMessage: string) => void;
  setShowModal: (showModal: boolean) => void;
  email: string;
}) => {
  switch (modalState) {
    case ModalStates.INSTRUCTIONS:
      return (
        <Flex justifyContent="end" gap={2}>
          <Button color="gray" text="Cancel" onClick={() => setShowModal(false)} />
          <Button
            color="blue"
            text="Reset Password"
            onClick={() => {
              setErrorMessage("");
              sendPasswordReset(email, setModalState, setErrorMessage);
            }}
          />
        </Flex>
      );
    case ModalStates.LOADING:
      return <Button color="gray" text="Cancel" onClick={() => setShowModal(false)} />;
    case ModalStates.SUCCESS:
    default:
      return <Button color="gray" text="Go Back" onClick={() => setShowModal(false)} />;
  }
};

const PasswordResetModal = ({
  setShowModal,
  zIndex,
}: {
  setShowModal: (showModal: boolean) => void;
  zIndex?: number;
}) => {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [modalState, setModalState] = useState(ModalStates.INSTRUCTIONS);
  const zIndexForLayer = zIndex ? new FixedZIndex(zIndex) : undefined;
  return (
    <Layer zIndex={zIndexForLayer}>
      <Modal
        accessibilityModalLabel="Password Reset Prompt"
        onDismiss={() => setShowModal(false)}
        align="start"
        heading={
          <Box padding={9}>
            <Flex direction="row" justifyContent="between">
              <Heading size="500">{header(modalState)}</Heading>
              <IconButton
                accessibilityLabel="Dismiss modal"
                icon="cancel"
                iconColor="darkGray"
                onClick={() => setShowModal(false)}
              />
            </Flex>
          </Box>
        }
        footer={
          <Flex justifyContent="end" gap={2}>
            <Footer
              modalState={modalState}
              setModalState={setModalState}
              setErrorMessage={setErrorMessage}
              setShowModal={setShowModal}
              email={email}
            />
          </Flex>
        }>
        <Body
          modalState={modalState}
          setEmail={setEmail}
          errorMessage={errorMessage}
          email={email}
        />
      </Modal>
    </Layer>
  );
};

export default PasswordResetModal;
