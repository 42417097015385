import React from "react";
import { Box, Button, Flex, Text } from "gestalt";
import "gestalt/dist/gestalt.css";

import IMAGES from "pages/home/assets/media_kits";

import "pages/home/styles/light-styles.css";
import Spacer from "components/Spacer";
import { useNavigate } from "react-router-dom";
import { CreatorSignupButton } from "pages/home/components/Buttons";
import Footer from "pages/home/Footer";
import { Helmet } from "react-helmet-async";
import { ProductHighlight } from "pages/home/components/Highlights";

const ViewSampleMediaKit = () => {
  const navigateToSampleMediaKit = useNavigate();
  return (
    <Button
      text="View Sample Media Kit"
      color="gray"
      onClick={() => navigateToSampleMediaKit("/humphreytalks")}
    />
  );
};

const HeroContent = () => (
  <div id="hero">
    <Box width="min(100%, 900px)" alignSelf="center">
      <Text size="600" weight="bold" align="center">
        Media Kits for Creators that{" "}
        <Text inline size="600" weight="bold" italic>
          work
        </Text>
      </Text>
      <Spacer height={32} />
      <Text align="center" size="400">
        Create a media kit, view brands, and get discovered&mdash;all in one place. Join the first
        open network for brands and creators to collaborate.
      </Text>
    </Box>
    <Box display="flex" width="100%" flex="grow" justifyContent="center" wrap>
      <Box padding={1}>
        <ViewSampleMediaKit />
      </Box>
      <Box padding={1}>
        <CreatorSignupButton buttonText="Create your free profile" />
      </Box>
    </Box>
    <img src={IMAGES.mediaKitHero} alt="mock up of a creator's media kit" id="heroImage" />
  </div>
);

const ProductFeature = () => (
  <Flex direction="row" justifyContent="center">
    <Box direction="column" maxWidth={1048} padding={0}>
      <ProductHighlight
        title="Your metrics updated in real time"
        description="Connect your profiles and never worry about updating your numbers before sending your media kit."
        imageSrc={IMAGES.realTimeMetrics}
        upsellButton={<CreatorSignupButton />}
        left
      />
      <Spacer height={48} />
      <ProductHighlight
        title="Generate a custom, shareable link"
        description="Share a single link with brands and be confident that you're sharing the correct version of your metrics."
        imageSrc={IMAGES.linkImage}
        upsellButton={<CreatorSignupButton />}
      />
      <Spacer height={48} />
      <ProductHighlight
        title="Get discovered by your dream brands"
        description="Every day, we recommend thousands of creators to their dream brands. We can do the same for you. Create your profile to get started!"
        imageSrc={IMAGES.needle}
        upsellButton={<CreatorSignupButton />}
        left
      />
      <Spacer height={120} />
    </Box>
  </Flex>
);

const MediaKits = () => {
  const title = "Media Kits by 1stCollab";
  const description = "Create a media kit in under a minute on 1stCollab";
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="title" property="og:title" content={title} />
        <meta name="description" property="og:description" content={description} />
      </Helmet>
      <HeroContent />
      <ProductFeature />
    </>
  );
};

export default MediaKits;
