import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Button,
  Text,
  Heading,
  Spinner,
  Avatar,
  ScrollBoundaryContainer,
  Link,
  TapArea,
  Flex,
  IconButton,
} from "gestalt";
import "gestalt/dist/gestalt.css";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { User } from "firebase/auth";
import IMAGES from "pages/home/assets/images";

import { AuthContext } from "auth/AuthContext";
import Spacer from "components/Spacer";
import LayoutBase from "components/LayoutBase";
import { Helmet } from "react-helmet-async";
import { useUser } from "utils/UserContext";
import { getByUsername } from "utils/UserUtils";
import LoadingScreen from "components/LoadingScreen";
import CreatorFilter from "components/discovery/CreatorFilter";
import {
  getSavedYoutubeCreators,
  SavedCreatorsResponse,
  unsaveYoutubeCreator,
  fetchAndSetCreatorLists,
  CreatorList,
} from "components/creator_lists/CreatorListsUtils";

const EmptyUserLists = (
  <>
    <Box
      // borderStyle="sm"
      justifyContent="center"
      display="flex"
      // rounding={4}
      alignSelf="center">
      <img
        src={IMAGES.astronaut}
        alt="Campaign Waitlist"
        id="campaignPlant"
        width="200"
        height="200"
      />
    </Box>
    <Spacer height={5} />
    <Flex alignSelf="center" justifyContent="center">
      <Box id="box1" width={600} borderStyle="sm" padding={8} rounding={2}>
        <Text size="500" weight="bold" align="center">
          You haven&apos;t saved any influencers yet!
        </Text>
        <Spacer height={36} />
        <Text size="300" align="center">
          Your saved influencers and Lists show up here. You can save as many influencers as
          you&apos;d like, and can make an unlimited number of Lists. If you&apos;re running a
          Campaign on 1stcollab, you can also use a list to set targeting criteria for your
          Campaign.
        </Text>
        <Spacer height={36} />
        <Text size="300" align="center">
          To get started, head over to{" "}
          <a href="/campaigns/discover" style={{ display: "inline" }} target="blank">
            Discover
          </a>
          . Once you find an influencer you like, click &quot;Save&quot; to add them to your first
          List.
        </Text>
        <Spacer height={18} />
        <Flex width="100%" justifyContent="center">
          <Button
            text="Search for influencers"
            onClick={() => window.open("/campaigns/discover", "_blank")}
          />
        </Flex>
      </Box>
    </Flex>
  </>
);

// View for a given users lists
// i.e. you can see all the different lists for a given user
const UserListView = () => {
  const { user } = useOutletContext<AuthContext>();
  const [userProfile, userLoading] = useUser();

  const [creatorLists, setCreatorLists] = useState([]);
  const [creatorListsLoading, setCreatorListsLoading] = useState(true);
  const prevCreatorLists = useRef([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (userProfile !== null) {
      fetchAndSetCreatorLists(user, userProfile.username, setCreatorLists);
    }
  }, [userProfile]);

  useEffect(() => {
    if (userProfile !== null && prevCreatorLists.current.length !== creatorLists.length) {
      fetchAndSetCreatorLists(user, userProfile.username, setCreatorLists);
      prevCreatorLists.current = creatorLists;
      setCreatorListsLoading(false);
    }
  }, [creatorLists]);

  const ListScreen = (
    <Box display="flex" alignItems="center">
      <Flex gap={2} direction="column">
        {creatorLists.map((list: CreatorList) => (
          <Box key={list.name}>
            <TapArea
              onTap={() => {
                navigate(`/${userProfile.username}/saved/${list.name}`);
              }}>
              <Flex alignItems="center">
                <Text key={list.name} weight="bold">
                  {list.fullname}
                </Text>
                <IconButton size="md" accessibilityLabel="go-to-list" icon="arrow-forward" />
              </Flex>
            </TapArea>
          </Box>
        ))}
      </Flex>
    </Box>
  );

  const ListContent =
    creatorListsLoading === false && creatorLists.length === 0 ? EmptyUserLists : ListScreen;

  return <LayoutBase>{userLoading ? <LoadingScreen /> : ListContent}</LayoutBase>;
};

export default UserListView;
