import { Flex, Paper, Stack, Text } from "@mantine/core";
import { IconBrandYoutubeFilled, IconBrandTiktok, IconBrandInstagram } from "@tabler/icons-react";
import React from "react";

interface ContractBrandReviewDeliverableProps {
  format: string;
  platform: string;
  usageRights: number;
}

function formatDeliverableFormat(str: string): string {
  return (
    str
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      // lol
      .map((word) => (word === "Youtube" ? "YouTube" : word))
      .join(" ")
  );
}

export const ContractBrandReviewDeliverable: React.FC<ContractBrandReviewDeliverableProps> = ({
  format,
  platform,
  usageRights,
}) => {
  let icon = <IconBrandYoutubeFilled color="black" size="30px" />;
  if (platform === "tiktok") {
    icon = <IconBrandTiktok color="black" size="30px" />;
  } else if (platform === "instagram") {
    icon = <IconBrandInstagram color="black" size="30px" />;
  }

  const deliverableFormat = formatDeliverableFormat(format);
  return (
    <Paper radius="md" withBorder p="xs">
      <Flex gap="xs" align="center">
        {icon}
        <Stack gap={0}>
          <Text size="sm">{deliverableFormat}</Text>
          <Text size="xs" c="dimmed">
            {usageRights ? `${usageRights} days usage rights included` : "No Usage Rights"}
          </Text>
        </Stack>
      </Flex>
    </Paper>
  );
};

export default ContractBrandReviewDeliverable;
