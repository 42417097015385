import moment from "moment";

export const isNotValidTimeAgo = (timeString: string) =>
  timeString > "2030-04-20T21:00:15UTC" || timeString < "2000-04-20T21:00:15UTC";

// takes a timestamp from the backend such as 2023-04-20T21:00:15UTC
// and converts it into a time ago string. needs to be UTC time and have the characters UTC at the end
export const timeAgo = (timeString: string) => {
  // if the timeString is greater than a certain date - just return null
  // or if the timeString is super old - just return null
  if (isNotValidTimeAgo(timeString)) {
    return null;
  }
  return moment.utc(timeString.substring(0, timeString.length - 3)).fromNow();
};

export default null;
