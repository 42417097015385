import React from "react";

import { Button, Flex, Modal, Stack, Text } from "@mantine/core";

export default function ConfirmModal({
  modalMessage,
  onConfirm,
  isProcessing,
  opened,
  close,
}: {
  modalMessage: React.ReactNode;
  onConfirm: () => void;
  isProcessing: boolean;
  opened: boolean;
  close: () => void;
}) {
  return (
    <Modal opened={opened} onClose={close} size="xs" title={<Text fw="600">Confirm Action</Text>}>
      <Stack gap="sm">
        <Text>{modalMessage}</Text>
        <Flex gap="sm" justify="center" align="center">
          <Button onClick={close} variant="default" color="gray">
            Cancel
          </Button>
          <Button
            loading={isProcessing}
            onClick={() => {
              onConfirm();
            }}>
            Confirm
          </Button>
        </Flex>
      </Stack>
    </Modal>
  );
}
