import React, { Dispatch, SetStateAction, useState } from "react";
import { notifications } from "@mantine/notifications";
import { Button, Flex, Stack, Table } from "@mantine/core";

export const identityTransformation = () => {
  return (updates: { [key: string]: any }): { [key: string]: any } => {
    const transformed = { ...updates };
    return transformed;
  };
};

export const notifyUpdate = (response: any, objType: string) => {
  if (response != null && response?.message != null) {
    notifications.show({
      color: "red",
      title: `Error with updating the ${objType}!`,
      message: `${response.message}`,
      autoClose: false,
    });
  }
  if (response != null && response.id != null) {
    const capitalObjType = objType.charAt(0).toUpperCase() + objType.slice(1);
    notifications.show({
      title: `${capitalObjType} updated successfully!`,
      message: `${capitalObjType} updated.`,
      autoClose: false,
    });
  } else {
    notifications.show({
      color: "red",
      title: `Error with updating the ${objType}!`,
      message: "Unknown error.",
      autoClose: false,
    });
  }
};

export const notifyDelete = (response: any, objType: string) => {
  if (response != null && response?.message != null) {
    notifications.show({
      color: "red",
      title: `Error with deleting the ${objType}!`,
      message: `${response.message}`,
      autoClose: false,
    });
  }
  if (response != null && response.ok && response.status === 204) {
    const capitalObjType = objType.charAt(0).toUpperCase() + objType.slice(1);
    notifications.show({
      title: `${capitalObjType} deleted successfully!`,
      message: `${capitalObjType} deleted.`,
      autoClose: false,
    });
  } else {
    notifications.show({
      color: "red",
      title: `Error with deleting the ${objType}!`,
      message: "Unknown error.",
      autoClose: false,
    });
  }
};

export function wrapUpdateFunc(
  form: any,
  updateFunc: (
    objId: number,
    updates: { [key: string]: any },
    updateFields: string[],
  ) => Promise<any>,
  setButtonLoading: Dispatch<SetStateAction<boolean>>,
  close: () => void,
  objType: string, // Object type, e.g., "creator set"
  transformUpdates: (updates: { [key: string]: any }) => { [key: string]: any }, // Transformation function for updates
): (objId: number, updates: { [key: string]: any }, updateFields: string[]) => Promise<any> {
  const wrappedfn = async (
    objId: number,
    updates: { [key: string]: any },
    updateFields: string[],
  ): Promise<any> => {
    // Apply the transformation function to updates
    const transformedUpdates = transformUpdates(updates);

    try {
      const response = await updateFunc(objId, transformedUpdates, updateFields);
      notifyUpdate(response, objType);
      form.resetDirty();
      return response;
    } catch (e) {
      notifications.show({
        color: "red",
        title: `Error with updating the ${objType}!`,
        message: e.message,
        autoClose: false,
      });
    } finally {
      setButtonLoading(false);
      close();
    }
    return null;
  };
  return wrappedfn;
}

const valuesEqual = (key: string, initialValue: any, value: any) => {
  if (Array.isArray(initialValue) && Array.isArray(value)) {
    // lol
    return initialValue.sort().toString() === value.sort().toString();
  }
  return initialValue === value;
};

const diffConfirmation = (
  formValues: { [key: string]: any },
  initialValues: { [key: string]: any },
  customFormatValue: (key: string, value: any) => any,
) => {
  const diffs: JSX.Element[] = [];
  Object.entries(formValues).forEach(([key, value]) => {
    if (!valuesEqual(key, initialValues[key], value)) {
      diffs.push(
        <Table.Tr key={`field-changed-${key}`}>
          <Table.Td>{key}</Table.Td>
          <Table.Td>{customFormatValue(key, initialValues[key])}</Table.Td>
          <Table.Td>{customFormatValue(key, value)}</Table.Td>
        </Table.Tr>,
      );
    }
  });
  return diffs;
};

export const ChangeConfirmation = ({
  objId,
  formValues,
  initialValues,
  buttonLoading,
  updateFunc,
  customDiffFormatValue,
}: {
  objId: number;
  formValues: { [key: string]: any };
  initialValues: { [key: string]: any };
  buttonLoading: boolean;
  updateFunc: (
    objId: number,
    updates: { [key: string]: any },
    updateFields: string[],
  ) => Promise<any>;
  customDiffFormatValue: (key: string, value: any) => any;
}) => {
  const updates: { [key: string]: any } = {
    id: objId,
  };

  const diffs: JSX.Element[] = diffConfirmation(formValues, initialValues, customDiffFormatValue);

  // change updates to use the form values
  Object.entries(formValues).forEach(([key, value]) => {
    if (!valuesEqual(key, initialValues[key], value)) {
      updates[key] = value;
    }
  });

  const updateFields = Object.keys(updates);

  return (
    <Stack>
      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Field Name</Table.Th>
            <Table.Th>Previous Value</Table.Th>
            <Table.Th>Updated Value</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{diffs}</Table.Tbody>
      </Table>
      <Flex justify="center">
        <Button loading={buttonLoading} onClick={() => updateFunc(objId, updates, updateFields)}>
          Submit
        </Button>
      </Flex>
    </Stack>
  );
};

export default null;
