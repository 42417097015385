import React from "react";
import "gestalt/dist/gestalt.css";

import CreateLiveContent from "components/campaign/live/CreateLiveContent";
import { useAdminAppContext } from "admin/app/AdminAppShell";

export default function AdminLiveContent() {
  const { user } = useAdminAppContext();

  return (
    <>
      <CreateLiveContent user={user} />
      {/* <Space h={30} />
      <Divider />
      <Space h={30} />
      <CampaignsList /> */}
    </>
  );
}
