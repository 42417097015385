import React, { ReactNode } from "react";
import { Box, Text } from "gestalt";
import "gestalt/dist/gestalt.css";

import { AudiencePieGraph } from "components/AudienceGraph";
import FlatModule from "components/FlatModule";
import { AudienceEntryResponse } from "social/utils";

export const InfoCard = ({
  children,
  title,
  height,
  width,
  justifyContent,
}: {
  children: ReactNode;
  title: string;
  height?: number | string;
  width?: number | string;
  justifyContent?: "center" | "start" | "end" | "between" | "around" | "evenly";
}) => (
  <FlatModule height={height} width={width}>
    <Box
      display="flex"
      direction="column"
      flex="grow"
      height="100%"
      width="100%"
      justifyContent={justifyContent}>
      <Text size="200">{title}</Text>
      {children}
    </Box>
  </FlatModule>
);

const DemographicsCard = ({
  cardWidth,
  graphWidth,
  audienceName,
  instagramAudience,
  youtubeAudience,
  isClaimed,
}: {
  cardWidth: string;
  graphWidth: number;
  audienceName: "country" | "age" | "gender";
  instagramAudience: AudienceEntryResponse[];
  youtubeAudience: AudienceEntryResponse[];
  isClaimed: boolean;
}) => {
  const audience = instagramAudience || youtubeAudience;
  const platform = instagramAudience ? "Instagram" : "YouTube";

  const modifiedAudience =
    audienceName === "gender"
      ? audience?.map((entry) => {
          const newEntry = { ...entry };
          if (entry.audience === "M") {
            newEntry.audience = "Male";
          } else if (entry.audience === "F") {
            newEntry.audience = "Female";
          } else if (entry.audience === "U") {
            newEntry.audience = "Unspecified";
          }
          return newEntry;
        })
      : audience;

  return isClaimed ? (
    <InfoCard
      title={`${platform} ${
        audienceName.charAt(0).toUpperCase() + audienceName.slice(1)
      } Distribution`}
      width={cardWidth}>
      {modifiedAudience ? (
        <AudiencePieGraph audienceSegment={modifiedAudience} width={graphWidth} />
      ) : (
        <Box height={120} direction="column" justifyContent="center" display="flex">
          <Text align="start">{`Not enough data to display ${audienceName} audience distribution`}</Text>
        </Box>
      )}
    </InfoCard>
  ) : null;
};

export default DemographicsCard;
