import React from "react";
import { Button } from "@mantine/core";
import { IconBrandInstagram, IconBrandTiktok, IconBrandYoutubeFilled } from "@tabler/icons-react";

import { SupportedPlatform } from "models/Common";

export default function SocialHandleButton({
  platform,
  handle,
  profileLink,
  showIcon = true,
}: {
  platform: SupportedPlatform;
  handle: string;
  profileLink: string;
  showIcon?: boolean;
}) {
  let icon = null;
  switch (platform) {
    case SupportedPlatform.INSTAGRAM:
      icon = <IconBrandInstagram size="1.25rem" />;
      break;
    case SupportedPlatform.TIKTOK:
      icon = <IconBrandTiktok size="1.25rem" />;
      break;
    case SupportedPlatform.YOUTUBE:
      icon = <IconBrandYoutubeFilled size="1.25rem" />;
      break;
    default:
      return null;
  }

  return (
    <Button
      variant="subtle"
      size="compact-xs"
      radius="xl"
      leftSection={showIcon && icon}
      component="a"
      onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.stopPropagation()}
      href={profileLink}
      target="_blank">
      {handle.toUpperCase()}
    </Button>
  );
}
