import React, { useEffect, useState } from "react";

import { Flex, Group, Loader, Stack } from "@mantine/core";

import { ContentType } from "components/contracts/common/Common";

import { SupportedFormat } from "models/Common";

import {
  ContractDeliverableStatus,
  ContractDeliverableStatusMapping,
} from "components/contracts/models/Deliverable";

import { fetchVideosForDeliverable } from "components/contracts/common/Api";

import DeliverableVideo from "components/contracts/models/DeliverableVideo";
import VersionedDeliverableVideo from "components/contracts/models/VersionedDeliverableVideo";

import LoadingError from "components/contracts/common/LoadingError";

import CreatorMessages from "components/contracts/deliverables/CreatorMessages";
import ViewPreviousRevisions from "components/contracts/deliverables/ViewPreviousRevisions";

import VideoPlayer from "components/contracts/deliverables/VideoPlayer";
import VideoUpload from "components/contracts/deliverables/VideoUpload";

function ShowPreviousVideos({
  deliverableStatus,
  versionedDeliverableVideo,
}: {
  deliverableStatus: ContractDeliverableStatus;
  versionedDeliverableVideo: VersionedDeliverableVideo;
}) {
  // If a video has already been uploaded, show it.
  let uploadedVideoLocation = null;
  let transcodedVideoLocation = null;
  let previousVideoRevisions = null;
  let uploadedVideoTitle = null;
  let uploadedVideoCaption = null;
  if (versionedDeliverableVideo?.deliverableVideos?.length > 0) {
    uploadedVideoLocation = versionedDeliverableVideo.deliverableVideos[0].location;
    transcodedVideoLocation =
      versionedDeliverableVideo.deliverableVideos[0].transcodedVideoLocation;
    previousVideoRevisions = versionedDeliverableVideo.deliverableVideos.slice(1);
    uploadedVideoTitle = versionedDeliverableVideo.deliverableVideos[0].title;
    uploadedVideoCaption = versionedDeliverableVideo.deliverableVideos[0].caption;
  }

  if (deliverableStatus === ContractDeliverableStatus.VIDEO_DRAFT_SUBMITTED) {
    return (
      <Group>
        <VideoPlayer
          videoUrl={transcodedVideoLocation || uploadedVideoLocation}
          title={uploadedVideoTitle}
          caption={uploadedVideoCaption}
          label="View Video Draft"
        />
        <ViewPreviousRevisions
          contentType={ContentType.VIDEO}
          previousVideoRevisions={previousVideoRevisions}
        />
      </Group>
    );
  } else if (deliverableStatus === ContractDeliverableStatus.VIDEO_DRAFT_REVISIONS_REQUESTED) {
    return (
      <Group>
        <VideoPlayer
          videoUrl={transcodedVideoLocation || uploadedVideoLocation}
          title={uploadedVideoTitle}
          caption={uploadedVideoCaption}
          label="View Submitted Video"
        />
        <ViewPreviousRevisions
          contentType={ContentType.VIDEO}
          previousVideoRevisions={previousVideoRevisions}
        />
      </Group>
    );
  } else if (
    ContractDeliverableStatusMapping[deliverableStatus] >=
    ContractDeliverableStatusMapping[ContractDeliverableStatus.VIDEO_DRAFT_APPROVED]
  ) {
    return (
      <Group>
        <VideoPlayer
          videoUrl={transcodedVideoLocation || uploadedVideoLocation}
          title={uploadedVideoTitle}
          caption={uploadedVideoCaption}
          label="View Approved Video"
        />
        <ViewPreviousRevisions
          contentType={ContentType.VIDEO}
          previousVideoRevisions={previousVideoRevisions}
        />
      </Group>
    );
  }

  return null;
}

export default function VideoDraftTool({
  brandName,
  adGroupId,
  contractId,
  deliverableId,
  deliverableFormat,
  deliverableStatus,
  setDeliverableStatus,
  latestVideosForContract,
  setLatestVideosForContract,
  handleRefetchUploadedVideos,
  showAdminOptions,
}: {
  brandName: string;
  adGroupId: number;
  contractId: string;
  deliverableId: string;
  deliverableFormat: SupportedFormat;
  deliverableStatus: ContractDeliverableStatus;
  setDeliverableStatus: (status: ContractDeliverableStatus) => void;
  latestVideosForContract: DeliverableVideo[];
  setLatestVideosForContract: (videos: DeliverableVideo[]) => void;
  handleRefetchUploadedVideos: (handleFetched: () => void) => void;
  showAdminOptions: boolean;
}) {
  if (deliverableStatus === ContractDeliverableStatus.NOT_STARTED) {
    return null;
  }

  const disabled =
    ContractDeliverableStatusMapping[deliverableStatus] <
    ContractDeliverableStatusMapping[ContractDeliverableStatus.WAITING_FOR_VIDEO_DRAFT];

  const [fetchedVersionedDeliverableVideo, setFetchedVersionedDeliverableVideo] =
    useState<VersionedDeliverableVideo>(null);
  const [videosLoaded, setVideosLoaded] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);

  useEffect(() => {
    fetchVideosForDeliverable(deliverableId)
      .then((response) => {
        const { versionedDeliverableVideo, success } = response;
        if (success) {
          const deserializedVersionedDeliverableVideo = versionedDeliverableVideo
            ? VersionedDeliverableVideo.deserialize(versionedDeliverableVideo)
            : null;

          setFetchedVersionedDeliverableVideo(deserializedVersionedDeliverableVideo);
          setVideosLoaded(true);
        } else {
          setVideosLoaded(false);
          setShowError(true);
        }
      })
      .catch(() => {
        setVideosLoaded(false);
        setShowError(true);
      });
  }, [latestVideosForContract]);

  const currentDeliverableVideo = fetchedVersionedDeliverableVideo?.deliverableVideos?.[0];

  return (
    <Stack mt="md" mb="-xs" gap="sm">
      {showError && <LoadingError message="Failed to load video draft." />}
      {!videosLoaded && !showError && (
        <Flex justify="center">
          <Loader size="sm" />
        </Flex>
      )}
      {videosLoaded && !showError && (
        <>
          {!disabled && (
            <ShowPreviousVideos
              deliverableStatus={deliverableStatus}
              versionedDeliverableVideo={fetchedVersionedDeliverableVideo}
            />
          )}
          {!disabled && (
            <CreatorMessages
              brandName={brandName}
              deliverableStatus={deliverableStatus}
              setDeliverableStatus={setDeliverableStatus}
              contentType={ContentType.VIDEO}
              deliverableVideo={currentDeliverableVideo}
              showAdminOptions={showAdminOptions}
            />
          )}
          <VideoUpload
            brandName={brandName}
            adGroupId={adGroupId}
            contractId={contractId}
            deliverableId={deliverableId}
            deliverableStatus={deliverableStatus}
            deliverableFormat={deliverableFormat}
            latestVideosForContract={latestVideosForContract}
            setLatestVideosForContract={setLatestVideosForContract}
            fetchedVersionedDeliverableVideo={fetchedVersionedDeliverableVideo}
            setFetchedVersionedDeliverableVideo={setFetchedVersionedDeliverableVideo}
            setDeliverableStatus={setDeliverableStatus}
            handleRefetchUploadedVideos={handleRefetchUploadedVideos}
            disabled={disabled}
          />
        </>
      )}
    </Stack>
  );
}
