/* eslint-disable react/no-danger */
import { Accordion, Box, Container, Flex, Text, Title } from "@mantine/core";
import React, { useState } from "react";

interface FAQItem {
  id: number;
  question: string;
  answer: string;
}
const FAQ_ITEMS = [
  {
    id: 1,
    question: "What is 1stCollab?",
    answer:
      "We're an influencer marketing platform that helps both brands and creators with influencer marketing campaigns. For brands, we're the fastest way for brands to scale up and improve performance for their campaigns. For creators, we're the easiest way to find and join campaigns that are relevant for your audience.",
  },
  {
    id: 2,
    question: "What if my question isn't here?",
    answer: "Feel free to drop us a line any time at hello@1stcollab.com.",
  },
  {
    id: 3,
    question: "What do I need to do for a 1stCollab campaign?",
    answer:
      "When we reach out to you, it means a brand has already approved you to join a campaign and wants to partner with you. From there, we'll finalize a rate. Afterwards, you'll upload content and we'll pay you one week after your content goes live. To be considered for future 1stCollab brand campaigns, just sign up for an account!",
  },
  {
    id: 4,
    question: "Do I really get paid after one week?",
    answer:
      "Yep! No more waiting weeks or months to get paid. We want to send you money as soon as you've done the work.",
  },
  {
    id: 5,
    question: "How much can I earn on 1stCollab?",
    answer:
      "You'll have the opportunity to earn significantly more than you would working on a traditional brand deal. We'll pay a fixed fee upfront, but also add a bonus depending on how your content performs. If your content goes viral, you'll be in for a huge payday!",
  },
  {
    id: 6,
    question: "How do I get paid?",
    answer:
      "Once you go into contract for the campaign, we'll ask for your payment information and send you a payment through PayPal or Stripe one week after your content goes live.",
  },
  {
    id: 7,
    question: "How do I work with more brands on 1stCollab?",
    answer:
      "We're constantly onboarding new brands. Once you join 1stCollab, we'll give you the option to hear about other relevant campaigns to join. By being a creator on 1stCollab, we'll help make sure you have a steady stream of relevant brand deals always available.",
  },
  {
    id: 8,
    question: "I previously worked with 1stCollab, where can I get help with my 2023 taxes?",
    answer:
      "Check out our <a href='https://campaigns.1stcollab.com/taxes' target='_blank'>tax center</a> for assistance—including help if you never received your Form 1099-NEC. If you urgently need help, email us at <a href='mailto:hello@1stcollab.com'>hello@1stcollab.com.",
  },
];

const FAQ = () => {
  const [openedQuestions, setOpenedQuestions] = useState<string[]>([]);

  // Splitting FAQ items into two halves
  const halfwayIndex = Math.ceil(FAQ_ITEMS.length / 2);
  const firstHalfItems = FAQ_ITEMS.slice(0, halfwayIndex);
  const secondHalfItems = FAQ_ITEMS.slice(halfwayIndex);

  // Function to map FAQ items to Accordion Items
  const mapFAQItemsToAccordion = (items: FAQItem[]) =>
    items.map((item) => (
      <Accordion.Item
        key={item.id}
        value={`${item.id}`}
        style={{
          borderBottom: "1px solid rgba(255, 255, 255, 0.25)",
        }}>
        <Accordion.Control
          style={{
            color: "white",
            backgroundColor: "transparent",
            ":hover": {
              backgroundColor: "transparent",
            },
          }}
          px={0}
          py={8}>
          <Text size="lg" fw={500}>
            {item.question}
          </Text>
        </Accordion.Control>
        <Accordion.Panel style={{ color: "white" }} px={0} py={8}>
          <Text fw={500} opacity={0.8}>
            <span dangerouslySetInnerHTML={{ __html: item.answer }} />
          </Text>
        </Accordion.Panel>
      </Accordion.Item>
    ));

  return (
    <Flex
      w="100%"
      style={{
        background: "linear-gradient(to bottom, #282828, #111111)",
      }}
      p={0}>
      <Container style={{ maxWidth: "1920px" }} p={0} w="100%">
        <Box py={112} px={{ base: "24px", md: "32px", lg: "96px" }}>
          <Container fluid>
            <Title c="white" ta="center" mb={16} style={{ fontSize: "40px" }}>
              FAQs
            </Title>
            <Text c="white" size="lg" ta="center" mb={48}>
              Find answers to common questions about creator collaboration, expected results, and
              getting started.
            </Text>
            <Flex gap={{ base: 0, sm: 24 }} direction={{ base: "column", sm: "row" }}>
              <Box w={{ base: "100%", sm: "50%" }}>
                <Accordion
                  multiple
                  value={openedQuestions}
                  onChange={(value) => setOpenedQuestions(value)}>
                  {mapFAQItemsToAccordion(firstHalfItems)}
                </Accordion>
              </Box>
              <Box w={{ base: "100%", sm: "50%" }}>
                <Accordion
                  multiple
                  value={openedQuestions}
                  onChange={(value) => setOpenedQuestions(value)}>
                  {mapFAQItemsToAccordion(secondHalfItems)}
                </Accordion>
              </Box>
            </Flex>
          </Container>
        </Box>
      </Container>
    </Flex>
  );
};

export default FAQ;
