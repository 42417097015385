import { API_URL } from "configs/Configs";
import { Creator } from "models/Creator";
import { MembershipTypes, User } from "models/User";
import { createRequestWithFirebaseToken, handleResult } from "utils/ApiUtils";

const USER_API_URL = `${API_URL}/api/models/users/`;
const CREATOR_API_URL = `${API_URL}/api/models/creators/`;
const BRAND_API_URL = `${API_URL}/api/onboarding/models/intake/`;

// NOTE (victoria 6.2024): THIS DOESNT ACTUALLY DO ANYTHING
export const updateMembershipType = async (
  user_key: string,
  membershipType: MembershipTypes,
): Promise<User> => {
  const requestUrl = `${USER_API_URL}${user_key}/`;

  const updates = {
    key: user_key,
    membership_type: membershipType,
  };

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "PATCH",
    body: JSON.stringify(updates),
  });
  const result = await handleResult(request);
  return result;
};

export const updateUsername = async (
  user_key: string,
  username: string,
  abortController?: AbortController | null,
): Promise<User> => {
  const requestUrl = `${USER_API_URL}${user_key}/`;

  const updates = {
    key: user_key,
    username,
  };

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "PATCH",
    body: JSON.stringify(updates),
  });
  const result = await handleResult(request, abortController);
  if (result && result.ok === false) {
    // ok doesn't always exist in result. only exists in error cases
    if (result.result.username.length > 0) {
      throw Error("Username already taken.");
    }
    throw Error("Oops something went wrong. Please try again.");
  }
  return result;
};

export const completeOnboarding = async (
  user_key: string,
  abortController?: AbortController | null,
): Promise<User> => {
  const requestUrl = `${USER_API_URL}${user_key}/`;

  const updates = {
    key: user_key,
    completed_onboarding: true,
  };

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "PATCH",
    body: JSON.stringify(updates),
  });
  const result = await handleResult(request, abortController);
  return result;
};

export const confirmEmailVerification = async (): Promise<User> => {
  const requestUrl = `${USER_API_URL}verify_email/`;

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
  });
  const result = await handleResult(request);
  return result;
};

export const createOrUpdateBrandProfile = async (
  userKey: string,
  companyName: string,
  reason: string,
  useCase: string,
  abortController: AbortController,
) => {
  const requestBody = JSON.stringify({
    user: userKey,
    brand: companyName,
    use_case: useCase,
    reason,
  });

  const request = await createRequestWithFirebaseToken({
    url: BRAND_API_URL,
    method: "POST",
    body: requestBody,
  });

  const result = await handleResult(request, abortController);

  if (result.status === "500") {
    // if profile already exists, need to update it instead
    const updateRequest = await createRequestWithFirebaseToken({
      url: `${BRAND_API_URL}${userKey}/`,
      method: "PUT", // just overwrite entirely because typical user doesn't have read permissions
      body: requestBody,
    });

    const updateResult = await handleResult(updateRequest, abortController);
    return updateResult;
  }
  return result;
};

// TODO (victoria 4.2024): THIS CREATOR MODEL WILL SUPPOSEDLY BE DEPRECATED
export const fetchAuthCreator = async (
  abortController?: AbortController | null,
): Promise<Creator | null> => {
  /* Fetch current user's creator model */
  const request = await createRequestWithFirebaseToken({
    url: `${CREATOR_API_URL}get_authed_creator/`,
  });

  const result = await handleResult(request, abortController);
  return result;
};

export const createCreator = async (
  creator: Creator,
  abortController: AbortController | null,
): Promise<Creator | null> => {
  const request = await createRequestWithFirebaseToken({
    url: CREATOR_API_URL,
    method: "POST",
    body: JSON.stringify(creator),
    skipRequestIfNoFirebaseToken: true,
  });
  const result = await handleResult(request, abortController);
  if (result) {
    const updatedCreator = await fetchAuthCreator(abortController);
    return updatedCreator;
  }
  return null;
};

export default null;
