import React from "react";

import { Card, Group, Stack, Text } from "@mantine/core";

import { Brand } from "models/Brand";
import { OpsTask } from "models/OpsTask";
import { OpsBrandTasksTable } from "admin/app/ops/OpsBrandTasksTable";

export const OpsBrandTasks = ({ brand, opsTasks }: { brand: Brand; opsTasks: OpsTask[] }) => {
  // filter tasks to only the brand
  const brandTasks = opsTasks.filter((task) => task?.creator_set_details?.brand_id === brand?.id);

  return (
    <Stack w="100%">
      <Card shadow="xs" padding="md" radius="md" withBorder>
        <Card.Section inheritPadding withBorder py="sm">
          <Group justify="space-between">
            <Text fw={500}>{brand.brand_name}</Text>
          </Group>
        </Card.Section>
        <Card.Section inheritPadding withBorder py="sm">
          <OpsBrandTasksTable key={brand?.id} brand={brand} opsTasks={brandTasks} />
        </Card.Section>
      </Card>
    </Stack>
  );
};

export default OpsBrandTasks;
