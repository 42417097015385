import { User as FirebaseUser } from "firebase/auth";

import { API_URL } from "configs/Configs";

import { createRequestWithFirebaseToken, handleResult } from "utils/ApiUtils";
import { CampaignParametersV2, CampaignParameterResponse } from "models/Campaign";

export const CAMPAIGN_PARAMETERS_URL = `${API_URL}/api/models/campaign_parameters_v2`;

export const fetchCampaignParametersByHashId = async (
  user: FirebaseUser,
  campaignHashId: number,
  abortController: AbortController,
): Promise<CampaignParameterResponse> => {
  const firebaseToken = await user.getIdToken();

  const requestUrl = new URL(CAMPAIGN_PARAMETERS_URL);
  requestUrl.searchParams.append("campaignId", campaignHashId.toString());

  const request = new Request(requestUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
  });
  const response: CampaignParameterResponse | null = await handleResult(request, abortController);
  return response;
};

export const fetchAllCampaignParameters = async (
  user: FirebaseUser,
  setCampaignParameters: (campaignParameters: CampaignParametersV2[]) => void,
  abortController: AbortController,
): Promise<CampaignParameterResponse> => {
  // NOTE: this will only work for admins
  const firebaseToken = await user.getIdToken();

  const request = new Request(`${CAMPAIGN_PARAMETERS_URL}/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
  });

  const response = await handleResult(request, abortController);
  if (response && !(response?.ok === false) && response?.results) {
    setCampaignParameters(response?.results);
  }
  return response;
};

export const fetchCampaignParameterById = async (
  campaignParameterId: number,
  abortController: AbortController,
): Promise<CampaignParametersV2 | null> => {
  const requestUrl = new URL(`${CAMPAIGN_PARAMETERS_URL}/${campaignParameterId}/`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
  });

  const response = await handleResult(request, abortController);
  if (response && !(response?.ok === false)) {
    return response;
  }
  return null;
};

export const fetchCampaignParameterByBrandId = async (
  user: FirebaseUser,
  brandId: number,
  abortController: AbortController,
): Promise<CampaignParameterResponse> => {
  const firebaseToken = await user.getIdToken();

  const requestUrl = new URL(`${CAMPAIGN_PARAMETERS_URL}/`);
  requestUrl.searchParams.append("brandId", brandId.toString());

  const request = new Request(requestUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
  });
  const response: CampaignParameterResponse | null = await handleResult(request, abortController);
  return response;
};

export const createCampaignParameterByBrandId = async (
  user: FirebaseUser,
  brandId: number,
  campaignParameterName: string,
  // ehhh not sure if this is necessary
  abortController: AbortController = new AbortController(),
): Promise<CampaignParametersV2> => {
  const firebaseToken = await user.getIdToken();

  const requestUrl = new URL(`${CAMPAIGN_PARAMETERS_URL}/`);

  // Prepare the data to be sent in the request body
  const requestBody = {
    brand_id: brandId,
    name: campaignParameterName,
  };

  const request = new Request(requestUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
    body: JSON.stringify(requestBody),
    signal: abortController.signal,
  });

  const response = await handleResult(request, abortController);
  return response;
};

export default null;
