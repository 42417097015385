import React, { createContext } from "react";
import { MembershipTypes } from "models/User";

interface OnboardingV2ContextState {
  // OnboardingStep.MEMBERSHIP
  selectedMembershipType: MembershipTypes | null;
  setSelectedMembershipType: (type: MembershipTypes) => void;
  // OnboardingStep.CREATOR_PROFILE
  selectedVerticals: string[];
  setSelectedVerticals: (verticals: string[]) => void;
  username: string;
  setUsername: (username: string) => void;
  usernameError: string | null;
  aboutMe: string;
  setAboutMe: (about: string) => void;
  // OnboardingStep.BRAND_PROFILE
  companyName: string;
  setCompanyName: (companyName: string) => void;
  selectedReason: string | null;
  setSelectedReason: (reason: string) => void;
  other: string;
  setOther: (other: string) => void;
}

export const OnboardingV2Context = createContext<OnboardingV2ContextState>({
  selectedMembershipType: null,
  setSelectedMembershipType: () => {},
  selectedVerticals: [],
  setSelectedVerticals: () => {},
  username: "",
  setUsername: () => {},
  usernameError: null,
  aboutMe: "",
  setAboutMe: () => {},
  companyName: "",
  setCompanyName: () => {},
  selectedReason: null,
  setSelectedReason: () => {},
  other: "",
  setOther: () => {},
});

export default null;
