import React, { useState } from "react";

import { ActionIcon, Text, Tooltip } from "@mantine/core";

import { IconExternalLink } from "@tabler/icons-react";

import { submitLiveUrl } from "components/contracts/common/Api";

import {
  SupportedFormat,
  SupportedPlatform,
  SUPPORTED_PLATFORMS_TO_DISPLAY_NAMES,
} from "models/Common";

import { ContractDeliverableStatus } from "components/contracts/models/Deliverable";
import EditableTextInput from "components/contracts/deliverables/live_content/EditableTextInput";

function getPlaceholder({
  platform,
  format,
  creatorHandle,
}: {
  platform: SupportedPlatform;
  format: SupportedFormat;
  creatorHandle: string;
}) {
  switch (platform) {
    case SupportedPlatform.INSTAGRAM:
      return "https://www.instagram.com/reel/...";
    case SupportedPlatform.TIKTOK:
      return `https://www.tiktok.com/${creatorHandle}/video/...`;
    case SupportedPlatform.YOUTUBE:
      if (format === SupportedFormat.YOUTUBE_SHORT) {
        return "https://www.youtube.com/shorts/...";
      }
      return "https://www.youtube.com/watch?v=...";
    default:
      return "";
  }
}

export default function LiveLinkSubmission({
  deliverableId,
  setDeliverableStatus,
  missingReferralLink,
  missingPromoCode,
  requiresInput,
  isEditable,
  platform,
  format,
  creatorHandle,
  liveLink,
  setLiveLink,
  setLiveLinkSubmitted,
  setLiveContentReviewDeadline,
  setSubmissionSucceeded,
  disabled,
}: {
  deliverableId: string;
  setDeliverableStatus: (status: ContractDeliverableStatus) => void;
  missingReferralLink: boolean;
  missingPromoCode: boolean;
  requiresInput: boolean;
  isEditable: boolean;
  platform: SupportedPlatform;
  format: SupportedFormat;
  creatorHandle: string;
  liveLink: string;
  setLiveLink: (liveLink: string) => void;
  setLiveLinkSubmitted: (submitted: boolean) => void;
  setLiveContentReviewDeadline: (deadline: Date) => void;
  setSubmissionSucceeded: (succeeded: boolean) => void;
  disabled: boolean;
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmitUrl = () => {
    setErrorMessage("");
    setIsLoading(true);
    submitLiveUrl({ deliverableId, url: liveLink, isEditing })
      .then((response) => {
        const { success, status, liveContentUrl, reviewDeadline, error } = response;
        if (success) {
          // Note, the following state below will only get advanced upon submission if
          // Usage Rights are not required. This is handled by the backend.
          setDeliverableStatus(status);
          setLiveContentReviewDeadline(reviewDeadline);
          setLiveLink(liveContentUrl);
          setIsEditing(false);
          setLiveLinkSubmitted(true);
          setSubmissionSucceeded(true);
        } else {
          setErrorMessage(error);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <EditableTextInput
      label={
        <Text fw="500" size="sm" mb="-sm">
          {`Submit ${SUPPORTED_PLATFORMS_TO_DISPLAY_NAMES[platform]} URL`}
        </Text>
      }
      placeholder={getPlaceholder({ platform, format, creatorHandle })}
      value={liveLink}
      setValue={setLiveLink}
      platform={platform}
      requiresInput={requiresInput}
      isEditable={isEditable}
      isEditing={isEditing}
      setIsEditing={setIsEditing}
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
      loading={isLoading}
      disabled={disabled || missingReferralLink || missingPromoCode}
      handleSubmit={handleSubmitUrl}
      actionButton={
        <Tooltip label={`Visit ${SUPPORTED_PLATFORMS_TO_DISPLAY_NAMES[platform]} URL`}>
          <ActionIcon href={liveLink} component="a" target="_blank" variant="light">
            <IconExternalLink size="1rem" />
          </ActionIcon>
        </Tooltip>
      }
    />
  );
}
