import React from "react";
import { Flex, Text } from "gestalt";
import "gestalt/dist/gestalt.css";
import { InstagramMediaResponse } from "social/fb/InstagramUtils";
import moment from "moment";
import Spacer from "components/Spacer";

export const MediaStats = ({ mediaResponse }: { mediaResponse: InstagramMediaResponse }) => (
  <Flex direction="column">
    <Text size="200">{mediaResponse.caption}</Text>
    <Spacer height={6} />
    <Text size="100" color="subtle">
      {mediaResponse.like_count} Likes
    </Text>
    <Text size="100" color="subtle">
      {mediaResponse.comments_count} Comments
    </Text>
    <Text size="100" color="subtle">
      {moment(mediaResponse.creation_timestamp).fromNow()}
    </Text>
  </Flex>
);

export default null;
