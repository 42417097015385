import React, { useEffect, useRef } from "react";

import { Button, Flex, Stack, Text } from "@mantine/core";

import { useAuthState } from "react-firebase-hooks/auth";

import { auth } from "auth/firebaseAuthHelpers";
import { sendEmailVerification, User } from "firebase/auth";
import LoadingScreen from "components/LoadingScreen";

const sendEmail = async (user: User) => {
  await sendEmailVerification(user);
};

export const ResendEmail = ({ user }: { user: User }) => (
  <Stack gap="md" style={{ textAlign: "center", maxWidth: 450 }}>
    <Text>Didn&apos;t get the email? Check your spam folder or click the button below.</Text>
    <Stack align="center">
      <Button size="sm" onClick={() => sendEmail(user)}>
        Resend Email
      </Button>
    </Stack>
  </Stack>
);

const EmailVerification = () => {
  const [user, loading] = useAuthState(auth);
  const emailSent = useRef(false);

  useEffect(() => {
    if (user && !emailSent.current) {
      sendEmail(user);
      emailSent.current = true;
    }
  }, [user]);

  if (loading) return <LoadingScreen />;
  return <ResendEmail user={user} />;
};

export default EmailVerification;
