/** eslint-disable prettier/prettier */
/** eslint-disable no-console */
import React, { useEffect, useState } from "react";

import {
  IconCheck,
  IconChevronDown,
  IconChevronUp,
  IconCircleCheck,
  IconCircleX,
  IconSelector,
} from "@tabler/icons-react";

import {
  ActionIcon,
  Anchor,
  Center,
  Chip,
  Flex,
  Group,
  Loader,
  Paper,
  Progress,
  RingProgress,
  Stack,
  Table,
  Text,
  ThemeIcon,
  Title,
  Tooltip,
  UnstyledButton,
  rem,
} from "@mantine/core";

import { toShortDateString } from "utils/DateUtils";

import { API_URL } from "configs/Configs";
import { createRequestWithFirebaseToken, handleResult } from "utils/ApiUtils";

async function getBrandOnboardingStatus() {
  const request = await createRequestWithFirebaseToken({
    url: `${API_URL}/api/onboarding/get_brand_onboarding_status`,
    method: "GET",
  });

  const result = await handleResult(request);

  return result;
}

interface BrandOnboardingStatus {
  date_created: Date;
  brand_id: number;
  display_name: string;
  has_display_name: boolean;
  has_website: boolean;
  has_blurb: boolean;
  has_contact_email: boolean;
  has_billing_email: boolean;
  has_outreach_email: boolean;
  num_brand_settings_complete: number;
  has_creative_brief: boolean;
  accepted_brand_tos: boolean;
  has_completed_call: boolean;
  num_activated: number;
  num_saved: number;
  activations_complete: boolean;
  onboarding_activation_target: number;
  has_internal_slack_channel: boolean;
  outreach_email_configured: boolean;
  has_active_campaign: boolean;
  skip_onboarding: boolean;
}

interface ThProps {
  children: React.ReactNode;
  reversed: boolean;
  sorted: boolean;
  onSort(): void;
  alignLeft?: boolean;
}

function Th({ children, reversed, sorted, onSort, alignLeft }: ThProps) {
  let Icon: React.ElementType;

  if (sorted) {
    if (reversed) {
      Icon = IconChevronDown;
    } else {
      Icon = IconChevronUp;
    }
  } else {
    Icon = IconSelector;
  }

  return (
    <Table.Th>
      <UnstyledButton onClick={onSort}>
        <Flex
          align="center"
          gap="sm"
          justify={alignLeft ? "left" : "center"}
          px="xs"
          ml={alignLeft ? "xs" : 0}>
          <Text fw={600}>{children}</Text>
          <Center>
            <Icon size="0.9rem" stroke={1.5} />
          </Center>
        </Flex>
      </UnstyledButton>
    </Table.Th>
  );
}

function sortData(
  data: BrandOnboardingStatus[],
  payload: { sortBy: keyof BrandOnboardingStatus | null; reversed: boolean; search: string },
) {
  const { sortBy } = payload;

  if (!sortBy) {
    return data;
  }

  return [...data].sort((a, b) => {
    let compareResult = 0;

    if (typeof a[sortBy] === "number" && typeof b[sortBy] === "number") {
      compareResult = (a[sortBy] as number) - (b[sortBy] as number);
    } else if (a[sortBy] instanceof Date && b[sortBy] instanceof Date) {
      compareResult = (a[sortBy] as Date).getTime() - (b[sortBy] as Date).getTime();
    } else {
      compareResult = a[sortBy].toString().localeCompare(b[sortBy].toString());
    }

    return payload.reversed ? -compareResult : compareResult;
  });
}

function DateCreated({ dateCreated }: { dateCreated: Date }) {
  return <Text fw={500}>{toShortDateString(dateCreated)}</Text>;
}

function BrandSettingsComplete({
  onboardingDetails,
}: {
  onboardingDetails: BrandOnboardingStatus;
}) {
  const requiredSettings = [
    onboardingDetails.has_display_name,
    onboardingDetails.has_website,
    onboardingDetails.has_blurb,
    onboardingDetails.has_contact_email,
    onboardingDetails.has_billing_email,
    // TODO(albert): Enable when we start including outreach email in onboarding
    // onboardingDetails.has_outreach_email,
  ];

  const settingsComplete = requiredSettings.every((setting) => setting);
  const numSettingsComplete = requiredSettings.filter((setting) => setting).length;
  const numSettings = requiredSettings.length;

  const progressValue = settingsComplete ? 100 : (numSettingsComplete / numSettings) * 100;

  const tooltipContent = [];

  if (!onboardingDetails.has_display_name) {
    tooltipContent.push("Display Name");
  }
  if (!onboardingDetails.has_website) {
    tooltipContent.push("Website");
  }
  if (!onboardingDetails.has_blurb) {
    tooltipContent.push("Blurb");
  }
  if (!onboardingDetails.has_contact_email) {
    tooltipContent.push("Contact Email");
  }
  if (!onboardingDetails.has_billing_email) {
    tooltipContent.push("Billing Email");
  }
  // if (!onboardingDetails.has_outreach_email) {
  //   tooltipContent.push("Outreach Email");
  // }

  const tooltipLabel = `Missing: ${tooltipContent.join(", ")}`;

  return (
    <RingProgress
      size={60}
      thickness={8}
      sections={[{ value: progressValue, color: settingsComplete ? "teal" : "yellow" }]}
      label={
        settingsComplete ? (
          <Center>
            <ActionIcon color="teal" variant="light" radius="xl" size="xl">
              <IconCheck style={{ width: rem(22), height: rem(22) }} />
            </ActionIcon>
          </Center>
        ) : (
          <Tooltip label={tooltipLabel}>
            <Center>
              <ActionIcon variant="transparent" color="black">
                <Text fw={500} size="xs">
                  {numSettingsComplete}/{numSettings}
                </Text>
              </ActionIcon>
            </Center>
          </Tooltip>
        )
      }
    />
  );
}

function BooleanIcon({ value }: { value: boolean }) {
  if (value) {
    return (
      <ThemeIcon variant="light" color="green" size="sm" radius="xl">
        <IconCircleCheck />
      </ThemeIcon>
    );
  }

  return (
    <ThemeIcon variant="light" color="red" size="sm" radius="xl">
      <IconCircleX />
    </ThemeIcon>
  );
}

function NumActivatedCreators({
  numActivated,
  targetActivated,
  numSaved,
}: {
  numActivated: number;
  targetActivated: number;
  numSaved: number;
}) {
  const activationsComplete = numActivated >= targetActivated;

  let color = "gray";
  if (activationsComplete) {
    color = "teal";
  } else if (numActivated > 0) {
    color = "yellow";
  }

  const activatedValue = (numActivated / targetActivated) * 100;
  const savedValue = (numSaved / targetActivated) * 100;

  return (
    <Stack gap={2}>
      <Progress.Root size={15}>
        <Progress.Section value={activatedValue} color={color}>
          {activationsComplete ? <Progress.Label>Complete</Progress.Label> : null}
        </Progress.Section>
        {!activationsComplete && <Progress.Section value={savedValue} />}
      </Progress.Root>
      <Text size="xs" fw="500">
        {numActivated} {!activationsComplete && `/ ${targetActivated} `}Activated
      </Text>
      <Text size="xs" fw="500">
        {numSaved} Saved
      </Text>
    </Stack>
  );
}

function Row({ onboardingDetails }: { onboardingDetails: BrandOnboardingStatus }) {
  return (
    <Table.Tr>
      <Table.Td pl="md">
        <DateCreated dateCreated={onboardingDetails.date_created} />
      </Table.Td>
      <Table.Td pl="md">
        <Anchor
          href={`https://www.1stcollab.com/admin/brands?brandId=${onboardingDetails.brand_id}`}
          target="_blank">
          <Text fw={500}>{onboardingDetails.display_name}</Text>
        </Anchor>
      </Table.Td>
      <Table.Td pl="md">
        <BrandSettingsComplete onboardingDetails={onboardingDetails} />
      </Table.Td>
      <Table.Td pl="md">
        <BooleanIcon value={onboardingDetails.accepted_brand_tos} />
      </Table.Td>
      <Table.Td pl="md">
        <BooleanIcon value={onboardingDetails.has_completed_call} />
      </Table.Td>
      <Table.Td pl="md">
        <BooleanIcon value={onboardingDetails.has_creative_brief} />
      </Table.Td>
      <Table.Td pl="md">
        <BooleanIcon value={onboardingDetails.has_internal_slack_channel} />
      </Table.Td>
      <Table.Td pl="md">
        <BooleanIcon value={onboardingDetails.has_active_campaign} />
      </Table.Td>
      <Table.Td pl="md">
        <BooleanIcon value={onboardingDetails.outreach_email_configured} />
      </Table.Td>
      <Table.Td pl="md">
        <NumActivatedCreators
          numActivated={onboardingDetails.num_activated}
          targetActivated={onboardingDetails.onboarding_activation_target}
          numSaved={onboardingDetails.num_saved}
        />
      </Table.Td>
    </Table.Tr>
  );
}

export function TableSort({
  brandOnboardingStatus,
  setBrandOnboardingStatus,
}: {
  brandOnboardingStatus: BrandOnboardingStatus[];
  setBrandOnboardingStatus: (data: BrandOnboardingStatus[]) => void;
}) {
  const [sortBy, setSortBy] = useState<keyof BrandOnboardingStatus | null>(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);
  const [hideSkippedOnboarding, setHideSkippedOnboarding] = useState(true);

  const setSorting = (field: keyof BrandOnboardingStatus) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
    setBrandOnboardingStatus(
      sortData(brandOnboardingStatus, { sortBy: field, reversed, search: "" }),
    );
  };

  const rows = brandOnboardingStatus
    .filter((onboardingDetails) => !hideSkippedOnboarding || !onboardingDetails.skip_onboarding)
    .map((onboardingDetails) => (
      <Row key={onboardingDetails.brand_id} onboardingDetails={onboardingDetails} />
    ));

  return (
    <Stack>
      <Group justify="space-between">
        <Title order={3} fw="500">
          Verified Brands
        </Title>
        <Flex gap="xs" justify="right" mr="md">
          <Chip
            checked={hideSkippedOnboarding}
            size="xs"
            onChange={() => setHideSkippedOnboarding((v) => !v)}>
            Hide Skipped Onboarding
          </Chip>
        </Flex>
      </Group>
      <Table.ScrollContainer minWidth={500}>
        <Table withTableBorder horizontalSpacing="xs" verticalSpacing="xs" highlightOnHover>
          <Table.Thead>
            <Table.Tr>
              <Th
                sorted={sortBy === "date_created"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("date_created")}>
                Date Created
              </Th>
              <Th
                sorted={sortBy === "display_name"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("display_name")}>
                Brand
              </Th>
              <Th
                sorted={sortBy === "num_brand_settings_complete"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("num_brand_settings_complete")}>
                Brand Settings
              </Th>
              <Th
                sorted={sortBy === "accepted_brand_tos"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("accepted_brand_tos")}>
                Accepted TOS
              </Th>
              <Th
                sorted={sortBy === "has_completed_call"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("has_completed_call")}>
                Completed Call
              </Th>
              <Th
                sorted={sortBy === "has_creative_brief"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("has_creative_brief")}>
                Creative Brief
              </Th>
              <Th
                sorted={sortBy === "has_internal_slack_channel"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("has_internal_slack_channel")}>
                Slack Channel
              </Th>
              <Th
                sorted={sortBy === "has_active_campaign"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("has_active_campaign")}>
                Active Campaign
              </Th>
              <Th
                sorted={sortBy === "outreach_email_configured"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("outreach_email_configured")}>
                Outreach Email Configured
              </Th>
              <Th
                sorted={sortBy === "activations_complete"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("activations_complete")}>
                Activated Creators
              </Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
      </Table.ScrollContainer>
    </Stack>
  );
}

export default function BrandOnboardingDashboard() {
  const [brandOnboardingStatus, setBrandOnboardingStatus] = useState<BrandOnboardingStatus[]>([]);
  const [loading, setLoading] = useState(true);

  // Update Contracts
  useEffect(() => {
    getBrandOnboardingStatus()
      .then((response) => {
        const { success, onboardingDetails } = response;

        if (success) {
          setBrandOnboardingStatus(onboardingDetails);
        } else {
          // eslint-disable-next-line no-console
          console.error("Failed to fetch brand onboarding details.");
        }
      })
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  return (
    <Paper p="md">
      <TableSort
        brandOnboardingStatus={brandOnboardingStatus}
        setBrandOnboardingStatus={setBrandOnboardingStatus}
      />
    </Paper>
  );
}
