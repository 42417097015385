import React, { useState } from "react";
import { TextField, Flex, Text, Box } from "gestalt";
import "gestalt/dist/gestalt.css";

import Spacer from "components/Spacer";

const EditDisplayName = ({
  name,
  onChange,
  setIsValid,
}: {
  name: string;
  onChange: (value: string) => void;
  setIsValid?: (isValid: boolean) => void;
}) => (
  <Box width={400}>
    <TextField
      id="NameTextField"
      label="Name"
      helperText="Your display name on 1stCollab"
      value={name}
      onChange={({ value }) => {
        // make sure you can't save a blank name
        if (setIsValid) setIsValid(Boolean(value));
        onChange(value);
      }}
    />
    <Spacer height={16} />
  </Box>
);

export default EditDisplayName;
