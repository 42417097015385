import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "reduxStore/store";

const selectCreativeBriefsMap = (state: RootState) => state.creativeBriefs.allCreativeBriefs;
const selectFetchAllCreativeBriefsList = (state: RootState) => state.creativeBriefs.fetchAllOrdered;

export const selectAllCreativeBriefs = createSelector(
  [selectCreativeBriefsMap, selectFetchAllCreativeBriefsList],
  (creativeBriefs, fetchAllIdList) => {
    return fetchAllIdList.map((id) => creativeBriefs[id]).filter((brief) => brief !== null);
  },
);

export default null;
