import React, { useState } from "react";

import { User } from "firebase/auth";

import { ActionIcon, Button, Group, Menu, Modal, Select, Stack, Text } from "@mantine/core";

import { DatePickerInput } from "@mantine/dates";

import { useDisclosure } from "@mantine/hooks";

import { IconCalendar, IconEdit, IconLink, IconSettings, IconTrash } from "@tabler/icons-react";

import {
  ContractDeliverableStatus,
  ContractDeliverableStatusMapping,
  ReverseContractDeliverableStatusMapping,
} from "components/contracts/models/Deliverable";

import {
  removeLatestVideoSubmission,
  updateDeliverableStatus,
  updateDeliverableTimeline,
} from "components/contracts/common/Api";

import {
  DateUpdateType,
  SHORT_FORM_FORMATS,
  SUPPORTED_FORMATS_TO_LABELS,
} from "components/contracts/common/Common";
import { SupportedFormat } from "models/Common";
import DeliverableTimeline from "components/contracts/contract/DeliverableTimeline";

import { showFailureNotification, showSuccessNotification } from "components/common/Notifications";

import { toISODateString, toMediumDateString } from "utils/DateUtils";

import AddReferralLinkModals from "components/contracts/dashboard/AddReferralLinkModals";

function EditDeliverableDates({
  deliverableId,
  format,
  deliverableStatus,
  timeline,
  opened,
  close,
  handleRefetchDeliverable,
}: {
  deliverableId: string;
  format: SupportedFormat;
  deliverableStatus: ContractDeliverableStatus;
  timeline: DeliverableTimeline;
  opened: boolean;
  close: () => void;
  handleRefetchDeliverable: (deliverableId: string, handleFetched: () => void) => void;
}) {
  const icon = <IconCalendar size="1rem" />;

  const [loading, setLoading] = useState(false);
  const [timelineState, setTimelineState] = useState(timeline);

  const handleDateUpdate = (dateUpdateType: DateUpdateType, newDate: Date) => {
    setTimelineState(timelineState.updateDate({ dateUpdateType, newDate, format }));
  };

  const handleUseDefaultTimeline = () => {
    const newTimeline = timelineState.adjustTimeline({
      isShortForm: SHORT_FORM_FORMATS.includes(format),
    });

    const scriptApproved =
      ContractDeliverableStatusMapping[deliverableStatus] >=
      ContractDeliverableStatusMapping[ContractDeliverableStatus.SCRIPT_APPROVED];
    const videoApproved =
      ContractDeliverableStatusMapping[deliverableStatus] >=
      ContractDeliverableStatusMapping[ContractDeliverableStatus.VIDEO_DRAFT_APPROVED];
    const videoLive =
      ContractDeliverableStatusMapping[deliverableStatus] >=
      ContractDeliverableStatusMapping[ContractDeliverableStatus.LIVE_CONTENT_APPROVED];
    const analyticsApproved =
      ContractDeliverableStatusMapping[deliverableStatus] >=
      ContractDeliverableStatusMapping[ContractDeliverableStatus.ASSETS_AND_ANALYTICS_APPROVED];

    setTimelineState(
      new DeliverableTimeline({
        ...newTimeline,
        scriptDate: scriptApproved ? timelineState.scriptDate : newTimeline.scriptDate,
        scriptRevisionDate: scriptApproved
          ? timelineState.scriptRevisionDate
          : newTimeline.scriptRevisionDate,
        videoDraftDate: videoApproved ? timelineState.videoDraftDate : newTimeline.videoDraftDate,
        approvalDate: videoApproved ? timelineState.approvalDate : newTimeline.approvalDate,
        liveDate: videoLive ? timelineState.liveDate : newTimeline.liveDate,
        analyticsDate: analyticsApproved ? timelineState.analyticsDate : newTimeline.analyticsDate,
      }),
    );
  };

  const handleUpdateDeliverableTimeline = () => {
    setLoading(true);
    updateDeliverableTimeline({
      deliverableId,
      scriptDateString: timelineState.scriptDate ? toISODateString(timelineState.scriptDate) : "",
      scriptRevisionDateString: timelineState.scriptRevisionDate
        ? toISODateString(timelineState.scriptRevisionDate)
        : "",
      videoDraftDateString: timelineState.videoDraftDate
        ? toISODateString(timelineState.videoDraftDate)
        : "",
      approvalDateString: timelineState.approvalDate
        ? toISODateString(timelineState.approvalDate)
        : "",
      liveDateString: timelineState.liveDate ? toISODateString(timelineState.liveDate) : "",
      analyticsDateString: timelineState.analyticsDate
        ? toISODateString(timelineState.analyticsDate)
        : "",
      overrideLiveWindow: true,
    })
      .then((response) => {
        const { success } = response;
        if (success) {
          handleRefetchDeliverable(deliverableId, () => {
            showSuccessNotification({
              message: "Successfully updated deliverable timeline.",
            });
            setLoading(false);
            close();
          });
        } else {
          showFailureNotification({
            message: `Failed to update deliverable timeline. ${response.error}.`,
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        showFailureNotification({ message: `Failed to update deliverable timeline. ${error}.` });
        setLoading(false);
      });
  };

  return (
    <Modal
      opened={opened}
      onClose={close}
      size="auto"
      title={<Text fw="600">Edit Deliverable Dates</Text>}>
      <Stack gap="xs">
        <Group gap="sm" align="flex-end">
          <DatePickerInput
            label="Live Date"
            size="xs"
            firstDayOfWeek={0}
            value={timelineState.liveDate}
            onChange={(value) => handleDateUpdate(DateUpdateType.LIVE, value)}
            leftSection={icon}
          />
          <Group align="center">
            <Button
              key={`campaignLiveDate-${deliverableId}`}
              variant="light"
              size="xs"
              onClick={handleUseDefaultTimeline}>
              Use Default Timeline
            </Button>
          </Group>
        </Group>
        <Group>
          {timelineState.requiresScriptReview && (
            <DatePickerInput
              label="Script Due Date"
              size="xs"
              firstDayOfWeek={0}
              value={timelineState.scriptDate}
              onChange={(value) => handleDateUpdate(DateUpdateType.SCRIPT, value)}
              leftSection={icon}
            />
          )}
          {timelineState.requiresScriptReview && (
            <DatePickerInput
              label="Script Revision Due Date"
              size="xs"
              firstDayOfWeek={0}
              value={timelineState.scriptRevisionDate}
              onChange={(value) => handleDateUpdate(DateUpdateType.SCRIPT_REVISION, value)}
              leftSection={icon}
            />
          )}
          {timelineState.requiresVideoReview && (
            <DatePickerInput
              label="Video Draft Due Date"
              size="xs"
              firstDayOfWeek={0}
              value={timelineState.videoDraftDate}
              onChange={(value) => handleDateUpdate(DateUpdateType.VIDEO_DRAFT, value)}
              leftSection={icon}
            />
          )}
          {timelineState.requiresVideoReview && (
            <DatePickerInput
              label="Video Draft Approval Date"
              size="xs"
              firstDayOfWeek={0}
              value={timelineState.approvalDate}
              onChange={(value) => handleDateUpdate(DateUpdateType.APPROVAL, value)}
              leftSection={icon}
            />
          )}
          <DatePickerInput
            label="Analytics Due Date"
            size="xs"
            firstDayOfWeek={0}
            value={timelineState.analyticsDate}
            onChange={(value) => handleDateUpdate(DateUpdateType.ANALYTICS, value)}
            leftSection={icon}
          />
        </Group>
        <Group mt="xs" justify="right" gap="xs">
          <Button variant="default" size="sm" onClick={close}>
            Cancel
          </Button>
          <Button size="sm" loading={loading} onClick={handleUpdateDeliverableTimeline}>
            Save
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
}

export default function EditDeliverableMenu({
  user,
  deliverableId,
  format,
  timeline,
  currentDeliverableStatus,
  requiresReferralLink,
  missingReferralLink,
  handleRefetchDeliverable,
}: {
  user: User;
  deliverableId: string;
  format: SupportedFormat;
  timeline: DeliverableTimeline;
  currentDeliverableStatus: ContractDeliverableStatus;
  requiresReferralLink: boolean;
  missingReferralLink: boolean;
  handleRefetchDeliverable: (deliverableId: string, handleFetched: () => void) => void;
}) {
  const [updateStatusModalOpened, { open: openUpdateStatusModal, close: closeUpdateStatusModal }] =
    useDisclosure();
  const [
    editDeliverableDatesModalOpened,
    { open: openEditDeliverableDatesModal, close: closeEditDeliverableDatesModal },
  ] = useDisclosure();
  const [openedAddReferralLink, { open: openAddReferralLink, close: closeAddReferralLink }] =
    useDisclosure();
  const [openedDeleteVideo, { open: openDeleteVideo, close: closeDeleteVideo }] = useDisclosure();

  const [newDeliverableStatus, setNewDeliverableStatus] = useState(null);
  const [isLoadingStatus, setIsLoadingStatus] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  const snakeCaseToTitleCase = (str: string) =>
    str
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");

  const deliverableStatusOptions = Object.entries(ContractDeliverableStatus).map(
    ([key, value]) => ({
      value,
      label: snakeCaseToTitleCase(value),
    }),
  );

  const handleUpdateDeliverableStatus = () => {
    setIsLoadingStatus(true);
    updateDeliverableStatus(
      user,
      deliverableId,
      ContractDeliverableStatusMapping[
        newDeliverableStatus as ContractDeliverableStatus
      ].toString(),
    )
      .then((response) => {
        const { success, status } = response;
        if (success) {
          handleRefetchDeliverable(deliverableId, () => {
            const newStatus = ReverseContractDeliverableStatusMapping[status];
            showSuccessNotification({
              message: (
                <Text>
                  <Text span>Successfully updated deliverable status to </Text>
                  <Text span fw="500">
                    {snakeCaseToTitleCase(newStatus)}
                  </Text>
                  .
                </Text>
              ),
            });
          });
        } else {
          showFailureNotification({
            message: `Failed to update deliverable status. ${response.error}.`,
          });
        }
        setIsLoadingStatus(false);
        closeUpdateStatusModal();
      })
      .catch((error) => {
        showFailureNotification({ message: `Failed to update deliverable status. ${error}.` });
        setIsLoadingStatus(false);
        closeUpdateStatusModal();
      });
  };

  const handleDeleteVideoDraft = () => {
    setIsLoadingDelete(true);
    removeLatestVideoSubmission({ deliverableId })
      .then((response) => {
        const { success } = response;
        if (success) {
          handleRefetchDeliverable(deliverableId, () => {
            showSuccessNotification({
              message: "Successfully deleted video draft.",
            });
          });
        } else {
          showFailureNotification({
            message: `Failed to delete video draft. ${response.error}.`,
          });
        }
        setIsLoadingDelete(false);
        closeDeleteVideo();
      })
      .catch((error) => {
        showFailureNotification({ message: `Failed to delete video draft. ${error}.` });
        setIsLoadingDelete(false);
        closeDeleteVideo();
      });
  };

  return (
    <>
      <Modal
        opened={openedDeleteVideo}
        onClose={closeDeleteVideo}
        size="sm"
        title={<Text fw="600">Delete Video Draft</Text>}>
        <Stack gap="xs">
          <Text>
            Are you sure you want to delete the video draft for{" "}
            <Text span fw="500">
              {SUPPORTED_FORMATS_TO_LABELS[format]}
            </Text>
            , due on{" "}
            <Text span fw="500">
              {toMediumDateString(timeline.liveDate)}
            </Text>
            ? This action is irreversible.{" "}
          </Text>
          <Group justify="space-between" grow>
            <Button variant="default" onClick={closeDeleteVideo}>
              Cancel
            </Button>
            <Button
              loading={isLoadingDelete}
              leftSection={<IconTrash size={14} />}
              color="red"
              onClick={handleDeleteVideoDraft}>
              Delete
            </Button>
          </Group>
        </Stack>
      </Modal>
      <Modal
        opened={updateStatusModalOpened}
        onClose={closeUpdateStatusModal}
        size="sm"
        title={<Text fw="600">Edit Deliverable Status</Text>}>
        <Stack gap="xs">
          <Text>
            <Text span size="sm" fw="500">
              Current Status
            </Text>
            :{" "}
            <Text span size="sm" fs="italic">
              {snakeCaseToTitleCase(currentDeliverableStatus)}
            </Text>
          </Text>
          <Select
            label="New Status"
            data={deliverableStatusOptions}
            defaultValue={currentDeliverableStatus}
            onChange={setNewDeliverableStatus}
          />
          <Button loading={isLoadingStatus} onClick={handleUpdateDeliverableStatus}>
            Update Deliverable Status
          </Button>
        </Stack>
      </Modal>
      {requiresReferralLink && missingReferralLink && (
        <AddReferralLinkModals
          deliverableId={deliverableId}
          format={format}
          opened={openedAddReferralLink}
          open={openAddReferralLink}
          close={closeAddReferralLink}
          handleRefetchDeliverable={handleRefetchDeliverable}
        />
      )}
      <EditDeliverableDates
        deliverableId={deliverableId}
        format={format}
        deliverableStatus={currentDeliverableStatus}
        timeline={timeline}
        opened={editDeliverableDatesModalOpened}
        close={closeEditDeliverableDatesModal}
        handleRefetchDeliverable={handleRefetchDeliverable}
      />
      <Menu shadow="md" width={220}>
        <Menu.Target>
          <ActionIcon variant="subtle" color="gray">
            <IconSettings size="1.1rem" />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
          {requiresReferralLink && (
            <>
              <Menu.Item
                disabled={!missingReferralLink}
                leftSection={<IconLink size={14} />}
                onClick={openAddReferralLink}>
                Add Referral Link
              </Menu.Item>
              <Menu.Divider />
            </>
          )}
          <Menu.Item disabled leftSection={<IconEdit size={14} />} onClick={openUpdateStatusModal}>
            Edit Deliverable Status
          </Menu.Item>
          <Menu.Item leftSection={<IconEdit size={14} />} onClick={openEditDeliverableDatesModal}>
            Edit Deliverable Dates
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item
            disabled={
              !timeline.requiresVideoReview ||
              ![
                ContractDeliverableStatus.WAITING_FOR_LIVE_CONTENT,
                ContractDeliverableStatus.VIDEO_DRAFT_APPROVED,
                ContractDeliverableStatus.VIDEO_DRAFT_SUBMITTED,
              ].includes(currentDeliverableStatus)
            }
            color="red"
            leftSection={<IconTrash size={14} />}
            onClick={openDeleteVideo}>
            Delete Video Draft
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </>
  );
}
