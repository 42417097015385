import React, { useEffect, useState } from "react";

import { AppShell, Flex, LoadingOverlay } from "@mantine/core";
import { Outlet, useOutletContext } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "auth/firebaseAuthHelpers";
import { useUser } from "utils/UserContext";
import { User as FirebaseUser } from "firebase/auth";
import { User } from "models/User";

import AdminNavbar from "admin/navbar/AdminNavbar";
import { getOpsUsers } from "admin/api/opsApi";
import { Brand, getAndSetBrands } from "models/Brand";
import { Campaign, CampaignParametersV2 } from "models/Campaign";
import { CreatorSet } from "components/discovery/Datamodels";
import { fetchAllCreatorSets } from "campaigns/api/fetchCreatorSets";
import { getAndSetCampaigns } from "components/campaign/CampaignUtils";
import { fetchAllCampaignParameters } from "campaigns/api/fetchCampaignParameters";

const NAV_BAR_WIDTH = 250;

export type AdminAppContext = {
  user: FirebaseUser | null;
  userProfile: User | null;
  opsUsers: User[];
  brands: Brand[];
  campaigns: Campaign[];
  creatorSets: CreatorSet[];
  // TODO(andrew): refactor this into redux
  setCreatorSets: (creatorSets: CreatorSet[]) => void;
  campaignParameters: CampaignParametersV2[];
  // TODO(andrew): add more context here
  // like campaigns, brands etc
};

export function useAdminAppContext() {
  return useOutletContext<AdminAppContext>();
}

const AdminAppShell = () => {
  const [userProfile, userProfileLoading] = useUser();
  const [firebaseUser, loading] = useAuthState(auth);
  const [opsUsers, setOpsUsers] = useState<User[]>([]);
  const [brands, setBrands] = useState<Brand[]>([]);
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [creatorSets, setCreatorSets] = useState<CreatorSet[]>([]);
  const [campaignParameters, setCampaignParameters] = useState<CampaignParametersV2[]>([]);

  useEffect(() => {
    const abortController = new AbortController();
    if (firebaseUser != null) {
      getOpsUsers(firebaseUser, setOpsUsers);
      getAndSetBrands(firebaseUser, setBrands, abortController);
      getAndSetCampaigns(firebaseUser, setCampaigns, abortController);
      fetchAllCreatorSets(firebaseUser, setCreatorSets, abortController);
      fetchAllCampaignParameters(firebaseUser, setCampaignParameters, abortController);
    }
    return () => {
      abortController.abort();
    };
  }, [firebaseUser]);

  if (userProfileLoading)
    return <LoadingOverlay visible zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />;

  return (
    <AppShell
      navbar={{
        width: NAV_BAR_WIDTH,
        breakpoint: "sm",
      }}
      padding="md">
      <AppShell.Navbar withBorder>
        <Flex gap={0} h="100%">
          <AdminNavbar style={{ width: NAV_BAR_WIDTH }} userProfile={userProfile} />
        </Flex>
      </AppShell.Navbar>

      <AppShell.Main bg="var(--mantine-color-gray-1)">
        <Outlet
          context={{
            user: firebaseUser,
            userProfile,
            opsUsers,
            brands,
            campaigns,
            creatorSets,
            setCreatorSets,
            campaignParameters,
          }}
        />
      </AppShell.Main>
    </AppShell>
  );
};

export default AdminAppShell;
