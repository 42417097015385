import React, { useEffect, useState } from "react";
import { User } from "firebase/auth";

import {
  ActionIcon,
  Anchor,
  Container,
  Button,
  Flex,
  Group,
  Menu,
  Modal,
  Stack,
  Switch,
  Table,
  Text,
  TextInput,
  Title,
  LoadingOverlay,
} from "@mantine/core";

import { useDisclosure } from "@mantine/hooks";

import {
  IconCirclePlus,
  IconCircleX,
  IconEdit,
  IconExternalLink,
  IconMenu2,
  IconSend,
} from "@tabler/icons-react";

import {
  getInvoices,
  sendInvoice,
  voidInvoice,
  updateBillingEmail,
} from "components/contracts/common/Api";

import { showFailureNotification, showSuccessNotification } from "components/common/Notifications";

import InvoiceModel, { InvoiceStatus } from "components/contracts/models/Invoice";
import InvoiceStatusBadge from "components/contracts/billing/InvoiceStatusBadge";

import { toFormattedMonthYearString, toShortDateString } from "utils/DateUtils";

import { formatAmount } from "components/contracts/dashboard/Utils";

function ManageInvoiceButton({
  user,
  brandName,
  invoiceId,
  invoiceStatus,
  setInvoiceStatus,
  setStripeInvoiceUrl,
  disableSend,
}: {
  user: User;
  brandName: string;
  invoiceId: string;
  invoiceStatus: InvoiceStatus;
  setInvoiceStatus(status: InvoiceStatus): void;
  setStripeInvoiceUrl(url: string): void;
  disableSend: boolean;
}) {
  const [loading, setLoading] = useState(false);
  const [opened, { open, close }] = useDisclosure();

  const handleSendInvoice = () => {
    setLoading(true);
    close();
    sendInvoice({ user, invoiceId })
      .then((response) => {
        const { success, error, status, stripeInvoiceUrl } = response;

        if (success) {
          setInvoiceStatus(status);
          setStripeInvoiceUrl(stripeInvoiceUrl);
          showSuccessNotification({
            message: (
              <Text>
                Successfully sent{" "}
                <Anchor href={stripeInvoiceUrl} target="_blank">
                  invoice
                </Anchor>{" "}
                to{" "}
                <Text span fw="500">
                  {brandName}
                </Text>
                .
              </Text>
            ),
          });
        } else {
          showFailureNotification({ message: error });
        }
      })
      .finally(() => setLoading(false));
  };

  const handleVoidInvoice = () => {
    setLoading(true);
    close();
    voidInvoice({ user, invoiceId })
      .then((response) => {
        const { success, error, status } = response;

        if (success) {
          setInvoiceStatus(status);
          showSuccessNotification({ message: `Successfully voided ${brandName}'s invoice.` });
        } else {
          showFailureNotification({ message: error });
        }
      })
      .finally(() => setLoading(false));
  };

  if (invoiceStatus === InvoiceStatus.DRAFT) {
    return (
      <>
        <Modal opened={opened} onClose={close} size="sm" title={<Text fw="600">Send Invoice</Text>}>
          <Stack gap="xs">
            <Text size="sm">
              Send the invoice to{" "}
              <Text span fw="500">
                {brandName}
              </Text>
              ?
            </Text>
            <Group grow justify="center">
              <Button size="xs" variant="default" color="gray" onClick={close}>
                Cancel
              </Button>
              <Button size="xs" onClick={handleSendInvoice}>
                Confirm
              </Button>
            </Group>
          </Stack>
        </Modal>
        <Button
          rightSection={<IconSend size="0.8rem" />}
          size="xs"
          onClick={open}
          loading={loading}
          disabled={disableSend}>
          Send Invoice
        </Button>
      </>
    );
  } else if (invoiceStatus === InvoiceStatus.OPEN) {
    return (
      <>
        <Modal opened={opened} onClose={close} size="sm" title={<Text fw="600">Void Invoice</Text>}>
          <Stack gap="xs">
            <Text size="sm">
              Are you sure you want to void the invoice for{" "}
              <Text span fw="500">
                {brandName}
              </Text>
              ?
            </Text>
            <Group grow justify="center">
              <Button size="xs" variant="default" color="gray" onClick={close}>
                Cancel
              </Button>
              <Button size="xs" onClick={handleVoidInvoice}>
                Confirm
              </Button>
            </Group>
          </Stack>
        </Modal>
        <Button
          rightSection={<IconCircleX size="0.8rem" />}
          color="red"
          size="xs"
          onClick={open}
          loading={loading}>
          Void Invoice
        </Button>
      </>
    );
  }

  return null;
}

function Amount({ invoice }: { invoice: InvoiceModel }) {
  if (invoice.status === InvoiceStatus.PAID) {
    return <Text>{formatAmount(invoice.amount_paid)}</Text>;
  } else if (invoice.status === InvoiceStatus.OPEN) {
    return <Text>{formatAmount(invoice.amount_invoiced)}</Text>;
  }

  return <Text>{formatAmount(invoice.base_payment + invoice.platform_payment)}</Text>;
}

function BillingEmail({
  user,
  invoiceId,
  brandName,
  billingEmail,
  setBillingEmail,
  disabled,
}: {
  user: User;
  invoiceId: string;
  brandName: string;
  billingEmail: string;
  setBillingEmail(billingEmail: string): void;
  disabled: boolean;
}) {
  const [opened, { open, close }] = useDisclosure();
  const [loading, setLoading] = useState(false);

  const [newBillingEmail, setNewBillingEmail] = useState(billingEmail);

  const isValidEmail = (email: string) =>
    email && email.length > 0 && email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i);

  const handleUpdateBillingEmail = () => {
    setLoading(true);
    updateBillingEmail({ user, invoiceId, email: newBillingEmail })
      .then((response) => {
        const { success, email } = response;

        if (success) {
          setBillingEmail(email);
          showSuccessNotification({
            message: (
              <Text>
                Successfully updated{" "}
                <Text span fw="500">
                  {brandName}
                </Text>
                &apos;s billing email to{" "}
                <Text span fw="500">
                  {email}
                </Text>
                .
              </Text>
            ),
          });
          close();
        } else {
          showFailureNotification({
            message: "Failed to update billing email.",
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  let button = (
    <Button
      rightSection={<IconCirclePlus size="0.8rem" />}
      variant="light"
      color="red"
      size="xs"
      onClick={open}
      disabled={disabled}>
      Add Billing Email
    </Button>
  );

  if (billingEmail && billingEmail.length > 0) {
    button = (
      <Button
        rightSection={<IconEdit size="0.8rem" />}
        variant="default"
        size="xs"
        onClick={() => {
          setNewBillingEmail(billingEmail);
          open();
        }}
        disabled={disabled}>
        {billingEmail}
      </Button>
    );
  }

  return (
    <>
      <Modal
        opened={opened}
        onClose={close}
        size="sm"
        title={<Text fw="600">Update Billing Email</Text>}>
        <Stack gap="xs">
          <Text size="sm">
            Update the main Billing Email for{" "}
            <Text span fw="500">
              {brandName}
            </Text>
            :
          </Text>
          <TextInput
            onChange={(event) => {
              setNewBillingEmail(event.currentTarget.value);
            }}
            value={newBillingEmail}
            size="sm"
            error={!isValidEmail(newBillingEmail) ? "Invalid email address" : null}
          />
          <Group grow justify="center">
            <Button size="xs" variant="default" color="gray" onClick={close}>
              Cancel
            </Button>
            <Button
              size="xs"
              loading={loading}
              onClick={handleUpdateBillingEmail}
              disabled={billingEmail === newBillingEmail || !isValidEmail(newBillingEmail)}>
              Confirm
            </Button>
          </Group>
        </Stack>
      </Modal>
      {button}
    </>
  );
}

function ActionMenu({
  invoiceId,
  stripeInvoiceUrl,
  stripeCustomerUrl,
}: {
  invoiceId: string;
  stripeInvoiceUrl: string;
  stripeCustomerUrl: string;
}) {
  return (
    <Group justify="center">
      <Menu>
        <Menu.Target>
          <ActionIcon variant="subtle" color="gray">
            <IconMenu2 size="1rem" />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item
            leftSection={<IconExternalLink size="1rem" />}
            component="a"
            href={`https://www.1stcollab.com/admin/invoice/${invoiceId}`}
            target="_blank">
            View Internal Invoice
          </Menu.Item>
          {stripeInvoiceUrl && (
            <Menu.Item
              leftSection={<IconExternalLink size="1rem" />}
              component="a"
              href={stripeInvoiceUrl}
              target="_blank">
              View Stripe Invoice
            </Menu.Item>
          )}
          {stripeCustomerUrl && (
            <Menu.Item
              leftSection={<IconExternalLink size="1rem" />}
              component="a"
              href={stripeCustomerUrl}
              target="_blank">
              View Stripe Customer
            </Menu.Item>
          )}
        </Menu.Dropdown>
      </Menu>
    </Group>
  );
}

function InvoiceRow({ user, invoice }: { user: User; invoice: InvoiceModel }) {
  const [invoiceStatus, setInvoiceStatus] = useState(invoice.status);
  const [billingEmail, setBillingEmail] = useState(invoice.billing_email);
  const [stripeInvoiceUrl, setStripeInvoiceUrl] = useState(invoice.stripe_invoice_url);

  return (
    <Table.Tr key={invoice.hash_id}>
      <Table.Td>{invoice.brand_name}</Table.Td>
      <Table.Td>{toFormattedMonthYearString(invoice.billing_month)}</Table.Td>
      {invoice.status === InvoiceStatus.OPEN && (
        <Table.Td>
          <Text>{toShortDateString(invoice.invoice_date)}</Text>
        </Table.Td>
      )}
      {invoice.status === InvoiceStatus.OPEN && (
        <Table.Td>
          <Text>{toShortDateString(invoice.invoice_due_date)}</Text>
        </Table.Td>
      )}
      {invoice.status === InvoiceStatus.PAID && (
        <Table.Td>
          <Text>{toShortDateString(invoice.payment_date)}</Text>
        </Table.Td>
      )}
      <Table.Td>
        <Amount invoice={invoice} />
      </Table.Td>
      <Table.Td>
        <InvoiceStatusBadge status={invoiceStatus} size="sm" />
      </Table.Td>
      {invoice.status === InvoiceStatus.DRAFT && (
        <Table.Td>
          <BillingEmail
            user={user}
            invoiceId={invoice.hash_id}
            brandName={invoice.brand_name}
            billingEmail={billingEmail}
            setBillingEmail={setBillingEmail}
            disabled={invoiceStatus !== InvoiceStatus.DRAFT}
          />
        </Table.Td>
      )}
      <Table.Td>
        <Group justify="center">
          {invoiceStatus !== InvoiceStatus.PAID && (
            <ManageInvoiceButton
              user={user}
              brandName={invoice.brand_name}
              invoiceId={invoice.hash_id}
              invoiceStatus={invoiceStatus}
              setInvoiceStatus={setInvoiceStatus}
              setStripeInvoiceUrl={setStripeInvoiceUrl}
              disableSend={!billingEmail || billingEmail.length === 0}
            />
          )}
          <ActionMenu
            invoiceId={invoice.hash_id}
            stripeInvoiceUrl={stripeInvoiceUrl}
            stripeCustomerUrl={invoice.stripe_customer_url}
          />
        </Group>
      </Table.Td>
    </Table.Tr>
  );
}

function InvoiceTable({
  user,
  invoices,
  invoiceStatus,
  testMode,
}: {
  user: User;
  invoices: InvoiceModel[];
  invoiceStatus: InvoiceStatus;
  testMode: boolean;
}) {
  if (!invoices || invoices.length === 0) {
    return <Text>No invoices found</Text>;
  }

  const filteredInvoices = invoices.filter((invoice) => invoice.is_test_brand === testMode);

  if (!filteredInvoices || filteredInvoices.length === 0) {
    return <Text>No invoices found</Text>;
  }

  const sortedInvoices = filteredInvoices.sort((a, b) => {
    if (a.billing_month === b.billing_month) {
      return a.brand_name > b.brand_name ? 1 : -1;
    }
    return new Date(b.billing_month).getTime() - new Date(a.billing_month).getTime();
  });

  return (
    <Table withTableBorder horizontalSpacing="xs" verticalSpacing="xs" highlightOnHover>
      <Table.Thead>
        <Table.Tr>
          <Table.Th fw={500}>Brand</Table.Th>
          <Table.Th fw={500}>Billing Month</Table.Th>
          {invoiceStatus === InvoiceStatus.OPEN && <Table.Th fw={500}>Invoice Date</Table.Th>}
          {invoiceStatus === InvoiceStatus.OPEN && <Table.Th fw={500}>Due Date</Table.Th>}
          {invoiceStatus === InvoiceStatus.PAID && <Table.Th fw={500}>Payment Date</Table.Th>}
          <Table.Th fw={500}>Amount</Table.Th>
          <Table.Th fw={500}>Status</Table.Th>
          {invoiceStatus === InvoiceStatus.DRAFT && <Table.Th fw={500}>Billing Email</Table.Th>}
          <Table.Th fw={500}>
            <Flex justify="center">Manage Invoice</Flex>
          </Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {sortedInvoices.map((invoice) => (
          <InvoiceRow key={invoice.hash_id} user={user} invoice={invoice} />
        ))}
      </Table.Tbody>
    </Table>
  );
}

interface MonthSummaryData {
  billableGrossRevenue: number;
  realizedGrossRevenue: number;
  billableNetRevenue: number;
  realizedNetRevenue: number;
}

function SummaryRow({ monthString, data }: { monthString: string; data: MonthSummaryData }) {
  return (
    <Table.Tr key={`${monthString}-revenue`}>
      <Table.Td>{monthString}</Table.Td>
      <Table.Td>{formatAmount(data.billableGrossRevenue)}</Table.Td>
      <Table.Td>{formatAmount(data.billableNetRevenue)}</Table.Td>
      <Table.Td>{formatAmount(data.realizedGrossRevenue)}</Table.Td>
      <Table.Td>{formatAmount(data.realizedNetRevenue)}</Table.Td>
    </Table.Tr>
  );
}

function SummaryStats({
  draftInvoices,
  openInvoices,
  paidInvoices,
  testMode,
}: {
  draftInvoices: InvoiceModel[];
  openInvoices: InvoiceModel[];
  paidInvoices: InvoiceModel[];
  testMode: boolean;
}) {
  // Revenue summary starts from January 2024
  const startMonth = new Date(Date.UTC(2024, 1, 1));

  const monthSummaryData: { [key: string]: MonthSummaryData } = {};

  const initializeMonthData = (month: string) => {
    if (!monthSummaryData[month]) {
      monthSummaryData[month] = {
        billableGrossRevenue: 0,
        billableNetRevenue: 0,
        realizedGrossRevenue: 0,
        realizedNetRevenue: 0,
      };
    }
  };

  const filteredInvoices = (invoices: InvoiceModel[]) =>
    invoices.filter(
      (invoice) =>
        invoice.is_test_brand === testMode && new Date(invoice.billing_month) >= startMonth,
    );

  filteredInvoices(draftInvoices).forEach((invoice) => {
    const month = toFormattedMonthYearString(invoice.billing_month);
    initializeMonthData(month);
    monthSummaryData[month].billableGrossRevenue += invoice.base_payment + invoice.platform_payment;
    monthSummaryData[month].billableNetRevenue += invoice.platform_payment;
  });

  filteredInvoices(openInvoices).forEach((invoice) => {
    const month = toFormattedMonthYearString(invoice.billing_month);
    initializeMonthData(month);
    monthSummaryData[month].billableGrossRevenue += invoice.creator_fees + invoice.platform_fees;
    monthSummaryData[month].billableNetRevenue += invoice.platform_fees;
  });

  filteredInvoices(paidInvoices).forEach((invoice) => {
    const month = toFormattedMonthYearString(invoice.billing_month);
    initializeMonthData(month);
    monthSummaryData[month].billableGrossRevenue += invoice.creator_fees + invoice.platform_fees;
    monthSummaryData[month].billableNetRevenue += invoice.platform_fees;
  });

  filteredInvoices(paidInvoices).forEach((invoice) => {
    const month = toFormattedMonthYearString(invoice.payment_date);
    initializeMonthData(month);
    monthSummaryData[month].realizedGrossRevenue += invoice.amount_paid;
    monthSummaryData[month].realizedNetRevenue += invoice.amount_paid - invoice.creator_fees;
  });

  const rows = Object.keys(monthSummaryData).map((month) => (
    <SummaryRow key={month} monthString={month} data={monthSummaryData[month]} />
  ));

  return (
    <Table withTableBorder horizontalSpacing="xs" verticalSpacing="xs" highlightOnHover>
      <Table.Thead>
        <Table.Tr>
          <Table.Th fw={500}>Month</Table.Th>
          <Table.Th fw={500}>Billable Gross Revenue</Table.Th>
          <Table.Th fw={500}>Billable Net Revenue</Table.Th>
          <Table.Th fw={500}>Realized Gross Revenue</Table.Th>
          <Table.Th fw={500}>Realized Net Revenue</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>{rows}</Table.Tbody>
    </Table>
  );
}

export default function Billing({ user }: { user: User }) {
  const [draftInvoicesState, setDraftInvoicesState] = useState<InvoiceModel[]>([]);
  const [openInvoicesState, setOpenInvoicesState] = useState<InvoiceModel[]>([]);
  const [paidInvoicesState, setPaidInvoicesState] = useState<InvoiceModel[]>([]);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [testMode, setTestMode] = useState(false);

  useEffect(() => {
    setLoading(true);
    getInvoices({ user })
      .then((response) => {
        const { success, draftInvoices, openInvoices, paidInvoices } = response;

        if (success) {
          setDraftInvoicesState(draftInvoices);
          setOpenInvoicesState(openInvoices);
          setPaidInvoicesState(paidInvoices);
          setLoaded(true);
        }
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <Container>
      <LoadingOverlay visible={loading} />
      {loaded && (
        <Stack>
          <Group justify="space-between">
            <Title order={2} fw="600">
              Revenue Summary
            </Title>
            <Switch
              checked={testMode}
              onChange={(event) => setTestMode(event.currentTarget.checked)}
              label="Test Mode"
            />
          </Group>
          <SummaryStats
            draftInvoices={draftInvoicesState}
            openInvoices={openInvoicesState}
            paidInvoices={paidInvoicesState}
            testMode={testMode}
          />
          <Title order={2} fw="600">
            Draft Invoices
          </Title>
          <InvoiceTable
            user={user}
            invoices={draftInvoicesState}
            invoiceStatus={InvoiceStatus.DRAFT}
            testMode={testMode}
          />
          <Title order={2} fw="600">
            Open Invoices
          </Title>
          <InvoiceTable
            user={user}
            invoices={openInvoicesState}
            invoiceStatus={InvoiceStatus.OPEN}
            testMode={testMode}
          />
          <Title order={2} fw="600">
            Paid Invoices
          </Title>
          <InvoiceTable
            user={user}
            invoices={paidInvoicesState}
            invoiceStatus={InvoiceStatus.PAID}
            testMode={testMode}
          />
        </Stack>
      )}
    </Container>
  );
}
