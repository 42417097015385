import React, { useState } from "react";

// Assume that notifications has already been rendered in the app.
import { User } from "models/User";
import { wrapUpdateFunc, ChangeConfirmation } from "campaigns/main/campaignPage/ChangeConfirmation";

// Example of a transformation function for "ops_users"
const createOpsUsersTransformation = (opsUsers: User[]) => {
  return (updates: { [key: string]: any }): { [key: string]: any } => {
    const transformed = { ...updates };
    if (transformed.ops_users) {
      transformed.ops_users = transformed.ops_users.map(
        (name: string) => opsUsers.find((user) => user.name === name)?.key,
      );
    }
    return transformed;
  };
};

export const CreatorSetChangeConfirmation = ({
  objId,
  form,
  formValues,
  customFormatValue,
  initialValues,
  opsUsers = [],
  onSubmit,
  close,
}: {
  objId: number;
  form: any;
  formValues: { [key: string]: any };
  customFormatValue: (key: string, value: any) => any;
  initialValues: { [key: string]: any };
  opsUsers?: User[];
  onSubmit: (
    objId: number,
    updates: { [key: string]: any },
    updateFields: string[],
  ) => Promise<any>;
  close: () => void;
}) => {
  const transformUpdates = createOpsUsersTransformation(opsUsers);
  const [buttonLoading, setButtonLoading] = useState(false);
  const wrappedFunc = wrapUpdateFunc(
    form,
    onSubmit,
    setButtonLoading,
    close,
    "creator set",
    transformUpdates,
  );

  return (
    <ChangeConfirmation
      objId={objId}
      formValues={formValues}
      initialValues={initialValues}
      updateFunc={wrappedFunc}
      buttonLoading={buttonLoading}
      customDiffFormatValue={customFormatValue}
    />
  );
};

export default CreatorSetChangeConfirmation;
