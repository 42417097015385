import React, { useRef } from "react";
import { Box, Button, Divider, Flex, Group, Modal, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconUpload, IconX } from "@tabler/icons-react";
import ReactPlayer from "react-player";
import TitleAndCaption from "components/contracts/common/TitleAndCaption";
import { timestampToSeconds } from "utils/DateUtils";
import SeekButton from "components/contracts//common/SeekButton";

export default function ReviewVideoSubmission({
  videoUrl,
  title,
  caption,
  integrationTimestamp,
  disableUploadButton,
  validUpload,
  handleUpload,
  loading,
  uploading,
  handleCancelUpload,
  handleShowError,
}: {
  videoUrl: string;
  title: string;
  caption: string;
  integrationTimestamp: string;
  disableUploadButton: boolean;
  validUpload: boolean;
  handleUpload: () => void;
  loading?: boolean;
  uploading?: boolean;
  handleCancelUpload?: () => void;
  handleShowError: () => void;
}) {
  const [opened, { open, close }] = useDisclosure();
  const playerRef = useRef(null);

  return (
    <Box>
      <Modal padding={0} opened={opened} onClose={close} size="auto" withCloseButton={false}>
        <ReactPlayer ref={playerRef} url={videoUrl} playing muted controls />
        <TitleAndCaption title={title} caption={caption} width={640} />
        <Divider />
        <Group justify={integrationTimestamp ? "space-between" : "right"} p="sm">
          {integrationTimestamp && (
            <Flex align="center" gap="0.4rem">
              <Text fw="500" size="sm" fs="italic">
                Integration Starts at
              </Text>
              <SeekButton
                playerRef={playerRef}
                integrationTimestamp={integrationTimestamp}
              />
            </Flex>
          )}
          <Group justify="right">
            <Button variant="default" onClick={close}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleUpload();
                close();
              }}>
              Submit
            </Button>
          </Group>
        </Group>
      </Modal>
      <Button
        leftSection={uploading ? <IconX size="1rem" /> : <IconUpload size="1rem" />}
        disabled={disableUploadButton}
        onClick={() => {
          if (!validUpload) {
            handleShowError();
          } else if (uploading) {
            handleCancelUpload();
          } else {
            open();
          }
        }}
        loading={loading}
        color={uploading ? "red" : "blue"}>
        {uploading ? "Cancel Upload" : "Upload Video"}
      </Button>
    </Box>
  );
}
