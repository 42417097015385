import React from "react";

import { Table } from "@mantine/core";

import { OpsUserOwnedCampaignTableRow } from "admin/app/ops/OpsUserOwnedCampaignTableRow";
import { useAdminAppContext } from "admin/app/AdminAppShell";

export const OpsUserOwnedCampaignTable = ({ campaignIds }: { campaignIds: number[] }) => {
  // metadata is joined on campaign from context
  const { campaigns } = useAdminAppContext();

  const filteredCampaigns = campaigns.filter((campaign) => campaignIds.includes(campaign.id));

  return (
    <Table horizontalSpacing="md" verticalSpacing="md" highlightOnHover withTableBorder>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Campaign Name</Table.Th>
          <Table.Th>Brand</Table.Th>
          <Table.Th>Status</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {filteredCampaigns.map((campaign) => (
          <OpsUserOwnedCampaignTableRow key={campaign.id} campaign={campaign} />
        ))}
      </Table.Tbody>
    </Table>
  );
};

export default OpsUserOwnedCampaignTable;
