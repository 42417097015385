import React from "react";
import { Box, Button, Card, Container, Flex, Image, Stack, Text, Title } from "@mantine/core";
import { useNavigate } from "react-router-dom";

/* eslint-disable @typescript-eslint/no-var-requires */
const MEDIA_KIT_IMAGE = require("pages/home/v2/assets/images/mediaKitImage.png");
/* eslint-enable @typescript-eslint/no-var-requires */

const MediaKitCTA = () => {
  const navigate = useNavigate();
  return (
    <Flex w="100%" p={0} bg="gray.1">
      <Container style={{ maxWidth: "1920px" }} p={0}>
        <Box py={48} px={{ base: "24px", md: "32px", lg: "96px" }}>
          <Card
            p={0}
            radius="md"
            style={{
              background: "#F8F7F4",
            }}>
            <Flex direction={{ base: "column", sm: "row" }}>
              {/* Left Half: Stack with title, subtitle, and button */}
              <Box w={{ base: "100%", sm: "50%" }}>
                <Stack p={32}>
                  <Title style={{ fontSize: "32px" }}>Get your free media kit</Title>
                  <Text size="lg">
                    Maintaining a media kit sucks. Your media kit on 1stCollab is beautiful
                    out-of-the-box, stays updated with your metrics, and includes a custom link you
                    can share with brands.
                  </Text>
                  <Button
                    component="a"
                    href="/media-kits"
                    variant="light"
                    size="md"
                    radius="md"
                    w={224}>
                    Create your media kit
                  </Button>
                </Stack>
              </Box>

              {/* Image for bigger screens */}
              <Box visibleFrom="sm" w="50%">
                <Box w="100%" h="100%" ml="32" mt="32">
                  <Image
                    w={{ base: "80%", md: "70%", lg: "50%" }}
                    src={MEDIA_KIT_IMAGE}
                    style={{
                      borderRadius: "8px",
                      position: "absolute",
                    }}
                  />
                </Box>
              </Box>

              <Box h="300px" hiddenFrom="sm">
                <Image
                  w="650px"
                  src={MEDIA_KIT_IMAGE}
                  style={{
                    borderTopLeftRadius: "8px",
                    position: "absolute",
                    left: "32px",
                  }}
                />
              </Box>
            </Flex>
          </Card>
        </Box>
      </Container>
    </Flex>
  );
};

export default MediaKitCTA;
