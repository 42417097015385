import { Brand } from "models/Brand";

export enum MembershipTypes {
  NONE = 0,
  ADMIN = 1,
  BASIC = 2,
  COLLAB_USER = 3,
  BRAND_USER = 4,
  CREATOR_MANAGER = 5,
  BRAND_MANAGER = 6,
}

// this should be kept in sync with common.models.User in our Django backend.
export interface User {
  name?: string;
  email: string;
  key?: string;
  username?: string;
  avatar_url?: string;
  banner_url?: string;
  numFollowers?: string;
  numPosts?: number;
  platformInfo?: PlatformInfo[];
  giniStats?: GiniStatsObject;
  platformStats?: PlatformStatsObject;
  recommendedBrands?: {
    brand: Brand;
    rtc: string;
  }[];
  googleAccessToken?: string; // used to access Google data (such as Youtube stats)
  firebaseIdToken: string; // id token provided by Firebase. This token can be decoded to get basic user info and is the main way that our server and firebase auth systems stay in sync.
  providerId?: string; // identify who most recently provided the user sign in information. useful to know if google.com so can use the access token directly.
  membership_type?: MembershipTypes;
  is_claimed?: boolean;
  is_staff?: boolean;
  // TODO (andrew/victoria 10/2023): THESE FIELDS DO NOT EXIST YET ON common.models.User in our Django backend. PLACEHOLDER FOR CAMPAIGN PORTAL
  company?: Company;
  brand?: Brand | null; // TODO (victoria 5.2024): NOTE: it seems like backend is usually returning an ID here instead
  // Only for ops team
  campaigns?: { id: number; title: string; brand_id: number }[];
  trained_creator_sets?: { id: number; name: string; status: number }[];
  accepted_brand_tos?: boolean;
  completed_onboarding?: boolean;
}

export interface Company {
  name: string;
  avatar_url?: string;
}

export interface PlatformStatsObject {
  current: PlatformStats[];
  prior: PlatformStats[];
}

export interface GiniStatsObject {
  current: GiniStats;
  prior: GiniStats;
}

export interface PlatformInfo {
  platform: string;
  username: string;
}

interface KeysWithNumberValues {
  [key: string]: number;
}

// TODO (victoria): RETHINK THIS TYPING
interface KeyWithStringNumberValues {
  [key: string]: string | number;
}

export interface GiniStats extends KeysWithNumberValues {
  totalComp: number;
  campaignsStarted: number;
  inboundRequests: number;
  profileViews: number;
}

export const SampleUserGiniStats30d: GiniStats = {
  totalComp: 38976,
  campaignsStarted: 12,
  inboundRequests: 182,
  profileViews: 1928,
};

export const PriorSampleUserGiniStats30d: GiniStats = {
  totalComp: 33281,
  campaignsStarted: 10,
  inboundRequests: 190,
  profileViews: 2021,
};

export interface PlatformStats extends KeyWithStringNumberValues {
  platform: string;
  totalEngagement?: number;
  followers?: number;
  watchTime?: number;
  subscribers?: number;
}

export const SampleUserPlatformStats30d: PlatformStats[] = [
  { platform: "ig", totalEngagement: 108273, followers: 294827 },
  { platform: "tiktok", totalEngagement: 92819, followers: 83728 },
  { platform: "youtube", watchTime: 808273, subscribers: 1283749 },
];

export const PriorSampleUserPlatformStats30d: PlatformStats[] = [
  { platform: "ig", totalEngagement: 129387, followers: 282719 },
  { platform: "tiktok", totalEngagement: 83928, followers: 72910 },
  { platform: "youtube", watchTime: 629480, subscribers: 1281827 },
];

export const SampleUser: User = {
  name: "Andrew Lin",
  email: "andrewlin@1stcollab.com",
  firebaseIdToken: "123xyz",
  avatar_url: "https://i.ibb.co/2Fc00R3/james.jpg",
  numFollowers: "100k-1M",
  // tags: ["#finance", "#lifestyle", "#realestate", "#financeblogger"],
  numPosts: 123,
  platformInfo: [
    { platform: "ig", username: "investing_with_andrew" },
    { platform: "tiktok", username: "re.investing.guru" },
    { platform: "youtube", username: "Investing with Andrew" },
  ],
  // about: `I'm a former personal finance manager who ran hundred million dollar funds at some of the world's largest
  //   financial institutions for two decades. Now I want to to help everyone understand how to plan out their financial futures.
  //   I make short videos explaining tricky financial concepts and longer videos that go in depth. Check out my Instagram, Youtube, or Tiktok
  //   pages to see new content every week!
  //   `,
  giniStats: {
    current: SampleUserGiniStats30d,
    prior: PriorSampleUserGiniStats30d,
  },
  platformStats: {
    current: SampleUserPlatformStats30d,
    prior: PriorSampleUserPlatformStats30d,
  },
  recommendedBrands: [],
};

export const CarinaBansal: User = {
  name: "Carina Bansal",
  email: "carinabansal@1stcollab.com",
  firebaseIdToken: "123xyz",
  avatar_url: "https://cdn2.stylecraze.com/wp-content/uploads/2013/05/Mehwish-Hayat-1.jpg.webp",
  numFollowers: "100k-1M",
  // tags: ["#beauty", "#lifestyle", "#fashion"],
  numPosts: 24,
  platformInfo: [
    { platform: "ig", username: "fashionistacarina" },
    { platform: "tiktok", username: "fashionistacarina" },
    { platform: "youtube", username: "Fashionista Carina" },
  ],
  // about: `I'm a former personal finance manager who ran hundred million dollar funds at some of the world's largest
  //   financial institutions for two decades. Now I want to to help everyone understand how to plan out their financial futures.
  //   I make short videos explaining tricky financial concepts and longer videos that go in depth. Check out my Instagram, Youtube, or Tiktok
  //   pages to see new content every week!
  //   `,
  giniStats: {
    current: SampleUserGiniStats30d,
    prior: PriorSampleUserGiniStats30d,
  },
  platformStats: {
    current: SampleUserPlatformStats30d,
    prior: PriorSampleUserPlatformStats30d,
  },
};

export default null;
