import React from "react";
import { Group, Stack, Title, ThemeIcon, Container } from "@mantine/core";
import { IconBell } from "@tabler/icons-react";

const TodoBox = ({
  isVisible,
  todoComponent,
}: {
  isVisible: boolean;
  todoComponent: JSX.Element;
}) => (
  <Container
    hidden={!isVisible}
    w="100%"
    bg="var(--mantine-color-white)"
    pt={18}
    pb={18}
    pl={24}
    pr={24}
    style={{
      borderRadius: 20,
    }}>
    <Stack gap={0}>
      <Group mb="sm">
        <ThemeIcon variant="white" color="black">
          <IconBell size="1.5rem" />
        </ThemeIcon>
        <Title order={3}>To-Do</Title>
      </Group>
      {todoComponent}
    </Stack>
  </Container>
);

export default TodoBox;
