import React from "react";
import { Flex, Icon } from "gestalt";
import "gestalt/dist/gestalt.css";
import { User } from "firebase/auth";
import EmailVerification from "auth/EmailVerification";
import Spacer from "components/Spacer";
import HeaderMessage from "components/HeaderWithSubtitle";
import { getSettingsUser } from "pages/settings/Settings";

const SettingsVerification = () => {
  const user: User = getSettingsUser();

  if (user.emailVerified) {
    return (
      <Flex direction="column" alignItems="center">
        <HeaderMessage
          header="Email verified!"
          subtitle="Thanks for verifying your email address"
        />
        <Spacer height={100} />
        <Icon
          icon="check-circle"
          color="success"
          size={30}
          accessibilityLabel="Email verification checkmark"
        />
      </Flex>
    );
  }

  return (
    <>
      <HeaderMessage
        header="Verify your email address"
        subtitle="Click on the link we sent you to complete account registration."
      />
      <Spacer height={100} />
      <EmailVerification />
    </>
  );
};

export default SettingsVerification;
