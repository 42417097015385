import { getAuth } from "firebase/auth";
import { handleResult } from "utils/ApiUtils";

import { API_URL } from "configs/Configs";

import { MediaEntry } from "components/MediaBlockCard";

const GET_TIKTOK_MEDIA_URL = `${API_URL}/api/ops/debug/tiktok_media`;

export const getTikTokMedia = async (
  tikTokVideoId: string,
  setTikTokMedia: (tikTokMedia: MediaEntry) => void,
) => {
  const firebaseToken = await getAuth().currentUser?.getIdToken();
  const requestUrl = new URL(`${GET_TIKTOK_MEDIA_URL}/`);
  requestUrl.searchParams.append("id", tikTokVideoId);
  const request = new Request(requestUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
  });
  const response = await handleResult(request);
  if (response && response?.video_id) {
    setTikTokMedia(response);
  }
  return response;
};

export default null;
