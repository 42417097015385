import React, { useEffect, useState } from "react";
import {
  ActionIcon,
  Badge,
  Card,
  CopyButton,
  Group,
  Menu,
  Space,
  Stack,
  Tooltip,
  rem,
} from "@mantine/core";
import { useInputState, useDisclosure } from "@mantine/hooks";
import {
  IconArrowsJoin,
  IconEdit,
  IconPencil,
  IconBrandTiktok,
  IconBrandInstagram,
  IconBrandYoutubeFilled,
  IconSpiral,
} from "@tabler/icons-react";

import { fetchCreatorInfoFromLink } from "campaigns/api/fetchCreatorInfoFromLink";
import { CreatorDetails } from "components/discovery/Datamodels";
import { getCreatorInfoFromDetails } from "components/creator_lists/CreatorListsUtils";
import Spacer from "components/Spacer";
import PlatformTable from "components/creator_lists/unified_creator_rep/PlatformTable";
import CreatorHeader from "components/discovery/search/CreatorHeader";
import CreatorMergeModal from "components/creator/CreatorMergeModal";
import { TikTokStats, YoutubeStats, InstagramStats } from "creators/api/fetchExtendedStats";

const ProfileActionMenu = ({
  baseCreatorId,
  fetchCreatorInfo,
  youtubeHandle,
  couldNotFindYoutube,
  tiktokHandle,
  couldNotFindTiktok,
  instagramHandle,
  couldNotFindInstagram,
  firstName,
  couldNotFindFirstName,
  lastName,
  email,
  couldNotFindEmail,
  showDetailModal,
  setShowDetailModal,
  disableCreatorEditAction = false,
}: {
  baseCreatorId: number | string;
  fetchCreatorInfo: () => Promise<void>;
  youtubeHandle?: string;
  couldNotFindYoutube?: boolean;
  tiktokHandle?: string;
  couldNotFindTiktok?: boolean;
  instagramHandle?: string;
  couldNotFindInstagram?: boolean;
  firstName?: string;
  couldNotFindFirstName?: boolean;
  lastName?: string;
  email?: string;
  couldNotFindEmail?: boolean;
  showDetailModal?: boolean;
  setShowDetailModal?: (showDetailModal: boolean) => void;
  disableCreatorEditAction?: boolean;
}) => {
  const [openedMenu, setOpenedMenu] = useState(false);
  const [modalOpened, { open: openModal, close: closeModal }] = useDisclosure();

  const closeModelDetails = () => {
    setShowDetailModal(false);
    closeModal();
  };

  const fetchCreatorDetails = () => {
    setShowDetailModal(true);
    fetchCreatorInfo().then(() => {
      openModal();
    });
  };

  useEffect(() => {
    if (showDetailModal) {
      fetchCreatorInfo().then(() => {
        openModal();
      });
    }
  }, []);

  return (
    <>
      <CreatorMergeModal
        baseCreatorId={baseCreatorId}
        youtubeHandle={youtubeHandle}
        tiktokHandle={tiktokHandle}
        instagramHandle={instagramHandle}
        firstName={firstName}
        lastName={lastName}
        email={email}
        opened={modalOpened}
        close={closeModelDetails}
        couldNotFindYoutube={couldNotFindYoutube}
        couldNotFindTiktok={couldNotFindTiktok}
        couldNotFindInstagram={couldNotFindInstagram}
        couldNotFindFirstName={couldNotFindFirstName}
        couldNotFindEmail={couldNotFindEmail}
      />
      {!disableCreatorEditAction ? (
        <Menu position="left-start" onChange={setOpenedMenu} shadow="md" width={200}>
          <Menu.Target>
            <ActionIcon variant="filled" size="lg" aria-label="Creator Settings">
              <IconEdit style={{ width: "70%", height: "70%" }} />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Label>Creator Profile Actions</Menu.Label>
            <Menu.Item
              closeMenuOnClick
              onClick={fetchCreatorDetails}
              leftSection={
                <IconArrowsJoin style={{ color: "red", width: rem(16), height: rem(16) }} />
              }>
              Edit Creator Info
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      ) : (
        <Space w="xs" />
      )}
    </>
  );
};

const openPage = (url: string) => {
  return () => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
};

const CreatorBasicInfoBar = ({
  baseCreatorId,
  tiktokHandle,
  instagramHandle,
  youtubeHandle,
  firstName,
  lastName,
  email,
  closeUrl,
  couldNotFindYoutube,
  couldNotFindTiktok,
  couldNotFindInstagram,
  couldNotFindFirstName,
  couldNotFindEmail,
  disableEditButton = false,
}: {
  baseCreatorId: string | number;
  tiktokHandle: string;
  instagramHandle: string;
  youtubeHandle: string;
  firstName: string;
  lastName: string;
  email: string;
  closeUrl: string;
  couldNotFindYoutube: boolean;
  couldNotFindTiktok: boolean;
  couldNotFindInstagram: boolean;
  couldNotFindFirstName: boolean;
  couldNotFindEmail: boolean;
  disableEditButton?: boolean;
}) => {
  const [modalOpened, { open: openModal, close: closeModal }] = useDisclosure();
  return (
    <>
      <CreatorMergeModal
        baseCreatorId={baseCreatorId}
        youtubeHandle={youtubeHandle}
        tiktokHandle={tiktokHandle}
        instagramHandle={instagramHandle}
        firstName={firstName}
        lastName={lastName}
        email={email}
        opened={modalOpened}
        close={closeModal}
        couldNotFindYoutube={couldNotFindYoutube}
        couldNotFindTiktok={couldNotFindTiktok}
        couldNotFindInstagram={couldNotFindInstagram}
        couldNotFindFirstName={couldNotFindFirstName}
        couldNotFindEmail={couldNotFindEmail}
      />
      {/* TODO: adding loading component state for badges */}
      <Group>
        {tiktokHandle ? (
          <ActionIcon
            variant="light"
            color="cyan"
            radius="md"
            component="a"
            href={`https://www.tiktok.com/@${tiktokHandle}`}>
            <IconBrandTiktok size={20} color="darkturquoise" />
          </ActionIcon>
        ) : null}
        {instagramHandle ? (
          <ActionIcon
            variant="light"
            color="grape"
            radius="md"
            component="a"
            href={`https://www.instagram.com/${instagramHandle}`}>
            <IconBrandInstagram size={20} color="deeppink" />
          </ActionIcon>
        ) : null}
        {youtubeHandle ? (
          <ActionIcon
            variant="light"
            color="red"
            radius="md"
            component="a"
            href={`https://www.youtube.com/@${youtubeHandle}`}>
            <IconBrandYoutubeFilled color="red" size={20} />
          </ActionIcon>
        ) : null}
        <Badge size="md" variant="light">
          {!firstName ? "No First Name" : firstName} {!lastName ? "No Last Name" : lastName}
        </Badge>
        <CopyButton value={email} timeout={1500}>
          {({ copied, copy }) => (
            <Tooltip label={copied ? "Copied!" : "Copy"} withArrow position="right">
              <Badge color={copied ? "teal" : "blue"} size="md" variant="light" onClick={copy}>
                {!email ? "No Email" : email}
              </Badge>
            </Tooltip>
          )}
        </CopyButton>
        {!disableEditButton && (
          <ActionIcon p={1} variant="transparent" color="dimgray" size="md" onClick={openModal}>
            <IconPencil size={22} />
          </ActionIcon>
        )}
        {closeUrl ? (
          <ActionIcon
            p={1}
            variant="transparent"
            color="dimgray"
            size="md"
            onClick={openPage(closeUrl)}>
            <Tooltip label="Open Close Url" withArrow position="right">
              <IconSpiral size={22} />
            </Tooltip>
          </ActionIcon>
        ) : null}
      </Group>
    </>
  );
};

const SummaryCreatorProfileView = ({
  creatorId,
  archetype = null, // need this to reuse CreatorHeader component
  baseCreator,
  tikTokStats,
  youtubeShortsStats,
  youtubeLongStats,
  instagramStats,
  defaultExpandedPlatforms,
  useScrollOverflow = false,
  showDetailModal = false,
  setShowDetailModal,
  includeLinkToProfile = false,
  showExtendedStats = true,
  disableCreatorEditAction = false,
}: {
  creatorId: number | null;
  archetype?: string | null;
  baseCreator: CreatorDetails;
  tikTokStats: TikTokStats;
  youtubeLongStats: YoutubeStats;
  youtubeShortsStats: YoutubeStats;
  instagramStats: InstagramStats;
  defaultExpandedPlatforms: string[];
  useScrollOverflow?: boolean;
  showDetailModal?: boolean;
  setShowDetailModal?: (showDetailModal: boolean) => void;
  includeLinkToProfile?: boolean;
  showExtendedStats?: boolean;
  disableCreatorEditAction?: boolean;
}) => {
  // pasted from unifiedcreatorrep component
  // for each field, we try to populate that information in order of platform priority
  const allPlatormCreatorDetails = (baseCreator.platform_priority ?? ["all"]).map((platform) =>
    getCreatorInfoFromDetails(baseCreator, platform),
  );

  const creatorFromDetails = {
    profile_link: allPlatormCreatorDetails.find((platformDetails) => platformDetails?.profile_link)
      ?.profile_link,
    handle: allPlatormCreatorDetails.find((platformDetails) => platformDetails?.handle)?.handle,
    avatar_url: allPlatormCreatorDetails.find((platformDetails) => platformDetails?.avatar_url)
      ?.avatar_url,
    num_subscribers: allPlatormCreatorDetails.find(
      (platformDetails) => platformDetails?.num_subscribers,
    )?.num_subscribers,
    title: allPlatormCreatorDetails.find((platformDetails) => platformDetails?.title)?.title,
    country: allPlatormCreatorDetails.find((platformDetails) => platformDetails?.country)?.country,
    views_med: allPlatormCreatorDetails.find((platformDetails) => platformDetails?.views_med)
      ?.views_med,
    engagement_rate: allPlatormCreatorDetails.find(
      (platformDetails) => platformDetails?.engagement_rate,
    )?.engagement_rate,
    comment_rate: allPlatormCreatorDetails.find((platformDetails) => platformDetails?.comment_rate)
      ?.comment_rate,
    comments_med: allPlatormCreatorDetails.find((platformDetails) => platformDetails?.comments_med)
      ?.comments_med,
    likes_med: allPlatormCreatorDetails.find((platformDetails) => platformDetails?.likes_med)
      ?.likes_med,
    total_vids: allPlatormCreatorDetails.find((platformDetails) => platformDetails?.total_vids)
      ?.total_vids,
    all_time_videos: allPlatormCreatorDetails.find(
      (platformDetails) => platformDetails?.all_time_videos,
    )?.all_time_videos,
    vibe_score: allPlatormCreatorDetails.find((platformDetails) => platformDetails?.vibe_score)
      ?.vibe_score,
    videos: allPlatormCreatorDetails.find((platformDetails) => platformDetails?.videos)?.videos,
    description: allPlatormCreatorDetails.find((platformDetails) => platformDetails?.description)
      ?.description,
  };

  const [socialUrl, setSocialUrl] = useInputState(creatorFromDetails.profile_link);
  const [youtubeHandle, setYoutubeHandle] = useInputState("");
  const [tiktokHandle, setTiktokHandle] = useInputState("");
  const [instagramHandle, setInstagramHandle] = useInputState("");
  const [firstName, setFirstName] = useInputState("");
  const [lastName, setLastName] = useInputState("");
  const [email, setEmail] = useInputState("");
  const [closeUrl, setCloseUrl] = useInputState("");
  const [couldNotFindYoutube, setCouldNotFindYoutube] = useState(false);
  const [couldNotFindTiktok, setCouldNotFindTiktok] = useState(false);
  const [couldNotFindInstagram, setCouldNotFindInstagram] = useState(false);
  const [couldNotFindFirstName, setCouldNotFindFirstName] = useState(false);
  const [couldNotFindEmail, setCouldNotFindEmail] = useState(false);

  const fetchDetails = () =>
    fetchCreatorInfoFromLink(socialUrl).then((data) => {
      const { creator } = data;

      // clear inputs
      setYoutubeHandle("");
      setInstagramHandle("");
      setTiktokHandle("");
      setFirstName("");
      setLastName("");
      setEmail("");

      if (creator != null) {
        if (creator.youtube_handle != null) {
          setYoutubeHandle(creator.youtube_handle);
        }
        if (creator.could_not_find_yt) {
          setCouldNotFindYoutube(true);
        }
        if (creator.tiktok_handle != null) {
          setTiktokHandle(creator.tiktok_handle);
        }
        if (creator.could_not_find_tt) {
          setCouldNotFindTiktok(true);
        }
        if (creator.instagram_handle != null) {
          setInstagramHandle(creator.instagram_handle);
        }
        if (creator.could_not_find_ig) {
          setCouldNotFindInstagram(true);
        }
        if (creator.first_name != null) {
          setFirstName(creator.first_name);
        }
        if (creator.could_not_find_first_name) {
          setCouldNotFindFirstName(true);
        }
        if (creator.last_name != null) {
          setLastName(creator.last_name);
        }
        if (creator.email != null) {
          setEmail(creator.email);
        }
        if (creator.could_not_find_email) {
          setCouldNotFindEmail(true);
        }
        if (creator.close_url != null) {
          setCloseUrl(creator.close_url);
        }
      }
    });

  useEffect(() => {
    const abortController = new AbortController();
    fetchDetails();

    return () => abortController.abort();
  }, []);

  const overflowStyle = useScrollOverflow ? { overflow: "scroll" } : {};

  return (
    <Card shadow="xs" mx="lg" padding="lg" radius="md" withBorder style={overflowStyle}>
      <Group justify="space-between" align="flex-start" p="md" wrap="nowrap" gap="xl">
        <Stack>
          <CreatorHeader
            creatorInfo={creatorFromDetails}
            archetype={archetype}
            includeLinkToProfile={includeLinkToProfile}
            creatorId={creatorId}
          />
          <CreatorBasicInfoBar
            baseCreatorId={creatorId}
            tiktokHandle={tiktokHandle}
            youtubeHandle={youtubeHandle}
            instagramHandle={instagramHandle}
            firstName={firstName}
            lastName={lastName}
            email={email}
            closeUrl={closeUrl}
            couldNotFindYoutube={couldNotFindYoutube}
            couldNotFindTiktok={couldNotFindTiktok}
            couldNotFindInstagram={couldNotFindInstagram}
            couldNotFindFirstName={couldNotFindFirstName}
            couldNotFindEmail={couldNotFindEmail}
            disableEditButton={disableCreatorEditAction}
          />
        </Stack>
        <ProfileActionMenu
          baseCreatorId={creatorId}
          fetchCreatorInfo={fetchDetails}
          youtubeHandle={youtubeHandle}
          couldNotFindYoutube={couldNotFindYoutube}
          tiktokHandle={tiktokHandle}
          couldNotFindTiktok={couldNotFindTiktok}
          instagramHandle={instagramHandle}
          couldNotFindInstagram={couldNotFindInstagram}
          firstName={firstName}
          couldNotFindFirstName={couldNotFindFirstName}
          lastName={lastName}
          email={email}
          couldNotFindEmail={couldNotFindEmail}
          showDetailModal={showDetailModal}
          setShowDetailModal={setShowDetailModal}
          disableCreatorEditAction={disableCreatorEditAction}
        />
      </Group>
      <Spacer height={16} />
      <PlatformTable
        creator={baseCreator}
        defaultExpandedPlatforms={defaultExpandedPlatforms}
        tikTokStats={tikTokStats}
        youtubeShortsStats={youtubeShortsStats}
        youtubeLongStats={youtubeLongStats}
        instagramStats={instagramStats}
        useExtendedStats={showExtendedStats}
        refetchThumbnails
      />
    </Card>
  );
};

export default SummaryCreatorProfileView;
