import { API_URL } from "configs/Configs";
import { createRequestWithFirebaseToken, handleResult } from "utils/ApiUtils";

export const updateBrand = async (brandId: number, updatedFields: { [key: string]: any }) => {
  const request = await createRequestWithFirebaseToken({
    url: `${API_URL}/api/models/brands/${brandId}/`,
    method: "PATCH",
    body: JSON.stringify(updatedFields),
  });

  const result = await handleResult(request);

  return result;
};

export default null;
