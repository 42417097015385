import { createRequestWithFirebaseToken, handleResult } from "utils/ApiUtils";

export const updateBrandObjById = async (
  brandId: number,
  endpoint: string,
  objId: number,
  updates: { [key: string]: any },
  updateFields: string[],
) => {
  // convert update to raw fields
  const idFields: { [key: string]: any } = {
    id: objId,
    brandId,
  };
  const translatedUpdates = Object.entries(updates).reduce(
    (acc, [key, value]) => {
      if (updateFields.includes(key)) {
        acc[key] = value;
      }
      return acc;
    },
    { ...idFields },
  );

  const request = await createRequestWithFirebaseToken({
    url: `${endpoint}/${objId}/`,
    method: "PATCH",
    body: JSON.stringify(translatedUpdates),
  });

  const response = await handleResult(request);
  return response;
};

export default null;
