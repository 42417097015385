import React from "react";
import { Box, Button, Container, Flex, Stack, Text, Title } from "@mantine/core";

const ManagersAndAgencies = () => (
  <Flex w="100%" p={0}>
    <Container style={{ maxWidth: "1920px" }} p={0}>
      <Box py="112px" px={{ base: "24px", md: "32px", lg: "96px" }}>
        <Flex gap={{ base: 24, sm: 80 }} direction={{ base: "column", sm: "row" }}>
          {/* Left half with big title */}
          <Box w={{ base: "100%", sm: "50%" }}>
            <Title order={1} style={{ fontSize: "48px" }}>
              We work with managers and agencies too!
            </Title>
          </Box>

          {/* Right half with vertical subtitle and CTA button */}
          <Box w={{ base: "100%", sm: "50%" }}>
            <Stack justify="center">
              <Text size="lg" fw={500}>
                Are you a creator manager or agency looking to maximize your brand partnership
                opportunities? We can help find opportunities for everyone on your roster—whether
                that’s a single creator or a thousand.
              </Text>
              <Button
                w={180}
                size="md"
                variant="filled"
                radius="md"
                onClick={() => {
                  window.location.href = "mailto:hello@1stcollab.com";
                }}>
                Learn More
              </Button>
            </Stack>
          </Box>
        </Flex>
      </Box>
    </Container>
  </Flex>
);

export default ManagersAndAgencies;
