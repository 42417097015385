import React, { useEffect, useState } from "react";

import { Box, Card, Group, Text } from "@mantine/core";

import { fetchMessages } from "components/contracts/common/Api";
import DeliverableCardHeader from "components/contracts/common/DeliverableCardHeader";
import Message, { MessageParty } from "components/contracts/models/Message";
import Script from "components/contracts/models/Script";
import MessageHistory from "components/contracts/common/MessageHistory";
import ScriptContent from "components/contracts/common/ScriptContent";
import SocialHandleButton from "components/contracts/common/SocialHandleButton";
import ReviewStatusBadge from "components/contracts/common/ReviewStatusBadge";

import { timeAgo } from "utils/DateUtils";

export default function PreviousScriptCard({
  script,
  messageParty,
  versionNumber,
}: {
  script: Script;
  messageParty: MessageParty;
  versionNumber: number;
}) {
  const [pastMessages, setPastMessages] = useState<Message[]>([]);
  const [messagesLoaded, setMessagesLoaded] = useState(false);
  const [messageLodingError, setMessageLoadingError] = useState(false);

  useEffect(() => {
    fetchMessages({
      deliverableId: script.deliverableId,
      messageParty: messageParty.toString(),
      scriptId: script.scriptId,
    })
      .then((response: any) => {
        const { success, messages } = response;
        if (success) {
          const deserializedMessages = messages.map((message: any) => Message.deserialize(message));
          setPastMessages(deserializedMessages);
          setMessagesLoaded(true);
        } else {
          setMessageLoadingError(true);
        }
      })
      .catch(() => {
        setMessageLoadingError(true);
      });
  }, []);

  return (
    <Card shadow="sm" padding="lg" radius="md" withBorder>
      <Group justify="space-between" mb="lg" align="start">
        <DeliverableCardHeader
          platform={script.platform}
          format={script.format}
          subHeading={
            <Text>
              <Text size="sm" c="dimmed">
                Version #{versionNumber} - Submitted by
                <SocialHandleButton
                  platform={script.platform}
                  handle={script.creatorHandle}
                  profileLink={script.profileLink}
                  showIcon={false}
                />
                {timeAgo(script.submissionDate)}
              </Text>
            </Text>
          }
        />
        <ReviewStatusBadge status={script.status} reviewDate={script.reviewDate} />
      </Group>
      <Group justify="center">
        <ScriptContent script={script} />
      </Group>
      <Box mt="md">
        <MessageHistory
          messages={pastMessages}
          setMessages={setPastMessages}
          messageViewer={messageParty}
          messagesLoaded={messagesLoaded}
          loadingError={messageLodingError}
        />
      </Box>
    </Card>
  );
}
