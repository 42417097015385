import { Group, Select } from "@mantine/core";
import {
  BrandInfoCampaignParameters,
  BrandInfoCreatorSet,
  BrandRecords,
  getBrandCreatorSetSelectData,
  getBrandSelectData,
  getCampaignParametersSelectData,
} from "admin/AdminUtils";
import { Platform } from "components/discovery/Datamodels";
import React, { useEffect, useState } from "react";

const PlatformSelector = ({
  platforms,
  setPlatform,
  platform,
  setIsUserActionChange,
}: {
  platforms: Platform[];
  setPlatform: React.Dispatch<React.SetStateAction<string>>;
  platform: Platform;
  setIsUserActionChange: (isUserActionChange: boolean) => void;
}) => (
  <Select
    id="platforms"
    onChange={(value) => {
      // Split the string by commas
      if (value === null) {
        return;
      }
      setPlatform(value.toLowerCase());
      setIsUserActionChange(true);
    }}
    label="Platform"
    data={platforms.map((p) => ({
      label: p,
      value: p,
    }))}
    value={platform}
    clearable
    searchable
  />
);

export const BrandCreatorSetPlatformFilterSelector = ({
  brandData,
  brandId,
  creatorSetId,
  campaignParameterId,
  platform,
  setBrandId,
  setCampaignParameterId,
  setPlatform,
  setIsUserActionChange,
  setCreatorSetId,
}: {
  brandData: BrandRecords | null;
  brandId: number;
  creatorSetId: number;
  campaignParameterId: number;
  platform: Platform;
  setBrandId: (brandId: number) => void;
  setCampaignParameterId: (campaignParameter: number) => void;
  setPlatform: (platform: Platform) => void;
  setIsUserActionChange: (isUserActionChange: boolean) => void;
  setCreatorSetId: (creatorSetId: number) => void;
}) => {
  const [brandCreatorSets, setBrandCreatorSets] = useState<BrandInfoCreatorSet[]>([]);
  const [availableCampaignParameters, setAvailableCampaignParameters] = useState<
    BrandInfoCampaignParameters[]
  >([]);

  const isBrandDataValid = (data: BrandRecords | null) =>
    data !== null && data !== undefined && Object.keys(data).length > 0;

  const [eligiblePlatforms, setEligiblePlatforms] = useState<Platform[]>([]);

  useEffect(() => {
    if (
      !isBrandDataValid(brandData) ||
      Object.keys(brandData).length === 0 ||
      !brandId ||
      !brandData[brandId]
    ) {
      setBrandCreatorSets([]);
      setAvailableCampaignParameters([]);
      return;
    }
    setBrandCreatorSets(brandData[brandId].creatorsets);
    setAvailableCampaignParameters(brandData[brandId].campaign_parameters);
  }, [brandId, brandData]);

  useEffect(() => {
    // update campaign parameters when available campaign parameters change
    if (
      availableCampaignParameters?.length > 0 &&
      !availableCampaignParameters.find((params) => params.id === campaignParameterId)
    ) {
      setCampaignParameterId(null);
    }
  }, [availableCampaignParameters]);

  useEffect(() => {
    if (isBrandDataValid(brandData) && brandId && brandData[brandId] && campaignParameterId) {
      const brandCampaignParameters = brandData[brandId].campaign_parameters;
      const campaignParams = brandCampaignParameters.filter((campaignParameter) => {
        return campaignParameter.id === campaignParameterId;
      });
      if (campaignParams && campaignParams.length > 0 && campaignParams[0]?.platforms?.length > 0) {
        setEligiblePlatforms(campaignParams[0].platforms);
      } else {
        setEligiblePlatforms([]);
      }
    }
  }, [campaignParameterId]);

  useEffect(() => {
    // If available creator sets change, we need to check it still exists
    if (
      brandCreatorSets?.length > 0 &&
      !brandCreatorSets.find((creatorSet) => creatorSet.id === creatorSetId)
    ) {
      setCreatorSetId(null);
    }
  }, [brandCreatorSets]);

  return (
    <div>
      {isBrandDataValid(brandData) ? (
        <Group>
          <Select
            id="brand_name"
            onChange={(value) => {
              setBrandId(Number(value));
            }}
            label="Brand"
            value={String(brandId)}
            data={getBrandSelectData(brandData)}
            searchable
          />
          <Select
            id="creator_set"
            onChange={(value) => {
              setCreatorSetId(Number(value));
              setIsUserActionChange(true);
            }}
            label="Creator Set"
            data={getBrandCreatorSetSelectData(brandCreatorSets)}
            value={creatorSetId !== null ? String(creatorSetId) : null}
            clearable
            searchable
          />
          <Select
            id="campaign_parameter_name"
            onChange={(value) => {
              setCampaignParameterId(Number(value));
              setIsUserActionChange(true);
            }}
            label="Campaign Filter"
            data={getCampaignParametersSelectData(availableCampaignParameters)}
            value={campaignParameterId !== null ? String(campaignParameterId) : null}
            clearable
            searchable
          />
          <PlatformSelector
            platforms={eligiblePlatforms}
            setPlatform={setPlatform}
            platform={platform}
            setIsUserActionChange={setIsUserActionChange}
          />
        </Group>
      ) : null}
    </div>
  );
};

export default null;
