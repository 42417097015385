import React from "react";

import { Paper, Stack, Title } from "@mantine/core";

import DOMPurify from "dompurify";

import Script from "components/contracts/models/Script";

/**
 *
 * @param text Make given text safe for display in script content.
 * @returns The sanitized HTML as a string
 */
export function safeScriptHtml(text: string) {
  const sanitizedHtml = DOMPurify.sanitize(text);
  const parser = new DOMParser();
  const doc = parser.parseFromString(sanitizedHtml, "text/html");

  // Find all anchor tags and modify them
  const anchors = doc.querySelectorAll("a");
  anchors.forEach((anchor) => {
    anchor.setAttribute("target", "_blank");
    anchor.setAttribute("rel", "noopener noreferrer");
  });

  // Serialize the modified document back to string
  return doc.body.innerHTML;
}

export default function ScriptContent({ script }: { script: Script }) {
  const updatedHTML = safeScriptHtml(script.text);

  return (
    <Stack gap="0.3rem">
      <Title order={4} pl="0.3rem" fw="600">
        Video Concept
      </Title>
      <Paper withBorder px="md" radius="sm" shadow="xs">
        <div dangerouslySetInnerHTML={{ __html: updatedHTML }} />
      </Paper>
    </Stack>
  );
}
