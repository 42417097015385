import { Text, Tooltip } from "@mantine/core";
import { IconInfoCircle } from "@tabler/icons-react";
import React from "react";

const InfoCircleWithTooltip = ({
  tooltipText,
  iconColor = "var(--mantine-color-gray-6)",
}: {
  iconColor?: string;
  tooltipText: string;
}) => (
  <Tooltip multiline w={200} label={<Text size="xs">{tooltipText}</Text>}>
    <IconInfoCircle size={12} color={iconColor} />
  </Tooltip>
);

export default InfoCircleWithTooltip;
