import { User as FirebaseUser } from "firebase/auth";
import { handleResult } from "utils/ApiUtils";

export const updateCampaignObjByHashId = async (
  firebaseUser: FirebaseUser,
  campaignHashId: number,
  endpoint: string,
  objId: number,
  updates: { [key: string]: any },
  updateFields: string[],
) => {
  const firebaseToken = await firebaseUser.getIdToken();
  // convert update to raw fields
  const idFields: { [key: string]: any } = {
    id: objId,
    campaignId: campaignHashId,
  };
  const translatedUpdates = Object.entries(updates).reduce(
    (acc, [key, value]) => {
      if (updateFields.includes(key)) {
        acc[key] = value;
      }
      return acc;
    },
    { ...idFields },
  );
  const request = new Request(`${endpoint}/${objId}/`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
    body: JSON.stringify(translatedUpdates),
  });

  const response = await handleResult(request);
  return response;
};

export default null;
