import React, { useCallback, useEffect, useState } from "react";

import { Button, Flex, Stack, Table } from "@mantine/core";

import { BrandHashtag } from "models/BrandHashtag";
import { BrandHashtagLabelingTableRow } from "admin/app/ops/labeling/brand_hashtag/BrandHashtagLabelingTableRow";

export const BrandHashtagLabelingTable = ({
  hashtags,
  includeHidden,
}: {
  hashtags: BrandHashtag[];
  includeHidden: boolean;
}) => {
  const [hashtagMap, setHashtagMap] = useState(() => {
    const map = new Map<number, BrandHashtag>();
    hashtags.forEach((hashtag) => {
      map.set(hashtag.id, hashtag);
    });
    return map;
  });

  // update hashtagMap whenever hashtags change
  useEffect(() => {
    const map = new Map<number, BrandHashtag>();
    hashtags.forEach((hashtag) => {
      map.set(hashtag.id, hashtag);
    });
    setHashtagMap(map);
  }, [hashtags]);

  // callback for updating hashtag
  const onChange = useCallback((hashtagId: number, hashtag: BrandHashtag) => {
    setHashtagMap(() => ({ ...hashtagMap, [hashtagId]: hashtag }));
  }, []);

  return (
    <Stack gap="md>">
      <Table horizontalSpacing="md" verticalSpacing="md" highlightOnHover withTableBorder>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Hashtag</Table.Th>
            <Table.Th>Count</Table.Th>
            <Table.Th>Brand</Table.Th>
            <Table.Th>Is Sponsored</Table.Th>
            <Table.Th>Hide</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {hashtags.map((hashtag) => (
            <BrandHashtagLabelingTableRow
              key={hashtag.id}
              brandHashtag={hashtag}
              includeHidden={includeHidden}
              onChange={onChange}
            />
          ))}
        </Table.Tbody>
      </Table>
      <Flex justify="center">
        <Button
          color="blue"
          onClick={() => {
            Object.entries(hashtagMap).forEach(([hashtagId, hashtag]) => {
              console.log(hashtagId, hashtag);
            });
          }}>
          Save
        </Button>
      </Flex>
    </Stack>
  );
};

export default BrandHashtagLabelingTable;
