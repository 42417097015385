import { Alert, Anchor, List, Text } from "@mantine/core";
import { IconCopy, IconInfoCircle } from "@tabler/icons-react";
import ProductGuideLink from "campaigns/create/common/ProductGuideLink";
import React from "react";

const CreativeBriefGuide = () => (
  <Alert variant="light" color="blue" title="Creative Brief Guide" icon={<IconInfoCircle />}>
    <Text size="sm" pb={12}>
      We recommend you use our Notion Template to put together your creative brief. We’ve used and
      iterated on this template across hundreds of campaigns and have seen that it works well.
    </Text>
    <List size="sm" pb={12}>
      <List.Item
        style={{
          display:
            "inline-block" /* using inline-block because inline-flex doesn't respect padding from parent and text gets cut off */,
        }}>
        <Text size="sm">
          <b>1. Copy our template.</b> Make a copy of{" "}
          <Anchor
            c="black"
            underline="always"
            href="https://1stcollab.notion.site/Brand-x-Campaign-Creative-Brief-4db6bcec3c9c49499c192c83e87d49bb"
            target="_blank">
            this Notion template
          </Anchor>{" "}
          to begin crafting your brief. To make your own copy of the template, click the{" "}
          <IconCopy size={14} style={{ verticalAlign: "middle" }} /> in the top right corner of the
          Notion template.
        </Text>
      </List.Item>
      <List.Item
        style={{
          display:
            "inline-block" /* using inline-block because inline-flex doesn't respect padding from parent and text gets cut off */,
        }}>
        <Text size="sm">
          <b>2. Customize your brief.</b> Tailor the template to fit your specific needs and
          objectives. Instructions for each section are included within the template.
        </Text>
      </List.Item>
      <List.Item
        style={{
          display:
            "inline-block" /* using inline-block because inline-flex doesn't respect padding from parent and text gets cut off */,
        }}>
        <Text size="sm">
          <b>3. Publish your brief.</b> Once you&apos;re done editing, publish your copy of the
          brief and paste the public link below in the Published URL field. To publish, click
          “Share” in the top right of your Notion creative brief, then click “Publish”.
        </Text>
      </List.Item>
    </List>
    <Text size="sm">
      For more help, you can visit our <ProductGuideLink c="black" underline="always" />.
    </Text>
  </Alert>
);

export default CreativeBriefGuide;
