import React from "react";
import { User } from "models/User";
import { Avatar, Group, Stack, Text } from "@mantine/core";
import Spacer from "components/Spacer";
import { IconChevronsLeft } from "@tabler/icons-react";
import ShowHideNavBarIcon from "campaigns/navbar/ShowHideNavBarIcon";

const CompanyAndUserHeader = ({
  user,
  onCollapseIconClick,
}: {
  user: User;
  onCollapseIconClick: () => void;
}) => {
  // TODO (andrew/victoria 10.2023): REMOVE "YOUR COMPANY NAME" when backend is ready
  const companyName = user.brand?.display_name || user.name;
  return (
    <Group justify="space-between" wrap="nowrap">
      <Group gap={0} wrap="nowrap">
        <Avatar w={48} h={48} src={user?.avatar_url} style={{ flex: "none" }} />
        <Spacer width={12} />
        <Stack gap={0}>
          {companyName ? (
            <Text
              style={{
                fontSize: "14px",
                fontWeight: "700",
                lineHeight: "155%",
              }}>
              {companyName}
            </Text>
          ) : null}
          <Text
            lineClamp={1}
            style={{
              fontSize: "11px",
              fontWeight: "400",
              lineHeight: "155%",
            }}>
            {user.email}
          </Text>
        </Stack>
      </Group>
      <ShowHideNavBarIcon
        IconName={IconChevronsLeft}
        onIconClick={onCollapseIconClick}
        tooltipText="Collapse nav bar"
      />
    </Group>
  );
};

export default CompanyAndUserHeader;
