import {
    ActionIcon,
    Anchor,
    Button,
    Group,
    Modal,
    Popover,
    Stack,
    Text,
    TextInput,
    Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconLink } from "@tabler/icons-react";
import { updateAdGroup } from "campaigns/api/fetchCampaignSettings";
import TextareaWithCharacterLimit from "campaigns/create/adgroups/TextareaWithCharacterLimit";
import { FormAdGroup } from "campaigns/create/adgroups/types";
import Spacer from "components/Spacer";
import { Brand } from "models/Brand";
import React, { useState } from "react";

interface AdGroupEmailFormType {
  brandName: string;
  brandUrl: string;
  brandBlurb: string;
  creatorBlurb: string;
}

const MAX_BLURB_NUM_CHARACTERS = 600;

const PreviewMode = ({ values }: { values: AdGroupEmailFormType }) => (
  <>
    <Text size="sm" component="span">
      I&apos;m Michael from 1stCollab. Today I&apos;m reaching out on behalf of{" "}
      {values.brandUrl ? (
        <Anchor href={values.brandUrl} target="_blank" c="black" underline="always">
          {values.brandName || "[Brand Name]"}
        </Anchor>
      ) : (
        <span style={{ textDecoration: "underline" }}>{values.brandName || "[Brand Name]"}</span>
      )}{" "}
      to see if you&apos;d be interested in collaborating with them on a paid sponsorship.
    </Text>

    {values.brandBlurb ? (
      <Text size="sm" component="span">
        {values.brandBlurb}
      </Text>
    ) : null}
    {values.creatorBlurb ? (
      <Text size="sm" component="span">
        {values.creatorBlurb}
      </Text>
    ) : null}
    <Text size="sm">
      If you&apos;re interested, let me know by [date] and I can share more details.
    </Text>
    <Text size="sm">Michael</Text>
  </>
);

const EditAdGroupEmailModal = ({
  adGroup,
  brand,
  campaignHashId,
  closeModal,
}: {
  adGroup: FormAdGroup;
  campaignHashId: number;
  brand: Brand | null;
  closeModal: (hasUpdatedAdGroup: boolean) => void;
}) => {
  const [isLinkInsertEnabled, setIsLinkInsertEnabled] = useState<boolean>(false);
  const [isLinkSettingsOpen, setIsLinkSettingsOpen] = useState<boolean>(false);
  const [isPreviewMode, setIsPreviewMode] = useState<boolean>(false);
  const [previewModeErrorMessage, setPreviewModeErrorMessage] = useState<string | null>(null);
  const [haveSettingsChanged, setHaveSettingsChanged] = useState<boolean>(false);

  const initialState: AdGroupEmailFormType = {
    brandName: adGroup.brandDisplayName || brand?.display_name || "",
    brandUrl: adGroup.brandLink || brand?.company_website || "",
    brandBlurb: adGroup.blurb || brand?.company_blurb || "",
    creatorBlurb: adGroup.creatorFitBlurb || "",
  };
  const form = useForm<AdGroupEmailFormType>({
    mode: "uncontrolled",
    initialValues: initialState,

    validate: {
      brandName: (value) => (value ? null : "Please enter your brand name"),
      brandUrl: (value) => {
        if (!value) {
          return null;
        }
        try {
          const url = new URL(value?.trim());
        } catch {
          return "Please enter a valid URL";
        }
        return null;
      },
      brandBlurb: (value) => {
        if (value.length > MAX_BLURB_NUM_CHARACTERS) {
          return `Max ${MAX_BLURB_NUM_CHARACTERS} characters. ${
            MAX_BLURB_NUM_CHARACTERS - value.length
          } characters left`;
        }
        return null;
      },
      creatorBlurb: (value) => {
        if (value.length > MAX_BLURB_NUM_CHARACTERS) {
          return `Max ${MAX_BLURB_NUM_CHARACTERS} characters. ${
            MAX_BLURB_NUM_CHARACTERS - value.length
          } characters left`;
        }
        return null;
      },
    },
    onValuesChange: () => {
      setPreviewModeErrorMessage(null);
      setHaveSettingsChanged(form.isDirty());
    },
  });

  form.watch("brandUrl", ({ value }) => {
    setIsLinkInsertEnabled(value.length > 0);
  });

  form.watch("brandBlurb", ({ value }) => {
    form.validateField("brandBlurb");
  });

  form.watch("creatorBlurb", ({ value }) => {
    form.validateField("creatorBlurb");
  });

  return (
    <Modal.Root opened size="xl" onClose={() => closeModal(false)}>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header
          style={{
            alignItems: "start",
          }}>
          <Modal.Title>
            <Title size="h4" component="span">
              Email settings
            </Title>
            <Text size="sm" c="var(--mantine-color-dark-2)">
              This is an example of how your outreach email will look to creators.
            </Text>
          </Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={form.onSubmit(
              async (values) => {
                console.log(values);
                // TODO (victoria 7.2024): MOVE TO REDUX
                await updateAdGroup(campaignHashId, adGroup.key, {
                  ...(form.isDirty("brandName") && { brand_display_name: values.brandName }),
                  ...(form.isDirty("brandBlurb") && { brand_blurb: values.brandBlurb }),
                  ...(form.isDirty("creatorBlurb") && { creator_fit_blurb: values.creatorBlurb }),
                  ...(form.isDirty("brandUrl") && { brand_link: values.brandUrl }),
                }).then((updatedAdGroup) => {
                  form.resetDirty();
                  setHaveSettingsChanged(form.isDirty());
                  closeModal(true);
                });
              },
              (errors) => {
                if (isPreviewMode && errors) {
                  setPreviewModeErrorMessage("Please fix errors before saving.");
                }
              },
            )}>
            <Stack
              style={{
                backgroundColor: "var(--mantine-color-gray-0)",
                borderRadius: 8,
                border: "1px solid var(--mantine-color-gray-3)",
                paddingLeft: 16,
                paddingRight: 16,
                paddingTop: 12,
                paddingBottom: 12,
              }}>
              <Text size="sm">Hi [Creator Name],</Text>
              {isPreviewMode ? (
                <PreviewMode values={form.getValues()} />
              ) : (
                <>
                  <Text size="sm" component="span">
                    I&apos;m Michael from 1stCollab. Today I&apos;m reaching out on behalf of{" "}
                    <Group
                      display="inline-flex"
                      gap={0}
                      style={{
                        alignItems: "start",
                      }}>
                      <TextInput
                        placeholder="Brand Name"
                        size="xs"
                        display="inline-block"
                        key={form.key("brandName")}
                        {...form.getInputProps("brandName")}
                      />
                      <Popover
                        opened={isLinkSettingsOpen}
                        position="top"
                        shadow="md"
                        onChange={(isPopoverOpened) => {
                          setIsLinkSettingsOpen(isPopoverOpened);
                          // if closing popover and url is not valid, then reset url to blank
                          if (!isPopoverOpened && form.validateField("brandUrl").hasError) {
                            form.setFieldValue("brandUrl", "");
                          }
                        }}>
                        <Popover.Target>
                          <ActionIcon
                            variant="transparent"
                            aria-label="Add Link"
                            onClick={() => setIsLinkSettingsOpen(true)}>
                            <IconLink size={16} />
                          </ActionIcon>
                        </Popover.Target>
                        <Popover.Dropdown>
                          <Stack style={{ "--stack-gap": "8px" }}>
                            <Text size="xs" fw={600}>
                              Link Settings
                            </Text>
                            <Group
                              gap={0}
                              style={{ "--group-gap": "8px", "--group-align": "start" }}>
                              <TextInput
                                size="xs"
                                placeholder="https://www.1stcollab.com"
                                key={form.key("brandUrl")}
                                {...form.getInputProps("brandUrl")}
                              />
                              <Button
                                size="xs"
                                disabled={!isLinkInsertEnabled}
                                onClick={() => {
                                  // if brand url is valid, then close popover
                                  if (!form.validateField("brandUrl").hasError) {
                                    setIsLinkSettingsOpen(false);
                                  }
                                }}>
                                Insert
                              </Button>
                            </Group>
                          </Stack>
                        </Popover.Dropdown>
                      </Popover>
                    </Group>
                    to see if you&apos;d be interested in collaborating with them on a paid
                    sponsorship.
                  </Text>
                  <TextareaWithCharacterLimit
                    title="Brand Blurb"
                    placeholder="Brand blurb"
                    tooltipText="The brand blurb is used in our email outreach to creators. By default, we use the brand blurb you set in your brand settings. You can set a custom brand blurb for an ad group to send audience-specific messaging."
                    maxCharacters={MAX_BLURB_NUM_CHARACTERS}
                    formKey={form.key("brandBlurb")}
                    {...form.getInputProps("brandBlurb")}
                  />
                  <TextareaWithCharacterLimit
                    title="Creator Fit Blurb (recommended)"
                    placeholder="They're reaching out because their promoting a new line of fitness equipment they've just released. To help get the word out, they're looking to partner with health and fitness creators like you!"
                    tooltipText="Briefly describe why you're interested in partnering with the creator sets mapped to this ad group. As before, write the blurb in the third person."
                    maxCharacters={MAX_BLURB_NUM_CHARACTERS}
                    formKey={form.key("creatorBlurb")}
                    {...form.getInputProps("creatorBlurb")}
                  />
                  <Text size="sm">
                    If you&apos;re interested, let me know by [date] and I can share more details.
                  </Text>
                  <Text size="sm">Michael</Text>
                </>
              )}
            </Stack>

            <Spacer height={16} />
            <Stack gap={0} style={{ "--stack-gap": "4px" }}>
              <Group justify="space-between">
                <Button
                  variant="outline"
                  onClick={() => closeModal(false)}
                  color="var(--mantine-color-dark-0)"
                  styles={{
                    label: {
                      color: "black",
                    },
                  }}>
                  Cancel
                </Button>
                <Group>
                  <Button
                    variant="outline"
                    onClick={() => {
                      setIsPreviewMode(!isPreviewMode);
                    }}>
                    {isPreviewMode ? "Edit email" : "Preview"}
                  </Button>
                  <Button variant="filled" type="submit" disabled={!haveSettingsChanged}>
                    Save
                  </Button>
                </Group>
              </Group>
              {previewModeErrorMessage ? (
                <Text size="xs" c="var(--mantine-color-error)" style={{ textAlign: "end" }}>
                  {previewModeErrorMessage}
                </Text>
              ) : null}
            </Stack>
          </form>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};

export default EditAdGroupEmailModal;
