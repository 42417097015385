import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { ActionIcon, AppShell, Center, Container, Flex, Loader, Tooltip } from "@mantine/core";
import {
  Outlet,
  useLocation,
  useMatch,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { useUser } from "utils/UserContext";
import { IconChevronsRight, IconMenu2 } from "@tabler/icons-react";
import ShowHideNavBarIcon from "campaigns/navbar/ShowHideNavBarIcon";
import MainNavBar from "campaigns/navbar/main/MainNavBar";
import { ContractFeedType, NAV_BAR_WIDTH } from "campaigns/navbar/consts";
import SecondaryNavBar from "campaigns/navbar/secondary/SecondaryNavBar";
import { Campaign, CampaignAdGroup, BrandToDoSummary } from "models/Campaign";
import {
  SelectedNavButton,
  MainNavButtonType,
  selectAdGroup,
  selectCampaign,
  selectCreatorsMainNavButton,
  selectCreatorsSetNavButton,
  selectMainNavButton,
} from "campaigns/helpers/mainNavButtonHelpers";
import { RootButtonName } from "campaigns/navbar/main/RootButton";
import { SecondaryNavBarMainButtonName } from "campaigns/navbar/secondary/SecondaryNavBarButton";
import { CAMPAIGN_AD_GROUP_PATH, CAMPAIGN_PATH, CAMPAIGN_PORTAL } from "campaigns/PathConstants";
import { fetchCampaignToDoSummary } from "campaigns/api/fetchCampaignTodos";
import { CreatorSetItem } from "components/discovery/Datamodels";
import { getCreatorSetMapping } from "components/creator_sets/CreatorSetUtils";
import { useAppDispatch, useAppSelector } from "reduxStore/hooks";
import { GetCampaignsPayloadAction, getCampaigns } from "reduxStore/campaignsSlice";
import { getCreatorSetItems } from "reduxStore/creatorSetsSlice";
import { useElementSize } from "@mantine/hooks";
import { selectCreatorSetNames, selectCreatorSets } from "reduxStore/creatorSetsSelectors";
import { fetchMyBrand } from "reduxStore/meSlice";

type CampaignAppContext = {
  isCampaignRootSelected: boolean;
  mainAppShellHeight: number;
  selectedCampaign: Campaign | null;
  selectedAdGroup: CampaignAdGroup | null;
  toDoSummary: BrandToDoSummary | null;
};

export function useCampaignAppContext() {
  return useOutletContext<CampaignAppContext>();
}

const CampaignAppShell = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { ref: mainAppShellRef, height: mainAppShellHeight } = useElementSize();

  const [userFromContext, userLoading] = useUser();
  const currentUser = useAppSelector((state) => state.me.user);
  const [isNavBarCollapsed, setIsNavBarCollapsed] = useState<boolean>(false);
  // used to determine whether or not the user currently sees a secondary nav bar
  const [isSecondaryNavBarShown, setIsSecondaryNavBarShown] = useState<boolean>(false);
  // used to determine whether or not the current nav bar has a secondary nav bar
  const [hasSecondaryNavBar, setHasSecondaryNavBar] = useState<boolean>(false);
  const [campaigns, setCampaigns] = useState<Campaign[] | null>(null);
  const [creatorSets, setCreatorSets] = useState<CreatorSetItem[] | null>(null);
  const [toDoSummary, setToDoSummary] = useState<BrandToDoSummary | null>(null);

  const selectedCreatorSetNames = useSelector(selectCreatorSetNames) as { [key: number]: string };
  const selectedCreatorSets = useSelector(selectCreatorSets) as CreatorSetItem[];

  // parse static routes
  const homeMatch = useMatch(`${CAMPAIGN_PORTAL}`);
  const campaignHomeMatch = useMatch(`${CAMPAIGN_PORTAL}/overview`);
  const creativeBriefMatch = useMatch(`${CAMPAIGN_PORTAL}/creative_briefs`);
  const discoverMatch = useMatch(`${CAMPAIGN_PORTAL}/discover`);
  const contractsOverviewMatch = useMatch(`${CAMPAIGN_PORTAL}/contracts_overview/*`);

  const [selectedMainNavButton, setSelectedMainNavButton] = useState<SelectedNavButton>({
    type: MainNavButtonType.MAIN,
    rootButtonName: RootButtonName.HOME,
    campaignHashId: null,
    adGroupId: null,
  });
  const [selectedCampaign, setSelectedCampaign] = useState<Campaign | null>(null);
  const [selectedAdGroup, setSelectedAdGroup] = useState<CampaignAdGroup | null>(null);

  const [selectedSecondaryNavButton, setSelectedSecondaryNavButton] =
    useState<SecondaryNavBarMainButtonName>(SecondaryNavBarMainButtonName.RESULTS);

  // parse params from url
  const urlParams = useParams();
  const urlCampaignId = urlParams.campaignId ? parseInt(urlParams.campaignId, 10) : null;
  const urlAdGroupId = urlParams.adGroupId ? parseInt(urlParams.adGroupId, 10) : null;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const contractFeedType = queryParams.get("type") as ContractFeedType;

  const adGroupDefaultMatch = useMatch({
    path: `${CAMPAIGN_PORTAL}/${CAMPAIGN_AD_GROUP_PATH}`,
    end: false,
  });
  const adGroupContractsMatch = useMatch(`${CAMPAIGN_PORTAL}/${CAMPAIGN_AD_GROUP_PATH}/contracts`);
  const adGroupContractApprovalMatch = useMatch(
    `${CAMPAIGN_PORTAL}/${CAMPAIGN_AD_GROUP_PATH}/contract_approval`,
  );

  const adGroupLiveMatch = useMatch(`${CAMPAIGN_PORTAL}/${CAMPAIGN_AD_GROUP_PATH}/live`);
  const adGroupCreatorsMatch = useMatch(`${CAMPAIGN_PORTAL}/${CAMPAIGN_AD_GROUP_PATH}/creators`);

  const campaignCreatorsMatch = useMatch(`${CAMPAIGN_PORTAL}/${CAMPAIGN_PATH}/creators`);
  const campaignLiveMatch = useMatch(`${CAMPAIGN_PORTAL}/${CAMPAIGN_PATH}/live`);
  const campaignEditMatch = useMatch(`${CAMPAIGN_PORTAL}/${CAMPAIGN_PATH}/edit`);
  const campaignCreatorProgressMatch = useMatch(
    `${CAMPAIGN_PORTAL}/${CAMPAIGN_PATH}/creator_progress`,
  );

  useEffect(() => {
    if (
      homeMatch ||
      campaignHomeMatch ||
      creativeBriefMatch ||
      discoverMatch ||
      contractsOverviewMatch
    ) {
      let rootButtonName: RootButtonName = RootButtonName.CAMPAIGNS;
      if (creativeBriefMatch) {
        rootButtonName = RootButtonName.CREATIVE_BRIEFS;
      }
      if (discoverMatch) {
        rootButtonName = RootButtonName.DISCOVER;
      }
      if (homeMatch) {
        rootButtonName = RootButtonName.HOME;
      }
      if (contractsOverviewMatch) {
        rootButtonName = RootButtonName.CONTRACTS_OVERVIEW;
      }

      setSelectedMainNavButton({
        type: MainNavButtonType.MAIN,
        rootButtonName,
        campaignHashId: null,
        adGroupId: null,
      });
      return;
    }

    if (campaigns?.length > 0) {
      if (urlCampaignId) {
        const matchedCampaign = campaigns?.find((campaign) => campaign.hash_id === urlCampaignId);
        setSelectedCampaign(matchedCampaign);
        if (matchedCampaign) {
          if (urlAdGroupId) {
            const matchedAdGroup = matchedCampaign.ad_groups?.find(
              (adGroup) => adGroup.id === urlAdGroupId,
            );
            setSelectedAdGroup(matchedAdGroup);
            if (!matchedAdGroup) {
              // didn't find a valid ad group for the user's selected campaign so redirectly back to just campaign page
              navigate(`/campaigns/${urlCampaignId}`);
            } else {
              selectAdGroup(setSelectedMainNavButton, matchedCampaign.hash_id, matchedAdGroup.id);
            }
          } else {
            setSelectedAdGroup(null);
            selectCampaign(setSelectedMainNavButton, matchedCampaign.hash_id);
          }
        } else {
          // didn't find a valid campaign for the user so redirectly back to main campaign portal
          setSelectedCampaign(null);
          setSelectedAdGroup(null);
          navigate(`/campaigns`);
        }
      } else {
        // if urlCampaignId is not set, we should clear the fields
        setSelectedCampaign(null);
        setSelectedAdGroup(null);
      }
    }

    if (creatorSets?.length > 0) {
      if (location.pathname.includes("creator_sets/all")) {
        selectCreatorsMainNavButton(setSelectedMainNavButton);
        setSelectedSecondaryNavButton(SecondaryNavBarMainButtonName.ALL_CREATORS);
      } else if (location.pathname.includes("creator_sets/existing_partners")) {
        // if we are on the manage page, we should select the manage button
        selectCreatorsMainNavButton(setSelectedMainNavButton);
        setSelectedSecondaryNavButton(SecondaryNavBarMainButtonName.EXISTING_PARTNERS);
      } else if (location.pathname.includes("creator_sets/manage")) {
        // if we are on the manage page, we should select the manage button
        selectCreatorsMainNavButton(setSelectedMainNavButton);
        setSelectedSecondaryNavButton(SecondaryNavBarMainButtonName.MANAGE_CREATORSETS);
      } else if (location.pathname.includes("creator_sets/review")) {
        // if we are on the manage page, we should select the manage button
        selectCreatorsMainNavButton(setSelectedMainNavButton);
        setSelectedSecondaryNavButton(SecondaryNavBarMainButtonName.REVIEW_CREATORSETS);
      } else if (urlParams.creatorSetId && location.pathname.includes("/view")) {
        selectCreatorsSetNavButton(setSelectedMainNavButton, parseInt(urlParams.creatorSetId, 10));
        setSelectedSecondaryNavButton(SecondaryNavBarMainButtonName.VIEW_CREATORS);
      } else if (urlParams.creatorSetId && location.pathname.includes("/recommended")) {
        selectCreatorsSetNavButton(setSelectedMainNavButton, parseInt(urlParams.creatorSetId, 10));
        setSelectedSecondaryNavButton(SecondaryNavBarMainButtonName.REVIEW_CREATORS);
      }
    }
  }, [campaigns, creatorSets, urlParams]);

  useEffect(() => {
    const abortController = new AbortController();
    if (currentUser) {
      dispatch(getCampaigns(abortController))
        .unwrap()
        .then(({ aborted, campaigns: fetchedCampaigns }: GetCampaignsPayloadAction) => {
          if (!aborted) {
            const updatedCampaigns = fetchedCampaigns.map((campaign) => ({
              ...campaign,
              brand_name: campaign.brand?.brand_name,
            }));
            setCampaigns(updatedCampaigns);
          }
        });
      fetchCampaignToDoSummary(abortController).then((todoSummary) => {
        setToDoSummary(todoSummary);
      });
      dispatch(getCreatorSetItems(abortController))
        .unwrap()
        .then(({ aborted, creatorSetItems: fechedCreatorSetItems }) => {
          if (!aborted) {
            setCreatorSets(fechedCreatorSetItems);
          }
        });
      // Fetch brand as well
      dispatch(fetchMyBrand({ abortController }));
    }
    return () => {
      abortController.abort();
    };
  }, [currentUser]);

  // Update the local state when selectedCreatorSetNames changes
  useEffect(() => {
    const updatedCreatorSets = creatorSets?.map((item) => {
      return {
        ...item,
        name: selectedCreatorSetNames[item.id],
      };
    });
    setCreatorSets(updatedCreatorSets);
  }, [selectedCreatorSetNames]);

  // Update the local state when a new creator set is added
  useEffect(() => {
    if (selectedCreatorSets && creatorSets) {
      // Add the new creator set to the creator set list
      const newCreatorSet = selectedCreatorSets[selectedCreatorSets.length - 1];
      const updatedCreatorSets = [newCreatorSet, ...creatorSets];
      setCreatorSets(updatedCreatorSets);
    }
  }, [selectedCreatorSets]);

  useEffect(() => {
    const showAdGroupNavBar =
      (selectedMainNavButton.type === MainNavButtonType.AD_GROUP ||
        selectedMainNavButton.type === MainNavButtonType.CAMPAIGN) &&
      selectedMainNavButton.campaignHashId !== null;

    const showCreatorSetNavBar =
      selectedMainNavButton.type === MainNavButtonType.CREATORS ||
      selectedMainNavButton.type === MainNavButtonType.CREATOR_SETS;

    const showSideNavBar = showAdGroupNavBar || showCreatorSetNavBar;
    setIsSecondaryNavBarShown(showSideNavBar);
    setHasSecondaryNavBar(showSideNavBar);

    if (showAdGroupNavBar) {
      // Ad Group routes
      if (selectedAdGroup != null) {
        if (adGroupContractApprovalMatch) {
          setSelectedSecondaryNavButton(SecondaryNavBarMainButtonName.CONTRACT_APPROVAL);
          return;
        }
        if (adGroupContractsMatch) {
          if (contractFeedType === ContractFeedType.ReviewContent) {
            setSelectedSecondaryNavButton(SecondaryNavBarMainButtonName.CONTRACTS_REVIEW_CONTENT);
          } else if (contractFeedType === ContractFeedType.PromoCodesAndLinks) {
            setSelectedSecondaryNavButton(
              SecondaryNavBarMainButtonName.CONTRACTS_PROMO_CODES_AND_LINKS,
            );
          } else if (contractFeedType === ContractFeedType.ProductAccess) {
            setSelectedSecondaryNavButton(SecondaryNavBarMainButtonName.CONTRACT_PRODUCT_ACCESS);
          } else if (contractFeedType === ContractFeedType.LiveVerification) {
            setSelectedSecondaryNavButton(
              SecondaryNavBarMainButtonName.CONTRACTS_LIVE_VERIFICATION,
            );
          } else {
            setSelectedSecondaryNavButton(SecondaryNavBarMainButtonName.CONTRACTS);
          }
        } else if (adGroupLiveMatch) {
          setSelectedSecondaryNavButton(SecondaryNavBarMainButtonName.LIVE);
        } else if (adGroupCreatorsMatch) {
          setSelectedSecondaryNavButton(SecondaryNavBarMainButtonName.CREATORS);
        } else {
          setSelectedSecondaryNavButton(SecondaryNavBarMainButtonName.RESULTS);
        }
      } else if (selectedCampaign != null) {
        if (campaignCreatorsMatch) {
          setSelectedSecondaryNavButton(SecondaryNavBarMainButtonName.CREATORS);
        } else if (campaignCreatorProgressMatch) {
          setSelectedSecondaryNavButton(SecondaryNavBarMainButtonName.CREATOR_PROGRESS);
        } else if (campaignLiveMatch) {
          setSelectedSecondaryNavButton(SecondaryNavBarMainButtonName.LIVE);
        } else if (campaignEditMatch) {
          setSelectedSecondaryNavButton(SecondaryNavBarMainButtonName.EDIT_CAMPAIGN);
        } else {
          setSelectedSecondaryNavButton(SecondaryNavBarMainButtonName.RESULTS);
        }
      }
    }

    if (
      selectedMainNavButton.type === MainNavButtonType.CREATORS &&
      !(
        selectedSecondaryNavButton === SecondaryNavBarMainButtonName.ALL_CREATORS ||
        selectedSecondaryNavButton === SecondaryNavBarMainButtonName.MANAGE_CREATORSETS ||
        selectedSecondaryNavButton === SecondaryNavBarMainButtonName.REVIEW_CREATORSETS ||
        selectedSecondaryNavButton === SecondaryNavBarMainButtonName.EXISTING_PARTNERS
      )
    ) {
      setSelectedSecondaryNavButton(SecondaryNavBarMainButtonName.ALL_CREATORS);
    }

    if (
      selectedMainNavButton.type === MainNavButtonType.CREATOR_SETS &&
      !(
        selectedSecondaryNavButton === SecondaryNavBarMainButtonName.REVIEW_CREATORS ||
        selectedSecondaryNavButton === SecondaryNavBarMainButtonName.VIEW_CREATORS
      )
    ) {
      setSelectedSecondaryNavButton(SecondaryNavBarMainButtonName.REVIEW_CREATORS);
    }
  }, [selectedMainNavButton]);

  if (userLoading || !currentUser) {
    return (
      <Container fluid h="100vh">
        <Center h="100%">
          <Loader color="blue" />
        </Center>
      </Container>
    );
  }

  return (
    <AppShell
      navbar={{
        width: isSecondaryNavBarShown ? NAV_BAR_WIDTH * 2 : NAV_BAR_WIDTH,
        breakpoint: "sm",
        collapsed: { desktop: isNavBarCollapsed },
      }}
      padding="md">
      <AppShell.Navbar withBorder={!isSecondaryNavBarShown}>
        <Flex gap={0} h="100%">
          <MainNavBar
            campaigns={campaigns}
            user={currentUser}
            onCollapseNavBar={() => setIsNavBarCollapsed(true)}
            style={{ width: NAV_BAR_WIDTH }}
            selectedButton={selectedMainNavButton}
            setSelectedButton={setSelectedMainNavButton}
            creatorSets={creatorSets}
            toDoSummary={toDoSummary}
          />
          {isSecondaryNavBarShown ? (
            <SecondaryNavBar
              selectedButton={selectedSecondaryNavButton}
              setSelectedButton={(selectedButton) => setSelectedSecondaryNavButton(selectedButton)}
              onHideNavBar={() => setIsSecondaryNavBarShown(false)}
              toDoSummary={
                toDoSummary?.campaigns?.[selectedCampaign?.hash_id]?.ad_groups?.[
                  selectedAdGroup?.id
                ]
              }
              campaign={selectedCampaign}
            />
          ) : null}
        </Flex>
      </AppShell.Navbar>

      <AppShell.Main bg="var(--mantine-color-gray-1)" ref={mainAppShellRef}>
        {isNavBarCollapsed ? (
          <ShowHideNavBarIcon
            IconName={IconChevronsRight}
            onIconClick={() => setIsNavBarCollapsed(false)}
            tooltipText="Show nav bar"
          />
        ) : null}
        {!isSecondaryNavBarShown && !isNavBarCollapsed && hasSecondaryNavBar ? (
          <Flex h={48} align="center">
            <Tooltip label="Show secondary nav bar">
              <ActionIcon variant="light" size={32} onClick={() => setIsSecondaryNavBarShown(true)}>
                <IconMenu2 size={24} color="var(--mantine-color-blue-6)" />
              </ActionIcon>
            </Tooltip>
          </Flex>
        ) : null}
        <Outlet
          context={{
            isCampaignRootSelected:
              selectedMainNavButton.rootButtonName === RootButtonName.CAMPAIGNS,
            mainAppShellHeight,
            selectedCampaign,
            selectedAdGroup,
            toDoSummary,
          }}
        />
      </AppShell.Main>
    </AppShell>
  );
};

export default CampaignAppShell;
