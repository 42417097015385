/* eslint-disable @typescript-eslint/no-var-requires */
const bubbles = require("./bubbles.png");
const linkImage = require("./linkImage.png");
const mediaKitHero = require("./hero_clean.png");
const needle = require("./needle.png");
const realTimeMetrics = require("./realTimeMetrics.png");
/* eslint-enable @typescript-eslint/no-var-requires */

const IMAGES = {
  bubbles,
  linkImage,
  mediaKitHero,
  needle,
  realTimeMetrics,
};

export default IMAGES;
