import React from "react";
import { Text, Title } from "@mantine/core";

import header from "components/contracts/contract/TitleHeader";

const TermsOfService = ({ isPrintView = false }: { isPrintView?: boolean }) => (
  <>
    {isPrintView ? header("Terms and Conditions", 3) : <Title>Terms and Conditions</Title>}
    <br />
    <Text fw={700}>Effective as of January 1, 2024</Text>
    <br />
    <br />
    <Text fw={700}>1. Agreement to Terms</Text>
    <br />
    <Text>
      These Terms of Use are a legally binding agreement between you, whether personally or on
      behalf of an entity (“you”) and 1stCollab Inc. (“Company”, “we”, “us”, “our”) concerning your
      access to and use of of the https://www.1stcollab.com website as well as any other media form,
      media channel, website, mobile application related, linked, or otherwise connected thereto
      (collectively, the “Site”). We operate this site to provide information and certain services
      to visitors (the “Services“). If you access the Site or accept these Terms on behalf of a
      company or other legal entity, you represent and warrant that you have the authority to bind
      that legal entity and, in such event, “you” and “your” will refer to that legal entity. In
      addition, in these Terms, unless the context requires otherwise, words in one gender include
      all genders and words in the singular include the plural and vice-versa.
    </Text>
    <br />
    <Text>
      By using the Site or any Services available through the Site, you agree to comply with and be
      legally bound by the terms, conditions, and restrictions of these Terms of Use (“Terms“).
      Please read these Terms carefully, as well as our Privacy Policy, which may be found at
      https://www.1stcollab.com/privacy-policy, and which are incorporated by reference into these
      Terms. These Terms, together with our Privacy Policy, govern your access to and use of the
      Site and Services, and constitute a binding legal agreement between you and 1stCollab. If you
      do not agree to these Terms, you have no right to obtain information from or otherwise
      continue using the Site or Services.
    </Text>
    <br />
    <Text>
      This site is not tailored to comply with industry-specific regulations (such as Health
      Insurance Portability and Accountability Act (HIPAA), Federal Information Security Management
      Acr (FISMA), etc.), so if your interactions would be subjected to such laws, you are forbidden
      from using this site. You also may not use this Site in such a way that would violate the
      Gramm-Leach-Bliley Act (GLBA).
    </Text>
    <br />
    <Text>
      This Site is intended for users who are at least 18 years old. Persons under the age of 18 are
      not permitted to use or register for the Site, create a profile, run campaigns, or conduct any
      other activity on this Site.
    </Text>
    <br />
    <Text>
      IF YOU DO NOT AGREE WITH ALL OF THESE TERMS OF USE, THEN YOU ARE EXPRESSLY PROHIBITED FROM
      USING THE SITE AND ALL OF OUR OFFERINGS AND YOU MUST DISCONTINUE USE IMMEDIATELY.
    </Text>
    <br />
    <Text>
      YOU ACKNOWLEDGE AND AGREE THAT, BY ACCESSING OR USING THE SITE OR SERVICES, YOU ARE INDICATING
      THAT YOU HAVE READ, AND THAT YOU UNDERSTAND AND AGREE TO BE BOUND BY THESE TERMS.
    </Text>
    <br />
    <Text fw={700}>2. Modification</Text>
    <br />
    <Text>
      We reserve the right, at our sole discretion, to modify or discontinue, temporarily or
      permanently, the Site, Services or to modify these Terms at any time and without prior notice.
      If we modify these Terms, we will post the modification on the Site. We will also update the
      “Last Updated Date” at the top of these Terms. Modifications to these Terms will automatically
      take effect upon posting. By continuing to access or use the Site after we have posted a
      modification, you are indicating that you agree to be bound by the modified Terms. If the
      modified Terms are not acceptable to you, your only recourse is to cease accessing or using
      the Site.
    </Text>
    <br />
    <Text fw={700}>3. Eligibility</Text>
    <br />
    <Text>
      The Site is intended solely for persons who are 18 or older. By accessing or using the Site
      you represent and warrant that you are not legally prohibited from accessing the Site or using
      the Services under the laws of the country in which you access or use the Site.
    </Text>
    <br />
    <Text fw={700}>4. About the Site</Text>
    <br />
    <Text>
      The Site allows a User to access information and the Services (including but not limited to
      searching for influencers, viewing influencer profiles, creating a campaign, browsing
      postings, creating a user profile, creating a roster, participate in an influencer marketing
      campaign, and more). The Site also allows users to apply for an account, signup for
      notifications or a newsletter, or engage with 1stCollab through provided communication
      options. You agree to provide accurate, current and complete information in all communications
      with 1stCollab and in all other use of the Site or Services.
    </Text>
    <br />
    <Text>
      THE SITE AND SERVICES ARE INTENDED TO BE USED TO FACILITATE MARKETERS, AGENTS, AND INFLUENCERS
      TO CONNECT AND ENTER INTO AGREEMENTS DIRECTLY WITH EACH OTHER. 1STCOLLAB CANNOT AND DOES NOT
      CONTROL THE CONTENT POSTED BY USERS AND IS NOT RESPONSIBLE FOR AND DISCLAIMS ANY AND ALL
      LIABILITY RELATED TO ANY AND ALL SUCH USER CONTENT, INCLUDING COMPLIANCE WITH APPLICABLE LAWS,
      RULES, AND GUIDES (SUCH AS FTC GUIDES CONCERNING SPONSORED ENDORSEMENTS).
    </Text>
    <br />
    <Text fw={700}>5. User Affirmations, Conduct and Use</Text>
    <br />
    <Text>By using the Site, you represent, warrant and agree to the following:</Text>
    <br />
    <Text>&#x2022;You are at least 18 years of age.</Text>
    <br />
    <Text>
      &#x2022;You are solely responsible for compliance with any and all laws, rules, regulations,
      or obligations that may apply to your use of the Site.
    </Text>
    <br />
    <Text>
      &#x2022;You agree that you will safeguard your password and that you are solely responsible
      for any activities or actions under your Account, whether or not you have authorized such
      activities or actions.
    </Text>
    <br />
    <Text>
      &#x2022;Nothing that you upload, publish, represent, warrant or transmit using the Site, will
      infringe, misappropriate or violate a third party’s patent, copyright, trademark, trade
      secret, moral rights or other proprietary or intellectual property rights, or rights of
      publicity or privacy, or result in the violation of any applicable law or regulation.
    </Text>
    <br />
    <Text>
      &#x2022; You will not use manual or automated software, devices, scripts, redirects, robots,
      other means or processes to access, “scrape”, “crawl” or “spider” any web pages or other
      services contained in the Services.
    </Text>
    <br />
    <Text>
      &#x2022; You will not use the Site for any commercial or other purposes that are not expressly
      permitted by these Terms.
    </Text>
    <br />
    <Text>
      &#x2022; You will not copy, store or otherwise access any information contained on the Site
      for purposes not expressly permitted by these Terms.
    </Text>
    <br />
    <Text>
      &#x2022;You will not interfere with or damage the Site, including, without limitation, through
      the use of viruses, cancel bots, Trojan horses, harmful code, flood pings, denial-of-service
      attacks, packet or IP spoofing, forged routing or electronic mail address information or
      similar methods or technology;
    </Text>
    <br />
    <Text>
      &#x2022;You will not, directly or indirectly: reverse engineer, decompile, disassemble or
      otherwise attempt to discover the source code, object code or underlying structure, ideas,
      know-how or algorithms relevant to the Site or Services, documentation or data related to the
      Services; modify, translate, or create derivative works based on the Site or Services (except
      to the extent expressly permitted by Company or authorized within the Services); use the
      Services or Site for timesharing or service bureau purposes or otherwise for the benefit of a
      third; or remove any proprietary notices or labels.
    </Text>
    <br />
    <Text>
      &#x2022;You will not impersonate any person or entity, or falsify or otherwise misrepresent
      yourself or your affiliation with any person or entity. You will provide us with—and keep
      updated—accurate, and complete information about yourself. This includes but is not limited to
      your name and username (i.e., you should not select a name or username that you don’t have the
      right to use).
    </Text>
    <br />
    <Text>
      &#x2022;You will not systematically retrieve data or other content from our Services to create
      or compile, directly or indirectly, in single or multiple downloads, a collection,
      compilation, database, directory or the like, whether by manual methods, through the use of
      bots, crawlers, or spiders, or otherwise.
    </Text>
    <br />
    <Text>
      &#x2022;You will not access, tamper with, or use non-public areas of the Site, 1stCollab’s
      computer systems, or any third-party provider system.
    </Text>
    <br />
    <Text>
      &#x2022;You will not attempt to probe, scan, or test the vulnerability of any 1stCollab system
      or network or breach any security or authentication measures.
    </Text>
    <br />
    <Text>
      &#x2022;You will not avoid, bypass, remove, deactivate, impair, descramble, or otherwise
      circumvent any technological measure implemented by 1stCollab or any of 1stCollab’s providers
      or any other third party (including other Users) to protect the Site.
    </Text>
    <br />
    <Text>
      &#x2022;You will not advocate, encourage, or assist any third party in doing any of the
      foregoing. 1stCollab will have the right to investigate and prosecute violations of any of the
      above to the fullest extent of the law. 1stCollab may involve and cooperate with law
      enforcement authorities in prosecuting Users who violate these Terms.
    </Text>
    <br />
    <Text>
      You acknowledge that 1stCollab has no obligation to monitor your access to or use of the Site,
      but has the right to do so for the purpose of operating the Site, to ensure your compliance
      with these Terms, or to comply with applicable law or the order or requirement of a court,
      administrative agency or another governmental body.
    </Text>
    <br />
    <Text fw={700}>6. Intellectual property rights</Text>
    <br />
    <Text>
      Unless otherwise indicated, this Site and Services are our proprietary property. All source
      code, databases, functionality, software, designs, audio, video, text, photographs, and
      graphics on the Site (collectively, the “Content”) and the trademarks, service marks, and
      logos contained therein (the “Marks”) are owned or controlled by us or licensed to us and are
      protected by copyright, trademark, and other laws of the United States, international
      copyright laws, and international conventions. The Content and the Marks are provided on the
      Site “AS IS” for your information and personal use only. You acknowledge and agree that the
      Site and Services, including all associated intellectual property rights, are the exclusive
      property of 1stCollab and its licensors. You will not remove, alter or obscure any copyright,
      trademark, service mark or other proprietary rights notices incorporated in or accompanying
      the Site or Services. Except as expressly provided in these Terms, no part of the Site or
      Services and no Content or Marks may be copied, reproduced, aggregated, republished, uploaded,
      posted, publicly displayed, encoded, translated, transmitted, distributed, sold, licensed, or
      otherwise exploited for any commercial purpose whatsoever, without our express prior written
      permission.
    </Text>
    <br />
    <Text>
      Provided that you are eligible to use the Site, you are granted a limited license to access
      and use the site and to download or print a copy of any portion of the content to which you
      have properly gained access solely for your personal, non-commercial use. We reserve all
      rights not expressly granted to you in and to the site, the Content and the Marks.
    </Text>
    <br />
    <Text>
      Note that content displayed from other websites, such as an embedded post from Instagram, is
      not owned by 1stCollab and is subject to the terms of use from the original website. We do not
      claim ownership over such content.
    </Text>
    <br />
    <Text fw={700}>7. Third-party websites, links, and content</Text>
    <br />
    <Text>
      The Site may contain, or you may be sent via the Site, links to third-party websites
      (“Third-Party Websites”) or resources, as well as articles, photographs, text, graphics,
      pictures, designs, music, sound, video, information, applications, software, and other content
      or items belonging to or originating from third parties (“Third-Party Content”). Such
      Third-Party Websites and Third-Party Content are not investigated, monitored, or checked for
      accuracy, appropriateness, or completeness by us, and we are not responsible for any
      Third-Party Websites accessed through the Site or any Third-Party Content posted on, available
      through, embedded on, or installed from the Site, including the content, accuracy,
      offensiveness, opinions, reliability, privacy practices, or other policies of or contained in
      the Third-Party Websites or the Third-Party Content. Inclusion of, linking to, or permitting
      the use or installation of any Third-Party Websites or any Third-Party Content does not imply
      approval or endorsement thereof by us. If you decide to leave the site and access the
      Third-Party Websites or to use or install any Third-Party Content, you should do so at your
      own risk, and you should be aware these Terms no longer govern. You should review the
      applicable terms and policies, including privacy and data gathering practices, of any website
      to which you navigate from the site or relating to any applications you use or install from
      the site. Any agreements, purchases, or transactions that you make through Third-Party
      Websites will be through other websites and from other companies, and we take no
      responsibility whatsoever in relation to such purchases and agreements, which are exclusively
      between you and the applicable third party. You agree and acknowledge that we do not endorse
      their products or services offered on Third-Party Websites and you shall hold us harmless from
      any harm caused by your use of such products or Services. Additionally, you shall hold us
      harmless for many losses sustained by you or harm caused to you or relating to or resulting in
      any way from any Third-Party Content or any contact with Third-Party Websites. You acknowledge
      sole responsibility for and assume all risk arising from your use of any such websites or
      resources or the content, products or services on or available from such websites or
      resources.
    </Text>
    <br />
    <Text>
      Further, you are responsible for all Content you contribute, in any manner, to the Site and
      Services, and you represent and warrant you have all rights necessary to do so, in the manner
      in which you contribute it.
    </Text>
    <br />
    <Text fw={700}>8. Confidentiality and Proprietary Rights</Text>
    <br />
    <Text>
      Each party (the “Receiving Party”) understands that the other party (the “Disclosing Party”)
      has disclosed or may disclose business, technical or financial information relating to the
      Disclosing Party’s business (hereinafter referred to as “Proprietary Information” of the
      Disclosing Party). Proprietary Information of 1stCollab includes non-public information
      regarding features, functionality and performance of the Service. Your Proprietary Information
      includes non-public data provided by to 1stCollab to enable the provision of the Services
      (“Customer Data”). The Receiving Party agrees: (i) to take reasonable precautions to protect
      such Proprietary Information, and (ii) not to use (except in performance of the Services or as
      otherwise permitted herein) or divulge to any third person any such Proprietary Information.
      The Disclosing Party agrees that the foregoing shall not apply with respect to any information
      after five (5) years following the disclosure thereof or any information that the Receiving
      Party can document (a) is or becomes generally available to the public, or (b) was in its
      possession or known by it prior to receipt from the Disclosing Party, or (c) was rightfully
      disclosed to it without restriction by a third party, or (d) was independently developed
      without use of any Proprietary Information of the Disclosing Party or (e) is required to be
      disclosed by law.
    </Text>
    <br />
    <Text>
      You shall own all right, title and interest in and to the Customer Data. 1stCollab shall own
      and retain all right, title and interest in and to (a) the Services and Software, all
      improvements, enhancements or modifications thereto, (b) any software, applications,
      inventions or other technology developed in connection with Implementation Services or
      support, and (c) all intellectual property rights related to any of the foregoing.
    </Text>
    <br />
    <Text>
      Notwithstanding anything to the contrary, 1stCollab shall have the right collect and analyze
      data and other information relating to the provision, use and performance of various aspects
      of the Services and related systems and technologies (including, without limitation,
      information concerning Customer Data and data derived therefrom; Campaign parameters, budget,
      and performance; Influencer preferences and agreements; and other information concerning
      Campaigns), and 1stCollab will be free (during and after the term hereof) to (i) use such
      information and data to improve and enhance the Services and for other development, diagnostic
      and corrective purposes in connection with the Services and other offerings, and (ii) disclose
      such data solely in aggregate or other de-identified form in connection with its business. No
      rights or licenses are granted except as expressly set forth herein.
    </Text>
    <br />
    <Text fw={700}>9. Advertisements</Text>
    <br />
    <Text>
      1stCollab may include advertisements on its own behalf or paid advertisements on behalf of
      interested companies and/or individuals on the Site. By clicking on the advertisements, you
      may be shifted to a website of the advertiser or receive other messages, information, or
      offers from the advertiser. You acknowledge and agree that 1stCollab is not liable for the
      privacy practices of advertisers or the content of their websites, information, messages or
      offers. Users are wholly liable for all communications with advertisers and for all
      transactions subsequently executed.
    </Text>
    <br />
    <Text fw={700}>10. Proprietary Rights Notices</Text>
    <br />
    <Text>
      All trademarks, service marks, logos, trade names and any other proprietary designations of
      1stCollab used herein are trademarks or registered trademarks of 1stCollab. Any other
      trademarks, service marks, logos, trade names, and any other proprietary designations are the
      trademarks or registered trademarks of their respective parties.
    </Text>
    <br />
    <Text fw={700}>11. Termination</Text>
    <br />
    <Text>
      We may, in our discretion and without liability to you, with or without cause, with or without
      prior notice, and at any time terminate these Terms or your access to our Site.
    </Text>
    <br />
    <Text>
      If we terminate or suspend your account for any reason, you are prohibited from registering
      and creating a new account under your name, a fake or borrowed name, or the name of any third
      party, even if you may be acting on behalf of a third party. In addition to terminating or
      suspending your account, we reserve the right to take appropriate legal action, including
      without limitation pursuing civil, criminal, and injunctive redress.
    </Text>
    <br />
    <Text fw={700}>12. Disclaimers</Text>
    <br />
    <Text>
      IF YOU CHOOSE TO USE THE SITE OR SERVICE, YOU DO SO AT YOUR SOLE RISK. THE SITE AND SERVICE
      ARE PROVIDED “AS IS” AND “AS AVAILABLE,” WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR
      IMPLIED. WITHOUT LIMITING THE FOREGOING, 1STCOLLAB EXPLICITLY DISCLAIMS ANY WARRANTIES OF
      MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUIET ENJOYMENT OR NON-INFRINGEMENT, AND
      ANY WARRANTIES ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE. WE MAKE NO WARRANTIES OR
      REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SITE’S CONTENT OR THE CONTENT OF ANY
      WEBSITES LINKED TO THE SITE AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR (1) ANY
      ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY
      DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SITE, (3) ANY
      UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION
      AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION
      TO OR FROM THE SITE OR SERVICES, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY
      BE TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD PART, AND/OR (6) ANY ERRORS OR OMISSIONS IN
      ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND AS A RESULT OF THE USE OF THIS
      SITE, SERVICES, OR CONTENT. 1STCOLLAB MAKES NO WARRANTY THAT THE SITE OR SERVICES WILL MEET
      YOUR REQUIREMENTS OR BE AVAILABLE ON AN UNINTERRUPTED, SECURE, OR ERROR-FREE BASIS. 1STCOLLAB
      MAKES NO WARRANTY REGARDING THE QUALITY OF THE SITE OR SERVICES. YOU AGREE THAT YOUR USE OF
      THE SITE AND OUT SERVICES WILL BE AT YOUR SOLE RISK.
    </Text>
    <br />
    <Text fw={700}>13. Limitation of Liability</Text>
    <br />
    <Text>
      YOU ACKNOWLEDGE AND AGREE THAT, TO THE MAXIMUM EXTENT PERMITTED BY LAW, THE ENTIRE RISK
      ARISING OUT OF YOUR ACCESS TO AND USE OF THE SITE OR SERVICES REMAINS WITH YOU. IN NO EVENT
      WILL 1STCOLLAB NOR ANY OTHER PARTY INVOLVED IN CREATING, PRODUCING OR DELIVERING THE SITE OR
      ANY SERVICES BE LIABLE FOR ANY DIRECT, INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL
      DAMAGES, INCLUDING LOST PROFITS, LOSS OF DATA OR LOSS OF GOODWILL, SERVICE INTERRUPTION,
      COMPUTER DAMAGE OR SYSTEM FAILURE OR THE COST OF SUBSTITUTE PRODUCTS OR SERVICES, OR FOR ANY
      DAMAGES FOR PERSONAL OR BODILY INJURY OR EMOTIONAL DISTRESS ARISING OUT OF OR IN CONNECTION
      WITH THESE TERMS, FROM THE USE OF OR INABILITY TO USE THE SITE OR SERVICES, WHETHER BASED ON
      WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR ANY OTHER LEGAL THEORY,
      AND WHETHER OR NOT 1STCOLLAB HAS BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, EVEN IF A
      LIMITED REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.
    </Text>
    <br />
    <Text fw={700}>14. Indemnification</Text>
    <br />
    <Text>
      You agree to release, defend, indemnify, and hold 1stCollab and its affiliates and
      subsidiaries, and their officers, directors, employees and agents, harmless from and against
      any claims, liabilities, damages, losses, and expenses, including, without limitation,
      reasonable legal fees, arising out of or in any way connected with (a) your access to or use
      of the Site, or your violation of these Terms; (b) your reliance on the Site; (c) your
      violation of any third party right, including without limitation any copyright, property, or
      privacy right; or (d) any claim that your use of the Site caused damage to a third party.
      1stCollab shall have the right to control all defense and settlement activities.
    </Text>
    <br />
    <Text fw={700}>15. Assignment</Text>
    <br />
    <Text>
      You may not assign or transfer these Terms, by operation of law or otherwise, without our
      prior written consent. Any attempt by you to assign or transfer these Terms, without such
      consent, will be null and of no effect. 1stCollab may assign or transfer these Terms, at its
      sole discretion, without restriction. Subject to the foregoing, these Terms will bind and
      inure to the benefit of the parties, their successors and permitted assigns.
    </Text>
    <br />
    <Text fw={700}>16. Notices and Corrections</Text>
    <br />
    <Text>
      Unless otherwise specified herein, any notices or other communications permitted or required
      hereunder, including those regarding modifications to these Terms, will be in writing and
      given by 1stCollab by posting to the website or via electronic mail. For notices or
      communications by 1stCollab made by e-mail, if any, the date of receipt will be deemed the
      date on which such notice is transmitted.
    </Text>
    <br />
    <Text>
      There may be information on the Site that contains typographical errors, inaccuracies, or
      omissions that may relate to the Site or Services, including descriptions, pricing,
      availability, and various other information. We reserve the right to correct any errors,
      inaccuracies, or omissions and to change our update the information on the Site at any time,
      without prior notice.
    </Text>
    <br />
    <Text fw={700}>17. Governing Law and Jurisdiction</Text>
    <br />
    <Text>
      You agree that (i) the Site shall be deemed solely based in California, and (ii) the Site
      shall be deemed a passive website that does not give rise to personal jurisdiction over
      1stCollab, either specific or general, in jurisdictions other than California. These Terms
      shall be governed by the internal substantive laws of the State of California, without respect
      to its conflict of laws principles. You and we agree to submit to the personal jurisdiction of
      a state court located in California for any actions for which the parties retain the right to
      seek injunctive or other equitable relief in a court of competent jurisdiction to prevent the
      actual or threatened infringement, misappropriation or violation of a party’s copyrights,
      trademarks, trade secrets, patents, or other intellectual property rights, as set forth in the
      Dispute Resolution Provision below.
    </Text>
    <br />
    <Text>
      YOU AND 1STCOLLAB AGREE THAT ANY CAUSE OF ACTION ARISING OUT OF OR RELATED TO THE SITE,
      SERVICES OR CONTENT MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES.
      OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY BARRED AND WAIVED.
    </Text>
    <br />
    <Text fw={700}>18. Severability</Text>
    <br />
    <Text>
      These Terms are intended to govern the agreement between 1stCollab and you to the extent
      permitted by all applicable laws, ordinances, rules, and regulations. If any provision of
      these Terms or the application thereof to any person or circumstances shall, for any reason or
      to any extent, be invalid or unenforceable, the remainder of these Terms and the application
      of such provision to other persons or circumstances shall not be affected thereby, but rather
      shall be enforced to the greatest extent permitted by law.
    </Text>
    <br />
    <Text fw={700}>19. Dispute Resolution Provision</Text>
    <br />
    <Text>
      You and 1stCollab agree that any dispute, claim or controversy arising out of or relating to
      these Terms or the breach, termination, enforcement, interpretation or validity thereof, or to
      the use of the Site (collectively, “Disputes”) will be settled by binding arbitration; except
      that each party retains the right to seek injunctive or other equitable relief in a court of
      competent jurisdiction to prevent the actual or threatened infringement, misappropriation or
      violation of a party’s copyrights, trademarks, trade secrets, patents, or other intellectual
      property rights.
    </Text>
    <br />
    <Text>
      You acknowledge and agree that you and 1stCollab are each waiving the right to a trial by jury
      or to participate as a plaintiff or class member in any purported class action or
      representative proceeding. Further, unless both you and 1stCollab otherwise agree in writing,
      the arbitrator may not consolidate more than one person’s claims, and may not otherwise
      preside over any form of any class or representative proceeding. If this specific paragraph is
      held unenforceable, then the entirety of this “Dispute Resolution” section will be deemed
      void. Except as provided in the preceding sentence, this “Dispute Resolution” section will
      survive any termination of these Terms.
    </Text>
    <br />
    <Text>
      Arbitration Rules and Governing Law. The arbitration will be administered by the American
      Arbitration Association (“AAA”) in accordance with the Commercial Arbitration Rules and the
      Supplementary Procedures for Consumer-Related Disputes (the “AAA Rules”) then in effect,
      except as modified by this “Dispute Resolution” section. (The AAA Rules are available at
      www.adr.org/ or by calling the AAA at 1-800-778-7879.) The Federal Arbitration Act will govern
      the interpretation and enforcement of this section.
    </Text>
    <br />
    <Text>
      Arbitration Process. A party who desires to initiate arbitration must provide the other party
      with a written Demand for Arbitration as specified in the AAA Rules. The AAA provides a form
      Demand for Arbitration at www.adr.org/ and a separate form for California residents at
      www.adr.org/. The arbitrator will be either a retired judge or an attorney licensed to
      practice law in the state of California and will be selected by the parties from the AAA’s
      roster of consumer dispute arbitrators. If the parties are unable to agree upon an arbitrator
      within seven (7) days of delivery of the Demand for Arbitration, then the AAA will appoint the
      arbitrator in accordance with the AAA Rules.
    </Text>
    <br />
    <Text>
      Arbitration Location and Procedure. Unless you and 1stCollab otherwise agree, the arbitration
      will be conducted in the county where you reside. If your claim does not exceed $10,000, then
      the arbitration will be conducted solely on the basis of documents you and 1stCollab submit to
      the arbitrator, unless you request a hearing or the arbitrator determines that a hearing is
      necessary. If your claim exceeds $10,000, your right to a hearing will be determined by the
      AAA Rules. Subject to the AAA Rules, the arbitrator will have the discretion to direct a
      reasonable exchange of information by the parties, consistent with the expedited nature of the
      arbitration.
    </Text>
    <br />
    <Text>
      Arbitrator’s Decision. The arbitrator will render an award within the time frame specified in
      the AAA Rules. The arbitrator’s decision will include the essential findings and conclusions
      upon which the arbitrator based the award. Judgment on the arbitration award may be entered in
      any court having jurisdiction thereof. The arbitrator’s award damages must be consistent with
      the terms of the “Limitation of Liability” section above as to the types and the amounts of
      damages for which a party may be held liable. The arbitrator may award declaratory or
      injunctive relief only in favor of the claimant and only to the extent necessary to provide
      relief warranted by the claimant’s individual claim. If you prevail in arbitration you will be
      entitled to an award of attorneys’ fees and expenses, to the extent provided under applicable
      law.
    </Text>
    <br />
    <Text>
      Fees. Your responsibility to pay any AAA filing, administrative and arbitrator fees will be
      solely as set forth in the AAA Rules. However, if your claim for damages does not exceed
      $20,000, 1stCollab will pay all such fees unless the arbitrator finds that either the
      substance of your claim or the relief sought in your Demand for Arbitration was frivolous or
      was brought for an improper purpose (as measured by the standards set forth in Federal Rule of
      Civil Procedure 11(b)).
    </Text>
    <br />
    <Text>
      No Class Action. YOU AND 1STCOLLAB AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN
      YOUR OR ITS INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS
      OR REPRESENTATIVE PROCEEDING. Further, unless both you and we agree otherwise, the arbitrator
      may not consolidate more than one person’s claims, and may not otherwise preside over any form
      of a representative or class proceeding.
    </Text>
    <br />
    <Text>
      Changes. Notwithstanding the provisions of the “Modification” section above, if 1stCollab
      amends this “Dispute Resolution” section after the date you first accepted these Terms (or
      accepted any subsequent changes to these Terms) you will be notified in accordance with these
      Terms. You may reject any such change by sending us written notice (including by email to
      hello@1stcollab.com) within 30 days of the date such change became effective, as indicated in
      the “Last Updated Date” above or in the date of 1stCollab’s email to you notifying you of such
      change. By rejecting any change, you are agreeing that you will arbitrate any Dispute between
      you and 1stCollab in accordance with the provisions of this “Dispute Resolution” section as of
      the date you first accepted these Terms (or accepted any subsequent changes to these Terms).
    </Text>
    <br />
    <Text>
      Enforceability. If the “No Class Action” section of this Dispute Resolution Provision is found
      to be unenforceable, or if the entire Dispute Resolution Provision is found to be
      unenforceable, then the entirety of the Dispute Resolution Provision will be null and void
      and, in that case, the parties agree that the exclusive jurisdiction and venue described above
      will govern any action arising out of or related to these Terms.
    </Text>
    <br />
    <Text>
      Right to Opt-Out of Arbitration and Class Action/Jury Trial Waiver: You may opt-out of this
      arbitration and class action/jury trial waiver provision by notifying us in writing within 30
      days of the date you first registered for the Services.To opt-out, you must send a written
      notification to 1stCollab, Inc., 2261 Market Street #4935, San Francisco, CA 94114 that
      includes (a) your account username, (b) your name, (c) your address, (d) your telephone
      number, (e) your email address, and (f) a clear statement indicating that you do not wish to
      resolve claims through arbitration and demonstrating compliance with the 30-day time limit to
      opt-out of the above arbitration and class action/jury trial waiver provisions. Alternatively
      or in addition, you may send this written notification to hello@1stcollab.com.
    </Text>
    <br />
    <Text fw={700}>20. Trademark license</Text>
    <br />
    <Text>
      You grant to 1stCollab—on behalf of yourself and your company or other entity (if applicable)—
      a non-exclusive, non-transferable, non-assignable, non-sublicensable, limited right and
      license to use your trademarks, trade names and service marks in connection with the Services
      and Sites provided by 1stCollab, and our relationship with you. (For example, this allows
      1stCollab to find and recruit influencers to work with you on an influencer marketing
      campaign).
    </Text>
    <br />
    <Text fw={700}>21. International Users</Text>
    <br />
    <Text>
      1stCollab makes no claim that the Site is appropriate or may be downloaded outside of the
      United States. If you access the Site from a location outside the United States, you do so at
      your own risk and are responsible for compliance with all applicable laws, rules, regulations
      or decrees of your jurisdiction.
    </Text>
    <br />
    <Text fw={700}>22. Interactions with others</Text>
    <br />
    <Text>
      Your interactions with organizations and/or individuals found on or through the Site or
      Services are solely between you and such organizations and/or individuals. You should make
      whatever investigation you feel necessary or appropriate before proceeding with any
      transaction with any of these third parties. You agree that 1stCollab shall not be responsible
      or liable for any loss or damage of any sort incurred as the result of any such dealings.
    </Text>
    <br />
    <Text>
      Should there be a dispute between participants through our Site or Services, or between users
      and any third party, you agree that 1stCollab has no obligation to become involved. In the
      event that you have a dispute with one or more other users, you release 1stCollab, its
      directors, officers, employees, agents, and successors from claims, demands, and damages of
      every kind or nature, known or unknown, suspected or unsuspected, disclosed or undisclosed,
      arising out of or in any way related to such disputes and/or our Services. You shall and
      hereby do waive California Civil Code Section 1542 or any similar law of any jurisdiction,
      which says in substance: “A general release does not extend to claims that the creditor or
      releasing party does not know or suspect to exist in his or her favor at the time of executing
      the release and that, if known by him or her, would have materially affected his or her
      settlement with the debtor or released party.”
    </Text>
    <br />
    <Text fw={700}>23. Payments and Fees</Text>
    <br />
    <Text>
      Depending on your usage, certain types of actions on the Site and Services may be free, while
      we may charge a fee for using other actions through our Site and Services. If you are using a
      free version of the Services, we will notify you before any Services you are then using begin
      carrying a fee, and if you wish to continue using such Services, you must pay all applicable
      fees for such Services. We reserve the right to begin charging fees for any or all parts of
      our Site and Services at any time.
    </Text>
    <br />
    <Text>
      a. Paid Services. Some of our Services may be subject to payments and fees, now or in the
      future (the “Paid Services”). Please note that any payment terms presented to you in the
      process of using, signing up, or paying for a Paid Service are deemed part of these Terms.
    </Text>
    <br />
    <Text>
      b. Billing. We use a third-party payment processor (the “Payment Processor”) to bill you for
      use of the Paid Services linked to your account on the Site or Services (“Billing Account”).
      This may be done either through the Site, or off-Site through email, messaging, Slack, or some
      other medium. In addition to the Terms described here, the processing of payments will be
      subject to the terms, conditions and privacy policies of the Payment Processor. We currently
      use Stripe, Inc. as our Payment Processor; you can access their Terms of Service{" "}
      <a href="https://stripe.com/us/checkout/legal">here</a>, and their Privacy Policy{" "}
      <a href="https://stripe.com/us/privacy">here</a>. We are not responsible for any error by, or
      other acts or omissions of, the Payment Processor. By choosing to use Paid Services, you agree
      to pay us, through the Payment Processor, all charges at the prices then in effect for any use
      of such Paid Services in accordance with the applicable payment terms, and you authorize us,
      through the Payment Processor, to charge your chosen payment provider (your “Payment Method”).
      You agree to make payment using that selected Payment Method. We reserve the right to correct
      any errors or mistakes that the Payment Processor makes even if it has already requested or
      received payment.
    </Text>
    <br />
    <Text>
      c. Payment Method. The terms of your payment will be based on your Payment Method and may be
      determined by agreements between you and the financial institution, credit card issuer or
      other provider of your chosen Payment Method. If we, through the Payment Processor, do not
      receive payment from you, you agree to pay all amounts due on your Billing Account upon
      demand.
    </Text>
    <br />
    <Text>
      d. Recurring Billing. Some of the Paid Services may consist of an initial period, for which
      there is a one-time charge, followed by recurring period charges as agreed to by you. By
      choosing a recurring payment plan, you acknowledge that such Services have an initial and
      recurring payment feature and you accept responsibility for all recurring charges prior to
      cancellation. WE MAY SUBMIT PERIODIC CHARGES (E.G., MONTHLY) WITHOUT FURTHER AUTHORIZATION
      FROM YOU, UNTIL YOU PROVIDE PRIOR NOTICE (RECEIPT OF WHICH IS CONFIRMED BY US) THAT YOU HAVE
      TERMINATED THIS AUTHORIZATION OR WISH TO CHANGE YOUR PAYMENT METHOD. SUCH NOTICE WILL NOT
      AFFECT CHARGES SUBMITTED BEFORE WE REASONABLY COULD ACT.
    </Text>
    <br />
    <Text>
      e. Rolling Billing. For some of the Paid Services, we charge for fees on a rolling basis, for
      which there is a one-time upfront charge, followed by recurring charges. The amount charged
      for these recurring charges might be variable, and depends on the specific services rendered
      by 1stCollab and or by Creators we work with. By using a Paid Service for which we charge on a
      rolling basis, you acknowledge that such Services have an initial and variable recurring
      payment feature. You accept responsibility for all recurring and variable charges prior to
      cancellation. You also acknowledge that there might be a lag between when you cancel the
      Service and when fees accrued due to the Service actually halt—and you accept responsibility
      for all charges that take place within that lag period. (For example, you might choose to stop
      an influencer marketing campaign, but a creator might be about to post a piece of content
      promoting your brand. In this instance, you would be responsible for the fees incurred for and
      by that creator, even though it would take place after you stopped the campaign). WE MAY
      SUBMIT CHARGES WITHOUT FURTHER AUTHORIZATION FROM YOU, UNTIL YOU PROVIDE PRIOR NOTICE (RECEIPT
      OF WHICH IS CONFIRMED BY US) THAT YOU HAVE TERMINATED THIS AUTHORIZATION OR WISH TO CHANGE
      YOUR PAYMENT METHOD. SUCH NOTICE WILL NOT AFFECT CHARGES SUBMITTED BEFORE WE REASONABLY COULD
      ACT.
    </Text>
    <br />
    <Text>
      f. Current Information Required. YOU MUST PROVIDE CURRENT, COMPLETE AND ACCURATE INFORMATION
      FOR YOUR BILLING ACCOUNT. YOU MUST PROMPTLY UPDATE ALL INFORMATION TO KEEP YOUR BILLING
      ACCOUNT CURRENT, COMPLETE AND ACCURATE (SUCH AS A CHANGE IN BILLING ADDRESS, CREDIT CARD
      NUMBER, OR CREDIT CARD EXPIRATION DATE), AND YOU MUST PROMPTLY NOTIFY US OR OUR PAYMENT
      PROCESSOR IF YOUR PAYMENT METHOD IS CANCELED (E.G., FOR LOSS OR THEFT) OR IF YOU BECOME AWARE
      OF A POTENTIAL BREACH OF SECURITY, SUCH AS THE UNAUTHORIZED DISCLOSURE OR USE OF YOUR USER
      NAME OR PASSWORD. CHANGES TO SUCH INFORMATION CAN BE MADE BY CONTACTING US AT
      hello@1stcollab.com. IF YOU FAIL TO PROVIDE ANY OF THE FOREGOING INFORMATION, YOU AGREE THAT
      WE MAY CONTINUE CHARGING YOU FOR ANY USE OF PAID SERVICES UNDER YOUR BILLING ACCOUNT UNLESS
      YOU HAVE TERMINATED YOUR PAID SERVICES AS SET FORTH ABOVE.
    </Text>
    <br />
    <Text>
      g. Change in Amount Authorized. If the amount to be charged to your Billing Account varies
      from the amount you preauthorized (other than due to the imposition or change in the amount of
      state sales taxes), you have the right to receive, and we shall provide, notice of the amount
      to be charged and the date of the charge before the scheduled date of the transaction. Any
      agreement you have with your payment provider will govern your use of your Payment Method. You
      agree that we may accumulate charges incurred and submit them as one or more aggregate charges
      during or at the end of each billing cycle.
    </Text>
    <br />
    <Text>
      h. Auto-Renewal for Paid Services. Unless you opt out of auto-renewal, any Paid Services you
      have signed up for will be automatically extended for successive renewal periods of the same
      duration as the subscription term originally selected, at the then-current non-promotional
      rate. If you terminate a Paid Service, you may use your subscription until the end of your
      then-current term, and your subscription will not be renewed after your then-current term
      expires. However, you will not be eligible for a prorated refund of any portion of the
      subscription fee paid for the then-current subscription period. If you do not want to continue
      to be charged on a recurring monthly basis, you must cancel the applicable Paid Service. Paid
      Services cannot be terminated before the end of the period for which you have already paid,
      and except as expressly provided in these terms, we will not refund any fees that you have
      already paid.
    </Text>
    <br />
    <Text>
      i. Reaffirmation of Authorization. Your non-termination or continued use of a Paid Service
      reaffirms that we are authorized to charge your Payment Method for that Paid Service. We may
      submit those charges for payment and you will be responsible for such charges. This does not
      waive our right to seek payment directly from you. Your charges may be payable in advance, in
      arrears, per usage, or as otherwise described when you initially selected to use the Paid
      Service.
    </Text>
    <br />
    <Text>
      j. Free Trials and Other Promotions. Any free trial or other promotion that provides access to
      a Paid Service must be used within the specified time of the trial. You must stop using a Paid
      Service before the end of the trial period in order to avoid being charged for that Paid
      Service. If you cancel prior to the end of the trial period and are inadvertently charged for
      a Paid Service, please contact us at hello@1stcollab.com.
    </Text>
    <br />
    <Text>
      k. Corrections. If you believe that we have billed you incorrectly, you must contact 1stCollab
      no later than 60 days after the closing date on the first billing statement in which the error
      or problem appeared, in order to receive an adjustment or credit. Inquiries should be directed
      to hello@1stcollab.com.
    </Text>
    <br />
    <Text fw={700}>24. Feedback and Reporting Misconduct</Text>
    <br />
    <Text>
      We welcome and encourage you to provide feedback, comments and suggestions (collectively
      “Feedback”) for improvements to the Site. You may submit feedback by emailing us at
      hello@1stcollab.com. You acknowledge and agree that all Feedback will be the sole and
      exclusive property of 1stCollab and you hereby irrevocably assign to 1stCollab and agree to
      irrevocably assign to 1stCollab all of your right, title, and interest in and to all Feedback,
      including without limitation all worldwide patent, copyright, trade secret, moral and other
      proprietary or intellectual property rights therein. At 1stCollab’s request and expense, you
      will execute documents and take such further acts as 1stCollab may reasonably request to
      assist 1stCollab to acquire, perfect, and maintain its intellectual property rights and other
      legal protections for the Feedback.
    </Text>
    <br />
    <Text fw={700}>25. Notice to California Residents.</Text>
    <br />
    <Text>
      If any complaints with us are not satisfactorily resolved, you can contact the Complaint
      Assistance Unit of the Division of Consumer Services of the California Department of Consumer
      Affairs in writing at 1625 North Market Boulevard, Suite N 112, Sacramento, California 95834
      or by telephone at (800) 952-5210 or (916) 445-1254.
    </Text>
    <br />
    <Text fw={700}>26. Miscellaneous</Text>
    <br />
    <Text>
      These Terms constitute the entire and exclusive understanding and agreement between 1stCollab
      and you regarding the Site and supersede and replace any and all prior oral or written
      understandings or agreements between 1stCollab and you regarding the same.
    </Text>
    <br />
    <Text>
      The failure of 1stCollab to enforce any right or provision of these Terms will not constitute
      a waiver of future enforcement of that right or provision. We may assign any or all our rights
      and obligations to others at any time. We shall not be responsible or liable for any loss,
      damage, delay, or failure caused by any cause beyond our reasonable control. If any provision
      or part of a provision of these Terms of Use is determined to be unlawful, void, or
      unemployable, that provision or part of the provision is deemed severable from these Terms of
      Use and does not affect the validity and enforceability of any remaining provisions. There is
      no joint venture, partnership, employment, or agency relationship created between you and us
      as a result of these Terms of Use or use of the Services or Site. You agree that these Terms
      of Use not be construed against us by virtue of having drafted them. You hereby waive any and
      all defenses you may have based on the electronic form of these Terms of Use and a lack of
      signing by the parties hereto to execute these Terms of Use.
    </Text>
    <br />
    <Text>
      The waiver of any such right or provision will be effective only if in writing and signed by a
      duly authorized representative of 1stCollab. Except as expressly set forth in these Terms, the
      exercise by either party of any of its remedies under these Terms will be without prejudice to
      its other remedies under these Terms or otherwise. If for any reason an arbitrator or a court
      of competent jurisdiction finds any provision of these Terms invalid or unenforceable, that
      provision will be enforced to the maximum extent permissible and the other provisions of these
      Terms will remain in full force and effect.
    </Text>
    <br />
    <br />
    {isPrintView ? (
      header("Additional Terms and Conditions for Creators", 3)
    ) : (
      <Title>Additional Terms and Conditions for Creators</Title>
    )}
    <br />
    <Text fw={700}>Last updated Janurary 8, 2024</Text>
    <br />
    <br />
    <Text fw={700}>1. Agreement to Terms</Text>
    <br />
    <Text>
      By accessing or using any Services available to Creators, you agree to these Additional Terms
      and Conditions (“AT&C“), which amend and add to the 1stCollab Terms of Service referenced
      above. Please read these AT&C, the Terms, and our Privacy Policy carefully. Our Privacy Policy
      is at https://www.1stcollab.com/privacy-policy. All capitalized words not defined here have
      the meaning given to them in the Terms. If there is a conflict between the AT&C and the Terms,
      the AT&C takes precedence. “Creator” means an individual who controls and operates a Social
      Media Account and who is captured in the 1stCollab Services. “Social Media Account” means a
      registered account with a social media, social network or content sharing platform, including
      but not limited to Facebook, Twitter, Instagram, Snap, YouTube, Vimeo and Pinterest.
    </Text>
    <br />
    <Text fw={700}>2. Modification</Text>
    <br />
    <Text>
      1stCollab reserves the right, at its sole discretion, to modify these AT&C at any time and
      without prior notice. If we modify these AT&C, we will post the modification on the Site. We
      will also update the “Last Updated Date” at the top of these AT&C. Modifications to these AT&C
      will automatically take effect upon posting. By continuing to access or use the Site or any
      Services available to Creators after we have posted a modification, you are indicating that
      you agree to be bound by the modified AT&C. If the modified AT&C are not acceptable to you,
      your only recourse is to cease accessing or using the Site and Services as a Creator.
    </Text>
    <br />
    <Text fw={700}>3. Eligibility</Text>
    <br />
    <Text>The eligibility provisions in the Terms apply.</Text>
    <br />
    <Text fw={700}>4. Registration</Text>
    <br />
    <Text>
      The Service allows registered Creators to access certain information, and provides them
      opportunities to express interest in participating in marketing campaigns proposed by other
      Users.
    </Text>
    <br />
    <Text>
      To access and use the Services as a Creator, you may be required to register an account as a
      Creator (“Creator Account”). You agree to provide accurate, current and complete information
      during registration and to update such information to keep it accurate, current and complete.
      You agree that you will safeguard your password and that you are solely responsible for any
      activities or actions under your Creator Account, whether or not you have authorized such
      activities or actions. You will immediately notify us of any unauthorized use of your Creator
      Account. You are solely responsible for your Content and Creator Account. Your “Content” is
      defined as all social media postings, comments, and other forms of media that is posted on
      your Third Party Accounts (defined below).
    </Text>
    <br />
    <Text>
      If you create a Creator Account via certain third party social networking services or sites
      (including, but not limited to, Facebook) (each such account, a “Third Party Account” or
      “TPA”), you represent that you are entitled to disclose your TPA login information to us and
      to grant us access to your TPA (including, but not limited to, for use for the purposes
      described herein), without breach by you of any of the terms and conditions that govern your
      use of the applicable TPA and without obligating us to pay any fees or making us subject to
      any usage limitations imposed by such third party service providers.
    </Text>
    <br />
    <Text>
      You acknowledge and agree that you are solely responsible for your Creator Account and all
      Creator Account information. You represent and warrant that any Creator Account Content that
      you post, and any agreements you enter into with other Users (i) will not breach any
      agreements you have entered into with any third parties and (ii) will (a) comply with all
      applicable laws, tax requirements, licenses, rules and regulations that may apply to you and
      (b) not conflict with the rights of third parties.
    </Text>
    <br />
    <Text fw={700}>5. Campaign Participation</Text>
    <br />
    <Text>
      Creators may use the Services to offer and accept opportunities to participate in any
      marketing campaigns to which they are invited. These marketing campaigns may be offered by
      1stCollab or by other Users (“Brands”). 1stCollab has sole discretion to determine eligibility
      for a campaign, as well as sole discretion to determine whose proposals are accepted to
      participate in the marketing campaign. 1stCollab’s determination on eligibility and
      participation in campaigns is final and binding. Note that if a Creator uses our Services to
      participate in a campaign, and if they sign and agree to these T&C in a contract presented to
      them at the start of said campaign, they they are subject to these T&C and AT&C, even if they
      do not register for an account.
    </Text>
    <br />
    <Text>
      Creators retained to participate in a marketing campaign may be compensated in a number of
      ways: by monetary payment; through receipt of goods or services; or a combination of both.
      Some marketing campaigns will provide compensation based on actions taken by end users (such
      as but not limited to views, engagement, shares, re-posts). The compensation for each
      marketing campaign will be communicated in the marketing campaign description.
    </Text>
    <br />
    <Text>
      When two Users enter into an agreement with each other through the Services, we will share
      information with each User as necessary or requested, such as but not limited to (i) the first
      and last name, Social Media Account handles, and contact information, (ii) links to the other
      User’s profile or other information expressly made available by that User, and (iii) details
      of the Users and campaign (including campaign requirements). Users may enter into agreements
      with each other through the Services by following the prompts to establish all agreed terms
      and mutual acceptance of those terms.
    </Text>
    <br />
    <Text>
      When an agreement between Users is confirmed through the Services, we will communicate with
      each User confirming such agreement and facilitating other requested communications.
      Acceptance of these terms is your consent to receive these communications.
    </Text>
    <br />
    <Text fw={700}>6. Account Profiles</Text>
    <br />
    <Text>
      The Services includes creation of a profile for Users that may become Creators, based on that
      Creator’s publicly available social network posts, followers and activity. Other Users will be
      able to see your Profile and Users seeking to connect with Creators will be able to search
      your profile and will use the information to invite you to make a proposal on offered work or
      otherwise communicate with you. You understand and agree that the placement or ranking of your
      Profile in search results may depend on a variety of factors, including, but not limited to,
      Brand or Creator Preferences, ratings and/or offered rates.
    </Text>
    <br />
    <Text fw={700}>7. Use of Your Content; Responsibility for Your Content</Text>
    <br />
    <Text>
      When you as a Creator post Content on the Services, or post Content on your Social Media
      Accounts pursuant to an agreement with another User, you represent and warrant as follows:
    </Text>
    <br />
    <Text>
      &#x2022; (a) that you have the right, power, and authority to post that Content and grant the
      licenses specified below;
    </Text>
    <br />
    <Text>
      &#x2022; (b) that by posting or providing such Content you will not violate third-party rights
      of any kind, including, without limitation, any Intellectual Property Rights, rights of
      publicity, and privacy rights;
    </Text>
    <br />
    <Text>
      &#x2022; (c) you are the owner of all the copyright rights to your original Content and that
      we may exercise the rights to your Content granted under the AT&C without any liability or
      obligation for any payment beyond the compensation agreed to between you and the User that
      commissioned your Content;
    </Text>
    <br />
    <Text>
      &#x2022; (d) As to any Content you created pursuant to an agreement with 1stCollab, you will
      immediately remove such Content from the Service or any Social Media Account on the request of
      1stCollab or the User that commissioned the Content, and that 1stCollab may make this request
      in its sole discretion, and that our determination is final and binding;
    </Text>
    <br />
    <Text>
      &#x2022; (e) You are solely responsible for all and Content and all postings to your Social
      Media Accounts;
    </Text>
    <br />
    <Text>
      &#x2022; (f) all of your Social Media Account postings made under or based on an agreement
      with another User or 1stCollab: (a) will be in compliance with all applicable laws, rules and
      guides (such as FTC Guides Concerning Sponsored Endorsements); (b) will have all necessary
      intellectual property and other rights for such use; (c) will not infringe the rights of any
      third party.
    </Text>
    <br />
    <Text fw={700}>8. Performance-Based Compensation</Text>
    <br />
    <Text>
      In addition to other forms of compensation, 1stCollab may offer you as the Creator the
      opportunity to receive performance-based payments. “Performance” metrics may include, but are
      not limited to, views, likes, shares, comments, and other forms of audience engagement as
      determined by 1stCollab.
    </Text>
    <br />
    <Text>
      Such compensation is contingent upon the Organic performance of your content on your Social
      Media Accounts. “Organic” performance refers to genuine, voluntary interaction with content on
      Social Media Accounts from your audience. This includes likes, shares, comments, views, and
      other forms of audience participation (as determined by 1stCollab) occurring naturally,
      without compensation for said participation, and without the influence of automated systems,
      paid promotions, or any artificial means. “Inorganic” participation refers to any engagement
      or participation on your content obtained through artificial or non-authentic means, such as
      the use of automated systems (such as “bots”), click farms, paid advertisements, sponsored
      content, or any method that artificially inflates engagement metrics.
    </Text>
    <br />
    <Text>
      Creators are expressly forbidden from using any Inorganic engagement methods to enhance their
      content&apos;s performance. This includes any manipulation aimed at artificially increasing
      content visibility or engagement metrics. Further, otherwise explicitly agreed-upon in an
      upfront agreement, Creators are forbidden from employing paid advertising (e.g., “boosting”
      content on TikTok) or other promotional strategies to increase engagement or views on their
      content that is subject to performance-based compensation. This includes any form of paid ad
      spend or sponsored content intended to artificially boost content visibility or engagement.
    </Text>
    <br />
    <Text>
      1stCollab reserves the exclusive right to investigate and determine the authenticity of
      engagement on a Creator&apos;s content. 1stCollab holds sole discretion in deciding whether
      engagement is valid or not, and may provide details of its findings at its discretion. The
      determination made by 1stCollab regarding the nature of engagement and eligibility for
      performance-based compensation is final and binding.
    </Text>
    <br />
    <Text>
      Violations of these terms, including the use of Inorganic Engagement, may result in
      withholding or forfeiture of performance-based compensation and may lead to termination of the
      Creator&apos;s participation in 1stCollab campaigns or services.
    </Text>
    <br />
    <Text fw={700}>9. No endorsement</Text>
    <br />
    <Text>
      You understand and agree that 1stCollab is not involved in the interactions between Users and
      does not refer or endorse or recommend particular Creators. You also understand and
      acknowledge that 1stCollab does not edit, modify, filter, screen, monitor, endorse or
      guarantee User Content or the content of communications between Users.
    </Text>
    <br />
    <Text>
      Users are responsible for investigating and verifying to the extent they deem necessary the
      identity and credentials of other Users contacted via the Services. By using the Services, you
      agree that any legal remedy or liability that you seek to obtain for actions or omissions of
      other Users or other third parties will be limited to a claim against the particular User(s)
      or other third party(ies) who caused you harm, and you agree not to attempt to impose
      liability on, or seek any legal remedy from, 1stCollab with respect to such actions or
      omissions.
    </Text>
    <br />
    <Text>
      1stCollab does not endorse, and assumes no responsibility for compliance with, any agreements
      between Users, or duties owed by a User to a third party, or a User’s compliance with
      applicable laws, rules and regulations. We reserve the right, at any time and without prior
      notice, to remove or disable access to any Profile or Content on the Service for any reason,
      including Profiles or Content that we, in our sole discretion, consider to be objectionable
      for any reason, in violation of these AT&C or any of our then-current policies and guidelines,
      or otherwise harmful to the Service. “Content” means text, graphics, images, music, software
      (excluding the Site), audio, video, information or other materials.
    </Text>
    <br />
    <Text fw={700}>10. Licenses</Text>
    <br />
    <Text>
      &#x2022; (a) 1stCollab License to You. Subject to your compliance with these AT&C and the
      Terms, we grant you a limited, non-exclusive, revocable, non-transferable license, to (i)
      access and utilize the Services made available to you, and (ii) access and view any User
      Content to which you are permitted access. You have no right to sublicense the license rights
      granted in this section.You represent, warrant and agree that you will not use, copy, adapt,
      modify, prepare derivative works based upon, distribute, license, sell, transfer, publicly
      display, publicly perform, transmit, broadcast or otherwise exploit the Services or Collective
      Content, except as expressly permitted in these AT&C. No licenses or rights are granted to you
      by implication or otherwise under any intellectual property rights owned or controlled by
      1stCollab or its licensors, except for the licenses and rights expressly granted in these
      AT&C. “Collective Content” means User Content and 1stCollab Content.
    </Text>
    <br />
    <Text>
      &#x2022; (b) Your License to 1stCollab. Unless otherwise specified in an alternative agreement
      (such as a partnership contract signed at the start of a Campaign), by posting Content on,
      through, or facilitated by the Services as part of an engagement with 1stCollab, you hereby
      grant to 1stCollab a worldwide, 365 day, sublicensable, non-exclusive, royalty-free license,
      with the right to create derivative works, sublicense, use, edit, view, copy, adapt, modify,
      distribute, license, sell, host, market, transfer, publicly display, publicly perform,
      transmit, stream, broadcast, access, and otherwise exploit such publicly available User
      Content on, through, or by means of the Services as currently exist or may be developed in the
      future, to use, host, store, transmit, reproduce, distribute, sublicence, modify, create
      derivative works, communicate, publish, publicly perform and publicly display your Content for
      any purpose. You further grant 1stCollab a non-exclusive, perpetual, worldwide, royalty-free,
      sublicensable license to use your name, images, and likeness as incorporated in the Content in
      connection with the licenses granted hereunder.
    </Text>
    <br />
    <Text fw={700}>11. Confidentiality; Non-Disparagement</Text>
    <br />
    <Text>
      You understand and agree that the information contained within a marketing campaign to which
      you are invited, as well as other information you may receive from the User posting such
      marketing campaign, contains confidential business, technical or financial information
      relating to that User’s business (hereinafter referred to as “Confidential Information”).You
      agree: (i) to take reasonable precautions to protect such Confidential Information from
      disclosure to third parties; and (ii) to only use such Confidential Information in the
      performance of the Services or satisfaction of any agreement you reach with the User posting
      the marketing campaign.Your obligations under the provision expire five (5) years following
      the disclosure of the Confidential Information to you, or when such Confidential Information
      (a) is or becomes generally available to the public, or (b) was in your possession or known to
      you prior to receipt, or (c) was rightfully disclosed to you without restriction by a third
      party, or (d) was independently developed by you without the use of any Confidential
      Information, or (e) is required to be disclosed by law.
    </Text>
    <br />
    <Text>
      You further understand and agree that you will not make any (i) negative, critical, hostile,
      or disparaging public or private statements concerning any User, any marketing campaign, or
      any product or service in any way associated with a marketing campaign.This agreement shall
      not in any way prevent you from disclosing information in response to a lawful subpoena or
      court order, or to your personal attorney, accountant or other professional obligated to
      maintain the confidentiality of the information disclosed.
    </Text>

    <br />
    <Text fw={700}>12. Termination</Text>
    <br />
    <Text>
      We may, in our discretion and without liability to you, with or without cause, with or without
      prior notice, and at any time terminate these AT&C, the Terms, or your access to the Service.
      You may cancel your Creator Account at any time by contacting us or following the prompts on
      the Site. Please note that if your Creator Account is canceled, we do not have an obligation
      to delete or return to you any Content you have posted to the Services, including, but not
      limited to, any reviews or feedback.
    </Text>
    <br />
    <Text fw={700}>13. Data Sources</Text>
    <br />
    <Text>
      In order to keep social platform data up-to-date, and to assist with campaign eligibility,
      1stCollab uses the API services of third parties such as YouTube, Instagram, and TikTok, and
      you may be required to connect these third-party social media accounts (“Third Party
      Accounts”) to your Creator Account.
    </Text>
    <br />
    <Text>
      You may also link your Creator Account to your TPAs, by either: (i) providing your TPA login
      information through the Services; or (ii) expressly authorizing us to access your TPA, as is
      permitted under the applicable terms and conditions of each Third Party Account. You represent
      that you are entitled to disclose your TPA login information to us and/or grant us access to
      your TPA (including, but not limited to, for use for the purposes described herein), without
      breach by you of any of the terms and conditions that govern your use of the applicable TPA
      and without obligating us to pay any fees or making us subject to any usage limitations
      imposed by such third party service providers.
    </Text>
    <br />
    <Text>
      You also authorize us to obtain certain information about you from your TPAs, including the
      content created and/or posted by you, select personal information (including but not limited
      to: name, social media account metrics, and more), and any information that you may make
      publicly available on your TPA profiles.We may include some or all of that information and
      content onto 1stCollab, and make it available to Users. By using 1stCollab and creating an
      account, you are allowing us to share the previous information, content, and personal
      information with Users, regardless of your actual interaction with each such User.
    </Text>
    <br />
    <Text>
      1stCollab utilizes YouTube API Services to access and provide information on YouTube creators,
      such as their publicly displayed name and YouTube handle, video thumbnails, and links to
      recent videos. When you authorize 1stCollab to access your YouTube account (also referred to
      as &quot;connecting your YouTube account&quot;), you also agree to be bound by the YouTube
      Terms of Service, available <a href="https://www.youtube.com/t/terms">here</a>.
    </Text>
    <br />
    <Text>
      If you authorize 1stCollab to access your Third-Party Accounts (such as YouTube), you also
      represent that you are entitled to disclose your Third-Party Account login information to
      1stCollab and/or grant 1stCollab access to your Third-Party Account without breach by you of
      any of the terms and conditions that govern your use of the applicable Third-Party Account and
      without obligating 1stCollab to pay any fees or making 1stCollab subject to any usage
      limitations imposed by such third-party service providers.
    </Text>
    <br />
    <Text fw={700}>14. FTC Compliance</Text>
    <br />
    <Text>
      You are solely responsible for your Content and your Social Media Properties, including
      Content produced as a result of a campaign from 1stCollab. Further, it is your sole and
      exclusive responsibility to ensure the compliance of all Content. For such content, 1stCollab
      requires that Influencer adhere to the Federal Trade Commission’s Guides Concerning
      Endorsements and Testimonials in Advertising (&quot;FTC Guides&quot;), available at
      https://www.ftc.gov/sites/default/files/attachments/press-releases/ftc-publishes-final-guides-governing-endorsements-testimonials/091005revisedendorsementguides.pdf.
      You shall adhere to any and all applicable consumer protection and privacy-related laws and
      regulations, including, without limitation, the U.S. Federal Trade Commission’s
      (&quot;FTC&quot;) Guides Concerning the Use of Endorsements and Testimonials in Advertising,
      all FTC endorsement requirements, and any other such similar FTC rules and regulations
      (collectively, &quot;FTC Regulations&quot;). You shall include full, fair, and effective
      disclosures of the material facts relating to your relationship with 1stCollab and any User.
    </Text>
    <br />
    <Text fw={700}>15. Content and Creator Standards</Text>
    <br />
    <Text>
      1stCollab depends on the responsibility and goodwill of each User in the community. By
      accessing or using 1stCollab, you are becoming a member of that community. As such, you agree
      that all social media postings and comments will always be in good taste and free of
      inappropriate language and/or content. In particular, you understand that you shall not not
      transmit or communicate any images, content, or text that: (a) contains material that is
      unlawful, in violation of or contrary to the laws and regulations of your local, state,
      federal, and/or international jurisdictions; (b) is obscene, indecent, explicit, abusive,
      offensive, defamatory, harassing, tortious, violent, hateful, slanderous, libelous,
      inflammatory, inappropriate or otherwise objectionable; (c) contains sexually explicit or
      pornographic material; (d) promotes violence; (e) contains material that is discriminatory or
      promotes bigotry based on race, sex or gender, religion, nationality, disability, gender
      identity, sexual orientation or age; (f) infringes any patent, trademark, trade secret,
      copyright or other intellectual property or other rights of any other person or entity; (g) is
      likely to deceive any person; (h) contains information known by Creator to be false,
      inaccurate, or misleading; (i) promotes any illegal activity, or advocate, promote or assist
      any unlawful act; (j) violates the legal rights (including the rights of publicity and
      privacy) of others; (k) contain any material that could give rise to any civil or criminal
      liability under applicable laws or regulations or that otherwise may be in conflict with these
      Terms of Use and our Privacy Policy; (l) causes annoyance, inconvenience or needless anxiety
      or be likely to upset, embarrass, alarm or annoy any other person; (m) impersonates any
      person, or misrepresent your identity or affiliation with any person or organization; (n)
      gives the impression that they emanate from or are endorsed by us or any other person or
      entity, if this is not the case; (o) disparages 1stCollab or any other person or party; and/or
      (p) contains material not consistent with the image and values of 1stCollab or otherwise
      associates 1stCollab or any of 1stCollab’s products or services with any inappropriate or
      controversial content that would reflect poorly upon 1stCollab, 1stCollab’s products or
      services or any products or services on 1stCollab’s sites or channels.
    </Text>
    <br />
    <Text fw={700}>16. Morals</Text>
    <br />
    <Text>
      If you participate in a marketing campaign offered by another User, you certify that you (i)
      have not committed and will not commit any morally repugnant act which may bring Creator, the
      Brand, or Company, in Company’s sole discretion, by association, into public disrepute,
      contempt, scandal or ridicule, or which may insult or offend the public or any protected group
      or class thereof including, without limitation, the general community to which the Company
      and/or Brand’s products or services are directed, and as such, may reflect unfavorably upon
      the reputation of the Company, Brand, or products or services through Company’s or Brand’s
      association with Creator, irrespective of whether such act is related to Creator’s services
      and of the length of time that has elapsed since such act was committed; and (ii) has not and
      will not during the Term and thereafter, make public damaging statements regarding Company or
      Brand, Company’s or Brand’s products, or Creator’s association with Company or Brand in a
      manner that, may discredit Company or Brand, promote products of a competitor, or injure the
      success of the Company or Brand, or any of Company’s or Brand’s products or services.
    </Text>
    <br />
    <Text fw={700}>17. Taxes</Text>
    <br />
    <Text>
      The fees payable to Creator are inclusive of, and Creator will be solely responsible for, any
      and all tax obligations or other charges due to all taxing authorities arising from or in
      connection with amounts paid to Influencer hereunder (collectively, “Taxes”) with respect to
      any earnings or payments made hereunder, whether or not 1stCollab is legally required to
      deduct said Taxes from the payments due to Creator.
    </Text>
    <br />
    <Text>
      Creator will not be entitled to any benefits paid or made available by 1stCollab or other
      Users to their employees, including, without limitation, any vacation or illness payments, or
      to participate in any plans, arrangements or distributions made by 1stCollab pertaining to any
      bonus, stock option, profit sharing, insurance or similar benefits. Creator will indemnify and
      hold 1stCollab harmless from and against any and all Claims arising out of or relating to any
      employment claims made by Creator or any obligation imposed by law on 1stCollab to pay any
      withholding taxes, social security, unemployment or disability insurance or similar items in
      connection with any compensation received by Creator pursuant to this Agreement.
    </Text>
    <br />
    <Text fw={700}>18. General</Text>
    <br />
    <Text>
      The failure of 1stCollab to enforce any right or provision of these AT&C will not constitute a
      waiver of future enforcement of that right or provision. The waiver of any such right or
      provision will be effective only if in writing and signed by a duly authorized representative
      of 1stCollab. Except as expressly set forth in these AT&C, the exercise by either party of any
      of its remedies under these AT&C will be without prejudice to its other remedies under these
      AT&C or otherwise. If for any reason an arbitrator or a court of competent jurisdiction finds
      any provision of these AT&C invalid or unenforceable, that provision will be enforced to the
      maximum extent permissible and the other provisions of these AT&C will remain in full force
      and effect.
    </Text>
    <br />
  </>
);

export default TermsOfService;
