import React from "react";

import { CampaignParametersV2 } from "models/Campaign";
import BrandAndNameSelector from "campaigns/main/campaignPage/BrandAndNameSelector";

export const CampaignParameterSelector = ({
  campaignParameters,
  selectedCampaignParameterId,
  setSelectedCampaignParameterId,
  searchParams = null,
  setSearchParams = null,
}: {
  campaignParameters: CampaignParametersV2[];
  selectedCampaignParameterId: number | null;
  setSelectedCampaignParameterId: (campaignParameterId: number) => void;
  searchParams?: URLSearchParams | null;
  setSearchParams?: (searchParams: URLSearchParams) => void | null;
}) => (
  <BrandAndNameSelector
    items={campaignParameters}
    selectedItemId={selectedCampaignParameterId}
    setSelectedItemId={setSelectedCampaignParameterId}
    searchParams={searchParams}
    setSearchParams={setSearchParams}
    objType="campaignParameterId"
    objName="Campaign Parameter"
  />
);

export default CampaignParameterSelector;
