import { Text, Box, Button, Divider, Spinner, TextField, Link, Flex, Heading } from "gestalt";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "gestalt/dist/gestalt.css";

import Auth from "auth/Auth";
import { Campaign } from "models/Campaign";
import { getCampaign } from "components/campaign/CampaignUtils";
import LoadingScreen from "components/LoadingScreen";
import { useSession } from "utils/SessionContext";

enum ApplyCampaignState {
  LOADING = 1,
  SUCCESS = 2,
  ERROR = 3,
}

const getAndSetCampaign = async (
  campaignId: number,
  setCampaign: (campaign: Campaign) => void,
  setApplyState: (state: ApplyCampaignState) => void,
) => {
  try {
    const campaign = await getCampaign(campaignId);
    setCampaign(campaign);
    setApplyState(ApplyCampaignState.SUCCESS);
  } catch (ex) {
    setApplyState(ApplyCampaignState.ERROR);
  }
};

const SignupView = ({ campaign, success }: { campaign?: Campaign; success: boolean }) => (
  <Flex direction="column" alignItems="center" gap={8}>
    <Heading>&#128075; Welcome to 1stCollab!</Heading>
    <Flex direction="column" alignItems="center">
      {success ? (
        <Flex direction="column" gap={2} alignItems="center">
          <Text>Sign up below to apply to the campaign</Text>
          <Text weight="bold">{campaign.title}</Text>
        </Flex>
      ) : (
        <Flex direction="column" gap={2} alignItems="center">
          <Text size="400" weight="bold">
            No campaign found &#128542;
          </Text>
          <Text>
            You can still sign up below and we&apos;ll let you know about future campaigns!
          </Text>
        </Flex>
      )}
    </Flex>
    <Auth isLogin={false} />
  </Flex>
);

const ApplyView = ({
  applyState,
  campaign,
}: {
  applyState: ApplyCampaignState;
  campaign: Campaign;
}) => {
  switch (applyState) {
    case ApplyCampaignState.LOADING:
      return <LoadingScreen />;
    case ApplyCampaignState.SUCCESS:
      return <SignupView campaign={campaign} success />;
    case ApplyCampaignState.ERROR:
      return <SignupView success={false} />;
    default:
      return <>uncaught state</>;
  }
};

const ApplyCampaign = () => {
  const { campaignId } = useParams();
  const [campaign, setCampaign] = useState(null);
  const [session, setSession] = useSession();
  const [applyState, setApplyState] = useState(ApplyCampaignState.LOADING);

  useEffect(() => {
    const campaignIdNum = parseInt(campaignId, 10);
    getAndSetCampaign(campaignIdNum, setCampaign, setApplyState);
    setSession({ applyingCampaignId: campaignIdNum });
  }, []);

  return <ApplyView applyState={applyState} campaign={campaign} />;
};

export default ApplyCampaign;
