import React, { useEffect, useState } from "react";

import { Anchor, Avatar, Group, Table, Text } from "@mantine/core";

import { User } from "models/User";
import { CreatorSetSourcingPlan } from "models/OpsTask";
import { OpsDailyTaskRow } from "admin/app/ops/tasks/OpsDailyTaskRow";
import { useAdminAppContext } from "admin/app/AdminAppShell";

const OpsDailyUserTasksSummaryRow = ({
  opsUser,
  taskCount,
  workload,
}: {
  opsUser: User;
  taskCount: number;
  workload: number;
}) => {
  const getInitials = (name: string) => {
    if (!name) return "";
    const names = name.split(" ");
    return names[0].charAt(0) + names[names.length - 1].charAt(0);
  };
  return (
    <Table.Tr>
      <Table.Td>
        <Group grow preventGrowOverflow={false} wrap="nowrap">
          <Avatar w={40} h={40} style={{ flex: "none" }}>
            {getInitials(opsUser?.name)}
          </Avatar>
          <Text fw={500}>{opsUser?.name || "Unassigned"}</Text>
        </Group>
      </Table.Td>
      <Table.Td>{taskCount}</Table.Td>
      <Table.Td>{workload}</Table.Td>
    </Table.Tr>
  );
};

const OpsDailyUserTasksSummary = ({
  opsUsers,
  sourcingAllocations,
}: {
  opsUsers: User[];
  sourcingAllocations: CreatorSetSourcingPlan[];
}) => {
  const { creatorSets } = useAdminAppContext();
  const [userTaskCount, setUserTaskCount] = useState<{ [key: string]: number }>({});
  const [userWorkload, setUserWorkload] = useState<{ [key: string]: number }>({});

  useEffect(() => {
    // Aggregate the number of tasks per user
    const taskCount: { [key: string]: number } = {};
    const workload: { [key: string]: number } = {};
    sourcingAllocations.forEach((allocation) => {
      const creatorSetId = allocation.creator_set_id;
      const creatorSet = creatorSets.find((cs) => cs.id === creatorSetId);
      Object.keys(allocation.allocated).forEach((email) => {
        if (taskCount[email]) {
          taskCount[email] += allocation.allocated[email];
          workload[email] += allocation.allocated[email] * (creatorSet?.sourcing_difficulty || 1);
        } else {
          taskCount[email] = allocation.allocated[email];
          workload[email] = allocation.allocated[email] * (creatorSet?.sourcing_difficulty || 1);
        }
        // Also track workload
      });
    });
    setUserTaskCount(taskCount);
    setUserWorkload(workload);
  }, [sourcingAllocations, creatorSets]);

  return (
    <Table horizontalSpacing="md" verticalSpacing="md" highlightOnHover withTableBorder>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Ops User</Table.Th>
          <Table.Th>Count</Table.Th>
          <Table.Th>Workload</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {Object.keys(userTaskCount).map((email) => {
          const user = opsUsers.find((u) => u.email === email);
          return (
            <OpsDailyUserTasksSummaryRow
              key={user.email}
              opsUser={user}
              taskCount={userTaskCount[email]}
              workload={userWorkload[email]}
            />
          );
        })}
      </Table.Tbody>
    </Table>
  );
};

export const OpsDailyUserTasks = ({
  opsUsers,
  sourcingAllocations,
}: {
  opsUsers: User[];
  sourcingAllocations: CreatorSetSourcingPlan[];
}) => {
  return <OpsDailyUserTasksSummary opsUsers={opsUsers} sourcingAllocations={sourcingAllocations} />;
};

export default OpsDailyUserTasks;
