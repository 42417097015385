import { Button, Flex, Image } from "@mantine/core";
import {
  Icon,
  IconProps,
  IconAlertCircle,
  IconCircleCheck,
  IconCircleDotted,
  IconLoader,
} from "@tabler/icons-react";
import Spacer from "components/Spacer";
import { AuthenticationState } from "onboarding/OnboardingUtils";
import React from "react";

const getStatusIcon = (
  authState: AuthenticationState,
): React.ForwardRefExoticComponent<Omit<IconProps, "ref"> & React.RefAttributes<Icon>> => {
  switch (authState) {
    case AuthenticationState.LOADING:
      return IconLoader;
    case AuthenticationState.AUTHENTICATED:
      return IconCircleCheck;
    case AuthenticationState.ERROR:
      return IconAlertCircle;
    case AuthenticationState.NOT_AUTHENTICATED:
    case AuthenticationState.IN_DEVELOPMENT:
    default:
      return IconCircleDotted;
  }
};

const getStatusIconColor = (authState: AuthenticationState): "green" | "red" | undefined => {
  switch (authState) {
    case AuthenticationState.AUTHENTICATED:
      return "green";
    case AuthenticationState.ERROR:
      return "red";
    default:
      return undefined;
  }
};

const SocialAuthButton = ({
  iconSrc,
  label,
  onClick,
  authState,
}: {
  iconSrc: any;
  label: string;
  onClick: () => void;
  authState: AuthenticationState;
}) => {
  const StatusIcon = getStatusIcon(authState);

  return (
    <Flex direction="row" align="center">
      <StatusIcon style={{ height: 20, width: 20 }} color={getStatusIconColor(authState)} />
      <Spacer width={48} />
      <Button
        variant="outline"
        color="var(--mantine-color-dark-6)"
        w="240px"
        h="40px"
        leftSection={<Image src={iconSrc} h="20px" w="20px" />}
        onClick={onClick}>
        {label}
      </Button>
    </Flex>
  );
};

export default SocialAuthButton;
