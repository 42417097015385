import React from "react";
import { Badge } from "@mantine/core";
import { ContentStatus } from "components/contracts/common/Common";
import {
  ContractDeliverableStatus,
  ContractDeliverableStatusMapping,
} from "components/contracts/models/Deliverable";

import { toShortDateString } from "utils/DateUtils";

export default function ReviewStatusBadge({
  status,
  reviewDate,
  liveContentSubmissionDate,
  deliverableStatus,
  withTimelineContext,
}: {
  status: ContentStatus;
  reviewDate?: Date;
  liveContentSubmissionDate?: Date;
  deliverableStatus?: ContractDeliverableStatus;
  withTimelineContext?: boolean;
}) {
  if (withTimelineContext) {
    if (!deliverableStatus) {
      throw new Error("Deliverable Status must be provided.");
    }

    if (
      ContractDeliverableStatusMapping[deliverableStatus] >=
      ContractDeliverableStatusMapping[ContractDeliverableStatus.LIVE_CONTENT_APPROVED]
    ) {
      return (
        <Badge color="blue" variant="light">
          Content Live
          {liveContentSubmissionDate ? ` on ${toShortDateString(liveContentSubmissionDate)}` : ""}
        </Badge>
      );
    } else if (deliverableStatus === ContractDeliverableStatus.LIVE_CONTENT_REVISIONS_REQUESTED) {
      // TODO(albert): Do we need a separate status badge for this?
      // Right now, it will still say "Approved to Go Live".
    } else if (deliverableStatus === ContractDeliverableStatus.LIVE_CONTENT_SUBMITTED) {
      return (
        <Badge color="red" variant="light">
          Verification Required
        </Badge>
      );
    }

    if (status === ContentStatus.APPROVED) {
      if (
        ContractDeliverableStatusMapping[deliverableStatus] <=
        ContractDeliverableStatusMapping[ContractDeliverableStatus.WAITING_FOR_VIDEO_DRAFT]
      ) {
        return (
          <Badge color="yellow" variant="light">
            Waiting for Video Draft
          </Badge>
        );
      }
      return (
        <Badge color="teal" variant="light">
          Approved to Go Live
        </Badge>
      );
    } else if (status === ContentStatus.REVISIONS_REQUESTED) {
      return (
        <Badge color="yellow" variant="light">
          Waiting for Revision
        </Badge>
      );
    } else if (status === ContentStatus.PENDING_REVIEW) {
      return (
        <Badge color="red" variant="light">
          Review Required
        </Badge>
      );
    }
  }

  if (status === ContentStatus.APPROVED) {
    return (
      <Badge color="teal" variant="light">
        Approved{reviewDate ? ` on ${toShortDateString(reviewDate)}` : ""}
      </Badge>
    );
  } else if (status === ContentStatus.REVISIONS_REQUESTED) {
    return (
      <Badge color="yellow" variant="light">
        Revision Requested{reviewDate ? ` on ${toShortDateString(reviewDate)}` : ""}
      </Badge>
    );
  } else if (status === ContentStatus.PENDING_REVIEW) {
    return (
      <Badge color="red" variant="light">
        Review Required
      </Badge>
    );
  }

  if (deliverableStatus === ContractDeliverableStatus.WAITING_FOR_SCRIPT) {
    return (
      <Badge color="yellow" variant="light">
        Waiting for Concept
      </Badge>
    );
  } else if (deliverableStatus === ContractDeliverableStatus.WAITING_FOR_VIDEO_DRAFT) {
    return (
      <Badge color="yellow" variant="light">
        Waiting for Video Draft
      </Badge>
    );
  }

  // This code should be unreachable.
  return null;
}
