import React, { MouseEventHandler, useEffect } from "react";

import "gestalt/dist/gestalt.css";
import { Flex, Text, Spinner, Table } from "gestalt";
import { useMediaQuery } from "react-responsive";

import { useNextButton } from "onboarding/OnboardingNextContext";
import { AuthenticationState, AuthenticationStatus } from "onboarding/OnboardingUtils";
import { SMALL_SCREEN_WIDTH } from "utils/DeviceTypeUtils";

const buttonStyles = {
  width: 250,
  border: "1px solid #000000",
  backgroundColor: "#FFFFFF",
  cursor: "pointer",
};

const imageStyles = {
  verticalAlign: "middle",
  height: 25,
  width: 25,
};

export const AuthButton = ({
  icon,
  text,
  onClick,
}: {
  icon: string;
  text: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
}) => (
  <button type="button" onClick={onClick} style={buttonStyles}>
    <Flex alignItems="center" gap={2} justifyContent="center" flex="grow">
      <img className="authButtonIcon" src={icon} alt="icon" style={imageStyles} />
      <Text weight="bold">{text}</Text>
    </Flex>
  </button>
);

const DefaultLoadingView = ({ platform }: { platform: string }) => (
  <Spinner show accessibilityLabel={`${platform} Spinner`} />
);

const DefaultErrorView = () => <div>Error. Refresh to try again.</div>;

const DefaultUncaughtView = () => <div>Uncaught State</div>;

interface AuthViewProps {
  platform: string;
  authStep: AuthenticationState;
  NotAuthView: JSX.Element;
  AuthView: JSX.Element;
  LoadingView?: JSX.Element;
  ErrorView?: JSX.Element;
  UncaughtView?: JSX.Element;
}

export const AuthButtonView = ({
  platform,
  authStep,
  NotAuthView,
  AuthView,
  LoadingView,
  ErrorView,
  UncaughtView,
}: AuthViewProps) => {
  switch (authStep) {
    case AuthenticationState.LOADING:
      return LoadingView || <DefaultLoadingView platform={platform} />;
    case AuthenticationState.NOT_AUTHENTICATED:
      return NotAuthView;
    case AuthenticationState.AUTHENTICATED:
      return AuthView;
    case AuthenticationState.ERROR:
      return ErrorView || <DefaultErrorView />;
    default:
      return UncaughtView || <DefaultUncaughtView />;
  }
};

export const AuthRowView = ({
  platform,
  authStep,
  NotAuthView,
  AuthView,
  LoadingView,
  ErrorView,
  UncaughtView,
}: AuthViewProps) => {
  const bigScreen = useMediaQuery({ query: `(min-width: ${SMALL_SCREEN_WIDTH}px)` });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [nextEnabled, setNext] = useNextButton();

  useEffect(() => {
    if (authStep === AuthenticationState.AUTHENTICATED) {
      if (setNext) setNext(true); // Some times there will be no "next" button, like in the settings menu
    }
  }, [authStep]);

  return (
    <Table.Row>
      {bigScreen && (
        <Table.Cell>
          <AuthenticationStatus authStep={authStep} />
        </Table.Cell>
      )}
      <Table.Cell>
        <AuthButtonView
          platform={platform}
          authStep={authStep}
          NotAuthView={NotAuthView}
          AuthView={AuthView}
          LoadingView={LoadingView}
          ErrorView={ErrorView}
          UncaughtView={UncaughtView}
        />
      </Table.Cell>
    </Table.Row>
  );
};
