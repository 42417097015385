import React from "react";

import { Alert, Text } from "@mantine/core";

import { IconAlertCircle } from "@tabler/icons-react";

import { toLongTimeDateString } from "utils/DateUtils";

import { contractExpired, ContractStatus } from "components/contracts/common/Common";

export default function ExpirationBanner({
  status,
  expirationDate,
}: {
  status: ContractStatus;
  expirationDate: Date;
}) {
  if (!contractExpired(status)) {
    return null;
  }

  return (
    <Alert
      color="red"
      variant="light"
      radius="md"
      my="lg"
      icon={<IconAlertCircle size="1rem" />}
      title="Partnership Offer Expired">
      This partnership offer expired on{" "}
      <Text size="sm" fw="500" span>
        {toLongTimeDateString(expirationDate)}
      </Text>
      . If you are interested in proceeding with this partnership, please contact your 1stCollab
      representative.
    </Alert>
  );
}
