import { Flex, Text, Table } from "gestalt";
import "gestalt/dist/gestalt.css";
import React from "react";

import { InstagramAudienceResponse } from "social/fb/InstagramUtils";
import { AudienceEntryResponse } from "social/utils";

const AudienceTable = ({
  audienceSegment,
  label,
}: {
  audienceSegment: AudienceEntryResponse[];
  label: string;
}) => {
  const countRows = audienceSegment.map((entry) => (
    <Table.Row key={`row-${entry.audience}`}>
      <Table.Cell key={`cell1-${entry.audience}`}>
        <Text>{entry.audience}</Text>
      </Table.Cell>
      <Table.Cell key={`cell2-${entry.audience}`}>
        <Text>{entry.value}</Text>
      </Table.Cell>
    </Table.Row>
  ));
  return (
    <Table accessibilityLabel={`Table-${label}`}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>
            <Text weight="bold">{label}</Text>
          </Table.HeaderCell>
          <Table.HeaderCell>
            <Text weight="bold">Followers</Text>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>{countRows}</Table.Body>
    </Table>
  );
};

const AudienceStats = ({ audience }: { audience: InstagramAudienceResponse }) => (
  <Flex direction="column">
    <Flex gap={12} justifyContent="center">
      <AudienceTable audienceSegment={audience.audience_city} label="City" />
      <AudienceTable audienceSegment={audience.audience_country} label="Country" />
      <AudienceTable audienceSegment={audience.audience_gender_age} label="Age and Gender" />
    </Flex>
  </Flex>
);

export default AudienceStats;
