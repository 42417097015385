import React, { useEffect, useState } from "react";

import { User } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";

import { auth } from "auth/firebaseAuthHelpers";
import { AuthenticationState, SetAuthState } from "onboarding/OnboardingUtils";
import tiktok_auth_icon from "social/tiktok/assets/tiktok_auth_button_icon.svg";
import { AuthButton, AuthRowView } from "social/AuthenticationComponents";
import { connectTikTok, getTiktokLoginStatus, logoutTikTok } from "social/tiktok/TikTokUtils";

// TODO: migrate this to util function
const openInNewTab = (url: string) => {
  window.open(url, "_blank", "noopener,noreferrer");
};

const TiktokLoginButton = ({ user, setAuth }: { user: User; setAuth: SetAuthState }) => {
  const [externalPopup, setExternalPopup] = useState(null);

  useEffect(() => {
    // Uses a timer that polls every 0.5s to tracks the state of the externalPopup that is created in connectClick
    // Once it is closed, we query the backend to check whether or not the authorization credentials
    // were saved correctly.
    if (!externalPopup) {
      return;
    }

    const timer = setInterval(() => {
      if (!externalPopup) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        timer && clearInterval(timer);
      } else if (externalPopup !== null && externalPopup.closed) {
        getTiktokLoginStatus().then((authStatus) => {
          setAuth(authStatus);
        });
        setExternalPopup(null);
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        timer && clearInterval(timer);
      }
    }, 500);
  }, [externalPopup]);

  return (
    <AuthButton
      icon={tiktok_auth_icon}
      text="Connect TikTok"
      onClick={() => connectTikTok().then((popup) => setExternalPopup(popup))}
    />
  );
};

const LoggedInView = ({ user, setAuth }: { user: User; setAuth: SetAuthState }) => {
  const logout = async () => {
    await logoutTikTok();
    setAuth(AuthenticationState.NOT_AUTHENTICATED);
  };

  return <AuthButton icon={tiktok_auth_icon} text="Disconnect TikTok" onClick={() => logout()} />;
};

const TiktokRow = () => {
  const [user, loading] = useAuthState(auth);
  const [authStep, setAuth] = useState(AuthenticationState.LOADING);

  useEffect(() => {
    if (user && authStep === AuthenticationState.LOADING) {
      // do nothing
      setAuth(AuthenticationState.NOT_AUTHENTICATED);
    }
    // TODO: check authentication state and set it appropriately. See FBLogin.tsx for example
    getTiktokLoginStatus().then((authState) => setAuth(authState));
  }, [loading]);

  return (
    <AuthRowView
      platform="TikTok"
      authStep={authStep}
      NotAuthView={<TiktokLoginButton user={user} setAuth={setAuth} />}
      AuthView={<LoggedInView user={user} setAuth={setAuth} />}
    />
  );
};

export default TiktokRow;
