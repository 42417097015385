import React, { useState } from "react";
import { User } from "firebase/auth";
import { Modal, Text, Flex, Button, Box, Link, Icon, Heading, IconButton, Spinner } from "gestalt";
import { useGoogleLogin, CodeResponse } from "@react-oauth/google";

import Spacer from "components/Spacer";
import { AuthenticationState, SetAuthState } from "onboarding/OnboardingUtils";
import { YOUTUBE_CALLBACK_URL } from "social/google/YoutubeUtils";
import { createRequestWithFirebaseToken, handleResult } from "utils/ApiUtils";

enum ModalStates {
  INSTRUCTIONS,
  LOADING,
  MISSING_PERMISSIONS,
}

const YOUTUBE_SCOPE = [
  "https://www.googleapis.com/auth/yt-analytics.readonly",
  "https://www.googleapis.com/auth/userinfo.profile",
  "https://www.googleapis.com/auth/userinfo.email",
  "https://www.googleapis.com/auth/youtube.readonly",
].join(" ");

const InstructionsBody = () => (
  <>
    <Text>
      Please check off all the boxes in the next Google authentication screen; all the permissions
      are necessary for correctly showing your analytics.
    </Text>
    <Spacer height={24} />
    <Flex gap={2} alignItems="center">
      <Icon size="24" accessibilityLabel="Your information is private" icon="lock" />
      <Text size="200" color="subtle">
        <Text size="200" inline weight="bold" color="subtle">
          Your data is secure.
        </Text>{" "}
        It&apos;s only used to verify eligibility for campaigns and generating insights for
        registered brands.
      </Text>
    </Flex>
  </>
);

const MissingPermissionsBody = () => (
  <Flex direction="column" gap={6}>
    <Text color="error">Error: Insufficient Permissions</Text>
    <Text>
      To fix this error, tap &quot;Try Again&quot; and make sure to grant 1stCollab permissions to:
      <ul>
        <li>View your YouTube Account</li>
        <li>View your YouTube Analytics</li>
      </ul>
    </Text>
  </Flex>
);

const ModalBody = ({ modalState }: { modalState: ModalStates }) => {
  switch (modalState) {
    case ModalStates.INSTRUCTIONS:
      return <InstructionsBody />;
    case ModalStates.LOADING:
      return <Spinner show accessibilityLabel="YouTube Loading Spinner" />;
    case ModalStates.MISSING_PERMISSIONS:
      return <MissingPermissionsBody />;
    default:
      return <Text>Unknown Error. Please refresh</Text>;
  }
};

const ModalHeader = ({
  modalState,
  setShowInstructions,
}: {
  modalState: ModalStates;
  setShowInstructions: (showInstructions: boolean) => void;
}) => {
  const headerContent =
    modalState === ModalStates.MISSING_PERMISSIONS ? (
      <Flex gap={2} alignItems="center">
        <Icon icon="alert" accessibilityLabel="alert" color="error" size="24" />
        <Heading size="500">Error connecting YouTube</Heading>
      </Flex>
    ) : (
      <Heading size="500">Connecting to YouTube</Heading>
    );

  return (
    <Box padding={9}>
      <Flex direction="row" justifyContent="between" alignItems="center">
        {headerContent}
        <IconButton
          accessibilityLabel="Dismiss modal"
          icon="cancel"
          iconColor="darkGray"
          onClick={() => setShowInstructions(false)}
        />
      </Flex>
    </Box>
  );
};

const getMissingPermissions = (scopes: string) => {
  const grantedScope = scopes.split(" ");
  const missingPermissions = YOUTUBE_SCOPE.split(" ").filter(
    (requiredScope) => !grantedScope.includes(requiredScope),
  );
  return missingPermissions;
};

const InstructionModal = ({
  userEmail,
  setAuth,
  setShowInstructions,
}: {
  userEmail: string | null;
  setAuth: SetAuthState;
  setShowInstructions: (showInstructions: boolean) => void;
}) => {
  const [modalState, setModalState] = useState(ModalStates.INSTRUCTIONS);

  const onSuccess = async (res: CodeResponse) => {
    const missingPermissions = getMissingPermissions(res.scope);

    if (missingPermissions.length) {
      setAuth(AuthenticationState.NOT_AUTHENTICATED);
      setModalState(ModalStates.MISSING_PERMISSIONS);
      return;
    }

    const request = await createRequestWithFirebaseToken({
      url: YOUTUBE_CALLBACK_URL,
      method: "POST",
      body: JSON.stringify({
        code: res.code,
        email: userEmail,
      }),
    });

    await handleResult(request);
    setShowInstructions(false);
    setAuth(AuthenticationState.AUTHENTICATED);
  };

  const login = useGoogleLogin({
    onSuccess,
    flow: "auth-code",
    redirect_uri: YOUTUBE_CALLBACK_URL,
    scope: YOUTUBE_SCOPE,
  });

  return (
    <Modal
      align="start"
      size="sm"
      heading={<ModalHeader modalState={modalState} setShowInstructions={setShowInstructions} />}
      accessibilityModalLabel="YouTube Authentication Instructions"
      onDismiss={() => {
        setShowInstructions(false);
      }}
      footer={
        <Flex justifyContent="end" gap={2}>
          <Button color="gray" text="Cancel" onClick={() => setShowInstructions(false)} />
          {modalState !== ModalStates.LOADING && (
            <Button
              color="blue"
              text={modalState === ModalStates.INSTRUCTIONS ? "Connect YouTube" : "Try Again"}
              onClick={() => {
                setModalState(ModalStates.LOADING);
                login();
              }}
            />
          )}
        </Flex>
      }>
      <Box direction="column" paddingX={9}>
        <ModalBody modalState={modalState} />
      </Box>
    </Modal>
  );
};

export default InstructionModal;
