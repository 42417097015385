import { Icon, IconProps, Icon123, IconLink } from "@tabler/icons-react";
import { NativeSelectWithTooltipData } from "campaigns/create/execution/NativeSelectWithTooltip";
import { ReferralLinkLevel, ReferralLinkType } from "models/Campaign";

interface ConceptReviewOption {
  label: string;
  value: string;
  description: string;
  tooltip: string;
}
export const LIVE_VERIFICATION: ConceptReviewOption = {
  label: "Live Verification",
  value: "live_verification",
  description: "1 hour per 100 deliverables",
  tooltip: "Review deliverable when live.",
};

export const CONCEPT_REVIEW: ConceptReviewOption = {
  label: "Concept Review",
  value: "concept_review",
  description: "+1-3 hours per 100 deliverables",
  tooltip: "Review a short written description of the deliverable.",
};

export const DRAFT_REVIEW: ConceptReviewOption = {
  label: "Video Draft Review",
  value: "video_draft_review",
  description: "+3-5 hours per 100 deliverables",
  tooltip: "Review a draft of the deliverable.",
};

export const CONCEPT_REVIEW_OPTIONS: ConceptReviewOption[] = [
  CONCEPT_REVIEW,
  DRAFT_REVIEW,
  LIVE_VERIFICATION,
];

interface ReferralManagementTypeInfo {
  label: string;
  value: string;
  IconName: React.ForwardRefExoticComponent<Omit<IconProps, "ref"> & React.RefAttributes<Icon>>;
}

export const REFERRAL_LINK_MANAGEMENT_TYPE_INFO: ReferralManagementTypeInfo = {
  label: "Referral Links",
  value: "referral_links",
  IconName: IconLink,
};

export const PROMO_CODE_MANAGEMENT_TYPE_INFO: ReferralManagementTypeInfo = {
  label: "Promo Codes",
  value: "promo_codes",
  IconName: Icon123,
};

export const REFERRAL_MANAGEMENT_OPTIONS: ReferralManagementTypeInfo[] = [
  REFERRAL_LINK_MANAGEMENT_TYPE_INFO,
  PROMO_CODE_MANAGEMENT_TYPE_INFO,
];

export const REFERRAL_LINK_TYPE_PARAMETERIZED: NativeSelectWithTooltipData = {
  label: "Parameterized (Recommended)",
  value: "parameterized",
  tooltip:
    "Creators get custom link that changes based off of certain variables. We automatically generate these links for creators so there’s no work on the brand side to provide links for each creator.",
};

export const REFERRAL_LINK_TYPE_FIXED: NativeSelectWithTooltipData = {
  label: "Fixed",
  value: "fixed",
  tooltip: "Every creator gets the same link.",
};

export const REFERRAL_LINK_TYPE_CUSTOM: NativeSelectWithTooltipData = {
  label: "Custom",
  value: "custom",
  tooltip:
    "Creators get a link that must be provided by the brand for each deliverable, creator, or contract.",
};

export const REFERRAL_LINK_TYPES: NativeSelectWithTooltipData[] = [
  REFERRAL_LINK_TYPE_PARAMETERIZED,
  REFERRAL_LINK_TYPE_FIXED,
  REFERRAL_LINK_TYPE_CUSTOM,
];

export const REFERRAL_LINK_TYPE_VALUE_TO_API_VALUE: { [value: string]: ReferralLinkType } = {
  [REFERRAL_LINK_TYPE_PARAMETERIZED.value]: ReferralLinkType.AUTOMATIC,
  [REFERRAL_LINK_TYPE_FIXED.value]: ReferralLinkType.AUTOMATIC, // fixed is parameterized without any parameters :shrug:
  [REFERRAL_LINK_TYPE_CUSTOM.value]: ReferralLinkType.CUSTOM,
};

export const getReferralLinkTypeFromApiValue = (
  apiValue: ReferralLinkType,
  linkTemplate: string | null,
): string => {
  if (apiValue === ReferralLinkType.CUSTOM) {
    return REFERRAL_LINK_TYPE_CUSTOM.value;
  }
  const validParams = /\{(creator_handle|platform|format|contract_id|deliverable_id)\}/g;
  const validParamsInUrl = linkTemplate?.match(validParams);

  if (!validParamsInUrl || validParamsInUrl.length === 0) {
    return REFERRAL_LINK_TYPE_FIXED.value;
  }
  return REFERRAL_LINK_TYPE_PARAMETERIZED.value;
};

export const REFFERAL_LINK_GRANULARITY_TYPE_PER_CONTRACT: NativeSelectWithTooltipData = {
  label: "Per Contract",
  value: ReferralLinkLevel.CONTRACT.toString(),
  tooltip:
    "A new link is provided for each contract for a creator. If a creator receives multiple contracts for the campaign (such as the case of a repeat deal), we provide the creator a different link for each contract.",
};

// export const REFFERAL_LINK_GRANULARITY_TYPE_PER_CREATOR: NativeSelectWithTooltipData = {
//   label: "Per Creator",
//   value: "creator",
//   tooltip: "A new link is provided for each new creator that joins the campaign.",
// };

export const REFFERAL_LINK_GRANULARITY_TYPE_PER_DELIVERABLE: NativeSelectWithTooltipData = {
  label: "Per Deliverable (Recommended)",
  value: ReferralLinkLevel.DELIVERABLE.toString(),
  tooltip:
    "A new link is provided for every deliverable. So if a contract has multiple deliverables (such as a YouTube Video and an IG Reel), a new link will need to be generated for each deliverable.",
};

export const REFERRAL_LINK_GRANULARITY_TYPES: NativeSelectWithTooltipData[] = [
  REFFERAL_LINK_GRANULARITY_TYPE_PER_DELIVERABLE,
  REFFERAL_LINK_GRANULARITY_TYPE_PER_CONTRACT,
  // REFFERAL_LINK_GRANULARITY_TYPE_PER_CREATOR,
];

export const PROMO_CODE_TYPE_PER_CONTRACT: NativeSelectWithTooltipData = {
  label: "Per Contract",
  value: "per_contract",
  tooltip: "You'll be asked to enter a promo code for each new creator in contract.",
};

export const PROMO_CODE_TYPE_FIXED: NativeSelectWithTooltipData = {
  label: "Fixed",
  value: "fixed",
  tooltip: "Every creator gets the same promo code.",
};

export const PROMO_CODE_TYPES: NativeSelectWithTooltipData[] = [
  PROMO_CODE_TYPE_PER_CONTRACT,
  PROMO_CODE_TYPE_FIXED,
];

// export const PROMO_CODE_GRANULARITY_PER_CREATOR: NativeSelectWithTooltipData = {
//   label: "Per Creator (Recommended)",
//   value: "creator",
//   tooltip: "A new promo code is provided for each new creator that joins the campaign.",
// };

// export const PROMO_CODE_GRANULARITY_PER_CONTRACT: NativeSelectWithTooltipData = {
//   label: "Per Contract",
//   value: "contract",
//   tooltip:
//     "A new promo code is provided for each contract for a creator. If a creator receives multiple contracts for the campaign (such as the case of a repeat deal), we provide the creator a different promo code for each contract.",
// };
// export const PROMO_CODE_GRANULARITY_TYPES: NativeSelectWithTooltipData[] = [
//   PROMO_CODE_GRANULARITY_PER_CREATOR,
//   PROMO_CODE_GRANULARITY_PER_CONTRACT,
// ];

interface NativeSelectBasicData {
  label: string;
  value: string;
}

export const PRODUCT_ACCESS_INFO_FROM_CREATOR_NONE = {
  label: "None",
  value: "0",
};
export const PRODUCT_ACCESS_INFO_FROM_CREATOR_SHIPPING_ADDRESS = {
  label: "Shipping Address",
  value: "1",
};

export const PRODUCT_ACCESS_INFO_FROM_CREATOR_ACCOUNT_EMAIL = {
  label: "Account Email Address",
  value: "2",
};
export const PRODUCT_ACCESS_INFO_FROM_CREATOR_ACCOUNT_USERNAME = {
  label: "Account Username",
  value: "3",
};
export const PRODUCT_ACCESS_INFO_FROM_CREATOR_OTHER = {
  label: "Other",
  value: "4",
};
export const PRODUCT_ACCESS_INFO_FROM_CREATOR: NativeSelectBasicData[] = [
  PRODUCT_ACCESS_INFO_FROM_CREATOR_NONE,
  PRODUCT_ACCESS_INFO_FROM_CREATOR_SHIPPING_ADDRESS,
  PRODUCT_ACCESS_INFO_FROM_CREATOR_ACCOUNT_EMAIL,
  PRODUCT_ACCESS_INFO_FROM_CREATOR_ACCOUNT_USERNAME,
  PRODUCT_ACCESS_INFO_FROM_CREATOR_OTHER,
];

export const PRODUCT_ACCESS_INFO_PROVIDED_FOR_CREATOR_NONE = {
  label: "None",
  value: "0",
};

export const PRODUCT_ACCESS_INFO_PROVIDED_FOR_CREATOR_ACCESS_CODE = {
  label: "Access Code",
  value: "1",
};

export const PRODUCT_ACCESS_INFO_PROVIDED_FOR_CREATOR_TRACKING_CODE = {
  label: "Tracking Code",
  value: "2",
};

export const PRODUCT_ACCESS_INFO_PROVIDED_FOR_CREATOR_OTHER = {
  label: "Other",
  value: "3",
};

export const PRODUCT_ACCESS_INFO_PROVIDED_FOR_CREATOR: NativeSelectBasicData[] = [
  PRODUCT_ACCESS_INFO_PROVIDED_FOR_CREATOR_NONE,
  PRODUCT_ACCESS_INFO_PROVIDED_FOR_CREATOR_ACCESS_CODE,
  PRODUCT_ACCESS_INFO_PROVIDED_FOR_CREATOR_TRACKING_CODE,
  PRODUCT_ACCESS_INFO_PROVIDED_FOR_CREATOR_OTHER,
];

export default null;
