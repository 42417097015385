import React, { useState } from "react";

import { notifications } from "@mantine/notifications";

import { User } from "firebase/auth";
import {
  Badge,
  Button,
  Flex,
  Modal,
  NativeSelect,
  Stack,
  Table,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { IconDeviceFloppy } from "@tabler/icons-react";

import { CreativeBriefStatus } from "models/Campaign";
import { CreativeBrief } from "models/CreativeBrief";
import { CreativeBriefChangeConfirmation } from "campaigns/main/campaignPage/creative_briefs/CreativeBriefChangeConfirmation";
import { updateBrandObjById } from "campaigns/api/updateBrandObj";
import { deleteObjById } from "campaigns/api/deleteObj";
import { CREATIVE_BRIEF_URL } from "campaigns/api/fetchCreativeBriefs";
import { notifyDelete } from "campaigns/main/campaignPage/ChangeConfirmation";

const deleteCreativeBriefAndNotify = async (
  creativeBrief: CreativeBrief,
  setHidden: (hidden: boolean) => void,
  close: () => void,
) => {
  const objType = "creative brief";
  try {
    const response = await deleteObjById(CREATIVE_BRIEF_URL, creativeBrief.id);

    // TODO(andrew): we should generalize the updateFunc to return a response with debug info.
    notifyDelete(response, objType);
    setHidden(true);
    return response;
  } catch (e) {
    // assume that <Notifications /> has been rendered in the app.
    notifications.show({
      color: "red",
      title: `Error with updating the ${objType}!`,
      message: e.message,
      autoClose: false,
    });
  } finally {
    close();
  }
  return null;
};

const DeleteCreativeBriefButton = ({
  creativeBrief,
  setHidden,
}: {
  creativeBrief: CreativeBrief;
  setHidden: (hidden: boolean) => void;
}) => {
  const [opened, { open, close }] = useDisclosure(false);
  return (
    <>
      <Modal
        size="lg"
        opened={opened}
        onClose={close}
        title={<Text fw="600">Delete Creative Brief</Text>}>
        <Stack gap={24}>
          <Text>
            Please confirm that you want to delete the creative brief: &quot;
            {creativeBrief.name || ""}&quot;.
          </Text>
          <Flex justify="center" gap={8}>
            <Button
              color="red"
              onClick={() => deleteCreativeBriefAndNotify(creativeBrief, setHidden, close)}>
              Delete
            </Button>
            <Button onClick={() => close()}>Cancel</Button>
          </Flex>
        </Stack>
      </Modal>
      {creativeBrief.ad_groups?.length === 0 ? (
        <Button color="red" onClick={() => open()}>
          Delete
        </Button>
      ) : (
        <Tooltip label="Please unlink ad groups before deleting a brief.">
          <Button
            data-disabled
            onClick={(event: { preventDefault: () => void }) => event.preventDefault()}>
            Delete
          </Button>
        </Tooltip>
      )}
    </>
  );
};

export const CreativeBriefTableRow = ({
  brandId,
  creativeBrief,
  columns = [
    "id",
    "name",
    "external_brief_url",
    "internal_brief_url",
    "status",
    "ad_groups",
    "actions",
  ],
  allowDelete = true,
  readOnly = false,
}: {
  brandId: number;
  creativeBrief: CreativeBrief;
  columns?: string[];
  allowDelete?: boolean;
  readOnly?: boolean;
}) => {
  const [opened, { open, close }] = useDisclosure(false);

  const initialObj = {
    name: creativeBrief.name,
    external_brief_url: creativeBrief.external_brief_url || "",
    internal_brief_url: creativeBrief.internal_brief_url || "",
    status:
      creativeBrief?.status != null
        ? creativeBrief?.status.toString()
        : CreativeBriefStatus.Draft.toString(),
  };

  const [initialValues, setInitialValues] = useState<{ [key: string]: any }>(initialObj);

  const [hidden, setHidden] = useState(false);

  const form = useForm({
    initialValues: initialObj,
  });

  return (
    <>
      <Modal
        size="lg"
        opened={opened}
        onClose={close}
        title={<Text fw="600">Creative Brief Info</Text>}>
        <Stack gap={24}>
          <Text>Please confirm that the following fields were changed:</Text>
          <CreativeBriefChangeConfirmation
            brandId={brandId}
            creativeBriefId={creativeBrief.id}
            formValues={form.values}
            initialValues={initialValues}
            onSubmit={updateBrandObjById}
            form={form}
            close={close}
          />
        </Stack>
      </Modal>
      <Table.Tr key={`creative-brief-table-${creativeBrief.id}`} hidden={hidden}>
        {columns.includes("id") && <Table.Td>{creativeBrief.id}</Table.Td>}
        {columns.includes("name") ? (
          <Table.Td>
            <TextInput
              placeholder="Creative Brief Name"
              /* eslint-disable react/jsx-props-no-spreading */
              {...form.getInputProps("name")}
              disabled={readOnly}
            />
          </Table.Td>
        ) : null}
        {columns.includes("internal_brief_url") ? (
          <Table.Td>
            <TextInput
              placeholder="Internal URL for editing the brief"
              {...form.getInputProps("internal_brief_url")}
              disabled={readOnly}
            />
          </Table.Td>
        ) : null}
        {columns.includes("external_brief_url") ? (
          <Table.Td>
            <TextInput
              placeholder="Published URL for sharing with creators"
              /* eslint-disable react/jsx-props-no-spreading */
              {...form.getInputProps("external_brief_url")}
              disabled={readOnly}
            />
          </Table.Td>
        ) : null}
        {columns.includes("status") ? (
          <Table.Td>
            <NativeSelect
              required
              data={Object.keys(CreativeBriefStatus)
                .filter((k) => typeof CreativeBriefStatus[k as any] === "number")
                .map((key) => ({ label: key, value: CreativeBriefStatus[key as any] }))}
              {...form.getInputProps("status")}
              disabled={readOnly}
            />
          </Table.Td>
        ) : null}
        {columns.includes("ad_groups") ? (
          <Table.Td maw={400}>
            <Flex gap={4} wrap="wrap">
              {creativeBrief.ad_groups?.length > 0 &&
                creativeBrief.ad_groups.map((adGroup) => (
                  <Tooltip
                    key={`creative-brief-adgroup=${adGroup.id}-tooltip`}
                    label={`(${adGroup.campaign_name}) ${adGroup.name}`}>
                    <Badge variant="light" key={`creative-brief-adgroup=${adGroup.id}`}>
                      {adGroup.name}
                    </Badge>
                  </Tooltip>
                ))}
            </Flex>
          </Table.Td>
        ) : null}
        {columns.includes("actions") ? (
          <Table.Td>
            <Flex justify={allowDelete ? "center" : "flex-start"} gap={8}>
              <Button type="submit" onClick={() => open()} disabled={!form.isDirty()}>
                Save
              </Button>
              {allowDelete && (
                <DeleteCreativeBriefButton creativeBrief={creativeBrief} setHidden={setHidden} />
              )}
            </Flex>
          </Table.Td>
        ) : null}
      </Table.Tr>
    </>
  );
};

export default CreativeBriefTableRow;
