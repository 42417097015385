import { ContractStatus, PaymentStatus } from "components/contracts/common/Common";

import Deliverable from "components/contracts/models/Deliverable";

import { fromISODateString } from "utils/DateUtils";
import { Payment, PaymentType } from "components/contracts/models/Payment";
import { GMVData } from "components/contracts/models/GMVData";
import { SupportedPlatform } from "models/Common";
import { RepeatContractValue } from "components/contracts/common/Common";

export interface Contract {
  contractId: string;
  campaignId: string;
  displayName: string;
  firstName: string;
  lastName: string;
  socialHandles: SocialHandle[];
  dateCreated: Date;
  lastUpdated: Date;
  creatorId: string;
  requiresReferralLink: boolean;
  missingReferralLink: boolean;
  requiresPromoCode: boolean;
  missingPromoCode: boolean;
  missingTracking: boolean;
  contractStatus: ContractStatus;
  paymentStatus: PaymentStatus;
  amount: number;
  bonusPaymentStatus: PaymentStatus;
  hasBonus: boolean;
  bonusCondition: string;
  avatarUrl: string;
  amountInMinorUnits: number;
  bonusAmountInMinorUnits: number;
  remainingAmountInMinorUnits: number;
  remainingBonusAmountInMinorUnits: number;
  readyForPayment: boolean;
  meetsBonusCondition: boolean;
  awaitingPaymentDetails: boolean;
  allDeliverablesLive: boolean;
  archived: boolean;
  numDeliverablesLive: number;
  deliverables: Deliverable[];
  payments: Payment[];
  sourcedEmail: string;
  additionalEmails: string[];
  repeatState: RepeatContractValue;
  stripeAccountUrl: string;
  closeUrl: string;
  dueDate: Date;
  waitingOnBrand: boolean;
  snoozed: boolean;
  numDeadlineExtensions: number;
  liveDate: Date;
  isRecurring: boolean;
  isCurrent: boolean;
}

export interface SocialHandle {
  platform: SupportedPlatform;
  handle: string;
  profileLink: string;
}

export function truncateWithEllipsis(input: string, length = 15): string {
  if (input.length <= length) return input;
  return `${input.substring(0, length)}...`;
}

export function formatAmount(amountInMinorUnits: number) {
  return (amountInMinorUnits / 100).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
}

export function getRemainingAmountInMinorUnits({
  amountInMinorUnits,
  paymentStatus,
  payments,
}: {
  amountInMinorUnits: number;
  paymentStatus: PaymentStatus;
  payments: Payment[];
}) {
  const remainingAmountInMinorUnits = payments
    .filter((p) => p.paymentType === PaymentType.BASE)
    .reduce((acc, payment) => acc - payment.amount, amountInMinorUnits);

  return paymentStatus === PaymentStatus.FULLY_PAID ? 0 : remainingAmountInMinorUnits;
}

export function getRemainingBonusAmountInMinorUnits({
  amountInMinorUnits,
  bonusPaymentStatus,
  payments,
}: {
  amountInMinorUnits: number;
  bonusPaymentStatus: PaymentStatus;
  payments: Payment[];
}) {
  const remainingAmountInMinorUnits = payments
    .filter((p) => p.paymentType === PaymentType.BONUS)
    .reduce((acc, payment) => acc - payment.amount, amountInMinorUnits);

  return bonusPaymentStatus === PaymentStatus.FULLY_PAID ? 0 : remainingAmountInMinorUnits;
}

export function buildContractFromApiResponse(contract: any) {
  const firstName = contract.signatureFirstName || "";
  const lastName = contract.signatureLastName || "";
  const { deliverables }: { deliverables: Deliverable[] } = contract;

  const socialHandles = deliverables
    .map((deliverable: Deliverable) => ({
      platform: deliverable.platform,
      handle: truncateWithEllipsis(deliverable.creatorHandle),
      profileLink: deliverable.profileLink,
    }))
    .filter(
      (value, index, self) =>
        index ===
        self.findIndex(
          (v) =>
            v.platform === value.platform &&
            v.handle === value.handle &&
            v.profileLink === value.profileLink,
        ),
    )
    .sort((a, b) => a.platform.localeCompare(b.platform));

  const displayName = truncateWithEllipsis(
    firstName.length + lastName.length > 0
      ? `${firstName.charAt(0).toUpperCase() + firstName.slice(1)} ${
          lastName.charAt(0).toUpperCase() + lastName.slice(1)
        }`
      : socialHandles[0].handle.toLowerCase(),
  );

  const payments = (contract.payments as any[]).map(
    (payment) => ({ ...payment, createdAt: fromISODateString(payment.createdAt) } as Payment),
  );
  const remainingAmountInMinorUnits = getRemainingAmountInMinorUnits({
    amountInMinorUnits: contract.amount,
    paymentStatus: contract.paymentStatus,
    payments,
  });
  const remainingBonusAmountInMinorUnits = getRemainingBonusAmountInMinorUnits({
    amountInMinorUnits: contract.bonusAmount,
    bonusPaymentStatus: contract.bonusPaymentStatus,
    payments,
  });

  return {
    contractId: contract.contractId,
    campaignId: contract.campaignId,
    creatorId: contract.creatorId,
    displayName,
    firstName,
    lastName,
    socialHandles,
    dateCreated: fromISODateString(contract.signatureDatetime || contract.dateCreated),
    lastUpdated: fromISODateString(contract.lastUpdated),
    requiresReferralLink: contract.requiresReferralLink,
    missingReferralLink: contract.missingReferralLink,
    requiresPromoCode: contract.requiresPromoCode,
    missingPromoCode: contract.missingPromoCode,
    missingTracking: contract.missingReferralLink || contract.missingPromoCode,
    contractStatus: contract.contractStatus,
    paymentStatus: contract.paymentStatus,
    bonusPaymentStatus: contract.bonusPaymentStatus,
    hasBonus: contract.bonusCondition.length > 0,
    bonusCondition: contract.bonusCondition,
    avatarUrl: contract.avatarUrl,
    amountInMinorUnits: contract.amount,
    bonusAmountInMinorUnits: contract.bonusAmount,
    remainingAmountInMinorUnits,
    remainingBonusAmountInMinorUnits,
    readyForPayment: contract.readyForPayment,
    meetsBonusCondition: contract.meetsBonusCondition,
    awaitingPaymentDetails: contract.awaitingPaymentDetails,
    deliverables: (contract.deliverables as []).map((deliverable) =>
      Deliverable.deserialize(deliverable),
    ),
    payments,
    archived: contract.archived,
    sourcedEmail: contract.sourcedEmail,
    additionalEmails: contract.additionalEmails,
    repeatState: contract.repeatState,
    stripeAccountUrl: contract.stripeAccountUrl,
    closeUrl: contract.closeUrl,
    dueDate: fromISODateString(contract.dueDate),
    waitingOnBrand: contract.waitingOnBrand,
    snoozed: contract.snoozed,
    numDeadlineExtensions: contract.numDeadlineExtensions,
    liveDate: contract.liveDate,
    isRecurring: contract.isRecurring,
    isCurrent: contract.isCurrent,
  } as Contract;
}
export function deserializeGMVData(gmvData: any): Record<string, GMVData> {
  const deserializedGMVData = Object.keys(gmvData).reduce(
    (newObj, key) => ({
      ...newObj,
      [key]: {
        ...gmvData[key],
        automaticPayments: gmvData[key].automaticPayments.map((payment: any) => ({
          ...payment,
          createdAt: fromISODateString(payment.createdAt),
        })),
        manualPayments: gmvData[key].manualPayments.map((payment: any) => ({
          ...payment,
          createdAt: fromISODateString(payment.createdAt),
        })),
        offPlatformPayments: gmvData[key].offPlatformPayments.map((payment: any) => ({
          ...payment,
          createdAt: fromISODateString(payment.createdAt),
        })),
      },
    }),
    {} as Record<string, GMVData>,
  );

  return deserializedGMVData;
}

export function getFormattedCurrency(amountInMinorUnits: number) {
  return (amountInMinorUnits / 100).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
}
