import React, { ReactNode } from "react";
import { Flex, Box } from "gestalt";
import "gestalt/dist/gestalt.css";

const FlatModule = ({
  children,
  height,
  width,
}: {
  children: ReactNode;
  height?: number | string;
  width?: number | string;
}) => (
  <Box borderStyle="sm" padding={6} rounding={4} height={height} width={width}>
    {children}
  </Box>
);

export default FlatModule;
