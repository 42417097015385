import React, { useEffect, useState } from "react";
import { Table } from "gestalt";
import "gestalt/dist/gestalt.css";
import { User } from "firebase/auth";
import moment from "moment-timezone";

import { createRequest, handleResult } from "utils/ApiUtils";
import LoadingScreen from "components/LoadingScreen";
import { API_URL } from "configs/Configs";
import { getAdminUser } from "admin/AdminLayout";

type RelatedCreatorsFrontendEntry = {
  query: string;
  date_created: Date;
  user?: string;
};

const fetchRelated = async (
  user: User,
  setRelatedLogs: (relatedLogs: RelatedCreatorsFrontendEntry[]) => void,
) => {
  const firebaseToken = await user.getIdToken();
  const request = createRequest({
    url: `${API_URL}/analytics/related_queries/`,
    firebaseToken,
  });
  const response = await handleResult(request);
  const relatedLogs: RelatedCreatorsFrontendEntry[] = response.results;
  setRelatedLogs(relatedLogs);
};

const RelatedCreatorsFrontend = () => {
  const user: User = getAdminUser();
  const [relatedLogs, setRelatedLogs] = useState(null);

  useEffect(() => {
    fetchRelated(user, setRelatedLogs);
  }, []);

  const tableBody = relatedLogs?.map((logEntry: RelatedCreatorsFrontendEntry, index: number) => (
    // eslint-disable-next-line react/no-array-index-key
    <Table.Row key={`row-${index}`}>
      <Table.Cell>{logEntry.query}</Table.Cell>
      <Table.Cell>{logEntry.user}</Table.Cell>
      <Table.Cell>
        {moment(logEntry.date_created).tz("America/Los_Angeles").format("YYYY-MM-DD hh:mm A")}
      </Table.Cell>
    </Table.Row>
  ));

  return relatedLogs ? (
    <Table accessibilityLabel="Related Creators Query Entries">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Query</Table.HeaderCell>
          <Table.HeaderCell>User</Table.HeaderCell>
          <Table.HeaderCell>Time</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>{tableBody}</Table.Body>
    </Table>
  ) : (
    <LoadingScreen />
  );
};

export default RelatedCreatorsFrontend;
