import React from "react";
import { User } from "firebase/auth";

import { Anchor, Divider, Group, Table, Text } from "@mantine/core";

import { BudgetType, Campaign, CampaignStatus } from "models/Campaign";

export default function CampaignRow({ user, campaign }: { user: User; campaign: Campaign }) {
  const hasDashboard = campaign.dashboard_csv_id != null;
  const csvDashboardText = hasDashboard ? "CSV dashboard" : "No CSV dashboard";

  return (
    <Table.Tr key={campaign.id}>
      <Table.Td>
        <Anchor href={`/admin/brands?brandId=${campaign.brand.id}`} target="_blank">
          {campaign.brand.brand_name}
        </Anchor>
      </Table.Td>
      <Table.Td>
        <Text>{campaign.title}</Text>
      </Table.Td>
      <Table.Td>
        <Text>{campaign.id}</Text>
      </Table.Td>
      <Table.Td>
        <Text>{campaign.date_created.toDateString()}</Text>
      </Table.Td>
      <Table.Td>
        <Text>{CampaignStatus[campaign.status]}</Text>
      </Table.Td>
      <Table.Td>
        <Text>{campaign.budget}</Text>
      </Table.Td>
      <Table.Td>
        <Text>{BudgetType[campaign.budget_type]}</Text>
      </Table.Td>
      <Table.Td>
        <Group>
          <Anchor href={`/campaigns/${campaign.hash_id}/`} target="_blank">
            Campaign Dashboard
          </Anchor>
        </Group>
      </Table.Td>
      <Table.Td>
        <Anchor
          href={`/admin/dashboard?navItem=content_review&campaignId=${campaign.id}`}
          target="_blank">
          Admin Content Review
        </Anchor>
      </Table.Td>
      <Table.Td>
        <Anchor href={`/internal-review?brandId=${campaign.brand?.id}`} target="_blank">
          Internal Review Page
        </Anchor>
      </Table.Td>
      <Table.Td>
        <Anchor
          href={`/admin/brands?brandId=${campaign.brand.id}&navItem=campaigns&campaignId=${campaign.id}`}
          target="_blank">
          Campaign Settings
        </Anchor>
      </Table.Td>
      <Table.Td>
        <Anchor
          href={`https://docs.google.com/spreadsheets/d/${campaign.dashboard_csv_id}`}
          target="_blank">
          {csvDashboardText}
        </Anchor>
      </Table.Td>
    </Table.Tr>
  );
}
