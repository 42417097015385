import React from "react";

import {
  Anchor,
  Badge,
  Button,
  Card,
  CopyButton,
  Flex,
  Group,
  Paper,
  Text,
  ThemeIcon,
  Title,
  Skeleton,
  Stack,
} from "@mantine/core";

import { IconCircleX, IconCopy, IconCheck, IconLink, IconTicket } from "@tabler/icons-react";

import { SupportedPlatform, SUPPORTED_PLATFORMS_TO_DISPLAY_NAMES } from "models/Common";

import { ContractDeliverableStatus } from "components/contracts/models/Deliverable";

import PlatformLogo from "components/contracts/common/PlatformLogo";

function LinkStatusBadge({
  requiresReferralLink,
  requiresPromoCode,
  missingReferralLink,
  missingPromoCode,
}: {
  requiresReferralLink: boolean;
  requiresPromoCode: boolean;
  missingReferralLink: boolean;
  missingPromoCode: boolean;
}) {
  if (!requiresReferralLink && !requiresPromoCode) {
    return null;
  }

  let color = "teal";
  let text = `${requiresReferralLink ? "Link" : ""}${
    requiresReferralLink && requiresPromoCode ? " & " : ""
  }${requiresPromoCode ? "Promo Code" : ""} Active`;

  if (requiresReferralLink && requiresPromoCode) {
    if (missingReferralLink && missingPromoCode) {
      color = "yellow";
      text = "Link & Promo Code Pending";
    } else if (missingPromoCode) {
      color = "yellow";
      text = "Promo Code Pending";
    } else if (missingReferralLink) {
      color = "yellow";
      text = "Link Pending";
    }
  } else if (requiresReferralLink && missingReferralLink) {
    color = "yellow";
    text = "Link Pending";
  } else if (requiresPromoCode && missingPromoCode) {
    color = "yellow";
    text = "Promo Code Pending";
  }

  return (
    <Badge variant="light" color={color}>
      {text}
    </Badge>
  );
}

function Description({
  requiresReferralLink,
  requiresPromoCode,
  missingReferralLink,
  missingPromoCode,
  brandName,
  platform,
}: {
  requiresReferralLink: boolean;
  requiresPromoCode: boolean;
  missingReferralLink: boolean;
  missingPromoCode: boolean;
  brandName: string;
  platform: SupportedPlatform;
}) {
  if (!requiresReferralLink && !requiresPromoCode) {
    return null;
  }

  let description = "";

  if (requiresReferralLink && requiresPromoCode) {
    if (missingReferralLink && missingPromoCode) {
      description = `${brandName} is preparing your custom link and promo code. They will become active shortly!`;
    } else if (missingReferralLink) {
      description = `${brandName} is preparing your custom link. It will become active shortly!`;
    } else if (missingPromoCode) {
      description = `${brandName} is preparing your custom promo code. It will become active shortly!`;
    } else {
      description = `Please use your custom referral link and promo code above when your ${SUPPORTED_PLATFORMS_TO_DISPLAY_NAMES[platform]} content goes live!`;
    }
  } else if (requiresReferralLink) {
    if (missingReferralLink) {
      description = `${brandName} is preparing your custom link. It will become active shortly!`;
    } else {
      description = `Please use your custom referral link above when your ${SUPPORTED_PLATFORMS_TO_DISPLAY_NAMES[platform]} content goes live!`;
    }
  } else if (requiresPromoCode) {
    if (missingPromoCode) {
      description = `${brandName} is preparing your custom promo code. It will become active shortly!`;
    } else {
      description = `Please use your custom promo code above when your ${SUPPORTED_PLATFORMS_TO_DISPLAY_NAMES[platform]} content goes live!`;
    }
  }

  return (
    <Flex justify="center">
      <Text fs="italic" size="xs">
        {description}
      </Text>
    </Flex>
  );
}

function ReferralLinkHeader({
  requiresReferralLink,
  requiresPromoCode,
  missingReferralLink,
  missingPromoCode,
  brandName,
  platform,
}: {
  requiresReferralLink: boolean;
  requiresPromoCode: boolean;
  missingReferralLink: boolean;
  missingPromoCode: boolean;
  brandName: string;
  platform: SupportedPlatform;
}) {
  let text = "";

  if (requiresReferralLink && requiresPromoCode) {
    text = "Partnership Kit";
  } else if (requiresReferralLink) {
    text = "Referral Link";
  } else if (requiresPromoCode) {
    text = "Promo Code";
  } else {
    return null;
  }

  return (
    <Group justify="space-between" align="flex-start">
      <Flex justify="left" align="flex-end" gap="xs">
        <PlatformLogo platform={platform} />
        <Title order={5} fw="500">
          {brandName} {text}
        </Title>
      </Flex>
      <LinkStatusBadge
        requiresReferralLink={requiresReferralLink}
        requiresPromoCode={requiresPromoCode}
        missingReferralLink={missingReferralLink}
        missingPromoCode={missingPromoCode}
      />
    </Group>
  );
}

function WrapWithTitle({
  shouldWrap,
  title,
  children,
}: {
  shouldWrap: boolean;
  title: string;
  children: React.ReactElement;
}) {
  if (!shouldWrap) {
    return children;
  }

  return (
    <Stack gap={2}>
      <Text size="xs" pl={2} fw="500">
        {title}
      </Text>
      {children}
    </Stack>
  );
}

export function ReferralLinkURL({
  requiresReferralLink,
  missingReferralLink,
  url,
  addTitle,
  disableBorder,
}: {
  requiresReferralLink: boolean;
  missingReferralLink: boolean;
  url: string;
  addTitle?: boolean;
  disableBorder?: boolean;
}) {
  if (!requiresReferralLink) {
    return null;
  }

  const emptyUrl = url === "";

  return (
    <Group justify="center">
      <WrapWithTitle shouldWrap={addTitle} title="Referral Link">
        <Paper px="xs" py={4} shadow={disableBorder ? "" : "xs"} withBorder={!disableBorder}>
          <Flex gap="xs" align="center">
            {missingReferralLink ? (
              <ThemeIcon variant="subtle" color="red">
                <IconCircleX size="1.2rem" />
              </ThemeIcon>
            ) : (
              <ThemeIcon variant="subtle" color="gray">
                <IconLink size="1.2rem" />
              </ThemeIcon>
            )}
            {missingReferralLink && !emptyUrl && <Text size="sm">{url}</Text>}
            {missingReferralLink && emptyUrl && (
              <Skeleton p="xs" radius="md" animate={false} width={300} height={20} />
            )}
            {!missingReferralLink && (
              <Anchor size="sm" href={url} target="_blank">
                {url}
              </Anchor>
            )}
            <CopyButton value={url} timeout={1000}>
              {({ copied, copy }) => (
                <Button
                  disabled={missingReferralLink}
                  w={80}
                  size="compact-xs"
                  onClick={copy}
                  variant="light"
                  color={copied ? "teal" : "blue"}
                  rightSection={copied ? <IconCheck size="1rem" /> : <IconCopy size="1rem" />}>
                  {copied ? "Copied" : "Copy"}
                </Button>
              )}
            </CopyButton>
          </Flex>
        </Paper>
      </WrapWithTitle>
    </Group>
  );
}

export function PromoCode({
  requiresPromoCode,
  promoCode,
  missingPromoCode,
  addTitle,
  disableBorder,
}: {
  requiresPromoCode: boolean;
  promoCode: string;
  missingPromoCode: boolean;
  addTitle?: boolean;
  disableBorder?: boolean;
}) {
  if (!requiresPromoCode) {
    return null;
  }

  return (
    <Group justify="center">
      <WrapWithTitle shouldWrap={addTitle} title="Promo Code">
        <Paper px="xs" py={4} shadow={disableBorder ? "" : "xs"} withBorder={!disableBorder}>
          <Flex gap="xs" align="center">
            {missingPromoCode ? (
              <ThemeIcon variant="subtle" color="red">
                <IconCircleX size="1.2rem" />
              </ThemeIcon>
            ) : (
              <ThemeIcon variant="subtle" color="gray">
                <IconTicket size="1.2rem" />
              </ThemeIcon>
            )}
            {!missingPromoCode && (
              <Text fw="500" size="sm">
                {promoCode}
              </Text>
            )}
            {missingPromoCode && (
              <Skeleton p="xs" radius="md" animate={false} width={300} height={20} />
            )}
            <CopyButton value={promoCode} timeout={1000}>
              {({ copied, copy }) => (
                <Button
                  disabled={missingPromoCode}
                  w={80}
                  size="compact-xs"
                  onClick={copy}
                  variant="light"
                  color={copied ? "teal" : "blue"}
                  rightSection={copied ? <IconCheck size="1rem" /> : <IconCopy size="1rem" />}>
                  {copied ? "Copied" : "Copy"}
                </Button>
              )}
            </CopyButton>
          </Flex>
        </Paper>
      </WrapWithTitle>
    </Group>
  );
}

export default function ReferralLinkAndPromoCode({
  deliverableStatus,
  url,
  promoCode,
  requiresReferralLink,
  requiresPromoCode,
  missingReferralLink,
  missingPromoCode,
  brandName,
  platform,
}: {
  deliverableStatus: ContractDeliverableStatus;
  url: string;
  promoCode: string;
  requiresReferralLink: boolean;
  requiresPromoCode: boolean;
  missingReferralLink: boolean;
  missingPromoCode: boolean;
  brandName: string;
  platform: SupportedPlatform;
}) {
  if (deliverableStatus === ContractDeliverableStatus.NOT_STARTED) {
    return null;
  }

  if (!requiresReferralLink && !requiresPromoCode) {
    return null;
  }

  return (
    <Flex justify="center">
      <Card style={{ width: "fit-content" }} radius="md" py="sm" px="md" withBorder>
        <Stack gap="xs">
          <ReferralLinkHeader
            requiresReferralLink={requiresReferralLink}
            requiresPromoCode={requiresPromoCode}
            missingReferralLink={missingReferralLink}
            missingPromoCode={missingPromoCode}
            brandName={brandName}
            platform={platform}
          />
          <ReferralLinkURL
            requiresReferralLink={requiresReferralLink}
            addTitle={requiresPromoCode}
            missingReferralLink={missingReferralLink}
            url={url}
          />
          <PromoCode
            requiresPromoCode={requiresPromoCode}
            addTitle={requiresReferralLink}
            missingPromoCode={missingPromoCode}
            promoCode={promoCode}
          />
          <Description
            requiresReferralLink={requiresReferralLink}
            requiresPromoCode={requiresPromoCode}
            missingReferralLink={missingReferralLink}
            missingPromoCode={missingPromoCode}
            brandName={brandName}
            platform={platform}
          />
        </Stack>
      </Card>
    </Flex>
  );
}
