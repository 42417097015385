import React, { useEffect, useState } from "react";
import { Text } from "@mantine/core";
import Spacer from "components/Spacer";

import { useAppSelector } from "reduxStore/hooks";

import TiktokConnectV2 from "social/tiktok/TikTokConnectV2";
import YoutubeConnectV2 from "social/google/YoutubeConnectV2";
import InstagramConnectV2 from "social/fb/InstagramConnectV2";
import InstagramConnect from "social/instagram/InstagramConnect";

const OnboardingCreatorSocialsStep = () => {
  const { user } = useAppSelector((state) => state.me);
  const [isDev, setIsDev] = useState(false);

  useEffect(() => {
    if (user?.email?.includes("@liu.dev")) {
      setIsDev(true);
    }
  }, [user]);

  return (
    <>
      <Text fw={500} size="lg" style={{ textAlign: "center" }}>
        Connect Your Socials
      </Text>
      <Spacer height={12} />
      <Text fw={400} size="md" style={{ textAlign: "center" }}>
        Connect your socials to streamline your analytics, gain priority for brand campaigns, and
        auto-generate your media kit.
      </Text>
      <Spacer height={48} />
      <TiktokConnectV2 />
      <Spacer height={24} />
      <YoutubeConnectV2 />
      <Spacer height={24} />
      <InstagramConnectV2 />
      {isDev && (
        <>
          <Spacer height={24} />
          <InstagramConnect />
        </>
      )}
    </>
  );
};

export default OnboardingCreatorSocialsStep;
