import React, { useEffect, useState } from "react";

import {
  ActionIcon,
  Anchor,
  Badge,
  Button,
  Center,
  Flex,
  Group,
  LoadingOverlay,
  Modal,
  Paper,
  Stack,
  Table,
  Tabs,
  Text,
  Title,
  Tooltip,
} from "@mantine/core";

import { useDisclosure } from "@mantine/hooks";

import {
  IconArchive,
  IconCalendar,
  IconClock,
  IconClockOff,
  IconExternalLink,
  IconInbox,
  IconMail,
} from "@tabler/icons-react";

import {
  archiveContract,
  extendContractDeadlines,
  fetchOverdueContentReview,
  fetchOverdueContracts,
  snoozeContract,
  unsnoozeContract,
} from "components/contracts/common/Api";

import { PaymentStatus } from "components/contracts/common/Common";

import { showFailureNotification, showSuccessNotification } from "components/common/Notifications";

import { Contract, buildContractFromApiResponse } from "components/contracts/dashboard/Utils";

import CreatorAvatar from "components/contracts/common/CreatorAvatar";
import DeliverableProgress from "components/contracts/common/DeliverableProgress";
import ContractAmount from "components/contracts/dashboard/ContractAmount";
import LinkAndPromoStatus from "components/contracts/dashboard/LinkAndPromoStatus";
import SocialHandleButtons from "components/contracts/dashboard/SocialHandleButtons";

import { fromISODateString, toMediumDateString, toShortDateString } from "utils/DateUtils";
import { SEMI_BOLD_FONT_WEIGHT } from "components/contracts/dashboard/Constants";

export interface OverdueContractsByCampaign {
  campaignId: number;
  campaignName: string;
  contracts: Contract[];
}

export interface OverdueContentReviewByCampaign {
  campaignId: number;
  campaignName: string;
  date: Date;
  contracts: Contract[];
}

function Date({ date }: { date: Date }) {
  return <Text fw={SEMI_BOLD_FONT_WEIGHT}>{toShortDateString(date)}</Text>;
}

function NextAction({ waitingOnBrand }: { waitingOnBrand: boolean }) {
  if (waitingOnBrand) {
    return (
      <Center>
        <Badge color="red" variant="light">
          Brand
        </Badge>
      </Center>
    );
  }
  return (
    <Center>
      <Badge color="teal" variant="light">
        Creator
      </Badge>
    </Center>
  );
}

function NumDeadlineExtensions({ numDeadlineExtensions }: { numDeadlineExtensions: number }) {
  let color = "gray";
  if (numDeadlineExtensions === 1) {
    color = "yellow";
  } else if (numDeadlineExtensions > 1) {
    color = "red";
  }
  return (
    <Center>
      <Badge color={color} variant="light">
        <Text fw="500" size="sm">{numDeadlineExtensions}</Text>
      </Badge>
    </Center>
  );
}

function ExtendDeadlineModal({
  contract,
  opened,
  close,
  loading,
  setLoading,
  removeContract,
}: {
  contract: Contract;
  opened: boolean;
  close: () => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  removeContract: () => void;
}) {
  const [numWeeks, setNumWeeks] = useState(0);
  const onExtendDeadline = (selectedNumWeeks: number) => {
    setNumWeeks(selectedNumWeeks);
    setLoading(true);
    extendContractDeadlines({ contractId: contract.contractId, numWeeks: selectedNumWeeks })
      .then((response) => {
        if (response.success) {
          showSuccessNotification({
            message: (
              <Text size="sm">
                Deadline for{" "}
                <Text span fw={SEMI_BOLD_FONT_WEIGHT}>
                  {contract.firstName} {contract.lastName}
                </Text>{" "}
                extended by {selectedNumWeeks} week{selectedNumWeeks > 1 ? "s" : ""}!
              </Text>
            ),
          });
          removeContract();
          close();
        } else {
          showFailureNotification({
            message: (
              <Text size="sm">
                Failed to extend deadline for{" "}
                <Text span fw={SEMI_BOLD_FONT_WEIGHT}>
                  {contract.firstName} {contract.lastName}
                </Text>
                .
              </Text>
            ),
          });
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <Modal opened={opened} onClose={close} size="md" title={<Text fw="600">Extend Deadline</Text>}>
      <Stack gap="sm">
        <Text>
          Extend the deadline for{" "}
          <Text span fw={SEMI_BOLD_FONT_WEIGHT}>
            {contract.firstName} {contract.lastName}
          </Text>
          &apos;s contract by
        </Text>
        <Flex gap="sm" justify="center" align="center">
          <Button
            size="xs"
            loading={loading && numWeeks === 1}
            disabled={loading}
            onClick={() => onExtendDeadline(1)}>
            1 Week
          </Button>
          <Button
            size="xs"
            loading={loading && numWeeks === 2}
            disabled={loading}
            onClick={() => onExtendDeadline(2)}>
            2 Weeks
          </Button>
          <Button
            size="xs"
            loading={loading && numWeeks === 3}
            disabled={loading}
            onClick={() => onExtendDeadline(3)}>
            3 Weeks
          </Button>
          <Button
            size="xs"
            loading={loading && numWeeks === 4}
            disabled={loading}
            onClick={() => onExtendDeadline(4)}>
            4 Weeks
          </Button>
        </Flex>
      </Stack>
    </Modal>
  );
}

function ArchiveModal({
  contract,
  opened,
  close,
  loading,
  setLoading,
  removeContract,
}: {
  contract: Contract;
  opened: boolean;
  close: () => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  removeContract: () => void;
}) {
  const onArchive = () => {
    setLoading(true);
    archiveContract({ contractId: contract.contractId })
      .then((response) => {
        if (response.success) {
          removeContract();
          showSuccessNotification({
            message: (
              <Text size="sm">
                Contract for{" "}
                <Text span fw={SEMI_BOLD_FONT_WEIGHT}>
                  {contract.firstName} {contract.lastName}
                </Text>{" "}
                archived successfully!
              </Text>
            ),
          });
          close();
        } else {
          showFailureNotification({
            message: (
              <Text size="sm">
                Failed to archive contract for{" "}
                <Text span fw={SEMI_BOLD_FONT_WEIGHT}>
                  {contract.firstName} {contract.lastName}
                </Text>
                .
              </Text>
            ),
          });
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <Modal opened={opened} onClose={close} size="xs" title={<Text fw="600">Confirm Archive</Text>}>
      <Stack gap="sm">
        <Text>
          Are you sure you want to archive the contract for{" "}
          <Text span fw={SEMI_BOLD_FONT_WEIGHT}>
            {contract.firstName} {contract.lastName}
          </Text>
          ?
        </Text>
        <Flex gap="sm" justify="center" align="center">
          <Button onClick={close} variant="default" color="gray">
            Cancel
          </Button>
          <Button loading={loading} onClick={onArchive}>
            Archive
          </Button>
        </Flex>
      </Stack>
    </Modal>
  );
}

function OverdueContractRow({
  contract,
  updateContract,
  removeContract,
}: {
  contract: Contract;
  updateContract: (newContract: Contract) => void;
  removeContract: (contractId: string) => void;
}) {
  const [snoozeLoading, setSnoozeLoading] = useState(false);
  const [archiveLoading, setArchiveLoading] = useState(false);
  const [extendDeadlineLoading, setExtendDeadlineLoading] = useState(false);

  // Confirmation Modal State
  const [archiveOpened, { open: openArchive, close: closeArchive }] = useDisclosure();
  const [extendDeadlineOpened, { open: openExtendDeadline, close: closeExtendDeadline }] =
    useDisclosure();

  const handleSnooze = () => {
    setSnoozeLoading(true);

    const handleSuccess = () => {
      updateContract({ ...contract, snoozed: !contract.snoozed });
      showSuccessNotification({
        message: (
          <Text size="sm">
            Contract for{" "}
            <Text span fw={SEMI_BOLD_FONT_WEIGHT}>
              {contract.firstName} {contract.lastName}
            </Text>{" "}
            {contract.snoozed ? "unsnoozed" : "snoozed"} successfully!
          </Text>
        ),
      });
      setSnoozeLoading(false);
    };

    const handleFailure = () => {
      updateContract({ ...contract, snoozed: !contract.snoozed });
      showFailureNotification({
        message: (
          <Text size="sm">
            Failed to {contract.snoozed ? "unsnooze" : "snooze"} contract for{" "}
            <Text span fw={SEMI_BOLD_FONT_WEIGHT}>
              {contract.firstName} {contract.lastName}
            </Text>
            .
          </Text>
        ),
      });
      setSnoozeLoading(false);
    };

    if (contract.snoozed) {
      unsnoozeContract({ contractId: contract.contractId }).then((response) => {
        if (response.success) {
          handleSuccess();
        } else {
          handleFailure();
        }
      });
    } else {
      snoozeContract({ contractId: contract.contractId }).then((response) => {
        if (response.success) {
          handleSuccess();
        } else {
          handleFailure();
        }
      });
    }
  };

  return (
    <>
      <ExtendDeadlineModal
        contract={contract}
        opened={extendDeadlineOpened}
        close={closeExtendDeadline}
        loading={extendDeadlineLoading}
        setLoading={setExtendDeadlineLoading}
        removeContract={() => removeContract(contract.contractId)}
      />
      <ArchiveModal
        contract={contract}
        opened={archiveOpened}
        close={closeArchive}
        loading={archiveLoading}
        setLoading={setArchiveLoading}
        removeContract={() => removeContract(contract.contractId)}
      />
      <Table.Tr>
        <Table.Td>
          <CreatorAvatar
            creatorId={contract.creatorId}
            displayName={contract.displayName}
            avatarUrl={contract.avatarUrl}
            hasLink
          />
        </Table.Td>
        <Table.Td>
          <SocialHandleButtons socialHandles={contract.socialHandles} />
        </Table.Td>
        <Table.Td style={{ textAlign: "center" }}>
          <Date date={contract.dueDate} />
        </Table.Td>
        <Table.Td style={{ textAlign: "center" }}>
          <ContractAmount amountInMinorUnits={contract.amountInMinorUnits} />
        </Table.Td>
        <Table.Td style={{ textAlign: "center" }}>
          <DeliverableProgress deliverables={contract.deliverables} />
        </Table.Td>
        <Table.Td style={{ textAlign: "center" }}>
          <LinkAndPromoStatus
            requiresReferralLink={contract.requiresReferralLink}
            missingReferralLink={contract.missingReferralLink}
            requiresPromoCode={contract.requiresPromoCode}
            missingPromoCode={contract.missingPromoCode}
          />
        </Table.Td>
        <Table.Td style={{ textAlign: "center" }}>
          <NextAction waitingOnBrand={contract.waitingOnBrand} />
        </Table.Td>
        <Table.Td style={{ textAlign: "center" }}>
          <NumDeadlineExtensions numDeadlineExtensions={contract.numDeadlineExtensions} />
        </Table.Td>
        <Table.Td>
          <Stack gap="xs" justify="left">
            <Button
              disabled={archiveLoading}
              loading={extendDeadlineLoading}
              size="compact-xs"
              variant="light"
              leftSection={<IconCalendar size="1rem" />}
              onClick={openExtendDeadline}>
              Extend Deadline
            </Button>
            <Tooltip
              disabled={contract.paymentStatus === PaymentStatus.UNPAID}
              label="Cannot archive a paid contract">
              <Button
                disabled={extendDeadlineLoading || contract.paymentStatus !== PaymentStatus.UNPAID}
                loading={archiveLoading}
                size="compact-xs"
                variant="light"
                color="red"
                leftSection={<IconArchive size="1rem" />}
                onClick={openArchive}>
                Archive
              </Button>
            </Tooltip>
          </Stack>
        </Table.Td>
        <Table.Td style={{ textAlign: "center" }}>
          <ActionIcon.Group>
            <Tooltip label={contract.snoozed ? "Unsnooze" : "Snooze for 5 days"}>
              <ActionIcon
                disabled={archiveLoading || extendDeadlineLoading}
                loading={snoozeLoading}
                variant="subtle"
                color="yellow"
                onClick={handleSnooze}>
                {contract.snoozed ? <IconClockOff size="1rem" /> : <IconClock size="1rem" />}
              </ActionIcon>
            </Tooltip>
            <Tooltip label="View in Close">
              <ActionIcon
                disabled={!contract.closeUrl}
                onClick={() => window.open(contract.closeUrl, "_blank")}
                variant="subtle"
                color="gray">
                <IconMail size="1rem" />
              </ActionIcon>
            </Tooltip>
            <Tooltip label="View Contract">
              <ActionIcon
                onClick={() =>
                  window.open(
                    `https://www.1stcollab.com/admin/contracts/${contract.contractId}`,
                    "_blank",
                  )
                }
                variant="subtle"
                color="gray">
                <IconExternalLink size="1rem" />
              </ActionIcon>
            </Tooltip>
          </ActionIcon.Group>
        </Table.Td>
      </Table.Tr>
    </>
  );
}

function OverdueContractsTable({
  campaignOverdueContracts,
  setCampaignOverdueContracts,
}: {
  campaignOverdueContracts: OverdueContractsByCampaign;
  setCampaignOverdueContracts: (newCampaignContracts: Contract[]) => void;
}) {
  const [activeTab, setActiveTab] = useState("inbox");

  const rows = campaignOverdueContracts.contracts
    .filter((contract) => (activeTab === "inbox" ? !contract.snoozed : contract.snoozed))
    .sort((a, b) => a.dueDate.getTime() - b.dueDate.getTime())
    .map((contract) => (
      <OverdueContractRow
        key={contract.contractId}
        contract={contract}
        updateContract={(newContract: Contract) => {
          setCampaignOverdueContracts(
            campaignOverdueContracts.contracts.map((existingContract) =>
              existingContract.contractId === newContract.contractId
                ? newContract
                : existingContract,
            ),
          );
        }}
        removeContract={(contractId: string) => {
          setCampaignOverdueContracts(
            campaignOverdueContracts.contracts.filter(
              (existingContract) => existingContract.contractId !== contractId,
            ),
          );
        }}
      />
    ));

  const numContracts = campaignOverdueContracts.contracts.length;
  const numSnoozedContracts = campaignOverdueContracts.contracts.filter(
    (contract) => contract.snoozed,
  ).length;
  const numActiveContracts = numContracts - numSnoozedContracts;

  return (
    <>
      <Group justify="space-between">
        <Title order={4} fw={SEMI_BOLD_FONT_WEIGHT}>
          {campaignOverdueContracts.campaignName}
        </Title>
      </Group>
      <Paper shadow="sm" radius="sm" withBorder>
        <Stack gap={0}>
          <Tabs value={activeTab} onChange={setActiveTab}>
            <Tabs.List>
              <Tabs.Tab value="inbox" leftSection={<IconInbox size="1rem" />}>
                <Flex gap="xs" align="center">
                  <Text size="sm">Inbox</Text>
                  <Badge
                    size="xs"
                    radius="xl"
                    circle={numActiveContracts < 10}
                    color={numActiveContracts > 0 ? "red" : "gray"}>
                    {numActiveContracts}
                  </Badge>
                </Flex>
              </Tabs.Tab>
              <Tabs.Tab
                value="snoozed"
                leftSection={<IconClock size="1rem" />}
                disabled={numSnoozedContracts === 0}>
                <Flex gap="xs" align="center">
                  <Text size="sm">Snoozed</Text>
                  <Badge
                    size="xs"
                    radius="xl"
                    circle={numSnoozedContracts < 10}
                    color={numSnoozedContracts > 0 ? "red" : "gray"}>
                    {numSnoozedContracts}
                  </Badge>
                </Flex>
              </Tabs.Tab>
            </Tabs.List>
          </Tabs>
          <Table.ScrollContainer minWidth={500}>
            <Table horizontalSpacing="xs" verticalSpacing="xs">
              <Table.Thead>
                <Table.Tr>
                  <Table.Th fw={SEMI_BOLD_FONT_WEIGHT}>Creator</Table.Th>
                  <Table.Th fw={SEMI_BOLD_FONT_WEIGHT}>Social Profiles</Table.Th>
                  <Table.Th fw={SEMI_BOLD_FONT_WEIGHT}>
                    <Flex justify="center">Due Date</Flex>
                  </Table.Th>
                  <Table.Th fw={SEMI_BOLD_FONT_WEIGHT}>
                    <Flex justify="center">Amount</Flex>
                  </Table.Th>
                  <Table.Th fw={SEMI_BOLD_FONT_WEIGHT}>
                    <Flex justify="center">Deliverables</Flex>
                  </Table.Th>
                  <Table.Th fw={SEMI_BOLD_FONT_WEIGHT}>
                    <Flex justify="center">Tracking</Flex>
                  </Table.Th>
                  <Table.Th fw={SEMI_BOLD_FONT_WEIGHT}>
                    <Flex justify="center">Next Action</Flex>
                  </Table.Th>
                  <Table.Th fw={SEMI_BOLD_FONT_WEIGHT}>
                    <Flex justify="center">Num Extensions</Flex>
                  </Table.Th>
                  <Table.Th />
                  <Table.Th />
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {rows.length === 0 && (
                  <Table.Tr>
                    <Table.Td colSpan={6}>
                      <Text size="sm" c="gray">
                        No contracts.
                      </Text>
                    </Table.Td>
                  </Table.Tr>
                )}
                {rows}
              </Table.Tbody>
            </Table>
          </Table.ScrollContainer>
        </Stack>
      </Paper>
    </>
  );
}

function OverdueContentRow({
  overdueContentReview,
}: {
  overdueContentReview: OverdueContentReviewByCampaign;
}) {
  const blockedGmv = overdueContentReview.contracts.reduce(
    (totalBlockedAmount, contract) => totalBlockedAmount + contract.amountInMinorUnits,
    0,
  );
  const numBlockedContracts = overdueContentReview.contracts.length;

  return (
    <Table.Tr>
      <Table.Td>
        <Group>
          <Badge size="sm" color="red" circle={numBlockedContracts < 10} radius="xl">
            {numBlockedContracts}
          </Badge>
          <Text fw={SEMI_BOLD_FONT_WEIGHT}>{overdueContentReview.campaignName}</Text>
        </Group>
      </Table.Td>
      <Table.Td>
        <Text fw="500">{toMediumDateString(overdueContentReview.date)}</Text>
      </Table.Td>
      <Table.Td>
        <ContractAmount amountInMinorUnits={blockedGmv} />
      </Table.Td>
      <Table.Td>
        <Stack gap="xs">
          {overdueContentReview.contracts.map((contract) => (
            <Anchor
              key={contract.contractId}
              href={`https://www.1stcollab.com/admin/contracts/${contract.contractId}`}
              target="_blank">
              <Text size="sm">
                {contract.firstName} {contract.lastName}
              </Text>
            </Anchor>
          ))}
        </Stack>
      </Table.Td>
      <Table.Td>
        <Button
          size="xs"
          variant="subtle"
          leftSection={<IconExternalLink size="1rem" />}
          onClick={() =>
            window.open(
              `https://www.1stcollab.com/admin/dashboard?navItem=content_review&campaignId=${overdueContentReview.campaignId}`,
              "_blank",
            )
          }>
          View Content
        </Button>
      </Table.Td>
    </Table.Tr>
  );
}

function OverdueContentTable({
  allOverdueContentReview,
}: {
  allOverdueContentReview: OverdueContentReviewByCampaign[];
}) {
  const rows = allOverdueContentReview.map((overdueContentReview) => (
    <OverdueContentRow
      key={`overdueContent-${overdueContentReview.campaignId}`}
      overdueContentReview={overdueContentReview}
    />
  ));

  if (rows.length === 0) {
    return null;
  }

  return (
    <>
      <Title order={3} fw="600">
        Overdue Brands
      </Title>
      <Paper shadow="sm" radius="sm" withBorder>
        <Table.ScrollContainer minWidth={500}>
          <Table horizontalSpacing="xs" verticalSpacing="xs">
            <Table.Thead>
              <Table.Tr>
                <Table.Th fw={SEMI_BOLD_FONT_WEIGHT}>Campaign Name</Table.Th>
                <Table.Th fw={SEMI_BOLD_FONT_WEIGHT}>Last Reviewed</Table.Th>
                <Table.Th fw={SEMI_BOLD_FONT_WEIGHT}>Blocked GMV</Table.Th>
                <Table.Th fw={SEMI_BOLD_FONT_WEIGHT}>Blocked Contracts</Table.Th>
                <Table.Th />
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>{rows}</Table.Tbody>
          </Table>
        </Table.ScrollContainer>
      </Paper>
    </>
  );
}

export default function OverdueContracts() {
  const [loadingContracts, setLoadingContracts] = useState(false);
  const [loadingContent, setLoadingContent] = useState(false);

  const [allOverdueContracts, setAllOverdueContracts] = useState<OverdueContractsByCampaign[]>([]);
  const [allOverdueContentReview, setAllOverdueContentReview] = useState<
    OverdueContentReviewByCampaign[]
  >([]);

  useEffect(() => {
    setLoadingContracts(true);
    fetchOverdueContracts()
      .then((response) => {
        const { success, overdueContractsByCampaign } = response;

        if (success) {
          const overdueContracts = overdueContractsByCampaign.map(
            (campaignOverdueContracts: any) => ({
              campaignId: campaignOverdueContracts.campaignId,
              campaignName: campaignOverdueContracts.campaignName,
              contracts: campaignOverdueContracts.contracts.map((contract: any) =>
                buildContractFromApiResponse(contract),
              ),
            }),
          );

          setAllOverdueContracts(overdueContracts);
        }
      })
      .finally(() => setLoadingContracts(false));

    setLoadingContent(true);
    fetchOverdueContentReview()
      .then((response) => {
        const { success, overdueContentReviews } = response;

        if (success) {
          const deserializedOverdueContentReview = overdueContentReviews.map(
            (campaignOverdueContentReview: any) => ({
              campaignId: campaignOverdueContentReview.campaignId,
              campaignName: campaignOverdueContentReview.campaignName,
              date: fromISODateString(campaignOverdueContentReview.date),
              contracts: campaignOverdueContentReview.contracts.map((contract: any) =>
                buildContractFromApiResponse(contract),
              ),
            }),
          );
          console.log(deserializedOverdueContentReview);
          setAllOverdueContentReview(deserializedOverdueContentReview);
        }
      })
      .finally(() => setLoadingContent(false));
  }, []);

  const overdueContractTables = allOverdueContracts.map((campaignOverdueContracts) => (
    <OverdueContractsTable
      key={campaignOverdueContracts.campaignName}
      campaignOverdueContracts={campaignOverdueContracts}
      setCampaignOverdueContracts={(newCampaignContracts: Contract[]) => {
        setAllOverdueContracts(
          allOverdueContracts.map((existingCampaignContracts) =>
            existingCampaignContracts.campaignId === campaignOverdueContracts.campaignId
              ? { ...existingCampaignContracts, contracts: newCampaignContracts }
              : existingCampaignContracts,
          ),
        );
      }}
    />
  ));

  return (
    <>
      <LoadingOverlay visible={loadingContracts || loadingContent} />
      <Stack>
        <OverdueContentTable allOverdueContentReview={allOverdueContentReview} />
        <Title order={3} fw="600">
          Overdue Creators
        </Title>
        {overdueContractTables}
      </Stack>
    </>
  );
}
