// https://blog.logrocket.com/user-authentication-firebase-react-apps/
import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  getAuth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  updateProfile,
  User as FirebaseUser,
} from "firebase/auth";
import { addDoc, collection, getDocs, getFirestore, query, where } from "firebase/firestore";

import { FIREBASE_CONFIG } from "firebaseConfig";
import { User } from "models/User";
import { getByEmail, getSelf } from "utils/UserUtils";

const app = initializeApp(FIREBASE_CONFIG);
export const auth = getAuth(app);
export const db = getFirestore(app);
const googleProvider = new GoogleAuthProvider();

// TODO: Firebase Docs isn't set up so these calls won't work
const writeFirebaseDoc = async (uid: string, name: string, authProvider: string, email: string) => {
  try {
    const q = query(collection(db, "users"), where("uid", "==", uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      await addDoc(collection(db, "users"), {
        uid,
        name,
        authProvider,
        email,
      });
    }
  } catch (error) {
    console.error(error);
  }
};

export const signInWithGoogle = async () => {
  const res = await signInWithPopup(auth, googleProvider);
};

export const logInWithEmailAndPassword = async (email: string, password: string) => {
  const res = await signInWithEmailAndPassword(auth, email, password);
};

// returns a boolean of whether a user exists in our backend and the firebase user
const checkUserExists = async (
  email: string,
  password: string,
): Promise<[boolean, FirebaseUser]> => {
  try {
    const res = await signInWithEmailAndPassword(auth, email, password);
    const { user } = res;
    const idToken = await user.getIdToken();
    const fetchedUser = await getByEmail(idToken, email);
    return [Boolean(fetchedUser), user];
  } catch (err) {
    if (err.name === "FirebaseError" && err.code === "auth/wrong-password") {
      console.error("entered wrong password");
      return [true, null];
    }
    return [true, null];
  }
};

const completeUserRegistration = async (
  user: FirebaseUser,
  name: string,
  updateUser: (newProps: Partial<User>) => void,
  onUserRegistrationComplete?: (user: User) => void,
) => {
  // const newUser = await updateCurrentUser(user, updateUser, user.providerId, name);
  await updateProfile(user, { displayName: name }); // this needs to be executed first because the registerUser Django logic grabs the displayName from firebase to complete user registration.
  const firebaseIdToken = await user.getIdToken();
  const userProfile: User = await getSelf(firebaseIdToken);
  if (onUserRegistrationComplete) {
    onUserRegistrationComplete(userProfile);
  }
  const newUser = {
    name,
    email: user.email,
    providerId: user.providerId,
    username: userProfile.username,
    key: userProfile.key,
  };
  updateUser(newUser);
};

export const registerWithEmailAndPassword = async (
  name: string,
  email: string,
  password: string,
  updateUser: (newProps: Partial<User>) => void,
  onUserRegistrationComplete?: (user: User) => void,
) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const { user } = res;
    await completeUserRegistration(user, name, updateUser, onUserRegistrationComplete);
  } catch (err) {
    if (err.name === "FirebaseError" && err.code === "auth/email-already-in-use") {
      const [userExists, user] = await checkUserExists(email, password);
      if (userExists) {
        if (user) {
          // this is basically the user logs in from the signup screen.
          return;
        }
        // if a user exists on our backend, then this is a fully registered user and we tell the user that this account exists already
        throw err;
      }
      // else we complete the registration process so that the user is stored in both firebase and in our backend.
      await completeUserRegistration(user, name, updateUser, onUserRegistrationComplete);
    }
    throw err; // handle other error types in parent functions
  }
};

export const logout = (onFinish: () => void) => {
  signOut(auth).then(() => onFinish());
};
