import React from "react";
import { Button, Text } from "@mantine/core";

const BasicNavButton = ({
  isSelected,
  text,
  onButtonClick,
  leftSection,
  rightSection,
  rootStyles,
  innerStyles,
}: {
  isSelected: boolean;
  text: string;
  onButtonClick: () => void;
  leftSection?: React.ReactNode;
  rightSection?: React.ReactNode;
  rootStyles?: React.CSSProperties;
  innerStyles?: React.CSSProperties;
}) => (
  <Button
    h={46}
    fullWidth
    justify="start"
    leftSection={leftSection}
    rightSection={rightSection}
    variant={isSelected ? "filled" : "transparent"}
    styles={{
      root: {
        color: isSelected ? undefined : "black",
        flexShrink: 0, // maintain height of button
        ...(rootStyles !== null ? rootStyles : {}),
      },
      inner: {
        ...(innerStyles !== null ? innerStyles : {}),
      },
      label: {
        flexGrow: 1, // take up all available space so right section will be on rightmost part
        whiteSpace: "normal",
        textAlign: "start",
      },
    }}
    onClick={onButtonClick}>
    <Text fw={600} lineClamp={2} size="sm">
      {text}
    </Text>
  </Button>
);

export default BasicNavButton;
