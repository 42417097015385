import { createRequestWithFirebaseToken, handleResult } from "utils/ApiUtils";

export const deleteObjById = async (endpoint: string, objId: number) => {
  const request = await createRequestWithFirebaseToken({
    url: `${endpoint}/${objId}/`,
    method: "DELETE",
  });

  const response = await handleResult(request);
  return response;
};

export default null;
