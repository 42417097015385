import React from "react";

import { Autocomplete, Burger, Flex, Group, rem } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconSearch } from "@tabler/icons-react";

import { Brand } from "models/Brand";
import BrandSelector from "campaigns/main/campaignPage/brand/BrandSelector";

export const BrandHeader = ({
  brands,
  selectedBrandId,
  setSelectedBrandId,
  searchParams = null,
  setSearchParams = null,
}: {
  brands: Brand[];
  selectedBrandId: number | null;
  setSelectedBrandId: (brandId: number) => void;
  searchParams?: URLSearchParams | null;
  setSearchParams?: (searchParams: URLSearchParams) => void | null;
}) => {
  const [opened, { toggle }] = useDisclosure(false);

  return (
    <header
      style={{
        height: "rem(56px)",
        marginBottom: "rem(120px)",
        // backgroundColor: "var(--mantine-color-body)",
        borderBottom:
          "rem(1px) solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4))",
        paddingLeft: "var(--mantine-spacing-md)",
        paddingRight: "var(--mantine-spacing-md)",
      }}>
      <Flex justify="flex-end" align="center" h="rem(56px)">
        <Group>
          <BrandSelector
            brands={brands}
            selectedBrandId={selectedBrandId}
            setSelectedBrandId={setSelectedBrandId}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
          />
        </Group>
      </Flex>
    </header>
  );
};

export default BrandHeader;
