import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { applyActionCode, checkActionCode, sendEmailVerification, User } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";

import { Button, Loader, Stack, Text } from "@mantine/core";

import { auth } from "auth/firebaseAuthHelpers";
import { useAppDispatch, useAppSelector } from "reduxStore/hooks";
import { completeUserOnboarding } from "reduxStore/meSlice";
import { confirmEmailVerification } from "onboarding/v2/api/OnboardingApi";

const sendEmail = async (user: User) => {
  await sendEmailVerification(user);
};

export const ResendEmail = ({ user }: { user: User }) => (
  <Stack gap="md" align="center">
    <Text>Having trouble verifying or need a new verification email? Click the button below.</Text>
    <Button onClick={() => sendEmail(user)}>Resend Email</Button>
  </Stack>
);

export const EmailVerificationConfirmation = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector((state) => state.me.user);
  const [loading, setLoading] = useState(true);
  const [verified, setVerified] = useState(false);
  const [verificationError, setVerificationError] = useState(null);
  const [firebaseUser, firebaseLoading] = useAuthState(auth);

  const mode = searchParams.get("mode");
  const oobCode = searchParams.get("oobCode");

  useEffect(() => {
    if (mode === "verifyEmail" && oobCode && currentUser !== null) {
      setLoading(true);
      checkActionCode(auth, oobCode)
        .then((info) => {
          applyActionCode(auth, oobCode)
            .then(() => {
              const abortController = new AbortController();
              dispatch(
                completeUserOnboarding({
                  abortController,
                  user_key: currentUser.key,
                }),
              );
              // TODO(andrew): we should put the steps below
              // in the .then for confirmEmailVerification
              confirmEmailVerification();
              setVerified(true);
              setLoading(false);
              // Redirect after waiting 3s
              setTimeout(() => {
                const isUnauthSearch = localStorage.getItem("unauth-search") === "true";
                if (isUnauthSearch) {
                  navigate("/campaigns/discover");
                } else {
                  navigate("/campaigns");
                }
              }, 3000);
            })
            .catch((error) => {
              setVerificationError(error);
            });
        })
        .catch((error) => {
          setVerificationError(error);
        })
        .finally(() => setLoading(false));
    }
  }, [mode, currentUser]);

  if (loading)
    return (
      <Stack align="center">
        <Text>Attempting to verify email. Please wait.</Text>
        <Loader />
      </Stack>
    );

  if (verified) {
    return (
      <Stack align="center">
        <Text>Email Verified! Thank you for signing up!</Text>
      </Stack>
    );
  }

  return (
    <Stack align="center">
      {verificationError && !verified ? (
        <Text color="red">Error verifying email. Please try again.</Text>
      ) : null}
      <ResendEmail user={firebaseUser} />
    </Stack>
  );
};

export default EmailVerificationConfirmation;
