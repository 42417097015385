import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useOutletContext } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { User } from "firebase/auth";

import { auth } from "auth/firebaseAuthHelpers";
import { AdminAppContext, useAdminAppContext } from "admin/app/AdminAppShell";
import { isAdminUser } from "utils/UserUtils";

export enum IsAdminState {
  LOADING = 1,
  IS_ADMIN = 2,
  IS_NOT_ADMIN = 3,
}

export const checkUser = async (
  user: User,
  setAdminState: (isAdminState: IsAdminState) => void,
) => {
  const isAdmin = await isAdminUser(user);
  if (isAdmin) {
    setAdminState(IsAdminState.IS_ADMIN);
  } else {
    setAdminState(IsAdminState.IS_NOT_ADMIN);
  }
};

const AdminLayout = () => {
  const [user, loading] = useAuthState(auth);
  const [isAdminState, setAdminState] = useState(IsAdminState.LOADING);
  const {
    userProfile,
    opsUsers,
    brands,
    campaigns,
    creatorSets,
    setCreatorSets,
    campaignParameters,
  } = useAdminAppContext();

  useEffect(() => {
    if (loading) return;
    if (user) {
      checkUser(user, setAdminState);
    } else {
      setAdminState(IsAdminState.IS_NOT_ADMIN);
    }
  }, [loading]);

  if (isAdminState === IsAdminState.LOADING) return <>Loading</>;
  if (isAdminState === IsAdminState.IS_NOT_ADMIN) return <Navigate to="/" />;
  return (
    // pass context to the outlet
    <Outlet
      context={{
        user,
        userProfile,
        opsUsers,
        brands,
        campaigns,
        creatorSets,
        setCreatorSets,
        campaignParameters,
      }}
    />
  );
};

export function getAdminUser() {
  return useOutletContext<AdminAppContext>()?.user;
}

export default AdminLayout;
