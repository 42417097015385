import { Button, Stack, TextInput, Textarea } from "@mantine/core";
import { useForm } from "@mantine/form";
import CardBase from "campaigns/create/common/CardBase";
import { Brand } from "models/Brand";
import React, { useEffect } from "react";
import { useAppDispatch } from "reduxStore/hooks";
import { updateMyBrand } from "reduxStore/meSlice";

const BrandAbout = ({ brand }: { brand: Brand }) => {
  const dispatch = useAppDispatch();
  const form = useForm({
    mode: "uncontrolled",
    initialValues: {
      name: brand.display_name,
      blurb: brand.company_blurb,
      website: brand.company_website,
    },

    validate: {
      name: (value) => (value?.trim() ? null : "Please enter your company's name"),
      blurb: (value) => (value?.trim() ? null : "Please add a blurb for your company"),
      website: (value) => {
        if (!value?.trim()) {
          return "Please enter your company's website";
        }
        try {
          const url = new URL(value?.trim());
        } catch {
          return "Please enter a valid URL";
        }
        return null;
      },
    },
  });

  useEffect(() => {
    form.setInitialValues({
      name: brand.display_name,
      blurb: brand.company_blurb,
      website: brand.company_website,
    });
    form.resetDirty();
  }, [brand]);

  return (
    <CardBase
      isActive
      nodeShownWhenActive={
        <form
          onSubmit={form.onSubmit((values) => {
            dispatch(
              updateMyBrand({
                updatedFields: {
                  ...(form.isDirty("name") && { display_name: values.name }),
                  ...(form.isDirty("blurb") && { company_blurb: values.blurb }),
                  ...(form.isDirty("website") && { company_website: values.website }),
                },
              }),
            );
          })}>
          <Stack px={0} style={{ "--stack-gap": "24px" }}>
            <TextInput
              label="Company Name"
              placeholder="1stCollab"
              withAsterisk
              key={form.key("name")}
              {...form.getInputProps("name")}
            />
            <TextInput
              label="Company Website"
              placeholder="1stcollab.com"
              withAsterisk
              key={form.key("website")}
              {...form.getInputProps("website")}
            />
            <Textarea
              withAsterisk
              minRows={6}
              autosize
              label="Company Blurb"
              description="A short, 2-4 sentence description of your brand, written in the 3rd person"
              placeholder="1stCollab is the first influencer marketing platform meant for performance oriented marketers. They’re a YC backed company that have helped many of the world’s best brands start and scale their influencer marketing programs. Learn more about them at 1stcollab.com"
              key={form.key("blurb")}
              {...form.getInputProps("blurb")}
            />
            <Button variant="filled" type="submit">
              Save
            </Button>
          </Stack>
        </form>
      }
      title="About"
      subtitle=""
    />
  );
};

export default BrandAbout;
