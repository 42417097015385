import React, { useEffect, useState } from "react";

import { Flex, Group, Progress, Stack, Text } from "@mantine/core";

import { Dropzone, FileWithPath } from "@mantine/dropzone";
import { useWindowEvent } from "@mantine/hooks";

import { IconCircleCheck, IconCircleX, IconUpload, IconVideo, IconX } from "@tabler/icons-react";

import {
  showFailureNotification,
  showSuccessNotification,
} from "components/common/Notifications";

import { ContractDeliverableStatus } from "components/contracts/models/Deliverable";

import MetadataFields from "components/contracts/deliverables/MetadataFields";
import ReviewVideoSubmission from "components/contracts/deliverables/ReviewVideoSubmission";
import VideoUploader from "components/contracts/deliverables/VideoUploader";

import { SupportedFormat } from "models/Common";

import { timestampToSeconds } from "utils/DateUtils";

const DEFAULT_MAX_VIDEO_FILE_SIZE = 1 * 1024 ** 3; // 1 GB
const LONG_FORM_MAX_VIDEO_FILE_SIZE = 3 * 1024 ** 5; // 3 GB

const DEFAULT_MAX_VIDEO_FILE_SIZE_LABEL = "1 GB";
const LONG_FORM_MAX_VIDEO_FILE_SIZE_LABEL = "5 GB";

export default function VideoDropzone({
  contractId,
  deliverableId,
  format,
  title,
  setTitle,
  caption,
  setCaption,
  integrationTimestamp,
  setIntegrationTimestamp,
  metadataValid,
  setMetadataValid,
  disableMetadata,
  setDisableMetadata,
  setDeliverableStatus,
  handleRefetchUploadedVideos,
  disabled,
}: {
  contractId: string;
  deliverableId: string;
  format: SupportedFormat;
  title: string;
  setTitle: (title: string) => void;
  caption: string;
  setCaption: (caption: string) => void;
  integrationTimestamp: string;
  setIntegrationTimestamp: (timestamp: string) => void;
  metadataValid: boolean;
  setMetadataValid: (valid: boolean) => void;
  disableMetadata: boolean;
  setDisableMetadata: (disable: boolean) => void;
  setDeliverableStatus: (status: ContractDeliverableStatus) => void;
  handleRefetchUploadedVideos: (handleFetched: () => void) => void;
  disabled?: boolean;
}) {
  const [file, setFile] = useState<FileWithPath>(null);
  const [fileName, setFileName] = useState<string>(null);
  const [localVideoUrl, setLocalVideoUrl] = useState<string>(null);

  const [uploadSuccessful, setUploadSuccessful] = useState<boolean>(false);
  const [initializingUpload, setInitializingUpload] = useState<boolean>(false);
  const [failedUpload, setFailedUpload] = useState<boolean>(false);
  const [showErrors, setShowErrors] = useState<boolean>(false);

  // Progress Bar State
  const [progress, setProgress] = useState<number>(0);

  // Warn user before leaving page if video is still uploading.
  const warnUser = (event: BeforeUnloadEvent) => {
    if (initializingUpload || (progress > 0 && progress < 100)) {
      const message = "Your file is still uploading. Are you sure you want to leave?";

      // eslint-disable-next-line no-param-reassign
      event.returnValue = message;

      return message;
    }
    return undefined;
  };
  useWindowEvent("beforeunload", warnUser);

  const resetProgress = () => {
    setProgress(0);
    setDisableMetadata(false);
  };

  const setInitializeUploadState = () => {
    resetProgress();
    setInitializingUpload(true);
    setFailedUpload(false);
    setDisableMetadata(true);
  };

  const handleUploadSuccess = (location: string) => {
    handleRefetchUploadedVideos(() => {
      setInitializingUpload(false);
      setUploadSuccessful(true);
      setLocalVideoUrl(location);
      setDeliverableStatus(ContractDeliverableStatus.VIDEO_DRAFT_SUBMITTED);
      showSuccessNotification({
        message: "Your video draft has been uploaded successfully.",
        stayOpen: true,
      });
    });
  };

  const handleUploadFailure = () => {
    resetProgress();

    setFile(null);
    setFileName(null);
    setLocalVideoUrl(null);

    setInitializingUpload(false);
    setFailedUpload(true);

    showFailureNotification({
      message: "There was an error uploading your video draft. Please try again.",
      stayOpen: true,
    });
  };

  const [uploaderInstance] = useState(() =>
    VideoUploader.build({
      contractId,
      deliverableId,
      setProgress,
      handleUploadSuccess,
      handleUploadFailure,
    }),
  );

  const handleUpload = () => {
    setInitializeUploadState();
    uploaderInstance.addFile({
      name: file.name,
      title,
      caption,
      integrationTimestampInSeconds: timestampToSeconds(integrationTimestamp),
      deliverableId,
      type: file.type,
      data: file,
    });
    uploaderInstance.upload();
  };

  const handleCancelUpload = () => {
    uploaderInstance.cancel();
  };

  useEffect(
    () => () => {
      if (uploaderInstance) {
        uploaderInstance.close();
      }
    },
    [],
  );

  let maxFileSize = DEFAULT_MAX_VIDEO_FILE_SIZE;
  let maxFileSizeLabel = DEFAULT_MAX_VIDEO_FILE_SIZE_LABEL;

  if (
    [
      SupportedFormat.YOUTUBE_DEDICATED_VIDEO,
      SupportedFormat.YOUTUBE_30S_INTEGRATED_VIDEO,
      SupportedFormat.YOUTUBE_60S_INTEGRATED_VIDEO,
      SupportedFormat.YOUTUBE_90S_INTEGRATED_VIDEO,
    ].includes(format)
  ) {
    maxFileSize = LONG_FORM_MAX_VIDEO_FILE_SIZE;
    maxFileSizeLabel = LONG_FORM_MAX_VIDEO_FILE_SIZE_LABEL;
  }

  return (
    <>
      <Stack gap="xs">
        <Dropzone
          disabled={
            disabled ||
            !uploaderInstance ||
            uploadSuccessful ||
            initializingUpload ||
            (!failedUpload && progress > 0)
          }
          styles={
            (disabled ||
              !uploaderInstance ||
              uploadSuccessful ||
              initializingUpload ||
              (!failedUpload && progress > 0)) && {
              root: {
                backgroundColor: "var(--mantine-color-gray-0)",
                borderColor: "var(--mantine-color-gray-2)",
                color: "var(--mantine-color-gray-5)",
                cursor: "not-allowed",
              },
            }
          }
          onDrop={(acceptedFiles) => {
            setFileName(acceptedFiles[0].name);
            setFile(acceptedFiles[0]);
            setLocalVideoUrl(URL.createObjectURL(acceptedFiles[0]));
          }}
          onReject={(rejectedFiles) => {
            let errorMessage;
            if (rejectedFiles.length > 1) {
              errorMessage = "You can only upload one video.";
            } else if (rejectedFiles[0].file.size > maxFileSize) {
              errorMessage = "The file size is too large. Please downscale your video.";
            } else if (
              rejectedFiles[0].file.type !== "video/mp4" &&
              rejectedFiles[0].file.type !== "video/quicktime"
            ) {
              errorMessage =
                "The file type is not supported. Only .mp4 and .mov files are accepted.";
            } else {
              errorMessage = "An unknown error occurred.";
            }
            showFailureNotification({
              message: errorMessage,
              title: "Error Uploading Video",
              stayOpen: true,
            });
          }}
          maxSize={maxFileSize}
          maxFiles={1}
          accept={["video/mp4", "video/quicktime"]}>
          <Group justify="center" gap="xl" style={{ pointerEvents: "none" }}>
            <Dropzone.Accept>
              <IconUpload
                size="2.6rem"
                style={{ color: "var(--mantine-color-blue-6)" }}
                stroke={1.5}
              />
            </Dropzone.Accept>
            <Dropzone.Reject>
              <IconX size="2.6rem" style={{ color: "var(--mantine-color-red-6)" }} stroke={1.5} />
            </Dropzone.Reject>
            <Dropzone.Idle>
              <IconVideo
                size="2.6rem"
                style={{ color: "var(--mantine-color-dimmed)" }}
                stroke={1.5}
              />
            </Dropzone.Idle>

            <div>
              <Text size="md" inline>
                Drag video here or click to select file
              </Text>
              <Text size="xs" c="dimmed" inline mt={7}>
                The size of the video (.mp4 or .mov) should not exceed {maxFileSizeLabel}
              </Text>
            </div>
          </Group>
        </Dropzone>
        <Progress
          value={failedUpload ? 0 : progress}
          animated={!uploadSuccessful}
          color={progress < 100 ? "yellow" : "teal"}
        />
        {!disabled && (
          <Flex gap="xs" align="center" justify="flex-start">
            {file ? (
              <IconCircleCheck size="1.2rem" color="teal" />
            ) : (
              <IconCircleX size="1.2rem" color="red" />
            )}
            <Text>
              <Text span fw="500">
                Video to Upload:{" "}
              </Text>
              {fileName || "None"}
            </Text>
          </Flex>
        )}
      </Stack>
      {!disabled && (
        <MetadataFields
          format={format}
          title={title}
          setTitle={setTitle}
          caption={caption}
          setCaption={setCaption}
          integrationTimestamp={integrationTimestamp}
          setIntegrationTimestamp={setIntegrationTimestamp}
          setMetadataValid={setMetadataValid}
          disableMetadata={disableMetadata}
          showErrors={showErrors}
        />
      )}
      {!disabled && (
        <Group justify="right">
          <ReviewVideoSubmission
            videoUrl={localVideoUrl}
            title={title}
            caption={caption}
            integrationTimestamp={integrationTimestamp}
            disableUploadButton={!file || uploadSuccessful}
            validUpload={metadataValid && file && !uploadSuccessful}
            uploading={initializingUpload || (!failedUpload && progress > 0 && progress < 100)}
            handleUpload={handleUpload}
            handleCancelUpload={handleCancelUpload}
            handleShowError={() => setShowErrors(true)}
          />
        </Group>
      )}
    </>
  );
}
