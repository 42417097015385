import React, { useEffect, useState } from "react";

import { Box, Card, Group, Text } from "@mantine/core";

import { fetchMessages } from "components/contracts/common/Api";
import DeliverableCardHeader from "components/contracts/common/DeliverableCardHeader";
import DeliverableVideo from "components/contracts/models/DeliverableVideo";
import Message, { MessageParty } from "components/contracts/models/Message";
import EmbeddedVideoPlayer from "components/contracts/common/EmbeddedVideoPlayer";
import MessageHistory from "components/contracts/common/MessageHistory";
import ReviewStatusBadge from "components/contracts/common/ReviewStatusBadge";
import SocialHandleButton from "components/contracts/common/SocialHandleButton";

import { INTEGRATED_FORMATS } from "components/contracts/common/Common";

import { timeAgo } from "utils/DateUtils";

export default function PreviousVideoCard({
  deliverableVideo,
  messageParty,
  versionNumber,
}: {
  deliverableVideo: DeliverableVideo;
  messageParty: MessageParty;
  versionNumber: number;
}) {
  const [pastMessages, setPastMessages] = useState<Message[]>([]);
  const [messagesLoaded, setMessagesLoaded] = useState(false);
  const [messageLodingError, setMessageLoadingError] = useState(false);

  const isIntegration = INTEGRATED_FORMATS.includes(deliverableVideo.deliverable.format);

  useEffect(() => {
    fetchMessages({
      deliverableId: deliverableVideo.deliverable.id,
      messageParty: messageParty.toString(),
      videoId: deliverableVideo.videoId,
    })
      .then((response: any) => {
        const { success, messages } = response;
        if (success) {
          const deserializedMessages = messages.map((message: any) => Message.deserialize(message));
          setPastMessages(deserializedMessages);
          setMessagesLoaded(true);
        } else {
          setMessageLoadingError(true);
        }
      })
      .catch(() => {
        setMessageLoadingError(true);
      });
  }, []);

  return (
    <Card shadow="sm" padding="lg" radius="md" withBorder>
      <Group justify="space-between" mb="lg" align="start">
        <DeliverableCardHeader
          platform={deliverableVideo.deliverable.platform}
          format={deliverableVideo.deliverable.format}
          subHeading={
            <Text>
              <Text size="sm" c="dimmed">
                Version #{versionNumber} - Submitted by
                <SocialHandleButton
                  platform={deliverableVideo.deliverable.platform}
                  handle={deliverableVideo.deliverable.creatorHandle}
                  profileLink={deliverableVideo.deliverable.profileLink}
                  showIcon={false}
                />
                {timeAgo(deliverableVideo.dateCreated)}
              </Text>
            </Text>
          }
        />
        <Group gap="xs">
          <ReviewStatusBadge
            status={deliverableVideo.contentStatus}
            reviewDate={deliverableVideo.reviewDate}
          />
        </Group>
      </Group>
      <Group justify="center">
        <EmbeddedVideoPlayer
          url={deliverableVideo.transcodedVideoLocation || deliverableVideo.location}
          title={deliverableVideo.title}
          caption={deliverableVideo.caption}
          showRawLink={!deliverableVideo.transcodedVideoLocation}
          integrationTimestamp={deliverableVideo.integrationTimestamp}
          isIntegration={isIntegration}
        />
      </Group>
      <Box mt="md">
        <MessageHistory
          messages={pastMessages}
          setMessages={setPastMessages}
          messageViewer={messageParty}
          messagesLoaded={messagesLoaded}
          loadingError={messageLodingError}
        />
      </Box>
    </Card>
  );
}
