import { NativeSelect, Stack, Text, Title, Tooltip } from "@mantine/core";
import { GetInputPropsReturnType } from "@mantine/form/lib/types";
import { IconInfoCircle } from "@tabler/icons-react";
import Spacer from "components/Spacer";
import React from "react";

export interface NativeSelectWithTooltipData {
  label: string;
  value: string;
  tooltip: string;
}

const NativeSelectWithTooltip = ({
  data,
  disabled = false,
  inputProps,
  keyProp,
  title,
}: {
  data: NativeSelectWithTooltipData[];
  disabled?: boolean;
  inputProps: GetInputPropsReturnType;
  keyProp: string;
  title: string;
}) => {
  return (
    <NativeSelect
      key={keyProp}
      disabled={disabled}
      label={
        <>
          {title}&nbsp;
          <Tooltip
            w="300px"
            multiline
            label={
              <Stack gap={0}>
                {data.map(({ label, value, tooltip }, index) => (
                  <React.Fragment key={value}>
                    <Title size="h5" td="underline">
                      {label}:
                    </Title>
                    <Text c="var(--mantine-color-gray-2)">{tooltip}</Text>
                    {index < data.length ? <Spacer height={4} /> : null}
                  </React.Fragment>
                ))}
              </Stack>
            }>
            <IconInfoCircle size={16} color="var(--mantine-color-gray-6)" />
          </Tooltip>
          &nbsp;
        </>
      }
      withAsterisk
      data={data.map((type) => ({ label: type.label, value: type.value }))}
      styles={{
        label: {
          display: "inline-flex",
          alignItems: "center",
        },
      }}
      {...inputProps}
    />
  );
};

export default NativeSelectWithTooltip;
