import React, { useEffect, useState } from "react";
import { Avatar, Box, Image, Label, Text, Flex, Mask } from "gestalt";
import "gestalt/dist/gestalt.css";
import { User } from "firebase/auth";

import Spacer from "components/Spacer";
import { handleImageUpload } from "utils/UserUtils";
import { useUser } from "utils/UserContext";
import UploadButton from "components/UploadButton";
import banner from "pages/profile/assets/default-banner.jpg";
import "pages/profile/styles/profile.css";

const bannerEditStyle = {
  position: "absolute",
  transform: "translate(-50%,-50%)",
  left: "50%",
  top: "50%",
  borderRadius: "50%",
  zIndex: 3,
  cursor: "pointer",
} as React.CSSProperties;

const ProfileBanner = ({
  requestUser,
  src,
  isSelf,
  hideInput,
}: {
  requestUser: User;
  src: string;

  // Whether a user is looking at their own avatar. If they are, then they will be able to edit it
  isSelf: boolean;

  // Whether to hide the input component (useful when users are explicitly trying to edit their profile)
  hideInput?: boolean;
}) => {
  const [bannerSrc, setBannerSrc] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [userProfile, isLoading, updateUser] = useUser();

  const initialSrc = src || banner;
  const bannerClassName = hideInput ? "profile-header-banner" : "profile-header-banner-edit";

  useEffect(() => {
    setBannerSrc(initialSrc);
  }, [bannerSrc]);

  const handleImageUploadChange = (element: React.ChangeEvent<HTMLInputElement>) =>
    handleImageUpload(requestUser, element, setBannerSrc, setErrorMessage, updateUser, "banner");

  const getBannerImage = (useDefaultImage = false) => (
    <Box height={200} width="100%" opacity={useDefaultImage || initialSrc === banner ? 0.3 : 1}>
      <Mask rounding={4} height="100%" width="100%">
        <Image
          color="transparent"
          alt="banner_image"
          fit="cover"
          src={useDefaultImage ? banner : bannerSrc}
          naturalHeight={1}
          naturalWidth={1}
        />
      </Mask>
    </Box>
  );

  return (
    <div className={bannerClassName}>
      {isLoading ? (
        getBannerImage(true)
      ) : (
        <Box>
          {isSelf ? (
            <Box position="relative">
              {getBannerImage()}
              {hideInput ? null : (
                <div style={bannerEditStyle}>
                  <UploadButton
                    buttonName="bannerButton"
                    handleImageUpload={handleImageUploadChange}
                  />
                </div>
              )}
            </Box>
          ) : (
            getBannerImage()
          )}
          {errorMessage ? (
            <>
              <Spacer height={32} />
              <Text color="error">{errorMessage}</Text>
            </>
          ) : null}
        </Box>
      )}
    </div>
  );
};

export default ProfileBanner;
