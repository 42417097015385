import React, { useState } from "react";

// Assume that notifications has already been rendered in the app.
import { User } from "models/User";
import {
  wrapUpdateFunc,
  ChangeConfirmation,
  identityTransformation,
} from "campaigns/main/campaignPage/ChangeConfirmation";

const campaignParamsFormatValue = (key: string, value: any) => {
  if (value == null) return "";
  if (typeof value === "boolean") {
    return value.toString();
  }
  if (key === "countries") {
    return value.join(", ");
  } else if (key === "languages") {
    return value.join(", ");
  }
  return value;
};

export const CampaignParametersChangeConfirmation = ({
  objId,
  form,
  formValues,
  initialValues,
  onSubmit,
  close,
}: {
  objId: number;
  form: any;
  formValues: { [key: string]: any };
  initialValues: { [key: string]: any };
  onSubmit: (
    objId: number,
    updates: { [key: string]: any },
    updateFields: string[],
  ) => Promise<any>;
  close: () => void;
}) => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const wrappedFunc = wrapUpdateFunc(
    form,
    onSubmit,
    setButtonLoading,
    close,
    "campaign parameters",
    identityTransformation(),
  );

  return (
    <ChangeConfirmation
      objId={objId}
      formValues={formValues}
      initialValues={initialValues}
      updateFunc={wrappedFunc}
      buttonLoading={buttonLoading}
      customDiffFormatValue={campaignParamsFormatValue}
    />
  );
};

export default CampaignParametersChangeConfirmation;
