import {
  Anchor,
  Button,
  Divider,
  Group,
  MultiSelect,
  NativeSelect,
  NumberInput,
  Radio,
  Stack,
  Switch,
  Text,
  Textarea,
  TextInput,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import { BudgetType, Campaign, CampaignObjective, CampaignStatus } from "models/Campaign";
import { DatePickerInput } from "@mantine/dates";
import { IconCalendar } from "@tabler/icons-react";
import { useForm } from "@mantine/form";
import CardBase from "campaigns/create/common/CardBase";
import ProductGuideLink from "campaigns/create/common/ProductGuideLink";
import { fromISODateString } from "utils/DateUtils";
import { updateCampaign } from "campaigns/create/api/UpdateCampaignApi";
import { createCampaign } from "campaigns/create/api/CreateCampaignApi";
import { useAppSelector } from "reduxStore/hooks";

interface BudgetTypeInfo {
  label: string;
  description: string;
  value: string;
}
const MonthlyType: BudgetTypeInfo = {
  label: "Monthly",
  description: "Budget resets on the 1st of each month.",
  value: BudgetType.Monthly.toString(),
};
const QuarterlyType: BudgetTypeInfo = {
  label: "Quarterly",
  description: "Budget resets on the 1st of each calendar quarter (Jan 1, Apr 1, Jul 1, Oct 1).",
  value: BudgetType.Quarterly.toString(),
};
const YearlyType: BudgetTypeInfo = {
  label: "One-Time",
  description: "Budget is one-time and is not renewed.",
  value: BudgetType.Once.toString(),
};
const ALL_BUDGET_TYPES: BudgetTypeInfo[] = [MonthlyType, QuarterlyType, YearlyType];
const BUDGET_TYPE_VALUE_TO_INFO_MAP: { [value: string]: BudgetTypeInfo } = {
  [MonthlyType.value]: MonthlyType,
  [QuarterlyType.value]: QuarterlyType,
  [YearlyType.value]: YearlyType,
};

const ALL_CAMPAIGN_OBJECTIVES: { label: string; value: string }[] = Object.keys(CampaignObjective)
  .filter((k) => typeof CampaignObjective[k as any] === "number")
  .map((key) => ({
    label: key.charAt(0).toUpperCase() + key.slice(1).toLowerCase(),
    value: CampaignObjective[key as any].toString(),
  }));

const COUNTRY_SETTINGS: { label: string; value: string }[] = [
  { label: "US", value: true.toString() },
  { label: "Global", value: false.toString() },
];

export interface SetupFormType {
  name: string | null;
  description: string;
  selectedCampaignObjectives: string[];
  budgetType: string;
  budgetAmount: number | null;
  campaignDates: Date[];
  usOnly: string;
}

const SetupCampaignCard = ({
  campaign,
  isActive,
  onSaveSuccess,
}: {
  campaign: Campaign | null;
  isActive: boolean;
  onSaveSuccess: (formInfo: SetupFormType, campaign: Campaign | null, isCreated: boolean) => void;
}) => {
  const myBrandId = useAppSelector((state) => state.me.brandId);
  const [showAdvancedSettings, setShowAdvancedSettings] = useState<boolean>(false);

  const [isCampaignAlreadyLive, setIsCampaignAlreadyLive] = useState<boolean>(false);

  const [hasFormBeenEdited, setHasFormBeenEdited] = useState<boolean>(false);
  // only looks at subset of fields that can be changed when campaign is live (name, description, campaign objectives)
  const [
    haveValidFieldsOnFormChangedIfCampaignLive,
    setHaveValidFieldsOnFormChangedIfCampaignLive,
  ] = useState<boolean>(false);

  const form = useForm<SetupFormType>({
    mode: "uncontrolled",
    initialValues: {
      name: null,
      description: "",
      selectedCampaignObjectives: [],
      budgetType: MonthlyType.value,
      budgetAmount: null,
      campaignDates: [],
      usOnly: true.toString(),
    },

    validate: {
      name: (value) => (value ? null : "Please enter your name"),
      budgetAmount: (value) => (value ? null : "Please enter a budget amount"),
    },
  });

  form.watch("name", () => {
    setHaveValidFieldsOnFormChangedIfCampaignLive(form.isDirty());
    setHasFormBeenEdited(form.isDirty());
  });
  form.watch("description", () => {
    setHaveValidFieldsOnFormChangedIfCampaignLive(form.isDirty());
    setHasFormBeenEdited(form.isDirty());
  });
  form.watch("selectedCampaignObjectives", () => {
    setHaveValidFieldsOnFormChangedIfCampaignLive(form.isDirty());
    setHasFormBeenEdited(form.isDirty());
  });

  form.watch("budgetAmount", () => {
    setHasFormBeenEdited(form.isDirty());
  });

  form.watch("campaignDates", () => {
    setHasFormBeenEdited(form.isDirty());
  });

  form.watch("usOnly", () => {
    setHasFormBeenEdited(form.isDirty());
  });

  const resetFormDirtyState = () => {
    form.resetDirty();
    setHaveValidFieldsOnFormChangedIfCampaignLive(form.isDirty());
    setHasFormBeenEdited(form.isDirty());
  };

  useEffect(() => {
    if (campaign) {
      setIsCampaignAlreadyLive(campaign.status === CampaignStatus.Active);
      const startDate = campaign.start_date ? fromISODateString(campaign.start_date) : null;
      const endDate = campaign.end_date ? fromISODateString(campaign.end_date) : null;

      if (startDate || endDate) {
        setShowAdvancedSettings(true);
      }

      form.setValues({
        name: campaign.title,
        description: campaign.description || "",
        budgetType: campaign.budget_type?.toString() ?? MonthlyType.value,
        budgetAmount: campaign.budget,
        campaignDates: [startDate, endDate],
        selectedCampaignObjectives:
          campaign.objectives?.map((objective) => objective.toString()) ?? [],
        usOnly: (campaign.us_only ?? true).toString(),
      });
      resetFormDirtyState();
    }
  }, [campaign]);

  return (
    <CardBase
      isActive={isActive}
      title="Setup"
      subtitle="Set goals and budget for your campaign."
      nodeShownWhenActive={
        <form
          onSubmit={form.onSubmit((values) => {
            let formBlob: { [key: string]: any } = {
              title: values.name,
              description: values.description,
              budget: values.budgetAmount,
              budget_type: Number(values.budgetType),
              start_date: values.campaignDates[0]?.toISOString() || null,
              end_date: values.campaignDates[1]?.toISOString() || null,
              objectives: values.selectedCampaignObjectives.map((objective) => Number(objective)),
              us_only: Boolean(values.usOnly),
            };
            if (campaign) {
              if (isCampaignAlreadyLive) {
                const canBeUpdatedValues = new Set(["title", "description", "objectives"]);
                formBlob = Object.keys(formBlob)
                  .filter((objKey) => canBeUpdatedValues.has(objKey))
                  .reduce((newFormBlob: { [field: string]: any }, key) => {
                    /* eslint-disable no-param-reassign */
                    newFormBlob[key] = formBlob[key];
                    /* eslint-enable no-param-reassign */
                    return newFormBlob;
                  }, {});
              }
              updateCampaign(campaign.hash_id, formBlob).then((result) => {
                onSaveSuccess(values, result, false);
                resetFormDirtyState();
              });
            } else {
              createCampaign(myBrandId, formBlob).then((response) => {
                onSaveSuccess(values, response.campaign, true);
                resetFormDirtyState();
              });
            }
          })}>
          <Stack px={0} style={{ "--stack-gap": "24px" }}>
            <TextInput
              label="Name"
              placeholder="Campaign Name"
              withAsterisk
              key={form.key("name")}
              {...form.getInputProps("name")}
            />
            <Textarea
              label="Description"
              placeholder="Campaign description"
              minRows={2}
              key={form.key("description")}
              {...form.getInputProps("description")}
            />
            <MultiSelect
              label="Objectives"
              description="Your objectives are used for creator recommendations and evaluation. Different creators can perform better for different objectives."
              data={ALL_CAMPAIGN_OBJECTIVES}
              key={form.key("selectedCampaignObjectives")}
              {...form.getInputProps("selectedCampaignObjectives")}
            />
            <NativeSelect
              disabled
              data={["English"]}
              label="Language"
              withAsterisk
              description="Only target creators who speak a specific language"
            />
            <NativeSelect
              data={COUNTRY_SETTINGS}
              label="Country"
              withAsterisk
              description="Only target creators in a specific country"
              key={form.key("usOnly")}
              {...form.getInputProps("usOnly")}
              disabled={isCampaignAlreadyLive}
            />
            <NumberInput
              label="Monthly Budget (in USD)"
              description={
                <Text size="xs" component="span">
                  Amount to spend (including 1stCollab platform fees). Note that budget resets each
                  time period regardless of amount spent. (Unspent budget does not roll over). See
                  our <ProductGuideLink /> to learn more.
                </Text>
              }
              placeholder="0"
              prefix="$"
              min={1}
              allowDecimal
              thousandSeparator=","
              decimalScale={2}
              withAsterisk
              key={form.key("budgetAmount")}
              {...form.getInputProps("budgetAmount")}
              disabled={isCampaignAlreadyLive}
            />
            <Divider />
            <Switch
              size="xs"
              label="Show advanced campaign settings"
              checked={showAdvancedSettings}
              onChange={(event) => setShowAdvancedSettings(event.currentTarget.checked)}
              labelPosition="left"
            />
            {showAdvancedSettings ? (
              <DatePickerInput
                type="range"
                label="Duration"
                description="To optimize performance, we recommend not having specific start and end dates for your campaign. Only use this option if your campaign is time sensitive (e.g., Black Friday promo). All content will be required to go live within this time period."
                placeholder="Pick campaign date range"
                clearable
                leftSection={<IconCalendar size="20" />}
                leftSectionPointerEvents="none"
                key={form.key("campaignDates")}
                {...form.getInputProps("campaignDates")}
                disabled={isCampaignAlreadyLive}
              />
            ) : null}
            <Button
              variant="filled"
              type="submit"
              disabled={
                !hasFormBeenEdited ||
                (isCampaignAlreadyLive && !haveValidFieldsOnFormChangedIfCampaignLive)
              }>
              Save
            </Button>
          </Stack>
        </form>
      }
    />
  );
};

export default SetupCampaignCard;
