import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";

import { Card, Stack, Text } from "@mantine/core";

import { IconGraph } from "@tabler/icons-react";

import { User } from "firebase/auth";
import { AuthContext } from "auth/AuthContext";
import { API_URL } from "configs/Configs";
import { createRequestWithFirebaseToken, handleResult } from "utils/ApiUtils";
import {
  getInstagramProfileInsights,
  InstagramProfileResponse,
  INSTAGRAM_PROFILE_URL,
} from "social/fb/InstagramUtils";
import {
  YoutubeProfileResponse,
  getYoutubeProfileInsights,
  YOUTUBE_PROFILE_URL,
} from "social/google/YoutubeUtils";
import {
  getTiktokProfileInsights,
  TikTokProfileResponse,
  TIKTOK_PROFILE_URL,
} from "social/tiktok/TikTokUtils";
import { PlatformConstants } from "components/MediaBlockCard";
import { OverviewCard } from "pages/profile/ProfileCards";
import { getFirstProfileByKey } from "social/ApiUtils";
import { PlatformInsightsResponse } from "social/utils";
import { InternalCardTitle } from "components/creator/InternalCardTitle";

type SocialProfileType = InstagramProfileResponse | YoutubeProfileResponse | TikTokProfileResponse;

const fetchUserFromCreator = async (creatorId: number, abortController: AbortController) => {
  const request = await createRequestWithFirebaseToken({
    url: `${API_URL}/api/creator/get_user_from_creator?creator_id=${creatorId}`,
    method: "GET",
  });
  const response = await handleResult(request, abortController);
  return response;
};

const fetchProfile = async (
  platformUrl: string,
  requestUser: User,
  profileKey: string,
  setSocialProfile: (profile: SocialProfileType) => void,
) => {
  const firebaseToken = await requestUser?.getIdToken();
  const socialProfile = await getFirstProfileByKey(firebaseToken, profileKey, platformUrl);
  setSocialProfile(socialProfile);
};

const isValidStats = (platformInsights: PlatformInsightsResponse) =>
  platformInsights && platformInsights?.stats && platformInsights?.stats.length > 0;

export const calculateMaxWidthBlockCard = (platformConstants: PlatformConstants[]) => {
  const maxWidths = platformConstants.map((p) => p.numVideosDisplayLimit * (p.iframeWidth + 20));
  return Math.max(...maxWidths);
};

const fetchSocialStats = async (
  requestUser: User,
  socialProfile: SocialProfileType,
  getProfileInsights: (
    username: string,
    firebaseToken: string,
  ) => Promise<PlatformInsightsResponse>,
  setStats: (stats: PlatformInsightsResponse) => void,
) => {
  const firebaseToken = await requestUser?.getIdToken();
  if (socialProfile === null) {
    const socialAudienceStats: PlatformInsightsResponse = null;
    setStats(socialAudienceStats);
  } else if ("channel_id" in socialProfile) {
    const youtubeProfile = socialProfile as YoutubeProfileResponse;
    // TODO(andrew): should we switch this to channel id?
    const socialAudienceStats = socialProfile
      ? await getProfileInsights(youtubeProfile.user, firebaseToken)
      : null;
    setStats(socialAudienceStats);
  } else {
    const socialAudienceStats = socialProfile
      ? await getProfileInsights(socialProfile.socialUsername, firebaseToken)
      : null;
    setStats(socialAudienceStats);
  }
};

export const CreatorDemographics = ({ creatorId }: { creatorId: number }) => {
  const requestUser: User = useOutletContext<AuthContext>()?.user;

  const [creatorUserKey, setCreatorUserKey] = useState<string>(null);

  const [instagramProfile, setInstagramProfile] = useState(null);
  const [instagramStats, setInstagramStats] = useState(null);

  const [youtubeProfile, setYoutubeProfile] = useState(null);
  const [youtubeStats, setYoutubeStats] = useState(null);

  const [tiktokProfile, setTiktokProfile] = useState(null);
  const [tiktokStats, setTiktokStats] = useState(null);

  useEffect(() => {
    const abortController = new AbortController();
    fetchUserFromCreator(creatorId, new AbortController()).then((response) => {
      if (response?.user_key) {
        setCreatorUserKey(response.user_key);
      }
    });
    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    if (creatorUserKey) {
      fetchProfile(INSTAGRAM_PROFILE_URL, requestUser, creatorUserKey, setInstagramProfile);
      fetchProfile(YOUTUBE_PROFILE_URL, requestUser, creatorUserKey, setYoutubeProfile);
      fetchProfile(TIKTOK_PROFILE_URL, requestUser, creatorUserKey, setTiktokProfile);
    }
  }, [creatorUserKey]);

  // get Instagram info
  useEffect(() => {
    fetchSocialStats(requestUser, instagramProfile, getInstagramProfileInsights, setInstagramStats);
  }, [instagramProfile]);

  // get YouTube info
  useEffect(() => {
    fetchSocialStats(requestUser, youtubeProfile, getYoutubeProfileInsights, setYoutubeStats);
  }, [youtubeProfile]);

  // get TikTok info
  useEffect(() => {
    fetchSocialStats(requestUser, tiktokProfile, getTiktokProfileInsights, setTiktokStats);
  }, [tiktokProfile]);

  return (
    <Card shadow="xs" mx="lg" padding="lg" radius="md" withBorder>
      <Stack>
        <InternalCardTitle
          title="Audience"
          icon={<IconGraph size={24} color="var(--mantine-color-blue-filled)" />}
        />
        {creatorUserKey != null ? (
          <OverviewCard
            isClaimed
            instagramProfile={instagramProfile}
            instagramAudience={instagramStats?.audience}
            youtubeProfile={youtubeProfile}
            youtubeAudience={youtubeStats?.audience}
            tiktokProfile={tiktokProfile}
          />
        ) : (
          <Text c="dimmed">Creator not authenticated.</Text>
        )}
      </Stack>
    </Card>
  );
};

export default CreatorDemographics;
