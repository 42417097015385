import React, { useState, useEffect } from "react";
import { Badge, Card, Space, Stack, Table, Text, Title } from "@mantine/core";
import { Contract } from "components/contracts/dashboard/Utils";
import Opportunity, {
  OPPORTUNITY_COLORS,
  OPPORTUNITY_STATES,
  OpportunityActiveState,
} from "models/Opportunity";
import { Link } from "react-router-dom";
import { IconHistory, IconScript } from "@tabler/icons-react";

import { InternalCardSubtitle, InternalCardTitle } from "components/creator/InternalCardTitle";
import ContractOffer from "models/ContractOffer";
import { ContractOfferStatus, ContractStatusColors, SupportedFormat } from "models/Common";
import DeliverablePackage from "models/DeliverablePackage";

const HistoricalContracts = ({
  completedContracts,
  contractOffers,
  opportunity,
}: {
  completedContracts: Contract[];
  opportunity: Opportunity[];
  contractOffers?: ContractOffer[];
}) => {
  // arr of contract items
  const contractTableElements = completedContracts.map((contract) => {
    const { contractId, amount, deliverables, dateCreated, campaignId } = contract;
    const currOpportunity = opportunity.find((opp) => opp.campaign_id === Number(campaignId));
    const campaignName = currOpportunity ? currOpportunity.ad_group.campaign_name : "Unknown";
    return {
      contractId,
      campaignId,
      campaignName,
      opportunityState: currOpportunity
        ? currOpportunity.active_state
        : OpportunityActiveState.UNKNOWN,
      opportunityId: currOpportunity ? currOpportunity.id : "Unknown",
      deliverables,
      amount,
      dateCreated,
    };
  });

  // arr of items for historical contract offers table
  const contractOfferRows = contractOffers.map((offer) => {
    const {
      date_created: offerDateCreated,
      deliverable_packages: deliverables,
      opportunity: opportunityId,
      status,
      id: contractOfferId,
    } = offer;
    const currOpportunity = opportunity.find((opp) => opp.id === (opportunityId as any));

    return deliverables.map((packages) => {
      const {
        id,
        price,
        brand_approved_price: brandPrice,
        suggested_price: suggestedPrice,
        date_created: dateCreated,
        status: dpStatus,
        creator_asking_price: askingPrice,
      } = packages;

      const selectedFormats = Object.keys(packages)
        .filter((key) => {
          const dp = key as keyof DeliverablePackage;
          return (
            Object.values(SupportedFormat).includes(key as SupportedFormat) && packages[dp] !== 0
          );
        })
        .map((dp) => {
          return (
            <Text size="xs" key={dp}>
              {dp}
            </Text>
          );
        });

      const oppStateBadge = (
        <Badge variant="light" color={OPPORTUNITY_COLORS[currOpportunity?.active_state]}>
          {OPPORTUNITY_STATES[currOpportunity?.active_state]}
        </Badge>
      );

      const offerOutcome =
        status === ContractOfferStatus.CONTRACT_ISSUED &&
        dpStatus !== ContractOfferStatus.CONTRACT_ISSUED
          ? "Package Closed"
          : ContractOfferStatus[dpStatus];

      return (
        <Table.Tr key={id}>
          <Table.Td>{currOpportunity?.ad_group?.campaign_name}</Table.Td>
          <Table.Td>{opportunityId as unknown as number}</Table.Td>
          <Table.Td>{contractOfferId}</Table.Td>
          <Table.Td>{oppStateBadge}</Table.Td>
          <Table.Td>{selectedFormats}</Table.Td>
          <Table.Td>${askingPrice}</Table.Td>
          <Table.Td>${suggestedPrice}</Table.Td>
          <Table.Td>{brandPrice ? `$${brandPrice}` : "N/A"}</Table.Td>
          <Table.Td>{price ? `$${price}` : "N/A"}</Table.Td>
          <Table.Td>
            <Badge
              variant="light"
              color={offerOutcome === "Package Closed" ? "red" : ContractStatusColors[dpStatus]}>
              {offerOutcome}
            </Badge>
          </Table.Td>
          <Table.Td>{new Date(offerDateCreated).toLocaleDateString()}</Table.Td>
        </Table.Tr>
      );
    });
  });

  // arr of items for historical deliverables table
  const deliverableRows = contractTableElements.map((contract) => {
    const { deliverables, campaignId } = contract;
    return deliverables.map((deliverable) => {
      const { format, usageRightsDays, dateCreated } = deliverable;
      return (
        <Table.Tr key={format}>
          <Table.Td>{campaignId}</Table.Td>
          <Table.Td>{format}</Table.Td>
          <Table.Td>{usageRightsDays > 0 ? "True" : "False"}</Table.Td>
          <Table.Td>{usageRightsDays}</Table.Td>
          <Table.Td>{new Date(dateCreated).toLocaleDateString()}</Table.Td>
          <Table.Td>
            <Link to={`/contracts/${contract.contractId}`}>View Contract</Link>
          </Table.Td>
        </Table.Tr>
      );
    });
  });

  // arr of items for historical contracts table
  const contractsRows = contractTableElements.map((contract) => {
    const { contractId, amount, dateCreated, deliverables, campaignId } = contract;
    const contractURL = `/contracts/${contractId}`;
    const deliverableMap = deliverables.map((deliverable) => {
      const { format } = deliverable;
      return (
        <Text size="sm" key={format}>
          {format}
        </Text>
      );
    });
    return (
      <Table.Tr key={contract.contractId}>
        <Table.Td>{campaignId}</Table.Td>
        <Table.Td>{deliverableMap}</Table.Td>
        <Table.Td>${(amount / 100).toLocaleString()}</Table.Td>
        <Table.Td> {new Date(dateCreated).toLocaleDateString()}</Table.Td>
        <Table.Td>
          <Link to={contractURL}>View Contract</Link>
        </Table.Td>
      </Table.Tr>
    );
  });

  return (
    <Card shadow="xs" mx="lg" padding="lg" radius="md" withBorder>
      <Stack>
        <InternalCardTitle
          title="Creator Contract History"
          icon={<IconHistory size={24} color="var(--mantine-color-blue-filled)" />}
        />
        <InternalCardSubtitle
          title="Historical Contracts"
          icon={<IconHistory size={20} color="var(--mantine-color-gray-9)" />}
        />
        {completedContracts.length > 0 ? (
          <Table.ScrollContainer minWidth="xl">
            <Table striped highlightOnHover>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>Campaign Id</Table.Th>
                  <Table.Th>Deliverable Format</Table.Th>
                  <Table.Th>Amount</Table.Th>
                  <Table.Th>Created Date</Table.Th>
                  <Table.Th>Contract Link</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>{contractsRows}</Table.Tbody>
            </Table>
          </Table.ScrollContainer>
        ) : (
          <Text c="dimmed">No completed contracts found</Text>
        )}
        <Space h="sm" />
        <InternalCardSubtitle
          title="Historical Contract Offers"
          icon={<IconScript size={20} color="var(--mantine-color-gray-9)" />}
        />
        {contractOffers.length > 0 ? (
          <Table.ScrollContainer minWidth="xl">
            <Table striped highlightOnHover>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>Campaign Name</Table.Th>
                  <Table.Th>Opportunity ID</Table.Th>
                  <Table.Th>Contract Offer ID</Table.Th>
                  <Table.Th>Opportunity State</Table.Th>
                  <Table.Th>Deliverables in Package</Table.Th>
                  <Table.Th>Creator Asking Price</Table.Th>
                  <Table.Th>Recommended Price</Table.Th>
                  <Table.Th>Brand Approved Price</Table.Th>
                  <Table.Th>Offer Price</Table.Th>
                  <Table.Th>Offer Outcome </Table.Th>
                  <Table.Th>Created Date</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>{contractOfferRows}</Table.Tbody>
            </Table>
          </Table.ScrollContainer>
        ) : (
          <Text c="dimmed">No contract offers found</Text>
        )}
        <Space h="sm" />
        <InternalCardSubtitle
          title="Deliverables"
          icon={<IconScript size={20} color="var(--mantine-color-gray-9)" />}
        />
        {contractTableElements.length > 0 ? (
          <Table.ScrollContainer minWidth="xl">
            <Table striped highlightOnHover>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>Campaign Id</Table.Th>
                  <Table.Th>Deliverable Format</Table.Th>
                  <Table.Th>Usage Rights</Table.Th>
                  <Table.Th>Usage Rights Duration</Table.Th>
                  <Table.Th>Created Date</Table.Th>
                  <Table.Th>Contract Link</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>{deliverableRows}</Table.Tbody>
            </Table>
          </Table.ScrollContainer>
        ) : (
          <Text c="dimmed">No deliverables found</Text>
        )}
      </Stack>
    </Card>
  );
};

export default HistoricalContracts;
