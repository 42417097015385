import React from "react";
import { Flex, Text, ButtonGroup } from "gestalt";
import { Box, Center } from "@mantine/core";
import "gestalt/dist/gestalt.css";

import "pages/home/styles/light-styles.css";
import Spacer from "components/Spacer";
import { CreatorSignupButton } from "pages/home/components/Buttons";
import { useLocation } from "react-router-dom";

const LowerCTA = () => (
  <div className="ctaModule">
    <Box px={{ base: "24px", md: "32px", lg: "96px" }}>
      <Center>
        <Flex alignItems="center" direction="column" gap={8} width="min(400px, 100%)">
          <Text color="inverse" weight="bold" size="500" align="center">
            Ready for a better way to do influencer marketing?
          </Text>
          <Text color="subtle" size="300" align="center">
            Brand partnerships are a pain to make happen. We make it easier. Try for free today!
          </Text>
          <ButtonGroup>
            <CreatorSignupButton />
          </ButtonGroup>
        </Flex>
      </Center>
    </Box>
  </div>
);

const LowerFooter = () => (
  <footer>
    <div className="footerLinks">
      <div className="footerCol">
        <div className="logo" id="footerLogo" />
        <Text color="inverse">The best way to find, create, and manage influencer campaigns</Text>
      </div>
      <div className="footerCol">
        <Text color="inverse" weight="bold" size="400">
          Product
        </Text>
        <Spacer height={8} />
        <a href="/login" className="footerLink">
          Log in
        </a>
        <a href="/signup" className="footerLink">
          Sign up
        </a>
      </div>
      <div className="footerCol">
        <Text color="inverse" weight="bold" size="400">
          Legal
        </Text>
        <Spacer height={8} />
        <a href="/privacy-policy" className="footerLink">
          Privacy Policy
        </a>
        <a href="/terms-of-service" className="footerLink">
          Terms & Conditions
        </a>
      </div>
      <div className="footerCol">
        <Text color="inverse" weight="bold" size="400">
          Contact us
        </Text>
        <Spacer height={8} />
        <a href="mailto:hello@1stcollab.com" className="footerLink">
          hello@1stcollab.com
        </a>
        <a
          href="https://campaigns.1stcollab.com/taxes"
          target="_blank"
          className="footerLink"
          rel="noreferrer">
          2023 Tax Center
        </a>
      </div>
    </div>
    <div className="divider" />
    <div className="socialCopyright">
      <Text color="subtle" size="100">
        © 2024 1stCollab Inc. All rights reserved. Made with love in San Francisco, California
      </Text>
    </div>
  </footer>
);

const FOOTER_PATHS = ["/", "/discover", "/media-kits"];
const Footer = () => {
  const location = useLocation();
  const shouldShowFooter = FOOTER_PATHS.includes(location.pathname);

  if (!shouldShowFooter) return null;

  return (
    <>
      <LowerCTA />
      <LowerFooter />
    </>
  );
};

export default Footer;
