import React from "react";
import { Box, Flex, Image, Mask, Text } from "gestalt";
import "gestalt/dist/gestalt.css";
import Spacer from "components/Spacer";
import FlatModule from "components/FlatModule";
import { getAbbreviatedNumber, getPercentage } from "utils/AnalyticsUtils";
import { timeAgo } from "utils/TimeUtils";

export interface PlatformConstants {
  platformName: string;
  iframeHeight: number;
  iframeWidth: number;
  numVideosDisplayLimit: number;
  maxTitleLengthLowerBound: number;
}

export interface MediaEntry {
  url: string;
  video_url: string;
  title?: string;
  likes?: number;
  views?: number;
  comments?: number;
  total_engagement?: number;
  creation_timestamp?: string;
  is_image?: boolean;
  thumbnail?: string;
  platform: string;
  link_clicks?: number;
  conversions?: number;
  video_id?: string;
}

export const PLATFORM_INFO = [
  {
    platformName: "youtube",
    iframeHeight: 162,
    iframeWidth: 288,
    numVideosDisplayLimit: 3,
    maxTitleLengthLowerBound: 35,
  },
  {
    platformName: "instagram",
    iframeHeight: 300,
    iframeWidth: 288,
    numVideosDisplayLimit: 4,
    maxTitleLengthLowerBound: 20,
  },
  {
    platformName: "tiktok",
    iframeHeight: 400,
    iframeWidth: 300,
    numVideosDisplayLimit: 4,
    maxTitleLengthLowerBound: 20,
  },
];

export const PLATFORM_INFO_MAP = new Map(PLATFORM_INFO.map((i) => [i.platformName, i]));

const getMaxTextLength = (medias: MediaEntry[], lowerBound: number) => {
  const textLengths = medias.map((m) => m.title?.length || 0);
  const minLength = Math.min(...textLengths.filter((l) => l > 0));
  return minLength > lowerBound ? lowerBound : minLength;
};

const truncateTitle = (title: string, maxTextLength: number) => {
  if (title.length > maxTextLength) {
    return `${title.slice(0, maxTextLength)}...`;
  }
  return title;
};

const MediaStats = ({ media, titleLength }: { media: MediaEntry; titleLength: number }) => (
  <Flex direction="column">
    <Box height={24} display="flex" direction="column" justifyContent="center">
      {media.title && <Text size="200">{truncateTitle(media.title, titleLength)}</Text>}
    </Box>
    {!(media.views === undefined) && (
      <Text size="100" color="subtle">
        {media.views} Views
      </Text>
    )}
    {!(media.likes === undefined) && (
      <Text size="100" color="subtle">
        {media.likes} Likes
      </Text>
    )}
    {!(media.comments === undefined) && (
      <Text size="100" color="subtle">
        {media.comments} Comments
      </Text>
    )}
  </Flex>
);

// displays a single thumbnail image and links to the original source
const ThumbnailImage = ({
  url,
  thumbnail,
  platform = "youtube",
}: {
  url: string;
  thumbnail: string;
  platform?: string;
}) => (
  <Mask
    rounding={4}
    width={PLATFORM_INFO_MAP.get(platform).iframeWidth + 10}
    height={PLATFORM_INFO_MAP.get(platform).iframeHeight + 10}>
    {/* ratio is 9/16 */}
    <Box
      height={PLATFORM_INFO_MAP.get(platform).iframeHeight}
      width={PLATFORM_INFO_MAP.get(platform).iframeWidth}>
      <a href={url} target="_blank" rel="noreferrer">
        <Image
          key={`thumbnail-${thumbnail}`}
          alt="Media Thumbnail"
          naturalHeight={PLATFORM_INFO_MAP.get(platform).iframeHeight}
          naturalWidth={PLATFORM_INFO_MAP.get(platform).iframeWidth}
          src={thumbnail}
          color="tranparent"
          loading="lazy"
          fit="cover"
        />
      </a>
    </Box>
  </Mask>
);

const MediaBlock = ({
  platformConstants,
  media,
}: {
  platformConstants: PlatformConstants;
  media: MediaEntry;
}) => {
  if (media.thumbnail) return <ThumbnailImage url={media.video_url} thumbnail={media.thumbnail} />;
  return media.is_image ? (
    <img src={media.url} alt={media.title} height={170} width={170} style={{ borderRadius: 10 }} />
  ) : (
    <iframe
      className={`${platformConstants.platformName}IFrame`}
      width={platformConstants.iframeWidth}
      height={platformConstants.iframeHeight}
      src={media.url}
      frameBorder="0"
      allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title={media.title}
      seamless
      style={{ borderRadius: 10 }}
    />
  );
};

/**
 * A card component with various pieces of media inside an iframe. The dimensions of the
 * iframe are passed in as props.
 */
export const MediaBlockCard = ({
  platformConstants,
  title,
  medias,
}: {
  platformConstants: PlatformConstants;
  title: string;
  medias: MediaEntry[];
}) => {
  const maxTextLength = getMaxTextLength(medias, platformConstants.maxTitleLengthLowerBound);

  return (
    <FlatModule>
      <Text weight="bold" size="400">
        {title}
      </Text>
      <Spacer height={20} />
      <Box
        display="flex"
        direction="row"
        alignItems="start"
        overflow="scrollX"
        marginStart={-6}
        marginEnd={-6}>
        {medias.map((m, index) => (
          <Box
            display="flex"
            direction="column"
            key={m.title}
            marginStart={index === 0 ? 6 : 0}
            marginEnd={index < medias.length - 1 ? 4 : 6}>
            <MediaBlock platformConstants={platformConstants} media={m} />
            <MediaStats media={m} titleLength={maxTextLength} />
          </Box>
        ))}
      </Box>
      <Spacer height={20} />
    </FlatModule>
  );
};

const MediaEntryDescription = ({
  media,
  detailedMetrics = false,
  hideMetrics = false,
}: {
  media: MediaEntry;
  detailedMetrics?: boolean;
  hideMetrics?: boolean;
}) => {
  const timeString = timeAgo(media.creation_timestamp);
  return (
    <Box direction="column" display="flex" padding={2}>
      <>
        {media.title && (
          <Text weight="bold" size="200">
            {truncateTitle(media.title, 65)}
          </Text>
        )}
        <Spacer height={8} />
        {(() => {
          if (media.views && !hideMetrics) {
            if (detailedMetrics) {
              return (
                <Text size="100" color="subtle">
                  {media.views && getAbbreviatedNumber(media.views)} Views &#x2022;{" "}
                  {media.likes && getAbbreviatedNumber(media.likes)} Likes &#x2022;{" "}
                  {media.comments && getAbbreviatedNumber(media.comments)} Comments
                  <br />
                  {media.views &&
                    getPercentage(
                      ((media.likes ? media.likes : 0) + (media.comments ? media.comments : 0)) /
                        (media.views ? media.views : 1),
                    )}{" "}
                  Engagement Rate
                  <br />
                  {timeString}
                </Text>
              );
            }
            return (
              <Text size="100" color="subtle">
                {media.views && getAbbreviatedNumber(media.views)} Views &#x2022; {timeString}
              </Text>
            );
          }
          return (
            <Text size="100" color="subtle">
              {timeString}
            </Text>
          );
        })()}
      </>
    </Box>
  );
};

export const MediaEntryCard = ({
  media,
  detailedMetrics = false,
  hideMetrics = false,
  padding = 100,
}: {
  media: MediaEntry;
  detailedMetrics?: boolean;
  hideMetrics?: boolean;
  padding?: number;
}) => {
  return (
    <Box
      display="flex"
      direction="column"
      key={`thumb-box-${media.video_url}`}
      maxWidth={PLATFORM_INFO_MAP.get(media.platform).iframeWidth + padding}
      maxHeight={PLATFORM_INFO_MAP.get(media.platform).iframeHeight + padding}
      justifyContent="center"
      alignItems="center">
      <ThumbnailImage
        url={media.video_url}
        thumbnail={media.thumbnail}
        key={`thumbnail-${media.video_url}`}
        platform={media.platform}
      />
      <MediaEntryDescription
        media={media}
        detailedMetrics={detailedMetrics}
        hideMetrics={hideMetrics}
      />
    </Box>
  );
};

export const ThumbnailCard = ({
  medias,
  listWidth = 920,
  numThumbnails = 3,
  detailedMetrics = false,
  hideMetrics = false,
}: {
  medias: MediaEntry[];
  listWidth?: number;
  numThumbnails?: number;
  detailedMetrics?: boolean;
  hideMetrics?: boolean;
}) => (
  <Box direction="row" display="flex" width={listWidth} justifyContent="between">
    {medias.slice(0, numThumbnails).map((media) => (
      <MediaEntryCard
        media={media}
        key={media.video_url}
        detailedMetrics={detailedMetrics}
        hideMetrics={hideMetrics}
      />
    ))}
  </Box>
);
