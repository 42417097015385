import React from "react";
import { Flex, Stack, Title, UnstyledButton } from "@mantine/core";

import classes from "components/discovery/search/styles/PriceFilter.module.css";

function PriceButton({
  label,
  active,
  setActive,
}: {
  label: string;
  active: boolean;
  setActive: (value: boolean) => void;
}) {
  return (
    <UnstyledButton
      px={10}
      py={6}
      fw="600"
      className={classes.control}
      onClick={() => setActive(!active)}
      mod={{ active }}>
      <span className={classes.controlLabel}>{label}</span>
    </UnstyledButton>
  );
}

export default function PriceFilter({
  active,
  setActive,
}: {
  active: { [key: number]: boolean };
  setActive: (value: { [key: number]: boolean }) => void;
}) {
  return (
    <Stack>
      <Title order={3} fw="500">
        Price
      </Title>
      <Flex justify="center" gap={2} className={classes.root} p={4}>
        <PriceButton
          label="$"
          active={active[1]}
          setActive={(value) => setActive({ ...active, 1: value })}
        />
        <PriceButton
          label="$$"
          active={active[2]}
          setActive={(value) => setActive({ ...active, 2: value })}
        />
        <PriceButton
          label="$$$"
          active={active[3]}
          setActive={(value) => setActive({ ...active, 3: value })}
        />
        <PriceButton
          label="$$$$"
          active={active[4]}
          setActive={(value) => setActive({ ...active, 4: value })}
        />
      </Flex>
    </Stack>
  );
}
