import { BrandStatus } from "models/Brand";
import { Campaign, CampaignAdGroup, CampaignAdGroupStatus, CampaignStatus } from "models/Campaign";

export const isAdGroupActive = (campaign: Campaign, adGroup: CampaignAdGroup): boolean => {
  const isBrandActive = campaign.brand?.status === BrandStatus.Active;
  const isCampaignActive = campaign.status === CampaignStatus.Active;
  const isAgActive = adGroup.status === CampaignAdGroupStatus.Active;
  return isBrandActive && isCampaignActive && isAgActive;
};

export default null;
