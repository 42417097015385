import React from "react";
import { Box, Title, TitleOrder } from "@mantine/core";

const DEFAULT_HEADER_PADDING = "xs";

const header = (text: string, size: TitleOrder) => {
  return (
    <Box py={DEFAULT_HEADER_PADDING}>
      <Title order={size}>{text}</Title>
    </Box>
  );
};

export default header;
