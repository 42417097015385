import React, { useEffect, useState } from "react";

import {
  ActionIcon,
  Button,
  Checkbox,
  Divider,
  Group,
  Loader,
  Paper,
  Select,
  Stack,
  Table,
  TagsInput,
  Text,
  Tooltip,
} from "@mantine/core";
import { notifications, Notifications } from "@mantine/notifications";
import { User } from "firebase/auth";
import { useOutletContext, useSearchParams } from "react-router-dom";

import { IconRefresh } from "@tabler/icons-react";
import { fetchAdminBrandData } from "admin/AdminUtils";
import { AuthContext } from "auth/AuthContext";
import {
  getCrawlStatus,
  hidePrecrawledSearch,
  LABELING_DATASETS_URL,
  markNotRelevantPrecrawledSearch,
  retriggerCrawl,
} from "components/creator_lists/LabelingUtils";
import {
  Platform,
  RecommendedSearchItem,
  RecommendedSeedType,
} from "components/discovery/Datamodels";
import { BrandCreatorSetPlatformFilterSelector } from "components/search/BrandCreatorSetPlatformFilterSelector";
import { createRequestWithFirebaseToken, handleResult } from "utils/ApiUtils";
import { fromISODateString } from "utils/DateUtils";

type RecommendedSearchResponse = {
  recommendations: RecommendedSearchItem[];
};

const fetchRecommendedSearchItems = async (
  requestUser: User,
  brandId: number,
  creatorSetId: number,
  platform: string,
  campaignParameterId: number,
  recommendedSeedType: RecommendedSeedType,
  setRecommendedSearches: (recommendedSearchItems: RecommendedSearchItem[]) => void,
  setLoading: (loading: boolean) => void,
) => {
  const firebaseToken = await requestUser.getIdToken();
  const requestData = {
    brandId,
    creatorSetId,
    platform,
    recommendedSeedType,
    campaignParameterId,
  };
  const searchSummaryEndpoint = `${LABELING_DATASETS_URL}get_recommended_searches/`;
  const request = new Request(searchSummaryEndpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`, // Assuming you are sending the token in the Authorization header
    },
    body: JSON.stringify(requestData),
  });

  const response: RecommendedSearchResponse = await handleResult(request);
  setRecommendedSearches(response.recommendations);
  setLoading(false);
};

const addPrecrawlSeeds = async (
  hashtags: string[],
  handles: string[],
  platform: string,
  creatorSetId: number,
) => {
  const request = await createRequestWithFirebaseToken({
    method: "POST",
    url: `${LABELING_DATASETS_URL}add_precrawl_seeds/`,
    body: JSON.stringify({
      hashtags,
      handles,
      platform,
      creatorSetId,
    }),
  });
  const response = await handleResult(request);
  return response;
};

const RecommendedSearchButton = ({
  requestUser,
  brandId,
  creatorSetId,
  platform,
  campaignParameterId,
  recommendedSeedType,
  setRecommendedSearches,
  setLoading,
}: {
  requestUser: User;
  brandId: number;
  creatorSetId: number;
  platform: string;
  campaignParameterId: number;
  recommendedSeedType: RecommendedSeedType;
  setRecommendedSearches: (recommendedSearchItems: RecommendedSearchItem[]) => void;
  setLoading: (loading: boolean) => void;
}) => (
  <Group>
    <Button
      variant="filled"
      disabled={
        brandId === null ||
        campaignParameterId === null ||
        creatorSetId === null ||
        platform === null ||
        recommendedSeedType === null
      }
      onClick={() => {
        setLoading(true);
        fetchRecommendedSearchItems(
          requestUser,
          brandId,
          creatorSetId,
          platform,
          campaignParameterId,
          recommendedSeedType,
          setRecommendedSearches,
          setLoading,
        );
      }}>
      Get recommended searches
    </Button>
  </Group>
);

const RecommendedSearchResultsItem = ({
  item,
  platform,
  creatorSetId,
}: {
  item: RecommendedSearchItem;
  platform: Platform;
  creatorSetId: number;
}) => {
  const [notRelevant, setNotRelevant] = useState(item.not_relevant_ts !== null);
  const [hidden, setHidden] = useState(item.hidden_ts !== null);
  const [regularCrawlStatus, setRegularCrawlStatus] = useState("Unknown");
  const [deepCrawlStatus, setDeepCrawlStatus] = useState("Unknown");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [retriggerLoading, setRetriggerLoading] = useState(false);

  // eslint-disable-next-line consistent-return
  const getCrawlStatusTextFromStatus = (crawlStatus: any) => {
    if (!crawlStatus?.enqueue_ts) {
      return "Not Enqueued";
    } else if (crawlStatus?.enqueue_ts && !crawlStatus?.start_ts) {
      const enqueueDate = fromISODateString(crawlStatus.enqueue_ts);
      return `Enqueued: ${enqueueDate.toLocaleString()}`;
    } else if (crawlStatus?.start_ts && !crawlStatus?.finish_ts) {
      const startDate = fromISODateString(crawlStatus.start_ts);
      return `In Progress: ${startDate.toLocaleString()}`;
    } else if (crawlStatus?.finish_ts) {
      const finishDate = fromISODateString(crawlStatus.finish_ts);
      return `Finished: ${finishDate.toLocaleString()}`;
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    const seedType = item.seed_type;
    const seed = item.seed_type === "hashtags" ? item.hashtag.hashtag : item.creator.creator;
    if (platform && seed && seedType) {
      getCrawlStatus(seed, seedType, platform, abortController).then((resp) => {
        if (resp?.deep_crawl_status) {
          setDeepCrawlStatus(getCrawlStatusTextFromStatus(resp.deep_crawl_status));
        }
        if (resp?.crawl_status) {
          setRegularCrawlStatus(getCrawlStatusTextFromStatus(resp.crawl_status));
        }
      });
    }
    return () => {
      abortController.abort();
    };
  }, [item, platform]);

  return (
    <Table.Tr>
      <Table.Td>{item.platform}</Table.Td>
      <Table.Td>{item.seed_type}</Table.Td>
      <Table.Td>{item.seed}</Table.Td>
      <Table.Td>{String(item.finished_crawl)}</Table.Td>
      <Table.Td>
        <Group align="flex-end" gap="sm">
          {regularCrawlStatus}
        </Group>
      </Table.Td>
      <Table.Td>
        <Group align="flex-end" gap="sm">
          {deepCrawlStatus}
          {retriggerLoading ? (
            <Loader size="sm" type="dots" />
          ) : (
            <Tooltip label="Re-trigger Crawl">
              <ActionIcon
                variant="subtle"
                component="button"
                size="sm"
                onClick={() => {
                  setRetriggerLoading(true);
                  retriggerCrawl(item.seed, item.seed_type, item.platform)
                    .then((resp) => {
                      // Send notification
                      notifications.show({
                        title: "Re-trigger Crawl",
                        message: resp?.message || "Unknown error",
                        autoClose: false,
                      });
                      // Update data
                      setDeepCrawlStatus(getCrawlStatusTextFromStatus(resp?.crawl_status));
                    })
                    .finally(() => {
                      setRetriggerLoading(false);
                    });
                }}>
                <IconRefresh />
              </ActionIcon>
            </Tooltip>
          )}
        </Group>
      </Table.Td>
      <Table.Td>{item.num_previous_searches}</Table.Td>
      <Table.Td>
        <a href={item.search_link} target="_blank" rel="noreferrer">
          Link
        </a>
      </Table.Td>
      <Table.Td>
        <Checkbox
          checked={notRelevant}
          onChange={() => {
            markNotRelevantPrecrawledSearch(
              item.seed_type === "hashtags" ? item.hashtag.hashtag : item.creator.creator,
              item.seed_type,
              creatorSetId,
              platform,
              !notRelevant,
            );
            setNotRelevant(!notRelevant);
          }}
        />
      </Table.Td>
      <Table.Td>
        <Checkbox
          checked={hidden}
          onChange={() => {
            hidePrecrawledSearch(
              item.seed_type === "hashtags" ? item.hashtag.hashtag : item.creator.creator,
              item.seed_type,
              creatorSetId,
              platform,
              !hidden,
            );
            setHidden(!hidden);
          }}
        />
      </Table.Td>
    </Table.Tr>
  );
};

const RecommendedSearchResults = ({
  recommendedSearches,
  platform,
  creatorSetId,
}: {
  recommendedSearches: RecommendedSearchItem[];
  platform: Platform;
  creatorSetId: number;
}) => {
  // sort recommended searches by relevant and not hidden first
  // then by seed type
  // and finally by size (users)
  recommendedSearches.sort((a, b) => {
    if (a.not_relevant_ts !== null && b.not_relevant_ts === null) {
      return 1;
    }
    if (a.not_relevant_ts === null && b.not_relevant_ts !== null) {
      return -1;
    }
    if (a.hidden_ts !== null && b.hidden_ts === null) {
      return 1;
    }
    if (a.hidden_ts === null && b.hidden_ts !== null) {
      return -1;
    }
    if (a.seed_type <= b.seed_type) {
      return 1;
    }
    if (a.seed_type > b.seed_type) {
      return -1;
    }
    if (a.seed_type === "hashtags") {
      if (a.hashtag.view_count <= b.hashtag.view_count) {
        return 1;
      }
      if (a.hashtag.view_count > b.hashtag.view_count) {
        return -1;
      }
    } else {
      if (a.creator.follower_count <= b.creator.follower_count) {
        return 1;
      }
      if (a.creator.follower_count > b.creator.follower_count) {
        return -1;
      }
    }
    return 0;
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const rows = recommendedSearches.map((item, index) => (
    <RecommendedSearchResultsItem
      key={`${item.seed}`}
      item={item}
      platform={platform}
      creatorSetId={creatorSetId}
    />
  ));

  return (
    <Paper style={{ overflowX: "auto" }}>
      <Table striped highlightOnHover withTableBorder withColumnBorders>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Platform</Table.Th>
            <Table.Th>Seed Type</Table.Th>
            <Table.Th>Seed</Table.Th>
            <Table.Th>Finished crawl</Table.Th>
            <Table.Th>Regular Crawl Status</Table.Th>
            <Table.Th>Deep Crawl Status</Table.Th>
            <Table.Th># of previous searches</Table.Th>
            <Table.Th>Link to search</Table.Th>
            <Table.Th w={50}>Not Relevant</Table.Th>
            <Table.Th w={50}>Hidden</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
      </Table>
    </Paper>
  );
};

const RecommendedSearchView = () => {
  const requestUser: User = useOutletContext<AuthContext>()?.user;
  const [brandData, setBrandData] = useState(null);
  const [brandId, setBrandId] = useState(null);
  const [creatorSetId, setCreatorSetId] = useState(null);
  const [campaignParameterId, setCampaignParameterId] = useState(null);
  const [platform, setPlatform] = useState<Platform>(null);
  const [seedType, setSeedType] = useState<RecommendedSeedType>(null);
  const [recommendedSearches, setRecommendedSearches] = useState<RecommendedSearchItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadedBrandData, setLoadedBrandData] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const [newHashtags, setNewHashtags] = useState<string[]>([]);
  const [newHandles, setNewHandles] = useState<string[]>([]);

  useEffect(() => {
    const abortController = new AbortController();
    fetchAdminBrandData(abortController).then((data) => {
      if (data && data.brands) {
        setBrandData(data.brands);
        setLoadedBrandData(true);
      }
    });

    if (searchParams.get("brandId")) {
      setBrandId(Number(searchParams.get("brandId")));
    }
    if (searchParams.get("creatorSetId")) {
      setCreatorSetId(Number(searchParams.get("creatorSetId")));
    }
    if (searchParams.get("campaignParameterId") !== null) {
      setCampaignParameterId(Number(searchParams.get("campaignParameterId")));
    }
    if (searchParams.get("platform")) {
      setPlatform(searchParams.get("platform") as Platform);
    }
    if (searchParams.get("seedType")) {
      setSeedType(searchParams.get("seedType") as RecommendedSeedType);
    }

    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    if (brandId !== null && brandId !== 0) {
      updatedSearchParams.set("brandId", String(brandId));
    }
    if (creatorSetId !== null && creatorSetId !== 0) {
      updatedSearchParams.set("creatorSetId", String(creatorSetId));
    } else if (creatorSetId === null) {
      updatedSearchParams.delete("creatorSetId");
    }
    if (platform !== null) {
      updatedSearchParams.set("platform", String(platform));
    }
    if (seedType !== null) {
      updatedSearchParams.set("seedType", String(seedType));
    }
    if (campaignParameterId !== null) {
      updatedSearchParams.set("campaignParameterId", String(campaignParameterId));
    } else if (campaignParameterId === null) {
      updatedSearchParams.delete("campaignParameterId");
    }
    setSearchParams(updatedSearchParams.toString());
  }, [brandId, creatorSetId, platform, seedType, campaignParameterId]);

  const LOADING_SCREEN = <Text>Getting recommended searches..</Text>;
  const searchResultTable = (
    <RecommendedSearchResults
      recommendedSearches={recommendedSearches}
      platform={platform}
      creatorSetId={creatorSetId}
    />
  );

  if (!loadedBrandData) {
    return <Loader />;
  }

  return (
    <Stack>
      <Notifications />
      <Group>
        <BrandCreatorSetPlatformFilterSelector
          brandData={brandData}
          brandId={brandId}
          creatorSetId={creatorSetId}
          platform={platform}
          campaignParameterId={campaignParameterId}
          setBrandId={setBrandId}
          setCreatorSetId={setCreatorSetId}
          setPlatform={setPlatform}
          setIsUserActionChange={() => {}}
          setCampaignParameterId={setCampaignParameterId}
        />
        <Select
          id="seed-type"
          value={seedType}
          onChange={(value: RecommendedSeedType) => {
            // Split the string by commas
            if (value === null) {
              return;
            }
            setSeedType(value);
          }}
          label="Seed type"
          // TODO(kevin): Make this dynamic based off the platforms
          data={[
            { label: "precrawled recs", value: "precrawled_search" },
            { label: "general recs", value: "general_recs" },
          ]}
          searchable
        />
      </Group>
      <RecommendedSearchButton
        requestUser={requestUser}
        brandId={brandId}
        creatorSetId={creatorSetId}
        platform={platform}
        campaignParameterId={campaignParameterId}
        recommendedSeedType={seedType}
        setRecommendedSearches={setRecommendedSearches}
        setLoading={setLoading}
        // eslint-disable-next-line prettier/prettier
      />
      <Divider />
      <Stack w={600}>
        <TagsInput
          label="Add new hashtags"
          value={newHashtags}
          onChange={setNewHashtags}
          required
          splitChars={[",", " "]}
        />
        <TagsInput
          label={`Add new handles (${platform})`}
          value={newHandles}
          onChange={setNewHandles}
          required
          splitChars={[",", " "]}
        />
        <Button
          onClick={() => {
            addPrecrawlSeeds(newHashtags, newHandles, platform, creatorSetId).then((resp) => {
              // Should we follow the drf conventions?
              if (resp?.success) {
                setNewHandles([]);
                setNewHashtags([]);
                notifications.show({
                  title: "Success",
                  message: `Successfully enqueued seeds for ${platform}.`,
                  autoClose: false,
                });
              } else {
                notifications.show({
                  color: "red",
                  title: "Failure",
                  message: `Failed to enqueue seeds. ${
                    resp?.result?.message || resp?.text || "Unknown error"
                    // eslint-disable-next-line prettier/prettier
                  }`,
                  autoClose: false,
                });
              }
            });
          }}>
          Crawl Seeds
        </Button>
      </Stack>
      <Divider />
      {loading ? LOADING_SCREEN : searchResultTable}
    </Stack>
  );
  // Display the search summary request results
};

export default RecommendedSearchView;
