import React, { useState, useEffect } from "react";
import { Flex, Heading, Link, Text } from "gestalt";
import "gestalt/dist/gestalt.css";

import { useSession } from "utils/SessionContext";
import LayoutBase from "components/LayoutBase";
import { Campaign } from "models/Campaign";
import { getCampaign } from "components/campaign/CampaignUtils";
import LoadingScreen from "components/LoadingScreen";
import { useParams, useSearchParams } from "react-router-dom";

const getAndSetCampaign = async (campaignId: number, setCampaign: (campaign: Campaign) => void) => {
  try {
    const campaign = await getCampaign(campaignId);
    setCampaign(campaign);
  } catch (ex) {
    console.error(`invalid campaign id: ${campaignId.toString()}`);
  }
};

const OnboardingThanks = () => {
  const [session, setSession] = useSession();
  const [searchParams, setSearchParams] = useSearchParams();
  const waitlist = searchParams.get("waitlist");

  // if a creator isn't applying for a campaign, we simply thank them for signing up.
  if (!session || !session.applyingCampaignId) {
    const header = waitlist && Boolean(waitlist) ? "Waitlist Joined!" : "Profile Submitted!";

    return (
      <LayoutBase>
        <Flex justifyContent="center">
          <Flex direction="column" gap={12} alignItems="center" maxWidth="70%">
            <Heading>{`${header}`} &#127881;</Heading>
            <Text>Thanks for signing up for 1stCollab</Text>
            <Text>
              We&apos;ll get back to you with campaigns that are a good fit. Please feel free to
              reach out to us at{" "}
              <Link href="mailto:hello@1stcollab.com" inline>
                hello@1stcollab.com
              </Link>{" "}
              if you have any questions.
            </Text>
          </Flex>
        </Flex>
      </LayoutBase>
    );
  }

  const [campaign, setCampaign] = useState(null);

  const campaignId = session.applyingCampaignId;

  useEffect(() => {
    const campaignIdNum = parseInt(campaignId, 10);
    getAndSetCampaign(campaignIdNum, setCampaign);
    setSession({ applyingCampaignId: campaignIdNum });
  }, []);

  return campaign ? (
    <LayoutBase>
      <Flex justifyContent="center">
        <Flex direction="column" gap={12} alignItems="center" maxWidth="70%">
          <Heading>Application Received! &#127881;</Heading>
          <Text>{`Thanks for applying to: ${campaign.title}`}</Text>
          <Text>
            We&apos;ll get back to you within a few days on next steps. Please feel free to reach
            out to us at{" "}
            <Link href="mailto:hello@1stcollab.com" inline>
              hello@1stcollab.com
            </Link>{" "}
            if you have any questions.
          </Text>
        </Flex>
      </Flex>
    </LayoutBase>
  ) : (
    <LoadingScreen />
  );
};

export default OnboardingThanks;
