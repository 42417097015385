import React from "react";

import { Badge, Flex, Group, Text, Tooltip } from "@mantine/core";


import { ContentStatus } from "components/contracts/common/Common";
import { ContractDeliverableStatus } from "components/contracts/models/Deliverable";
import DeliverableCardHeader from "components/contracts/common/DeliverableCardHeader";
import SocialHandleButton from "components/contracts/common/SocialHandleButton";
import { timeAgo, toLongDateString } from "utils/DateUtils";
import ReviewStatusBadge from "components/contracts/common/ReviewStatusBadge";
import UsageRightsIndicator from "components/contracts/common/UsageRightsIndicator";

import { SupportedFormat, SupportedPlatform } from "models/Common";

export default function ContentReviewCardHeader({
  platform,
  format,
  creatorHandle,
  profileLink,
  numContentVersions,
  submissionDate,
  liveByDate,
  reviewStatus,
  deliverableStatus,
  numUnreadMessages,
  liveContentSubmissionDate,
  usageRightsEndDate,
}: {
  platform: SupportedPlatform;
  format: SupportedFormat;
  creatorHandle: string;
  profileLink: string;
  numContentVersions: number;
  submissionDate: Date;
  liveByDate: Date;
  reviewStatus: ContentStatus;
  deliverableStatus: ContractDeliverableStatus;
  numUnreadMessages: number;
  liveContentSubmissionDate: Date;
  usageRightsEndDate: Date;
}) {
  return (
    <Group justify="space-between">
      <DeliverableCardHeader
        platform={platform}
        format={format}
        subHeading={
          <Text size="sm" c="dimmed">
            {numContentVersions > 0 && `Version #${numContentVersions + 1} - `}
            {submissionDate ? "Submitted by" : "Live on"}
            <SocialHandleButton
              platform={platform}
              handle={creatorHandle}
              profileLink={profileLink}
              showIcon={false}
            />
            {submissionDate
              ? timeAgo(submissionDate)
              : `by ${toLongDateString(new Date(liveByDate))}`}
          </Text>
        }
      />
      <Flex align="center" gap="xs">
        {numUnreadMessages > 0 && (
          <Tooltip
            label={`${numUnreadMessages} Unread Message${numUnreadMessages === 1 ? "" : "s"}`}>
            <Badge variant="dot" color="red">
              {numUnreadMessages}
            </Badge>
          </Tooltip>
        )}
        <ReviewStatusBadge
          status={reviewStatus}
          deliverableStatus={deliverableStatus}
          liveContentSubmissionDate={liveContentSubmissionDate}
          withTimelineContext
        />
        <UsageRightsIndicator usageRightsEndDate={usageRightsEndDate} />
      </Flex>
    </Group>
  );
}
