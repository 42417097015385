import React from "react";

import { Anchor, Avatar, Flex, Text } from "@mantine/core";
import { SEMI_BOLD_FONT_WEIGHT } from "components/contracts/dashboard/Constants";
import { useUser } from "utils/UserContext";

export default function CreatorAvatar({
  displayName,
  avatarUrl,
  contractAmount,
  creatorId,
  hasLink = false,
  onClick,
}: {
  displayName: string;
  avatarUrl: string;
  contractAmount?: number;
  creatorId?: string;
  hasLink?: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}) {
  const [userProfile, userProfileLoading] = useUser();
  const isStaff = userProfile?.is_staff;

  return (
    <Flex align="center" wrap="nowrap" gap="sm">
      {isStaff && creatorId && hasLink ? (
        <Avatar
          component="a"
          href={`/ops_home/creator?creatorId=${creatorId}`}
          src={avatarUrl}
          size="md"
          radius="xl"
          onClick={onClick}
        />
      ) : (
        <Avatar src={avatarUrl} size="md" radius="xl" onClick={onClick} />
      )}
      <Text span truncate fw={SEMI_BOLD_FONT_WEIGHT}>
        {isStaff && creatorId && hasLink ? (
          <Anchor href={`/ops_home/creator?creatorId=${creatorId}`} underline="hover" c="black">
            {displayName}
          </Anchor>
        ) : (
          displayName
        )}
        {contractAmount && (
          <Text size="xs" c="dimmed">
            {(contractAmount / 100).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
          </Text>
        )}
      </Text>
    </Flex>
  );
}
