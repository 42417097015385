import React, { useState } from "react";

import { Anchor, Button, Group, List, Modal, Stack, Text, TextInput } from "@mantine/core";

import { useDisclosure } from "@mantine/hooks";

import { addReferralLinkToDeliverable } from "components/contracts/common/Api";
import { showFailureNotification } from "components/common/Notifications";

import { SUPPORTED_FORMATS_TO_LABELS } from "components/contracts/common/Common";

import { SupportedFormat } from "models/Common";

function isValidUrl(url: string) {
  const regex = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
  return regex.test(url);
}

export default function AddReferralLinkModals({
  deliverableId,
  format,
  opened,
  open,
  close,
  handleRefetchDeliverable,
}: {
  deliverableId: string;
  format: SupportedFormat;
  opened: boolean;
  open: () => void;
  close: () => void;
  handleRefetchDeliverable: (deliverableId: string, handleFetched: () => void) => void;
}) {
  const [url, setUrl] = useState<string>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [confirmOpened, { open: openConfirm, close: closeConfirm }] = useDisclosure();

  const goBack = () => {
    closeConfirm();
    open();
  };

  const continueToConfirm = () => {
    if (url && isValidUrl(url)) {
      close();
      openConfirm();
    }
  };

  const handleAddReferralLink = () => {
    setLoading(true);
    addReferralLinkToDeliverable({ deliverableId, url })
      .then(() => {
        handleRefetchDeliverable(deliverableId, () => {
          setLoading(false);
          closeConfirm();
        });
      })
      .catch((error) => {
        setLoading(false);
        showFailureNotification(error);
      });
  };

  return (
    <>
      <Modal
        opened={confirmOpened}
        onClose={closeConfirm}
        size="md"
        title={<Text fw="600">Confirm Referral Link</Text>}>
        <Stack gap="xs">
          <Text>
            Are you sure you want to add the following referral link to{" "}
            <Text span fw="500">
              {SUPPORTED_FORMATS_TO_LABELS[format]}
            </Text>
            ?
          </Text>
          <List>
            <List.Item>
              <Anchor href={url} target="_blank">
                {url}
              </Anchor>
            </List.Item>
          </List>
          <Group grow mt="xs">
            <Button onClick={goBack} variant="default" color="gray">
              Back
            </Button>
            <Button loading={loading} onClick={handleAddReferralLink}>
              Submit
            </Button>
          </Group>
        </Stack>
      </Modal>
      <Modal
        opened={opened}
        onClose={close}
        size="md"
        title={<Text fw="600">Add Referral Link</Text>}>
        <Stack gap="xs">
          <Text>
            Add a referral link to{" "}
            <Text span fw="500">
              {SUPPORTED_FORMATS_TO_LABELS[format]}
            </Text>
            :
          </Text>
          <TextInput
            required
            placeholder="https://www.example.com"
            value={url}
            onChange={(event) => setUrl(event.currentTarget.value)}
            error={url && !isValidUrl(url) ? "Please enter a valid URL." : null}
          />
          <Group grow mt="xs">
            <Button onClick={close} variant="default" color="gray">
              Cancel
            </Button>
            <Button disabled={!url || !isValidUrl(url)} onClick={continueToConfirm}>
              Continue
            </Button>
          </Group>
        </Stack>
      </Modal>
    </>
  );
}
