import { API_URL, TIKTOK_REDIRECT_HOST } from "configs/Configs";
import { getAuth } from "firebase/auth";
import { AuthenticationState, SetAuthState } from "onboarding/OnboardingUtils";
import React from "react";

import { SOCIAL_API_BASE } from "social/ApiUtils";
import { createRequest, createRequestWithFirebaseToken, handleResult } from "utils/ApiUtils";

export const TIKTOK_PROFILE_URL = `${SOCIAL_API_BASE}/tiktok_profile/`;
const TIKTOK_AUTHORIZATION_URL = "https://www.tiktok.com/v2/auth/authorize/";
const TIKTOK_CALLBACK_URL = `${API_URL}/authentication/tiktok/`;
const TIKTOK_REDIRECT_URL = `${TIKTOK_REDIRECT_HOST}/authentication/tiktok/`;
const TIKTOK_CLIENT_KEY = "awk1ycgmsg7r9q33";
const TIKTOK_SCOPES = ["user.info.basic", "video.list"].join(",");

export interface TikTokProfileResponse {
  socialUsername?: string;
  user?: string;
  url?: string;
  bio_description?: string;
  avatar_url?: string;
  last_updated?: Date;
  date_created?: Date;
  profile_deep_link?: string;
  follower_count?: number;
  likes_count?: number;
}

export const getTiktokProfileInsights = async (tiktokUsername: string, firebaseToken: string) => {
  const requestUrl = new URL(`${TIKTOK_PROFILE_URL}${tiktokUsername}/profile_stats/`);
  const request = createRequest({ url: requestUrl.toString(), firebaseToken });
  const response = await handleResult(request);
  return response;
};

export const getTiktokLoginStatus = async (): Promise<AuthenticationState> => {
  const request = await createRequestWithFirebaseToken({ url: TIKTOK_CALLBACK_URL });
  const response = await handleResult(request);
  return response.auth_status;
};

export const connectTikTok = async (): Promise<Window> => {
  const width = 500;
  const height = 700;
  const left = window.screenX + (window.outerWidth - width) / 2;
  const top = window.screenY + (window.outerHeight - height) / 2.5;
  const title = `Tiktok Auth`;

  const firebaseToken = await getAuth().currentUser?.getIdToken();
  const requestUrl = new URL(TIKTOK_AUTHORIZATION_URL);
  requestUrl.searchParams.append("client_key", TIKTOK_CLIENT_KEY);
  requestUrl.searchParams.append("scope", TIKTOK_SCOPES);
  requestUrl.searchParams.append("response_type", "code");
  requestUrl.searchParams.append("redirect_uri", TIKTOK_REDIRECT_URL);
  requestUrl.searchParams.append("state", firebaseToken);

  const popup: Window = window.open(
    requestUrl,
    title,
    `width=${width},height=${height},left=${left},top=${top}`,
  );
  return popup;
};

export const logoutTikTok = async (): Promise<void> => {
  // TODO logout
  const request = await createRequestWithFirebaseToken({
    url: TIKTOK_CALLBACK_URL,
    method: "DELETE",
  });
  const response = await handleResult(request);
  return response;
};
