import React, { useState } from "react";
import { Anchor, Flex, List, Text } from "@mantine/core";
import { CampaignToDoSummary, AdGroupToDoSummary, CreatorReviewToDoCount } from "models/Campaign";
import { BasicTodoWithDetails } from "campaigns/main/campaignPage/BasicTodo";
import { useNavigate } from "react-router-dom";
import TodoListItem from "campaigns/main/campaignPage/TodoListItem";

const PLATFORM_TO_TEXT = new Map<string, string>([
  ["youtube", "YouTube"],
  ["instagram", "Instagram"],
  ["tiktok", "TikTok"],
]);

const CreatorReviewToDos = ({
  campaignId,
  creatorReviewToDoCount,
}: {
  campaignId: number;
  creatorReviewToDoCount: CreatorReviewToDoCount;
}) => {
  if (!creatorReviewToDoCount) {
    return null;
  }
  const count = creatorReviewToDoCount.total;
  if (count === 0) {
    return null;
  }

  const navigate = useNavigate();
  const [showDetails, setShowDetails] = useState<boolean>(false);

  let creatorTodoText = "";
  if (count === 1) {
    creatorTodoText = "creator";
  } else {
    creatorTodoText = "creators";
  }

  const ctaText = "View Creators";

  return (
    <>
      <BasicTodoWithDetails
        title="Creator Review"
        description={`${count} ${creatorTodoText} to review for your campaign.`}
        showDetails={showDetails}
        onShowDetailsToggled={(newValue) => setShowDetails(newValue)}
      />
      {showDetails ? (
        <List withPadding>
          <TodoListItem
            key={`${campaignId}-todo-creators`}
            title={`You have ${count} creators to review.`}
            onClick={() => navigate(`/campaigns/${campaignId}/creators/`)}
            ctaText={ctaText}
          />
        </List>
      ) : null}
    </>
  );
};

const CampaignAdGroupToDoDetails = ({
  campaignId,
  adGroupId,
  adGroupToDoSummary,
}: {
  campaignId: number;
  adGroupId: number;
  adGroupToDoSummary: AdGroupToDoSummary;
}) => {
  const navigate = useNavigate();

  const videoCount = adGroupToDoSummary.video_reviews;
  const referralLinkCount = adGroupToDoSummary.referral_links;
  const promoCodeCount = adGroupToDoSummary.promo_codes;
  const scriptCount = adGroupToDoSummary.script_reviews;
  const unreadCount = adGroupToDoSummary.unread_messages;
  const liveVerificationCount = adGroupToDoSummary.live_verification;
  const contractReviewCount = adGroupToDoSummary.contract_reviews;

  const generateCreativeTodoLabel = (scriptTag: string, count: number) =>
    `You have ${count} ${scriptTag} to review.`;
  const generateReferralLinkLabel = (count: number) => {
    const deliverableText = count > 1 ? "deliverables" : "deliverable";
    const linkText = count > 1 ? "referral links" : "a referral link";
    return `You have ${count} ${deliverableText} that ${
      count > 1 ? "require" : "requires"
    } ${linkText}.`;
  };

  const generateDeliverableToDoLabel = (
    pluralItems: string,
    singularItem: string,
    count: number,
  ) => {
    const deliverableText = count > 1 ? "deliverables" : "deliverable";
    const linkText = count > 1 ? pluralItems : singularItem;
    return `You have ${count} ${deliverableText} that require ${linkText}.`;
  };

  const generateNavLink = () => `/campaigns/${campaignId}/${adGroupId}/contracts/`;
  const contractReviewLink = () => `/campaigns/${campaignId}/${adGroupId}/contract_approval/`;

  const ctaText = "Review Content";

  // TODO (victoria): FEELS REPETITIVE
  return (
    <List withPadding>
      {contractReviewCount > 0 ? (
        <TodoListItem
          key={`${campaignId}-${adGroupId}-todo-details-contract-reviews`}
          title={generateCreativeTodoLabel(
            contractReviewCount > 1 ? "contract reviews" : "contract review",
            contractReviewCount,
          )}
          onClick={() => navigate(contractReviewLink())}
          ctaText={contractReviewCount > 1 ? "Review Contracts" : "Review Contract"}
        />
      ) : null}
      {liveVerificationCount > 0 ? (
        <TodoListItem
          key={`${campaignId}-${adGroupId}-todo-details-live-verification`}
          title={generateCreativeTodoLabel(
            liveVerificationCount > 1 ? "live videos" : "live video",
            liveVerificationCount,
          )}
          onClick={() => navigate(generateNavLink())}
          ctaText={ctaText}
        />
      ) : null}
      {referralLinkCount > 0 ? (
        <TodoListItem
          key={`${campaignId}-${adGroupId}-todo-details-referral-links`}
          title={generateDeliverableToDoLabel(
            "referral links",
            "a referral link",
            referralLinkCount,
          )}
          onClick={() => navigate(generateNavLink())}
          ctaText={ctaText}
        />
      ) : null}
      {promoCodeCount > 0 ? (
        <TodoListItem
          key={`${campaignId}-${adGroupId}-todo-details-promo-codes`}
          title={generateDeliverableToDoLabel("promo codes", "a promo code", promoCodeCount)}
          onClick={() => navigate(generateNavLink())}
          ctaText={ctaText}
        />
      ) : null}
      {videoCount > 0 ? (
        <TodoListItem
          key={`${campaignId}-${adGroupId}-todo-details-videos`}
          title={generateCreativeTodoLabel(
            videoCount > 1 ? "video drafts" : "video draft",
            videoCount,
          )}
          onClick={() => navigate(generateNavLink())}
          ctaText={ctaText}
        />
      ) : null}
      {scriptCount > 0 ? (
        <TodoListItem
          key={`${campaignId}-${adGroupId}-todo-details-scripts`}
          title={generateCreativeTodoLabel(scriptCount > 1 ? "scripts" : "script", scriptCount)}
          onClick={() => navigate(generateNavLink())}
          ctaText={ctaText}
        />
      ) : null}
      {unreadCount > 0 ? (
        <TodoListItem
          key={`${campaignId}-${adGroupId}-todo-details-messages`}
          title={generateCreativeTodoLabel(
            unreadCount > 1 ? "unread messages" : "unread message",
            unreadCount,
          )}
          onClick={() => navigate(generateNavLink())}
          ctaText={ctaText}
        />
      ) : null}
    </List>
  );
};

const CampaignToDoDetails = ({
  campaignId,
  adGroupId,
  adGroupTodos,
}: {
  campaignId: number;
  adGroupId: number;
  adGroupTodos: AdGroupToDoSummary;
}) => {
  const numTodos = adGroupTodos.total;
  const adGroupName = adGroupTodos.ad_group_name;
  if (numTodos === 0) {
    return null;
  }
  const [showDetails, setShowDetails] = useState<boolean>(false);
  return (
    <div key={`${campaignId}-${adGroupId}-todo-list`}>
      <BasicTodoWithDetails
        key={`${adGroupId}-todo`}
        title={`${adGroupName}`}
        description={`You have ${numTodos} ${numTodos === 1 ? "item" : "items"} that ${
          numTodos === 1 ? "requires" : "require"
        } your attention.`}
        showDetails={showDetails}
        onShowDetailsToggled={(newValue) => setShowDetails(newValue)}
      />
      {showDetails ? (
        <CampaignAdGroupToDoDetails
          campaignId={campaignId}
          adGroupId={adGroupId}
          adGroupToDoSummary={adGroupTodos}
        />
      ) : null}
    </div>
  );
};

const CampaignToDos = ({
  campaignId,
  toDoSummary,
}: {
  campaignId: number;
  toDoSummary: CampaignToDoSummary;
}) => (
  <>
    {Object.keys(toDoSummary.ad_groups).map((key) => {
      const adGroupId = parseInt(key, 10);
      const adGroupTodos = toDoSummary.ad_groups[adGroupId];
      return (
        <CampaignToDoDetails
          key={`${campaignId}-${adGroupId}-todo-list`}
          campaignId={campaignId}
          adGroupId={adGroupId}
          adGroupTodos={adGroupTodos}
        />
      );
    })}
  </>
);

export default CampaignToDos;
