import { PayloadAction, createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import {
  CREATOR_SETS_URL,
  CreatorSetEntriesResponse,
  fetchCreatorSetItems,
} from "components/creator_sets/CreatorSetUtils";
import { fetchAllCreatorSetEntryItems } from "components/creator_sets/api/CreatorSetsApi";
import { CreatorSetEntryItem, CreatorSetItem } from "components/discovery/Datamodels";
import _ from "lodash";
import { createRequestWithFirebaseToken, handleResult } from "utils/ApiUtils";

export interface CreatorSetsState {
  allActivatedEntryItemsIdList: number[]; // maps to list returned by `${CREATOR_SETS_URL}get_all_creator_set_entries/` and onlyActivated = true
  allEntryItemsIdList: number[]; // maps to list returned by `${CREATOR_SETS_URL}get_all_creator_set_review_entries/`
  creatorSetItems: { [id: number]: CreatorSetItem }; // map of creator set id to creator set
  creatorSetIdToEntryItemIds: { [id: number]: number[] };
  entryItems: { [id: number]: CreatorSetEntryItem };
}

const initialState: CreatorSetsState = {
  allActivatedEntryItemsIdList: [],
  allEntryItemsIdList: [],
  creatorSetItems: {},
  creatorSetIdToEntryItemIds: {},
  entryItems: {},
};

interface GetCreatorSetItemsPayloadAction {
  aborted: boolean;
  creatorSetItems: CreatorSetItem[];
}

export const getCreatorSetItems = createAsyncThunk(
  "creatorSetItems/fetch",
  async (abortController: AbortController): Promise<GetCreatorSetItemsPayloadAction> => {
    const response = await fetchCreatorSetItems(abortController);
    return {
      aborted: abortController.signal.aborted,
      creatorSetItems: response ?? [],
    };
  },
);

interface GetCreatorSetEntriesPayloadAction {
  aborted: boolean;
  creatorSetEntryItems: CreatorSetEntryItem[];
}

export const getEntriesForCreatorSet = createAsyncThunk(
  "creatorsForCreatorSetId/fetch",
  async ({
    creatorSetId,
    abortController,
  }: {
    creatorSetId: number;
    abortController: AbortController;
  }): Promise<GetCreatorSetEntriesPayloadAction> => {
    const url = new URL(`${CREATOR_SETS_URL}get_creator_set_review_entries/`);
    const params = {
      creatorSetId: String(creatorSetId),
    };
    url.search = new URLSearchParams(params).toString();
    const request = await createRequestWithFirebaseToken({
      url: url.toString(),
    });

    const response: CreatorSetEntriesResponse = await handleResult(request, abortController);

    return {
      aborted: abortController.signal.aborted,
      creatorSetEntryItems: response?.creator_set_entries ?? [],
    };
  },
);

export const getAllCreatorSetReviewEntries = createAsyncThunk(
  "allCreatorSetReviewEntries/fetch",
  async (abortController: AbortController): Promise<GetCreatorSetEntriesPayloadAction> => {
    const url = new URL(`${CREATOR_SETS_URL}get_all_creator_set_review_entries/`);
    const request = await createRequestWithFirebaseToken({ url });

    const response: CreatorSetEntriesResponse = await handleResult(request, abortController);
    return {
      aborted: abortController.signal.aborted,
      creatorSetEntryItems: response?.creator_set_entries ?? [],
    };
  },
);

export const getAllActivatedEntries = createAsyncThunk(
  "allActivatedEntries/fetch",
  async (abortController: AbortController): Promise<GetCreatorSetEntriesPayloadAction> => {
    const response = await fetchAllCreatorSetEntryItems(abortController, true);
    return {
      aborted: abortController.signal.aborted,
      creatorSetEntryItems: response ?? [],
    };
  },
);

interface UpdateEntriesForCreatorSetIdPayloadAction {
  creatorSetId: number;
  entries: CreatorSetEntryItem[];
}

interface UpdateCreatorSetNameAndDescriptionPayloadAction {
  creatorSetId: number;
  name: string;
  description: string;
}

interface AddNewCreatorSetPayloadAction {
  creatorSet: CreatorSetItem;
}

const updateStateWithEntryItemsForCreatorSetId = (
  state: CreatorSetsState,
  creatorSetId: number,
  entries: CreatorSetEntryItem[],
) => {
  const entryItemIds: number[] = [];
  const { entryItems, creatorSetIdToEntryItemIds } = state;
  entries.forEach((entry: CreatorSetEntryItem) => {
    entryItemIds.push(entry.id);
    entryItems[entry.id] = entry;
  });
  creatorSetIdToEntryItemIds[creatorSetId] = entryItemIds;
};

/* eslint-disable no-param-reassign */
const creatorSetsSlice = createSlice({
  name: "creatorSets",
  initialState,
  reducers: {
    updateEntriesForCreatorSetId: (
      state,
      action: PayloadAction<UpdateEntriesForCreatorSetIdPayloadAction>,
    ) => {
      updateStateWithEntryItemsForCreatorSetId(
        state,
        action.payload.creatorSetId,
        action.payload.entries,
      );
    },
    updateCreatorSetNameAndDescription: (
      state: CreatorSetsState,
      action: PayloadAction<UpdateCreatorSetNameAndDescriptionPayloadAction>,
    ) => {
      const { creatorSetId, name, description } = action.payload;
      const creatorSetItem = state.creatorSetItems[creatorSetId];

      if (creatorSetItem) {
        creatorSetItem.name = name;
        creatorSetItem.description = description;
      }
    },
    addNewCreatorSetToState: (state, action: PayloadAction<AddNewCreatorSetPayloadAction>) => {
      const { creatorSet } = action.payload;
      state.creatorSetItems[creatorSet.id] = creatorSet;
    },
  },
  extraReducers(builder) {
    builder.addCase("me/logout", (state) => {
      return initialState;
    });
    builder.addCase(getCreatorSetItems.fulfilled, (state, { payload }) => {
      if (!payload.aborted) {
        const newCreatorSets = Object.fromEntries(
          payload.creatorSetItems.map((creatorSetItem) => [creatorSetItem.id, creatorSetItem]),
        );
        const existingCreatorSets = state.creatorSetItems;
        state.creatorSetItems = { ...existingCreatorSets, ...newCreatorSets };
      }
    });
    builder.addCase(getEntriesForCreatorSet.fulfilled, (state, { meta, payload }) => {
      if (!payload.aborted) {
        updateStateWithEntryItemsForCreatorSetId(
          state,
          meta.arg.creatorSetId,
          payload.creatorSetEntryItems,
        );
      }
    });
    builder.addCase(getAllCreatorSetReviewEntries.fulfilled, (state, { payload }) => {
      if (!payload.aborted) {
        const listOfEntryItems = payload.creatorSetEntryItems.map(({ id }) => id);
        state.allEntryItemsIdList = listOfEntryItems;
        const creatorSetIdsToEntries = _.groupBy(
          payload.creatorSetEntryItems,
          (entryItem) => entryItem.creator_set_id,
        );
        Object.entries(creatorSetIdsToEntries).forEach((entry: [string, CreatorSetEntryItem[]]) => {
          updateStateWithEntryItemsForCreatorSetId(state, Number(entry[0]), entry[1]);
        });
      }
    });
    builder.addCase(getAllActivatedEntries.fulfilled, (state, { payload }) => {
      if (!payload.aborted) {
        const allActivatedEntriesList: number[] = [];
        payload.creatorSetEntryItems.forEach((entry) => {
          allActivatedEntriesList.push(entry.id);
          state.entryItems[entry.id] = entry;
        });
        state.allActivatedEntryItemsIdList = allActivatedEntriesList;
      }
    });
  },
});
/* eslint-enable no-param-reassign */

export const {
  addNewCreatorSetToState,
  updateEntriesForCreatorSetId,
  updateCreatorSetNameAndDescription,
} = creatorSetsSlice.actions;
export default creatorSetsSlice.reducer;
