import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
  ActionIcon,
  Box,
  Divider,
  Flex,
  LoadingOverlay,
  MultiSelect,
  Select,
  Stack,
  Tooltip,
} from "@mantine/core";

import { IconArrowDown, IconArrowUp, IconFilter, IconFilterOff } from "@tabler/icons-react";

import LiveContentModel from "components/contracts/models/LiveContentModel";
import { SupportedPlatform } from "models/Common";
import { INTEGRATED_FORMATS, SHORT_FORM_FORMATS } from "components/contracts/common/Common";

import ContentCard from "components/contracts/content/ContentCard";
import LoadingError from "components/contracts/common/LoadingError";

import MasonryLayout from "components/contracts/content/MasonryLayout";
import { fetchContentLibrary, fetchContentLibraryForUser } from "components/contracts/common/Api";

enum SortBy {
  PUBLISHED_AT = "published_at",
  VIEWS = "views",
  LIKES = "likes",
  USAGE_RIGHTS_EXPIRATION_DATE = "usage_rights_expiration_date",
}

enum FilterBy {
  // Platform Filters
  PLATFORM_INSTAGRAM = "Instagram",
  PLATFORM_TIKTOK = "TikTok",
  PLATFORM_YOUTUBE = "YouTube",

  // Format Filters
  FORMAT_SHORT_FORM = "Short-Form",
  FORMAT_LONG_FORM = "Long-Form",

  // Partnership Type
  PARTNERSHIP_INTEGRATION = "Integration",
  PARTNERSHIP_DEDICATED = "Dedicated",

  // Usage Rights Filters
  USAGE_RIGHTS_EXPIRED = "Usage Rights Expired",
  USAGE_RIGHTS_ACTIVE = "Usage Rights Active",
  USAGE_RIGHTS_NONE = "No Usage Rights",
}

enum FilterGroup {
  PLATFORM = "Platform",
  FORMAT = "Format",
  TYPE = "Type",
  USAGE_RIGHTS = "Usage Rights",
}

const FILTER_TO_GROUP = {
  [FilterBy.PLATFORM_INSTAGRAM]: FilterGroup.PLATFORM,
  [FilterBy.PLATFORM_TIKTOK]: FilterGroup.PLATFORM,
  [FilterBy.PLATFORM_YOUTUBE]: FilterGroup.PLATFORM,
  [FilterBy.FORMAT_SHORT_FORM]: FilterGroup.FORMAT,
  [FilterBy.FORMAT_LONG_FORM]: FilterGroup.FORMAT,
  [FilterBy.PARTNERSHIP_INTEGRATION]: FilterGroup.TYPE,
  [FilterBy.PARTNERSHIP_DEDICATED]: FilterGroup.TYPE,
  [FilterBy.USAGE_RIGHTS_EXPIRED]: FilterGroup.USAGE_RIGHTS,
  [FilterBy.USAGE_RIGHTS_ACTIVE]: FilterGroup.USAGE_RIGHTS,
  [FilterBy.USAGE_RIGHTS_NONE]: FilterGroup.USAGE_RIGHTS,
};

function getSortFn(sortBy: SortBy, sortOrder: "asc" | "desc") {
  return (a: LiveContentModel, b: LiveContentModel) => {
    // Handle null values for usage rights expiration date. Sort to the top when
    // sorting in ascending order.
    if (sortBy === SortBy.USAGE_RIGHTS_EXPIRATION_DATE) {
      if (sortOrder === "asc") {
        if (a[sortBy] === null) {
          return -1;
        } else if (b[sortBy] === null) {
          return 1;
        }
      }

      if (a[sortBy] === null) {
        return 1;
      } else if (b[sortBy] === null) {
        return -1;
      }
    }

    if (sortOrder === "asc") {
      return a[sortBy] > b[sortBy] ? 1 : -1;
    }

    return a[sortBy] < b[sortBy] ? 1 : -1;
  };
}

function getFilterFn(filter: FilterBy) {
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  switch (filter) {
    case FilterBy.PLATFORM_INSTAGRAM:
      return (content: LiveContentModel) => content.platform === SupportedPlatform.INSTAGRAM;
    case FilterBy.PLATFORM_TIKTOK:
      return (content: LiveContentModel) => content.platform === SupportedPlatform.TIKTOK;
    case FilterBy.PLATFORM_YOUTUBE:
      return (content: LiveContentModel) => content.platform === SupportedPlatform.YOUTUBE;
    case FilterBy.FORMAT_SHORT_FORM:
      return (content: LiveContentModel) => SHORT_FORM_FORMATS.includes(content.format);
    case FilterBy.FORMAT_LONG_FORM:
      return (content: LiveContentModel) => !SHORT_FORM_FORMATS.includes(content.format);
    case FilterBy.PARTNERSHIP_INTEGRATION:
      return (content: LiveContentModel) => INTEGRATED_FORMATS.includes(content.format);
    case FilterBy.PARTNERSHIP_DEDICATED:
      return (content: LiveContentModel) => !INTEGRATED_FORMATS.includes(content.format);
    case FilterBy.USAGE_RIGHTS_EXPIRED:
      return (content: LiveContentModel) =>
        content.usage_rights_expiration_date !== null &&
        new Date(content.usage_rights_expiration_date) < currentDate;
    case FilterBy.USAGE_RIGHTS_ACTIVE:
      return (content: LiveContentModel) =>
        new Date(content.usage_rights_expiration_date) >= currentDate;
    case FilterBy.USAGE_RIGHTS_NONE:
      return (content: LiveContentModel) => content.usage_rights_expiration_date === null;
    default:
      return () => true;
  }
}

function SortAndFilter({
  sortBy,
  setSortBy,
  sortOrder,
  setSortOrder,
  filters,
  setFilters,
}: {
  sortBy: SortBy;
  setSortBy: (sortBy: SortBy) => void;
  sortOrder: "asc" | "desc";
  setSortOrder: (sortOrder: "asc" | "desc") => void;
  filters: FilterBy[];
  setFilters: (filters: FilterBy[]) => void;
}) {
  return (
    <Flex gap="xs" mx="xl">
      <Select
        leftSection={
          <Tooltip
            arrowPosition="side"
            label={`${sortOrder === "asc" ? "Ascending" : "Descending"}`}>
            <ActionIcon
              w="sm"
              mx="xs"
              variant="transparent"
              c="gray"
              onClick={() => {
                if (sortOrder === "desc") {
                  setSortOrder("asc");
                } else {
                  setSortOrder("desc");
                }
              }}>
              {sortOrder === "asc" ? (
                <IconArrowUp color="gray" size="1rem" />
              ) : (
                <IconArrowDown color="gray" size="1rem" />
              )}
            </ActionIcon>
          </Tooltip>
        }
        data={[
          { value: SortBy.PUBLISHED_AT, label: "Publish Date" },
          { value: SortBy.VIEWS, label: "Views" },
          { value: SortBy.LIKES, label: "Likes" },
          { value: SortBy.USAGE_RIGHTS_EXPIRATION_DATE, label: "Usage Rights Expiration" },
        ]}
        value={sortBy}
        onChange={setSortBy}
        w={230}
      />
      <MultiSelect
        leftSection={
          filters.length === 0 ? <IconFilterOff size="1rem" /> : <IconFilter size="1rem" />
        }
        placeholder="Add filters..."
        data={[
          {
            group: FilterGroup.PLATFORM.valueOf(),
            items: [
              { value: FilterBy.PLATFORM_INSTAGRAM, label: "Instagram" },
              { value: FilterBy.PLATFORM_TIKTOK, label: "TikTok" },
              { value: FilterBy.PLATFORM_YOUTUBE, label: "YouTube" },
            ],
          },
          {
            group: FilterGroup.FORMAT.valueOf(),
            items: [
              { value: FilterBy.FORMAT_SHORT_FORM, label: "Short-Form" },
              { value: FilterBy.FORMAT_LONG_FORM, label: "Long-Form" },
            ],
          },
          {
            group: FilterGroup.TYPE.valueOf(),
            items: [
              { value: FilterBy.PARTNERSHIP_DEDICATED, label: "Dedicated" },
              { value: FilterBy.PARTNERSHIP_INTEGRATION, label: "Integration" },
            ],
          },
          {
            group: FilterGroup.USAGE_RIGHTS.valueOf(),
            items: [
              { value: FilterBy.USAGE_RIGHTS_ACTIVE, label: "Usage Rights Active" },
              { value: FilterBy.USAGE_RIGHTS_EXPIRED, label: "Usage Rights Expired" },
              { value: FilterBy.USAGE_RIGHTS_NONE, label: "No Usage Rights" },
            ],
          },
        ]}
        value={filters}
        onChange={setFilters}
        clearable
      />
    </Flex>
  );
}

export default function ContentLibrary({
  campaignId,
  showDefaultContent,
}: {
  campaignId?: string;
  showDefaultContent?: boolean;
}) {
  const { campaignHashId } = useParams<{ campaignHashId: string }>();

  if (!campaignHashId && !campaignId && !showDefaultContent) {
    return null;
  }

  const [contentLibraryState, setContentLibraryState] = useState<LiveContentModel[]>([]);
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc");
  const [sortBy, setSortBy] = useState<SortBy>(SortBy.PUBLISHED_AT);

  const [filters, setFilters] = useState<FilterBy[]>([]);

  const handleResponse = (response: any) => {
    const { success, error, liveContent } = response;

    if (success) {
      setContentLibraryState(liveContent);
      setShowError(false);
      setErrorMessage("");
    } else {
      setShowError(true);
      setErrorMessage(error);
    }
  };

  useEffect(() => {
    setLoading(true);
    if (campaignHashId || campaignId) {
      fetchContentLibrary(campaignHashId, campaignId)
        .then(handleResponse)
        .finally(() => setLoading(false));
    } else {
      fetchContentLibraryForUser()
        .then(handleResponse)
        .finally(() => setLoading(false));
    }
  }, [campaignId]);

  // Group LiveContentModel by Contract ID
  const contractIdToContent = new Map<string, LiveContentModel[]>();
  contentLibraryState.forEach((content) => {
    if (contractIdToContent.has(content.contract_id)) {
      contractIdToContent.get(content.contract_id)?.push(content);
    } else {
      contractIdToContent.set(content.contract_id, [content]);
    }
  });

  const cards = contentLibraryState
    .filter((content) => {
      // Group filters.
      const groupedFilters = filters.reduce((acc, filter) => {
        const group = FILTER_TO_GROUP[filter];
        if (!acc[group]) {
          acc[group] = [];
        }
        acc[group].push(filter);
        return acc;
      }, {} as Record<FilterGroup, FilterBy[]>);

      // Apply filters.
      return Object.values(groupedFilters).every((group) =>
        group.some((filter) => getFilterFn(filter)(content)),
      );
    })
    .sort(getSortFn(sortBy, sortOrder))
    .map((content) => (
      <ContentCard
        key={content.deliverable_id}
        contractId={content.contract_id}
        deliverableId={content.deliverable_id}
        contractAmount={content.contract_amount}
        platform={content.platform}
        format={content.format}
        creatorHandle={content.creator_handle}
        profileLink={content.profile_link}
        liveContentUrl={content.url}
        numViews={content.views}
        numClicks={content.clicks}
        numLikes={content.likes}
        avatarUrl={content.avatar_url}
        playableVideoUrl={content.playable_video_url}
        rawVideoDownloadUrl={content.video_download_url}
        adCode={content.ad_code}
        title={content.title}
        caption={content.caption}
        usageRightsEndDate={content.usage_rights_expiration_date}
        publishedAt={content.published_at}
        relatedContent={contractIdToContent.get(content.contract_id)}
        disableClicks
      />
    ));

  return (
    <Box>
      <LoadingOverlay visible={loading} />
      {showError && <LoadingError message={errorMessage} />}
      {!showError && (
        <Stack>
          <SortAndFilter
            sortBy={sortBy}
            setSortBy={setSortBy}
            sortOrder={sortOrder}
            setSortOrder={setSortOrder}
            filters={filters}
            setFilters={setFilters}
          />
          <Divider />
          <MasonryLayout cards={cards} />
        </Stack>
      )}
    </Box>
  );
}
