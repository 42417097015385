import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { updateBrand } from "brand/api/UpdateBrand";
import { acceptTOS } from "campaigns/api/onboarding/acceptTOS";
import { Brand, getBrand } from "models/Brand";
import { Creator } from "models/Creator";
import { User } from "models/User";
import {
  createCreator,
  fetchAuthCreator,
  updateUsername,
  completeOnboarding,
} from "onboarding/v2/api/OnboardingApi";
import { fetchAllCreativeBriefs } from "reduxStore/creativeBriefsSlice";
import { getAllActivatedEntries } from "reduxStore/creatorSetsSlice";
import { getCampaigns } from "reduxStore/campaignsSlice";

export interface MeState {
  user: User | null;
  creatorProfile: Creator | null;
  // NOTE: this is just a hack because brand on user object is actually a number even though we've typed it as a Brand object
  brandId: number | null;
  brand: Brand | null;
}

const initialState: MeState = {
  user: null,
  creatorProfile: null,
  brandId: null,
  brand: null,
};

interface AuthCreatorPayload {
  aborted: boolean;
  creator: Creator | null;
}

export const getAuthCreator = createAsyncThunk(
  "getMeCreator",
  async (abortController: AbortController | null): Promise<AuthCreatorPayload> => {
    const result = await fetchAuthCreator(abortController);
    return {
      aborted: abortController.signal.aborted,
      creator: abortController.signal.aborted ? null : result,
    };
  },
);

interface UpdatedCreatorProfilePayload {
  creator: Creator | null;
  user: User | null;
  usernameError?: string | null;
  creatorError?: string | null;
  aborted: boolean;
}

export const updateCreatorProfile = createAsyncThunk(
  "updateMeCreator",
  async (
    {
      abortController,
      user_key,
      username,
      creator,
    }: {
      abortController: AbortController | null;
      user_key: string;
      username: string;
      creator: Creator;
    },
    { getState },
  ): Promise<UpdatedCreatorProfilePayload> => {
    const { me } = getState() as { me: MeState };
    let updatedUserWithNewUsername: User | null = me.user;

    if (me.user === null) {
      // if no auth user, return early
      return {
        creator: null,
        user: null,
        aborted: abortController.signal.aborted,
      };
    }

    // don't bother updating username if username did not change
    if (me?.user?.username !== username) {
      try {
        updatedUserWithNewUsername = await updateUsername(user_key, username, abortController);
      } catch (error) {
        return {
          usernameError: error.message,
          creator: null,
          user: null,
          aborted: abortController.signal.aborted,
        };
      }
      if (updatedUserWithNewUsername === null) {
        // if updated user is null, just return early
        return {
          creator: null,
          user: null,
          aborted: abortController.signal.aborted,
        };
      }
    }

    let updatedCreator: Creator | null = null;
    try {
      updatedCreator = await createCreator(creator, abortController);
    } catch (error) {
      return {
        creatorError: error.message,
        creator: null,
        user: null,
        aborted: abortController.signal.aborted,
      };
    }
    return {
      creator: updatedCreator,
      user: updatedUserWithNewUsername,
      aborted: abortController.signal.aborted,
    };
  },
);

export const completeUserOnboarding = createAsyncThunk(
  "completeMeOnboarding",
  async (
    {
      abortController,
      user_key,
    }: {
      abortController: AbortController | null;
      user_key: string;
    },
    { getState },
  ) => {
    const { me } = getState() as { me: MeState };
    let updatedUser: User | null = me.user;

    if (me.user === null) {
      // if no auth user, return early
      return {
        user: null,
        aborted: abortController.signal.aborted,
      };
    }

    // don't mark complete if already complete
    if (!me?.user?.completed_onboarding) {
      try {
        updatedUser = await completeOnboarding(user_key, abortController);
      } catch (error) {
        return {
          onboardingError: error.message,
          user: null,
          aborted: abortController.signal.aborted,
        };
      }
      if (updatedUser === null) {
        // if updated user is null, just return early
        return {
          user: null,
          aborted: abortController.signal.aborted,
        };
      }
    }
    return {
      user: updatedUser,
      aborted: abortController.signal.aborted,
    };
  },
);

export interface AuthBrandPayload {
  aborted: boolean;
  brand: Brand | null;
}

export const fetchMyBrand = createAsyncThunk(
  "fetchMyBrand",
  async (
    {
      abortController,
    }: {
      abortController: AbortController | null;
    },
    { getState },
  ): Promise<AuthBrandPayload> => {
    const { me } = getState() as { me: MeState };
    const { brandId } = me;
    let brand: Brand | null = null;

    if (brandId !== null) {
      try {
        brand = await getBrand(brandId, abortController);
      } catch (error) {
        // TODO (victoria 5.2024): DOES THIS NEED TO BE SPECIAL CASED?
      }
    }
    return {
      aborted: abortController?.signal?.aborted ?? false,
      brand,
    };
  },
);

export const updateMyBrand = createAsyncThunk(
  "updateMyBrand",
  async (
    {
      updatedFields,
    }: {
      updatedFields: { [key: string]: any };
    },
    { getState },
  ): Promise<AuthBrandPayload> => {
    const { me } = getState() as { me: MeState };
    const { brandId } = me;
    let brand: Brand | null = null;
    if (brandId !== null) {
      try {
        brand = await updateBrand(brandId, updatedFields);
      } catch (error) {
        // TODO (victoria 5.2024): DOES THIS NEED TO BE SPECIAL CASED?
      }
    }
    return {
      aborted: false,
      brand,
    };
  },
);

export const acceptBrandTOS = createAsyncThunk(
  "acceptTOS",
  async (_, { getState }): Promise<User | null> => {
    const { me } = getState() as { me: MeState };
    let { user } = me;
    if (me.user?.key) {
      try {
        const updatedMe = await acceptTOS(me.user.key);
        user = updatedMe;
      } catch (error) {
        // TODO (victoria 6.2024): HANDLE ERROR?
      }
    }
    return user;
  },
);

export const checkNeedsCampaignOnboarding = createAsyncThunk(
  "checkNeedsCampaignOnboarding",
  async (
    {
      getBrandAbortController,
      briefsAbortController,
      activatedCreatorsAbortController,
      campaignsAbortController,
      shouldFetchCampaigns = false,
    }: {
      activatedCreatorsAbortController: AbortController;
      getBrandAbortController: AbortController;
      briefsAbortController: AbortController;
      campaignsAbortController: AbortController;
      shouldFetchCampaigns?: boolean;
    },
    { dispatch },
  ): Promise<boolean> => {
    const brandFetched = new Promise<boolean>((resolve) => {
      dispatch(fetchMyBrand({ abortController: getBrandAbortController }))
        .unwrap()
        .then(({ aborted }) => {
          resolve(aborted);
        });
    });
    const briefsFetched = new Promise<boolean>((resolve) => {
      dispatch(fetchAllCreativeBriefs({ abortController: briefsAbortController }))
        .unwrap()
        .then(({ aborted }) => {
          resolve(aborted);
        });
    });
    const activatedCreatorsFetched = new Promise<boolean>((resolve) => {
      dispatch(getAllActivatedEntries(activatedCreatorsAbortController))
        .unwrap()
        .then(({ aborted }) => {
          resolve(aborted);
        });
    });
    const campaignsFetched = new Promise<boolean>((resolve) => {
      if (shouldFetchCampaigns) {
        dispatch(getCampaigns(campaignsAbortController))
          .unwrap()
          .then(({ aborted }) => {
            resolve(aborted);
          });
      } else {
        resolve(false);
      }
    });
    const requestsNotAborted = Promise.all([
      brandFetched,
      briefsFetched,
      activatedCreatorsFetched,
      campaignsFetched,
    ]).then((values) => {
      // returns whether or not all the requests finished without aborting
      return !values[0] && !values[1] && !values[2] && !values[3];
    });
    return requestsNotAborted;
  },
);

/* eslint-disable no-param-reassign */
const meSlice = createSlice({
  name: "me",
  initialState,
  reducers: {
    updateMe: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
      if (action.payload.brand) {
        if (action.payload.brand.id) {
          state.brandId = action.payload.brand.id;
          state.brand = action.payload.brand;
        } else if (!Number.isNaN(Number(action.payload.brand))) {
          state.brandId = Number(action.payload.brand);
        }
      }
    },
    logout: (state) => {
      state.user = null;
      state.creatorProfile = null;
      state.brandId = null;
      state.brand = null;
    },
  },
  extraReducers(builder) {
    builder.addCase(getAuthCreator.fulfilled, (state, { payload }) => {
      if (!payload.aborted && payload?.creator) {
        state.creatorProfile = payload.creator;
      }
    });
    builder.addCase(updateCreatorProfile.fulfilled, (state, { payload }) => {
      if (!payload.creatorError && !payload.usernameError && !payload.aborted) {
        if (payload.user) {
          state.user = payload.user;
          if (payload.user.brand) {
            if (payload.user.brand.id) {
              state.brandId = payload.user.brand.id;
              state.brand = payload.user.brand;
            } else if (!Number.isNaN(Number(payload.user.brand))) {
              state.brandId = Number(payload.user.brand);
            }
          }
        }
        if (payload.creator) {
          state.creatorProfile = payload.creator;
        }
      }
    });
    builder.addCase(fetchMyBrand.fulfilled, (state, { payload }) => {
      if (!payload.aborted && payload.brand) {
        state.brand = payload.brand;
      }
    });
    builder.addCase(updateMyBrand.fulfilled, (state, { payload }) => {
      if (!payload.aborted && payload.brand) {
        state.brand = payload.brand;
      }
    });
    builder.addCase(acceptBrandTOS.fulfilled, (state, { payload }) => {
      if (payload) {
        state.user = payload;
      }
    });
  },
});
/* eslint-enable no-param-reassign */

export const { updateMe, logout } = meSlice.actions;
export default meSlice.reducer;
