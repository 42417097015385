import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { User } from "firebase/auth";
import { Layer } from "gestalt";
import "gestalt/dist/gestalt.css";

import { auth } from "auth/firebaseAuthHelpers";
import { AuthenticationState, SetAuthState } from "onboarding/OnboardingUtils";
import { processAuthResponse } from "social/fb/FBDataUtils";
import { AuthButton, AuthRowView } from "social/AuthenticationComponents";
import instagramIcon from "social/fb/assets/instagram_icon.svg";
import InstructionModal from "social/fb/InstagramInstructionModal";
import { fbLogout, getLoginStatus } from "social/fb/InstagramUtils";

const FBLoginButton = ({
  setShowInstructions,
}: {
  setShowInstructions: (showInstructions: boolean) => void;
}) => (
  <AuthButton
    icon={instagramIcon}
    text="Connect Instagram"
    onClick={() => setShowInstructions(true)}
  />
);

const LoggedInView = ({ setAuth, user }: { setAuth: SetAuthState; user: User }) => {
  const logout = () => {
    setAuth(AuthenticationState.LOADING);
    fbLogout(() => {
      setAuth(AuthenticationState.NOT_AUTHENTICATED);
    });
  };
  return <AuthButton icon={instagramIcon} text="Disconnect Instagram" onClick={() => logout()} />;
};

const FacebookRow = () => {
  const [user, loading] = useAuthState(auth);
  const [authStep, setAuth] = useState(AuthenticationState.LOADING);
  const [showInstructions, setShowInstructions] = useState(false);

  useEffect(() => {
    if (authStep === AuthenticationState.LOADING) {
      getLoginStatus(
        () => {
          setAuth(AuthenticationState.AUTHENTICATED);
        },
        () => {
          setAuth(AuthenticationState.NOT_AUTHENTICATED);
        },
      );
    }
  }, [loading]);

  return (
    <>
      <AuthRowView
        platform="Instagram"
        authStep={authStep}
        NotAuthView={<FBLoginButton setShowInstructions={setShowInstructions} />}
        AuthView={<LoggedInView setAuth={setAuth} user={user} />}
      />
      {showInstructions && (
        <Layer>
          <InstructionModal
            setAuth={setAuth}
            setShowInstructions={setShowInstructions}
          />
        </Layer>
      )}
    </>
  );
};

export default FacebookRow;
