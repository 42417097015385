import React, { useEffect, useState } from "react";
import { Table } from "gestalt";
import "gestalt/dist/gestalt.css";
import { User } from "firebase/auth";
import moment from "moment-timezone";

import { createRequest, handleResult } from "utils/ApiUtils";
import LoadingScreen from "components/LoadingScreen";
import { API_URL } from "configs/Configs";
import { getAdminUser } from "admin/AdminLayout";

type RelatedCreatorsLogEntry = {
  user: string;
  query: string;
  num_results: number;
  date_created: Date;
  num_hop_neighbors: number;
  is_new_creator: boolean;
  trigger_backfill: boolean;
  could_parse_query: boolean;
};

const fetchRelated = async (
  user: User,
  setRelatedLogs: (relatedLogs: RelatedCreatorsLogEntry[]) => void,
) => {
  const firebaseToken = await user.getIdToken();
  const request = createRequest({
    url: `${API_URL}/analytics/related_creators_logs/`,
    firebaseToken,
  });
  const response = await handleResult(request);
  const relatedLogs: RelatedCreatorsLogEntry[] = response.results;
  setRelatedLogs(relatedLogs);
};

const RelatedCreatorsBackend = () => {
  const user: User = getAdminUser();
  const [relatedLogs, setRelatedLogs] = useState(null);

  useEffect(() => {
    fetchRelated(user, setRelatedLogs);
  }, []);

  const tableBody = relatedLogs?.map((logEntry: RelatedCreatorsLogEntry, index: number) => (
    // eslint-disable-next-line react/no-array-index-key
    <Table.Row key={`row-${index}`}>
      <Table.Cell>{logEntry.user}</Table.Cell>
      <Table.Cell>{logEntry.query}</Table.Cell>
      <Table.Cell>{logEntry.num_results}</Table.Cell>
      <Table.Cell>{logEntry.num_hop_neighbors}</Table.Cell>
      <Table.Cell>{logEntry.is_new_creator.toString()}</Table.Cell>
      <Table.Cell>{logEntry.trigger_backfill.toString()}</Table.Cell>
      <Table.Cell>{logEntry.could_parse_query.toString()}</Table.Cell>
      <Table.Cell>
        {moment(logEntry.date_created).tz("America/Los_Angeles").format("YYYY-MM-DD hh:mm A")}
      </Table.Cell>
    </Table.Row>
  ));

  return relatedLogs ? (
    <Table accessibilityLabel="Related Creators Log Entries">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>User</Table.HeaderCell>
          <Table.HeaderCell>Query</Table.HeaderCell>
          <Table.HeaderCell># Results</Table.HeaderCell>
          <Table.HeaderCell># of Hop neighbors</Table.HeaderCell>
          <Table.HeaderCell>Never seen creator</Table.HeaderCell>
          <Table.HeaderCell>Was a crawl triggered</Table.HeaderCell>
          <Table.HeaderCell>Could parse query</Table.HeaderCell>
          <Table.HeaderCell>Query Time</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>{tableBody}</Table.Body>
    </Table>
  ) : (
    <LoadingScreen />
  );
};

export default RelatedCreatorsBackend;
