import { API_URL } from "configs/Configs";
import { Campaign } from "models/Campaign";
import { createRequestWithFirebaseToken, handleResult } from "utils/ApiUtils";

export const createCampaign = async (brandId: number, creationFields: { [key: string]: any }) => {
  const request = await createRequestWithFirebaseToken({
    url: `${API_URL}/api/models/campaigns/`,
    method: "POST",
    body: JSON.stringify({
      ...creationFields,
      brand_id: brandId,
    }),
  });
  const result = await handleResult(request);
  // TODO (victoria): HANDLE ERROR
  return result;
};

export default null;
