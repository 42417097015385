import React from "react";
import { Box, Spinner } from "gestalt";
import "gestalt/dist/gestalt.css";

const LoadingScreen = () => (
  // TODO: figure out how to center spinner in middle of the screen
  <Box flex="grow" width="100%" minHeight="100%">
    <Spinner show accessibilityLabel="Loading Screen" />
  </Box>
);

export default LoadingScreen;
