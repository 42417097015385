import {
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  signInWithGoogle,
} from "auth/firebaseAuthHelpers";
import { User } from "models/User";
import AuthLoading from "auth/AuthUtils";

// TODO: Make sure to account for all errors here:
// https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#createuserwithemailandpassword

// TODO: Most notably handle the dreaded account-exists-with-different-crediential error:
// https://firebase.google.com/docs/auth/web/google-signin#expandable-1

export enum ErrorStates {
  UNKNOWN = 0,
  NONE = 1,
  USER_NOT_FOUND = 2,
  POPUP_CLOSED = 3,
  WRONG_PASSWORD = 4,
  ACCOUNT_ALREADY_EXISTS = 5,
  INVALID_EMAIL = 6,
  WEAK_PASSWORD = 7,
  POPUP_FAILED_TO_OPEN = 8,
}

export const loginWrapper = async (
  email: string,
  password: string,
  setError: (input: ErrorStates) => void,
  setLoading: (loadState: AuthLoading) => void,
) => {
  try {
    await logInWithEmailAndPassword(email, password);
    setError(ErrorStates.NONE);
    setLoading(AuthLoading.FINISHED);
  } catch (err) {
    setLoading(AuthLoading.NOT_LOADING);
    if (err.name === "FirebaseError") {
      if (err.code === "auth/user-not-found") {
        setError(ErrorStates.USER_NOT_FOUND);
        return;
      }
      if (err.code === "auth/wrong-password") {
        setError(ErrorStates.WRONG_PASSWORD);
        return;
      }
    }
    setError(ErrorStates.UNKNOWN);
  }
};

export const registerWithEmailAndPasswordWrapper = async (
  name: string,
  email: string,
  password: string,
  setError: (errorState: ErrorStates) => void,
  updateUser: (newProps: Partial<User>) => void,
  setLoading: (loadState: AuthLoading) => void,
  onUserRegistrationComplete?: (user: User) => void,
) => {
  try {
    await registerWithEmailAndPassword(
      name,
      email,
      password,
      updateUser,
      onUserRegistrationComplete,
    );
    setError(ErrorStates.NONE);
    setLoading(AuthLoading.FINISHED);
  } catch (err) {
    setLoading(AuthLoading.NOT_LOADING);
    if (err.name === "FirebaseError") {
      if (err.code === "auth/email-already-in-use") {
        // TODO: This might occur if the firebase call succeeds but other service calls fail and user has to try
        // signing up again. Catch this earlier in the flow and instead just retry the other service calls in the
        // registration flow.
        setError(ErrorStates.ACCOUNT_ALREADY_EXISTS);
        return;
      }
      if (err.code === "auth/invalid-email") {
        setError(ErrorStates.INVALID_EMAIL);
        return;
      }
      if (err.code === "auth/weak-password") {
        setError(ErrorStates.WEAK_PASSWORD);
        return;
      }
    }
    setError(ErrorStates.UNKNOWN);
  }
};

export const signInWithGoogleWrapper = async (
  setError: (errorState: ErrorStates) => void,
  setLoading: (loadState: AuthLoading) => void,
) => {
  try {
    await signInWithGoogle();
    setError(ErrorStates.NONE);
    setLoading(AuthLoading.FINISHED);
  } catch (err) {
    setLoading(AuthLoading.NOT_LOADING);
    if (err.name === "FirebaseError" && err.code === "auth/popup-closed-by-user") {
      // TODO: This error takes a long time to send back, so there's a race condition where the user
      // toggles between sign up and log in and this error doesn't appear until after they've togged.
      // So error wlll be displayed even though they haven't done anything. Figure out how to trigger
      // this response more quickly (maybe manually check if popup is closed?).
      setError(ErrorStates.POPUP_CLOSED);
      return;
    }
    setError(ErrorStates.UNKNOWN);
  }
};

export const getErrorMessage = (error: ErrorStates): string | null => {
  switch (error) {
    case ErrorStates.NONE:
      return null;
    case ErrorStates.USER_NOT_FOUND:
      return "Email not found. Try again or signup for an account";
    case ErrorStates.POPUP_CLOSED:
      return "Please complete the Google flow to log in or signup.";
    case ErrorStates.WRONG_PASSWORD:
      return "Incorrect password for this email. Try again or reset your password.";
    case ErrorStates.ACCOUNT_ALREADY_EXISTS:
      return "An account already exists for this email. Please log in with this email.";
    case ErrorStates.INVALID_EMAIL:
      return "Please enter a valid email address.";
    case ErrorStates.WEAK_PASSWORD:
      return "Password needs to be at least 6 characters.";
    case ErrorStates.POPUP_FAILED_TO_OPEN:
      return "Unable to start Google login flow. Please check browser permissions.";
    default:
      return "Sorry an unknown error occurred. Please try refreshing.";
  }
};

const errorProperties = (
  errorState: ErrorStates,
  setShowPasswordReset?: (showReset: boolean) => void,
) => {
  switch (errorState) {
    case ErrorStates.UNKNOWN:
      return {
        iconAccessibilityLabel: "Unknown Error",
        message: "Sorry an unknown error occurred. Please try refreshing.",
      };
    case ErrorStates.USER_NOT_FOUND:
      return {
        iconAccessibilityLabel: "Email not found",
        message: "Email not found. Try again or ",
        helperLink: {
          text: "signup for an account.",
          accessibilityLabel: "Signup",
          href: "/signup",
          onClick: () => {},
        },
      };
    case ErrorStates.POPUP_CLOSED:
      return {
        iconAccessibilityLabel: "Popup Closed",
        message: "Please complete the Google flow to log in or signup.",
      };
    case ErrorStates.WRONG_PASSWORD:
      return {
        iconAccessibilityLabel: "Wrong password",
        message: "Incorrect password for this email. Try again or ",
        helperLink: {
          text: "reset your password.",
          accessibilityLabel: "Reset Password",
          href: "#",
          onClick: () => {
            setShowPasswordReset(true);
          },
        },
      };
    case ErrorStates.ACCOUNT_ALREADY_EXISTS:
      return {
        iconAccessibilityLabel: "Account exists",
        message: "An account already exists for this email. Please ",
        helperLink: {
          text: "log in with this email",
          accessibilityLabel: "Login with email",
          href: "/login",
          onClick: () => {},
        },
      };
    case ErrorStates.INVALID_EMAIL:
      return {
        iconAccessibilityLabel: "Invalid Email",
        message: "Please enter a valid email address.",
      };
    case ErrorStates.WEAK_PASSWORD:
      return {
        iconAccessibilityLabel: "Password is weak",
        message: "Password needs to be at least 6 characters.",
      };
    case ErrorStates.POPUP_FAILED_TO_OPEN:
      return {
        iconAccessibilityLabel: "Popup failed to open",
        message: "Unable to start Google login flow. Please check browser permissions.",
      };
    default:
      throw Error("Undefined Authentication Error State");
  }
};
