import { getAuth, onAuthStateChanged, User as FirebaseUser } from "firebase/auth";
import React, { createContext, ReactNode, useContext, useEffect, useMemo, useState } from "react";

import { User } from "models/User";
import { getSelf } from "utils/UserUtils";
import { logout, updateMe } from "reduxStore/meSlice";
import { useAppDispatch } from "reduxStore/hooks";

const UserContext = createContext([null, null]);
const useUser = () => useContext(UserContext);

const UserProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState(null);
  const dispatch = useAppDispatch();

  // set to false when we have fetched and set the user
  const [isLoading, setLoading] = useState(true);

  const updateUser = (newProps: Partial<User>) => {
    setLoading(true);
    const newUser = { ...user, ...newProps };
    setUser(newUser);
    dispatch(updateMe(newUser));
    setLoading(false);
  };

  const logoutUser = () => {
    // consider always updating the user on the backend as well?
    setLoading(true);
    setUser(null);
    setLoading(false);
    dispatch(logout());
  };

  const userState = useMemo(() => [user, isLoading, updateUser], [user, isLoading]);

  const fetchAndUpdateUser = async (firebaseUser: FirebaseUser) => {
    setLoading(true);
    const firebaseToken = await firebaseUser.getIdToken();
    const userProfile: User = await getSelf(firebaseToken);
    setUser(userProfile);
    setLoading(false);
    dispatch(updateMe(userProfile));
  };

  useEffect(() => {
    onAuthStateChanged(getAuth(), (firebaseUser) => {
      if (firebaseUser && firebaseUser.displayName) {
        fetchAndUpdateUser(firebaseUser);
      } else {
        logoutUser();
      }
    });
  }, []); // only needs to be run once to set up the listeners

  return <UserContext.Provider value={userState}>{children}</UserContext.Provider>;
};

export { useUser, UserProvider };
