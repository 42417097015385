import React from "react";

import { Tooltip } from "@mantine/core";

import { IconBrandInstagram, IconBrandTiktok, IconBrandYoutubeFilled } from "@tabler/icons-react";

import { SupportedPlatform } from "models/Common";

const DEFAULT_SIZE = 22;

export default function PlatformLogo({
  platform,
  size,
  label,
}: {
  platform: SupportedPlatform;
  size?: number;
  label?: string;
}) {
  let icon = null;

  switch (platform) {
    case SupportedPlatform.INSTAGRAM:
      icon = <IconBrandInstagram size={size || DEFAULT_SIZE} color="black" />;
      break;
    case SupportedPlatform.TIKTOK:
      icon = <IconBrandTiktok size={size || DEFAULT_SIZE} color="black" />;
      break;
    case SupportedPlatform.YOUTUBE:
      icon = <IconBrandYoutubeFilled size={size || DEFAULT_SIZE} color="black" />;
      break;
    default:
      return null;
  }

  return (
    <Tooltip label={label} disabled={!label}>
      {icon}
    </Tooltip>
  );
}
