import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createCreativeBriefForBrand,
  fetchCreativeBriefs,
  updateCreativeBriefById,
} from "campaigns/api/fetchCreativeBriefs";
import CreativeBrief from "models/CreativeBrief";

export interface CreativeBriefsState {
  allCreativeBriefs: { [id: number]: CreativeBrief };
  fetchAllOrdered: number[];
}

const initialState: CreativeBriefsState = {
  allCreativeBriefs: {}, // empty
  fetchAllOrdered: [],
};

export const addCreativeBrief = createAsyncThunk(
  "creative_briefs/add",
  async ({
    brandId,
    creationParams,
    abortController,
  }: {
    brandId: number;
    creationParams: { [key: string]: any };
    abortController: AbortController;
  }): Promise<CreativeBrief> => {
    const brief = await createCreativeBriefForBrand(brandId, creationParams, abortController);
    return brief;
  },
);

export const updateCreativeBrief = createAsyncThunk(
  "creative_briefs/edit",
  async ({
    briefId,
    updateParams,
  }: {
    briefId: number;
    updateParams: { [key: string]: any };
  }): Promise<CreativeBrief> => {
    const brief = await updateCreativeBriefById(briefId, updateParams);
    return brief;
  },
);

interface FetchAllBriefsPayloadAction {
  aborted: boolean;
  briefs: CreativeBrief[];
}

export const fetchAllCreativeBriefs = createAsyncThunk(
  "creative_briefs/fetch_all",
  async ({
    abortController,
  }: {
    abortController: AbortController;
  }): Promise<FetchAllBriefsPayloadAction> => {
    const response = await fetchCreativeBriefs(abortController);
    if (response === null) {
      return {
        aborted: abortController.signal.aborted,
        briefs: [],
      };
    }
    return {
      aborted: abortController.signal.aborted,
      briefs: response.results,
    };
  },
);

/* eslint-disable no-param-reassign */
const creativeBriefsSlice = createSlice({
  name: "creativeBriefs",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase("me/logout", (state) => {
      return initialState;
    });
    builder.addCase(addCreativeBrief.fulfilled, (state, { payload }) => {
      if (payload?.id) {
        state.allCreativeBriefs[payload.id] = payload;
      }
    });
    builder.addCase(updateCreativeBrief.fulfilled, (state, { payload }) => {
      if (payload?.id) {
        state.allCreativeBriefs[payload.id] = payload;
      }
    });
    builder.addCase(fetchAllCreativeBriefs.fulfilled, (state, { payload }) => {
      if (!payload.aborted) {
        const fetchedBriefsMap: { [id: number]: CreativeBrief } = {};
        const orderedBriefs: number[] = [];
        payload.briefs.forEach((brief) => {
          fetchedBriefsMap[brief.id] = brief;
          orderedBriefs.push(brief.id);
        });
        const existingBriefs = state.allCreativeBriefs;
        state.allCreativeBriefs = { ...existingBriefs, ...fetchedBriefsMap };
        state.fetchAllOrdered = orderedBriefs;
      }
    });
  },
});
/* eslint-enable no-param-reassign */

export default creativeBriefsSlice.reducer;
