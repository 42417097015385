import React, { createContext, ReactNode, useContext, useMemo, useState } from "react";

import { Creator } from "models/Creator";
import { Paper } from "@mantine/core";

const CreatorContext = createContext([null, null]);

export const useCreator = () => useContext(CreatorContext);

export const CreatorProvider = ({ children }: { children: ReactNode }) => {
  const [creator, setCreator] = useState(null);

  const updateCreator = (newProps: Partial<Creator>) => {
    const newUser = { ...creator, ...newProps };
    setCreator(newUser);
  };

  const creatorState = useMemo(() => [creator, updateCreator], [creator]);

  return (
    <CreatorContext.Provider value={creatorState}>
      <Paper p="xl" radius="lg">
        {children}
      </Paper>
    </CreatorContext.Provider>
  );
};
