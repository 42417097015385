import React, { useEffect, useState } from "react";

import { Card, Center, Loader, Stack, Table, Text } from "@mantine/core";

import { IconZoomQuestion } from "@tabler/icons-react";

import { InternalCardTitle } from "components/creator/InternalCardTitle";
import { CreatorSetEntryState } from "components/discovery/Datamodels";
import {
  CreatorEnrichmentDebugDetails,
  CreatorSetDebugDetails,
  fetchCreatorEnrichmentDebugDetails,
  fetchCreatorSetDebugDetails,
} from "creators/api/creatorDebugApiUtils";

export const CreatorEnrichmentDebug = ({ creatorId }: { creatorId: number }) => {
  const [enrichmentData, setEnrichmentData] = useState<CreatorEnrichmentDebugDetails[]>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const abortController = new AbortController();
    if (creatorId) {
      setLoading(true);
      fetchCreatorEnrichmentDebugDetails(creatorId, abortController)
        .then((response) => {
          if (response?.results?.length > 0) {
            setEnrichmentData(response.results);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [creatorId]);

  if (loading) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  return (
    <Stack>
      <Text size="md" fw={500}>
        Enrichment Debug
      </Text>
      {enrichmentData ? (
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Enricher</Table.Th>
              <Table.Th>First Name</Table.Th>
              <Table.Th>Creator Email</Table.Th>
              <Table.Th>YouTube Handle</Table.Th>
              <Table.Th>TikTok Handle</Table.Th>
              <Table.Th>Instagram Handle</Table.Th>
              <Table.Th>Date Created</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {enrichmentData.map((data) => (
              <Table.Tr key={`enrich-${data.creator_id}-${data.date_created}`}>
                <Table.Td>{data.request_user_email}</Table.Td>
                <Table.Td>{data.first_name}</Table.Td>
                <Table.Td>{data.creator_email}</Table.Td>
                <Table.Td>{data.youtube_handle}</Table.Td>
                <Table.Td>{data.tiktok_handle}</Table.Td>
                <Table.Td>{data.instagram_handle}</Table.Td>
                <Table.Td>{data.date_created}</Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      ) : (
        <Text>No data found</Text>
      )}
    </Stack>
  );
};

export const CreatorSetsDebug = ({ creatorId }: { creatorId: number }) => {
  const [creatorSetsData, setCreatorSetsData] = useState<CreatorSetDebugDetails[]>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const abortController = new AbortController();
    if (creatorId) {
      setLoading(true);
      fetchCreatorSetDebugDetails(creatorId, abortController)
        .then((response) => {
          if (response?.results?.length > 0) {
            setCreatorSetsData(response.results);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [creatorId]);

  if (loading) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  return (
    <Stack>
      <Text size="md" fw={500}>
        Labeling Debug
      </Text>
      {creatorSetsData ? (
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Labeler</Table.Th>
              <Table.Th>Brand</Table.Th>
              <Table.Th>Creator Set Name</Table.Th>
              <Table.Th>State</Table.Th>
              <Table.Th>Date Labeled</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {creatorSetsData.map((data) => (
              <Table.Tr key={`${data.creator_id}-${data.creator_set_entry_id}`}>
                <Table.Td>{data.internal_review_enqueuer}</Table.Td>
                <Table.Td>{data.brand_name}</Table.Td>
                <Table.Td>{data.creator_set_name}</Table.Td>
                <Table.Td>{data.state ? CreatorSetEntryState[data.state] : "Unknown"}</Table.Td>
                <Table.Td>{data.internal_review_enqueue_ts}</Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      ) : (
        <Text>No data found</Text>
      )}
    </Stack>
  );
};

export const CreatorSourcingDebug = ({ creatorId }: { creatorId: number }) => {
  return (
    <Card shadow="xs" mx="lg" padding="lg" radius="md" withBorder>
      <Stack>
        <InternalCardTitle
          title="Sourcing Debug"
          icon={<IconZoomQuestion size={24} color="var(--mantine-color-blue-filled)" />}
        />
        <CreatorEnrichmentDebug creatorId={creatorId} />
        <CreatorSetsDebug creatorId={creatorId} />
      </Stack>
    </Card>
  );
};

export default CreatorSourcingDebug;
