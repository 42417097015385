import React from "react";
import { Button, Flex, Stack, TextInput } from "@mantine/core";
import { IconCheck, IconEdit } from "@tabler/icons-react";
import { SupportedPlatform } from "models/Common";
import PlatformLogo from "components/contracts/common/PlatformLogo";

export default function EditableTextInput({
  label,
  placeholder,
  value,
  setValue,
  platform,
  requiresInput,
  isEditable,
  isEditing,
  setIsEditing,
  errorMessage,
  setErrorMessage,
  loading,
  disabled,
  handleSubmit,
  actionButton,
}: {
  label: React.ReactElement;
  placeholder: string;
  value: string;
  setValue: (value: string) => void;
  platform: SupportedPlatform;
  requiresInput: boolean;
  isEditable: boolean;
  isEditing: boolean;
  setIsEditing: (editing: boolean) => void;
  errorMessage: string;
  setErrorMessage: (message: string) => void;
  loading: boolean;
  disabled: boolean;
  handleSubmit: () => void;
  actionButton?: React.ReactElement;
}) {
  const showTextInput = requiresInput || isEditing;

  return (
    <Stack>
      {label}
      <Flex align="flex-start" gap="xs" w="100%">
        <TextInput
          leftSection={
            !showTextInput ? (
              <IconCheck size={16} />
            ) : (
              <PlatformLogo platform={platform} size={16} />
            )
          }
          placeholder={placeholder}
          value={value || ""}
          onChange={(event) => {
            setErrorMessage("");
            setValue(event.currentTarget.value);
          }}
          error={errorMessage}
          disabled={!showTextInput || disabled}
          required
          w="70%"
        />
        <Flex align="center" gap="xs" mt={3}>
          {(disabled || requiresInput || isEditing) && (
            <Button
              size="xs"
              variant="light"
              onClick={handleSubmit}
              loading={loading}
              disabled={disabled}
              w={80}>
              Submit
            </Button>
          )}
          {!disabled && !requiresInput && !isEditing && actionButton}
          {isEditable && !isEditing && (
            <Button
              size="xs"
              variant="light"
              leftSection={<IconEdit size="1rem" />}
              onClick={() => {
                setIsEditing(true);
              }}>
              Edit
            </Button>
          )}
        </Flex>
      </Flex>
    </Stack>
  );
}
