import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import {
  fetchContractsWithContentForAdGroup,
  fetchContractsWithContentForCampaign,
  fetchContractsMissingReferralLinkOrPromoCodeForAdGroup,
  fetchContractsMissingReferralLinkOrPromoCodeForCampaign,
} from "components/contracts/common/Api";

import { Center, Container, Loader, Paper, Stack, Title } from "@mantine/core";

import { Notifications } from "@mantine/notifications";

import { fromISODateString } from "utils/DateUtils";

import { ContractStatus, ProductAccessStatus } from "components/contracts/common/Common";

import Deliverable from "components/contracts/models/Deliverable";

import ContractHeaderInfo from "components/contracts/review/ContractHeaderInfo";
import ContractReviewCards from "components/contracts/review/ContractReviewCards";
import ReferralLinkAndPromoCodeInput from "components/contracts/review/ReferralLinkAndPromoCodeInput";
import { ContractFeedType } from "campaigns/navbar/consts";
import BrandProductAccessInput from "components/contracts/review/BrandProductAccessInput";

interface ContractMissingReferralLink {
  contractId: string;
  contractStatus: ContractStatus;
  amount: number;
  bonusAmount: number;
  bonusCondition: string;
  signatureFirstName: string;
  signatureLastName: string;
  avatarUrl: string;
  deliverables: Deliverable[];
}

export default function ContentReviewFeed({
  adGroupId,
  campaignId,
  showAdminOptions,
}: {
  adGroupId?: string;
  campaignId?: string;
  showAdminOptions?: boolean;
}) {
  const { campaignHashId } = useParams<{ campaignHashId: string }>();
  if (!campaignHashId && !campaignId && !adGroupId) {
    return null;
  }

  if ((campaignHashId || campaignId) && adGroupId) {
    throw new Error("Cannot specify both campaignId and adGroupId");
  }

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [feedType, setFeedType] = useState(
    (queryParams.get("type") as ContractFeedType) || ContractFeedType.All,
  );

  const fetchContractsMissingReferralLinks = () => {
    if (adGroupId) {
      return fetchContractsMissingReferralLinkOrPromoCodeForAdGroup(adGroupId);
    } else if (campaignId || campaignHashId) {
      return fetchContractsMissingReferralLinkOrPromoCodeForCampaign(campaignHashId, campaignId);
    }
    throw new Error("Must specify either adGroupId or campaignId");
  };

  const fetchContent = () => {
    if (adGroupId) {
      return fetchContractsWithContentForAdGroup(adGroupId);
    } else if (campaignId || campaignHashId) {
      return fetchContractsWithContentForCampaign(campaignHashId, campaignId);
    }
    throw new Error("Must specify either adGroupId or campaignId");
  };

  const [
    contractsMissingReferralLinksOrPromoCodesState,
    setContractsMissingReferralLinksOrPromoCodesState,
  ] = useState<ContractMissingReferralLink[]>([]);
  const [
    contractsMissingReferralLinksOrPromoCodesLoaded,
    setContractsMissingReferralLinksOrPromoCodesLoaded,
  ] = useState<boolean>(false);

  const [contractHeadersState, setContractHeadersState] = useState<ContractHeaderInfo[]>([]);

  const [productAccessData, setProductAccessData] = useState<ContractHeaderInfo[]>([]);

  const [contentLoaded, setContentLoaded] = useState<boolean>(false);

  const [requiresPromoCodeState, setRequiresPromoCodeState] = useState<boolean>(false);
  const [requiresReferralLinkState, setRequiresReferralLinkState] = useState<boolean>(false);

  useEffect(() => {
    setContractsMissingReferralLinksOrPromoCodesLoaded(false);
    fetchContractsMissingReferralLinks()
      .then((response) => {
        const { contracts, success, requiresPromoCode, requiresReferralLink } = response;

        if (success) {
          const deserializedContracts = contracts.map((contract: any) => {
            const deliverables = contract.deliverables.map((deliverable: any) =>
              Deliverable.deserialize(deliverable),
            );

            return {
              ...contract,
              linkCreated: fromISODateString(contract.linkCreated),
              deliverables,
            };
          });
          setRequiresPromoCodeState(requiresPromoCode);
          setRequiresReferralLinkState(requiresReferralLink);
          setContractsMissingReferralLinksOrPromoCodesState(deserializedContracts);
        }
      })
      .finally(() => setContractsMissingReferralLinksOrPromoCodesLoaded(true));

    setContentLoaded(false);
    fetchContent()
      .then((response) => {
        const { contractHeaders, success } = response;

        if (success) {
          const deserializedContractHeaders: ContractHeaderInfo[] = contractHeaders.map(
            (contractHeader: any) => ContractHeaderInfo.deserialize(contractHeader),
          );
          setContractHeadersState(deserializedContractHeaders);

          setProductAccessData(
            deserializedContractHeaders
              .filter((contract) => contract.requiresProductAccess)
              .sort((a, b) => a.productAccessStatus - b.productAccessStatus)
              .sort((a, b) => {
                if (a.productAccessStatus === ProductAccessStatus.BRAND_ACTION_REQUIRED) return -1;
                if (b.productAccessStatus === ProductAccessStatus.BRAND_ACTION_REQUIRED) return 1;
                return 0;
              }),
          );
        }
      })
      .finally(() => setContentLoaded(true));
  }, [adGroupId, campaignId]);

  useEffect(() => {
    // parse feedType from the search params
    const newType = queryParams.get("type");

    if (!newType) {
      setFeedType(ContractFeedType.All);
    } else {
      setFeedType(newType as ContractFeedType);
    }
  }, [queryParams]);
  const loaded = contractsMissingReferralLinksOrPromoCodesLoaded && contentLoaded;
  const referralLinkAndPromoCodeTitle = `${requiresReferralLinkState ? "Referral Links" : ""}${
    requiresReferralLinkState && requiresPromoCodeState ? " and " : ""
  }${requiresPromoCodeState ? "Promo Codes" : ""}`;

  const hasPromoCodesOrReferralLinks = contractsMissingReferralLinksOrPromoCodesState.length > 0;

  let filteredContractHeadersState = contractHeadersState;
  if (feedType === ContractFeedType.ReviewContent) {
    filteredContractHeadersState = contractHeadersState.filter(
      (header) => header.reviewRequired || header.numUnreadMessages > 0,
    );
  }
  if (feedType === ContractFeedType.LiveVerification) {
    filteredContractHeadersState = contractHeadersState.filter(
      (header) => header.verificationRequired,
    );
  }
  if (feedType === ContractFeedType.PromoCodesAndLinks) {
    filteredContractHeadersState = [];
  }
  return (
    <Container>
      <Notifications />
      <Stack gap="xs">
        {!loaded && (
          <Center mt="xl">
            <Loader />
          </Center>
        )}
        {productAccessData.length > 0 &&
          (feedType === ContractFeedType.All || feedType === ContractFeedType.ProductAccess) &&
          loaded && (
            <>
              <Title order={2} fw="500">
                Product Access
              </Title>
              {productAccessData.map((contract) => (
                <BrandProductAccessInput
                  key={`${contract.contractId}-product-access-link`}
                  contractHeaderInfo={contract}
                />
              ))}
            </>
          )}

        {(feedType === ContractFeedType.All || feedType === ContractFeedType.PromoCodesAndLinks) &&
          loaded &&
          hasPromoCodesOrReferralLinks && (
            <>
              <Title order={2} fw="500">
                {referralLinkAndPromoCodeTitle}
              </Title>
              {contractsMissingReferralLinksOrPromoCodesState.map((contract) => (
                <ReferralLinkAndPromoCodeInput
                  key={`${contract.contractId}-referral-link`}
                  contractId={contract.contractId}
                  signatureFirstName={contract.signatureFirstName}
                  signatureLastName={contract.signatureLastName}
                  avatarUrl={contract.avatarUrl}
                  amount={contract.amount}
                  deliverables={contract.deliverables}
                  showAdminOptions={showAdminOptions}
                />
              ))}
            </>
          )}
        {(feedType === ContractFeedType.All ||
          feedType === ContractFeedType.ReviewContent ||
          feedType === ContractFeedType.LiveVerification) &&
          loaded &&
          filteredContractHeadersState.length > 0 && (
            <>
              <Title mt="xs" order={2} fw="500">
                Content Review
              </Title>
              {filteredContractHeadersState.map((contractHeader) => (
                <ContractReviewCards
                  key={contractHeader.contractId}
                  contractHeaderInfo={contractHeader}
                  showAdminOptions={showAdminOptions}
                />
              ))}
            </>
          )}
        {loaded &&
          (((feedType === ContractFeedType.All ||
            feedType === ContractFeedType.ReviewContent ||
            feedType === ContractFeedType.LiveVerification) &&
            filteredContractHeadersState.length === 0) ||
            (feedType === ContractFeedType.PromoCodesAndLinks &&
              hasPromoCodesOrReferralLinks === false)) && <Paper>Nothing to review here</Paper>}
      </Stack>
    </Container>
  );
}
