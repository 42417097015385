import React from "react";
import { useNavigate } from "react-router-dom";

import { Anchor, Avatar, Badge, Group, Table, Text } from "@mantine/core";

import { IconCheck, IconX } from "@tabler/icons-react";

import { OpsTask } from "models/OpsTask";

export const OpsTaskTableRow = ({
  opsTask,
  showAssignee = true,
}: {
  opsTask: OpsTask;
  showAssignee?: boolean;
}) => {
  const navigate = useNavigate();
  const getInitials = (name: string) => {
    if (!name) return "";
    const names = name.split(" ");
    return names[0].charAt(0) + names[names.length - 1].charAt(0);
  };
  return (
    <Table.Tr>
      <Table.Td>
        <Anchor size="sm" href={`/admin/ops/task/${opsTask.id}`}>
          {opsTask.name}
        </Anchor>
      </Table.Td>
      <Table.Td>
        <Group grow preventGrowOverflow={false} wrap="nowrap">
          {showAssignee && opsTask?.assigned_user_details?.avatar_url ? (
            <Avatar
              w={40}
              h={40}
              src={opsTask?.assigned_user_details?.avatar_url}
              style={{ flex: "none" }}
            />
          ) : (
            <Avatar w={40} h={40} style={{ flex: "none" }}>
              {getInitials(opsTask?.assigned_user_details?.name)}
            </Avatar>
          )}
          <Text fw={500}>{opsTask?.assigned_user_details?.name || "Unassigned"}</Text>
        </Group>
      </Table.Td>
      <Table.Td>
        {opsTask.completed ? (
          <IconCheck size={24} color="green" />
        ) : (
          <IconX size={24} color="red" />
        )}
      </Table.Td>
      <Table.Td>
        <Badge
          variant="light"
          onClick={() =>
            navigate(`/admin/creator_sets?creatorSetId=${opsTask.creator_set_details.id}`)
          }>
          {opsTask.creator_set_details.name}
        </Badge>
      </Table.Td>
      <Table.Td>{opsTask.value}</Table.Td>
      <Table.Td>{opsTask.creator_set_details.sourcing_difficulty}</Table.Td>
    </Table.Tr>
  );
};

export default OpsTaskTableRow;
