import React, { useEffect, useState } from "react";
import { Box, Container, Flex } from "gestalt";
import "gestalt/dist/gestalt.css";
import { User } from "firebase/auth";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import { AuthContext } from "auth/AuthContext";
import { Creator } from "models/Creator";
import ProfileHeader from "pages/profile/ProfileHeader";
import About from "pages/profile/About";
import Spacer from "components/Spacer";
import LoadingScreen from "components/LoadingScreen";
import { getCreatorByKey } from "utils/CreatorUtils";
import { InstagramProfileResponse } from "social/fb/InstagramUtils";
import { YoutubeProfileResponse, YOUTUBE_PROFILE_URL } from "social/google/YoutubeUtils";
import { User as UserProfile } from "models/User";
import { getByUsername } from "utils/UserUtils";
import { getFirstProfileByKey } from "social/ApiUtils";
import { TikTokProfileResponse } from "social/tiktok/TikTokUtils";
import { OverallProfile } from "pages/profile/ProfileCards";
import { isMobile } from "react-device-detect";
import { getUnclaimedProfileInfo } from "pages/profile/UnclaimedProfileUtils";

const getAndSetCreator = async (
  firebaseToken: string,
  key: string,
  setCreator: (creator: Creator) => void,
) => {
  const creatorResult = await getCreatorByKey(firebaseToken, key);
  setCreator(creatorResult);
  return creatorResult;
};

const getAndSetSocialProfile = async (
  platformUrl: string,
  firebaseToken: string,
  key: string,
  setSocialProfile: (
    profile: InstagramProfileResponse | YoutubeProfileResponse | TikTokProfileResponse,
  ) => void,
) => {
  const socialProfile = await getFirstProfileByKey(firebaseToken, key, platformUrl);
  setSocialProfile(socialProfile);
  return socialProfile;
};

const fetchData = async (
  requestUser: User,
  username: string,
  setCreator: (creator: Creator) => void,
  setUserProfile: (userProfile: UserProfile) => void,
  setYoutubeProfile: (profile: YoutubeProfileResponse) => void,
  setLoaded: (isLoaded: boolean) => void,
  onFailure: () => void,
) => {
  // remove this later once we require authentication to view a profile
  const firebaseToken = await requestUser?.getIdToken();
  try {
    const user = await getByUsername(firebaseToken, username);
    setUserProfile(user);

    await Promise.all([
      await getAndSetCreator(firebaseToken, user.key, setCreator),
      await getAndSetSocialProfile(YOUTUBE_PROFILE_URL, firebaseToken, user.key, setYoutubeProfile),
    ]);

    setLoaded(true);
  } catch {
    onFailure();
  }
};

const Profile = () => {
  // requesting user
  const requestUser: User = useOutletContext<AuthContext>()?.user;
  // target username
  const { username } = useParams();

  const [creator, setCreator] = useState(null);
  const [userProfile, setProfile] = useState(null);

  // need to fetch this for the claimed profile logic
  const [youtubeProfile, setYoutubeProfile] = useState(null);

  // Defines if the user is looking at their own profile
  const isSelf = requestUser && userProfile && userProfile.firebaseUid === requestUser.uid;
  const [isLoaded, setLoaded] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    fetchData(requestUser, username, setCreator, setProfile, setYoutubeProfile, setLoaded, () => {
      navigate("/", { replace: true });
    });
  }, [username]);

  const title = userProfile
    ? `${userProfile.name} (${username}) - Profile | 1stCollab`
    : "Profile | 1stCollab";
  const description = userProfile
    ? `See the creator profile for ${userProfile.name} (${username}). Only on 1stCollab`
    : "View this creator's profile. Only on 1stCollab.";

  const unclaimedProfileInfo = getUnclaimedProfileInfo(youtubeProfile);

  const loadedProfile = (
    <Container>
      <ProfileHeader
        user={userProfile}
        requestUser={requestUser}
        isSelf={isSelf}
        creator={creator}
        unclaimedProfile={unclaimedProfileInfo}
      />
      <Spacer height={64} />
      <OverallProfile
        profileKey={userProfile?.key}
        isClaimed={userProfile && userProfile?.is_claimed}
      />
    </Container>
  );

  return isLoaded ? (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="title" property="og:title" content={title} />
        <meta name="description" property="og:description" content={description} />
      </Helmet>
      {loadedProfile}
    </>
  ) : (
    <LoadingScreen />
  );
};

export default Profile;
