import { Loader, Stack, Title } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "reduxStore/hooks";
import { fetchMyBrand } from "reduxStore/meSlice";
import BrandAbout from "brand/settings/BrandAbout";
import BrandContact from "brand/settings/BrandContact";

const BrandSettings = () => {
  const dispatch = useAppDispatch();
  const brand = useAppSelector((state) => state.me.brand);

  const [isFetchingBrand, setIsFetchingBrand] = useState<boolean>(false);

  useEffect(() => {
    const abortController = new AbortController();

    setIsFetchingBrand(true);
    dispatch(fetchMyBrand({ abortController }))
      .unwrap()
      .then(({ aborted }) => {
        if (!aborted) {
          setIsFetchingBrand(false);
        }
      });

    return () => {
      abortController.abort();
    };
  }, []);

  if (isFetchingBrand) {
    return <Loader />;
  }
  if (!brand) {
    return null;
  }
  return (
    <Stack style={{ "--stack-gap": "24px" }}>
      <Title order={1}>Brand Settings</Title>
      <BrandAbout brand={brand} />
      <BrandContact brand={brand} />
    </Stack>
  );
};

export default BrandSettings;
