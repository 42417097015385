import React, { useEffect, useState } from "react";

import { useAppSelector } from "reduxStore/hooks";

import {
  Box,
  Button,
  Center,
  Checkbox,
  CloseButton,
  Combobox,
  Container,
  Divider,
  Group,
  InputBase,
  Loader,
  NativeSelect,
  Paper,
  Stack,
  Text,
  Title,
  useCombobox,
} from "@mantine/core";
import { notifications, Notifications } from "@mantine/notifications";

import { bulkImportCreatorsToCreatorSet } from "admin/api/creatorSetApi";
import { useAdminAppContext } from "admin/app/AdminAppShell";
import Spacer from "components/Spacer";
import UnifiedCreatorRep from "components/creator_lists/unified_creator_rep/UnifiedCreatorRep";
import { useCreatorSetPaginate } from "components/creator_sets/CreatorSetCommons";
import {
  CREATOR_SETS_URL,
  CreatorSetEntriesResponse,
} from "components/creator_sets/CreatorSetUtils";
import {
  CreatorDetails,
  CreatorSet,
  CreatorSetEntryItem,
  CreatorSetEntryState,
  CreatorSetEntryWithDetail,
} from "components/discovery/Datamodels";
import { createRequestWithFirebaseToken, handleResult } from "utils/ApiUtils";

type BrandType = {
  brand_name: string;
};

type ItemWithBrand = {
  id: number;
  name: string;
  brand?: BrandType;
};

const fetchCreatorSetEntryItems = async (
  creatorSetId: number,
  abortController: AbortController = new AbortController(),
  includeContracted = false,
  includeActivated = true,
  includeSaved = true,
  includeNeedsReview = false,
  includeRejected = false,
  filterEmails = true,
): Promise<CreatorSetEntryItem[]> => {
  const endpoint = new URL(`${CREATOR_SETS_URL}get_creator_set_entries/`);
  endpoint.searchParams.append("creatorSetId", creatorSetId.toString());
  endpoint.searchParams.append("includeContracted", includeContracted.toString());
  endpoint.searchParams.append("includeActivated", includeActivated.toString());
  endpoint.searchParams.append("includeSaved", includeSaved.toString());
  endpoint.searchParams.append("includeNeedsReview", includeNeedsReview.toString());
  endpoint.searchParams.append("includeRejected", includeRejected.toString());
  endpoint.searchParams.append("filterEmails", filterEmails.toString());
  const request = await createRequestWithFirebaseToken({
    url: endpoint,
  });

  const response: CreatorSetEntriesResponse = await handleResult(request, abortController);
  if (response !== null) {
    return response.creator_set_entries;
  }
  return [];
};

const CreatorSetImportSelector = ({
  label,
  creatorSets,
  selectedItemId,
  setSelectedItemId,
}: {
  label: string;
  creatorSets: CreatorSet[];
  selectedItemId: number | null;
  setSelectedItemId: (itemId: number) => void;
}) => {
  const [search, setSearch] = useState("");
  const [cleared, setCleared] = useState(false);

  const formatName = (item: ItemWithBrand): string => `${item.name} (${item?.brand?.brand_name})`;

  const itemData = creatorSets.map((item: ItemWithBrand) => ({
    id: item.id.toString(),
    value: item.id.toString(),
    label: formatName(item),
  }));

  const [itemIdToLabel, setItemIdToLabel] = useState<{ [key: number]: string }>({});

  useEffect(() => {
    if (creatorSets?.length > 0) {
      const newItemIdToLabel: { [key: number]: string } = {};
      creatorSets.forEach((item: ItemWithBrand) => {
        newItemIdToLabel[item.id] = formatName(item);
      });
      setItemIdToLabel(newItemIdToLabel);
    }
  }, [creatorSets]);

  useEffect(() => {
    if (cleared) {
      setSearch("");
      setCleared(false);
      setSelectedItemId(null);
    }
  }, [cleared]);

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const shouldFilterOptions = itemData.every((item) => item.label !== search);
  const filteredOptions =
    shouldFilterOptions && search?.length > 0
      ? itemData.filter((item) => item.label.toLowerCase().includes(search.toLowerCase().trim()))
      : itemData;

  const options = filteredOptions.map((item) => (
    <Combobox.Option value={item.value} key={`${item.label}-${item.id}`}>
      {item.label}
    </Combobox.Option>
  ));

  return (
    <Combobox
      store={combobox}
      withinPortal={false}
      onOptionSubmit={(val) => {
        setSelectedItemId(val ? Number(val) : null);
        setSearch(val ? itemIdToLabel[Number(val)] : val);
        combobox.closeDropdown();
      }}>
      <Combobox.Target>
        <InputBase
          label={label}
          w={350}
          rightSection={
            setSelectedItemId !== null ? (
              <CloseButton
                size="sm"
                onMouseDown={(event: { preventDefault: () => void }) => event.preventDefault()}
                onClick={() => {
                  setCleared(true);
                }}
                aria-label="Clear value"
              />
            ) : (
              <Combobox.Chevron />
            )
          }
          rightSectionPointerEvents={setSelectedItemId === null ? "none" : "all"}
          value={search}
          onChange={(event: { currentTarget: { value: React.SetStateAction<string> } }) => {
            combobox.openDropdown();
            combobox.updateSelectedOptionIndex();
            setSearch(event.currentTarget.value);
          }}
          onClick={() => combobox.openDropdown()}
          onFocus={() => combobox.openDropdown()}
          onBlur={() => {
            combobox.closeDropdown();
            setSearch(selectedItemId ? itemIdToLabel[selectedItemId] : "");
          }}
          placeholder="Select a Creator Set"
        />
      </Combobox.Target>
      <Combobox.Dropdown>
        <Combobox.Options>
          {options.length > 0 ? options : <Combobox.Empty>Nothing found</Combobox.Empty>}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};

const CreatorSetEntryResults = ({
  creatorSetEntriesWithDetails,
}: {
  creatorSetEntriesWithDetails: CreatorSetEntryWithDetail[];
}) => {
  const creatorSetMap = useAppSelector((state) => state.creatorSets.creatorSetItems);
  return (
    <Box>
      {creatorSetEntriesWithDetails.map((entry, index) => (
        <React.Fragment key={`entry-${entry.creator_set_entry.creator_id}`}>
          {index > 0 ? <Spacer height={12} /> : null}
          <UnifiedCreatorRep
            creator={entry.creator_details}
            key={`entry-${entry.creator_set_entry.creator_id}`}
            defaultExpandedPlatforms={[]}
            archetype={creatorSetMap[entry.creator_set_entry.creator_set_id]?.name}
          />
        </React.Fragment>
      ))}
    </Box>
  );
};

export const AdminCreatorSetImportTools = ({ creatorSet }: { creatorSet: CreatorSet }) => {
  const { creatorSets } = useAdminAppContext();
  const [selectedCreatorSetId, setSelectedCreatorSetId] = useState(null);
  const [includeContracted, setIncludeContracted] = useState(false);
  const [includeActivated, setIncludeActivated] = useState(true);
  const [includeSaved, setIncludeSaved] = useState(false);
  const [includeNeedsReview, setIncludeNeedsReview] = useState(false);
  const [includeRejected, setIncludeRejected] = useState(false);
  const [hasEmail, setHasEmail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  // Stored creator ids
  const [creatorSetEntryItems, setCreatorSetEntryItems] = useState<CreatorSetEntryItem[]>([]);

  // Import state
  const [importState, setImportState] = useState(CreatorSetEntryState.NEEDS_REVIEW.toString());

  // Fetch the creator set entries
  useEffect(() => {
    const abortController = new AbortController();
    if (selectedCreatorSetId) {
      setLoading(true);
      fetchCreatorSetEntryItems(
        selectedCreatorSetId,
        abortController,
        includeContracted,
        includeActivated,
        includeSaved,
        includeNeedsReview,
        includeRejected,
        hasEmail,
      )
        .then((resp) => {
          if (resp?.length > 0) {
            setCreatorSetEntryItems(resp);
          } else {
            setCreatorSetEntryItems([]);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setCreatorSetEntryItems([]);
    }
  }, [
    selectedCreatorSetId,
    includeContracted,
    includeActivated,
    includeSaved,
    includeNeedsReview,
    includeRejected,
    hasEmail,
  ]);

  // Load creator set view
  const [creatorSetDetailsMap, setCreatorSetDetailsMap] = useState<Record<number, CreatorDetails>>(
    {},
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { creatorSetPageDetails, EntryPagniator, TopOfEntries } = useCreatorSetPaginate(
    creatorSetEntryItems,
    creatorSetDetailsMap,
    setCreatorSetDetailsMap,
  );

  return (
    <Paper p="md">
      <Notifications />
      <Stack gap="md">
        <Title order={4}>Import Tools</Title>
        <Title order={5}>Import from Creator Set</Title>
        <Group justify="space-between" wrap="nowrap">
          <Group align="flex-end" wrap="nowrap">
            <CreatorSetImportSelector
              label="Source Creator Set"
              creatorSets={creatorSets}
              selectedItemId={selectedCreatorSetId}
              setSelectedItemId={setSelectedCreatorSetId}
            />
            <NativeSelect
              styles={{
                root: {
                  maxWidth: "40%",
                },
              }}
              label="Imported Status"
              required
              data={Object.keys(CreatorSetEntryState)
                .filter((k) => typeof CreatorSetEntryState[k as any] === "number")
                .map((key) => ({ label: key, value: CreatorSetEntryState[key as any] }))}
              value={importState}
              onChange={(e) => setImportState(e.target.value)}
            />
          </Group>
          <Group align="flex-end">
            <Checkbox
              label="Contracted"
              checked={includeContracted}
              onChange={() => setIncludeContracted(!includeContracted)}
            />
            <Checkbox
              label="Activated"
              checked={includeActivated}
              onChange={() => setIncludeActivated(!includeActivated)}
            />
            <Checkbox
              label="Saved"
              checked={includeSaved}
              onChange={() => setIncludeSaved(!includeSaved)}
            />
            <Checkbox
              label="Needs Review"
              checked={includeNeedsReview}
              onChange={() => setIncludeNeedsReview(!includeNeedsReview)}
            />
            <Checkbox
              label="Rejected"
              checked={includeRejected}
              onChange={() => setIncludeRejected(!includeRejected)}
            />
            <Checkbox
              label="Must Have Email"
              checked={hasEmail}
              onChange={() => setHasEmail(!hasEmail)}
            />
          </Group>
        </Group>
        <Button
          loading={buttonLoading}
          onClick={() => {
            if (selectedCreatorSetId) {
              setButtonLoading(true);
              bulkImportCreatorsToCreatorSet(
                creatorSetEntryItems.map((entry) => entry.creator_id),
                creatorSet.id,
                CreatorSetEntryState[importState as keyof typeof CreatorSetEntryState],
              )
                .then((resp) => {
                  if (resp?.success) {
                    notifications.show({
                      title: `${resp.message}`,
                      message: `Successfully imported ${resp?.added?.length} creators to ${creatorSet.name}. ${resp?.filtered?.length} filtered (${resp?.overlap_count} overlaps).`,
                      autoClose: false,
                    });
                  } else {
                    notifications.show({
                      color: "red",
                      title: `${resp.message}`,
                      message: `Failed to import creators to ${creatorSet.name}.`,
                      autoClose: false,
                    });
                  }
                })
                .finally(() => {
                  setButtonLoading(false);
                });
            }
          }}>
          Bulk Import
        </Button>
        <Divider />
        {loading ? (
          <Stack align="center">
            <Loader />
          </Stack>
        ) : (
          <Stack>
            <Group justify="flex-end">
              <Text fw={400}>Num creators: {creatorSetEntryItems.length}</Text>
            </Group>
            <Stack>
              {creatorSetPageDetails.length === 0 && creatorSetEntryItems.length !== 0 ? (
                <Container fluid h="100vh">
                  <Center h="100%">
                    <Loader color="blue" />
                  </Center>
                </Container>
              ) : (
                <CreatorSetEntryResults creatorSetEntriesWithDetails={creatorSetPageDetails} />
              )}
              <EntryPagniator />
            </Stack>
          </Stack>
        )}
      </Stack>
    </Paper>
  );
};

export default AdminCreatorSetImportTools;
