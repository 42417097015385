import React, { useState } from "react";
import { User } from "firebase/auth";

import { ActionIcon, Popover, Stack, Tooltip } from "@mantine/core";

import { IconLink } from "@tabler/icons-react";

import { SupportedFormat, SupportedPlatform } from "models/Common";

import {
  PromoCodeInput,
  ReferralLinkInput,
} from "components/contracts/review/ReferralLinkAndPromoCodeInput";

export function EditFields({
  deliverableId,
  platform,
  format,
  creatorHandle,
  referralLink,
  setReferralLink,
  promoCode,
  setPromoCode,
  requiresReferralLink,
  isMissingReferralLink,
  setIsMissingReferralLink,
  requiresPromoCode,
  isMissingPromoCode,
  setIsMissingPromoCode,
  disableEditing,
}: {
  deliverableId: string;
  platform: SupportedPlatform;
  format: SupportedFormat;
  creatorHandle: string;
  referralLink: string;
  setReferralLink: (value: string) => void;
  promoCode: string;
  setPromoCode: (value: string) => void;
  requiresReferralLink: boolean;
  isMissingReferralLink: boolean;
  setIsMissingReferralLink: (value: boolean) => void;
  requiresPromoCode: boolean;
  isMissingPromoCode: boolean;
  setIsMissingPromoCode: (isMissingPromoCode: boolean) => void;
  disableEditing: boolean;
}) {
  if (!requiresReferralLink && !requiresPromoCode) {
    return null;
  }

  return (
    <Stack gap="xs">
      {requiresReferralLink && (
        <ReferralLinkInput
          deliverableId={deliverableId}
          platform={platform}
          format={format}
          creatorHandle={creatorHandle}
          url={referralLink || ""}
          setUrl={setReferralLink}
          isMissingReferralLink={isMissingReferralLink}
          setIsMissingReferralLink={setIsMissingReferralLink}
          disabled={disableEditing}
        />
      )}
      {requiresPromoCode && (
        <PromoCodeInput
          deliverableId={deliverableId}
          platform={platform}
          format={format}
          creatorHandle={creatorHandle}
          promoCode={promoCode || ""}
          setPromoCode={setPromoCode}
          isMissingPromoCode={isMissingPromoCode}
          setIsMissingPromoCode={setIsMissingPromoCode}
          disabled={disableEditing}
        />
      )}
    </Stack>
  );
}

export default function EditReferralLinkAndPromoCode({
  deliverableId,
  platform,
  format,
  creatorHandle,
  requiresReferralLink,
  requiresPromoCode,
  isMissingReferralLink,
  isMissingPromoCode,
  setIsMissingReferralLink,
  setIsMissingPromoCode,
  referralLink,
  promoCode,
  disableEditing,
}: {
  deliverableId: string;
  platform: SupportedPlatform;
  format: SupportedFormat;
  creatorHandle: string;
  requiresReferralLink: boolean;
  requiresPromoCode: boolean;
  isMissingReferralLink: boolean;
  setIsMissingReferralLink: (value: boolean) => void;
  setIsMissingPromoCode: (value: boolean) => void;
  isMissingPromoCode: boolean;
  referralLink: string;
  promoCode: string;
  disableEditing: boolean;
}) {
  if (!requiresReferralLink && !requiresPromoCode) {
    return null;
  }

  const [referralLinkState, setReferralLinkState] = useState<string>(referralLink);
  const [promoCodeState, setPromoCodeState] = useState<string>(promoCode);
  const tooltipLabel = `${disableEditing ? "View" : "Edit"} ${
    requiresReferralLink ? "Referral Link" : ""
  }${requiresReferralLink && requiresPromoCode ? " and " : ""}${
    requiresPromoCode ? "Promo Code" : ""
  }`;

  return (
    <Popover withArrow position="top" shadow="sm">
      <Popover.Target>
        <Tooltip label={tooltipLabel}>
          <ActionIcon variant="subtle" color="gray">
            <IconLink color="gray" size="1rem" />
          </ActionIcon>
        </Tooltip>
      </Popover.Target>
      <Popover.Dropdown w="30%" py="xs" pt={4}>
        <EditFields
          deliverableId={deliverableId}
          platform={platform}
          format={format}
          creatorHandle={creatorHandle}
          requiresReferralLink={requiresReferralLink}
          isMissingReferralLink={isMissingReferralLink}
          setIsMissingReferralLink={setIsMissingReferralLink}
          requiresPromoCode={requiresPromoCode}
          isMissingPromoCode={isMissingPromoCode}
          setIsMissingPromoCode={setIsMissingPromoCode}
          referralLink={referralLinkState}
          setReferralLink={setReferralLinkState}
          promoCode={promoCodeState}
          setPromoCode={setPromoCodeState}
          disableEditing={disableEditing}
        />
      </Popover.Dropdown>
    </Popover>
  );
}
