import React from "react";

import { Flex, Tooltip } from "@mantine/core";

import { IconCircleCheck, IconCircleX } from "@tabler/icons-react";

import { isAdGroupActive } from "campaigns/logic/CampaignValidator";
import { BrandStatus } from "models/Brand";
import { Campaign, CampaignAdGroupStatus, CampaignAdGroup, CampaignStatus } from "models/Campaign";

export const AdGroupStatusIcon = ({
  campaign,
  adGroup,
}: {
  campaign: Campaign;
  adGroup: CampaignAdGroup;
}) => {
  const isActive = isAdGroupActive(campaign, adGroup);

  if (isActive) {
    return (
      <Flex justify="center">
        <IconCircleCheck color="green" size="1.5rem" />
      </Flex>
    );
  }

  const labelStates = `Brand Status: ${BrandStatus[campaign.brand?.status]}\nCampaign Status: ${
    CampaignStatus[campaign.status]
  }\nAdGroup Status: ${CampaignAdGroupStatus[adGroup.status]}`;
  return (
    <Flex justify="center">
      <Tooltip label={labelStates} multiline w={200}>
        <IconCircleX color="red" size="1.5rem" />
      </Tooltip>
    </Flex>
  );
};

export default AdGroupStatusIcon;
