import { Card, Center, Stack, Text, Title } from "@mantine/core";
import React from "react";

const EmptyState = ({
  ctaButton,
  description,
  secondDescription,
  title,
}: {
  ctaButton: React.ReactNode;
  description: string;
  secondDescription: React.ReactNode;
  title: string;
}) => {
  return (
    <Center>
      <Card p="xl" withBorder radius="md">
        <Stack align="center" gap="lg" px="lg">
          <Title order={2}>{title}</Title>
          <Text size="lg" style={{ textAlign: "center" }}>
            {description}
            <br />
            <br />
            {secondDescription}
          </Text>
          {ctaButton}
        </Stack>
      </Card>
    </Center>
  );
};
export default EmptyState;
