import React, { useState } from "react";
import { User } from "firebase/auth";

import { ActionIcon, Tooltip } from "@mantine/core";

import { useDisclosure } from "@mantine/hooks";

import { IconArrowBackUp } from "@tabler/icons-react";

import { revertReviewStatus } from "components/contracts/common/Api";

import { ContentStatus } from "components/contracts/common/Common";

import {
  showFailureNotification,
  showSuccessNotification,
} from "components/common/Notifications";

import ConfirmModal from "components/contracts/common/ConfirmModal";

export default function RevertReviewStatus({
  showAdminOptions,
  contentReviewStatus,
  updateCardState,
  deliverableId,
  scriptId,
  videoId,
}: {
  showAdminOptions: boolean;
  contentReviewStatus: ContentStatus;
  updateCardState: (newContentReviewStatus: ContentStatus, newContentReviewDate: Date) => void;
  deliverableId: string;
  scriptId?: string;
  videoId?: string;
}) {
  if (!showAdminOptions) {
    return null;
  }

  if (
    contentReviewStatus !== ContentStatus.APPROVED &&
    contentReviewStatus !== ContentStatus.REVISIONS_REQUESTED
  ) {
    return null;
  }

  const [opened, { open, close }] = useDisclosure();
  const [isProcessing, setIsProcessing] = useState(false);

  const handleRevertReviewStatus = () => {
    setIsProcessing(true);
    revertReviewStatus(deliverableId, scriptId, videoId)
      .then((response) => {
        const { success, errorMessage, reviewStatus, reviewDate } = response;
        if (success) {
          updateCardState(reviewStatus as ContentStatus, reviewDate as Date);
          showSuccessNotification({ message: "Successfully reverted review status." });
        } else {
          showFailureNotification({ message: errorMessage });
        }
      })
      .catch((error) => {
        showFailureNotification({ message: error.message });
      })
      .finally(() => {
        setIsProcessing(false);
        close();
      });
  };

  return (
    <>
      <ConfirmModal
        opened={opened}
        close={close}
        modalMessage="Are you sure you want to revert the review status? This will mark the content as Pending Review."
        onConfirm={handleRevertReviewStatus}
        isProcessing={isProcessing}
      />
      <Tooltip label="Revert Review Status">
        <ActionIcon variant="subtle" color="gray" loading={isProcessing} onClick={open}>
          <IconArrowBackUp size="1rem" />
        </ActionIcon>
      </Tooltip>
    </>
  );
}
