import React from "react";
import { Button, Text } from "gestalt";
import "gestalt/dist/gestalt.css";

export enum SaveStatus {
  INIT = 1,
  SUCCESS = 2,
  FAILURE = 3,
}

export const SaveButton = ({ enabled, onSave }: { enabled: boolean; onSave: () => void }) => (
  <Button text="Save" color="red" disabled={!enabled} onClick={() => onSave()} />
);

export const StatusText = ({ status, message }: { status: SaveStatus; message?: string }) => {
  switch (status) {
    case SaveStatus.INIT:
      return <></>; // eslint-disable-line react/jsx-no-useless-fragment
    case SaveStatus.SUCCESS:
      return <Text color="success">Successfully updated Profile!</Text>;
    case SaveStatus.FAILURE:
      return <Text color="error">{message || "Error saving profile. Please try again."}</Text>;
    default:
      return <></>; // eslint-disable-line react/jsx-no-useless-fragment
  }
};
