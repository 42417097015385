import React, { useEffect, useState } from "react";

import { User } from "firebase/auth";

import { Anchor, Button, Group, Modal, Stack, TagsInput, Text, TextInput } from "@mantine/core";

import { fetchContract, reissueContract } from "components/contracts/common/Api";

import { Contract } from "components/contracts/dashboard/Utils";
import {
  showFailureNotification,
  showSuccessNotification,
} from "components/common/Notifications";
import { invalidEmail, validateEmailList } from "utils/ValidationUtils";
import { first } from "lodash";

export default function ReissueContractModal({
  contractId,
  opened,
  open,
  close,
  displayName,
  handleRefetchContract,
}: {
  contractId: string;
  opened: boolean;
  open: () => void;
  close: () => void;
  displayName: string;
  handleRefetchContract: (contractId: string, handleFetched: () => void) => void;
}) {
  const [firstName, setFirstName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [additionalEmails, setAdditionalEmails] = useState<string[]>([]);
  useEffect(() => {
    fetchContract(contractId).then(({ contract }: { contract: Contract }) => {
      // console.log(JSON.stringify(contract));
      if (contract.sourcedEmail) {
        setEmail(contract.sourcedEmail);
      }
      if (contract.additionalEmails) {
        setAdditionalEmails(contract.additionalEmails);
      }
      setFirstName(contract.firstName);
    });
  }, []);

  const validateFields = () =>
    // Validate Emails
    !invalidEmail(email) && validateEmailList(additionalEmails);

  const onConfirm = () => {
    if (validateFields()) {
      close();
      reissueContract({ contractId, creatorEmail: email, additionalEmails }).then((response) => {
        if (response.success) {
          handleRefetchContract(contractId, () => {
            showSuccessNotification({
              message: (
                <Text>
                  Successfully reissued {displayName}&apos;s contract! Click{" "}
                  <Anchor
                    href={`https://www.1stcollab.com/admin/contracts/${contractId}`}
                    target="_blank">
                    here
                  </Anchor>{" "}
                  to view the contract.
                </Text>
              ),
            });
          });
        } else {
          showFailureNotification({ message: response.error });
        }
      });
    }
  };

  const handleAdditionalEmailChange = (emails: string[]) => {
    setAdditionalEmails(emails);
  };
  const handleEmailChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setEmail(event.target.value);
  };

  return (
    <Modal
      opened={opened}
      onClose={close}
      size="md"
      title={
        <Text size="lg" fw="600">
          Are you sure you want to reissue {displayName}&apos;s contract?
        </Text>
      }>
      <Stack gap="xs">
        <Text>
          <b>NOTE:</b> This will send a new contract to the email&apos;s below.
        </Text>
        <TextInput
          label="Creator's Email Address"
          onChange={handleEmailChange}
          value={email}
          error={invalidEmail(email) ? "Invalid Email Address" : null}
        />
        <TagsInput
          label="Additional Email Addresses"
          w={400}
          value={additionalEmails}
          onChange={handleAdditionalEmailChange}
          error={
            !validateEmailList(additionalEmails)
              ? "Invalid Email Addresses was added, please remove it."
              : null
          }
          splitChars={[",", " "]}
        />
      </Stack>
      <Group mt="lg" grow justify="center">
        <Button onClick={close} variant="default" color="gray">
          Cancel
        </Button>
        <Button onClick={onConfirm} disabled={!validateFields()}>
          Confirm
        </Button>
      </Group>
    </Modal>
  );
}
