import { Campaign, CampaignStatus } from "models/Campaign";
import React, { useEffect, useState } from "react";
import { Anchor, Button, Group, Text } from "@mantine/core";
import CardBase from "campaigns/create/common/CardBase";
import { updateCampaign } from "campaigns/create/api/UpdateCampaignApi";
import { useAppDispatch, useAppSelector } from "reduxStore/hooks";
import { checkNeedsCampaignOnboarding } from "reduxStore/meSlice";
import { selectUserNeedsCampaignOnboarding } from "reduxStore/meSelectors";
import { IconAlertCircle } from "@tabler/icons-react";
import Spacer from "components/Spacer";
import { useNavigate } from "react-router-dom";

const GoLiveCampaignCard = ({
  campaign,
  isActive,
  onSaveSuccess,
}: {
  campaign: Campaign | null;
  isActive: boolean;
  onSaveSuccess: (campaignHashId: number) => void;
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const needsToFinishOnboarding = useAppSelector((state) =>
    selectUserNeedsCampaignOnboarding(state, { checkLaunchedCampaigns: false }),
  );
  const [onboardingLoading, setOnboardingLoading] = useState<boolean>(true);

  useEffect(() => {
    const briefsAbortController = new AbortController();
    const activatedCreatorsAbortController = new AbortController();
    const getBrandAbortController = new AbortController();
    const campaignsAbortController = new AbortController();
    dispatch(
      checkNeedsCampaignOnboarding({
        getBrandAbortController,
        briefsAbortController,
        activatedCreatorsAbortController,
        campaignsAbortController,
      }),
    )
      .unwrap()
      .then((finishedWithoutAborting) => {
        if (finishedWithoutAborting) {
          setOnboardingLoading(false);
        }
      });

    return () => {
      briefsAbortController.abort();
      activatedCreatorsAbortController.abort();
      getBrandAbortController.abort();
      campaignsAbortController.abort();
    };
  }, []);

  return (
    <CardBase
      isActive={isActive}
      title="Go Live"
      subtitle="You’re ready to go! When you’re ready, click “Start Campaign” to get going."
      nodeShownWhenActive={
        <>
          <Button
            fullWidth
            variant="filled"
            disabled={
              !campaign || campaign?.status === CampaignStatus.Active || needsToFinishOnboarding
            }
            onClick={() => {
              if (campaign) {
                updateCampaign(campaign.hash_id, { status: CampaignStatus.Active }).then(
                  (response) => {
                    onSaveSuccess(campaign.hash_id);
                  },
                );
              }
            }}>
            Start Campaign
          </Button>
          {!onboardingLoading && needsToFinishOnboarding ? (
            <Group gap={4}>
              <IconAlertCircle color="var(--mantine-color-red-6)" />
              <Text size="sm" c="var(--mantine-color-red-6)">
                You must first complete all the onboarding steps before launching a campaign.{" "}
                <Anchor
                  onClick={() => {
                    navigate("/campaigns");
                  }}
                  c="var(--mantine-color-red-6)"
                  underline="always">
                  Click here to finish onboarding.
                </Anchor>
              </Text>
            </Group>
          ) : null}
        </>
      }
    />
  );
};

export default GoLiveCampaignCard;
