import { API_URL } from "configs/Configs";
import { createRequestWithFirebaseToken, handleResult } from "utils/ApiUtils";

const BASE_CREATOR_DEBUG_URL = `${API_URL}/api/ops/`;

const CREATOR_SET_DEBUG_URL = `${BASE_CREATOR_DEBUG_URL}debug/get_creator_set_entries_for_creator/`;
const CREATOR_ENRICHMENT_DEBUG_URL = `${BASE_CREATOR_DEBUG_URL}debug/get_creator_enrichment_debug_details/`;
const CREATOR_ENRICHMENT_POSSIBLE_VALUES_URL = `${BASE_CREATOR_DEBUG_URL}debug/enrichment_possible_values/`;

export interface CreatorSetDebugDetails {
  creator_set_entry_id?: number;
  creator_id?: number;
  creator_set_id?: number;
  creator_set_name?: string;
  brand_name?: string;
  state?: number;
  internal_review_enqueuer?: string;
  internal_review_enqueue_ts?: number;
}

export interface CreatorSetDebugDetailsResponse {
  success?: boolean;
  results?: CreatorSetDebugDetails[];
}

export interface CreatorEnrichmentDebugDetails {
  creator_id?: number;
  origin_creator_id?: number;
  request_user_id?: string;
  request_user_email?: string;
  first_name?: string;
  creator_email?: string;
  youtube_handle?: string;
  tiktok_handle?: string;
  instagram_handle?: string;
  date_created?: string;
}

export interface CreatorEnrichmentDebugDetailsResponse {
  success?: boolean;
  results?: CreatorEnrichmentDebugDetails[];
}

export interface CreatorEnrichmentPossibleValues {
  id: number;
  possible_emails?: string[];
  possible_first_names?: string[];
  possible_last_names?: string[];
  possible_youtube_handles?: string[];
  possible_youtube_links?: string[];
  possible_tiktok_handles?: string[];
  possible_tiktok_links?: string[];
  possible_instagram_handles?: string[];
  possible_instagram_links?: string[];
  possible_bio_links?: string[];
  last_enrichment_update?: string;
}

export interface CreatorEnrichmentPossibleValuesResponse {
  success?: boolean;
  data?: CreatorEnrichmentPossibleValues;
}

export const fetchCreatorSetDebugDetails = async (
  creatorId: number,
  abortController: AbortController,
): Promise<CreatorSetDebugDetailsResponse> => {
  const endpoint = new URL(CREATOR_SET_DEBUG_URL);
  endpoint.searchParams.append("creator_id", creatorId.toString());
  const request = await createRequestWithFirebaseToken({ url: endpoint, method: "GET" });
  const response = await handleResult(request, abortController);
  return response;
};

export const fetchCreatorEnrichmentDebugDetails = async (
  creatorId: number,
  abortController: AbortController,
): Promise<CreatorEnrichmentDebugDetailsResponse> => {
  const endpoint = new URL(CREATOR_ENRICHMENT_DEBUG_URL);
  endpoint.searchParams.append("creator_id", creatorId.toString());
  const request = await createRequestWithFirebaseToken({ url: endpoint, method: "GET" });
  const response = await handleResult(request, abortController);
  return response;
};

export const fetchCreatorEnrichmentPossibleValues = async (
  creatorId: number,
  abortController: AbortController,
): Promise<CreatorEnrichmentPossibleValuesResponse> => {
  const endpoint = new URL(CREATOR_ENRICHMENT_POSSIBLE_VALUES_URL);
  endpoint.searchParams.append("creator_id", creatorId.toString());
  const request = await createRequestWithFirebaseToken({ url: endpoint, method: "GET" });
  const response = await handleResult(request, abortController);
  return response;
};

export default null;
