import React, { useState } from "react";

import {
  Anchor,
  Badge,
  Button,
  Card,
  Checkbox,
  ComboboxItem,
  Flex,
  Group,
  NumberInput,
  OptionsFilter,
  Select,
  Stack,
  Text,
  Textarea,
  TextInput,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { notifications, Notifications } from "@mantine/notifications";

import { OpsTask } from "models/OpsTask";
import { fromISODateString } from "utils/DateUtils";
import { useAdminAppContext } from "admin/app/AdminAppShell";
import { updateOpsTask } from "admin/api/opsApi";

export const OpsEditTaskCard = ({
  opsTask,
  setTask,
  close,
}: {
  opsTask: OpsTask;
  setTask: (task: OpsTask) => void;
  close: () => void | null;
}) => {
  // this is probably not always available
  const firebaseUser = useAdminAppContext()?.user;
  const opsUsers = useAdminAppContext()?.opsUsers;
  const creatorSets = useAdminAppContext()?.creatorSets;

  const [loading, setLoading] = useState(false);

  const optionsFilter: OptionsFilter = ({ options, search }) => {
    const filtered = (options as ComboboxItem[]).filter((option) =>
      option.label.toLowerCase().trim().includes(search.toLowerCase().trim()),
    );

    filtered.sort((a, b) => a.label.localeCompare(b.label));
    return filtered;
  };

  const opsUserData = opsUsers.map((user) => ({
    value: user.key,
    label: user.name,
  }));

  const creatorSetData = creatorSets.map((cs) => ({
    value: cs.id.toString(),
    label: cs.name,
  }));

  const form = useForm({
    initialValues: {
      name: opsTask?.name,
      description: opsTask?.description,
      due_date: opsTask?.due_date != null ? fromISODateString(opsTask?.due_date) : null,
      type: opsTask?.type,
      value: opsTask?.value,
      opsUser: opsTask?.assigned_user_details?.key,
      campaign_id: opsTask?.campaign?.id,
      creator_set_id: opsTask?.creator_set_details?.id?.toString() || null,
      completed: opsTask?.completed,
    },
  });

  const formatTask = (formValues: { [key: string]: any }) => {
    const task = {
      name: formValues.name,
      // type: formValues.type,
      assigned_user: formValues.opsUser,
      description: formValues.description,
      value: formValues.value,
      // estimated_workload: formValues.estimatedWorkload,
      creator_set: formValues.creatorSet,
      due_date: formValues.due_date != null ? formValues.due_date.toISOString() : null,
    };
    return task;
  };

  const updateTask = () => {
    setLoading(true);
    updateOpsTask(firebaseUser, opsTask.id, formatTask(form.values))
      .then((response) => {
        if (response != null && response?.message != null) {
          notifications.show({
            color: "red",
            title: "Error with updating the task!",
            message: `${response.message}`,
            autoClose: false,
          });
        }
        if (response != null && response?.id != null) {
          notifications.show({
            title: "Task updated successfully!",
            message: (
              <Anchor
                href={`https://www.1stcollab.com/admin/ops/tasks?taskId=${response.id}`}
                target="_blank">
                View task details.
              </Anchor>
            ),
            autoClose: false,
          });
          form.resetDirty();
          // also update the upstream task
          const newTask = response;
          setTask(newTask);
          if (close) {
            close();
          }
        } else {
          notifications.show({
            color: "red",
            title: "Error with updating the task!",
            message: "Unknown error.",
            autoClose: false,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Notifications />
      <form onSubmit={form.onSubmit(() => updateTask())}>
        <Card w={800} shadow="xs" padding="md" radius="md" withBorder>
          <Card.Section inheritPadding withBorder py="sm">
            <Stack>
              <Group justify="space-between">
                <Group align="flex-end">
                  <TextInput
                    label="Task Name"
                    placeholder="Task Name"
                    required
                    {...form.getInputProps("name")}
                  />
                  <DatePickerInput
                    allowDeselect
                    {...form.getInputProps("due_date")}
                    label="Due Date"
                  />
                  <Checkbox
                    label="Completed"
                    {...form.getInputProps("completed", { type: "checkbox" })}
                  />
                </Group>
                <Group gap={4}>
                  <Select
                    label="Ops User"
                    data={opsUserData}
                    /* eslint-disable react/jsx-props-no-spreading */
                    {...form.getInputProps("opsUser")}
                    filter={optionsFilter}
                    w={300}
                  />
                </Group>
              </Group>
            </Stack>
          </Card.Section>
          <Card.Section inheritPadding withBorder py="sm">
            <Stack>
              <Text fw={500}>Task Details</Text>
              <Stack gap="xs">
                <Badge variant="light" color="cyan">{`Type: ${opsTask?.type}`}</Badge>
                <Select
                  label="Creator Set"
                  data={creatorSetData}
                  {...form.getInputProps("creator_set_id")}
                  filter={optionsFilter}
                  w={300}
                  searchable
                />
              </Stack>
              <Stack gap={4}>
                <Textarea label="Description" {...form.getInputProps("description")} />
              </Stack>
              <Stack gap={4}>
                <NumberInput
                  w={300}
                  label="Value"
                  description="Optional value field for storing task count (like # of creators to source)."
                  {...form.getInputProps("value")}
                />{" "}
              </Stack>
              <Flex justify="center">
                <Button type="submit" size="sm">
                  Save
                </Button>
              </Flex>
            </Stack>
          </Card.Section>
        </Card>
      </form>
    </>
  );
};

export default OpsEditTaskCard;
