import React, { useEffect, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import "gestalt/dist/gestalt.css";

import AuthLoading from "auth/AuthUtils";
import { useUser } from "utils/UserContext";
import "auth/styles/auth-styles.css";
import { User } from "models/User";
import NewAuth from "auth/NewAuth";

type Props = {
  isLogin: boolean;
};

const goNext = (navigate: NavigateFunction, isLogin: boolean, currentUser: User) => {
  const isUnauthSearch = localStorage.getItem("unauth-search") === "true";
  if (isUnauthSearch) {
    navigate("/campaigns/discover");
  } else if (isLogin && currentUser && currentUser.completed_onboarding) {
    if (currentUser.brand) {
      navigate("/campaigns");
    } else {
      navigate(`/${currentUser.username}`);
    }
  } else if (currentUser && !currentUser?.completed_onboarding) {
    // only navigate to onboarding if the user is logged in AND has not completed onboarding
    navigate("/onboarding");
  } else {
    navigate("/");
  }
};

const Auth = ({ isLogin }: Props) => {
  const [currentUser, isLoading, updateUser] = useUser();

  const [authLoadingState, setLoading] = useState(AuthLoading.NOT_LOADING);
  const [showLoadingSpinner, setLoadingSpinner] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (authLoadingState === AuthLoading.LOADING) {
      setLoadingSpinner(true);
      return;
    }
    setLoadingSpinner(false);
    if (authLoadingState === AuthLoading.FINISHED) {
      goNext(navigate, isLogin, currentUser);
      return;
    }
    if (currentUser != null) {
      if (currentUser.completed_onboarding) {
        navigate("/");
      } else {
        navigate("/onboarding");
      }
    }
  }, [authLoadingState, currentUser]);

  return <NewAuth showLoadingUI={showLoadingSpinner} setLoading={setLoading} isLogin={isLogin} />;
};

export default Auth;
