import React, { useEffect, useState } from "react";
import { fetchContractReviewData, saveContractReview } from "components/contracts/common/Api";
import { CreatorDetails } from "components/discovery/Datamodels";
import { Center, Container, Loader, Stack, Title } from "@mantine/core";
import { ContractBrandReview, ContractReviewStatus } from "components/contracts/brand_review/types";
import { Notifications, notifications } from "@mantine/notifications";
import NothingHereComponent from "components/general/NothingHere";
import { ContractApprovalCard } from "components/contracts/brand_review/ContractBrandReviewCard";

export default function ContractApprovalFeed({
  adGroupId,
  campaignId,
  showAdminOptions,
}: {
  adGroupId?: string;
  campaignId?: string;
  showAdminOptions?: boolean;
}) {
  if (!campaignId && !campaignId && !adGroupId) {
    return null;
  }

  if (campaignId && adGroupId) {
    throw new Error("Cannot specify both campaignId and adGroupId");
  }
  const [creatorDetails, setCreatorDetails] = useState<Record<number, CreatorDetails>>({});
  const [contractReviews, setContractReviews] = useState<ContractBrandReview[]>([]);
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    fetchContractReviewData(campaignId, adGroupId, showAdminOptions).then((data) => {
      setContractReviews(data.contract_reviews);
      setCreatorDetails(data.creator_details);
      setContractReviews((prevReviews) => {
        const updatedReviews = prevReviews.map((review) => {
          if (review.brand_max_price === 0) {
            return {
              ...review,
              brand_max_price: review.recommended_price,
            };
          }
          return review;
        });
        return updatedReviews;
      });
      setLoaded(true);
    });
  }, []);

  const declineCreator = (contractReviewId: number, index: number, brandReview: boolean) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { brand_max_price, brand_feedback } = contractReviews[index];

    const brandStatus = brandReview ? ContractReviewStatus.REJECTED : undefined;
    const internalStatus = brandReview ? undefined : ContractReviewStatus.REJECTED;
    setLoaded(false);
    saveContractReview(
      contractReviewId,
      brand_feedback,
      brandStatus,
      internalStatus,
      brand_max_price,
    )
      .then(() => {
        setLoaded(true);
        notifications.show({
          color: "blue",
          title: "Declining Creator",
          message: "Creator has be declined successfully!",
          autoClose: false,
        });
        setContractReviews((prevReviews) => {
          const updatedReviews = prevReviews.map((review) => {
            if (review.id === contractReviewId) {
              return {
                ...review,
                brand_review_status: brandStatus,
              };
            }
            return review;
          });
          return updatedReviews;
        });
      })
      .catch(() => {
        notifications.show({
          color: "red",
          title: "Error Declining Creator",
          message: "Error occured while saving!",
          autoClose: true,
        });
        setLoaded(true);
      });
  };

  const approveOffer = (contractReviewId: number, index: number, brandReview: boolean) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { brand_max_price, brand_feedback } = contractReviews[index];
    const brandStatus = brandReview ? ContractReviewStatus.APPROVED : undefined;
    const internalStatus = brandReview ? undefined : ContractReviewStatus.APPROVED;
    setLoaded(false);

    saveContractReview(
      contractReviewId,
      brand_feedback,
      brandStatus,
      internalStatus,
      brand_max_price,
    )
      .then(() => {
        setLoaded(true);

        notifications.show({
          color: "blue",
          title: "Approve Creator with Max Offer",
          message: "Creator has been successfully Approved with new Max Offer!",
          autoClose: false,
        });
        setContractReviews((prevReviews) => {
          const updatedReviews = prevReviews.map((review) => {
            if (review.id === contractReviewId) {
              return {
                ...review,
                brand_review_status: brandStatus,
              };
            }
            return review;
          });
          return updatedReviews;
        });
      })
      .catch(() => {
        setLoaded(true);

        notifications.show({
          color: "red",
          title: "Error Approve Creator with Max Offer",
          message: "Error occured while saving!",
          autoClose: true,
        });
      });
  };

  return (
    <Container>
      <Notifications />
      <Stack gap="xs">
        {loaded && !showAdminOptions && (
          <Title order={2} fw="500">
            Contract Review
          </Title>
        )}
        {!loaded && (
          <Center mt="xl">
            <Loader />
          </Center>
        )}
        {loaded && contractReviews.length === 0 && <NothingHereComponent />}
        {contractReviews.map((contractReview, index) => {
          const creatorDetail = creatorDetails[contractReview.creator_id];
          const {
            deliverables,
            deliverable_package_usage_rights: usageRightsDuration,
            deliverable_package_usage_rights_price: usagePrice,
            deliverable_package_creator_asking_usage_rights_price: creatorAskingUsageRightsPrice,
          } = contractReview;
          const inReview = contractReview.brand_review_status === ContractReviewStatus.IN_REVIEW;
          const accepted = contractReview.brand_review_status === ContractReviewStatus.APPROVED;
          const rejected = contractReview.brand_review_status === ContractReviewStatus.REJECTED;

          return (
            <ContractApprovalCard
              key={contractReview.id}
              contractReview={contractReview}
              deliverables={deliverables}
              deliverablePackageInfo={{
                usageRightsDuration,
                usagePrice,
                creatorAskingUsageRightsPrice,
              }}
              creatorDetail={creatorDetail}
              isAccepted={accepted}
              isRejected={rejected}
              isInReview={inReview}
              showAdminOptions={showAdminOptions}
              index={index}
              setContractReviews={setContractReviews}
              approveOffer={approveOffer}
              declineCreator={declineCreator}
            />
          );
        })}
      </Stack>
    </Container>
  );
}
