import { API_URL } from "configs/Configs";
import { handleResult } from "utils/ApiUtils";
import { User as FirebaseUser } from "firebase/auth";

export const TIKTOK_STATS_URL = `${API_URL}/api/creator/get_tiktok_stats/`;
export const YT_STATS_URL = `${API_URL}/api/creator/get_youtube_stats/`;
export const IG_STATS_URL = `${API_URL}/api/creator/get_instagram_stats/`;

export interface TikTokStats {
  has_90d_videos: string;
  num_videos: number;
  num_videos_90d: number;
  num_videos_30d: number;
  num_videos_14d: number;
  num_videos_7d: number;
  views: number;
  views_90d: number;
  views_30d: number;
  views_14d: number;
  views_7d: number;
  avg_views: number;
  avg_views_90d: number;
  lowest_view_count: number;
  likes: number;
  comments: number;
  shares: number;
  median_views: number;
  median_views_90d: number;
  median_views_30d: number;
  median_views_14d: number;
  median_views_7d: number;
  median_likes: number;
  median_comments: number;
  engagement_rate: number;
  comment_rate: number;
  p25_views_90d: number;
  p25_views_30d: number;
  p25_views_14d: number;
  p25_views_7d: number;
  p40_views_90d: number;
  p40_views_30d: number;
  p40_views_14d: number;
  p40_views_7d: number;
  predicted_views_lower: number;
  predicted_views_upper: number;
}

export interface YoutubeStats {
  has_90d_videos: string;
  num_videos: number;
  num_videos_90d: number;
  num_videos_30d: number;
  num_videos_14d: number;
  num_videos_7d: number;
  views: number;
  views_90d: number;
  views_30d: number;
  views_14d: number;
  views_7d: number;
  avg_views: number;
  avg_views_90d: number;
  lowest_view_count: number;
  likes: number;
  likes_90d: number;
  likes_30d: number;
  likes_14d: number;
  likes_7d: number;
  comments: number;
  comments_90d: number;
  comments_30d: number;
  comments_14d: number;
  comments_7d: number;
  favorites: number;
  favorites_90d: number;
  favorites_30d: number;
  favorites_14d: number;
  favorites_7d: number;
  median_views: number;
  median_views_90d: number;
  median_views_30d: number;
  median_views_14d: number;
  median_views_7d: number;
  median_likes: number;
  median_likes_90d: number;
  median_likes_30d: number;
  median_likes_14d: number;
  median_likes_7d: number;
  median_comments: number;
  median_comments_90d: number;
  median_comments_30d: number;
  median_comments_14d: number;
  median_comments_7d: number;
  median_engagements: number;
  median_engagements_90d: number;
  median_engagements_30d: number;
  median_engagements_14d: number;
  median_engagements_7d: number;
  total_videos: number;
  engagement_rate: number;
  comment_rate: number;
  p25_views_90d: number;
  p25_views_30d: number;
  p25_views_14d: number;
  p25_views_7d: number;
  p40_views_90d: number;
  p40_views_30d: number;
  p40_views_14d: number;
  p40_views_7d: number;
  predicted_views_upper?: number;
  predicted_views_lower: number;
}

export interface InstagramStats {
  has_90d_videos: string;
  num_videos: number;
  num_videos_90d: number;
  median_views: number;
  avg_views: number;
  avg_views_90d: number;
  p25_views: number;
  p40_views: number;
  lowest_view_count: number;
  median_likes: number;
  median_comments: number;
  engagement_rate: number;
  comment_rate: number;
  predicted_views_lower: number;
  predicted_views_upper: number;
}

export interface TikTokStatsResponse {
  success?: boolean;
  message?: string;
  data?: TikTokStats;
}

export interface YoutubeStatsResponse {
  success?: boolean;
  message?: string;
  data?: YoutubeStats;
}

export interface InstagramStatsResponse {
  success?: boolean;
  message?: string;
  data?: InstagramStats;
}

export const fetchTikTokStatsByCreatorId = async (
  requestUser: FirebaseUser,
  creatorId: number,
  setTikTokStats: (tikTokStats: TikTokStats) => void,
  abortController: AbortController,
) => {
  const firebaseToken = await requestUser.getIdToken();
  const endpoint = `${TIKTOK_STATS_URL}?creatorId=${creatorId}`;
  const request = new Request(endpoint, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`, // Assuming you are sending the token in the Authorization header
    },
    signal: abortController.signal,
  });

  const response: TikTokStatsResponse = await handleResult(request, abortController);
  if (response?.data == null) {
    setTikTokStats(null);
    return;
  }
  setTikTokStats(response.data);
};

export const fetchYoutubeStatsByCreatorId = async ({
  requestUser,
  creatorId,
  isShorts,
  setYoutubeStats,
  abortController,
  isIntegration = false,
}: {
  requestUser: FirebaseUser;
  creatorId: number;
  isShorts: boolean;
  setYoutubeStats: (youtubeStats: YoutubeStats) => void;
  abortController: AbortController;
  isIntegration?: boolean;
}) => {
  const firebaseToken = await requestUser.getIdToken();
  const endpoint = `${YT_STATS_URL}?creatorId=${creatorId}&isShorts=${isShorts}&isIntegration=${isIntegration}`;
  const request = new Request(endpoint, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`, // Assuming you are sending the token in the Authorization header
    },
    signal: abortController.signal,
  });

  const response: YoutubeStatsResponse = await handleResult(request, abortController);
  if (response?.data == null) {
    setYoutubeStats(null);
    return;
  }
  setYoutubeStats(response.data);
};

export const fetchInstagramStatsByCreatorId = async (
  requestUser: FirebaseUser,
  creatorId: number,
  setInstagramStats: (instagramStats: InstagramStats) => void,
  abortController: AbortController,
) => {
  const firebaseToken = await requestUser.getIdToken();
  const endpoint = `${IG_STATS_URL}?creatorId=${creatorId}`;
  const request = new Request(endpoint, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`, // Assuming you are sending the token in the Authorization header
    },
    signal: abortController.signal,
  });

  const response: InstagramStatsResponse = await handleResult(request, abortController);
  if (response?.data == null) {
    setInstagramStats(null);
    return;
  }
  setInstagramStats(response.data);
};

export default null;
