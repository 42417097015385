import React, { useState } from "react";
import { User } from "firebase/auth";
import { User as UserProfile } from "models/User";
import LayoutBase from "components/LayoutBase";
import { UserLookup } from "admin/AdminUtils";
import { getAdminUser } from "admin/AdminLayout";
import { UserEdit } from "admin/user_edit/UserEditConstants";

import "gestalt/dist/gestalt.css";
import LoadingScreen from "components/LoadingScreen";
import Spacer from "components/Spacer";
import { Box, Container, Text } from "gestalt";
import EditUser from "admin/user_edit/EditUser";

const ErrorBody = () => (
  <Box display="flex" direction="column">
    <Spacer height={32} />
    <Text color="error">User not found. Please check the username.</Text>
  </Box>
);

const AdminUserEdit = () => {
  const adminUser: User = getAdminUser();
  const [user, setUser] = useState(null);
  const [editState, setEditState] = useState(UserEdit.INIT);

  const onLoading = () => {
    setEditState(UserEdit.LOADING);
  };

  const onSuccess = (foundUser: UserProfile | null) => {
    setEditState(UserEdit.SUCCESS);
    setUser(foundUser);
  };

  const onError = () => {
    setEditState(UserEdit.ERROR);
  };

  const getViewBasedOnLoadingState = () => {
    switch (editState) {
      case UserEdit.INIT:
        return null;
      case UserEdit.LOADING:
        return <LoadingScreen />;
      case UserEdit.SUCCESS:
        return <EditUser user={user} />;
      case UserEdit.ERROR:
        return <ErrorBody />;
      default:
        return null;
    }
  };

  return (
    <LayoutBase>
      <Container>
        <UserLookup
          requestUser={adminUser}
          onLoading={onLoading}
          onSuccess={onSuccess}
          onError={onError}
        />
        {getViewBasedOnLoadingState()}
      </Container>
    </LayoutBase>
  );
};

export default AdminUserEdit;
