import React from "react";

import { Stack, Tabs, Text, Title } from "@mantine/core";
import ContractsBudgetTab from "campaigns/main/campaignPage/contracts_overview/ContractsBudgetTab";
import ContractsTab from "campaigns/main/campaignPage/contracts_overview/ContractsTab";
import DeliverablesTab from "campaigns/main/campaignPage/contracts_overview/DeliverablesTab";
import { useNavigate, useParams } from "react-router-dom";

const blueSix = "#228BE6";
const darkOne = "#A6A7AB";

// Only valid tabs, anything else is assumed to be contracts
const VALID_TABS = new Set(["contracts", "deliverables", "budget"]);

const ContractsOverview = () => {
  const navigate = useNavigate();
  // Active tab now sourced from the URL
  const { activeTab } = useParams();

  const tabValue = VALID_TABS.has(activeTab) ? activeTab : "contracts";

  return (
    <Stack>
      <Title size="h2">Contracts</Title>
      <Tabs
        // Empty or invalid tab redirects into contracts
        value={tabValue}
        color={blueSix}
        onChange={(value) => navigate(`/campaigns/contracts_overview/${value}`)}>
        <Tabs.List>
          <Tabs.Tab value="contracts" color={tabValue === "contracts" ? blueSix : darkOne}>
            <Text inherit c={tabValue === "contracts" ? blueSix : darkOne}>
              Contracts
            </Text>
          </Tabs.Tab>
          <Tabs.Tab value="deliverables" color={tabValue === "deliverables" ? blueSix : darkOne}>
            <Text inherit c={tabValue === "deliverables" ? blueSix : darkOne}>
              Deliverables
            </Text>
          </Tabs.Tab>
          <Tabs.Tab value="budget" color={tabValue === "budget" ? blueSix : darkOne}>
            <Text inherit c={tabValue === "budget" ? blueSix : darkOne}>
              Budget
            </Text>
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="budget">
          <ContractsBudgetTab />
        </Tabs.Panel>
        <Tabs.Panel value="deliverables">
          <DeliverablesTab />
        </Tabs.Panel>
        <Tabs.Panel value="contracts">
          <ContractsTab />
        </Tabs.Panel>
      </Tabs>
    </Stack>
  );
};

export default ContractsOverview;
