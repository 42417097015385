import { createRequestWithFirebaseToken, handleResult } from "utils/ApiUtils";

import { API_URL } from "configs/Configs";

export const manualUploadOpportunityToClose = async (opportunityId: number) => {
  const request = await createRequestWithFirebaseToken({
    url: `${API_URL}/api/ops/opportunities/upload_opportunity_for_manual_outreach/`,
    method: "POST",
    body: JSON.stringify({ opportunity_id: opportunityId }),
  });
  const response = await handleResult(request);
  return response;
};

export default null;
