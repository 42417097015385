import React, { useState } from "react";
import { Box, Button, SelectList, Text } from "gestalt";
import "gestalt/dist/gestalt.css";
import { User } from "firebase/auth";

import { MembershipTypes, User as UserProfile } from "models/User";
import Spacer from "components/Spacer";
import { updateUserInfo } from "utils/UserUtils";
import { getAdminUser } from "admin/AdminLayout";

const saveUserInfo = async (adminUser: User, user: UserProfile) => {
  const firebaseToken = await adminUser.getIdToken();
  const response = await updateUserInfo(user, firebaseToken);
  // TODO (victoria 3.2023): handle success / error
};

const SaveButton = ({ user }: { user: UserProfile }) => {
  const adminUser: User = getAdminUser();

  return (
    <Button
      text="Save"
      color="blue"
      onClick={() => {
        saveUserInfo(adminUser, user);
      }}
    />
  );
};

const EditUser = ({ user }: { user: UserProfile }) => {
  const [currentUserProfile, setCurrentUserProfile] = useState(user);

  const membershipTypesList = Object.keys(MembershipTypes);
  const membershipKeys = membershipTypesList.splice(0, membershipTypesList.length / 2);

  return (
    <Box paddingY={12} direction="column">
      <Box direction="row" display="flex">
        <Text weight="bold">Name:</Text>
        <Spacer width={16} />
        <Text>{currentUserProfile.name}</Text>
      </Box>
      <Spacer height={16} />
      <Box direction="row" display="flex">
        <SelectList
          id="membershipSelectList"
          label="Membership"
          onChange={({ value }) => {
            const newUser = {
              ...currentUserProfile,
              ...{ membership_type: parseInt(value, 10) },
            };
            setCurrentUserProfile(newUser); // optimistically update user to show changes
          }}
          size="lg"
          value={currentUserProfile.membership_type.toString()}>
          {membershipKeys.map((entry) => (
            <SelectList.Option
              key={entry}
              label={MembershipTypes[parseInt(entry, 10)]}
              value={entry}
            />
          ))}
        </SelectList>
      </Box>
      <Spacer height={32} />
      <SaveButton user={currentUserProfile} />
    </Box>
  );
};

export default EditUser;
