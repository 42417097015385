import React, { useEffect, useState } from "react";

import { User } from "firebase/auth";

import { ActionIcon, Menu, Tooltip } from "@mantine/core";

import { useDisclosure } from "@mantine/hooks";

import {
  IconArchive,
  IconArrowForward,
  IconBrandStripe,
  IconCash,
  IconCircleCheck,
  IconMail,
  IconPlus,
  IconRestore,
  IconRotate,
  IconSettings,
  IconTrash,
} from "@tabler/icons-react";

import {
  archiveContract,
  deleteContract,
  markContractAsRepeat,
  markEligibleForBonus,
  markReadyForPayment,
  resetStripeAccount,
  unarchiveContract,
  updateContractStatus,
} from "components/contracts/common/Api";
import { showFailureNotification, showSuccessNotification } from "components/common/Notifications";

import ManualPaymentModals from "components/contracts/dashboard/ManualPaymentModals";
import ConfirmModal from "components/contracts/common/ConfirmModal";
import ViewContractLink from "components/contracts/common/ViewContractLink";
import ViewPaymentsModal from "components/contracts/dashboard/ViewPaymentsModal";

import {
  ContractStatus,
  PaymentStatus,
  RepeatContractValue,
} from "components/contracts/common/Common";

import { Payment } from "components/contracts/models/Payment";
import ReissueContractModal from "components/contracts/dashboard/ReissueContractModal";

const REPEATDEAL_MENU_MESSAGE = {
  [RepeatContractValue.MARKED_FOR_REPEAT]: "Unmark next repeat",
  [RepeatContractValue.ELIGIBLE_FOR_REPEAT]: "Mark for a repeat",
  [RepeatContractValue.REPEAT_INFLIGHT]: "Repeat contract in progress",
  [RepeatContractValue.NOT_ELIGIBLE]: "Not eligible for repeat",
};

const REPEATDEAL_SUCCESS: { [key in RepeatContractValue]?: string } = {
  [RepeatContractValue.MARKED_FOR_REPEAT]: "Unmarked contract for repeat",
  [RepeatContractValue.ELIGIBLE_FOR_REPEAT]: "Marked contract for repeat",
};

const REPEATDEAL_COLOR = {
  [RepeatContractValue.MARKED_FOR_REPEAT]: "green",
  [RepeatContractValue.ELIGIBLE_FOR_REPEAT]: "black",
  [RepeatContractValue.REPEAT_INFLIGHT]: "yellow 7",
  [RepeatContractValue.NOT_ELIGIBLE]: "gray",
};

const VALID_REPEAT_VALUES: { [key in RepeatContractValue]?: RepeatContractValue } = {
  [RepeatContractValue.MARKED_FOR_REPEAT]: RepeatContractValue.ELIGIBLE_FOR_REPEAT,
  [RepeatContractValue.ELIGIBLE_FOR_REPEAT]: RepeatContractValue.MARKED_FOR_REPEAT,
};

const MarkRepeatContractMenuItem = ({
  contractId,
  repeatState,
  handleRefetchContract,
  setIsProcessing,
  close,
}: {
  contractId: string;
  repeatState: RepeatContractValue;
  handleRefetchContract: (contractId: string, handleFetched: () => void) => void;
  setIsProcessing: (isProcessing: boolean) => void;
  close: () => void;
}) => {
  if (repeatState === RepeatContractValue.NOT_ELIGIBLE) {
    return null;
  }

  const [repeatVal, setRepeatVal] = useState<RepeatContractValue>(repeatState);

  useEffect(() => {
    console.log("repeatVal updated to: ", repeatVal);
  }, [repeatVal]);

  return (
    <Menu.Item
      disabled={repeatVal === RepeatContractValue.REPEAT_INFLIGHT}
      leftSection={
        <IconArrowForward stroke-width="3px" size={14} color={REPEATDEAL_COLOR[repeatVal]} />
      }
      onClick={() => {
        if (!(repeatVal in VALID_REPEAT_VALUES)) {
          return;
        }
        const newRepeatVal = VALID_REPEAT_VALUES[repeatVal];
        console.log("newRepeatVal", newRepeatVal, "old", repeatVal);
        markContractAsRepeat({
          contractId,
          canRepeat: newRepeatVal === RepeatContractValue.MARKED_FOR_REPEAT,
        }).then((response) => {
          if (response.success) {
            showSuccessNotification({
              message: `${REPEATDEAL_SUCCESS[repeatVal]}`,
            });
          } else {
            showFailureNotification({ message: response.error });
          }

          handleRefetchContract(contractId, () => {
            setIsProcessing(false);
            close();
          });
        });
        setRepeatVal(newRepeatVal);
      }}>
      {REPEATDEAL_MENU_MESSAGE[repeatVal]}
    </Menu.Item>
  );
};

function ActionMenu({
  user,
  contractId,
  archived,
  displayName,
  contractStatus,
  paymentStatus,
  bonusPaymentStatus,
  readyForPayment,
  hasBonusCondition,
  meetsBonusCondition,
  payments,
  stripeAccountUrl,
  closeUrl,
  handleRefetchContract,
  handleDeleteContract,
  hideDelete,
  remainingAmountInMinorUnits,
  repeatState,
}: {
  user: User;
  contractId: string;
  archived: boolean;
  displayName: string;
  contractStatus: ContractStatus;
  paymentStatus: PaymentStatus;
  bonusPaymentStatus: PaymentStatus;
  readyForPayment: boolean;
  hasBonusCondition: boolean;
  meetsBonusCondition: boolean;
  payments: Payment[];
  stripeAccountUrl: string;
  closeUrl: string;
  handleRefetchContract: (contractId: string, handleFetched: () => void) => void;
  handleDeleteContract: (contractId: string) => void;
  hideDelete?: boolean;
  remainingAmountInMinorUnits?: number;
  repeatState: RepeatContractValue;
}) {
  const [opened, { open, close }] = useDisclosure();
  const [modalMessage, setModalMessage] = React.useState<string>("");
  const [modalButtonHandler, setModalButtonHandler] = React.useState<() => void>(() => () => {});
  const [isProcessing, setIsProcessing] = React.useState<boolean>(false);

  const [openedManualPayment, { open: openManualPayment, close: closeManualPayment }] =
    useDisclosure();
  const [openedViewPayments, { open: openViewPayments, close: closeViewPayments }] =
    useDisclosure();

  const hideManualPayment = remainingAmountInMinorUnits === undefined;
  const showReissueContract = contractStatus === ContractStatus.REJECTED;
  const [openedReissueContract, { open: openReissueContract, close: closeReissueContract }] =
    useDisclosure();

  const handleMarkReadyForPaymentAction = (successMessage: string, failureMessage: string) => {
    setModalMessage(
      `Are you sure you want to mark ${displayName}'s contract as ready for payment?`,
    );
    setModalButtonHandler(() => () => {
      setIsProcessing(true);
      markReadyForPayment(contractId)
        .then((response) => {
          if (response.success) {
            showSuccessNotification({ message: successMessage });
          } else {
            showFailureNotification({ message: response.error });
          }

          handleRefetchContract(contractId, () => {
            setIsProcessing(false);
            close();
          });
        })
        .catch(() => {
          showFailureNotification({ message: failureMessage });
          setIsProcessing(false);
          close();
        });
    });
    open();
  };

  const handleMarkEligibleForBonusAction = (successMessage: string, failureMessage: string) => {
    setModalMessage(
      `Are you sure you want to mark ${displayName}'s contract as eligible for bonus?`,
    );
    setModalButtonHandler(() => () => {
      setIsProcessing(true);
      markEligibleForBonus(contractId)
        .then((response) => {
          if (response.success) {
            showSuccessNotification({ message: successMessage });
          } else {
            showFailureNotification({ message: response.error });
          }

          handleRefetchContract(contractId, () => {
            setIsProcessing(false);
            close();
          });
        })
        .catch(() => {
          showFailureNotification({ message: failureMessage });
          setIsProcessing(false);
          close();
        });
    });
    open();
  };

  const handleUpdateContractStatusAction = (
    successMessage: string,
    failureMessage: string,
    newContractStatus?: ContractStatus,
    newPaymentStatus?: PaymentStatus,
  ) => {
    if (newContractStatus) {
      setModalMessage(`Are you sure you want to mark ${displayName}'s contract complete?`);
    } else if (newPaymentStatus) {
      setModalMessage(`Are you sure you want to mark ${displayName}'s contract as fully paid?`);
    } else {
      setModalMessage("Invalid state");
    }
    setModalButtonHandler(() => () => {
      setIsProcessing(true);
      updateContractStatus(contractId, newContractStatus, newPaymentStatus)
        .then((response) => {
          if (response.success) {
            showSuccessNotification({ message: successMessage });
          } else {
            showFailureNotification({ message: response.error });
          }

          handleRefetchContract(contractId, () => {
            setIsProcessing(false);
            close();
          });
        })
        .catch(() => {
          showFailureNotification({ message: failureMessage });
          setIsProcessing(false);
          close();
        });
    });
    open();
  };

  const handleDeleteContractAction = (successMessage: string, failureMessage: string) => {
    setModalMessage(`Are you sure you want to delete ${displayName}'s contract?`);
    setModalButtonHandler(() => () => {
      setIsProcessing(true);
      deleteContract(contractId)
        .then((response) => {
          if (response.success) {
            showSuccessNotification({ message: successMessage });
          } else {
            showFailureNotification({ message: response.error });
          }
          setIsProcessing(false);
          close();
        })
        .catch(() => {
          showFailureNotification({ message: failureMessage });
          setIsProcessing(false);
          close();
        });
    });
    open();
  };

  const handleResetStripeAccount = () => {
    setModalMessage(`Are you sure you want to reset ${displayName}'s Stripe Account?`);
    setModalButtonHandler(() => () => {
      setIsProcessing(true);
      resetStripeAccount({ contractId })
        .then((response) => {
          if (response.success) {
            showSuccessNotification({
              message: `Successfully reset ${displayName}'s Stripe Account!`,
            });
            handleRefetchContract(contractId, () => {
              setIsProcessing(false);
              close();
            });
          } else {
            showFailureNotification({ message: response.error });
          }
        })
        .catch(() => {
          showFailureNotification({ message: `Failed to Reset ${displayName}'s Stripe Account.` });
          setIsProcessing(false);
          close();
        });
    });
    open();
  };

  const handleArchiveOrUnarchiveContractAction = (
    archive: boolean,
    successMessage: string,
    failureMessage: string,
  ) => {
    setModalMessage(
      `Are you sure you want to ${archive ? "archive" : "unarchive"} ${displayName}'s contract?`,
    );
    setModalButtonHandler(() => () => {
      setIsProcessing(true);

      const apiFunction = archive ? archiveContract : unarchiveContract;

      apiFunction({ contractId })
        .then((response) => {
          if (response.success) {
            showSuccessNotification({ message: successMessage });
          } else {
            showFailureNotification({ message: response.error });
          }

          handleRefetchContract(contractId, () => {
            setIsProcessing(false);
            close();
          });
        })
        .catch(() => {
          showFailureNotification({ message: `failureMessage` });
          setIsProcessing(false);
          close();
        });
    });
    open();
  };

  return (
    <>
      {!hideManualPayment && (
        <ManualPaymentModals
          user={user} // TODO (victoria 3.2024): using for display name - need to get rid of this
          contractId={contractId}
          remainingAmountInMinorUnits={remainingAmountInMinorUnits}
          displayName={displayName}
          opened={openedManualPayment}
          open={openManualPayment}
          close={closeManualPayment}
          handleRefetchContract={handleRefetchContract}
        />
      )}
      {showReissueContract && (
        <ReissueContractModal
          contractId={contractId}
          opened={openedReissueContract}
          open={openReissueContract}
          displayName={displayName}
          close={closeReissueContract}
          handleRefetchContract={handleRefetchContract}
        />
      )}
      <ConfirmModal
        modalMessage={modalMessage}
        onConfirm={modalButtonHandler}
        isProcessing={isProcessing}
        opened={opened}
        close={close}
      />
      <ViewPaymentsModal
        payments={payments}
        opened={openedViewPayments}
        close={closeViewPayments}
        hideCampaignName
      />
      <Menu shadow="md" width={220}>
        <Menu.Target>
          <Tooltip label="Edit Contract">
            <ActionIcon variant="subtle" color="gray">
              <IconSettings size="1rem" />
            </ActionIcon>
          </Tooltip>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Item
            disabled={!closeUrl}
            leftSection={<IconMail size={14} />}
            onClick={() => window.open(closeUrl, "_blank")}>
            View in Close
          </Menu.Item>
          <Menu.Item
            disabled={!stripeAccountUrl}
            leftSection={<IconBrandStripe size={14} />}
            onClick={() => window.open(stripeAccountUrl, "_blank")}>
            View Stripe Account
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item
            disabled={!payments || !payments.length || payments.length === 0}
            leftSection={<IconCash size={14} />}
            onClick={openViewPayments}>
            View Past Payments
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item
            disabled={
              archived ||
              readyForPayment ||
              contractStatus < ContractStatus.ACCEPTED ||
              paymentStatus === PaymentStatus.FULLY_PAID
            }
            leftSection={<IconCircleCheck size={14} />}
            onClick={() =>
              handleMarkReadyForPaymentAction(
                `Successfully marked ${displayName}'s contract as ready for payment.`,
                `Failed to mark ${displayName}'s contract as ready for payment.`,
              )
            }>
            Mark Ready for Payment
          </Menu.Item>
          <Menu.Item
            disabled={archived || paymentStatus !== PaymentStatus.UNPAID || !stripeAccountUrl}
            leftSection={<IconRotate size={14} />}
            onClick={handleResetStripeAccount}>
            Reset Stripe Account
          </Menu.Item>
          {hasBonusCondition && (
            <Menu.Item
              disabled={
                archived ||
                meetsBonusCondition ||
                contractStatus < ContractStatus.ACCEPTED ||
                bonusPaymentStatus === PaymentStatus.FULLY_PAID
              }
              leftSection={<IconCircleCheck size={14} />}
              onClick={() =>
                handleMarkEligibleForBonusAction(
                  `Successfully marked ${displayName}'s contract as eligible for bonus.`,
                  `Failed to mark ${displayName}'s contract as eligible for bonus.`,
                )
              }>
              Mark Eligible for Bonus
            </Menu.Item>
          )}
          <Menu.Item
            disabled={
              archived ||
              contractStatus === ContractStatus.COMPLETE ||
              contractStatus < ContractStatus.ACCEPTED
            }
            leftSection={<IconCircleCheck size={14} />}
            onClick={() =>
              handleUpdateContractStatusAction(
                `Successfully marked ${displayName}'s contract as complete.`,
                `Failed to mark ${displayName}'s contract as complete.`,
                ContractStatus.COMPLETE,
                null,
              )
            }>
            Mark Complete
          </Menu.Item>
          <MarkRepeatContractMenuItem
            contractId={contractId}
            repeatState={repeatState}
            handleRefetchContract={handleRefetchContract}
            setIsProcessing={setIsProcessing}
            close={close}
          />
          {!hideManualPayment && (
            <>
              <Menu.Divider />
              <Menu.Item
                disabled={paymentStatus === PaymentStatus.FULLY_PAID}
                onClick={openManualPayment}
                leftSection={<IconPlus size={14} />}>
                Record Manual Payment
              </Menu.Item>
            </>
          )}
          {showReissueContract && (
            <>
              <Menu.Divider />
              <Menu.Item
                disabled={contractStatus !== ContractStatus.REJECTED}
                leftSection={<IconRestore size={14} />}
                onClick={openReissueContract}>
                Reissue Contract
              </Menu.Item>
            </>
          )}
          {!hideDelete && (
            <>
              <Menu.Divider />
              <Menu.Item
                disabled={
                  contractStatus === ContractStatus.COMPLETE ||
                  paymentStatus !== PaymentStatus.UNPAID
                }
                leftSection={<IconArchive size={14} />}
                onClick={() =>
                  handleArchiveOrUnarchiveContractAction(
                    !archived,
                    `Successfully ${
                      archived ? "unarchived" : "archived"
                    } ${displayName}'s contract.`,
                    `Failed to ${archived ? "unarchive" : "archive"} ${displayName}'s contract.,`,
                  )
                }>
                {archived ? "Unarchive" : "Archive"} Contract
              </Menu.Item>
              <Menu.Divider />
              <Menu.Item
                disabled={paymentStatus !== PaymentStatus.UNPAID}
                color="red"
                leftSection={<IconTrash size={14} />}
                onClick={() =>
                  handleDeleteContractAction(
                    `Successfully deleted ${displayName}'s contract.`,
                    `Failed to delete ${displayName}'s contract.,`,
                  )
                }>
                Delete Contract
              </Menu.Item>
            </>
          )}
        </Menu.Dropdown>
      </Menu>
    </>
  );
}

export default function ContractActions({
  user,
  contractId,
  archived,
  contractStatus,
  paymentStatus,
  bonusPaymentStatus,
  readyForPayment,
  meetsBonusCondition,
  hasBonusCondition,
  displayName,
  payments,
  stripeAccountUrl,
  closeUrl,
  handleRefetchContract,
  handleDeleteContract,
  hideDelete,
  remainingAmountInMinorUnits,
  repeatState = RepeatContractValue.NOT_ELIGIBLE,
}: {
  user: User;
  contractId: string;
  archived: boolean;
  contractStatus: ContractStatus;
  paymentStatus: PaymentStatus;
  bonusPaymentStatus: PaymentStatus;
  readyForPayment: boolean;
  meetsBonusCondition: boolean;
  hasBonusCondition: boolean;
  displayName: string;
  payments: Payment[];
  stripeAccountUrl: string;
  closeUrl: string;
  handleRefetchContract: (contractId: string, handleFetched: () => void) => void;
  handleDeleteContract?: (contractId: string) => void;
  hideDelete?: boolean;
  remainingAmountInMinorUnits?: number;
  repeatState?: RepeatContractValue;
}) {
  return (
    <ActionIcon.Group>
      <ActionMenu
        key={`${contractId}-actionMenu`}
        user={user} // TODO (victoria 3.2024): using for display name in ManualPaymentModal - need to get rid of this
        contractId={contractId}
        archived={archived}
        displayName={displayName}
        contractStatus={contractStatus}
        paymentStatus={paymentStatus}
        bonusPaymentStatus={bonusPaymentStatus}
        readyForPayment={readyForPayment}
        meetsBonusCondition={meetsBonusCondition}
        hasBonusCondition={hasBonusCondition}
        payments={payments}
        stripeAccountUrl={stripeAccountUrl}
        closeUrl={closeUrl}
        handleRefetchContract={handleRefetchContract}
        handleDeleteContract={handleDeleteContract}
        hideDelete={hideDelete}
        remainingAmountInMinorUnits={remainingAmountInMinorUnits}
        repeatState={repeatState}
      />
      <ViewContractLink contractId={contractId} />
    </ActionIcon.Group>
  );
}
