import React, { useEffect, useState } from "react";

import { CloseButton, Combobox, InputBase, useCombobox } from "@mantine/core";

import { Campaign } from "models/Campaign";

export const CampaignSelector = ({
  campaigns,
  selectedCampaignId,
  setSelectedCampaignId,
  searchParams = null,
  setSearchParams = null,
}: {
  campaigns: Campaign[];
  selectedCampaignId: number | null;
  setSelectedCampaignId: (campaignId: number) => void;
  searchParams?: URLSearchParams | null;
  setSearchParams?: (searchParams: URLSearchParams) => void | null;
}) => {
  const [search, setSearch] = useState("");
  const [cleared, setCleared] = useState(false);

  const campaignData = campaigns.map((campaign: Campaign) => ({
    value: campaign.id.toString(),
    label: campaign.title,
  }));

  const [campaignIdToLabel, setCampaignIdToLabel] = useState<{ [key: number]: string }>({});

  useEffect(() => {
    if (campaigns?.length > 0) {
      const newCampaignIdToLabel: { [key: number]: string } = {};
      campaigns.forEach((campaign: Campaign) => {
        newCampaignIdToLabel[campaign.id] = campaign.title;
      });
      setCampaignIdToLabel(newCampaignIdToLabel);
    }
  }, [campaigns]);

  // parse initial campaignId from URL
  useEffect(() => {
    const campaignIdParam = searchParams.get("campaignId");
    if (campaignIdParam) {
      if (parseInt(campaignIdParam, 10) !== selectedCampaignId) {
        setSelectedCampaignId(parseInt(campaignIdParam, 10));
        // also set search val
        setSearch(campaignIdToLabel[parseInt(campaignIdParam, 10)]);
      }
    } else {
      setSelectedCampaignId(null);
      setSearch("");
    }
  }, [campaignIdToLabel, searchParams]);

  useEffect(() => {
    let changed = false;
    if (selectedCampaignId) {
      if (selectedCampaignId.toString() !== searchParams.get("campaignId")) {
        searchParams.set("campaignId", selectedCampaignId.toString());
        changed = true;
      }
    }
    if (changed) {
      setSearchParams(searchParams);
    }
  }, [selectedCampaignId]);

  useEffect(() => {
    if (cleared) {
      setSearch("");
      setCleared(false);
      setSelectedCampaignId(null);
      // clear search params
      searchParams.delete("campaignId");
      setSearchParams(searchParams);
    }
  }, [cleared]);

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const shouldFilterOptions = campaignData.every((item) => item.label !== search);
  const filteredOptions =
    shouldFilterOptions && search?.length > 0
      ? campaignData.filter((item) =>
          item.label.toLowerCase().includes(search.toLowerCase().trim()),
        )
      : campaignData;

  const options = filteredOptions.map((item) => (
    <Combobox.Option value={item.value} key={item.label}>
      {item.label}
    </Combobox.Option>
  ));

  return (
    <Combobox
      store={combobox}
      withinPortal={false}
      onOptionSubmit={(val) => {
        setSelectedCampaignId(val ? Number(val) : null);
        setSearch(val ? campaignIdToLabel[Number(val)] : val);
        combobox.closeDropdown();
      }}>
      <Combobox.Target>
        <InputBase
          rightSection={
            setSelectedCampaignId !== null ? (
              <CloseButton
                size="sm"
                onMouseDown={(event: { preventDefault: () => void }) => event.preventDefault()}
                onClick={() => {
                  setCleared(true);
                }}
                aria-label="Clear value"
              />
            ) : (
              <Combobox.Chevron />
            )
          }
          // rightSectionPointerEvents="none"
          rightSectionPointerEvents={setSelectedCampaignId === null ? "none" : "all"}
          value={search}
          onChange={(event: { currentTarget: { value: React.SetStateAction<string> } }) => {
            combobox.openDropdown();
            combobox.updateSelectedOptionIndex();
            setSearch(event.currentTarget.value);
          }}
          onClick={() => combobox.openDropdown()}
          onFocus={() => combobox.openDropdown()}
          onBlur={() => {
            combobox.closeDropdown();
            setSearch(selectedCampaignId ? campaignIdToLabel[selectedCampaignId] : "");
          }}
          placeholder="Select a Campaign"
        />
      </Combobox.Target>
      <Combobox.Dropdown>
        <Combobox.Options>
          {options.length > 0 ? options : <Combobox.Empty>Nothing found</Combobox.Empty>}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};

export default CampaignSelector;
