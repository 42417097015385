import React, { useEffect, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";

import Intercom from "@intercom/messenger-js-sdk";

import {
  AppShell,
  Box,
  Card,
  Group,
  LoadingOverlay,
  Stack,
  Text,
  ThemeIcon,
  Title,
  Tooltip,
  useMantineTheme,
} from "@mantine/core";

import {
  IconAlertCircle,
  IconCircleDashed,
  IconProgress,
  IconProgressCheck,
  IconCircleCheck,
  IconCircleX,
} from "@tabler/icons-react";

import { User } from "firebase/auth";
import { AuthContext } from "auth/AuthContext";

import { getContractDetails } from "components/contracts/common/Api";

import ContractDeliverables from "components/contracts/deliverables/ContractDeliverables";
import {
  contractInProgress,
  contractPendingSignature,
  contractExpired,
  ContractStatus,
  PaymentStatus,
} from "components/contracts/common/Common";
import { RecurrenceType, RecurrenceFrequency } from "models/Common";
import { toLongDateString, toMediumDateString } from "utils/DateUtils";

const NAV_BAR_WIDTH = 320;

interface IntercomSettings {
  enabled: boolean;
  userId: string;
  name: string;
  email: string;
  createdAt: number;
  contractUrl: string;
  closeUrl: string;
  brandName: string;
}

interface ContractDetails {
  id: string;
  status: ContractStatus;
  amount: number;
  paymentStatus: PaymentStatus;
  completionDate: Date;
  instanceNumber: number;
  archived: boolean;
  liveDate: Date;
  brandName: string;
}

function StatusBadge({
  contractStatus,
  paymentStatus,
  isActive,
}: {
  contractStatus: ContractStatus;
  paymentStatus: PaymentStatus;
  isActive: boolean;
}) {
  let icon = null;
  let color = null;
  let tooltipLabel = null;

  if (contractExpired(contractStatus)) {
    icon = <IconCircleX />;
    color = "red";
    tooltipLabel = "Contract Expired";
  } else if (contractStatus === ContractStatus.COMPLETE) {
    if (paymentStatus === PaymentStatus.FULLY_PAID) {
      icon = <IconCircleCheck />;
      color = "teal";
      tooltipLabel = "Complete";
    } else {
      icon = <IconProgressCheck />;
      color = "teal";
      tooltipLabel = "Pending Payment";
    }
  } else if (contractInProgress(contractStatus)) {
    if (isActive) {
      icon = <IconProgress />;
      color = "yellow";
      tooltipLabel = "In Progress";
    } else {
      icon = <IconCircleDashed />;
      color = "gray";
      tooltipLabel = "Not Started";
    }
  } else if (contractPendingSignature(contractStatus)) {
    icon = <IconAlertCircle />;
    color = "red";
    tooltipLabel = "Pending Signature";
  } else {
    throw new Error("Invalid contract status");
  }

  return (
    <Tooltip label={tooltipLabel}>
      <ThemeIcon color={color} variant="transparent">
        {icon}
      </ThemeIcon>
    </Tooltip>
  );
}

function RepeatSubHeading({
  repeatInterval,
  repeatFrequency,
  repeatType,
  lastLiveDate,
}: {
  repeatInterval: number;
  repeatFrequency: RecurrenceFrequency;
  repeatType: RecurrenceType;
  lastLiveDate: Date;
}) {
  let repeatTypeText = null;
  if (repeatType === RecurrenceType.FIXED) {
    repeatTypeText = (
      <Text span>
        {" "}
        until{" "}
        <Text span fw="600">
          {toMediumDateString(lastLiveDate)}
        </Text>
      </Text>
    );
  }

  let repeatIntervalText = null;
  if (repeatInterval === 1) {
    if (repeatFrequency === RecurrenceFrequency.WEEK) {
      repeatIntervalText = "Weekly";
    } else if (repeatFrequency === RecurrenceFrequency.MONTH) {
      repeatIntervalText = "Monthly";
    } else if (repeatFrequency === RecurrenceFrequency.YEAR) {
      repeatIntervalText = "Yearly";
    }
  } else {
    repeatIntervalText = (
      <Text span>
        Every{" "}
        <Text span fw="600">
          {repeatInterval} {repeatFrequency}s
        </Text>
      </Text>
    );
  }

  const text = (
    <Text c="dimmed" size="sm">
      {repeatType === RecurrenceType.INDEFINITE && "Repeats "}
      <Text span fw="600">
        {repeatIntervalText}
      </Text>
      {repeatTypeText}
    </Text>
  );

  return text;
}

export default function CreatorContract({
  showAdminOptions = false,
}: {
  showAdminOptions?: boolean;
}) {
  const user: User = useOutletContext<AuthContext>()?.user;
  const { contractId } = useParams();

  const theme = useMantineTheme();
  const activeTabColor = theme.colors.gray[1];

  const [selectedContractId, setSelectedContractId] = useState<string>(null);
  const [isSignedContract, setIsSignedContract] = useState<boolean>(null);
  const [activeInstance, setActiveInstance] = useState<number>(null);
  const [brandDisplayName, setBrandDisplayName] = useState<string>(null);

  const [repeatContract, setRepeatContract] = useState(false);
  const [repeatInterval, setRepeatInterval] = useState(null);
  const [repeatFrequency, setRepeatFrequency] = useState(null);
  const [repeatType, setRepeatType] = useState(null);
  const [allContracts, setAllContracts] = useState<ContractDetails[]>([]);
  const [lastLiveDate, setLastLiveDate] = useState<Date>(null);

  const [intercomConfig, setIntercomConfig] = useState<IntercomSettings>(null);

  useEffect(() => {
    getContractDetails(contractId).then((response) => {
      const { success, currentContractId, isSigned, brandName, repeatDetails, intercomSettings } =
        response;
      if (success) {
        setIsSignedContract(isSigned);
        setIntercomConfig(intercomSettings);
        setSelectedContractId(currentContractId);
        setRepeatContract(repeatDetails.isRepeat);
        setRepeatInterval(repeatDetails.repeatInterval);
        setRepeatFrequency(repeatDetails.repeatFrequency);
        setRepeatType(repeatDetails.repeatType);
        setActiveInstance(repeatDetails.currentInstanceNumber);
        setAllContracts(repeatDetails.allContracts);
        setLastLiveDate(repeatDetails.lastLiveDate);
        setBrandDisplayName(brandName);
      }
    });
  }, []);

  if (intercomConfig && intercomConfig.enabled) {
    Intercom({
      app_id: "y997rmt2",
      user_id: intercomConfig.userId,
      name: intercomConfig.name,
      email: intercomConfig.email,
      created_at: intercomConfig.createdAt,
      contract_url: intercomConfig.contractUrl,
      close_url: intercomConfig.closeUrl,
      brand_name: intercomConfig.brandName,
    });
  }

  if (!selectedContractId) {
    return <LoadingOverlay visible />;
  }

  if (!repeatContract) {
    return (
      <ContractDeliverables
        user={user}
        contractId={selectedContractId}
        showAdminOptions={showAdminOptions}
      />
    );
  }

  const tabs = allContracts.map((contract) => (
    <Card
      key={contract.id}
      style={{ cursor: "pointer" }}
      p="xs"
      ml={selectedContractId === contract.id ? "xs" : 0}
      variant={selectedContractId === contract.id ? "light" : "transparent"}
      bg={selectedContractId === contract.id ? activeTabColor : ""}
      onClick={() => setSelectedContractId(contract.id)}>
      <Group gap="xs" justify="space-bewteen">
        <StatusBadge
          contractStatus={contract.status}
          paymentStatus={contract.paymentStatus}
          isActive={activeInstance === contract.instanceNumber}
        />
        <Text fw="500">{toLongDateString(contract.liveDate)}</Text>
      </Group>
    </Card>
  ));

  return (
    <AppShell
      navbar={{
        width: NAV_BAR_WIDTH,
        breakpoint: "sm",
      }}
      padding="md">
      <AppShell.Navbar withBorder>
        <Stack gap={0}>
          <Box pt="sm" pb="xs" pl="xs">
            <Title
              order={3}
              fw="600"
              style={{
                maxWidth: NAV_BAR_WIDTH,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}>
              {brandDisplayName} Partnerships
            </Title>
            <RepeatSubHeading
              repeatInterval={repeatInterval}
              repeatFrequency={repeatFrequency}
              repeatType={repeatType}
              lastLiveDate={lastLiveDate}
            />
          </Box>
          {tabs}
        </Stack>
      </AppShell.Navbar>
      <AppShell.Main>
        <ContractDeliverables
          user={user}
          contractId={selectedContractId}
          showAdminOptions={showAdminOptions}
        />
      </AppShell.Main>
    </AppShell>
  );
}
