import React, { Fragment, useEffect, useState } from "react";
import { User } from "firebase/auth";
import {
  Box,
  Button,
  Center,
  Drawer,
  Grid,
  Group,
  Space,
  Stack,
  Text,
  Textarea,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { IconMessageChatbotFilled, IconNotes, IconSend2, IconX } from "@tabler/icons-react";

import { InternalCardTitle } from "components/creator/InternalCardTitle";
import {
  ContractErrorResponse,
  addInternalContractOfferNotes,
} from "components/contracts/negotiations/api/ContractOfferApi";
import { Campaign } from "models/Campaign";
import { ContractOffer } from "models/ContractOffer";
import { toISOString } from "utils/DateUtils";

const InternalNotesDrawer = ({
  user,
  opened,
  onClose,
  contractOffer,
  currCampaign,
}: {
  user: User;
  opened: boolean;
  onClose: () => void;
  contractOffer: ContractOffer;
  currCampaign: Campaign;
}) => {
  const contractOfferId = contractOffer?.id;
  const contractNotes = contractOffer?.internal_notes;

  const [noteStr, setNoteStr] = useState("");
  const [fetchedNotes, setFetchedNotes] = useState<string[]>(contractNotes || []);
  const [saveError, setSaveError] = useState(false);

  const chatLog =
    fetchedNotes &&
    fetchedNotes.map((note) => {
      const [timeStamp, ...chat] = note.split("\n" || " ");
      return (
        <Fragment key={timeStamp}>
          <Box
            p="sm"
            bg="blue.0"
            style={{
              borderRadius: "10px",
            }}>
            <Stack justify="space-between" align="flex-end" gap="sm">
              <Text size="sm">{chat}</Text>
              <Text size="xs" c="dimmed">
                {timeStamp}
              </Text>
            </Stack>
          </Box>
          <Space h="md" />
        </Fragment>
      );
    });

  useEffect(() => {
    if (!contractOffer) {
      setFetchedNotes([]);
      return;
    }
    setFetchedNotes(contractOffer?.internal_notes);
  }, [contractOffer]);

  const title = (
    <>
      <InternalCardTitle
        title="Contract Offer Notes"
        icon={<IconNotes size={20} color="var(--mantine-color-blue-filled)" />}
      />
      <Group>
        <Space w="lg" />
        <Text size="sm">For {currCampaign?.title}</Text>
      </Group>
    </>
  );

  const handleSend = async () => {
    const abortController = new AbortController();
    const resContractNotes = await addInternalContractOfferNotes(
      contractOfferId,
      noteStr,
      abortController,
    );

    const newNote = `${toISOString(new Date())} (${user.email})\n${noteStr}`;
    if (resContractNotes.id) {
      setFetchedNotes([...fetchedNotes, newNote]);
      setNoteStr("");
    } else {
      const errRes = resContractNotes as unknown as ContractErrorResponse;
      setSaveError(true);
      notifications.show({
        title: "Note failed to save!",
        message: `Error: ${errRes.text}. Your note failed to save. Please try again.`,
        color: "red",
        icon: <IconX />,
      });
    }
  };

  return (
    <Drawer
      position="right"
      opened={opened}
      onClose={onClose}
      title={title}
      withCloseButton={false}>
      <Box>
        {chatLog ? (
          chatLog.map((chat) => chat)
        ) : (
          <Stack align="center" gap="xs">
            <IconMessageChatbotFilled size={60} color="var(--mantine-color-indigo-3)" />
            <Text size="sm" fw={600}>
              No Notes Here
            </Text>
            <Text size="xs" c="dimmed">
              There are no notes for this contract offer yet.
            </Text>
          </Stack>
        )}
      </Box>
      <Space h="md" />
      {contractOfferId ? (
        <Grid
          style={{
            position: "sticky",
            bottom: 0,
          }}
          grow
          align={saveError ? "flex-start" : "flex-end"}>
          <Grid.Col span={8}>
            <Textarea
              placeholder="Type here for internal notes..."
              value={noteStr}
              autosize
              minRows={1}
              maxRows={1}
              resize="vertical"
              onChange={(event) => {
                setSaveError(false);
                setNoteStr(event.currentTarget.value);
              }}
              error={saveError ? "Note failed to save. Please try again." : false}
            />
          </Grid.Col>
          <Grid.Col span="auto">
            <Button color="blue" rightSection={<IconSend2 size={18} />} onClick={handleSend}>
              Send
            </Button>
          </Grid.Col>
        </Grid>
      ) : (
        <Center>
          <Text c="dimmed">No active contract offer to add notes! 😣</Text>
        </Center>
      )}
    </Drawer>
  );
};

export default InternalNotesDrawer;
