import React, { MouseEventHandler, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "auth/firebaseAuthHelpers";
import { Button, ButtonProps } from "@mantine/core";
import { isAdminUser } from "utils/UserUtils";

type AdminButtonProps =
  | ButtonProps
  | {
      onClick?: MouseEventHandler<HTMLButtonElement>;
    };

const AdminButton = ({ ...props }: AdminButtonProps) => {
  const { disabled } = props as ButtonProps;
  const [user, loading] = useAuthState(auth);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  useEffect(() => {
    isAdminUser(user).then((res) => {
      setIsAdmin(res);
    });
  }, []);

  return <Button {...props} disabled={!isAdmin || disabled} />;
};

export default AdminButton;
