import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { notifications } from "@mantine/notifications";

import {
  Group,
  Button,
  Flex,
  Modal,
  Stack,
  Table,
  Text,
  TextInput,
  ActionIcon,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { IconSettings } from "@tabler/icons-react";

import { CampaignParametersV2 } from "models/Campaign";
import { CampaignParametersChangeConfirmation } from "components/campaignParameters/CampaignParametersChangeConfirmation";
import { deleteObjById } from "campaigns/api/deleteObj";
import { CAMPAIGN_PARAMETERS_URL } from "campaigns/api/fetchCampaignParameters";
import { notifyDelete } from "campaigns/main/campaignPage/ChangeConfirmation";
import { updateCampaignParamsById } from "campaigns/api/updateObjsById";

const deleteCampaignParameterAndNotify = async (
  campaignParameter: CampaignParametersV2,
  setHidden: (hidden: boolean) => void,
  close: () => void,
) => {
  const objType = "campaign parameter";
  try {
    const response = await deleteObjById(CAMPAIGN_PARAMETERS_URL, campaignParameter.id);

    // TODO(andrew): we should generalize the updateFunc to return a response with debug info.
    notifyDelete(response, objType);
    setHidden(true);
    return response;
  } catch (e) {
    // assume that <Notifications /> has been rendered in the app.
    notifications.show({
      color: "red",
      title: `Error with updating the ${objType}!`,
      message: e.message,
      autoClose: false,
    });
  } finally {
    close();
  }
  return null;
};

const DeleteCampaignParameterButton = ({
  campaignParameter,
  setHidden,
}: {
  campaignParameter: CampaignParametersV2;
  setHidden: (hidden: boolean) => void;
}) => {
  const [opened, { open, close }] = useDisclosure(false);
  return (
    <>
      <Modal
        size="lg"
        opened={opened}
        onClose={close}
        title={<Text fw="600">Delete Campaign Parameter</Text>}>
        <Stack gap={24}>
          <Text>
            Please confirm that you want to delete the Campaign Parameter: &quot;
            {campaignParameter.name || ""}&quot;.
          </Text>
          <Flex justify="center" gap={8}>
            <Button
              color="red"
              onClick={() => deleteCampaignParameterAndNotify(campaignParameter, setHidden, close)}>
              Delete
            </Button>
            <Button onClick={() => close()}>Cancel</Button>
          </Flex>
        </Stack>
      </Modal>
      <Button color="red" onClick={() => open()}>
        Delete
      </Button>
    </>
  );
};

export const CampaignParameterTableRow = ({
  campaignParameter,
  columns = ["id", "name", "actions"],
  allowDelete = true,
  readOnly = false,
}: {
  campaignParameter: CampaignParametersV2;
  columns?: string[];
  allowDelete?: boolean;
  readOnly?: boolean;
}) => {
  const navigate = useNavigate();
  const [opened, { open, close }] = useDisclosure(false);

  const initialObj = {
    name: campaignParameter.name,
  };

  const [initialValues, setInitialValues] = useState<{ [key: string]: any }>(initialObj);

  const [hidden, setHidden] = useState(false);

  const form = useForm({
    initialValues: initialObj,
  });

  return (
    <>
      <Modal
        size="lg"
        opened={opened}
        onClose={close}
        title={<Text fw="600">Campaign Parameter Info</Text>}>
        <Stack gap={24}>
          <Text>Please confirm that the following fields were changed:</Text>
          <CampaignParametersChangeConfirmation
            objId={campaignParameter.id}
            form={form}
            formValues={form.values}
            initialValues={initialValues}
            onSubmit={updateCampaignParamsById}
            close={close}
          />
        </Stack>
      </Modal>
      <Table.Tr key={`campaign-paramater-table-${campaignParameter.id}`} hidden={hidden}>
        {columns.includes("id") && (
          <Table.Td>
            <Group gap="xs" justify="space-between">
              <Group>
                <Text size="sm">{campaignParameter.id}</Text>
              </Group>
              <Group gap="xs">
                <ActionIcon
                  variant="subtle"
                  component="button"
                  size={32}
                  // TODO(make this compatible externally)
                  onClick={() =>
                    navigate(
                      `/admin/campaign_parameters?campaignParameterId=${campaignParameter.id}`,
                    )
                  }>
                  <IconSettings size="1.5rem" />
                </ActionIcon>
              </Group>
            </Group>
          </Table.Td>
        )}
        {columns.includes("name") ? (
          <Table.Td>
            <TextInput
              placeholder="Campaign Parameter Name"
              /* eslint-disable react/jsx-props-no-spreading */
              {...form.getInputProps("name")}
              disabled={readOnly}
            />
          </Table.Td>
        ) : null}
        {columns.includes("actions") ? (
          <Table.Td>
            <Flex justify={allowDelete ? "center" : "flex-start"} gap={8}>
              <Button type="submit" onClick={() => open()} disabled={!form.isDirty()}>
                Save
              </Button>
              {allowDelete && (
                <DeleteCampaignParameterButton
                  campaignParameter={campaignParameter}
                  setHidden={setHidden}
                />
              )}
            </Flex>
          </Table.Td>
        ) : null}
      </Table.Tr>
    </>
  );
};

export default CampaignParameterTableRow;
