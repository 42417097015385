import React from "react";

import {
  Box,
  Button,
  Flex,
  Image,
  Title,
  Text,
  TextInput,
  Group,
  Center,
  Card,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { API_URL } from "configs/Configs";
import Spacer from "components/Spacer";
import IMAGES from "pages/home/assets/images";
import Logos from "pages/home/v2/Logos";

const MoreInfoForm = () => {
  const form = useForm({
    initialValues: {
      email: "",
      name: "",
      company: "",
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
      name: (value) => (value ? null : "Please enter your name"),
      company: (value) => (value ? null : "Please enter your company name"),
    },
  });

  function submitWaitlistForm(values: { email: string; name: string; company: string }) {
    const url = `${API_URL}/api/campaigns/submit_waitlist_form/`;
    const body = JSON.stringify({
      ...values,
    });

    return fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body,
    });
  }

  return (
    <Card radius="md" p="16px" style={{ border: "1px solid #BCBCBC" }}>
      <Box w={300} mx="auto">
        <form
          onSubmit={form.onSubmit((values) => {
            try {
              submitWaitlistForm(values);
            } finally {
              window.location.href = "https://calendar.app.google/MWHYb7ZducX5vrgJ8";
            }
          })}>
          <Title size="h5">Schedule some time with us</Title>
          <TextInput
            withAsterisk
            label="Name"
            placeholder="Your name"
            {...form.getInputProps("name")}
          />
          <Spacer height={12} />
          <TextInput
            withAsterisk
            label="Email"
            placeholder="your@email.com"
            {...form.getInputProps("email")}
          />
          <Spacer height={12} />
          <TextInput
            withAsterisk
            label="Company name"
            placeholder="Your company name"
            {...form.getInputProps("company")}
          />

          <Group justify="flex-end" mt="md" w="100%">
            <Button w="100%" type="submit">
              Submit
            </Button>
          </Group>
        </form>
      </Box>
    </Card>
  );
};

const CampaignWaitlistScheduleTime = () => (
  <Center w="100%">
    <Flex direction="column" align="center">
      <Flex maw="700px" direction="column" align="center">
        <Image src={IMAGES.plant} alt="Campaign Waitlist" id="campaignPlant" w={200} h={200} />

        <Title order={3}>Schedule time with us</Title>
        <Spacer height={36} />
        <Text size="lg">
          If you’re looking to run an influencer marketing campaign on YouTube, Instagram, or
          TikTok, 1stCollab can help! We take care of the execution (from finding creators to
          contracting to payments) so you can focus on the bigger picture. Our unique
          experimentation framework allows you to quickly test and learn what targeting and creative
          works best, while our AI-powered platform can get you hundreds of sponsored posts live
          with just a few hours of work.
          <br />
          <br />
          To learn more, please schedule some time with us. Our CEO, Leon Lin, would be happy to
          chat!
        </Text>
        <Spacer height={24} />
        <MoreInfoForm />
        <Spacer height={24} />
        <Text size="lg">
          In the meantime, our influencer discovery tool is always free to use. Head over to{" "}
          <a href="/campaigns/discover">Discover</a> to search for an influencer you like and discover
          hundreds more like them.
        </Text>
      </Flex>
      <Logos paddingY={36} />
    </Flex>
  </Center>
);

export default CampaignWaitlistScheduleTime;
