import React, { useState } from "react";
import { BrandToDoSummary, CreatorSetTodoSummary } from "models/Campaign";
import { BasicTodoNoDetails } from "campaigns/main/campaignPage/BasicTodo";
import { useNavigate } from "react-router-dom";
import TodoBox from "campaigns/main/campaignPage/TodoBox";
import { Stack } from "@mantine/core";

// The todos in this file for CreatorSets correspond to the Todos on the review page of CreatorSets.
// We will have a different set of Todos for the CreatorSets on the Home page.

const CreatorSetToDo = ({
  creatorSetTodoSummary,
}: {
  creatorSetTodoSummary: CreatorSetTodoSummary;
}) => {
  if (!creatorSetTodoSummary) {
    return null;
  }
  const count = creatorSetTodoSummary.creator_review.total;
  if (count === 0) {
    return null;
  }

  const navigate = useNavigate();

  let creatorTodoText = "";
  if (count === 1) {
    creatorTodoText = "creator";
  } else {
    creatorTodoText = "creators";
  }

  const ctaText = "View Creators";
  const creatorsetId = creatorSetTodoSummary.creator_set_id;
  const creatorSetName = creatorSetTodoSummary.creator_set_name;

  return (
    <BasicTodoNoDetails
      title={creatorSetName}
      description={`${count} ${creatorTodoText} to review`}
      onClick={() => navigate(`/campaigns/creator_sets/${creatorsetId}/recommended/`)}
      onClickText={ctaText}
    />
  );
};

export const CreatorSetToDos = ({
  brandToDoSummary,
  creatorSetIds = null,
}: {
  brandToDoSummary: BrandToDoSummary;
  creatorSetIds?: number[] | null;
}) => {
  const creatorSetKeyIds =
    creatorSetIds !== null ? creatorSetIds : Object.keys(brandToDoSummary.creatorsets).map(Number);
  return (
    <Stack gap={0} style={{ width: "100%" }}>
      {creatorSetKeyIds.map((creatorSetId) => {
        const creatorSet = brandToDoSummary.creatorsets[creatorSetId];
        return <CreatorSetToDo key={`${creatorSetId}-todo`} creatorSetTodoSummary={creatorSet} />;
      })}
    </Stack>
  );
};

const hasCreatorSetToDos = (brandToDoSummary: BrandToDoSummary) =>
  Object.keys(brandToDoSummary.creatorsets).length > 0;

export const CreatorSetTodoList = ({
  brandToDoSummary,
}: {
  brandToDoSummary: BrandToDoSummary;
}) => {
  if (brandToDoSummary === null) {
    return null;
  }
  return (
    <TodoBox
      isVisible={hasCreatorSetToDos(brandToDoSummary)}
      todoComponent={<CreatorSetToDos brandToDoSummary={brandToDoSummary} />}
    />
  );
};
