import { Anchor, MantineColor } from "@mantine/core";
import React from "react";

const ProductGuideLink = ({
  c,
  underline = "hover",
}: {
  c?: MantineColor;
  underline?: "always" | "hover" | "never";
}) => (
  <Anchor
    c={c}
    href="https://1stcollab.notion.site/1stCollab-Brand-Product-Guide-c260996ea008436c9837f6078b83bdab"
    target="_blank"
    underline={underline}>
    Product Guide
  </Anchor>
);

export default ProductGuideLink;
