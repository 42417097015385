import React from "react";
import { Button } from "@mantine/core";
import { IconBrandInstagram, IconBrandTiktok, IconBrandYoutubeFilled } from "@tabler/icons-react";

import { SupportedPlatform } from "models/Common";

export default function SimpleSocialHandleButton({
  platform,
  profileLink,
}: {
  platform: SupportedPlatform;
  profileLink: string;
}) {
  let icon = null;
  switch (platform) {
    case SupportedPlatform.INSTAGRAM:
      icon = <IconBrandInstagram size="1.25rem" color="black" />;
      break;
    case SupportedPlatform.TIKTOK:
      icon = <IconBrandTiktok size="1.25rem" color="black" />;
      break;
    case SupportedPlatform.YOUTUBE:
      icon = <IconBrandYoutubeFilled size="1.25rem" color="black" />;
      break;
    default:
      return null;
  }

  return (
    <Button
      variant="subtle"
      size="compact-xs"
      radius="md"
      p="2px"
      component="a"
      onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.stopPropagation()}
      href={profileLink}
      target="_blank">
      {icon}
    </Button>
  );
}
