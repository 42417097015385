import { User as FirebaseUser, getAuth } from "firebase/auth";
import { createRequestWithFirebaseToken, handleResult } from "utils/ApiUtils";

import { API_URL } from "configs/Configs";
import { OpsSourcingPlan, OpsTaskResponse, SourcingAllocation } from "models/OpsTask";
import { User } from "models/User";

const GET_OPS_USERS_URL = `${API_URL}/api/admin/ops/users`;
const OPS_TASKS_URL = `${API_URL}/api/models/ops_tasks`;

export const getOpsUsers = async (
  firebaseUser: FirebaseUser,
  setOpsUsers: (users: User[]) => void,
) => {
  const firebaseToken = await firebaseUser.getIdToken();
  const request = new Request(`${GET_OPS_USERS_URL}/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
  });

  const response = await handleResult(request);
  if (response?.results && response?.results?.length > 0) {
    setOpsUsers(response.results);
  }
  return response;
};

export const getOpsTask = async (taskId: number, abortController: AbortController) => {
  const firebaseToken = await getAuth().currentUser?.getIdToken();
  const request = new Request(`${OPS_TASKS_URL}/${taskId}/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
  });

  const response = await handleResult(request, abortController);
  return response;
};

export const createOpsTask = async (task: { [key: string]: any }) => {
  const firebaseToken = await getAuth().currentUser?.getIdToken();
  const request = new Request(`${OPS_TASKS_URL}/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
    body: JSON.stringify(task),
  });

  const response = await handleResult(request);
  return response;
};

export const updateOpsTask = async (
  firebaseUser: FirebaseUser,
  taskId: number,
  task: { [key: string]: any },
) => {
  const firebaseToken = await firebaseUser.getIdToken();
  const request = new Request(`${OPS_TASKS_URL}/${taskId}/`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
    body: JSON.stringify(task),
  });

  const response = await handleResult(request);
  return response;
};

export const deleteOpsTask = async (firebaseUser: FirebaseUser, taskId: number) => {
  const firebaseToken = await firebaseUser.getIdToken();
  const request = new Request(`${OPS_TASKS_URL}/${taskId}/`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
  });

  const response = await handleResult(request);
  return response;
};

export const getOpsTasks = async (
  firebaseUser: FirebaseUser,
  abortController: AbortController,
): Promise<OpsTaskResponse> => {
  const firebaseToken = await firebaseUser.getIdToken();
  const request = new Request(`${OPS_TASKS_URL}/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
  });

  const response = await handleResult(request, abortController);
  return response;
};

export const getOpsSourcingPlan = async (
  abortController: AbortController,
): Promise<OpsSourcingPlan> => {
  const request = await createRequestWithFirebaseToken({
    url: `${API_URL}/api/ops/get_sourcing_plan/`,
    method: "GET",
  });
  const response = await handleResult(request, abortController);
  return response;
};

export const updateOpsSourcingPlan = async (data: string) => {
  const request = await createRequestWithFirebaseToken({
    url: `${API_URL}/api/ops/update_sourcing_plan/`,
    method: "POST",
    body: JSON.stringify({ raw_data: data }),
  });
  const response = await handleResult(request);
  return response;
};

export const getOpsSourcingDailyTasks = async (
  abortController: AbortController,
): Promise<SourcingAllocation> => {
  const request = await createRequestWithFirebaseToken({
    url: `${API_URL}/api/ops/get_daily_sourcing_tasks/`,
    method: "GET",
  });
  const response = await handleResult(request, abortController);
  return response;
};

export const resetOpsSourcingDailyTasks = async () => {
  const request = await createRequestWithFirebaseToken({
    url: `${API_URL}/api/ops/reset_daily_sourcing_tasks/`,
    method: "POST",
  });
  const response = await handleResult(request);
  return response;
};

export const getCreatorSetSourcingDetails = async (
  creatorSetId: number,
  abortController: AbortController,
) => {
  const request = await createRequestWithFirebaseToken({
    url: `${API_URL}/api/ops/get_creator_set_sourcing_details/?creatorSetId=${creatorSetId}`,
    method: "GET",
  });
  const response = await handleResult(request, abortController);
  return response;
};

export default null;
