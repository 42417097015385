import React, { CSSProperties, useState } from "react";
import Spacer from "components/Spacer";
import { useNavigate } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Box, Button, Text } from "gestalt";
import "pages/home/styles/light-styles.css";
import { isMobile } from "react-device-detect";
import { useUser } from "utils/UserContext";
import RelatedSearch from "components/discovery/related/RelatedSearchBar";

const SearchItem = ({ searchText, searchQuery }: { searchText: string; searchQuery: string }) => {
  const navigate = useNavigate();
  return (
    <Box
      height={200}
      width="100%"
      direction="column"
      alignSelf="center"
      display="flex"
      alignItems="center"
      justifyContent="center"
      paddingY={isMobile ? 2 : 4}>
      <Box
        height="100%"
        width="min(85%, 400px)"
        alignSelf="center"
        alignItems="center"
        direction="column"
        paddingX={isMobile ? 4 : 0}>
        <Text align="center" italic>
          {searchText}
        </Text>
        <Spacer height={16} />

        {searchQuery !== "" && (
          <Box direction="column" display="flex" alignSelf="center" alignItems="center">
            <Button
              text="Try it out!"
              color="blue"
              onClick={() => {
                localStorage.setItem("unauth-search", "true");
                localStorage.setItem("unauth-search-q", searchQuery);
                navigate("/campaigns/discover");
              }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

const MakeExampleSearchArrow = ({
  onClickHandler,
  label,
  direction,
}: {
  onClickHandler: () => void;
  label: string;
  direction: "left" | "right";
}) => {
  const arrowStyles: CSSProperties = {
    position: "absolute",
    background: "transparent",
    fontSize: 30,
    zIndex: 2,
    color: "rgb(180 180 180)",
    top: "calc(50% - 15px)",
    width: 30,
    height: 30,
    cursor: "pointer",
    padding: 0,
    display: "flex",
    justifyContent: "center",
  };

  if (direction === "left") {
    return (
      <button
        type="button"
        onClick={onClickHandler}
        title={label}
        style={{ ...arrowStyles, left: 15 }}>
        &#8249;
      </button>
    );
  }
  return (
    <button
      type="button"
      onClick={onClickHandler}
      title={label}
      style={{ ...arrowStyles, right: 15 }}>
      &#8250;
    </button>
  );
};

export const ExampleSearches = () => {
  const content = [
    {
      text: '"My dream creator is @imjennim, but I can’t afford her. Show me similar creators who are smaller"',
      searchQuery: "@imjennim",
    },
    {
      text: '"Our partnership with @humphrey went really well. Show me more creators in the US like him"',
      searchQuery: "@humphrey",
    },
    {
      text: '"@AmandaDucks is perfect but doesn’t have enough reach on her own. Show me similar creators who have at least as many average views as her"',
      searchQuery: "@AmandaDucks",
    },
  ];

  return (
    <Box
      direction="column"
      height={200}
      width="min(100%, 600px)"
      display="flex"
      justifyContent="start"
      alignSelf="center"
      padding={2}
      rounding={2}
      dangerouslySetInlineStyle={{
        __style: { background: "#F8F7F4" },
      }}
      fit>
      <Carousel
        centerMode
        showArrows
        autoPlay
        interval={5000}
        centerSlidePercentage={100}
        showThumbs={false}
        showStatus={false}
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && (
            <MakeExampleSearchArrow
              onClickHandler={onClickHandler}
              label={label}
              direction="left"
            />
          )
        }
        renderArrowNext={(onClickHandler, hasNext, label) =>
          hasNext && (
            <MakeExampleSearchArrow
              onClickHandler={onClickHandler}
              label={label}
              direction="right"
            />
          )
        }
        renderIndicator={(clickHandler, isSelected, index, label) => {
          const sizeOfIndicatorAndPadding = 15 + 8; // 4 padding on each side
          const offset =
            -sizeOfIndicatorAndPadding * (content.length / 2) + index * sizeOfIndicatorAndPadding;
          return (
            <Box
              width="100%"
              display="flex"
              justifyContent="center"
              paddingY={2}
              position="absolute"
              bottom
              marginBottom={4}>
              <div
                style={{
                  position: "absolute",
                  padding: 4,
                  left: `calc(50% + ${offset}px)`,
                  width: 15,
                  height: 15,
                  borderRadius: "50%",
                  border: "1px solid",
                  backgroundColor: isSelected ? "var(--g-blue)" : undefined,
                  borderColor: "var(--g-blue)",
                }}
                onClick={clickHandler}
                aria-hidden="true"
              />
            </Box>
          );
        }}>
        {content.map((item, index) => (
          <SearchItem key={item.text} searchText={item.text} searchQuery={item.searchQuery} />
        ))}
      </Carousel>
    </Box>
  );
};

export const LandingPageSearchBar = () => {
  const navigate = useNavigate();
  const [profileLink, setProfileLink] = useState("");
  const [currentUser, isLoading, updateUser] = useUser();

  return (
    <RelatedSearch
      handleSubmit={() => {
        // if the user is not authed, send them to signup instead
        // also add a localstorage cookie so that
        if (currentUser == null) {
          localStorage.setItem("unauth-search", "true");
          localStorage.setItem("unauth-search-q", encodeURIComponent(profileLink));

          navigate(`/signup`);
        } else {
          navigate(`/campaigns/discover?q=${encodeURIComponent(profileLink)}`);
        }
      }}
      onChange={(value) => {
        setProfileLink(value);
      }}
      input={profileLink}
    />
  );
};
