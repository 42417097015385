// Datamodels that match social/datamodels.py + other constant types
import { MediaEntry } from "components/MediaBlockCard";
import { CampaignAdGroupMin } from "models/CampaignAdGroup";

export type Platform = "tiktok" | "youtube" | "any" | "instagram";

export type SeedType = "hashtags" | "creator" | "keywords";

export type RecommendedSeedType = "precrawled_search" | "general_recs";

export enum CreatorSetEntryState {
  NEEDS_REVIEW = 1,
  ACCEPTED = 2,
  REJECTED = 3,
  WORKED_WITH = 4,
  PENDING = 5,
  NEEDS_INTERNAL_REVIEW = 6,
  ADMIN_REMOVED = 7,
  SAVED = 8,
}

export type YoutubeChannelInfo = {
  channel_id: string;
  handle: string;
  avatar_url: string;
  num_subscribers: number;
  title: string;
  country: string;
  views_med: number;
  engagement_rate?: number;
  comment_rate?: number;
  comments_med: number;
  likes_med: number;
  total_vids: number;
  all_time_videos: number;
  vibe_score?: number;
  videos_last_updated: string;
  videos: MediaEntry[];
  description: string;
};

export type TiktokProfileStats = {
  median_views: number;
  median_likes: number;
  median_comments: number;
  avg_views: number;
  engagement_rate: number;
  comment_rate: number;
  num_videos: number;
};

export type TiktokProfileInfo = {
  tt_user_uuid: number;
  handle: string;
  display_name: string;
  description: string;
  follower_count: number;
  avatar_url: string;
  total_videos: number;
  region: string;
  videos_last_updated: string;
  videos: MediaEntry[];
};

export type TiktokCreatorInfo = {
  stats: TiktokProfileStats;
  info: TiktokProfileInfo;
};

export type InstagramProfileStats = {
  median_views: number;
  avg_views: number;
  engagement_rate: number;
  comment_rate: number;
  num_videos: number;
  median_likes: number;
  median_comments: number;
};

export type InstagramProfileInfo = {
  instagram_id: number;
  handle: string;
  display_name: string;
  biography: string;
  region: string;
  follower_count: number;
  total_videos: number;
  avatar_url: string;
  videos_last_updated: string;
  videos: MediaEntry[];
};

export type InstagramCreatorInfo = {
  stats: InstagramProfileStats;
  info: InstagramProfileInfo;
};

export type CreatorDetails = {
  id?: number;
  youtube_channel: YoutubeChannelInfo | null;
  tiktok_profile: TiktokCreatorInfo | null;
  instagram_profile: InstagramCreatorInfo | null;
  creator_id?: number;
  platform_priority?: string[];
  link_clicks?: PlatformLinkClicks;
};

export type PlatformLinkClicks = {
  youtube: number;
  tiktok: number;
  instagram: number;
};

export type CreatorProfileDetails = {
  profile_link: string;
  handle: string;
  avatar_url: string;
  num_subscribers: number;
  title?: string;
  country?: string;
  views_med?: number;
  engagement_rate?: number;
  comment_rate?: number;
  comments_med?: number;
  total_vids?: number;
  all_time_vids?: number;
  vibe_score?: number;
  videos?: MediaEntry[];
  description?: string;
};

export type SearchSummaryItem = {
  crawl_status: string;
  date_created: string;
  platform: string;
  campaign_name: string;
  creator_set_name: string;
  job_strategy: string;
  seeds: string[];
  initial_expansion_size: number;
  new_creators: number;
  last_processed_expansion_size: number;
  last_processed_date: string;
  num_first_page_search: number;
  total_searches: number;
  num_searches_after_post_process: number;
  recommendation_type: string;
  recommendation: string;
  search_link: string;
};

export type CreatorSet = {
  id: number;
  name: string;
  // This is actually a Brand object but avoiding circular imports
  brand?: any;
  date_created?: string;
  status?: CreatorSetStatus;
  notes?: string;
  example_tiktok_creators?: string[];
  example_youtube_creators?: string[];
  example_instagram_creators?: string[];
  example_keywords?: string[];
  example_hashtags?: string[];
  discovery_tiktok_creators?: string[];
  discovery_youtube_creators?: string[];
  discovery_instagram_creators?: string[];
  discovery_hashtags?: string[];
  related_brands?: string[];
  description?: string;
  example_topics?: string;
  requirements?: string;
  brand_requirements?: string;
  sourcing_goal?: number;
  creator_language?: string[];
  creator_country?: string[];
  min_media?: number;
  min_media_28d?: number;
  min_followers?: number;
  max_followers?: number;
  min_median_views?: number;
  // string key of ops users who are qualified to work on this creator set
  ops_users?: string[];
  sourcing_difficulty?: number;
  sourcing_priority?: number;
  ad_groups?: CampaignAdGroupMin[];
};

export enum CreatorSetStatus {
  DRAFT = 0,
  GROW = 1,
  MANUAL = 2,
  STABLE = 3,
  ARCHIVED = 4,
  DELETED = 5,
}

export type CreatorSetItem = {
  id: number;
  date_created: string;
  name: string;
  num_needs_review: number;
  num_accepted: number;
  num_saved: number;
  campaigns: string[];
  description: string;
  brand_requested_recommendations: boolean;
  example_topics: string;
  example_hashtags?: string[];
  brand_requirements: string;
};

// This matches CreatorSetEntryDict
export type CreatorSetEntryItem = {
  id: number;
  date_created: string;
  creator_set_id: number;
  creator_id: number;
  notes: string;
  state: CreatorSetEntryState;
  // todo(kevin): Add CreatorSetAuxInfo for
  // pieces of information that don't cleanly fit into
  // this but are needed for display - i.e.
  // how many other CreatorSets this creator is in
};

// This is the filled in CreatorSetEntryItem with details
// for pagination
export type CreatorSetEntryWithDetail = {
  creator_set_entry: CreatorSetEntryItem;
  creator_details: CreatorDetails;
};

export type HashtagRec = {
  hashtag: string;
  view_count: number;
  user_count: number;
  size_category: string;
};

export type CreatorRec = {
  creator: string;
  follower_count: number;
  size_category: string;
};

export type RecommendedSearchItem = {
  seed: string;
  seed_type: SeedType;
  search_link: string;
  num_previous_searches: number;
  hashtag: HashtagRec;
  creator: CreatorRec;
  platform: string;
  finished_crawl: boolean;
  hidden_ts: string;
  not_relevant_ts: string;
};
export interface CreatorSetMembershipDetails {
  creatorId: number;
  creatorSetId: number;
  name: string;
  isActivated: boolean;
  isSaved: boolean;
}
