import { User as FirebaseUser } from "firebase/auth";
import { handleResult } from "utils/ApiUtils";

import { API_URL } from "configs/Configs";
import { ShortDomain } from "models/ShortDomain";

const GET_UNLINKED_DOMAINS_URL = `${API_URL}/api/campaigns/get_short_domains`;

export const getUnlinkedDomains = async (
  firebaseUser: FirebaseUser,
  setUnlinkedDomains: (domains: ShortDomain[]) => void,
  abortController: AbortController,
) => {
  const firebaseToken = await firebaseUser.getIdToken();
  const request = new Request(`${GET_UNLINKED_DOMAINS_URL}/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
  });

  const response = await handleResult(request, abortController);
  if (response?.results && response?.results?.length > 0) {
    setUnlinkedDomains(response.results);
  }
  return response;
};

export default null;
