import React from "react";
import { Navigate, Outlet, useLocation, useOutletContext } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";

import { auth } from "auth/firebaseAuthHelpers";
import { AuthContext } from "auth/AuthContext";
import LoadingScreen from "components/LoadingScreen";

// if canContinue is true, then we still allow the user to navigate to
// the route even if they aren't authenticated in.
const AuthLayout = ({ canContinue = false }: { canContinue?: boolean }) => {
  const location = useLocation();
  const [user, loading] = useAuthState(auth);

  if (loading) return <LoadingScreen />;
  if (!canContinue && !user) {
    const fullPath = `${location.pathname}${location.search}`;
    const nextUrl = encodeURIComponent(fullPath);
    const loginUrl = `/login?next=${nextUrl}`;
    return <Navigate to={loginUrl} replace />;
  }
  // Fetch django version of user object?
  return <Outlet context={{ user }} />;
};

export function getLoggedInUser() {
  return useOutletContext<AuthContext>()?.user;
}

export default AuthLayout;
