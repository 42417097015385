import React, { useEffect, useState } from "react";

import { useSearchParams } from "react-router-dom";

import { Container, Grid, Stack, TextInput } from "@mantine/core";
import { useInputState } from "@mantine/hooks";
import { TikTokVideoDebugCard } from "admin/app/ops/labeling/debug/TikTokVideoDebugCard";

export const TikTokVideoFeed = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [videoIds, setVideoIds] = useInputState<string>(null);

  const [cards, setCards] = useState(null);

  useEffect(() => {
    // parse video ids from search params
    const parsedVideoIds = searchParams.get("video_ids");
    if (parsedVideoIds) {
      if (parsedVideoIds !== videoIds) {
        setVideoIds(parsedVideoIds);
      }
    } else {
      setVideoIds("");
    }
  }, [searchParams]);

  // Sync search params with hashtag
  useEffect(() => {
    // update search params based on query changes
    const newSearchParams = new URLSearchParams(searchParams);
    let changed = false;
    if (videoIds) {
      if (videoIds !== searchParams.get("video_ids")) {
        newSearchParams.set("video_ids", videoIds);
        changed = true;
      }
    } else if (videoIds === "") {
      newSearchParams.delete("video_ids");
      changed = true;
    }
    if (changed) {
      setSearchParams(newSearchParams);
    }
  }, [videoIds]);

  useEffect(() => {
    if (videoIds) {
      setCards(
        videoIds.split(",").map((id) => (
          <Grid.Col key={id.trim()} span="content">
            <TikTokVideoDebugCard tikTokVideoId={id.trim()} />
          </Grid.Col>
        )),
      );
    } else {
      setCards(null);
    }
  }, [videoIds]);

  return (
    <Stack gap="md">
      <TextInput value={videoIds} onChange={setVideoIds} />
      <Container fluid my="md">
        <Grid gutter="sm">{cards}</Grid>
      </Container>
    </Stack>
  );
};

export default TikTokVideoFeed;
