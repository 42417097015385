import React, { useEffect, useState } from "react";

import { Button, Group, Stack, Text, Textarea } from "@mantine/core";

import { IconSend } from "@tabler/icons-react";

import { ContentType } from "components/contracts/common/Common";

import {
  ContractDeliverableStatus,
  ContractDeliverableStatusMapping,
  ReverseContractDeliverableStatusMapping,
} from "components/contracts/models/Deliverable";

import {
  showFailureNotification,
  showSuccessNotification,
} from "components/common/Notifications";

import DeliverableVideo from "components/contracts/models/DeliverableVideo";
import Message, { MessageParty } from "components/contracts/models/Message";
import MessageHistory from "components/contracts/common/MessageHistory";
import Script from "components/contracts/models/Script";

import { fetchMessages, sendMessageToBrand } from "components/contracts/common/Api";

const MESSAGE_LENGTH_LIMIT = 5000;

function SendMessage({
  brandName,
  contentType,
  deliverableStatus,
  setDeliverableStatus,
  pastMessages,
  setPastMessages,
  script,
  deliverableVideo,
}: {
  brandName: string;
  contentType: ContentType;
  deliverableStatus: ContractDeliverableStatus;
  setDeliverableStatus: (status: ContractDeliverableStatus) => void;
  pastMessages: Message[];
  setPastMessages: (messages: Message[]) => void;
  script?: Script;
  deliverableVideo?: DeliverableVideo;
}) {
  const [messageText, setMessageText] = useState("");
  const [sendingMessage, setSendingMessage] = useState(false);

  const handleSendMessagetoBrand = (setLoader: (loading: boolean) => void) => () => {
    setLoader(true);
    sendMessageToBrand(
      contentType === ContentType.SCRIPT
        ? {
            deliverableId: script.deliverableId,
            messageText,
            scriptId: script.scriptId,
          }
        : {
            deliverableId: deliverableVideo.deliverable.id,
            messageText,
            videoId: deliverableVideo.videoId,
          },
    )
      .then((response: any) => {
        const { success, message, status } = response;
        if (success) {
          const deserializedMessage = Message.deserialize(message);
          setPastMessages([...pastMessages, deserializedMessage]);
          setMessageText("");
          setDeliverableStatus(ReverseContractDeliverableStatusMapping[status]);
          showSuccessNotification({ message: `Message sent to ${brandName} successfully.` });
        } else {
          showFailureNotification({ message: `Failed to send message to ${brandName}.` });
        }
        setLoader(false);
      })
      .catch(() => {
        showFailureNotification({ message: `Failed to send message to ${brandName}.` });
        setLoader(false);
      });
  };

  // Don't allow creators to send messages after the script has been approved.
  if (
    contentType === ContentType.SCRIPT &&
    ContractDeliverableStatusMapping[deliverableStatus] >=
      ContractDeliverableStatusMapping[ContractDeliverableStatus.SCRIPT_APPROVED]
  ) {
    return null;
  }

  // Don't allow creators to send messages after the video has been approved.
  if (
    contentType === ContentType.VIDEO &&
    ContractDeliverableStatusMapping[deliverableStatus] >=
      ContractDeliverableStatusMapping[ContractDeliverableStatus.VIDEO_DRAFT_APPROVED]
  ) {
    return null;
  }

  return (
    <Stack mb="-xs">
      <Textarea
        label={
          <Text ml={1} mb={4} fw="500">
            Send Message to {brandName}
          </Text>
        }
        autosize
        minRows={3}
        value={messageText}
        onChange={(event) => setMessageText(event.currentTarget.value)}
        error={
          messageText.length > MESSAGE_LENGTH_LIMIT &&
          `Feedback must not exceed ${MESSAGE_LENGTH_LIMIT} characters.`
        }
      />
      <Group justify="right">
        <Button
          disabled={messageText.length === 0 || messageText.length > MESSAGE_LENGTH_LIMIT}
          loading={sendingMessage}
          onClick={handleSendMessagetoBrand(setSendingMessage)}
          leftSection={<IconSend size="1rem" />}
          color="blue"
          variant="filled"
          size="sm">
          Send Message
        </Button>
      </Group>
    </Stack>
  );
}

export default function CreatorMessages({
  brandName,
  deliverableStatus,
  setDeliverableStatus,
  contentType,
  script,
  deliverableVideo,
  showAdminOptions,
}: {
  brandName: string;
  deliverableStatus: ContractDeliverableStatus;
  setDeliverableStatus: (status: ContractDeliverableStatus) => void;
  contentType: ContentType;
  script?: Script;
  deliverableVideo?: DeliverableVideo;
  showAdminOptions?: boolean;
}) {
  // Validate Content Type
  if (contentType === ContentType.SCRIPT) {
    if (deliverableVideo) {
      throw new Error("Video cannot be provided for Script content type.");
    }

    // Only show script messages once creator has reached script draft stage.
    if (
      !script ||
      ContractDeliverableStatusMapping[deliverableStatus] <=
        ContractDeliverableStatusMapping[ContractDeliverableStatus.WAITING_FOR_SCRIPT]
    ) {
      return null;
    }
  } else if (contentType === ContentType.VIDEO) {
    if (script) {
      throw new Error("Script cannot be provided for Video content type.");
    }

    // Only show messages once creator has reached video draft stage.
    if (
      !deliverableVideo ||
      ContractDeliverableStatusMapping[deliverableStatus] <=
        ContractDeliverableStatusMapping[ContractDeliverableStatus.WAITING_FOR_VIDEO_DRAFT]
    ) {
      return null;
    }
  } else {
    throw new Error("Invalid content type");
  }

  const [pastMessages, setPastMessages] = useState<Message[]>([]);
  const [messagesLoaded, setMessagesLoaded] = useState(false);
  const [messageLoadingError, setMessageLoadingError] = useState(false);

  useEffect(() => {
    fetchMessages(
      contentType === ContentType.SCRIPT
        ? {
            deliverableId: script.deliverableId,
            messageParty: MessageParty.CREATOR.toString(),
            scriptId: script.scriptId,
          }
        : {
            deliverableId: deliverableVideo.deliverable.id,
            messageParty: MessageParty.CREATOR.toString(),
            videoId: deliverableVideo.videoId,
          },
    )
      .then((response: any) => {
        const { success, messages } = response;
        if (success) {
          const deserializedMessages = messages.map((message: any) => Message.deserialize(message));
          setPastMessages(deserializedMessages);
          setMessagesLoaded(true);
        } else {
          setMessageLoadingError(true);
          setMessagesLoaded(true);
        }
      })
      .catch(() => {
        setMessageLoadingError(true);
        setMessagesLoaded(true);
      });
  }, []);

  return (
    <Stack>
      <MessageHistory
        messages={pastMessages}
        setMessages={setPastMessages}
        messageViewer={MessageParty.CREATOR}
        messagesLoaded={messagesLoaded}
        loadingError={messageLoadingError}
        showAdminOptions={showAdminOptions}
        deliverableId={
          contentType === ContentType.SCRIPT
            ? script.deliverableId
            : deliverableVideo.deliverable.id
        }
        scriptId={script?.scriptId}
        videoId={deliverableVideo?.videoId}
      />
      <SendMessage
        brandName={brandName}
        contentType={contentType}
        deliverableStatus={deliverableStatus}
        setDeliverableStatus={setDeliverableStatus}
        pastMessages={pastMessages}
        setPastMessages={setPastMessages}
        script={script}
        deliverableVideo={deliverableVideo}
      />
    </Stack>
  );
}
