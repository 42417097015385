import React, { useEffect, useState } from "react";

import { useSearchParams } from "react-router-dom";

import {
  Badge,
  Button,
  Box,
  Center,
  Divider,
  Flex,
  Group,
  Loader,
  LoadingOverlay,
  SegmentedControl,
  Select,
  Stack,
  Paper,
  Text,
  Title,
} from "@mantine/core";

import {
  getCreatorSetNames,
  getCreatorsForCreatorSet,
  getRecommendationsForCreatorSet,
} from "admin/api/recommendationsApi";

import { CreatorSetEntryState, CreatorDetails } from "components/discovery/Datamodels";

import { CreatorSearchResults } from "components/creator/search/CreatorSearchResults";

export default function CreatorSetRecommendations() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [optionsLoading, setOptionsLoading] = useState(true);
  const [creatorsLoading, setCreatorsLoading] = useState(false);
  const [recommendationsLoading, setRecommendationsLoading] = useState(false);

  const [creatorSetOptions, setCreatorSetOptions] = useState([]);
  const [selectedCreatorSet, setSelectedCreatorSet] = useState(null);
  const [showAcceptedCreators, setShowAcceptedCreators] = useState(true);

  const [acceptedCreators, setAcceptedCreators] = useState<Record<number, CreatorDetails>>({});
  const [rejectedCreators, setRejectedCreators] = useState<Record<number, CreatorDetails>>({});
  const [recommendedCreators, setRecommendedCreators] = useState<Record<number, CreatorDetails>>(
    {},
  );
  const [hashtagsUsedForRetreival, setHashtagsUsedForRetrieval] = useState([]);

  const [showNoRecommendations, setShowNoRecommendations] = useState(false);

  useEffect(() => {
    if (creatorSetOptions && creatorSetOptions.length > 0) {
      if (searchParams.get("selectedCreatorSet") !== null) {
        setSelectedCreatorSet(searchParams.get("selectedCreatorSet"));
      }
    }
  }, [creatorSetOptions]);

  useEffect(() => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    if (selectedCreatorSet) {
      updatedSearchParams.set("selectedCreatorSet", selectedCreatorSet);
    }
    setSearchParams(updatedSearchParams.toString());
  }, [selectedCreatorSet]);

  useEffect(() => {
    getCreatorSetNames()
      .then((response) => {
        const { results } = response;
        setCreatorSetOptions(results);
      })
      .finally(() => setOptionsLoading(false));
  }, []);

  useEffect(() => {
    const abortController = new AbortController();

    if (selectedCreatorSet) {
      setCreatorsLoading(true);
      getCreatorsForCreatorSet(selectedCreatorSet, abortController)
        .then((response) => {
          const { acceptedCreatorDetails, rejectedCreatorDetails } = response;

          setAcceptedCreators(acceptedCreatorDetails);
          setRejectedCreators(rejectedCreatorDetails);
        })
        .finally(() => setCreatorsLoading(false));

      setRecommendationsLoading(true);
      getRecommendationsForCreatorSet(selectedCreatorSet, abortController)
        .then((response) => {
          const { success, creatorDetails, retrievalHashtags } = response;

          if (success) {
            setRecommendedCreators(creatorDetails);
            setHashtagsUsedForRetrieval(retrievalHashtags);
          } else {
            setShowNoRecommendations(true);
          }
        })
        .finally(() => setRecommendationsLoading(false));
    } else {
      setAcceptedCreators({});
      setRejectedCreators({});
      setRecommendedCreators({});
      setHashtagsUsedForRetrieval([]);
    }

    return () => {
      abortController.abort();
    };
  }, [selectedCreatorSet]);

  return (
    <Paper p="lg" radius="sm">
      <LoadingOverlay visible={optionsLoading} />
      <Stack>
        <Title fw="500" order={2}>
          Creator Set Recommendations
        </Title>
        <Select
          data={creatorSetOptions}
          placeholder="Select a Creator Set..."
          value={selectedCreatorSet}
          onChange={setSelectedCreatorSet}
          searchable
        />
        {selectedCreatorSet && (
          <Flex gap="lg">
            <Stack w="50%">
              <Title fw="500" order={4}>
                Current Creators
              </Title>
              {creatorsLoading && (
                <Center>
                  <Loader type="dots" />
                </Center>
              )}
              {!creatorsLoading && (
                <>
                  <SegmentedControl
                    value={
                      showAcceptedCreators
                        ? CreatorSetEntryState.ACCEPTED.toString()
                        : CreatorSetEntryState.REJECTED.toString()
                    }
                    onChange={(value) =>
                      setShowAcceptedCreators(value === CreatorSetEntryState.ACCEPTED.toString())
                    }
                    data={[
                      {
                        value: CreatorSetEntryState.ACCEPTED.toString(),
                        label: (
                          <Center>
                            <Group>
                              <Text fw="500" size="sm">
                                Accepted
                              </Text>
                              <Badge variant="light">{Object.keys(acceptedCreators).length}</Badge>
                            </Group>
                          </Center>
                        ),
                      },
                      {
                        value: CreatorSetEntryState.REJECTED.toString(),
                        label: (
                          <Center>
                            <Group>
                              <Text fw="500" size="sm">
                                Rejected
                              </Text>
                              <Badge variant="light">{Object.keys(rejectedCreators).length}</Badge>
                            </Group>
                          </Center>
                        ),
                      },
                    ]}
                  />
                  <Box px="-lg" mx="-lg">
                    <CreatorSearchResults
                      results={showAcceptedCreators ? acceptedCreators : rejectedCreators}
                    />
                  </Box>
                </>
              )}
            </Stack>
            <Divider orientation="vertical" />
            <Stack w="50%">
              <Title fw="500" order={4}>
                Recommended Creators
              </Title>
              {recommendationsLoading && (
                <Center>
                  <Loader type="dots" />
                </Center>
              )}
              {!recommendationsLoading && (
                <Box>
                  {showNoRecommendations ? (
                    <Text>No recommendations found</Text>
                  ) : (
                    <Stack>
                      <Text c="dimmed" size="sm">
                        {Object.keys(recommendedCreators).length} creators found using the following
                        hashtags
                      </Text>
                      <Flex wrap="wrap" gap="xs">
                        {hashtagsUsedForRetreival.map((hashtag) => (
                          <Button
                            key={hashtag}
                            variant="light"
                            size="compact-xs"
                            component="a"
                            href={`https://www.1stcollab.com/admin/debug/feed/user_hashtags?hashtag=${hashtag}`}
                            target="_blank">
                            #{hashtag}
                          </Button>
                        ))}
                      </Flex>
                      <Box px="-lg" mx="-lg">
                        <CreatorSearchResults results={recommendedCreators} />
                      </Box>
                    </Stack>
                  )}
                </Box>
              )}
            </Stack>
          </Flex>
        )}
      </Stack>
    </Paper>
  );
}
