import React from "react";
import { Flex, Heading, Text } from "gestalt";
import "gestalt/dist/gestalt.css";

const HeaderMessage = ({
  header,
  subtitle,
  alignItems = "center",
}: {
  header: string;
  subtitle: string;
  alignItems?: "start" | "end" | "center" | "baseline" | "stretch" | undefined;
}) => (
  <Flex direction="column" alignItems={alignItems} gap={4}>
    <Heading size="400">{header}</Heading>
    <Text color="subtle">{subtitle}</Text>
  </Flex>
);

export default HeaderMessage;
