import Spacer from "components/Spacer";
import { VERTICALS } from "models/Verticals";
import React, { useContext } from "react";
import { MultiSelect, Stack, Text, TextInput, Textarea } from "@mantine/core";
import { OnboardingV2Context } from "onboarding/v2/OnboardingV2Context";

const MAX_VERTICALS = 3;
const OnboardingCreatorProfileStep = () => {
  const {
    selectedVerticals,
    setSelectedVerticals,
    username,
    setUsername,
    usernameError,
    aboutMe,
    setAboutMe,
  } = useContext(OnboardingV2Context);

  const options = VERTICALS.map((vertical) => ({
    label: vertical.name,
    value: vertical.value,
    subtext: vertical.subtext,
  }));

  const getPlaceholderText = () => {
    const numSelectedVerticals = selectedVerticals.length;
    if (numSelectedVerticals === 0) {
      return `Pick ${MAX_VERTICALS} verticals`;
    }
    if (numSelectedVerticals === MAX_VERTICALS) {
      return "";
    }
    if (numSelectedVerticals === MAX_VERTICALS - 1) {
      return "Pick 1 more vertical";
    }
    return `Pick ${MAX_VERTICALS - numSelectedVerticals} more verticals`;
  };

  return (
    <>
      <Text fw={500} size="lg" style={{ textAlign: "center" }}>
        Complete your creator profile setup
      </Text>
      <Spacer height={48} />
      <Stack maw="520px" gap={0}>
        <MultiSelect
          w="100%"
          label={`Pick ${MAX_VERTICALS}  Verticals`}
          placeholder={getPlaceholderText()}
          data={options}
          maxValues={MAX_VERTICALS}
          value={selectedVerticals}
          onChange={(verticals) => setSelectedVerticals(verticals)}
        />
        <Spacer height={24} />
        <TextInput
          w="100%"
          label="Username"
          description="This username will let you claim your own custom 1stCollab url - https://www.1stcollab.com/username"
          placeholder="Username"
          value={username}
          onChange={(event) => setUsername(event.currentTarget.value)}
          error={usernameError}
        />
        <Spacer height={24} />
        <Textarea
          label="About me"
          placeholder="A short description about yourself..."
          autosize
          minRows={3}
          value={aboutMe}
          onChange={(event) => setAboutMe(event.currentTarget.value)}
        />
      </Stack>
    </>
  );
};

export default OnboardingCreatorProfileStep;
