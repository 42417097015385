import { User as FirebaseUser } from "firebase/auth";

import { API_URL } from "configs/Configs";

import { Campaign, CampaignAdGroup, CampaignResponse } from "models/Campaign";
import { CreativeBrief } from "models/CreativeBrief";
import { createRequestWithFirebaseToken, handleResult } from "utils/ApiUtils";
import { CreatorSet } from "components/discovery/Datamodels";

const CAMPAIGN_MODEL_URL = `${API_URL}/api/models/campaigns/retrieve_from_hash_id`;
const BRAND_CAMPAIGNS_URL = `${API_URL}/api/models/campaigns/list_from_brand_id`;

export const CREATOR_SET_URL = `${API_URL}/api/models/creator_set`;
export const ADGROUP_URL = `${API_URL}/api/models/campaign_ad_group`;

export interface CreatorSetSettingsResponse {
  results: CreatorSet[];
  count: number;
  next?: number | null;
  previous?: number | null;
}

export const fetchCampaignByHashId = async (
  campaignHashId: number,
  abortController: AbortController | null,
): Promise<Campaign | null> => {
  const requestUrl = new URL(CAMPAIGN_MODEL_URL);
  requestUrl.searchParams.append("campaignId", campaignHashId.toString());

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
  });

  const response: Campaign | null = await handleResult(request, abortController);

  return response;
};

export const fetchCampaignsByBrandId = async (
  user: FirebaseUser,
  brandId: number,
  setCampaigns: (campaigns: Campaign[]) => void,
  abortController: AbortController,
): Promise<CampaignResponse> => {
  const firebaseToken = await user.getIdToken();

  const requestUrl = new URL(BRAND_CAMPAIGNS_URL);
  requestUrl.searchParams.append("brandId", brandId.toString());

  const request = new Request(requestUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
  });
  const response: CampaignResponse | null = await handleResult(request, abortController);

  if (response != null) {
    const updatedCampaign = response.results.map((campaign) => ({
      ...campaign,
      brand_name: campaign.brand?.brand_name,
    }));
    setCampaigns(updatedCampaign);
  }
  return response;
};

export const fetchCreatorSetsByHashId = async (
  user: FirebaseUser,
  campaignHashId: number,
  abortController: AbortController,
): Promise<CreatorSetSettingsResponse> => {
  const firebaseToken = await user.getIdToken();

  const requestUrl = new URL(`${CREATOR_SET_URL}/`);
  requestUrl.searchParams.append("campaignId", campaignHashId.toString());

  const request = new Request(requestUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
  });
  const response: CreatorSetSettingsResponse | null = await handleResult(request, abortController);
  return response;
};

export const createCreatorSetByHashId = async (
  user: FirebaseUser,
  campaignHashId: number,
  creatorSetName: string,
  abortController: AbortController,
): Promise<CreatorSet> => {
  const firebaseToken = await user.getIdToken();

  const requestUrl = new URL(`${CREATOR_SET_URL}/`);
  requestUrl.searchParams.append("campaignId", campaignHashId.toString());

  // Prepare the data to be sent in the request body
  const requestBody = {
    campaignId: campaignHashId.toString(),
    name: creatorSetName,
  };

  const request = new Request(requestUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
    body: JSON.stringify(requestBody),
    signal: abortController.signal,
  });

  const response = await handleResult(request, abortController);
  console.log("make creatorset", response);
  return response;
};

export const createAdGroupByHashId = async (
  campaignHashId: number,
  adGroupName: string,
  adGroupDescription: string | null,
  creativeBriefId: number,
  abortController: AbortController | null,
  blurb: string | null = null,
  creatorSets: number[] | null = null,
): Promise<CampaignAdGroup> => {
  const requestUrl = new URL(`${ADGROUP_URL}/`);

  // Prepare the data to be sent in the request body
  const requestBody = {
    campaign_id: campaignHashId.toString(),
    name: adGroupName,
    brief_id: creativeBriefId,
    ...(adGroupDescription && { description: adGroupDescription }),
    ...(blurb && { brand_blurb: blurb }),
    ...(creatorSets && { creator_sets: creatorSets }),
  };

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify(requestBody),
  });

  const response = await handleResult(request, abortController);
  return response;
};

export const updateCreatorSetAdGroup = async (
  user: FirebaseUser,
  adGroupId: number,
  creatorSetIds: number[],
  hashCampaignId: number,
  abortController: AbortController,
): Promise<CampaignAdGroup> => {
  const firebaseToken = await user.getIdToken();
  const requestUrl = new URL(`${ADGROUP_URL}/${adGroupId}/?campaignId=${hashCampaignId}`); // Adjust URL to include adGroupId

  const requestBody = {
    creator_sets: creatorSetIds,
  };

  const request = new Request(requestUrl, {
    method: "PATCH", // Using PATCH for partial update
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
    body: JSON.stringify(requestBody),
    signal: abortController.signal,
  });

  const response = await handleResult(request, abortController);
  return response;
};

export const deleteAdGroup = async (adGroupId: string, abortController: AbortController | null) => {
  const requestUrl = new URL(`${ADGROUP_URL}/${adGroupId}/`); // Adjust URL to include adGroupId

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "DELETE",
  });

  const response = await handleResult(request, abortController);
  return response;
};

export const updateAdGroup = async (
  campaignHashId: number,
  adGroupId: string,
  updatedFields: { [key: string]: any },
) => {
  const idFields: { [key: string]: any } = {
    id: adGroupId,
    campaignId: campaignHashId,
  };
  const fieldsToUpdate = {
    ...updatedFields,
    ...idFields,
  };

  const request = await createRequestWithFirebaseToken({
    url: `${ADGROUP_URL}/${adGroupId}/`,
    method: "PATCH",
    body: JSON.stringify(fieldsToUpdate),
  });

  const response = await handleResult(request);
  return response;
};

export default null;
