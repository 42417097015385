import React from "react";
import { Button, Modal } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconPlayerPlay } from "@tabler/icons-react";
import ReactPlayer from "react-player";
import TitleAndCaption from "components/contracts/common/TitleAndCaption";

export default function VideoPlayer({
  videoUrl,
  title,
  caption,
  label,
  disabled,
  onClick,
}: {
  videoUrl: string;
  title: string;
  caption: string;
  label: string;
  disabled?: boolean;
  onClick?: () => void;
}) {
  const [opened, { open, close }] = useDisclosure();

  return (
    <>
      <Modal padding={0} opened={opened} onClose={close} size="auto" withCloseButton={false}>
        <ReactPlayer url={videoUrl} playing muted controls />
        <TitleAndCaption title={title} caption={caption} width={640} />
      </Modal>
      <Button
        disabled={disabled}
        leftSection={<IconPlayerPlay size="1rem" />}
        variant="light"
        onClick={() => {
          if (onClick) {
            onClick();
          }
          open();
        }}>
        {label}
      </Button>
    </>
  );
}
