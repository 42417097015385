import React, { createContext, ReactNode, useContext, useMemo, useState } from "react";

export interface Session {
  applyingCampaignId: number; // stores the ID of the campaign the user most recently landed on
}

const SessionContext = createContext([null, null]);
export const useSession = () => useContext(SessionContext);

export const SessionProvider = ({ children }: { children: ReactNode }) => {
  const [session, setSession] = useState(null);

  const updateSession = (newProps: Partial<Session>) => {
    const newSession = { ...session, ...newProps };
    setSession(newSession);
  };

  const sessionState = useMemo(() => [session, updateSession], [session]);

  return <SessionContext.Provider value={sessionState}>{children}</SessionContext.Provider>;
};
