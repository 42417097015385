import React, { ChangeEvent, useEffect, useState } from "react";
import { Avatar, Box, Label, Text, Flex, Spinner } from "gestalt";
import "gestalt/dist/gestalt.css";
import { User } from "firebase/auth";

import Spacer from "components/Spacer";
import { handleImageUpload } from "utils/UserUtils";
import { useUser } from "utils/UserContext";
import UploadButton from "components/UploadButton";

const profileAvatarEditStyle = {
  position: "absolute",
  transform: "translate(-50%,-50%)",
  left: "50%",
  top: "50%",
  borderRadius: "50%",
  zIndex: 3,
  cursor: "pointer",
} as React.CSSProperties;

const ProfileAvatar = ({
  requestUser,
  src,
  displayName,
  isSelf,
  hideInput,
}: {
  requestUser: User;
  src: string;
  displayName: string;

  // Whether a user is looking at their own avatar. If they are, then they will be able to edit it
  isSelf: boolean;

  // Whether to hide the input component (useful when users are explicitly trying to edit their profile)
  hideInput?: boolean;
}) => {
  const [avatarSrc, setAvatarSrc] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [userProfile, isLoading, updateUser] = useUser();

  useEffect(() => {
    setAvatarSrc(src);
  });

  const handleImageUploadChange = (element: React.ChangeEvent<HTMLInputElement>) =>
    handleImageUpload(requestUser, element, setAvatarSrc, setErrorMessage, updateUser, "avatar");

  const avatarName = hideInput ? displayName : "";

  return isLoading ? (
    <Spinner accessibilityLabel="Loading Avatar" show />
  ) : (
    <Flex direction="column" alignItems="center">
      <Box width={120} position="relative">
        {isSelf ? (
          <>
            <Avatar name={avatarName} size="xl" src={avatarSrc} />
            {hideInput ? null : (
              <div style={profileAvatarEditStyle}>
                <UploadButton
                  buttonName="profilePictureButton"
                  handleImageUpload={handleImageUploadChange}
                />
              </div>
            )}
            {/* <Label htmlFor="avatarSrc">
              <Avatar name={displayName} size="xl" src={avatarSrc} />
            </Label>
            {hideInput ? null : <Spacer height={10} />}
            <input
              id="avatarSrc"
              type="file"
              accept="image/*"
              multiple={false}
              onChange={(element) => handleImageUpload(
                requestUser, element, setAvatarSrc, setErrorMessage, updateUser, 'avatar'
              )}
              style={hideInput ? { display: "none" } : null}
            /> */}
          </>
        ) : (
          <Avatar name={avatarName} size="xl" src={avatarSrc} />
        )}
      </Box>
      {errorMessage && (
        <>
          <Spacer height={32} />
          <Text color="error">{errorMessage}</Text>
        </>
      )}
    </Flex>
  );
};

export default ProfileAvatar;
