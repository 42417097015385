/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "@mantine/hooks";
import { Flex, Stack } from "@mantine/core";

export default function MasonryLayout({ cards }: { cards: React.ReactElement[] }) {
  // Use 3 columns by default
  const [numColumns, setNumColumns] = useState(3);

  // Adjust the number of columns based on the screen width 
  // (360px width card with 20px gap between columns and 300px toolbar width)
  const breakpoints = [
    useMediaQuery("(max-width: 1040px)"),
    useMediaQuery("(max-width: 1420px)"),
    useMediaQuery("(max-width: 1800px)"),
    useMediaQuery("(max-width: 2180px)"),
    useMediaQuery("(max-width: 2560px)"),
  ];

  useEffect(() => {
    if (breakpoints[0]) setNumColumns(1);
    else if (breakpoints[1]) setNumColumns(2);
    else if (breakpoints[2]) setNumColumns(3);
    else if (breakpoints[3]) setNumColumns(4);
    else if (breakpoints[4]) setNumColumns(5);
    else setNumColumns(6); 
  }, [breakpoints]);

  // Distribute items into columns
  const distributeItems = (items: React.ReactNode[], columns: number) => {
    const cols = Array.from({ length: columns }, () => []);
    items.forEach((item, i) => {
      cols[i % columns].push(item);
    });
    return cols;
  };

  const distributedItems = distributeItems(cards, numColumns);

  return (
    <Flex justify="center" gap="md">
      {distributedItems.map((columnItems, idx) => (
        <Stack key={`masonry-column-${idx}`} gap="md">
          {columnItems}
        </Stack>
      ))}
    </Flex>
  );
}
