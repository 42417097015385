import React, { useEffect, useState } from "react";

import { Stack, Textarea } from "@mantine/core";

import { CreatorDetails } from "components/discovery/Datamodels";

import { CreatorSearchResults } from "components/creator/search/CreatorSearchResults";
import { fetchCreatorSetDetails } from "components/creator_sets/CreatorSetUtils";

const CreatorListInput = ({
  setResults,
}: {
  setResults: (results: Record<number, CreatorDetails>) => void;
}) => {
  const [creatorIds, setCreatorIds] = useState<string>(null);

  useEffect(() => {
    const abortController = new AbortController();
    if (creatorIds && creatorIds !== "") {
      const parsedCreatorIds = creatorIds.split(",").map((creatorId) => Number(creatorId.trim()));
      fetchCreatorSetDetails(parsedCreatorIds, {}, setResults, abortController);
    } else {
      setResults({});
    }
    return () => {
      abortController.abort();
    };
  }, [creatorIds]);

  return (
    <Textarea
      placeholder="Enter creator ids separated by commas"
      onChange={(event) => {
        // remove newlines
        const cleanedCreatorIds = event.currentTarget.value.replace(/(\r\n|\n|\r)/gm, "");
        console.log(cleanedCreatorIds);
        setCreatorIds(cleanedCreatorIds);
      }}
    />
  );
};

export const CreatorDebugFeed = () => {
  const [results, setResults] = useState<Record<number, CreatorDetails>>({});
  return (
    <Stack>
      <CreatorListInput setResults={setResults} />
      <CreatorSearchResults results={results} />
    </Stack>
  );
};

export default CreatorDebugFeed;
