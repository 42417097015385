import { Stack } from "@mantine/core";
import React, { useEffect, useState } from "react";
import CardTitle from "campaigns/create/common/CardTitle";

const CardBase = ({
  isActive,
  nodeShownWhenActive,
  title,
  subtitle,
}: {
  isActive: boolean;
  nodeShownWhenActive: React.ReactNode;
  title: string;
  subtitle: string;
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(isActive);

  useEffect(() => {
    setIsOpen(isActive);
  }, [isActive]);

  return (
    <Stack
      py="20px"
      px="28px"
      opacity={isOpen ? 1 : 0.5}
      bg="var(--mantine-color-white)"
      style={{
        borderRadius: 12,
        border: "1px solid var(--mantine-color-dark-0)",
        "--stack-gap": "24px",
      }}>
      <CardTitle
        title={title}
        subtitle={subtitle}
        isOpen={isOpen}
        onIconTapped={() => {
          setIsOpen(!isOpen);
        }}
      />
      {isOpen ? nodeShownWhenActive : null}
    </Stack>
  );
};

export default CardBase;
