import React, { createContext, ReactNode, useContext, useMemo, useState } from "react";

const OnboardingNextContext = createContext([null, null]);

export const useNextButton = () => useContext(OnboardingNextContext);

export const OnboardingNextProvider = ({ children }: { children: ReactNode }) => {
  const [nextEnabled, setNext] = useState(null);

  const nextState = useMemo(() => [nextEnabled, setNext], [nextEnabled]);

  return (
    <OnboardingNextContext.Provider value={nextState}>{children}</OnboardingNextContext.Provider>
  );
};
