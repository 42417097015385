import React from "react";

import { Anchor, Button, Flex, Group, Modal, Stack, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

import { IconExternalLink } from "@tabler/icons-react";

import { contractPendingSignature, ContractStatus } from "components/contracts/common/Common";

export default function CreativeBriefLink({
  brandName,
  creativeBriefUrl,
  contractStatus,
}: {
  brandName: string;
  creativeBriefUrl: string;
  contractStatus: ContractStatus;
}) {
  if (!creativeBriefUrl) {
    return null;
  }

  const [opened, { close }] = useDisclosure(contractPendingSignature(contractStatus));

  return (
    <>
      <Modal
        opened={opened}
        onClose={close}
        title={
          <Text fw="bold" mx="sm" mt="sm" size="lg">
            {brandName} Creative Brief
          </Text>
        }
        centered>
        <Stack gap="xl" mx="sm" mb="sm">
          <Text>
            Before signing the contract, be sure to review the Creative Brief for {brandName} below.
          </Text>
          <Flex justify="center">
            <Button
              component="a"
              href={creativeBriefUrl}
              target="_blank"
              variant="default"
              leftSection={<IconExternalLink size="1rem" />}>
              View Creative Brief
            </Button>
          </Flex>
        </Stack>
      </Modal>
      <Anchor href={creativeBriefUrl} target="_blank">
        <Group gap="0.3rem">
          <Text fw="initial">View Creative Brief</Text>
          <IconExternalLink size="1rem" />
        </Group>
      </Anchor>
    </>
  );
}
