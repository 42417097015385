import { API_URL } from "configs/Configs";
import { createRequestWithFirebaseToken, handleResult } from "utils/ApiUtils";

export async function getCoreMetrics() {
  const requestUrl = new URL(`${API_URL}/api/metrics/get_core_metrics`);
  const request = await createRequestWithFirebaseToken({ url: requestUrl });
  const response = await handleResult(request);
  return response;
}

export async function getCashFlowMetrics(abortController: AbortController) {
  const requestUrl = new URL(`${API_URL}/api/metrics/get_cash_flow_metrics`);
  const request = await createRequestWithFirebaseToken({ url: requestUrl });
  const response = await handleResult(request, abortController);
  return response;
}

export async function getRevenueMetrics(abortController: AbortController) {
  const requestUrl = new URL(`${API_URL}/api/metrics/get_revenue_metrics`);
  const request = await createRequestWithFirebaseToken({ url: requestUrl });
  const response = await handleResult(request, abortController);
  return response;
}

export async function fetchSignedContractRates({
  startDate,
  endDate,
  smoothingWindow = 1,
  abortController,
}: {
  startDate?: string;
  endDate?: string;
  smoothingWindow?: number;
  abortController: AbortController;
}) {
  const data: Record<string, string> = {
    startDate,
    endDate,
    smoothingWindow: smoothingWindow.toString(),
  };
  const params = new URLSearchParams(data);
  const requestUrl = new URL(`${API_URL}/api/metrics/get_contract_signed_rates/?${params}`);
  const request = await createRequestWithFirebaseToken({ url: requestUrl });
  const response = await handleResult(request, abortController);

  return response;
}

export async function getGMVForecast({ campaignId }: { campaignId?: string }) {
  const requestUrl = new URL(`${API_URL}/api/metrics/get_gmv_forecast`);
  if (campaignId) {
    requestUrl.searchParams.append("campaignId", campaignId.toString());
  }
  const request = await createRequestWithFirebaseToken({ url: requestUrl });
  const response = await handleResult(request);
  return response;
}

export async function getCampaignsWithBudget() {
  const requestUrl = new URL(`${API_URL}/api/metrics/get_campaigns_with_budget`);
  const request = await createRequestWithFirebaseToken({ url: requestUrl });
  const response = await handleResult(request);
  return response;
}

export async function getBudgetMetricsForCampaigns({ campaignIds }: { campaignIds: string[] }) {
  const requestUrl = new URL(`${API_URL}/api/metrics/get_campaign_budget_metrics`);
  requestUrl.searchParams.append("campaignIds", campaignIds.join(","));
  const request = await createRequestWithFirebaseToken({ url: requestUrl });
  const response = await handleResult(request);
  return response;
}

export default null;
