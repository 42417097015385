import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Center } from "@mantine/core";

import { useUser } from "utils/UserContext";

import AutocompleteInput from "components/discovery/search/AutocompleteInput";

export default function PublicCreatorSetSearch() {
  const navigate = useNavigate();
  const [currentUser] = useUser();

  const [searchBoxValue, setSearchBoxValue] = useState(null);

  return (
    <Center>
      <AutocompleteInput
        loadingResults={false}
        searchBoxValue={searchBoxValue}
        setSearchBoxValue={setSearchBoxValue}
        handleSubmit={(query: string) => {
          localStorage.setItem("unauth-search", "true");
          localStorage.setItem("unauth-search-q", query);

          if (currentUser == null) {
            navigate(`/signup`);
          } else if (currentUser && !currentUser.completed_onboarding) {
            navigate("/onboarding");
          } else {
            navigate("/campaigns/discover");
          }
        }}
      />
    </Center>
  );
}
