import React from "react";

import { Flex, Progress, Stack, Text } from "@mantine/core";

const DEFAULT_NUM_FREE_SEARCHES = 10;

export default function RateLimitProgress({
  numSearchesRemaining,
  enforceLimit,
}: {
  numSearchesRemaining: number;
  enforceLimit: boolean;
}) {
  if (!enforceLimit) {
    return null;
  }

  return (
    <Stack gap="xs">
      <Progress
        value={((DEFAULT_NUM_FREE_SEARCHES - numSearchesRemaining) / DEFAULT_NUM_FREE_SEARCHES) * 100}
      />
      <Flex justify="right">
        <Text fs="italic" size="sm" c="dimmed">
          {numSearchesRemaining} free search{numSearchesRemaining === 1 ? "" : "es"} remaining...
        </Text>
      </Flex>
    </Stack>
  );
}
