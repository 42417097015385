import React from "react";

import { Flex, Text } from "@mantine/core";

import { IconHeart, IconLink, IconPlayerPlay } from "@tabler/icons-react";

function formatNumber(amount: number): string {
  if (!amount) {
    return "0";
  }

  let formattedAmount: string;

  if (amount < 1e3) {
    // For amounts less than 1k, return the actual amount.
    formattedAmount = amount.toString();
  } else if (amount < 1e6) {
    // For amounts 1k to 1m, format as Xk
    formattedAmount = `${(amount / 1e3).toFixed(1)}k`;
  } else {
    // For amounts 1m and above, format as X.XXm
    formattedAmount = `${(amount / 1e6).toFixed(2)}m`;
  }

  return formattedAmount;
}

export default function EngagementMetrics({
  numViews,
  numLikes,
  numClicks,
  disableClicks,
}: {
  numViews: number;
  numClicks: number;
  numLikes: number;
  disableClicks?: boolean;
}) {
  return (
    <Flex gap="xs" justify="left">
      <Flex align="center" gap="0.2rem">
        <IconPlayerPlay size="1rem" stroke={2} />
        <Text size="sm">{formatNumber(numViews)}</Text>
      </Flex>
      <Flex align="center" gap="0.2rem">
        <IconHeart size="1rem" stroke={2} />
        <Text size="sm">{formatNumber(numLikes)}</Text>
      </Flex>
      {!disableClicks && (
        <Flex align="center" gap="0.2rem">
          <IconLink size="1rem" stroke={2} />
          <Text size="sm">{formatNumber(numClicks)}</Text>
        </Flex>
      )}
    </Flex>
  );
}
