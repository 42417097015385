import React from "react";

import { Table } from "@mantine/core";

import { OpsTask } from "models/OpsTask";
import { User } from "models/User";
import OpsUserTasksTableRow from "admin/app/ops/OpsUserTasksTableRow";

export const OpsUserTasksTable = ({
  opsUser,
  opsTasks,
}: {
  opsUser: User;
  opsTasks: OpsTask[];
}) => {
  // filter tasks by user
  const userTasks = opsUser
    ? opsTasks.filter((task) => task?.assigned_user_details?.key === opsUser.key)
    : opsTasks.filter((task) => task?.assigned_user_details?.key === null);

  return (
    <Table horizontalSpacing="md" verticalSpacing="md" highlightOnHover withTableBorder>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Task Name</Table.Th>
          <Table.Th>Completed</Table.Th>
          <Table.Th>Creator Set</Table.Th>
          <Table.Th>Count</Table.Th>
          <Table.Th>Difficulty</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {userTasks.map((task) => (
          <OpsUserTasksTableRow key={task.id} opsTask={task} />
        ))}
      </Table.Tbody>
    </Table>
  );
};

export default OpsUserTasksTable;
