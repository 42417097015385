import React, { useEffect, useState } from "react";
import CardBase from "campaigns/create/common/CardBase";
import { Button, Checkbox, Divider, Group, NativeSelect, Stack, Switch, Text } from "@mantine/core";
import { useForm } from "@mantine/form";
import InfoCircleWithTooltip from "campaigns/create/common/InfoCircleWithTooltip";
import { SupportedFormatIntegers, UsageRightsDuration } from "models/Common";
import { Campaign, CampaignStatus, UsageRightsPolicy } from "models/Campaign";
import { updateCampaign } from "campaigns/create/api/UpdateCampaignApi";

const CONTENT_TYPES = [
  {
    platform: "Instagram",
    types: [{ label: "Reels", value: SupportedFormatIntegers.INSTAGRAM_DEDICATED_REEL.toString() }],
    formField: "instagramFormats",
  },
  {
    platform: "TikTok",
    types: [{ label: "Videos", value: SupportedFormatIntegers.TIKTOK_DEDICATED_VIDEO.toString() }],
    formField: "tiktokFormats",
  },
  {
    platform: "YouTube",
    types: [
      { label: "Shorts", value: SupportedFormatIntegers.YOUTUBE_SHORT.toString() },
      {
        label: "Dedicated Videos",
        value: SupportedFormatIntegers.YOUTUBE_DEDICATED_VIDEO.toString(),
      },
      // {label: "Video Integrations (30 sec)", value: SupportedFormatIntegers.YOUTUBE_30S_INTEGRATED_VIDEO.toString() },
      {
        label: "Integrated Video (60 sec)",
        value: SupportedFormatIntegers.YOUTUBE_60S_INTEGRATED_VIDEO.toString(),
      },
      // {label: "Video Integrations (90 sec)", value: SupportedFormatIntegers.YOUTUBE_90S_INTEGRATED_VIDEO.toString() },
    ],
    formField: "youtubeFormats",
  },
];

const DURATION_7_DAYS = {
  label: "7 days",
  value: UsageRightsDuration.SEVEN_DAYS.toString(),
};

const DURATION_30_DAYS = {
  label: "30 days",
  value: UsageRightsDuration.THIRTY_DAYS.toString(),
};

const DURATION_60_DAYS = {
  label: "60 days",
  value: UsageRightsDuration.SIXTY_DAYS.toString(),
};

const DURATION_1_YEAR = {
  label: "1 year",
  value: UsageRightsDuration.ONE_YEAR.toString(),
};

const USAGE_RIGHTS_DURATION = [
  DURATION_7_DAYS,
  DURATION_30_DAYS,
  DURATION_60_DAYS,
  DURATION_1_YEAR,
];

export interface DeliverableFormType {
  instagramFormats: string[];
  tiktokFormats: string[];
  youtubeFormats: string[];
  requireUsageRightsUpfront: boolean;
  usageRightsDuration: string;
}

const setInitialFormats = (existingFormats: number[] | null, validFormats: number[]): string[] =>
  existingFormats
    ?.filter((format) => validFormats.includes(format))
    .map((format) => format.toString()) || [];

const DeliverableCampaignCard = ({
  campaign,
  isActive,
  onSaveSuccess,
}: {
  campaign: Campaign | null;
  isActive: boolean;
  onSaveSuccess: (input: DeliverableFormType) => void;
}) => {
  const [isCampaignAlreadyLive, setIsCampaignAlreadyLive] = useState<boolean>(false);
  const [showUsageRightsDuration, setShowUsageRightsDuration] = useState<boolean>(false);
  const [hasFormBeenEdited, setHasFormBeenEdited] = useState<boolean>(false);

  const form = useForm<DeliverableFormType>({
    mode: "uncontrolled",
    initialValues: {
      instagramFormats: [SupportedFormatIntegers.INSTAGRAM_DEDICATED_REEL.toString()],
      tiktokFormats: [SupportedFormatIntegers.TIKTOK_DEDICATED_VIDEO.toString()],
      youtubeFormats: [
        SupportedFormatIntegers.YOUTUBE_SHORT.toString(),
        SupportedFormatIntegers.YOUTUBE_DEDICATED_VIDEO.toString(),
        SupportedFormatIntegers.YOUTUBE_60S_INTEGRATED_VIDEO.toString(),
      ],
      requireUsageRightsUpfront: false,
      usageRightsDuration: DURATION_7_DAYS.value,
    },
    onValuesChange: () => {
      setHasFormBeenEdited(form.isDirty());
    },
  });

  form.watch("requireUsageRightsUpfront", ({ value }) => {
    setShowUsageRightsDuration(value);
  });

  useEffect(() => {
    setIsCampaignAlreadyLive(campaign?.status === CampaignStatus.Active);
    if (campaign) {
      setShowUsageRightsDuration(campaign.usage_rights_policy === UsageRightsPolicy.UPFRONT);
      form.setValues({
        instagramFormats: setInitialFormats(campaign.formats, [
          SupportedFormatIntegers.INSTAGRAM_DEDICATED_REEL,
        ]),
        tiktokFormats: setInitialFormats(campaign.formats, [
          SupportedFormatIntegers.TIKTOK_DEDICATED_VIDEO,
        ]),
        youtubeFormats: setInitialFormats(campaign.formats, [
          SupportedFormatIntegers.YOUTUBE_SHORT,
          SupportedFormatIntegers.YOUTUBE_DEDICATED_VIDEO,
          SupportedFormatIntegers.YOUTUBE_60S_INTEGRATED_VIDEO,
        ]),
        requireUsageRightsUpfront: campaign.usage_rights_policy === UsageRightsPolicy.UPFRONT,
        usageRightsDuration: campaign.usage_rights_duration?.toString() || DURATION_7_DAYS.value,
      });
      form.resetDirty();
      setHasFormBeenEdited(form.isDirty());
    }
  }, [campaign]);

  return (
    <CardBase
      isActive={isActive}
      title="Deliverables"
      subtitle="Define the platforms and content types you would like to run your campaign with."
      nodeShownWhenActive={
        <form
          onSubmit={form.onSubmit((values) => {
            const allFormats = values.instagramFormats
              .concat(values.tiktokFormats)
              .concat(values.youtubeFormats);

            updateCampaign(campaign.hash_id, {
              formats: allFormats.map((formatValueString) => Number(formatValueString)),
              usage_rights_policy: values.requireUsageRightsUpfront
                ? UsageRightsPolicy.UPFRONT
                : UsageRightsPolicy.POST_LIVE,
              usage_rights_duration: Number(values.usageRightsDuration),
            }).then((result) => {
              onSaveSuccess(values);
              form.resetDirty();
              setHasFormBeenEdited(form.isDirty());
            });
          })}>
          <Stack style={{ "--stack-gap": "24px" }}>
            <Stack style={{ "--stack-gap": "16px" }}>
              <Text size="xs" c="var(--mantine-color-gray-6)">
                Our recommendation is to pick as many formats as possible. This allows us to reach
                out to a broader range of creators and negotiate bundled deals across platforms to
                maximize performance.
              </Text>
              {CONTENT_TYPES.map((content_type) => (
                <Checkbox.Group
                  key={form.key(content_type.formField)}
                  label={content_type.platform}
                  {...form.getInputProps(content_type.formField)}
                  readOnly={isCampaignAlreadyLive}>
                  <Group mt={8}>
                    {content_type.types.map(({ label, value }) => (
                      <Checkbox
                        key={label}
                        value={value}
                        label={label}
                        disabled={isCampaignAlreadyLive}
                      />
                    ))}
                  </Group>
                </Checkbox.Group>
              ))}
            </Stack>
            <Divider />
            <Stack gap={0}>
              <Group gap={4} align="flex-start" wrap="nowrap">
                <Switch
                  size="sm"
                  label={
                    <Text size="sm" fw={500}>
                      Require usage rights from all creators upfront
                    </Text>
                  }
                  labelPosition="left"
                  key={form.key("requireUsageRightsUpfront")}
                  {...form.getInputProps("requireUsageRightsUpfront", { type: "checkbox" })}
                  disabled={isCampaignAlreadyLive}
                />
                <InfoCircleWithTooltip
                  tooltipText="Usage rights allow you to reuse the content—for example, in paid ads. (This is
                      sometimes called “UGC”). On Instagram and TikTok, it also gives you the right
                      to put spend behind the creator's post and run it as a paid ad."
                />
              </Group>
              <Text size="xs" c="var(--mantine-color-gray-6)">
                It’s recommended that you only require usage rights upfront if you plan on using
                most of the content from this campaign as organic ads.
              </Text>
            </Stack>
            {showUsageRightsDuration ? (
              <NativeSelect
                label="Usage Rights Duration"
                description="Specify the length of the usage rights license you would like to request. For each deliverable, the usage rights window begins once the deliverable goes live. Longer durations cost more than shorter durations."
                data={USAGE_RIGHTS_DURATION}
                withAsterisk
                key={form.key("usageRightsDuration")}
                {...form.getInputProps("usageRightsDuration")}
                disabled={isCampaignAlreadyLive}
              />
            ) : null}
            <Button
              type="submit"
              variant="filled"
              disabled={
                !campaign || campaign?.status === CampaignStatus.Active || !hasFormBeenEdited
              }>
              Save
            </Button>
          </Stack>
        </form>
      }
    />
  );
};

export default DeliverableCampaignCard;
