import { AspectRatio, Box, Button, Container, Flex, Image, Text, Title } from "@mantine/core";
import React, { useRef } from "react";
import Autoplay from "embla-carousel-autoplay";
import { Carousel } from "@mantine/carousel";
import "@mantine/carousel/styles.css";

/* eslint-disable @typescript-eslint/no-var-requires */
const alinatrvl = require("pages/home/v2/assets/videos/alinatrvl.mp4");
const amenidareb = require("pages/home/v2/assets/videos/amenidareb.mp4");
const brodfit = require("pages/home/v2/assets/videos/brodfit_21.mp4");
const excelwithanamaria = require("pages/home/v2/assets/videos/excelwithanamaria.mp4");
const iconikkifits = require("pages/home/v2/assets/videos/iconikkifits.mp4");
const lilitravels = require("pages/home/v2/assets/videos/lilitravels.mp4");
const nataliacardenass = require("pages/home/v2/assets/videos/nataliacardenass_.mp4");
const soundmindbeats = require("pages/home/v2/assets/videos/soundmindbeats.mp4");
const techwizzdom = require("pages/home/v2/assets/videos/tech_wizzdom.mp4");
const thebrownbarista = require("pages/home/v2/assets/videos/thebrownbarista.mp4");
const thedonibrown = require("pages/home/v2/assets/videos/thedonibrown.mp4");
const xpertrn = require("pages/home/v2/assets/videos/xpertrn.mp4");
/* eslint-enable @typescript-eslint/no-var-requires */

const CAMPAIGN_TO_ASSET_LEFT = new Map<string, any>([
  ["techwizzdom", techwizzdom],
  ["excelwithanamaria", excelwithanamaria],
  ["thedonibrown", thedonibrown],
  ["thebrownbarista", thebrownbarista],
  ["brodfit", brodfit],
  ["xpertrn", xpertrn],
]);

const CAMPAIGN_TO_ASSET_RIGHT = new Map<string, any>([
  ["iconikkifits", iconikkifits],
  ["soundmindbeats", soundmindbeats],
  ["nataliacardenass", nataliacardenass],
  ["amenidareb", amenidareb],
  ["lilitravels", lilitravels],
  ["alinatrvl", alinatrvl],
]);

const GifCarousel = ({
  align,
  campaignToAssetMap,
}: {
  align: number | "start" | "center" | "end";
  campaignToAssetMap: Map<string, any>;
}) => {
  const autoplay = useRef(Autoplay({ delay: 3000 }));

  return (
    <Box w="50%" h="700px">
      <Flex align="center" justify="center" h="100%">
        <Box w="100%" h="700px">
          <Carousel
            plugins={[autoplay.current]}
            align={align}
            height={700}
            slideSize="30%"
            orientation="vertical"
            loop
            withControls={false}>
            {Object.keys(Object.fromEntries(campaignToAssetMap)).map((key) => (
              <Carousel.Slide key={`${key}-campaign-example`}>
                <AspectRatio
                  ratio={9 / 16}
                  mb={{ base: 8, md: 16, xl: 32 }}
                  onClick={(event: React.MouseEvent<HTMLElement>) => {
                    /* intercept click events */
                    event.preventDefault();
                    event.stopPropagation();
                  }}>
                  <video
                    src={campaignToAssetMap.get(key)}
                    title={`${key} Demo`}
                    style={{
                      width: "100%",
                      height: "auto",
                      border: 0,
                      borderRadius: "12px",
                      pointerEvents: "none",
                    }}
                    autoPlay
                    muted
                    loop
                    playsInline
                  />
                </AspectRatio>
              </Carousel.Slide>
            ))}
          </Carousel>
        </Box>
      </Flex>
    </Box>
  );
};

const Header = () => (
  <Flex w="100%" p={0}>
    <Container style={{ maxWidth: "1920px" }} p={0}>
      <Flex
        h={{ base: "100%", sm: "700px" }}
        px={{ base: "24px", md: "32px", lg: "96px" }}
        w="100%"
        direction={{ base: "column", sm: "row" }}
        gap={{ base: "sm", sm: "lg" }}
        align={{ sm: "center" }}>
        <Flex
          w={{ base: "100%", sm: "50%" }}
          gap="md"
          justify="flex-start"
          align="flex-start"
          direction="column"
          wrap="wrap"
          pr={{ base: "0px", sm: "80px" }}
          py={{ base: "64px", sm: "0px" }}>
          <Title style={{ fontSize: "56px" }}>
            <Text
              inherit
              variant="gradient"
              component="span"
              gradient={{ from: "pink", to: "red" }}>
              Better brand deals,{" "}
            </Text>
            a lot less work
          </Title>
          <Text size="lg" fw={500}>
            We personally match you with a brand your audience will love, pay you quickly, and get
            you repeat deals. Join the hundreds of creators who get paid every week.
          </Text>
          <Button
            w={180}
            variant="filled"
            size="md"
            radius="md"
            component="a"
            href="/signup"
            c="white">
            Sign Up
          </Button>
        </Flex>
        <Container
          w={{ base: "100%", sm: "50%" }}
          style={{ overflowY: "hidden", height: "700px" }}
          p={0}>
          <Container size="sm" p={0}>
            <Flex
              w="100%"
              gap={{ base: 8, md: 16, xl: 32 }}
              direction="row"
              justify="center"
              style={{ pointerEvents: "none" }}>
              <GifCarousel align="start" campaignToAssetMap={CAMPAIGN_TO_ASSET_LEFT} />
              <GifCarousel align="center" campaignToAssetMap={CAMPAIGN_TO_ASSET_RIGHT} />
            </Flex>
          </Container>
        </Container>
      </Flex>
    </Container>
  </Flex>
);

export default Header;
