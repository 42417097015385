import React, { useEffect, useState } from "react";
import { TextField, Flex, Box, Text, Icon } from "gestalt";
import "gestalt/dist/gestalt.css";
import { User } from "firebase/auth";

import Spacer from "components/Spacer";
import { SaveStatus } from "pages/settings/SettingsComponents";
import { updateUserInfo } from "utils/UserUtils";
import { ErrorInfo } from "utils/ApiUtils";
import { User as UserProfile } from "models/User";

enum UsernameChecks {
  CHARACTER_LIMIT, // between 3 and 20 characters
  ALLOWED_CHARACTERS_ONLY, // only a-z A-Z 0-9 -. used
  GOOD_START_END, // no - or . at the start or end
  NO_CONSECUTIVE, // no - or . consecutively
}

const checkUsernameFormat = (
  username: string,
  setSatsifiedReq: (satisfiedReq: UsernameChecks[]) => void,
) => {
  const requirementsMet: UsernameChecks[] = [];
  if (username.length >= 3 && username.length <= 20)
    requirementsMet.push(UsernameChecks.CHARACTER_LIMIT);
  const characterMatch = username.match(/^[a-z0-9._]+$/i);
  if (characterMatch && characterMatch[0] === username)
    requirementsMet.push(UsernameChecks.ALLOWED_CHARACTERS_ONLY);
  if (!(username.match(/^[-.]/) || username.match(/[-.]$/)))
    requirementsMet.push(UsernameChecks.GOOD_START_END);
  if (!username.match(/[-.]{2}/)) requirementsMet.push(UsernameChecks.NO_CONSECUTIVE);
  setSatsifiedReq(requirementsMet);
  return requirementsMet;
};

const RequirementsMessage = ({ message, isError }: { message: string; isError: boolean }) => (
  <Flex gap={2} alignItems="center">
    <Icon
      icon={isError ? "cancel" : "check"}
      accessibilityLabel={isError ? "Not met" : "Met"}
      color={isError ? "error" : "success"}
    />
    <Text size="200" color={isError ? "error" : "success"}>
      {message}
    </Text>
  </Flex>
);

const UsernameRequirements = ({ satisfiedReq }: { satisfiedReq: UsernameChecks[] }) => {
  const ReqToMessage = new Map<number, string>([
    [UsernameChecks.CHARACTER_LIMIT, "Between 3 to 20 characters long"],
    [UsernameChecks.ALLOWED_CHARACTERS_ONLY, "Only uses alphanumber characters, '-' and '.'"],
    [UsernameChecks.GOOD_START_END, "Does not start or end with '-' or '.'"],
    [UsernameChecks.NO_CONSECUTIVE, "Does not have consecutive '-' or '.' characters"],
  ]);

  const reqList = Object.keys(UsernameChecks).map(
    (value) =>
      !Number.isNaN(Number(value)) && (
        <RequirementsMessage
          key={`message-${value}`}
          message={ReqToMessage.get(parseInt(value, 10))}
          isError={!satisfiedReq.includes(parseInt(value, 10))}
        />
      ),
  );

  return (
    <Flex direction="column" gap={2}>
      {reqList}
    </Flex>
  );
};

/* Lets users change their username. Shows visuals for indicating whether a user's 
provided username meets the criteria for a valid username.
*/
const EditUsername = ({
  // TODO (leon): Refactor this to use OnChange, OnError, and OnSuccess?
  firebaseUser,
  user,
  setIsValid,
  onChange,
}: {
  // the firebase user profile for the user.
  firebaseUser: User;

  // the user profile we store for the user.
  user: UserProfile;

  // sets a boolean that indicates whether the username that the user has typed in is valid.
  setIsValid: (isValid: boolean) => void;
  onChange: (value: string) => void;
}) => {
  // stores the set of username requirements that have been met.
  const [satisfiedReq, setSatisfiedReq] = useState([]);

  useEffect(() => {
    // only set the username once so we don't reset it for subsequent updates.
    checkUsernameFormat(user.username, setSatisfiedReq);
  }, []);

  return (
    <Box width={400}>
      <TextField
        id="username"
        onChange={({ value }) => {
          // check to see that all requirements are met before enabling the save button
          setIsValid(
            checkUsernameFormat(value, setSatisfiedReq).length ===
              Object.values(UsernameChecks).length / 2,
          );
          if (onChange) onChange(value);
        }}
        label="Username"
        helperText="Your 1stCollab handle"
        value={user.username}
        // maxLength={20}
      />
      <Spacer height={20} />
      <UsernameRequirements satisfiedReq={satisfiedReq} />
    </Box>
  );
};

export default EditUsername;
