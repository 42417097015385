import React, { useEffect, useState } from "react";
import { Flex, Text } from "gestalt";
import "gestalt/dist/gestalt.css";
import { User } from "firebase/auth";

import { getAuthCreator, updateCreator } from "utils/CreatorUtils";
import { useCreator } from "components/creator/CreatorContext";
import { Creator } from "models/Creator";
import LoadingScreen from "components/LoadingScreen";
import { SaveButton, StatusText, SaveStatus } from "pages/settings/SettingsComponents";
import About from "components/creator/CreatorAbout";
import Spacer from "components/Spacer";
import HeaderMessage from "components/HeaderWithSubtitle";
import { getSettingsUser } from "pages/settings/Settings";

const getAndSetCreator = async (
  user: User,
  setCreator: (creator: Creator) => void,
  setIsLoaded: (isLoaded: boolean) => void,
) => {
  const firebaseToken = await user.getIdToken();
  const creator = await getAuthCreator(firebaseToken);
  if (creator) setCreator(creator);
  setIsLoaded(true);
};

const SettingsAbout = () => {
  const user: User = getSettingsUser();
  const [creator, setCreator] = useCreator();
  const [saveEnabled, setSaveEnabled] = useState(false);
  const [saveStatus, setSaveStatus] = useState(SaveStatus.INIT);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    getAndSetCreator(user, setCreator, setIsLoaded);
  }, []);

  const onSave = async () => {
    try {
      await updateCreator(creator, user);
      setSaveEnabled(false);
      setSaveStatus(SaveStatus.SUCCESS);
    } catch (error) {
      console.error("Failed to update user profile. Please try again.");
      setSaveStatus(SaveStatus.FAILURE);
      throw error;
    }
  };

  const onChange = () => {
    setSaveEnabled(true);
    setSaveStatus(SaveStatus.INIT);
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isLoaded ? (
        <Flex direction="column" width={600} gap={6} alignItems="center">
          <HeaderMessage
            header="Edit your creator profile"
            subtitle="Brands and creators visiting your profile will see the following info"
          />
          <Spacer height={50} />
          <About onChange={onChange} />
          <SaveButton enabled={saveEnabled} onSave={onSave} />
          <StatusText status={saveStatus} />
        </Flex>
      ) : (
        <LoadingScreen />
      )}
    </>
  );
};

export default SettingsAbout;
