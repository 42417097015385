import React from "react";
import { Alert } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";
import { ContractDeliverableStatus } from "components/contracts/models/Deliverable";

export default function DisputeReasonAlert({
  deliverableStatus, disputeReason,
}: {
  deliverableStatus: ContractDeliverableStatus;
  disputeReason: string;
}) {
  if (deliverableStatus !== ContractDeliverableStatus.LIVE_CONTENT_REVISIONS_REQUESTED) {
    return null;
  }

  return (
    <Alert
      radius="md"
      variant="light"
      color="red"
      title="Requested Changes"
      icon={<IconAlertCircle size="1rem" />}>
      {disputeReason}
    </Alert>
  );
}
