import DeliverableWithContent from "components/contracts/models/DeliverableWithContent";

export default class ContractContent {
  contractId: string;

  contractAmount: number;

  signatureDatetime: Date;

  signatureFirstName: string;

  signatureLastName: string;

  avatarUrl: string;

  deliverablesWithContent: DeliverableWithContent[];

  constructor({
    contractId,
    contractAmount,
    signatureDatetime,
    signatureFirstName,
    signatureLastName,
    avatarUrl,
    deliverablesWithContent,
  }: {
    contractId: string;
    contractAmount: number;
    signatureDatetime: Date;
    signatureFirstName: string;
    signatureLastName: string;
    avatarUrl: string;
    deliverablesWithContent: DeliverableWithContent[];
  }) {
    this.contractId = contractId;
    this.contractAmount = contractAmount;
    this.signatureDatetime = signatureDatetime;
    this.signatureFirstName = signatureFirstName;
    this.signatureLastName = signatureLastName;
    this.avatarUrl = avatarUrl;
    this.deliverablesWithContent = deliverablesWithContent;
  }

  public static deserialize(response: any) {
    const {
      contractId,
      contractAmount,
      signatureDatetime,
      signatureFirstName,
      signatureLastName,
      avatarUrl,
      deliverablesWithContent,
    } = response;

    return new ContractContent({
      contractId,
      contractAmount,
      signatureDatetime,
      signatureFirstName,
      signatureLastName,
      avatarUrl,
      deliverablesWithContent: deliverablesWithContent
        ? deliverablesWithContent.map((deliverableWithContent: any) =>
            DeliverableWithContent.deserialize(deliverableWithContent),
          )
        : [],
    });
  }
}
