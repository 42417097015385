import Script from "components/contracts/models/Script";

export default class VersionedScript {
  deliverableId: string;

  scripts: Script[];

  constructor({ deliverableId, scripts }: { deliverableId: string; scripts: Script[] }) {
    this.deliverableId = deliverableId;
    this.scripts = scripts;
  }

  public static deserialize(response: any) {
    const { deliverableId, scripts } = response;

    return new VersionedScript({
      deliverableId,
      scripts: scripts.map((script: any) => Script.deserialize(script)),
    });
  }
}
