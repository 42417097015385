import React, { useEffect, useState } from "react";
import { Flex, Table, Text } from "gestalt";
import "gestalt/dist/gestalt.css";
import { User } from "firebase/auth";

import LayoutBase from "components/LayoutBase";
import LoadingScreen from "components/LoadingScreen";
import { handleResult } from "utils/ApiUtils";
import { API_URL } from "configs/Configs";
import { getAdminUser } from "admin/AdminLayout";

interface CRMResponse {
  [key: string]: { [key: string]: number };
}

interface CRMEntry {
  [key: string]: number;
}

const transformData = (data: CRMResponse) => {
  const result: CRMEntry[] = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const [columnName, values] of Object.entries(data)) {
    // eslint-disable-next-line no-restricted-syntax
    for (const [index, value] of Object.entries(values)) {
      const intIndex = parseInt(index, 10);
      if (typeof result[intIndex] === "undefined") {
        result[intIndex] = {};
      }
      result[parseInt(index, 10)][columnName] = value;
    }
  }
  return result;
};

const getAndSetData = async (
  user: User,
  setData: (data: CRMEntry[]) => void,
  userOnly: boolean,
) => {
  const firebaseToken = await user.getIdToken();
  // ig_creator_stats_csv is the local user only view
  const apiToUse = userOnly ? "ig_creator_stats_csv" : "creator_stats_csv";
  const request = new Request(`${API_URL}/api/${apiToUse}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
  });
  const response = await handleResult(request);
  const tableData = transformData(response.data);
  setData(tableData);
};

const transformFee = (fee: number) => `$${fee.toFixed(2).toString()}`;

const TableDisplay = ({ data }: { data: CRMEntry[] }) => {
  const columnNames = [...Object.keys(data[0])].sort();

  // adds instagram link column as the first entry
  const instagramLinkIndex = columnNames.findIndex((name) => name === "Instagram Link");
  const [instagramLink] = columnNames.splice(instagramLinkIndex, 1);
  columnNames.unshift(instagramLink);

  // and then pushes email in front of that
  const emailIndex = columnNames.findIndex((name) => name === "email");
  const [email] = columnNames.splice(emailIndex, 1);
  columnNames.unshift(email);

  const headerCells = columnNames.map((entry) => (
    <Table.HeaderCell key={`HeaderCell-${entry}`}>
      <Text weight="bold">{entry}</Text>
    </Table.HeaderCell>
  ));

  const tableRows = [];
  // eslint-disable-next-line no-restricted-syntax
  for (let i = 0; i < data.length; i += 1) {
    const entry = data[i];
    const tableEntry = [];
    tableEntry.push(
      columnNames.map((columnName) => {
        const value =
          columnName.search("Fee --") > -1 ? transformFee(entry[columnName]) : entry[columnName];

        return <Table.Cell key={`Cell-${columnName}`}>{value}</Table.Cell>;
      }),
    );
    tableRows.push(<Table.Row key={`Row-${i}`}>{tableEntry}</Table.Row>);
  }

  return (
    <Table accessibilityLabel="CRM data">
      <Table.Header>
        <Table.Row>{headerCells}</Table.Row>
      </Table.Header>
      <Table.Body>{tableRows}</Table.Body>
    </Table>
  );
};

const AdminCRM = ({ userOnly }: { userOnly: boolean }) => {
  const user: User = getAdminUser();
  const [data, setData] = useState(null);

  useEffect(() => {
    getAndSetData(user, setData, userOnly);
  }, []);

  return (
    <Flex direction="column" gap={4}>
      <LayoutBase>{data ? <TableDisplay data={data} /> : <LoadingScreen />}</LayoutBase>
    </Flex>
  );
};

export default AdminCRM;
