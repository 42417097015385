import React from "react";

import { ActionIcon, Tooltip } from "@mantine/core";

import { IconExternalLink } from "@tabler/icons-react";

export default function ViewContractLink({ contractId }: { contractId: string }) {
  return (
    <Tooltip label="View Deliverables">
      <ActionIcon
        key={`${contractId}-actionIcon`}
        variant="subtle"
        color="gray"
        component="a"
        href={`https://www.1stcollab.com/admin/contracts/${contractId}`}
        target="_blank">
        <IconExternalLink color="gray" key={`${contractId}-deliverableLink`} size="1rem" />
      </ActionIcon>
    </Tooltip>
  );
}
