import React, { useEffect, useState } from "react";
import { User } from "firebase/auth";

import { Group, LoadingOverlay, Stack, Text } from "@mantine/core";

import { Contract, buildContractFromApiResponse } from "components/contracts/dashboard/Utils";
import {
  fetchContract,
  fetchPendingPayments,
  getPlatformBalance,
} from "components/contracts/common/Api";
import PendingPaymentsTable from "components/contracts/dashboard/PendingPaymentsTable";

export default function AllPendingPayments({ user }: { user: User }) {
  const [currentPlatformBalance, setCurrentPlatformBalance] = useState<number>(0);
  const [contractsPendingPayment, setContractsPendingPayment] = useState<Contract[]>([]);
  const [campaignIdToNameState, setCampaignIdToNameState] = useState<Record<string, string>>({});

  const [loadingPendingPayments, setLoadingPendingPayments] = useState(false);
  const [loadingPlatformBalance, setLoadingPlatformBalance] = useState(false);

  useEffect(() => {
    setLoadingPlatformBalance(true);
    getPlatformBalance(user)
      .then((response) => {
        const { success, platformBalance } = response;

        if (success) {
          setCurrentPlatformBalance(platformBalance);
        }
      })
      .finally(() => setLoadingPlatformBalance(false));

    setLoadingPendingPayments(true);
    fetchPendingPayments()
      .then((response) => {
        const { success, contracts, campaignIdToName } = response;

        if (success) {
          const deserializedContractsPendingPayments = contracts.map((contract: any) =>
            buildContractFromApiResponse(contract),
          );
          setContractsPendingPayment(deserializedContractsPendingPayments);
          setCampaignIdToNameState(campaignIdToName);
        }
      })
      .finally(() => setLoadingPendingPayments(false));
  }, []);

  const handleRefetchContract = (contractId: string, handleFetched: () => void) => {
    getPlatformBalance(user)
      .then((balanceResponse) => {
        const { success, platformBalance } = balanceResponse;

        if (success) {
          setCurrentPlatformBalance(platformBalance);
        }
        fetchContract(contractId).then((contractResponse) => {
          const updatedContracts = contractsPendingPayment.map((contract) => {
            if (contract.contractId === contractId) {
              return buildContractFromApiResponse(contractResponse.contract);
            }
            return contract;
          });
          setContractsPendingPayment(updatedContracts);
          handleFetched();
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const campaignsPendingPayment = Object.entries(campaignIdToNameState).map(
    ([campaignId, campaignName]) => {
      const contractsInCampaign = contractsPendingPayment.filter(
        (contract) => contract.campaignId.toString() === campaignId.toString(),
      );

      return (
        <PendingPaymentsTable
          key={`pending-payments-table-${campaignId}`}
          user={user} // TODO (victoria 3.2024): using for display name in ManualPaymentModal - need to get rid of this
          contracts={contractsInCampaign}
          campaignName={campaignName}
          handleRefetchContract={handleRefetchContract}
        />
      );
    },
  );

  return (
    <>
      <LoadingOverlay visible={loadingPlatformBalance || loadingPendingPayments} />
      <Stack>
        <Group justify="right">
          <Text size="xl">
            <Text fw="600" span>
              Stripe Balance:
            </Text>{" "}
            <Text span>
              {(currentPlatformBalance / 100).toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </Text>
          </Text>
        </Group>
        {campaignsPendingPayment}
      </Stack>
    </>
  );
}
