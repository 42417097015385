import React, { useState } from "react";
import { Flex, Loader, Center, Container, Stack, Group, NativeSelect } from "@mantine/core";
import "gestalt/dist/gestalt.css";
import { CandidateStateIndex } from "components/creator_lists/CreatorListsUtils";
import CreatorPlatformMultiSelector from "components/creator/CreatorPlatformMultiSelector";
import { CreatorSetMap, CreatorSetStateIndex } from "components/creator_sets/CreatorSetUtils";
import {
  CreatorSetEntryItem,
  CreatorSetEntryState,
  CreatorSetItem,
} from "components/discovery/Datamodels";
import { useAppSelector } from "reduxStore/hooks";

export const getCreatorSetStateCounter = (creatorEntries: CreatorSetEntryItem[]) => {
  const stateCounter = new Map<CreatorSetEntryState, number>();

  creatorEntries.forEach((entry) => {
    const entryState = entry.state;
    if (stateCounter.has(entryState)) {
      stateCounter.set(entryState, stateCounter.get(entryState)! + 1);
    } else {
      stateCounter.set(entryState, 1);
    }
  });
  return stateCounter;
};

const MantineCreatorSetStateStateDropdown = ({
  state,
  stateCounter,
  onStateChange,
}: {
  state: CreatorSetStateIndex;
  stateCounter: Map<CreatorSetEntryState, number>;
  onStateChange: (selectedState: number) => void;
}) => {
  const constructLabel = (entryState: CreatorSetEntryState, defaultLabel: string) => {
    const stateCount = stateCounter.get(entryState);
    if (stateCount > 0) {
      return `${defaultLabel} - ${stateCount}`;
    }
    return defaultLabel;
  };

  const selectData = [
    { value: "0", label: constructLabel(CreatorSetEntryState.NEEDS_REVIEW, "Needs Review") },
    { value: "1", label: constructLabel(CreatorSetEntryState.ACCEPTED, "Activated") },
    { value: "2", label: constructLabel(CreatorSetEntryState.REJECTED, "Rejected") },
    { value: "3", label: constructLabel(CreatorSetEntryState.WORKED_WITH, "Worked With") },
  ];

  return (
    <NativeSelect
      radius="md"
      data={selectData}
      label="Status"
      defaultValue={state}
      onChange={(event) => {
        const selectedState = parseInt(event.currentTarget.value, 10);
        onStateChange(selectedState);
      }}
    />
  );
};

const CreatorSetSelector = ({
  creatorSetId,
  setCreatorSetId,
}: {
  creatorSetId: number;
  setCreatorSetId: (creatorSetId: number) => void;
}) => {
  const allCreatorSetItems = useAppSelector((state) => state.creatorSets.creatorSetItems);
  const creatorSetItems = Object.values(allCreatorSetItems).sort(
    (a, b) => new Date(b.date_created).getTime() - new Date(a.date_created).getTime(),
  );

  const creatorSetSelectData = [
    ...creatorSetItems.map((creatorSetItem) => ({
      value: String(creatorSetItem.id),
      label: creatorSetItem.name,
    })),
  ];
  creatorSetSelectData.unshift({ value: "-1", label: "All Creator Sets" });

  return (
    <NativeSelect
      radius="md"
      data={creatorSetSelectData}
      label="Creator Set"
      defaultValue={creatorSetId}
      onChange={(event) => {
        const selectedCreatorSetId = parseInt(event.currentTarget.value, 10);
        setCreatorSetId(selectedCreatorSetId);
      }}
    />
  );
};

const CreatorSetBanner = ({
  creatorSetEntries,
  creatorSetId,
  setCreatorSetId,
  creatorSetStateIndex,
  setCreatorSetStateIndex,
  multiSelectedPlatforms,
  onMultiSelectedPlatformsChange,
  useCreatorSetSelector = false,
}: {
  creatorSetEntries: CreatorSetEntryItem[];
  creatorSetId: number;
  setCreatorSetId: (creatorSetId: number) => void;
  creatorSetStateIndex: CreatorSetStateIndex;
  setCreatorSetStateIndex: (index: CreatorSetStateIndex) => void;
  multiSelectedPlatforms: string[];
  onMultiSelectedPlatformsChange: (platformList: string[]) => void;
  useCreatorSetSelector: boolean;
}) => {
  const stateCounter = getCreatorSetStateCounter(creatorSetEntries);

  return (
    <Flex>
      <Group preventGrowOverflow w="100%" justify="center">
        <CreatorPlatformMultiSelector
          selectedPlatforms={multiSelectedPlatforms}
          onMultiSelectedPlatformsChange={onMultiSelectedPlatformsChange}
        />
        {useCreatorSetSelector ? (
          <CreatorSetSelector creatorSetId={creatorSetId} setCreatorSetId={setCreatorSetId} />
        ) : null}
        <MantineCreatorSetStateStateDropdown
          state={creatorSetStateIndex}
          stateCounter={stateCounter}
          onStateChange={(activeIndex) => {
            setCreatorSetStateIndex(activeIndex);
          }}
        />
      </Group>
    </Flex>
  );
};

export default CreatorSetBanner;
