import React from "react";

import { IconCircleCheck, IconCircleX, IconExclamationCircle } from "@tabler/icons-react";

import { ThemeIcon, Tooltip } from "@mantine/core";

import { PaymentStatus } from "components/contracts/common/Common";

export default function ReadyForPayment({
  contractAccepted,
  contractComplete,
  paymentStatus,
  awaitingPaymentDetails,
  readyForPayment,
}: {
  contractAccepted: boolean;
  contractComplete: boolean;
  paymentStatus: PaymentStatus;
  awaitingPaymentDetails: boolean;
  readyForPayment: boolean;
}) {
  if (!contractAccepted) {
    return (
      <ThemeIcon variant="light" color="gray" size="sm" radius="xl">
        <IconCircleX />
      </ThemeIcon>
    );
  }
  if (readyForPayment) {
    return (
      <ThemeIcon variant="light" color="green" size="sm" radius="xl">
        <IconCircleCheck />
      </ThemeIcon>
    );
  }
  if (!contractComplete) {
    return (
      <Tooltip label="Incomplete Deliverables">
        <ThemeIcon variant="light" color="red" size="sm" radius="xl">
          <IconCircleX />
        </ThemeIcon>
      </Tooltip>
    );
  }
  if (paymentStatus === PaymentStatus.FULLY_PAID) {
    return (
      <ThemeIcon variant="light" color="gray" size="sm" radius="xl">
        <IconCircleCheck />
      </ThemeIcon>
    );
  }
  if (awaitingPaymentDetails) {
    return (
      <Tooltip label="Missing Payment Details">
        <ThemeIcon variant="light" color="red" size="sm" radius="xl">
          <IconExclamationCircle />
        </ThemeIcon>
      </Tooltip>
    );
  }

  return null;
}
