/* eslint-disable react/jsx-props-no-spreading */
// needs this disabled since mantine/form needs prop spreading
import React, { useState } from "react";
import {
  ActionIcon,
  Button,
  Card,
  Checkbox,
  Group,
  LoadingOverlay,
  Stack,
  Text,
  TextInput,
  Title,
  Tooltip,
  rem,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { modals } from "@mantine/modals";
import { useForm } from "@mantine/form";
import { IconAt, IconExternalLink, IconX, IconArrowForward, IconMail } from "@tabler/icons-react";

import { mergeCreatorFromLink, checkMergeRequest } from "campaigns/api/fetchCreatorInfoFromLink";
import { useDisclosure } from "@mantine/hooks";

// eslint-disable-next-line no-promise-executor-return
const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const MergeInputFields = ({
  baseCreatorId,
  youtubeHandle,
  couldNotFindYoutube,
  tiktokHandle,
  couldNotFindTiktok,
  instagramHandle,
  couldNotFindInstagram,
  firstName,
  couldNotFindFirstName,
  lastName,
  email,
  couldNotFindEmail,
  close,
}: {
  baseCreatorId: string | number;
  youtubeHandle?: string;
  couldNotFindYoutube?: boolean;
  tiktokHandle?: string;
  couldNotFindTiktok?: boolean;
  instagramHandle?: string;
  couldNotFindInstagram?: boolean;
  firstName?: string;
  couldNotFindFirstName?: boolean;
  lastName?: string;
  email?: string;
  couldNotFindEmail?: boolean;
  close: () => void;
}) => {
  let mergeRequestId: number;

  const [mergeButtonLoading, setMergeButtonLoading] = useState(false);
  const [visible, handlers] = useDisclosure(false);

  const form = useForm({
    initialValues: {
      youtubeHandle,
      couldNotFindYoutube,
      instagramHandle,
      couldNotFindInstagram,
      tiktokHandle,
      couldNotFindTiktok,
      firstName,
      couldNotFindFirstName,
      lastName,
      email,
      couldNotFindEmail,
    },
  });

  const notifyFailedMerge = () => {
    notifications.show({
      color: "red",
      title: "Error with merging the creator!",
      icon: <IconX />,
      message: `Merge request failed`,
      autoClose: false,
    });
  };

  const combinedDetails = {
    creatorId: Number(baseCreatorId),
    ...form.values,
  };

  const destructiveFields = [
    form.isDirty("youtubeHandle"),
    form.isDirty("tiktokHandle"),
    form.isDirty("instagramHandle"),
  ];
  const dirtied = destructiveFields.some((e) => e);

  /* eslint-disable no-await-in-loop */
  /* eslint-disable @typescript-eslint/no-loop-func */
  const pollMergeRequestAndNotify = async (retries = 10, delayTime = 1000) => {
    let i = 0;
    let done = false;
    while (i < retries && !done) {
      checkMergeRequest({ mergeRequestId }).then((result) => {
        if (result && result.success && result.merge_status === "success") {
          window.location.reload();
          done = true;
          close();
        } else if (result && result.success && result.merge_status === "failed") {
          done = true;
        }
      });
      await delay(delayTime);
      i += 1;
    }
    notifyFailedMerge();
    handlers.close();
  };
  /* eslint-enable no-await-in-loop */
  /* eslint-enable @typescript-eslint/no-loop-func */

  const triggerMerge = () => {
    setMergeButtonLoading(true);
    handlers.open();
    mergeCreatorFromLink(combinedDetails)
      .then((res) => {
        if (res == null || !res?.success) {
          notifications.show({
            color: "red",
            title: "Error with creator merge submission",
            icon: <IconX />,
            message: `${res.message}`,
            autoClose: false,
          });
          setMergeButtonLoading(false);
          handlers.close();
        } else {
          mergeRequestId = res.data.merge_request_id;
          notifications.show({
            title: "Creator merge request submitted",
            message: "Page will refresh if merge success, or notify failure",
            color: "blue",
            icon: <IconArrowForward />,
            autoClose: false,
          });
          pollMergeRequestAndNotify(10, 3000);
          setMergeButtonLoading(false);
        }
      })
      .catch((error) => {
        notifications.show({
          color: "red",
          title: "Error with merging the creator!",
          icon: <IconX />,
          message: `${error.message}`,
          autoClose: false,
        });
      });
  };

  const openModal = () =>
    modals.openConfirmModal({
      title: "Please confirm your edits",
      children: (
        <Text size="sm">
          You made edits to the handles which can cause any info stored on those handles to be
          overwritten with the fields filled out below. Proceed with merge?
        </Text>
      ),
      labels: { confirm: "Confirm", cancel: "Cancel" },
      onConfirm: () => triggerMerge(),
    });

  const mergeCreatorfromUrl = () => {
    // if handle inputs touched, give warning window
    if (dirtied) {
      openModal();
    } else {
      // contine merge as usual
      triggerMerge();
    }
  };

  return (
    <Stack gap="sm">
      <LoadingOverlay visible={visible} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
      <Card bg="blue.0" p={20} withBorder>
        <Title order={5}>Creator Social Handles</Title>
        <Group align="stretch">
          <Stack gap="sm">
            <TextInput
              leftSection={<IconAt size={16} />}
              {...form.getInputProps("youtubeHandle")}
              onChange={(event) => form.setValues({ youtubeHandle: event.currentTarget.value })}
              label="YouTube Handle"
              styles={{
                input: form.isDirty("youtubeHandle")
                  ? {
                      outline: "2px solid #B2F2BB",
                      outlineOffset: "-2px",
                      backgroundColor: "#EBFBEE",
                    }
                  : null,
              }}
              rightSection={
                <Tooltip
                  label="View Channel"
                  style={{ display: youtubeHandle ? undefined : "none" }}>
                  <ActionIcon
                    key="youtube-actionIcon"
                    variant="subtle"
                    color="gray"
                    component="a"
                    href={`https://www.youtube.com/@${youtubeHandle}`}
                    target="_blank"
                    size={32}
                    style={{ display: youtubeHandle ? undefined : "none" }}>
                    <IconExternalLink
                      color="gray"
                      key="youtube-link"
                      style={{
                        width: rem(18),
                        height: rem(18),
                        display: youtubeHandle ? undefined : "none",
                      }}
                      stroke={1.5}
                    />
                  </ActionIcon>
                </Tooltip>
              }
            />
            <Checkbox
              label="Could not find youtube"
              {...form.getInputProps("couldNotFindYoutube", { type: "checkbox" })}
              disabled={form.values.youtubeHandle !== ""}
            />
          </Stack>

          <Stack gap="sm">
            <TextInput
              leftSection={<IconAt size={16} />}
              {...form.getInputProps("tiktokHandle")}
              onChange={(event) => form.setValues({ tiktokHandle: event.currentTarget.value })}
              label="TikTok Handle"
              styles={{
                input: form.isDirty("tiktokHandle")
                  ? {
                      outline: "2px solid #B2F2BB",
                      outlineOffset: "-2px",
                      backgroundColor: "#EBFBEE",
                    }
                  : null,
              }}
              rightSection={
                <Tooltip
                  label="View Profile"
                  style={{ display: tiktokHandle ? undefined : "none" }}>
                  <ActionIcon
                    key="tiktok-actionIcon"
                    variant="subtle"
                    color="gray"
                    component="a"
                    href={`https://www.tiktok.com/@${tiktokHandle}`}
                    target="_blank"
                    size={32}
                    style={{ display: tiktokHandle ? undefined : "none" }}>
                    <IconExternalLink
                      color="gray"
                      key="tiktok-link"
                      style={{
                        width: rem(18),
                        height: rem(18),
                        display: tiktokHandle ? undefined : "none",
                      }}
                      stroke={1.5}
                    />
                  </ActionIcon>
                </Tooltip>
              }
            />
            <Checkbox
              label="Could not find tiktok"
              {...form.getInputProps("couldNotFindTiktok", { type: "checkbox" })}
              disabled={form.values.tiktokHandle !== ""}
            />
          </Stack>
          <Stack gap="sm">
            <TextInput
              leftSection={<IconAt size={16} />}
              {...form.getInputProps("instagramHandle")}
              onChange={(event) => form.setValues({ instagramHandle: event.currentTarget.value })}
              label="Instagram Handle"
              styles={{
                input: form.isDirty("instagramHandle")
                  ? {
                      outline: "2px solid #B2F2BB",
                      outlineOffset: "-2px",
                      backgroundColor: "#EBFBEE",
                    }
                  : null,
              }}
              rightSection={
                <Tooltip
                  label="View Profile"
                  style={{ display: instagramHandle ? undefined : "none" }}>
                  <ActionIcon
                    key="instagram-actionIcon"
                    variant="subtle"
                    color="gray"
                    component="a"
                    href={`https://www.instagram.com/${instagramHandle}`}
                    target="_blank"
                    size={32}
                    style={{ display: instagramHandle ? undefined : "none" }}>
                    <IconExternalLink
                      color="gray"
                      key="instagram-link"
                      style={{
                        width: rem(18),
                        height: rem(18),
                        display: instagramHandle ? undefined : "none",
                      }}
                      stroke={1.5}
                    />
                  </ActionIcon>
                </Tooltip>
              }
            />
            <Checkbox
              label="Could not find instagram"
              {...form.getInputProps("couldNotFindInstagram", { type: "checkbox" })}
              disabled={form.values.instagramHandle !== ""}
            />
          </Stack>
        </Group>
      </Card>
      <Card bg="gray.0" p={20} withBorder>
        <Title order={5}>Creator Contact Info</Title>
        <Group align="stretch" justify="space-between">
          <Stack gap="sm">
            <TextInput
              {...form.getInputProps("firstName")}
              label="First Name"
              styles={{
                input: form.isDirty("firstName")
                  ? {
                      outline: "2px solid #B2F2BB",
                      outlineOffset: "-2px",
                      backgroundColor: "#EBFBEE",
                    }
                  : null,
              }}
            />
            <Checkbox
              label="Could not find first name"
              {...form.getInputProps("couldNotFindFirstName", { type: "checkbox" })}
              disabled={form.values.firstName !== ""}
            />
          </Stack>
          <TextInput
            {...form.getInputProps("lastName")}
            label="Last Name"
            styles={{
              input: form.isDirty("lastName")
                ? {
                    outline: "2px solid #B2F2BB",
                    outlineOffset: "-2px",
                    backgroundColor: "#EBFBEE",
                  }
                : null,
            }}
          />
          <Stack gap="sm">
            <TextInput
              leftSection={<IconMail size={16} />}
              {...form.getInputProps("email")}
              label="Email"
              styles={{
                input: form.isDirty("email")
                  ? {
                      outline: "2px solid #B2F2BB",
                      outlineOffset: "-2px",
                      backgroundColor: "#EBFBEE",
                    }
                  : null,
              }}
            />
            <Checkbox
              label="Could not find email"
              {...form.getInputProps("couldNotFindEmail", { type: "checkbox" })}
              disabled={form.values.email !== ""}
            />
          </Stack>
        </Group>
      </Card>
      <Button loading={mergeButtonLoading} onClick={mergeCreatorfromUrl}>
        Save
      </Button>
    </Stack>
  );
};

const ImportedCreatorDetails = ({
  baseCreatorId,
  close,
  youtubeHandle,
  tiktokHandle,
  instagramHandle,
  firstName,
  lastName,
  email,
  couldNotFindYoutube,
  couldNotFindTiktok,
  couldNotFindInstagram,
  couldNotFindFirstName,
  couldNotFindEmail,
  title,
  subtitle,
  hidden = true,
}: {
  baseCreatorId: string | number;
  close: () => void;
  youtubeHandle?: string;
  tiktokHandle?: string;
  instagramHandle?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  couldNotFindYoutube?: boolean;
  couldNotFindTiktok?: boolean;
  couldNotFindInstagram?: boolean;
  couldNotFindFirstName?: boolean;
  couldNotFindEmail?: boolean;
  title?: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  hidden?: boolean;
}) => {
  if (hidden) {
    return null;
  }

  return (
    <Stack gap="xs">
      {title ? (
        <Title pt={10} order={3}>
          {title}
          <Text component="div" size="sm" c="gray" p={2}>
            {subtitle}
          </Text>
        </Title>
      ) : null}
      <MergeInputFields
        baseCreatorId={baseCreatorId}
        close={close}
        youtubeHandle={youtubeHandle}
        tiktokHandle={tiktokHandle}
        instagramHandle={instagramHandle}
        firstName={firstName}
        lastName={lastName}
        email={email}
        couldNotFindYoutube={couldNotFindYoutube}
        couldNotFindTiktok={couldNotFindTiktok}
        couldNotFindInstagram={couldNotFindInstagram}
        couldNotFindFirstName={couldNotFindFirstName}
        couldNotFindEmail={couldNotFindEmail}
      />
    </Stack>
  );
};

export default ImportedCreatorDetails;
