import { MultiSelect } from "@mantine/core";
import React, { useState } from "react";
import { PLATFORM_ID_TO_LABEL_NO_ALL } from "components/creator/CreatorPlatformUtils";

const CreatorPlatformMultiSelector = ({
  selectedPlatforms,
  onMultiSelectedPlatformsChange,
}: {
  selectedPlatforms: string[];
  onMultiSelectedPlatformsChange: (platformList: string[]) => void;
}) => {
  const platforms = PLATFORM_ID_TO_LABEL_NO_ALL;
  const platformOrder = [...platforms.keys()];

  const selectData = platformOrder.map((platformId) => ({
    value: String(platformId),
    label: String(platforms.get(platformId)),
  }));

  return (
    <MultiSelect
      label="Expanded views"
      radius="md"
      checkIconPosition="left"
      placeholder={selectedPlatforms.length === 0 ? "Select one or more" : null}
      data={selectData}
      searchable
      clearable
      onChange={(platformIdList) => onMultiSelectedPlatformsChange(platformIdList)}
      miw="400px"
    />
  );
};

export default CreatorPlatformMultiSelector;
