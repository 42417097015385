import React, { useEffect, useState } from "react";

import { Loader, NumberInput, Paper, Switch, Table } from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";

import { IconCheck, IconX } from "@tabler/icons-react";

import {
  CrawlStatus,
  getTikTokCrawlStatus,
  updateCrawlPriority,
  updateCrawlSkip,
} from "admin/api/labeling/crawlingApi";
import { fromISODateString } from "utils/DateUtils";

const OpsCrawlStatusTableRow = ({ crawlStatus }: { crawlStatus: CrawlStatus }) => {
  const [originalPriority, setOriginalPriority] = useState<string | number>(crawlStatus.priority);
  const [originalSkip, setOriginalSkip] = useState<boolean>(crawlStatus.force_skip);
  const [priority, setPriority] = useState<string | number>(crawlStatus.priority);
  const [debouncedPriority] = useDebouncedValue<string | number>(priority, 500);
  const [skip, setSkip] = useState(crawlStatus.force_skip);
  const [saveSuccess, setSaveSuccess] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (debouncedPriority !== originalPriority) {
      setSaveSuccess(false);
      setLoading(true);
      updateCrawlPriority(crawlStatus.crawl_id, crawlStatus.crawl_type, Number(debouncedPriority))
        .then(() => {
          setSaveSuccess(true);
          setOriginalPriority(debouncedPriority);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [debouncedPriority]);

  useEffect(() => {
    if (skip !== originalSkip) {
      setSaveSuccess(false);
      setLoading(true);
      updateCrawlSkip(crawlStatus.crawl_id, crawlStatus.crawl_type, skip)
        .then(() => {
          setSaveSuccess(true);
          setOriginalSkip(skip);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [skip]);

  return (
    <Table.Tr>
      <Table.Td>{crawlStatus.crawl_id}</Table.Td>
      <Table.Td>{crawlStatus.seed}</Table.Td>
      <Table.Td>{crawlStatus.crawl_type}</Table.Td>
      <Table.Td>
        <NumberInput value={priority} onChange={setPriority} />
      </Table.Td>
      <Table.Td>
        {crawlStatus?.enqueue_ts
          ? fromISODateString(crawlStatus.enqueue_ts)?.toLocaleString()
          : "-"}
      </Table.Td>
      <Table.Td>
        {crawlStatus?.start_ts ? fromISODateString(crawlStatus.start_ts)?.toLocaleString() : "-"}
      </Table.Td>
      <Table.Td>
        {crawlStatus?.finish_ts ? fromISODateString(crawlStatus.finish_ts)?.toLocaleString() : "-"}
      </Table.Td>
      <Table.Td>{crawlStatus.num_crawls}</Table.Td>
      <Table.Td>{crawlStatus.num_pages}</Table.Td>
      <Table.Td>{crawlStatus.recent_failures}</Table.Td>
      <Table.Td>
        <Switch checked={skip} onChange={(event) => setSkip(event.currentTarget.checked)} />
      </Table.Td>
      <Table.Td>
        {loading ? (
          <Loader size="xs" />
        ) : (
          (saveSuccess && <IconCheck color="green" />) || <IconX color="red" />
        )}
      </Table.Td>
    </Table.Tr>
  );
};

const OpsCrawlStatusTable = ({ crawlStatuses }: { crawlStatuses: CrawlStatus[] }) => {
  return (
    <Table horizontalSpacing="md" verticalSpacing="md" highlightOnHover withTableBorder>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Crawl ID</Table.Th>
          <Table.Th>Seed</Table.Th>
          <Table.Th>Crawl Type</Table.Th>
          <Table.Th>Priority</Table.Th>
          <Table.Th>Enqueue Time</Table.Th>
          <Table.Th>Start Time</Table.Th>
          <Table.Th>Finish Time</Table.Th>
          <Table.Th>Number of Crawls</Table.Th>
          <Table.Th>Number of Pages</Table.Th>
          <Table.Th>Recent Failures</Table.Th>
          <Table.Th>Skip</Table.Th>
          <Table.Th>Saved</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {crawlStatuses.map((crawlStatus) => (
          <OpsCrawlStatusTableRow key={crawlStatus.crawl_id} crawlStatus={crawlStatus} />
        ))}
      </Table.Tbody>
    </Table>
  );
};

export const OpsCrawlTool = () => {
  const [crawlStatuses, setCrawlStatuses] = useState<CrawlStatus[]>([]);

  useEffect(() => {
    getTikTokCrawlStatus(setCrawlStatuses);
  }, []);

  return (
    <Paper>
      <OpsCrawlStatusTable crawlStatuses={crawlStatuses} />
    </Paper>
  );
};

export default OpsCrawlTool;
