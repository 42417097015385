import { configureStore } from "@reduxjs/toolkit";
import meSlice from "reduxStore/meSlice";
import campaignsSlice from "reduxStore/campaignsSlice";
import creatorSetsSlice from "reduxStore/creatorSetsSlice";
import creativeBriefsSlice from "reduxStore/creativeBriefsSlice";

const store = configureStore({
  reducer: {
    me: meSlice,
    campaigns: campaignsSlice,
    creatorSets: creatorSetsSlice,
    creativeBriefs: creativeBriefsSlice,
  },
  devTools: false, // set to true for local development only
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
