import React, { useState } from "react";
import { Anchor, Flex, List, Text } from "@mantine/core";
import { AdGroupToDoSummary } from "models/Campaign";
import { BasicTodoWithDetails } from "campaigns/main/campaignPage/BasicTodo";
import { useNavigate } from "react-router-dom";
import TodoListItem from "campaigns/main/campaignPage/TodoListItem";

const getReviewCount = (toDoSummary: AdGroupToDoSummary) => toDoSummary.total;

const AdGroupCreativeReviewToDoDetails = ({
  campaignId,
  adGroupId,
  toDoSummary,
}: {
  campaignId: number;
  adGroupId: number;
  toDoSummary: AdGroupToDoSummary;
}) => {
  const navigate = useNavigate();
  const liveVerificationCount = toDoSummary?.live_verification || 0;
  const promoCodeCount = toDoSummary?.promo_codes || 0;
  const scriptCount = toDoSummary?.script_reviews || 0;
  const videoCount = toDoSummary?.video_reviews || 0;
  const unreadCount = toDoSummary?.unread_messages || 0;
  const referralLinkCount = toDoSummary?.referral_links || 0;
  const productAccessCount = toDoSummary?.product_access_reviews || 0;
  const contractReviewCount = toDoSummary?.contract_reviews || 0;

  const generateCreativeTodoLabel = (scriptTag: string, count: number) =>
    `You have ${count} ${scriptTag} to review.`;
  const generateDeliverableToDoLabel = (
    pluralItems: string,
    singularItem: string,
    count: number,
  ) => {
    const deliverableText = count > 1 ? "deliverables" : "deliverable";
    const linkText = count > 1 ? pluralItems : singularItem;
    return `You have ${count} ${deliverableText} that ${
      count > 1 ? "require" : "requires"
    } ${linkText}.`;
  };
  const generateNavLink = () => `/campaigns/${campaignId}/${adGroupId}/contracts/`;
  const contractProvideProductAccessLink = `${generateNavLink()}?type=product_access`;
  const contractPromocodesAndReferralLink = `${generateNavLink()}?type=promo_codes_and_links`;
  const contractReviewContentLink = `${generateNavLink()}?type=review_content`;
  const contractReviewLink = `/campaigns/${campaignId}/${adGroupId}/contract_approval/`;
  const liveVerificationLink = `${generateNavLink()}?type=live_verification`;
  const ctaText = "Review Content";

  // TODO (victoria): FEELS REPETITIVE
  return (
    <List withPadding>
      {contractReviewCount > 0 ? (
        <TodoListItem
          key={`${campaignId}-${adGroupId}-todo-details-contract-reviews`}
          title={generateCreativeTodoLabel(
            contractReviewCount > 1 ? "contract reviews" : "contract review",
            contractReviewCount,
          )}
          onClick={() => navigate(contractReviewLink)}
          ctaText={contractReviewCount > 1 ? "Review Contracts" : "Review Contract"}
        />
      ) : null}
      {liveVerificationCount > 0 ? (
        <TodoListItem
          key={`${campaignId}-${adGroupId}-todo-details-live-verification`}
          title={generateCreativeTodoLabel(
            liveVerificationCount > 1 ? "live videos" : "live video",
            liveVerificationCount,
          )}
          onClick={() => navigate(liveVerificationLink)}
          ctaText={ctaText}
        />
      ) : null}
      {productAccessCount > 0 ? (
        <TodoListItem
          key={`${campaignId}-${adGroupId}-todo-details-product-access`}
          title={generateDeliverableToDoLabel(
            "product access",
            "product access",
            productAccessCount,
          )}
          onClick={() => navigate(contractProvideProductAccessLink)}
          ctaText={ctaText}
        />
      ) : null}
      {referralLinkCount > 0 ? (
        <TodoListItem
          key={`${campaignId}-${adGroupId}-todo-details-referral-links`}
          title={generateDeliverableToDoLabel(
            "referral links",
            "a referral link",
            referralLinkCount,
          )}
          onClick={() => navigate(contractPromocodesAndReferralLink)}
          ctaText={ctaText}
        />
      ) : null}
      {promoCodeCount > 0 ? (
        <TodoListItem
          key={`${campaignId}-${adGroupId}-todo-details-promo-codes`}
          title={generateDeliverableToDoLabel("promo codes", "a promo code", promoCodeCount)}
          onClick={() => navigate(contractPromocodesAndReferralLink)}
          ctaText={ctaText}
        />
      ) : null}
      {videoCount > 0 ? (
        <TodoListItem
          key={`${campaignId}-${adGroupId}-todo-details-videos`}
          title={generateCreativeTodoLabel(
            videoCount > 1 ? "video drafts" : "video draft",
            videoCount,
          )}
          onClick={() => navigate(contractReviewContentLink)}
          ctaText={ctaText}
        />
      ) : null}
      {scriptCount > 0 ? (
        <TodoListItem
          key={`${campaignId}-${adGroupId}-todo-details-scripts`}
          title={generateCreativeTodoLabel(scriptCount > 1 ? "scripts" : "script", scriptCount)}
          onClick={() => navigate(contractReviewContentLink)}
          ctaText={ctaText}
        />
      ) : null}
      {unreadCount > 0 ? (
        <TodoListItem
          key={`${campaignId}-${adGroupId}-todo-details-messages`}
          title={generateCreativeTodoLabel(
            unreadCount > 1 ? "unread messages" : "unread message",
            unreadCount,
          )}
          onClick={() => navigate(contractReviewContentLink)}
          ctaText={ctaText}
        />
      ) : null}
    </List>
  );
};

const AdGroupCreativeReviewToDos = ({
  campaignId,
  adGroupId,
  toDoSummary,
}: {
  campaignId: number;
  adGroupId: number;
  toDoSummary: AdGroupToDoSummary;
}) => {
  const numTodos = getReviewCount(toDoSummary);
  if (numTodos === 0) {
    return null;
  }
  const todoText = `${numTodos} ${
    numTodos === 1 ? "creative" : "creatives"
  } to review for your campaign.`;
  const [showDetails, setShowDetails] = useState<boolean>(false);
  return (
    <>
      <BasicTodoWithDetails
        title="Creative Review"
        description={`${todoText}`}
        showDetails={showDetails}
        onShowDetailsToggled={(newValue) => setShowDetails(newValue)}
      />
      {showDetails ? (
        <AdGroupCreativeReviewToDoDetails
          campaignId={campaignId}
          adGroupId={adGroupId}
          toDoSummary={toDoSummary}
        />
      ) : null}
    </>
  );
};

const AdGroupToDos = ({
  campaignId,
  adGroupId,
  toDoSummary,
}: {
  campaignId: number;
  adGroupId: number;
  toDoSummary: AdGroupToDoSummary;
}) => {
  if (toDoSummary.total === 0) {
    return null;
  }
  return (
    <AdGroupCreativeReviewToDos
      campaignId={campaignId}
      adGroupId={adGroupId}
      toDoSummary={toDoSummary}
    />
  );
};

export default AdGroupToDos;
