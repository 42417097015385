import React from "react";

import { Avatar, Card, Group, Stack, Text } from "@mantine/core";

import { User } from "models/User";
import { OpsTask } from "models/OpsTask";
import { OpsUserTasksTable } from "admin/app/ops/OpsUserTasksTable";

export const OpsUserTasks = ({ opsUser, opsTasks }: { opsUser: User; opsTasks: OpsTask[] }) => {
  // filter tasks to only the ops user
  const userTasks = opsUser
    ? opsTasks.filter((task) => task?.assigned_user_details?.key === opsUser.key)
    : opsTasks.filter((task) => task?.assigned_user_details?.key === null);

  const getInitials = (name: string) => {
    if (!name) return "";
    const names = name.split(" ");
    return names[0].charAt(0) + names[names.length - 1].charAt(0);
  };
  return (
    <Stack w="100%">
      <Card shadow="xs" padding="md" radius="md" withBorder>
        <Card.Section inheritPadding withBorder py="sm">
          <Group justify="space-between">
            <Group>
              {opsUser?.avatar_url ? (
                <Avatar w={40} h={40} src={opsUser?.avatar_url} style={{ flex: "none" }} />
              ) : (
                <Avatar w={40} h={40} style={{ flex: "none" }}>
                  {getInitials(opsUser?.name)}
                </Avatar>
              )}
              <Text fw={500}>{opsUser ? opsUser.name : "Unassigned"}</Text>
            </Group>
          </Group>
        </Card.Section>
        <Card.Section inheritPadding withBorder py="sm">
          <OpsUserTasksTable
            key={opsUser?.key || "unassigned-user-task-table"}
            opsUser={opsUser}
            opsTasks={userTasks}
          />
        </Card.Section>
      </Card>
    </Stack>
  );
};

export default OpsUserTasks;
