import React, { ReactNode } from "react";

import { notifications } from "@mantine/notifications";

import { IconCheck, IconX } from "@tabler/icons-react";

export function showSuccessNotification({
  message,
  title,
  stayOpen,
}: {
  message: ReactNode;
  title?: string;
  stayOpen?: boolean;
}) {
  if (stayOpen) {
    notifications.show({
      title: title || "Success",
      message,
      icon: <IconCheck size="1.1rem" />,
      color: "teal",
      autoClose: false,
    });
  } else {
    notifications.show({
      title: title || "Success",
      message,
      icon: <IconCheck size="1.1rem" />,
      color: "teal",
    });
  }
}

export function showFailureNotification({
  message,
  title,
  stayOpen,
}: {
  message: ReactNode;
  title?: string;
  stayOpen?: boolean;
}) {
  if (stayOpen) {
    notifications.show({
      title: title || "Error",
      message,
      icon: <IconX size="1.1rem" />,
      color: "red",
      autoClose: false,
    });
  } else {
    notifications.show({
      title: title || "Error",
      message,
      icon: <IconX size="1.1rem" />,
      color: "red",
    });
  }
}
