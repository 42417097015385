import React from "react";
import SimpleSocialHandleButton from "components/contracts/common/SimpleSocialHandleButton";
import { SocialHandle } from "components/contracts/dashboard/Utils";


// Social handle buttons without handles.
export default function SocialHandleButtonRow({ socialHandles }: { socialHandles: SocialHandle[] }) {
  const socialHandleButtons = socialHandles.map((socialHandleData) => (
    <SimpleSocialHandleButton
      key={socialHandleData.platform + socialHandleData.handle}
      platform={socialHandleData.platform}
      profileLink={socialHandleData.profileLink}
    />
  ));

  // Wrap in a bare div because we don't want any special treatment.
  // Just buttons close to one another.
  return <div>{socialHandleButtons}</div>;
};