import React from "react";

import { Checkbox, Flex, TextInput } from "@mantine/core";

export const BrandHashtagSearch = ({
  query,
  setQuery,
  shouldFilterLabeled,
  setShouldFilterLabeled,
  includeHidden,
  setIncludeHidden,
  shouldFilterSponsored,
  setShouldFilterSponsored,
}: {
  query: string;
  setQuery: (query: string) => void;
  shouldFilterLabeled: boolean;
  setShouldFilterLabeled: (shouldFilterLabeled: boolean) => void;
  includeHidden: boolean;
  setIncludeHidden: (shouldFilterHidden: boolean) => void;
  shouldFilterSponsored: boolean;
  setShouldFilterSponsored: (shouldFilterSponsored: boolean) => void;
}) => {
  return (
    <Flex justify="center" align="center" gap="md">
      <TextInput
        w={300}
        placeholder="Search for a brand or hashtag"
        value={query}
        onChange={(event) => setQuery(event.currentTarget.value)}
      />
      <Checkbox
        size="md"
        label="Show Unlabeled Only"
        checked={shouldFilterLabeled}
        onChange={() => setShouldFilterLabeled(!shouldFilterLabeled)}
      />
      <Checkbox
        size="md"
        label="Show Sponsored Only"
        checked={shouldFilterSponsored}
        onChange={() => setShouldFilterSponsored(!shouldFilterSponsored)}
      />
      <Checkbox
        size="md"
        label="Include Hidden"
        checked={includeHidden}
        onChange={() => setIncludeHidden(!includeHidden)}
      />
    </Flex>
  );
};

export default BrandHashtagSearch;
