import React, { useState } from "react";

import { User } from "firebase/auth";

import {
  Anchor,
  Button,
  Group,
  List,
  Modal,
  NumberInput,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";

import { DatePickerInput } from "@mantine/dates";

import { useDisclosure } from "@mantine/hooks";

import { IconCalendar } from "@tabler/icons-react";

import { recordManualPayment } from "components/contracts/common/Api";

import { formatAmount } from "components/contracts/dashboard/Utils";
import {
  showFailureNotification,
  showSuccessNotification,
} from "components/common/Notifications";
import { toISODateString } from "utils/DateUtils";

function isValidAmount(amountInMinorUnits: number, remainingAmountInMinorUnits: number) {
  return amountInMinorUnits > 0 && amountInMinorUnits <= remainingAmountInMinorUnits;
}

function isValidReceiptUrl(url: string) {
  const regex = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
  return regex.test(url);
}

function isValidMemo(memo: string) {
  return memo.length > 10;
}

export default function ManualPaymentModals({
  user,
  contractId,
  remainingAmountInMinorUnits,
  displayName,
  opened,
  open,
  close,
  handleRefetchContract,
}: {
  user: User;
  contractId: string;
  remainingAmountInMinorUnits: number;
  displayName: string;
  opened: boolean;
  open: () => void;
  close: () => void;
  handleRefetchContract: (contractId: string, handleFetched: () => void) => void;
}) {
  const [paymentDate, setPaymentDate] = useState<Date>(new Date());
  const [amountInMinorUnits, setAmountInMinorUnits] = useState<number>(remainingAmountInMinorUnits);
  const [receiptUrl, setReceiptUrl] = useState<string>("");
  const [memo, setMemo] = useState<string>("");
  const [checkValidation, setCheckValidation] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  const [confirmOpened, { open: openConfirm, close: closeConfirm }] = useDisclosure();

  const validateFields = () =>
    isValidAmount(amountInMinorUnits, remainingAmountInMinorUnits) &&
    isValidReceiptUrl(receiptUrl) &&
    isValidMemo(memo);

  const validateFieldsAndContinue = () => {
    if (validateFields()) {
      close();
      openConfirm();
    } else {
      setCheckValidation(true);
    }
  };

  const editPaymentDetails = () => {
    closeConfirm();
    open();
  };

  const handleRecordManualPayment = () => {
    setLoading(true);
    recordManualPayment({
      paidBy: user.displayName, // why are we using the displayName from firebase?
      contractId,
      paymentDate: toISODateString(paymentDate),
      amountInMinorUnits,
      receiptUrl,
      memo,
    })
      .then((response) => {
        if (response.success) {
          showSuccessNotification({
            message: `Successfully recorded payment of ${formatAmount(
              amountInMinorUnits,
            )} to ${displayName}.`,
            title: "Manual Payment Recorded",
          });
          handleRefetchContract(contractId, () => {
            closeConfirm();
          });
        } else {
          showFailureNotification({
            message: response.error,
            title: "Failed to Record Manual Payment",
          });
        }
      })
      .catch((error) => {
        showFailureNotification({
          message: error,
          title: "Failed to Record Manual Payment",
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Modal
        opened={confirmOpened}
        onClose={closeConfirm}
        size="auto"
        title={<Text fw="600">Confirm Payment Details</Text>}>
        <Stack gap="xs">
          <Text>
            A manual payment was sent to{" "}
            <Text fw="500" span>
              {displayName}
            </Text>{" "}
            in the amount of{" "}
            <Text fw="500" span>
              {formatAmount(amountInMinorUnits)}
            </Text>{" "}
            on{" "}
            <Text span fw="500">
              {paymentDate.toLocaleDateString()}
            </Text>{" "}
            with the following details:
          </Text>
          <List>
            <List.Item>
              <Text fw="500" span>
                Payer
              </Text>
              : <Text span>{user.displayName}</Text>
            </List.Item>
            <List.Item>
              <Text fw="500" span>
                Receipt URL
              </Text>
              :{" "}
              <Anchor href={receiptUrl} target="_blank">
                {receiptUrl}
              </Anchor>
            </List.Item>
            <List.Item>
              <Text fw="500" span>
                Memo
              </Text>
              :{" "}
              <Text span fs="italic">
                {memo}
              </Text>
            </List.Item>
          </List>
        </Stack>
        <Group mt="lg" grow justify="center">
          <Button onClick={editPaymentDetails} variant="default" color="gray">
            Back
          </Button>
          <Button loading={loading} onClick={handleRecordManualPayment}>
            Confirm
          </Button>
        </Group>
      </Modal>
      <Modal
        opened={opened}
        onClose={close}
        size="md"
        title={
          <Text size="lg" fw="600">
            Add Manual Payment for {displayName}
          </Text>
        }>
        <Stack gap="xs">
          <DatePickerInput
            leftSection={<IconCalendar size="1rem" />}
            label="Payment Date"
            value={paymentDate}
            onChange={setPaymentDate}
            minDate={new Date(new Date().setMonth(new Date().getMonth() - 1))}
            maxDate={new Date()}
            firstDayOfWeek={0}
          />
          <NumberInput
            label={
              <Text size="sm">
                <Text span fw="500">
                  Amount
                </Text>
                <Text span fs="italic">
                  {" "}
                  ({formatAmount(remainingAmountInMinorUnits)} Remaining on Contract)
                </Text>
              </Text>
            }
            value={amountInMinorUnits / 100}
            onChange={(value: number) => setAmountInMinorUnits(value * 100)}
            min={0}
            decimalScale={2}
            fixedDecimalScale
            decimalSeparator="."
            thousandSeparator=","
            hideControls
            prefix="$"
            error={
              isValidAmount(amountInMinorUnits, remainingAmountInMinorUnits)
                ? null
                : `Amount must be less than or equal to ${formatAmount(
                    remainingAmountInMinorUnits,
                  )}`
            }
          />
          <TextInput
            label="Receipt URL"
            onChange={(event) => {
              setReceiptUrl(event.currentTarget.value);
            }}
            value={receiptUrl}
            error={
              (receiptUrl || checkValidation) && !isValidReceiptUrl(receiptUrl)
                ? "Invalid URL"
                : null
            }
          />
          <TextInput
            label="Memo"
            onChange={(event) => {
              setMemo(event.currentTarget.value);
            }}
            value={memo}
            error={
              (memo || checkValidation) && !isValidMemo(memo)
                ? "Memo must be at least 10 characters"
                : null
            }
          />
        </Stack>
        <Group mt="lg" grow justify="center">
          <Button onClick={close} variant="default" color="gray">
            Cancel
          </Button>
          <Button onClick={validateFieldsAndContinue}>Continue</Button>
        </Group>
      </Modal>
    </>
  );
}
