import { BrandToDoSummary, BrandToDoSummaryResponse } from "models/Campaign";
import { createRequestWithFirebaseToken, handleResult } from "utils/ApiUtils";
import { API_URL } from "configs/Configs";

const CAMPAIGN_TODOS_URL = `${API_URL}/api/campaigns/todos_summary/`;

export const fetchCampaignToDoSummary = async (
  abortController: AbortController,
): Promise<BrandToDoSummary> => {
  const request = await createRequestWithFirebaseToken({ url: CAMPAIGN_TODOS_URL });

  const response: BrandToDoSummaryResponse = await handleResult(request, abortController);
  if (!response) {
    return null;
  }
  return response.todos;
};

export default null;
