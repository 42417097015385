import React, { useEffect, useState } from "react";

import { Stack } from "@mantine/core";

import { CreatorDetails } from "components/discovery/Datamodels";

import { CreatorSearchInput } from "components/creator/search/CreatorSearchInput";
import { CreatorSearchResults } from "components/creator/search/CreatorSearchResults";

export const CreatorSearchPage = () => {
  const [results, setResults] = useState<Record<number, CreatorDetails>>({});
  const [loading, setLoading] = useState(false);
  return (
    <Stack>
      <CreatorSearchInput setResults={setResults} setLoading={setLoading} />
      <CreatorSearchResults
        results={results}
        setResults={setResults}
        loading={loading}
        allowManualImport
      />
    </Stack>
  );
};

export default CreatorSearchPage;
