import { Stepper, Text, Timeline } from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";
import React from "react";

const generateStepItem = ({
  subtitle,
  title,
  isActive,
}: {
  subtitle: string;
  title: string;
  isActive: boolean;
}) => <Stepper.Step label={title} />;

const CreateCampaignTimeline = ({ active }: { active: number }) => {
  return (
    <Stepper
      iconSize={36}
      active={active}
      styles={{
        separator: {
          backgroundColor: "var(--mantine-color-gray-4)",
        },
        steps: {
          flexWrap: "nowrap",
        },
      }}>
      {generateStepItem({
        title: "Setup",
        subtitle: "Set goals and budget for your campaign.",
        isActive: active > 0,
      })}
      {generateStepItem({
        title: "Execution",
        subtitle: "Choose how involved you want to be in the campaign execution process.",
        isActive: active > 1,
      })}
      {generateStepItem({
        title: "Deliverables",
        subtitle:
          "Define the platforms and content types you would like to run your campaign with.",
        isActive: active > 2,
      })}
      {generateStepItem({
        title: "Ad Groups",
        subtitle: "Create targeted ad groups to get your brand in front of specific audiences.",
        isActive: active > 3,
      })}
      {generateStepItem({
        title: "Go Live",
        subtitle: "Finalize and launch your campaign!",
        isActive: active > 4,
      })}
    </Stepper>
  );
};

export default CreateCampaignTimeline;
