import { ContentStatus } from "components/contracts/common/Common";
import { ContractDeliverableStatus } from "components/contracts/models/Deliverable";
import DeliverableWithContent from "components/contracts/models/DeliverableWithContent";

export default interface ReviewStatusProps {
  contentStatus: ContentStatus;
  deliverableStatus: ContractDeliverableStatus;
  reviewDate: Date;
  submissionDate: Date;
  liveDate: Date;
}

export function deliverableWithContentToProps(
  deliverableWithContent: DeliverableWithContent,
): ReviewStatusProps {
  const { deliverable } = deliverableWithContent;
  const { status } = deliverable;
  const script = deliverableWithContent?.versionedScript?.scripts?.[0];
  const deliverableVideo =
    deliverableWithContent?.versionedDeliverableVideo?.deliverableVideos?.[0];

  return {
    contentStatus: deliverableVideo?.contentStatus || script?.status,
    deliverableStatus: status,
    reviewDate: deliverableVideo?.reviewDate || script?.reviewDate,
    submissionDate: deliverableVideo?.dateCreated || script?.submissionDate,
    liveDate: deliverable.liveContentSubmissionDatetime,
  } as ReviewStatusProps;
}
