/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { ReactNode, useState } from "react";
import { Box, Text, Avatar, Datapoint, Tag, IconButton, Layer, Toast } from "gestalt";
import "gestalt/dist/gestalt.css";

import { API_URL } from "configs/Configs";
import { handleResult } from "utils/ApiUtils";
import { useAppSelector } from "reduxStore/hooks";

import { BrandIcons } from "components/BrandIcons";
import Spacer from "components/Spacer";
import { CreatorDetails } from "components/discovery/Datamodels";
import { ThumbnailCard } from "components/MediaBlockCard";
import { User } from "firebase/auth";
import "components/discovery/styles/discovery-styles.css";
import { getAbbreviatedNumber, getPercentage } from "utils/AnalyticsUtils";
import { ExpandingBox } from "utils/TextUtils";
import { CreatorInfo, getCreatorInfoFromDetails } from "components/creator_lists/CreatorListsUtils";

const REFRESH_CREATOR_URL = `${API_URL}/api/campaigns/refresh_creator/`;

const getTruncatedTitle = (creator: CreatorInfo) => {
  const maxLength = 60; // max number of characters
  if (creator.title.length <= maxLength) return creator.title;
  return `${creator.title.slice(0, maxLength)}...`;
};

const DEFAULT_VIEWABLE_STATS = [
  "num_subscribers",
  "views_med",
  "total_vids",
  "engagement_rate",
  "all_time_videos",
];

const StatsView = ({
  creator,
  viewableStats,
  daysBack = 28,
}: {
  creator: CreatorInfo;
  viewableStats: string[];
  daysBack: number;
}) => {
  const datapoints = [];

  if (viewableStats.includes("views_med") && creator.views_med !== null) {
    datapoints.push(
      <Datapoint
        key="median-views"
        title="Median Views"
        value={getAbbreviatedNumber(creator.views_med)}
      />,
    );
  }
  if (viewableStats.includes("likes_med") && creator.likes_med !== null) {
    datapoints.push(
      <Datapoint
        key="median-likes"
        title="Avg Likes"
        value={getAbbreviatedNumber(creator.likes_med)}
      />,
    );
  }
  if (viewableStats.includes("comments_med") && creator.comments_med !== null) {
    datapoints.push(
      <Datapoint
        key="median-comments"
        title="Avg Comments"
        value={getAbbreviatedNumber(creator.comments_med)}
      />,
    );
  }
  if (viewableStats.includes("total_vids")) {
    datapoints.push(
      <Datapoint
        key="num-recent-videos"
        title="# Recent Videos"
        value={getAbbreviatedNumber(creator.total_vids)}
      />,
    );
  }
  if (creator.engagement_rate !== null && viewableStats.includes("engagement_rate")) {
    datapoints.push(
      <Datapoint
        key="engagement-rate"
        title="Engagement rate"
        value={getPercentage(creator.engagement_rate)}
      />,
    );
  }

  // take up 20% more width with each new stat
  const statsWidth = `${Math.min(datapoints.length * 20, 100)}%`;
  return (
    <Box display="flex" direction="row" justifyContent="between" width={statsWidth}>
      {datapoints}
    </Box>
  );
};

const CreatorVideoStatsView = ({ creator }: { creator: CreatorInfo }) => {
  const datapoints = [];

  // Calculate total metrics
  let totalViews = 0;
  let totalLikes = 0;
  let totalComments = 0;
  let totalConversions = 0;
  creator.videos.map((video) => {
    totalViews += video.views;
    totalLikes += video.likes;
    totalComments += video.comments;
    if (video.conversions !== null) {
      totalConversions += video.conversions;
    }
    return true;
  });

  const linkClicks = creator.link_clicks || 0;

  datapoints.push(
    <Datapoint key="total-views" title="Total Views" value={getAbbreviatedNumber(totalViews)} />,
  );

  datapoints.push(
    <Datapoint key="total-likes" title="Total Likes" value={getAbbreviatedNumber(totalLikes)} />,
  );

  datapoints.push(
    <Datapoint
      key="total-comments"
      title="Total Comments"
      value={getAbbreviatedNumber(totalComments)}
    />,
  );

  datapoints.push(
    <Datapoint
      key="engagement-rate"
      title="Engagement Rate"
      value={totalViews > 0 ? getPercentage((totalLikes + totalComments) / totalViews) : "N/A"}
    />,
  );

  if (linkClicks > 0) {
    datapoints.push(
      <Datapoint key="link-clicks" title="Link Clicks" value={getAbbreviatedNumber(linkClicks)} />,
    );
  }

  // if (totalConversions >= 0) {
  //   datapoints.push(
  //     <Datapoint
  //       key="conversions"
  //       title="Conversions"
  //       value={getAbbreviatedNumber(totalConversions)}
  //     />,
  //   );
  // }

  // take up 20% more width with each new stat
  const statsWidth = `${Math.min(datapoints.length * 20, 100)}%`;
  return (
    <Box display="flex" direction="row" justifyContent="between" width={statsWidth}>
      {datapoints}
    </Box>
  );
};

export const HiddenCreatorEntry = ({
  undoButton,
  text,
  subtext,
}: {
  undoButton: ReactNode;
  text: string;
  subtext: string;
}) => (
  // super hacky height/width setting....figure out a way to get this automatically
  <Box
    width={1048}
    height={414}
    padding={10}
    color="transparentDarkGray"
    justifyContent="center"
    alignItems="center"
    display="flex"
    direction="column">
    <Text align="center" color="inverse" weight="bold">
      {text}
    </Text>
    <Text align="center" color="inverse">
      {subtext}
    </Text>
    {undoButton && (
      <>
        <Spacer height={24} />
        <Box>{undoButton}</Box>
      </>
    )}
  </Box>
);

export const refreshCreator = async (user: User, creatorId: number, platform: string) => {
  const firebaseToken = await user.getIdToken();
  const request = new Request(REFRESH_CREATOR_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
    body: JSON.stringify({ creatorId, platform }),
  });
  const response = await handleResult(request);
  return response;
};

// Shows an entry for a creator within a list (such as when showing creator results or showing saved creators).
// Displays stats and sample media. Takes in a set of actions to show for each entry.
export const CreatorListView = ({
  user,
  creatorId,
  platform = "youtube",
  creatorDetails,
  actions,
  listWidth = 920,
  numThumbnails = 3,
  extendedActions = null,
  daysBack = 28,
  viewableStats = DEFAULT_VIEWABLE_STATS,
  detailedMetrics = false,
  usePassedVideoStats = false,
  archetypeText = null,
  socialLinks = null,
}: {
  user: User;
  creatorId?: number;
  platform?: string;
  creatorDetails: CreatorDetails;
  actions: ReactNode;
  listWidth?: number;
  numThumbnails?: number;
  extendedActions?: ReactNode;
  daysBack?: number;
  viewableStats?: string[];
  detailedMetrics?: boolean;
  usePassedVideoStats?: boolean;
  archetypeText?: string | null;
  socialLinks?: Record<string, string>;
}) => {
  const creator = getCreatorInfoFromDetails(creatorDetails, platform);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  if (!creator) {
    return null;
  }
  return (
    <Box direction="row" padding={4} display="flex" alignItems="start">
      <Box direction="row" display="flex" alignItems="center">
        {/* <Checkbox id="creatorSelector" onChange={() => {}} /> */}
        {/* <Spacer width={16} /> */}
        <a href={creator.profile_link} target="_blank" rel="noreferrer">
          <Avatar src={creator.avatar_url} name={creator.title} size="xl" />
        </a>
      </Box>
      <Box paddingX={4} paddingY={2}>
        <Box display="flex" direction="row">
          <Box>
            <Box
              display="flex"
              direction="row"
              justifyContent="between"
              width={user && creatorId && platform ? 1005 : 650}>
              <Box display="flex" direction="row" alignItems="center">
                <a
                  className="noUnderlineLink"
                  href={creator.profile_link}
                  rel="noreferrer"
                  target="_blank">
                  <Text weight="bold" size="400">
                    {getTruncatedTitle(creator)}
                  </Text>
                </a>
              </Box>
              {/* Only show refresh button if we have a leadId and platform */}
              {user && creatorId && platform ? (
                <Box>
                  <IconButton
                    icon="refresh"
                    type="button"
                    size="md"
                    tooltip={{
                      text: "Refresh Creator",
                      idealDirection: "down",
                    }}
                    accessibilityLabel="Refresh Creator"
                    onClick={() => {
                      refreshCreator(user, creatorId, platform).then((response) => {
                        if (response.success) {
                          setToastMessage(
                            "Refreshing creator details. Please check back in a few minutes.",
                          );
                          setShowToast(true);
                        } else {
                          setToastMessage(
                            "Failed to refresh creator details. Please try again later.",
                          );
                          setShowToast(true);
                        }
                      });
                    }}
                  />
                </Box>
              ) : null}
            </Box>
            <Spacer height={4} />
            <Box display="flex" direction="row" flex-wrap="nowrap">
              {socialLinks && socialLinks.youtube ? (
                <>
                  <BrandIcons platform="youtube" size="l" href={socialLinks.youtube} />
                  <Spacer width={8} />
                </>
              ) : null}
              {socialLinks && socialLinks.tiktok ? (
                <BrandIcons platform="tiktok" size="l" href={socialLinks.tiktok} />
              ) : null}
            </Box>
            {showToast && (
              <Layer>
                <Box
                  dangerouslySetInlineStyle={{
                    __style: {
                      bottom: 50,
                      left: "50%",
                      transform: "translateX(-50%)",
                    },
                  }}
                  width="100%"
                  paddingX={1}
                  position="fixed"
                  display="flex"
                  justifyContent="center">
                  <Toast
                    text={<Text inline>{toastMessage}</Text>}
                    // guh im using primary action because dismissButton doesn't exist in our version of gestalt
                    primaryAction={{
                      accessibilityLabel: "dismiss",
                      label: "Dismiss",
                      size: "sm",
                      onClick: () => setShowToast(false),
                    }}
                    // dismissButton={{ onDismiss: () => {} }}
                  />
                </Box>
              </Layer>
            )}
            <Spacer height={8} />
            <Text color="subtle" size="200">
              <Text inline weight="bold" color="subtle" size="200">
                {creator.handle}
              </Text>{" "}
              &#x2022; {getAbbreviatedNumber(creator.num_subscribers)} subscribers &#x2022;{" "}
              {getAbbreviatedNumber(creator.all_time_videos)} videos
              {creator.country ? (
                <Text inline color="subtle" size="200">
                  {" "}
                  &#x2022; {creator.country}
                </Text>
              ) : null}
            </Text>
            <Spacer height={8} />
            {archetypeText ? <Tag disabled text={archetypeText} /> : null}

            <Spacer height={8} />
            <ExpandingBox
              text={creator.description}
              width={user && creatorId && platform ? 1005 : 600}
            />
          </Box>
          <Box display="flex" direction="column" alignItems="center">
            {actions}
          </Box>
        </Box>
        <Spacer height={32} />
        {usePassedVideoStats ? (
          <CreatorVideoStatsView creator={creator} />
        ) : (
          <StatsView creator={creator} viewableStats={viewableStats} daysBack={daysBack} />
        )}
        <Spacer height={32} />
        <ThumbnailCard
          medias={creator.videos}
          listWidth={listWidth}
          numThumbnails={numThumbnails}
          detailedMetrics={detailedMetrics}
          // hide metrics if we are showing stats for passed in videos
          hideMetrics={usePassedVideoStats}
        />
        <Spacer height={24} />
        {extendedActions}
      </Box>
    </Box>
  );
};

export default CreatorListView;
