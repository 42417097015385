import React from "react";

import { Box, Flex, Select, TagsInput, TextInput, Title } from "@mantine/core";

import { DEFAULT_TITLE_FONT_WEIGHT, DEFAULT_TITLE_SIZE } from "components/contracts/common/Common";
import { Text } from "gestalt";
import { invalidEmail, validateEmailList } from "utils/ValidationUtils";

function get1stCollabEmployees() {
  return ["Michael", "Albert", "Andrew", "Leon", "Kevin", "Varun"];
}

export default function ContractContactInfo({
  creatorFirstName,
  creatorEmail,
  closeContactName,
  additionalEmails,
  setCreatorFirstName,
  setCreatorEmail,
  setCloseContactName,
  setAdditionalEmails,
  disabled,
}: {
  creatorFirstName: string;
  creatorEmail: string;
  closeContactName: string;
  additionalEmails: string[];
  setCreatorFirstName: (value: string) => void;
  setCreatorEmail: (value: string) => void;
  setCloseContactName: (value: string) => void;
  setAdditionalEmails: (value: string[]) => void;
  disabled: boolean;
}) {
  const handleEmailChange = (emails: string[]) => {
    setAdditionalEmails(emails);
  };
  return (
    <>
      <Title order={DEFAULT_TITLE_SIZE} fw={DEFAULT_TITLE_FONT_WEIGHT}>
        Contract Contact Details
      </Title>
      <Text>Please verify the following info in close.</Text>
      <Box my="sm">
        <Flex gap="sm">
          <TextInput
            id="creatorFirstName"
            value={creatorFirstName}
            onChange={(event) => setCreatorFirstName(event.currentTarget.value)}
            placeholder="Optional"
            label="Creator's First Name"
            disabled={disabled}
          />
          <TextInput
            id="creatorEmail"
            value={creatorEmail}
            onChange={(event) => setCreatorEmail(event.currentTarget.value)}
            label="Creator's Email Address"
            error={
              creatorEmail && creatorEmail.length > 0 && invalidEmail(creatorEmail)
                ? "Invalid Email Address"
                : null
            }
            disabled={disabled}
          />
          <Select
            id="creatorCloseContact"
            label="Creator's Close Contact"
            data={get1stCollabEmployees()}
            disabled={disabled}
            value={closeContactName}
            onChange={setCloseContactName}
            searchable
          />
          <TagsInput
            label="Additional Email Addresses"
            w={400}
            value={additionalEmails}
            onChange={handleEmailChange}
            error={
              !validateEmailList(additionalEmails)
                ? "Invalid Email Addresses was added, please remove it."
                : null
            }
            splitChars={[",", " "]}
            disabled={disabled}
          />
        </Flex>
      </Box>
    </>
  );
}
