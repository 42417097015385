import { API_URL } from "configs/Configs";
import { createRequest, handleResult } from "utils/ApiUtils";
import { AudienceEntryResponse, StatsEntryResponse } from "social/utils";
import { processAuthResponse } from "social/fb/FBDataUtils";

const INSTAGRAM_API_BASE = `${API_URL}/social/`;
const INSTAGRAM_MEDIA_URL = `${INSTAGRAM_API_BASE}instagram_media/`;
const INSTAGRAM_STATS_URL = `${INSTAGRAM_API_BASE}instagram_stats/`;

export const INSTAGRAM_PROFILE_URL = `${INSTAGRAM_API_BASE}instagram_profile/`;
const INSTAGRAM_ALL_PROFILES_URL = `${INSTAGRAM_PROFILE_URL}list_all/`;
const INSTAGRAM_PROFILE_SYNC_URL = `${INSTAGRAM_PROFILE_URL}update_stats/`;

export interface InstagramProfileResponse {
  socialUsername?: string;
  user?: string;
  url?: string;
  last_updated?: Date;
  date_created?: Date;
  instagramId?: number;
  numFollowers?: number;
  numPosts?: number;
  avatar_url?: string;
  biography?: string;
}

export interface InstagramMediaResponse {
  caption?: string;
  comments_count: number;
  creation_timestamp: Date;
  id: number;
  last_update_time: Date;
  like_count?: number;
  media_product_type: InstagramMediaProductType;
  media_type: InstagramMediaType;
  media_url: string;
  owner: string;
  permalink: string;
  last_updated?: Date;
  date_created?: Date;
  oembed_html?: string;
}

export interface InstagramStatsResponse {
  likes_median: number;
  comments_median: number;
  num_media: number;
  lower_price: number;
  upper_price: number;
}

export interface InstagramAudienceEntryResponse {
  audience: string;
  date: Date;
  last_update: Date;
  date_created: Date;
  metric: string;
  profile: string;
  value: number;
}

export interface InstagramAudienceResponse {
  audience_city?: AudienceEntryResponse[];
  audience_country?: AudienceEntryResponse[];
  audience_gender_age?: AudienceEntryResponse[];
  audience_locale?: AudienceEntryResponse[];
  gender?: AudienceEntryResponse[];
  age_bucket?: AudienceEntryResponse[];
}

export interface InstagramDailyStatsEntryResponse {
  date: Date;
  date_created: Date;
  last_updated: Date;
  metric: string;
  period: string;
  profile: string;
  value: number;
}

// stats pulled through the Instagram insights API
export interface InstagramInsightsResponse {
  audience: InstagramAudienceResponse;
  stats: InstagramDailyStatsEntryResponse[];
}

// should match InstagramMediaProductType defined in the backend
export enum InstagramMediaProductType {
  UNKNOWN = 0,
  AD = 1,
  FEED = 2,
  STORY = 3,
  REELS = 4,
}

// should match InstagramMediaType defined in the backend
export enum InstagramMediaType {
  UNKNOWN = 0,
  CAROUSEL_ALBUM = 1,
  IMAGE = 2,
  VIDEO = 3,
}

// gets all instagram profiles for a given user.
export const getInstagramProfiles = async (firebaseToken: string, getAll?: boolean) => {
  const requestUrl = getAll ? new URL(INSTAGRAM_ALL_PROFILES_URL) : new URL(INSTAGRAM_PROFILE_URL);
  const request = createRequest({ url: requestUrl.toString(), firebaseToken });
  const response = await handleResult(request);
  return response;
};

// gets the very first instance of an instagram profile for a user. this is used because while users
// can have multiple instagram accounts per profile, 1stCollab doesn't support multiple instagram
// profiles at the moment.
export const getFirstInstagramProfile = async (firebaseToken: string) => {
  const instagramProfiles = await getInstagramProfiles(firebaseToken);
  return instagramProfiles[0];
};

// returns an InstagramProfile given a username
export const getInstagramProfile = async (instagramUsername: string, firebaseToken: string) => {
  const requestUrl = new URL(`${INSTAGRAM_PROFILE_URL}${instagramUsername}/`);
  // requestUrl.searchParams.append("socialUsername", username);
  const request = createRequest({ url: requestUrl.toString(), firebaseToken });
  const response = await handleResult(request);
  return response;
};

export const getInstagramMedia = async (
  instagramUsername: string,
  platform?: string,
  format?: InstagramMediaProductType,
  showSponsored?: boolean,
  useDiscover?: boolean,
  useApi?: boolean,
  oembed?: boolean,
  orderBy?: string,
) => {
  const requestUrl = new URL(`${INSTAGRAM_MEDIA_URL}${instagramUsername}/`);
  if (platform) requestUrl.searchParams.append("platform", platform);
  if (format) requestUrl.searchParams.append("media_format", format.toString());
  if (showSponsored) requestUrl.searchParams.append("sponsored", true.toString());
  if (useDiscover) requestUrl.searchParams.append("use_discover", true.toString());
  if (useApi) requestUrl.searchParams.append("use_api", true.toString());
  if (oembed) requestUrl.searchParams.append("include_oembed", true.toString());
  if (orderBy) requestUrl.searchParams.append("order_by", orderBy);
  const request = createRequest({ url: requestUrl.toString() });
  const response = await handleResult(request);
  return response;
};

export const refreshInstagramMedia = async (instagramUsername: string) => {
  const requestUrl = `${INSTAGRAM_MEDIA_URL}${instagramUsername}/`;
  const request = createRequest({ url: requestUrl.toString(), method: "PUT" });
  const response = await handleResult(request);
  return response;
};

// Public stats available from Instagram Media
export const getInstagramStats = async (
  instagramUsername: string,
  platform?: string,
  format?: InstagramMediaProductType,
  showSponsored?: boolean,
  useDiscover?: boolean,
  useApi?: boolean,
  oembed?: boolean,
) => {
  const requestUrl = new URL(`${INSTAGRAM_STATS_URL}${instagramUsername}/`);
  if (platform) requestUrl.searchParams.append("platform", platform);
  if (format) requestUrl.searchParams.append("media_format", format.toString());
  if (showSponsored) requestUrl.searchParams.append("sponsored", true.toString());
  if (useDiscover) requestUrl.searchParams.append("use_discovery", true.toString());
  if (useApi) requestUrl.searchParams.append("use_api", true.toString());
  if (oembed) requestUrl.searchParams.append("include_oembed", true.toString());

  const request = createRequest({ url: requestUrl.toString() });
  const response = await handleResult(request);
  return JSON.parse(response);
};

// Refreshes audience and insights stats
export const refreshInstagramStats = async (instagramUsername: string) => {
  const requestUrl = `${INSTAGRAM_PROFILE_URL}${instagramUsername}/update_stats/`;
  const request = createRequest({ url: requestUrl.toString(), method: "POST" });
  const response = await handleResult(request);
  return response;
};

// Return profile stats for Instagram insights
export const getInstagramProfileInsights = async (
  instagramUsername: string,
  firebaseToken: string,
) => {
  const requestUrl = new URL(`${INSTAGRAM_PROFILE_URL}${instagramUsername}/profile_stats/`);
  requestUrl.searchParams.append("audience_limit", "5");
  const request = createRequest({ url: requestUrl.toString(), firebaseToken });
  const response = await handleResult(request);
  return response;
};

export const getLoginStatus = async (onConnected: () => void, onNotConnected: () => void) => {
  // Login Status doesn't account for having a long lived access token, so there
  // will be times where the status will be empty or not authenticated but
  // we'll still have a valid access token on the backend. We tried to solve
  // this problem by checking if a user had an access token on the backend,
  // but doing that didn't properly set a "response" object that Facebook uses for their
  // JS SDK. So for instance, "logout" would get called without a valid response object
  // because it was never set when the JS SDK didn't call getlogin to fetch and set it.

  window.FB.getLoginStatus((response) => {
    if (response.status === "connected") {
      processAuthResponse(response.authResponse);
      onConnected();
    } else {
      onNotConnected();
    }
  });
};

export const fbLogout = async (onDisconnected: () => void) => {
  window.FB.logout((response) => {
    processAuthResponse(response.authResponse);
    onDisconnected();
  });
};
