import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { Text } from "@mantine/core";

import { continueOnboardStripe } from "components/contracts/common/Api";

export default function StripeRedirect() {
  const { contractId } = useParams();

  useEffect(() => {
    continueOnboardStripe(contractId).then((response) => {
      if (response.success) {
        const { redirectUrl } = response;
        window.location.href = redirectUrl;
      }
    });
  }, []);
  return <Text>You will be redirected to Stripe shortly.</Text>;
}
