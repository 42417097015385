import {
  Box,
  Button,
  Dropdown,
  FixedZIndex,
  IconButton,
  Image,
  Sticky,
  Text,
  Callout,
  CompositeZIndex,
} from "gestalt";
import React, { useEffect, useState } from "react";
import "gestalt/dist/gestalt.css";
import { useAuthState } from "react-firebase-hooks/auth";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { isMobile } from "react-device-detect";

import { auth, logout } from "auth/firebaseAuthHelpers";
import Spacer from "components/Spacer";
import { useUser } from "utils/UserContext";
import SearchBar from "components/discovery/SearchBar";
import logo from "components/assets/logo-black.svg";

const NAV_BAR_Z_INDEX = new FixedZIndex(100);

enum NavBarState {
  LOADING = 0,
  LOGGED_OUT = 1,
  LOGGED_IN = 2,
}

const NavSearchBar = ({ location }: { location: string }) => {
  if (location.startsWith("/instagram") || location.startsWith("/profile")) {
    return (
      <>
        <Spacer width={100} />
        <Box flex="grow" justifyContent="center">
          <SearchBar />
        </Box>
        <Spacer width={100} />
      </>
    );
  }
  return null;
};

const NavOptions = ({ navState, isNotBrand }: { navState: NavBarState; isNotBrand: boolean }) => {
  switch (navState) {
    case NavBarState.LOGGED_IN:
      if (isNotBrand) {
        return null;
      }
      return (
        <Box direction="row" display="flex">
          <a href="/campaigns/discover" style={{ textDecoration: "none" }}>
            <Text weight="bold">Discover</Text>
          </a>
          <Spacer width={48} />
          <a href="/campaigns/creator_sets/all" style={{ textDecoration: "none" }}>
            <Text weight="bold">Saved</Text>
          </a>
          <Spacer width={48} />
          <a href="/campaigns" style={{ textDecoration: "none" }}>
            <Text weight="bold">Campaigns</Text>
          </a>
        </Box>
      );
    case NavBarState.LOGGED_OUT:
      return (
        <Box direction="row" display="flex">
          <a href="https://brands.1stcollab.com/" style={{ textDecoration: "none" }}>
            <Text weight="bold">For Brands</Text>
          </a>
          <Spacer width={48} />
          <a href="/media-kits" style={{ textDecoration: "none" }}>
            <Text weight="bold">For Creators</Text>
          </a>
          <Spacer width={48} />
          <a href="/faqs" style={{ textDecoration: "none" }}>
            <Text weight="bold">FAQs</Text>
          </a>
        </Box>
      );
    case NavBarState.LOADING:
    default:
      return null;
  }
};

// make sure we bring users back to their original page after they login / signup
const getNext = (pathname: string) => {
  const [searchParams, setSearchParams] = useSearchParams();
  if (pathname.startsWith("/signup") || pathname.startsWith("/login")) {
    // make sure we keep the "next" params, especially if users are switching between login to signup or vice versa
    return encodeURIComponent(searchParams.get("next"));
  }
  return encodeURIComponent(pathname);
};

const NavBar = () => {
  const [navbarState, setNavbarState] = useState(NavBarState.LOADING);
  const location = useLocation();
  const navigate = useNavigate();
  const [firebaseUser, firebaseLoading] = useAuthState(auth);
  const [currentUser, userLoading, updateUser] = useUser();
  const [showDropdown, setShowDropdown] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const menuDropdownAnchorRef = React.useRef(null);
  const profileDropdownAnchor = React.useRef(null);

  const isHomepage = location.pathname === "/";
  const signupText = isHomepage ? "Start for free" : "Sign up";

  // only on landing pages do we want to display links to certain media upsells such as "For Brands"
  const isLandingPage =
    isHomepage ||
    location.pathname === "/discover" ||
    location.pathname.startsWith("/discover/") ||
    location.pathname === "/media-kits" ||
    location.pathname.startsWith("/media-kits/");

  // needed to fix bug where dropdown menu is hidden when the top nav bar is sticky
  const dropdownZIndex = new CompositeZIndex([NAV_BAR_Z_INDEX]);

  useEffect(() => {
    if (firebaseLoading || userLoading) {
      setNavbarState(NavBarState.LOADING);
      // The reason why we need to check for currentUser.username as well is because
      // the very first time a user onboards, a firebase user will be created for them
      // but the username won't be immediately available because that's created and fetched
      // from our backend.
      // note that current.username == null checks for both undefined and null. we don't want to
      // include the empty string because there are times where the username will be temporarily
      // set to an empty string (like when the user is trying to edit their username)
    } else if (firebaseUser && currentUser && !(currentUser.username == null)) {
      setNavbarState(NavBarState.LOGGED_IN);
    } else {
      setNavbarState(NavBarState.LOGGED_OUT);
    }
    // We need to run this both when the loading state changes and when the user objects change.
    // If we base it on just the user object, this won't properly run when users remained logged out
    // because users will remain null.
    // If we use just the loading state, some times the loading state changes so quickly that
    // the effect won't register (so when we change loading from true to false).
  }, [firebaseUser, currentUser, firebaseLoading, userLoading]);

  const nextUrl = getNext(location.pathname);

  const navbarBox = (
    <Box
      direction="column"
      color="light"
      // navbar needs to expand past parents 16px padding so on scroll, subsequent views dont show up on the left and right side of the nav bar
      width="calc(100% + 32px)" // 100% of view + left padding 16 and right padding 16
      marginStart={-4} // used to extend past parent 16px
      paddingX={4} // balance out marginStart(-4)
    >
      <Box display="flex" height={60} alignItems="center" justifyContent="between">
        <a href="/">
          <Box height={40} width={130}>
            <Image
              src={logo}
              alt="1stCollab"
              naturalHeight={40}
              naturalWidth={130}
              color="white"
              fit="contain"
            />
          </Box>
        </a>
        {/* <NavSearchBar location={location.pathname} /> */}
        {!isMobile ? (
          <NavOptions
            navState={navbarState}
            isNotBrand={currentUser && currentUser.completed_onboarding && !currentUser.brand}
          />
        ) : null}
        <Box display="flex">
          {navbarState === NavBarState.LOGGED_IN && (
            <>
              <Spacer width={16} />
              <Button
                text={currentUser.name}
                onClick={() => {
                  setShowDropdown(!showDropdown);
                }}
                ref={profileDropdownAnchor}
                selected={showDropdown}
              />
              {showDropdown && (
                <Dropdown
                  anchor={profileDropdownAnchor.current}
                  id="profileDropdown"
                  zIndex={dropdownZIndex}
                  onDismiss={() => setShowDropdown(false)}>
                  <Dropdown.Item
                    onSelect={() => {
                      navigate(`/${currentUser.username}`);
                      setShowDropdown(false);
                    }}
                    option={{ label: "Profile", value: "profile" }}
                  />
                  <Dropdown.Item
                    onSelect={() => {
                      navigate("/settings/account");
                      setShowDropdown(false);
                    }}
                    option={{ label: "Settings", value: "settings" }}
                  />
                  <Dropdown.Item
                    onSelect={() => {
                      logout(() => {
                        setNavbarState(NavBarState.LOGGED_OUT);
                        setShowDropdown(false);
                        navigate("/login");
                        window.FB.logout();
                      });
                    }}
                    option={{ label: "Logout", value: "logout" }}
                  />
                </Dropdown>
              )}
              {isMobile ? (
                <>
                  <Spacer width={4} />
                  <IconButton
                    icon="menu"
                    size="md"
                    accessibilityExpanded={openMenu}
                    accessibilityHaspopup
                    accessibilityLabel="Menu"
                    ref={menuDropdownAnchorRef}
                    onClick={() => {
                      setOpenMenu(!openMenu);
                    }}
                  />
                  {openMenu ? (
                    <Dropdown
                      anchor={menuDropdownAnchorRef.current}
                      id="menuDropdown"
                      zIndex={dropdownZIndex}
                      onDismiss={() => setOpenMenu(false)}>
                      <Dropdown.Item
                        onSelect={() => {
                          navigate("/campaigns/discover");
                          setOpenMenu(false);
                        }}
                        option={{ label: "Discover", value: "discover" }}
                      />
                      <Dropdown.Item
                        onSelect={() => {
                          navigate(`/${currentUser.username}/saved`);
                          setOpenMenu(false);
                        }}
                        option={{ label: "Saved", value: "saved" }}
                      />
                      <Dropdown.Item
                        onSelect={() => {
                          navigate("/campaign-waitlist");
                          setOpenMenu(false);
                        }}
                        option={{ label: "Campaigns", value: "campaigns" }}
                      />
                    </Dropdown>
                  ) : null}
                </>
              ) : null}
            </>
          )}
          {navbarState === NavBarState.LOGGED_OUT && (
            <>
              <Spacer width={16} />
              {isMobile ? null : (
                <>
                  <Box display="flex" flex="none">
                    <Button
                      text="Log in"
                      selected={location.pathname.startsWith("/login")}
                      onClick={() => navigate(`/login?next=${nextUrl}`)}
                    />
                  </Box>
                  <Spacer width={16} />
                </>
              )}
              <Box display="flex" flex="none">
                <Button
                  text={signupText}
                  color="blue"
                  selected={location.pathname.startsWith("/signup")}
                  onClick={() =>
                    navigate(
                      `/signup?next=${nextUrl}&prev=${encodeURIComponent(location.pathname)}`,
                    )
                  }
                />
              </Box>
              {isMobile ? (
                <>
                  <Spacer width={4} />
                  <IconButton
                    icon="menu"
                    size="md"
                    accessibilityExpanded={openMenu}
                    accessibilityHaspopup
                    accessibilityLabel="Menu"
                    ref={menuDropdownAnchorRef}
                    onClick={() => {
                      setOpenMenu(!openMenu);
                    }}
                  />
                  {openMenu ? (
                    <Dropdown
                      anchor={menuDropdownAnchorRef.current}
                      id="menuDropdown"
                      zIndex={dropdownZIndex}
                      onDismiss={() => setOpenMenu(false)}>
                      <Dropdown.Item
                        onSelect={() => {
                          if (!location.pathname.startsWith("/login")) {
                            navigate(`/login?next=${nextUrl}`);
                          }
                          setOpenMenu(false);
                        }}
                        option={{ label: "Log in", value: "log_in" }}
                      />
                      <Dropdown.Item
                        onSelect={() => {
                          navigate("/discover");
                          setOpenMenu(false);
                        }}
                        option={{ label: "For Brands", value: "for_brands" }}
                      />
                      <Dropdown.Item
                        onSelect={() => {
                          navigate("/media-kits");
                          setOpenMenu(false);
                        }}
                        option={{ label: "For Creators", value: "for_creators" }}
                      />
                    </Dropdown>
                  ) : null}
                </>
              ) : null}
            </>
          )}
        </Box>
      </Box>
      <Spacer height={20} />
    </Box>
  );
  return (
    <Sticky top={0} zIndex={NAV_BAR_Z_INDEX}>
      {navbarBox}
    </Sticky>
  );
};

export default NavBar;
