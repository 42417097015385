import React from "react";

import { Badge, Stack } from "@mantine/core";

import { ContractStatus, PaymentStatus } from "components/contracts/common/Common";

import PaymentStatusBadge from "components/contracts/dashboard/PaymentStatusBadge";

function ContractStatusBadge({ contractStatus }: { contractStatus: ContractStatus }) {
  switch (contractStatus) {
    case ContractStatus.CREATED:
    case ContractStatus.PENDING:
      return (
        <Badge color="red" variant="light">
          Needs Signature
        </Badge>
      );
    case ContractStatus.ACCEPTED:
    case ContractStatus.IN_PROGRESS:
      return (
        <Badge color="yellow" variant="light">
          In Progress
        </Badge>
      );
    case ContractStatus.COMPLETE:
      return (
        <Badge color="green" variant="light">
          Complete
        </Badge>
      );
    case ContractStatus.REJECTED:
      return (
        <Badge color="red" variant="light">
          Expired
        </Badge>
      );
    default:
      return null;
  }
}

function RecurringBadge({ isRecurring }: { isRecurring: boolean }) {
  if (isRecurring) {
    return (
      <Badge color="grape" variant="light">
        Recurring
      </Badge>
    );
  }
  return null;
}

export default function ContractStatusBadges({
  contractStatus,
  paymentStatus,
  readyForPayment,
  archived,
  isRecurring,
}: {
  contractStatus: ContractStatus;
  paymentStatus: PaymentStatus;
  readyForPayment: boolean;
  archived: boolean;
  isRecurring: boolean;
}) {
  if (archived) {
    return (
      <Stack gap="xs">
        <Badge color="gray" variant="filled">
          Archived
        </Badge>
      </Stack>
    );
  }
  if (contractStatus === ContractStatus.REJECTED) {
    return (
      <Stack gap="xs">
        <Badge color="red" variant="outline">
          Expired
        </Badge>
      </Stack>
    );
  }
  return (
    <Stack gap="xs">
      <ContractStatusBadge contractStatus={contractStatus} />
      <PaymentStatusBadge paymentStatus={paymentStatus} readyForPayment={readyForPayment} />
      <RecurringBadge isRecurring={isRecurring} />
    </Stack>
  );
}
