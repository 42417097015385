import React from "react";
import { useNavigate } from "react-router-dom";

import { useInputState } from "@mantine/hooks";
import { TextInput, Flex, Button, Group } from "@mantine/core";

const OpsCreatorSearch = ({ setCreatorId }: { setCreatorId: (creatorId: number) => void }) => {
  const [inputCreatorID, setInputCreatorId] = useInputState("");

  const navigate = useNavigate();

  return (
    <Flex justify="center" gap="md">
      <Group align="flex-end">
        <TextInput
          label="Search for Creator"
          description="Creator ID or Handle"
          placeholder="238979"
          value={inputCreatorID}
          onChange={setInputCreatorId}
        />
        <Button onClick={() => navigate(`/admin/creator/search/?query=${inputCreatorID}`)}>
          Search
        </Button>
      </Group>
    </Flex>
  );
};

export default OpsCreatorSearch;
