import React, { useState } from "react";

import { Group, Stack, Text } from "@mantine/core";

import { SupportedPlatform } from "models/Common";

import AdCodeEducation from "components/contracts/deliverables/live_content/AdCodeEducation";
import EditableTextInput from "components/contracts/deliverables/live_content/EditableTextInput";

import { submitAdCode } from "components/contracts/common/Api";

import { addDays, toLongDateString } from "utils/DateUtils";

export default function AdCodeSubmission({
  platform,
  deliverableId,
  usageRightsDays,
  requiresInput,
  isEditable,
  adCodeState,
  setAdCodeState,
  setAdCodeSubmitted,
  disableEducation,
  setSubmissionSucceeded,
  disabled,
}: {
  platform: SupportedPlatform;
  deliverableId: string;
  usageRightsDays: number;
  requiresInput: boolean;
  isEditable: boolean;
  adCodeState: string;
  setAdCodeState: (adCode: string) => void;
  setAdCodeSubmitted: (submitted: boolean) => void;
  disableEducation: boolean;
  setSubmissionSucceeded: (succeeded: boolean) => void;
  disabled: boolean;
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const usageRightsEndDate = addDays(new Date(), usageRightsDays);

  let label = "";
  let placeholder = "";

  if (platform === SupportedPlatform.TIKTOK) {
    label = "Submit TikTok Spark Code";
    placeholder = "e.g. #lJfkg+kOLaFawypoXi251R6o/w3dKQp4r5DfCiYSYG9kQceUhrR00MBBuSzJAqg=";
  } else if (platform === SupportedPlatform.INSTAGRAM) {
    label = "Submit Instagram Ad Code";
    placeholder = "e.g. adcode-18002457065809230";
  } else {
    return null;
  }

  const handleSubmitAdCode = () => {
    setErrorMessage("");
    setIsLoading(true);
    submitAdCode({ platform, deliverableId, adCode: adCodeState, isEditing })
      .then((response) => {
        const { success, adCode: adCodeRespone, error } = response;
        if (success) {
          setAdCodeState(adCodeRespone);
          setIsEditing(false);
          setAdCodeSubmitted(true);
          setSubmissionSucceeded(true);
        } else {
          setErrorMessage(error);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <EditableTextInput
      label={
        <Stack mb="-sm" gap="0.1rem">
          <Group gap="0.3rem">
            <Text fw="500" size="sm">
              {label}
            </Text>
            <AdCodeEducation platform={platform} disabled={disableEducation} />
          </Group>
          {(requiresInput || isEditing) && (
            <Text size="xs" c="dimmed">
              Please ensure the code is valid until{" "}
              <Text span fw="600">
                {toLongDateString(usageRightsEndDate)}
              </Text>{" "}
              or later.
            </Text>
          )}
        </Stack>
      }
      placeholder={placeholder}
      value={adCodeState}
      setValue={setAdCodeState}
      platform={platform}
      requiresInput={requiresInput}
      isEditable={isEditable}
      isEditing={isEditing}
      setIsEditing={setIsEditing}
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
      loading={isLoading}
      disabled={disabled}
      handleSubmit={handleSubmitAdCode}
    />
  );
}
