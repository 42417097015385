import { DeliverableConfigs } from "components/contracts/models/Deliverable";

export function encodeDeliverablesConfigs(deliverablesConfigs: DeliverableConfigs[]) {
  return encodeURIComponent(JSON.stringify(deliverablesConfigs));
}

export function decodeDeliverablesConfigs(deliverablesConfigs: string) {
  return JSON.parse(decodeURIComponent(deliverablesConfigs)) as DeliverableConfigs[];
}

export default null;
