import React, { useEffect, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";

import { User } from "firebase/auth";

import {
  ActionIcon,
  Badge,
  Card,
  Container,
  Divider,
  Group,
  LoadingOverlay,
  SimpleGrid,
  Stack,
  Table,
  Text,
  Title,
} from "@mantine/core";

import { IconExternalLink } from "@tabler/icons-react";

import { AuthContext } from "auth/AuthContext";
import { getInvoice } from "components/contracts/common/Api";
import {
  toFormattedMonthYearString,
  toLongDateTimeString,
  toShortDateString,
} from "utils/DateUtils";

import CreatorAvatar from "components/contracts/common/CreatorAvatar";

import InvoiceModel, {
  AugmentedInvoiceItem,
  InvoiceItem,
  InvoiceItemType,
  InvoiceStatus,
  getAugmentedBaseInvoiceItem,
  getAugmentedBonusInvoiceItem,
} from "components/contracts/models/Invoice";

import { formatAmount } from "components/contracts/dashboard/Utils";
import InvoiceStatusBadge from "components/contracts/billing/InvoiceStatusBadge";

function StatusTimestamp({
  status,
  invoiceDate,
  paymentDate,
}: {
  status: InvoiceStatus;
  invoiceDate: Date;
  paymentDate: Date;
}) {
  if (status === InvoiceStatus.PAID) {
    return <Text c="dimmed">Paid on {toLongDateTimeString(paymentDate)}</Text>;
  } else if (status === InvoiceStatus.OPEN) {
    return <Text c="dimmed">Invoiced on {toLongDateTimeString(invoiceDate)}</Text>;
  }

  return null;
}

function InvoiceTypeBadge({ type }: { type: InvoiceItemType }) {
  if (type === InvoiceItemType.BASE) {
    return (
      <Badge color="cyan" variant="light" size="sm">
        Base
      </Badge>
    );
  }

  return (
    <Badge color="orange" variant="light" size="sm">
      Bonus
    </Badge>
  );
}

function InvoiceItemRow({ item }: { item: AugmentedInvoiceItem }) {
  return (
    <Table.Tr>
      <Table.Td>
        <CreatorAvatar displayName={item.name} avatarUrl={item.avatarUrl} />
      </Table.Td>
      <Table.Td>{item.campaignTitle}</Table.Td>
      <Table.Td>{toShortDateString(item.completionDate)}</Table.Td>
      <Table.Td>{formatAmount(item.amount)}</Table.Td>
      <Table.Td>
        <InvoiceTypeBadge type={item.type} />
      </Table.Td>
      <Table.Td>
        <Group justify="center" pr="xs">
          <ActionIcon
            variant="subtle"
            component="a"
            href={`https://www.1stcollab.com/admin/contracts/${item.hashId}`}>
            <IconExternalLink size="1rem" />
          </ActionIcon>
        </Group>
      </Table.Td>
    </Table.Tr>
  );
}

function InvoiceItems({
  baseItems,
  bonusItems,
}: {
  baseItems: InvoiceItem[];
  bonusItems: InvoiceItem[];
}) {
  const augmentedBaseInvoiceItems = baseItems.map((item) => getAugmentedBaseInvoiceItem(item));
  const augmentedBonusInvoiceItems = bonusItems.map((item) => getAugmentedBonusInvoiceItem(item));

  const augmentedInvoiceItems = augmentedBaseInvoiceItems
    .concat(augmentedBonusInvoiceItems)
    .sort((a, b) => new Date(a.completionDate).getTime() - new Date(b.completionDate).getTime());

  const rows = augmentedInvoiceItems.map((contract) => (
    <InvoiceItemRow key={contract.hashId} item={contract} />
  ));

  return (
    <Table withTableBorder horizontalSpacing="xs" verticalSpacing="xs" highlightOnHover>
      <Table.Thead>
        <Table.Tr>
          <Table.Th fw={500}>Creator</Table.Th>
          <Table.Th fw={500}>Campaign</Table.Th>
          <Table.Th fw={500}>Date</Table.Th>
          <Table.Th fw={500}>Amount</Table.Th>
          <Table.Th fw={500}>Type</Table.Th>
          <Table.Th fw={500}>Contract</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>{rows}</Table.Tbody>
    </Table>
  );
}

function getAmountDue(invoice: InvoiceModel) {
  if (invoice.status === InvoiceStatus.PAID) {
    return invoice.amount_invoiced;
  } else if (invoice.status === InvoiceStatus.OPEN) {
    return invoice.amount_invoiced;
  }
  return invoice.base_payment + invoice.platform_payment;
}

export function InvoiceView({ invoiceState }: { invoiceState: InvoiceModel }) {
  return (
    <Card shadow="xs" withBorder p="lg">
      <Stack>
        <Group justify="space-between" align="flex-start">
          <Stack gap={0}>
            <Title order={2} fw="600">
              {invoiceState.name}
            </Title>
            <StatusTimestamp
              status={invoiceState.status}
              invoiceDate={invoiceState.invoice_date}
              paymentDate={invoiceState.payment_date}
            />
          </Stack>
          <InvoiceStatusBadge status={invoiceState.status} size="lg" />
        </Group>
        <Stack gap={0}>
          <Text size="lg" fw="600">
            Billing Period:{" "}
            <Text span>{toFormattedMonthYearString(invoiceState.billing_month)}</Text>
          </Text>
          {invoiceState.status === InvoiceStatus.PAID && (
            <Text size="lg" fw="600">
              Invoice Date: <Text span>{toLongDateTimeString(invoiceState.invoice_date)}</Text>
            </Text>
          )}
        </Stack>
        <Divider />
        <Title order={3} fw="500">
          Invoice Items
        </Title>
        <InvoiceItems
          baseItems={invoiceState.base_contracts}
          bonusItems={invoiceState.bonus_contracts}
        />
        <Group justify="right">
          <Stack>
            <Title order={3} fw="500">
              Total Payment
            </Title>
            <SimpleGrid cols={2} spacing="xl" verticalSpacing="xs">
              <Text fw="600">Creator Fees</Text>
              <Text>{formatAmount(invoiceState.base_payment)}</Text>
              <Text fw="600">
                Platform Fee (
                {invoiceState.take_rate.toLocaleString(undefined, {
                  style: "percent",
                  minimumFractionDigits: 2,
                })}
                {invoiceState.capped_take > 0 &&
                  `, ${formatAmount(invoiceState.capped_take)} cap`}
                )
              </Text>
              <Text>{formatAmount(invoiceState.platform_payment)}</Text>
              <Text fw="600">Amount Due</Text>
              <Text>{formatAmount(getAmountDue(invoiceState))}</Text>
              <Text fw="600">Amount Paid</Text>
              <Text>{formatAmount(invoiceState.amount_paid)}</Text>
            </SimpleGrid>
          </Stack>
        </Group>
      </Stack>
    </Card>
  );
}

export default function Invoice() {
  // User state
  const { user } = useOutletContext<AuthContext>();

  const { invoiceId } = useParams();

  const [invoiceState, setInvoiceState] = useState<InvoiceModel>(null);
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getInvoice({ user, invoiceId })
      .then((response) => {
        const { success, invoice } = response;

        if (success) {
          setInvoiceState(invoice);
          setLoaded(true);
        }
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <Container>
      <LoadingOverlay visible={loading} />
      {loaded && <InvoiceView invoiceState={invoiceState} />}
    </Container>
  );
}
