import React from "react";
import { useParams } from "react-router-dom";
import { Button, Flex, Stack } from "@mantine/core";
import { IconPrinter } from "@tabler/icons-react";

import ViewContract from "components/contracts/contract/ViewContract";
import classes from "components/contracts/contract//ViewContract.module.css";

const printIcon = <IconPrinter size={18} />;

const ViewPrintContract = () => {
  const { contractId } = useParams();

  const printContract = () => {
    window.print();
  };

  return (
    <Flex className={classes.modal} direction="column" align="center">
      <Stack gap="md" p="md">
        <Button onClick={printContract} mx="md" leftSection={printIcon}>
          Print Contract
        </Button>
        <ViewContract contractId={contractId} isPrintView />
        <Button onClick={printContract} mx="md" leftSection={printIcon}>
          Print Contract
        </Button>
      </Stack>
    </Flex>
  );
};

export default ViewPrintContract;
