import { CreatorSetEntryState } from "components/discovery/Datamodels";

export enum ActiveTab {
  RESULTS = "results",
  SAVED = "saved",
  ACTIVATED = "activated",
  HIDDEN = "hidden",
}

export enum SearchMethod {
  HASHTAG = 1,
  PLATFORM = 2,
  CREATOR = 3,
  KEYWORD = 4,
}

export enum SearchSource {
  AUTOCOMPLETE = 1,
  RECOMMENDED_HASHTAG = 2,
  RELATED_CREATOR = 3,
  FILTER = 4,
  DISCOVER_LANDING_PAGE = 5,
}

export interface CreatorSetEntryStates {
  [creatorSetId: number]: {
    [key in CreatorSetEntryState]?: Set<number>;
  };
}

export interface LoggingMetadata {
  sessionId: string;
  queryId: string;
  sortBy: string;
  activeTab: ActiveTab;
  pageNumber: number;
}

export default null;
