import React, { useEffect, useState } from "react";
import { Button, Flex, Text, TextField, Heading, Box, SelectList } from "gestalt";
import "gestalt/dist/gestalt.css";
import { User as FirebaseUser } from "firebase/auth";
import { Link } from "react-router-dom";

import { handleResult } from "utils/ApiUtils";
import {
  ADMIN_URL,
  MANUAL_ADD_TO_CREATOR_SET_URL,
  InputSeedsTextField,
  BrandCreatorSetSelector,
  BrandRecords,
  CampaignPlatformArchetypeSelector,
  CampaignRecords,
  fetchCampaignData,
  fetchAdminBrandData,
} from "admin/AdminUtils";
import Spacer from "components/Spacer";
import { getOpsUser } from "ops_team/OpsLayout";
import { ArchetypeFeedInfos, ArchetypeFeedInfo } from "schemas/discovery";

const fetchArchetypeFeedInfos = async (user: FirebaseUser) => {
  const firebaseToken = await user.getIdToken();
  const response = await fetch(`${ADMIN_URL}get_all_archetype_feeds`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${firebaseToken}`,
    },
  });

  const arrayBuffer = await response.arrayBuffer();
  const uint8Array = new Uint8Array(arrayBuffer);
  const feedInfos = ArchetypeFeedInfos.decode(uint8Array);
  return feedInfos;
};

const CreateArchetypeFeedReq = async (
  user: FirebaseUser,
  archetypeId: number,
  feedName: string,
) => {
  const firebaseToken = await user.getIdToken();
  const request = new Request(`${ADMIN_URL}create_archetype_feed`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
    body: JSON.stringify({
      archetypeId,
      feedName,
    }),
  });
  return handleResult(request);
};

const AddHashtagToFeedReq = async (user: FirebaseUser, seeds: string[], feedName: string) => {
  const firebaseToken = await user.getIdToken();
  const request = new Request(`${ADMIN_URL}add_seed_to_archetype`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
    body: JSON.stringify({
      seeds,
      feedName,
    }),
  });
  return handleResult(request);
};

const AddCreatorToCreatorSet = async (
  user: FirebaseUser,
  leadNames: string[],
  brandId: number,
  creatorSetId: number,
  platform: string,
) => {
  const firebaseToken = await user.getIdToken();
  const request = new Request(`${MANUAL_ADD_TO_CREATOR_SET_URL}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
    body: JSON.stringify({
      leadNames,
      brandId,
      creatorSetId,
      platform,
    }),
  });
  return handleResult(request);
};

const ManualUploadToCreatorSetForm = ({
  user,
  brandData,
}: {
  user: FirebaseUser;
  brandData: BrandRecords;
}) => {
  const [seeds, setSeeds] = useState([]);
  const [brandId, setBrandId] = useState(0);
  const [creatorSetId, setCreatorSetId] = useState(0);
  const [platform, setPlatform] = useState("youtube");
  const [message, setMessage] = useState("");

  return (
    <>
      <Heading size="300">Manually upload creators</Heading>
      <Box alignItems="end" direction="row" display="flex">
        <Box width={400}>
          <InputSeedsTextField
            seeds={seeds}
            setSeeds={setSeeds}
            label="Creators"
            formId="creator-seeds"
          />
        </Box>
        <Spacer width={16} />
        <BrandCreatorSetSelector
          brandData={brandData}
          platforms={["select platform", "youtube", "tiktok", "instagram"]}
          brandId={brandId}
          setBrandId={setBrandId}
          setCreatorSetId={setCreatorSetId}
          setPlatform={setPlatform}
        />
        <Spacer width={16} />
        <Box paddingY={1}>
          <Button
            text="Add creators"
            color="blue"
            onClick={() => {
              setMessage("Starting to add users - please limit to 5 at a time to avoid timeouts");
              AddCreatorToCreatorSet(user, seeds, brandId, creatorSetId, platform)
                .then((_) => {
                  setMessage("Success: Finish adding users");
                  setTimeout(() => {
                    setMessage("");
                  }, 2000);
                })
                .catch((error) => {
                  const parsed = JSON.parse(error.message);
                  setMessage(parsed.result.error);
                });
            }}
          />
        </Box>
        <Spacer width={16} />
        <Box width={200}>
          <Text>{message}</Text>
        </Box>
      </Box>
    </>
  );
};

const ManualUploadCreators = ({
  user,
  brandData,
}: {
  user: FirebaseUser;
  brandData: BrandRecords;
}) => (
  <Flex direction="column" gap={4}>
    <Heading size="400">Manual upload commands</Heading>
    <ManualUploadToCreatorSetForm user={user} brandData={brandData} />
  </Flex>
);

const CreateArchetypeFeed = ({
  user,
  campaignData,
}: {
  user: FirebaseUser;
  campaignData: CampaignRecords;
}) => {
  const [feedName, setFeedName] = useState("");
  const [campaignId, setCampaignId] = useState(0);
  const [archetypeId, setArchetypeId] = useState(0);
  const [platform, setPlatform] = useState("tiktok");
  const [message, setMessage] = useState("");

  return (
    <>
      <Heading size="300">Create Feed</Heading>
      <Box alignItems="end" direction="row" display="flex">
        <Box width={400}>
          <TextField
            autoComplete="off"
            id="feed-name"
            label="Feed Name"
            onChange={({ value }) => {
              setFeedName(value);
            }}
            placeholder="Please enter a feed name"
            type="text"
            value={feedName}
          />
        </Box>
        <Spacer width={16} />
        <CampaignPlatformArchetypeSelector
          campaignData={campaignData}
          platforms={["select platform", "tiktok"]}
          campaignId={campaignId}
          setCampaignId={setCampaignId}
          setArchetypeId={setArchetypeId}
          setPlatform={setPlatform}
        />
        <Spacer width={16} />
        <Box paddingY={1}>
          <Button
            text="Add Feed"
            color="blue"
            onClick={() => {
              setMessage("Creating new feed");
              CreateArchetypeFeedReq(user, archetypeId, feedName)
                .then((_) => {
                  setMessage("Success: Created feed - please refresh page");
                  setTimeout(() => {
                    setMessage("");
                  }, 2000);
                })
                .catch((error) => {
                  const parsed = JSON.parse(error.message);
                  setMessage(parsed.result.error);
                });
            }}
          />
        </Box>
        <Spacer width={16} />
        <Box width={200}>
          <Text>{message}</Text>
        </Box>
      </Box>
    </>
  );
};

// GetArchetypeIdToFeeds returns a map from archetypeId to feedNames
const GetArchetypeIdToFeeds = (
  archetypeFeedInfos: ArchetypeFeedInfo[],
): Map<number, ArchetypeFeedInfo[]> => {
  const archetypeIdToFeeds = new Map();
  archetypeFeedInfos.forEach((feedInfo) => {
    if (!archetypeIdToFeeds.has(feedInfo.archetypeId)) {
      archetypeIdToFeeds.set(feedInfo.archetypeId, []);
    }
    archetypeIdToFeeds.get(feedInfo.archetypeId).push(feedInfo);
  });
  return archetypeIdToFeeds;
};

const AddHashtagToFeed = ({
  user,
  campaignData,
  archetypeFeedInfos,
}: {
  user: FirebaseUser;
  campaignData: CampaignRecords;
  archetypeFeedInfos: ArchetypeFeedInfo[];
}) => {
  const [seeds, setSeeds] = useState([]);
  const [campaignId, setCampaignId] = useState(0);
  const [archetypeId, setArchetypeId] = useState(0);
  const [availableFeeds, setAvailableFeeds] = useState([]);
  const [feedName, setFeedName] = useState("");
  const [existingFeedSeeds, setExistingFeedSeeds] = useState([]);
  const archetypeIdToFeeds = GetArchetypeIdToFeeds(archetypeFeedInfos);

  const [platform, setPlatform] = useState("tiktok");
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (
      campaignId === 0 ||
      Object.keys(campaignData).length === 0 ||
      !campaignData[campaignId] ||
      !campaignData[campaignId].archetypes ||
      archetypeId === 0
    ) {
      setAvailableFeeds([]);
      return;
    }

    const feeds = archetypeIdToFeeds.get(archetypeId);
    if (!feeds) {
      setAvailableFeeds([]);
      return;
    }
    setAvailableFeeds(feeds.map((feedInfo) => feedInfo.feedName));
  }, [campaignId, archetypeId]);

  useEffect(() => {
    if (feedName === "") {
      setExistingFeedSeeds([]);
      return;
    }
    const selectedFeedInfo = archetypeFeedInfos.find((feedInfo) => feedInfo.feedName === feedName);
    if (!selectedFeedInfo) {
      setExistingFeedSeeds([]);
      return;
    }
    setExistingFeedSeeds(selectedFeedInfo.hashtags);
  }, [feedName]);

  return (
    <>
      <Heading size="300">Create Feed</Heading>
      <Box alignItems="end" direction="row" display="flex">
        <Box width={400}>
          <InputSeedsTextField
            seeds={seeds}
            setSeeds={setSeeds}
            label="hashtag seeds"
            formId="archetype_feed_ht_seeds"
          />
        </Box>
        <Spacer width={16} />
        <CampaignPlatformArchetypeSelector
          campaignData={campaignData}
          platforms={["select platform", "tiktok"]}
          campaignId={campaignId}
          setCampaignId={setCampaignId}
          setArchetypeId={setArchetypeId}
          setPlatform={setPlatform}
        />
        <Spacer width={16} />
        <SelectList
          id="feed_names"
          onChange={({ value }) => {
            // Split the string by commas
            setFeedName(value);
          }}
          label="Feed Name"
          size="lg">
          {availableFeeds.map((availablefeedName) => (
            <SelectList.Option
              key={availablefeedName}
              label={availablefeedName}
              value={availablefeedName}
            />
          ))}
        </SelectList>
        <Spacer width={16} />
        <Box paddingY={1}>
          <Button
            text="Add hashtags"
            color="blue"
            onClick={() => {
              setMessage("Creating new feed");
              AddHashtagToFeedReq(user, seeds, feedName)
                .then((_) => {
                  setMessage("Success: Added new hashtag to feed - please refresh page");
                  setTimeout(() => {
                    setMessage("");
                  }, 2000);
                })
                .catch((error) => {
                  const parsed = JSON.parse(error.message);
                  setMessage(parsed.result.error);
                });
            }}
          />
        </Box>
        <Spacer width={16} />
        <Box width={400}>
          <Text>Hashtags: {existingFeedSeeds.join(", ")}</Text>
        </Box>
        <Box width={200}>
          <Text>{message}</Text>
        </Box>
      </Box>
    </>
  );
};

const ArchetypeFeedsSetup = ({
  user,
  campaignData,
  archetypeFeedInfos,
}: {
  user: FirebaseUser;
  campaignData: CampaignRecords;
  archetypeFeedInfos: ArchetypeFeedInfo[];
}) => (
  <Flex direction="column" gap={4}>
    <Heading size="400">Archetype Feeds setup</Heading>
    <CreateArchetypeFeed user={user} campaignData={campaignData} />
    <AddHashtagToFeed
      user={user}
      campaignData={campaignData}
      archetypeFeedInfos={archetypeFeedInfos}
    />
  </Flex>
);

const OpsCampaignDataset = () => {
  const user: FirebaseUser = getOpsUser();
  // make a get request to get base data for this page
  const [campaignData, setCampaignData] = useState({});
  const [brandData, setBrandData] = useState({});
  const [archetypeFeedInfos, setArchetypeFeedInfos] = useState([]);

  useEffect(() => {
    const abortController = new AbortController();
    // make a call using fetchCampaignData and set the
    // campaignData state to be the dictionary in CampaignArchetypeResponse
    fetchCampaignData(abortController).then((data) => {
      setCampaignData(data?.campaigns);
    });
    fetchAdminBrandData(abortController).then((data) => {
      setBrandData(data?.brands);
    });
    fetchArchetypeFeedInfos(user).then((data) => {
      setArchetypeFeedInfos(data.feedInfos);
    });

    return () => {
      abortController.abort();
    };
  }, []);

  // Add youtube datasets + instagram datasets here
  return (
    <Flex direction="column" gap={8}>
      <Text weight="bold">Operations home</Text>
      <Link to="/labeling-search">Labeling search</Link>
      <Link to="/labeling-search-summary">Search summary</Link>
      <Link to="/labeling-search-recs">Search recommendations</Link>
      <ManualUploadCreators user={user} brandData={brandData} />
      <ArchetypeFeedsSetup
        user={user}
        campaignData={campaignData}
        archetypeFeedInfos={archetypeFeedInfos}
      />
    </Flex>
  );
};

export default OpsCampaignDataset;
