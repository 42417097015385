import { Campaign, CampaignAdGroup } from "models/Campaign";
import React from "react";
import BasicNavButton from "campaigns/navbar/BasicNavButton";
import { ActionIcon, Badge } from "@mantine/core";
import { getTextWidth } from "campaigns/helpers/mainNavButtonHelpers";

const AdGroupNavButton = ({
  adGroup,
  campaign,
  isSelected,
  onClick,
  numTodos,
}: {
  adGroup: CampaignAdGroup;
  campaign: Campaign;
  isSelected: boolean;
  onClick: (campaign: Campaign, adGroupId: number) => void;
  numTodos: number;
}) => (
  // TODO (victoria): MAYBE SWITCH TO UNSTYLED BUTTON IF THIS DOESNT SCALE VERY NICELY
  <BasicNavButton
    isSelected={isSelected}
    text={adGroup.name}
    onButtonClick={() => onClick(campaign, adGroup.id)}
    leftSection={<ActionIcon variant="transparent" size={26} component="div" />}
    rootStyles={{
      backgroundImage: "linear-gradient(to right, white 62.5%, black 62.5% 67.5%, white 67.5%)",
      backgroundSize: "40px 105%", // button seems to be a bit bigger than root where we are adding these props
      backgroundRepeat: "no-repeat",
      backgroundPosition: "-1px", // again button seems a bit larger than root so moving this over -1px so we don't see additional background color from button when selected
      borderRadius: 0,
    }}
    innerStyles={{
      paddingRight: "var(--mantine-spacing-md)", // p={md} - need to align rightSection badge with other badges in nav bar; matches the padding in AdGroupNavBar
    }}
    rightSection={
      numTodos ? (
        <Badge size="sm" color="red" miw={getTextWidth(numTodos.toString()) + 15}>
          {numTodos}
        </Badge>
      ) : null
    }
  />
);

export default AdGroupNavButton;
