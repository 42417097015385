import React from "react";
import { ButtonGroup, Button, Flex, Heading, Box, Text } from "gestalt";
import "gestalt/dist/gestalt.css";
import { useNavigate } from "react-router-dom";
import { User } from "firebase/auth";

import { PlatformInfo, User as UserProfile } from "models/User";
import { BrandIconsFontAwesome } from "components/BrandIcons";
import { InstagramProfileResponse } from "social/fb/InstagramUtils";
import ProfileAvatar from "pages/profile/ProfileAvatar";
import Spacer from "components/Spacer";
import { Creator } from "models/Creator";
import "pages/profile/styles/profile.css";
import ProfileBanner from "pages/profile/ProfileBanner";
import { isMobile } from "react-device-detect";
import { UnclaimedProfileInfo } from "pages/profile/UnclaimedProfileUtils";

type PlatformUsernameEntryProps = {
  platformMap: PlatformInfo;
  index: number;
};

const PlatformUsernameEntry = ({ platformMap, index }: PlatformUsernameEntryProps) => {
  const { username, platform } = platformMap;
  return (
    <Flex key={`platformUsernameEntry${index}`} direction="row" gap={1} alignItems="center">
      <BrandIconsFontAwesome key={`brandIconUsernames${index}`} platform={platform} size="1x" />
      <Text key={`platformUsernameText${index}`} size="200" color="link">
        {username}
      </Text>
    </Flex>
  );
};

const buildPlatformInfoList = (instagramProfile: InstagramProfileResponse) => [
  { platform: "ig", username: instagramProfile.socialUsername },
];

const UserNamesByPlatform = ({
  instagramProfile,
}: {
  instagramProfile: InstagramProfileResponse;
}) => {
  const platformInfoList = buildPlatformInfoList(instagramProfile);
  const usernameDisplay = platformInfoList.map((platformMap, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <Box display="flex" key={`platformInfo-${index}`} paddingY={1}>
      <Box paddingX={4}>
        <PlatformUsernameEntry platformMap={platformMap} index={index} />
      </Box>
      {index < platformInfoList.length - 1 ? (
        // eslint-disable-next-line react/no-array-index-key
        <Box key={`divider${index}`} paddingX={4}>
          <div style={{ width: 1, height: "100%", background: "lightgray" }} />
        </Box>
      ) : null}
    </Box>
  ));
  return (
    <Flex direction="row" wrap justifyContent="center">
      {usernameDisplay}
    </Flex>
  );
};

const AccountInfo = ({
  user,
  requestUser,
  isSelf,
  unclaimedProfile,
}: {
  user: UserProfile;
  requestUser: User;
  isSelf: boolean;
  unclaimedProfile: UnclaimedProfileInfo;
}) => {
  const navigate = useNavigate();

  const username = user.is_claimed ? user.username : unclaimedProfile.username;
  const displayName = user.is_claimed ? user.name : unclaimedProfile.display_name;
  const avatarUrl = user.is_claimed ? user.avatar_url : unclaimedProfile.avatar_url;
  let accountButton;

  if (isSelf) {
    accountButton = (
      <Button
        text="Edit Profile"
        onClick={() => navigate("/settings/account")}
        color="blue"
        fullWidth={isMobile}
      />
    );
  } else if (!user.is_claimed) {
    accountButton = (
      <Button
        text="Claim Profile"
        onClick={() => navigate("/onboarding")}
        color="blue"
        fullWidth={isMobile}
      />
    );
  } else {
    accountButton = (
      <Button
        text="Email"
        onClick={() => window.open(`mailto:${user.email}`)}
        color="blue"
        fullWidth={isMobile}
      />
    );
  }

  return (
    <Box
      display="flex"
      justifyContent="between"
      wrap
      dangerouslySetInlineStyle={{ __style: { gap: 8 } }}>
      <Box display="flex" alignItems="center">
        <ProfileAvatar
          requestUser={requestUser}
          src={avatarUrl}
          displayName={displayName}
          isSelf={isSelf}
          hideInput
        />
        <Spacer width={32} />
        <Box display="flex" direction="column" alignSelf="end">
          <Heading overflow="breakWord">{displayName}</Heading>
          <Text>@{username}</Text>
        </Box>
      </Box>
      <Box display="flex" flex="none" alignItems="end" width={isMobile ? "100%" : undefined}>
        {accountButton}
      </Box>
    </Box>
  );
};

const Verticals = ({ selectedVerticals }: { selectedVerticals: string[] }) => (
  <ButtonGroup>
    {selectedVerticals.map((vertical) => (
      <Button key={`button-${vertical}`} text={vertical} />
    ))}
  </ButtonGroup>
);

const ProfileHeader = ({
  user,
  requestUser,
  isSelf,
  creator,
  unclaimedProfile,
}: {
  user: UserProfile;
  requestUser: User;
  isSelf: boolean;
  creator: Creator;
  unclaimedProfile: UnclaimedProfileInfo;
}) => {
  const halfOfAvatarHeight = 60;
  return (
    <Flex direction="column">
      <Box position="relative">
        <ProfileBanner requestUser={requestUser} src={user.banner_url} isSelf={isSelf} hideInput />
        <Spacer height={halfOfAvatarHeight} />
        <Box
          position="absolute"
          bottom
          width="98%"
          dangerouslySetInlineStyle={{ __style: { left: "1%", zIndex: 2 } }}>
          <AccountInfo
            user={user}
            requestUser={requestUser}
            isSelf={isSelf}
            unclaimedProfile={unclaimedProfile}
          />
        </Box>
      </Box>
      {creator?.about ? (
        <>
          <Spacer height={16} />
          <Box paddingX={2}>
            <Flex direction="column" gap={2}>
              {creator.about.split("\n").map((line, idx) => (
                /* eslint-disable-next-line react/no-array-index-key */
                <Text key={`text-${idx}`}>{line}</Text>
              ))}
            </Flex>
          </Box>
        </>
      ) : null}
      {creator?.selected_verticals ? (
        <>
          <Spacer height={16} />
          <Verticals selectedVerticals={creator.selected_verticals} />
        </>
      ) : null}
    </Flex>
  );
};

export default ProfileHeader;
