import React from "react";

import { DateTypes } from "utils/DateUtils";

import { Box, Text } from "@mantine/core";

import { SupportedFormat, SupportedPlatform } from "models/Common";

export const DEFAULT_TITLE_SIZE = 3;
export const DEFAULT_SUB_TITLE_SIZE = 5;
export const DEFAULT_TITLE_FONT_WEIGHT = "500";

// Keep in sync with Django enum.
export enum ContractStatus {
  CREATED = 1,
  PENDING = 2,
  ACCEPTED = 3,
  IN_PROGRESS = 4,
  COMPLETE = 5,
  REJECTED = 6,
}

export function contractAccepted(contractStatus: ContractStatus) {
  return (
    contractStatus === ContractStatus.ACCEPTED ||
    contractStatus === ContractStatus.IN_PROGRESS ||
    contractStatus === ContractStatus.COMPLETE
  );
}

export function contractInProgress(contractStatus: ContractStatus) {
  return (
    contractStatus === ContractStatus.ACCEPTED || contractStatus === ContractStatus.IN_PROGRESS
  );
}

export function contractPendingSignature(contractStatus: ContractStatus) {
  return contractStatus === ContractStatus.CREATED || contractStatus === ContractStatus.PENDING;
}

export function contractExpired(contractStatus: ContractStatus) {
  return contractStatus === ContractStatus.REJECTED;
}

// Keep in sync with Django enum.
export enum PaymentStatus {
  UNPAID = 1,
  PARTIALLY_PAID = 2,
  FULLY_PAID = 3,
}

// Keep in sync with Django enum.
export enum UploadStatus {
  PENDING = 1,
  SUCCEEDED = 2,
  FAILED = 3,
}

// Keep in sync with Django enum.
export enum ContentStatus {
  IN_PROGRESS = 0,
  PENDING_REVIEW = 1,
  REVISIONS_REQUESTED = 2,
  APPROVED = 3,
}

export enum RepeatContractValue {
  ELIGIBLE_FOR_REPEAT = 1,
  MARKED_FOR_REPEAT = 2,
  NOT_ELIGIBLE = 3,
  REPEAT_INFLIGHT = 4,
}

export enum DateUpdateType {
  ALL = "ALL",
  ANALYTICS = "ANALYTICS",
  APPROVAL = "APPROVAL",
  MIN_LIVE = "MIN_LIVE",
  MAX_LIVE = "MAX_LIVE",
  LIVE = "LIVE",
  SCRIPT = "SCRIPT",
  SCRIPT_REVISION = "SCRIPT_REVISION",
  VIDEO_DRAFT = "VIDEO_DRAFT",
}

export enum DeliverableListActionType {
  // List Operations
  ADD_DELIVERABLE = "ADD_DELIVERABLE",
  ADD_DELIVERABLE_WITH_DETAILS = "ADD_DELIVERABLE_WITH_DETAILS",
  REMOVE_DELIVERABLE = "REMOVE_DELIVERABLE",

  // Deliverable Operations
  SET_INITIAL_FIELDS = "SET_INITIAL_FIELDS",
  UPDATE_DATE = "UPDATE_DATE",
  UPDATE_EDITABLE_LIVE_DATE = "UPDATE_EDITABLE_LIVE_DATE",
  UPDATE_REQUIRES_SCRIPT_REVIEW = "UPDATE_REQUIRES_SCRIPT_REVIEW",
  UPDATE_REQUIRES_VIDEO_REVIEW = "UPDATE_REQUIRES_VIDEO_REVIEW",
  UPDATE_USAGE_RIGHTS_DAYS = "UPDATE_USAGE_RIGHTS_DAYS",
}

export interface DeliverableListAction {
  type: DeliverableListActionType;
  payload: {
    id?: number;
    format?: SupportedFormat;
    platform?: SupportedPlatform;
    profileLink?: string;
    creatorHandle?: string;
    dateUpdate?: { newDate: Date; dateUpdateType: DateUpdateType };
    editableLiveDate?: boolean;
    requiresScriptReview?: boolean;
    requiresVideoReview?: boolean;
    usageRightsDays?: number;
  };
}

export type MultiPlatformState = {
  [value in SupportedPlatform]: {
    selected: boolean;
    handle: string;
    profileLink: string;
    creatorId: string;
  };
};

// Product Access
// Keep in sync with Django enum.
export enum ProductAccessStatus {
  NOT_STARTED = 0,
  CREATOR_ACTION_REQUIRED = 1,
  CREATOR_RESUBMIT_REQUIRED = 2,
  BRAND_ACTION_REQUIRED = 3,
  COMPLETED = 4,
}

// Keep in sync with Django enum.
export enum ProductAccessInfoNeededFromCreatorType {
  NONE = 0,
  SHIPPING_ADDRESS = 1,
  ACCOUNT_EMAIL_ADDRESS = 2,
  ACCOUNT_USERNAME = 3,
  OTHER = 4,
}

// Keep in sync with Django enum.
export enum ProductAccessInfoToProvideToCreatorType {
  NONE = 0,
  ACCESS_CODE = 1,
  TRACKING_CODE = 2,
  OTHER = 3,
}

// Keep in sync with Django enum.
export enum ShortLinkStatus {
  PENDING = 1,
  ACTIVE = 2,
}

export enum ContentType {
  SCRIPT = "script",
  VIDEO = "video",
  LIVE_CONTENT = "live_content",
}

export const SUPPORTED_FORMAT_TO_SORT_ORDER: { [value in SupportedFormat]: number } = {
  [SupportedFormat.YOUTUBE_DEDICATED_VIDEO]: 1,
  [SupportedFormat.YOUTUBE_90S_INTEGRATED_VIDEO]: 2,
  [SupportedFormat.YOUTUBE_60S_INTEGRATED_VIDEO]: 3,
  [SupportedFormat.YOUTUBE_30S_INTEGRATED_VIDEO]: 4,
  // [SupportedFormat.YOUTUBE_HALF_INTEGRATED_VIDEO]: 5,
  [SupportedFormat.TIKTOK_DEDICATED_VIDEO]: 5,
  [SupportedFormat.INSTAGRAM_DEDICATED_REEL]: 6,
  [SupportedFormat.YOUTUBE_SHORT]: 7,
};

type PlatformFormatMap = {
  [value in SupportedPlatform]: {
    label: string;
    value: SupportedFormat;
    contractString: string;
    deliverableString: string;
    timeline: { adjustment: number; adjustmentType: DateTypes };
    isIntegration: boolean;
    isShortForm: boolean;
  }[];
};

export const SUPPORTED_PLATFORMS_TO_FORMATS_AND_TIMELINES: PlatformFormatMap = {
  [SupportedPlatform.INSTAGRAM]: [
    {
      label: "Instagram - Dedicated Reel",
      value: SupportedFormat.INSTAGRAM_DEDICATED_REEL,
      contractString: "1 dedicated Instagram video (>30 seconds)",
      deliverableString: "Instagram Reel",
      timeline: { adjustment: 2, adjustmentType: DateTypes.WEEKS },
      isIntegration: false,
      isShortForm: true,
    },
  ],
  [SupportedPlatform.TIKTOK]: [
    {
      label: "TikTok - Dedicated Video",
      value: SupportedFormat.TIKTOK_DEDICATED_VIDEO,
      contractString: "1 dedicated TikTok video (>30 seconds)",
      deliverableString: "TikTok Video",
      timeline: { adjustment: 2, adjustmentType: DateTypes.WEEKS },
      isIntegration: false,
      isShortForm: true,
    },
  ],
  [SupportedPlatform.YOUTUBE]: [
    {
      label: "YouTube - 30s Integrated Video",
      value: SupportedFormat.YOUTUBE_30S_INTEGRATED_VIDEO,
      contractString: "1 YouTube integration (30 seconds)",
      deliverableString: "YouTube Integration",
      timeline: { adjustment: 4, adjustmentType: DateTypes.WEEKS },
      isIntegration: true,
      isShortForm: false,
    },
    {
      label: "YouTube - 60s Integrated Video",
      value: SupportedFormat.YOUTUBE_60S_INTEGRATED_VIDEO,
      contractString: "1 YouTube integration (60 seconds)",
      deliverableString: "YouTube Integration",
      timeline: { adjustment: 4, adjustmentType: DateTypes.WEEKS },
      isIntegration: true,
      isShortForm: false,
    },
    {
      label: "YouTube - 90s Integrated Video",
      value: SupportedFormat.YOUTUBE_90S_INTEGRATED_VIDEO,
      contractString: "1 YouTube integration (90 seconds)",
      deliverableString: "YouTube Integration",
      timeline: { adjustment: 4, adjustmentType: DateTypes.WEEKS },
      isIntegration: true,
      isShortForm: false,
    },
    // {
    //   label: "YouTube - Half Integrated Video",
    //   value: SupportedFormat.YOUTUBE_HALF_INTEGRATED_VIDEO,
    //   contractString: "1 YouTube Half integration",
    //   deliverableString: "YouTube Half Integration",
    //   timeline: { adjustment: 4, adjustmentType: DateTypes.WEEKS },
    // },
    {
      label: "YouTube - Dedicated Video",
      value: SupportedFormat.YOUTUBE_DEDICATED_VIDEO,
      contractString: "1 YouTube dedicated video",
      deliverableString: "YouTube Video",
      timeline: { adjustment: 4, adjustmentType: DateTypes.WEEKS },
      isIntegration: false,
      isShortForm: false,
    },
    {
      label: "YouTube - Short",
      value: SupportedFormat.YOUTUBE_SHORT,
      contractString: "1 YouTube Short (>30 seconds)",
      deliverableString: "YouTube Short",
      timeline: { adjustment: 2, adjustmentType: DateTypes.WEEKS },
      isIntegration: false,
      isShortForm: true,
    },
  ],
};

// Reverse the mapping of SUPPORTED_PLATFORMS_TO_FORMATS_AND_TIMELINES
// to get a map of SupportedFormat to SupportedPlatform.
export const SUPPORTED_FORMATS_TO_PLATFORMS: Record<SupportedFormat, SupportedPlatform> =
  Object.entries(SUPPORTED_PLATFORMS_TO_FORMATS_AND_TIMELINES).reduce(
    (acc, [platform, formats]) => {
      formats.forEach(({ value }) => {
        acc[value] = platform as SupportedPlatform;
      });
      return acc;
    },
    {} as Record<SupportedFormat, SupportedPlatform>,
  );

export const SUPPORTED_FORMATS_TO_CONTRACT_STRINGS: Record<SupportedFormat, string> =
  Object.entries(SUPPORTED_PLATFORMS_TO_FORMATS_AND_TIMELINES).reduce(
    (acc, [platform, formats]) => {
      formats.forEach(({ value, contractString }) => {
        acc[value] = contractString;
      });
      return acc;
    },
    {} as Record<SupportedFormat, string>,
  );

export const SUPPORTED_FORMATS_TO_LABELS: Record<SupportedFormat, string> = Object.entries(
  SUPPORTED_PLATFORMS_TO_FORMATS_AND_TIMELINES,
).reduce((acc, [platform, formats]) => {
  formats.forEach(({ value, label }) => {
    acc[value] = label;
  });
  return acc;
}, {} as Record<SupportedFormat, string>);

export const SUPPORTED_FORMATS_TO_DELIVERABLE_STRINGS: Record<SupportedFormat, string> =
  Object.entries(SUPPORTED_PLATFORMS_TO_FORMATS_AND_TIMELINES).reduce(
    (acc, [platform, formats]) => {
      formats.forEach(({ value, deliverableString }) => {
        acc[value] = deliverableString;
      });
      return acc;
    },
    {} as Record<SupportedFormat, string>,
  );

export const INTEGRATED_FORMATS: SupportedFormat[] = Object.entries(
  SUPPORTED_PLATFORMS_TO_FORMATS_AND_TIMELINES,
)
  .map(([platform, formats]) => formats)
  .flat()
  .filter(({ isIntegration }) => isIntegration)
  .map(({ value }) => value);

export const SHORT_FORM_FORMATS: SupportedFormat[] = Object.entries(
  SUPPORTED_PLATFORMS_TO_FORMATS_AND_TIMELINES,
)
  .map(([platform, formats]) => formats)
  .flat()
  .filter(({ isShortForm }) => isShortForm)
  .map(({ value }) => value);

export function ErrorMessage({ errorMessage }: { errorMessage: string }) {
  return (
    <Box my="sm">
      <Text c="red">{errorMessage}</Text>
    </Box>
  );
}

export function SuccessMessage({ message }: { message: string }) {
  return (
    <Box my="sm">
      <Text c="green">
        <div dangerouslySetInnerHTML={{ __html: message }} />
      </Text>
    </Box>
  );
}

export function simpleHash(str: string): number {
  let hash = 0;
  if (str.length === 0) return hash;
  for (let i = 0; i < str.length; i += 1) {
    const char = str.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash = (hash << 5) - hash + char;
    // eslint-disable-next-line no-bitwise
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}
