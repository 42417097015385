import React from "react";
import { Box, Button, Container, Flex, Stack, Text, Title, rem } from "@mantine/core";
import { Icon, IconProps, IconMail, IconPigMoney, IconUser, IconVideo } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";

interface Step {
  title: string;
  description: string;
  icon: React.ForwardRefExoticComponent<Omit<IconProps, "ref"> & React.RefAttributes<Icon>>;
  iconColor: string;
  bgColor: string;
}

const STEPS: Step[] = [
  {
    title: "Sign up",
    description: "Sign up for 1stCollab and fill out your profile...",
    icon: IconUser,
    iconColor: "#4075D9",
    bgColor: "#D4E3FF", // Light blue
  },
  {
    title: "Check your dashboard for invites",
    description: "Get matched with partnerships based on your profile...",
    icon: IconMail,
    iconColor: "#ED6976",
    bgColor: "#FAD4D9", // Light pink/red
  },
  {
    title: "Accept deals, create, and get paid!",
    description: "Once you accept a deal, we’ll guide you through content creation...",
    icon: IconVideo,
    iconColor: "#E59500",
    bgColor: "#FBE5C7", // Light orange
  },
  {
    title: "Repeat and keep earning",
    description: "We work with brands looking for repeat deals with creators...",
    icon: IconPigMoney,
    iconColor: "#6DECB9",
    bgColor: "#CFF6E9", // Light green
  },
];

const HowItWorks = () => {
  const navigate = useNavigate();
  return (
    <Flex w="100%" p={0} bg="white">
      <Container style={{ maxWidth: "1920px" }} p={0}>
        <Box py={112} px={{ base: "24px", md: "32px", lg: "96px" }}>
          <Flex gap={80} direction={{ base: "column", sm: "row" }}>
            {/* Left Column */}
            <Box w={{ base: "100%", sm: "50%" }}>
              <Stack gap={0}>
                <Text fw={700} mb={8}>
                  How It Works
                </Text>
                <Title order={1} style={{ fontSize: "48px" }} mb={24}>
                  Land your next brand deal on 1stCollab
                </Title>
                <Button
                  variant="light"
                  w={224}
                  size="md"
                  radius="md"
                  onClick={() => navigate("/signup")}>
                  Sign up
                </Button>
              </Stack>
            </Box>
            {/* Right Column */}
            <Box w={{ base: "100%", sm: "50%" }}>
              <Stack>
                {STEPS.map((step, index) => (
                  <Flex key={step.title} align="start" gap="40">
                    <Flex direction="column" justify="center" align="center" gap={8} h="150px">
                      <Flex
                        p={12}
                        justify="center"
                        align="center"
                        bg={step.bgColor}
                        style={{
                          borderRadius: "50px",
                          border: `1px solid ${step.iconColor}`,
                          background: "linear-gradient(to bottom, white, transparent)",
                        }}>
                        <step.icon
                          style={{ width: rem(40), height: rem(40), stroke: step.iconColor }}
                          stroke={1.5}
                        />
                      </Flex>
                      {/* Hidden for last box */}
                      <Box w={2} h="100%" bg={index !== 3 ? "black" : "transparent"}>
                        <Text opacity={0}>0</Text>
                      </Box>
                    </Flex>
                    <Stack gap={4}>
                      <Text size="lg" fw={700}>
                        {step.title}
                      </Text>
                      <Text>{step.description}</Text>
                    </Stack>
                  </Flex>
                ))}
              </Stack>
            </Box>
          </Flex>
        </Box>
      </Container>
    </Flex>
  );
};

export default HowItWorks;
