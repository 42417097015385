// API calls for manipulating user creator data
import { User } from "firebase/auth";

import { API_URL } from "configs/Configs";
import { createRequest, handleResult } from "utils/ApiUtils";
import { Creator } from "models/Creator";

const CREATOR_API_URL = `${API_URL}/api/models/creators/`;

export const updateCreator = async (creator: Creator, user: User) => {
  if (!creator || !user) return "";
  const firebaseToken = await user.getIdToken();
  const request = new Request(CREATOR_API_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
    body: JSON.stringify(creator),
  });
  return handleResult(request);
};

// Gets creator given a user's key
export const getCreatorByKey = async (firebaseToken: string, key: string) => {
  const requestUrl = new URL(`${CREATOR_API_URL}${key}/`);
  const request = createRequest({ url: requestUrl.toString(), firebaseToken });
  try {
    const creator: Creator = await handleResult(request);
    return creator;
  } catch (error) {
    return null;
  }
};

// Gets the creator profile of the current authed in user
export const getAuthCreator = async (firebaseToken: string) => {
  const request = new Request(`${CREATOR_API_URL}get_authed_creator/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
  });
  try {
    const creator: Creator = await handleResult(request);
    return creator;
  } catch (error) {
    return null;
  }
};
