import React from "react";
import ReactDOM from "react-dom/client";
import App from "App";

import { Provider as ReduxProvider } from "react-redux";

import { MantineProvider, createTheme } from "@mantine/core";

import "@mantine/core/styles.css";
import "@mantine/charts/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/dropzone/styles.css";
import "@mantine/notifications/styles.css";
import "@mantine/tiptap/styles.css";
import { ModalsProvider } from "@mantine/modals";
import store from "reduxStore/store";
// import reportWebVitals from "./reportWebVitals";

const theme = createTheme({
  /** Put your mantine theme override here */
});

const root = ReactDOM.createRoot(document.getElementById("root") as Element);
root.render(
  <MantineProvider theme={theme}>
    <ModalsProvider>
      <ReduxProvider store={store}>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </ReduxProvider>
    </ModalsProvider>
  </MantineProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
