import React, { useState } from "react";

import { Card, SegmentedControl, Stack } from "@mantine/core";

import { CreatorSetSourcingSummary } from "admin/app/ops/tasks/CreatorSetSourcingSummary";
import OpsDailyTaskTable from "admin/app/ops/tasks/OpsDailyTaskTable";
import { OpsDailyUserTasks } from "admin/app/ops/tasks/OpsDailyUserTasks";
import { CreatorSetSourcingPlan } from "models/OpsTask";
import { User } from "models/User";

export const OpsDailyTasks = ({
  opsUsers,
  sourcingAllocations,
}: {
  opsUsers: User[];
  sourcingAllocations: CreatorSetSourcingPlan[];
}) => {
  const [control, setControl] = useState("tasks");

  return (
    <Card shadow="xs" padding="md" radius="md" withBorder>
      <Card.Section inheritPadding withBorder py="sm">
        <Stack gap="md">
          <SegmentedControl
            value={control}
            onChange={setControl}
            data={[
              { label: "Tasks", value: "tasks" },
              { label: "Creator Sets", value: "creator_sets" },
            ]}
          />
          {control === "tasks" && (
            <Stack>
              <OpsDailyUserTasks opsUsers={opsUsers} sourcingAllocations={sourcingAllocations} />
              <OpsDailyTaskTable opsUsers={opsUsers} sourcingAllocations={sourcingAllocations} />
            </Stack>
          )}
          {control === "creator_sets" && <CreatorSetSourcingSummary />}
        </Stack>
      </Card.Section>
    </Card>
  );
};

export default OpsDailyTasks;
