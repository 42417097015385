import React, { useState } from "react";

import { useDisclosure } from "@mantine/hooks";

import CollapsibleCard from "components/contracts/common/CollapsibleCard";

import { Badge, Box, Group, Text } from "@mantine/core";
import { IconGiftFilled } from "@tabler/icons-react";
import ProductAccessCardTimeline from "components/contracts/product_access/ProductAccessCardTimeline";
import { ProductAccessStatus } from "components/contracts/common/Common";
import {
  ProductAccessInfoNeededFromCreatorType,
  ProductAccessInfoToProvideToCreatorType,
} from "models/Campaign";
// import { toLongDateString } from "utils/DateUtils";

function ProgressBadge({
  status,
  productAccessInfoNeededFromCreator,
}: {
  status: ProductAccessStatus;
  productAccessInfoNeededFromCreator: ProductAccessInfoNeededFromCreatorType;
}) {
  let badgeColor = null;
  let badgeLabel = null;

  switch (status) {
    case ProductAccessStatus.NOT_STARTED:
      badgeColor = "gray";
      badgeLabel = "Not Started";
      break;
    case ProductAccessStatus.CREATOR_ACTION_REQUIRED:
    case ProductAccessStatus.CREATOR_RESUBMIT_REQUIRED:
      badgeColor = "red";
      badgeLabel = "Action Required: Submit Information";
      break;
    case ProductAccessStatus.BRAND_ACTION_REQUIRED:
      badgeColor = "yellow";
      badgeLabel = "Waiting for brand to provide product access";
      break;
    case ProductAccessStatus.COMPLETED:
      badgeColor = "teal";
      badgeLabel = "Complete";
      break;
    default:
      badgeColor = "yellow";
      break;
  }
  if (
    productAccessInfoNeededFromCreator === ProductAccessInfoNeededFromCreatorType.NONE &&
    status !== ProductAccessStatus.COMPLETED
  ) {
    badgeColor = "yellow";
    badgeLabel = "Waiting for brand to provide product access";
  }

  return (
    <Badge color={badgeColor} variant="light">
      {badgeLabel}
    </Badge>
  );
}

export function ProductAccessHeader({
  productAccessStatus,
  productAccessInfoNeededFromCreator,
}: // byDate,
{
  productAccessStatus: ProductAccessStatus;
  productAccessInfoNeededFromCreator: ProductAccessInfoNeededFromCreatorType;
  // byDate: Date;
}) {
  return (
    <Group justify="space-between" py="0.2rem">
      <Group justify="left" align="center">
        <IconGiftFilled />
        <Box>
          <Text fw="500">Receive Product</Text>
          {/* <Text size="sm" c="dimmed">
            by {toLongDateString(byDate)}
          </Text> */}
        </Box>
      </Group>
      <ProgressBadge
        status={productAccessStatus}
        productAccessInfoNeededFromCreator={productAccessInfoNeededFromCreator}
      />
    </Group>
  );
}

export default function ProductAccessCard({
  brandName,
  productAccessStatus,
  productAccessDueDate,
  productAccessDescription,
  productAccessInfoNeededFromCreator,
  productAccessOtherInfoNeededFromCreator,
  productAccessInfoToProvideToCreator,
  productAccessOtherInfoToProvideToCreator,
  productAccessBrandDisputeReason,
  productAccessCreatorInput,
  setProductAccessCreatorInput,
  productAccessBrandOutput,
  handleSaveCreatorProductAccessInfo,
  showAdminOptions,
}: {
  brandName: string;
  productAccessStatus: ProductAccessStatus;
  productAccessDueDate: Date;
  productAccessDescription: string;
  productAccessInfoNeededFromCreator: ProductAccessInfoNeededFromCreatorType;
  productAccessOtherInfoNeededFromCreator: string;
  productAccessInfoToProvideToCreator: ProductAccessInfoToProvideToCreatorType;
  productAccessOtherInfoToProvideToCreator: string;
  productAccessBrandDisputeReason: string;
  productAccessCreatorInput: string;
  setProductAccessCreatorInput: (productAccessInput: string) => void;
  productAccessBrandOutput: string;
  handleSaveCreatorProductAccessInfo: (productAccessInput: string | object) => void;
  showAdminOptions: boolean;
}) {
  const isOpen = true; // productAccessStatus !== ProductAccessStatus.COMPLETED;
  const [opened, { toggle }] = useDisclosure(isOpen);

  return (
    <CollapsibleCard
      isOpen={isOpen}
      header={
        <ProductAccessHeader
          productAccessStatus={productAccessStatus}
          productAccessInfoNeededFromCreator={productAccessInfoNeededFromCreator}
          // byDate={productAccessDueDate}
        />
      }
      controls={null}
      content={
        <ProductAccessCardTimeline
          brandName={brandName}
          productAccessStatus={productAccessStatus}
          productAccessDueDate={productAccessDueDate}
          productAccessDescription={productAccessDescription}
          productAccessInfoNeededFromCreator={productAccessInfoNeededFromCreator}
          productAccessOtherInfoNeededFromCreator={productAccessOtherInfoNeededFromCreator}
          productAccessInfoToProvideToCreator={productAccessInfoToProvideToCreator}
          productAccessOtherInfoToProvideToCreator={productAccessOtherInfoToProvideToCreator}
          productAccessCreatorInput={productAccessCreatorInput}
          productAccessBrandDisputeReason={productAccessBrandDisputeReason}
          setProductAccessCreatorInput={setProductAccessCreatorInput}
          productAccessBrandOutput={productAccessBrandOutput}
          handleSaveCreatorProductAccessInfo={handleSaveCreatorProductAccessInfo}
          showAdminOptions={showAdminOptions}
        />
      }
      disableHoverStyle
      customOpened={opened}
      customToggle={toggle}
    />
  );
}
