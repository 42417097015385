import React, { useEffect, useState } from "react";

import {
  Anchor,
  Button,
  Flex,
  Group,
  Modal,
  Paper,
  Pill,
  Select,
  Space,
  Stack,
  Table,
  Text,
  Title,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useDisclosure } from "@mantine/hooks";
import { IconRotateClockwise } from "@tabler/icons-react";

import {
  actionButton,
  getExternalDisplayUsername,
  iconFromBoolean,
  makeCreatorRep,
  makeDeliverableFormatName,
  makeDeliverableFormatRep,
  sortData,
  Th,
} from "campaigns/main/campaignPage/contracts_overview/Common";
import { fetchContractsForBrand } from "components/contracts/common/Api";
import { getFormattedCurrency, SocialHandle } from "components/contracts/dashboard/Utils";
import { RangeFilter } from "components/discovery/search/SearchFilters";

import { fromISODateString, toShortDateString } from "utils/DateUtils";
import { useUser } from "utils/UserContext";

interface ContractInfo {
  contractId: number;
  campaignHash: number;
  campaignName: string;
  adGroupId: number;
  adGroupName: string;
  creatorId: number;
  contractStatus: string;
  deliverableFormats: string[];
  cost: number;
  displayUsername: string;
  creatorFirstName: string;
  creatorLastName: string;
  signatureFirstName: string;
  signatureLastName: string;
  signatureEmail: string;
  signatureDatetime: Date;
  sourcedEmail: string;
  avatarUrl: string;
  socialHandles: SocialHandle[];
  isRecurring: boolean;
  numberOfRecurrences: number;
  activeRecurrenceNum: number;
  recurrenceFrequency: string;
  completionDate: Date;
  liveDate: Date;
  exclusivity: boolean;
  hasContentToPost: boolean;
}

interface RowData {
  // Most of these fields are for sorting
  creatorName: string;
  campaignName: string;
  adGroupName: string;
  signedDate: Date;
  completionDate: Date;
  postContent: boolean;
  cost: number;
  recurring: boolean;
  numRecurrences: number;
  recurrenceCadence: string;
  recurrencesRemaining: number;
  exclusivity: boolean;
  status: string;
  // This link to ContractInfo aids in rendering row values
  contractInfo: ContractInfo;
}

function makeDeliverableFormatStack(contractInfo: ContractInfo) {
  return (
    <Stack gap="xs">
      {contractInfo.deliverableFormats.map((val) => {
        return makeDeliverableFormatRep(val, `${contractInfo.contractId}-${val}`);
      })}
    </Stack>
  );
}

function pillify(status: string) {
  let text = status;
  let textColor = "";
  let bgColor = "";
  switch (status) {
    case "CREATED":
    case "PENDING":
      text = "AWAITING SIGNATURE";
      textColor = "#7950F2";
      bgColor = "#F3F0FF";
      break;
    case "ACCEPTED":
    case "IN_PROGRESS":
      text = "IN PRODUCTION";
      textColor = "#FAB005";
      bgColor = "#FFF9DB";
      break;
    case "COMPLETE":
      text = "COMPLETE";
      textColor = "#228BE6";
      bgColor = "#E7F5FF";
      break;
    case "REJECTED":
      text = "REJECTED";
      textColor = "#FA5252";
      bgColor = "#FFF5F5";
      break;
    default:
      break;
  }
  return (
    <Pill
      c={textColor}
      styles={{
        root: {
          backgroundColor: bgColor,
        },
      }}>
      {text}
    </Pill>
  );
}

const TableSortRow = ({ row }: { row: RowData }) => {
  const [userProfile, userProfileLoading] = useUser();
  const [isStaff, setIsStaff] = useState<boolean>(false);

  useEffect(() => {
    if (!userProfileLoading && userProfile?.is_staff) {
      setIsStaff(userProfile.is_staff);
    }
  }, [userProfileLoading, userProfile]);

  const displaySignedDate = row.contractInfo.signatureDatetime
    ? toShortDateString(row.signedDate)
    : "TBD";
  return (
    <Table.Tr key={row.contractInfo.contractId}>
      <Table.Td>
        {makeCreatorRep(
          row.creatorName,
          row.contractInfo.creatorId,
          row.contractInfo.avatarUrl,
          row.contractInfo.socialHandles,
          isStaff,
        )}
      </Table.Td>
      <Table.Td>
        <Anchor
          href={`https://www.1stcollab.com/campaigns/${row.contractInfo.campaignHash}/`}
          target="_blank">
          {row.campaignName}
        </Anchor>
      </Table.Td>
      <Table.Td>
        <Anchor
          href={`https://www.1stcollab.com/campaigns/${row.contractInfo.campaignHash}/${row.contractInfo.adGroupId}/`}
          target="_blank">
          {row.adGroupName}
        </Anchor>
      </Table.Td>
      <Table.Td>{makeDeliverableFormatStack(row.contractInfo)}</Table.Td>
      <Table.Td>{displaySignedDate}</Table.Td>
      <Table.Td>{row.completionDate && toShortDateString(row.completionDate)}</Table.Td>
      <Table.Td>{iconFromBoolean(row.postContent)}</Table.Td>
      <Table.Td>{getFormattedCurrency(row.cost || 0)}</Table.Td>
      <Table.Td>{iconFromBoolean(row.recurring)}</Table.Td>
      <Table.Td>{row.recurring ? row.numRecurrences : "N/A"}</Table.Td>
      <Table.Td>{row.recurring ? row.recurrenceCadence : "N/A"}</Table.Td>
      <Table.Td>{row.recurring ? row.recurrencesRemaining : "N/A"}</Table.Td>
      <Table.Td>{iconFromBoolean(row.exclusivity)}</Table.Td>
      <Table.Td>{pillify(row.contractInfo.contractStatus)}</Table.Td>
      <Table.Td>{actionButton(row.contractInfo.contractId)}</Table.Td>
    </Table.Tr>
  );
};

function TableSort({ rowData }: { rowData: RowData[] }) {
  const [sortedData, setSortedData] = useState(rowData);
  const [sortBy, setSortBy] = useState<keyof RowData | null>(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);

  const setSorting = (field: keyof RowData) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
    setSortedData(sortData(rowData, { sortBy: field, reversed }));
  };

  useEffect(() => {
    // Clear the sort by (even though we set it below)
    setSortBy(null);
    setReverseSortDirection(false);
    // Always impose a default sorting by month
    setSortedData(sortData(rowData, { sortBy: "completionDate", reversed: false }));
    setSortBy("completionDate");
  }, [rowData]);

  const rows = sortedData.map((row: RowData) => (
    <TableSortRow key={row.contractInfo.contractId} row={row} />
  ));

  return (
    <Table.ScrollContainer minWidth={500}>
      <Table horizontalSpacing="md" verticalSpacing="xs" miw={700} layout="auto" stickyHeader>
        <Table.Tbody>
          <Table.Tr>
            <Th
              sorted={sortBy === "creatorName"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("creatorName")}>
              Creator
            </Th>
            <Th
              sorted={sortBy === "campaignName"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("campaignName")}>
              Campaign
            </Th>
            <Th
              sorted={sortBy === "adGroupName"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("adGroupName")}>
              Ad Group
            </Th>
            <Table.Th>
              <Text fw={500} fz="sm">
                Deliverable Format
              </Text>
            </Table.Th>
            <Th
              sorted={sortBy === "signedDate"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("signedDate")}>
              Signed Date
            </Th>
            <Th
              sorted={sortBy === "completionDate"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("completionDate")}>
              Completion Date
            </Th>
            <Th
              sorted={sortBy === "postContent"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("postContent")}>
              Post Content
            </Th>
            <Th
              sorted={sortBy === "cost"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("cost")}>
              Cost
            </Th>
            <Th
              sorted={sortBy === "recurring"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("recurring")}>
              Recurring
            </Th>
            <Th
              sorted={sortBy === "numRecurrences"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("numRecurrences")}>
              Number of Recurrences
            </Th>
            <Th
              sorted={sortBy === "recurrenceCadence"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("recurrenceCadence")}>
              Recurrence Cadence
            </Th>
            <Th
              sorted={sortBy === "recurrencesRemaining"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("recurrencesRemaining")}>
              Recurrences Remaining
            </Th>
            <Th
              sorted={sortBy === "exclusivity"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("exclusivity")}>
              Exclusivity
            </Th>
            <Th
              sorted={sortBy === "status"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("status")}>
              Status
            </Th>
            <Table.Th>
              <Text fw={500} fz="sm">
                Action
              </Text>
            </Table.Th>
          </Table.Tr>
        </Table.Tbody>
        <Table.Tbody>{rows}</Table.Tbody>
      </Table>
    </Table.ScrollContainer>
  );
}

// Summary cards are buttons that filter on status but display information about contracts in that status
const SummaryCards = ({
  contracts,
  selected,
  setSelected,
}: {
  // The filtered list of contracts
  contracts: ContractInfo[];
  // Which of the cards is currently selected (or null)
  selected: string | null;
  // A callback to update the selected state
  setSelected: (whichSelected: string) => void;
}) => {
  const [cardCounts, setCardCounts] = useState<Record<string, number>>({
    awaiting: 0,
    inprod: 0,
    complete: 0,
  });
  const [cardValues, setCardValues] = useState<Record<string, number>>({
    awaiting: 0,
    inprod: 0,
    complete: 0,
  });

  // Update card values when the input changes
  useEffect(() => {
    const newCounts = { awaiting: 0, inprod: 0, complete: 0 };
    const newValues = { awaiting: 0, inprod: 0, complete: 0 };
    contracts.forEach((contractInfo) => {
      switch (contractInfo.contractStatus) {
        case "CREATED":
        case "PENDING":
          newCounts.awaiting += 1;
          newValues.awaiting += contractInfo.cost;
          break;
        case "ACCEPTED":
        case "IN_PROGRESS":
          newCounts.inprod += 1;
          newValues.inprod += contractInfo.cost;
          break;
        case "COMPLETE":
          newCounts.complete += 1;
          newValues.complete += contractInfo.cost;
          break;
        default:
          break;
      }
    });
    setCardCounts(newCounts);
    setCardValues(newValues);
  }, [contracts]);

  const buttonStylesWhenSelected = {
    root: {
      // Border is blue when selected
      outlineColor: "#228BE6",
      borderColor: "#228BE6",
    },
    label: {
      width: "100%",
    },
  };

  const buttonStylesWhenNotSelected = {
    // Border is default when not selected
    root: {
      outlineColor: "",
      borderColor: "",
    },
    label: {
      width: "100%",
    },
  };

  return (
    <Group grow>
      <Button
        radius="md"
        color="#868e96"
        variant="outline"
        onClick={() => {
          if (selected === "awaiting") {
            setSelected(null);
          } else {
            setSelected("awaiting");
          }
        }}
        justify="space-between"
        h={100}
        styles={selected === "awaiting" ? buttonStylesWhenSelected : buttonStylesWhenNotSelected}>
        <Stack w="100%">
          <Group preventGrowOverflow={false} justify="space-between">
            <Title order={6} c="black">
              Awaiting Signature
            </Title>
            <Pill
              styles={{
                root: {
                  backgroundColor: "#F1F3F5",
                },
              }}>
              {new Intl.NumberFormat(undefined, {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 0,
              }).format(cardValues.awaiting / 100)}
            </Pill>
          </Group>
          <Flex justify="flex-start">
            <Title order={4} c="black">
              {cardCounts.awaiting}
            </Title>
          </Flex>
        </Stack>
      </Button>
      <Button
        radius="md"
        color="#868e96"
        variant="outline"
        onClick={() => {
          if (selected === "inprod") {
            setSelected(null);
          } else {
            setSelected("inprod");
          }
        }}
        justify="space-between"
        h={100}
        styles={selected === "inprod" ? buttonStylesWhenSelected : buttonStylesWhenNotSelected}>
        <Stack w="100%">
          <Group preventGrowOverflow={false} justify="space-between">
            <Title order={6} c="black">
              In Production
            </Title>
            <Pill
              styles={{
                root: {
                  backgroundColor: "#F1F3F5",
                },
              }}>
              {new Intl.NumberFormat(undefined, {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 0,
              }).format(cardValues.inprod / 100)}
            </Pill>
          </Group>
          <Flex justify="flex-start">
            <Title order={4} c="black">
              {cardCounts.inprod}
            </Title>
          </Flex>
        </Stack>
      </Button>
      <Button
        radius="md"
        color="#868e96"
        variant="outline"
        onClick={() => {
          if (selected === "complete") {
            setSelected(null);
          } else {
            setSelected("complete");
          }
        }}
        justify="space-between"
        h={100}
        styles={{
          label: {
            width: "100%",
          },
        }}>
        <Stack w="100%">
          <Group preventGrowOverflow={false} justify="space-between">
            <Title order={6} c="black">
              Complete
            </Title>
            <Pill
              styles={{
                root: {
                  backgroundColor: "#F1F3F5",
                },
              }}>
              {new Intl.NumberFormat(undefined, {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 0,
              }).format(cardValues.complete / 100)}
            </Pill>
          </Group>
          <Flex justify="flex-start">
            <Title order={4} c="black">
              {cardCounts.complete}
            </Title>
          </Flex>
        </Stack>
      </Button>
    </Group>
  );
};

// Extract filter values from a list of contract info.
// This is intended to populate filter fields.
function extractFilterValues(contracts: ContractInfo[]) {
  const res = {
    campaignNames: [
      ...new Set(contracts.map((contractInfo) => contractInfo.campaignName).filter((val) => val)),
    ].sort(),
    adGroupNames: [
      ...new Set(contracts.map((contractInfo) => contractInfo.adGroupName).filter((val) => val)),
    ].sort(),
    deliverableFormats: [
      ...new Set(
        contracts
          .map((contractInfo) => contractInfo.deliverableFormats)
          .flat()
          .map((val) => makeDeliverableFormatName(val))
          .filter((val) => val),
      ),
    ].sort(),
    maximumCost: contracts
      .map((contractInfo) => contractInfo.cost)
      .reduce((prev, curr) => Math.max(prev, curr)),
    // TODO(chris): Min and max signed dates, completion dates, live dates, etc.
  };
  return res;
}

const ContractsTab = () => {
  // Raw contracts are the full list of contracts (unfiltered)
  const [rawContracts, setRawContracts] = useState<ContractInfo[]>([]);
  // The list of filtered contracts after filters have been applied.
  const [filteredContracts, setFilteredContracts] = useState<ContractInfo[]>([]);
  // A version of the filtered contracts suitable for use with the table.
  const [rowData, setRowData] = useState<RowData[]>([]);

  // A list of filter values for some of the filters
  const [availableCampaignNames, setAvailableCampaignNames] = useState<string[]>([]);
  const [availableAdGroupNames, setAvailableAdGroupNames] = useState<string[]>([]);
  const [availableDeliverableFormats, setAvailableDeliverableFormats] = useState<string[]>([]);
  // TODO(chris): Fix this to properly update when we learn of the maximum cost
  // The issue here is we don't want to update the displayed value of the
  // filter if it has been adjusted by the user.
  const [maximumContractCost, setMaximumContractCost] = useState<number>(10000);

  // Selected values for filters
  const [selectedRecurrence, setSelectedRecurrence] = useState<string>("All");
  const [selectedNumberOfRecurrences, setSelectedNumberOfRecurrences] = useState<string>("All");
  const [selectedRecurrencesRemaining, setSelectedRecurrencesRemaining] = useState<string>("All");
  const [selectedCampaign, setSelectedCampaign] = useState<string>("All");
  const [selectedAdGroup, setSelectedAdGroup] = useState<string>("All");
  const [selectedDeliverableFormat, setSelectedDeliverableFormat] = useState<string>("All");

  // Some filters have a range, for which we only want to make an update
  // when the full range is given.
  // Local versions absorb the partial updates, and we only trigger a change
  // the global version when both ends of the range have updated.
  const [localSelectedSignedDateRange, setLocalSelectedSignedDateRange] = useState<
    [Date | null, Date | null]
  >([null, null]);
  const [selectedSignedDateRange, setSelectedSignedDateRange] = useState<
    [Date | null, Date | null]
  >([null, null]);
  const [localSelectedCompletionDateRange, setLocalSelectedCompletionDateRange] = useState<
    [Date | null, Date | null]
  >([null, null]);
  const [selectedCompletionDateRange, setSelectedCompletionDateRange] = useState<
    [Date | null, Date | null]
  >([null, null]);
  const [localSelectedCostRange, setLocalSelectedCostRange] = useState<[number, number]>([
    0,
    maximumContractCost,
  ]);
  const [selectedCostRange, setSelectedCostRange] = useState<[number | null, number | null]>([
    null,
    null,
  ]);

  // The summary card that's selected (if any)
  const [selectedSummaryCard, setSelectedSummaryCard] = useState<string>(null);

  // The display state of the cost select modal
  const [costSelectModal, { open: openCostSelectModal, close: closeCostSelectModal }] =
    useDisclosure(false);

  useEffect(() => {
    fetchContractsForBrand()
      .then((response) => {
        const { success, contracts } = response;
        if (success) {
          setRawContracts(contracts);
          // Capture the available filter values
          const { campaignNames, adGroupNames, deliverableFormats, maximumCost } =
            extractFilterValues(contracts);
          setAvailableCampaignNames(campaignNames);
          setAvailableAdGroupNames(adGroupNames);
          setAvailableDeliverableFormats(deliverableFormats);
          setMaximumContractCost(Math.max((maximumCost || 0) / 100, 100));
        } else {
          setRawContracts([]);
        }
      })
      .catch(() => {
        setRawContracts([]);
      });
  }, []);

  // Produce row data from the filtered contracts, triggered whenever the
  // filtered contracts are changed.
  useEffect(() => {
    const newRowData: RowData[] = [];
    filteredContracts.forEach((contractInfo) => {
      let creatorName = `${contractInfo.signatureFirstName || contractInfo.creatorFirstName} ${
        contractInfo.signatureLastName || contractInfo.creatorLastName
      }`;
      if (creatorName.trim().length === 0) {
        creatorName = getExternalDisplayUsername(contractInfo.socialHandles);
      }
      const singleRow: RowData = {
        creatorName,
        campaignName: contractInfo.campaignName,
        adGroupName: contractInfo.adGroupName,
        signedDate: contractInfo.signatureDatetime || new Date(),
        completionDate:
          (contractInfo.completionDate ? contractInfo.completionDate : contractInfo.liveDate) ||
          new Date(),
        postContent: contractInfo.hasContentToPost,
        cost: contractInfo.cost,
        recurring: contractInfo.isRecurring,
        numRecurrences: contractInfo.isRecurring ? contractInfo.numberOfRecurrences || 0 : 0,
        recurrenceCadence: contractInfo.recurrenceFrequency
          ? contractInfo.recurrenceFrequency[0].toUpperCase() +
            contractInfo.recurrenceFrequency.slice(1).toLowerCase()
          : "",
        recurrencesRemaining: contractInfo.isRecurring
          ? contractInfo.numberOfRecurrences - contractInfo.activeRecurrenceNum
          : 0,
        exclusivity: contractInfo.exclusivity,
        status: contractInfo.contractStatus
          ? contractInfo.contractStatus[0].toUpperCase() +
            contractInfo.contractStatus.slice(1).toLowerCase()
          : "",
        contractInfo,
      };
      newRowData.push(singleRow);
    });
    setRowData(newRowData);
  }, [filteredContracts]);

  // Respond to changes in the filter criteria (and the raw data)
  useEffect(() => {
    const newFilteredContracts = [] as ContractInfo[];
    rawContracts.forEach((contractInfo) => {
      let skip = false;
      if (
        selectedCampaign !== undefined &&
        selectedCampaign !== "All" &&
        contractInfo.campaignName !== selectedCampaign
      ) {
        skip = true;
      }
      if (
        selectedAdGroup !== undefined &&
        selectedAdGroup !== "All" &&
        contractInfo.adGroupName !== selectedAdGroup
      ) {
        skip = true;
      }
      if (selectedDeliverableFormat !== undefined && selectedDeliverableFormat !== "All") {
        // Check if the filtered deliverable format is in the list of deliverable formats
        const deliverableFormats = new Set(
          contractInfo.deliverableFormats.map((val) => makeDeliverableFormatName(val)),
        );
        // Skip if we don't have that deliverable format in our list
        skip = skip || !deliverableFormats.has(selectedDeliverableFormat);
      }
      // Check the signed date
      if (selectedSignedDateRange !== undefined && !selectedSignedDateRange.includes(null)) {
        const [startDate, endDate] = selectedSignedDateRange;
        if (!contractInfo.signatureDatetime) {
          // A missing signed date fails to pass any date filter we might give.
          skip = true;
        } else {
          skip =
            skip ||
            fromISODateString(contractInfo.signatureDatetime.toString()) < startDate ||
            fromISODateString(contractInfo.signatureDatetime.toString()) > endDate;
        }
      }
      // Check completion date (which is either the completion date or the live date)
      if (
        selectedCompletionDateRange !== undefined &&
        !selectedCompletionDateRange.includes(null)
      ) {
        const [startDate, endDate] = selectedCompletionDateRange;
        const dateUsed = contractInfo.completionDate || contractInfo.liveDate;
        if (!dateUsed) {
          skip = true;
        } else {
          const verifiedDateUsed = fromISODateString(dateUsed.toString());
          skip = skip || verifiedDateUsed < startDate || verifiedDateUsed > endDate;
        }
      }
      // Check the cost filter range
      if (selectedCostRange && !selectedCostRange.includes(null)) {
        const [minCost, maxCost] = selectedCostRange;
        const costInMajorUnits = contractInfo.cost / 100;
        skip = skip || costInMajorUnits < minCost || costInMajorUnits > maxCost;
      }
      if (selectedRecurrence !== undefined && selectedRecurrence !== "All") {
        skip =
          skip ||
          (contractInfo.isRecurring && selectedRecurrence === "Not Recurring") ||
          (!contractInfo.isRecurring && selectedRecurrence === "Recurring");
      }
      if (
        !skip &&
        selectedNumberOfRecurrences !== undefined &&
        selectedNumberOfRecurrences !== "All"
      ) {
        switch (selectedNumberOfRecurrences) {
          case "1":
            skip = contractInfo.numberOfRecurrences !== 1;
            break;
          case ">1":
            skip = contractInfo.numberOfRecurrences <= 1;
            break;
          default:
            break;
        }
      }
      if (
        !skip &&
        selectedRecurrencesRemaining !== undefined &&
        selectedRecurrencesRemaining !== "All"
      ) {
        switch (selectedRecurrencesRemaining) {
          case "None":
            skip = contractInfo.numberOfRecurrences - contractInfo.activeRecurrenceNum > 0;
            break;
          case "2":
            skip = contractInfo.numberOfRecurrences - contractInfo.activeRecurrenceNum !== 2;
            break;
          case ">2":
            skip = contractInfo.numberOfRecurrences - contractInfo.activeRecurrenceNum <= 2;
            break;
          default:
            break;
        }
      }
      if (!skip && selectedSummaryCard !== undefined && selectedSummaryCard !== null) {
        switch (selectedSummaryCard) {
          case "awaiting":
            skip =
              contractInfo.contractStatus !== "CREATED" &&
              contractInfo.contractStatus !== "PENDING";
            break;
          case "inprod":
            skip =
              contractInfo.contractStatus !== "ACCEPTED" &&
              contractInfo.contractStatus !== "IN_PROGRESS";
            break;
          case "complete":
            skip = contractInfo.contractStatus !== "COMPLETE";
            break;
          default:
            break;
        }
      }
      if (!skip) {
        newFilteredContracts.push(contractInfo);
      }
    });
    setFilteredContracts(newFilteredContracts);
  }, [
    selectedCampaign,
    selectedAdGroup,
    selectedDeliverableFormat,
    selectedSignedDateRange,
    selectedCompletionDateRange,
    selectedCostRange,
    selectedRecurrence,
    selectedNumberOfRecurrences,
    selectedRecurrencesRemaining,
    selectedSummaryCard,
    rawContracts,
  ]);

  return (
    <>
      <Space h="lg" />
      <Stack gap="xs">
        <Group justify="space-between">
          <Title order={4}>Filter by:</Title>
          <Button
            leftSection={<IconRotateClockwise color="black" />}
            variant="filled"
            color="#DEE2E6"
            onClick={() => {
              // Return all filter values to their default states (including status cards)
              setSelectedRecurrence("All");
              setSelectedNumberOfRecurrences("All");
              setSelectedRecurrencesRemaining("All");
              setSelectedCampaign("All");
              setSelectedAdGroup("All");
              setSelectedDeliverableFormat("All");
              setLocalSelectedSignedDateRange([null, null]);
              setSelectedSignedDateRange([null, null]);
              setLocalSelectedCompletionDateRange([null, null]);
              setSelectedCompletionDateRange([null, null]);
              setLocalSelectedCostRange([0, maximumContractCost]);
              setSelectedCostRange([null, null]);
              setSelectedSummaryCard(null);
            }}>
            <Title order={6} c="black">
              Reset Filters
            </Title>
          </Button>
        </Group>
        <Group grow>
          <Select
            label="Campaign"
            value={selectedCampaign}
            data={["All", ...availableCampaignNames]}
            onChange={(newValue) => {
              setSelectedCampaign(newValue);
            }}
          />
          <Select
            label="Ad Group"
            value={selectedAdGroup}
            data={["All", ...availableAdGroupNames]}
            onChange={(newValue) => {
              setSelectedAdGroup(newValue);
            }}
          />
          <Select
            label="Deliverable Format"
            value={selectedDeliverableFormat}
            data={["All", ...availableDeliverableFormats]}
            onChange={(newValue) => {
              setSelectedDeliverableFormat(newValue);
            }}
          />
          <DatePickerInput
            type="range"
            label="Signed Date"
            placeholder="All"
            valueFormat="MM/DD/YYYY"
            value={localSelectedSignedDateRange}
            onChange={(newState) => {
              // Always update the local value for the picker
              setLocalSelectedSignedDateRange(newState);
              if (newState.includes(null)) {
                // We only care about the selection of a full range.
                // Half-selecting a range is not actionable, so we just track it locally.
                return;
              }
              setSelectedSignedDateRange(newState);
            }}
            clearable
          />
          <DatePickerInput
            type="range"
            label="Completion Date"
            placeholder="All"
            valueFormat="MM/DD/YYYY"
            value={localSelectedCompletionDateRange}
            onChange={(newState) => {
              // Always update the local value for the picker
              setLocalSelectedCompletionDateRange(newState);
              if (newState.includes(null)) {
                // We only care about the selection of a full range.
                // Half-selecting a range is not actionable, so we just track it locally.
                return;
              }
              setSelectedCompletionDateRange(newState);
            }}
            clearable
          />
        </Group>
        <Group grow>
          <>
            <Modal
              opened={costSelectModal}
              onClose={closeCostSelectModal}
              closeOnClickOutside={false}
              withCloseButton={false}>
              <Stack>
                <Title order={5}>Select price range</Title>
                <RangeFilter
                  rangeValue={localSelectedCostRange}
                  setRangeValue={setLocalSelectedCostRange}
                  min={0}
                  max={maximumContractCost}
                  stepSize={10}
                  marks={undefined}
                  hideNumberInput={false}
                  showsSuffix={false}
                />
                <Group>
                  <Button
                    variant="outline"
                    onClick={() => {
                      setSelectedCostRange(localSelectedCostRange);
                      closeCostSelectModal();
                    }}>
                    Accept
                  </Button>
                  <Button
                    color="red"
                    onClick={() => {
                      setLocalSelectedCostRange(
                        selectedCostRange.includes(null)
                          ? [0, maximumContractCost]
                          : selectedCostRange,
                      );
                      closeCostSelectModal();
                    }}>
                    Cancel
                  </Button>
                  <Button
                    variant="outline"
                    color="gray"
                    onClick={() => {
                      setLocalSelectedCostRange([0, maximumContractCost]);
                      setSelectedCostRange([null, null]);
                      closeCostSelectModal();
                    }}>
                    Clear
                  </Button>
                </Group>
              </Stack>
            </Modal>
            <Select
              label="Cost"
              placeholder={
                selectedCostRange.includes(null)
                  ? "Any"
                  : `${new Intl.NumberFormat(undefined, {
                      style: "currency",
                      currency: "USD",
                      maximumFractionDigits: 0,
                    }).format(selectedCostRange[0] || 0)} - ${new Intl.NumberFormat(undefined, {
                      style: "currency",
                      currency: "USD",
                      maximumFractionDigits: 0,
                    }).format(selectedCostRange[1] || 0)}`
              }
              onClick={openCostSelectModal}
            />
          </>
          <Select
            label="Recurring"
            value={selectedRecurrence}
            data={["All", "Recurring", "Not Recurring"]}
            onChange={(newValue) => {
              setSelectedRecurrence(newValue);
            }}
          />
          <Select
            label="Number of Recurrences"
            value={selectedNumberOfRecurrences}
            data={["All", "2", ">2"]}
            onChange={(newValue) => {
              setSelectedNumberOfRecurrences(newValue);
            }}
          />
          <Select
            label="Recurrences Remaining"
            value={selectedRecurrencesRemaining}
            data={["All", "None", "1", ">1"]}
            onChange={(newValue) => {
              setSelectedRecurrencesRemaining(newValue);
            }}
          />
        </Group>
        <Paper radius="lg" p={10}>
          <Stack>
            <Title order={2} p={10}>
              Status Summary
            </Title>
            <SummaryCards
              contracts={filteredContracts}
              selected={selectedSummaryCard}
              setSelected={setSelectedSummaryCard}
            />
          </Stack>
        </Paper>
        <Paper radius="lg">
          <Stack>
            <Title order={2} p={10}>
              All Contracts
            </Title>
            <TableSort rowData={rowData} />
          </Stack>
        </Paper>
      </Stack>
    </>
  );
};

export default ContractsTab;
