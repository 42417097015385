import React, { useEffect, useState } from "react";

import { User as FirebaseUser } from "firebase/auth";

import {
  Accordion,
  ActionIcon,
  Anchor,
  Button,
  ComboboxItem,
  Container,
  Fieldset,
  Flex,
  Group,
  NumberInput,
  OptionsFilter,
  Select,
  Stack,
  Text,
  Textarea,
  TextInput,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";

import { useForm } from "@mantine/form";

import { notifications, Notifications } from "@mantine/notifications";

import { useInputState } from "@mantine/hooks";

import { IconExternalLink } from "@tabler/icons-react";

import { OpsTask, OpsTaskType } from "models/OpsTask";
import { User } from "models/User";

import { createOpsTask } from "admin/api/opsApi";
import { useAdminAppContext } from "admin/app/AdminAppShell";
import { CreatorSet } from "components/discovery/Datamodels";

export const OpsCreateTask = ({
  opsUsers,
  opsTasks,
  setOpsTasks,
}: {
  opsUsers: User[];
  opsTasks: OpsTask[];
  setOpsTasks: (opsTasks: OpsTask[]) => void;
}) => {
  const { creatorSets } = useAdminAppContext();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [opsUser, setOpsUser] = useInputState(null);
  // const [name, setName] = useInputState("");
  const [type, setType] = useInputState(OpsTaskType.SOURCING.toString());
  const [creatorSet, setCreatorSet] = useInputState(null);
  const [description, setDescription] = useInputState("");
  const [value, setValue] = useInputState(0);
  const [estimatedWorkload, setEstimatedWorkload] = useInputState(0);
  const [dueDate, setDueDate] = useInputState(null);

  const validateCreatorSet = (values: { [key: string]: any }): string => {
    if (values.type === OpsTaskType.SOURCING.toString()) {
      if (values.creatorSet === undefined || values.creatorSet == null) {
        return "Creator set is required for sourcing tasks.";
      }
    }
    return null;
  };

  const validateAssignedUser = (values: { [key: string]: any }): string => {
    if (
      values.type === OpsTaskType.SOURCING.toString() &&
      values.creatorSet != null &&
      values.opsUser != null
    ) {
      const opsUserDetails = opsUsers.find((user) => user.key === values.opsUser);
      if (
        opsUserDetails?.trained_creator_sets.find((cs) => cs.id.toString() === values.creatorSet) ==
        null
      ) {
        return "Ops user is not trained for the creator set.";
      }
      return null;
    }
    return null;
  };

  const form = useForm({
    initialValues: {
      opsUser,
      // name,
      type,
      description,
      value,
      estimatedWorkload,
      creatorSet,
      dueDate,
    },

    validate: {
      // If there is an assigned user, then the user must be trained
      creatorSet: (v, values) => validateCreatorSet(values),
      opsUser: (v, values) => validateAssignedUser(values),
    },
  });

  useEffect(() => {
    const { values } = form;
    if (values.type === OpsTaskType.SOURCING.toString()) {
      if (!(values.creatorSet === undefined || values.creatorSet == null)) {
        // update estimated workload based on creatorset difficulty
        const csObj: CreatorSet = creatorSets.find((cs) => cs.id.toString() === values.creatorSet);
        if (csObj != null) {
          form.setFieldValue(
            "estimatedWorkload",
            Math.ceil((values.value * csObj.sourcing_difficulty) / 5),
          );
        }
      }
    }
  }, [form.values]);

  const opsUserData = opsUsers.map((user) => ({
    value: user.key,
    label: user.name,
  }));

  const typeData = Object.values(OpsTaskType).map((taskType) => ({
    value: taskType,
    label: taskType,
  }));

  const creatorSetData = creatorSets.map((cs) => ({
    value: cs.id.toString(),
    label: `${cs.name} (${cs.brand?.brand_name})`,
  }));

  const optionsFilter: OptionsFilter = ({ options, search }) => {
    const filtered = (options as ComboboxItem[]).filter((option) =>
      option.label.toLowerCase().trim().includes(search.toLowerCase().trim()),
    );

    filtered.sort((a, b) => a.label.localeCompare(b.label));
    return filtered;
  };

  const formatTask = (formValues: { [key: string]: any }) => {
    const genTaskName = () => {
      // task name follows the following format: "<type>: <creator set> (count)"
      if (formValues.type === OpsTaskType.SOURCING.toString()) {
        const csObj: CreatorSet = creatorSets.find(
          (cs) => cs.id.toString() === formValues.creatorSet,
        );
        return `${csObj?.name} (${csObj?.brand?.brand_name}) - ${formValues.value}`;
      }
      return `${formValues.type} (count)`;
    };
    const task = {
      name: genTaskName(),
      type: formValues.type,
      assigned_user: formValues.opsUser,
      description: formValues.description,
      value: formValues.value,
      estimated_workload: formValues.estimatedWorkload,
      creator_set: formValues.creatorSet,
      due_date: formValues.dueDate != null ? formValues.dueDate.toISOString() : null,
    };
    return task;
  };

  const createOpsTaskHelper = async () => {
    setButtonLoading(true);
    try {
      const response = await createOpsTask(formatTask(form.values));
      if (response != null && response?.message != null) {
        notifications.show({
          color: "red",
          title: "Error with creating the task!",
          message: `${response.message}`,
          autoClose: false,
        });
      }
      if (response != null && response?.id != null) {
        notifications.show({
          title: "Task created successfully!",
          message: (
            <Anchor href={`/admin/ops/tasks?taskId=${response.id}`} target="_blank">
              View task details.
            </Anchor>
          ),
          autoClose: false,
        });
        form.reset();
        // append tasks
        setOpsTasks([response, ...opsTasks]);
      } else {
        notifications.show({
          color: "red",
          title: "Error with creating the task!",
          message: "Unknown error.",
          autoClose: false,
        });
      }
    } catch (error) {
      notifications.show({
        title: "Error with creating the task!",
        message: error.message,
        color: "red",
        autoClose: false,
      });
    }
    setButtonLoading(false);
  };

  return (
    <Container>
      <Notifications />
      <Accordion variant="filled">
        <Accordion.Item key="create-task" value="create">
          <Accordion.Control>
            <Flex justify="center" align="center" direction="row">
              <Text fw={700}>Create a Task</Text>
            </Flex>
          </Accordion.Control>
          <Accordion.Panel>
            <Stack align="center">
              <Fieldset legend="Task Information">
                <form
                  onSubmit={form.onSubmit(
                    () => createOpsTaskHelper(),
                    // createBrandRequest({ user, formValues: form.values, setButtonLoading }),
                  )}>
                  <Stack gap={12}>
                    {/* <Select
                    label="Task Type"
                    data={typeData}
                    {...form.getInputProps("type")}
                    filter={optionsFilter}
                    w={300}
                  /> */}
                    <Group gap="xs" align="flex-end">
                      <Select
                        label="Creator Set"
                        data={creatorSetData}
                        {...form.getInputProps("creatorSet")}
                        filter={optionsFilter}
                        w={400}
                        searchable
                        required
                        rightSection={
                          form.values.creatorSet && (
                            <ActionIcon
                              color="gray"
                              variant="subtle"
                              component="a"
                              target="_blank"
                              size="sm"
                              href={`/admin/creator_sets?creatorSetId=${form.values.creatorSet}`}>
                              <IconExternalLink size="sm" color="gray" />
                            </ActionIcon>
                          )
                        }
                        rightSectionPointerEvents="auto"
                      />
                    </Group>
                    <Select
                      label="Ops User"
                      data={opsUserData}
                      /* eslint-disable react/jsx-props-no-spreading */
                      {...form.getInputProps("opsUser")}
                      filter={optionsFilter}
                      w={400}
                    />
                    <NumberInput
                      w={400}
                      label="Value"
                      required
                      description="Optional value field for storing task count (like # of creators to source)."
                      {...form.getInputProps("value")}
                    />
                    <DatePickerInput
                      allowDeselect
                      {...form.getInputProps("dueDate")}
                      label="Due Date"
                      w={400}
                    />
                    <NumberInput
                      w={400}
                      label="Estimated Workload"
                      description="Estimated workload in hours."
                      {...form.getInputProps("estimatedWorkload")}
                    />
                    <Textarea label="Description" {...form.getInputProps("description")} />
                    <Flex justify="center">
                      <Button type="submit" w="40%" loading={buttonLoading}>
                        Create
                      </Button>
                    </Flex>
                  </Stack>
                </form>
              </Fieldset>
            </Stack>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </Container>
  );
};

export default OpsCreateTask;
