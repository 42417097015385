import React from "react";
import { Box, Flex, Heading, Module, Text, Datapoint } from "gestalt";
import "gestalt/dist/gestalt.css";

import Spacer from "components/Spacer";
import { InstagramProfileResponse, InstagramAudienceResponse } from "social/fb/InstagramUtils";
import { AudienceGraph } from "components/AudienceGraph";
import GridView from "components/discovery/GridView";
import { getAbbreviatedNumber } from "utils/AnalyticsUtils";

export const ProfileSummary = ({ profile }: { profile: InstagramProfileResponse }) => (
  <Box direction="column" margin={3}>
    <Text size="200">{profile.biography}</Text>
    <Spacer height={50} />
    <Flex flex="grow" justifyContent="center" gap={12}>
      <Datapoint title="Number of Followers" value={getAbbreviatedNumber(profile.numFollowers)} />
      <Datapoint title="Lifetime Posts" value={getAbbreviatedNumber(profile.numPosts)} />
    </Flex>
  </Box>
);

export const InstagramProfile = ({
  profile,
  audience,
}: {
  profile: InstagramProfileResponse;
  audience: InstagramAudienceResponse;
}) => (
  <>
    {profile ? (
      <>
        <Spacer height={32} />
        <Module title="Instagram Profile Summary" id="ProfileSummary">
          <ProfileSummary profile={profile} />
        </Module>
      </>
    ) : (
      <></> // eslint-disable-line react/jsx-no-useless-fragment
    )}
    {audience && (
      <>
        <Spacer height={32} />
        <Flex justifyContent="between">
          {audience.audience_country && (
            <Module title="Instagram Follower Country Distribution" id="CountryDistribution">
              <AudienceGraph audienceSegment={audience.audience_country} />
            </Module>
          )}
          {audience.age_bucket && (
            <Module title="Instagram Follower Age Distribution" id="AgeDistribution">
              <AudienceGraph audienceSegment={audience.age_bucket} />
            </Module>
          )}
        </Flex>
        <Spacer height={32} />
        {audience.gender && (
          <Module title="Instagram Follower Gender Distribution" id="GenderDistribution">
            <AudienceGraph audienceSegment={audience.gender} />
          </Module>
        )}
        <Spacer height={32} />
      </>
    )}
    {/* <Spacer height={32} />
      <Analytics giniStats={SampleUser.giniStats} platformStats={SampleUser.platformStats} />
      <Spacer height={32} />
      <DiscoverBrands brands={SampleUser.recommendedBrands} /> */}
    {profile ? (
      <>
        <Spacer height={32} />
        <Flex alignItems="center" direction="column">
          <Heading size="300">Recent Instagram Posts</Heading>
          <Spacer height={16} />
          <GridView username={profile.socialUsername} />
        </Flex>
      </>
    ) : null}
  </>
);
