import {
  Button,
  Divider,
  MantineColor,
  NumberInput,
  Select,
  Space,
  Stack,
  Text,
} from "@mantine/core";
import React, { useEffect, useState } from "react";

import { notifications } from "@mantine/notifications";
import { IconCheck, IconCurrencyDollar, IconX } from "@tabler/icons-react";
import { useAdminAppContext } from "admin/app/AdminAppShell";
import { SUPPORTED_FORMATS_TO_PLATFORMS } from "components/contracts/common/Common";
import { encodeDeliverablesConfigs } from "components/contracts/deliverables/DeliverablesUrlUtils";
import { DeliverableConfigs } from "components/contracts/models/Deliverable";
import {
  addContractOffer,
  approveContractOfferInternal,
  ContractErrorResponse,
  rejectContractOfferInternal,
} from "components/contracts/negotiations/api/ContractOfferApi";
import { ActivePlatformInfo, getHandlesFromPlatformInfo } from "components/creator/CreatorUtils";
import {
  ContractOfferStatus,
  SupportedFormat,
  SupportedFormatNameToInteger,
  SupportedPlatform,
  UsageRightsDurationToDays,
} from "models/Common";
import ContractOffer from "models/ContractOffer";
import { DeliverablePackage } from "models/DeliverablePackage";
import { Campaign } from "models/Campaign";

let defaultAction;
let disablePrice = true;
let defaultButtonText = "Generate Contract";
let defaultButtonColor: MantineColor = "blue";

const options = [
  { value: "accept", label: "Creator Accepted" },
  { value: "counteroffer", label: "Creator Counter Offered" },
  { value: "reject", label: "Creator Rejected" },
];

const rejectedWithBrandPriceOptions = [
  { value: "accept-offer", label: "Creator Accepted Brand Offer" },
  { value: "counteroffer", label: "Creator Counter Offered" },
  { value: "reject", label: "Creator Rejected" },
];

const defaultRejectedOptions = [
  { value: "counteroffer", label: "Creator Counter Offered" },
  { value: "reject", label: "Creator Rejected" },
];

const ApprovedOfferModule = ({
  contractOfferId,
  offerPrice,
  usagePrice,
  maxPrice,
  selectedDeliverable,
  generateContractData,
  activePlatforms,
  setStatus,
  setCreatorAskingPrice,
  setCreatorUsagePrice,
  setOfferedPrice,
  setUsagePrice,
  fetchedPackages,
  setFetchedPackages,
  onChangeHandler,
}: {
  contractOfferId: number;
  offerPrice: number;
  usagePrice: number;
  maxPrice: number;
  selectedDeliverable: DeliverablePackage;
  generateContractData?: Record<string, string>;
  activePlatforms?: ActivePlatformInfo;
  fetchedPackages?: [number, ContractOfferStatus, DeliverablePackage[]][];
  setStatus?: (status: ContractOfferStatus) => void;
  setCreatorAskingPrice?: (price: number) => void;
  setCreatorUsagePrice?: (price: number) => void;
  setOfferedPrice?: (price: number) => void;
  setUsagePrice?: (price: number) => void;
  setFetchedPackages?: React.Dispatch<
    React.SetStateAction<[number, ContractOfferStatus, DeliverablePackage[]][]>
  >;
  onChangeHandler?: (value: string | number) => void;
}) => {
  const contractStatus = selectedDeliverable.status;

  // allow option for creator to accept brand price
  // only when we have a brand approved price
  if (
    contractStatus === ContractOfferStatus.BRAND_REJECTED_OFFER &&
    selectedDeliverable?.brand_approved_price
  ) {
    defaultAction = "accept-offer";
    defaultButtonText = "Generate Contract";
  } else if (
    contractStatus === ContractOfferStatus.BRAND_REJECTED_OFFER &&
    !selectedDeliverable?.brand_approved_price
  ) {
    defaultAction = "counteroffer";
    defaultButtonText = "Submit Counter Offer";
    defaultButtonColor = "yellow";
  } else if (contractStatus === ContractOfferStatus.BRAND_APPROVED_OFFER) {
    defaultAction = "accept";
    defaultButtonText = "Approve Price & Generate Contract";
  } else {
    defaultAction = "accept";
    defaultButtonText = "Generate Contract";
  }

  const rejectedOptions = selectedDeliverable?.brand_approved_price
    ? rejectedWithBrandPriceOptions
    : defaultRejectedOptions;

  // button and selector states
  const [action, setAction] = useState<string | null>(defaultAction);
  const [buttonText, setButtonText] = useState<string>(defaultButtonText);
  const [buttonColor, setButtonColor] = useState<MantineColor>(defaultButtonColor);

  const [counterUsagePrice, setCounterUsagePrice] = useState<number>(usagePrice);
  const [maxAllowedPrice, setMaxAllowedPrice] = useState<number | null>(
    selectedDeliverable?.brand_approved_price > 0
      ? selectedDeliverable?.brand_approved_price
      : maxPrice,
  );
  const [counterPrice, setCounterPrice] = useState<number>(offerPrice);
  const [combinedPrice, setCombinedPrice] = useState<number>(counterPrice + counterUsagePrice);

  // get format details for each package for contract generation data
  const { campaigns } = useAdminAppContext();
  const { campaignId } = generateContractData;
  const [campaign, setCampaign] = useState<Campaign>(null);
  const [needsScriptReview, setNeedsScriptReview] = useState<boolean>(
    campaign?.concept_review || true,
  );
  // TODO(andrew): should we be referencing counterUsagePrice instead of
  // the selectedDeliverable.usage_rights_price?
  // because the counter usage price could change
  // right now, it's not an issue because we approve first -> save state ->
  // then generate the contract
  // but if we ever merge it into a single step, we need to read from the counterUsagePrice
  const [hasUsageRights, setHasUsageRights] = useState<boolean>(
    selectedDeliverable?.usage_rights > 0 || false,
  );
  const [usageRightsDuration, setUsageRightsDuration] = useState<number>(
    selectedDeliverable?.usage_rights || 0,
  );
  const basePlatform: Record<string, string> = {};

  const selectedPlatformsOnPackage = Object.keys(selectedDeliverable).filter(
    (item) =>
      Object.values(SupportedFormat).includes(item as SupportedFormat) &&
      selectedDeliverable[item as keyof DeliverablePackage] === 1,
  ) as SupportedFormat[];

  const [deliverablesConfigs, setDeliverablesConfigs] = useState<DeliverableConfigs[]>([]);

  const seenPlatforms: Record<SupportedPlatform, boolean> = {
    [SupportedPlatform.INSTAGRAM]: null,
    [SupportedPlatform.TIKTOK]: null,
    [SupportedPlatform.YOUTUBE]: null,
  };

  // build url for selected platforms based on selected deliverable formats
  selectedPlatformsOnPackage.forEach((format) => {
    const platformName = SUPPORTED_FORMATS_TO_PLATFORMS[format as SupportedFormat];
    if (!seenPlatforms[platformName]) {
      seenPlatforms[platformName] = true;
      basePlatform[platformName] = "true";
    }
  });

  const buildUrl = () => {
    const encodedUrlString = encodeDeliverablesConfigs(deliverablesConfigs);
    const baseHandles = getHandlesFromPlatformInfo(selectedPlatformsOnPackage, activePlatforms);
    const filteredHandles = Object.fromEntries(
      Object.entries(baseHandles).filter(([_, value]) => value !== null),
    );

    const contractAmount = counterPrice + counterUsagePrice;

    const data: Record<string, string> = {
      deliverables: encodedUrlString,
      ...{ ...generateContractData, contractAmount: contractAmount.toString() },
      ...filteredHandles,
      ...basePlatform,
    };

    const params = new URLSearchParams(data);
    const url = `/admin/create_contract?${params}`;
    return url;
  };

  // validation booleans
  if (
    (action === "accept" &&
      ContractOfferStatus.BRAND_APPROVED_OFFER !== selectedDeliverable?.status) ||
    action === "reject" ||
    (action === "accept-offer" &&
      selectedDeliverable?.status === ContractOfferStatus.INTERNAL_APPROVED_OFFER)
  ) {
    disablePrice = true;
  } else {
    disablePrice = false;
  }

  // Update when selected deliverable changes
  useEffect(() => {
    setHasUsageRights(selectedDeliverable.usage_rights > 0 || false);
    setUsageRightsDuration(selectedDeliverable?.usage_rights || 0);
  }, [selectedDeliverable]);

  // Update campaign
  useEffect(() => {
    if (campaignId) {
      const newCampaign = campaigns?.find((c) => c.id === Number(campaignId));
      setCampaign(newCampaign);
    }
  }, [campaignId, campaigns]);

  // Update campaign-related fields when campaign changes
  useEffect(() => {
    if (campaign) {
      setNeedsScriptReview(campaign.concept_review || true);
    }
  }, [campaign]);

  // Update deliverables configs when params change
  useEffect(() => {
    const newDeliverablesConfigs = selectedPlatformsOnPackage.map((platform) => {
      const formatInt = SupportedFormatNameToInteger[platform as SupportedFormat];
      return {
        format: formatInt,
        include_usage_rights: hasUsageRights,
        include_script_review: needsScriptReview,
        usage_rights_duration:
          hasUsageRights && usageRightsDuration > 0
            ? UsageRightsDurationToDays[
                usageRightsDuration as keyof typeof UsageRightsDurationToDays
              ]
            : 0,
      } as DeliverableConfigs;
    });
    setDeliverablesConfigs(newDeliverablesConfigs);
  }, [hasUsageRights, needsScriptReview, usageRightsDuration]);

  // TODO: move to on change handler
  useEffect(() => {
    if (action === "accept" || action === "accept-offer") {
      // An accept needs to be below the max allowed price
      setOfferedPrice(Math.max(maxAllowedPrice, counterPrice));
      setButtonColor("blue");
      if (
        selectedDeliverable.status === ContractOfferStatus.INTERNAL_APPROVED_OFFER ||
        selectedDeliverable.status === ContractOfferStatus.BRAND_REJECTED_OFFER
      ) {
        setButtonText("Generate Contract");
      } else {
        setButtonText("Approve Price & Generate Contract");
      }
    } else if (action === "counteroffer") {
      setButtonText("Submit Counter Offer");
      setButtonColor("yellow");
    } else if (action === "reject") {
      setButtonText("Confirm Creator Rejected");
      setButtonColor("red");
    }
    setAction(action);
  }, [action]);

  // update max price when it changes
  useEffect(() => {
    setMaxAllowedPrice(
      selectedDeliverable?.brand_approved_price > 0
        ? selectedDeliverable?.brand_approved_price
        : maxPrice,
    );
  }, [selectedDeliverable?.brand_approved_price, maxPrice]);

  const submitAction = () => {
    if (!campaignId) return;
    // accept
    if (action === "accept" || action === "accept-offer") {
      setButtonText("Generated!");
      // also approve the input offer price when generating a contract
      // if the selected deliverable status is BRAND_APPROVED_OFFER
      // will set the package status to INTERNALLY_APPROVED_OFFER
      if (
        selectedDeliverable.status === ContractOfferStatus.BRAND_APPROVED_OFFER
        // TODO: right now backend doesn't support brand rejected offer -> internally approved offer
        // || selectedDeliverable.status === ContractOfferStatus.BRAND_REJECTED_OFFER
      ) {
        approveContractOfferInternal(
          contractOfferId,
          combinedPrice,
          usagePrice,
          selectedDeliverable.id,
        ).then((res) => {
          if (res.success === "true") {
            notifications.show({
              title: "Offer Price Approved!",
              message: "Offer price was approved successfuly.",
              color: "green",
              icon: <IconCheck />,
              autoClose: false,
            });
          } else {
            const errRes = res as unknown as ContractErrorResponse;
            notifications.show({
              title: "Error approving offer price",
              message: `${errRes.text}. ${errRes.result.message}. Could not approve offer price.`,
              color: "red",
              icon: <IconX />,
              autoClose: false,
            });
          }
        });
      }
      const win = window.open(buildUrl(), "_blank");
      win.focus();
    } // counteroffer
    else if (action === "counteroffer") {
      const fetchedDP = fetchedPackages[0][2].map((dp) => {
        if (dp.id === selectedDeliverable.id) {
          return {
            ...dp,
            creator_id: selectedDeliverable.creator_id || (selectedDeliverable.creator as any),
            opportunity_id:
              selectedDeliverable.opportunity_id || (selectedDeliverable.opportunity as any),
            creator_asking_price: counterPrice,
            price: offerPrice,
            creator_asking_usage_rights_price: counterUsagePrice,
          };
        }
        return dp;
      });

      const contractOffer: ContractOffer = {
        id: contractOfferId,
        // typing here is so hacky
        creator_id: selectedDeliverable.creator_id || (selectedDeliverable.creator as any),
        opportunity_id:
          selectedDeliverable.opportunity_id || (selectedDeliverable.opportunity as any),
        deliverable_packages: fetchedDP,
        direction: 0,
        status: selectedDeliverable.status,
        is_counteroffer: true,
      };

      addContractOffer(contractOffer)
        .then((res) => {
          if (!res.id) {
            const errRes = res as unknown as ContractErrorResponse;
            throw new Error(errRes.result.message);
          }
          setButtonText("Counter Offer Submitted!");
          notifications.show({
            title: "Counter Offer Submitted!",
            message: "Counter offer submitted successfully.",
            color: "green",
            icon: <IconCheck />,
            autoClose: false,
          });
          setFetchedPackages([[res.id, res.status, res.deliverable_packages]]);

          setStatus(ContractOfferStatus.NEEDS_INTERNAL_REVIEW);
          setAction("accept");
          setCreatorAskingPrice(counterPrice);
          setCreatorUsagePrice(counterUsagePrice);
          setUsagePrice(counterUsagePrice);
        })
        .catch((err) => {
          notifications.show({
            title: "Error submitting counter offer",
            message: `${err}. Could not submit current offer as counter offer.`,
            color: "red",
            icon: <IconX />,
            autoClose: false,
          });
        });
    } // reject
    else if (action === "reject") {
      rejectContractOfferInternal(contractOfferId)
        .then((res) => {
          if (res.success !== "true") throw new Error("Error submitting rejection");
          setButtonText("Rejection Submitted");
          notifications.show({
            title: "Rejection Submitted",
            message: "Rejection submitted successfully.",
            color: "green",
            icon: <IconCheck />,
            autoClose: 2000,
          });
          setStatus(ContractOfferStatus.REJECTED);
          if (counterPrice) {
            setOfferedPrice(counterPrice);
          }
        })
        .catch((err) => {
          notifications.show({
            title: "Error submitting rejection",
            message: "Could not submit current offer as rejection.",
            color: "red",
            icon: <IconX />,
            autoClose: false,
          });
        });
    }
  };

  return (
    <Stack>
      <Stack gap={2}>
        <Text size="sm">Creator Asking Price: ${offerPrice}</Text>
        <Text size="sm">Usage Rights Price: ${usagePrice}</Text>
        <Divider variant="dashed" w={200} my={2} />
        <Text size="sm" fw={700}>
          Latest Total Offer Price: ${usagePrice + offerPrice}
        </Text>
        <Space h="xs" />
        <Text size="sm" fw={700}>
          Brand Approved Price:{" "}
          {selectedDeliverable?.brand_approved_price
            ? `$${selectedDeliverable?.brand_approved_price}`
            : "N/A"}
        </Text>
      </Stack>
      <Select
        data={
          contractStatus === ContractOfferStatus.BRAND_REJECTED_OFFER ? rejectedOptions : options
        }
        value={action}
        onChange={setAction}
        allowDeselect={false}
      />
      {/* TODO: controlled value doesn't work when changing between action types */}
      <NumberInput
        label={action === "counteroffer" ? "Creator Countered Price" : "Offer Price"}
        description={
          action !== "counteroffer"
            ? `Max price allowed: ${
                maxAllowedPrice ? `$${maxAllowedPrice - counterUsagePrice}` : null
              }`
            : null
        }
        value={counterPrice}
        disabled={disablePrice}
        clampBehavior="strict"
        allowNegative={false}
        max={
          maxAllowedPrice && ["accept", "accept-offer"].includes(action)
            ? maxAllowedPrice - counterUsagePrice
            : Infinity
        }
        required
        leftSection={<IconCurrencyDollar />}
        onChange={(value) => {
          setCounterPrice(value as number);
          setCombinedPrice((value as number) + counterUsagePrice);
        }}
        error={
          maxAllowedPrice &&
          ["accept", "accept-offer"].includes(action) &&
          counterPrice + counterUsagePrice > maxAllowedPrice
            ? `Total price (Offer + Usage Rights) cannot exceed $${maxAllowedPrice}`
            : null
        }
      />
      <NumberInput
        label="Usage Rights Price"
        value={counterUsagePrice}
        disabled={disablePrice}
        required
        leftSection={<IconCurrencyDollar />}
        onChange={(value) => {
          setCounterUsagePrice(value as number);
          setCombinedPrice(counterPrice + (value as number));
        }}
        max={
          maxAllowedPrice && ["accept", "accept-offer"].includes(action)
            ? maxAllowedPrice - counterPrice
            : Infinity
        }
        error={
          maxAllowedPrice &&
          ["accept", "accept-offer"].includes(action) &&
          counterPrice + counterUsagePrice > maxAllowedPrice
            ? `Total price (Offer + Usage Rights) cannot exceed $${maxAllowedPrice}`
            : null
        }
      />
      <Button
        variant="light"
        color={buttonColor}
        onClick={submitAction}
        disabled={
          [ContractOfferStatus.BRAND_REJECTED_OFFER].includes(contractStatus) ||
          ["counteroffer", "reject"].includes(action)
            ? false
            : !counterPrice || counterPrice + counterUsagePrice > maxAllowedPrice
        }>
        {buttonText}
      </Button>
      <Divider my="sm" />
    </Stack>
  );
};

export default ApprovedOfferModule;
