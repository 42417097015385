import React, { useEffect, useState } from "react";
import { useOutletContext, useSearchParams } from "react-router-dom";
import {
  Stack,
  Box,
  Container,
  Loader,
  Center,
  Group,
  Select,
  Button,
  Text,
  Tooltip,
} from "@mantine/core";

import { User, getAuth } from "firebase/auth";

import { AuthContext } from "auth/AuthContext";
import {
  CreatorDetails,
  CreatorSetEntryWithDetail,
  CreatorSet,
  CreatorSetEntryItem,
} from "components/discovery/Datamodels";
import {
  BrandCreatorSetDict,
  CreatorSetCreatorProps,
  CreatorSetEntriesResponse,
  CreatorSetInternalReviewProps,
  fetchBrandCreatorSetData,
  CREATOR_SETS_URL,
} from "components/creator_sets/CreatorSetUtils";
import { handleResult } from "utils/ApiUtils";
import UnifiedCreatorRep from "components/creator_lists/unified_creator_rep/UnifiedCreatorRep";
import { InternalReviewCreatorSetActionRow } from "components/creator_lists/unified_creator_rep/ActionRows";
import Spacer from "components/Spacer";
import { TopOfEntries, useCreatorSetPaginate } from "components/creator_sets/CreatorSetCommons";
import LayoutBase from "components/LayoutBase";
import { Notifications } from "@mantine/notifications";
import { isAdminUser } from "utils/UserUtils";

enum InternalReviewFilterState {
  INTERNAL_REVIEW_NEEDS_REVIEW = 1,
  INTERNAl_REVIEW_REJECTED = 2,
  BRAND_ACCEPTED = 3,
  BRAND_REJECTED = 4,
  BRAND_NEEDS_REVIEW = 5,
  INTERNAL_REVIEW_ACCEPTED = 6,
}

const InternalReviewCreatorSetEntryResults = ({
  creatorEntriesWithDetails,
  user,
  creatorSet,
  availalbleCreatorSets,
  internalReviewProps,
  creatorProps,
  isInternalReview,
  isBrandReview,
}: {
  creatorEntriesWithDetails: CreatorSetEntryWithDetail[];
  user: User;
  creatorSet: CreatorSet;
  availalbleCreatorSets: CreatorSet[];
  internalReviewProps: Record<number, CreatorSetInternalReviewProps>;
  creatorProps: Record<number, CreatorSetCreatorProps>;
  isInternalReview: boolean;
  isBrandReview: boolean;
}) => (
  <Box>
    <Notifications />
    {creatorEntriesWithDetails.map((entryWithDetail, index) => {
      const entry = entryWithDetail.creator_set_entry;
      const actionRow = (
        <InternalReviewCreatorSetActionRow
          creatorSetId={entry.creator_set_id}
          creatorId={entry.creator_id}
          user={user}
          availalbleCreatorSets={availalbleCreatorSets}
          internalReviewProp={internalReviewProps[entry.creator_id]}
          creatorProp={creatorProps[entry.creator_id]}
          isInternalReview={isInternalReview}
          isBrandReview={isBrandReview}
        />
      );
      const expandedPlatform = internalReviewProps[entry.creator_id]?.internal_review_platform;
      return (
        <Box key={`entry-${entry.creator_id}`}>
          <div style={{ height: 1, width: "100%", background: "lightgray" }} />
          {index > 0 ? <Spacer height={12} /> : null}
          <UnifiedCreatorRep
            creator={entryWithDetail.creator_details}
            key={`entry-${entry.creator_id}`}
            defaultExpandedPlatforms={[expandedPlatform]}
            actionRow={actionRow}
            archetype={creatorSet.name}
            useScrollOverflow={false}
          />
        </Box>
      );
    })}
  </Box>
);

const fetchCreatorSetInternalReviewEntryItems = async (
  requestUser: User,
  creatorSetId: number = null,
  opsReviewer = "all",
  internalReviewFilterState = InternalReviewFilterState.INTERNAL_REVIEW_NEEDS_REVIEW,
  abortController: AbortController = new AbortController(),
) => {
  const firebaseToken = await requestUser.getIdToken();
  const url = new URL(`${CREATOR_SETS_URL}get_internal_review_creator_set_entries/`);
  const params = {
    creatorSetId: String(creatorSetId),
    opsReviewer,
    internalReviewFilterState: String(internalReviewFilterState),
  };
  url.search = new URLSearchParams(params).toString();
  const request = new Request(url.toString(), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`, // Assuming you are sending the token in the Authorization header
    },
    signal: abortController.signal,
  });

  const response: CreatorSetEntriesResponse = await handleResult(request, abortController);
  return response;
};

const hasInternalReviewChangeAccess = async (
  abortController: AbortController = new AbortController(),
) => {
  const firebaseToken = await getAuth().currentUser?.getIdToken();
  const url = new URL(`${CREATOR_SETS_URL}has_internal_review_label_access/`);
  url.search = new URLSearchParams().toString();
  const request = new Request(url.toString(), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`, // Assuming you are sending the token in the Authorization header
    },
    signal: abortController.signal,
  });

  try {
    const response = await fetch(request);
    return response.ok;
  } catch (error) {
    return false;
  }
};

const BRAND_REVIEW_FILTER_STATES = [
  InternalReviewFilterState.BRAND_ACCEPTED,
  InternalReviewFilterState.BRAND_REJECTED,
  InternalReviewFilterState.BRAND_NEEDS_REVIEW,
  InternalReviewFilterState.INTERNAL_REVIEW_ACCEPTED,
];

const CreatorSetInternalReviewView = ({
  user,
  creatorSetEntryItems,
  creatorSet,
  availalbleCreatorSets,
  internalReviewProps,
  creatorProps,
  internalReviewFilterState,
}: {
  user: User;
  creatorSetEntryItems: CreatorSetEntryItem[];
  creatorSet: CreatorSet;
  availalbleCreatorSets: CreatorSet[];
  internalReviewProps: Record<number, CreatorSetInternalReviewProps>;
  creatorProps: Record<number, CreatorSetCreatorProps>;
  internalReviewFilterState: InternalReviewFilterState;
}) => {
  const [creatorSetDetailsMap, setCreatorSetDetailsMap] = useState<Record<number, CreatorDetails>>(
    {},
  );
  const { creatorSetPageDetails, EntryPagniator } = useCreatorSetPaginate(
    creatorSetEntryItems,
    creatorSetDetailsMap,
    setCreatorSetDetailsMap,
  );

  const [internlReviewChangeAccess, setInternalReviewChangeAccess] = useState(false);

  useEffect(() => {
    hasInternalReviewChangeAccess().then((boolVal) => {
      setInternalReviewChangeAccess(boolVal);
    });
  }, []);

  return (
    <Stack>
      {/*
        Make the top filter
      */}
      <>
        {creatorSetPageDetails.length === 0 && creatorSetEntryItems.length !== 0 ? (
          <Container fluid h="100vh">
            <Center h="100%">
              <Loader color="blue" />
            </Center>
          </Container>
        ) : (
          <InternalReviewCreatorSetEntryResults
            creatorEntriesWithDetails={creatorSetPageDetails}
            creatorSet={creatorSet}
            availalbleCreatorSets={availalbleCreatorSets}
            user={user}
            internalReviewProps={internalReviewProps}
            creatorProps={creatorProps}
            isInternalReview={
              [
                InternalReviewFilterState.INTERNAL_REVIEW_NEEDS_REVIEW,
                InternalReviewFilterState.INTERNAl_REVIEW_REJECTED,
              ].includes(internalReviewFilterState) && internlReviewChangeAccess
            }
            isBrandReview={BRAND_REVIEW_FILTER_STATES.includes(internalReviewFilterState)}
          />
        )}
        <EntryPagniator />
      </>
    </Stack>
  );
};

export const BrandCreatorSetSelector = ({
  brandCreatorSetData,
  brandId,
  creatorSetId,
  opsReviewer,
  internalReviewFilter,
  brandReviewFilter,
  setBrandId,
  setCreatorSetId,
  setOpsReviewer,
  setCreatorSetEntryItems,
  setInternalReviewFilter,
  setBrandReviewFilter,
}: {
  brandCreatorSetData: Record<number, BrandCreatorSetDict>;
  brandId: number;
  creatorSetId: number;
  opsReviewer: string;
  internalReviewFilter: InternalReviewFilterState;
  brandReviewFilter: number;
  setBrandId: (brandId: number) => void;
  setCreatorSetId: (creatorSetId: number) => void;
  setOpsReviewer: (enqueuer: string) => void;
  setCreatorSetEntryItems: (creatorSetEntryItems: CreatorSetEntryItem[]) => void;
  setInternalReviewFilter: (internalReviewFilter: InternalReviewFilterState) => void;
  setBrandReviewFilter: (brandReviewFilter: number) => void;
}) => {
  const [internalAcceptSelected, setInternalAcceptSelected] = useState(
    internalReviewFilter === InternalReviewFilterState.INTERNAL_REVIEW_ACCEPTED,
  );
  const isValidBrandCreatorSetData =
    brandCreatorSetData !== null &&
    brandCreatorSetData !== undefined &&
    Object.keys(brandCreatorSetData).length > 0;

  return (
    <div>
      {isValidBrandCreatorSetData ? (
        <Group>
          <Select
            id="brand_selector"
            onChange={(v) => {
              setBrandId(Number(v));
              setCreatorSetId(-1);
              setOpsReviewer("all");
              setCreatorSetEntryItems([]);
              setInternalReviewFilter(InternalReviewFilterState.INTERNAL_REVIEW_NEEDS_REVIEW);
              setBrandReviewFilter(-1);
            }}
            label="Brand Name"
            value={String(brandId)}
            data={Object.keys(brandCreatorSetData).map((key) => {
              const brandData = brandCreatorSetData[Number(key)];
              return {
                label: brandData.brand_name,
                value: String(brandData.brand_id),
              };
            })}
            searchable
          />
          <Select
            id="creator_set_selector"
            onChange={(v) => {
              setCreatorSetId(Number(v));
              setOpsReviewer("all");
              setCreatorSetEntryItems([]);
              setInternalReviewFilter(InternalReviewFilterState.INTERNAL_REVIEW_NEEDS_REVIEW);
              setBrandReviewFilter(-1);
            }}
            label="Creator Set"
            value={creatorSetId !== null ? String(creatorSetId) : ""}
            data={
              brandId != null && brandId !== -1 && brandId !== 0
                ? [
                    {
                      label: "Please select a creator set",
                      value: "-1",
                    },
                    ...brandCreatorSetData[brandId].creatorsets.map((creatorSet) => ({
                      label: creatorSet.name,
                      value: String(creatorSet.id),
                    })),
                  ]
                : []
            }
            searchable
          />
          <Select
            id="ops_reviewer_selector"
            onChange={(v) => {
              setOpsReviewer(v);
              setCreatorSetEntryItems([]);
            }}
            label="Ops Reviewer"
            value={String(opsReviewer)}
            data={
              brandId !== null && creatorSetId !== null && creatorSetId !== -1
                ? brandCreatorSetData[brandId].creatorset_enqueuers[Number(creatorSetId)].map(
                    (reviewer) => ({
                      label: reviewer,
                      value: reviewer,
                    }),
                  )
                : []
            }
            searchable
          />
          <Select
            id="internal_review_filter_selector"
            onChange={(v) => {
              if (v === String(InternalReviewFilterState.INTERNAL_REVIEW_ACCEPTED)) {
                setInternalAcceptSelected(true);
                setBrandReviewFilter(InternalReviewFilterState.INTERNAL_REVIEW_ACCEPTED);
              } else {
                setInternalAcceptSelected(false);
                setBrandReviewFilter(-1);
              }
              setInternalReviewFilter(Number(v));
              setCreatorSetEntryItems([]);
            }}
            label="Internal Review Selector"
            value={String(internalReviewFilter)}
            data={[
              {
                label: "Needs Review",
                value: String(InternalReviewFilterState.INTERNAL_REVIEW_NEEDS_REVIEW),
              },
              {
                label: "Rejected",
                value: String(InternalReviewFilterState.INTERNAl_REVIEW_REJECTED),
              },
              {
                label: "Accepted",
                value: String(InternalReviewFilterState.INTERNAL_REVIEW_ACCEPTED),
              },
            ]}
          />
          <Select
            id="brand_review_selector"
            onChange={(v) => {
              setBrandReviewFilter(Number(v));
              setCreatorSetEntryItems([]);
            }}
            disabled={!internalAcceptSelected}
            label="Brand Review Selector"
            value={String(brandReviewFilter)}
            data={[
              {
                label: "Brand Accepted",
                value: String(InternalReviewFilterState.BRAND_ACCEPTED),
              },
              {
                label: "Brand Rejected",
                value: String(InternalReviewFilterState.BRAND_REJECTED),
              },
              {
                label: "Brand Needs Review",
                value: String(InternalReviewFilterState.BRAND_NEEDS_REVIEW),
              },
              {
                label: "All",
                value: String(InternalReviewFilterState.INTERNAL_REVIEW_ACCEPTED),
              },
            ]}
          />
        </Group>
      ) : null}
    </div>
  );
};

const InternalReviewButton = ({
  user,
  brandId,
  creatorSetId,
  opsReviewer,
  brandCreatorSetData,
  internalReviewFilterState,
  setCreatorSetEntryItems,
  setInternalReviewProps,
  setCreatorProps,
}: {
  user: User;
  brandId: number;
  creatorSetId: number;
  opsReviewer: string;
  brandCreatorSetData: Record<number, BrandCreatorSetDict>;
  internalReviewFilterState: InternalReviewFilterState;
  setCreatorSetEntryItems: (creatorSetEntryItems: CreatorSetEntryItem[]) => void;
  setInternalReviewProps: (
    internalReviewProps: Record<number, CreatorSetInternalReviewProps>,
  ) => void;
  setCreatorProps: (creatorProps: Record<number, CreatorSetCreatorProps>) => void;
}) => {
  const [searchedCreatorSet, setSearchedCreatorSet] = useState("");
  const [searchedOpsReviewer, setSearchedOpsReviewer] = useState("");
  const [numCreatorsFound, setNumCreatorsFound] = useState(-1);

  return (
    <Group>
      <Button
        variant="filled"
        onClick={() => {
          const abortController = new AbortController();
          fetchCreatorSetInternalReviewEntryItems(
            user,
            creatorSetId,
            opsReviewer,
            internalReviewFilterState,
            abortController,
          ).then((response) => {
            if (response != null && response.success === true) {
              setCreatorSetEntryItems(response.creator_set_entries);
              setInternalReviewProps(response.creator_set_internal_review_props);
              setCreatorProps(response.creator_props);
            }
            setNumCreatorsFound(response.creator_set_entries.length);
            setSearchedCreatorSet(
              brandCreatorSetData[brandId].creatorsets.find(
                (creatorset) => creatorset.id === creatorSetId,
              ).name,
            );
            setSearchedOpsReviewer(opsReviewer);
          });
        }}>
        Get Internal Review Creators
      </Button>
      {numCreatorsFound > -1 ? (
        <Text>
          Creator Set {searchedCreatorSet}, reviewer: {searchedOpsReviewer} has {numCreatorsFound}{" "}
          creators
        </Text>
      ) : null}
    </Group>
  );
};

export const CreatorSetInternalCreatorReviewView = () => {
  const requestUser: User = useOutletContext<AuthContext>()?.user;
  const [brandCreatorSetData, setBrandCreatorSetData] =
    useState<Record<number, BrandCreatorSetDict>>(null);
  const [loadedBrandCreatorSetData, setLoadedBrandCreatorSetData] = useState(false);
  const [brandId, setBrandId] = useState(-1);
  const [creatorSetId, setCreatorSetId] = useState(-1);
  const [opsReviewer, setOpsReviewer] = useState("all");
  const [internalReviewFilter, setInternalReviewFilter] = useState(
    InternalReviewFilterState.INTERNAL_REVIEW_NEEDS_REVIEW,
  );
  const [internalBrandReviewFilter, setInternalBrandReviewFilterState] = useState(-1);
  const [internalReviewFilterState, setInternalReviewFilterState] = useState(
    InternalReviewFilterState.INTERNAL_REVIEW_NEEDS_REVIEW,
  );

  // Fetched everything
  const [creatorSetEntryItems, setCreatorSetEntryItems] = useState<CreatorSetEntryItem[]>([]);
  const [internalReviewProps, setInternalReviewProps] = useState<
    Record<number, CreatorSetInternalReviewProps>
  >({});
  const [creatorProps, setCreatorProps] = useState<Record<number, CreatorSetCreatorProps>>({});
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("brandId") !== null) {
      setBrandId(Number(searchParams.get("brandId")));
    }
    if (searchParams.get("creatorSetId") !== null) {
      setCreatorSetId(Number(searchParams.get("creatorSetId")));
    }
    if (searchParams.get("opsReviewer") !== null) {
      setOpsReviewer(searchParams.get("opsReviewer"));
    }
    if (searchParams.get("internalReviewFilter") !== null) {
      setInternalReviewFilter(Number(searchParams.get("internalReviewFilter")));
    }
    if (searchParams.get("internalBrandReviewFilter") !== null) {
      setInternalBrandReviewFilterState(Number(searchParams.get("internalBrandReviewFilter")));
    }
  }, []);

  useEffect(() => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    if (brandId !== null) {
      updatedSearchParams.set("brandId", String(brandId));
    }
    if (creatorSetId !== null) {
      updatedSearchParams.set("creatorSetId", String(creatorSetId));
    }
    if (opsReviewer !== null) {
      updatedSearchParams.set("opsReviewer", opsReviewer);
    }
    if (internalReviewFilter !== null) {
      updatedSearchParams.set("internalReviewFilter", String(internalReviewFilter));
    }
    if (internalBrandReviewFilter !== null) {
      updatedSearchParams.set("internalBrandReviewFilter", String(internalBrandReviewFilter));
    }
    setSearchParams(updatedSearchParams);
  }, [brandId, creatorSetId, opsReviewer, internalReviewFilter, internalBrandReviewFilter]);

  useEffect(() => {
    if (BRAND_REVIEW_FILTER_STATES.includes(internalBrandReviewFilter)) {
      setInternalReviewFilterState(internalBrandReviewFilter);
    } else if (internalReviewFilter !== null) {
      setInternalReviewFilterState(internalReviewFilter);
    }
  }, [internalReviewFilter, internalBrandReviewFilter]);

  // Grab initial brand creatorset data.
  useEffect(() => {
    const abortController = new AbortController();
    fetchBrandCreatorSetData(abortController).then((data) => {
      setBrandCreatorSetData(data.brand_creatorsets);
      setLoadedBrandCreatorSetData(true);
    });
    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <LayoutBase>
      {!loadedBrandCreatorSetData ? (
        "Waiting for brand creatorset data"
      ) : (
        <Stack h={300} justify="flex-start">
          <TopOfEntries />
          <BrandCreatorSetSelector
            brandCreatorSetData={brandCreatorSetData}
            brandId={brandId}
            creatorSetId={creatorSetId}
            opsReviewer={opsReviewer}
            internalReviewFilter={internalReviewFilter}
            brandReviewFilter={internalBrandReviewFilter}
            setBrandId={setBrandId}
            setCreatorSetId={setCreatorSetId}
            setOpsReviewer={setOpsReviewer}
            setCreatorSetEntryItems={setCreatorSetEntryItems}
            setInternalReviewFilter={setInternalReviewFilter}
            setBrandReviewFilter={setInternalBrandReviewFilterState}
          />
          <InternalReviewButton
            user={requestUser}
            brandId={brandId}
            creatorSetId={creatorSetId}
            opsReviewer={opsReviewer}
            brandCreatorSetData={brandCreatorSetData}
            internalReviewFilterState={internalReviewFilterState}
            setCreatorSetEntryItems={setCreatorSetEntryItems}
            setInternalReviewProps={setInternalReviewProps}
            setCreatorProps={setCreatorProps}
          />
          {brandId !== null && creatorSetId !== null && creatorSetEntryItems.length > 0 ? (
            <CreatorSetInternalReviewView
              user={requestUser}
              creatorSetEntryItems={creatorSetEntryItems}
              creatorSet={brandCreatorSetData[brandId].creatorsets.find(
                (creatorset) => creatorset.id === creatorSetId,
              )}
              availalbleCreatorSets={brandCreatorSetData[brandId].creatorsets}
              internalReviewProps={internalReviewProps}
              creatorProps={creatorProps}
              internalReviewFilterState={internalReviewFilterState}
            />
          ) : null}
        </Stack>
      )}
    </LayoutBase>
  );
};
