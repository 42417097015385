import { API_URL } from "configs/Configs";
import { createRequestWithFirebaseToken, handleResult } from "utils/ApiUtils";

import React, { useEffect, useState } from "react";

import {
  ActionIcon,
  Button,
  Checkbox,
  Container,
  Flex,
  Grid,
  Group,
  Input,
  LoadingOverlay,
  Modal,
  Select,
  Stack,
  Table,
  Text,
  Tooltip,
} from "@mantine/core";

import CreatorAvatar from "components/contracts/common/CreatorAvatar";
import SocialHandleButtons from "components/contracts/dashboard/SocialHandleButtons";
import Deliverable from "components/contracts/models/Deliverable";
import { truncateWithEllipsis } from "components/contracts/dashboard/Utils";
import { SEMI_BOLD_FONT_WEIGHT } from "components/contracts/dashboard/Constants";
import { fromISODateString, toShortDateString } from "utils/DateUtils";
import ContractAmount from "components/contracts/dashboard/ContractAmount";
import SortableTable, { SortableTableColumnProps } from "components/SortableTable";
import Spacer from "components/Spacer";
import { RepeatContractValue } from "components/contracts/common/Common";
import { IconArrowForward } from "@tabler/icons-react";
import { notifications } from "@mantine/notifications";
import { useDisclosure } from "@mantine/hooks";
import _ from "lodash";

type RepeatOpportunity = {
  contractCompletionDate: string;
  deliverables: Deliverable[];
  contractId: string;
  creatorId: number;
  campaignId: number;
  opportunityId: number;
  username: string;
  views: number;
  clicks: number;
  cpm: number;
  amount: number;
  bonusAmount: number;
  avatarUrl: string;
  repeatState: RepeatContractValue;
  numContractsAllTime: number;
  numContractsPerCampaign: number;
};

type RepeatOpportunityResponse = {
  success: boolean;
  opportunities: RepeatOpportunity[];
};

const REPEATDEAL_COLOR = {
  [RepeatContractValue.MARKED_FOR_REPEAT]: "green",
  [RepeatContractValue.ELIGIBLE_FOR_REPEAT]: "black",
  [RepeatContractValue.REPEAT_INFLIGHT]: "yellow",
  [RepeatContractValue.NOT_ELIGIBLE]: "gray",
};

const REPEATDEAL_TOOLTIP = {
  [RepeatContractValue.MARKED_FOR_REPEAT]: "Unmark for repeat",
  [RepeatContractValue.ELIGIBLE_FOR_REPEAT]: "Send repeat",
  [RepeatContractValue.REPEAT_INFLIGHT]: "Repeat is inflight",
  [RepeatContractValue.NOT_ELIGIBLE]: "Ineligible for repeat",
};

const REPEATDEAL_STATUS = {
  [RepeatContractValue.MARKED_FOR_REPEAT]: "Queued for Repeat",
  [RepeatContractValue.ELIGIBLE_FOR_REPEAT]: "Repeat eligible",
  [RepeatContractValue.REPEAT_INFLIGHT]: "Repeat inflight",
  [RepeatContractValue.NOT_ELIGIBLE]: "Ineligible for repeat",
};

const fetchRepeatOpportunities = async (campaignId: number, date_str: string) => {
  const requestUrl = new URL(`${API_URL}/api/campaigns/get_repeat_opportunities/`);
  requestUrl.searchParams.append("campaignId", campaignId.toString());
  requestUrl.searchParams.append("contractCompletionDate", date_str);
  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "GET",
  });

  const response = await handleResult(request);
  return response;
};

const submitRepeatOpportunity = async (opportunityIds: number[], setRepeat = true) => {
  const requestUrl = new URL(`${API_URL}/api/campaigns/set_repeat_opportunities/`);
  const emailFunnelState = setRepeat ? 41 : 40;
  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      opportunityIds,
      emailFunnelState,
    }),
  });

  const response = await handleResult(request);
  return response;
};

const RepeatStateCols = ({
  opportunityId,
  repeatState,
}: {
  opportunityId: number;
  repeatState: RepeatContractValue;
}) => {
  const [repeatVal, setRepeatVal] = useState(repeatState);

  // Synchronize component state with props
  useEffect(() => {
    setRepeatVal(repeatState);
  }, [repeatState]);

  return (
    <>
      <Table.Td>
        <Text>{REPEATDEAL_STATUS[repeatVal]}</Text>
      </Table.Td>
      <Table.Td>
        <Flex justify="left">
          <Tooltip label={REPEATDEAL_TOOLTIP[repeatVal]}>
            <ActionIcon variant="light" color={REPEATDEAL_COLOR[repeatVal]}>
              <IconArrowForward
                stroke-width="3px"
                size={14}
                onClick={() => {
                  if (repeatVal === RepeatContractValue.ELIGIBLE_FOR_REPEAT) {
                    submitRepeatOpportunity([opportunityId]);
                    setRepeatVal(RepeatContractValue.MARKED_FOR_REPEAT);
                  } else if (repeatVal === RepeatContractValue.MARKED_FOR_REPEAT) {
                    submitRepeatOpportunity([opportunityId], false);
                    setRepeatVal(RepeatContractValue.ELIGIBLE_FOR_REPEAT);
                  }
                }}
              />
            </ActionIcon>
          </Tooltip>
        </Flex>
      </Table.Td>
    </>
  );
};

function getSocialHandles(opportunity: RepeatOpportunity) {
  return opportunity.deliverables
    .map((deliverable: Deliverable) => ({
      platform: deliverable.platform,
      handle: truncateWithEllipsis(deliverable.creatorHandle),
      profileLink: deliverable.profileLink,
    }))
    .filter(
      (value, index, self) =>
        index ===
        self.findIndex(
          (v) =>
            v.platform === value.platform &&
            v.handle === value.handle &&
            v.profileLink === value.profileLink,
        ),
    )
    .sort((a, b) => a.platform.localeCompare(b.platform));
}

const RepeatStateModalCheckbox = ({
  opportunityId,
  markedRepeatOpportunityIds,
  setMarkedRepeatOpportunityIds,
}: {
  opportunityId: number;
  markedRepeatOpportunityIds: number[];
  setMarkedRepeatOpportunityIds: (opportunityIds: number[]) => void;
}) => {
  const [checked, setChecked] = useState(markedRepeatOpportunityIds.includes(opportunityId));

  useEffect(() => {
    setChecked(markedRepeatOpportunityIds.includes(opportunityId));
  }, [markedRepeatOpportunityIds]);

  return (
    <Table.Td>
      <Checkbox
        checked={checked}
        onChange={() => {
          if (checked) {
            setMarkedRepeatOpportunityIds(
              markedRepeatOpportunityIds.filter((id) => id !== opportunityId),
            );
          } else {
            setMarkedRepeatOpportunityIds([...markedRepeatOpportunityIds, opportunityId]);
          }
          setChecked(!checked);
        }}
      />
    </Table.Td>
  );
};

const RepeatOpportunityModalRow = ({
  opportunity,
  markedRepeatOpportunityIds,
  setMarkedRepeatOpportunityIds,
}: {
  opportunity: RepeatOpportunity;
  markedRepeatOpportunityIds: number[];
  setMarkedRepeatOpportunityIds: (opportunityIds: number[]) => void;
}) => {
  return (
    <Table.Tr>
      <Table.Td>
        <CreatorAvatar
          displayName={opportunity.username}
          avatarUrl={opportunity.avatarUrl}
          creatorId={opportunity.creatorId.toString()}
          hasLink
        />
      </Table.Td>
      <Table.Td>
        <Text fw={SEMI_BOLD_FONT_WEIGHT}>
          {toShortDateString(fromISODateString(opportunity.contractCompletionDate))}
        </Text>
      </Table.Td>
      <Table.Td>
        <Text>{opportunity.views}</Text>
      </Table.Td>
      <Table.Td>
        <ContractAmount amountInMinorUnits={opportunity.amount} />
      </Table.Td>
      <Table.Td>
        <Text>{opportunity.cpm.toFixed(2)}</Text>
      </Table.Td>
      <Table.Td>
        <Text>{opportunity.numContractsAllTime}</Text>
      </Table.Td>
      <Table.Td>
        <Text>{opportunity.numContractsPerCampaign}</Text>
      </Table.Td>
      <RepeatStateModalCheckbox
        opportunityId={opportunity.opportunityId}
        markedRepeatOpportunityIds={markedRepeatOpportunityIds}
        setMarkedRepeatOpportunityIds={setMarkedRepeatOpportunityIds}
      />
    </Table.Tr>
  );
};

const RepeatOpportunityRow = ({ opportunity }: { opportunity: RepeatOpportunity }) => {
  const socialHandles = getSocialHandles(opportunity);
  return (
    <Table.Tr>
      <Table.Td>
        <CreatorAvatar
          displayName={opportunity.username}
          avatarUrl={opportunity.avatarUrl}
          creatorId={opportunity.creatorId.toString()}
          hasLink
        />
      </Table.Td>
      <Table.Td>
        <Text fw={SEMI_BOLD_FONT_WEIGHT}>
          {toShortDateString(fromISODateString(opportunity.contractCompletionDate))}
        </Text>
      </Table.Td>
      <Table.Td>
        <Text>{opportunity.views}</Text>
      </Table.Td>
      <Table.Td>
        <ContractAmount amountInMinorUnits={opportunity.amount} />
      </Table.Td>
      <Table.Td>
        <Text>{opportunity.cpm.toFixed(2)}</Text>
      </Table.Td>
      <Table.Td>
        <Text>{opportunity.numContractsAllTime}</Text>
      </Table.Td>
      <Table.Td>
        <Text>{opportunity.numContractsPerCampaign}</Text>
      </Table.Td>
      <Table.Td>
        <SocialHandleButtons socialHandles={socialHandles} />
      </Table.Td>
      <RepeatStateCols
        opportunityId={opportunity.opportunityId}
        repeatState={opportunity.repeatState}
      />
    </Table.Tr>
  );
};

type RepeatOpportunityFields =
  | "username"
  | "contractCompletionDate"
  | "views"
  | "amount"
  | "cpm"
  | "numContractsAllTime"
  | "numContractsPerCampaign";

function parsePositiveNumber(str: string): number | null {
  // Regular expression for matching positive integers or decimal numbers
  const regex = /^\d+(\.\d+)?$/;
  if (regex.test(str)) {
    // If it's an integer or a valid decimal number, parse it
    return Number(str);
  }
  return null;
}

const REPEAT_OPP_COLS: SortableTableColumnProps<RepeatOpportunity, RepeatOpportunityFields>[] = [
  { label: "Creator", field: "username", alignLeft: true },
  { label: "Contract Completion Date", field: "contractCompletionDate", alignLeft: true },
  { label: "Views", field: "views", alignLeft: true },
  { label: "Amount", field: "amount", alignLeft: true },
  { label: "CPM", field: "cpm", alignLeft: true },
  { label: "# complete - all time", field: "numContractsAllTime", alignLeft: true },
  { label: "# complete - campaign", field: "numContractsPerCampaign", alignLeft: true },
];

const RepeatOpportunityModal = ({
  repeatOpportunities,
  cpmThreshold,
  setRepeatOpportunities,
  close,
}: {
  repeatOpportunities: RepeatOpportunity[];
  cpmThreshold: number;
  setRepeatOpportunities: (opportunities: RepeatOpportunity[]) => void;
  close: () => void;
}) => {
  const [repeatOpportunitySubmitLoading, setRepeatOpportunitySubmitLoading] = useState(false);
  const cpmThresholdOpportunityIds = repeatOpportunities
    .filter((opportunity) => opportunity.repeatState === RepeatContractValue.ELIGIBLE_FOR_REPEAT)
    .filter((opportunity) => opportunity.cpm < cpmThreshold)
    .map((opportunity) => opportunity.opportunityId);

  const eligibleRepeatOpportunities = repeatOpportunities.filter((opportunity) =>
    cpmThresholdOpportunityIds.includes(opportunity.opportunityId),
  );

  const [markedRepeatOpportunityIds, setMarkedRepeatOpportunityIds] = useState<number[]>(
    cpmThresholdOpportunityIds,
  );

  if (cpmThresholdOpportunityIds.length === 0) {
    return <Text>No repeat opportunities found under CPM</Text>;
  }

  return (
    <Container fluid>
      <Stack>
        <SortableTable<RepeatOpportunity, RepeatOpportunityFields>
          data={eligibleRepeatOpportunities}
          renderRow={(opportunity) => (
            <RepeatOpportunityModalRow
              key={opportunity.opportunityId}
              opportunity={opportunity}
              markedRepeatOpportunityIds={markedRepeatOpportunityIds}
              setMarkedRepeatOpportunityIds={setMarkedRepeatOpportunityIds}
            />
          )}
          sortableCols={REPEAT_OPP_COLS}
          regCols={[""]}
        />
      </Stack>
      <Button
        loading={repeatOpportunitySubmitLoading}
        onClick={() => {
          setRepeatOpportunitySubmitLoading(true);
          submitRepeatOpportunity(markedRepeatOpportunityIds).then((response) => {
            setRepeatOpportunitySubmitLoading(false);
            setRepeatOpportunities(
              repeatOpportunities.map((opportunity) => {
                if (markedRepeatOpportunityIds.includes(opportunity.opportunityId)) {
                  return {
                    ...opportunity,
                    repeatState: RepeatContractValue.MARKED_FOR_REPEAT,
                  };
                }
                return opportunity;
              }),
            );
            close();
            notifications.show({
              title: "Repeat opportunities marked",
              message: `${markedRepeatOpportunityIds.length} Repeat opportunities have been marked`,
              autoClose: 5000,
            });
          });
        }}>
        Set repeat deals
      </Button>
    </Container>
  );
};

export default function RepeatDeals({ campaignId }: { campaignId: number }) {
  const [isLoading, setIsLoading] = useState(false);
  const [repeatOpportunities, setRepeatOpportunities] = useState<RepeatOpportunity[]>([]);
  const [contractDate, setContractDate] = useState<string>(null);
  const [cpmThreshold, setCpmThreshold] = useState<number>(null);
  const [opened, { open, close }] = useDisclosure(false);

  useEffect(() => {
    if (contractDate === null) {
      return () => {};
    }
    setIsLoading(true);
    const abortController = new AbortController();

    fetchRepeatOpportunities(campaignId, contractDate)
      .then((response: RepeatOpportunityResponse) => {
        if (response.success) {
          setRepeatOpportunities(response.opportunities);
        }
      })
      .finally(() => setIsLoading(false));
    return () => {
      abortController.abort();
    };
  }, [campaignId, contractDate]);

  const oneMonthBack = new Date();
  const threeMonthBack = new Date();
  oneMonthBack.setMonth(oneMonthBack.getMonth() - 1);
  threeMonthBack.setMonth(threeMonthBack.getMonth() - 3);
  const oneMonthBackStr = oneMonthBack.toISOString().split("T")[0];
  const threeMonthBackStr = threeMonthBack.toISOString().split("T")[0];

  return (
    <>
      <Modal size="75%" opened={opened} onClose={close} title="Repeat Deals">
        <RepeatOpportunityModal
          repeatOpportunities={repeatOpportunities}
          cpmThreshold={cpmThreshold}
          setRepeatOpportunities={setRepeatOpportunities}
          close={close}
        />
      </Modal>
      <Container size="s">
        <Text fw={400}>Contracts from:</Text>
        <Select
          data={[
            { label: "One month", value: oneMonthBackStr },
            { label: "Three months", value: threeMonthBackStr },
            { label: "All Time", value: "2023-01-01" },
          ]}
          onChange={setContractDate}
        />
        <Spacer height={8} />
        <Text fw={400}>CPM threshold for repeat opportunities</Text>
        <Group mb="sm" gap="xs" grow>
          <Grid>
            <Grid.Col span={9}>
              <Input
                placeholder="CPM threshold"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const numStr = event.currentTarget.value;
                  const numVal = parsePositiveNumber(numStr);
                  if (!Number.isNaN(numVal) && numVal !== null) {
                    setCpmThreshold(numVal);
                  } else {
                    setCpmThreshold(null);
                  }
                }}
              />
            </Grid.Col>
            <Grid.Col span={3}>
              <Button
                onClick={() => {
                  // Check that the cpm threshold is a value number
                  if (cpmThreshold === null) {
                    notifications.show({
                      title: "Invalid CPM threshold",
                      message: "Please enter a valid number for the CPM threshold",
                      color: "red",
                      autoClose: 5000,
                    });
                    return;
                  }
                  open();
                }}>
                Mark repeat opportunities
              </Button>
            </Grid.Col>
          </Grid>
        </Group>
        <Spacer height={10} />
      </Container>

      <LoadingOverlay visible={isLoading} />
      {repeatOpportunities && repeatOpportunities.length > 0 && (
        <Container fluid>
          <Stack>
            <SortableTable<RepeatOpportunity, RepeatOpportunityFields>
              data={repeatOpportunities}
              renderRow={(opportunity) => (
                <RepeatOpportunityRow key={opportunity.opportunityId} opportunity={opportunity} />
              )}
              sortableCols={REPEAT_OPP_COLS}
              regCols={["Social Handles", "Repeat Status", ""]}
            />
          </Stack>
        </Container>
      )}
    </>
  );
}
