import React from "react";

import { Stack } from "@mantine/core";

import { SocialHandle } from "components/contracts/dashboard/Utils";
import SocialHandleButton from "components/contracts/common/SocialHandleButton";

export default function SocialHandleButtons({ socialHandles }: { socialHandles: SocialHandle[] }) {
  const socialHandleButtons = socialHandles.map((socialHandleData) => (
    <SocialHandleButton
      key={socialHandleData.platform + socialHandleData.handle}
      platform={socialHandleData.platform}
      handle={socialHandleData.handle}
      profileLink={socialHandleData.profileLink}
    />
  ));

  return (
    <Stack gap="xs" align="flex-start">
      {socialHandleButtons}
    </Stack>
  );
}
