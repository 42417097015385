import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useOutletContext } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { User } from "firebase/auth";
import { AuthContext } from "auth/AuthContext";

import { auth } from "auth/firebaseAuthHelpers";
import { isBrandUser } from "utils/UserUtils";

enum IsBrandState {
  LOADING = 1,
  IS_BRAND = 2,
  IS_NOT_BRAND = 3,
}

const checkUser = async (user: User, setBrandState: (isBrandState: IsBrandState) => void) => {
  const isBrand = await isBrandUser(user);
  if (isBrand) {
    setBrandState(IsBrandState.IS_BRAND);
  } else {
    setBrandState(IsBrandState.IS_NOT_BRAND);
  }
};

const BrandLayout = () => {
  const [user, loading] = useAuthState(auth);
  const [isBrandState, setBrandState] = useState(IsBrandState.LOADING);

  useEffect(() => {
    if (loading) return;
    if (user) {
      checkUser(user, setBrandState);
    } else {
      setBrandState(IsBrandState.IS_NOT_BRAND);
    }
  }, [loading]);

  if (isBrandState === IsBrandState.LOADING) return <>Loading</>;
  if (isBrandState === IsBrandState.IS_NOT_BRAND) return <Navigate to="/" />;
  return <Outlet context={{ user }} />;
};

export function getBrandUser() {
  return useOutletContext<AuthContext>()?.user;
}

export default BrandLayout;
