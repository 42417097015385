import React, { useEffect, useState } from "react";

import { toLongDateString, toLongTimeDateString } from "utils/DateUtils";

import {
  Anchor,
  Box,
  Group,
  Stack,
  Text,
  TextInput,
  Timeline,
  Button,
  CopyButton,
  Tooltip,
  ActionIcon,
  Flex,
  Grid,
  NumberInput,
  Alert,
  Textarea,
  Blockquote,
  rem,
  Paper,
  ThemeIcon,
} from "@mantine/core";

import {
  ProductAccessInfoNeededFromCreatorType,
  ProductAccessInfoToProvideToCreatorType,
  ProductAccessStatus,
  SUPPORTED_FORMATS_TO_DELIVERABLE_STRINGS,
} from "components/contracts/common/Common";

import {
  SupportedFormat,
  SupportedPlatform,
  SUPPORTED_PLATFORMS_TO_DISPLAY_NAMES,
} from "models/Common";

import {
  IconCheck,
  IconCircleCheck,
  IconCircleX,
  IconClock,
  IconCopy,
  IconFileDescription,
  IconGift,
  IconInfoCircle,
  IconLink,
  IconPackage,
  IconTicket,
  IconVideo,
  IconVideoPlus,
} from "@tabler/icons-react";

import Spacer from "components/Spacer";
import { invalidEmail } from "utils/ValidationUtils";
import Placeholder from "@tiptap/extension-placeholder";

export default function ProductAccessCardTimeline({
  brandName,
  productAccessStatus,
  productAccessDueDate,
  productAccessDescription,
  productAccessInfoNeededFromCreator,
  productAccessOtherInfoNeededFromCreator,
  productAccessInfoToProvideToCreator,
  productAccessOtherInfoToProvideToCreator,
  productAccessBrandDisputeReason,
  productAccessCreatorInput,
  setProductAccessCreatorInput,
  productAccessBrandOutput,
  handleSaveCreatorProductAccessInfo,
  showAdminOptions,
}: {
  brandName: string;
  productAccessStatus: ProductAccessStatus;
  productAccessDueDate: Date;
  productAccessDescription: string;
  productAccessInfoNeededFromCreator: ProductAccessInfoNeededFromCreatorType;
  productAccessOtherInfoNeededFromCreator: string;
  productAccessInfoToProvideToCreator: ProductAccessInfoToProvideToCreatorType;
  productAccessOtherInfoToProvideToCreator: string;
  productAccessBrandDisputeReason: string;
  productAccessCreatorInput: string;
  setProductAccessCreatorInput: (productAccessInput: string) => void;
  productAccessBrandOutput: string;
  handleSaveCreatorProductAccessInfo: (productAccessInput: string | object) => void;
  showAdminOptions: boolean;
}) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [streetAddress2, setStreetAddress2] = useState("");

  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState<number>(null);

  const isCompleted = productAccessStatus === ProductAccessStatus.COMPLETED;
  const isDisputed = productAccessStatus === ProductAccessStatus.CREATOR_RESUBMIT_REQUIRED;

  const isEmailInput =
    productAccessInfoNeededFromCreator ===
    ProductAccessInfoNeededFromCreatorType.ACCOUNT_EMAIL_ADDRESS;
  const isUsernameInput =
    productAccessInfoNeededFromCreator === ProductAccessInfoNeededFromCreatorType.ACCOUNT_USERNAME;
  const isAddressInput =
    productAccessInfoNeededFromCreator === ProductAccessInfoNeededFromCreatorType.SHIPPING_ADDRESS;
  const isNoneInput =
    productAccessInfoNeededFromCreator === ProductAccessInfoNeededFromCreatorType.NONE;
  const isCustomInput =
    productAccessInfoNeededFromCreator === ProductAccessInfoNeededFromCreatorType.OTHER;

  function newlineToBr(text: string) {
    const textArray = text.split("\n");
    return (
      <>
        {textArray.map((line: string, index: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <Text key={`${index}`}>
            {line}
            <br />
          </Text>
        ))}
      </>
    );
  }

  // this is for isEmailInput
  const displayTexts = {
    header: `${brandName} requires additional information to provide you with product access.`,
    placeholder: "Enter your email address",
    label: `Email used to sign up for ${productAccessDescription}:`,
    submitText: "Submit Email",
  };
  if (isUsernameInput) {
    displayTexts.placeholder = "Enter your account username";
    displayTexts.label = `Username used to sign up for ${productAccessDescription}:`;
    displayTexts.submitText = "Submit Username";
  } else if (isAddressInput) {
    displayTexts.header = `${brandName} requires additional information to ship your product.`;
    displayTexts.placeholder = "Enter your full name and shipping address";
    displayTexts.label = `Your full name and shipping address:`;
    displayTexts.submitText = "Submit Shipping Address";
  } else if (isCustomInput) {
    displayTexts.placeholder = "Enter your information";
    displayTexts.label = `${productAccessOtherInfoNeededFromCreator}`;
    displayTexts.submitText = "Submit Information";
  } else if (isNoneInput) {
    displayTexts.header = `${brandName} does not require additional information. You will receive an update here once
    you have been provided with product access.`;
    displayTexts.placeholder = "";
    displayTexts.label = "";
    displayTexts.submitText = "";
  }
  const isStep1Enabled = [
    ProductAccessStatus.CREATOR_ACTION_REQUIRED,
    ProductAccessStatus.CREATOR_RESUBMIT_REQUIRED,
  ].includes(productAccessStatus);

  const activeIndex = isNoneInput || isStep1Enabled ? 0 : 1;

  let infoColor = "gray";
  let completeColor = "gray";
  if (isNoneInput) {
    completeColor = "yellow";
  }
  if (isCompleted) {
    infoColor = "teal";
    completeColor = "teal";
  }
  if (isStep1Enabled) {
    infoColor = "yellow";
  }
  if (productAccessStatus === ProductAccessStatus.BRAND_ACTION_REQUIRED) {
    infoColor = "teal";
    completeColor = "yellow";
  }

  useEffect(() => {
    if (isAddressInput && typeof productAccessCreatorInput === "object") {
      const address = productAccessCreatorInput as {
        firstName: string;
        lastName: string;
        streetAddress: string;
        streetAddress2: string;
        city: string;
        state: string;
        zipCode: number;
      };
      if (address) {
        setFirstName(address.firstName);
        setLastName(address.lastName);
        setStreetAddress(address.streetAddress);
        setStreetAddress2(address.streetAddress2);
        setCity(address.city);
        setState(address.state);
        setZipCode(address.zipCode);
      }
    }
  }, []);

  const getTrackingInfoComp = () => {
    if (
      productAccessInfoToProvideToCreator ===
        ProductAccessInfoToProvideToCreatorType.TRACKING_CODE &&
      typeof productAccessBrandOutput === "object"
    ) {
      const trackingInfo = productAccessBrandOutput as {
        shippingCarrier: string;
        code: string;
        shippingUrl: string;
      };
      return (
        <Stack gap="sm">
          <Text size="md">
            {brandName} has shipped you the product <b>{productAccessDescription}</b> using{" "}
            <b>{trackingInfo.shippingCarrier}</b> with the shipping address you provided.
          </Text>
          <Group justify="left">
            <Stack gap={2}>
              <Text size="sm">Tracking Number:</Text>
              <Paper px="xs" py={4} shadow="xs" withBorder>
                <Flex gap="xs" align="center">
                  <ThemeIcon variant="subtle" color="gray">
                    <IconPackage size="1.2rem" />
                  </ThemeIcon>
                  <Text fw="500" size="sm">
                    {trackingInfo.code}
                  </Text>

                  <CopyButton value={trackingInfo.code} timeout={1000}>
                    {({ copied, copy }) => (
                      <Button
                        w={80}
                        size="compact-xs"
                        onClick={copy}
                        variant="light"
                        color={copied ? "teal" : "blue"}
                        rightSection={
                          copied ? <IconCheck size="1rem" /> : <IconCopy size="1rem" />
                        }>
                        {copied ? "Copied" : "Copy"}
                      </Button>
                    )}
                  </CopyButton>
                </Flex>
              </Paper>
            </Stack>
          </Group>
        </Stack>
      );
    }
    return null;
  };

  const getShippingAddress = () => {
    return {
      firstName,
      lastName,
      streetAddress,
      streetAddress2,
      city,
      state,
      zipCode,
    };
  };

  const isShippingAddressValid = () => {
    return (
      firstName &&
      firstName.length > 0 &&
      lastName &&
      lastName.length > 0 &&
      streetAddress &&
      streetAddress.length > 0 &&
      city &&
      city.length > 0 &&
      state &&
      state.length > 0 &&
      zipCode != null
    );
  };

  let brandOutputComp = null;

  if (isCompleted) {
    switch (productAccessInfoToProvideToCreator) {
      case ProductAccessInfoToProvideToCreatorType.NONE:
        brandOutputComp = (
          <Stack gap="sm">
            <Text size="md">
              {brandName} has confirmed they provided you access to{" "}
              <b>{productAccessDescription}</b> with the info you provided
            </Text>
          </Stack>
        );
        break;
      case ProductAccessInfoToProvideToCreatorType.TRACKING_CODE:
        brandOutputComp = getTrackingInfoComp();
        break;
      case ProductAccessInfoToProvideToCreatorType.ACCESS_CODE:
        brandOutputComp = (
          <Stack gap="sx">
            <Text size="md">
              Please use the following code to get access to <b>{productAccessDescription}</b>
            </Text>
            <Group justify="left">
              <Stack gap={2}>
                <Paper px="xs" py={4} shadow="xs" withBorder>
                  <Flex gap="xs" align="center">
                    <ThemeIcon variant="subtle" color="gray">
                      <IconTicket size="1.2rem" />
                    </ThemeIcon>
                    <Text fw="500" size="sm">
                      {productAccessBrandOutput}
                    </Text>

                    <CopyButton value={productAccessBrandOutput} timeout={1000}>
                      {({ copied, copy }) => (
                        <Button
                          w={80}
                          size="compact-xs"
                          onClick={copy}
                          variant="light"
                          color={copied ? "teal" : "blue"}
                          rightSection={
                            copied ? <IconCheck size="1rem" /> : <IconCopy size="1rem" />
                          }>
                          {copied ? "Copied" : "Copy"}
                        </Button>
                      )}
                    </CopyButton>
                  </Flex>
                </Paper>
              </Stack>
            </Group>
          </Stack>
        );
        break;
      case ProductAccessInfoToProvideToCreatorType.OTHER:
        brandOutputComp = (
          <Stack gap="xs">
            <Text size="sm">
              <b>{productAccessOtherInfoToProvideToCreator}</b>
            </Text>
            <Paper px="sm" py="sm" shadow="xs" withBorder>
              <Text size="sm">{newlineToBr(productAccessBrandOutput)}</Text>
            </Paper>
          </Stack>
        );
        break;
      default:
        break;
    }
  }
  return (
    <Stack>
      <Box pb="sm" px="sm">
        <Timeline active={activeIndex} bulletSize={24} color={infoColor} lineWidth={2}>
          {!isNoneInput && (
            <Timeline.Item
              lineVariant={activeIndex ? "solid" : "dashed"}
              bullet={<IconFileDescription size={12} />}
              title="Submit Information">
              <Text c="dimmed" size="sm">
                {displayTexts.header}
              </Text>
              {!isNoneInput && !isAddressInput && (
                <>
                  <TextInput
                    disabled={!isStep1Enabled}
                    placeholder={displayTexts.placeholder}
                    label={displayTexts.label}
                    value={productAccessCreatorInput}
                    onChange={(event) => setProductAccessCreatorInput(event.currentTarget.value)}
                    error={
                      isEmailInput &&
                      productAccessCreatorInput &&
                      productAccessCreatorInput.length > 0 &&
                      invalidEmail(productAccessCreatorInput)
                        ? "Invalid Email Address"
                        : null
                    }
                  />
                  {isDisputed && (
                    <>
                      <Spacer height={16} />
                      <Alert
                        variant="light"
                        color="red"
                        radius="md"
                        title="Please Resubmit Information"
                        icon={<IconInfoCircle />}>
                        <b>
                          {brandName} reported the following issue with trying to provide you with
                          your product:
                        </b>
                        <Blockquote color="gray" fs="italic" p={rem(8)} pl="sm">
                          {productAccessBrandDisputeReason}
                        </Blockquote>
                      </Alert>
                    </>
                  )}
                  <Spacer height={16} />
                  <Group justify="right">
                    <Button
                      disabled={
                        !isStep1Enabled ||
                        (isEmailInput && invalidEmail(productAccessCreatorInput)) ||
                        productAccessCreatorInput.length === 0
                      }
                      onClick={() => handleSaveCreatorProductAccessInfo(productAccessCreatorInput)}
                      color="blue">
                      {displayTexts.submitText}
                    </Button>
                  </Group>
                </>
              )}
              {isAddressInput && (
                <>
                  <Grid gutter="md">
                    <Grid.Col span={6}>
                      <TextInput
                        required
                        disabled={!isStep1Enabled}
                        label="First Name"
                        value={firstName}
                        onChange={(event) => setFirstName(event.currentTarget.value)}
                        placeholder="Enter your first name"
                      />
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <TextInput
                        required
                        disabled={!isStep1Enabled}
                        label="Last Name"
                        value={lastName}
                        onChange={(event) => setLastName(event.currentTarget.value)}
                        placeholder="Enter your last name"
                      />
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <TextInput
                        required
                        disabled={!isStep1Enabled}
                        label="Street Address"
                        value={streetAddress}
                        onChange={(event) => setStreetAddress(event.currentTarget.value)}
                        placeholder="Street address, P.O. box, company name, c/o"
                      />
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <TextInput
                        disabled={!isStep1Enabled}
                        label="Street Address 2"
                        value={streetAddress2}
                        onChange={(event) => setStreetAddress2(event.currentTarget.value)}
                        placeholder="Apartment, suite, unit, building, floor, etc."
                      />
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <TextInput
                        required
                        disabled={!isStep1Enabled}
                        label="City"
                        value={city}
                        onChange={(event) => setCity(event.currentTarget.value)}
                        placeholder="Enter your city"
                      />
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <TextInput
                        required
                        disabled={!isStep1Enabled}
                        label="State"
                        value={state}
                        onChange={(event) => setState(event.currentTarget.value)}
                        placeholder="Enter your state"
                      />
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <NumberInput
                        required
                        disabled={!isStep1Enabled}
                        label="Postal Code"
                        value={zipCode}
                        onChange={(value: number) => {
                          setZipCode(value);
                        }}
                        hideControls
                        placeholder="Enter your postal code"
                      />
                    </Grid.Col>
                  </Grid>
                  {isDisputed && (
                    <>
                      <Spacer height={16} />
                      <Alert
                        variant="light"
                        color="red"
                        radius="md"
                        title="Please Resubmit Information"
                        icon={<IconInfoCircle />}>
                        <b>
                          {brandName} reported the following issue with trying to provide you with
                          your product:
                        </b>
                        <Blockquote color="gray" fs="italic" p={rem(8)} pl="sm">
                          {productAccessBrandDisputeReason}
                        </Blockquote>
                      </Alert>
                    </>
                  )}
                  <Spacer height={16} />
                  <Group justify="right">
                    <Button
                      disabled={!isStep1Enabled || !isShippingAddressValid()}
                      onClick={() => handleSaveCreatorProductAccessInfo(getShippingAddress())}
                      color="blue">
                      {displayTexts.submitText}
                    </Button>
                  </Group>
                </>
              )}
            </Timeline.Item>
          )}

          <Timeline.Item
            title="Receive Product"
            color={completeColor}
            bullet={isCompleted ? <IconCheck size={12} /> : <IconClock size={12} />}>
            {/* <Text size="sm" c="dimmed">
              by {toLongDateString(productAccessDueDate)}
            </Text> */}
            {(productAccessStatus === ProductAccessStatus.BRAND_ACTION_REQUIRED ||
              (isNoneInput && !isCompleted)) && (
              <Text c="dimmed" size="sm">
                {brandName} is working on giving you product access.
              </Text>
            )}
            {isCompleted && (
              <Text c="dimmed" size="sm">
                {brandName} has provided you with product access.
              </Text>
            )}
            {brandOutputComp}
          </Timeline.Item>
        </Timeline>
      </Box>
    </Stack>
  );
}
