import React from "react";
import { Flex } from "gestalt";
import "gestalt/dist/gestalt.css";

import SocialAuthentication from "components/creator/CreatorSocialAuthentication";
import HeaderMessage from "components/HeaderWithSubtitle";
import Spacer from "components/Spacer";

const SettingsSocial = () => (
  <Flex direction="column" alignItems="center" width={600}>
    <HeaderMessage
      header="Authenticate into your creator accounts"
      subtitle="This helps us automatically provide your latest engagement data to brands."
    />
    <Spacer height={100} />
    <SocialAuthentication />
  </Flex>
);

export default SettingsSocial;
