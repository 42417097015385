import { createRequestWithFirebaseToken, handleResult } from "utils/ApiUtils";

import { API_URL } from "configs/Configs";

const GET_TIKTOK_CRAWL_STATUS_URL = `${API_URL}/api/ops/crawling/get_crawl_progress/`;
const UPDATE_CRAWL_PRIORITY_URL = `${API_URL}/api/ops/crawling/update_crawl_priority/`;
const UPDATE_CRAWL_SKIP_URL = `${API_URL}/api/ops/crawling/update_skip_crawl/`;

export interface CrawlStatus {
  crawl_id: string;
  seed: string;
  crawl_type: number;
  priority: number;
  enqueue_ts: string | null;
  start_ts: string | null;
  finish_ts: string | null;
  num_crawls: number;
  num_pages: number;
  recent_failures: number;
  force_skip: boolean;
}

export const getTikTokCrawlStatus = async (
  setTikTokCrawlStatus: (crawlStatus: CrawlStatus[]) => void,
) => {
  const request = await createRequestWithFirebaseToken({
    url: GET_TIKTOK_CRAWL_STATUS_URL,
    method: "GET",
  });
  const response = await handleResult(request);
  if (response?.results && response?.results?.length > 0) {
    setTikTokCrawlStatus(response.results);
  }
};

export const updateCrawlPriority = async (crawlId: string, crawlType: number, priority: number) => {
  const request = await createRequestWithFirebaseToken({
    url: UPDATE_CRAWL_PRIORITY_URL,
    method: "POST",
    body: JSON.stringify({ crawl_id: crawlId, crawl_type: crawlType, priority }),
  });
  const response = await handleResult(request);
  return response;
};

export const updateCrawlSkip = async (crawlId: string, crawlType: number, skip: boolean) => {
  const request = await createRequestWithFirebaseToken({
    url: UPDATE_CRAWL_SKIP_URL,
    method: "POST",
    body: JSON.stringify({ crawl_id: crawlId, crawl_type: crawlType, skip }),
  });
  const response = await handleResult(request);
  return response;
};

export default null;
