import React, { Fragment, useEffect, useState } from "react";

import {
  Alert,
  Badge,
  Box,
  Checkbox,
  Divider,
  Group,
  NumberInput,
  Radio,
  Skeleton,
  Space,
  Stack,
  Text,
  Transition,
} from "@mantine/core";
import { IconCurrencyDollar, IconExclamationCircle, IconInfoCircle } from "@tabler/icons-react";

import { Campaign, UsageRightsPolicy, UsageRightsPolicytoFormatString } from "models/Campaign";
import {
  SUPPORTED_PLATFORMS_TO_USAGE_RIGHTS_DAYS,
  SupportedFormat,
  SupportedPlatform,
  UsageRightsColors,
  UsageRightsDuration,
  UsageRightsDurationMultiplier,
  UsageRightsDurationToDays,
} from "models/Common";
import {
  SUPPORTED_FORMATS_TO_LABELS,
  SUPPORTED_FORMATS_TO_PLATFORMS,
} from "components/contracts/common/Common";

const USAGE_RIGHTS_DAYS_TO_DURATION: Record<number, UsageRightsDuration> = {
  7: UsageRightsDuration.SEVEN_DAYS,
  30: UsageRightsDuration.THIRTY_DAYS,
  60: UsageRightsDuration.SIXTY_DAYS,
  365: UsageRightsDuration.ONE_YEAR,
};

const UsageRightsPricing = ({
  isOps,
  campaign,
  platforms,
  creatorPrice,
  usagePrice,
  setUsagePrice,
  suggestedUsagePrice,
  setSuggestedUsagePrice,
  usageRightsDuration,
  setUsageRightsDuration,
  checkedUsageRights,
  setCheckedUsageRights,
  isUsageRightsUpfront,
}: {
  isOps: boolean;
  campaign: Campaign;
  platforms: Record<SupportedFormat, number>;
  creatorPrice: number;
  usagePrice: number;
  setUsagePrice: (value: number) => void;
  suggestedUsagePrice: number;
  setSuggestedUsagePrice: (value: number) => void;
  usageRightsDuration: UsageRightsDuration;
  setUsageRightsDuration: (value: UsageRightsDuration) => void;
  checkedUsageRights: boolean;
  setCheckedUsageRights: (value: boolean) => void;
  isUsageRightsUpfront?: boolean;
}) => {
  const selectedPlatforms = Object.keys(platforms)
    .filter((format) => {
      return platforms[format as SupportedFormat] !== 0;
    })
    .map((platform) => {
      const platformName = SUPPORTED_FORMATS_TO_PLATFORMS[platform as SupportedFormat];
      const usageDays = SUPPORTED_PLATFORMS_TO_USAGE_RIGHTS_DAYS[platformName as SupportedPlatform];

      return {
        [platform]: {
          platform: SUPPORTED_FORMATS_TO_PLATFORMS[platform as SupportedFormat],
          usageDays,
        },
      };
    });

  const usageRightsOptions = Object.entries(selectedPlatforms).map(([platform, info]) => {
    const platformFormat = Object.keys(info)[0] as SupportedFormat;
    const radioMap = Object.values(info).map((stats) => {
      const usageDaysList = stats.usageDays.map((day) => {
        return (
          <Radio
            p={1}
            key={`${platformFormat}-usageRights-${day}`}
            id={`${platformFormat}-${day.toString()}`}
            label={`${day} days`}
            value={day.toString()}
            disabled={isUsageRightsUpfront}
          />
        );
      });
      return usageDaysList;
    });

    const formatNametoDeliverableString =
      SUPPORTED_FORMATS_TO_LABELS[platformFormat as SupportedFormat];
    return (
      <Fragment key={platformFormat}>
        <Stack key={`usageRights-${platformFormat}`}>
          <Radio.Group
            key={`${platformFormat}-selectedUsageDays`}
            name={`${platformFormat}-selectedUsageDays`}
            label={formatNametoDeliverableString}
            description={
              isUsageRightsUpfront
                ? "Duration of usage rights for this campaign are fixed"
                : "Select the duration of usage rights for this campaign"
            }
            value={UsageRightsDurationToDays[usageRightsDuration].toString()}
            onChange={(value) => {
              setUsageRightsDuration(USAGE_RIGHTS_DAYS_TO_DURATION[Number(value)]);
            }}
            withAsterisk>
            <Group my="xs">{radioMap}</Group>
          </Radio.Group>
        </Stack>
        <Space w="sm" key={`space-${platformFormat}`} />
      </Fragment>
    );
  });

  const usageRightsDayOptions = Object.values(UsageRightsDurationToDays).map((day) => {
    return (
      <Radio
        p={1}
        key={`usageRights-${day}`}
        id={`${day.toString()}`}
        label={`${day} days`}
        value={day.toString()}
        disabled={isUsageRightsUpfront}
      />
    );
  });

  return (
    <Stack>
      <Divider my={2} variant="dotted" label="Usage Rights Pricing" labelPosition="center" />
      <Box>
        {campaign && campaign?.usage_rights_policy === UsageRightsPolicy.UPFRONT && (
          <Stack align="center">
            <Group justify="center" gap={5}>
              <IconExclamationCircle size={20} color="var(--mantine-color-red-filled)" />
              <Text fw={500} size="sm">
                {campaign?.brand_name}
              </Text>
              <Text size="sm" component="div">
                requires usage rights{" "}
                <Badge
                  component="div"
                  color={UsageRightsColors[campaign?.usage_rights_policy]}
                  variant="light">
                  {UsageRightsPolicytoFormatString[campaign?.usage_rights_policy] || "Unknown"}
                </Badge>{" "}
                for{" "}
                <Badge component="div" color="gray" variant="light">
                  {UsageRightsDurationToDays[campaign?.usage_rights_duration] || "Unknown"} days
                </Badge>{" "}
                ! Please select a usage rights price.
              </Text>
            </Group>
          </Stack>
        )}
        {campaign && campaign?.usage_rights_policy !== UsageRightsPolicy.UPFRONT && (
          <Stack align="center">
            <Group justify="center" gap={5}>
              <IconInfoCircle size={20} color="var(--mantine-color-blue-filled)" />
              <Text fw={500} size="sm">
                {campaign?.brand_name}
              </Text>
              <Text size="sm" component="div">
                has the following usage rights policy:{" "}
                <Badge
                  component="div"
                  color={UsageRightsColors[campaign?.usage_rights_policy]}
                  variant="light">
                  {UsageRightsPolicytoFormatString[campaign?.usage_rights_policy] || "Unknown"}
                </Badge>{" "}
                for{" "}
                <Badge component="div" color="gray" variant="light">
                  {UsageRightsDurationToDays[campaign?.usage_rights_duration] || "Unknown"} days
                </Badge>
              </Text>
            </Group>
          </Stack>
        )}
        <Space my="sm" />
        <Checkbox
          label="Usage Rights Included"
          checked={checkedUsageRights}
          onChange={(e) => {
            setCheckedUsageRights(e.currentTarget.checked);
          }}
          value="usage-rights"
          disabled={isUsageRightsUpfront && isOps}
        />
        {checkedUsageRights && (
          <Stack>
            <Stack align="flex-start">
              <Group gap={2} mt="xs" align="flex-start">
                <Radio.Group
                  label="Usage Rights Duration"
                  description={
                    isUsageRightsUpfront
                      ? "Duration of usage rights for this campaign are fixed"
                      : "Select the duration of usage rights for all packages in this contract offer"
                  }
                  value={UsageRightsDurationToDays[usageRightsDuration].toString()}
                  onChange={(value) => {
                    setUsageRightsDuration(USAGE_RIGHTS_DAYS_TO_DURATION[Number(value)]);
                  }}
                  withAsterisk>
                  <Group my="xs">{usageRightsDayOptions.map((option) => option)}</Group>
                </Radio.Group>
              </Group>
              <Group>
                <NumberInput
                  label="Creator Asking Usage Rights Price"
                  description="Enter price in whole dollars"
                  leftSection={<IconCurrencyDollar />}
                  value={usagePrice}
                  allowNegative={false}
                  allowDecimal={false}
                  onChange={(value) => setUsagePrice(Number(value))}
                />
                <NumberInput
                  label="Suggested Usage Rights Price"
                  description="Enter price in whole dollars"
                  leftSection={<IconCurrencyDollar />}
                  value={suggestedUsagePrice}
                  allowNegative={false}
                  allowDecimal={false}
                  disabled
                />
              </Group>
            </Stack>
          </Stack>
        )}
      </Box>
    </Stack>
  );
};

export default UsageRightsPricing;
