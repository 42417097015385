import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useOutletContext } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { User } from "firebase/auth";

import { auth } from "auth/firebaseAuthHelpers";
import { AuthContext } from "auth/AuthContext";
import { isOpsUser } from "utils/UserUtils";

enum IsOpsState {
  LOADING = 1,
  IS_OPS = 2,
  IS_NOT_OPS = 3,
}

const checkUser = async (user: User, setOpsState: (IsOpsState: IsOpsState) => void) => {
  try {
    const isOps = await isOpsUser(user);
    if (isOps) {
      setOpsState(IsOpsState.IS_OPS);
    } else {
      setOpsState(IsOpsState.IS_NOT_OPS);
    }
  } catch (error) {
    setOpsState(IsOpsState.IS_NOT_OPS);
  }
};

const OpsLayout = () => {
  const [user, loading] = useAuthState(auth);
  const [isOpsState, setOpsState] = useState(IsOpsState.LOADING);

  useEffect(() => {
    if (loading) return;
    if (user) {
      checkUser(user, setOpsState);
    } else {
      setOpsState(IsOpsState.IS_NOT_OPS);
    }
  }, [loading]);

  if (isOpsState === IsOpsState.LOADING) return <>Loading</>;
  if (isOpsState === IsOpsState.IS_NOT_OPS) return <Navigate to="/" />;
  return <Outlet context={{ user }} />;
};

export function getOpsUser() {
  return useOutletContext<AuthContext>()?.user;
}

export default OpsLayout;
