import React, { useEffect, useState } from "react";
import { User } from "firebase/auth";

import { IconX, IconCheck, IconHelp } from "@tabler/icons-react";

import {
  Anchor,
  Button,
  Container,
  Fieldset,
  Flex,
  rem,
  Select,
  Space,
  Stack,
  TagsInput,
  Text,
  Textarea,
  TextInput,
  Tooltip,
} from "@mantine/core";

import { useForm } from "@mantine/form";

import { notifications, Notifications } from "@mantine/notifications";

import { useInputState } from "@mantine/hooks";

import { getAndSetIsAdminUser } from "utils/UserUtils";
import { BrandResponse, createBrand } from "models/Brand";

export const createBrandRequest = async ({
  user,
  formValues,
  setButtonLoading,
}: {
  user: User;
  formValues: { [key: string]: any };
  setButtonLoading: (buttonLoading: boolean) => void;
}) => {
  setButtonLoading(true);
  const brandName = formValues.brandName ? formValues.brandName : "";
  const takeRate = formValues.takeRate ? formValues.takeRate : 0.2;
  const contactEmail = formValues.contactEmail ? formValues.contactEmail : "";
  const billingEmail = formValues.billingEmail ? formValues.billingEmail : "";
  const companyBlurb = formValues.companyBlurb ? formValues.companyBlurb : "";
  const companyWebsite = formValues.companyWebsite ? formValues.companyWebsite : "";
  const memberEmails = formValues.memberEmails ? formValues.memberEmails : [];

  try {
    const response = await createBrand({
      user,
      brandName,
      takeRate,
      contactEmail,
      billingEmail,
      companyBlurb,
      companyWebsite,
      memberEmails,
    });
    if (response != null && response?.id != null) {
      notifications.show({
        title: "Brand created successfully!",
        message: (
          <Anchor
            href={`https://www.1stcollab.com/admin/brands?brandId=${response.id}`}
            target="_blank">
            Go to brand dashboard.
          </Anchor>
        ),
        autoClose: false,
      });
    } else if (response != null) {
      const err = JSON.stringify(response);
      notifications.show({
        color: "red",
        title: "Error with creating the brand!",
        message: err,
        autoClose: false,
      });
    } else {
      notifications.show({
        color: "red",
        title: "Error with creating the brand!",
        message: "Unknown error.",
        autoClose: false,
      });
    }
  } catch (e) {
    notifications.show({
      color: "red",
      title: "Error with creating the Brand!",
      message: "Unknown error.",
      autoClose: false,
    });
  } finally {
    setButtonLoading(false);
  }
};

const validateEmail = (email: string) => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  // lol let's eventually setup some shared email validation logic
  return regex.test(email);
};

export default function AdminCreateBrand({ user }: { user: User }) {
  const [brandName, setBrandName] = useInputState("");
  const [takeRate, setBrandTakeRate] = useInputState(0.2);
  const [contactEmail, setContactEmail] = useInputState("");
  const [billingEmail, setBillingEmail] = useInputState("");
  const [companyBlurb, setCompanyBlurb] = useInputState("");
  const [companyWebsite, setCompanyWebsite] = useInputState("");
  const [memberEmails, setMemberEmails] = useInputState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [notificationEmails, setNotificationEmails] = useInputState([]);
  const form = useForm({
    initialValues: {
      brandName,
      takeRate,
      contactEmail,
      billingEmail,
      companyBlurb,
      companyWebsite,
      memberEmails,
      notificationEmails,
    },
    validate: {
      brandName: (value) => (value.length < 1 ? "Brand name is required" : null),
      takeRate: (value) =>
        Number(value) < 0 || Number(value) > 1 ? "Take rate must be between 0 and 1" : null,
      contactEmail: (value) => (!validateEmail(value) ? "Valid email required" : null),
      billingEmail: (value) => (!validateEmail(value) ? "Valid email required" : null),
      memberEmails: (value) =>
        !value.every((email: string) => validateEmail(email)) ? "Valid emails required" : null,
    },
  });
  const validNotificationEmails = (emails: string[]) =>
    emails.every((email: string) => form.values.memberEmails.includes(email));

  useEffect(() => {
    if (user) {
      getAndSetIsAdminUser(user, setIsAdmin);
    } else {
      setIsAdmin(false);
    }
  }, []);

  return (
    <Container size="50%">
      <Notifications />
      <Flex justify="center" align="center" direction="row">
        <Text fw={900}>Create a Brand</Text>
      </Flex>
      <Fieldset legend="Brand Information">
        <form
          onSubmit={form.onSubmit(() =>
            createBrandRequest({ user, formValues: form.values, setButtonLoading }),
          )}>
          <Stack gap={12}>
            <TextInput
              label="Brand Name"
              required
              placeholder="Test brand name"
              /* eslint-disable react/jsx-props-no-spreading */
              {...form.getInputProps("brandName")}
              w={300}
            />
            <TextInput
              w={100}
              label="Take Rate"
              required
              placeholder="0.0 - 1.0"
              description="0.0 - 1.0"
              {...form.getInputProps("takeRate")}
            />
            <TextInput
              w={300}
              label="Contact Email"
              required
              placeholder="contact@1stcollab.com"
              {...form.getInputProps("contactEmail")}
            />
            <TextInput
              w={300}
              label="Billing Email"
              required
              placeholder="billing@1stcollab.com"
              {...form.getInputProps("billingEmail")}
            />
            <TagsInput
              label="Member Emails"
              w={400}
              {...form.getInputProps("memberEmails")}
              splitChars={[",", " "]}
            />
            <TagsInput
              label="Notification Emails"
              w={400}
              {...form.getInputProps("notificationEmails")}
              error={
                validNotificationEmails(form.values.notificationEmails)
                  ? null
                  : "Notification Emails need to exist in Member Emails"
              }
              splitChars={[",", " "]}
            />
            <TextInput
              w={300}
              label="Company Website"
              required
              placeholder="https://www.1stcollab.com"
              {...form.getInputProps("companyWebsite")}
            />
            <Textarea label="Company Blurb" {...form.getInputProps("companyBlurb")} />
            <Flex justify="center">
              <Button type="submit" w="25%" loading={buttonLoading}>
                Create
              </Button>
            </Flex>
          </Stack>
        </form>
      </Fieldset>
    </Container>
  );
}
