import React, { useEffect, useState } from "react";
import { User } from "firebase/auth";

import { IconX, IconCheck } from "@tabler/icons-react";

import {
  Anchor,
  Button,
  Container,
  Flex,
  rem,
  Select,
  Space,
  Text,
  Textarea,
  TextInput,
} from "@mantine/core";
import { notifications, Notifications } from "@mantine/notifications";

import { useInputState } from "@mantine/hooks";

import { API_URL } from "configs/Configs";
import { handleResult } from "utils/ApiUtils";
import { getAndSetIsAdminUser } from "utils/UserUtils";
import { Brand, getAndSetBrands } from "models/Brand";

export const createCampaignRequest = async ({
  user,
  brandId,
  campaignName,
  campaignDescription,
  setButtonLoading,
}: {
  user: User;
  brandId: number;
  campaignName: string;
  campaignDescription?: string;
  setButtonLoading: (buttonLoading: boolean) => void;
}) => {
  setButtonLoading(true);
  const firebaseToken = await user.getIdToken();
  const request = new Request(`${API_URL}/api/models/campaigns/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
    body: JSON.stringify({
      brand_id: brandId,
      title: campaignName,
      campaign_description: campaignDescription,
    }),
  });
  try {
    const response = await handleResult(request);
    if (response != null && response.campaign != null && response.campaign.hash_id != null) {
      notifications.show({
        title: "Campaign created successfully!",
        message: (
          <Anchor
            href={`https://www.1stcollab.com/campaigns/${response.campaign.hash_id}/`}
            target="_blank">
            Go to campaign dashboard.
          </Anchor>
        ),
        autoClose: false,
      });
    } else if (response != null) {
      const err = JSON.stringify(response);
      notifications.show({
        color: "red",
        title: "Error with creating the campaign!",
        message: err,
        autoClose: false,
      });
    } else {
      notifications.show({
        color: "red",
        title: "Error with creating the campaign!",
        message: "Unknown error.",
        autoClose: false,
      });
    }
  } catch (e) {
    notifications.show({
      color: "red",
      title: "Error with creating the campaign!",
      message: e.message,
      autoClose: false,
    });
  } finally {
    setButtonLoading(false);
  }
};

export default function AdminCreateCampaign({ user }: { user: User }) {
  // Selected Campaign
  const [brands, setBrands] = useState([]);
  const [brandIdsAndTitles, setBrandIdsAndTitles] = useState([]);
  const [brandId, setBrandId] = useInputState(null);
  const [campaignName, setCampaignName] = useInputState("");
  const [campaignDescription, setCampaignDescription] = useInputState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  useEffect(() => {
    if (user) {
      getAndSetIsAdminUser(user, setIsAdmin);
    } else {
      setIsAdmin(false);
    }
  }, []);

  useEffect(() => {
    // Note: we sometimes fetch all of the campaigns multiple times
    // if we have this component + the CampaignsList component on the same page.
    const abortController = new AbortController();
    getAndSetBrands(user, setBrands, abortController);
    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    const updatedBrandIdsAndTitles = brands.map((brand) => ({
      value: brand.id.toString(),
      label: brand.brand_name,
    }));
    setBrandIdsAndTitles(updatedBrandIdsAndTitles);
  }, [brands]);

  return (
    <Container size="80%">
      <Notifications />
      <Flex justify="center" align="center" direction="row">
        <Text fw={900}>Create a Campaign</Text>
      </Flex>
      <Flex align="Flex-start" direction="column">
        <Select
          label="Brand"
          searchable
          description={
            isAdmin ? "To create a Brand, go to https:/www.1stcollab.com/admin/brands" : null
          }
          data={brandIdsAndTitles}
          onChange={setBrandId}
          mb="lg"
          required
          width={800}
        />
        <TextInput
          label="Campaign Name"
          required
          placeholder="Test campaign name"
          onChange={setCampaignName}
          value={campaignName}
          miw={600}
        />
        <Space h={10} />
        <Textarea
          label="Campaign Description"
          placeholder="Test campaign description"
          onChange={setCampaignDescription}
          value={campaignDescription}
          miw={600}
        />
        <Space h={10} />
        <Button
          loading={buttonLoading}
          onClick={() =>
            createCampaignRequest({
              user,
              brandId,
              campaignName,
              campaignDescription,
              setButtonLoading,
            })
          }>
          Create
        </Button>
      </Flex>
    </Container>
  );
}
