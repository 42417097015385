import React, { useEffect, useState } from "react";

import { Center, Loader, Pagination, Stack } from "@mantine/core";

import { getExistingBrandPartners } from "components/creator_sets/api/CreatorSetsApi";
import { fetchCreatorSetDetails } from "components/creator_sets/CreatorSetUtils";
import UnifiedCreatorRep from "components/creator_lists/unified_creator_rep/UnifiedCreatorRep";
import { CreatorDetails } from "components/discovery/Datamodels";
import { chunk } from "utils/PaginationUtils";


const DEFAULT_PAGE_SIZE = 25;

export const BrandExistingPartnersView = () => {
  const [existingPartnerCreatorIds, setExistingPartnerCreatorIds] = useState([]);
  const [activePage, setPage] = useState(1);
  const [creatorIdChunks, setCreatorIdChunks] = useState([]);
  const [numPages, setNumPages] = useState(0);
  const [currChunk, setCurrChunk] = useState([]);
  const [creatorDetailResults, setCreatorDetailResults] = useState<Record<number, CreatorDetails>>(
    {},
  );
  const [loading, setLoading] = useState(true);

  // Fetch existing partner list
  useEffect(() => {
    const abortController = new AbortController();
    setLoading(true);
    getExistingBrandPartners(abortController).then((response) => {
      if (response?.existing_partners) {
        setExistingPartnerCreatorIds(response.existing_partners);
      } else {
        setLoading(false);
      }
    });
  }, []);

  // Paginate existing partner creator ids
  useEffect(() => {
    setCreatorIdChunks(chunk(existingPartnerCreatorIds, DEFAULT_PAGE_SIZE));
    setNumPages(Math.ceil(existingPartnerCreatorIds.length / DEFAULT_PAGE_SIZE));
  }, [existingPartnerCreatorIds]);

  // Set current page
  useEffect(() => {
    console.log(activePage, creatorIdChunks);
    setCurrChunk(creatorIdChunks[activePage - 1]);
  }, [creatorIdChunks, activePage]);

  // Fetch details for current page
  useEffect(() => {
    const abortController = new AbortController();
    if (currChunk?.length > 0) {
      setLoading(true);
      fetchCreatorSetDetails(currChunk, {}, setCreatorDetailResults, abortController).then(
        () => setLoading(false)
      );
    }
  }, [currChunk]);

  if (loading) {
    <Center mt="xs">
      <Loader type="dots" />
    </Center>
  }

  return (
    <Stack>
    {currChunk && currChunk.map((creatorId) => {
      if (!creatorDetailResults[creatorId]) {
        return null;
      }
      return (
        <UnifiedCreatorRep
              creator={creatorDetailResults[creatorId]}
              key={`entry-${creatorId}`}
              defaultExpandedPlatforms={[]}
              archetype="Existing Partner"
            />
      );
    })}
    <Center>
      <Pagination value={activePage} onChange={setPage} total={numPages} />
    </Center>
  </Stack>
  );
};

export default BrandExistingPartnersView;
