import React from "react";
import { Badge } from "@mantine/core";
import { InvoiceStatus } from "components/contracts/models/Invoice";

export default function InvoiceStatusBadge({
  status,
  size,
}: {
  status: InvoiceStatus;
  size: "xs" | "sm" | "md" | "lg" | "xl";
}) {
  if (status === InvoiceStatus.PAID) {
    return (
      <Badge size={size} color="teal">
        Paid
      </Badge>
    );
  } else if (status === InvoiceStatus.OPEN) {
    return (
      <Badge size={size} color="yellow">
        Awaiting Payment
      </Badge>
    );
  } else if (status === InvoiceStatus.DRAFT) {
    return (
      <Badge size={size} color="gray">
        Draft
      </Badge>
    );
  }

  throw new Error(`Unknown invoice status: ${status}`);
}
