import { Flex, SelectList, Tabs } from "gestalt";
import "gestalt/dist/gestalt.css";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { InstagramMediaProductType } from "social/fb/InstagramUtils";
import Spacer from "components/Spacer";
import GridView from "components/discovery/GridView";
import InstagramStatsView from "pages/profile/InstagramStatsView";

const TopTabs = () => (
  <Tabs
    activeTabIndex={0}
    onChange={() => {}}
    tabs={[
      { href: "/", text: "Media" },
      { href: "/", text: "Overview" },
    ]}
  />
);

const Segments = ({
  platform,
  format,
  showSponsored,
  orderBy,
  setPlatform,
  setFormat,
  setSponsored,
  setOrderBy,
}: {
  platform: string;
  format: InstagramMediaProductType;
  orderBy: string;
  showSponsored: boolean;
  setPlatform: (platform: string) => void;
  setFormat: (format: InstagramMediaProductType) => void;
  setSponsored: (showSponsored: boolean) => void;
  setOrderBy: (orderBy: string) => void;
}) => (
  <Flex gap={4}>
    <SelectList
      id="platformSelect"
      label="Platform"
      onChange={({ value }) => {
        if (!value) {
          setPlatform(null);
        } else {
          setPlatform(value);
        }
      }}
      size="md"
      value={platform}>
      {[
        { label: "All", value: "" },
        { label: "Instagram", value: "instagram" },
      ].map(({ label, value }) => (
        <SelectList.Option key={label} label={label} value={value} />
      ))}
    </SelectList>
    <SelectList
      id="formatSelect"
      label="Format"
      onChange={({ value }) => {
        if (value) {
          if (value === InstagramMediaProductType.FEED.toString()) {
            setFormat(InstagramMediaProductType.FEED);
          } else if (value === InstagramMediaProductType.REELS.toString()) {
            setFormat(InstagramMediaProductType.REELS);
          } else {
            setFormat(null);
          }
        } else {
          setFormat(null);
        }
      }}
      size="md"
      value={format ? format.toString() : ""}>
      {[
        { label: "All", value: "" },
        { label: "Post", value: InstagramMediaProductType.FEED.toString() },
        { label: "Reel", value: InstagramMediaProductType.REELS.toString() },
      ].map(({ label, value }) => (
        <SelectList.Option key={label} label={label} value={value} />
      ))}
    </SelectList>
    <SelectList
      id="sponsoredSelect"
      label="Sponsored"
      onChange={({ value }) => {
        setSponsored(value === "true");
      }}
      size="md"
      value={showSponsored ? "true" : "false"}>
      {[
        { label: "All", value: "false" },
        { label: "Sponsored", value: "true" },
      ].map(({ label, value }) => (
        <SelectList.Option key={label} label={label} value={value} />
      ))}
    </SelectList>
    <SelectList
      id="orderBySelect"
      label="Order By"
      onChange={({ value }) => {
        setOrderBy(value);
      }}
      size="md"
      value={orderBy}>
      {[
        { label: "Most Recent", value: "creation_timestamp" },
        { label: "Most Likes", value: "like_count" },
        { label: "Most Comments", value: "comments_count" },
      ].map(({ label, value }) => (
        <SelectList.Option key={label} label={label} value={value} />
      ))}
    </SelectList>
  </Flex>
);

const InstagramViewWithUsername = ({ username }: { username: string }) => {
  const [platform, setPlatform] = useState("");
  const [format, setFormat] = useState(null);
  const [showSponsored, setSponsored] = useState(false);
  const [orderBy, setOrderBy] = useState("creation_timestamp");

  useEffect(() => {
    setPlatform("");
    setFormat(null);
    setSponsored(false);
    setOrderBy("creation_timestamp");
  }, [username]);

  return (
    <>
      <Flex direction="column" gap={4} alignItems="center" flex="grow">
        <TopTabs />
        <Segments
          platform={platform}
          format={format}
          showSponsored={showSponsored}
          orderBy={orderBy}
          setPlatform={setPlatform}
          setFormat={setFormat}
          setSponsored={setSponsored}
          setOrderBy={setOrderBy}
        />
      </Flex>
      <Spacer height={20} />
      <InstagramStatsView
        username={username}
        platform={platform}
        format={format}
        showSponsored={showSponsored}
      />
      <Spacer height={20} />
      <GridView
        username={username}
        platform={platform}
        format={format}
        showSponsored={showSponsored}
        orderBy={orderBy}
      />
    </>
  );
};

const InstagramView = () => {
  const { username } = useParams();
  return <InstagramViewWithUsername username={username} />;
};

export default InstagramView;
