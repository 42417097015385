import { getAuth } from "firebase/auth";

import { handleResult } from "utils/ApiUtils";
import { API_URL } from "configs/Configs";

const CREATOR_OPPORTUNITY_BASE = `${API_URL}/api/campaigns`;
const GET_OPPORTUNITIES_URL = `${CREATOR_OPPORTUNITY_BASE}/get_creator_opportunities/`;
const UPDATE_OPP_URL = `${CREATOR_OPPORTUNITY_BASE}/update_opportunity_state/`;
const ADD_OPP_URL = `${CREATOR_OPPORTUNITY_BASE}/add_creator_opportunity/`;
const GET_STATUS_MAP_URL = `${CREATOR_OPPORTUNITY_BASE}/get_campaign_status_mapping/`;

export const fetchCreatorOpportunities = async (
  creatorId: number,
  abortController: AbortController,
  brandId?: number,
): Promise<[]> => {
  const firebaseToken = await getAuth().currentUser?.getIdToken();
  if (!creatorId) {
    return Promise.resolve(null);
  }

  const requestUrl = new URL(GET_OPPORTUNITIES_URL);
  requestUrl.searchParams.append("creatorId", creatorId.toString());
  if (brandId) {
    requestUrl.searchParams.append("brandId", brandId.toString());
  }

  const request = new Request(requestUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
  });

  const response = await handleResult(request, abortController);
  return response;
};

export const fetchCampaignStatusMapping = async (
  abortController: AbortController,
): Promise<any> => {
  const firebaseToken = await getAuth().currentUser?.getIdToken();

  const request = new Request(GET_STATUS_MAP_URL, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
  });

  const response = await handleResult(request, abortController);
  return response;
};

export const deleteOpporunity = async (
  opportunityId: number,
  abortController: AbortController,
): Promise<any> => {
  const firebaseToken = await getAuth().currentUser?.getIdToken();
  const request = new Request(`${CREATOR_OPPORTUNITY_BASE}/delete_opportunity/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
    body: JSON.stringify({ opportunityId }),
  });

  const response = await handleResult(request, abortController);
  return response;
};

export const updateOpportunityState = async (
  opportunityId: number,
  emailFunnelStatusId: number,
  abortController: AbortController,
): Promise<any> => {
  const firebaseToken = await getAuth().currentUser?.getIdToken();
  const request = new Request(UPDATE_OPP_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
    body: JSON.stringify({ opportunityId, emailFunnelStatusId }),
  });

  const response = await handleResult(request, abortController);
  return response;
};

export const addCreatorOpportunity = async (
  creatorId: number,
  campaignId: number,
  adGroupId: number,
  abortController: AbortController,
): Promise<any> => {
  const firebaseToken = await getAuth().currentUser?.getIdToken();
  const request = new Request(ADD_OPP_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
    body: JSON.stringify({ creatorId, campaignId, adGroupId }),
  });

  const response = await handleResult(request, abortController);
  return response;
};

export default null;
