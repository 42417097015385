import React, { forwardRef, useEffect, useState } from "react";
import { Button, Combobox, HoverCard, Pill, PillsInput, Popover, Text } from "@mantine/core";
import { useClickOutside, useClipboard, useUncontrolled } from "@mantine/hooks";

function splitTags(splitChars: string[] | undefined, value: string): string[] {
  if (!splitChars) return [value];

  return value
    .split(new RegExp(`[${splitChars.join("")}]`))
    .filter((tag) => tag.trim() !== "")
    .map((tag) => tag.trim())
    .filter((tag) => tag !== "");
}

interface PillTextProps {
  pillText: string;
  hoverText?: string | null;
  onButtonClick: () => void;
}

const PillText = forwardRef<HTMLDivElement, PillTextProps>((props: PillTextProps, ref) => {
  const pillText = (
    <Text size="xs" ref={ref}>
      {props.pillText}
    </Text>
  );

  if (props.hoverText) {
    return (
      <HoverCard shadow="md" position="top">
        <HoverCard.Target>
          <Text size="xs" ref={ref}>
            {pillText}
          </Text>
        </HoverCard.Target>
        <HoverCard.Dropdown>
          {props.hoverText ? (
            <Button variant="filled" onClick={props.onButtonClick}>
              {props.hoverText}
            </Button>
          ) : null}
        </HoverCard.Dropdown>
      </HoverCard>
    );
  }
  return pillText;
});
PillText.displayName = "PillText";

const CustomTag = ({
  hoverText,
  item,
  onRemove,
  onRightClick,
}: {
  hoverText?: ((item: string) => string) | null;
  item: string;
  onRemove: () => void;
  onRightClick: (item: string) => void;
}) => {
  const clipboard = useClipboard();
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const ref = useClickOutside(() => setIsOpened(false), ["mouseup", "touchend", "keydown"]);

  useEffect(() => {
    setIsOpened(clipboard.copied);
  }, [clipboard.copied]);

  return (
    <Popover withArrow shadow="md" opened={isOpened} position="bottom">
      <Popover.Target>
        <Pill
          key={item}
          withRemoveButton
          onRemove={onRemove}
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            e.preventDefault();
            clipboard.copy(item);
          }}
          onContextMenu={(e: React.MouseEvent<HTMLElement>) => {
            e.preventDefault();
            onRightClick(item);
          }}
          styles={{
            label: {
              display: "flex",
              alignItems: "center",
            },
          }}>
          <PillText
            pillText={item}
            hoverText={hoverText ? hoverText(item) : null}
            onButtonClick={() => onRightClick(item)}
          />
        </Pill>
      </Popover.Target>
      <Popover.Dropdown ref={ref}>
        <Text size="xs">{item} copied!</Text>
      </Popover.Dropdown>
    </Popover>
  );
};

const CustomTagsInput = ({
  allowDuplicates = false,
  defaultValue,
  description,
  disabled = false,
  error,
  label,
  placeholder,
  onChange = () => {},
  onRightClick = () => {},
  splitChars = [","],
  tagHoverText,
  value,
  withAsterisk = false,
}: {
  allowDuplicates?: boolean;
  defaultValue?: string[];
  description?: string | null;
  disabled?: boolean;
  error?: React.ReactNode;
  label?: React.ReactNode;
  onChange?: (newValue: string[]) => void;
  onRightClick?: (item: string) => void;
  placeholder?: string | null;
  splitChars?: string[];
  tagHoverText?: ((item: string) => string) | null;
  value?: string[];
  withAsterisk?: boolean;
}) => {
  // eslint-disable-next-line
  const [_value, setValue] = useUncontrolled({
    value,
    defaultValue,
    onChange,
  });

  // eslint-disable-next-line
  const [_search, setSearch] = useUncontrolled({
    defaultValue: "",
    onChange: () => {},
  });

  const handleAddNewValue = (searchString: string) => {
    setSearch("");
    const cleanedSearch = searchString ? searchString.trim() : "";
    if (allowDuplicates) {
      setValue([..._value, cleanedSearch]);
    } else {
      const isDuplicate = _value.some((tag) => {
        return tag.toLowerCase() === cleanedSearch.toLowerCase();
      });
      if (!isDuplicate) {
        setValue([...new Set([..._value, cleanedSearch])]);
      }
    }
  };

  const handleValueRemove = (val: string) => {
    setValue(_value.filter((v) => v !== val));
  };

  const values = _value.map((item) => (
    <CustomTag
      key={item}
      hoverText={tagHoverText}
      item={item}
      onRemove={() => handleValueRemove(item)}
      onRightClick={onRightClick}
    />
  ));

  return (
    <Combobox withinPortal={false} disabled={disabled}>
      <Combobox.DropdownTarget>
        <PillsInput
          disabled={disabled}
          label={label}
          description={description}
          withAsterisk={withAsterisk}
          error={error}>
          <Pill.Group disabled={disabled}>
            {values}

            <Combobox.EventsTarget>
              <PillsInput.Field
                disabled={disabled}
                value={_search}
                placeholder={placeholder}
                onChange={(event) => {
                  setSearch(event.currentTarget.value);
                }}
                onKeyDown={(event) => {
                  if (splitChars!.includes(event.key) && _search.length > 0) {
                    const newValues = splitTags(splitChars, _search);
                    newValues.forEach((newValue) => handleAddNewValue(newValue));
                    event.preventDefault();
                  }

                  if (!event.nativeEvent.isComposing) {
                    if (event.key === "Backspace" && _search.length === 0) {
                      event.preventDefault();
                      handleValueRemove(_value[_value.length - 1]);
                    }
                    if (event.key === "Enter" && _search.length > 0) {
                      event.preventDefault();
                      handleAddNewValue(_search);
                    }
                  }
                }}
              />
            </Combobox.EventsTarget>
          </Pill.Group>
        </PillsInput>
      </Combobox.DropdownTarget>
    </Combobox>
  );
};

export default CustomTagsInput;
