import React, { ReactNode } from "react";
import { Group, Text, Title } from "@mantine/core";
import { IconEye } from "@tabler/icons-react";

export const InternalCardTitle = ({ title, icon }: { title: string; icon?: ReactNode }) => {
  return (
    <Group gap="xs" justify="space-between">
      <Group>
        {icon}
        <Title order={4} fw={600}>
          {title}
        </Title>
      </Group>
      <Group gap="xs">
        <IconEye size={24} color="var(--mantine-color-gray-5)" />
        <Text c="gray.5" size="sm">
          Internal Only
        </Text>
      </Group>
    </Group>
  );
};

export const InternalCardSubtitle = ({ title, icon }: { title: string; icon: ReactNode }) => {
  return (
    <Group gap="xs" align="center">
      <Text c="gray.9" fw={500}>
        {title}
      </Text>
      {icon}
    </Group>
  );
};
