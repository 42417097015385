import { createRequestWithFirebaseToken, handleResult } from "utils/ApiUtils";

import { API_URL } from "configs/Configs";

const GET_LABELING_TIMESERIES_URL = `${API_URL}/labeling/timeseries/`;

export interface LabelingTimeseries {
  date: string;
  approved: Record<string, number>;
  labeled: Record<string, number>;
}

export const getLabelingTimeseriesByCreatorSet = async (
  setLabelingTimeseries: (labelingTimeseries: LabelingTimeseries[]) => void,
  abortController?: AbortController,
) => {
  const request = await createRequestWithFirebaseToken({
    url: `${GET_LABELING_TIMESERIES_URL}creator_sets/`,
    method: "GET",
  });
  const response = await handleResult(request, abortController);
  if (response?.results && response?.results?.length > 0) {
    setLabelingTimeseries(response.results);
  }
  return response;
};

export const getLabelingTimeseriesByBrandCreatorSets = async (
  brandId: string,
  setLabelingTimeseries: (labelingTimeseries: LabelingTimeseries[]) => void,
  abortController?: AbortController,
) => {
  const request = await createRequestWithFirebaseToken({
    url: `${GET_LABELING_TIMESERIES_URL}creator_sets/?brand_id=${brandId}`,
    method: "GET",
  });
  const response = await handleResult(request, abortController);
  if (response?.results && response?.results?.length > 0) {
    setLabelingTimeseries(response.results);
  } else {
    setLabelingTimeseries([]);
  }
  return response;
};

export const getLabelingTimeseriesForBrand = async (
  brandId: number,
  setLabelingTimeseries: (labelingTimeseries: LabelingTimeseries[]) => void,
  abortController?: AbortController,
) => {
  const request = await createRequestWithFirebaseToken({
    url: `${GET_LABELING_TIMESERIES_URL}brands/?brand_ids=${brandId}`,
    method: "GET",
  });
  const response = await handleResult(request, abortController);
  if (response?.results && response?.results?.length > 0) {
    setLabelingTimeseries(response.results);
  }
  return response;
};

export const getLabelingTimeseriesByBrand = async (
  brandIds: string[],
  setLabelingTimeseries: (labelingTimeseries: LabelingTimeseries[]) => void,
  setBrandOrder: (brandOrder: string[]) => void,
  abortController?: AbortController,
) => {
  const request = await createRequestWithFirebaseToken({
    url: `${GET_LABELING_TIMESERIES_URL}brands/${
      brandIds?.length > 0 ? `?brand_ids=${brandIds.join(",")}` : ""
    }`,
    method: "GET",
  });
  const response = await handleResult(request, abortController);
  if (response?.results && response?.results?.length > 0) {
    setLabelingTimeseries(response.results);
    if (response?.sort_order) {
      setBrandOrder(response.sort_order);
    }
  }
  return response;
};

export default null;
