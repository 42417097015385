import React from "react";

import { Timeline, Text } from "@mantine/core";

import { IconFilePlus } from "@tabler/icons-react";

const ActionTypeToText = new Map<number, string>([
  [0, "Contract Created"],
  [1, "Contract Signed"],
  [2, "Contract Expired"],
  [3, "Contract Reissued"],
  [4, "Deliverable Deadline Extended"],
  [5, "Deliverable Concept Approved"],
  [6, "Deliverable Concept Requested Changes"],
  [7, "Message Sent"],
]);

export const ContractHistoryTimelineItem = ({
  user,
  actionType,
  action,
  changes,
  date,
}: {
  user: string;
  actionType: number;
  action: string;
  changes: Record<string, string[]>;
  date: string;
}) => {
  // TODO(andrew): make this a map
  const actionText = ActionTypeToText.get(actionType);
  return (
    <Timeline.Item title={actionText}>
      <Text size="sm">
        <Text fw={500} component="span">
          {user}
        </Text>
        {" - "}
        {action || actionText}
      </Text>
      {Object.entries(changes).map(([key, value]) => {
        if (!(value?.length === 2) || value[0] === value[1]) {
          return null;
        }
        return (
          <Text key={key} size="sm">
            <Text component="span" fw={500}>
              {key}
            </Text>
            {": "}
            {value.join(" => ")}
          </Text>
        );
      })}
      <Text size="xs" mt={4}>
        On {date}
      </Text>
    </Timeline.Item>
  );
};

export default ContractHistoryTimelineItem;
