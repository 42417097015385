import React from "react";

import { Table } from "@mantine/core";

import { CreatorSetTableRow } from "campaigns/main/campaignPage/creator_sets/CreatorSetTableRow";
import { CreatorSet } from "components/discovery/Datamodels";

export const CreatorSetTable = ({
  creatorSets,
  showBrandDetails = false,
  readOnly = false,
}: {
  creatorSets: CreatorSet[];
  showBrandDetails?: boolean;
  readOnly?: boolean;
}) => {
  if (!creatorSets || creatorSets?.length === 0) {
    return null;
  }
  return (
    <Table withTableBorder withColumnBorders>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Creator Set Name</Table.Th>
          <Table.Th>Status</Table.Th>
          <Table.Th>Priority</Table.Th>
          <Table.Th>Difficulty</Table.Th>
          {showBrandDetails && <Table.Th>Brand</Table.Th>}
          <Table.Th>Assigned Ad Groups</Table.Th>
          <Table.Th>Creator Set Id</Table.Th>
          {!readOnly && <Table.Th w={100}>Actions</Table.Th>}
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {creatorSets.map((creatorSet: CreatorSet) => (
          <CreatorSetTableRow
            key={`creator-set-${creatorSet.id}`}
            brandId={creatorSet.brand.id}
            creatorSet={creatorSet}
            showBrandDetails={showBrandDetails}
            readOnly={readOnly}
          />
        ))}
      </Table.Tbody>
    </Table>
  );
};

export default CreatorSetTable;
