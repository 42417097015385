import React from "react";

import { Table } from "@mantine/core";

import { CreatorSet } from "components/discovery/Datamodels";
import { OpsTask } from "models/OpsTask";
import { OpsTaskTableRow } from "admin/app/ops/OpsTaskTableRow";

export const OpsCreatorSetTasksTable = ({
  creatorSet,
  opsTasks,
}: {
  creatorSet: CreatorSet;
  opsTasks: OpsTask[];
}) => {
  // filter tasks by creatorSet
  const creatorSetTasks = opsTasks.filter(
    (task) => task?.creator_set_details?.id === creatorSet.id,
  );

  return (
    <Table horizontalSpacing="md" verticalSpacing="md" highlightOnHover withTableBorder>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Task Name</Table.Th>
          <Table.Th>Assignee</Table.Th>
          <Table.Th>Completed</Table.Th>
          <Table.Th>Creator Set</Table.Th>
          <Table.Th>Count</Table.Th>
          <Table.Th>Difficulty</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {creatorSetTasks.map((task) => (
          <OpsTaskTableRow key={task.id} opsTask={task} showAssignee />
        ))}
      </Table.Tbody>
    </Table>
  );
};

export default OpsCreatorSetTasksTable;
