import React, { useEffect, useState } from "react";

import { Anchor, Badge, Table, Tooltip } from "@mantine/core";

import { getCreatorSetSourcingDetails } from "admin/api/opsApi";
import { useAdminAppContext } from "admin/app/AdminAppShell";
import { CreatorSet, CreatorSetStatus } from "components/discovery/Datamodels";
import { fromISODateString } from "utils/DateUtils";

export const CreatorSetSourcingRow = ({ creatorSet }: { creatorSet: CreatorSet }) => {
  const [sourcingDetails, setSourcingDetails] = useState(null);

  useEffect(() => {
    const abortController = new AbortController();
    if (creatorSet?.id) {
      getCreatorSetSourcingDetails(creatorSet.id, abortController).then((resp) => {
        if (resp) {
          setSourcingDetails(resp);
        }
      });
    }
    return () => {
      abortController.abort();
    };
  }, [creatorSet]);
  return (
    <Table.Tr>
      <Table.Td>
        <Anchor href={`/admin/brands?brandId=${creatorSet.brand.id}`} target="_blank">
          {creatorSet.brand.brand_name}
        </Anchor>
      </Table.Td>
      <Table.Td>
        <Anchor href={`/admin/creator_sets?creatorSetId=${creatorSet.id}`} target="_blank">
          {creatorSet.name}
        </Anchor>
      </Table.Td>
      <Table.Td>{creatorSet.sourcing_priority}</Table.Td>
      <Table.Td>
        {creatorSet.ad_groups?.length > 0 &&
          creatorSet.ad_groups.map((adGroup) => (
            <Tooltip
              key={`creative-brief-adgroup=${adGroup.id}-tooltip`}
              label={`(${adGroup.campaign_name}) ${adGroup.name}`}>
              <Badge variant="light" key={`creator-set-adgroup=${adGroup.id}`}>
                {adGroup.name}
              </Badge>
            </Tooltip>
          ))}
      </Table.Td>
      <Table.Td>
        {fromISODateString(sourcingDetails?.enable_recommendations_timestamp)?.toDateString()}
      </Table.Td>
      <Table.Td>{sourcingDetails?.approved || 0}</Table.Td>
      <Table.Td>{sourcingDetails?.need_brand_review || 0}</Table.Td>
      <Table.Td>{sourcingDetails?.need_internal_review || 0}</Table.Td>
      <Table.Td>{sourcingDetails?.need_enrichment}</Table.Td>
      <Table.Td>{sourcingDetails?.missing_email}</Table.Td>
    </Table.Tr>
  );
};

export const CreatorSetSourcingTable = ({ creatorSets }: { creatorSets: CreatorSet[] }) => {
  return (
    <Table horizontalSpacing="md" verticalSpacing="md" highlightOnHover withTableBorder>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Brand</Table.Th>
          <Table.Th>Creator Set</Table.Th>
          <Table.Th>Priority</Table.Th>
          <Table.Th>Campaigns</Table.Th>
          <Table.Th>Recommendations Enabled Date</Table.Th>
          <Table.Th>Lifetime Brand Approved</Table.Th>
          <Table.Th>Needs Brand Review</Table.Th>
          <Table.Th>Needs Internal Review</Table.Th>
          <Table.Th>Needs Email Enrichment</Table.Th>
          <Table.Th>Missing Email</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {creatorSets.map((creatorSet) => (
          <CreatorSetSourcingRow key={creatorSet.id} creatorSet={creatorSet} />
        ))}
      </Table.Tbody>
    </Table>
  );
};

export const CreatorSetSourcingSummary = () => {
  const { creatorSets } = useAdminAppContext();
  const [growCreatorSets, setGrowCreatorSets] = useState([]);

  useEffect(() => {
    if (creatorSets) {
      const filteredCreatorSets = creatorSets.filter(
        (creatorSet) => creatorSet.status === CreatorSetStatus.GROW,
      );
      // sort by priority
      filteredCreatorSets.sort((a, b) => a.sourcing_priority - b.sourcing_priority);
      setGrowCreatorSets(filteredCreatorSets);
    }
  }, [creatorSets]);

  return <CreatorSetSourcingTable creatorSets={growCreatorSets} />;
};

export default CreatorSetSourcingSummary;
