import { Button, Center, Group, Progress, Stack } from "@mantine/core";
import Spacer from "components/Spacer";
import React, { useEffect, useMemo, useState } from "react";
import { MembershipTypes } from "models/User";
import OnboardingMembershipStep, {
  ONBOARDING_MEMBERSHIP_OPTIONS,
  validateEmailForMembershipResult,
} from "onboarding/v2/OnboardingMembershipStep";
import { OnboardingV2Context } from "onboarding/v2/OnboardingV2Context";
import { createOrUpdateBrandProfile, updateMembershipType } from "onboarding/v2/api/OnboardingApi";
import { useAppDispatch, useAppSelector } from "reduxStore/hooks";
import OnboardingCreatorProfileStep from "onboarding/v2/OnboardingCreatorProfileStep";
import { getAuthCreator, updateCreatorProfile, completeUserOnboarding } from "reduxStore/meSlice";
import OnboardingBrandProfileStep, {
  SIGNUP_REASON_TO_LABEL_MAP,
  SignupReason,
} from "onboarding/v2/OnboardingBrandProfileStep";
import { useNavigate } from "react-router-dom";
import OnboardingCreatorSocialsStep from "onboarding/v2/OnboardingCreatorSocialsStep";
import { UNAUTH_MEMBERSHIP_TYPE } from "auth/NewAuth";

enum OnboardingStep {
  MEMBERSHIP,
  CREATOR_PROFILE,
  CREATOR_SOCIALS,
  BRAND_PROFILE,
}

const SHARED_FLOW: OnboardingStep[] = [OnboardingStep.MEMBERSHIP];
const CREATOR_SPECIFIC_FLOW: OnboardingStep[] = [
  OnboardingStep.CREATOR_PROFILE,
  OnboardingStep.CREATOR_SOCIALS,
];
const OTHER_FLOW: OnboardingStep[] = [OnboardingStep.BRAND_PROFILE];

const OnboardingV2 = () => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector((state) => state.me.user);
  const currentCreator = useAppSelector((state) => state.me.creatorProfile);
  const navigate = useNavigate();

  const unauthMembershipType = localStorage.getItem(UNAUTH_MEMBERSHIP_TYPE)
    ? Number(localStorage.getItem(UNAUTH_MEMBERSHIP_TYPE))
    : null;

  useEffect(() => {
    // Redirect if onboarding has been completed
    if (currentUser?.completed_onboarding) {
      navigate("/");
    }
  }, [currentUser]);

  const [isNextLoading, setIsNextLoading] = useState<boolean>(false);
  // abort controller used when we already hit "next" but now want to abort the call (ie hit back button)
  const [nextButtonAbortController, setNextButtonAbortController] =
    useState<AbortController | null>(null);

  useEffect(() => {
    const abortController = new AbortController();
    dispatch(getAuthCreator(abortController));

    return () => {
      abortController.abort();
    };
  }, []);

  const [selectedMembershipType, setSelectedMembershipType] = useState<MembershipTypes | null>(
    currentUser?.membership_type,
  );

  const [selectedVerticals, setSelectedVerticals] = useState<string[]>(
    currentCreator?.selected_verticals || [],
  );
  const [username, setUsername] = useState<string>(currentUser?.username ?? "");
  const [usernameError, setUsernameError] = useState<string | null>(null);
  const [aboutMe, setAboutMe] = useState<string>(currentCreator?.about || "");
  const [companyName, setCompanyName] = useState<string>("");
  const [selectedReason, setSelectedReason] = useState<string>("");
  const [other, setOther] = useState<string>("");

  const contextValues = useMemo(
    () => ({
      selectedMembershipType,
      setSelectedMembershipType,
      selectedVerticals,
      setSelectedVerticals,
      username,
      setUsername,
      usernameError,
      aboutMe,
      setAboutMe,
      companyName,
      setCompanyName,
      selectedReason,
      setSelectedReason,
      other,
      setOther,
    }),
    [
      selectedMembershipType,
      selectedVerticals,
      username,
      usernameError,
      aboutMe,
      companyName,
      selectedReason,
      other,
    ],
  );

  const [currentStep, setCurrentStep] = useState<number>(0);
  const [steps, setSteps] = useState<OnboardingStep[]>(SHARED_FLOW.concat(CREATOR_SPECIFIC_FLOW));

  useEffect(() => {
    // put check for existing users that might be forced through onboarding - need to verify their emails are valid for being a brand
    const isNotValidForBrandFlow = validateEmailForMembershipResult(
      currentUser?.email ?? "",
      MembershipTypes.BRAND_USER,
    );
    if (isNotValidForBrandFlow) {
      // if user email is not valid for brand, just show creator flow
      setSteps(CREATOR_SPECIFIC_FLOW);
    } else if (
      // if we don't know what they selected in onboarding or if what they selected in onboarding was not valid, show membership option
      unauthMembershipType === null ||
      !ONBOARDING_MEMBERSHIP_OPTIONS.includes(unauthMembershipType)
    ) {
      if (selectedMembershipType === MembershipTypes.BASIC) {
        setSteps(SHARED_FLOW.concat(CREATOR_SPECIFIC_FLOW));
      } else {
        setSteps(SHARED_FLOW.concat(OTHER_FLOW));
      }
    } else if (unauthMembershipType === MembershipTypes.BASIC) {
      setSteps(CREATOR_SPECIFIC_FLOW);
    } else {
      setSteps(OTHER_FLOW);
    }
  }, [currentUser, selectedMembershipType]);

  useEffect(() => {
    if (currentUser) {
      if (!selectedMembershipType) {
        setSelectedMembershipType(currentUser.membership_type);
      }
      setUsername(currentUser.username);
    }
    if (currentCreator) {
      setAboutMe(currentCreator.about ?? "");
      setSelectedVerticals(currentCreator.selected_verticals ?? []);
    }
  }, [currentUser, currentCreator]);

  useEffect(() => {
    setUsernameError(null); // on change clear username error
  }, [username]);

  useEffect(() => {
    setNextButtonAbortController(new AbortController());
    if (isNextLoading) {
      nextButtonAbortController.abort();
    }
    setIsNextLoading(false);
  }, [currentStep]);

  const renderStep = () => {
    switch (steps[currentStep]) {
      case OnboardingStep.MEMBERSHIP:
        return <OnboardingMembershipStep />;
      case OnboardingStep.BRAND_PROFILE:
        return <OnboardingBrandProfileStep />;
      case OnboardingStep.CREATOR_PROFILE:
        return <OnboardingCreatorProfileStep />;
      case OnboardingStep.CREATOR_SOCIALS:
        return <OnboardingCreatorSocialsStep />;
      default:
        return null;
    }
  };

  const onNextClicked = async (): Promise<boolean> => {
    setIsNextLoading(true);
    switch (steps[currentStep]) {
      case OnboardingStep.MEMBERSHIP:
        return updateMembershipType(currentUser.key, selectedMembershipType).then(() => {
          return true;
        });
      case OnboardingStep.CREATOR_PROFILE:
        return dispatch(
          updateCreatorProfile({
            abortController: nextButtonAbortController,
            user_key: currentUser.key,
            username,
            creator: { ...currentCreator, about: aboutMe, selected_verticals: selectedVerticals },
          }),
        )
          .unwrap()
          .then(({ usernameError: currentUsernameError, creatorError, aborted }) => {
            if (currentUsernameError) {
              setUsernameError(currentUsernameError);
              return false;
            }
            if (creatorError) {
              // TODO (victoria 4.2024): DO SOMETHING WITH CREATOR ERROR
              return false;
            }
            return !aborted;
          });
      case OnboardingStep.BRAND_PROFILE: {
        const index = Number(selectedReason) as SignupReason;
        const reasonString = SIGNUP_REASON_TO_LABEL_MAP[index];
        return createOrUpdateBrandProfile(
          currentUser.key,
          companyName,
          reasonString,
          other,
          nextButtonAbortController,
        ).then((response) => {
          return true;
        });
      }
      case OnboardingStep.CREATOR_SOCIALS:
      default:
        return true;
    }
  };

  return (
    <OnboardingV2Context.Provider value={contextValues}>
      <Center w="100%">
        <Stack
          w="100%"
          style={{ maxWidth: "620px" }}
          // height of screen minus height of navbar + top / bottom padding of base
          // layout + additional padding so button doesnt touch bottom of screen
          h="calc(100vh - 60px - 32px - 32px)"
          justify="space-between">
          <Stack align="center" gap={0}>
            <Progress
              size="xs"
              value={Math.round(((currentStep + 0.5) / steps.length) * 100)}
              w="100%"
              style={{ maxWidth: "520px" }}
            />
            <Spacer height={48} />
            {renderStep()}
          </Stack>
          <Group justify="space-between" w="100%">
            {currentStep > 0 ? (
              <Button
                variant="outline"
                color="var(--mantine-color-dark-6)"
                onClick={() => setCurrentStep(Math.max(currentStep - 1, 0))}>
                Back
              </Button>
            ) : null}
            <Spacer />
            <Button
              onClick={async () => {
                const canProceed = await onNextClicked();
                setIsNextLoading(false);
                if (canProceed) {
                  if (currentStep === steps.length - 1) {
                    // Once they've finished onboarding, we should mark it and then redirect
                    dispatch(
                      completeUserOnboarding({
                        abortController: nextButtonAbortController,
                        user_key: currentUser.key,
                      }),
                    );
                    localStorage.removeItem(UNAUTH_MEMBERSHIP_TYPE);
                    if (steps[currentStep] === OnboardingStep.BRAND_PROFILE) {
                      const isUnauthSearch = localStorage.getItem("unauth-search") === "true";
                      if (isUnauthSearch) {
                        navigate("/campaigns/discover");
                      } else {
                        navigate("/campaigns");
                      }
                    } else if (
                      steps[currentStep] === OnboardingStep.CREATOR_SOCIALS &&
                      currentUser?.username
                    ) {
                      navigate(`/${currentUser.username}`);
                    }
                  } else {
                    setCurrentStep(Math.min(currentStep + 1, steps.length - 1));
                  }
                }
              }}
              loading={isNextLoading}>
              {currentStep === steps.length - 1 ? "Done" : "Next"}
            </Button>
          </Group>
        </Stack>
      </Center>
    </OnboardingV2Context.Provider>
  );
};

export default OnboardingV2;
