import React, { useRef } from "react";

import ReactPlayer from "react-player";

import { Anchor, Card, Flex, Group, Stack, Text } from "@mantine/core";

import { IconExternalLink } from "@tabler/icons-react";

import TitleAndCaption from "components/contracts/common/TitleAndCaption";
import SeekButton from "components/contracts/common/SeekButton";
import { secondsToTimestamp } from "utils/DateUtils";

export default function EmbeddedVideoPlayer({
  url,
  title,
  caption,
  integrationTimestamp,
  isIntegration,
  showRawLink = false,
  rawUrl,
}: {
  url: string;
  title: string;
  caption: string;
  integrationTimestamp: number;
  isIntegration: boolean;
  showRawLink: boolean;
  rawUrl?: string;
}) {
  if (!url) {
    return null;
  }

  const playerRef = useRef(null);

  const videoPlayer = (
    <Card shadow="xs" padding={0} radius="sm" withBorder>
      <ReactPlayer ref={playerRef} url={url} controls />
      <TitleAndCaption title={title} caption={caption} width={640} />
    </Card>
  );

  if (!showRawLink && !isIntegration) {
    return videoPlayer;
  }

  return (
    <Stack>
      {videoPlayer}
      <Group justify={isIntegration ? "space-between" : "right"} mt={isIntegration ? "-xs" : "-sm"}>
        {isIntegration && (
          <SeekButton
            playerRef={playerRef}
            integrationTimestamp={secondsToTimestamp(integrationTimestamp)}
            showLabel
          />
        )}
        {showRawLink && (
          <Flex align="flex-end" justify="right">
            <Anchor size="xs" underline="never" target="_blank" href={rawUrl}>
              Video Source <IconExternalLink size="0.7rem" />
            </Anchor>
          </Flex>
        )}
      </Group>
    </Stack>
  );
}
