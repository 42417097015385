import React, { useEffect, useState } from "react";

import { Paper } from "@mantine/core";

import { MediaEntry, MediaEntryCard } from "components/MediaBlockCard";

import { getTikTokMedia } from "admin/api/mediaApi";

export const TikTokVideoDebugCard = ({ tikTokVideoId }: { tikTokVideoId: string }) => {
  const [mediaEntry, setMediaEntry] = useState<MediaEntry>(null);

  if (!tikTokVideoId) {
    return null;
  }

  useEffect(() => {
    if (tikTokVideoId && tikTokVideoId !== mediaEntry?.video_id) {
      // fetch video
      getTikTokMedia(tikTokVideoId, setMediaEntry);
    }
  }, [tikTokVideoId]);

  if (!mediaEntry) {
    return null;
  }

  return (
    <Paper p="md">
      <MediaEntryCard media={mediaEntry} padding={0} detailedMetrics />
    </Paper>
  );
};

export default TikTokVideoDebugCard;
