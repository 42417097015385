import React, { useEffect, useState } from "react";
import { Anchor, Center, Flex, Image, Stack, Table, Text } from "@mantine/core";

import { useAppSelector } from "reduxStore/hooks";

import { CreatorDetails } from "components/discovery/Datamodels";
import { CreatorInfo, getCreatorInfoFromDetails } from "components/creator_lists/CreatorListsUtils";
import Spacer from "components/Spacer";
import { TikTokStats, YoutubeStats, InstagramStats } from "creators/api/fetchExtendedStats";
import { getAbbreviatedNumber, getPercentage } from "utils/AnalyticsUtils";
import { PLATFORM_ID_TO_LABEL_NO_ALL } from "components/creator/CreatorPlatformUtils";
import tiktok_icon from "social/tiktok/assets/tiktok_auth_button_icon.svg";
import instagram_icon from "social/fb/assets/simple_bw_instagram_icon.svg";
import youtube_icon from "social/google/assets/youtube_black_white_icon.svg";
import { MediaEntry } from "components/MediaBlockCard";
import Video from "components/creator_lists/unified_creator_rep/Video";

const PLATFORM_TABLE_COLUMN_NAMES = [
  "Subscribers",
  "Total Videos",
  "Median Views",
  "Engagement Rate",
];

const INTERNAL_PLATFORM_TABLE_COLUMN_NAMES = [
  "Subscribers",
  "Total Videos",
  "Videos in Last 90d",
  "Videos Last Updated",
  "Median Views",
  "Average Views",
  "Engagement Rate",
];

const LIVE_MEDIA_TABLE_COLUMN_NAMES = [
  "Total Views",
  "Total Likes",
  "Total Comments",
  "Engagement Rate",
  "Link Clicks",
];

interface DisplayInfo {
  height?: number;
  width?: number;
  maxVideosToDisplay: number;
}
const PLATFORM_DISPLAY_INFO_MAP: Record<string, DisplayInfo> = {
  youtube: {
    // assets seem to be 4:3
    width: 295,
    height: 217.5,
    maxVideosToDisplay: 8,
  },
  tiktok: {
    // assets seem to be 3:4
    width: 217.5,
    height: 295,
    maxVideosToDisplay: 8,
  },
  instagram: {
    // assets seem to be 9:16
    width: 165,
    height: 294,
    maxVideosToDisplay: 8,
  },
};

export const PlatformMediaRow = ({
  hasBorder,
  platform,
  media,
  totalColSpan,
  refetchThumbnails,
}: {
  hasBorder: boolean;
  platform: string;
  media: MediaEntry[];
  totalColSpan: number;
  refetchThumbnails: boolean;
}) => (
  <Table.Tr
    style={{
      borderBottom: hasBorder ? "1px solid var(--mantine-color-gray-4)" : undefined,
    }}>
    <Table.Td colSpan={totalColSpan}>
      <Flex
        wrap="nowrap"
        gap={0}
        align="flex-start"
        style={{
          overflow: "scroll",
        }}>
        {media
          .slice(0, PLATFORM_DISPLAY_INFO_MAP[platform].maxVideosToDisplay)
          .map((video, index) => {
            const videoHasViews = (video.views || 0) > 0;
            const videoHasTimeStamp = video.creation_timestamp !== null;
            return (
              <React.Fragment key={`video-rep-${video.video_url}`}>
                {index > 0 ? <Spacer width={16} /> : null}
                <Video video={video} refetchThumbnails={refetchThumbnails} />
              </React.Fragment>
            );
          })}
      </Flex>
    </Table.Td>
  </Table.Tr>
);

const PlatformMediaStats = ({
  platformInfo,
  showDerivedStats = false,
}: {
  platformInfo: CreatorInfo;
  showDerivedStats: boolean;
}) => {
  // Calculate total metrics
  let totalViews = 0;
  let totalLikes = 0;
  let totalComments = 0;
  let totalLinkClicks = 0;
  let totalConversions = 0;
  platformInfo.videos.map((video) => {
    totalViews += video.views;
    totalLikes += video.likes;
    totalComments += video.comments;
    if (video.link_clicks !== null) {
      totalLinkClicks += video.link_clicks;
    }
    if (video.conversions !== null) {
      totalConversions += video.conversions;
    }
    return true;
  });
  const engagmentRate =
    totalViews > 0 ? getPercentage((totalLikes + totalComments) / totalViews) : "N/A";

  return (
    <>
      <Table.Td>{getAbbreviatedNumber(totalViews)}</Table.Td>
      <Table.Td>{getAbbreviatedNumber(totalLikes)}</Table.Td>
      <Table.Td>{getAbbreviatedNumber(totalComments)}</Table.Td>
      {showDerivedStats && <Table.Td>{engagmentRate}</Table.Td>}
      {showDerivedStats && <Table.Td>{getAbbreviatedNumber(totalLinkClicks)}</Table.Td>}
    </>
  );
};

const PlatformStats = ({
  platformInfo,
  showDerivedStats = false,
}: {
  platformInfo: CreatorInfo;
  showDerivedStats: boolean;
}) => {
  return (
    <>
      <Table.Td>{getAbbreviatedNumber(platformInfo.num_subscribers)}</Table.Td>
      <Table.Td>{getAbbreviatedNumber(platformInfo.all_time_videos)}</Table.Td>
      {showDerivedStats && <Table.Td>{getAbbreviatedNumber(platformInfo.views_med)}</Table.Td>}
      {showDerivedStats && <Table.Td>{getPercentage(platformInfo.engagement_rate)}</Table.Td>}
    </>
  );
};

const PlatformExtendedStats = ({ platformInfo }: { platformInfo: CreatorInfo }) => {
  const lastUpdated = platformInfo.videos_last_updated ? platformInfo.videos_last_updated : null;
  const dateString = new Date(lastUpdated);

  return (
    <>
      <Table.Td>{getAbbreviatedNumber(platformInfo.num_subscribers)}</Table.Td>
      <Table.Td>{getAbbreviatedNumber(platformInfo.all_time_videos)}</Table.Td>
      <Table.Td>{platformInfo.num_videos_90d}</Table.Td>
      <Table.Td>{lastUpdated ? dateString.toDateString() : "null"}</Table.Td>
      <Table.Td>{getAbbreviatedNumber(platformInfo.views_med)}</Table.Td>
      <Table.Td>{getAbbreviatedNumber(platformInfo.avg_views_90d)}</Table.Td>
      <Table.Td>{getPercentage(platformInfo.engagement_rate)}</Table.Td>
    </>
  );
};

export const PlatformRow = ({
  defaultExpandedPlatforms,
  index,
  platformName,
  platformInfo,
  totalRows,
  refetchThumbnails,
  useLiveMedia,
  useExtendedStats = false,
  showDerivedStats = false,
}: {
  defaultExpandedPlatforms: string[];
  index: number;
  platformName: string;
  platformInfo: CreatorInfo;
  totalRows: number;
  refetchThumbnails: boolean;
  useLiveMedia: boolean;
  useExtendedStats?: boolean;
  showDerivedStats?: boolean;
}) => {
  const [showContent, setShowContent] = useState<boolean>(
    defaultExpandedPlatforms.includes(platformName),
  );
  useEffect(() => {
    setShowContent(defaultExpandedPlatforms.includes(platformName));
  }, [defaultExpandedPlatforms]);

  let platformRowLength = PLATFORM_TABLE_COLUMN_NAMES.length + 1;
  if (!showDerivedStats) {
    platformRowLength -= 2;
  }
  let platformStats = (
    <PlatformStats platformInfo={platformInfo} showDerivedStats={showDerivedStats} />
  );
  if (useExtendedStats && !useLiveMedia) {
    platformRowLength = INTERNAL_PLATFORM_TABLE_COLUMN_NAMES.length + 1;
    platformStats = <PlatformExtendedStats platformInfo={platformInfo} />;
  }
  if (useLiveMedia) {
    platformRowLength = LIVE_MEDIA_TABLE_COLUMN_NAMES.length + 1;
    if (!showDerivedStats) {
      platformRowLength -= 2;
    }
    platformStats = (
      <PlatformMediaStats platformInfo={platformInfo} showDerivedStats={showDerivedStats} />
    );
  }

  let platformImage = null;
  switch (platformName) {
    case "tiktok":
      platformImage = tiktok_icon;
      break;
    case "youtube":
      platformImage = youtube_icon;
      break;
    case "instagram":
      platformImage = instagram_icon;
      break;
    default:
      platformImage = null;
  }

  return (
    <>
      <Table.Tr key={platformName}>
        <Table.Td>
          <Flex direction="row" gap={0} align="center">
            <Anchor
              href={platformInfo.profile_link}
              target="_blank"
              c="var(--mantine-color-black)"
              style={{
                overflow: "hidden",
              }}>
              <Flex direction="row" gap={0} align="center">
                <Center w="20px" h="20px">
                  <Image src={platformImage} style={{ width: 20, height: 20 }} />
                </Center>
                <Spacer width={8} />
                <Text
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "155%",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}>
                  {platformInfo.handle}
                </Text>
              </Flex>
            </Anchor>
            <Anchor
              onClick={() => setShowContent(!showContent)}
              style={{
                fontSize: "14px",
                fontWeight: "600",
                lineHeight: "155%",
                flexShrink: 0,
              }}
              pl="4px">
              ({showContent ? "Collapse" : "Expand"})
            </Anchor>
          </Flex>
        </Table.Td>
        {platformStats}
      </Table.Tr>
      {showContent ? (
        <PlatformMediaRow
          platform={platformName}
          media={platformInfo.videos}
          hasBorder={index < totalRows - 1}
          totalColSpan={platformRowLength}
          refetchThumbnails={refetchThumbnails}
        />
      ) : null}
    </>
  );
};

const PlatformTableColumns = ({ showDerivedStats = false }: { showDerivedStats: boolean }) => {
  let columnNames = PLATFORM_TABLE_COLUMN_NAMES;
  if (!showDerivedStats) {
    columnNames = columnNames.filter(
      (name) => name !== "Engagement Rate" && name !== "Median Views",
    );
  }
  return (
    <>
      {columnNames.map((name) => (
        <Table.Th key={name}>{name}</Table.Th>
      ))}
    </>
  );
};

export const ExtendedPlatformTableColumns = () => (
  <>
    {INTERNAL_PLATFORM_TABLE_COLUMN_NAMES.map((name) => (
      <Table.Th key={name}>{name}</Table.Th>
    ))}
  </>
);

const LiveMediaTableColumns = ({ showDerivedStats = false }: { showDerivedStats: boolean }) => {
  let columnNames = LIVE_MEDIA_TABLE_COLUMN_NAMES;
  if (!showDerivedStats) {
    columnNames = columnNames.filter(
      (name) => name !== "Engagement Rate" && name !== "Link Clicks",
    );
  }
  return (
    <>
      {columnNames.map((name) => (
        <Table.Th key={name}>{name}</Table.Th>
      ))}
    </>
  );
};

const PlatformTable = ({
  creator,
  defaultExpandedPlatforms,
  useLiveMedia = false,
  useExtendedStats = false,
  refetchThumbnails,
  tikTokStats,
  youtubeLongStats,
  youtubeShortsStats,
  instagramStats,
}: {
  creator: CreatorDetails;
  defaultExpandedPlatforms: string[];
  useLiveMedia?: boolean;
  useExtendedStats?: boolean;
  refetchThumbnails?: boolean;
  tikTokStats?: TikTokStats;
  youtubeLongStats?: YoutubeStats;
  youtubeShortsStats?: YoutubeStats;
  instagramStats?: InstagramStats;
}) => {
  const brand = useAppSelector((state) => state.me.brand);
  const userProfile = useAppSelector((state) => state.me.user);
  const [showDerivedStats, setShowDerivedStats] = useState<boolean>(false);

  useEffect(() => {
    setShowDerivedStats(brand?.is_verified || userProfile?.is_staff);
  }, [brand, userProfile]);

  let defaultPlatformTable = <PlatformTableColumns showDerivedStats={showDerivedStats} />;
  if (useExtendedStats && !useLiveMedia) {
    defaultPlatformTable = <ExtendedPlatformTableColumns />;
  }
  if (useLiveMedia) {
    defaultPlatformTable = <LiveMediaTableColumns showDerivedStats={showDerivedStats} />;
  }

  //  if we want extended stats, combine yt long and shorts stats
  let activePlatforms: Record<string, TikTokStats | YoutubeStats | InstagramStats> = {};
  if (useExtendedStats) {
    let ytNumVids = null;
    if (youtubeLongStats && youtubeShortsStats) {
      ytNumVids = youtubeShortsStats.num_videos_90d + youtubeLongStats.num_videos_90d;
    }
    const numVids = {
      youtube: ytNumVids,
      tiktok: tikTokStats?.num_videos_90d || null,
      instagram: instagramStats,
    };

    const platformToStatsMap: Record<string, TikTokStats | YoutubeStats | InstagramStats> = {
      tiktok: tikTokStats,
      youtube: {
        ...youtubeLongStats,
        num_videos_90d: numVids.youtube,
      },
      instagram: instagramStats,
    };
    activePlatforms = Object.entries(platformToStatsMap)
      .filter(([platform, value]) => value !== null)
      .reduce((acc, [platform, value]) => {
        acc[platform] = value;
        return acc;
      }, {} as Record<string, TikTokStats | YoutubeStats | InstagramStats>);
  }
  const platformsToShow = creator.platform_priority || [...PLATFORM_ID_TO_LABEL_NO_ALL.keys()];
  const creatorPlatforms: Record<string, CreatorInfo> = Object.fromEntries(
    platformsToShow
      .map((platform) => {
        if (getCreatorInfoFromDetails(creator, platform)) {
          if (useExtendedStats) {
            return [
              platform,
              {
                ...getCreatorInfoFromDetails(creator, platform),
                num_videos_90d: activePlatforms[platform]?.num_videos_90d || 0,
                avg_views_90d: activePlatforms[platform]?.avg_views_90d || 0,
              },
            ];
          }
          return [platform, getCreatorInfoFromDetails(creator, platform)];
        }
        return [platform, null];
      })
      .filter(([_, creatorInfo]) => creatorInfo !== null),
  );

  const numPlatforms = Object.keys(creatorPlatforms).length;

  return (
    <Table
      withRowBorders={false}
      styles={{
        table: {
          tableLayout: "fixed",
        },
        th: {
          textOverflow: "ellipsis",
          overflow: "hidden",
        },
      }}>
      <Table.Thead
        styles={{
          thead: {
            fontSize: "12px",
            fontWeight: "400",
            lineHeight: "155%",
            borderBottom: "1px solid var(--mantine-color-gray-4)",
          },
        }}>
        <Table.Tr>
          <Table.Th
            style={{
              width: 250, // hardcoding width here so we can show more of the user's platform handles
            }}>
            Platform
          </Table.Th>
          {defaultPlatformTable}
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {Object.entries(creatorPlatforms).map(([platformName, platform], index) =>
          platform ? (
            <PlatformRow
              key={platformName}
              platformName={platformName}
              platformInfo={platform}
              index={index}
              totalRows={numPlatforms}
              defaultExpandedPlatforms={defaultExpandedPlatforms}
              useLiveMedia={useLiveMedia}
              useExtendedStats={useExtendedStats}
              refetchThumbnails={refetchThumbnails}
              showDerivedStats={showDerivedStats}
            />
          ) : null,
        )}
      </Table.Tbody>
    </Table>
  );
};

export default PlatformTable;
