import React from "react";

import { Text } from "@mantine/core";
import { RichTextEditor as RTE, Link } from "@mantine/tiptap";
import { useEditor, Editor } from "@tiptap/react";
import CharacterCount from "@tiptap/extension-character-count";
import Highlight from "@tiptap/extension-highlight";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import Placeholder from "@tiptap/extension-placeholder";

const CHARACTER_LIMIT = 5000;

export function getEditorWithCharacterLimit(placeholder: string) {
  const editor = useEditor({
    extensions: [
      StarterKit as any,
      Underline,
      Link,
      Highlight,
      Placeholder.configure({ placeholder }),
      CharacterCount.configure({
        limit: CHARACTER_LIMIT,
      }),
    ],
  });

  return editor;
}

export function getEditor(placeholder: string, setEditorContent: (content: string) => void) {
  const editor = useEditor({
    extensions: [
      StarterKit as any,
      Underline,
      Link,
      Highlight,
      Placeholder.configure({ placeholder }),
    ],
    onUpdate() {
      if (editor.isEmpty) {
        setEditorContent("");
      } else {
        setEditorContent(JSON.stringify(editor.getJSON()));
      }
    },
  });

  return editor;
}

export default function RichTextEditor({ editor }: { editor: Editor }) {
  return (
    <>
      <RTE withTypographyStyles editor={editor}>
        <RTE.Toolbar sticky stickyOffset={60}>
          <RTE.ControlsGroup>
            <RTE.Bold />
            <RTE.Italic />
            <RTE.Underline />
            <RTE.Strikethrough />
            <RTE.ClearFormatting />
            <RTE.Highlight />
            <RTE.Code />
          </RTE.ControlsGroup>

          <RTE.ControlsGroup>
            <RTE.H1 />
            <RTE.H2 />
            <RTE.H3 />
            <RTE.H4 />
          </RTE.ControlsGroup>

          <RTE.ControlsGroup>
            <RTE.Blockquote />
            <RTE.Hr />
            <RTE.BulletList />
            <RTE.OrderedList />
          </RTE.ControlsGroup>

          <RTE.ControlsGroup>
            <RTE.Link />
            <RTE.Unlink />
          </RTE.ControlsGroup>
        </RTE.Toolbar>

        <RTE.Content />
      </RTE>
      {editor.storage.characterCount && (
        <Text
          pl="0.1rem"
          mt="-0.5rem"
          size="xs"
          c={editor.storage.characterCount.characters() >= CHARACTER_LIMIT ? "red" : "default"}>
          {editor.storage.characterCount.characters()}/{CHARACTER_LIMIT} characters
        </Text>
      )}
    </>
  );
}
