import { Layer } from "gestalt";
import { AuthenticationState } from "onboarding/OnboardingUtils";
import SocialAuthButton from "onboarding/v2/SocialAuthButton";
import React, { useEffect, useState } from "react";
import youtube_auth_icon from "social/google/assets/youtube_icon.svg";
import InstructionModal from "social/google/GoogleInstructionsModal";
import { useAppSelector } from "reduxStore/hooks";
import { deleteYoutubeConnect, getYoutubeLoginStatus } from "social/google/YoutubeUtils";
import { googleLogout } from "@react-oauth/google";

const YoutubeConnectV2 = () => {
  const userEmail = useAppSelector((state) => state.me.user?.email);
  const [authState, setAuthState] = useState(AuthenticationState.LOADING);
  const [showInstructions, setShowInstructions] = useState(false);

  useEffect(() => {
    // TODO: check authentication state and set it appropriately. See FBLogin.tsx for example
    getYoutubeLoginStatus().then((newAuthState) => {
      setAuthState(newAuthState);
    });
  }, []);

  const logoutYoutube = async () => {
    // TODO(implement logout)
    googleLogout();

    deleteYoutubeConnect().then(() => {
      setAuthState(AuthenticationState.NOT_AUTHENTICATED);
    });
  };

  return (
    <>
      <SocialAuthButton
        label={
          authState === AuthenticationState.AUTHENTICATED ? "Disconnect YouTube" : "Connect YouTube"
        }
        authState={authState}
        iconSrc={youtube_auth_icon}
        onClick={() => {
          if (authState === AuthenticationState.NOT_AUTHENTICATED) {
            setShowInstructions(true);
          } else if (authState === AuthenticationState.AUTHENTICATED) {
            logoutYoutube();
          }
        }}
      />
      {showInstructions ? (
        <Layer>
          <InstructionModal
            userEmail={userEmail}
            setAuth={setAuthState}
            setShowInstructions={setShowInstructions}
          />
        </Layer>
      ) : null}
    </>
  );
};

export default YoutubeConnectV2;
