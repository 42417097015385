import { Box, Container, Group, LoadingOverlay, Stack, Stepper, Title } from "@mantine/core";
import React, { useEffect, useState } from "react";
import CreateCampaignTimeline from "campaigns/create/CreateCampaignTimeline";
import Spacer from "components/Spacer";
import { useCampaignAppContext } from "campaigns/CampaignAppShell";
import { useNavigate } from "react-router-dom";
import SetupCampaignCard, { SetupFormType } from "campaigns/create/SetupCampaignCard";
import ExecutionCampaignCard, { ExecutionFormType } from "campaigns/create/ExecutionCampaignCard";
import DeliverableCampaignCard, {
  DeliverableFormType,
} from "campaigns/create/DeliverableCampaignCard";
import AdGroupsCampaignCard, { AdGroupsFormType } from "campaigns/create/AdGroupsCampaignCard";
import { Campaign } from "models/Campaign";
import { fetchCampaignByHashId } from "campaigns/api/fetchCampaignSettings";
import GoLiveCampaignCard from "campaigns/create/GoLiveCampaignCard";
import { startCampaign } from "campaigns/create/api/StartCampaignApi";

interface CampaignFormInfo {
  setUpStep: SetupFormType | null;
  executionStep: ExecutionFormType | null;
  deliverableStep: DeliverableFormType | null;
  adGroupStep: AdGroupsFormType | null;
}

enum Step {
  SETUP,
  EXECUTION,
  DELIVERABLES,
  AD_GROUPS,
  LIVE,
}

type NextStepMap = {
  [key in Step]: Step | null;
};

const NEXT_STEPS: NextStepMap = {
  [Step.SETUP]: Step.EXECUTION,
  [Step.EXECUTION]: Step.DELIVERABLES,
  [Step.DELIVERABLES]: Step.AD_GROUPS,
  [Step.AD_GROUPS]: Step.LIVE,
  [Step.LIVE]: null,
};

const CreateCampaign = ({ campaignHashId }: { campaignHashId?: number | null }) => {
  const navigate = useNavigate();
  const [step, setStep] = useState<Step>(Step.SETUP);
  const [isLoading, setIsLoading] = useState<boolean>(
    campaignHashId !== undefined && campaignHashId !== null,
  );
  // this is the campaign that exists in API
  const [currentCampaign, setCurrentCampaign] = useState<Campaign | null>(null);
  // this is the information currently in the form - not necessarily reflected in API
  const [currentCampaignFormInfo, setCurrentCampaignFormInfo] = useState<CampaignFormInfo>();
  useEffect(() => {
    const abortController = new AbortController();
    if (campaignHashId) {
      // TODO (victoria): MOVE TO REDUX
      fetchCampaignByHashId(/* 76030 44356 */ campaignHashId, abortController).then(
        (campaign: Campaign | null) => {
          setCurrentCampaign(campaign);
          if (campaign) {
            setIsLoading(false);
          }
        },
      );
    }

    return () => {
      abortController.abort();
    };
  }, [campaignHashId]);

  return (
    <Stack gap={0}>
      <Title order={1}>{campaignHashId ? "Edit Campaign" : "Create Campaign"}</Title>
      <Spacer height={24} />
      <Box pos="relative">
        <LoadingOverlay visible={isLoading} zIndex={1000} overlayProps={{ radius: 12, blur: 2 }} />
        <Stack style={{ flexGrow: 1, "--stack-gap": "24px" }}>
          <Container w="100%">
            <CreateCampaignTimeline active={step} />
          </Container>
          <SetupCampaignCard
            campaign={currentCampaign}
            isActive={step === Step.SETUP}
            onSaveSuccess={(formInfo, updatedCampaign, isCreated) => {
              if (updatedCampaign && isCreated) {
                setCurrentCampaign(updatedCampaign);
              }
              setCurrentCampaignFormInfo({
                ...currentCampaignFormInfo,
                setUpStep: formInfo,
              });
              setStep(NEXT_STEPS[Step.SETUP]);
            }}
          />
          <ExecutionCampaignCard
            isActive={step === Step.EXECUTION}
            campaign={currentCampaign}
            onSaveSuccess={(formInfo) => {
              setCurrentCampaignFormInfo({
                ...currentCampaignFormInfo,
                executionStep: formInfo,
              });
              setStep(NEXT_STEPS[Step.EXECUTION]);
            }}
          />
          <DeliverableCampaignCard
            campaign={currentCampaign}
            isActive={step === Step.DELIVERABLES}
            onSaveSuccess={(formInfo) => {
              setCurrentCampaignFormInfo({
                ...currentCampaignFormInfo,
                deliverableStep: formInfo,
              });
              setStep(NEXT_STEPS[Step.DELIVERABLES]);
            }}
          />
          <AdGroupsCampaignCard
            campaign={currentCampaign}
            isActive={step === Step.AD_GROUPS}
            onSaveSuccess={(formInfo) => {
              setCurrentCampaignFormInfo({
                ...currentCampaignFormInfo,
                adGroupStep: formInfo,
              });
              setStep(NEXT_STEPS[Step.AD_GROUPS]);
              // refetch campaign so we can properly update ad groups - can consider manually updating state in AdGroupsCampaignCard
              fetchCampaignByHashId(campaignHashId, null).then((campaign: Campaign | null) => {
                if (campaign) {
                  setCurrentCampaign(campaign);
                }
              });
            }}
            onEmailSettingsUpdated={() => {
              fetchCampaignByHashId(campaignHashId, null).then((campaign: Campaign | null) => {
                if (campaign) {
                  setCurrentCampaign(campaign);
                }
              });
            }}
          />
          <GoLiveCampaignCard
            campaign={currentCampaign}
            isActive={step === Step.LIVE}
            onSaveSuccess={(nowLiveCampaignHashId) => {
              startCampaign(currentCampaign.hash_id).then(() => {
                // just fire and forget
              });
              navigate(`/campaigns/${nowLiveCampaignHashId}`);
            }}
          />
        </Stack>
      </Box>
    </Stack>
  );
};

export default CreateCampaign;
