import React from "react";

import { Stack } from "@mantine/core";

import DeliverableVideo from "components/contracts/models/DeliverableVideo";
import { MessageParty } from "components/contracts/models/Message";
import PreviousVideoCard from "components/contracts/common/PreviousVideoCard";

export default function ViewPreviousVideos({
  deliverableVideos,
  messageParty,
}: {
  deliverableVideos: DeliverableVideo[];
  messageParty: MessageParty;
}) {
  const previousVideosCards = deliverableVideos.map((deliverableVideo, index) => (
    <PreviousVideoCard
      key={deliverableVideo.videoId}
      deliverableVideo={deliverableVideo}
      messageParty={messageParty}
      versionNumber={deliverableVideos.length - index}
    />
  ));

  return <Stack>{previousVideosCards}</Stack>;
}
