import React, { useEffect, useState } from "react";

import { useSearchParams } from "react-router-dom";

import {
  Anchor,
  Badge,
  Button,
  Card,
  Center,
  Container,
  Flex,
  Group,
  Loader,
  Paper,
  Stack,
  Switch,
  Table,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { useDebouncedValue, useInputState } from "@mantine/hooks";

import { getHashtagUsers, getRecommendedHashtags } from "admin/api/feedApi";

import { CreatorDetails } from "components/discovery/Datamodels";
import { CreatorSearchResults } from "components/creator/search/CreatorSearchResults";
import { fetchCreatorSetDetails } from "components/creator_sets/CreatorSetUtils";

const DEFAULT_LIMIT = 100;

interface UserHashtagResult {
  username: string;
  frequency: number;
  tf_idf: number;
  user_num_hashtags: number;
  user_num_videos: number;
  tiktok_url: string;
  creator_id: number;
}

function RawResultsTable({ results }: { results: UserHashtagResult[] }) {
  const rows = results.map((result) => (
    <Table.Tr key={result.tiktok_url}>
      <Table.Td>
        <Anchor href={result.tiktok_url} target="_blank">
          <Text fw="500">@{result.username}</Text>
        </Anchor>
      </Table.Td>
      <Table.Td>
        <Flex justify="center">{result.frequency}</Flex>
      </Table.Td>
      <Table.Td>
        <Flex justify="center">{result.user_num_hashtags}</Flex>
      </Table.Td>
      <Table.Td>
        <Flex justify="center">{result.user_num_videos}</Flex>
      </Table.Td>
      <Table.Td>
        <Flex justify="center">{result.tf_idf.toFixed(3)}</Flex>
      </Table.Td>
      <Table.Td>
        <Flex justify="center">
          {result.creator_id !== undefined ? (
            <Badge color="teal" variant="light" size="sm">
              True
            </Badge>
          ) : (
            <Badge color="red" variant="light" size="sm">
              False
            </Badge>
          )}
        </Flex>
      </Table.Td>
    </Table.Tr>
  ));

  return (
    <Table withTableBorder>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Creator</Table.Th>
          <Table.Th>Hashtag Frequency</Table.Th>
          <Table.Th>User Total Num Hashtags</Table.Th>
          <Table.Th>User Num Videos</Table.Th>
          <Table.Th>TF-IDF</Table.Th>
          <Table.Th>CreatorV2 Exists</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>{rows}</Table.Tbody>
    </Table>
  );
}

function RecommendedHashtags({
  hashtags,
  setHashtag,
  setLoading,
}: {
  hashtags: any[];
  setHashtag: (hashtag: string) => void;
  setLoading: (value: boolean) => void;
}) {
  return (
    <Flex gap="xs">
      {hashtags.map((hashtag) => (
        <Button
          key={hashtag.related_hashtag}
          variant="light"
          size="compact-xs"
          onClick={() => {
            setLoading(true);
            setHashtag(hashtag.related_hashtag);
          }}>
          #{hashtag.related_hashtag}
        </Button>
      ))}
    </Flex>
  );
}

export default function HashtagUserFeed() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [hashtag, setHashtag] = useInputState(null);
  const [limit, setLimit] = useInputState(DEFAULT_LIMIT);
  const [debouncedHashtag] = useDebouncedValue(hashtag, 500);
  const [debouncedLimit] = useDebouncedValue(limit, 500);

  const [broadHashtags, setBroadHashtags] = useState([]);
  const [narrowHashtags, setNarrowHashtags] = useState([]);
  const [hashtagsLoading, setHashtagsLoading] = useState(false);
  const [usersLoading, setUsersLoading] = useState(false);

  const [creatorIds, setCreatorIds] = useState([]);
  const [rawResults, setRawResults] = useState<UserHashtagResult[]>([]);
  const [creatorDetailResults, setCreatorDetailResults] = useState<Record<number, CreatorDetails>>(
    {},
  );

  const [showCreatorReps, setShowCreatorReps] = useState(false);

  // sync hashtag with searchparams
  useEffect(() => {
    // parse hashtag from search params
    const parsedHashtag = searchParams.get("hashtag");
    if (parsedHashtag) {
      if (parsedHashtag !== hashtag) {
        setHashtag(parsedHashtag);
      }
    } else {
      setHashtag("");
    }

    // parse limit from search params
    const parsedLimit = searchParams.get("limit");
    if (parsedLimit) {
      const intLimit = Number(parsedLimit);
      if (intLimit !== limit) {
        setLimit(intLimit);
      }
    } else {
      setLimit(DEFAULT_LIMIT);
    }
  }, [searchParams]);

  // Sync search params with hashtag
  useEffect(() => {
    // update search params based on query changes
    const newSearchParams = new URLSearchParams(searchParams);
    let changed = false;
    if (hashtag) {
      if (hashtag !== searchParams.get("hashtag")) {
        newSearchParams.set("hashtag", hashtag);
        changed = true;
      }
    } else if (hashtag === "") {
      newSearchParams.delete("hashtag");
      changed = true;
    }

    if (limit) {
      if (
        limit.toString() !== searchParams.get("limit") &&
        !(limit === 100 && !searchParams.get("limit"))
      ) {
        newSearchParams.set("limit", limit.toString());
        changed = true;
      }
    } else {
      newSearchParams.delete("limit");
      changed = true;
    }
    if (changed) {
      setSearchParams(newSearchParams);
    }
  }, [hashtag, limit]);

  useEffect(() => {
    const abortController = new AbortController();
    if (debouncedHashtag) {
      setHashtagsLoading(true);

      getRecommendedHashtags(debouncedHashtag, abortController)
        .then((response) => {
          const { results } = response;
          const broadHashtagResults = results
            .filter((res: any) => res.related_occurrences > res.source_occurrences)
            .slice(0, 10);
          const narrowHashtagResults = results
            .filter((res: any) => res.related_occurrences <= res.source_occurrences)
            .slice(0, 10);
          setBroadHashtags(broadHashtagResults);
          setNarrowHashtags(narrowHashtagResults);
        })
        .finally(() => setHashtagsLoading(false));

      setUsersLoading(true);
      getHashtagUsers(debouncedHashtag, limit, abortController)
        .then((response) => {
          const { results } = response;
          setRawResults(results);

          const existingCreatorIds = results
            .filter((result: UserHashtagResult) => result.creator_id !== undefined)
            .map((result: UserHashtagResult) => result.creator_id);
          setCreatorIds(existingCreatorIds);
        })
        .finally(() => setUsersLoading(false));
    } else {
      setBroadHashtags([]);
      setNarrowHashtags([]);
      setCreatorIds([]);
      setRawResults([]);
    }
    return () => {
      abortController.abort();
    };
  }, [debouncedHashtag, debouncedLimit]);

  // Update Creator Reps
  useEffect(() => {
    const abortController = new AbortController();
    if (creatorIds && creatorIds.length > 0) {
      fetchCreatorSetDetails(creatorIds, {}, setCreatorDetailResults, abortController);
    } else {
      setCreatorDetailResults({});
    }
    return () => {
      abortController.abort();
    };
  }, [creatorIds]);

  return (
    <Stack gap="md">
      <Group justify="center">
        <TextInput w={300} description="Hashtag" value={hashtag} onChange={setHashtag} />{" "}
        <TextInput w={300} description="Limit" value={limit} onChange={setLimit} />
      </Group>
      {(hashtagsLoading || usersLoading) && (
        <Center>
          <Loader />
        </Center>
      )}
      {!hashtagsLoading && !usersLoading && (
        <>
          {broadHashtags.length > 0 && (
            <>
              <Title order={5}>Broader Hashtags</Title>
              <RecommendedHashtags
                hashtags={broadHashtags}
                setHashtag={setHashtag}
                setLoading={setHashtagsLoading}
              />
            </>
          )}
          {narrowHashtags.length > 0 && (
            <>
              <Title order={5}>Narrower Hashtags</Title>
              <RecommendedHashtags
                hashtags={narrowHashtags}
                setHashtag={setHashtag}
                setLoading={setHashtagsLoading}
              />
            </>
          )}
          <Paper p="lg">
            <Container>
              <Stack>
                <Group justify="right">
                  <Switch
                    checked={showCreatorReps}
                    onChange={(event) => setShowCreatorReps(event.currentTarget.checked)}
                    label="Show Creator Reps"
                  />
                </Group>
                {!showCreatorReps && <RawResultsTable results={rawResults} />}
                {showCreatorReps && <CreatorSearchResults results={creatorDetailResults} />}
              </Stack>
            </Container>
          </Paper>
        </>
      )}
    </Stack>
  );
}
