import React from "react";
import { Box, Button, Center, Container, Flex, Stack, Text, Title } from "@mantine/core";
import { useNavigate } from "react-router-dom";

const JoinNowCTA = () => {
  const navigate = useNavigate();
  return (
    <Flex
      w="100%"
      bg="blue.7"
      style={{ background: "linear-gradient(to bottom, #0074E8, #0364C5" }}
      p={0}>
      <Container size="xl" p={0}>
        <Box py={112} px={{ base: "24px", sm: "32px", md: "96px" }}>
          <Center>
            <Stack align="center">
              <Container size="md">
                <Title c="white" ta="center" style={{ fontSize: "48px" }}>
                  Join now to start getting brand deals
                </Title>
              </Container>
              <Text c="white" ta="center" size="lg">
                No obligation, no risk, and always free for creators.
              </Text>
              <Button
                size="md"
                variant="white"
                w={225}
                radius="md"
                onClick={() => navigate("/signup")}>
                Sign Up
              </Button>
            </Stack>
          </Center>
        </Box>
      </Container>
    </Flex>
  );
};

export default JoinNowCTA;
