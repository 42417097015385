import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { User as FirebaseUser } from "firebase/auth";

import { Brand } from "models/Brand";
import { Campaign } from "models/Campaign";
import { CampaignSelector } from "campaigns/main/campaignPage/campaigns/CampaignSelector";
import { fetchCampaignsByBrandId } from "campaigns/api/fetchCampaignSettings";
import AdminCampaignSettings from "admin/AdminCampaignSettings";

export const AdminBrandCampaigns = ({
  user,
  brand,
  searchParams,
  setSearchParams,
  readOnly,
}: {
  user: FirebaseUser;
  brand: Brand;
  searchParams?: URLSearchParams | null;
  setSearchParams?: (searchParams: URLSearchParams) => void | null;
  readOnly?: boolean;
}) => {
  if (!user || !brand) {
    return null;
  }
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaignId, setSelectedCampaignId] = useState(null);
  const [selectedCampaign, setSelectedCampaign] = useState<Campaign | null>(null);

  useEffect(() => {
    const abortController = new AbortController();
    fetchCampaignsByBrandId(user, brand.id, setCampaigns, abortController);
    return () => {
      abortController.abort();
    };
  }, [brand, user]);

  useEffect(() => {
    if (selectedCampaignId) {
      const campaign = campaigns.find((c: Campaign) => c.id === selectedCampaignId);
      setSelectedCampaign(campaign);
      if (campaigns?.length > 0 && !campaign) {
        setSelectedCampaignId(null);
      }
    }
  }, [selectedCampaignId, campaigns]);

  return (
    <>
      <CampaignSelector
        campaigns={campaigns}
        selectedCampaignId={selectedCampaignId}
        setSelectedCampaignId={setSelectedCampaignId}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
      />
      {selectedCampaign && (
        <AdminCampaignSettings
          firebaseUser={user}
          campaign={selectedCampaign}
          readOnly={readOnly}
        />
      )}
    </>
  );
};

export default AdminBrandCampaigns;
