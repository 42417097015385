import React, { useEffect, useState } from "react";
import {
  CreatorDetails,
  CreatorSetEntryItem,
  CreatorSetEntryState,
  CreatorSetEntryWithDetail,
} from "components/discovery/Datamodels";
import {
  CreatorSetStateIndex,
  chunk,
  fetchCreatorSetDetails,
} from "components/creator_sets/CreatorSetUtils";
import { Flex, Pagination } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import { User } from "firebase/auth";

const TOP_OF_ENTRIES_ID = "creator-details-top";

export const useCreatorSetStateSelector = (
  creatorSetEntries: CreatorSetEntryItem[],
  creatorSetStateIndex: CreatorSetStateIndex,
  setViewableCreatorSetEntryItems: (creatorSetEntryItems: CreatorSetEntryItem[]) => void,
) => {
  useEffect(() => {
    const stateIndexToCreatorSetEntryState = {
      [CreatorSetStateIndex.NEEDS_REVIEW]: CreatorSetEntryState.NEEDS_REVIEW,
      [CreatorSetStateIndex.ACCEPT]: CreatorSetEntryState.ACCEPTED,
      [CreatorSetStateIndex.REJECT]: CreatorSetEntryState.REJECTED,
      [CreatorSetStateIndex.WORKED_WITH]: CreatorSetEntryState.WORKED_WITH,
    };

    const selectedState = stateIndexToCreatorSetEntryState[creatorSetStateIndex];

    if (creatorSetEntries != null) {
      const filteredCreatorSetEntryItems = creatorSetEntries.filter(
        (entry) => entry.state === selectedState,
      );
      setViewableCreatorSetEntryItems(filteredCreatorSetEntryItems);
    }
  }, [creatorSetStateIndex, creatorSetEntries]);
};

export const TopOfEntries = () => <div id={TOP_OF_ENTRIES_ID} />;

export const useCreatorSetPaginate = (
  creatorSetEntries: CreatorSetEntryItem[],
  creatorSetDetailsMap: Record<number, CreatorDetails>,
  setCreatorSetDetailsMap: (creatorSetDetailsMap: Record<number, CreatorDetails>) => void,
  pageSize = 25,
  isLive = false,
  campaignId: number = null,
  timeWindow = "0",
) => {
  const [activePage, setPage] = useState(1);
  const [creatorSetEntryPage, setCreatorSetEntryPage] = useState<CreatorSetEntryItem[]>([]);
  const [creatorSetPageDetails, setCreatorSetPageDetails] = useState<CreatorSetEntryWithDetail[]>(
    [],
  );

  const handleClick = () => {
    window.location.hash = `#${TOP_OF_ENTRIES_ID}`; // Set hash instead of full url
    setTimeout(() => {
      window.location.hash = ""; // Clear hash to enable another visit
    }, 1000);
  };

  useEffect(() => {
    if (creatorSetEntries === undefined || creatorSetEntries === null) {
      setCreatorSetPageDetails([]);
      setCreatorSetEntryPage([]);
      return;
    }
    const creatorSetChunks = chunk(creatorSetEntries, pageSize);
    if (creatorSetChunks.length === 0) {
      setCreatorSetPageDetails([]);
      setCreatorSetEntryPage([]);
      return;
    }
    const pageIndex = Math.min(activePage - 1, creatorSetChunks.length - 1);
    const pageEntries = chunk(creatorSetEntries, pageSize)[pageIndex];
    if (pageEntries != null) {
      setCreatorSetPageDetails([]);
      setCreatorSetEntryPage(pageEntries);
    }
  }, [activePage, creatorSetEntries]);

  // If the time window changes, we need to refetch the creator details
  // Note: when we update the timeWindow, the upstream already updates creatorSetEntries
  // which should clear the cache
  useEffect(() => {
    // Reset the page
    setPage(1);
  }, [timeWindow]);

  useEffect(() => {
    const creatorDetailsAbortController = new AbortController();
    if (creatorSetEntryPage === undefined) {
      return;
    }
    const creatorSetIdsToReq = creatorSetEntryPage.map((entry) => entry.creator_id);
    const timeWindowInt = timeWindow === "0" ? null : Number(timeWindow);
    fetchCreatorSetDetails(
      creatorSetIdsToReq,
      creatorSetDetailsMap,
      setCreatorSetDetailsMap,
      creatorDetailsAbortController,
      isLive,
      campaignId,
      timeWindowInt,
    );
  }, [creatorSetEntryPage]);

  useEffect(() => {
    const validCreatorSetEntries = creatorSetEntryPage.filter(
      (entry) => creatorSetDetailsMap[entry.creator_id] !== undefined,
    );
    setCreatorSetPageDetails(
      validCreatorSetEntries.map((entry) => ({
        creator_set_entry: entry,
        creator_details: creatorSetDetailsMap[entry.creator_id],
      })),
    );
  }, [creatorSetEntryPage, creatorSetDetailsMap]);

  const EntryPagniator = () => (
    <Flex align="center" justify="center">
      <Notifications />
      <Pagination
        value={activePage}
        onChange={setPage}
        onClick={handleClick}
        total={Math.ceil((creatorSetEntries?.length || 0) / pageSize)}
      />
    </Flex>
  );

  return {
    creatorSetPageDetails,
    EntryPagniator,
    TopOfEntries,
  };
};
