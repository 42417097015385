import { Brand } from "models/Brand";
import { User } from "models/User";
import { SupportedFormatIntegers, UsageRightsDuration } from "models/Common";
import { CreativeBrief } from "models/CreativeBrief";
import { SocialHandle } from "components/contracts/dashboard/Utils";

export interface CampaignDataset {
  dataset_id: number;
}

export interface Campaign {
  id: number;
  hash_id: number;
  is_test_campaign: boolean;
  title: string;
  description: string;
  goals: string;
  start_date: string;
  end_date: string;
  date_created?: Date;
  last_updated?: Date;
  is_active?: boolean;
  brand?: Brand;
  brand_name?: string;
  dashboard_csv_id?: string;
  collab_datasets?: CampaignDataset[];
  ad_groups?: CampaignAdGroup[] | null;
  budget?: number;
  budget_type?: number;
  client_approved_creators_goal?: number;
  content_going_live_goal?: number;
  find_emails?: boolean;
  send_emails?: boolean;
  take_rate?: number;
  monthly_take_cap?: number;
  status?: number;
  formats?: SupportedFormatIntegers[];
  objectives?: CampaignObjective[];
  mode?: CampaignMode;
  content_review_mode?: ContentReviewMode;
  creative_asset_requests?: string;
  usage_rights_policy?: UsageRightsPolicy;
  usage_rights_duration?: UsageRightsDuration;
  max_offer_cap?: number;
  max_offer_action?: MaxOfferAction;
  has_product_access?: boolean;
  product_access_description?: string;
  product_access_info_needed_from_creator?: ProductAccessInfoNeededFromCreatorType;
  product_access_other_info_needed_from_creator?: string;
  product_access_info_to_provide_to_creator?: ProductAccessInfoToProvideToCreatorType;
  product_access_other_info_to_provide_to_creator?: string;
  product_access_same_access_code?: string;
  target_cost_per_creator?: number;
  referral_link_type?: ReferralLinkType;
  referral_link_level?: ReferralLinkLevel;
  referral_link_template?: string;
  has_promo_code?: boolean;
  fixed_promo_code?: string;
  ops_owner?: User;
  campaign_params?: CampaignParametersV2;
  brand_auto_accept?: boolean;
  internal_auto_accept?: boolean;
  concept_review?: boolean;
  draft_review?: boolean;
  us_only?: boolean;
}

export interface CampaignResponse {
  results: Campaign[];
  count: number;
  next?: number | null;
  previous?: number | null;
}

// Keep this in sync with campaigns.models.CampaignV2.BudgetType
export enum BudgetType {
  Once = 0,
  Monthly = 1,
  Quarterly = 2,
  Yearly = 3,
}

export enum CampaignStatus {
  Unknown = 0,
  Draft = 1,
  Onboarding = 2,
  Active = 3,
  Paused = 4,
  Completed = 5,
}

export enum CreativeBriefStatus {
  Off = 0,
  Draft = 1,
  Active = 2,
}

export enum CampaignObjective {
  CONVERSION = 0,
  CONSIDERATION = 1,
  AWARENESS = 2,
  TRAFFIC = 3,
}

export enum CampaignMode {
  BALANCED = 0,
  CONTROLLED = 1,
  AUTOPILOT = 2,
  SCALED = 3,
}

export enum ContentReviewMode {
  BALANCED = 0,
  LIGHT = 1,
  COMPREHENSIVE = 2,
}

export enum UsageRightsPolicy {
  DEFAULT = 0,
  UPFRONT = 1,
  POST_LIVE = 2,
}

export const UsageRightsPolicytoFormatString = {
  0: "Default",
  1: "Upfront",
  2: "Post Live",
};

export enum MaxOfferAction {
  Review = 0,
  Reject = 1,
}

export enum ProductAccessInfoNeededFromCreatorType {
  NONE = 0,
  SHIPPING_ADDRESS = 1,
  ACCOUNT_EMAIL_ADDRESS = 2,
  ACCOUNT_USERNAME = 3,
  OTHER = 4,
}

export enum ProductAccessInfoToProvideToCreatorType {
  NONE = 0,
  ACCESS_CODE = 1,
  TRACKING_CODE = 2,
  OTHER = 3,
}

export enum ReferralLinkType {
  NONE = 1,
  CUSTOM = 2,
  AUTOMATIC = 3,
}

export enum ReferralLinkLevel {
  NONE = 1,
  DELIVERABLE = 2,
  CONTRACT = 3,
}

export interface CampaignListResponse {
  // Follows the drf json response format
  results: Campaign[];
  count: number;
  next?: number | null;
  previous?: number | null;
}

export type CampaignParametersV2 = {
  id: number;
  // This is actually a Brand object but avoiding circular imports
  brand?: any;
  name: string;

  source_youtube: boolean;
  source_tiktok: boolean;
  source_instagram: boolean;

  youtube_min_subscribers: bigint;
  youtube_max_subscribers: bigint;
  youtube_min_median_views: bigint;
  youtube_max_median_views: bigint;
  youtube_min_videos_28d: bigint;
  youtube_min_videos_60d: bigint;
  youtube_min_videos_90d: bigint;
  youtube_min_videos_total: bigint;

  tiktok_min_subscribers: bigint;
  tiktok_max_subscribers: bigint;
  tiktok_min_median_views: bigint;
  tiktok_max_median_views: bigint;
  tiktok_min_videos_28d: bigint;
  tiktok_min_videos_60d: bigint;
  tiktok_min_videos_90d: bigint;
  tiktok_min_videos_total: bigint;

  instagram_min_subscribers: bigint;
  instagram_max_subscribers: bigint;
  instagram_min_median_views: bigint;
  instagram_max_median_views: bigint;
  instagram_min_videos_28d: bigint;
  instagram_min_videos_60d: bigint;
  instagram_min_videos_90d: bigint;
  instagram_min_videos_total: bigint;

  countries?: string[]; // Optional, since it can be null
  languages?: string[]; // Optional, since it can be null
  us_only: boolean;
  use_likely_email_filter: boolean;
  use_creator_likely_email_filter: boolean;
  requirements?: string;
};

export interface CampaignParameterResponse {
  results: CampaignParametersV2[];
  count: number;
  next?: number | null;
  previous?: number | null;
}

export interface CampaignAdGroup {
  id: number;
  name: string;
  description?: string;
  brief?: CreativeBrief;
  targeting?: AdGroupTargeting;
  status?: number;
  landing_page?: string;
  brand_blurb?: string;
  blurb?: string;
  creator_fit_blurb?: string;
  creator_sets?: number[]; // Assuming this is an array of IDs
  target_cpm?: number;
  brand_link?: string;
  brand_display_name?: string;
}

export enum CampaignAdGroupStatus {
  Unknown = 0,
  Deleted = 1,
  Archived = 2,
  Completed = 3,
  Paused = 4,
  Draft = 5,
  Active = 6,
}

export interface AdGroupTargeting {
  creator_sets: number[]; // Assuming this is an array of IDs
}

export interface CreatorReviewToDoCount {
  total?: number;
  youtube?: number;
  instagram?: number;
  tiktok?: number;
  linkedin?: number;
  twitter?: number;
}

export interface AdGroupToDoSummary {
  script_reviews: number;
  video_reviews: number;
  live_verification: number;
  contract_reviews: number;
  unread_messages: number;
  referral_links: number;
  promo_codes: number;
  product_access_reviews: number;
  ad_group_name?: string;
  total: number;
}

export interface CampaignToDoSummary {
  total: number;
  campaign_name: string;
  creator_review?: CreatorReviewToDoCount;
  ad_groups?: Record<number, AdGroupToDoSummary>;
}

export interface CreatorSetTodoSummary {
  creator_set_name: string;
  creator_set_id: number;
  creator_review: CreatorReviewToDoCount;
}

export interface BrandToDoSummary {
  campaign_total: number;
  creatorset_total: number;
  campaigns?: Record<number, CampaignToDoSummary>;
  creatorsets?: Record<number, CreatorSetTodoSummary>;
}

export interface BrandToDoSummaryResponse {
  success: boolean;
  todos?: BrandToDoSummary;
}

export interface CampaignOverviewResult {
  results: CampaignAdGroupOverview[];
}

export interface AdGroupProgressReport {
  id: number;
  name: string;
  budget: number;
  completed: number;
  contracted: number;
  monthly_budget?: number;
  monthly_completed?: number;
  monthly_contracted?: number;
  live: number;
  views: number;
  clicks?: number;
}

export interface CampaignProgressReportResponse {
  success: boolean;
  progress?: Record<number, AdGroupProgressReport>;
  budget?: number;
  monthly_budget?: number;
  budget_type?: BudgetType;
  spent_before_this_month?: number;
  spent_off_platform?: number;
}

export interface CampaignAdGroupOverview {
  adGroup: CampaignAdGroup;
  // 1-time spend
  totalBudget?: number | null;
  totalBudgetSpent?: number | null; // should these be currency objects instead?
  totalBudgetAllocated?: number | null;
  // recurring spend
  monthlyBudget?: number | null;
  monthlyBudgetSpent?: number | null;
  monthlyBudgetAllocated?: number | null; // assume allocated includes spent
  numConversions?: number | null;
  views?: number | null;
  numLiveContent?: number | null;
  cpm?: number | null;
  clicks?: number | null;
  cpc?: number | null;
}

export interface CampaignCreatorProgressResponse {
  success: boolean;
  cumulative: Record<string, Record<string, number>>;
  hasEarlierLogs: boolean;
  window: Record<string, Record<string, number>>;
}

export interface CreatorProgressDetail {
  creatorId: number;
  canonicalId: number | null;
  firstName: string | null;
  lastName: string | null;
  displayUsername: string | null;
  socialHandles: SocialHandle[];
  avatarUrl: string | null;
  actions: Record<string, Record<string, any>>;
  contractDetails: Record<string, any>;
}

export interface CampaignCreatorListResponse {
  success: boolean;
  creators: CreatorProgressDetail[];
}

export default null;
