import { Box, Flex, Datapoint } from "gestalt";
import "gestalt/dist/gestalt.css";
import React from "react";
import { getAbbreviatedNumber } from "utils/AnalyticsUtils";
import { StatsEntryResponse } from "social/utils";
import { toTitleCase } from "utils/StringUtils";
import Spacer from "components/Spacer";

/**
 * Displays a collection of grouped stats together in a card. The caller before hand
 * has collected the stats together s.t. they are a useful grouping.
 */
const StatsCard = ({
  stats,
  metric_names,
}: {
  stats: StatsEntryResponse[];
  metric_names: string[];
}) => {
  const StatCardDataPoints = metric_names.map((metric_name) => {
    const stat = stats.filter((entry) => entry.metric === metric_name);
    if (stat.length) {
      return {
        metric_name,
        value: stat ? stat[0].value : undefined,
      };
    }
    return null;
  });
  return (
    <Box direction="column">
      <Flex flex="grow" justifyContent="between" gap={12}>
        {StatCardDataPoints.map((p) =>
          p ? (
            <Datapoint
              key={p.metric_name}
              title={toTitleCase(p.metric_name)}
              value={getAbbreviatedNumber(p.value)}
            />
          ) : null,
        )}
        <Spacer width={1} />
      </Flex>
    </Box>
  );
};

export default StatsCard;
