interface Vertical {
  name: string;
  subtext: string;
  value?: string;
}

const VERTICALS_RAW_V1: Vertical[] = [
  { name: "Art & Photography", subtext: "Everything from behind the lens" },
  { name: "Auto", subtext: "Fast cars, slow cars, and everything in between" },
  { name: "Beauty & Makeup", subtext: "Blending, contouring, and highlighting top beauty trends" },
  { name: "Business & Careers", subtext: "Making the most of your work" },
  { name: "Fashion & Style", subtext: "Whats your OOTD?" },
  { name: "Finance", subtext: "All things money related" },
  { name: "Food & Drink", subtext: "Recipes and reservations" },
  { name: "Health & Fitness", subtext: "Improving physical wellbeing" },
  { name: "Home & Garden", subtext: "DIY, decor, and other projects around the house" },
  { name: "Mind & Body", subtext: "Spiritual gurus, yogis, and peace seekers" },
  { name: "Movies, TV, & Books", subtext: "All things entertainment and pop culture" },
  { name: "Music", subtext: "Chart toppers and show stoppers" },
  { name: "Outdoors & Nature", subtext: "Exploring the beautiful world we call home" },
  { name: "Parenting & Family", subtext: "For the whole gang - mom, dad, and kids" },
  { name: "Pets", subtext: "For all our non-human best friends" },
  { name: "Self Development", subtext: "Becoming the best version of yourself" },
  { name: "Sports", subtext: "Touchdown, field goals, and everything in between" },
  { name: "Technology", subtext: "The newest trends in electronics and apps" },
  { name: "Teens", subtext: "What's relevant to Gen-Z" },
  { name: "Travel", subtext: "All your adventures away from home" },
  { name: "Other", subtext: "Anything we missed above" },
];

const VERTICALS_RAW_V2: Vertical[] = [
  { name: "Art, Photography & Crafts", subtext: "" },
  { name: "Automotive & Transportation", subtext: "" },
  { name: "Babies, Parenting & Family", subtext: "" },
  { name: "Beauty & Makeup", subtext: "" },
  { name: "Business & Careers", subtext: "" },
  { name: "Comedy", subtext: "" },
  { name: "Education & Self-Development", subtext: "" },
  { name: "Fashion & Style", subtext: "" },
  { name: "Film & Animation", subtext: "" },
  { name: "Finance & Investing", subtext: "" },
  { name: "Food & Drink", subtext: "" },
  { name: "Gaming", subtext: "" },
  { name: "Health & Wellness", subtext: "" },
  { name: "Home & Garden", subtext: "" },
  { name: "Media & Entertainment", subtext: "" },
  { name: "Music", subtext: "" },
  { name: "News & Politics", subtext: "" },
  { name: "Nonprofits & Activism", subtext: "" },
  { name: "Outdoors & Nature", subtext: "" },
  { name: "People & Blogs", subtext: "" },
  { name: "Pets & Animals", subtext: "" },
  { name: "Real Estate", subtext: "" },
  { name: "Science & Technology", subtext: "" },
  { name: "Sports & Fitness", subtext: "" },
  { name: "Travel & Tourism", subtext: "" },
  { name: "Other", subtext: "" },
];

// strips out all non alphanumeric characters from the string
function nameToValue(name: string) {
  return name.replace(/[^0-9A-Z]+/gi, "");
}

const VERTICALS: Vertical[] = VERTICALS_RAW_V2.map((vertical) => ({
  name: vertical.name,
  subtext: vertical.subtext,
  value: nameToValue(vertical.name),
}));

export { Vertical, VERTICALS };
