import React, { useEffect } from "react";

import {
  Button,
  Checkbox,
  Divider,
  Group,
  Indicator,
  Modal,
  NumberInput,
  RangeSlider,
  SimpleGrid,
  Stack,
  Title,
} from "@mantine/core";

import { useDisclosure } from "@mantine/hooks";

import { IconAdjustmentsHorizontal } from "@tabler/icons-react";
import {
  CountryFilterOption,
  CountryCodeToLabel,
  FilterConfigs,
  FilterType,
} from "components/discovery/search/FilterConstants";

import PriceFilter from "components/discovery/search/PriceFilter";

export function RangeFilter({
  rangeValue,
  setRangeValue,
  min,
  max,
  stepSize,
  marks,
  hideNumberInput,
  showsSuffix,
}: {
  rangeValue: [number, number];
  setRangeValue: (value: [number, number]) => void;
  min: number;
  max: number;
  stepSize: number;
  marks: { value: number; label: string }[];
  hideNumberInput?: boolean;
  showsSuffix?: boolean;
}) {
  return (
    <>
      <RangeSlider
        color="black"
        value={rangeValue}
        onChange={setRangeValue}
        min={min}
        max={max}
        minRange={stepSize}
        step={stepSize}
        marks={marks}
        label={null}
      />
      {hideNumberInput ? (
        <Group />
      ) : (
        <Group justify="center" align="flex-end">
          <NumberInput
            label="Minimum"
            value={rangeValue[0]}
            onChange={(num: number) => setRangeValue([num || min, rangeValue[1]])}
            clampBehavior="strict"
            min={min}
            max={rangeValue[1] - stepSize}
            thousandSeparator=","
            hideControls
          />
          <Title order={3} fw="200" mb={4}>
            -
          </Title>
          <NumberInput
            label="Maximum"
            value={rangeValue[1]}
            onChange={(num: number) => setRangeValue([rangeValue[0], num || max])}
            clampBehavior="strict"
            min={rangeValue[0] + stepSize}
            max={max}
            suffix={(showsSuffix && rangeValue[1] === max) ? "+" : ""}
            thousandSeparator=","
            hideControls
          />
        </Group>
      )}
    </>
  );
}

function LabeledRangeFilter({
  rangeValue,
  setRangeValue,
  filterType,
  title,
  hideNumberInput,
}: {
  rangeValue: [number, number];
  setRangeValue: (value: [number, number]) => void;
  filterType: FilterType;
  title: string;
  hideNumberInput?: boolean;
}) {
  const { min, max, stepSize, marks } = FilterConfigs[filterType];

  return (
    <>
      <Title order={3} fw="500">
        {title}
      </Title>
      <RangeFilter
        rangeValue={rangeValue}
        setRangeValue={setRangeValue}
        min={min}
        max={max}
        stepSize={stepSize}
        marks={marks}
        hideNumberInput={hideNumberInput}
      />
    </>
  );
}

function getNumActiveFilters(
  filterTypeToValues: Record<FilterType, any>,
  selectedCountries: CountryFilterOption[],
  recentlyActive: boolean,
  priceFilters: { [key: number]: boolean },
) {
  return (
    Object.keys(filterTypeToValues).reduce((acc, filterType) => {
      const filterValue = filterTypeToValues[filterType as FilterType];
      const { min, max } = FilterConfigs[filterType as FilterType];
      if (min === filterValue[0] && max === filterValue[1]) {
        return acc;
      }
      return 1 + acc;
    }, 0) +
    selectedCountries.length +
    (recentlyActive ? 1 : 0) +
    (Object.values(priceFilters).some((value) => value) ? 1 : 0)
  );
}

function CountryFilters({
  selectedCountries,
  setSelectedCountries,
}: {
  selectedCountries: CountryFilterOption[];
  setSelectedCountries: (countries: CountryFilterOption[]) => void;
}) {
  return (
    <>
      <Title order={3} fw="500">
        Countries
      </Title>
      <SimpleGrid cols={2} px="sm">
        {Object.keys(CountryFilterOption).map((country: CountryFilterOption) => (
          <Checkbox
            key={country.valueOf()}
            color="black"
            checked={selectedCountries.includes(country)}
            label={CountryCodeToLabel[country]}
            onChange={(event) => {
              if (event.currentTarget.checked) {
                if (!selectedCountries.includes(country)) {
                  setSelectedCountries([...selectedCountries, country]);
                }
              } else {
                setSelectedCountries(
                  selectedCountries.filter((selectedCountry) => selectedCountry !== country),
                );
              }
            }}
          />
        ))}
      </SimpleGrid>
    </>
  );
}

function OtherFilters({
  recentlyActive,
  setRecentlyActive,
}: {
  recentlyActive: boolean;
  setRecentlyActive: (value: boolean) => void;
}) {
  return (
    <Stack>
      <Title order={3} fw="500">
        Other
      </Title>
      <Checkbox
        color="black"
        checked={recentlyActive}
        label="Recently Active"
        onChange={(event) => setRecentlyActive(event.currentTarget.checked)}
      />
    </Stack>
  );
}

export default function SearchFilters({
  selectedCountries,
  setSelectedCountries,
  followersRange,
  setFollowersRange,
  medianViewsRange,
  setMedianViewsRange,
  selectedPrices,
  setSelectedPrices,
  recentlyActive,
  setRecentlyActive,
  handleSubmit,
  numActiveFilters,
  setNumActiveFilters,
  enabled,
}: {
  selectedCountries: CountryFilterOption[];
  setSelectedCountries: (countries: CountryFilterOption[]) => void;
  followersRange: [number, number];
  setFollowersRange: (value: [number, number]) => void;
  medianViewsRange: [number, number];
  setMedianViewsRange: (value: [number, number]) => void;
  selectedPrices: { [key: number]: boolean };
  setSelectedPrices: (value: { [key: number]: boolean }) => void;
  recentlyActive: boolean;
  setRecentlyActive: (value: boolean) => void;
  handleSubmit: () => void;
  numActiveFilters: number;
  setNumActiveFilters: (value: number) => void;
  enabled: boolean;
}) {
  const [opened, { open, close }] = useDisclosure();

  useEffect(() => {
    const currentNumActiveFilters = getNumActiveFilters(
      {
        [FilterType.FOLLOWERS]: followersRange,
        [FilterType.MEDIAN_VIEWS]: medianViewsRange,
      },
      selectedCountries,
      recentlyActive,
      selectedPrices,
    );
    setNumActiveFilters(currentNumActiveFilters);
  }, [followersRange, medianViewsRange, selectedCountries, recentlyActive, selectedPrices]);

  const handleClearAllFilters = () => {
    const filterTypes = {
      [FilterType.FOLLOWERS]: setFollowersRange,
      [FilterType.MEDIAN_VIEWS]: setMedianViewsRange,
    };
    Object.keys(filterTypes).forEach((filterType) => {
      const { min, max } = FilterConfigs[filterType as FilterType];
      filterTypes[filterType as FilterType]([min, max]);
    });
    setSelectedPrices({ 1: false, 2: false, 3: false, 4: false });
    setSelectedCountries([]);
  };

  return (
    <>
      <Modal withCloseButton={false} opened={opened} onClose={close} size="lg" p={0}>
        <Stack>
          <CountryFilters
            selectedCountries={selectedCountries}
            setSelectedCountries={setSelectedCountries}
          />
          <LabeledRangeFilter
            rangeValue={followersRange}
            setRangeValue={setFollowersRange}
            filterType={FilterType.FOLLOWERS}
            title="Followers"
          />
          <LabeledRangeFilter
            rangeValue={medianViewsRange}
            setRangeValue={setMedianViewsRange}
            filterType={FilterType.MEDIAN_VIEWS}
            title="Median Views"
          />
          <PriceFilter active={selectedPrices} setActive={setSelectedPrices} />
          {/* <OtherFilters recentlyActive={recentlyActive} setRecentlyActive={setRecentlyActive} /> */}
          <Divider mx="-md" />
          <Group justify="space-between">
            <Button variant="outline" color="black" onClick={handleClearAllFilters}>
              Clear All
            </Button>
            <Button
              color="black"
              disabled={!enabled}
              onClick={() => {
                handleSubmit();
                close();
              }}>
              Apply {numActiveFilters > 0 ? numActiveFilters : ""} Filters
            </Button>
          </Group>
        </Stack>
      </Modal>
      <Indicator
        color="black"
        label={numActiveFilters}
        inline
        disabled={!numActiveFilters}
        size="1rem"
        radius="xl">
        <Button
          onClick={open}
          variant="default"
          leftSection={<IconAdjustmentsHorizontal size="1rem" />}>
          Filters
        </Button>
      </Indicator>
    </>
  );
}
