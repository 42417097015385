import { Button, Space, Tooltip } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { IconCheck, IconNotes } from "@tabler/icons-react";
import DeliverablePackage from "models/DeliverablePackage";
import React from "react";

const ButtonGroup = ({
  isAdmin,
  isOps,
  validate,
  disableSave,
  disableWithOffer,
  disableClear,
  openDrawer,
  addPackage,
  savePackages,
  setPackages,
  setPackagePrice,
  adminOnly = true,
}: {
  isAdmin: boolean;
  isOps: boolean;
  validate: boolean;
  disableSave: boolean;
  disableWithOffer: boolean;
  disableClear: boolean;
  openDrawer: () => void;
  addPackage: () => void;
  savePackages: () => void;
  setPackages: React.Dispatch<React.SetStateAction<Array<[number, DeliverablePackage]>>>;
  setPackagePrice: React.Dispatch<React.SetStateAction<number | string>>;
  adminOnly?: boolean;
}) => {
  let disableForOps = true;
  if (!adminOnly) disableForOps = false;
  return (
    <>
      <Button variant="default" leftSection={<IconNotes size={18} />} onClick={openDrawer}>
        View Offer Notes
      </Button>
      <Space my={4} />
      {disableWithOffer && disableForOps ? (
        <Tooltip label="Cannot save any new packages since there is an active offer.">
          <Button color="blue" data-disabled onClick={addPackage}>
            Add Deliverable Package
          </Button>
        </Tooltip>
      ) : (
        <Button color="blue" disabled={validate} onClick={addPackage}>
          Add Deliverable Package
        </Button>
      )}
      <Button
        color="red"
        variant="outline"
        disabled={disableClear}
        onClick={() => {
          setPackages([]);
          setPackagePrice(0);
        }}>
        Clear All Manually Added Packages
      </Button>

      {disableWithOffer && disableForOps ? (
        <Tooltip label="Cannot save any new packages since there is an active offer.">
          <Button
            data-disabled
            leftSection={<IconCheck size={18} />}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault()}>
            Save All Packages + Set Ready to Review
          </Button>
        </Tooltip>
      ) : (
        <Button
          leftSection={<IconCheck size={18} />}
          color="green"
          disabled={disableSave}
          onClick={savePackages}>
          Save All Packages + Set Ready to Review
        </Button>
      )}
    </>
  );
};

export default ButtonGroup;
