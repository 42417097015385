/* eslint-disable @typescript-eslint/no-var-requires */
// const hero = require("./hero.png");
const hero = require("./hero_clean.png");
const campaign = require("./campaign.png");
const campaignPerformance = require("./campaign_performance.png");
const boringStuff = require("./boring_stuff.png");
/* eslint-enable @typescript-eslint/no-var-requires */

const IMAGES = {
  hero,
  campaign,
  campaignPerformance,
  boringStuff,
};

export default IMAGES;
