import React from "react";

// Unfortunately, we need two different maps to make sure the spelling is ok
export const PLATFORM_ID_TO_LABEL = new Map<string, string>([
  ["all", "All"],
  ["youtube", "YouTube"],
  ["instagram", "Instagram"],
  ["tiktok", "TikTok"],
]);

export const PLATFORM_ID_TO_LABEL_NO_ALL = new Map<string, string>([
  ["youtube", "YouTube"],
  ["instagram", "Instagram"],
  ["tiktok", "TikTok"],
]);

export default null;
