import React, { useEffect } from "react";
import { Text } from "@mantine/core";

import { API_URL } from "configs/Configs";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "auth/firebaseAuthHelpers";
import { createRequestWithFirebaseToken, handleResult } from "utils/ApiUtils";

const INSTAGRAM_CALLBACK_URL = `${API_URL}/authentication/instagram/callback/`;

const InstagramCallback = () => {
  const [user, loading] = useAuthState(auth);

  useEffect(() => {
    const saveCredentials = async () => {
      // extract code from url and send to backend
      const currentUrl = window.location.href;
      const { searchParams } = new URL(currentUrl);
      const code = searchParams.get("code");
      if (code) {
        const request = await createRequestWithFirebaseToken({
          url: INSTAGRAM_CALLBACK_URL,
          method: "POST",
          body: JSON.stringify({
            code,
            email: user.email,
          }),
        });
        const response = await handleResult(request);
        window.close();
      }
    };
    if (!loading && user !== null) {
      saveCredentials();
    }
  }, [loading]);
  return <Text>Thank you for authenticating! The window will automatically close.</Text>;
};

export default InstagramCallback;
