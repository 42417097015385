import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  Center,
  Flex,
  Stack,
  SegmentedControl,
  Table,
  Text,
  Title,
} from "@mantine/core";

import { useLocation, useParams } from "react-router-dom";
import { useCampaignAppContext } from "campaigns/CampaignAppShell";

import { getContractSummaryMetrics } from "components/contracts/common/Api";
import { formatAmount } from "components/contracts/dashboard/Utils";
import { ContractFeedType } from "campaigns/navbar/consts";
import ContentReviewFeed from "components/contracts/review/ContentReviewFeed";

interface ContractSummaryMetricsResponse {
  num_contract_reviews: number;
  contract_review_gmv: number;
  num_not_started: number;
  not_started_gmv: number;
  num_in_production: number;
  in_production_gmv: number;
  num_complete: number;
  complete_gmv: number;
}

interface DeliverableSummaryMetricsResponse {
  [deliverable_type: string]: {
    num_contract_review: number;
    num_not_started: number;
    num_concept_production: number;
    num_video_production: number;
    num_ready_to_go_live: number;
    num_live_verification: number;
    num_live: number;
  };
}

function ContractSummaryMetrics({ metrics }: { metrics: ContractSummaryMetricsResponse }) {
  if (!metrics) {
    return null;
  }

  return (
    <Table>
      <Table.Thead>
        <Table.Tr>
          <Table.Th />
          <Table.Th>
            <Text fw="600" size="sm">
              Contract Review
            </Text>
          </Table.Th>
          <Table.Th>
            <Text fw="600" size="sm">
              Not Started
            </Text>
          </Table.Th>
          <Table.Th>
            <Text fw="600" size="sm">
              In Production
            </Text>
          </Table.Th>
          <Table.Th>
            <Text fw="600" size="sm">
              Complete
            </Text>
          </Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        <Table.Tr>
          <Table.Td>
            <Text fw="600" size="sm">
              Contracts
            </Text>
          </Table.Td>
          <Table.Td>{metrics.num_contract_reviews}</Table.Td>
          <Table.Td>{metrics.num_not_started}</Table.Td>
          <Table.Td>{metrics.num_in_production}</Table.Td>
          <Table.Td>{metrics.num_complete}</Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw="600" size="sm">
              Contract Value
            </Text>
          </Table.Td>
          <Table.Td>{formatAmount(metrics.contract_review_gmv)}</Table.Td>
          <Table.Td>{formatAmount(metrics.not_started_gmv)}</Table.Td>
          <Table.Td>{formatAmount(metrics.in_production_gmv)}</Table.Td>
          <Table.Td>{formatAmount(metrics.complete_gmv)}</Table.Td>
        </Table.Tr>
      </Table.Tbody>
    </Table>
  );
}

function DeliverableSummaryMetrics({ metrics }: { metrics: DeliverableSummaryMetricsResponse }) {
  if (!metrics) {
    return null;
  }

  const rowNames = {
    tiktok: "TikTok Videos",
    instagram: "Instagram Reels",
    youtube_short: "YouTube Shorts",
    youtube_integrated: "YouTube Integrated",
    youtube_dedicated: "YouTube Dedicated",
  };

  const rowOrder = [
    "tiktok",
    "instagram",
    "youtube_short",
    "youtube_integrated",
    "youtube_dedicated",
  ];

  const getTotal = (property: keyof DeliverableSummaryMetricsResponse["row1"]) =>
    rowOrder.map((rowKey) => metrics[rowKey][property]).reduce((acc, val) => acc + val, 0);

  return (
    <Table>
      <Table.Thead>
        <Table.Tr>
          <Table.Th />
          <Table.Th>
            <Text fw="600" size="sm">
              Contract Review
            </Text>
          </Table.Th>
          <Table.Th>
            <Text fw="600" size="sm">
              Not Started
            </Text>
          </Table.Th>
          <Table.Th>
            <Text fw="600" size="sm">
              Concept Production
            </Text>
          </Table.Th>
          <Table.Th>
            <Text fw="600" size="sm">
              Video Production
            </Text>
          </Table.Th>
          <Table.Th>
            <Text fw="600" size="sm">
              Ready to Go Live
            </Text>
          </Table.Th>
          <Table.Th>
            <Text fw="600" size="sm">
              Live Verification
            </Text>
          </Table.Th>
          <Table.Th>
            <Text fw="600" size="sm">
              Live
            </Text>
          </Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {rowOrder.map((rowKey) => (
          <Table.Tr key={rowKey}>
            <Table.Td>
              <Text fw="600" size="sm">
                {rowNames[rowKey as keyof typeof rowNames]}
              </Text>
            </Table.Td>
            <Table.Td>{metrics[rowKey].num_contract_review}</Table.Td>
            <Table.Td>{metrics[rowKey].num_not_started}</Table.Td>
            <Table.Td>{metrics[rowKey].num_concept_production}</Table.Td>
            <Table.Td>{metrics[rowKey].num_video_production}</Table.Td>
            <Table.Td>{metrics[rowKey].num_ready_to_go_live}</Table.Td>
            <Table.Td>{metrics[rowKey].num_live_verification}</Table.Td>
            <Table.Td>{metrics[rowKey].num_live}</Table.Td>
          </Table.Tr>
        ))}
        <Table.Tr>
          <Table.Td>
            <Text fw="600" size="sm">
              Total
            </Text>
          </Table.Td>
          <Table.Td>
            <Text fw="600" size="sm">
              {getTotal("num_contract_review")}
            </Text>
          </Table.Td>
          <Table.Td>
            <Text fw="600" size="sm">
              {getTotal("num_not_started")}
            </Text>
          </Table.Td>
          <Table.Td>
            <Text fw="600" size="sm">
              {getTotal("num_concept_production")}
            </Text>
          </Table.Td>
          <Table.Td>
            <Text fw="600" size="sm">
              {getTotal("num_video_production")}
            </Text>
          </Table.Td>
          <Table.Td>
            <Text fw="600" size="sm">
              {getTotal("num_ready_to_go_live")}
            </Text>
          </Table.Td>
          <Table.Td>
            <Text fw="600" size="sm">
              {getTotal("num_live_verification")}
            </Text>
          </Table.Td>
          <Table.Td>
            <Text fw="600" size="sm">
              {getTotal("num_live")}
            </Text>
          </Table.Td>
        </Table.Tr>
      </Table.Tbody>
    </Table>
  );
}

function SummaryMetrics({
  contractMetrics,
  deliverableMetrics,
}: {
  contractMetrics: ContractSummaryMetricsResponse;
  deliverableMetrics: DeliverableSummaryMetricsResponse;
}) {
  if (!contractMetrics && !deliverableMetrics) {
    return null;
  }

  const [segment, setSegment] = useState("contracts");

  return (
    <Center mb="md">
      <Box w={1050}>
        <Card radius="md" withBorder shadow="xs">
          <Stack gap="xs">
            <Flex justify="space-between" align="flex-start">
              <Title fw="500" order={2}>
                Summary
              </Title>
              <SegmentedControl
                size="xs"
                value={segment}
                onChange={(value) => setSegment(value)}
                data={[
                  { label: "Contracts", value: "contracts" },
                  { label: "Deliverables", value: "deliverables" },
                ]}
              />
            </Flex>
            {segment === "contracts" && <ContractSummaryMetrics metrics={contractMetrics} />}
            {segment === "deliverables" && (
              <DeliverableSummaryMetrics metrics={deliverableMetrics} />
            )}
          </Stack>
        </Card>
      </Box>
    </Center>
  );
}

export default function AllContractsView() {
  const { selectedAdGroup } = useCampaignAppContext();
  const { adGroupId: adGroupIdFromParams } = useParams<{ adGroupId: string }>();

  const [contractSummaryMetrics, setContractSummaryMetrics] =
    useState<ContractSummaryMetricsResponse>(null);
  const [deliverableSummaryMetrics, setDeliverableSummaryMetrics] =
    useState<DeliverableSummaryMetricsResponse>(null);

  const adGroupIdToUse = selectedAdGroup?.id?.toString() || adGroupIdFromParams;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const feedType = (queryParams.get("type") as ContractFeedType) || ContractFeedType.All;

  if (!adGroupIdToUse) {
    throw new Error("Must specify adGroupId");
  }

  useEffect(() => {
    getContractSummaryMetrics({ adGroupId: adGroupIdToUse }).then((response) => {
      const { success, contractMetrics, deliverableMetrics } = response;

      if (success) {
        setContractSummaryMetrics(contractMetrics);
        setDeliverableSummaryMetrics(deliverableMetrics);
      }
    });
  }, [adGroupIdToUse]);

  return (
    <>
      {feedType === ContractFeedType.All && (
        <SummaryMetrics
          contractMetrics={contractSummaryMetrics}
          deliverableMetrics={deliverableSummaryMetrics}
        />
      )}
      <ContentReviewFeed adGroupId={adGroupIdToUse} />
    </>
  );
}
