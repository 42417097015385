import React, { useState } from "react";
import { Box } from "gestalt";
import "gestalt/dist/gestalt.css";
import { User, updateProfile } from "firebase/auth";

import HeaderMessage from "components/HeaderWithSubtitle";
import Spacer from "components/Spacer";
import { useUser } from "utils/UserContext";
import LoadingScreen from "components/LoadingScreen";
import { SaveButton, StatusText, SaveStatus } from "pages/settings/SettingsComponents";
import { updateUserInfo } from "utils/UserUtils";
import { ErrorInfo } from "utils/ApiUtils";
import EditUsername from "components/user/EditUsername";
import EditDisplayName from "components/user/EditDisplayName";
import EditAvatarBanner from "components/user/EditAvatarBanner";
import { getSettingsUser } from "pages/settings/Settings";

const SettingsAccount = () => {
  const firebaseUser: User = getSettingsUser();
  const [user, isLoading, updateUser] = useUser();

  const [saveEnabled, setSaveEnabled] = useState(false);
  const [saveStatus, setSaveStatus] = useState(SaveStatus.INIT);
  const [errorMessage, setErrorMessage] = useState("");

  const onSave = async () => {
    try {
      setSaveEnabled(false);
      const firebaseToken = await firebaseUser.getIdToken();
      await updateUserInfo(user, firebaseToken);
      await updateProfile(firebaseUser, { displayName: user.name });
      setSaveStatus(SaveStatus.SUCCESS);
    } catch (error) {
      const errorInfo: ErrorInfo = JSON.parse(error.message);
      // TODO (leon): change lib to es2022 so we can use Object hasOwn instead
      // This is a bit simplified, but if there is an error message in validating
      // username, it is almost certainly because the username isn't available.
      // eslint-disable-next-line no-prototype-builtins
      if (errorInfo.result && errorInfo.result.hasOwnProperty("username"))
        setErrorMessage("Username not available. Please try another one.");
      setSaveStatus(SaveStatus.FAILURE);
      throw error;
    }
  };

  const resetStatus = () => {
    setSaveStatus(SaveStatus.INIT);
    setErrorMessage("");
  };

  const onDisplayNameChange = (value: string) => {
    updateUser({ name: value });
    resetStatus();
  };

  const onUsernameChange = (value: string) => {
    updateUser({ username: value });
    resetStatus();
  };

  return isLoading ? (
    <LoadingScreen />
  ) : (
    <Box display="flex" direction="column" width={600}>
      <HeaderMessage header="Edit your account settings" subtitle="" alignItems="start" />
      <Spacer height={48} />
      <EditAvatarBanner firebaseUser={firebaseUser} user={user} />
      <Spacer height={16} />
      <EditDisplayName
        name={user.name}
        onChange={onDisplayNameChange}
        setIsValid={setSaveEnabled}
      />
      <Spacer height={16} />
      <EditUsername
        firebaseUser={firebaseUser}
        user={user}
        setIsValid={setSaveEnabled}
        onChange={onUsernameChange}
      />
      <Spacer height={16} />
      <SaveButton enabled={saveEnabled} onSave={onSave} />
      <Spacer height={16} />
      <StatusText status={saveStatus} message={errorMessage} />
    </Box>
  );
};

export default SettingsAccount;
