import React, { useEffect, useState } from "react";

import {
  AppShell,
  Avatar,
  Button,
  Flex,
  Group,
  MantineStyleProp,
  ScrollArea,
  Stack,
  Text,
} from "@mantine/core";
import {
  IconBuildingCommunity,
  IconCash,
  IconChartLine,
  IconHome,
  IconLayoutDashboard,
  IconListLetters,
  IconPlus,
  IconPremiumRights,
  IconProgress,
  IconSearch,
  IconWallet,
  IconWindmill,
  IconUsers,
  IconUserSearch,
  IconUsersGroup,
  IconAdjustmentsHorizontal,
} from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";

import Spacer from "components/Spacer";
import { User } from "models/User";

// copied from "campaigns/navbar/main/CompanyAndUserHeader"
const CompanyAndUserHeader = ({ user }: { user: User }) => {
  const companyName = user.brand?.brand_name || user.name;
  return (
    <Group justify="space-between" wrap="nowrap">
      <Group gap={0} wrap="nowrap">
        <Avatar w={48} h={48} src={user.brand?.avatar_url} style={{ flex: "none" }} />
        <Spacer width={12} />
        <Stack gap={0}>
          {companyName ? (
            <Text
              style={{
                fontSize: "14px",
                fontWeight: "700",
                lineHeight: "155%",
              }}>
              {companyName}
            </Text>
          ) : null}
          <Text
            lineClamp={1}
            style={{
              fontSize: "11px",
              fontWeight: "400",
              lineHeight: "155%",
            }}>
            {user.email}
          </Text>
        </Stack>
      </Group>
    </Group>
  );
};

const dataMinusHome = [
  { link: "/admin/create_contract", label: "Create Contract", icon: IconPlus, adminOnly: false },
  { link: "/admin/dashboard", label: "Dashboard", icon: IconLayoutDashboard, adminOnly: true },
  { link: "/admin/onboarding_status", label: "Onboarding Status", icon: IconProgress, adminOnly: true },
  { link: "/admin/metrics/cash_flow", label: "Cash Flow", icon: IconCash, adminOnly: true },
  { link: "/admin/metrics/budget", label: "Budgets", icon: IconWallet, adminOnly: true },
  { link: "/admin/metrics", label: "Metrics", icon: IconChartLine, adminOnly: true },
  { link: "/admin/brands", label: "Brands", icon: IconBuildingCommunity, adminOnly: false },
  { link: "/admin/campaigns", label: "Campaigns", icon: IconListLetters, adminOnly: false },
  { link: "/admin/creator_sets", label: "Creator Sets", icon: IconUsers, adminOnly: false },
  { link: "/admin/creator/search", label: "Creator Search", icon: IconSearch, adminOnly: false },
  {
    link: "/admin/campaign_parameters",
    label: "Campaign Parameters",
    icon: IconAdjustmentsHorizontal,
    adminOnly: false,
  },
  { link: "/admin/ops", label: "Ops Team", icon: IconUsersGroup, adminOnly: false },
  { link: "/admin/negotiations", label: "Negotiations", icon: IconPremiumRights, adminOnly: false},
  { link: "/admin/discover", label: "Discover", icon: IconUserSearch, adminOnly: false },
  { link: "/admin/recommendations", label: "Recommendations", icon: IconWindmill, adminOnly: false },
];

const data = [
  { link: "/admin", label: "Home", icon: IconHome, adminOnly: false },
  ...dataMinusHome,
];

function getActiveFromUrl() {
  const url = window.location.pathname;
  const active = dataMinusHome.find((item) => url.startsWith(item.link));
  return active ? active.label : "Home";
}

export const AdminNavbar = ({
  userProfile,
  style,
}: {
  userProfile: User;
  style?: MantineStyleProp;
}) => {
  const [active, setActive] = useState(getActiveFromUrl());
  const navigate = useNavigate();

  useEffect(() => {
    setActive(getActiveFromUrl());
  }, [window.location.pathname]);

  const links = data.map((item) => {
    if (!userProfile.is_staff && item.adminOnly) {
      return null;
    }
    if (item.label === active) {
      return (
        <Button
          size="md"
          justify="flex-start"
          fullWidth
          key={item.label}
          variant="filled"
          onClick={() => {
            setActive(item.label);
            navigate(
              {
                pathname: item.link,
                search: "",
              },
              { replace: true },
            );
          }}
          leftSection={
            <item.icon
              style={{
                color: "var(--mantine-color-blue-1)",
                marginRight: "var(--mantine-spacing-sm)",
                width: "rem(25px)",
                height: "rem(25px)",
              }}
              stroke={1.5}
            />
          }>
          <Text fw={500} size="sm">
            {item.label}
          </Text>
        </Button>
      );
    }
    return (
      <Button
        size="md"
        justify="flex-start"
        fullWidth
        key={item.label}
        variant="subtle"
        onClick={() => {
          setActive(item.label);
          navigate({
            pathname: item.link,
            search: "",
          });
        }}
        leftSection={
          <item.icon
            style={{
              color: "var(--mantine-color-blue)",
              marginRight: "var(--mantine-spacing-sm)",
              width: "rem(25px)",
              height: "rem(25px)",
            }}
            stroke={1.5}
          />
        }>
        <Text fw={500} size="sm">
          {item.label}
        </Text>
      </Button>
    );
  });

  return (
    <Flex
      direction="column"
      justify="space-between"
      h="100%"
      style={{
        ...style,
      }}>
      <AppShell.Section pr="var(--mantine-spacing-md)" p="md">
        <CompanyAndUserHeader user={userProfile} />
      </AppShell.Section>
      <AppShell.Section grow my="md" component={ScrollArea}>
        <Stack px="md" gap={0}>
          {links}
        </Stack>
      </AppShell.Section>
    </Flex>
  );
};

export default AdminNavbar;
