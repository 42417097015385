import React, { ReactNode, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { User } from "firebase/auth";

import { auth } from "auth/firebaseAuthHelpers";
import LoadingScreen from "components/LoadingScreen";
import { processAuthResponse } from "social/fb/FBDataUtils";

// eslint-disable-next-line
const FacebookProvider = ({ children }: { children: ReactNode }) => {
  const [fbInitDone, setFbInitDone] = useState(false);

  window.fbAsyncInit = () => {
    window.FB.init({
      appId: "776328873362317", // find a good way to make this an env variable
      cookie: true,
      xfbml: true,
      version: "v14.0",
    });
    setFbInitDone(true);
  };

  // load facebook sdk script
  ((d, s, id) => {
    const fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {
      return;
    }
    const js = d.createElement(s) as HTMLScriptElement;
    js.id = id;
    js.async = true;
    js.src = "https://connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
  })(document, "script", "facebook-jssdk");

  setTimeout(() => {
    if (!fbInitDone) {
      setFbInitDone(true);
    }
  }, 500);

  return fbInitDone ? <>{children}</> : <LoadingScreen />; //eslint-disable-line
};

export default FacebookProvider;
