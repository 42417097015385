import { createRequestWithFirebaseToken, handleResult } from "utils/ApiUtils";

import { API_URL } from "configs/Configs";

export const bulkImportCreatorsToCreatorSet = async (
  creatorIds: number[],
  creatorSetId: number,
  state: number,
) => {
  const endpoint = new URL(`${API_URL}/creatorsets/bulk_import_creators_to_creator_set/`);
  const request = await createRequestWithFirebaseToken({
    url: endpoint,
    method: "POST",
    body: JSON.stringify({ creator_ids: creatorIds, creator_set_id: creatorSetId, state }),
  });
  const response = await handleResult(request);
  return response;
};

export default null;
