import React, { useEffect, useState } from "react";

import { useSearchParams } from "react-router-dom";

import { Container, Paper, Stack } from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";

import { BrandHashtagSearch } from "admin/app/ops/labeling/brand_hashtag/BrandHashtagSearch";
import { BrandHashtagLabelingTable } from "admin/app/ops/labeling/brand_hashtag/BrandHashtagLabelingTable";
import { getBrandHashtags } from "admin/api/labeling/brandHashtagApi";

export const BrandHashtagLabelingHome = () => {
  const [query, setQuery] = useState("");
  const [debounced] = useDebouncedValue(query, 500);
  const [searchParams, setSearchParams] = useSearchParams();
  const [hashtags, setHashtags] = useState([]);
  const [shouldFilterLabeled, setShouldFilterLabeled] = useState(true);
  const [includeHidden, setIncludeHidden] = useState(false);
  const [shouldFilterSponsored, setShouldFilterSponsored] = useState(false);

  useEffect(() => {
    // parse query from search params
    const parsedQuery = searchParams.get("q");
    if (parsedQuery) {
      if (parsedQuery !== query) {
        setQuery(parsedQuery);
      }
    } else {
      setQuery("");
    }
  }, [searchParams]);

  useEffect(() => {
    // update search params based on query changes
    if (query) {
      if (query !== searchParams.get("q")) {
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.set("q", query);
        setSearchParams(newSearchParams);
        // also don't filter labeled if query is set
        setShouldFilterLabeled(false);
      }
    } else {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete("q");
      setSearchParams(newSearchParams);
    }
  }, [query]);

  // on debounced query change, fetch hashtags
  useEffect(() => {
    const abortController = new AbortController();
    getBrandHashtags(
      setHashtags,
      query,
      shouldFilterLabeled,
      includeHidden,
      shouldFilterSponsored,
      abortController,
    );
    return () => {
      abortController.abort();
    };
  }, [debounced, shouldFilterLabeled, includeHidden, shouldFilterSponsored]);

  return (
    <Container fluid>
      <Stack gap="md">
        <Paper p="md">
          <Stack gap="md">
            <BrandHashtagSearch
              query={query}
              setQuery={setQuery}
              shouldFilterLabeled={shouldFilterLabeled}
              setShouldFilterLabeled={setShouldFilterLabeled}
              includeHidden={includeHidden}
              setIncludeHidden={setIncludeHidden}
              shouldFilterSponsored={shouldFilterSponsored}
              setShouldFilterSponsored={setShouldFilterSponsored}
            />
            <BrandHashtagLabelingTable hashtags={hashtags} includeHidden={includeHidden} />
          </Stack>
        </Paper>
      </Stack>
    </Container>
  );
};

export default BrandHashtagLabelingHome;
