import {
  ProductAccessInfoNeededFromCreatorType,
  ProductAccessInfoToProvideToCreatorType,
  ProductAccessStatus,
} from "components/contracts/common/Common";

export default class ContractHeaderInfo {
  brandName: string;

  contractId: string;

  contractAmount: number;

  bonusAmount: number;

  meetsBonusCondition: boolean;

  signatureFirstName: string;

  signatureLastName: string;

  signatureDatetime: Date;

  avatarUrl: string;

  creatorId: string;

  requiresProductAccess: boolean;

  productAccessDescription: string;

  productAccessInfoNeededFromCreator: ProductAccessInfoNeededFromCreatorType;

  productAccessInfoToProvideToCreator: ProductAccessInfoToProvideToCreatorType;

  productAccessOtherInfoNeededFromCreator: string;

  productAccessOtherInfoToProvideToCreator: string;

  productAccessCreatorInput: string;

  productAccessBrandOutput: string;

  productAccessStatus: ProductAccessStatus;

  numUnreadMessages: number;

  reviewRequired: boolean;

  verificationRequired: boolean;

  waitingForRevision: boolean;

  waitingForContent: boolean;

  approvedToGoLive: boolean;

  isLive: boolean;

  liveDate: Date;

  constructor({
    brandName,
    contractId,
    contractAmount,
    bonusAmount,
    meetsBonusCondition,
    signatureFirstName,
    signatureLastName,
    signatureDatetime,
    avatarUrl,
    creatorId,
    requiresProductAccess,
    productAccessDescription,
    productAccessInfoNeededFromCreator,
    productAccessInfoToProvideToCreator,
    productAccessOtherInfoNeededFromCreator,
    productAccessOtherInfoToProvideToCreator,
    productAccessCreatorInput,
    productAccessBrandOutput,
    productAccessStatus,
    numUnreadMessages,
    reviewRequired,
    verificationRequired,
    waitingForRevision,
    waitingForContent,
    approvedToGoLive,
    isLive,
    liveDate,
  }: {
    brandName: string;
    contractId: string;
    contractAmount: number;
    bonusAmount: number;
    meetsBonusCondition: boolean;
    signatureFirstName: string;
    signatureLastName: string;
    signatureDatetime: Date;
    avatarUrl: string;
    creatorId: string;
    requiresProductAccess: boolean;
    productAccessDescription: string;
    productAccessInfoNeededFromCreator: ProductAccessInfoNeededFromCreatorType;
    productAccessInfoToProvideToCreator: ProductAccessInfoToProvideToCreatorType;
    productAccessOtherInfoNeededFromCreator: string;
    productAccessOtherInfoToProvideToCreator: string;
    productAccessCreatorInput: string;
    productAccessBrandOutput: string;
    productAccessStatus: ProductAccessStatus;
    numUnreadMessages: number;
    reviewRequired: boolean;
    verificationRequired: boolean;
    waitingForRevision: boolean;
    waitingForContent: boolean;
    approvedToGoLive: boolean;
    isLive: boolean;
    liveDate: Date;
  }) {
    this.brandName = brandName;
    this.contractId = contractId;
    this.contractAmount = contractAmount;
    this.bonusAmount = bonusAmount;
    this.meetsBonusCondition = meetsBonusCondition;
    this.signatureFirstName = signatureFirstName;
    this.signatureLastName = signatureLastName;
    this.signatureDatetime = signatureDatetime;
    this.avatarUrl = avatarUrl;
    this.creatorId = creatorId;
    this.requiresProductAccess = requiresProductAccess;
    this.productAccessDescription = productAccessDescription;
    this.productAccessInfoNeededFromCreator = productAccessInfoNeededFromCreator;
    this.productAccessInfoToProvideToCreator = productAccessInfoToProvideToCreator;
    this.productAccessOtherInfoNeededFromCreator = productAccessOtherInfoNeededFromCreator;
    this.productAccessOtherInfoToProvideToCreator = productAccessOtherInfoToProvideToCreator;
    this.productAccessCreatorInput = productAccessCreatorInput;
    this.productAccessBrandOutput = productAccessBrandOutput;
    this.productAccessStatus = productAccessStatus;
    this.numUnreadMessages = numUnreadMessages;
    this.reviewRequired = reviewRequired;
    this.verificationRequired = verificationRequired;
    this.waitingForRevision = waitingForRevision;
    this.waitingForContent = waitingForContent;
    this.approvedToGoLive = approvedToGoLive;
    this.isLive = isLive;
    this.liveDate = liveDate;
  }

  public static deserialize(response: any) {
    const {
      brandName,
      contractId,
      contractAmount,
      bonusAmount,
      meetsBonusCondition,
      signatureFirstName,
      signatureLastName,
      signatureDatetime,
      avatarUrl,
      creatorId,
      requiresProductAccess,
      productAccessDescription,
      productAccessInfoNeededFromCreator,
      productAccessInfoToProvideToCreator,
      productAccessOtherInfoNeededFromCreator,
      productAccessOtherInfoToProvideToCreator,
      productAccessCreatorInput,
      productAccessBrandOutput,
      productAccessStatus,
      numUnreadMessages,
      reviewRequired,
      verificationRequired,
      waitingForRevision,
      waitingForContent,
      approvedToGoLive,
      isLive,
      liveDate,
    } = response;

    return new ContractHeaderInfo({
      brandName,
      contractId,
      contractAmount,
      bonusAmount,
      meetsBonusCondition,
      signatureFirstName,
      signatureLastName,
      signatureDatetime,
      avatarUrl,
      creatorId,
      requiresProductAccess,
      productAccessDescription,
      productAccessInfoNeededFromCreator,
      productAccessInfoToProvideToCreator,
      productAccessOtherInfoNeededFromCreator,
      productAccessOtherInfoToProvideToCreator,
      productAccessCreatorInput,
      productAccessBrandOutput,
      productAccessStatus,
      numUnreadMessages,
      reviewRequired,
      verificationRequired,
      waitingForRevision,
      waitingForContent,
      approvedToGoLive,
      isLive,
      liveDate,
    });
  }
}
