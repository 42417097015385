import { ActionIcon, Group, Stack, Text, Title } from "@mantine/core";
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import React from "react";

const CardTitle = ({
  title,
  subtitle,
  isOpen,
  onIconTapped,
}: {
  title: string;
  subtitle: string;
  isOpen: boolean;
  onIconTapped: () => void;
}) => (
  <Group w="100%" align="start" wrap="nowrap">
    <Stack style={{ flexGrow: 1 }} gap={0}>
      <Title order={3}>{title}</Title>
      <Text>{subtitle}</Text>
    </Stack>
    <ActionIcon
      variant="transparent"
      aria-label="Settings"
      onClick={onIconTapped}
      color="var(--mantine-color-black)">
      {isOpen ? <IconChevronUp size={24} /> : <IconChevronDown size={24} />}
    </ActionIcon>
  </Group>
);

export default CardTitle;
