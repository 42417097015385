import React from "react";
import {
  AspectRatio,
  Avatar,
  Box,
  Card,
  Container,
  Flex,
  Group,
  Image,
  SimpleGrid,
  Stack,
  Text,
  Title,
  em,
} from "@mantine/core";
import { useHover, useMediaQuery } from "@mantine/hooks";
import curated from "pages/home/v2/assets/images/curated.svg";
import equals from "pages/home/v2/assets/images/equals.svg";
import kindred from "pages/home/v2/assets/images/kindred.svg";
import locket from "pages/home/v2/assets/images/locket.svg";
import output from "pages/home/v2/assets/images/output.svg";
import notion from "pages/home/v2/assets/images/notion.svg";
import gradient from "pages/home/v2/assets/images/gradient.svg";

/* eslint-disable @typescript-eslint/no-var-requires */
const noahsterncoffee = require("pages/home/v2/assets/images/noah_stern.jpeg");
const acodingnomad = require("pages/home/v2/assets/images/acodingnomad.jpeg");
const loiabasial = require("pages/home/v2/assets/images/loiabasial.jpeg");
const soosfoods = require("pages/home/v2/assets/images/soosfoods.jpeg");
const matthewberman = require("pages/home/v2/assets/images/matthew_berman.jpeg");
const arjancodes = require("pages/home/v2/assets/images/arjancodes.jpeg");
const zachheyde = require("pages/home/v2/assets/images/zachheyde.jpeg");
const alexhomebarista = require("pages/home/v2/assets/images/alexhomebarista.jpeg");
const certbros = require("pages/home/v2/assets/images/certbros.jpeg");
const noahsterncoffeeVideo = require("pages/home/v2/assets/videos/noahsterncoffee.mp4");
const loiabasialVideo = require("pages/home/v2/assets/videos/loiabasial.mp4");
const acodingnomadVideo = require("pages/home/v2/assets/videos/acodingnomad.mp4");
const soosfoodsVideo = require("pages/home/v2/assets/videos/soosfoods.mp4");
const matthewbermanVideo = require("pages/home/v2/assets/videos/matthew_berman.mp4");
const arjancodesVideo = require("pages/home/v2/assets/videos/arjancodes.mp4");
const zachheydeVideo = require("pages/home/v2/assets/videos/zachheyde.mp4");
const alexhomebaristaVideo = require("pages/home/v2/assets/videos/alexhomebarista.mp4");
const certbrosVideo = require("pages/home/v2/assets/videos/certbros.mp4");
/* eslint-disable @typescript-eslint/no-var-requires */

interface Testimonial {
  type: string;
  avatarUrl: string;
  fullName: string;
  username: string;
  videoUrl: string;
  videoOriginalUrl: string; // links out to actual platform
  quote: string;
  partner: string;
  profileUrl: string;
  partnerAsset: any;
}

const A_CODING_NOMAD: Testimonial = {
  type: "instagram",
  avatarUrl: acodingnomad,
  fullName: "Khris S",
  username: "acodingnomad",
  videoUrl: acodingnomadVideo,
  videoOriginalUrl: "https://www.instagram.com/reel/Cx5YxO4g03q/",
  quote:
    "1stCollab had, by far, the most straightforward, simple process we experienced this year. The entire process from negotiation to payment was clear and efficient. We LOVED working with them!",
  partner: "Equals",
  partnerAsset: equals,
  profileUrl: "https://www.instagram.com/acodingnomad/",
};

const NOAH_STERN_COFFEE: Testimonial = {
  type: "tiktok",
  avatarUrl: noahsterncoffee,
  fullName: "Noah Stern",
  username: "noahsterncoffee",
  videoUrl: noahsterncoffeeVideo,
  videoOriginalUrl: "https://www.tiktok.com/@noahsterncoffee/video/7299891201090669866",
  quote:
    "It was a pleasure working with 1stCollab. They were timely in their communication and always clear about requirements and timelines. Everything from draft approval to invoice payment was made very quickly, I never had to follow up or give them a nudge. I hope to work with them again in the future!",
  partner: "Curated",
  partnerAsset: curated,
  profileUrl: "https://www.tiktok.com/@noahsterncoffee",
};

const LOI_ABASIAL: Testimonial = {
  type: "tiktok",
  avatarUrl: loiabasial,
  fullName: "Loi Abasial",
  username: "loiabasial",
  videoUrl: loiabasialVideo,
  videoOriginalUrl: "https://www.tiktok.com/@loiabasial/video/7280522874178342175",
  quote:
    "The team at 1stCollab is amazing!! The entire partnership was super easy and so much fun! The process was simple and clear, and I actually had a real person helping me the whole time. 1stCollab has been by far the best team that I’ve collaborated with, and I would 100% work with them again.",
  partner: "Locket",
  partnerAsset: locket,
  profileUrl: "https://www.tiktok.com/@loiabasial",
};

const MATTHEW_BERMAN: Testimonial = {
  type: "youtube",
  avatarUrl: matthewberman,
  fullName: "Matthew Berman",
  username: "matthew_berman",
  videoUrl: matthewbermanVideo,
  videoOriginalUrl: "https://www.youtube.com/watch?v=74NSDMvYZ9Y",
  quote:
    "Working with 1stCollab was the best sponsorship experience I've had to date — fast replies, quicker payments, and clear guidance every step of the way. Would gladly team up again!",
  partner: "Gradient",
  profileUrl: "https://www.youtube.com/@matthew_berman",
  partnerAsset: gradient,
};

const SOOSFOODS: Testimonial = {
  type: "instagram",
  avatarUrl: soosfoods,
  fullName: "Sooyeon",
  username: "soos.foods",
  videoUrl: soosfoodsVideo,
  videoOriginalUrl: "https://www.instagram.com/reel/CzeS_G-R44s/",
  quote:
    "1stCollab's process and platform was simple and clear for a creator like me to use. The team was also very communicative at every step along the way!",
  partner: "Kindred",
  partnerAsset: kindred,
  profileUrl: "https://www.instagram.com/soosfoods/",
};

const ALEX_HOME_BARISTA: Testimonial = {
  type: "tiktok",
  avatarUrl: alexhomebarista,
  fullName: "Alex Gren",
  username: "alexhomebarista",
  videoUrl: alexhomebaristaVideo,
  videoOriginalUrl: "https://www.tiktok.com/@alexhomebarista/video/7293560424396508459",
  quote:
    "1stCollab was by far the easiest company to work with in terms of partnerships. They always replied very quickly and I got paid a lot sooner than I thought I would which is a big plus! Would definitely partner with them again. Such a smooth simple process.",
  partner: "Curated",
  partnerAsset: curated,
  profileUrl: "https://www.tiktok.com/@alexhomebarista",
};

const ZACH_HEYDE: Testimonial = {
  type: "youtube",
  avatarUrl: zachheyde,
  fullName: "Zach Heyde",
  username: "zachheyde",
  videoUrl: zachheydeVideo,
  videoOriginalUrl: "https://www.youtube.com/watch?v=RXAzbOBb0RI",
  quote:
    "1stCollab was incredibly professional, prompt with replies and payment, and overall were phenomenal to work with. They make it easy for creators to connect with relevant brands, and serve as smooth intermediaries throughout the process. Highly recommend!",
  partner: "Output",
  partnerAsset: output,
  profileUrl: "https://www.youtube.com/@ZachHeyde",
};

const ARJAN_CODES: Testimonial = {
  type: "youtube",
  avatarUrl: arjancodes,
  fullName: "Arjan Egges",
  username: "arjancodes",
  videoUrl: arjancodesVideo,
  videoOriginalUrl: "https://www.youtube.com/watch?v=fBUAeFxSEqM",
  quote:
    "The team at 1stCollab is really reactive and it was fun to work with them. Having a dedicated contact person to speak with and who helped with every step made the experience easy.",
  partner: "Notion",
  partnerAsset: notion,
  profileUrl: "https://www.youtube.com/@ArjanCodes",
};

const CERTBROS: Testimonial = {
  type: "youtube",
  avatarUrl: certbros,
  fullName: "Sam Thompson",
  username: "certbros",
  videoUrl: certbrosVideo,
  videoOriginalUrl: "https://www.youtube.com/watch?v=c9sQqGilTjU",
  quote:
    "Working with 1stCollab was flawless. They provided a detailed brief of the project, provided excellent communication throughout the process, and made payments fast and effortless. On top of all of that, they were friendly, relaxed, and just great to work with.",
  partner: "Notion",
  partnerAsset: notion,
  profileUrl: "https://www.youtube.com/@Certbros",
};

const TESTIMONIALS: Testimonial[] = [
  A_CODING_NOMAD,

  SOOSFOODS,
  LOI_ABASIAL,
  ALEX_HOME_BARISTA,

  CERTBROS,
  NOAH_STERN_COFFEE,

  ZACH_HEYDE,
  MATTHEW_BERMAN,

  ARJAN_CODES,
];

const TestimonialVideo = ({
  direction,
  testimonial,
  inlineWithText = false,
}: {
  direction: "horizontal" | "vertical";
  testimonial: Testimonial;
  inlineWithText?: boolean;
}) => {
  const { hovered, ref } = useHover();
  return (
    <AspectRatio
      ref={ref}
      onClick={() => window.open(testimonial.videoOriginalUrl, "_blank")}
      ratio={direction === "horizontal" ? 9 / 16 : 16 / 9}
      style={{
        borderRadius: 8,
        flexShrink: 0,
        width: direction === "horizontal" ? 120 : "100%",
        cursor: hovered ? "pointer" : "default",
        float: inlineWithText ? "right" : undefined,
        marginLeft: inlineWithText ? "var(--mantine-spacing-md)" : undefined,
      }}>
      <video
        src={testimonial.videoUrl}
        title={`${testimonial.fullName} Demo`}
        style={{ border: 0, borderRadius: 6 }}
        autoPlay
        muted
        loop
      />
    </AspectRatio>
  );
};

const TestimonialInlineWithVideo = ({ testimonial }: { testimonial: Testimonial }) => (
  <Container fluid px={0}>
    <TestimonialVideo direction="horizontal" testimonial={testimonial} inlineWithText />
    <Text size="lg" fw={500} style={{ overflowWrap: "break-word", hyphens: "auto" }}>
      {testimonial.quote}
    </Text>
  </Container>
);

const TestimonalBase = ({
  direction,
  testimonial,
}: {
  direction: "vertical" | "horizontal";
  testimonial: Testimonial;
}) => {
  const { hovered: profileHovered, ref: profileRef } = useHover();
  const isMobile = useMediaQuery(`(max-width: 768px)`);
  return (
    <Card radius="md" p="32px" style={{ border: "1px solid #BCBCBC" }}>
      <Group
        wrap="nowrap"
        align="flex-start"
        style={{ display: isMobile && direction === "horizontal" ? "inline" : undefined }}
        gap="md">
        <Stack style={{ width: direction === "vertical" ? "100%" : undefined }}>
          {isMobile && direction === "horizontal" ? (
            // need to put video up here when inline with text so it inlines correctly
            <TestimonialInlineWithVideo testimonial={testimonial} />
          ) : (
            <Text size="lg" fw={500}>
              {testimonial.quote}
            </Text>
          )}
          <Group
            gap={0}
            style={{ gap: 12, cursor: profileHovered ? "pointer" : "default" }}
            ref={profileRef}
            onClick={() => window.open(testimonial.profileUrl, "_blank")}>
            <Avatar radius="xl" src={testimonial.avatarUrl} />
            <Stack gap={0}>
              <Text size="sm" fw={500}>
                {testimonial.fullName}
              </Text>
              <Text size="xs">@{testimonial.username}</Text>
            </Stack>
          </Group>
          <Group gap={0} style={{ gap: 4 }}>
            <Text size="sm">In partnership with:</Text>
            {/* <Title size="xs">{testimonial.partner}</Title> */}
            <Image src={testimonial.partnerAsset} h="16" fit="contain" />
          </Group>
          {direction === "vertical" ? (
            <TestimonialVideo direction={direction} testimonial={testimonial} />
          ) : null}
        </Stack>
        {direction === "horizontal" && !isMobile ? (
          // if quote is not inline with video, put testimonial video here
          <TestimonialVideo direction={direction} testimonial={testimonial} />
        ) : null}
      </Group>
    </Card>
  );
};

const TestimonialColumn = ({ testimonials }: { testimonials: Testimonial[] }) => (
  <Stack>
    {testimonials.map((testimonial) => (
      <TestimonalBase
        key={testimonial.username}
        testimonial={testimonial}
        direction={testimonial.type === "youtube" ? "vertical" : "horizontal"}
      />
    ))}
  </Stack>
);

const Testimonials = () => (
  <Flex w="100%" p={0} bg="white">
    <Container style={{ maxWidth: "1920px" }} p={0}>
      <Box py={112} px={{ base: "24px", md: "32px", lg: "96px" }}>
        <Container size="md">
          <Title ta="center" mb={80} style={{ fontSize: "48px" }}>
            Thousands of happy creators and counting
          </Title>
        </Container>
        {/* 3 column grid */}
        <SimpleGrid cols={{ base: 1, sm: 2, lg: 3 }} spacing="md" mb={20} visibleFrom="lg">
          <TestimonialColumn
            testimonials={TESTIMONIALS.filter((testimonial, index) => index % 3 === 0)}
          />
          <TestimonialColumn
            testimonials={TESTIMONIALS.filter((testimonial, index) => index % 3 === 1)}
          />
          <TestimonialColumn
            testimonials={TESTIMONIALS.filter((testimonial, index) => index % 3 === 2)}
          />
        </SimpleGrid>
        {/* anything smaller than 3 column grid */}
        <SimpleGrid cols={{ base: 1, sm: 2, lg: 3 }} spacing="md" mb={20} hiddenFrom="lg">
          <TestimonialColumn
            testimonials={TESTIMONIALS.filter((testimonial, index) => index % 2 === 0)}
          />
          <TestimonialColumn
            testimonials={TESTIMONIALS.filter((testimonial, index) => index % 2 === 1)}
          />
        </SimpleGrid>
      </Box>
    </Container>
  </Flex>
);

export default Testimonials;
