import React, { useEffect, useState } from "react";

import { Anchor, Avatar, Group, Table, Text } from "@mantine/core";

import { useAdminAppContext } from "admin/app/AdminAppShell";
import { CreatorSetSourcingPlan } from "models/OpsTask";
import { User } from "models/User";

export const OpsDailyTaskRow = ({
  creatorSetPlan,
  opsUsers,
}: {
  creatorSetPlan: CreatorSetSourcingPlan;
  opsUsers: User[];
}) => {
  const { creatorSets } = useAdminAppContext();
  const [creatorSet, setCreatorSet] = useState(null);

  useEffect(() => {
    if (creatorSets) {
      setCreatorSet(creatorSets.find((cs) => cs.id === creatorSetPlan.creator_set_id));
    }
  }, [creatorSets, creatorSetPlan.creator_set_id]);

  const getInitials = (name: string) => {
    if (!name) return "";
    const names = name.split(" ");
    return names[0].charAt(0) + names[names.length - 1].charAt(0);
  };

  if (!creatorSet) return null;

  if (Object.keys(creatorSetPlan.allocated)?.length === 0) {
    return (
      <Table.Tr key={`creator-set-${creatorSet.id}`}>
        <Table.Td>
          <Anchor size="sm" target="_blank" href={`/admin/brand?brandId=${creatorSet.brand.id}`}>
            {creatorSet.brand.brand_name}
          </Anchor>
        </Table.Td>
        <Table.Td>
          <Anchor
            size="sm"
            target="_blank"
            href={`/admin/creator_sets?creatorSetId=${creatorSet.id}`}>
            {creatorSet.name}
          </Anchor>
        </Table.Td>
        <Table.Td>{creatorSetPlan.priority}</Table.Td>
        <Table.Td>{creatorSetPlan.difficulty}</Table.Td>
        <Table.Td>Unassigned</Table.Td>
        <Table.Td>0</Table.Td>
        <Table.Td>{creatorSetPlan.unallocated}</Table.Td>
        <Table.Td>{creatorSetPlan.needs_brand_review}</Table.Td>
        <Table.Td>{creatorSetPlan.needs_internal_review}</Table.Td>
      </Table.Tr>
    );
  }

  return (
    <>
      {Object.keys(creatorSetPlan.allocated).map((email) => {
        const user = opsUsers.find((u) => u.email === email);
        return (
          <Table.Tr key={`creator-set-${creatorSet.id}-${email}`}>
            <Table.Td>
              <Anchor
                size="sm"
                target="_blank"
                href={`/admin/brand?brandId=${creatorSet.brand.id}`}>
                {creatorSet.brand.brand_name}
              </Anchor>
            </Table.Td>
            <Table.Td>
              <Anchor
                size="sm"
                target="_blank"
                href={`/admin/creator_sets?creatorSetId=${creatorSet.id}`}>
                {creatorSet.name}
              </Anchor>
            </Table.Td>
            <Table.Td>{creatorSetPlan.priority}</Table.Td>
            <Table.Td>{creatorSetPlan.difficulty}</Table.Td>
            <Table.Td>
              <Group grow preventGrowOverflow={false} wrap="nowrap">
                <Avatar w={40} h={40} style={{ flex: "none" }}>
                  {getInitials(user?.name)}
                </Avatar>
                <Text fw={500}>{user?.name || "Unassigned"}</Text>
              </Group>
            </Table.Td>
            <Table.Td>{creatorSetPlan.allocated[email]}</Table.Td>
            <Table.Td>{creatorSetPlan.unallocated}</Table.Td>
            <Table.Td>{creatorSetPlan.needs_brand_review}</Table.Td>
            <Table.Td>{creatorSetPlan.needs_internal_review}</Table.Td>
          </Table.Tr>
        );
      })}
    </>
  );
};

export default OpsDailyTaskRow;
