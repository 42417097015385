import React, { useState } from "react";
import { Table } from "@mantine/core";
import SortableTableHeader from "components/SortableTableHeader";

export interface SortableTableColumnProps<T, K extends keyof T> {
  label: string;
  field: K;
  alignLeft?: boolean;
}

interface SortableTableProps<T extends object, K extends keyof T = keyof T> {
  data: T[];
  renderRow: (item: T) => React.ReactNode;
  sortableCols: SortableTableColumnProps<T, K>[];
  regCols: string[];
}

function SortableTable<T extends object, K extends keyof T = keyof T>({
  data,
  renderRow,
  sortableCols,
  regCols,
}: SortableTableProps<T, K>) {
  const [sortBy, setSortBy] = useState<keyof T | null>(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);

  const setSorting = (field: keyof T) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
  };

  const sortedData = sortBy
    ? [...data].sort((a, b) => {
        const aVal = a[sortBy];
        const bVal = b[sortBy];
        if (typeof aVal === "number" && typeof bVal === "number") {
          return reverseSortDirection ? bVal - aVal : aVal - bVal;
        } else if (aVal instanceof Date && bVal instanceof Date) {
          return reverseSortDirection
            ? bVal.getTime() - aVal.getTime()
            : aVal.getTime() - bVal.getTime();
        }
        return reverseSortDirection
          ? bVal.toString().localeCompare(aVal.toString())
          : aVal.toString().localeCompare(bVal.toString());
      })
    : data;

  return (
    <Table.ScrollContainer minWidth={700}>
      <Table withTableBorder horizontalSpacing="xs" verticalSpacing="xs" highlightOnHover>
        <Table.Thead>
          <Table.Tr>
            {sortableCols.map(({ label, field, alignLeft }) => (
              <SortableTableHeader
                key={field.toString()}
                label={label}
                field={field}
                sortBy={sortBy}
                reverseSortDirection={reverseSortDirection}
                onSort={setSorting}
                alignLeft={alignLeft}
              />
            ))}
            {regCols.map((label) => (
              <Table.Th fw={500} key={label}>
                {label}
              </Table.Th>
            ))}
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{sortedData.map((item) => renderRow(item))}</Table.Tbody>
      </Table>
    </Table.ScrollContainer>
  );
}

export default SortableTable;
