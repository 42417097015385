import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Divider,
  Flex,
  Group,
  Modal,
  NumberInput,
  Paper,
  Stack,
  TagsInput,
  Text,
  Textarea,
  Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { IconEye } from "@tabler/icons-react";
import { CampaignParametersChangeConfirmation } from "components/campaignParameters/CampaignParametersChangeConfirmation";
import { updateCampaignParamsById } from "campaigns/api/updateObjsById";
import { CampaignParametersV2 } from "models/Campaign";
import { Country, Language } from "models/Common";

export const CampaignParameterSettings = ({
  campaignParameter,
}: {
  campaignParameter: CampaignParametersV2;
}) => {
  if (campaignParameter == null) {
    return null;
  }

  const [opened, { open, close }] = useDisclosure(false);
  const initialObj = {
    source_youtube: campaignParameter?.source_youtube || false,
    source_tiktok: campaignParameter?.source_tiktok || false,
    source_instagram: campaignParameter?.source_instagram || false,
    youtube_min_subscribers: campaignParameter?.youtube_min_subscribers || 0,
    youtube_max_subscribers: campaignParameter?.youtube_max_subscribers || 0,
    youtube_min_median_views: campaignParameter?.youtube_min_median_views || 0,
    youtube_max_median_views: campaignParameter?.youtube_max_median_views || 0,
    youtube_min_videos_28d: campaignParameter?.youtube_min_videos_28d || 0,
    youtube_min_videos_60d: campaignParameter?.youtube_min_videos_60d || 0,
    youtube_min_videos_90d: campaignParameter?.youtube_min_videos_90d || 0,
    youtube_min_videos_total: campaignParameter?.youtube_min_videos_total || 0,
    tiktok_min_subscribers: campaignParameter?.tiktok_min_subscribers || 0,
    tiktok_max_subscribers: campaignParameter?.tiktok_max_subscribers || 0,
    tiktok_min_median_views: campaignParameter?.tiktok_min_median_views || 0,
    tiktok_max_median_views: campaignParameter?.tiktok_max_median_views || 0,
    tiktok_min_videos_28d: campaignParameter?.tiktok_min_videos_28d || 0,
    tiktok_min_videos_60d: campaignParameter?.tiktok_min_videos_60d || 0,
    tiktok_min_videos_90d: campaignParameter?.tiktok_min_videos_90d || 0,
    tiktok_min_videos_total: campaignParameter?.tiktok_min_videos_total || 0,
    instagram_min_subscribers: campaignParameter?.instagram_min_subscribers || 0,
    instagram_max_subscribers: campaignParameter?.instagram_max_subscribers || 0,
    instagram_min_median_views: campaignParameter?.instagram_min_median_views || 0,
    instagram_max_median_views: campaignParameter?.instagram_max_median_views || 0,
    instagram_min_videos_28d: campaignParameter?.instagram_min_videos_28d || 0,
    instagram_min_videos_60d: campaignParameter?.instagram_min_videos_60d || 0,
    instagram_min_videos_90d: campaignParameter?.instagram_min_videos_90d || 0,
    instagram_min_videos_total: campaignParameter?.instagram_min_videos_total || 0,
    countries: campaignParameter?.countries || [],
    languages: campaignParameter?.languages || [],
    us_only: campaignParameter?.us_only || false,
    use_likely_email_filter: campaignParameter?.use_likely_email_filter || false,
    use_creator_likely_email_filter: campaignParameter?.use_creator_likely_email_filter || false,
    requirements: campaignParameter?.requirements || "",
  };
  const [initialValues, setInitialValues] = useState<{ [key: string]: any }>(initialObj);
  const form = useForm({ initialValues: initialObj });

  useEffect(() => {
    setInitialValues(initialObj);
    form.setValues(initialObj);
    form.resetDirty();
  }, [campaignParameter]);

  return (
    <>
      <Modal
        size="lg"
        opened={opened}
        onClose={close}
        title={<Text fw="600">Campaign Parameters Change Confirmation</Text>}>
        <Stack gap={24}>
          <Text>Please confirm that the following fields were changed:</Text>
          <CampaignParametersChangeConfirmation
            objId={campaignParameter.id}
            form={form}
            formValues={form.values}
            initialValues={initialValues}
            onSubmit={updateCampaignParamsById}
            close={close}
          />
        </Stack>
      </Modal>
      <Stack gap="md">
        <Group gap="xs" justify="space-between">
          <Group>
            <Title order={4}>Campaign Parameters</Title>
          </Group>
          <Stack gap="xs">
            <Title order={4}>{campaignParameter.name}</Title>
            <Group justify="space-between">
              <Text c="dimmed">ID: {campaignParameter.id}</Text>
            </Group>
          </Stack>
          <Group gap="xs">
            <IconEye size={24} color="var(--mantine-color-gray-5)" />
            <Text c="gray.5" size="sm">
              Internal Only
            </Text>
          </Group>
        </Group>
        <Stack gap="md">
          <Paper p="md">
            <Stack gap="md">
              <Group>
                <Checkbox
                  label="Source YouTube"
                  {...form.getInputProps("source_youtube", { type: "checkbox" })}
                />
                <Checkbox
                  label="Source TikTok"
                  {...form.getInputProps("source_tiktok", { type: "checkbox" })}
                />
                <Checkbox
                  label="Source Instagram"
                  {...form.getInputProps("source_instagram", { type: "checkbox" })}
                />
              </Group>
            </Stack>
          </Paper>
          <Divider my="md" label="YouTube" labelPosition="left" />
          <Paper p="md">
            <Stack gap="md">
              <Group gap="md">
                <NumberInput
                  label="Min Subscribers"
                  disabled={!form.values.source_youtube}
                  thousandSeparator=","
                  {...form.getInputProps("youtube_min_subscribers")}
                />
                <NumberInput
                  label="Max Subscribers"
                  disabled={!form.values.source_youtube}
                  thousandSeparator=","
                  {...form.getInputProps("youtube_max_subscribers")}
                />
              </Group>
              <Group gap="md">
                <NumberInput
                  label="Min Median Views"
                  disabled={!form.values.source_youtube}
                  thousandSeparator=","
                  {...form.getInputProps("youtube_min_median_views")}
                />
                <NumberInput
                  label="Max Median Views"
                  disabled={!form.values.source_youtube}
                  thousandSeparator=","
                  {...form.getInputProps("youtube_max_median_views")}
                />
              </Group>
              <Group gap="md">
                {/* <NumberInput
                  label="Min Videos (28d)"
                  {...form.getInputProps("youtube_min_videos_28d")}
                />
                <NumberInput
                  label="Min Videos (60d)"
                  {...form.getInputProps("youtube_min_videos_60d")}
                /> */}
                <NumberInput
                  label="Min Videos (90d)"
                  disabled={!form.values.source_youtube}
                  {...form.getInputProps("youtube_min_videos_90d")}
                />
                <NumberInput
                  label="Min Videos (Total)"
                  disabled={!form.values.source_youtube}
                  {...form.getInputProps("youtube_min_videos_total")}
                />
              </Group>
            </Stack>
          </Paper>
          <Divider my="md" label="TikTok" labelPosition="left" />
          <Paper p="md">
            <Stack gap="md">
              <Group gap="md">
                <NumberInput
                  label="Min Subscribers"
                  thousandSeparator=","
                  disabled={!form.values.source_tiktok}
                  {...form.getInputProps("tiktok_min_subscribers")}
                />
                <NumberInput
                  label="Max Subscribers"
                  thousandSeparator=","
                  disabled={!form.values.source_tiktok}
                  {...form.getInputProps("tiktok_max_subscribers")}
                />
              </Group>
              <Group gap="md">
                <NumberInput
                  label="Min Median Views"
                  thousandSeparator=","
                  disabled={!form.values.source_tiktok}
                  {...form.getInputProps("tiktok_min_median_views")}
                />
                <NumberInput
                  label="Max Median Views"
                  thousandSeparator=","
                  disabled={!form.values.source_tiktok}
                  {...form.getInputProps("tiktok_max_median_views")}
                />
              </Group>
              <Group gap="md">
                {/* <NumberInput
                  label="Min Videos (28d)"
                  {...form.getInputProps("tiktok_min_videos_28d")}
                />
                <NumberInput
                  label="Min Videos (60d)"
                  {...form.getInputProps("tiktok_min_videos_60d")}
                /> */}
                <NumberInput
                  label="Min Videos (90d)"
                  disabled={!form.values.source_tiktok}
                  {...form.getInputProps("tiktok_min_videos_90d")}
                />
                <NumberInput
                  label="Min Videos (Total)"
                  disabled={!form.values.source_tiktok}
                  {...form.getInputProps("tiktok_min_videos_total")}
                />
              </Group>
            </Stack>
          </Paper>
          <Divider my="md" label="Instagram" labelPosition="left" />
          <Paper p="md">
            <Stack gap="md">
              <Group gap="md">
                <NumberInput
                  label="Min Subscribers"
                  thousandSeparator=","
                  disabled={!form.values.source_instagram}
                  {...form.getInputProps("instagram_min_subscribers")}
                />
                <NumberInput
                  label="Max Subscribers"
                  thousandSeparator=","
                  disabled={!form.values.source_instagram}
                  {...form.getInputProps("instagram_max_subscribers")}
                />
              </Group>
              <Group gap="md">
                <NumberInput
                  label="Min Median Views"
                  thousandSeparator=","
                  disabled={!form.values.source_instagram}
                  {...form.getInputProps("instagram_min_median_views")}
                />
                <NumberInput
                  label="Max Median Views"
                  thousandSeparator=","
                  disabled={!form.values.source_instagram}
                  {...form.getInputProps("instagram_max_median_views")}
                />
              </Group>
              <Group gap="md">
                {/* <NumberInput
                  label="Min Videos (28d)"
                  {...form.getInputProps("instagram_min_videos_28d")}
                />
                <NumberInput
                  label="Min Videos (60d)"
                  {...form.getInputProps("instagram_min_videos_60d")}
                /> */}
                <NumberInput
                  label="Min Videos (90d)"
                  disabled={!form.values.source_instagram}
                  {...form.getInputProps("instagram_min_videos_90d")}
                />
                <NumberInput
                  label="Min Videos (Total)"
                  disabled={!form.values.source_instagram}
                  {...form.getInputProps("instagram_min_videos_total")}
                />
              </Group>
            </Stack>
          </Paper>
          <Divider my="md" label="Other" labelPosition="left" />
          <Paper p="md">
            <Stack gap="md">
              <TagsInput
                label="Countries"
                w={400}
                data={Object.values(Country)}
                {...form.getInputProps("countries")}
                splitChars={[",", " "]}
              />
              <TagsInput
                label="Languages"
                w={400}
                data={Object.values(Language)}
                {...form.getInputProps("languages")}
                splitChars={[",", " "]}
              />
              <Checkbox
                label="Use Likely Email Filter (new)"
                {...form.getInputProps("use_creator_likely_email_filter", { type: "checkbox" })}
              />
              <Textarea
                label="Requirements"
                autosize
                minRows={4}
                description="Custom requirements for creators"
                {...form.getInputProps("requirements")}
              />
            </Stack>
          </Paper>
        </Stack>
        <Flex justify="center">
          <Button onClick={() => open()} disabled={!form.isDirty()}>
            Save
          </Button>
        </Flex>
      </Stack>
    </>
  );
};

export default CampaignParameterSettings;
