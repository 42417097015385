import { API_URL } from "configs/Configs";
import { createRequestWithFirebaseToken, handleResult } from "utils/ApiUtils";

export const startCampaign = async (campaignHashId: number) => {
  const requestUrl = new URL(`${API_URL}/api/models/campaigns/start/`);
  requestUrl.searchParams.append("campaignId", campaignHashId.toString());
  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
  });
  const result = await handleResult(request);
  return result;
};

export default null;
