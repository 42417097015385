import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { User } from "firebase/auth";

import { IconSelector, IconChevronDown, IconChevronUp } from "@tabler/icons-react";

import {
  Box,
  Center,
  Chip,
  Container,
  Flex,
  Group,
  LoadingOverlay,
  Stack,
  Table,
  Text,
  Title,
  UnstyledButton,
} from "@mantine/core";

import { fetchContract, fetchContracts, fetchGMVData } from "components/contracts/common/Api";
import BudgetSummary from "components/contracts/dashboard/BudgetSummary";
import { GMVData } from "components/contracts/models/GMVData";
import ContractRow from "components/contracts/dashboard/ContractRow";
import {
  Contract,
  buildContractFromApiResponse,
  deserializeGMVData,
} from "components/contracts/dashboard/Utils";
import classes from "components/contracts/dashboard/css/ContractsDashboard.module.css";
import { ContractStatus, PaymentStatus } from "components/contracts/common/Common";
import { ContractDeliverableStatus } from "components/contracts/models/Deliverable";
import { GMVForecast } from "components/metrics/Metrics";
import LiveContentReviewRow from "components/contracts/dashboard/LiveContentReviewRow";
import PendingPaymentsTable from "components/contracts/dashboard/PendingPaymentsTable";

interface ThProps {
  children: React.ReactNode;
  reversed: boolean;
  sorted: boolean;
  onSort(): void;
  alignLeft?: boolean;
}

function Th({ children, reversed, sorted, onSort, alignLeft }: ThProps) {
  let Icon: React.ElementType;

  if (sorted) {
    if (reversed) {
      Icon = IconChevronDown;
    } else {
      Icon = IconChevronUp;
    }
  } else {
    Icon = IconSelector;
  }

  return (
    <Table.Th className={classes.th}>
      <UnstyledButton onClick={onSort} className={classes.control}>
        <Flex
          align="center"
          gap="sm"
          justify={alignLeft ? "left" : "center"}
          px="xs"
          ml={alignLeft ? "xs" : 0}>
          <Text fw={500} fz="sm">
            {children}
          </Text>
          <Center className={classes.icon}>
            <Icon size="0.9rem" stroke={1.5} />
          </Center>
        </Flex>
      </UnstyledButton>
    </Table.Th>
  );
}

function sortData(
  data: Contract[],
  payload: { sortBy: keyof Contract | null; reversed: boolean; search: string },
) {
  const { sortBy } = payload;

  if (!sortBy) {
    return data;
  }

  return [...data].sort((a, b) => {
    let compareResult = 0;

    if (typeof a[sortBy] === "number" && typeof b[sortBy] === "number") {
      compareResult = (a[sortBy] as number) - (b[sortBy] as number);
    } else if (a[sortBy] instanceof Date && b[sortBy] instanceof Date) {
      compareResult = (a[sortBy] as Date).getTime() - (b[sortBy] as Date).getTime();
    } else {
      compareResult = a[sortBy].toString().localeCompare(b[sortBy].toString());
    }

    return payload.reversed ? -compareResult : compareResult;
  });
}

export function LiveContentApprovalTable({
  user,
  contracts,
  handleRefetchContract,
}: {
  user: User;
  contracts: Contract[];
  handleRefetchContract: (contractId: string, handleFetched: () => void) => void;
}) {
  const rows = contracts.map((contract) => (
    <LiveContentReviewRow
      user={user} // TODO (victoria 3.2024): using for display name in ManualPaymentModal - need to get rid of this
      key={`live-content-${contract.contractId}`}
      contractId={contract.contractId}
      displayName={contract.displayName}
      socialHandles={contract.socialHandles}
      contractStatus={contract.contractStatus}
      paymentStatus={contract.paymentStatus}
      bonusPaymentStatus={contract.bonusPaymentStatus}
      amountInMinorUnits={contract.amountInMinorUnits}
      avatarUrl={contract.avatarUrl}
      readyForPayment={contract.readyForPayment}
      meetsBonusCondition={contract.meetsBonusCondition}
      hasBonusCondition={contract.hasBonus}
      deliverables={contract.deliverables}
      payments={contract.payments}
      stripeAccountUrl={contract.stripeAccountUrl}
      closeUrl={contract.closeUrl}
      handleRefetchContract={handleRefetchContract}
    />
  ));

  return (
    <>
      <Title order={3} fw="500">
        Pending Live Content
      </Title>
      <Table.ScrollContainer minWidth={500}>
        <Table withTableBorder horizontalSpacing="xs" verticalSpacing="xs">
          <Table.Thead>
            <Table.Tr>
              <Table.Th fw={500}>Creator</Table.Th>
              <Table.Th fw={500}>Social Profiles</Table.Th>
              <Table.Th fw={500}>
                <Flex justify="center">Submitted</Flex>
              </Table.Th>
              <Table.Th fw={500}>
                <Flex justify="center">Value</Flex>
              </Table.Th>
              <Table.Th fw={500}>
                <Flex justify="center">Deliverables Live</Flex>
              </Table.Th>
              <Table.Th fw={500}>
                <Flex justify="center">Pending Live Content</Flex>
              </Table.Th>
              <Table.Th />
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {rows.length === 0 && (
              <Table.Tr>
                <Table.Td colSpan={6}>
                  <Text size="sm" c="gray">
                    No contracts with pending live content.
                  </Text>
                </Table.Td>
              </Table.Tr>
            )}
            {rows}
          </Table.Tbody>
        </Table>
      </Table.ScrollContainer>
    </>
  );
}

export function TableSort({
  user,
  contracts,
  setContracts,
  handleRefetchContract,
  handleDeleteContract,
}: {
  user: User;
  contracts: Contract[];
  setContracts: (data: Contract[]) => void;
  handleRefetchContract: (contractId: string, handleFetched: () => void) => void;
  handleDeleteContract: (contractId: string) => void;
}) {
  // URL Params
  const [searchParams, setSearchParams] = useSearchParams();

  const [sortBy, setSortBy] = useState<keyof Contract | null>(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);
  const [hideArchived, setHideArchived] = useState(true);
  const [hideCompleted, setHideCompleted] = useState(true);
  const [hideExpired, setHideExpired] = useState(true);
  const [onlyMissingTracking, setOnlyMissingTracking] = useState(false);
  const [hideInactiveRecurrences, setHideInactiveRecurrences] = useState(true);

  useEffect(() => {
    if (
      searchParams.get("onlyMissingTracking") !== null &&
      Boolean(searchParams.get("onlyMissingTracking")) !== false
    ) {
      setOnlyMissingTracking(Boolean(searchParams.get("onlyMissingTracking")));
    }
    if (
      searchParams.get("hideArchived") !== null &&
      Boolean(searchParams.get("hideArchived")) !== false
    ) {
      setHideArchived(Boolean(searchParams.get("hideArchived")));
    }
    if (
      searchParams.get("hideCompleted") !== null &&
      Boolean(searchParams.get("hideCompleted")) !== false
    ) {
      setHideCompleted(Boolean(searchParams.get("hideCompleted")));
    }
    if (
      searchParams.get("hideExpired") !== null &&
      Boolean(searchParams.get("hideExpired")) !== false
    ) {
      setHideExpired(Boolean(searchParams.get("hideExpired")));
    }
    if (
      searchParams.get("hideInactiveRecurrences") !== null &&
      Boolean(searchParams.get("hideInactiveRecurrences")) !== false
    ) {
      setHideInactiveRecurrences(Boolean(searchParams.get("hideInactiveRecurrences")));
    }
  }, []);

  useEffect(() => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    if (onlyMissingTracking) {
      updatedSearchParams.set("onlyMissingTracking", String(onlyMissingTracking));
    } else {
      updatedSearchParams.delete("onlyMissingTracking");
    }
    if (hideArchived) {
      updatedSearchParams.set("hideArchived", String(hideArchived));
    } else {
      updatedSearchParams.delete("hideArchived");
    }
    if (hideCompleted) {
      updatedSearchParams.set("hideCompleted", String(hideCompleted));
    } else {
      updatedSearchParams.delete("hideCompleted");
    }
    if (hideExpired) {
      updatedSearchParams.set("hideExpired", String(hideExpired));
    } else {
      updatedSearchParams.delete("hideExpired");
    }
    if (hideInactiveRecurrences) {
      updatedSearchParams.set("hideInactiveRecurrences", String(hideInactiveRecurrences));
    } else {
      updatedSearchParams.delete("hideInactiveRecurrences");
    }
    setSearchParams(updatedSearchParams.toString());
  }, [hideArchived, hideCompleted, hideExpired, onlyMissingTracking, hideInactiveRecurrences]);

  const setSorting = (field: keyof Contract) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
    setContracts(sortData(contracts, { sortBy: field, reversed, search: "" }));
  };

  const rows = contracts
    .filter(
      (contract) =>
        !onlyMissingTracking || contract.missingReferralLink || contract.missingPromoCode,
    )
    .filter((contract) => !hideArchived || !contract.archived)
    .filter((contract) => !hideCompleted || contract.contractStatus !== ContractStatus.COMPLETE)
    .filter((contract) => !hideExpired || contract.contractStatus !== ContractStatus.REJECTED)
    .filter((contract) => !hideInactiveRecurrences || contract.isCurrent)
    .map((contract) => (
      <ContractRow
        user={user} // TODO (victoria 3.2024): using for display name in ManualPaymentModal - need to get rid of this
        key={`contract-${contract.contractId}`}
        contractId={contract.contractId}
        archived={contract.archived}
        creatorId={contract.creatorId}
        displayName={contract.displayName}
        socialHandles={contract.socialHandles}
        dateCreated={contract.dateCreated}
        lastUpdated={contract.lastUpdated}
        liveDate={contract.liveDate}
        requiresReferralLink={contract.requiresReferralLink}
        missingReferralLink={contract.missingReferralLink}
        requiresPromoCode={contract.requiresPromoCode}
        missingPromoCode={contract.missingPromoCode}
        contractStatus={contract.contractStatus}
        paymentStatus={contract.paymentStatus}
        isRecurring={contract.isRecurring}
        bonusPaymentStatus={contract.bonusPaymentStatus}
        avatarUrl={contract.avatarUrl}
        amountInMinorUnits={contract.amountInMinorUnits}
        remainingAmountInMinorUnits={contract.remainingAmountInMinorUnits}
        awaitingPaymentDetails={contract.awaitingPaymentDetails}
        readyForPayment={contract.readyForPayment}
        meetsBonusCondition={contract.meetsBonusCondition}
        hasBonusCondition={contract.hasBonus}
        deliverables={contract.deliverables}
        payments={contract.payments}
        stripeAccountUrl={contract.stripeAccountUrl}
        closeUrl={contract.closeUrl}
        repeatState={contract.repeatState}
        handleRefetchContract={handleRefetchContract}
        handleDeleteContract={handleDeleteContract}
      />
    ));

  return (
    <>
      <Group justify="space-between">
        <Title order={3} fw="500">
          All Contracts
        </Title>
        <Flex gap="xs" justify="right" mr="md">
          <Chip
            checked={onlyMissingTracking}
            size="xs"
            onChange={() => setOnlyMissingTracking((v) => !v)}>
            Missing Tracking Info
          </Chip>
          <Chip checked={hideCompleted} size="xs" onChange={() => setHideCompleted((v) => !v)}>
            Hide Completed
          </Chip>
          <Chip checked={hideArchived} size="xs" onChange={() => setHideArchived((v) => !v)}>
            Hide Archived
          </Chip>
          <Chip checked={hideExpired} size="xs" onChange={() => setHideExpired((v) => !v)}>
            Hide Expired
          </Chip>
          <Chip checked={hideInactiveRecurrences} size="xs" onChange={() => setHideInactiveRecurrences((v) => !v)}>
            Hide Inactive Recurrences
          </Chip>
        </Flex>
      </Group>
      <Table.ScrollContainer minWidth={500}>
        <Table withTableBorder horizontalSpacing="xs" verticalSpacing="xs" highlightOnHover>
          <Table.Thead>
            <Table.Tr>
              <Th
                sorted={sortBy === "displayName"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("displayName")}
                alignLeft>
                Creator
              </Th>
              <Table.Th fw={500}>Social Profiles</Table.Th>
              <Th
                sorted={sortBy === "dateCreated"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("dateCreated")}>
                Created
              </Th>
              <Th
                sorted={sortBy === "lastUpdated"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("lastUpdated")}>
                Updated
              </Th>
              <Th
                sorted={sortBy === "liveDate"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("liveDate")}>
                Live Date
              </Th>
              <Th
                sorted={sortBy === "contractStatus"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("contractStatus")}>
                Status
              </Th>
              <Th
                sorted={sortBy === "numDeliverablesLive"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("numDeliverablesLive")}>
                Deliverables Live
              </Th>
              <Th
                sorted={sortBy === "readyForPayment"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("readyForPayment")}>
                Ready for Payment
              </Th>
              <Th
                sorted={sortBy === "missingTracking"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("missingTracking")}>
                Tracking Info
              </Th>
              <Th
                sorted={sortBy === "amountInMinorUnits"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("amountInMinorUnits")}>
                Value
              </Th>
              <Th
                sorted={sortBy === "remainingAmountInMinorUnits"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("remainingAmountInMinorUnits")}>
                Remainder
              </Th>
              <Table.Th />
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
      </Table.ScrollContainer>
    </>
  );
}

export default function ContractsTable({
  user,
  campaignId,
  campaignName,
}: {
  user: User;
  campaignId: string;
  campaignName: string;
}) {
  if (!campaignId) {
    return null;
  }

  // GMV Data
  const [campaignGMVData, setCampaignGMVData] = useState<GMVData>(null);

  // Contracts
  const [contracts, setContracts] = useState<Contract[]>([]);
  const [liveContentContracts, setLiveContentContracts] = useState<Contract[]>([]);
  const [pendingPaymentContracts, setPendingPaymentContracts] = useState<Contract[]>([]);

  // Table State
  const [contractsLoading, setContractsLoading] = useState(false);
  const [gmvLoading, setGMVLoading] = useState(false);

  const handleFetchGMVData = (finallyFn: () => void) => {
    fetchGMVData(campaignId)
      .then((response) => {
        const { success, gmvData } = response;
        if (success) {
          const deserializedGMVData = deserializeGMVData(gmvData);
          setCampaignGMVData(deserializedGMVData[campaignId]);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => finallyFn());
  };

  // Update Contracts
  useEffect(() => {
    if (campaignId !== null) {
      setContractsLoading(true);
      fetchContracts(campaignId)
        .then((response) => {
          const fetchedContracts = response.contracts.map((contract: any) =>
            buildContractFromApiResponse(contract),
          );
          const sortedContracts = sortData(fetchedContracts, {
            sortBy: "lastUpdated",
            reversed: true,
            search: "",
          });
          setContracts(sortedContracts);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => setContractsLoading(false));

      setGMVLoading(true);
      setCampaignGMVData(null);
      handleFetchGMVData(() => setGMVLoading(false));
    }
  }, [campaignId]);

  // Update Secondary Table Views
  useEffect(() => {
    const contractsPendingPayment = contracts.filter(
      (contract) =>
        (contract.readyForPayment && contract.paymentStatus !== PaymentStatus.FULLY_PAID) ||
        (contract.contractStatus === ContractStatus.COMPLETE &&
          contract.paymentStatus !== PaymentStatus.FULLY_PAID) ||
        (contract.meetsBonusCondition && contract.bonusPaymentStatus !== PaymentStatus.FULLY_PAID),
    );
    const contractsWithLiveContent = contracts.filter((contract) =>
      contract.deliverables.some(
        (deliverable) => deliverable.status === ContractDeliverableStatus.LIVE_CONTENT_SUBMITTED,
      ),
    );
    setPendingPaymentContracts(contractsPendingPayment);
    setLiveContentContracts(contractsWithLiveContent);
  }, [contracts]);

  const handleRefetchContract = (contractId: string, handleFetched: () => void) => {
    fetchContract(contractId)
      .then((response) => {
        const updatedContracts = contracts.map((contract) => {
          if (contract.contractId === contractId) {
            return buildContractFromApiResponse(response.contract);
          }
          return contract;
        });
        setContracts(updatedContracts);
        handleFetched();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDeleteContract = (contractId: string) => {
    const updatedContracts = contracts.filter((contract) => contract.contractId !== contractId);
    setContracts(updatedContracts);
  };

  return (
    <>
      <LoadingOverlay visible={gmvLoading || contractsLoading} />
      <Stack>
        <Container fluid>
          <Box px="xl" mb="md">
            <GMVForecast campaignId={campaignId} />
          </Box>
          <BudgetSummary gmvData={campaignGMVData} campaignName={campaignName} />
        </Container>
        <PendingPaymentsTable
          user={user} // TODO (victoria 3.2024): using for display name in ManualPaymentModal - need to get rid of this
          contracts={pendingPaymentContracts}
          handleRefetchContract={handleRefetchContract}
        />
        <LiveContentApprovalTable
          user={user} // TODO (victoria 3.2024): using for display name in ManualPaymentModal - need to get rid of this
          contracts={liveContentContracts}
          handleRefetchContract={handleRefetchContract}
        />
        <TableSort
          user={user} // TODO (victoria 3.2024): using for display name in ManualPaymentModal - need to get rid of this
          contracts={contracts}
          setContracts={setContracts}
          handleRefetchContract={handleRefetchContract}
          handleDeleteContract={handleDeleteContract}
        />
      </Stack>
    </>
  );
}
