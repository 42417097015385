// Keep in sync with Django enum.
export enum PaymentType {
  BASE = 1,
  BONUS = 2,
}
// Keep in sync with Django model.
export interface Payment {
  createdAt: Date;
  brandName: string;
  name: string;
  id: string;
  contractId: string;
  campaignId: string;
  paymentDetailsId: string;
  transactionId: string;
  amount: number;
  currency: string;
  paymentType: PaymentType;
  isManualPayment: boolean;
  receiptUrl: string;
  memo: string;
}
