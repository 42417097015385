import { Box } from "gestalt";
import React from "react";
import "gestalt/dist/gestalt.css";

type Props = {
  children: React.ReactNode;
};

const LayoutBase = ({ children }: Props) => (
  <Box marginTop={4} width="100%">
    {children}
  </Box>
);

export default LayoutBase;
