import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { ActionIcon, Avatar, Badge, Card, Group, Stack, Text } from "@mantine/core";

import { User } from "models/User";
import { useAdminAppContext } from "admin/app/AdminAppShell";

import { OpsUserCreatorSetsTable } from "admin/app/ops/OpsUserCreatorSetsTable";
import { OpsUserOwnedCampaignTable } from "admin/app/ops/OpsUserOwnedCampaignTable";

export const OpsUserSummary = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [opsUser, setOpsUser] = useState<User | null>(null);
  const { opsUsers } = useAdminAppContext();

  // parse opsUserId from search query params
  useEffect(() => {
    const opsUserIdParam = searchParams.get("key");
    // Update if the current
    if (opsUserIdParam && opsUser?.key !== opsUserIdParam) {
      // set the ops user
      const user = opsUsers.find((u) => u.key === opsUserIdParam);
      if (user) {
        setOpsUser(user);
      }
    }
    // TODO(andrew): Should we unset the ops user if the key ißs not found?
  }, [opsUsers]);

  if (opsUser === null) {
    return null;
  }

  const getInitials = (name: string) => {
    const names = name.split(" ");
    return names[0].charAt(0) + names[names.length - 1].charAt(0);
  };

  return (
    <Card shadow="xs" padding="md" radius="md" withBorder>
      <Card.Section inheritPadding withBorder py="sm">
        <Group justify="space-between">
          <Group>
            {opsUser?.avatar_url ? (
              <Avatar w={40} h={40} src={opsUser?.avatar_url} style={{ flex: "none" }} />
            ) : (
              <Avatar w={40} h={40} style={{ flex: "none" }}>
                {getInitials(opsUser.name)}
              </Avatar>
            )}
            <Text fw={500}>{opsUser.name}</Text>
          </Group>
          <Badge>{opsUser.email}</Badge>
        </Group>
      </Card.Section>
      <Card.Section inheritPadding withBorder py="sm">
        <Stack gap="xs">
          <Text fw={500}>Owned Campaigns</Text>
          <Text c="dimmed" size="sm">
            You must go to the campaign settings to change campaign ownership.
          </Text>
          <Stack>
            <OpsUserOwnedCampaignTable
              campaignIds={opsUser.campaigns
                ?.sort((a, b) => a.title.localeCompare(b.title))
                .map((campaign) => campaign.id)}
            />
            {opsUser.campaigns?.length === 0 && <Text c="dimmed">No campaigns</Text>}
          </Stack>
        </Stack>
      </Card.Section>
      <Card.Section inheritPadding withBorder py="sm">
        <Stack>
          <Text fw={500}>Trained Creator Sets</Text>
          <Text c="dimmed" size="sm">
            You must go to the creator set settings to change which ops member is trained for the
            creator set.
          </Text>
          <Stack>
            <OpsUserCreatorSetsTable
              creatorSetIds={opsUser.trained_creator_sets
                ?.sort((a, b) => a.name.localeCompare(b.name))
                .map((creatorSet) => creatorSet.id)}
            />
            {opsUser.trained_creator_sets?.length === 0 && <Text c="dimmed">No creator sets</Text>}
          </Stack>
        </Stack>
      </Card.Section>
      <Card.Section inheritPadding withBorder py="sm">
        <Stack>
          <Text fw={500}>Tasks</Text>
        </Stack>
      </Card.Section>
    </Card>
  );
};

export default OpsUserSummary;
