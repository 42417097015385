import React from "react";
import BasicNavButton from "campaigns/navbar/BasicNavButton";
import { ActionIcon, Badge } from "@mantine/core";
import { CreatorSet } from "components/discovery/Datamodels";
import { getTextWidth } from "campaigns/helpers/mainNavButtonHelpers";

const CreatorSetNavButton = ({
  creatorSet,
  isSelected,
  onClick,
  numTodos,
}: {
  creatorSet: CreatorSet;
  isSelected: boolean;
  onClick: (campaign: CreatorSet) => void;
  numTodos: number | null;
}) => (
  <BasicNavButton
    isSelected={isSelected}
    text={creatorSet.name}
    leftSection={
      <ActionIcon
        variant="transparent"
        component="div"
        size={26}
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          event.stopPropagation(); // allow toggle of showing ad groups to be separate from selecting campaign button
          // Navigate to the creatorSet page
        }}
      />
    }
    rightSection={
      numTodos && numTodos > 0 ? (
        <Badge size="sm" color="red" miw={getTextWidth(numTodos.toString()) + 15}>
          {numTodos}
        </Badge>
      ) : null
    }
    onButtonClick={() => onClick(creatorSet)}
  />
);

export default CreatorSetNavButton;
