import React from "react";

import { Flex, RingProgress, Text } from "@mantine/core";

import Deliverable, {
  ContractDeliverableStatus,
  ContractDeliverableStatusMapping,
} from "components/contracts/models/Deliverable";

import { BOLD_FONT_WEIGHT } from "components/contracts/dashboard/Constants";

export default function DeliverableProgress({ deliverables }: { deliverables: Deliverable[] }) {
  const RING_SIZE = 55;
  const RING_THICKNESS = 5;

  const numDeliverables = deliverables.length;
  const numDeliverablesLive = deliverables.filter(
    (deliverable) =>
      ContractDeliverableStatusMapping[deliverable.status] >=
      ContractDeliverableStatusMapping[ContractDeliverableStatus.LIVE_CONTENT_APPROVED],
  ).length;
  const percentage = (numDeliverablesLive / numDeliverables) * 100 + 1;

  if (numDeliverablesLive === numDeliverables) {
    return (
      <Flex justify="center">
        <RingProgress
          size={RING_SIZE}
          thickness={RING_THICKNESS}
          sections={[{ value: 100, color: "teal" }]}
          label={
            <Text c="green" fw={BOLD_FONT_WEIGHT} ta="center" size="xs">
              {numDeliverablesLive} / {numDeliverables}
            </Text>
          }
        />
      </Flex>
    );
  }

  return (
    <Flex justify="center">
      <RingProgress
        size={RING_SIZE}
        thickness={RING_THICKNESS}
        sections={[{ value: percentage, color: "yellow" }]}
        label={
          <Text c="yellow" fw={BOLD_FONT_WEIGHT} ta="center" size="xs">
            {numDeliverablesLive} / {numDeliverables}
          </Text>
        }
      />
    </Flex>
  );
}
