import { Button, Tooltip } from "@mantine/core";
import { useDisclosure, useHover } from "@mantine/hooks";
import { IconEdit } from "@tabler/icons-react";
import React from "react";

const EditEmailSettingsButton = ({
  isDisabled,
  onClick,
}: {
  isDisabled: boolean;
  onClick: () => void;
}) => {
  const editButton = (
    <Button
      variant="outline"
      size="xs"
      color="var(--mantine-color-gray-4)"
      leftSection={<IconEdit size={14} color="black" />}
      disabled={isDisabled}
      styles={{
        label: {
          color: "black",
        },
        section: {
          marginRight: 8,
        },
      }}
      onClick={onClick}>
      Edit
    </Button>
  );

  return isDisabled ? (
    <Tooltip label="Please save ad group before editing email settings">{editButton}</Tooltip>
  ) : (
    editButton
  );
};

export default EditEmailSettingsButton;
