import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Container, Paper, SegmentedControl, Select, Stack } from "@mantine/core";

import { Notifications } from "@mantine/notifications";

import { getCampaigns } from "components/contracts/common/Api";

import { useAdminAppContext } from "admin/app/AdminAppShell";

import AdminLabelingReporting from "admin/app/labeling/AdminLabelingReporting";
import AdminContractBrandReviewFeed from "components/contracts/brand_review/AdminContractBrandReviewFeed";
import AllPendingPayments from "components/contracts/dashboard/AllPendingPayments";
import Billing from "components/contracts/dashboard/Billing";
import CampaignProgress from "components/campaign/dashboard/CampaignProgress";
import ContentLibrary from "components/contracts/content/ContentLibrary";
import ContentReviewFeed from "components/contracts/review/ContentReviewFeed";
import ContractsTable from "components/contracts/dashboard/ContractsTable";
import GMVSummary from "components/contracts/dashboard/GMVSummary";
import OpsInternalProgress from "components/campaign/dashboard/OpsInternalProgress";
import OverdueContracts from "components/contracts/dashboard/OverdueContracts";
import RepeatDeals from "components/campaign/dashboard/RepeatDeals";
import Transitions from "components/campaign/dashboard/Transitions";

enum NavItem {
  BILLING = "billing",
  CAMPAIGN_PROGRESS = "campaign_progress",
  CONTRACTS = "contracts",
  CONTRACT_REVIEW = "contract_review",
  CONTENT_REVIEW = "content_review",
  CONTENT_LIBRARY = "content_library",
  GMV = "gmv",
  OPS_PROGRESS = "ops_progress",
  OPS_SOURCING = "ops_sourcing",
  OVERDUE = "overdue_contracts",
  REPEAT_DEALS = "repeat_deals",
  PAYMENTS = "payments",
  TRANSITIONS = "transitions",
}

export default function AdminDashboard() {
  // User state
  const { user } = useAdminAppContext();

  // URL Params
  const [searchParams, setSearchParams] = useSearchParams();

  // Selected Campaign
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaignId, setSelectedCampaignId] = useState(null);

  // Selected Navitem
  const [navItem, setNavItem] = useState(null);

  useEffect(() => {
    getCampaigns().then((data) => {
      const transformedData = data.campaigns.map(
        ({ title, id }: { title: string; id: string }) => ({
          value: id.toString(),
          label: title,
        }),
      );
      setCampaigns(transformedData);
    });
  }, []);

  useEffect(() => {
    if (campaigns && campaigns.length > 0) {
      if (searchParams.get("campaignId") !== null) {
        setSelectedCampaignId(searchParams.get("campaignId"));
      }
      if (searchParams.get("navItem") !== null) {
        setNavItem(searchParams.get("navItem"));
      }
    }
  }, [campaigns]);

  useEffect(() => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    if (navItem === NavItem.GMV.valueOf()) {
      updatedSearchParams.delete("campaignId");
    } else if (selectedCampaignId) {
      updatedSearchParams.set("campaignId", selectedCampaignId.toString());
    }
    if (navItem) {
      updatedSearchParams.set("navItem", navItem);
    }
    setSearchParams(updatedSearchParams.toString());
  }, [selectedCampaignId, navItem]);

  return (
    <>
      <Notifications />
      <Paper p="md">
        <Container size="s">
          <Stack mb="sm" gap="xs">
            <SegmentedControl
              color="blue"
              value={navItem}
              onChange={(value) => setNavItem(value)}
              data={[
                { label: "Contracts", value: NavItem.CONTRACTS.valueOf() },
                { label: "Overdue", value: NavItem.OVERDUE.valueOf() },
                { label: "Review Contracts", value: NavItem.CONTRACT_REVIEW.valueOf() },
                { label: "Repeat Deals", value: NavItem.REPEAT_DEALS.valueOf() },
                { label: "Content Review", value: NavItem.CONTENT_REVIEW.valueOf() },
                { label: "Content Library", value: NavItem.CONTENT_LIBRARY.valueOf() },
                { label: "Payments", value: NavItem.PAYMENTS.valueOf() },
                { label: "GMV", value: NavItem.GMV.valueOf() },
                { label: "Billing", value: NavItem.BILLING.valueOf() },
                { label: "Campaign Progress", value: NavItem.CAMPAIGN_PROGRESS.valueOf() },
                { label: "Ops Progress", value: NavItem.OPS_PROGRESS.valueOf() },
                { label: "Ops Sourcing", value: NavItem.OPS_SOURCING.valueOf() },
                { label: "Transitions (Alpha)", value: NavItem.TRANSITIONS.valueOf() },
              ]}
            />
            {(navItem === NavItem.CONTRACTS.valueOf() ||
              navItem === NavItem.CONTENT_REVIEW.valueOf() ||
              navItem === NavItem.CONTENT_LIBRARY.valueOf() ||
              navItem === NavItem.REPEAT_DEALS.valueOf()) && (
              <Select
                label="Select Campaign"
                searchable
                value={selectedCampaignId}
                onChange={(value) => setSelectedCampaignId(value)}
                data={campaigns}
              />
            )}
          </Stack>
        </Container>
        {navItem === NavItem.CONTRACTS.valueOf() && (
          <ContractsTable
            user={user} // TODO (victoria 3.2024): using for display name in ManualPaymentModal - need to get rid of this
            campaignId={selectedCampaignId}
            campaignName={
              campaigns.find((campaign) => campaign.value === selectedCampaignId)?.label
            }
          />
        )}
        {navItem === NavItem.OVERDUE.valueOf() && <OverdueContracts />}
        {navItem === NavItem.CONTRACT_REVIEW.valueOf() && (
          <AdminContractBrandReviewFeed showAdminOptions />
        )}
        {navItem === NavItem.REPEAT_DEALS.valueOf() && (
          <RepeatDeals campaignId={selectedCampaignId} />
        )}
        {navItem === NavItem.CONTENT_REVIEW.valueOf() && (
          <ContentReviewFeed campaignId={selectedCampaignId} showAdminOptions />
        )}
        {navItem === NavItem.CONTENT_LIBRARY.valueOf() && (
          <ContentLibrary campaignId={selectedCampaignId} />
        )}
        {navItem === NavItem.PAYMENTS.valueOf() && <AllPendingPayments user={user} />}
        {navItem === NavItem.BILLING.valueOf() && <Billing user={user} />}
        {navItem === NavItem.GMV.valueOf() && <GMVSummary user={user} />}
        {navItem === NavItem.CAMPAIGN_PROGRESS.valueOf() && <CampaignProgress user={user} />}
        {navItem === NavItem.OPS_PROGRESS.valueOf() && <OpsInternalProgress user={user} />}
        {navItem === NavItem.OPS_SOURCING.valueOf() && <AdminLabelingReporting />}
        {navItem === NavItem.TRANSITIONS.valueOf() && <Transitions user={user} />}
      </Paper>
    </>
  );
}
