import React from "react";

import { Flex, Stack, Text } from "@mantine/core";

import { MediaEntry } from "components/MediaBlockCard";
import Video from "components/creator_lists/unified_creator_rep/Video";

function normalizeHashtag(hashtag: string) {
  return hashtag.replace("#", "").toLowerCase();
}

export default function RelevantVideos({
  relevantVideos,
  hashtag,
  refetchThumbnails,
}: {
  relevantVideos: MediaEntry[];
  hashtag: string;
  refetchThumbnails: boolean;
}) {
  if (!relevantVideos || relevantVideos.length === 0 || !hashtag) {
    return null;
  }

  const numVideos = relevantVideos.length;

  return (
    <Stack>
      <Text px="xs" fw="600">
        We found {numVideos} video{numVideos > 1 ? "s" : ""} from this creator related to{" "}
        <Text c="blue" span fw="600">
          #{normalizeHashtag(hashtag)}
        </Text>
      </Text>
      <Flex
        px="xs"
        wrap="nowrap"
        gap="md"
        style={{
          overflow: "scroll",
        }}>
        {relevantVideos.map((video) => (
          <Video key={video.video_id} video={video} refetchThumbnails={refetchThumbnails} />
        ))}
      </Flex>
    </Stack>
  );
}
