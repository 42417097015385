import { getAuth } from "firebase/auth";
import { handleResult } from "utils/ApiUtils";

import { API_URL } from "configs/Configs";

export const GET_CREATOR_SET_NAMES_URL = `${API_URL}/api/ops/debug/get_creatorset_names`;
export const GET_CREATORS_FOR_CREATOR_SET_URL = `${API_URL}/api/ops/debug/get_creators_for_creatorset`;
export const GET_RECOMMENDATIONS_FOR_CREATOR_SET_URL = `${API_URL}/api/ops/debug/get_recommendations_for_creatorset`;

export const getCreatorSetNames = async () => {
  const firebaseToken = await getAuth().currentUser?.getIdToken();
  const requestUrl = new URL(`${GET_CREATOR_SET_NAMES_URL}/`);

  const request = new Request(requestUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
  });
  const response = await handleResult(request);
  return response;
};

export const getCreatorsForCreatorSet = async (
  creatorSetId: string,
  abortController: AbortController,
) => {
  const firebaseToken = await getAuth().currentUser?.getIdToken();
  const requestUrl = new URL(`${GET_CREATORS_FOR_CREATOR_SET_URL}/`);
  requestUrl.searchParams.append("creatorSetId", creatorSetId);

  const request = new Request(requestUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
  });
  const response = await handleResult(request, abortController);
  return response;
};

export const getRecommendationsForCreatorSet = async (
  creatorSetId: string,
  abortController: AbortController,
) => {
  const firebaseToken = await getAuth().currentUser?.getIdToken();
  const requestUrl = new URL(`${GET_RECOMMENDATIONS_FOR_CREATOR_SET_URL}/`);
  requestUrl.searchParams.append("creatorSetId", creatorSetId);

  const request = new Request(requestUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
  });
  const response = await handleResult(request, abortController);
  return response;
};

export default null;
