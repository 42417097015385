import React, { useEffect, useState } from "react";
import { User } from "firebase/auth";

import {
  Anchor,
  Button,
  Container,
  Flex,
  Modal,
  NumberInput,
  Select,
  Space,
  Text,
  TextInput,
} from "@mantine/core";

import { useDisclosure } from "@mantine/hooks";

import { notifications, Notifications } from "@mantine/notifications";

import { useInputState } from "@mantine/hooks";

import { API_URL } from "configs/Configs";
import { handleResult } from "utils/ApiUtils";
import { getAndSetCampaigns } from "components/campaign/CampaignUtils";

export const createLiveContentRequest = async ({
  user,
  campaignId,
  linkUrl,
  instagramUsername = "",
  shortUrl = "",
  setButtonLoading,
}: {
  user: User;
  campaignId: number;
  linkUrl: string;
  instagramUsername?: string;
  shortUrl?: string;
  setButtonLoading: (buttonLoading: boolean) => void;
}) => {
  setButtonLoading(true);
  const firebaseToken = await user.getIdToken();
  const request = new Request(`${API_URL}/api/models/campaign_media/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
    body: JSON.stringify({
      campaign_id: campaignId,
      link_url: linkUrl,
      instagram_username: instagramUsername,
      short_link_url: shortUrl,
    }),
  });
  try {
    const response = await handleResult(request);
    if (response != null && !response.success) {
      const err = JSON.stringify(response);
      notifications.show({
        color: "red",
        title: "Error with adding the live media!",
        message: err,
        autoClose: false,
      });
    } else if (response != null && response.success) {
      notifications.show({
        title: "Media added successfully!",
        message: (
          <Anchor
            href={`https://core-staging.1stcollab.com/admin/campaigns/campaignmedia/${response.media.id}/change/`}
            target="_blank">
            Go to campaign media admin tool.
          </Anchor>
        ),
        autoClose: false,
      });
    } else {
      notifications.show({
        color: "red",
        title: "Error with adding the live media!",
        message: "Unknown error.",
        autoClose: false,
      });
    }
  } catch (e) {
    console.log(e);
    notifications.show({
      color: "red",
      title: "Error with adding the live media!",
      message: e.message,
      autoClose: false,
    });
  } finally {
    setButtonLoading(false);
  }
};

export default function CreateLiveContent({ user }: { user: User }) {
  const [campaigns, setCampaigns] = useState([]);
  const [campaignId, setCampaignId] = useInputState(null);
  const [linkUrl, setLinkUrl] = useInputState("");
  const [urlErrorMessage, setUrlErrorMessage] = useState("");
  const [campaignIdsAndTitles, setCampaignIdsAndTitles] = useState([]);
  const [isInstagram, setIsInstagram] = useState(false);
  const [instagramUsername, setInstagramUsername] = useInputState("");
  const [shortUrl, setShortUrl] = useInputState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const [price, setPrice] = useInputState<number>(null);

  const [opened, { open, close }] = useDisclosure(false);

  useEffect(() => {
    // Note: we sometimes fetch all of the campaigns multiple times
    // if we have this component + the CampaignsList component on the same page.
    const abortController = new AbortController();
    getAndSetCampaigns(user, setCampaigns, abortController);
    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    const updatedCampaignIdsAndTitles = campaigns.map((campaign) => ({
      value: campaign.id.toString(),
      label: campaign.title,
    }));
    setCampaignIdsAndTitles(updatedCampaignIdsAndTitles);
  }, [campaigns]);

  // Whenever a new url is entered, check if it is an instagram url.
  useEffect(() => {
    try {
      const { hostname } = new URL(linkUrl);
      if (hostname === "www.instagram.com") {
        setIsInstagram(true);
      } else {
        setIsInstagram(false);
      }
      setUrlErrorMessage("");
    } catch (e) {
      setIsInstagram(false);
      setUrlErrorMessage("Please enter a valid url.");
    }
  }, [linkUrl]);

  const handleCreate = () => {
    createLiveContentRequest({
      user,
      campaignId,
      linkUrl,
      instagramUsername,
      shortUrl,
      setButtonLoading,
    });
  };

  return (
    <>
      <Modal size="xs" opened={opened} onClose={close} title="Add Deliverable Details">
        <NumberInput
          label="Price"
          miw="85%"
          value={price / 100}
          placeholder="Optional..."
          onChange={(value: number) => setPrice(Math.round(value * 100))}
          min={0}
          max={Math.round(price / 100)}
          decimalScale={2}
          fixedDecimalScale
          decimalSeparator="."
          thousandSeparator=","
          hideControls
          prefix="$"
        />
      </Modal>
      <Container size="80%">
        <Notifications />
        <Flex justify="center" align="center" direction="row">
          <Text fw={900}>Add Live Content</Text>
        </Flex>
        <Flex align="Flex-start" direction="column">
          <Select
            label="Campaign"
            searchable
            data={campaignIdsAndTitles}
            onChange={setCampaignId}
            mb="lg"
            required
            width={800}
          />
          <TextInput
            label="Media URL"
            required
            placeholder="media url link"
            onChange={setLinkUrl}
            value={linkUrl}
            error={urlErrorMessage !== "" ? urlErrorMessage : null}
            miw={600}
          />
          <Space h={10} />
          <TextInput
            label="Tracking Link (optional)"
            placeholder="https://www.kindred.1stcollab.com/alinatrvl"
            onChange={setShortUrl}
            value={shortUrl}
            miw={600}
          />
          <Space h={10} />
          <TextInput
            label="Username"
            placeholder="mrbeast"
            onChange={setInstagramUsername}
            value={instagramUsername}
            miw={600}
            disabled={!isInstagram}
          />
          <Space h={10} />
          <Button loading={buttonLoading} onClick={open}>
            Create
          </Button>
        </Flex>
      </Container>
    </>
  );
}
