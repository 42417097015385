import React from "react";

import { Alert } from "@mantine/core";

import { IconAlertCircle } from "@tabler/icons-react";

export default function LoadingError({ message }: { message: string }) {
  return (
    <Alert radius="md" icon={<IconAlertCircle size="1rem" />} title="Error" color="red">
      {message}
    </Alert>
  );
}
