import React, { useEffect, useState } from "react";
import {
  fetchAllContractReviewData,
  fetchAllPendingContractReviewCount,
} from "components/contracts/common/Api";
import { CreatorDetails } from "components/discovery/Datamodels";
import {
  Badge,
  Collapse,
  Container,
  Group,
  LoadingOverlay,
  Stack,
  Title,
  Card,
} from "@mantine/core";
import {
  CampaignContractBrandPendingReviewCount,
  CampaignContractBrandReview,
  ContractReviewStatus,
} from "components/contracts/brand_review/types";
import { Notifications } from "@mantine/notifications";
import NothingHereComponent from "components/general/NothingHere";
import { ContractApprovalCard } from "components/contracts/brand_review/ContractBrandReviewCard";
import { IconChevronDown, IconChevronRight } from "@tabler/icons-react";
import Spacer from "components/Spacer";

export default function AdminContractBrandReviewFeed({
  showAdminOptions,
}: {
  showAdminOptions?: boolean;
}) {
  const [creatorDetails, setCreatorDetails] = useState<Record<number, CreatorDetails>>({});
  const [allContractReviews, setAllContractReviews] = useState<CampaignContractBrandReview[]>([]);
  const [pendingContractReviewCount, setPendingContractReviewCount] = useState<
    CampaignContractBrandPendingReviewCount[]
  >([]);
  const [isClose, setIsClose] = useState<{ [key: string]: boolean }>({});
  const [refreshKey, setRefreshKey] = useState(0);
  const toggleOpen = (id: string) => {
    setIsClose((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    setLoaded(false);

    fetchAllContractReviewData().then((data) => {
      setAllContractReviews(data.all_contract_reviews);
      setCreatorDetails(data.creator_details);

      setLoaded(true);
    });
    fetchAllPendingContractReviewCount().then((data) => {
      setPendingContractReviewCount(data.pending_contract_reviews);
    });
  }, [refreshKey]);

  return (
    <Container>
      <LoadingOverlay visible={!loaded} />
      <Notifications />
      <Stack gap="xs">
        {loaded && allContractReviews.length === 0 && <NothingHereComponent />}
        <Title order={3}>Pending Contracts</Title>
        <Card shadow="xs" padding="md" radius="md" withBorder>
          <Group style={{ flexWrap: "wrap" }}>
            {pendingContractReviewCount.map((campaign) => (
              <Badge variant="light" key={campaign.campaign_id} color="black">
                {`${campaign.campaign_name} `}(
                <a href={campaign.pending_review_link} target="_blank" rel="noopener noreferrer">
                  {`${campaign.pending_review_count} Pending Contracts`}
                </a>
                )
              </Badge>
            ))}
          </Group>
        </Card>
        <Spacer height={20} />
        {allContractReviews.map((campaign_with_review) => {
          const contractReviews = campaign_with_review.contract_reviews;
          return (
            <Stack key={campaign_with_review.campaign_id} gap="xs">
              <Title order={3} onClick={() => toggleOpen(campaign_with_review.campaign_id)}>
                {!isClose[campaign_with_review.campaign_id] ? (
                  <IconChevronDown />
                ) : (
                  <IconChevronRight />
                )}
                {`${campaign_with_review.campaign_name} `}
              </Title>
              <Collapse in={!isClose[campaign_with_review.campaign_id]}>
                {contractReviews.map((contractReview, index) => {
                  const creatorDetail = creatorDetails[contractReview.creator_id];
                  const {
                    deliverables,
                    deliverable_package_usage_rights: usageRightsDuration,
                    deliverable_package_usage_rights_price: usagePrice,
                    deliverable_package_creator_asking_usage_rights_price:
                      creatorAskingUsageRightsPrice,
                  } = contractReview;
                  const inReview =
                    contractReview.brand_review_status === ContractReviewStatus.IN_REVIEW;
                  const accepted =
                    contractReview.brand_review_status === ContractReviewStatus.APPROVED;
                  const rejected =
                    contractReview.brand_review_status === ContractReviewStatus.REJECTED;

                  return (
                    <ContractApprovalCard
                      key={contractReview.id}
                      contractReview={contractReview}
                      deliverables={deliverables}
                      deliverablePackageInfo={{
                        usageRightsDuration,
                        usagePrice,
                        creatorAskingUsageRightsPrice,
                      }}
                      creatorDetail={creatorDetail}
                      isAccepted={accepted}
                      isRejected={rejected}
                      isInReview={inReview}
                      showAdminOptions={showAdminOptions}
                      index={index}
                      setRefreshKey={setRefreshKey}
                    />
                  );
                })}
              </Collapse>
            </Stack>
          );
        })}
      </Stack>
    </Container>
  );
}
