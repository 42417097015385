import React, { useEffect, useState } from "react";
import { Anchor, Divider, Group, Select, Stack, Text } from "@mantine/core";
import { IconVideo } from "@tabler/icons-react";
import { addDeliverableVideo } from "components/contracts/common/Api";
import {
  showFailureNotification,
  showSuccessNotification,
} from "components/common/Notifications";
import { ContentStatus, SUPPORTED_FORMATS_TO_LABELS } from "components/contracts/common/Common";
import { SupportedFormat } from "models/Common";

import {
  ContractDeliverableStatus,
  ContractDeliverableStatusMapping,
} from "components/contracts/models/Deliverable";
import DeliverableVideo from "components/contracts/models/DeliverableVideo";
import PreSubmissionChecklist from "components/contracts/common/PreSubmissionChecklist";
import ReviewVideoSubmission from "components/contracts/deliverables/ReviewVideoSubmission";
import MetadataFields from "components/contracts/deliverables/MetadataFields";
import VersionedDeliverableVideo from "components/contracts/models/VersionedDeliverableVideo";
import VideoDropzone from "components/contracts/deliverables/VideoDropzone";

import { toShortDateString, timestampToSeconds, secondsToTimestamp } from "utils/DateUtils";

function getReusableVideos(
  deliverableId: string,
  deliverableFormat: SupportedFormat,
  deliverableVideos: DeliverableVideo[],
) {
  if (!deliverableVideos) {
    return [];
  }

  // Only YouTube Shorts, TikTok Dedicated Videos, and Instagram Dedicated Reels can be reused.
  if (
    deliverableFormat !== SupportedFormat.YOUTUBE_SHORT &&
    deliverableFormat !== SupportedFormat.TIKTOK_DEDICATED_VIDEO &&
    deliverableFormat !== SupportedFormat.INSTAGRAM_DEDICATED_REEL
  ) {
    return [];
  }

  // Select all uploaded videos within YouTube Shorts, TikTok Dedicated Videos, and Instagram Dedicated Reels
  // that have not been used for the current deliverable.
  return deliverableVideos.filter((deliverableVideo) => {
    if (
      deliverableVideo.deliverable.id !== deliverableId &&
      deliverableVideo.deliverable.format !== deliverableFormat &&
      deliverableVideo.contentStatus !== ContentStatus.REVISIONS_REQUESTED &&
      (deliverableVideo.deliverable.format === SupportedFormat.YOUTUBE_SHORT ||
        deliverableVideo.deliverable.format === SupportedFormat.TIKTOK_DEDICATED_VIDEO ||
        deliverableVideo.deliverable.format === SupportedFormat.INSTAGRAM_DEDICATED_REEL)
    ) {
      return true;
    }
    return false;
  });
}

function VideoSelector({
  contractId,
  deliverableId,
  deliverableFormat,
  latestVideosForContract,
  setLatestVideosForContract,
  deliverableVideos,
  setFetchedVersionedDeliverableVideo,
  setDeliverableStatus,
  handleRefetchUploadedVideos,
  disabled,
}: {
  contractId: string;
  deliverableId: string;
  deliverableFormat: SupportedFormat;
  latestVideosForContract: DeliverableVideo[];
  setLatestVideosForContract: (videos: DeliverableVideo[]) => void;
  deliverableVideos: DeliverableVideo[];
  setFetchedVersionedDeliverableVideo: (
    versionedDeliverableVideo: VersionedDeliverableVideo,
  ) => void;
  setDeliverableStatus: (status: ContractDeliverableStatus) => void;
  handleRefetchUploadedVideos: (handleFetched: () => void) => void;
  disabled: boolean;
}) {
  const UPLOAD_NEW_VIDEO_VALUE = "upload_new_video";
  const [videoId, setVideoId] = useState<string>("");
  const [selectedValue, setSelectedValue] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [disableMetadata, setDisableMetadata] = useState<boolean>(false);
  const [showErrors, setShowErrors] = useState<boolean>(false);

  const [title, setTitle] = useState<string>("");
  const [caption, setCaption] = useState<string>("");
  const [integrationTimestamp, setIntegrationTimestamp] = useState<string>("");
  const [metadataValid, setMetadataValid] = useState<boolean>(false);
  const [reusableVideos, setReusableVideos] = useState<DeliverableVideo[]>([]);

  if (!disabled) {
    useEffect(() => {
      const computedReusableVideos = getReusableVideos(
        deliverableId,
        deliverableFormat,
        latestVideosForContract,
      );
      const currentDeliverableVideo = deliverableVideos?.[0];
      const newReusableVideos = currentDeliverableVideo
        ? [currentDeliverableVideo, ...computedReusableVideos]
        : computedReusableVideos;
      setReusableVideos(newReusableVideos);
    }, [deliverableVideos, latestVideosForContract]);
  }

  // If there are no reusable videos, show the VideoDropzone instead of the selector.
  if (disabled || reusableVideos.length === 0) {
    return (
      <VideoDropzone
        format={deliverableFormat}
        title={title}
        setTitle={setTitle}
        caption={caption}
        setCaption={setCaption}
        integrationTimestamp={integrationTimestamp}
        setIntegrationTimestamp={setIntegrationTimestamp}
        metadataValid={metadataValid}
        setMetadataValid={setMetadataValid}
        disableMetadata={disableMetadata}
        setDisableMetadata={setDisableMetadata}
        contractId={contractId}
        deliverableId={deliverableId}
        setDeliverableStatus={setDeliverableStatus}
        handleRefetchUploadedVideos={handleRefetchUploadedVideos}
        disabled={disabled}
      />
    );
  }

  const handleAddDeliverableVideo = () => {
    setIsLoading(true);
    addDeliverableVideo(
      deliverableId,
      videoId,
      title,
      caption,
      integrationTimestamp.length > 0 ? timestampToSeconds(integrationTimestamp) : 0,
    )
      .then((response) => {
        if (response.success) {
          const addedDeliverableVideo = DeliverableVideo.deserialize(response.deliverableVideo);
          const newVersionedDeliverableVideo = new VersionedDeliverableVideo({
            deliverableId,
            deliverableVideos: [addedDeliverableVideo, ...deliverableVideos],
          });
          setFetchedVersionedDeliverableVideo(newVersionedDeliverableVideo);

          const currentDeliverableHasVideo = latestVideosForContract.find(
            (deliverableVideo) => deliverableVideo.deliverable.id === deliverableId,
          );
          const newLatestVideosForContract = currentDeliverableHasVideo
            ? latestVideosForContract.map((deliverableVideo) => {
                if (deliverableVideo.deliverable.id === deliverableId) {
                  return addedDeliverableVideo;
                }
                return deliverableVideo;
              })
            : [addedDeliverableVideo, ...latestVideosForContract];
          setLatestVideosForContract(newLatestVideosForContract);

          showSuccessNotification({ message: "Video Draft successfully added to deliverable." });
          setIsLoading(false);
          setSubmitted(true);
          setDeliverableStatus(response.status as ContractDeliverableStatus);
        } else {
          showFailureNotification({
            message: `Unable to add Video Draft to deliverable. ${response.error}`,
          });
          setIsLoading(false);
        }
      })
      .catch(() => {
        showFailureNotification({ message: "Unable to add Video Draft to deliverable." });
        setIsLoading(false);
      });
  };

  const videoOptions = reusableVideos.map((video) => ({
    value: video.deliverable.id,
    label: `[${toShortDateString(video.videoDateCreated)}] - ${
      SUPPORTED_FORMATS_TO_LABELS[video.deliverable.format]
    }: ${video.name}`,
  }));

  return (
    <Stack>
      <Select
        placeholder="Select a video..."
        leftSection={<IconVideo size="1rem" />}
        value={selectedValue}
        onChange={(value) => {
          setSelectedValue(value);

          const selectedDeliverableVideo = reusableVideos.find(
            (video) => video.deliverable.id === value,
          );
          if (selectedDeliverableVideo) {
            if (selectedDeliverableVideo.integrationTimestamp && selectedDeliverableVideo.integrationTimestamp > 0) {
              setIntegrationTimestamp(secondsToTimestamp(selectedDeliverableVideo.integrationTimestamp));
            }
            if (selectedDeliverableVideo.title && selectedDeliverableVideo.title.length > 0) {
              setTitle(selectedDeliverableVideo.title);
            }
            if (selectedDeliverableVideo.caption && selectedDeliverableVideo.caption.length > 0) {
              setCaption(selectedDeliverableVideo.caption);
            }
          } else {
            setIntegrationTimestamp("");
            setTitle(deliverableVideos?.[0]?.title || "");
            setCaption(deliverableVideos?.[0]?.caption || "");
          }

          if (value === UPLOAD_NEW_VIDEO_VALUE) {
            setVideoId(value);
          } else {
            setVideoId(selectedDeliverableVideo?.videoId);
          }
        }}
        data={[...videoOptions, { value: UPLOAD_NEW_VIDEO_VALUE, label: "Upload a new video" }]}
      />
      {videoId && videoId.length > 0 && videoId !== UPLOAD_NEW_VIDEO_VALUE && (
        <>
          <MetadataFields
            format={deliverableFormat}
            title={title}
            setTitle={setTitle}
            caption={caption}
            setCaption={setCaption}
            integrationTimestamp={integrationTimestamp}
            setIntegrationTimestamp={setIntegrationTimestamp}
            setMetadataValid={setMetadataValid}
            disableMetadata={disableMetadata}
            showErrors={showErrors}
          />
          <Group justify="right">
            <ReviewVideoSubmission
              videoUrl={
                reusableVideos.find((video) => video.videoId === videoId).transcodedVideoLocation ||
                reusableVideos.find((video) => video.videoId === videoId).location
              }
              title={title}
              caption={caption}
              integrationTimestamp={integrationTimestamp}
              disableUploadButton={submitted}
              validUpload={metadataValid && !submitted}
              handleUpload={handleAddDeliverableVideo}
              loading={isLoading}
              handleShowError={() => setShowErrors(true)}
            />
          </Group>
        </>
      )}
      {videoId === UPLOAD_NEW_VIDEO_VALUE && (
        <VideoDropzone
          format={deliverableFormat}
          title={title}
          setTitle={setTitle}
          caption={caption}
          setCaption={setCaption}
          integrationTimestamp={integrationTimestamp}
          setIntegrationTimestamp={setIntegrationTimestamp}
          metadataValid={metadataValid}
          setMetadataValid={setMetadataValid}
          disableMetadata={disableMetadata}
          setDisableMetadata={setDisableMetadata}
          contractId={contractId}
          deliverableId={deliverableId}
          setDeliverableStatus={setDeliverableStatus}
          handleRefetchUploadedVideos={handleRefetchUploadedVideos}
        />
      )}
    </Stack>
  );
}

export default function VideoUpload({
  brandName,
  adGroupId,
  contractId,
  deliverableId,
  deliverableStatus,
  deliverableFormat,
  latestVideosForContract,
  setLatestVideosForContract,
  fetchedVersionedDeliverableVideo,
  setFetchedVersionedDeliverableVideo,
  setDeliverableStatus,
  handleRefetchUploadedVideos,
  disabled,
}: {
  brandName: string;
  adGroupId: number;
  contractId: string;
  deliverableId: string;
  deliverableStatus: ContractDeliverableStatus;
  deliverableFormat: SupportedFormat;
  latestVideosForContract: DeliverableVideo[];
  setLatestVideosForContract: (videos: DeliverableVideo[]) => void;
  fetchedVersionedDeliverableVideo: VersionedDeliverableVideo;
  setFetchedVersionedDeliverableVideo: (
    versionedDeliverableVideo: VersionedDeliverableVideo,
  ) => void;
  setDeliverableStatus: (status: ContractDeliverableStatus) => void;
  handleRefetchUploadedVideos: (handleFetched: () => void) => void;
  disabled: boolean;
}) {
  if (
    ContractDeliverableStatusMapping[deliverableStatus] >=
      ContractDeliverableStatusMapping[ContractDeliverableStatus.VIDEO_DRAFT_APPROVED] ||
    deliverableStatus === ContractDeliverableStatus.VIDEO_DRAFT_SUBMITTED
  ) {
    return null;
  }

  let divider = null;
  if (deliverableStatus === ContractDeliverableStatus.VIDEO_DRAFT_REVISIONS_REQUESTED) {
    divider = <Divider mt="sm" label="OR" />;
  }

  // TODO(albert): This is Locket only right now. In the future, we should pull this off the Ad Group.
  const locketDefaultChecklistItems = [
    "I started with a video of my face, in shock.",
    "I then swiped to my homescreen, and lingered on it for a second, so my audience could get oriented.",
    "I sent a video (not a photo!) in Locket—and I actually showed it being sent.",
    "I showed my face in the video I sent.",
    "I cut out the typing, so I’m not spending more than 1-2 seconds typing out my message in Locket.",
  ];

  const locketSoundChecklistItems = [
    <Text key="locketSound-1">
      I used{" "}
      <Anchor
        href="https://www.tiktok.com/music/love-me-like-you-7175231812330507014"
        target="_blank">
        this sound
      </Anchor>{" "}
      on TikTok.
    </Text>,
    "I started with a video of my face, in shock.",
    "My homescreen had the Locket widget on page 1, and the Locket widget had a clear picture of a family member / friend in it.",
    "I showed my face in the video / pic I sent in Locket.",
    "My video is shorter than the length of the sound (15 seconds).",
  ];

  const checklistItems = adGroupId === 54 ? locketSoundChecklistItems : locketDefaultChecklistItems;

  return (
    <>
      {divider}
      <Text fw="500">Upload a Video</Text>
      <PreSubmissionChecklist
        header={
          <Text size="md" fw="500">
            Before uploading your video for your {brandName} partnership, please ensure your video
            includes the following:
          </Text>
        }
        checklistItems={checklistItems}
        disabled={
          brandName !== "Locket" ||
          (deliverableStatus !== ContractDeliverableStatus.WAITING_FOR_VIDEO_DRAFT &&
            deliverableStatus !== ContractDeliverableStatus.VIDEO_DRAFT_REVISIONS_REQUESTED)
        }>
        <VideoSelector
          contractId={contractId}
          deliverableId={deliverableId}
          deliverableFormat={deliverableFormat}
          latestVideosForContract={latestVideosForContract}
          setLatestVideosForContract={setLatestVideosForContract}
          deliverableVideos={fetchedVersionedDeliverableVideo?.deliverableVideos}
          setFetchedVersionedDeliverableVideo={setFetchedVersionedDeliverableVideo}
          setDeliverableStatus={setDeliverableStatus}
          handleRefetchUploadedVideos={handleRefetchUploadedVideos}
          disabled={disabled}
        />
      </PreSubmissionChecklist>
    </>
  );
}
