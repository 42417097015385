import React, { useState } from "react";

import {
  ActionIcon,
  Anchor,
  Button,
  Flex,
  Group,
  Modal,
  NumberInput,
  Radio,
  Stack,
  Text,
} from "@mantine/core";

import { IconCheck } from "@tabler/icons-react";

import { useDisclosure } from "@mantine/hooks";

import { payContract } from "components/contracts/common/Api";
import { PaymentStatus } from "components/contracts/common/Common";
import { formatAmount } from "components/contracts/dashboard/Utils";
import {
  showFailureNotification,
  showSuccessNotification,
} from "components/common/Notifications";
import { PaymentType } from "components/contracts/models/Payment";

function getFailureMessage(amountInMinorUnits: number, displayName: string, errorMessage: string) {
  return (
    <Text>
      Your payment of{" "}
      <Text span fw="600">
        {formatAmount(amountInMinorUnits)}
      </Text>{" "}
      to{" "}
      <Text span fw="600">
        {displayName}
      </Text>{" "}
      failed. {errorMessage}
    </Text>
  );
}

function getSuccessMessage(amountInMinorUnits: number, displayName: string, receiptUrl: string) {
  return (
    <Text>
      Your payment of{" "}
      <Text span fw="600">
        {formatAmount(amountInMinorUnits)}
      </Text>{" "}
      to{" "}
      <Text span fw="600">
        {displayName}
      </Text>{" "}
      was successful. Click{" "}
      <Anchor href={receiptUrl} target="_blank">
        here
      </Anchor>{" "}
      to view the receipt.
    </Text>
  );
}

export default function PayButton({
  contractId,
  readyForPayment,
  paymentStatus,
  paymentType,
  displayName,
  remainingAmountInMinorUnits,
  handleRefetchContract,
  meetsBonusCondition,
}: {
  contractId: string;
  readyForPayment: boolean;
  paymentStatus: PaymentStatus;
  paymentType: PaymentType;
  displayName: string;
  remainingAmountInMinorUnits: number;
  handleRefetchContract: (contractId: string, handleFetched: () => void) => void;
  meetsBonusCondition?: boolean;
}) {
  const [firstModalOpened, { open: openFirst, close: closeFirst }] = useDisclosure();
  const [secondModalOpened, { open: openSecond, close: closeSecond }] = useDisclosure();
  const [isFullPayment, setIsFullPayment] = useState(true);
  const [paymentFinalized, setPaymentFinalized] = useState(false);
  const [amountInMinorUnits, setAmountInMinorUnits] = useState<number>(0);
  const [isProcessingPayment, setIsProcessingPayment] = useState(false);

  const handlePayContract = () => {
    setIsProcessingPayment(true);
    payContract(contractId, amountInMinorUnits, paymentType)
      .then((response) => {
        if (response.success) {
          showSuccessNotification({
            message: getSuccessMessage(amountInMinorUnits, displayName, response.receiptUrl),
            title: "Payment Succeeded",
          });
        } else {
          showFailureNotification({
            message: getFailureMessage(amountInMinorUnits, displayName, response.error),
            title: "Payment Failed",
          });
        }
        handleRefetchContract(contractId, () => {
          setIsProcessingPayment(false);
          closeSecond();
        });
      })
      .catch((error) => {
        showFailureNotification({
          message: getFailureMessage(amountInMinorUnits, displayName, error),
          title: "Payment Failed",
        });
        setIsProcessingPayment(false);
        closeSecond();
      });
  };

  return (
    <>
      <Modal
        opened={firstModalOpened}
        onClose={closeFirst}
        size="xs"
        title={<Text fw="600">Select Amount</Text>}>
        <Stack>
          <Stack>
            <Radio
              checked={isFullPayment}
              label={`Full ${paymentType === PaymentType.BONUS ? "Bonus" : "Base"} Payment`}
              onChange={() => {
                setIsFullPayment(true);
                setPaymentFinalized(true);
                setAmountInMinorUnits(Math.round(remainingAmountInMinorUnits));
              }}
            />
            <Radio
              checked={!isFullPayment}
              label={`Partial ${paymentType === PaymentType.BONUS ? "Bonus" : "Base"} Payment`}
              onChange={() => {
                setPaymentFinalized(false);
                setIsFullPayment(false);
              }}
            />
          </Stack>
          <Flex align="center" gap="sm">
            <NumberInput
              miw="85%"
              disabled={isFullPayment}
              value={amountInMinorUnits / 100}
              onChange={(value: number) => {
                setPaymentFinalized(false);
                setAmountInMinorUnits(Math.round(value * 100));
              }}
              min={0}
              max={Math.round(remainingAmountInMinorUnits / 100)}
              decimalScale={2}
              fixedDecimalScale
              decimalSeparator="."
              thousandSeparator=","
              hideControls
              prefix="$"
            />
            <ActionIcon
              size="1.5rem"
              color="blue"
              variant="light"
              disabled={isFullPayment || paymentFinalized}
              onClick={() => setPaymentFinalized(true)}>
              <IconCheck />
            </ActionIcon>
          </Flex>
          <Button
            disabled={!isFullPayment && !paymentFinalized}
            onClick={() => {
              closeFirst();
              openSecond();
            }}>
            Continue
          </Button>
        </Stack>
      </Modal>
      <Modal
        opened={secondModalOpened}
        onClose={closeSecond}
        size="xs"
        title={<Text fw="600">Confirm Amount</Text>}>
        <Stack>
          <Text>
            You are about to send a payment of{" "}
            <Text span fw="600">
              {formatAmount(amountInMinorUnits)}
            </Text>{" "}
            to{" "}
            <Text span fw="600">
              {displayName}
            </Text>
            . The remaining {paymentType === PaymentType.BONUS && "bonus "}amount on their contract
            will be{" "}
            <Text span fw="600">
              {formatAmount(remainingAmountInMinorUnits - amountInMinorUnits)}
            </Text>{" "}
            after this transaction.
          </Text>
          <Group grow justify="center">
            <Button onClick={closeSecond} variant="default" color="gray">
              Cancel
            </Button>
            <Button loading={isProcessingPayment} onClick={handlePayContract}>
              Confirm
            </Button>
          </Group>
        </Stack>
      </Modal>
      <Button
        disabled={
          !readyForPayment ||
          (!meetsBonusCondition && paymentType === PaymentType.BONUS) ||
          paymentStatus === PaymentStatus.FULLY_PAID
        }
        variant="light"
        color={paymentType === PaymentType.BONUS ? "teal" : "blue"}
        size="xs"
        onClick={() => {
          setIsFullPayment(true);
          setAmountInMinorUnits(Math.round(remainingAmountInMinorUnits));
          openFirst();
        }}>
        Pay {displayName} {paymentType === PaymentType.BONUS ? "Bonus" : "Base"}
      </Button>
    </>
  );
}
