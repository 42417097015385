import React from "react";

import { CreatorSet } from "components/discovery/Datamodels";
import BrandAndNameSelector from "campaigns/main/campaignPage/BrandAndNameSelector";

export const CreatorSetSelector = ({
  creatorSets,
  selectedCreatorSetId,
  setSelectedCreatorSetId,
  searchParams = null,
  setSearchParams = null,
}: {
  creatorSets: CreatorSet[];
  selectedCreatorSetId: number | null;
  setSelectedCreatorSetId: (creatorSetId: number) => void;
  searchParams?: URLSearchParams | null;
  setSearchParams?: (searchParams: URLSearchParams) => void | null;
}) => (
  <BrandAndNameSelector
    items={creatorSets}
    selectedItemId={selectedCreatorSetId}
    setSelectedItemId={setSelectedCreatorSetId}
    searchParams={searchParams}
    setSearchParams={setSearchParams}
    objType="creatorSetId"
    objName="Creator Set"
  />
);

export default CreatorSetSelector;
