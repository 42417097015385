import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Button, Flex, Group, TextInput } from "@mantine/core";
import { useInputState } from "@mantine/hooks";

import { CreatorDetails } from "components/discovery/Datamodels";
import { fetchCreatorByQuery } from "creators/api/creatorApiUtils";
import { fetchCreatorSetDetails } from "components/creator_sets/CreatorSetUtils";
import { CreatorV2Info } from "models/CreatorV2Info";

export const CreatorSearchInput = ({
  setResults,
  setLoading,
}: {
  setResults: (results: Record<number, CreatorDetails>) => void;
  setLoading: (loading: boolean) => void;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [query, setQuery] = useInputState("");
  const [creators, setCreators] = useState<CreatorV2Info[]>([]);

  // parse query from search params
  useEffect(() => {
    const queryParam = searchParams.get("query");
    if (queryParam) {
      if (queryParam !== query) {
        setQuery(queryParam);
        // also make a new search if the queryParam is changed manually
        fetchCreatorByQuery(queryParam).then((resp) => setCreators(resp));
      }
    } else {
      setQuery("");
    }
  }, [searchParams]);

  // update search params when query changes
  useEffect(() => {
    let changed = false;
    if (query) {
      if (query !== searchParams.get("query")) {
        searchParams.set("query", query);
        changed = true;
      }
    }
    if (changed) {
      setSearchParams(searchParams);
    }
  }, [query]);

  useEffect(() => {
    const abortController = new AbortController();
    if (creators?.length > 0) {
      const creatorIds = creators.map((creator) => creator.id);
      setLoading(true);
      fetchCreatorSetDetails(creatorIds, {}, setResults, abortController).finally(() =>
        setLoading(false),
      );
    } else {
      setResults({});
    }
    return () => {
      abortController.abort();
    };
  }, [creators]);

  return (
    <Flex justify="center" gap="md">
      <Group align="flex-end">
        <TextInput
          label="Search for Creator"
          description="CreatorId or Handle"
          placeholder="raimeetravels"
          value={query}
          onChange={setQuery}
        />
        <Button onClick={() => fetchCreatorByQuery(query).then((resp) => setCreators(resp))}>
          Search
        </Button>
      </Group>
    </Flex>
  );
};

export default CreatorSearchInput;
