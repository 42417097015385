import React from "react";

import { Stepper, ThemeIcon } from "@mantine/core";

import { IconFileDescription, IconSignature, IconVideo, IconVideoPlus } from "@tabler/icons-react";

import { ContentStatus } from "components/contracts/common/Common";

import {
  ContractDeliverableStatus,
  ContractDeliverableStatusMapping,
} from "components/contracts/models/Deliverable";
import ReviewTimelineData from "components/contracts/models/ReviewTimelineData";

import { toShortDateString } from "utils/DateUtils";

export default function ReviewTimeline({
  reviewTimelineData,
}: {
  reviewTimelineData: ReviewTimelineData;
}) {
  let scriptReviewDescription = "";
  let scriptStepColor = "";
  let scriptIcon = <IconFileDescription style={{ width: "70%", height: "70%" }} />;
  let scriptCompleted = false;

  // This is a condition to handle cases in which scripts were approved outside of the review tool.
  if (
    !reviewTimelineData.scriptReviewStatus &&
    reviewTimelineData.scriptDueDate &&
    reviewTimelineData.videoSubmissionDate
  ) {
    scriptReviewDescription = `Approved on ${toShortDateString(
      reviewTimelineData.videoSubmissionDate,
    )}`;
    scriptStepColor = "teal";
    scriptCompleted = true;
  } else if (reviewTimelineData.scriptReviewStatus === ContentStatus.APPROVED) {
    scriptReviewDescription = `Approved on ${toShortDateString(
      reviewTimelineData.scriptReviewDate,
    )}`;
    scriptStepColor = "teal";
    scriptCompleted = true;
  } else if (reviewTimelineData.scriptReviewStatus === ContentStatus.REVISIONS_REQUESTED) {
    scriptReviewDescription = `Revision Requested on ${toShortDateString(
      reviewTimelineData.scriptReviewDate,
    )}`;
    scriptStepColor = "yellow";
    scriptIcon = (
      <ThemeIcon radius="xl" color={scriptStepColor}>
        {scriptIcon}
      </ThemeIcon>
    );
  } else if (reviewTimelineData.scriptReviewStatus === ContentStatus.PENDING_REVIEW) {
    scriptReviewDescription = `Submitted on ${toShortDateString(
      reviewTimelineData.scriptSubmissionDate,
    )}`;
    scriptStepColor = "red";
    scriptIcon = (
      <ThemeIcon radius="xl" color={scriptStepColor}>
        {scriptIcon}
      </ThemeIcon>
    );
  } else {
    scriptReviewDescription = `Due by ${toShortDateString(reviewTimelineData.scriptDueDate)}`;
    scriptStepColor = "yellow";
    scriptIcon = (
      <ThemeIcon radius="xl" color={scriptStepColor}>
        {scriptIcon}
      </ThemeIcon>
    );
  }

  let videoReviewDescription = "";
  let videoStepColor = "";
  let videoIcon = <IconVideo style={{ width: "70%", height: "70%" }} />;
  let videoCompleted = false;
  if (reviewTimelineData.videoReviewStatus === ContentStatus.APPROVED) {
    videoReviewDescription = `Approved on ${toShortDateString(reviewTimelineData.videoReviewDate)}`;
    videoStepColor = "teal";
    videoCompleted = true;
  } else if (reviewTimelineData.videoReviewStatus === ContentStatus.REVISIONS_REQUESTED) {
    videoReviewDescription = `Revision Requested on ${toShortDateString(
      reviewTimelineData.videoReviewDate,
    )}`;
    videoStepColor = "yellow";
    videoIcon = (
      <ThemeIcon radius="xl" color={videoStepColor}>
        {videoIcon}
      </ThemeIcon>
    );
  } else if (reviewTimelineData.videoReviewStatus === ContentStatus.PENDING_REVIEW) {
    videoReviewDescription = `Submitted on ${toShortDateString(
      reviewTimelineData.videoSubmissionDate,
    )}`;
    videoStepColor = "red";
    videoIcon = (
      <ThemeIcon radius="xl" color={videoStepColor}>
        {videoIcon}
      </ThemeIcon>
    );
  } else {
    videoReviewDescription = `Due by ${toShortDateString(reviewTimelineData.videoDueDate)}`;
    if (scriptCompleted || !reviewTimelineData.scriptDueDate) {
      videoStepColor = "yellow";
      videoIcon = (
        <ThemeIcon radius="xl" color={videoStepColor}>
          {videoIcon}
        </ThemeIcon>
      );
    }
  }

  let liveDescription = "";
  let liveStepColor = "";
  let liveIcon = <IconVideoPlus style={{ width: "70%", height: "70%" }} />;
  let liveCompleted = false;
  if (reviewTimelineData.deliverableStatus === ContractDeliverableStatus.LIVE_CONTENT_SUBMITTED) {
    liveDescription = `Submitted on ${toShortDateString(
      reviewTimelineData.liveContentSubmissionDate,
    )}`;
    liveStepColor = "red";
    liveIcon = (
      <ThemeIcon radius="xl" color={liveStepColor}>
        {liveIcon}
      </ThemeIcon>
    );
  } else if (
    reviewTimelineData.deliverableStatus ===
    ContractDeliverableStatus.LIVE_CONTENT_REVISIONS_REQUESTED
  ) {
    liveDescription = `Revision Requested on ${toShortDateString(
      reviewTimelineData.videoReviewDate,
    )}`;
    liveStepColor = "yellow";
    liveIcon = (
      <ThemeIcon radius="xl" color={liveStepColor}>
        {liveIcon}
      </ThemeIcon>
    );
  } else if (
    ContractDeliverableStatusMapping[reviewTimelineData.deliverableStatus] >=
    ContractDeliverableStatusMapping[ContractDeliverableStatus.LIVE_CONTENT_APPROVED]
  ) {
    if (reviewTimelineData.liveContentSubmissionDate) {
      liveDescription = `Published on ${toShortDateString(
        reviewTimelineData.liveContentSubmissionDate,
      )}!`;
    } else {
      liveDescription = `Published!`;
    }
    liveStepColor = "teal";
    liveCompleted = true;
  } else {
    liveDescription = `By ${toShortDateString(reviewTimelineData.liveDate)}`;
    if (reviewTimelineData.videoReviewStatus === ContentStatus.APPROVED) {
      liveStepColor = "yellow";
      liveIcon = (
        <ThemeIcon radius="xl" color={liveStepColor}>
          {liveIcon}
        </ThemeIcon>
      );
    }
  }

  let activeIndex = 1;
  if (scriptCompleted) {
    activeIndex += 1;
  }
  if (videoCompleted) {
    activeIndex += 1;
  }
  if (liveCompleted) {
    activeIndex += 1;
  }

  return (
    <Stepper color="teal" size="xs" active={activeIndex}>
      <Stepper.Step
        icon={
          <ThemeIcon radius="xl" color="teal">
            <IconSignature style={{ width: "70%", height: "70%" }} />
          </ThemeIcon>
        }
        label="Sign Contract"
        description={`Signed on ${toShortDateString(reviewTimelineData.signatureDatetime)}`}
      />
      {reviewTimelineData.scriptDueDate && (
        <Stepper.Step
          icon={scriptIcon}
          label="Concept Review"
          color={scriptStepColor}
          description={scriptReviewDescription}
        />
      )}
      {reviewTimelineData.videoDueDate && <Stepper.Step
        icon={videoIcon}
        label="Video Review"
        color={videoStepColor}
        description={videoReviewDescription}
      />}
      <Stepper.Step
        icon={liveIcon}
        label="Content Live"
        color={liveStepColor}
        description={liveDescription}
      />
    </Stepper>
  );
}
