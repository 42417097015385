import React, { useEffect, useState } from "react";
import { Text, Flex, TextArea } from "gestalt";
import "gestalt/dist/gestalt.css";
import { useAuthState } from "react-firebase-hooks/auth";

import VerticalsCombobox from "utils/VerticalViews";
import { auth } from "auth/firebaseAuthHelpers";
import { useCreator } from "components/creator/CreatorContext";

// Module for creators to change their profile information.
const About = ({
  onFill, // function that sets a state to true when all fields are filled
  onChange, // function that sets a state to true when any fields are changed
}: {
  onFill?: () => void;
  onChange?: () => void;
}) => {
  const [creator, updateCreator] = useCreator();

  // save value creator has input in already
  const aboutInitialState = creator && creator.about ? creator.about : "";
  const [about, setAbout] = useState(aboutInitialState);

  // save value creator has input in already
  const selectedInitialState =
    creator && creator.selected_verticals ? creator.selected_verticals : [];
  const [selected, setSelected] = useState(selectedInitialState);

  const [user, loading] = useAuthState(auth); // TODO set this user earlier?

  useEffect(() => {
    if (!loading) {
      updateCreator({ about, selected_verticals: selected }, user);
      if (onFill && about && selected.length > 0) {
        onFill();
      }
    }
  }, [about, selected]);

  return (
    <Flex direction="column" gap={10}>
      <VerticalsCombobox selected={selected} setSelected={setSelected} onSelected={onChange} />
      {about ? (
        <TextArea
          id="aboutMeField"
          label="About Me"
          onChange={({ value }) => {
            if (onChange) onChange();
            setAbout(value);
          }}
          value={about}
        />
      ) : (
        <TextArea
          id="aboutMeField"
          label="About Me"
          onChange={({ value }) => {
            if (onChange) onChange();
            setAbout(value);
          }}
          placeholder="Write something about yourself..."
        />
      )}
    </Flex>
  );
};

export default About;
