import React from "react";

import { CreativeBriefStatus } from "models/Campaign";
import { CREATIVE_BRIEF_URL } from "campaigns/api/fetchCreativeBriefs";
import { BrandObjChangeConfirmation } from "campaigns/main/campaignPage/brand/BrandObjChangeConfirmation";

export const CreativeBriefChangeConfirmation = ({
  brandId,
  creativeBriefId,
  form,
  formValues,
  initialValues,
  onSubmit,
  close,
}: {
  brandId: number;
  creativeBriefId: number;
  form: any;
  formValues: { [key: string]: any };
  initialValues: { [key: string]: any };
  onSubmit: (
    brandId: number,
    endpoint: string,
    objId: number,
    updates: { [key: string]: any },
    updateFields: string[],
  ) => Promise<any>;
  close: () => void;
}) => {
  const formatValue = (key: string, value: any) => {
    if (key === "status") {
      return CreativeBriefStatus[value];
    }
    return value;
  };
  return (
    <BrandObjChangeConfirmation
      brandId={brandId}
      endpoint={CREATIVE_BRIEF_URL}
      objType="creative brief"
      objId={creativeBriefId}
      form={form}
      formValues={formValues}
      customFormatValue={formatValue}
      initialValues={initialValues}
      onSubmit={onSubmit}
      close={close}
    />
  );
};

export default CreativeBriefChangeConfirmation;
