import {
  Anchor,
  Button,
  Checkbox,
  Divider,
  List,
  Modal,
  Progress,
  Stack,
  Text,
  ThemeIcon,
  Timeline,
  Title,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconCheck } from "@tabler/icons-react";
import Spacer from "components/Spacer";
import { BrandStatus } from "models/Brand";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { selectAllCreativeBriefs } from "reduxStore/creativeBriefsSelectors";
import { useAppDispatch, useAppSelector } from "reduxStore/hooks";
import { acceptBrandTOS } from "reduxStore/meSlice";

const OnboardingItem = ({
  additionalContent,
  description,
  buttonText,
  hasCompletedItem = false,
  onButtonClick,
}: {
  additionalContent?: React.ReactNode;
  description: string;
  buttonText: string;
  hasCompletedItem?: boolean;
  onButtonClick: () => void;
}) => (
  <>
    <Text size="md" c="var(--mantine-color-gray-6)">
      {description}
    </Text>
    {additionalContent ? (
      <>
        <Spacer height={12} />
        {additionalContent}
        <Spacer height={12} />
      </>
    ) : null}
    <Button
      variant="filled"
      mt={12}
      mb={24}
      onClick={onButtonClick}
      color={hasCompletedItem ? "gray" : "blue"}>
      {buttonText}
    </Button>
  </>
);

const OnboardingTitle = ({ title }: { title: string }) => (
  <Text size="lg" fw={600}>
    {title}
  </Text>
);

enum CampaignOnboardingStep {
  SCHEDULE_CALL = 0,
  ACTIVATE_CREATORS = 1,
  CREATIVE_BRIEF = 2,
  COMPLETE_BRAND_INFO = 3,
  ACCEPT_BRAND_TERMS = 4,
  LAUNCH_CAMPAIGN = 5,
}

const OnboardingStepDoneIcon = () => (
  <ThemeIcon size={20} radius="xl">
    <IconCheck />
  </ThemeIcon>
);

const CampaignHomeOnboarding = ({
  brandHasLaunchedCampaign,
}: {
  brandHasLaunchedCampaign: boolean;
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user: currentUser, brand: currentBrand } = useAppSelector((state) => state.me);
  const numCreativeBriefs = useAppSelector((state) => selectAllCreativeBriefs(state).length);
  const numActivatedCreators = useAppSelector(
    (state) => state.creatorSets.allActivatedEntryItemsIdList.length,
  );
  const currentUserAlreadyAcceptedTOS = currentUser?.accepted_brand_tos || false;
  const hasCompletedCall = currentBrand?.status === BrandStatus.Active;
  const brandSettingsCompleted =
    currentBrand &&
    currentBrand.brand_name &&
    currentBrand.company_website &&
    currentBrand.company_blurb &&
    currentBrand.contact_email &&
    currentBrand.billing_email &&
    currentBrand.outreach_email_provider &&
    currentBrand.outreach_email_address &&
    currentBrand.outreach_email_password_set;
  const creativeBriefsCompleted = numCreativeBriefs > 0;
  const numRequiredActivatedCreators = currentBrand?.onboarding_creator_activation_target ?? 100;
  const hasCompletedCreators = numActivatedCreators >= numRequiredActivatedCreators;
  const percentageCompletedActivatedCreators = Math.min(
    100,
    (numActivatedCreators / numRequiredActivatedCreators) * 100,
  );
  const remainingPercentageActivatedCreators = Math.max(
    0,
    100 - percentageCompletedActivatedCreators,
  );

  const [isBrandTermsOpened, { open: openBrandTermsModal, close: closeBrandTermsModal }] =
    useDisclosure(false);

  const [currentStep, setCurrentStep] = useState<number>(0);
  const [acceptedTerms, setAcceptedTerms] = useState<boolean>(currentUserAlreadyAcceptedTOS);

  return (
    <>
      <Stack gap={0} style={{ overflowY: "scroll" }}>
        <Title>Onboarding</Title>
        <Spacer height={24} />
        <Divider />
        <Spacer height={24} />
        <Title order={2}>Welcome to 1stCollab!</Title>
        <Spacer height={12} />
        <Text size="lg">
          We can’t wait to help you get started! Please complete the following onboarding steps to
          get started with your campaign.{" "}
        </Text>
        <Spacer height={48} />
        <Timeline active={currentStep} bulletSize={20} lineWidth={4}>
          <Timeline.Item
            title={<OnboardingTitle title="Schedule and complete a call" />}
            bullet={hasCompletedCall ? <OnboardingStepDoneIcon /> : undefined}>
            <OnboardingItem
              description="Talk with our CEO, Leon, to ask questions, double check your campaign set up, and make sure your campaign is set up for success."
              buttonText={hasCompletedCall ? "Completed" : "Schedule a call"}
              onButtonClick={() => {
                window.open("https://calendar.app.google/2TZFYbotT2Zg9MGF8", "_blank");
                setCurrentStep(CampaignOnboardingStep.ACTIVATE_CREATORS);
              }}
              hasCompletedItem={hasCompletedCall}
            />
          </Timeline.Item>
          <Timeline.Item
            title={<OnboardingTitle title={`Activate ${numRequiredActivatedCreators} creators`} />}
            bullet={hasCompletedCreators ? <OnboardingStepDoneIcon /> : undefined}>
            <OnboardingItem
              description="It might seem like a lot, but that’s how we’ve seen brands get the best performance. Find more creators to help us get the best prices for your campaign."
              buttonText={hasCompletedCreators ? "Completed" : "Discover creators"}
              onButtonClick={() => {
                navigate("/campaigns/discover");
                setCurrentStep(CampaignOnboardingStep.CREATIVE_BRIEF);
              }}
              hasCompletedItem={hasCompletedCreators}
              additionalContent={
                <Progress.Root size="xl" w="33%">
                  <Progress.Section value={percentageCompletedActivatedCreators} color="cyan">
                    <Progress.Label>{numActivatedCreators} Activated</Progress.Label>
                  </Progress.Section>
                  <Progress.Section value={remainingPercentageActivatedCreators} color="gray">
                    <Progress.Label>
                      {numRequiredActivatedCreators - numActivatedCreators} Remaining
                    </Progress.Label>
                  </Progress.Section>
                </Progress.Root>
              }
            />
          </Timeline.Item>
          <Timeline.Item
            title={<OnboardingTitle title="Write a creative brief" />}
            bullet={creativeBriefsCompleted ? <OnboardingStepDoneIcon /> : undefined}>
            <OnboardingItem
              description="Let us know what type of content you want your creators to make."
              buttonText={creativeBriefsCompleted ? "See my briefs" : "Write a brief"}
              onButtonClick={() => {
                navigate("/campaigns/creative_briefs");
                setCurrentStep(CampaignOnboardingStep.COMPLETE_BRAND_INFO);
              }}
              hasCompletedItem={creativeBriefsCompleted}
            />
          </Timeline.Item>
          <Timeline.Item
            title={<OnboardingTitle title="Complete your brand information" />}
            bullet={brandSettingsCompleted ? <OnboardingStepDoneIcon /> : undefined}>
            <OnboardingItem
              description="Complete the account setup for your brand."
              buttonText={brandSettingsCompleted ? "Completed" : "Complete account setup"}
              onButtonClick={() => {
                navigate("/campaigns/settings");
                setCurrentStep(CampaignOnboardingStep.ACCEPT_BRAND_TERMS);
              }}
              hasCompletedItem={brandSettingsCompleted}
            />
          </Timeline.Item>
          <Timeline.Item
            title={<OnboardingTitle title="Agree to our Brand Terms" />}
            bullet={currentUserAlreadyAcceptedTOS ? <OnboardingStepDoneIcon /> : undefined}>
            <OnboardingItem
              description="Review and agree to the terms of running a campaign on 1stCollab."
              buttonText={currentUserAlreadyAcceptedTOS ? "Completed" : "Review and agree to terms"}
              onButtonClick={openBrandTermsModal}
              hasCompletedItem={currentUserAlreadyAcceptedTOS}
            />
          </Timeline.Item>
          <Timeline.Item
            title={<OnboardingTitle title="Launch your campaign" />}
            bullet={brandHasLaunchedCampaign ? <OnboardingStepDoneIcon /> : undefined}>
            <OnboardingItem
              description="Configure you campaign and launch! Please note, you’ll need to complete the other onboarding steps in order to fully configure your campaign."
              buttonText={brandHasLaunchedCampaign ? "Completed" : "Create a campaign"}
              onButtonClick={() => {
                navigate("/campaigns/overview");
              }}
              hasCompletedItem={brandHasLaunchedCampaign}
            />
          </Timeline.Item>
        </Timeline>
      </Stack>
      <Modal
        size="xl"
        opened={isBrandTermsOpened}
        onClose={closeBrandTermsModal}
        title="Brand terms for 1stCollab Campaigns"
        centered>
        <Stack gap={16}>
          <Text fw={500}>
            1stCollab partnerships require commitments from both brands and creators in order to be
            successful. In running a campaign on 1stCollab, you agree to the following terms and
            understand that failure to adhere to these terms could result in the termination of your
            campaign.
          </Text>
          <List size="sm">
            <List.Item
              style={{
                display:
                  "inline-block" /* using inline-block because inline-flex doesn't respect padding from parent and text gets cut off */,
              }}>
              <b>&bull;</b> I will provide the creator and/or 1stCollab with product or information
              in a timely manner. Examples include:
            </List.Item>
            <List withPadding size="sm">
              <List.Item>Shipping product to creators</List.Item>
              <List.Item>Providing creators with promo codes or referral links</List.Item>
              <List.Item>Providing feedback on content from creators</List.Item>
            </List>
            <List.Item
              style={{
                display:
                  "inline-block" /* using inline-block because inline-flex doesn't respect padding from parent and text gets cut off */,
              }}>
              <b>&bull;</b> I will not modify my creative brief without first notifying 1stCollab
              once the campaign is live.
            </List.Item>
            <List.Item
              style={{
                display:
                  "inline-block" /* using inline-block because inline-flex doesn't respect padding from parent and text gets cut off */,
              }}>
              <b>&bull;</b> I will adhere to the 1stCollab content feedback guidelines when
              providing content feedback for creators.
            </List.Item>
            <List.Item
              style={{
                display:
                  "inline-block" /* using inline-block because inline-flex doesn't respect padding from parent and text gets cut off */,
              }}>
              <b>&bull;</b> I will not contact any of the creators sourced on 1stCollab off the
              1stCollab platform without first notifying 1stCollab.
            </List.Item>
          </List>
          <Text fw={500}>
            We also want to be clear about expectations for running the campaign. As much as
            possible, we want to be upfront about our unique process. Please don’t hesitate to ask
            us any questions before agreeing to these terms.
          </Text>
          <List size="sm">
            <List.Item
              style={{
                display:
                  "inline-block" /* using inline-block because inline-flex doesn't respect padding from parent and text gets cut off */,
              }}>
              <b>&bull;</b> I understand that 1stCollab will negotiate and issue contracts on my
              behalf for any creator whom I activate into a campaign.
            </List.Item>
            <List.Item
              style={{
                display:
                  "inline-block" /* using inline-block because inline-flex doesn't respect padding from parent and text gets cut off */,
              }}>
              <b>&bull;</b> I understand that once a creator goes into contract, that the contract
              is binding.
            </List.Item>
            <List.Item
              style={{
                display:
                  "inline-block" /* using inline-block because inline-flex doesn't respect padding from parent and text gets cut off */,
              }}>
              <b>&bull;</b> I understand that 1stCollab may work with the same creator multiple
              times within the same campaign or offer creators multiple deliverables per contract.
            </List.Item>
            <List.Item
              style={{
                display:
                  "inline-block" /* using inline-block because inline-flex doesn't respect padding from parent and text gets cut off */,
              }}>
              <b>&bull;</b> I understand that there is a risk of creators not honoring their
              contract once I provide them with product.
            </List.Item>
            <List.Item
              style={{
                display:
                  "inline-block" /* using inline-block because inline-flex doesn't respect padding from parent and text gets cut off */,
              }}>
              <b>&bull;</b> I understand that I can pause my campaign at any time. However,
              1stCollab reserves the right to continue partnerships all the way to completion for
              creators already activated into the campaign.
            </List.Item>
          </List>
          <Text size="sm">
            For more details about the brand terms, refer to our{" "}
            <Anchor
              href="https://1stcollab.notion.site/Brand-terms-for-1stCollab-Campaigns-951ec1601df64ee18a1b0c1f0550eed6"
              target="_blank"
              underline="always"
              c="var(--mantine-color-black)">
              Brand Terms Article
            </Anchor>
            .
          </Text>
          <Checkbox
            checked={acceptedTerms}
            disabled={currentUserAlreadyAcceptedTOS}
            onChange={(event) => setAcceptedTerms(event.currentTarget.checked)}
            label="By checking this box, you acknowledge that you have read and agree to all the brand terms above."
          />
          <Button
            variant="filled"
            disabled={!acceptedTerms || currentUserAlreadyAcceptedTOS}
            onClick={() => {
              dispatch(acceptBrandTOS())
                .unwrap()
                .then(() => {
                  closeBrandTermsModal();
                  setCurrentStep(CampaignOnboardingStep.LAUNCH_CAMPAIGN);
                });
            }}>
            Submit
          </Button>
        </Stack>
      </Modal>
    </>
  );
};

export default CampaignHomeOnboarding;
