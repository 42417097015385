import React, { useState } from "react";
import { Tabs } from "gestalt";
import "gestalt/dist/gestalt.css";
import { useLocation } from "react-router-dom";
import { User } from "firebase/auth";

import LayoutBase from "components/LayoutBase";
import RelatedCreatorsBackend from "admin/RelatedCreatorsBackend";
import RelatedCreatorsFrontend from "admin/RelatedCreatorsFrontend";
import { getAdminUser } from "admin/AdminLayout";

const AdminRelatedCreators = () => {
  const user: User = getAdminUser();
  const location = useLocation();

  const initActiveTab = location.pathname.includes("backend") ? 0 : 1;
  const [activeIndex, setActiveIndex] = useState(initActiveTab);

  return (
    <LayoutBase>
      <Tabs
        activeTabIndex={activeIndex}
        onChange={({ activeTabIndex }) => {
          setActiveIndex(activeTabIndex);
        }}
        tabs={[
          { href: "#", text: "Backend Logs" },
          { href: "#", text: "Frontend Logs" },
        ]}
      />
      {activeIndex === 0 ? <RelatedCreatorsBackend /> : <RelatedCreatorsFrontend />}
    </LayoutBase>
  );
};

export default AdminRelatedCreators;
