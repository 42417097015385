import React from "react";
import { Box, Button, Flex, Text } from "gestalt";
import "gestalt/dist/gestalt.css";

import IMAGES from "pages/home/assets/discover_images";

import "pages/home/styles/light-styles.css";
import Spacer from "components/Spacer";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  ExampleSearches,
} from "components/discovery/related/RelatedSearchComponents";
import { ProductHighlight } from "pages/home/components/Highlights";
import PublicCreatorSetSearch from "components/discovery/search/PublicCreatorSetSearch";

const BrandGetStartedButton = () => {
  const navigate = useNavigate();
  return <Button text="Get started" color="blue" onClick={() => navigate("/campaigns")} />;
};

const HeroContent = ({ navigate }: { navigate: NavigateFunction }) => (
  <div id="hero">
    <Box width="min(100%, 900px)" alignSelf="center">
      <Text size="600" weight="bold" align="center">
        Influencer marketing is a ton of work.
      </Text>
      <Text size="600" weight="bold" align="center">
        We&apos;ll take care of it for you.
      </Text>
      <Spacer height={36} />
      <Text align="center" size="400">
        We use AI to help you run high-ROAS influencer campaigns at scale. To get started, search
        for an influencer who would be perfect for your campaign. We&apos;ll take it from there
      </Text>
      <Spacer height={36} />
      <Flex flex="grow" justifyContent="center" direction="column">
        <PublicCreatorSetSearch />
        <Spacer height={23} />
        <ExampleSearches />
      </Flex>
    </Box>
    <Spacer height={5} />
    <Box
      // borderStyle="sm"
      justifyContent="center"
      display="flex"
      // rounding={4}
      width={900}
      alignSelf="center">
      <img src={IMAGES.hero} alt="Related Results" id="relatedIage" width="881" height="613" />
    </Box>
  </div>
);

const ProductFeature = () => (
  <Flex direction="row" justifyContent="center">
    <Box direction="column" maxWidth={948} padding={0}>
      <ProductHighlight
        key="highlightScroll"
        title="Get your campaign launched in minutes."
        description="Influencer marketing is a ton of work. Whether this is your first campaign or your thousandth, we’ll help you get it done in less than an hour."
        imageSrc={IMAGES.campaign}
        upsellButton={<BrandGetStartedButton />}
        left
      />
      <Spacer height={48} />
      <ProductHighlight
        key="highlightBudget"
        title="Get better performance on your campaigns."
        description="We use AI and tons of data to understand which influencers will perform best for your campaign."
        imageSrc={IMAGES.campaignPerformance}
        upsellButton={<BrandGetStartedButton />}
      />
      <Spacer height={48} />
      <ProductHighlight
        key="highlightNiche"
        title="Focus on what makes your program unique."
        description="You and your team have better things to do than hunt down emails and redline contracts. We’ll do the gruntwork, so you can focus on what’s important."
        imageSrc={IMAGES.boringStuff}
        upsellButton={<BrandGetStartedButton />}
        left
      />
      <Spacer height={48} />
    </Box>
  </Flex>
);

const DiscoverLandingPage = () => {
  const title = "1stCollab - Launch your influencer campaign in minutes";
  const description = "Launch your influencer campaign in minutes on 1stCollab.";

  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="title" property="og:title" content={title} />
        <meta name="description" property="og:description" content={description} />
      </Helmet>

      <HeroContent navigate={navigate} />
      <Spacer height={32} />
      <ProductFeature />
    </>
  );
};

export default DiscoverLandingPage;
