import React from "react";

import { Table } from "@mantine/core";

import { OpsTask } from "models/OpsTask";
import { User } from "models/User";
import OpsUserTableRow from "admin/app/ops/OpsUserTableRow";

export const OpsUserTable = ({ opsUsers, opsTasks }: { opsUsers: User[]; opsTasks: OpsTask[] }) => {
  // sort ops users by name
  opsUsers.sort((a, b) => a.name.localeCompare(b.name));
  return (
    <Table horizontalSpacing="md" verticalSpacing="md" highlightOnHover withTableBorder>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Name</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {opsUsers.map((user) => (
          <OpsUserTableRow opsUser={user} key={user.key} opsTasks={opsTasks} />
        ))}
      </Table.Tbody>
    </Table>
  );
};

export default OpsUserTable;
