import React from "react";

import { Campaign, BrandToDoSummary } from "models/Campaign";
import AdGroupToDos from "campaigns/main/campaignPage/AdGroupToDos";
import HomePageTodos from "campaigns/main/campaignPage/HomePageTodos";
import CampaignToDos from "campaigns/main/campaignPage/CampaignToDos";
import { useCampaignAppContext } from "campaigns/CampaignAppShell";
import TodoBox from "campaigns/main/campaignPage/TodoBox";

const hasAnyTodos = (toDoSummary: BrandToDoSummary) =>
  (toDoSummary.creatorset_total != null && toDoSummary.creatorset_total > 0) ||
  (toDoSummary.campaign_total != null && toDoSummary.campaign_total > 0);

const hasCampaignTodos = (toDoSummary: BrandToDoSummary) =>
  toDoSummary.campaign_total != null && toDoSummary.campaign_total > 0;

const CampaignTodoList = ({
  campaign = null,
  showCreatorSets = false,
}: {
  campaign?: Campaign;
  showCreatorSets?: boolean;
}) => {
  const { toDoSummary, selectedAdGroup } = useCampaignAppContext();
  let isVisible = false;

  if (!toDoSummary) {
    return null;
  }

  if (
    campaign != null &&
    selectedAdGroup != null &&
    toDoSummary.campaigns[campaign.hash_id]?.ad_groups[selectedAdGroup.id].total > 0
  ) {
    isVisible = true;
  } else if (
    campaign != null &&
    selectedAdGroup == null &&
    toDoSummary.campaigns[campaign.hash_id]?.total > 0
  ) {
    isVisible = true;
  } else if (campaign == null && hasAnyTodos(toDoSummary)) {
    isVisible = true;
  }

  // If it's a campaign page and we dont have ANY campaign todos - don't show todos
  // If it's the homepage and we dont have ANY todos - don't show todos
  if (!showCreatorSets && !hasCampaignTodos(toDoSummary)) {
    return null;
  } else if (campaign == null && !hasAnyTodos(toDoSummary)) {
    return null;
  }

  const todoComponent = (
    <>
      {/* We have 3 types of creator todos: 1) adgroup level, 2) campaign level, 3) brand level */}
      {campaign != null &&
      selectedAdGroup != null &&
      toDoSummary.campaigns[campaign.hash_id]?.ad_groups[selectedAdGroup.id] ? (
        <AdGroupToDos
          toDoSummary={toDoSummary.campaigns[campaign.hash_id].ad_groups[selectedAdGroup.id]}
          campaignId={campaign.hash_id}
          adGroupId={selectedAdGroup.id}
        />
      ) : null}
      {campaign != null && selectedAdGroup == null && toDoSummary.campaigns[campaign.hash_id] ? (
        <CampaignToDos
          toDoSummary={toDoSummary.campaigns[campaign.hash_id]}
          campaignId={campaign.hash_id}
        />
      ) : null}
      {campaign == null ? <HomePageTodos toDoSummary={toDoSummary} useCreatorSets /> : null}
    </>
  );

  return <TodoBox isVisible={isVisible} todoComponent={todoComponent} />;
};
export default CampaignTodoList;
