import React, { useEffect, useState } from "react";

import { Anchor, Group, Table, TextInput, Switch, Text } from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";

import { IconCircleCheck } from "@tabler/icons-react";

import { BrandHashtag } from "models/BrandHashtag";
import { updateBrandHashtag } from "admin/api/labeling/brandHashtagApi";

export const BrandHashtagLabelingTableRow = ({
  brandHashtag,
  includeHidden,
  onChange,
}: {
  brandHashtag: BrandHashtag;
  includeHidden: boolean;
  onChange: (hashtagId: number, hashtag: BrandHashtag) => void;
}) => {
  const [isChecked, setIsChecked] = useState(brandHashtag.is_sponsored);
  const [brandName, setBrandName] = useState(brandHashtag.brand_name || "");
  const [isHidden, setIsHidden] = useState(brandHashtag.is_hidden);
  const [savedChecked, setSavedChecked] = useState(brandHashtag.is_sponsored);
  const [savedBrandName, setSavedBrandName] = useState(brandHashtag.brand_name || "");
  const [savedIsHidden, setSavedIsHidden] = useState(brandHashtag.is_hidden);
  const [debouncedChecked] = useDebouncedValue(isChecked, 1000);
  const [debouncedBrandName] = useDebouncedValue(brandName, 1000);
  const [debouncedIsHidden] = useDebouncedValue(isHidden, 1000);

  function isSaved() {
    return savedChecked === isChecked && savedBrandName === brandName && savedIsHidden === isHidden;
  }

  useEffect(() => {
    if (isSaved()) {
      return;
    }
    updateBrandHashtag({
      ...brandHashtag,
      is_sponsored: debouncedChecked,
      brand_name: debouncedBrandName,
      is_hidden: debouncedIsHidden,
    }).then((resp) => {
      if (resp?.id) {
        setSavedChecked(resp.is_sponsored);
        setSavedBrandName(resp.brand_name);
        setSavedIsHidden(resp.is_hidden);
      }
    });
  }, [debouncedChecked, debouncedBrandName, debouncedIsHidden]);

  return (
    <Table.Tr>
      <Table.Td>
        <Group gap={4}>
          <Anchor
            href={`/admin/debug/feed/hashtag?hashtag=${brandHashtag.hashtag}`}
            target="_blank">
            {brandHashtag.hashtag}
          </Anchor>
          <IconCircleCheck size="1.5rem" color={isSaved() ? "green" : "gray"} />
        </Group>
      </Table.Td>
      <Table.Td>{brandHashtag.count}</Table.Td>
      <Table.Td>
        <TextInput
          value={brandHashtag.brand_name}
          onChange={(event) => {
            setBrandName(event.currentTarget.value);
            onChange(brandHashtag.id, {
              ...brandHashtag,
              brand_name: event.currentTarget.value,
            });
          }}
        />
      </Table.Td>
      <Table.Td>
        <Switch
          checked={isChecked}
          onChange={(event) => {
            setIsChecked(event.currentTarget.checked);
            onChange(brandHashtag.id, {
              ...brandHashtag,
              is_sponsored: event.currentTarget.checked,
            });
          }}
        />
      </Table.Td>
      <Table.Td>
        <Switch
          checked={isHidden}
          onChange={(event) => {
            setIsHidden(event.currentTarget.checked);
            onChange(brandHashtag.id, {
              ...brandHashtag,
              is_hidden: event.currentTarget.checked,
            });
          }}
        />
      </Table.Td>
    </Table.Tr>
  );
};

export default BrandHashtagLabelingTableRow;
