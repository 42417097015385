import { Button, Divider, Group, Stack, TextInput, Title } from "@mantine/core";
import { isNotEmpty, useForm } from "@mantine/form";
import { IconPlus } from "@tabler/icons-react";
import Spacer from "components/Spacer";
import { CreativeBrief } from "models/CreativeBrief";
import React, { useEffect } from "react";
import { addCreativeBrief, updateCreativeBrief } from "reduxStore/creativeBriefsSlice";
import { useAppDispatch, useAppSelector } from "reduxStore/hooks";

const CreativeBriefDrawer = ({
  brief,
  onBriefCreated,
  onBriefUpdated,
  onCancelClicked,
}: {
  brief?: CreativeBrief;
  onBriefCreated: (brief: CreativeBrief) => void;
  onBriefUpdated: (brief: CreativeBrief) => void;
  onCancelClicked: () => void;
}) => {
  const brandId = useAppSelector((state) => state.me.brandId);
  const dispatch = useAppDispatch();
  const form = useForm({
    mode: "uncontrolled",
    initialValues: {
      name: "",
      publicLink: "",
      editLink: "",
    },
    validate: {
      name: isNotEmpty("Please enter a name"),
      publicLink: (value) => {
        if (!value?.trim()) {
          return "Please enter a link to the published brief";
        }
        try {
          const url = new URL(value?.trim());
        } catch {
          return "Please enter a valid URL";
        }
        return null;
      },
    },
  });

  useEffect(() => {
    if (brief) {
      form.setValues({
        name: brief.name ?? "",
        publicLink: brief.external_brief_url ?? "",
        editLink: brief.internal_brief_url ?? "",
      });
      form.resetDirty();
    }
  }, [brief]);

  return (
    <>
      <Title order={3}>{brief ? `Edit ${brief.name}` : "New Creative Brief"}</Title>
      <Spacer height={12} />
      <Divider />
      <Spacer height={12} />
      <form
        onSubmit={form.onSubmit((values) => {
          if (brief) {
            dispatch(
              updateCreativeBrief({
                briefId: brief.id,
                updateParams: {
                  ...(form.isDirty("name") && { name: values.name }),
                  ...(form.isDirty("publicLink") && {
                    external_brief_url: values.publicLink.trim(),
                  }),
                  ...(form.isDirty("editLink") && { internal_brief_url: values.editLink.trim() }),
                },
              }),
            )
              .unwrap()
              .then((updatedBrief) => {
                onBriefUpdated(updatedBrief);
              });
          } else {
            dispatch(
              addCreativeBrief({
                brandId,
                creationParams: {
                  name: values.name,
                  external_brief_url: values.publicLink.trim(),
                  internal_brief_url: values.editLink.trim(),
                },
                abortController: new AbortController(),
              }),
            )
              .unwrap()
              .then((newBrief) => {
                onBriefCreated(newBrief);
              });
          }
        })}>
        <Stack gap={24}>
          <TextInput
            withAsterisk
            label="Name"
            description="Give this creative brief a descriptive name so you can find it easily within 1stCollab. Not visible to creators."
            placeholder="Name"
            key={form.key("name")}
            {...form.getInputProps("name")}
          />
          <TextInput
            withAsterisk
            label="Public Link"
            description="Paste the link to the published creative brief below. This is what creators receive."
            placeholder="https://yourcompany.notion.site/Your-Creative-Brief-Name-123"
            key={form.key("publicLink")}
            {...form.getInputProps("publicLink")}
          />
          <TextInput
            label="Edit Link"
            description="Paste the link to the editable version below. This is only for your future reference, if you need to make edits. Not visible to creators."
            placeholder="https://www.notion.so/yourcompany/Your-Creative-Brief-Name-123"
            key={form.key("editLink")}
            {...form.getInputProps("editLink")}
          />
          <Group style={{ justifyContent: "space-between" }}>
            <Button variant="outline" color="gray" onClick={onCancelClicked}>
              Cancel
            </Button>
            <Button type="submit" leftSection={brief ? null : <IconPlus size={16} />}>
              {brief ? "Save" : "Add Brief"}
            </Button>
          </Group>
        </Stack>
      </form>
    </>
  );
};

export default CreativeBriefDrawer;
