import React from "react";
import { Link } from "react-router-dom";
import { Accordion, Badge, Box, Divider, Group, Space, Stack, Text, Tooltip } from "@mantine/core";
import {
  IconBrandInstagram,
  IconBrandTiktok,
  IconBrandYoutubeFilled,
  IconExclamationCircle,
  IconHelpCircle,
} from "@tabler/icons-react";

import { PriceMap } from "creators/api/creatorApiUtils";
import DeliverablePackage from "models/DeliverablePackage";
import { SupportedFormat } from "models/Common";
import { getAbbreviatedNumber } from "utils/AnalyticsUtils";

const DOCUMENTATION_URL =
  "https://docs.google.com/document/d/11y2fc-rCYLGu7G6dAEm6zSbUSmz9DqVsC5k-e0FB9eA/";

const PLATFORM_COLORS: Record<string, string> = {
  youtube: "var(--mantine-color-red-6",
  tiktok: "var(--mantine-color-cyan-4",
  instagram: "var(--mantine-color-grape-6",
};

const SUPPORTED_FORMAT_TO_ICONS: Record<SupportedFormat, React.ReactNode> = {
  [SupportedFormat.TIKTOK_DEDICATED_VIDEO]: (
    <IconBrandTiktok size={15} color={PLATFORM_COLORS.tiktok} />
  ),
  [SupportedFormat.YOUTUBE_DEDICATED_VIDEO]: (
    <IconBrandYoutubeFilled size={15} color={PLATFORM_COLORS.youtube} />
  ),
  [SupportedFormat.YOUTUBE_30S_INTEGRATED_VIDEO]: (
    <IconBrandYoutubeFilled size={15} color={PLATFORM_COLORS.youtube} />
  ),
  [SupportedFormat.YOUTUBE_60S_INTEGRATED_VIDEO]: (
    <IconBrandYoutubeFilled size={15} color={PLATFORM_COLORS.youtube} />
  ),
  [SupportedFormat.YOUTUBE_90S_INTEGRATED_VIDEO]: (
    <IconBrandYoutubeFilled size={15} color={PLATFORM_COLORS.youtube} />
  ),
  [SupportedFormat.YOUTUBE_SHORT]: (
    <IconBrandYoutubeFilled size={15} color={PLATFORM_COLORS.youtube} />
  ),
  [SupportedFormat.INSTAGRAM_DEDICATED_REEL]: (
    <IconBrandInstagram size={15} color={PLATFORM_COLORS.instagram} />
  ),
};

export const FormulaAccordionItem = ({
  platform,
  platformIcon,
  formatPrice,
  formulaStr,
}: {
  platform: string;
  platformIcon: React.ReactNode;
  formatPrice: number;
  formulaStr: string;
}) => {
  return (
    <Accordion.Item key={platform} value={platform}>
      <Accordion.Control>
        <Group key={platform}>
          {platformIcon}
          <Text size="sm" c="gray.8">
            {platform}
          </Text>
          <Text size="sm">${Number(formatPrice).toLocaleString()}</Text>
        </Group>
      </Accordion.Control>
      <Accordion.Panel>
        <Text size="sm">{formulaStr}</Text>
      </Accordion.Panel>
    </Accordion.Item>
  );
};

const SuggestedPrice = ({
  cpm,
  upperPrice,
  lowerPrice,
  platformList,
  selectedDeliverable,
  totalViewsRange,
  platformPricing,
  isFixedPrice,
  hasDiscount,
  discountFactor,
  showUsageRights = true,
  hidden,
}: {
  cpm: number;
  upperPrice: number;
  platformList: Array<string>;
  selectedDeliverable?: DeliverablePackage;
  totalViewsRange?: Record<string, number[]>;
  platformPricing?: PriceMap;
  lowerPrice?: number;
  isFixedPrice?: boolean;
  hasDiscount?: boolean;
  discountFactor?: number;
  showUsageRights?: boolean;
  hidden?: boolean;
}) => {
  if (hidden) return null;
  let individualPlatformPrices: JSX.Element[] = [];
  const sumTotalUpper = totalViewsRange?.total_upper.reduce((a, b) => a + b, 0);

  // have to handle logic for pricing calculator vs negotiations flow
  if (platformPricing) {
    individualPlatformPrices = Object.entries(platformPricing)
      .filter(
        ([platform, stats]) =>
          (stats.lower_price && stats.upper_price) >= 0 && platformList.includes(platform),
      )
      .map(([platform, stats]) => {
        const platformIcon = SUPPORTED_FORMAT_TO_ICONS[platform as SupportedFormat];
        const convertLowerPrice = stats.lower_price / 100;
        const convertUpperPrice = stats.upper_price / 100;
        const platformKey = platform as keyof PriceMap;
        const calculateTruePrice =
          (platformPricing[platformKey].upper_views *
            platformPricing[platformKey].format_weight *
            cpm) /
          1000;

        const baseStr = `(${getAbbreviatedNumber(
          platformPricing[platformKey].upper_views,
        )} views * ${platformPricing[platformKey].views_adjustment} views adjustment)
  * (${platformPricing[platformKey].format_weight} format weight * $${cpm} CPM)`;

        const formulaStr = hasDiscount
          ? `${baseStr} * ${discountFactor} country discount`
          : baseStr;

        return (
          <FormulaAccordionItem
            key={platform}
            platform={platform}
            platformIcon={platformIcon}
            formatPrice={convertUpperPrice}
            formulaStr={formulaStr}
          />
        );
      });
  } else if (selectedDeliverable) {
    individualPlatformPrices = Object.entries(selectedDeliverable)
      .filter(([format, value]) => {
        const supportedFormatList = new Set(
          Object.values(SupportedFormat).map((debugFormat) => `${debugFormat}_price_debug`),
        );
        return value && supportedFormatList.has(format);
      })
      .map(([field, formula]) => {
        const platform = field.replace("_price_debug", "");
        const [individualFormatPrice, formulaStr, ...rest] = formula.split("\n");
        const platformIcon = SUPPORTED_FORMAT_TO_ICONS[platform as SupportedFormat];

        return (
          <FormulaAccordionItem
            key={platform}
            platform={platform}
            platformIcon={platformIcon}
            formatPrice={Number(individualFormatPrice)}
            formulaStr={formulaStr}
          />
        );
      });
  }

  const deliverableList = platformList.map((platform) => {
    let platformIcon = null;
    let variantColor = "";
    switch (platform) {
      case "tiktok_dedicated_video":
        platformIcon = <IconBrandTiktok size={15} />;
        variantColor = "cyan.5";
        break;
      case platform.match(/^youtube/)?.input:
        platformIcon = <IconBrandYoutubeFilled size={15} />;
        variantColor = "red.6";
        break;
      case "youtube_short":
        platformIcon = <IconBrandYoutubeFilled size={15} />;
        variantColor = "red.6";
        break;
      case "instagram_dedicated_reel":
        platformIcon = <IconBrandInstagram size={15} />;
        variantColor = "grape.6";
        break;
      default:
        platformIcon = null;
        variantColor = "light";
    }
    return (
      <Badge p={6} key={platform} size="xs" variant="light" color={variantColor}>
        <Group gap="xs">
          {platformIcon}
          {platform}
        </Group>
      </Badge>
    );
  });

  return (
    <Stack>
      <Group align="flex-start">
        <Stack align="flex-start">
          <Text size="sm" fw={600}>
            Selected Deliverables
          </Text>
          <Group wrap="wrap">{deliverableList.map((deliverable) => deliverable)}</Group>
        </Stack>
      </Group>
      <Divider my="sm" />
      <Box>
        <Stack>
          <Group align="flex-start">
            <Group>
              <Text size="sm" fw={600}>
                Suggested Price
              </Text>
              <Group gap="xs" align="center">
                <Text size="sm">${upperPrice.toLocaleString()}</Text>
                <Tooltip
                  position="right"
                  label="Pricing = (Predicted Views * Views Adjustment Weight)  * (CPM * Format CPM Weight)">
                  <IconHelpCircle size={18} color="var(--mantine-color-blue-6" />
                </Tooltip>
              </Group>
              {isFixedPrice && (
                <>
                  <Space h="xs" />
                  <Group gap={4}>
                    <IconExclamationCircle size={14} color="var(--mantine-color-red-6" />
                    <Text c="red.6" fw={500} size="xs">
                      Suggested price fixed to $100.
                    </Text>
                  </Group>
                  <Space h="xs" />
                </>
              )}
            </Group>
            <Stack gap={1} align="flex-start">
              <Text c="dimmed" size="xs">
                {`*Calculated based on an adjusted CPM from an original CPM of $${cpm}`}{" "}
                {totalViewsRange &&
                  `and cross-platform predicted views of ${getAbbreviatedNumber(sumTotalUpper)}.`}
              </Text>
              <Text c="dimmed" size="xs">
                *See{" "}
                <Link to={DOCUMENTATION_URL} target="_blank">
                  documentation
                </Link>{" "}
                for full list of format weights
              </Text>
            </Stack>
          </Group>
          <Divider />
          {showUsageRights && (
            <>
              <Group align="flex-start">
                <Text size="sm" fw={600}>
                  Usage Rights
                </Text>
                <Text size="sm">
                  +${Math.round(upperPrice * 0.1).toLocaleString()} maximum for usage
                </Text>
                <Space w="lg" />
                <Stack gap={1} align="flex-start">
                  <Text c="dimmed" size="xs">
                    *Based on brand policy of max additional usage for 10%
                  </Text>
                </Stack>
              </Group>
              <Divider />
            </>
          )}
          {(platformPricing || selectedDeliverable) && (
            <Box>
              <Text size="sm" fw={600}>
                Prices Split Out By Format
              </Text>
              <Space h={5} />
              <Accordion
                styles={{
                  control: {
                    borderRadius: 0,
                  },
                }}>
                {individualPlatformPrices.length > 0 ? (
                  individualPlatformPrices.map((platform) => platform)
                ) : (
                  <Text size="xs" c="dimmed">
                    No debug data available for packages made before 05/17/2024 UTC
                  </Text>
                )}
              </Accordion>
            </Box>
          )}
        </Stack>
      </Box>
    </Stack>
  );
};

export default SuggestedPrice;
