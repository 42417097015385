import React, { useState } from "react";

import { Button, Card, Group, Checkbox, Stack, Text } from "@mantine/core";

import { IconCheck } from "@tabler/icons-react";

import { simpleHash } from "components/contracts/common/Common";

export default function PreSubmissionChecklist({
  header,
  checklistItems,
  children,
  disabled,
}: {
  header: React.ReactNode;
  checklistItems: React.ReactNode[];
  children: React.ReactElement;
  disabled?: boolean;
}) {
  const [checkboxConfirmed, setCheckboxConfirmed] = useState(false);
  const [checkboxStates, setCheckboxStates] = useState<boolean[]>(
    new Array(checklistItems.length).fill(false),
  );

  if (!checkboxConfirmed && !disabled) {
    const checkboxes = checklistItems.map((item, index) => (
      <Checkbox
        key={`checkbox-${simpleHash(item.toString())}`}
        label={<Text td={checkboxStates[index] ? "line-through" : ""}>{item}</Text>}
        checked={checkboxStates[index]}
        onChange={() =>
          setCheckboxStates((prev) => {
            const newState = [...prev];
            newState[index] = !newState[index];
            return newState;
          })
        }
      />
    ));

    return (
      <Card withBorder radius="sm" py="sm" px="md">
        <Stack gap="xs">
          {header}
          {checkboxes}
          <Group justify="right">
            <Button
              variant="filled"
              disabled={checkboxStates.some((checked) => !checked)}
              color="teal"
              leftSection={<IconCheck size="1rem" />}
              onClick={() => setCheckboxConfirmed(true)}>
              Confirm
            </Button>
          </Group>
        </Stack>
      </Card>
    );
  }

  return children;
}
