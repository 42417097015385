import React from "react";
import { User } from "models/User";
import { User as FirebaseUser } from "firebase/auth";
import { Box } from "gestalt";
import "gestalt/dist/gestalt.css";
import Spacer from "components/Spacer";
import ProfileAvatar from "pages/profile/ProfileAvatar";
import ProfileBanner from "pages/profile/ProfileBanner";

const EditAvatarBanner = ({ firebaseUser, user }: { firebaseUser: FirebaseUser; user: User }) => (
  <Box position="relative">
    <Box width={800} direction="column">
      <ProfileBanner requestUser={firebaseUser} src={user.banner_url} isSelf />
    </Box>

    <Spacer height={60} />
    <Box
      display="flex"
      position="absolute"
      bottom
      width="98%"
      dangerouslySetInlineStyle={{ __style: { left: "1%", zIndex: 2 } }}>
      <ProfileAvatar
        requestUser={firebaseUser}
        src={user.avatar_url}
        displayName={user.name}
        isSelf
      />
    </Box>
  </Box>
);

export default EditAvatarBanner;
