import React, { useContext, useEffect } from "react";
import { NativeSelect, Stack, Text, TextInput, Textarea } from "@mantine/core";
import Spacer from "components/Spacer";
import { OnboardingV2Context } from "onboarding/v2/OnboardingV2Context";
import { MembershipTypes } from "models/User";

export enum SignupReason {
  CREATORS_WORK,
  CREATORS_ROSTER,
  RUN_NEW_CAMPAIGN_ON_1ST,
  MANAGE_EXISTING_CAMPAIGN,
  MANAGE_EXISTING_CAMPAIGN_ON_1ST,
  ASKED_BY_SOMEONE_I_REPRESENT,
  OTHER,
}

export const SIGNUP_REASON_TO_LABEL_MAP: Record<SignupReason, string> = {
  [SignupReason.CREATORS_WORK]: "Find creators to work with",
  [SignupReason.CREATORS_ROSTER]: "Find creators to add to my roster",
  [SignupReason.RUN_NEW_CAMPAIGN_ON_1ST]: "Run a new campaign on 1stCollab",
  [SignupReason.MANAGE_EXISTING_CAMPAIGN]: "Manage an existing campaign",
  [SignupReason.MANAGE_EXISTING_CAMPAIGN_ON_1ST]: "For an existing campaign on 1stCollab",
  [SignupReason.ASKED_BY_SOMEONE_I_REPRESENT]: "I was asked to by someone I represent",
  [SignupReason.OTHER]: "Other",
};

const DEFAULT_REASONS: SignupReason[] = [
  SignupReason.CREATORS_WORK,
  SignupReason.RUN_NEW_CAMPAIGN_ON_1ST,
  SignupReason.MANAGE_EXISTING_CAMPAIGN,
  SignupReason.OTHER,
];
const CREATOR_MANAGER_TO_REASONS: SignupReason[] = [
  SignupReason.CREATORS_ROSTER,
  SignupReason.ASKED_BY_SOMEONE_I_REPRESENT,
  SignupReason.MANAGE_EXISTING_CAMPAIGN_ON_1ST,
  SignupReason.OTHER,
];

const getReasons = (membershipType: MembershipTypes): SignupReason[] => {
  if (membershipType === MembershipTypes.CREATOR_MANAGER) {
    return CREATOR_MANAGER_TO_REASONS;
  }
  return DEFAULT_REASONS;
};

const OnboardingBrandProfileStep = () => {
  const {
    selectedMembershipType,
    companyName,
    setCompanyName,
    selectedReason,
    setSelectedReason,
    other,
    setOther,
  } = useContext(OnboardingV2Context);

  const reasons = getReasons(selectedMembershipType).map((reason) => {
    return {
      label: SIGNUP_REASON_TO_LABEL_MAP[reason],
      value: reason.toString(),
    };
  });

  useEffect(() => {
    setSelectedReason(getReasons(selectedMembershipType)[0].toString());
  }, [selectedMembershipType]);

  return (
    <>
      <Text fw={500} size="lg" style={{ textAlign: "center", maxWidth: 450 }}>
        Complete your brand profile to start running influencer marketing on 1stCollab
      </Text>
      <Spacer height={48} />
      <Stack maw="420px" w="100%" gap={0}>
        <TextInput
          w="100%"
          label="What is the name of your company?"
          placeholder="Company Name"
          value={companyName}
          onChange={(event) => setCompanyName(event.currentTarget.value)}
        />
        <Spacer height={32} />
        <NativeSelect
          value={selectedReason}
          label="What's the primary reason you're signing up for 1stCollab"
          data={reasons}
          onChange={(event) => setSelectedReason(event.currentTarget.value)}
        />
        {selectedReason === SignupReason.OTHER.toString() ? (
          <>
            <Spacer height={32} />
            <Textarea
              label="How can we help?"
              placeholder="Please describe how you are looking to use the platform in a few sentences..."
              autosize
              minRows={3}
              value={other}
              onChange={(event) => setOther(event.currentTarget.value)}
            />
          </>
        ) : null}
      </Stack>
    </>
  );
};

export default OnboardingBrandProfileStep;
