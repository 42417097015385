import { User as FirebaseUser } from "firebase/auth";
import {
  CreatorInfoFromLinkResponse,
  CreatorInfoFromLink,
  CreatorProfileInfoFromLinkResponse,
} from "models/Creator";
import { createRequestWithFirebaseToken, handleResult } from "utils/ApiUtils";
import { API_URL } from "configs/Configs";

const CAMPAIGN_TODOS_URL = `${API_URL}/api/campaigns/import_link/`;
const CREATOR_LINK_FETCH_URL = `${API_URL}/api/creator/get_creator_profile_info`;

export const fetchCreatorInfoFromLink = async (
  linkUrl: string,
  campaignId?: number,
  creatorSetId?: number,
): Promise<CreatorInfoFromLink> => {
  const requestUrl = new URL(CAMPAIGN_TODOS_URL);
  requestUrl.searchParams.append("link", linkUrl);
  if (campaignId) {
    requestUrl.searchParams.append("campaignId", campaignId.toString());
  }
  if (creatorSetId) {
    requestUrl.searchParams.append("creatorSetId", creatorSetId.toString());
  }

  const request = await createRequestWithFirebaseToken({
    url: requestUrl.toString(),
  });
  const response: CreatorInfoFromLinkResponse = await handleResult(request);
  return response.data;
};

export const addCreatorFromLink = async ({
  youtubeHandle,
  instagramHandle,
  tiktokHandle,
  firstName,
  lastName,
  email,
  campaignId,
  adGroupId,
  archetypeId,
  brandState,
}: {
  youtubeHandle?: string;
  instagramHandle?: string;
  tiktokHandle?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  campaignId?: number;
  adGroupId?: number;
  archetypeId?: number;
  brandState?: number;
}) => {
  const reqBody = JSON.stringify({
    campaign_id: campaignId,
    youtube_handle: youtubeHandle,
    instagram_handle: instagramHandle,
    tiktok_handle: tiktokHandle,
    first_name: firstName,
    last_name: lastName,
    email,
    ad_group_id: adGroupId,
    archetype_id: archetypeId,
    brand_state: brandState,
  });
  const request = await createRequestWithFirebaseToken({
    url: `${API_URL}/api/campaigns/import_creator/`,
    method: "POST",
    body: reqBody,
  });
  const response = await handleResult(request);
  return response;
};

// same as fetchCreatorInfoFromLink except no campaign data
export const fetchCreatorDetailsFromLink = async (
  linkUrl: string,
): Promise<CreatorProfileInfoFromLinkResponse> => {
  const requestUrl = new URL(CREATOR_LINK_FETCH_URL);
  requestUrl.searchParams.append("link", linkUrl);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl.toString(),
  });
  const response: CreatorProfileInfoFromLinkResponse = await handleResult(request);
  return response;
};

export const checkMergeRequest = async ({ mergeRequestId }: { mergeRequestId: number }) => {
  const request = await createRequestWithFirebaseToken({
    url: `${API_URL}/api/creator/merge_request_status/?merge_request_id=${mergeRequestId}`,
  });
  const response = await handleResult(request);
  return response;
};

export const mergeCreatorFromLink = async ({
  youtubeHandle,
  instagramHandle,
  tiktokHandle,
  firstName,
  lastName,
  email,
  creatorId,
  couldNotFindYoutube,
  couldNotFindInstagram,
  couldNotFindTiktok,
  couldNotFindFirstName,
  couldNotFindEmail,
}: {
  youtubeHandle?: string;
  instagramHandle?: string;
  tiktokHandle?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  creatorId: number;
  couldNotFindYoutube: boolean;
  couldNotFindInstagram: boolean;
  couldNotFindTiktok: boolean;
  couldNotFindFirstName: boolean;
  couldNotFindEmail: boolean;
}) => {
  const reqBody = JSON.stringify({
    youtube_handle: youtubeHandle,
    instagram_handle: instagramHandle,
    tiktok_handle: tiktokHandle,
    first_name: firstName,
    last_name: lastName,
    email,
    creator_id: creatorId,
    could_not_find_yt: couldNotFindYoutube,
    could_not_find_ig: couldNotFindInstagram,
    could_not_find_tt: couldNotFindTiktok,
    could_not_find_first_name: couldNotFindFirstName,
    could_not_find_email: couldNotFindEmail,
  });
  const request = await createRequestWithFirebaseToken({
    url: `${API_URL}/api/creator/merge_creator/`,
    method: "POST",
    body: reqBody,
  });
  const response = await handleResult(request);
  return response;
};

export default null;
