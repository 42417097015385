import React, { useRef } from "react";
import camera_icon from "components/assets/photo-camera-icon.svg";
import { Box } from "gestalt";

const cameraIconStyle = {
  position: "absolute",
  transform: "translate(-50%,-50%)",
  left: "50%",
  top: "50%",
  width: "30px",
  height: "30px",
  opacity: "0.8",
  cursor: "pointer",
} as React.CSSProperties;

const UploadButton = ({
  buttonName,
  handleImageUpload,
}: {
  buttonName: string;
  handleImageUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  const ref = useRef<HTMLInputElement>(null);
  return (
    <label htmlFor={buttonName} style={{ cursor: "inherit" }}>
      <Box
        rounding="circle"
        width={50}
        height={50}
        opacity={0.8}
        color="transparentDarkGray"
        position="relative">
        <img alt="camera icon" style={cameraIconStyle} src={camera_icon} />
      </Box>
      <input
        id={buttonName}
        type="file"
        accept="image/*"
        multiple={false}
        ref={ref}
        onChange={(element) => handleImageUpload(element)}
        style={{ display: "none" }}
      />
    </label>
  );
};

export default UploadButton;
