import React from "react";
import { Flex, Text, Link, Heading } from "gestalt";
import "gestalt/dist/gestalt.css";
import { User as FirebaseUser } from "firebase/auth";
import { getAdminUser } from "admin/AdminLayout";

const Links = () => (
  <Flex direction="column">
    <Heading size="400">Links</Heading>
    <ul>
      <li>
        <Link href="/ops_home/campaign_datasets">Campaign datasets generation</Link>
      </li>
      <li>
        <Link href="/admin/creator/search">Creator Search</Link>
      </li>
    </ul>
  </Flex>
);

const OpsHome = () => {
  const user: FirebaseUser = getAdminUser();
  return (
    <Flex direction="column" gap={12}>
      <Text>Welcome to the Ops portal</Text>
      <Links />
    </Flex>
  );
};

export default OpsHome;
