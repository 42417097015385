import React, { ReactNode } from "react";
import { Box, Text } from "gestalt";
import "gestalt/dist/gestalt.css";

import "pages/home/styles/light-styles.css";
import Spacer from "components/Spacer";

export const ProductHighlight = ({
  title,
  description,
  imageSrc,
  upsellButton,
  left,
}: {
  title: string;
  description: string;
  imageSrc: string;
  upsellButton: ReactNode;
  left?: boolean;
}) => {
  const featureString = (
    <div className="productFeatureContent" key="featureContent">
      <Text size="500" weight="bold">
        {title}
      </Text>
      <Text size="400">{description}</Text>
      <Spacer height={4} />
      <Box>{upsellButton}</Box>
    </div>
  );
  const featureImage = (
    <img key="featureImage" src={imageSrc} className="productFeatureImage" alt="Find Creators" />
  );
  return (
    <div className="productFeature">
      {left ? [featureString, featureImage] : [featureImage, featureString]}
    </div>
  );
};

export default null;
