import React from "react";

import { ActionIcon, Container, Divider, List, Modal, Space, Text, Title } from "@mantine/core";

import { useDisclosure } from "@mantine/hooks";

import { IconInfoCircle } from "@tabler/icons-react";

export default function ScriptEducation() {
  const [opened, { open, close }] = useDisclosure();

  return (
    <>
      <Modal withCloseButton={false} opened={opened} onClose={close} size="xl">
        <Container px="xs">
          <Title fw="600" order={3}>
            Video Concept Guide
          </Title>
          <Space h="sm" />
          <Text size="sm">
            Crafting an engaging video concept is a blend of creativity, authenticity, and a clear
            understanding of your audience. A well-thought-out concept can make the difference
            between content that resonates and content that falls flat. To help guide you,
            we&apos;ve laid out two video concepts below. One showcases elements of an effective
            concept, while the other provides insight into common pitfalls. Let&apos;s dive in and
            see how you can elevate your video concept!
          </Text>
          <Divider my="sm" />
          <Title fw="600" order={5}>
            Effective Video Concept
          </Title>
          <Space h="sm" />
          <Text size="sm">
            <Text span fw="600">
              Title:
            </Text>{" "}
            &ldquo;Morning Magic with [Brand&apos;s Facial Cleanser]&rdquo;
          </Text>
          <Space h="sm" />
          <Text size="sm">
            <Text span fw="600">
              Description:
            </Text>{" "}
            &ldquo;Join me from sunrise as I delve into a serene morning ritual! I&apos;ll begin
            with sunrise yoga, setting a tranquil tone. As daylight breaks, I&apos;ll transition
            into my skincare routine, integrating [Brand&apos;s Facial Cleanser]. I&apos;ll
            highlight its texture, aroma, and immediate effects, weaving in personal anecdotes about
            my skincare evolution. Concluding with a fresh, invigorated look, I aim to emphasize the
            product&apos;s role in my daily transformation.&rdquo;
          </Text>
          <Space h="sm" />
          <Text size="sm" fw="600">
            Tips for Creators
          </Text>
          <Space h="sm" />
          <List type="ordered" size="sm">
            <List.Item>
              <Text size="sm">
                <Text span fw="600">
                  Narrative Flow:
                </Text>{" "}
                Craft a journey. Viewers should feel they&apos;re progressing with you, making
                content more engaging.
              </Text>
            </List.Item>
            <List.Item>
              <Text size="sm">
                <Text span fw="600">
                  Authenticity Matters:
                </Text>{" "}
                Weave personal stories to build a connection. Share why you love or trust the
                product.
              </Text>
            </List.Item>
            <List.Item>
              <Text size="sm">
                <Text span fw="600">
                  Seamless Integration:
                </Text>{" "}
                Ensure the product feels like a natural part of the content, not a forced insert.
              </Text>
            </List.Item>
          </List>
          <Divider my="sm" />
          <Title fw="600" order={5}>
            Less Effective Video Concept
          </Title>
          <Space h="sm" />
          <Text size="sm">
            <Text span fw="600">
              Title:
            </Text>{" "}
            &ldquo;Washing with [Brand&apos;s Facial Cleanser]&rdquo;
          </Text>
          <Space h="sm" />
          <Text size="sm">
            <Text span fw="600">
              Description:
            </Text>{" "}
            &ldquo;I&apos;ll be washing my face with [Brand&apos;s Facial Cleanser], showing its
            foaminess and then rinsing. I&apos;ll briefly mention the ingredients and wrap
            up.&rdquo;
          </Text>
          <Space h="sm" />
          <Text size="sm" fw="600">
            Tips for Creators
          </Text>
          <Space h="sm" />
          <List type="ordered" size="sm">
            <List.Item>
              <Text size="sm">
                <Text span fw="600">
                  Add Depth:
                </Text>{" "}
                Avoid superficial rundowns. Dive deeper into your experience and the product&apos;s
                value.
              </Text>
            </List.Item>
            <List.Item>
              <Text size="sm">
                <Text span fw="600">
                  Connect & Relate:
                </Text>{" "}
                Without a personal touch, viewers may lose interest. Why did you choose this
                product? How has it helped?
              </Text>
            </List.Item>
            <List.Item>
              <Text size="sm">
                <Text span fw="600">
                  Think Unique:
                </Text>{" "}
                What sets your content apart? Ensure there&apos;s a creative twist that&apos;s
                authentic to you.
              </Text>
            </List.Item>
          </List>
        </Container>
      </Modal>
      <ActionIcon variant="subtle" radius="xl" size="1.1rem" onClick={open}>
        <IconInfoCircle />
      </ActionIcon>
    </>
  );
}
