import React, { useEffect, useState } from "react";

import {
  Avatar,
  Badge,
  Button,
  Center,
  Flex,
  Group,
  NumberInput,
  Paper,
  SegmentedControl,
  Stack,
  Table,
  Text,
} from "@mantine/core";

import { useAdminAppContext } from "admin/app/AdminAppShell";

import { Brand, BrandStatus } from "models/Brand";
import { Campaign, CampaignStatus } from "models/Campaign";
import { CreatorSet } from "components/discovery/Datamodels";
import { OpsTask } from "models/OpsTask";
import { User } from "models/User";

import { getOpsSourcingPlan, updateOpsSourcingPlan } from "admin/api/opsApi";
import { CreatorSetStatusReadOnlyColumn } from "campaigns/main/campaignPage/creator_sets/CreatorSetTableRow";
import { BrandState } from "components/creator_lists/CreatorListsUtils";
import BrandHeader from "admin/app/brand/BrandHeader";

function getCampaignToCreatorSetMap(creatorSets: CreatorSet[]) {
  // iterates through
  const campaignMap = new Map<number, CreatorSet[]>();
  const campaignToCreatorSetIds = new Map<number, Set<number>>();
  creatorSets.forEach((creatorSet) => {
    creatorSet.ad_groups.forEach((adGroup) => {
      if (campaignToCreatorSetIds.has(adGroup.campaign_id)) {
        // check if creatorset is already mapped to this campaign
        const existingCreatorSetIds = campaignToCreatorSetIds.get(adGroup.campaign_id);
        if (!existingCreatorSetIds.has(creatorSet.id)) {
          const creatorSetList = campaignMap.get(adGroup.campaign_id);
          creatorSetList.push(creatorSet);
          existingCreatorSetIds.add(creatorSet.id);
        }
      } else {
        campaignMap.set(adGroup.campaign_id, [creatorSet]);
        campaignToCreatorSetIds.set(adGroup.campaign_id, new Set([creatorSet.id]));
      }
    });
  });
  return campaignMap;
}

function getCampaignIdToCampaignMap(campaigns: Campaign[]) {
  const campaignMap = new Map<number, Campaign>();
  campaigns.forEach((campaign) => {
    campaignMap.set(campaign.id, campaign);
  });
  return campaignMap;
}

function getCreatorSetIdToCreatorSetMap(creatorSets: CreatorSet[]) {
  const creatorSetMap = new Map<number, CreatorSet>();
  creatorSets.forEach((creatorSet) => {
    creatorSetMap.set(creatorSet.id, creatorSet);
  });
  return creatorSetMap;
}

function getBrandToCampaignMap(campaigns: Campaign[]) {
  const brandMap = new Map<number, Campaign[]>();
  campaigns.forEach((campaign) => {
    if (brandMap.has(campaign.brand.id)) {
      const campaignList = brandMap.get(campaign.brand.id);
      campaignList.push(campaign);
    } else {
      brandMap.set(campaign.brand.id, [campaign]);
    }
  });
  return brandMap;
}

function getBrandIdToBrandMap(brands: Brand[]) {
  const brandMap = new Map<number, Brand>();
  brands.forEach((brand) => {
    brandMap.set(brand.id, brand);
  });
  return brandMap;
}

const OpsWorkloadRow = ({
  opsUser,
  opsWorkloadMap,
  opsTaskCountMap,
}: {
  opsUser: User;
  opsWorkloadMap: Map<string, number>;
  opsTaskCountMap: Map<string, number>;
}) => {
  const [workload, setWorkload] = useState(0);
  const [volume, setVolume] = useState(0);
  useEffect(() => {
    if (opsWorkloadMap.has(opsUser.key)) {
      setWorkload(opsWorkloadMap.get(opsUser.key) || 0);
    } else {
      setWorkload(0);
    }
  }, [opsWorkloadMap, opsUser]);

  useEffect(() => {
    if (opsTaskCountMap.has(opsUser.key)) {
      setVolume(opsTaskCountMap.get(opsUser.key) || 0);
    } else {
      setVolume(0);
    }
  }, [opsTaskCountMap, opsUser]);

  return (
    <Table.Tr>
      <Table.Td>{opsUser.name}</Table.Td>
      <Table.Td>{volume}</Table.Td>
      <Table.Td>{workload}</Table.Td>
    </Table.Tr>
  );
};

// TODO(andrew): generalize this?
function replacer(key: any, value: any) {
  if (value instanceof Map) {
    return {
      dataType: "Map",
      value: Array.from(value.entries()), // or with spread: value: [...value]
    };
  }
  return value;
}

function reviver(key: any, value: any) {
  if (typeof value === "object" && value !== null) {
    if (value.dataType === "Map") {
      return new Map(value.value);
    }
  }
  return value;
}

const OpsUserWorkloadTable = ({
  opsUsers,
  opsTaskCountMap,
  opsWorkloadMap,
}: {
  opsUsers: User[];
  opsTaskCountMap: Map<string, number>;
  opsWorkloadMap: Map<string, number>;
}) => {
  return (
    <Table>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Ops User</Table.Th>
          <Table.Th>Sourcing Volume</Table.Th>
          <Table.Th>Workload</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {opsUsers.map((opsUser) => (
          <OpsWorkloadRow
            key={opsUser.key}
            opsUser={opsUser}
            opsTaskCountMap={opsTaskCountMap}
            opsWorkloadMap={opsWorkloadMap}
          />
        ))}
      </Table.Tbody>
    </Table>
  );
};

const OpsCreatorSetWorkloadRow = ({
  creatorSet,
  opsCreatorSetTasks,
}: {
  creatorSet: CreatorSet;
  opsCreatorSetTasks: Map<number, Map<string, number>>;
}) => {
  const [workload, setWorkload] = useState(0);
  const [volume, setVolume] = useState(0);
  useEffect(() => {
    if (opsCreatorSetTasks.has(creatorSet?.id)) {
      const taskCount = opsCreatorSetTasks.get(creatorSet.id);
      const totalWorkload = Array.from(taskCount.values()).reduce(
        (acc, curr) => acc + (curr || 0) * creatorSet.sourcing_difficulty,
        0,
      );
      const totalTaskCount = Array.from(taskCount.values()).reduce(
        (acc, curr) => acc + (curr || 0),
        0,
      );
      setWorkload(totalWorkload || 0);
      setVolume(totalTaskCount || 0);
    } else {
      setWorkload(0);
      setVolume(0);
    }
  }, [opsCreatorSetTasks, creatorSet]);

  return (
    <Table.Tr>
      <Table.Td>{creatorSet.name}</Table.Td>
      <Table.Td w={125}>
        <CreatorSetStatusReadOnlyColumn status={creatorSet.status} />
      </Table.Td>
      <Table.Td>{volume}</Table.Td>
      <Table.Td>{workload}</Table.Td>
    </Table.Tr>
  );
};

const OpsCreatorSetWorkloadTable = ({
  opsCreatorSetTasks,
}: {
  opsCreatorSetTasks: Map<number, Map<string, number>>;
}) => {
  const { creatorSets } = useAdminAppContext();
  return (
    <Table>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Creator Set</Table.Th>
          <Table.Th>Status</Table.Th>
          <Table.Th>Sourcing Volume</Table.Th>
          <Table.Th>Workload</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {creatorSets.map((creatorSet) => (
          <OpsCreatorSetWorkloadRow
            key={creatorSet.id}
            creatorSet={creatorSet}
            opsCreatorSetTasks={opsCreatorSetTasks}
          />
        ))}
      </Table.Tbody>
    </Table>
  );
};

const OpsCampaignWorkloadRow = ({
  campaign,
  campaignWorkloadMap,
  campaignTaskCountMap,
}: {
  campaign: Campaign;
  campaignWorkloadMap: Map<number, number>;
  campaignTaskCountMap: Map<number, number>;
}) => {
  const [workload, setWorkload] = useState(0);
  const [volume, setVolume] = useState(0);

  useEffect(() => {
    if (campaignWorkloadMap?.has(campaign.id)) {
      setWorkload(campaignWorkloadMap.get(campaign.id) || 0);
    } else {
      setWorkload(0);
    }
  }, [campaignWorkloadMap, campaign]);

  useEffect(() => {
    if (campaignTaskCountMap?.has(campaign.id)) {
      setVolume(campaignTaskCountMap.get(campaign.id) || 0);
    } else {
      setVolume(0);
    }
  }, [campaignTaskCountMap, campaign]);

  function getColorFromStatus(status: CampaignStatus): string {
    switch (status) {
      case CampaignStatus.Draft:
        return "yellow";
      case CampaignStatus.Onboarding:
        return "yellow";
      case CampaignStatus.Active:
        return "green";
      case CampaignStatus.Paused:
        return "orange";
      case CampaignStatus.Completed:
        return "blue";
      default:
        return "gray";
    }
  }

  return (
    <Table.Tr>
      <Table.Td>{campaign.title}</Table.Td>
      <Table.Td>
        <Badge color={getColorFromStatus(campaign.status)}>{CampaignStatus[campaign.status]}</Badge>
      </Table.Td>
      <Table.Td>{volume}</Table.Td>
      <Table.Td>{workload}</Table.Td>
    </Table.Tr>
  );
};

const OpsCampaignWorkloadTable = ({
  opsCreatorSetTasks,
  campaignIdToCampaignMap,
  campaignToCreatorSetMap,
  creatorSetToVolume,
  creatorSetToWorkload,
}: {
  opsCreatorSetTasks: Map<number, Map<string, number>>;
  campaignIdToCampaignMap: Map<number, Campaign>;
  campaignToCreatorSetMap: Map<number, CreatorSet[]>;
  creatorSetToVolume: Map<number, number>;
  creatorSetToWorkload: Map<number, number>;
}) => {
  const { campaigns } = useAdminAppContext();
  // calculate campaign-level workload
  const [campaignWorkloadMap, setCampaignWorkloadMap] = useState<Map<number, number>>(new Map());
  const [campaignTaskCountMap, setCampaignTaskCountMap] = useState<Map<number, number>>(new Map());

  useEffect(() => {
    const updatedCampaignWorkloadMap = new Map<number, number>();
    const updatedCampaignTaskCountMap = new Map<number, number>();

    campaignToCreatorSetMap.forEach((campaignCreatorSets, campaignId) => {
      let totalWorkload = 0;
      let totalTaskCount = 0;

      campaignCreatorSets.forEach((creatorSet) => {
        if (creatorSetToVolume.has(creatorSet.id)) {
          totalTaskCount += creatorSetToVolume.get(creatorSet.id) || 0;
        }
        if (creatorSetToWorkload.has(creatorSet.id)) {
          totalWorkload += creatorSetToWorkload.get(creatorSet.id) || 0;
        }
      });
      updatedCampaignWorkloadMap.set(campaignId, totalWorkload);
      updatedCampaignTaskCountMap.set(campaignId, totalTaskCount);
    });
    setCampaignWorkloadMap(updatedCampaignWorkloadMap);
    setCampaignTaskCountMap(updatedCampaignTaskCountMap);
  }, [campaigns, campaignToCreatorSetMap, creatorSetToVolume, creatorSetToWorkload]);
  return (
    <Table>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Campaign</Table.Th>
          <Table.Th>Status</Table.Th>
          <Table.Th>Sourcing Volume</Table.Th>
          <Table.Th>Workload</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {campaigns
          .filter(
            (campaign) =>
              (campaign.status === CampaignStatus.Onboarding ||
                campaign.status === CampaignStatus.Active ||
                campaign.status === CampaignStatus.Paused) &&
              !campaign.is_test_campaign,
          )
          .map((campaign) => (
            <OpsCampaignWorkloadRow
              key={campaign.id}
              campaign={campaign}
              campaignWorkloadMap={campaignWorkloadMap}
              campaignTaskCountMap={campaignTaskCountMap}
            />
          ))}
      </Table.Tbody>
    </Table>
  );
};

function BrandStatusBadge({ status }: { status: BrandStatus }) {
  let color = "gray";
  const label = BrandStatus[status] || "Unknown";

  switch (status) {
    case BrandStatus.Active:
      color = "green";
      break;
    case BrandStatus.Free:
      color = "cyan";
      break;
    case BrandStatus.Onboarding:
      color = "yellow";
      break;
    case BrandStatus.Paused:
      color = "orange";
      break;
    case BrandStatus.Completed:
      color = "blue";
      break;
    case BrandStatus.Deleted:
      color = "red";
      break;
    case BrandStatus.Archived:
      color = "grape";
      break;
    default:
      color = "gray";
      break;
  }

  return (
    <Center>
      <Badge color={color} fw={500}>
        {label}
      </Badge>
    </Center>
  );
}

const OpsBrandWorkloadRow = ({
  brand,
  brandWorkloadMap,
  brandTaskCountMap,
}: {
  brand: Brand;
  brandWorkloadMap: Map<number, number>;
  brandTaskCountMap: Map<number, number>;
}) => {
  const [workload, setWorkload] = useState(0);
  const [volume, setVolume] = useState(0);

  useEffect(() => {
    if (brandWorkloadMap?.has(brand.id)) {
      setWorkload(brandWorkloadMap.get(brand.id) || 0);
    } else {
      setWorkload(0);
    }
  }, [brandWorkloadMap, brand]);

  useEffect(() => {
    if (brandTaskCountMap?.has(brand.id)) {
      setVolume(brandTaskCountMap.get(brand.id) || 0);
    } else {
      setVolume(0);
    }
  }, [brandTaskCountMap, brand]);

  return (
    <Table.Tr>
      <Table.Td>{brand.brand_name}</Table.Td>
      <Table.Td>
        <BrandStatusBadge status={brand.status} />
      </Table.Td>
      <Table.Td>{volume}</Table.Td>
      <Table.Td>{workload}</Table.Td>
    </Table.Tr>
  );
};

const OpsBrandWorkloadTable = ({
  creatorSetToVolume,
  creatorSetToWorkload,
}: {
  creatorSetToVolume: Map<number, number>;
  creatorSetToWorkload: Map<number, number>;
}) => {
  const { brands, creatorSets } = useAdminAppContext();

  const [brandToCreatorSets, setBrandToCreatorSets] = useState<Map<number, CreatorSet[]>>(
    new Map(),
  );

  useEffect(() => {
    const updatedBrandToCreatorSets = new Map<number, CreatorSet[]>();
    creatorSets.forEach((creatorSet) => {
      if (updatedBrandToCreatorSets.has(creatorSet?.brand?.id)) {
        updatedBrandToCreatorSets.get(creatorSet?.brand?.id)?.push(creatorSet);
      } else {
        updatedBrandToCreatorSets.set(creatorSet?.brand?.id, [creatorSet]);
      }
    });
    setBrandToCreatorSets(updatedBrandToCreatorSets);
  }, [creatorSets]);

  // calculate campaign-level workload
  const [brandWorkloadMap, setBrandWorkloadMap] = useState<Map<number, number>>(new Map());
  const [brandTaskCountMap, setBrandTaskCountMap] = useState<Map<number, number>>(new Map());

  useEffect(() => {
    const updatedBrandWorkloadMap = new Map<number, number>();
    const updatedBrandTaskCountMap = new Map<number, number>();

    brandToCreatorSets.forEach((brandCreatorSets, brandId) => {
      let totalWorkload = 0;
      let totalTaskCount = 0;

      brandCreatorSets.forEach((creatorSet) => {
        if (creatorSetToVolume.has(creatorSet.id)) {
          totalTaskCount += creatorSetToVolume.get(creatorSet.id) || 0;
        }
        if (creatorSetToWorkload.has(creatorSet.id)) {
          totalWorkload += creatorSetToWorkload.get(creatorSet.id) || 0;
        }
      });
      updatedBrandWorkloadMap.set(brandId, totalWorkload);
      updatedBrandTaskCountMap.set(brandId, totalTaskCount);
    });
    setBrandWorkloadMap(updatedBrandWorkloadMap);
    setBrandTaskCountMap(updatedBrandTaskCountMap);
  }, [brands, brandToCreatorSets, creatorSetToVolume, creatorSetToWorkload]);
  return (
    <Table>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Brand</Table.Th>
          <Table.Th>Status</Table.Th>
          <Table.Th>Sourcing Volume</Table.Th>
          <Table.Th>Workload</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {brands
          .filter(
            (brand) =>
              brand.status === BrandStatus.Onboarding || brand.status === BrandStatus.Active,
          )
          .map((brand) => (
            <OpsBrandWorkloadRow
              key={brand.id}
              brand={brand}
              brandWorkloadMap={brandWorkloadMap}
              brandTaskCountMap={brandTaskCountMap}
            />
          ))}
      </Table.Tbody>
    </Table>
  );
};

const OpsWorkloadOverview = ({
  opsUsers,
  opsWorkloadMap,
  opsTaskCountMap,
  opsCreatorSetTasks,
  setOpsCreatorSetTasks,
  campaignIdToCampaignMap,
  campaignToCreatorSetMap,
  setTasks,
}: {
  opsUsers: User[];
  opsWorkloadMap: Map<string, number>;
  opsTaskCountMap: Map<string, number>;
  opsCreatorSetTasks: Map<number, Map<string, number>>;
  setOpsCreatorSetTasks: (opsCreatorSetTasks: Map<number, Map<string, number>>) => void;
  campaignIdToCampaignMap: Map<number, Campaign>;
  campaignToCreatorSetMap: Map<number, CreatorSet[]>;
  setTasks: (tasks: OpsTask[]) => void;
}) => {
  const { creatorSets } = useAdminAppContext();
  const [control, setControl] = useState("user");

  // Calculate creatorset volume and workload
  const [creatorSetToVolume, setCreatorSetToVolume] = useState<Map<number, number>>(new Map());
  const [creatorSetToWorkload, setCreatorSetToWorkload] = useState<Map<number, number>>(new Map());

  useEffect(() => {
    if (!creatorSets) {
      return;
    }
    const updatedCreatorSetToVolume = new Map<number, number>();
    const updatedCreatorSetToWorkload = new Map<number, number>();
    creatorSets.forEach((creatorSet) => {
      if (opsCreatorSetTasks.has(creatorSet?.id)) {
        const taskCount = opsCreatorSetTasks.get(creatorSet.id);
        const totalWorkload = Array.from(taskCount.values()).reduce(
          (acc, curr) => acc + (curr || 0) * creatorSet.sourcing_difficulty,
          0,
        );
        const totalTaskCount = Array.from(taskCount.values()).reduce(
          (acc, curr) => acc + (curr || 0),
          0,
        );
        updatedCreatorSetToVolume.set(creatorSet.id, totalTaskCount || 0);
        updatedCreatorSetToWorkload.set(creatorSet.id, totalWorkload || 0);
      } else {
        updatedCreatorSetToVolume.set(creatorSet.id, 0);
        updatedCreatorSetToWorkload.set(creatorSet.id, 0);
      }
    });
    setCreatorSetToVolume(updatedCreatorSetToVolume);
    setCreatorSetToWorkload(updatedCreatorSetToWorkload);
  }, [opsCreatorSetTasks, creatorSets]);

  return (
    <Stack gap="md">
      <SegmentedControl
        value={control}
        onChange={setControl}
        data={[
          { label: "By User", value: "user" },
          { label: "By Creator Set", value: "creator_set" },
          { label: "By Campaign", value: "campaign" },
          { label: "By Brand", value: "brand" },
        ]}
      />
      {control === "user" && (
        <OpsUserWorkloadTable
          opsUsers={opsUsers}
          opsWorkloadMap={opsWorkloadMap}
          opsTaskCountMap={opsTaskCountMap}
        />
      )}
      {control === "creator_set" && (
        <OpsCreatorSetWorkloadTable opsCreatorSetTasks={opsCreatorSetTasks} />
      )}
      {control === "campaign" && (
        <OpsCampaignWorkloadTable
          opsCreatorSetTasks={opsCreatorSetTasks}
          creatorSetToVolume={creatorSetToVolume}
          creatorSetToWorkload={creatorSetToWorkload}
          campaignIdToCampaignMap={campaignIdToCampaignMap}
          campaignToCreatorSetMap={campaignToCreatorSetMap}
        />
      )}
      {control === "brand" && (
        <OpsBrandWorkloadTable
          creatorSetToVolume={creatorSetToVolume}
          creatorSetToWorkload={creatorSetToWorkload}
        />
      )}
      <Group align="center" justify="center">
        <Button
          onClick={() => {
            // convert data in storable format
            const serializedData = JSON.stringify(opsCreatorSetTasks, replacer);
            updateOpsSourcingPlan(serializedData).then((resp) => {
              if (resp !== null && resp?.tasks && resp?.tasks?.length >= 0) {
                setTasks(resp.tasks);
              }
            });
          }}>
          Save Workload
        </Button>
        <Button
          color="red"
          onClick={() => {
            setOpsCreatorSetTasks(new Map());
          }}>
          Clear Plan
        </Button>
      </Group>
    </Stack>
  );
};

const OpsUserCreatorSetInput = ({
  opsUser,
  creatorSet,
  opsCreatorSetTasks,
  setOpsCreatorSetTasks,
}: {
  opsUser: User;
  creatorSet: CreatorSet;
  opsCreatorSetTasks: Map<number, Map<string, number>>;
  setOpsCreatorSetTasks: (opsCreatorSetTasks: Map<number, Map<string, number>>) => void;
}) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (
      opsCreatorSetTasks.has(creatorSet.id) &&
      opsCreatorSetTasks.get(creatorSet.id).has(opsUser.key) &&
      Number.isInteger(opsCreatorSetTasks.get(creatorSet.id).get(opsUser.key))
    ) {
      const taskCount = opsCreatorSetTasks.get(creatorSet.id).get(opsUser.key);
      const safeValue = Number.isInteger(taskCount) ? taskCount : 0;
      if (safeValue !== count) {
        setCount(safeValue);
      }
    } else if (!opsCreatorSetTasks.has(creatorSet.id)) {
      setCount(0);
    }
  }, [opsCreatorSetTasks, creatorSet, opsUser]);

  return (
    <NumberInput
      w={100}
      value={count}
      onChange={(value: number) => {
        const newOpsCreatorSetTasks = new Map(opsCreatorSetTasks);
        if (newOpsCreatorSetTasks.has(creatorSet.id)) {
          newOpsCreatorSetTasks.get(creatorSet.id).set(opsUser.key, value);
        } else {
          newOpsCreatorSetTasks.set(creatorSet.id, new Map([[opsUser.key, value]]));
        }
        setOpsCreatorSetTasks(newOpsCreatorSetTasks);
        setCount(value);
      }}
    />
  );
};

const OpsCampaignCreatorSetPlanning = ({
  campaign,
  creatorSets,
  creatorSetIdToCreatorSetMap,
  opsCreatorSetTasks,
  setOpsCreatorSetTasks,
  opsWorkloadMap,
}: {
  campaign: Campaign;
  creatorSets: CreatorSet[];
  creatorSetIdToCreatorSetMap: Map<number, CreatorSet>;
  opsCreatorSetTasks: Map<number, Map<string, number>>;
  setOpsCreatorSetTasks: (opsCreatorSetTasks: Map<number, Map<string, number>>) => void;
  opsWorkloadMap: Map<string, number>;
}) => {
  const { opsUsers } = useAdminAppContext();
  const [trainedOpsMembers, setTrainedOpsMembers] = useState<Map<number, User[]>>(
    new Map<number, User[]>(),
  );
  const [taskRows, setTaskRows] = useState([]);
  const [creatorSetTotalCount, setCreatorSetTotalCount] = useState<Map<number, number>>(new Map());
  const [campaignTotalCount, setCampaignTotalCount] = useState<Map<number, number>>(new Map());

  useEffect(() => {
    const trainedOpsMembersMap = new Map<number, User[]>();
    if (!creatorSets) {
      return;
    }
    creatorSets.forEach((creatorSet) => {
      creatorSet.ops_users.forEach((opsUserKey) => {
        const opsUser = opsUsers.find((user) => user.key === opsUserKey);
        if (trainedOpsMembersMap.has(creatorSet.id)) {
          const opsUserList = trainedOpsMembersMap.get(creatorSet.id);
          opsUserList.push(opsUser);
        } else {
          trainedOpsMembersMap.set(creatorSet.id, [opsUser]);
        }
      });
    });
    setTrainedOpsMembers(trainedOpsMembersMap);
  }, [creatorSets, opsUsers]);

  useEffect(() => {
    if (!creatorSets) {
      return;
    }
    const creatorSetTotalCountMap = new Map<number, number>();
    const campaignTotalCountMap = new Map<number, number>();
    creatorSets.forEach((creatorSet) => {
      let totalCount = 0;
      // get campaigns for each creatorSet
      const campaignIds = new Set(creatorSet.ad_groups.map((adGroup) => adGroup.campaign_id));
      creatorSet.ops_users.forEach((opsUserKey) => {
        if (
          opsCreatorSetTasks.has(creatorSet.id) &&
          opsCreatorSetTasks.get(creatorSet.id).has(opsUserKey)
        ) {
          const taskRawCount = opsCreatorSetTasks.get(creatorSet.id).get(opsUserKey);
          const taskCount = Number.isInteger(taskRawCount) ? taskRawCount : 0;
          totalCount += taskCount;
        }
      });
      // also update campaign counts
      campaignIds.forEach((campaignId) => {
        if (campaignTotalCountMap.has(campaignId)) {
          campaignTotalCountMap.set(campaignId, campaignTotalCountMap.get(campaignId) + totalCount);
        } else {
          campaignTotalCountMap.set(campaignId, totalCount);
        }
      });
      creatorSetTotalCountMap.set(creatorSet.id, totalCount);
    });
    setCreatorSetTotalCount(creatorSetTotalCountMap);
    setCampaignTotalCount(campaignTotalCountMap);
  }, [opsCreatorSetTasks, creatorSets]);

  const getInitials = (name: string) => {
    if (!name) return "";
    const names = name.split(" ");
    return names[0].charAt(0) + names[names.length - 1].charAt(0);
  };

  // Generate rows for each creator set x ops user
  useEffect(() => {
    const rows: React.ReactElement[] = [];
    trainedOpsMembers.forEach((trainedUsers, creatorSetId) => {
      const creatorSet = creatorSetIdToCreatorSetMap.get(creatorSetId);
      trainedUsers.forEach((user) => {
        rows.push(
          <Table.Tr key={`${campaign?.id || "unassigned"}-${user.key}-${creatorSet.id}`}>
            <Table.Td>
              {campaign?.title || "No Active Campaigns"} (
              {campaign?.id ? campaignTotalCount.get(campaign.id) || 0 : "N/A"})
            </Table.Td>
            <Table.Td>
              {creatorSet.name} ({creatorSetTotalCount.get(creatorSetId) || 0})
            </Table.Td>
            <Table.Td w={125}>
              <CreatorSetStatusReadOnlyColumn status={creatorSet.status} />
            </Table.Td>
            <Table.Td>
              <Group grow preventGrowOverflow={false} wrap="nowrap">
                {user?.avatar_url ? (
                  <Avatar w={40} h={40} src={user?.avatar_url} style={{ flex: "none" }} />
                ) : (
                  <Avatar w={40} h={40} style={{ flex: "none" }}>
                    {getInitials(user?.name)}
                  </Avatar>
                )}
                {user.name} ({opsWorkloadMap.get(user.key) || "0"})
              </Group>
            </Table.Td>
            <Table.Td>
              <OpsUserCreatorSetInput
                creatorSet={creatorSet}
                opsUser={user}
                opsCreatorSetTasks={opsCreatorSetTasks}
                setOpsCreatorSetTasks={setOpsCreatorSetTasks}
              />
            </Table.Td>
          </Table.Tr>,
        );
      });
    });
    setTaskRows(rows);
  }, [creatorSets, trainedOpsMembers, opsWorkloadMap]);

  return <Table.Tbody>{taskRows}</Table.Tbody>;
};

const OpsBrandCreatorSetPlanning = ({
  brand,
  brandToCampaignsMap,
  campaignIdToCampaignMap,
  campaignToCreatorSetMap,
  creatorSetIdToCreatorSetMap,
  opsCreatorSetTasks,
  setOpsCreatorSetTasks,
  opsWorkloadMap,
}: {
  brand: Brand;
  brandToCampaignsMap: Map<number, Campaign[]>;
  campaignIdToCampaignMap: Map<number, Campaign>;
  campaignToCreatorSetMap: Map<number, CreatorSet[]>;
  creatorSetIdToCreatorSetMap: Map<number, CreatorSet>;
  opsCreatorSetTasks: Map<number, Map<string, number>>;
  setOpsCreatorSetTasks: (opsCreatorSetTasks: Map<number, Map<string, number>>) => void;
  opsWorkloadMap: Map<string, number>;
}) => {
  const { creatorSets } = useAdminAppContext();
  const [campaigns, setCampaigns] = useState([]);

  const [unassignedCreatorSets, setUnassignedCreatorSets] = useState<CreatorSet[]>([]);

  useEffect(() => {
    const campaignCreatorSetIds = new Set();
    if (brandToCampaignsMap.has(brand.id)) {
      const brandCampaigns = brandToCampaignsMap.get(brand.id);
      setCampaigns(brandCampaigns);
      brandCampaigns.forEach((campaign) => {
        if (campaignToCreatorSetMap.has(campaign.id)) {
          const campaignCreatorSets = campaignToCreatorSetMap.get(campaign.id);
          campaignCreatorSets.forEach((creatorSet) => {
            campaignCreatorSetIds.add(creatorSet.id);
          });
        }
      });
    }
    const newUnassignedCreatorSets: CreatorSet[] = [];
    creatorSets.forEach((creatorSet) => {
      if (creatorSet?.brand?.id !== brand?.id) {
        return;
      }
      if (!campaignCreatorSetIds.has(creatorSet.id)) {
        newUnassignedCreatorSets.push(creatorSet);
      }
    });
    setUnassignedCreatorSets(newUnassignedCreatorSets);
  }, [brandToCampaignsMap, campaignToCreatorSetMap, brand, creatorSets]);

  return (
    <Table captionSide="top">
      <Table.Caption> {`${brand.brand_name} Creator Set Tasks`}</Table.Caption>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Campaign</Table.Th>
          <Table.Th>Creator Set (Total Volume)</Table.Th>
          <Table.Th>Status</Table.Th>
          <Table.Th>Ops User (Total User Workload)</Table.Th>
          <Table.Th>Count</Table.Th>
        </Table.Tr>
      </Table.Thead>
      {campaigns.map((campaign) => {
        const campaignCreatorSets = campaignToCreatorSetMap.get(campaign.id);
        return (
          <OpsCampaignCreatorSetPlanning
            key={`${brand.id}-${campaign.id}`}
            campaign={campaign}
            creatorSets={campaignCreatorSets}
            creatorSetIdToCreatorSetMap={creatorSetIdToCreatorSetMap}
            opsCreatorSetTasks={opsCreatorSetTasks}
            setOpsCreatorSetTasks={setOpsCreatorSetTasks}
            opsWorkloadMap={opsWorkloadMap}
          />
        );
      })}
      <OpsCampaignCreatorSetPlanning
        key={`${brand.id}-unassigned`}
        campaign={null}
        creatorSets={unassignedCreatorSets}
        creatorSetIdToCreatorSetMap={creatorSetIdToCreatorSetMap}
        opsCreatorSetTasks={opsCreatorSetTasks}
        setOpsCreatorSetTasks={setOpsCreatorSetTasks}
        opsWorkloadMap={opsWorkloadMap}
      />
    </Table>
  );
};

export const OpsCreatorSetPlanningMatrix = ({
  setTasks,
}: {
  setTasks: (tasks: OpsTask[]) => void;
}) => {
  const { brands, campaigns, creatorSets, opsUsers } = useAdminAppContext();
  const [brandIdToBrandMap, setBrandIdToBrandMap] = useState(new Map<number, Brand>());
  const [campaignIdToCampaignMap, setCampaignIdToCampaignMap] = useState(
    new Map<number, Campaign>(),
  );
  const [campaignToCreatorSetMap, setCampaignToCreatorSetMap] = useState(
    new Map<number, CreatorSet[]>(),
  );
  const [creatorSetIdToCreatorSetMap, setCreatorSetIdToCreatorSetMap] = useState(
    new Map<number, CreatorSet>(),
  );
  const [brandToCampaignsMap, setBrandToCampaignsMap] = useState(new Map<number, Campaign[]>());

  // Task tracking
  // {creatorSetId: {opsUserKey: count}}}
  const [opsCreatorSetTasks, setOpsCreatorSetTasks] = useState<Map<number, Map<string, number>>>(
    new Map(),
  );
  // Workload
  const [opsWorkloadMap, setOpsWorkloadMap] = useState(new Map<string, number>());
  const [opsTaskCount, setOpsTaskCount] = useState(new Map<string, number>());

  useEffect(() => {
    if (creatorSets?.length > 0) {
      const campaignMap = getCampaignToCreatorSetMap(creatorSets);
      setCampaignToCreatorSetMap(campaignMap);
      const creatorSetMap = getCreatorSetIdToCreatorSetMap(creatorSets);
      setCreatorSetIdToCreatorSetMap(creatorSetMap);
    }
  }, [creatorSets]);

  useEffect(() => {
    if (campaigns?.length > 0) {
      const campaignIdMap = getCampaignIdToCampaignMap(campaigns);
      setCampaignIdToCampaignMap(campaignIdMap);
      const brandToCampaignMap = getBrandToCampaignMap(campaigns);
      setBrandToCampaignsMap(brandToCampaignMap);
    }
  }, [campaigns]);

  useEffect(() => {
    if (brands?.length > 0) {
      const brandMap = getBrandIdToBrandMap(brands);
      setBrandIdToBrandMap(brandMap);
    }
  }, [brands]);

  useEffect(() => {
    // If creatorSets, opsUsers, and creatorSetIdToCreatorSetMap are not loaded
    // then return
    if (!creatorSets || !creatorSetIdToCreatorSetMap) {
      return;
    }
    // Update workload based on task map
    const workloadMap = new Map<string, number>();
    const taskCountMap = new Map<string, number>();
    opsCreatorSetTasks.forEach((taskMap, creatorSetId) => {
      const difficultyMultiplier =
        creatorSetIdToCreatorSetMap.get(creatorSetId)?.sourcing_difficulty;
      taskMap.forEach((count, opsUserKey) => {
        const safeCount = Number.isInteger(count) ? count : 0;
        const currentWorkload = workloadMap.get(opsUserKey) || 0;
        workloadMap.set(opsUserKey, currentWorkload + safeCount * difficultyMultiplier);
        const currentTaskCount = taskCountMap.get(opsUserKey) || 0;
        taskCountMap.set(opsUserKey, currentTaskCount + safeCount);
      });
    });
    setOpsWorkloadMap(workloadMap);
    setOpsTaskCount(taskCountMap);
  }, [opsCreatorSetTasks, creatorSetIdToCreatorSetMap]);

  useEffect(() => {
    // Get sourcing plan
    const abortController = new AbortController();
    getOpsSourcingPlan(abortController).then((plan) => {
      if (!plan) {
        return;
      }
      const parsedPlan = JSON.parse(plan?.raw_data, reviver);
      setOpsCreatorSetTasks(parsedPlan);
    });
    return () => {
      abortController.abort();
    };
  }, []);

  // Map Brand -> Campaign -> CreatorSet -> OpsMember
  return (
    <Stack gap="md">
      <Paper p="md">
        <OpsWorkloadOverview
          opsUsers={opsUsers}
          opsWorkloadMap={opsWorkloadMap}
          opsTaskCountMap={opsTaskCount}
          opsCreatorSetTasks={opsCreatorSetTasks}
          setOpsCreatorSetTasks={setOpsCreatorSetTasks}
          campaignIdToCampaignMap={campaignIdToCampaignMap}
          campaignToCreatorSetMap={campaignToCreatorSetMap}
          setTasks={setTasks}
        />
      </Paper>
      {brands
        .filter(
          (brand: Brand) =>
            brand.status === BrandStatus.Onboarding || brand.status === BrandStatus.Active,
        )
        .map((brand) => (
          <Paper p="md" key={brand.id}>
            <OpsBrandCreatorSetPlanning
              brand={brand}
              brandToCampaignsMap={brandToCampaignsMap}
              campaignIdToCampaignMap={campaignIdToCampaignMap}
              campaignToCreatorSetMap={campaignToCreatorSetMap}
              creatorSetIdToCreatorSetMap={creatorSetIdToCreatorSetMap}
              opsCreatorSetTasks={opsCreatorSetTasks}
              setOpsCreatorSetTasks={setOpsCreatorSetTasks}
              opsWorkloadMap={opsWorkloadMap}
            />
          </Paper>
        ))}
    </Stack>
  );
};

export default OpsCreatorSetPlanningMatrix;
