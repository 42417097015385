import React from "react";
import { User } from "firebase/auth";

import {
  Badge,
  Button,
  Card,
  Group,
  HoverCard,
  Modal,
  Stack,
  Text,
  ThemeIcon,
  Title,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

import {
  IconCircleCheck,
  IconCircleX,
  IconExternalLink,
  IconHistory,
  IconPrinter,
} from "@tabler/icons-react";

import { UseFormReturnType } from "@mantine/form";

import AdminContractHistory from "components/contracts/contract/AdminContractHistory";
import ContractButton from "components/contracts/deliverables/ContractButton";
import CreativeBriefLink from "components/contracts/deliverables/CreativeBriefLink";
import StripeButton from "components/contracts/deliverables/StripeButton";

import {
  contractExpired,
  contractPendingSignature,
  ContractStatus,
  PaymentStatus,
} from "components/contracts/common/Common";
import { formatAmount } from "components/contracts/dashboard/Utils";
import EmailNotificationButton from "components/contracts/deliverables/EmailNotificationButton";

function HeaderTitle({ brandName, displayName }: { brandName: string; displayName: string }) {
  if (!displayName) {
    return (
      <Title order={1} fw="600">
        🤝 {brandName} Partnership
      </Title>
    );
  }
  return (
    <Title
      style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
      order={1}
      fw="600">
      🤝 {brandName} / {displayName}
    </Title>
  );
}

function PaymentStatusBadges({
  contractStatus,
  amount,
  bonusAmount,
  bonusCondition,
  meetsBonusCondition,
  contractComplete,
  paymentStatus,
  bonusPaymentStatus,
}: {
  contractStatus: ContractStatus;
  amount: number;
  bonusAmount: number;
  bonusCondition: string;
  meetsBonusCondition: boolean;
  contractComplete: boolean;
  paymentStatus: PaymentStatus;
  bonusPaymentStatus: PaymentStatus;
}) {
  let paymentBadgeColor = null;
  let paymentBadgeText = null;
  switch (paymentStatus) {
    case PaymentStatus.UNPAID:
      if (contractComplete) {
        paymentBadgeColor = "yellow";
        paymentBadgeText = "PENDING PAYMENT";
      } else {
        paymentBadgeColor = "red";
        paymentBadgeText = "UNPAID";
      }
      break;
    case PaymentStatus.PARTIALLY_PAID:
      if (contractComplete) {
        paymentBadgeColor = "yellow";
        paymentBadgeText = "PENDING PAYMENT";
      } else {
        paymentBadgeColor = "red";
        paymentBadgeText = "PARTIALLY PAID";
      }
      break;
    case PaymentStatus.FULLY_PAID:
      paymentBadgeColor = "teal";
      paymentBadgeText = "PAID";
      break;
    default:
      paymentBadgeColor = "gray";
      paymentBadgeText = "";
      break;
  }

  let bonusBadge = null;

  if (bonusAmount > 0) {
    let bonusBadgeColor = null;
    let bonusBadgeText = null;

    if (!meetsBonusCondition) {
      bonusBadgeColor = "gray";
      bonusBadgeText = "INELIGIBLE";
    } else {
      switch (bonusPaymentStatus) {
        case PaymentStatus.UNPAID:
          bonusBadgeColor = "yellow";
          bonusBadgeText = "PENDING PAYMENT";
          break;
        case PaymentStatus.PARTIALLY_PAID:
          bonusBadgeColor = "yellow";
          bonusBadgeText = "PENDING PAYMENT";
          break;
        case PaymentStatus.FULLY_PAID:
          bonusBadgeColor = "teal";
          bonusBadgeText = "PAID";
          break;
        default:
          bonusBadgeColor = "gray";
          bonusBadgeText = "";
          break;
      }
    }

    if (contractExpired(contractStatus)) {
      bonusBadgeColor = "gray";
    }

    bonusBadge = (
      <HoverCard>
        <HoverCard.Target>
          <Badge size="md" color={bonusBadgeColor} style={{ cursor: "pointer" }}>
            {formatAmount(bonusAmount)} Bonus - {bonusBadgeText}
          </Badge>
        </HoverCard.Target>
        <HoverCard.Dropdown>
          <Group gap="xs">
            <ThemeIcon
              variant="white"
              radius="xl"
              color={meetsBonusCondition ? "teal" : "red"}
              size="sm">
              {meetsBonusCondition ? <IconCircleCheck /> : <IconCircleX />}
            </ThemeIcon>
            <Text>
              <Text span fw="600">
                Bonus Condition:{" "}
              </Text>
              Eligible if {bonusCondition}.
            </Text>
          </Group>
        </HoverCard.Dropdown>
      </HoverCard>
    );
  }

  if (contractExpired(contractStatus)) {
    paymentBadgeColor = "gray";
  }

  return (
    <Stack gap="0.3rem" align="flex-end">
      <Badge size={bonusAmount > 0 ? "md" : "lg"} color={paymentBadgeColor}>
        {formatAmount(amount)} {bonusAmount > 0 ? "BASE" : ""} - {paymentBadgeText}
      </Badge>
      {bonusBadge}
    </Stack>
  );
}

export default function Header({
  user,
  contractId,
  amount,
  bonusAmount,
  bonusCondition,
  meetsBonusCondition,
  paymentStatus,
  bonusPaymentStatus,
  brandName,
  creativeBriefUrl,
  displayName,
  signatureEmail,
  additionalEmails,
  setAdditionalEmails,
  status,
  setStatus,
  hasPaymentDetails,
  paymentDetailsComplete,
  stripeForm,
  closeUrl,
  showAdminOptions,
}: {
  user: User;
  contractId: string;
  amount: number;
  bonusAmount: number;
  bonusCondition: string;
  meetsBonusCondition: boolean;
  paymentStatus: PaymentStatus;
  bonusPaymentStatus: PaymentStatus;
  brandName: string;
  creativeBriefUrl: string;
  displayName: string;
  signatureEmail: string;
  additionalEmails: string[];
  setAdditionalEmails: (value: string[]) => void;
  status: ContractStatus;
  setStatus: (status: ContractStatus) => void;
  hasPaymentDetails: boolean;
  paymentDetailsComplete: boolean;
  stripeForm: UseFormReturnType<
    {
      contractId: string;
      country: string;
      businessType: string;
      firstName: string;
      lastName: string;
      email: string;
      confirmEmail: string;
    },
    (values: {
      contractId: string;
      country: string;
      businessType: string;
      firstName: string;
      lastName: string;
      email: string;
      confirmEmail: string;
    }) => {
      contractId: string;
      country: string;
      businessType: string;
      firstName: string;
      lastName: string;
      email: string;
      confirmEmail: string;
    }
  >;
  closeUrl: string;
  showAdminOptions: boolean;
}) {
  const [justSigned, setJustSigned] = React.useState(false);
  const [defaultEmail, setDefaultEmail] = React.useState(signatureEmail);
  const [historyModalOpened, { open: openHistoryModal, close: closeHistoryModal }] =
    useDisclosure();
  return (
    <Card p={0} radius={0}>
      <Modal
        size="auto"
        opened={historyModalOpened}
        onClose={closeHistoryModal}
        title="Contract History">
        <AdminContractHistory contractId={contractId} />
      </Modal>
      <Stack gap="lg">
        <Group justify="space-between" align="center">
          <HeaderTitle brandName={brandName} displayName={displayName} />
          <PaymentStatusBadges
            contractStatus={status}
            amount={amount}
            bonusAmount={bonusAmount}
            bonusCondition={bonusCondition}
            meetsBonusCondition={meetsBonusCondition}
            contractComplete={status === ContractStatus.COMPLETE}
            paymentStatus={paymentStatus}
            bonusPaymentStatus={bonusPaymentStatus}
          />
        </Group>
        <Group justify="space-between" align="flex-start">
          <Group>
            <CreativeBriefLink
              brandName={brandName}
              creativeBriefUrl={creativeBriefUrl}
              contractStatus={status}
            />
          </Group>
          <Group justify="right" align="end">
            <ContractButton
              user={user}
              contractId={contractId}
              status={status}
              setStatus={setStatus}
              setJustSigned={setJustSigned}
              setDefaultEmail={setDefaultEmail}
              setAdditionalEmails={setAdditionalEmails}
            />
            <StripeButton
              contractStatus={status}
              hasPaymentDetails={hasPaymentDetails}
              paymentDetailsComplete={paymentDetailsComplete}
              stripeForm={stripeForm}
            />
            {!contractExpired(status) && !contractPendingSignature(status) && (
              <EmailNotificationButton
                contractId={contractId}
                additionalEmails={additionalEmails}
                setAddtionalEmails={setAdditionalEmails}
                defaultEmail={defaultEmail}
                justSigned={justSigned}
                setJustSigned={setJustSigned}
              />
            )}
            {showAdminOptions && !contractPendingSignature(status) && (
              <Button
                leftSection={<IconPrinter size="1rem" />}
                onClick={() => window.open(`/admin/export_contract/${contractId}`, "_blank")}
                variant="outline">
                Printable Contract
              </Button>
            )}
            {showAdminOptions && (
              <Button
                leftSection={<IconExternalLink size="1rem" />}
                onClick={() => window.open(closeUrl, "_blank")}
                variant="outline">
                Close
              </Button>
            )}
            {showAdminOptions && (
              <Button
                leftSection={<IconHistory size="1rem" />}
                onClick={openHistoryModal}
                variant="outline">
                History
              </Button>
            )}
          </Group>
        </Group>
      </Stack>
    </Card>
  );
}
