import React from "react";
import { useParams } from "react-router-dom";
import { useCampaignAppContext } from "campaigns/CampaignAppShell";

import ContractApprovalFeed from "components/contracts/brand_review/ContractBrandReviewFeed";

export default function ContractApprovalFeedView() {
  const { selectedAdGroup } = useCampaignAppContext();

  let adGroupIdToUse = selectedAdGroup?.id?.toString();
  if (!adGroupIdToUse) {
    const { adGroupId } = useParams<{ adGroupId: string }>();
    adGroupIdToUse = adGroupId;
  }

  if (!adGroupIdToUse) {
    throw new Error("Must specify adGroupId");
  }

  return <ContractApprovalFeed adGroupId={adGroupIdToUse} />;
}
