import React from "react";
import { Alert, Text } from "@mantine/core";
import { IconInfoCircle } from "@tabler/icons-react";
import {
  ContractDeliverableStatus,
  ContractDeliverableStatusMapping
} from "components/contracts/models/Deliverable";

export default function LiveDateOverdueAlert({
  brandName, liveDate, deliverableStatus,
}: {
  brandName: string;
  liveDate: Date;
  deliverableStatus: ContractDeliverableStatus;
}) {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);

  if (tomorrow >= liveDate &&
    ContractDeliverableStatusMapping[deliverableStatus] >=
    ContractDeliverableStatusMapping[ContractDeliverableStatus.VIDEO_DRAFT_SUBMITTED] &&
    ContractDeliverableStatusMapping[deliverableStatus] <
    ContractDeliverableStatusMapping[ContractDeliverableStatus.VIDEO_DRAFT_APPROVED]) {
    const icon = <IconInfoCircle />;
    return (
      <Alert
        radius="lg"
        variant="light"
        color="red"
        title="Review taking longer than expected"
        icon={icon}>
        We understand your content is still being reviewed by {brandName}. Please wait for their
        approval before going live.{" "}
        <Text size="sm" span fw="500">
          Do not post without getting approval first.
        </Text>{" "}
        Don&apos;t worry about the delay; you won&apos;t be penalized and you will still be paid for
        your work!
      </Alert>
    );
  }

  return null;
}
