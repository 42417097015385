/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { UseFormReturnType } from "@mantine/form";

import { Box, TextInput, Button, Group, Indicator, Modal, Select, Text } from "@mantine/core";

import { useDisclosure } from "@mantine/hooks";

import { IconAt, IconCheck, IconWallet } from "@tabler/icons-react";

import {
  contractAccepted,
  contractExpired,
  contractPendingSignature,
  ContractStatus,
} from "components/contracts/common/Common";
import {
  STRIPE_CONNECT_DASHBOARD_URL,
  STRIPE_SUPPORTED_COUNTRIES,
} from "components/contracts/deliverables/StripeConstants";

import { continueOnboardStripe, onboardStripe } from "components/contracts/common/Api";

const handleOnboardStripe = (
  contractId: string,
  country: string,
  email: string,
  businessType: string,
  firstName: string,
  lastName: string,
  setLoading: (loading: boolean) => void,
) => {
  setLoading(true);
  onboardStripe(contractId, country, email, businessType, firstName, lastName)
    .then((response) => {
      if (response.success) {
        const { redirectUrl } = response;
        window.location.href = redirectUrl;
      }
    })
    .catch(() => {
      setLoading(false);
    });
};

const handleContinueOnboardStripe = (contractId: string) => {
  continueOnboardStripe(contractId).then((response) => {
    if (response.success) {
      const { redirectUrl } = response;
      window.location.href = redirectUrl;
    }
  });
};

function StripeForm({
  stripeForm,
}: {
  stripeForm: UseFormReturnType<
    {
      contractId: string;
      country: string;
      businessType: string;
      firstName: string;
      lastName: string;
      email: string;
      confirmEmail: string;
    },
    (values: {
      contractId: string;
      country: string;
      businessType: string;
      firstName: string;
      lastName: string;
      email: string;
      confirmEmail: string;
    }) => {
      contractId: string;
      country: string;
      businessType: string;
      firstName: string;
      lastName: string;
      email: string;
      confirmEmail: string;
    }
  >;
}) {
  const [loading, setLoading] = React.useState(false);

  return (
    <Box maw={400} pos="relative" mx="auto">
      <form
        onSubmit={stripeForm.onSubmit((values) => {
          handleOnboardStripe(
            values.contractId,
            values.country,
            values.email,
            values.businessType,
            values.firstName,
            values.lastName,
            setLoading,
          );
        })}>
        <Select
          mb="xs"
          searchable
          label="Country"
          placeholder="Select your country"
          data={STRIPE_SUPPORTED_COUNTRIES}
          {...stripeForm.getInputProps("country")}
        />
        <Select
          mb="xs"
          label="Business Type"
          placeholder="Select your business type"
          data={[
            { value: "individual", label: "Individual" },
            { value: "company", label: "Company" },
          ]}
          {...stripeForm.getInputProps("businessType")}
        />
        <Group mb="xs" justify="space-between" grow>
          <TextInput label="First Name" {...stripeForm.getInputProps("firstName")} />
          <TextInput label="Last Name" {...stripeForm.getInputProps("lastName")} />
        </Group>
        <TextInput
          mb="xs"
          label="Email"
          placeholder=""
          leftSection={<IconAt size="0.8rem" />}
          {...stripeForm.getInputProps("email")}
        />
        <TextInput
          mb="xs"
          label="Confirm Email"
          placeholder=""
          leftSection={<IconAt size="0.8rem" />}
          {...stripeForm.getInputProps("confirmEmail")}
        />
        <Group justify="center" mt="md">
          <Button loading={loading} type="submit">
            Continue
          </Button>
        </Group>
      </form>
    </Box>
  );
}

export default function StripeButton({
  contractStatus,
  hasPaymentDetails,
  paymentDetailsComplete,
  stripeForm,
}: {
  contractStatus: ContractStatus;
  hasPaymentDetails: boolean;
  paymentDetailsComplete: boolean;
  stripeForm: UseFormReturnType<
    {
      contractId: string;
      country: string;
      businessType: string;
      firstName: string;
      lastName: string;
      email: string;
      confirmEmail: string;
    },
    (values: {
      contractId: string;
      country: string;
      businessType: string;
      firstName: string;
      lastName: string;
      email: string;
      confirmEmail: string;
    }) => {
      contractId: string;
      country: string;
      businessType: string;
      firstName: string;
      lastName: string;
      email: string;
      confirmEmail: string;
    }
  >;
}) {
  const [opened, { open, close }] = useDisclosure();

  if (contractPendingSignature(contractStatus) || contractExpired(contractStatus)) {
    return (
      <Button disabled variant="outline" leftSection={<IconWallet size="1rem" />}>
        Add Payment Details
      </Button>
    );
  } else if (contractAccepted(contractStatus)) {
    if (hasPaymentDetails && paymentDetailsComplete) {
      return (
        <Button
          variant="outline"
          color="teal"
          leftSection={<IconCheck size="1rem" />}
          onClick={() => {
            const newWindow = window.open(
              STRIPE_CONNECT_DASHBOARD_URL,
              "_blank",
              "noopener,noreferrer",
            );
            if (newWindow) newWindow.opener = null;
          }}>
          View Payment Details
        </Button>
      );
    } else if (hasPaymentDetails && !paymentDetailsComplete) {
      return (
        <Indicator color="red" position="top-start" size={8} processing>
          <Button
            onClick={() => handleContinueOnboardStripe(stripeForm.values.contractId)}
            variant="outline"
            leftSection={<IconWallet size="1rem" />}>
            Add Payment Details
          </Button>
        </Indicator>
      );
    }
    return (
      <>
        <Modal
          opened={opened}
          onClose={close}
          title={
            <Text fz="lg" fw="500">
              Add Payment Details
            </Text>
          }
          transitionProps={{ transition: "fade", duration: 200 }}>
          <StripeForm stripeForm={stripeForm} />
        </Modal>
        <Indicator color="red" position="top-start" size={8} processing>
          <Button
            onClick={open}
            variant="outline"
            leftSection={<IconWallet size="1rem" />}>
            Add Payment Details
          </Button>
        </Indicator>
      </>
    );
  }
  return null;
}
