import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ActionIcon,
  Badge,
  Button,
  Flex,
  Group,
  Modal,
  NativeSelect,
  NumberInput,
  Stack,
  Table,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { IconSettings } from "@tabler/icons-react";

import { BrandObjChangeConfirmation } from "campaigns/main/campaignPage/brand/BrandObjChangeConfirmation";
import { CREATOR_SET_URL } from "campaigns/api/fetchCampaignSettings";
import { CreatorSet, CreatorSetStatus } from "components/discovery/Datamodels";
import { updateBrandObjById } from "campaigns/api/updateBrandObj";

const CreatorSetStatusEditColumn = ({ form }: { form: any }) => {
  return (
    <NativeSelect
      w={125}
      data={Object.keys(CreatorSetStatus)
        .filter((k) => typeof CreatorSetStatus[k as any] === "number")
        .map((key) => ({ label: key, value: CreatorSetStatus[key as any] }))}
      {...form.getInputProps("status")}
    />
  );
};

export const CreatorSetStatusReadOnlyColumn = ({ status }: { status: CreatorSetStatus }) => {
  function getColorFromStatus(s: CreatorSetStatus): string {
    switch (s) {
      case CreatorSetStatus.DRAFT:
        return "yellow";
      case CreatorSetStatus.GROW:
        return "green";
      case CreatorSetStatus.MANUAL:
        return "teal";
      case CreatorSetStatus.ARCHIVED:
        return "gray";
      case CreatorSetStatus.STABLE:
        return "blue";
      default:
        return "gray";
    }
  }
  return <Badge color={getColorFromStatus(status)}>{CreatorSetStatus[status]}</Badge>;
};

export const CreatorSetTableRow = ({
  brandId,
  creatorSet,
  showBrandDetails = false,
  readOnly = false,
}: {
  brandId: number;
  creatorSet: CreatorSet;
  readOnly?: boolean;
  showBrandDetails?: boolean;
}) => {
  const navigate = useNavigate();
  const [opened, { open, close }] = useDisclosure(false);

  const initialObj = {
    name: creatorSet.name,
    status: creatorSet?.status?.toString() || CreatorSetStatus.DRAFT.toString(),
    sourcing_priority: creatorSet.sourcing_priority,
    sourcing_difficulty: creatorSet.sourcing_difficulty,
  };
  const [initialValues, setInitialValues] = useState<{ [key: string]: any }>(initialObj);

  const form = useForm({
    initialValues: initialObj,
  });

  const formatValue = (key: string, value: any) => {
    if (key === "status") {
      return CreatorSetStatus[value];
    }
    return value;
  };
  return (
    <Table.Tr key={`creator-set-table-${creatorSet.id}`}>
      <Modal
        size="lg"
        opened={opened}
        onClose={close}
        title={<Text fw="600">Creator Set Info</Text>}>
        <Stack gap={24}>
          <Text>Please confirm that the following fields were changed:</Text>
          <BrandObjChangeConfirmation
            brandId={brandId}
            endpoint={CREATOR_SET_URL}
            objType="creator set"
            objId={creatorSet.id}
            form={form}
            formValues={form.values}
            customFormatValue={formatValue}
            initialValues={initialValues}
            onSubmit={updateBrandObjById}
            close={close}
          />
        </Stack>
      </Modal>
      <Table.Td>
        <TextInput
          placeholder="Test creator set name"
          /* eslint-disable react/jsx-props-no-spreading */
          {...form.getInputProps("name")}
          disabled={readOnly}
        />
      </Table.Td>
      <Table.Td w={125}>
        {readOnly ? (
          <CreatorSetStatusReadOnlyColumn status={creatorSet.status} />
        ) : (
          <CreatorSetStatusEditColumn form={form} />
        )}
      </Table.Td>
      <Table.Td w={125}>
        <NumberInput
          placeholder="0"
          min={0}
          /* eslint-disable react/jsx-props-no-spreading */
          {...form.getInputProps("sourcing_priority")}
          disabled={readOnly}
        />
      </Table.Td>
      <Table.Td w={125}>
        <NumberInput
          placeholder="1.0"
          min={0}
          /* eslint-disable react/jsx-props-no-spreading */
          {...form.getInputProps("sourcing_difficulty")}
          disabled={readOnly}
        />
      </Table.Td>
      {showBrandDetails && (
        <Table.Td>
          <Text size="sm">{creatorSet.brand.brand_name}</Text>
        </Table.Td>
      )}
      <Table.Td>
        {creatorSet.ad_groups?.length > 0 &&
          creatorSet.ad_groups.map((adGroup) => (
            <Tooltip
              key={`creative-brief-adgroup=${adGroup.id}-tooltip`}
              label={`(${adGroup.campaign_name}) ${adGroup.name}`}>
              <Badge variant="light" key={`creator-set-adgroup=${adGroup.id}`}>
                {adGroup.name}
              </Badge>
            </Tooltip>
          ))}
      </Table.Td>
      <Table.Td>
        <Group gap="xs" justify="space-between">
          <Group>
            <Text size="sm">{creatorSet.id}</Text>
          </Group>
          <Group gap="xs">
            <ActionIcon
              variant="subtle"
              component="button"
              size={32}
              // TODO(make this compatible externally)
              onClick={() => navigate(`/admin/creator_sets?creatorSetId=${creatorSet.id}`)}>
              <IconSettings size="1.5rem" />
            </ActionIcon>
          </Group>
        </Group>
      </Table.Td>
      {!readOnly && (
        <Table.Td>
          <Flex justify="center">
            <Button type="submit" onClick={() => open()} disabled={!form.isDirty()}>
              Save
            </Button>
          </Flex>
        </Table.Td>
      )}
    </Table.Tr>
  );
};

export default CreatorSetTableRow;
