import React, { useState } from "react";
import { Anchor, Flex, Group, List, Stack, Text, Title } from "@mantine/core";
import { CampaignToDoSummary, BrandToDoSummary } from "models/Campaign";
import { BasicTodoWithDetails } from "campaigns/main/campaignPage/BasicTodo";
import { useNavigate } from "react-router-dom";
import TodoListItem from "campaigns/main/campaignPage/TodoListItem";
import { CreatorSetToDos } from "campaigns/main/campaignPage/CreatorSetTodoList";

const CampaignAggregateToDos = ({
  campaignId,
  campaignToDoSummary,
}: {
  campaignId: number;
  campaignToDoSummary: CampaignToDoSummary;
}) => {
  const navigate = useNavigate();

  const creatorTodos = campaignToDoSummary.creator_review?.total || 0;
  let creatorTodoText = "";
  if (creatorTodos === 1) {
    creatorTodoText = "creator";
  } else {
    creatorTodoText = "creators";
  }
  return (
    <List withPadding>
      {creatorTodos > 0 ? (
        <TodoListItem
          key={`${campaignId}-todo-creators`}
          title={`You have ${creatorTodos} ${creatorTodoText} to review.`}
          onClick={() => navigate(`/campaigns/${campaignId}/creators/`)}
          ctaText="View Creators"
        />
      ) : null}
      {Object.keys(campaignToDoSummary.ad_groups).map((key) => {
        const adGroupId = parseInt(key, 10);
        const adGroupTodos = campaignToDoSummary.ad_groups[adGroupId];
        const numTodos = adGroupTodos.total;
        const adGroupName = adGroupTodos.ad_group_name;
        if (numTodos === 0) {
          return null;
        }
        return (
          <TodoListItem
            key={`${campaignId}-${adGroupId}-todo`}
            title={`${adGroupName} has ${numTodos} ${numTodos === 1 ? "item" : "items"} that ${
              numTodos === 1 ? "requires" : "require"
            } your attention.`}
            onClick={() => navigate(`/campaigns/${campaignId}/${adGroupId}/`)}
            ctaText="Go to Ad Group"
          />
        );
      })}
    </List>
  );
};

const BrandAggregateTodos = ({ toDoSummary }: { toDoSummary: BrandToDoSummary }) => (
  <>
    {Object.keys(toDoSummary.campaigns).map((key) => {
      const [showDetails, setShowDetails] = useState<boolean>(false);
      const campaignId = parseInt(key, 10);
      const campaign = toDoSummary.campaigns[campaignId];
      const numTodos = campaign.total;
      if (numTodos === 0) {
        return null;
      }
      const todoText = `${numTodos} ${
        numTodos === 1 ? "item" : "items"
      } that require your attention.`;
      return (
        <div key={`${campaignId}-todo-list`}>
          <BasicTodoWithDetails
            title={`${campaign.campaign_name}`}
            description={`${todoText}`}
            showDetails={showDetails}
            onShowDetailsToggled={(newValue) => setShowDetails(newValue)}
          />
          {showDetails ? (
            <CampaignAggregateToDos campaignId={campaignId} campaignToDoSummary={campaign} />
          ) : null}
        </div>
      );
    })}
  </>
);

const HomePageTodos = ({
  toDoSummary,
  useCreatorSets = true,
}: {
  toDoSummary: BrandToDoSummary;
  useCreatorSets?: boolean;
}) => {
  if (toDoSummary.creatorset_total === 0 && toDoSummary.campaign_total === 0) {
    return null;
  }

  if (useCreatorSets) {
    return (
      <Stack gap={3}>
        {toDoSummary.creatorset_total > 0 ? (
          <>
            <Title order={4}>Creator To-Dos</Title>
            <Group style={{ width: "100%", paddingLeft: "10px" }}>
              <CreatorSetToDos brandToDoSummary={toDoSummary} />
            </Group>
          </>
        ) : null}
        {toDoSummary.campaign_total > 0 ? (
          <>
            <Title order={4}>Campaign To-Dos</Title>
            <Group style={{ width: "100%", paddingLeft: "10px" }}>
              <Stack gap={0} style={{ width: "100%" }}>
                <BrandAggregateTodos toDoSummary={toDoSummary} />
              </Stack>
            </Group>
          </>
        ) : null}
      </Stack>
    );
  }
  return <BrandAggregateTodos toDoSummary={toDoSummary} />;
};

export default HomePageTodos;
