import React, { useEffect, useState } from "react";

import { Anchor, Table } from "@mantine/core";

import { useAdminAppContext } from "admin/app/AdminAppShell";
import { ContractOffer } from "models/ContractOffer";
import { fromISODateString } from "utils/DateUtils";

const ContractOfferRow = ({ contractOffer }: { contractOffer: ContractOffer }) => {
  if (!contractOffer) {
    return null;
  }
  const { campaigns } = useAdminAppContext();
  const campaign = campaigns.find((c) => c.id === contractOffer.opportunity?.campaign_id);
  if (!campaign) {
    return null;
  }

  const opportunityId = contractOffer.opportunity?.id;

  return (
    <Table.Tr>
      <Table.Td>{campaign?.title}</Table.Td>
      <Table.Td>
        <Anchor
          href={`/admin/creator?creatorId=${contractOffer.creator.id}&opportunityId=${opportunityId}`}
          target="_blank">
          {contractOffer.creator?.display_username}
        </Anchor>
      </Table.Td>
      <Table.Td>{fromISODateString(contractOffer?.date_created)?.toDateString()}</Table.Td>
      <Table.Td>{fromISODateString(contractOffer?.last_updated)?.toDateString()}</Table.Td>
    </Table.Tr>
  );
};

export const ContractOfferTable = ({
  contractOffers,
  caption,
  hideTests = false,
}: {
  contractOffers: ContractOffer[];
  caption?: string;
  hideTests?: boolean;
}) => {
  const { campaigns } = useAdminAppContext();
  const [testCampaignIds, setTestCampaignIds] = useState<Set<number>>(new Set());

  useEffect(() => {
    if (campaigns) {
      setTestCampaignIds(new Set(campaigns.filter((c) => c.is_test_campaign).map((c) => c.id)));
    }
  }, [campaigns]);

  // sort by id
  const [data, setData] = useState<ContractOffer[]>(contractOffers);

  useEffect(() => {
    let updatedData = contractOffers;
    if (hideTests) {
      updatedData = updatedData.filter(
        (contractOffer) => !testCampaignIds.has(contractOffer.opportunity?.campaign_id),
      );
    }
    updatedData.sort(
      (a, b) =>
        a.opportunity.campaign_id - b.opportunity.campaign_id ||
        b.last_updated.localeCompare(a.last_updated),
    );
    setData(updatedData);
  }, [hideTests, contractOffers, testCampaignIds]);

  return (
    <Table
      horizontalSpacing="md"
      verticalSpacing="md"
      highlightOnHover
      withTableBorder
      captionSide={caption ? "top" : null}>
      {caption ? (
        <Table.Caption
          styles={{
            caption: {
              fontSize: "16px",
              fontWeight: 400,
            },
          }}>
          {caption}
        </Table.Caption>
      ) : null}
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Campaign</Table.Th>
          <Table.Th>Creator</Table.Th>
          <Table.Th>Creation Date</Table.Th>
          <Table.Th>Last Updated</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {data.map((contractOffer) => (
          <ContractOfferRow key={contractOffer.id} contractOffer={contractOffer} />
        ))}
      </Table.Tbody>
    </Table>
  );
};

export default ContractOfferTable;
