import React from "react";
import { Button, Container, Modal } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconHistory } from "@tabler/icons-react";
import { ContentType } from "components/contracts/common/Common";
import Script from "components/contracts/models/Script";
import DeliverableVideo from "components/contracts/models/DeliverableVideo";
import { MessageParty } from "components/contracts/models/Message";
import ViewPreviousScripts from "components/contracts/common/ViewPreviousScripts";
import ViewPreviousVideos from "components/contracts/common/ViewPreviousVideos";

export default function ViewPreviousRevisions({
  contentType,
  previousScriptRevisions,
  previousVideoRevisions,
}: {
  contentType: ContentType;
  previousScriptRevisions?: Script[];
  previousVideoRevisions?: DeliverableVideo[];
}) {
  if (contentType === ContentType.SCRIPT) {
    if (previousVideoRevisions && previousVideoRevisions.length > 0) {
      throw new Error("Videos cannot be provided for Script content type.");
    }
    if (!previousScriptRevisions || previousScriptRevisions.length === 0) {
      return null;
    }
  } else if (contentType === ContentType.VIDEO) {
    if (previousScriptRevisions && previousScriptRevisions.length > 0) {
      throw new Error("Scripts cannot be provided for Video content type.");
    }
    if (!previousVideoRevisions || previousVideoRevisions.length === 0) {
      return null;
    }
  } else {
    throw new Error("Invalid content type");
  }

  const [opened, { open, close }] = useDisclosure();

  return (
    <>
      <Modal opened={opened} onClose={close} withCloseButton={false} size="auto" maw="70%">
        <Container>
          {contentType === ContentType.SCRIPT && (
            <ViewPreviousScripts
              scripts={previousScriptRevisions}
              messageParty={MessageParty.CREATOR}
            />
          )}
          {contentType === ContentType.VIDEO && (
            <ViewPreviousVideos
              deliverableVideos={previousVideoRevisions}
              messageParty={MessageParty.CREATOR}
            />
          )}
        </Container>
      </Modal>
      <Button onClick={open} variant="default" size="sm" leftSection={<IconHistory size="1rem" />}>
        View Previous Revisions
      </Button>
    </>
  );
}
