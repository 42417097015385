import React from "react";
import { Flex, Box, Datapoint } from "gestalt";
import Spacer from "components/Spacer";

import { API_URL } from "configs/Configs";
import { createRequest, createRequestWithFirebaseToken, handleResult } from "utils/ApiUtils";
import { AudienceEntryResponse, StatsEntryResponse } from "social/utils";
import { getAbbreviatedNumber } from "utils/AnalyticsUtils";
import { AuthenticationState } from "onboarding/OnboardingUtils";

const SOCIAL_API_BASE = `${API_URL}/social`;
export const YOUTUBE_PROFILE_URL = `${SOCIAL_API_BASE}/youtube_profile/`;
export const YOUTUBE_CALLBACK_URL = `${API_URL}/authentication/youtube/`;

export interface YoutubeProfileResponse {
  user?: string;
  url?: string;
  last_updated?: Date;
  date_created?: Date;
  custom_url?: string;
  num_subscribers?: number;
  num_videos?: number;
  view_count?: number;
  title?: string;
  avatar_url?: string;
  channel_id?: string;
  socialUsername?: string;
}

export interface YoutubeAudienceResponse {
  country?: AudienceEntryResponse[];
  gender?: AudienceEntryResponse[];
  age_bucket?: AudienceEntryResponse[];
}

export interface YoutubeInsightsResponse {
  audience: YoutubeAudienceResponse;
  stats: StatsEntryResponse[];
}

export const getYoutubeProfilesByKey = async (firebaseToken: string, key: string) => {
  const requestUrl = new URL(YOUTUBE_PROFILE_URL);
  requestUrl.searchParams.append("user", key);
  const request = createRequest({ url: requestUrl.toString(), firebaseToken });
  const response = await handleResult(request);
  return response;
};

export const getFirstYoutubeProfilesByKey = async (firebaseToken: string, key: string) => {
  const youtubeProfiles = await getYoutubeProfilesByKey(firebaseToken, key);
  return youtubeProfiles[0];
};

export const getYoutubeProfileInsights = async (youtubeUsername: string, firebaseToken: string) => {
  const requestUrl = new URL(`${YOUTUBE_PROFILE_URL}${youtubeUsername}/profile_stats/`);
  const request = createRequest({ url: requestUrl.toString(), firebaseToken });

  const response = await handleResult(request);
  return response;
};

export const YoutubeProfileSummary = ({ profile }: { profile: YoutubeProfileResponse }) => (
  <Box direction="column" margin={3}>
    <Spacer height={50} />
    <Flex flex="grow" justifyContent="center" gap={12}>
      <Datapoint
        title="Number of subscribers"
        value={getAbbreviatedNumber(profile.num_subscribers)}
      />
      <Datapoint title="Number of videos" value={getAbbreviatedNumber(profile.num_videos)} />
      <Datapoint title="View count" value={getAbbreviatedNumber(profile.view_count)} />
    </Flex>
  </Box>
);

export const getYoutubeLoginStatus = async (): Promise<AuthenticationState> => {
  const request = await createRequestWithFirebaseToken({
    url: YOUTUBE_CALLBACK_URL,
  });
  const response = await handleResult(request);
  return response.auth_status;
};

export const deleteYoutubeConnect = async (): Promise<void> => {
  const request = await createRequestWithFirebaseToken({
    url: YOUTUBE_CALLBACK_URL,
    method: "DELETE",
  });
  const response = await handleResult(request);
  return response;
};
