import React, { useState } from "react";

import { toShortDateString } from "utils/DateUtils";
import { User } from "firebase/auth";

import { approveLiveUrl, requestLiveUrlRevision } from "components/contracts/common/Api";

import Deliverable, { ContractDeliverableStatus } from "components/contracts/models/Deliverable";

import {
  showFailureNotification,
  showSuccessNotification,
} from "components/common/Notifications";

import {
  ContractStatus,
  PaymentStatus,
  SUPPORTED_FORMATS_TO_LABELS,
} from "components/contracts/common/Common";

import {
  ActionIcon,
  Button,
  Divider,
  Flex,
  Modal,
  Stack,
  Table,
  Text,
  Textarea,
  Tooltip,
} from "@mantine/core";

import { useDisclosure } from "@mantine/hooks";

import { IconCheck, IconExternalLink, IconX } from "@tabler/icons-react";

import ContractActions from "components/contracts/dashboard/ContractActions";
import ContractAmount from "components/contracts/dashboard/ContractAmount";
import CreatorAvatar from "components/contracts/common/CreatorAvatar";
import DeliverableProgress from "components/contracts/common/DeliverableProgress";
import SocialHandleButtons from "components/contracts/dashboard/SocialHandleButtons";

import { SEMI_BOLD_FONT_WEIGHT } from "components/contracts/dashboard/Constants";
import { SocialHandle } from "components/contracts/dashboard/Utils";

import { Payment } from "components/contracts/models/Payment";

function SubmissionDate({ deliverables }: { deliverables: Deliverable[] }) {
  const deliverablesSubmissionDates = deliverables
    .filter((deliverable) => deliverable.liveContentSubmissionDatetime)
    .map((deliverable) => deliverable.liveContentSubmissionDatetime.getTime());
  const maxSubmissionDate = new Date(Math.max(...deliverablesSubmissionDates));

  return <Text fw={SEMI_BOLD_FONT_WEIGHT}>{toShortDateString(maxSubmissionDate)}</Text>;
}

function LiveContentReviewButtons({
  contractId,
  deliverables,
  handleRefetchContract,
}: {
  contractId: string;
  deliverables: Deliverable[];
  handleRefetchContract: (contractId: string, handleFetched: () => void) => void;
}) {
  const filteredDeliverables = deliverables.filter(
    (deliverable) => deliverable.status === ContractDeliverableStatus.LIVE_CONTENT_SUBMITTED,
  );

  const [viewedContent, setViewedContent] = useState<Record<string, boolean>>(
    filteredDeliverables.reduce((acc, deliverable) => {
      acc[deliverable.id] = false;
      return acc;
    }, {} as Record<string, boolean>),
  );
  const [approveButtonLoading, setApproveButtonLoading] = useState<Record<string, boolean>>(
    filteredDeliverables.reduce((acc, deliverable) => {
      acc[deliverable.id] = false;
      return acc;
    }, {} as Record<string, boolean>),
  );
  const [rejectButtonLoading, setRejectButtonLoading] = useState<Record<string, boolean>>(
    filteredDeliverables.reduce((acc, deliverable) => {
      acc[deliverable.id] = false;
      return acc;
    }, {} as Record<string, boolean>),
  );

  const [opened, { open, close }] = useDisclosure();
  const [disputeReason, setDisputeReason] = useState<string>("");

  const rows = filteredDeliverables.map((deliverable, index) => {
    const setViewedContentForDeliverable = (value: boolean) => {
      setViewedContent((prevState) => {
        const newState = { ...prevState };
        newState[deliverable.id] = value;
        return newState;
      });
    };

    const setApproveButtonLoadingForDeliverable = (value: boolean) => {
      setApproveButtonLoading((prevState) => {
        const newState = { ...prevState };
        newState[deliverable.id] = value;
        return newState;
      });
    };

    const setRejectButtonLoadingForDeliverable = (value: boolean) => {
      setRejectButtonLoading((prevState) => {
        const newState = { ...prevState };
        newState[deliverable.id] = value;
        return newState;
      });
    };

    const formatLabel = SUPPORTED_FORMATS_TO_LABELS[deliverable.format];

    const handleApprove = () => {
      setApproveButtonLoadingForDeliverable(true);
      approveLiveUrl({ deliverableId: deliverable.id })
        .then((response) => {
          const { success, error } = response;

          if (success) {
            handleRefetchContract(contractId, () => {
              setViewedContentForDeliverable(false);
              setApproveButtonLoadingForDeliverable(false);
              showSuccessNotification({
                message: `Successfully approved ${deliverable.creatorHandle}'s ${formatLabel}!`,
              });
            });
          } else {
            setApproveButtonLoadingForDeliverable(false);
            showFailureNotification({ message: error });
          }
        })
        .catch(() => {
          setApproveButtonLoadingForDeliverable(false);
          showFailureNotification({ message: "Failed to approve live content." });
        });
    };

    const handleReject = () => {
      setRejectButtonLoadingForDeliverable(true);
      requestLiveUrlRevision({ deliverableId: deliverable.id, disputeReason })
        .then((response) => {
          const { success, error } = response;

          if (success) {
            handleRefetchContract(contractId, () => {
              setViewedContentForDeliverable(false);
              setRejectButtonLoadingForDeliverable(false);
              showSuccessNotification({
                message: `Requested a revision for ${deliverable.creatorHandle}'s ${formatLabel}.`,
              });
            });
          } else {
            setRejectButtonLoadingForDeliverable(false);
            showFailureNotification({ message: error });
          }
          close();
        })
        .catch(() => {
          setRejectButtonLoadingForDeliverable(false);
          showFailureNotification({ message: "Failed to request a revision." });
        });
    };

    return (
      <>
        <Modal
          opened={opened}
          size="lg"
          onClose={close}
          title={<Text fw="600">Describe the Issue</Text>}>
          <Stack>
            <Textarea
              minRows={3}
              autosize
              value={disputeReason}
              onChange={(event) => setDisputeReason(event.currentTarget.value)}
            />
            <Flex gap="sm" justify="right" align="center">
              <Button variant="default" color="gray" onClick={close}>
                Cancel
              </Button>
              <Button loading={rejectButtonLoading[deliverable.id]} onClick={handleReject}>
                Submit
              </Button>
            </Flex>
          </Stack>
        </Modal>
        <Stack key={`review-row-${deliverable.id}`} gap="xs">
          <Flex align="center" justify="right" gap="sm">
            <Button
              component="a"
              href={deliverable.liveContentUrl}
              target="_blank"
              size="compact-sm"
              variant="light"
              leftSection={<IconExternalLink size="1rem" />}
              onClick={() => setViewedContentForDeliverable(true)}>
              {formatLabel}
            </Button>
            <ActionIcon.Group>
              <Tooltip label="Reject Live Content" disabled={!viewedContent[deliverable.id]}>
                <ActionIcon
                  disabled={!viewedContent[deliverable.id]}
                  variant="light"
                  color="red"
                  loading={rejectButtonLoading[deliverable.id]}
                  onClick={open}>
                  <IconX />
                </ActionIcon>
              </Tooltip>
              <Tooltip label="Approve Live Content" disabled={!viewedContent[deliverable.id]}>
                <ActionIcon
                  disabled={!viewedContent[deliverable.id]}
                  variant="light"
                  color="teal"
                  loading={approveButtonLoading[deliverable.id]}
                  onClick={handleApprove}>
                  <IconCheck />
                </ActionIcon>
              </Tooltip>
            </ActionIcon.Group>
          </Flex>
          {index === filteredDeliverables.length - 1 ? null : <Divider />}
        </Stack>
      </>
    );
  });

  return <Stack gap="xs">{rows}</Stack>;
}

export default function LiveContentReviewRow({
  user,
  contractId,
  displayName,
  socialHandles,
  amountInMinorUnits,
  contractStatus,
  paymentStatus,
  bonusPaymentStatus,
  avatarUrl,
  readyForPayment,
  meetsBonusCondition,
  hasBonusCondition,
  deliverables,
  payments,
  stripeAccountUrl,
  closeUrl,
  handleRefetchContract,
}: {
  user: User;
  contractId: string;
  displayName: string;
  socialHandles: SocialHandle[];
  amountInMinorUnits: number;
  contractStatus: ContractStatus;
  paymentStatus: PaymentStatus;
  bonusPaymentStatus: PaymentStatus;
  avatarUrl: string;
  readyForPayment: boolean;
  meetsBonusCondition: boolean;
  hasBonusCondition: boolean;
  deliverables: Deliverable[];
  payments: Payment[];
  stripeAccountUrl: string;
  closeUrl: string;
  handleRefetchContract: (contractId: string, handleFetched: () => void) => void;
}) {
  return (
    <Table.Tr>
      <Table.Td>
        <CreatorAvatar displayName={displayName} avatarUrl={avatarUrl} />
      </Table.Td>
      <Table.Td>
        <SocialHandleButtons socialHandles={socialHandles} />
      </Table.Td>
      <Table.Td>
        <Flex justify="center">
          <SubmissionDate deliverables={deliverables} />
        </Flex>
      </Table.Td>
      <Table.Td>
        <Flex justify="center">
          <ContractAmount amountInMinorUnits={amountInMinorUnits} />
        </Flex>
      </Table.Td>
      <Table.Td>
        <DeliverableProgress deliverables={deliverables} />
      </Table.Td>
      <Table.Td>
        <LiveContentReviewButtons
          contractId={contractId}
          deliverables={deliverables}
          handleRefetchContract={handleRefetchContract}
        />
      </Table.Td>
      <Table.Td>
        <Flex justify="right">
          <ContractActions
            user={user} // TODO (victoria 3.2024): using for display name in ManualPaymentModal - need to get rid of this
            contractId={contractId}
            archived={false}
            contractStatus={contractStatus}
            paymentStatus={paymentStatus}
            bonusPaymentStatus={bonusPaymentStatus}
            readyForPayment={readyForPayment}
            meetsBonusCondition={meetsBonusCondition}
            hasBonusCondition={hasBonusCondition}
            displayName={displayName}
            payments={payments}
            stripeAccountUrl={stripeAccountUrl}
            closeUrl={closeUrl}
            handleRefetchContract={handleRefetchContract}
            hideDelete
          />
        </Flex>
      </Table.Td>
    </Table.Tr>
  );
}
