import { API_URL } from "configs/Configs";
import { createRequestWithFirebaseToken, handleResult } from "utils/ApiUtils";

const UPDATE_CREATOR_SET_URL = `${API_URL}/api/models/creator_set`;
const UPDATE_CAMPAIGN_PARAMS_URL = `${API_URL}/api/models/campaign_parameters_v2`;

// Higher-order function that accepts a URL and returns a new function
const createUpdateFunctionByUrl =
  (baseUrl: string) =>
  async (objId: number, updates: { [key: string]: any }, updateFields: string[]) => {
    // convert update to raw fields
    const idFields: { [key: string]: any } = { id: objId };
    const translatedUpdates = Object.entries(updates).reduce(
      (acc, [key, value]) => {
        if (updateFields.includes(key)) {
          acc[key] = value;
        }
        return acc;
      },
      { ...idFields },
    );
    const request = await createRequestWithFirebaseToken({
      url: `${baseUrl}/${objId}/`,
      method: "PATCH",
      body: JSON.stringify(translatedUpdates),
    });

    const response = await handleResult(request);
    return response;
  };

// Usage example
export const updateCreatorSetById = createUpdateFunctionByUrl(UPDATE_CREATOR_SET_URL);
export const updateCampaignParamsById = createUpdateFunctionByUrl(UPDATE_CAMPAIGN_PARAMS_URL);
