import { getAuth } from "firebase/auth";
import { handleResult } from "utils/ApiUtils";

import { API_URL } from "configs/Configs";

export const GET_HASHTAG_USERS_URL = `${API_URL}/api/ops/debug/hashtag_users`;
export const GET_HASHTAG_VIDEOS_URL = `${API_URL}/api/ops/debug/hashtag_videos`;
export const GET_ALL_HASHTAG_VIDEOS_URL = `${API_URL}/api/ops/debug/all_hashtag_videos`;
export const GET_LOCKET_HASHTAG_VIDEOS_URL = `${API_URL}/api/ops/debug/locket_hashtag_videos`;

export const getHashtagVideos = async (
  hashtag: string,
  limit: number,
  setVideoIds: (videoIds: string[]) => void,
  abortController: AbortController,
) => {
  const firebaseToken = await getAuth().currentUser?.getIdToken();
  const requestUrl = new URL(`${GET_HASHTAG_VIDEOS_URL}/`);
  requestUrl.searchParams.append("hashtag", hashtag);
  requestUrl.searchParams.append("limit", limit.toString());
  const request = new Request(requestUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
  });
  const response = await handleResult(request, abortController);
  if (response && response?.results) {
    setVideoIds(response.results);
  }
  return response;
};


export const getHashtagVideosFromEndpoint = async (
  hashtag: string,
  endpoint: string,
  limit: number,
  setVideoIds: (videoIds: string[]) => void,
  abortController: AbortController,
) => {
  const firebaseToken = await getAuth().currentUser?.getIdToken();
  const requestUrl = new URL(endpoint);
  requestUrl.searchParams.append("hashtag", hashtag);
  requestUrl.searchParams.append("limit", limit.toString());
  const request = new Request(requestUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
  });
  const response = await handleResult(request, abortController);
  if (response && response?.results) {
    setVideoIds(response.results);
  }
  return response;
};

export const getRecommendedHashtags = async (
  hashtag: string,
  abortController: AbortController,
) => {
  const firebaseToken = await getAuth().currentUser?.getIdToken();
  const requestUrl = new URL(`${API_URL}/api/ops/debug/hashtag_recommendations`);
  requestUrl.searchParams.append("hashtag", hashtag);

  const request = new Request(requestUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
  });
  const response = await handleResult(request, abortController);
  return response;
}

export const getHashtagUsers = async (
  hashtag: string,
  limit: number,
  abortController: AbortController,  
) => {
  const firebaseToken = await getAuth().currentUser?.getIdToken();
  const requestUrl = new URL(`${GET_HASHTAG_USERS_URL}/`);

  requestUrl.searchParams.append("hashtag", hashtag);
  requestUrl.searchParams.append("limit", limit.toString());

  const request = new Request(requestUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
  });

  const response = await handleResult(request, abortController);
  return response;
}

export default null;
