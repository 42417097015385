import { API_URL } from "configs/Configs";
import { User } from "models/User";
import { createRequestWithFirebaseToken, handleResult } from "utils/ApiUtils";

const USER_API_URL = `${API_URL}/api/models/users/`;

export const acceptTOS = async (userKey: string): Promise<User> => {
  const requestUrl = `${USER_API_URL}${userKey}/`;
  const updates = {
    key: userKey,
    accepted_brand_tos: true,
  };

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "PATCH",
    body: JSON.stringify(updates),
  });
  const result = await handleResult(request);
  return result;
};

export default null;
