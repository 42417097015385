import { ContentStatus } from "components/contracts/common/Common";
import { ContractDeliverableStatus } from "components/contracts/models/Deliverable";

export default class ReviewTimelineData {
  signatureDatetime: Date;

  liveDate: Date;

  liveContentSubmissionDate: Date;

  liveContentReviewDeadline: Date;

  usageRightsEndDate: Date;

  scriptDueDate: Date;

  scriptSubmissionDate: Date;

  scriptReviewDate: Date;

  scriptReviewStatus: ContentStatus;

  videoDueDate: Date;

  videoSubmissionDate: Date;

  videoReviewDate: Date;

  videoReviewStatus: ContentStatus;

  deliverableStatus: ContractDeliverableStatus;

  constructor({
    signatureDatetime,
    liveDate,
    liveContentSubmissionDate,
    liveContentReviewDeadline,
    usageRightsEndDate,
    scriptDueDate,
    scriptSubmissionDate,
    scriptReviewDate,
    scriptReviewStatus,
    videoDueDate,
    videoSubmissionDate,
    videoReviewDate,
    videoReviewStatus,
    deliverableStatus,
  }: {
    signatureDatetime: Date;
    liveDate: Date;
    liveContentSubmissionDate: Date;
    liveContentReviewDeadline: Date;
    usageRightsEndDate: Date;
    scriptDueDate: Date;
    scriptSubmissionDate: Date;
    scriptReviewDate: Date;
    scriptReviewStatus: ContentStatus;
    videoDueDate: Date;
    videoSubmissionDate: Date;
    videoReviewDate: Date;
    videoReviewStatus: ContentStatus;
    deliverableStatus: ContractDeliverableStatus;
  }) {
    this.signatureDatetime = signatureDatetime;
    this.liveDate = liveDate;
    this.liveContentSubmissionDate = liveContentSubmissionDate;
    this.liveContentReviewDeadline = liveContentReviewDeadline;
    this.usageRightsEndDate = usageRightsEndDate;
    this.scriptDueDate = scriptDueDate;
    this.scriptSubmissionDate = scriptSubmissionDate;
    this.scriptReviewDate = scriptReviewDate;
    this.scriptReviewStatus = scriptReviewStatus;
    this.videoDueDate = videoDueDate;
    this.videoSubmissionDate = videoSubmissionDate;
    this.videoReviewDate = videoReviewDate;
    this.videoReviewStatus = videoReviewStatus;
    this.deliverableStatus = deliverableStatus;
  }
}
