import { fromISODateString } from "utils/DateUtils";

export enum MessageParty {
  BRAND = 1,
  CREATOR = 2,
  PLATFORM = 3,
  AUTO = 4,
}

export default class Message {
  hashId: string;

  deliverableId: string;

  videoId: string;

  dateCreated: Date;

  senderDisplayName: string;

  senderType: MessageParty;

  recipientDisplayName: string;

  recipientType: MessageParty;

  text: string;

  isUnread?: boolean;

  constructor({
    hashId,
    deliverableId,
    videoId,
    dateCreated,
    senderDisplayName,
    senderType,
    recipientDisplayName,
    recipientType,
    text,
  }: {
    hashId: string;
    deliverableId: string;
    videoId: string;
    dateCreated: Date;
    senderDisplayName: string;
    senderType: MessageParty;
    recipientDisplayName: string;
    recipientType: MessageParty;
    text: string;
  }) {
    this.hashId = hashId;
    this.deliverableId = deliverableId;
    this.videoId = videoId;
    this.dateCreated = dateCreated;
    this.senderDisplayName = senderDisplayName;
    this.senderType = senderType;
    this.recipientDisplayName = recipientDisplayName;
    this.recipientType = recipientType;
    this.text = text;
  }

  public static deserialize(response: any) {
    const {
      hashId,
      deliverableId,
      videoId,
      dateCreated,
      senderDisplayName,
      senderType,
      recipientDisplayName,
      recipientType,
      text,
    } = response;

    return new Message({
      hashId,
      deliverableId,
      videoId,
      dateCreated: fromISODateString(dateCreated),
      senderDisplayName,
      senderType,
      recipientDisplayName,
      recipientType,
      text,
    });
  }

  public markUnread() {
    this.isUnread = true;
  }

  public markRead() {
    this.isUnread = false;
  }
}
