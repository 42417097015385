import React, { useState, useCallback } from "react";
import {
  Button,
  Flex,
  MantineColor,
  Modal,
  Text,
  TextInput,
  Checkbox,
  Stack,
  Group,
  Title,
  Radio,
  Select,
  Textarea,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { Icon, IconProps, IconCheck, IconPlus, IconX, IconTrash } from "@tabler/icons-react";
import { User as FirebaseUser, User } from "firebase/auth";
import {
  CreatorSetCreatorProps,
  CreatorSetInternalReviewProps,
  setInternalReviewCreatorSetState,
  updateCreatorSetMetadata,
  updateReviewCreatorSetEntry,
} from "components/creator_sets/CreatorSetUtils";
import { CreatorSetEntryState, CreatorSet } from "components/discovery/Datamodels";
import { debounce } from "lodash";
import Spacer from "components/Spacer";
import { notifications } from "@mantine/notifications";

export const ActionButton = ({
  color,
  IconName,
  onClick,
  text,
  disabled = false,
}: {
  color: MantineColor;
  IconName: React.ForwardRefExoticComponent<Omit<IconProps, "ref"> & React.RefAttributes<Icon>>;
  onClick: () => void;
  text: string;
  disabled?: boolean;
}) => (
  <Button
    disabled={disabled}
    variant="outline"
    size="sm"
    radius="xl"
    color={color}
    onClick={onClick}
    leftSection={<IconName size={24} />}>
    {text}
  </Button>
);

const ExistingPartnerCheckbox = ({
  status,
  setStatus,
  onChange,
}: {
  status: boolean;
  setStatus: (status: boolean) => void;
  onChange: (status: boolean) => void;
}) => (
  <Checkbox
    checked={status}
    variant="outline"
    size="sm"
    radius="xl"
    color="gray"
    label="Existing Partner"
    onChange={() => {
      const newStatus = !status;
      onChange(newStatus);
      setStatus(newStatus);
    }}
  />
);

export const CreatorSetReviewActionRows = ({
  creatorSetId,
  creatorId,
  initialNotes,
  onCreatorStateChanged,
  isStaff,
}: {
  creatorSetId: number;
  creatorId: number;
  initialNotes: string | null;
  onCreatorStateChanged: (
    creatorId: number,
    entryState: CreatorSetEntryState,
    notes: string | null,
  ) => void;
  isStaff: boolean;
}) => {
  const [notes, setNotes] = useState<string>(initialNotes || "");
  // NOTE(kevin): Disable this as worked_with is tracked as a state for now instead
  // of metadata because we want to treat it separately from the other states
  // const [workedWithStatus, setWorkedWithStatus] = useState<boolean>(isExistingPartner);
  const [opened, { open, close }] = useDisclosure(false);

  const isFeedbackTooLong = notes.length > 5000;

  const submitNotes = useCallback(
    debounce((value: string) => {
      if (isFeedbackTooLong) {
        open();
        return;
      }
      updateCreatorSetMetadata({
        creatorId,
        creatorSetId,
        notes: value,
      });
    }, 1000),
    [],
  ); // Adjust the delay as needed

  const submitCreatorSetState = (entryState: CreatorSetEntryState) => {
    updateReviewCreatorSetEntry({
      creatorId,
      creatorSetId,
      entryState,
    }).then((r) => {
      if (r && r.success) {
        onCreatorStateChanged(creatorId, entryState, notes);
        notifications.show({
          color: "Blue",
          title: "Successfully Updated",
          message: r.message,
        });
      } else if (r && r.message) {
        notifications.show({
          color: "red",
          title: "Update Unsuccessful",
          message: r.message,
        });
      } else {
        notifications.show({
          color: "red",
          title: "Update Unsuccessful",
          message: "An error occurred while updating the creator set entry",
        });
      }
    });
  };

  return (
    <Flex
      align="center"
      h="100%"
      pr="var(--mantine-spacing-lg)"
      pl="var(--mantine-spacing-lg)"
      justify="space-between"
      gap="8px"
      style={{
        overflow: "scroll",
      }}>
      <TextInput
        radius="xl"
        size="sm"
        style={{ flexGrow: 1 }}
        placeholder="Optional feedback"
        value={notes}
        onChange={(event) => {
          setNotes(event.currentTarget.value);
          submitNotes(event.currentTarget.value);
        }}
        rightSection={
          <Text size="xs" ta="end" pr="4px" c={isFeedbackTooLong ? "red" : undefined}>
            {notes.length}/5000
          </Text>
        }
        error={isFeedbackTooLong}
        rightSectionWidth="67px"
        miw="200px"
        styles={{
          section: {
            justifyContent: "flex-end",
          },
        }}
      />
      <Modal opened={opened} onClose={close} title="Error">
        Feedback is limited to 5000 characters.
      </Modal>
      <ActionButton
        onClick={() => submitCreatorSetState(CreatorSetEntryState.WORKED_WITH)}
        color="gray"
        text="Existing Partner"
        IconName={IconPlus}
      />
      <ActionButton
        onClick={() => submitCreatorSetState(CreatorSetEntryState.REJECTED)}
        color="red"
        text="Reject"
        IconName={IconX}
      />
      <ActionButton
        onClick={() => submitCreatorSetState(CreatorSetEntryState.ACCEPTED)}
        color="green"
        text="Activate"
        IconName={IconCheck}
      />
      {isStaff ? (
        <ActionButton
          onClick={() => submitCreatorSetState(CreatorSetEntryState.ADMIN_REMOVED)}
          color="dark"
          text="Remove (admin only)"
          IconName={IconTrash}
        />
      ) : null}
    </Flex>
  );
};

const InternalReviewRadioGroupScale = ({
  creatorId,
  user,
  label,
  leftLabel,
  rightLabel,
  id,
  keyName,
  externKeyValue,
  creatorSetId,
}: {
  creatorId: number;
  user: User;
  label: string;
  leftLabel: string;
  rightLabel: string;
  id: string;
  keyName: "brandSafety" | "qualityScore";
  externKeyValue: number;
  creatorSetId: number;
}) => {
  const safeExternKeyValue = externKeyValue ?? 0;
  const [internalVal, setInternalVal] = useState(safeExternKeyValue);

  const handleChange = (value: number) => {
    const setCampaignParams = {
      creatorId,
      [keyName]: value,
      originCreatorSetId: creatorSetId,
      targetCreatorSetId: creatorSetId,
    };

    setInternalReviewCreatorSetState(setCampaignParams);
    setInternalVal(value);
  };

  return (
    <>
      <Title order={5}>{label}</Title>
      <Stack style={{ border: "1px solid #ddd", borderRadius: "8px", padding: "6px" }}>
        <Group
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}>
          <Text>{leftLabel}</Text>
          <Text>{rightLabel}</Text>
        </Group>
        <Radio.Group
          id={`${id}-${creatorId}`}
          value={internalVal.toString()}
          onChange={(value) => handleChange(Number(value))}>
          <Group>
            {[1, 2, 3, 4, 5].map((val) => (
              <Radio
                key={val}
                value={val.toString()}
                label={val.toString()}
                checked={internalVal === val}
              />
            ))}
          </Group>
        </Radio.Group>
      </Stack>
    </>
  );
};

const CreatorSetInternalReviewSelector = ({
  user,
  creatorId,
  creatorSetId,
  availableCreatorSets,
}: {
  user: User;
  creatorId: number;
  creatorSetId: number;
  availableCreatorSets: CreatorSet[];
}) => {
  const [targetCreatorSetId, setTargetCreatorSetId] = useState(creatorSetId);
  const [internalReviewState, setInternalReviewState] = useState(0);

  const handleButtonClick = (value: number) => {
    const setInternalReviewParams = {
      creatorId,
      originCreatorSetId: creatorSetId,
      targetCreatorSetId,
      internalReviewState: value,
    };

    setInternalReviewCreatorSetState(setInternalReviewParams).then((response) => {
      const reviewStateStr = value === 2 ? "Activated" : "Rejected";
      if (response != null && response?.status === "403") {
        notifications.show({
          color: "red",
          title: "Could not update - Permissions issue",
          message: `Internal Review State update failed - no permissions`,
        });
      } else if (response == null || !response.success) {
        notifications.show({
          color: "red",
          title: "Could not update",
          message: `Internal Review State update failed`,
        });
      } else {
        setInternalReviewState(value);
        notifications.show({
          title: "Update success",
          message: `Internal Review State updated to ${reviewStateStr}`,
        });
      }
    });
  };

  const handleCreatorSetChange = (value: string) => {
    const convertedCreatorSetId = Number(value);
    setTargetCreatorSetId(convertedCreatorSetId);
    if (internalReviewState === 2) {
      const setInternalReviewParams = {
        creatorId,
        originCreatorSetId: creatorSetId,
        targetCreatorSetId: convertedCreatorSetId,
        internalReviewState,
      };
      setInternalReviewCreatorSetState(setInternalReviewParams);
    }
  };

  return (
    <>
      <Title order={5}>Creator Set</Title>
      <Stack style={{ border: "1px solid #ddd", borderRadius: "8px", padding: "6px" }}>
        <Select
          id=""
          label="Creator Set"
          value={String(targetCreatorSetId)}
          onChange={handleCreatorSetChange}
          data={availableCreatorSets.map(({ id, name }) => ({
            label: name,
            value: id.toString(),
          }))}
        />

        <Button.Group>
          <Button
            variant={internalReviewState === 2 ? "filled" : "default"}
            color="blue"
            onClick={() => handleButtonClick(2)}>
            Accept
          </Button>
          <Spacer width={3} />
          <Button
            variant={internalReviewState === 3 ? "filled" : "default"}
            color="red"
            onClick={() => handleButtonClick(3)}>
            Reject
          </Button>
        </Button.Group>
      </Stack>
    </>
  );
};

const InternalReviewBoolCheckbox = ({
  user,
  creatorId,
  idVal,
  idLabel,
  keyName,
  externKeyValue,
  creatorSetId,
}: {
  user: User;
  creatorId: number;
  idVal: string;
  idLabel: string;
  keyName: "isEnglish" | "creatorInVideo" | "isCreator";
  externKeyValue: boolean;
  creatorSetId: number;
}) => {
  const [internalVal, setInternalVal] = useState(externKeyValue);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const boolVal = event.currentTarget.checked;

    const setInternalReviewParams = {
      creatorId,
      originCreatorSetId: creatorSetId,
      targetCreatorSetId: creatorSetId,
      [keyName]: boolVal,
    };

    setInternalReviewCreatorSetState(setInternalReviewParams);
    setInternalVal(boolVal);
  };

  return <Checkbox id={idVal} label={idLabel} checked={internalVal} onChange={handleChange} />;
};

export const InternalReviewCreatorSetActionRow = ({
  creatorSetId,
  creatorId,
  availalbleCreatorSets,
  user,
  internalReviewProp,
  creatorProp,
  isInternalReview,
  isBrandReview,
}: {
  creatorSetId: number;
  creatorId: number;
  availalbleCreatorSets: CreatorSet[];
  user: FirebaseUser;
  internalReviewProp: CreatorSetInternalReviewProps;
  creatorProp: CreatorSetCreatorProps;
  isInternalReview: boolean;
  isBrandReview: boolean;
}) => {
  const [internalFeedback, setInternalFeedback] = useState<string>(
    internalReviewProp?.internal_feedback || "",
  );
  const [showSavedFeedback, setShowSavedFeedback] = useState<boolean>(false);

  const submitInternalFeedback = useCallback(
    debounce((value: string) => {
      setInternalReviewCreatorSetState({
        creatorId,
        originCreatorSetId: creatorSetId,
        targetCreatorSetId: creatorSetId,
        internalFeedback: value,
      }).then((response) => {
        if (response == null || !response.success) {
          notifications.show({
            color: "red",
            title: "Could not update - Permissions issue",
            message: `Internal Feedback update failed - permissions issue`,
          });
        } else {
          setShowSavedFeedback(true);
        }
      });
      // Hide the feedback saved message after 3 seconds
      setTimeout(() => {
        setShowSavedFeedback(false);
      }, 3000);
    }, 1000),
    [],
  ); // Adjust the delay as needed

  const setInternalFeedbackChange = (value: string) => {
    setInternalFeedback(value);
    submitInternalFeedback(value);
  };

  return (
    <Stack>
      <Group align="flex-start">
        {isInternalReview && (
          <Stack style={{ width: 300 }} gap="xs">
            <CreatorSetInternalReviewSelector
              user={user}
              creatorId={creatorId}
              creatorSetId={creatorSetId}
              availableCreatorSets={availalbleCreatorSets}
            />
            <InternalReviewRadioGroupScale
              creatorId={creatorId}
              user={user}
              keyName="brandSafety"
              creatorSetId={creatorSetId}
              externKeyValue={creatorProp?.brand_safety_score}
              label="Brand Safety"
              leftLabel="Not Safe"
              rightLabel="Very Safe"
              id="brand-safety"
            />
            <InternalReviewRadioGroupScale
              creatorId={creatorId}
              user={user}
              keyName="qualityScore"
              creatorSetId={creatorSetId}
              externKeyValue={creatorProp?.quality_score}
              label="Quality Score"
              leftLabel="Low Quality"
              rightLabel="High Quality"
              id="quality-score"
            />
          </Stack>
        )}
        <Stack style={{ width: 300 }} gap="xs">
          {isInternalReview && (
            <>
              <Title order={4}>Must Haves</Title>
              <Group>
                <InternalReviewBoolCheckbox
                  creatorId={creatorId}
                  user={user}
                  creatorSetId={creatorSetId}
                  keyName="isEnglish"
                  idVal="is_english"
                  idLabel="English"
                  externKeyValue={creatorProp?.is_english}
                />
                <InternalReviewBoolCheckbox
                  creatorId={creatorId}
                  user={user}
                  creatorSetId={creatorSetId}
                  keyName="isCreator"
                  idVal="is_creator"
                  idLabel="Creator"
                  externKeyValue={creatorProp?.is_creator}
                />
                <InternalReviewBoolCheckbox
                  creatorId={creatorId}
                  user={user}
                  creatorSetId={creatorSetId}
                  keyName="creatorInVideo"
                  idVal="creator_in_video"
                  idLabel="In Video"
                  externKeyValue={creatorProp?.creator_in_videos}
                />
              </Group>
            </>
          )}
          <Textarea
            id={`feedback-${creatorId}`}
            label="Internal Feedback"
            placeholder="Internal Feedback"
            value={internalFeedback}
            onChange={(event) => {
              setInternalFeedbackChange(event.currentTarget.value);
            }}
          />
          {isBrandReview && (
            <Textarea
              id={`brand-feedback-${creatorId}`}
              label="Brand Feedback"
              readOnly
              value={internalReviewProp?.brand_notes || ""}
            />
          )}
          {showSavedFeedback && <Text c="green">Feedback saved</Text>}
          <Text>Ops reviewer: {internalReviewProp?.internal_review_enqueuer}</Text>
          <Text>Platform sourced: {internalReviewProp?.internal_review_platform}</Text>
          <Text>
            Ops review date:{" "}
            {internalReviewProp?.internal_review_enqueue_ts
              ? new Date(internalReviewProp?.internal_review_enqueue_ts).toLocaleDateString("en-CA")
              : "N/A"}
          </Text>
        </Stack>
      </Group>
    </Stack>
  );
};
