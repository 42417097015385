import React from "react";
import { Button, Flex, Text } from "@mantine/core";
import { Icon, IconProps, IconChevronDown, IconChevronUp } from "@tabler/icons-react";

export const BasicTodoNoDetails = ({
  title,
  description,
  onClick,
  onClickText,
}: {
  title: string;
  description: string;
  onClick: () => void;
  onClickText: string;
}) => (
  <Flex w="100%" justify="space-between" align="center">
    <Flex justify="flex-start" align="flex-start">
      <Text
        lineClamp={1}
        style={{
          fontSize: "14px",
          fontWeight: "700",
          lineHeight: "155%",
        }}>
        {title}:&nbsp;
      </Text>
      <Text
        lineClamp={1}
        style={{
          fontSize: "14px",
          fontWeight: "400",
          lineHeight: "155%",
        }}>
        {description}
      </Text>
    </Flex>
    <Button
      variant="transparent"
      styles={{
        label: {
          fontSize: "14px",
          fontWeight: "400",
        },
      }}
      onClick={onClick}>
      {onClickText}
    </Button>
  </Flex>
);

export const BasicTodoWithDetails = ({
  title,
  description,
  showDetails,
  onShowDetailsToggled,
}: {
  title: string;
  description: string;
  showDetails: boolean;
  onShowDetailsToggled: (newValue: boolean) => void;
}) => {
  const IconName: React.ForwardRefExoticComponent<
    Omit<IconProps, "ref"> & React.RefAttributes<Icon>
  > = showDetails ? IconChevronUp : IconChevronDown;
  return (
    <Flex w="100%" justify="space-between" align="center">
      <Flex justify="flex-start" align="flex-start">
        <Text
          lineClamp={1}
          style={{
            fontSize: "14px",
            fontWeight: "700",
            lineHeight: "155%",
          }}>
          {title}:&nbsp;
        </Text>
        <Text
          lineClamp={1}
          style={{
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "155%",
          }}>
          {description}
        </Text>
      </Flex>
      <Button
        variant="transparent"
        rightSection={<IconName size={12} />}
        styles={{
          label: {
            fontSize: "14px",
            fontWeight: "400",
          },
        }}
        onClick={() => onShowDetailsToggled(!showDetails)}>
        {showDetails ? "Hide details" : "See details"}
      </Button>
    </Flex>
  );
};
