import React from "react";

import { Anchor, Badge, Table } from "@mantine/core";

import { Campaign, CampaignStatus } from "models/Campaign";

export const OpsUserOwnedCampaignTableRow = ({ campaign }: { campaign: Campaign }) => {
  function getColorFromStatus(status: CampaignStatus): string {
    switch (status) {
      case CampaignStatus.Draft:
        return "yellow";
      case CampaignStatus.Onboarding:
        return "yellow";
      case CampaignStatus.Active:
        return "green";
      case CampaignStatus.Paused:
        return "orange";
      case CampaignStatus.Completed:
        return "blue";
      default:
        return "gray";
    }
  }
  return (
    <Table.Tr>
      <Table.Td>
        <Anchor
          size="sm"
          href={`/admin/brands?brandId=${campaign.brand?.id}&navItem=campaigns&campaignId=${campaign.id}`}>
          {campaign.title}
        </Anchor>
      </Table.Td>
      <Table.Td>
        <Anchor size="sm" href={`/admin/brands?brandId=${campaign.brand?.id}`}>
          {campaign.brand.brand_name}
        </Anchor>
      </Table.Td>
      <Table.Td>
        <Badge color={getColorFromStatus(campaign.status)}>{CampaignStatus[campaign.status]}</Badge>
      </Table.Td>
    </Table.Tr>
  );
};

export default OpsUserOwnedCampaignTableRow;
