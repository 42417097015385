import { User as FirebaseUser } from "firebase/auth";

import { API_URL } from "configs/Configs";

import { createRequestWithFirebaseToken, handleResult } from "utils/ApiUtils";
import { CreatorSet } from "components/discovery/Datamodels";

export const CREATOR_SET_URL = `${API_URL}/api/models/creator_set`;
export const ADGROUP_URL = `${API_URL}/api/models/campaign_ad_group`;

export interface CreatorSetResponse {
  results: CreatorSet[];
  count: number;
  next?: number | null;
  previous?: number | null;
}

export const fetchAllCreatorSets = async (
  user: FirebaseUser,
  setCreatorSets: (creatorSets: CreatorSet[]) => void,
  abortController: AbortController,
): Promise<CreatorSetResponse> => {
  // NOTE: this will only work for admins
  const firebaseToken = await user.getIdToken();

  const request = new Request(`${CREATOR_SET_URL}/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
  });

  const response = await handleResult(request, abortController);
  if (response && !(response?.ok === false) && response?.results) {
    setCreatorSets(response?.results);
  }
  return response;
};

export const fetchCreatorSetById = async (
  creatorSetId: number,
  abortController: AbortController,
): Promise<CreatorSet | null> => {
  const requestUrl = new URL(`${CREATOR_SET_URL}/${creatorSetId}/`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
  });

  const response = await handleResult(request, abortController);
  if (response && !(response?.ok === false)) {
    return response;
  }
  return null;
};

export const fetchCreatorSetsByBrandId = async (
  brandId: number,
  abortController: AbortController,
): Promise<CreatorSetResponse> => {
  const requestUrl = new URL(`${CREATOR_SET_URL}/`);
  requestUrl.searchParams.append("brandId", brandId.toString());
  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
  });

  const response: CreatorSetResponse | null = await handleResult(request, abortController);
  return response;
};

export const createCreatorSetByBrandId = async (
  user: FirebaseUser,
  brandId: number,
  creatorSetName: string,
  // ehhh not sure if this is necessary
  abortController: AbortController = new AbortController(),
): Promise<CreatorSet> => {
  const firebaseToken = await user.getIdToken();

  const requestUrl = new URL(`${CREATOR_SET_URL}/`);

  // Prepare the data to be sent in the request body
  const requestBody = {
    brandId: brandId.toString(),
    name: creatorSetName,
  };

  const request = new Request(requestUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
    body: JSON.stringify(requestBody),
    signal: abortController.signal,
  });

  const response = await handleResult(request, abortController);
  return response;
};

export default null;
