import { ContentType } from "components/contracts/common/Common";
import Deliverable from "components/contracts/models/Deliverable";
import VersionedDeliverableVideo from "components/contracts/models/VersionedDeliverableVideo";
import VersionedScript from "components/contracts/models/VersionedScript";

export default class DeliverableWithContent {
  deliverable: Deliverable;

  versionedDeliverableVideo: VersionedDeliverableVideo;

  versionedScript: VersionedScript;

  numUnreadMessages: number;

  pendingContentType?: ContentType;

  constructor({
    deliverable,
    versionedDeliverableVideo,
    versionedScript,
    numUnreadMessages,
    pendingContentType,
  }: {
    deliverable: Deliverable;
    versionedDeliverableVideo: VersionedDeliverableVideo;
    versionedScript: VersionedScript;
    numUnreadMessages: number;
    pendingContentType?: ContentType;
  }) {
    this.deliverable = deliverable;
    this.versionedDeliverableVideo = versionedDeliverableVideo;
    this.versionedScript = versionedScript;
    this.numUnreadMessages = numUnreadMessages;
    this.pendingContentType = pendingContentType;
  }

  public static deserialize(response: any) {
    const { deliverable, versionedDeliverableVideo, versionedScript, numUnreadMessages } = response;

    const deserializedVersionedDeliverableVideo =
      VersionedDeliverableVideo.deserialize(versionedDeliverableVideo);
    const deserializedVersionedScript = VersionedScript.deserialize(versionedScript);

    let pendingContentType = null;
    if (deserializedVersionedDeliverableVideo?.deliverableVideos.length > 0) {
      pendingContentType = ContentType.VIDEO;
    } else if (deserializedVersionedScript?.scripts.length > 0) {
      pendingContentType = ContentType.SCRIPT;
    } else {
      pendingContentType = ContentType.LIVE_CONTENT;
    }

    return new DeliverableWithContent({
      deliverable: Deliverable.deserialize(deliverable),
      versionedDeliverableVideo: deserializedVersionedDeliverableVideo,
      versionedScript: deserializedVersionedScript,
      numUnreadMessages,
      pendingContentType,
    });
  }
}
