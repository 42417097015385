import { Button, PasswordInput, Select, Stack, TextInput } from "@mantine/core";
import { isEmail, isNotEmpty, useForm } from "@mantine/form";
import CustomTagsInput from "admin/CustomTagsInput";
import CardBase from "campaigns/create/common/CardBase";
import { Brand } from "models/Brand";
import React, { useEffect } from "react";
import { useAppDispatch } from "reduxStore/hooks";
import { updateMyBrand } from "reduxStore/meSlice";

enum EmailProvider {
  GOOGLE = 1,
  MICROSOFT = 2,
}

const EXISTING_PASSWORD_PLACEHOLDER = "********";

const BrandContact = ({ brand }: { brand: Brand }) => {
  const dispatch = useAppDispatch();
  const form = useForm({
    mode: "uncontrolled",
    initialValues: {
      mainContact: brand.contact_email,
      billingEmail: brand.billing_email,
      memberEmails: brand.member_emails ?? [],
      collabEmail: brand.outreach_email_address ?? "",
      collabEmailPassword:
        brand.outreach_email_configured || brand.outreach_email_password_set
          ? EXISTING_PASSWORD_PLACEHOLDER
          : "",
      collabEmailProvider: brand.outreach_email_provider?.toString(),
    },

    validate: {
      mainContact: isEmail("Please enter a valid email address"),
      billingEmail: isEmail("Please enter a valid email address"),
      memberEmails: (value, values) => {
        const validEmails = value.every((email) => {
          const emailRegex = /^[a-zA-Z0-9._%+-]+@(?<domain>[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
          const emailMatch = emailRegex.exec(email);

          // first check if email is in email form
          if (emailMatch === null) {
            return false;
          }

          // then check if email matches domain of main contact domain
          const mainContactEmailMatch = emailRegex.exec(values.mainContact);
          if (
            mainContactEmailMatch !== null &&
            mainContactEmailMatch.groups !== null &&
            mainContactEmailMatch.groups.domain !== null
          ) {
            return emailMatch.groups.domain === mainContactEmailMatch.groups.domain;
          }
          return false;
        });
        return validEmails
          ? null
          : "Please verify every email is valid and has the same domain as the main contact email.";
      },
      collabEmail: isNotEmpty("Please enter an email address"),
      collabEmailPassword: (value) => {
        if (!value) {
          return "Please enter an email password";
        }
        if (value === EXISTING_PASSWORD_PLACEHOLDER && form.isDirty("collabEmailPassword")) {
          return "Please enter a valid password";
        }
        return null;
      },
      collabEmailProvider: (value) => {
        // 0 === UNKNOWN - force them to select an email provider
        if (value === "0" || !value) {
          return "Please select an email provider";
        }
        return null;
      },
    },
  });

  useEffect(() => {
    form.setInitialValues({
      mainContact: brand.contact_email,
      billingEmail: brand.billing_email,
      memberEmails: brand.member_emails ?? [],
      collabEmail: brand.outreach_email_address ?? "",
      collabEmailPassword: brand.outreach_email_configured ? EXISTING_PASSWORD_PLACEHOLDER : "",
      collabEmailProvider: brand.outreach_email_provider?.toString(),
    });
    form.resetDirty();
  }, [brand]);

  return (
    <CardBase
      isActive
      nodeShownWhenActive={
        <form
          onSubmit={form.onSubmit((values) => {
            console.log(form.isDirty("collabEmailPassword"));
            dispatch(
              updateMyBrand({
                updatedFields: {
                  ...(form.isDirty("mainContact") && { contact_email: values.mainContact }),
                  ...(form.isDirty("billingEmail") && { billing_email: values.billingEmail }),
                  ...(form.isDirty("memberEmails") && { member_emails: values.memberEmails }),
                  ...(form.isDirty("collabEmail") && {
                    outreach_email_address: values.collabEmail,
                  }),
                  ...(form.isDirty("collabEmailPassword") && {
                    outreach_email_password: values.collabEmailPassword,
                  }),
                  ...(form.isDirty("collabEmailProvider") && {
                    outreach_email_provider: Number(values.collabEmailProvider),
                  }),
                },
              }),
            );
          })}>
          <Stack px={0} style={{ "--stack-gap": "24px" }}>
            <TextInput
              withAsterisk
              label="Main Contact"
              description="The main point of contact for the brand. This is the user who will be responsible for executing the campaign and will get notifications related to campaign progress and todo items."
              placeholder="Email address"
              key={form.key("mainContact")}
              {...form.getInputProps("mainContact")}
            />
            <TextInput
              withAsterisk
              label="Billing Email"
              description="This is the email address invoices will be sent to."
              placeholder="Email address"
              key={form.key("billingEmail")}
              {...form.getInputProps("billingEmail")}
            />
            <TextInput
              withAsterisk
              label="1stCollab Email Address"
              placeholder="1stcollab@yourbrand.com"
              description="This is the email address we’ll use to outreach on behalf of your brand. We suggest your email address begins with 1stcollab@. The email must be registered to your brand’s domain."
              key={form.key("collabEmail")}
              {...form.getInputProps("collabEmail")}
            />
            <PasswordInput
              withAsterisk
              label="1stCollab Email Password"
              description="The password for your 1stCollab Email Address"
              key={form.key("collabEmailPassword")}
              {...form.getInputProps("collabEmailPassword")}
            />
            <Select
              withAsterisk
              placeholder="Select an email provider"
              label="1stCollab Email Provider"
              description="The email service that hosts your 1stcollab@ email address. So for example, select Google if your company uses Gmail and Google Workspace or Microsoft if your company uses Outlook for email. We’ll need this information to connect to the email address for outreach."
              data={[
                { label: "Google", value: EmailProvider.GOOGLE.toString() },
                { label: "Microsoft", value: EmailProvider.MICROSOFT.toString() },
              ]}
              key={form.key("collabEmailProvider")}
              {...form.getInputProps("collabEmailProvider")}
            />
            <CustomTagsInput
              label="Other Member Emails"
              description="The email addresses of other 1stCollab accounts for whom you want to provide access to your brand dashboard."
              placeholder="leon@1stcollab.com"
              splitChars={[",", " "]}
              key={form.key("memberEmails")}
              {...form.getInputProps("memberEmails")}
            />
            <Button variant="filled" type="submit">
              Save
            </Button>
          </Stack>
        </form>
      }
      title="Contact Information"
      subtitle=""
    />
  );
};

export default BrandContact;
