import React from "react";

import { User } from "firebase/auth";

import { ContractDeliverableStatus } from "components/contracts/models/Deliverable";
import DeliverableTimeline from "components/contracts/contract/DeliverableTimeline";
import EditDeliverableMenu from "components/contracts/deliverables/EditDeliverableMenu";
import { SupportedFormat } from "models/Common";

export default function ContentSubmissionCardControls({
  user,
  deliverableId,
  format,
  timeline,
  deliverableStatus,
  requiresReferralLink,
  missingReferralLink,
  handleRefetchDeliverable,
  showAdminOptions,
}: {
  user: User;
  deliverableId: string;
  format: SupportedFormat;
  timeline: DeliverableTimeline;
  deliverableStatus: ContractDeliverableStatus;
  requiresReferralLink: boolean;
  missingReferralLink: boolean;
  handleRefetchDeliverable: (deliverableId: string, handleFetched: () => void) => void;
  showAdminOptions: boolean;
}) {
  if (!showAdminOptions) {
    return null;
  }

  return (
    <EditDeliverableMenu
      user={user}
      deliverableId={deliverableId}
      format={format}
      timeline={timeline}
      currentDeliverableStatus={deliverableStatus}
      requiresReferralLink={requiresReferralLink}
      missingReferralLink={missingReferralLink}
      handleRefetchDeliverable={handleRefetchDeliverable}
    />
  );
}
