import { CreatorDetails, CreatorSetEntryItem } from "components/discovery/Datamodels";
import { API_URL } from "configs/Configs";
import { User } from "firebase/auth";
import {
  createRequest,
  handleResult,
  createRequestBlob,
  downloadFileFromBlob,
  createRequestWithFirebaseToken,
  createRequestBlobWithFirebaseToken,
} from "utils/ApiUtils";
import { MediaEntry } from "components/MediaBlockCard";

const CREATOR_LISTS_URL = `${API_URL}/api/models/creator_lists/`;
const CREATOR_CAMPAIGN_DATASETS_URL = `${API_URL}/api/models/creator_campaigns_datasets/`;
const CREATOR_CAMPAIGN_URL = `${API_URL}/api/models/creator_campaigns/`;

export enum CandidateStateIndex {
  NEEDS_REVIEW = 0,
  ACCEPT = 1,
  REJECT = 2,
  WORKED_WITH = 3,
  LIVE = 4,
}

export enum BrandState {
  NO_BRAND_REVIEW = 0,
  NEEDS_REVIEW = 1,
  ACCEPT = 2,
  REJECT = 3,
  WORKED_WITH = 4,
  LIVE = 5,
}

export enum InternalReviewState {
  NO_INTERNAL_REVIEW = 0,
  ACCEPT = 1,
  REJECT = 2,
  NEEDS_REVIEW = 3,
  PASS = 4,
}

export enum OpsReviewState {
  NO_OPS_REVIEW = 0,
  ACCEPT = 1,
  REJECT = 2,
  PASS = 3,
}

export const CandidateStateIndexToBrandState = {
  [CandidateStateIndex.NEEDS_REVIEW]: BrandState.NEEDS_REVIEW,
  [CandidateStateIndex.ACCEPT]: BrandState.ACCEPT,
  [CandidateStateIndex.REJECT]: BrandState.REJECT,
  [CandidateStateIndex.WORKED_WITH]: BrandState.WORKED_WITH,
  [CandidateStateIndex.LIVE]: BrandState.LIVE,
};

export type CreatorList = {
  name: string;
  fullname: string;
};

export type CreatorListEntryDef = {
  name: string;
  fullname: string;
  channel_id: string;
};

// State of the browsing user creatorLists and the settors
export type UserCreatorListState = {
  creatorLists: CreatorList[];
  setCreatorLists: (creatorLists: CreatorList[]) => void;
  allCreatorListEntries: CreatorListEntryDef[];
  setAllCreatorListEntries: (allCreatorListEntries: CreatorListEntryDef[]) => void;
  savedChannels: Set<string>;
};

export type SavedButtonState = {
  saved: boolean;
  setSaved: (state: boolean) => void;
};

export type SavingUser = {
  user: User;
  userKey: string;
};

export const createList = async (user: User, userKey: string, name = "all-saved-creators") => {
  const firebaseToken = await user.getIdToken();
  const request = new Request(CREATOR_LISTS_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
    body: JSON.stringify({ user: userKey, name }),
  });
  const response = await handleResult(request);
  return response;
};

export const saveYoutubeCreator = async (
  channelId: string,
  user: User,
  userKey: string,
  name = "all-saved-creators",
) => {
  const firebaseToken = await user.getIdToken();
  const request = new Request(CREATOR_LISTS_URL, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
    body: JSON.stringify({ user: userKey, channelId, name }),
  });
  const response = await handleResult(request);
  return response;
};

export const unsaveYoutubeCreator = async (
  channelId: string,
  user: User,
  userKey: string,
  name = "all-saved-creators",
) => {
  const firebaseToken = await user.getIdToken();
  const request = new Request(`${CREATOR_LISTS_URL}delete_from_list/`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
    body: JSON.stringify({ user: userKey, channelId, name }),
  });
  const response = await handleResult(request);
  return response;
};

export interface SetCampaignDatasetStateParams {
  creatorId: number;
  user: User;
  datasetId?: number;
  relevanceState?: number;
  brandSafety?: number;
  qualityScore?: number;
  isEnglish?: boolean;
  creatorInVideo?: boolean;
  isCreator?: boolean;
  archetypeVal?: number;
  feedback?: string;
}

export interface SetCampaignCreatorCandidateParams {
  creatorId: number;
  user: User;
  campaignId: number;
  brandState?: BrandState;
  rejectionReason?: string;
}

type CampaignCandidateJson = {
  creatorId: number;
  campaignId: number;
  brandState: number;
  rejectionReason?: string;
};

type CampaignDatasetJson = {
  creatorId: number;
  datasetId?: number;
  relevanceState?: number;
  brandSafety?: number;
  qualityScore?: number;
  isEnglish?: boolean;
  creatorInVideo?: boolean;
  isCreator?: boolean;
  archetypeVal?: number;
  feedback?: string;
};

export const setCampaignDatasetState = async (params: SetCampaignDatasetStateParams) => {
  const firebaseToken = await params.user.getIdToken();

  const jsonParams: Partial<CampaignDatasetJson> = {};
  jsonParams.creatorId = params.creatorId;
  if (params.datasetId !== undefined) {
    jsonParams.datasetId = params.datasetId;
  }
  if (params.relevanceState !== undefined) {
    jsonParams.relevanceState = params.relevanceState;
  }
  if (params.brandSafety !== undefined) {
    jsonParams.brandSafety = params.brandSafety;
  }
  if (params.qualityScore !== undefined) {
    jsonParams.qualityScore = params.qualityScore;
  }
  if (params.isEnglish !== undefined) {
    jsonParams.isEnglish = params.isEnglish;
  }
  if (params.creatorInVideo !== undefined) {
    jsonParams.creatorInVideo = params.creatorInVideo;
  }
  if (params.isCreator !== undefined) {
    jsonParams.isCreator = params.isCreator;
  }
  if (params.archetypeVal !== undefined) {
    jsonParams.archetypeVal = params.archetypeVal;
  }
  if (params.feedback !== undefined) {
    jsonParams.feedback = params.feedback;
  }

  const request = new Request(`${CREATOR_CAMPAIGN_DATASETS_URL}update_dataset_item/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
    body: JSON.stringify(jsonParams),
  });
  const response = await handleResult(request);
  return response;
};

export type SavedCreatorsResponse = {
  channels: CreatorDetails[];
};

export type CampaignDatasetProps = {
  brand_safety_score?: number;
  quality_score?: number;
  is_english?: boolean;
  creator_in_videos?: boolean;
  is_creator?: boolean;
  brand_state?: BrandState;
  ops_review_state?: OpsReviewState;
  internal_review_state?: InternalReviewState;
};

export type CampaignDatasetListResponse = {
  creator_details: CreatorDetails[];
  lead_relevance: Record<number, number>;
  lead_props: Record<number, CampaignDatasetProps>;
  // this is a mapping from archetype id to archetype name
  archetype_mapping: Record<number, string>;
  // this is a mapping from lead id to archetype id
  lead_archetypes: Record<number, number>;
  // mapping of lead id to feedback
  lead_feedback: Record<number, string>;
};

export type LiveCreatorProps = {
  creatorset_name: string;
  creatorset_id: number;
  price: number;
  latest_contract_date: string;
  latest_opportunity_date: string;
};

export type LiveCampaignCreatorCandidatesResponse = {
  creator_details: CreatorDetails[];
  creator_properties: Record<number, LiveCreatorProps>;
};

export type LiveCreatorSetEntriesResponse = {
  creator_set_entries: CreatorSetEntryItem[];
  live_creator_props: Record<number, LiveCreatorProps>;
};

export type CreatorListsResponse = {
  lists: CreatorList[];
};

export type CreatorListEntriesResponse = {
  entries: CreatorListEntryDef[];
};

export const getSavedYoutubeCreators = async (user: User, userKey: string, name: string) => {
  const firebaseToken = await user.getIdToken();
  const getListUrl = `${CREATOR_LISTS_URL}get_list/?userKey=${userKey}&name=${name}`;
  const request = createRequest({ url: getListUrl, firebaseToken });
  const response: SavedCreatorsResponse = await handleResult(request);
  return response;
};

export const getLiveCreatorSetEntries = async (
  campaignId: number,
  adGroupId?: number,
  timeWindow?: number,
) => {
  const searchParams = new URLSearchParams();
  searchParams.append("campaign_id", campaignId.toString());
  if (adGroupId != null) {
    searchParams.append("ad_group_id", adGroupId.toString());
  }
  if (timeWindow != null) {
    searchParams.append("days_live", timeWindow.toString());
  }
  const getListUrl = `${CREATOR_CAMPAIGN_URL}get_live_creator_entries/?${searchParams.toString()}`;
  const request = await createRequestWithFirebaseToken({ url: getListUrl });
  const response: LiveCreatorSetEntriesResponse = await handleResult(request);
  return response;
};

export const getCampaignMediaCsv = async (campaignHashId: number) => {
  const campaignMediaUrl = `${API_URL}/api/campaigns/export_media/?campaignId=${campaignHashId}`;
  const request = await createRequestBlobWithFirebaseToken({ url: campaignMediaUrl });

  try {
    const response = await fetch(request);
    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }

    const blob = await response.blob();
    downloadFileFromBlob({ blob, filename: "campaign_media.csv" });
  } catch (error) {
    console.error("Error downloading file:", error);
  }
};

export const getAllUserLists = async (user: User, userKey: string) => {
  const firebaseToken = await user.getIdToken();
  const getListUrl = `${CREATOR_LISTS_URL}get_user_lists/?userKey=${userKey}`;
  const request = createRequest({ url: getListUrl, firebaseToken });
  const response: CreatorListsResponse = await handleResult(request);
  return response;
};

export const getAllUserListEntries = async (user: User, userKey: string) => {
  const firebaseToken = await user.getIdToken();
  const getListUrl = `${CREATOR_LISTS_URL}get_all_list_items/?userKey=${userKey}`;
  const request = createRequest({ url: getListUrl, firebaseToken });
  const response: CreatorListEntriesResponse = await handleResult(request);
  return response;
};

export const fetchAndSetCreatorListEntries = async (
  user: User,
  userKey: string,
  setCreatorListEntries: (creatorListEntries: CreatorListEntryDef[]) => void,
) => {
  const resp = await getAllUserListEntries(user, userKey);
  const creatorLists = resp.entries;
  if (creatorLists == null) {
    setCreatorListEntries([]);
  } else {
    setCreatorListEntries(creatorLists);
  }
};

export const fetchAndSetCreatorLists = async (
  user: User,
  userKey: string,
  setCreatorLists: (creatorLists: CreatorList[]) => void,
) => {
  const resp = await getAllUserLists(user, userKey);
  const creatorLists = resp.lists;
  setCreatorLists(creatorLists);
};

export type CreatorInfo = {
  profile_link: string;
  handle: string;
  avatar_url: string;
  num_subscribers: number;
  title: string;
  country: string;
  views_med: number;
  engagement_rate?: number;
  comment_rate?: number;
  comments_med?: number;
  likes_med?: number;
  total_vids: number;
  all_time_videos: number;
  videos_last_updated?: string;
  num_videos_90d?: number;
  avg_views_90d?: number;
  vibe_score?: number;
  videos: MediaEntry[];
  description: string;
  link_clicks?: number;
};

const getYoutubeCreatorInfo = (creator: CreatorDetails): CreatorInfo => {
  const channel = creator.youtube_channel;
  return {
    profile_link: `https://www.youtube.com/channel/${channel.channel_id}`,
    handle: channel.handle,
    avatar_url: channel.avatar_url,
    num_subscribers: channel.num_subscribers,
    title: channel.title,
    country: channel.country,
    views_med: channel.views_med,
    engagement_rate: channel.engagement_rate,
    comment_rate: channel.comment_rate,
    comments_med: channel.comments_med,
    likes_med: channel.likes_med,
    total_vids: channel.total_vids,
    videos_last_updated: channel.videos_last_updated,
    all_time_videos: channel.all_time_videos,
    vibe_score: channel.vibe_score,
    videos: channel.videos,
    description: channel.description,
    link_clicks: creator?.link_clicks?.youtube,
  };
};

const getTiktokCreatorInfo = (creator: CreatorDetails): CreatorInfo => {
  const profileInfo = creator.tiktok_profile.info;
  const profileStats = creator.tiktok_profile.stats;
  return {
    profile_link: `https://www.tiktok.com/@${profileInfo.handle}`,
    handle: profileInfo.handle,
    avatar_url: profileInfo.avatar_url,
    num_subscribers: profileInfo.follower_count,
    title: profileInfo.display_name,
    country: profileInfo.region,
    views_med: profileStats.median_views,
    engagement_rate: profileStats.engagement_rate,
    comment_rate: profileStats.comment_rate,
    comments_med: profileStats.median_comments,
    likes_med: profileStats.median_likes,
    total_vids: profileStats.num_videos,
    videos_last_updated: profileInfo.videos_last_updated,
    all_time_videos: profileInfo.total_videos,
    videos: profileInfo.videos,
    description: profileInfo.description,
    link_clicks: creator?.link_clicks?.tiktok,
  };
};

const getInstagramCreatorInfo = (creator: CreatorDetails): CreatorInfo => {
  const profileInfo = creator.instagram_profile.info;
  const profileStats = creator.instagram_profile.stats;
  return {
    profile_link: `https://www.instagram.com/${profileInfo.handle}`,
    handle: profileInfo.handle,
    avatar_url: profileInfo.avatar_url,
    num_subscribers: profileInfo.follower_count,
    title: profileInfo.display_name,
    country: profileInfo.region,
    views_med: profileStats.median_views,
    engagement_rate: profileStats.engagement_rate,
    comment_rate: profileStats.comment_rate,
    likes_med: profileStats.median_likes,
    comments_med: profileStats.median_comments,
    total_vids: profileStats.num_videos,
    videos: profileInfo.videos,
    videos_last_updated: profileInfo.videos_last_updated,
    all_time_videos: profileInfo.total_videos,
    description: profileInfo.biography,
    link_clicks: creator?.link_clicks?.instagram,
  };
};

export const getCreatorInfoFromDetails = (
  details: CreatorDetails,
  platform: string, // when all, defaults to youtube -> tiktok -> instagram
): CreatorInfo | null => {
  // TODO(kevin): Uncomment this and see if all the profiles work.
  // const platform = details.platform_priority[0];
  if (platform === "youtube" && details.youtube_channel !== null) {
    return getYoutubeCreatorInfo(details);
  } else if (platform === "tiktok" && details.tiktok_profile !== null) {
    return getTiktokCreatorInfo(details);
  } else if (platform === "instagram" && details.instagram_profile !== null) {
    return getInstagramCreatorInfo(details);
  } else if (details?.youtube_channel !== null && platform === "all") {
    return getYoutubeCreatorInfo(details);
  } else if (details?.tiktok_profile !== null && platform === "all") {
    return getTiktokCreatorInfo(details);
  } else if (details?.instagram_profile !== null && platform === "all") {
    return getInstagramCreatorInfo(details);
  }
  return null;
};

export default null;
