export const STRIPE_CONNECT_DASHBOARD_URL = "https://connect.stripe.com/app/express";

export const STRIPE_SUPPORTED_COUNTRIES = [
  { label: "Albania", value: "al" },
  { label: "Algeria", value: "dz" },
  { label: "Angola", value: "ao" },
  { label: "Antigua & Barbuda", value: "ag" },
  { label: "Argentina", value: "ar" },
  { label: "Armenia", value: "am" },
  { label: "Australia", value: "au" },
  { label: "Austria", value: "at" },
  { label: "Azerbaijan", value: "az" },
  { label: "Bahamas", value: "bs" },
  { label: "Bahrain", value: "bh" },
  { label: "Bangladesh", value: "bd" },
  { label: "Belgium", value: "be" },
  { label: "Benin", value: "bj" },
  { label: "Bhutan", value: "bt" },
  { label: "Bolivia", value: "bo" },
  { label: "Bosnia & Herzegovina", value: "ba" },
  { label: "Botswana", value: "bw" },
  { label: "Brazil", value: "br" },
  { label: "Brunei", value: "bn" },
  { label: "Bulgaria", value: "bg" },
  { label: "Cambodia", value: "kh" },
  { label: "Canada", value: "ca" },
  { label: "Chile", value: "cl" },
  { label: "Colombia", value: "co" },
  { label: "Costa Rica", value: "cr" },
  { label: "Croatia", value: "hr" },
  { label: "Cyprus", value: "cy" },
  { label: "Czech Republic", value: "cz" },
  { label: "Côte d'Ivoire", value: "ci" },
  { label: "Denmark", value: "dk" },
  { label: "Dominican Republic", value: "do" },
  { label: "Ecuador", value: "ec" },
  { label: "Egypt", value: "eg" },
  { label: "El Salvador", value: "sv" },
  { label: "Estonia", value: "ee" },
  { label: "Ethiopia", value: "et" },
  { label: "Finland", value: "fi" },
  { label: "France", value: "fr" },
  { label: "Gabon", value: "ga" },
  { label: "Gambia", value: "gm" },
  { label: "Germany", value: "de" },
  { label: "Ghana", value: "gh" },
  { label: "Greece", value: "gr" },
  { label: "Guatemala", value: "gt" },
  { label: "Guyana", value: "gy" },
  { label: "Hong Kong SAR China", value: "hk" },
  { label: "Hungary", value: "hu" },
  { label: "Iceland", value: "is" },
  { label: "India", value: "in" },
  { label: "Indonesia", value: "id" },
  { label: "Ireland", value: "ie" },
  { label: "Israel", value: "il" },
  { label: "Italy", value: "it" },
  { label: "Jamaica", value: "jm" },
  { label: "Japan", value: "jp" },
  { label: "Jordan", value: "jo" },
  { label: "Kazakhstan", value: "kz" },
  { label: "Kenya", value: "ke" },
  { label: "Kuwait", value: "kw" },
  { label: "Laos", value: "la" },
  { label: "Latvia", value: "lv" },
  { label: "Liechtenstein", value: "li" },
  { label: "Lithuania", value: "lt" },
  { label: "Luxembourg", value: "lu" },
  { label: "Macao SAR China", value: "mo" },
  { label: "Madagascar", value: "mg" },
  { label: "Malaysia", value: "my" },
  { label: "Malta", value: "mt" },
  { label: "Mauritius", value: "mu" },
  { label: "Mexico", value: "mx" },
  { label: "Moldova", value: "md" },
  { label: "Monaco", value: "mc" },
  { label: "Mongolia", value: "mn" },
  { label: "Morocco", value: "ma" },
  { label: "Mozambique", value: "mz" },
  { label: "Namibia", value: "na" },
  { label: "Netherlands", value: "nl" },
  { label: "New Zealand", value: "nz" },
  { label: "Nigeria", value: "ng" },
  { label: "North Macedonia", value: "mk" },
  { label: "Norway", value: "no" },
  { label: "Oman", value: "om" },
  { label: "Pakistan", value: "pk" },
  { label: "Panama", value: "pa" },
  { label: "Paraguay", value: "py" },
  { label: "Peru", value: "pe" },
  { label: "Philippines", value: "ph" },
  { label: "Poland", value: "pl" },
  { label: "Portugal", value: "pt" },
  { label: "Qatar", value: "qa" },
  { label: "Romania", value: "ro" },
  { label: "Rwanda", value: "rw" },
  { label: "San Marino", value: "sm" },
  { label: "Saudi Arabia", value: "sa" },
  { label: "Senegal", value: "sn" },
  { label: "Serbia", value: "rs" },
  { label: "Singapore", value: "sg" },
  { label: "Slovakia", value: "sk" },
  { label: "Slovenia", value: "si" },
  { label: "South Africa", value: "za" },
  { label: "South Korea", value: "kr" },
  { label: "Spain", value: "es" },
  { label: "Sri Lanka", value: "lk" },
  { label: "St. Lucia", value: "lc" },
  { label: "Sweden", value: "se" },
  { label: "Switzerland", value: "ch" },
  { label: "Taiwan", value: "tw" },
  { label: "Tanzania", value: "tz" },
  { label: "Thailand", value: "th" },
  { label: "Trinidad & Tobago", value: "tt" },
  { label: "Tunisia", value: "tn" },
  { label: "Turkey", value: "tr" },
  { label: "United Arab Emirates", value: "ae" },
  { label: "United Kingdom", value: "gb" },
  { label: "United States", value: "us" },
  { label: "Uruguay", value: "uy" },
  { label: "Uzbekistan", value: "uz" },
  { label: "Vietnam", value: "vn" },
];
