import { ActionIcon, Container, Flex, Table, Title, Tooltip } from "@mantine/core";

import { IconDownload } from "@tabler/icons-react";

import {
  fetchCampaignOverview,
  getCampaignAdGroupOverallStats,
  translateCampaignProgressResponse,
} from "campaigns/api/fetchCampaignOverview";
import Spacer from "components/Spacer";
import {
  BudgetType,
  Campaign,
  CampaignAdGroup,
  CampaignAdGroupOverview,
  CampaignProgressReportResponse,
} from "models/Campaign";
import React, { useEffect, useState } from "react";
import { getAbbreviatedNumber, formatCurrencyAmount } from "utils/AnalyticsUtils";
import { SpendBar } from "components/campaign/dashboard/CampaignOverviewComponents";
import { getCampaignMediaCsv } from "components/creator_lists/CreatorListsUtils";

const AdGroupOverview = ({
  adGroupOverview,
  budgetType,
  campaignTracksClicks,
}: {
  adGroupOverview: CampaignAdGroupOverview;
  budgetType: BudgetType;
  campaignTracksClicks: boolean;
}) => (
  <Table.Tr key={adGroupOverview.adGroup.id}>
    <Table.Td>{adGroupOverview.adGroup.name}</Table.Td>
    <Table.Td>{formatCurrencyAmount(adGroupOverview.totalBudgetSpent)}</Table.Td>
    <Table.Td>{formatCurrencyAmount(adGroupOverview.totalBudgetAllocated)}</Table.Td>
    <Table.Td>{getAbbreviatedNumber(adGroupOverview.views)}</Table.Td>
    <Table.Td>
      {adGroupOverview.cpm ? `${formatCurrencyAmount(adGroupOverview.cpm)}` : "None"}
    </Table.Td>
    {campaignTracksClicks ? (
      <Table.Td>{getAbbreviatedNumber(adGroupOverview.clicks)}</Table.Td>
    ) : undefined}
    {campaignTracksClicks ? (
      <Table.Td>
        {adGroupOverview.cpc ? `${formatCurrencyAmount(adGroupOverview.cpc)}` : "None"}
      </Table.Td>
    ) : undefined}
    <Table.Td>{adGroupOverview.numLiveContent}</Table.Td>
  </Table.Tr>
);

const CampaignAdGroupContainer = ({
  campaignHashId,
  campaignTracksClicks,
  campaignOverview,
  adGroupOverviews,
}: {
  campaignHashId: number;
  campaignTracksClicks: boolean;
  campaignOverview: CampaignProgressReportResponse;
  adGroupOverviews: CampaignAdGroupOverview[];
}) => {
  // Overall
  const overallStats = getCampaignAdGroupOverallStats(
    adGroupOverviews,
    campaignOverview.spent_off_platform,
  );
  const {
    totalOnAndOffPlatformSpend,
    totalBudgetSpent,
    totalBudgetAllocated,
    monthlyBudgetSpent,
    monthlyBudgetAllocated,
    numLiveContent,
    views,
    cpm,
    clicks,
    cpc,
  } = overallStats;

  const [downloading, setDownloading] = useState(false);

  return (
    <Container
      w="100%"
      bg="var(--mantine-color-white)"
      pt={18}
      pb={18}
      pl={24}
      pr={24}
      style={{
        borderRadius: 20,
      }}>
      <Flex direction="column" gap={0}>
        <Flex justify="space-between">
          <Title order={4}>Campaign Overview</Title>
          <Tooltip label="Export Metrics">
            <ActionIcon
              variant="subtle"
              onClick={() => {
                setDownloading(true);
                getCampaignMediaCsv(campaignHashId).finally(() => setDownloading(false));
              }}
              loading={downloading}>
              <IconDownload size="1.3rem" />
            </ActionIcon>
          </Tooltip>
        </Flex>
        <Spacer height={20} />
        <Table highlightOnHover>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Total Spend</Table.Th>
              <Table.Th>
                {campaignOverview.budget_type > 0 ? "Monthly Budget" : "Total Budget"}
              </Table.Th>
              <Table.Th>Views</Table.Th>
              <Table.Th>CPM</Table.Th>
              {campaignTracksClicks ? <Table.Th>Clicks</Table.Th> : undefined}
              {campaignTracksClicks ? <Table.Th>CPC</Table.Th> : undefined}
              <Table.Th>Live Content</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            <Table.Tr key={0}>
              <Table.Td>{formatCurrencyAmount(totalOnAndOffPlatformSpend)}</Table.Td>
              <Table.Td>
                <SpendBar
                  budget={100 * campaignOverview.budget}
                  spent={campaignOverview.budget_type > 0 ? monthlyBudgetSpent : totalBudgetSpent}
                  allocated={
                    campaignOverview.budget_type > 0 ? monthlyBudgetAllocated : totalBudgetAllocated
                  }
                />
              </Table.Td>
              <Table.Td>{getAbbreviatedNumber(views)}</Table.Td>
              <Table.Td>{cpm ? `${formatCurrencyAmount(cpm)}` : "None"}</Table.Td>
              {campaignTracksClicks ? (
                <Table.Td>{getAbbreviatedNumber(clicks)}</Table.Td>
              ) : undefined}
              {campaignTracksClicks ? (
                <Table.Td>{cpc ? `${formatCurrencyAmount(cpc)}` : "None"}</Table.Td>
              ) : undefined}
              <Table.Td>{numLiveContent}</Table.Td>
            </Table.Tr>
          </Table.Tbody>
        </Table>
      </Flex>
    </Container>
  );
};

const AdGroupOverviewContainer = ({
  overviews,
  adGroup,
  budgetType,
  campaignTracksClicks,
}: {
  overviews: CampaignAdGroupOverview[];
  adGroup: CampaignAdGroup;
  budgetType: BudgetType;
  campaignTracksClicks: boolean;
}) => {
  if (!overviews || overviews.length <= 1) {
    return null;
  }
  let filteredOverviews = overviews;
  if (adGroup) {
    // filter overviews by adGroup
    filteredOverviews = overviews.filter((overview) => overview.adGroup.id === adGroup.id);
  }
  return (
    <Container
      w="100%"
      bg="var(--mantine-color-white)"
      pt={18}
      pb={18}
      pl={24}
      pr={24}
      style={{
        borderRadius: 20,
      }}>
      <Flex direction="column" gap={0}>
        <Title order={4}>
          Ad Group Overview {adGroup ? null : `[${filteredOverviews.length} Ad Groups]`}
        </Title>
        <Spacer height={20} />
        <Table highlightOnHover>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Ad group</Table.Th>
              <Table.Th>Total Spent</Table.Th>
              <Table.Th>Contracted</Table.Th>
              <Table.Th>Views</Table.Th>
              <Table.Th>CPM</Table.Th>
              {campaignTracksClicks ? <Table.Th>Clicks</Table.Th> : undefined}
              {campaignTracksClicks ? <Table.Th>CPC</Table.Th> : undefined}
              <Table.Th>Live Content</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {filteredOverviews.map((adGroupOverview) => (
              <AdGroupOverview
                adGroupOverview={adGroupOverview}
                key={adGroupOverview.adGroup.id}
                budgetType={budgetType}
                campaignTracksClicks={campaignTracksClicks}
              />
            ))}
          </Table.Tbody>
        </Table>
      </Flex>
    </Container>
  );
};

const CampaignOverview = ({
  campaign,
  adGroup,
  tracksClicks = false,
}: {
  campaign: Campaign;
  adGroup: CampaignAdGroup;
  tracksClicks: boolean;
}) => {
  const [campaignOverview, setCampaignOverview] = useState<CampaignProgressReportResponse | null>(
    null,
  );
  const [adGroupOverviews, setAdGroupOverviews] = useState<CampaignAdGroupOverview[] | null>(null);

  useEffect(() => {
    const abortController = new AbortController();

    // reset on campaign change so we don't see the previous campaign's content while new campaigns overview is loading
    setCampaignOverview(null);
    setAdGroupOverviews(null);

    fetchCampaignOverview(campaign.hash_id, abortController).then((campaignProgress) => {
      setCampaignOverview(campaignProgress);
      setAdGroupOverviews(translateCampaignProgressResponse(campaignProgress));
    });

    return () => {
      abortController.abort();
    };
  }, [campaign.hash_id]);

  if (!adGroupOverviews) {
    return null;
  }

  const numAdGroups = adGroupOverviews?.length || 0;
  if (numAdGroups === 0) {
    return null;
  }

  return (
    <>
      {adGroup != null ? null : (
        <CampaignAdGroupContainer
          campaignHashId={campaign.hash_id}
          campaignTracksClicks={tracksClicks}
          campaignOverview={campaignOverview}
          adGroupOverviews={adGroupOverviews}
        />
      )}
      {adGroupOverviews && adGroupOverviews.length > 1 ? (
        <AdGroupOverviewContainer
          overviews={adGroupOverviews}
          adGroup={adGroup}
          budgetType={campaignOverview.budget_type}
          campaignTracksClicks={tracksClicks}
        />
      ) : null}
    </>
  );
};

export default CampaignOverview;
