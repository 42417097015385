import React from "react";
import { IconChevronDown, IconChevronUp, IconSelector } from "@tabler/icons-react";
import { Table, Text, UnstyledButton, Flex, Center } from "@mantine/core";
import table_classes from "css/table/table.module.css";

interface SortableTableHeaderProps<T extends object> {
  label: string;
  field: keyof T;
  sortBy: keyof T | null;
  reverseSortDirection: boolean;
  onSort: (field: keyof T) => void;
  alignLeft?: boolean;
}

function SortableTableHeader<T extends object>({
  label,
  field,
  sortBy,
  reverseSortDirection,
  onSort,
  alignLeft = false,
}: SortableTableHeaderProps<T>) {
  const sorted = sortBy === field;

  const Icon = (() => {
    if (sorted) {
      return reverseSortDirection ? IconChevronDown : IconChevronUp;
    }
    return IconSelector;
  })();

  return (
    <Table.Th className={table_classes.th}>
      <UnstyledButton onClick={() => onSort(field)} className={table_classes.control}>
        <Flex
          align="center"
          gap="sm"
          justify={alignLeft ? "left" : "center"}
          px="xs"
          ml={alignLeft ? "xs" : 0}>
          <Text fw={500} fz="sm">
            {label}
          </Text>
          <Center className={table_classes.icon}>
            <Icon size="0.9rem" stroke={1.5} />
          </Center>
        </Flex>
      </UnstyledButton>
    </Table.Th>
  );
}

export default SortableTableHeader;
