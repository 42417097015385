import { IconDiscountCheck } from "@tabler/icons-react";
import {
  AspectRatio,
  Center,
  Container,
  Box,
  Flex,
  Image,
  rem,
  Text,
  Title,
  Group,
  Avatar,
  Stack,
} from "@mantine/core";
import React from "react";
import tiktok_icon from "social/tiktok/assets/tiktok_auth_button_icon.svg";
import instagram_icon from "social/fb/assets/simple_bw_instagram_icon.svg";
import youtube_icon from "social/google/assets/youtube_black_white_icon.svg";
import output from "pages/home/v2/assets/images/output.svg";
import Spacer from "components/Spacer";
import { getAbbreviatedNumber } from "utils/AnalyticsUtils";
import { useHover } from "@mantine/hooks";

/* eslint-disable @typescript-eslint/no-var-requires */
const image1 = require("pages/home/v2/assets/images/product_overview_1.png");
const image2 = require("pages/home/v2/assets/images/product_overview_2.png");
const image3 = require("pages/home/v2/assets/images/product_overview_3.png");
const seshnolan = require("pages/home/v2/assets/images/seshnolan.jpeg");
const seshnolanVideo = require("pages/home/v2/assets/videos/seshnolan.mp4");
/* eslint-disable @typescript-eslint/no-var-requires */

interface TaglineWithDescription {
  tagline: string;
  description: string;
}

const PlatformDetails = ({
  image,
  numFollowers,
  profileUrl,
}: {
  image: any;
  numFollowers: number;
  profileUrl: string;
}) => {
  const { hovered, ref } = useHover();
  return (
    <Flex
      ref={ref}
      direction="row"
      gap={0}
      align="center"
      onClick={() => window.open(profileUrl, "_blank")}
      style={{ cursor: hovered ? "pointer" : "default" }}>
      <Center w="20px" h="20px">
        <Image src={image} style={{ width: 16, height: 16 }} />
      </Center>
      <Spacer width={4} />
      <Text
        style={{
          fontSize: "14px",
          fontWeight: "400",
          lineHeight: "155%",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}>
        {getAbbreviatedNumber(numFollowers)}
      </Text>
    </Flex>
  );
};

const ProductHero = () => (
  <Box py={56} px={{ base: "24px", md: "32px", lg: "96px" }}>
    <Flex gap={80} direction={{ base: "column", sm: "row" }}>
      <Box w={{ base: "100%", sm: "25%" }}>
        {/* eslint-disable-next-line */}
        <video
          controls
          src={seshnolanVideo}
          width="100%"
          height="auto"
          muted
          style={{ borderRadius: 8 }}
          aria-label="Sesh's 1stcollab feedback video"
        />
      </Box>
      <Flex w={{ base: "100%", sm: "75%" }} justify="center" direction="column">
        <Title order={1} pb={16} style={{ fontSize: "32px" }} ta={{ base: "center", sm: "left" }}>
          Hear about the process from an actual creator
        </Title>
        <Text size="lg" ta={{ base: "center", sm: "left" }}>
          Don&apos;t take our word for it! Take a peek into Sesh&apos;s journey and how smoothly
          everything&apos;s managed with 1stCollab.
        </Text>
        <Spacer height={24} />
        <Group gap={0} style={{ gap: 12 }}>
          {/* bigger avatar for larger screens */}
          <Avatar radius="xl" src={seshnolan} w="64px" h="64px" visibleFrom="md" />
          {/* smaller avatar for smaller screens */}
          <Avatar radius="xl" src={seshnolan} w="48px" h="48px" hiddenFrom="md" />
          <Stack gap={0}>
            <Text size="lg" fw={500}>
              @seshnolan
            </Text>
            <Group gap="lg">
              <PlatformDetails
                image={tiktok_icon}
                profileUrl="https://www.tiktok.com/@seshnolan"
                numFollowers={275000}
              />
              <PlatformDetails
                image={youtube_icon}
                profileUrl="https://www.youtube.com/channel/UCEMBwtlCc62W62N1KK5Qglw"
                numFollowers={20000}
              />
              <PlatformDetails
                image={instagram_icon}
                profileUrl="https://www.instagram.com/seshnolan"
                numFollowers={51400}
              />
            </Group>
          </Stack>
        </Group>

        <Spacer height={24} />

        <Group gap={0} style={{ gap: 4 }}>
          <Text size="sm">In partnership with:</Text>
          <Image src={output} h="20px" />
        </Group>
      </Flex>
    </Flex>
  </Box>
);

const taglinesWithDescription: TaglineWithDescription[] = [
  {
    tagline: "No surprises, and lots of help.",
    description:
      "Know exactly what’s expected upfront. And if you need help, every partnership has a dedicated 1stCollab rep who you’ll know by name and who can help.",
  },
  {
    tagline: "Free for creators.",
    description:
      "All our creator products are free—from the media kit to timeline management to payments & accounting. We make money by charging brands.",
  },
  {
    tagline: "Get paid fast.",
    description:
      "We pay creators in 7 days, guaranteed. (Most of our creators are paid within hours!). Payment is encrypted and secure, and we even take care of invoicing for you.",
  },
];

const ProductOverview = () => {
  const showHero = true;
  return (
    <Flex w="100%" p={0}>
      <Container style={{ maxWidth: "1920px" }} p={0}>
        <Box py={112} px={{ base: "24px", md: "32px", lg: "96px" }}>
          <Container size="md">
            <Title ta="center" style={{ fontSize: "48px" }}>
              We pay hundreds of creators every week. You can be one of them.
            </Title>
          </Container>

          {showHero ? <ProductHero /> : <Box py={28} />}
          <Box>
            <Flex
              direction={{ base: "column", sm: "row" }}
              w="100%"
              style={{
                borderRadius: "12px",
                background: "linear-gradient(to bottom, white, transparent)",
              }}>
              {/* Left half with gray product placeholder  - this one is only visible on bigger screens to handle ordering of the flex position */}
              <Box
                w={{ base: "100%", sm: "50%" }}
                h={500}
                px={{ base: "24px", md: "32px" }}
                pt={{ base: "24px", md: "32px" }}
                visibleFrom="sm"
                style={{
                  borderRadius: "12px",
                  background: "linear-gradient(to bottom, white, transparent)",
                }}>
                {/* Placeholder for image or content */}
                <Box
                  h="100%"
                  style={{
                    borderRadius: "12px",
                    background: `linear-gradient(to bottom, #C9EEDF, transparent)`,
                  }}>
                  <Box w="100%" h="100%" p="48px">
                    <Image h="100%" w="100%" src={image3} fit="contain" />
                  </Box>
                </Box>
              </Box>

              {/* Right half with title and subtitle */}
              <Box
                w={{ base: "100%", sm: "50%" }}
                p={{ base: "24px", md: "32px" }}
                style={{
                  borderRadius: "12px",
                  background: "linear-gradient(to bottom, white, transparent)",
                }}>
                <Title order={2} pb={32} style={{ fontSize: "32px" }}>
                  The smoothest, simplest process you’ll ever find.
                </Title>
                {taglinesWithDescription.map((feature) => (
                  <Flex align="start" key={feature.tagline} gap={12}>
                    <Flex
                      bg="gray.2"
                      p="4"
                      justify="center"
                      align="center"
                      style={{
                        borderRadius: "50%", // This makes the box circular
                        border: "1px solid darkgray", // Set the border color and width
                      }}>
                      <IconDiscountCheck style={{ width: rem(24), height: rem(24) }} />
                    </Flex>
                    <Text mb={12}>
                      <Text span fw={700}>
                        {feature.tagline}
                      </Text>{" "}
                      {feature.description}
                    </Text>
                  </Flex>
                ))}
              </Box>

              {/* Left half with gray product placeholder  - this one is only visible on smaller screens */}
              <Box
                w={{ base: "100%", sm: "50%" }}
                hiddenFrom="sm"
                style={{
                  borderRadius: "12px",
                  background: "linear-gradient(to bottom, white, transparent)",
                }}>
                {/* Placeholder for image or content */}
                <Box
                  h="100%"
                  style={{
                    borderRadius: "12px",
                    background: `linear-gradient(to bottom, #C9EEDF, transparent)`,
                  }}
                  p="48px">
                  <Box w="100%" h="100%">
                    <Image h="100%" src={image3} fit="contain" />
                  </Box>
                </Box>
              </Box>
            </Flex>
          </Box>
        </Box>
      </Container>
    </Flex>
  );
};

export default ProductOverview;
