import DeliverableVideo from "components/contracts/models/DeliverableVideo";

export default class VersionedDeliverableVideo {
  deliverableId: string;

  deliverableVideos: DeliverableVideo[];

  constructor({
    deliverableId,
    deliverableVideos,
  }: {
    deliverableId: string;
    deliverableVideos: DeliverableVideo[];
  }) {
    this.deliverableId = deliverableId;
    this.deliverableVideos = deliverableVideos;
  }

  public static deserialize(response: any) {
    const { deliverableId, deliverableVideos } = response;

    return new VersionedDeliverableVideo({
      deliverableId,
      deliverableVideos: deliverableVideos
        ? deliverableVideos.map((deliverableVideo: any) =>
            DeliverableVideo.deserialize(deliverableVideo),
          )
        : [],
    });
  }
}
