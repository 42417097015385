import React, { useEffect, useState } from "react";
import { Table, Checkbox, Flex, Text, Link } from "gestalt";
import "gestalt/dist/gestalt.css";
import { User } from "firebase/auth";
import moment from "moment";

import { handleResult } from "utils/ApiUtils";
import { ADMIN_URL } from "admin/AdminUtils";
import LoadingScreen from "components/LoadingScreen";
import { getAdminUser } from "admin/AdminLayout";

interface UserJoin {
  name: string;
  username: string;
  email: string;
  signup_date: Date;
  youtube_username: string;
  tiktok_username: string;
  instagram_username: string;
}

const getAndSetUsers = async (
  user: User,
  setUserJoins: (userjoins: UserJoin[]) => void,
  youtube: boolean,
  tiktok: boolean,
  instagram: boolean,
) => {
  const firebaseToken = await user.getIdToken();
  const requestUrl = new URL(`${ADMIN_URL}get_all_users_with_profiles`);
  requestUrl.searchParams.append("youtube", youtube.toString());
  requestUrl.searchParams.append("tiktok", tiktok.toString());
  requestUrl.searchParams.append("instagram", instagram.toString());
  const request = new Request(requestUrl.toString(), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
  });
  const userJoins: UserJoin[] = await handleResult(request);
  setUserJoins(userJoins);
};

const TableView = ({
  userjoins,
  youtube,
  setYoutube,
  tiktok,
  setTiktok,
  instagram,
  setInstagram,
}: {
  userjoins: UserJoin[];
  youtube: boolean;
  setYoutube: (requireYoutube: boolean) => void;
  tiktok: boolean;
  setTiktok: (requireTiktok: boolean) => void;
  instagram: boolean;
  setInstagram: (requireInstagram: boolean) => void;
}) => {
  const userDataBody = userjoins.map((userjoin) => (
    <Table.Row key={`row-${userjoin.username}`}>
      <Table.Cell key={`cell-${userjoin.username}-name`}>{userjoin.name}</Table.Cell>
      <Table.Cell key={`cell-${userjoin.username}-username`}>
        <Link href={`/${userjoin.username}`}>{userjoin.username}</Link>
      </Table.Cell>
      <Table.Cell key={`cell-${userjoin.username}-email`}>{userjoin.email}</Table.Cell>
      <Table.Cell key={`cell-${userjoin.username}-signup_date`}>
        {moment(userjoin.signup_date).format("YYYY-MM-DD")}
      </Table.Cell>
      <Table.Cell key={`cell-${userjoin.username}-youtube_username`}>
        {userjoin.youtube_username}
      </Table.Cell>
      <Table.Cell key={`cell-${userjoin.username}-tiktok_username`}>
        {userjoin.tiktok_username}
      </Table.Cell>
      <Table.Cell key={`cell-${userjoin.username}-instagram_username`}>
        {userjoin.instagram_username}
      </Table.Cell>
    </Table.Row>
  ));
  return (
    <Table accessibilityLabel="User Profile Data">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Username</Table.HeaderCell>
          <Table.HeaderCell>Email</Table.HeaderCell>
          <Table.HeaderCell>Signup Date</Table.HeaderCell>
          <Table.HeaderCell>
            <Flex alignItems="center" gap={2}>
              YouTube Username
              <Checkbox
                id="youtubeCheckbox"
                checked={youtube}
                onChange={() => {
                  setYoutube(!youtube);
                }}
              />
            </Flex>
          </Table.HeaderCell>
          <Table.HeaderCell>
            <Flex alignItems="center" gap={2}>
              TikTok Username
              <Checkbox
                id="tiktokCheckbox"
                checked={tiktok}
                onChange={() => {
                  setTiktok(!tiktok);
                }}
              />
            </Flex>
          </Table.HeaderCell>
          <Table.HeaderCell>
            <Flex alignItems="center" gap={2}>
              Instagram Username
              <Checkbox
                id="instagramCheckbox"
                checked={instagram}
                onChange={() => {
                  setInstagram(!instagram);
                }}
              />
            </Flex>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>{userDataBody}</Table.Body>
    </Table>
  );
};

const UserTable = () => {
  const user: User = getAdminUser();
  const [userjoins, setUserJoins] = useState(null);

  const [youtube, setYoutube] = useState(false);
  const [tiktok, setTiktok] = useState(false);
  const [instagram, setInstagram] = useState(false);

  useEffect(() => {
    getAndSetUsers(user, setUserJoins, youtube, tiktok, instagram);
  }, [youtube, tiktok, instagram]);

  return userjoins ? (
    <TableView
      userjoins={userjoins}
      youtube={youtube}
      setYoutube={setYoutube}
      tiktok={tiktok}
      setTiktok={setTiktok}
      instagram={instagram}
      setInstagram={setInstagram}
    />
  ) : (
    <LoadingScreen />
  );
};

export default UserTable;
