import React from "react";

import { Button, ThemeIcon, Tooltip } from "@mantine/core";

import { IconPlayerPlay } from "@tabler/icons-react";

import { timestampToSeconds } from "utils/DateUtils";

import ReactPlayer from "react-player";

export default function SeekButton({
  playerRef,
  integrationTimestamp,
  showLabel,
}: {
  playerRef: React.MutableRefObject<ReactPlayer>;
  integrationTimestamp: string;
  showLabel?: boolean;
}) {
  const handleSeek = () => {
    if (playerRef && playerRef.current) {
      playerRef.current.seekTo(timestampToSeconds(integrationTimestamp));
    }
  };

  return (
    <Tooltip label={`Seek to ${integrationTimestamp}`}>
      <Button
        size="compact-xs"
        variant="light"
        leftSection={
          <ThemeIcon mr={-12} ml={-8} variant="subtle">
            <IconPlayerPlay size={14} stroke={2} />
          </ThemeIcon>
        }
        onClick={handleSeek}>
        {showLabel && "Integration at "}{integrationTimestamp}
      </Button>
    </Tooltip>
  );
}
