import React from "react";

import { User } from "firebase/auth";

import {
  contractAccepted,
  contractExpired,
  contractPendingSignature,
  ContractStatus,
} from "components/contracts/common/Common";

import { Container, Text, Button, Indicator, Modal } from "@mantine/core";

import { useDisclosure } from "@mantine/hooks";

import { IconCheck, IconSignature } from "@tabler/icons-react";

import ViewContract from "components/contracts/contract/ViewContract";

export default function ContractButton({
  user,
  contractId,
  status,
  setStatus,
  setJustSigned,
  setDefaultEmail,
  setAdditionalEmails,
}: {
  user: User;
  contractId: string;
  status: ContractStatus;
  setStatus: (status: ContractStatus) => void;
  setJustSigned: (justSigned: boolean) => void;
  setDefaultEmail: (email: string) => void;
  setAdditionalEmails: (emails: string[]) => void;
}) {
  const [opened, { open, close }] = useDisclosure();
  // This is only called when contract is signed.
  const handleCloseModal = (success: boolean, newStatus: ContractStatus, defaultEmail: string) => {
    if (success) {
      setStatus(newStatus);
    }
    close();
    // window.location.reload();

    setJustSigned(true);
    setDefaultEmail(defaultEmail);
  };

  if (contractPendingSignature(status)) {
    return (
      <>
        <Modal
          opened={opened}
          onClose={close}
          fullScreen
          transitionProps={{ transition: "fade", duration: 200 }}>
          <Container>
            <ViewContract
              contractId={contractId}
              handleCloseModal={handleCloseModal}
              setAdditionalEmails={setAdditionalEmails}
            />
          </Container>
        </Modal>
        <Indicator color="red" position="top-start" size={8} processing>
          <Button onClick={open} variant="outline" leftSection={<IconSignature size="1rem" />}>
            Sign Contract
          </Button>
        </Indicator>
      </>
    );
  } else if (contractAccepted(status)) {
    return (
      <>
        <Modal
          opened={opened}
          onClose={close}
          fullScreen
          transitionProps={{ transition: "fade", duration: 200 }}>
          <Container>
            <ViewContract
              contractId={contractId}
              handleCloseModal={handleCloseModal}
              setAdditionalEmails={setAdditionalEmails}
            />
          </Container>
        </Modal>
        <Button
          onClick={open}
          variant="outline"
          color="teal"
          leftSection={<IconCheck size="1rem" />}>
          View Contract
        </Button>
      </>
    );
  } else if (contractExpired(status)) {
    return (
      <>
        <Modal
          opened={opened}
          onClose={close}
          fullScreen
          transitionProps={{ transition: "fade", duration: 200 }}>
          <Container>
            <ViewContract
              contractId={contractId}
              handleCloseModal={handleCloseModal}
              setAdditionalEmails={setAdditionalEmails}
            />
          </Container>
        </Modal>
        <Button
          onClick={open}
          variant="outline"
          color="gray"
          leftSection={<IconSignature size="1rem" />}>
          View Expired Contract
        </Button>
      </>
    );
  }
  return null;
}
