import React, { useEffect, useState } from "react";
import { User } from "firebase/auth";

import {
  ActionIcon,
  Button,
  CheckIcon,
  CloseButton,
  Combobox,
  Divider,
  Flex,
  Group,
  Menu,
  Modal,
  Text,
  TextInput,
  Tooltip,
  rem,
  useCombobox,
} from "@mantine/core";
import { useDisclosure, useInputState } from "@mantine/hooks";
import { notifications, Notifications } from "@mantine/notifications";

import { showFailureNotification, showSuccessNotification } from "components/common/Notifications";

import {
  IconAt,
  IconBookmarks,
  IconBookmarkPlus,
  IconCheck,
  IconExternalLink,
  IconPlus,
} from "@tabler/icons-react";
import Spacer from "components/Spacer";

import { CampaignAdGroupInfo, CampaignArchetypeInfo } from "models/Creator";
import {
  addCreatorFromLink,
  fetchCreatorInfoFromLink,
} from "campaigns/api/fetchCreatorInfoFromLink";
import { CreatorDetails, CreatorSetEntryState } from "components/discovery/Datamodels";
import { addCreatorToCreatorSet } from "components/creator_sets/CreatorSetUtils";

const CampaignDetailsSelectorWithSubmit = ({
  user,
  addCreatorLoading,
  setAddCreatorLoading,
  youtubeHandle,
  tiktokHandle,
  instagramHandle,
  firstName,
  lastName,
  email,
  campaignId,
  archetypeList = [],
  adGroupList = [],
  currAdGroup,
  currArchetype,
  close,
}: {
  user: User;
  addCreatorLoading: boolean;
  setAddCreatorLoading: (addCreatorLoading: boolean) => void;
  youtubeHandle?: string;
  tiktokHandle?: string;
  instagramHandle?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  campaignId?: number;
  archetypeList?: CampaignArchetypeInfo[];
  adGroupList?: CampaignAdGroupInfo[];
  currAdGroup?: string;
  currArchetype?: string;
  close?: () => void;
}) => {
  const archetypeStringToId = new Map(archetypeList.map((obj) => [obj.name, obj.id]));
  const archetypes = archetypeList.map((obj) => obj.name);
  const archetypeSelector = useCombobox({
    onDropdownClose: () => archetypeSelector.resetSelectedOption(),
    onDropdownOpen: (eventSource) => {
      if (eventSource === "keyboard") {
        archetypeSelector.selectActiveOption();
      } else {
        archetypeSelector.updateSelectedOptionIndex("active");
      }
    },
  });
  const [archetype, setArchetype] = useState(currArchetype);
  const [searchArchetype, setSearchArchetype] = useState(currArchetype);
  const shouldFilterArchetypOptions = archetypes.every((item) => item !== searchArchetype);
  const filteredArchetypeOptions = shouldFilterArchetypOptions
    ? archetypes.filter((item) => item.toLowerCase().includes(searchArchetype.toLowerCase().trim()))
    : archetypes;

  const archetypeOptions = filteredArchetypeOptions.map((item) => (
    <Combobox.Option value={item} key={item} active={item === archetype}>
      <Group gap="xs">
        {item === archetype && <CheckIcon size={12} />}
        <span>{item}</span>
      </Group>
    </Combobox.Option>
  ));

  // Ad Group Selector
  // Add "auto" option to adGroup
  adGroupList.unshift({ id: -1, name: "auto" });
  const adGroupStringToId = new Map(adGroupList.map((obj) => [obj.name, obj.id]));
  const adGroups = adGroupList.map((obj) => obj.name);
  const adGroupSelector = useCombobox({
    onDropdownClose: () => adGroupSelector.resetSelectedOption(),
    onDropdownOpen: (eventSource) => {
      if (eventSource === "keyboard") {
        adGroupSelector.selectActiveOption();
      } else {
        adGroupSelector.updateSelectedOptionIndex("active");
      }
    },
  });
  const [adGroup, setAdGroup] = useState(currAdGroup);
  const [searchAdGroup, setSearchAdGroup] = useState(currAdGroup);
  const shouldFilterAdGroupOptions = adGroups.every((item) => item !== searchArchetype);
  const filteredAdGroupOptions = shouldFilterAdGroupOptions
    ? adGroups.filter((item) => item.toLowerCase().includes(searchAdGroup.toLowerCase().trim()))
    : adGroups;

  const adGroupOptions = filteredAdGroupOptions.map((item) => (
    <Combobox.Option value={item} key={item} active={item === adGroup}>
      <Group gap="xs">
        {item === adGroup && <CheckIcon size={12} />}
        <span>{item}</span>
      </Group>
    </Combobox.Option>
  ));

  return (
    <>
      <Flex direction="column" gap="xs" align="flex-start">
        <Combobox
          store={archetypeSelector}
          withinPortal={false}
          onOptionSubmit={(val) => {
            setArchetype(val);
            setSearchArchetype(val);
            archetypeSelector.closeDropdown();
          }}>
          <Combobox.Target>
            <TextInput
              label="Archetype"
              rightSection={<Combobox.Chevron />}
              value={searchArchetype}
              onChange={(event: { currentTarget: { value: React.SetStateAction<string> } }) => {
                archetypeSelector.openDropdown();
                archetypeSelector.updateSelectedOptionIndex();
                setSearchArchetype(event.currentTarget.value);
              }}
              onClick={() => archetypeSelector.openDropdown()}
              onFocus={() => archetypeSelector.openDropdown()}
              onBlur={() => {
                archetypeSelector.closeDropdown();
                setSearchArchetype(archetype || "");
              }}
              placeholder="Archetype"
              rightSectionPointerEvents="none"
              required
            />
          </Combobox.Target>
          <Combobox.Dropdown>
            <Combobox.Options>
              {archetypeOptions.length > 0 ? (
                archetypeOptions
              ) : (
                <Combobox.Empty>Nothing found</Combobox.Empty>
              )}
            </Combobox.Options>
          </Combobox.Dropdown>
        </Combobox>
        <Combobox
          store={adGroupSelector}
          withinPortal={false}
          onOptionSubmit={(val) => {
            setAdGroup(val);
            setSearchAdGroup(val);
            adGroupSelector.closeDropdown();
          }}>
          <Combobox.Target>
            <TextInput
              label="Ad Group"
              rightSection={<Combobox.Chevron />}
              value={searchAdGroup}
              onChange={(event: { currentTarget: { value: React.SetStateAction<string> } }) => {
                adGroupSelector.openDropdown();
                adGroupSelector.updateSelectedOptionIndex();
                setSearchAdGroup(event.currentTarget.value);
              }}
              onClick={() => adGroupSelector.openDropdown()}
              onFocus={() => adGroupSelector.openDropdown()}
              onBlur={() => {
                adGroupSelector.closeDropdown();
                setSearchAdGroup(adGroup || "");
              }}
              placeholder="Ad Group"
              rightSectionPointerEvents="none"
              required
            />
          </Combobox.Target>
          <Combobox.Dropdown>
            <Combobox.Options>
              {adGroupOptions.length > 0 ? (
                adGroupOptions
              ) : (
                <Combobox.Empty>Nothing found</Combobox.Empty>
              )}
            </Combobox.Options>
          </Combobox.Dropdown>
        </Combobox>
      </Flex>
      <Spacer height={12} />
      <Flex direction="column" gap="sm" justify="center" align="center">
        <Button
          loading={addCreatorLoading}
          onClick={() => {
            setAddCreatorLoading(true);
            const archetypeId = archetypeStringToId.get(archetype);
            const adGroupId = adGroupStringToId.get(adGroup);
            addCreatorFromLink({
              youtubeHandle,
              instagramHandle,
              tiktokHandle,
              firstName,
              lastName,
              email,
              campaignId,
              adGroupId,
              archetypeId,
            })
              .then((response) => {
                if (response == null || !response.success) {
                  notifications.show({
                    color: "red",
                    title: "Error with adding the creator!",
                    message: `${response.message}`,
                    autoClose: false,
                  });
                } else {
                  notifications.show({
                    title: "Creator added successfully!",
                    message: `Creator added to ${adGroup} with archetype ${archetype}.`,
                    autoClose: false,
                  });
                  close();
                }
              })
              .finally(() => {
                setAddCreatorLoading(false);
              });
          }}>
          Add Creator to Campaign
        </Button>
      </Flex>
    </>
  );
};

export const BaseAddCreatorDetails = ({
  youtubeHandle,
  setYoutubeHandle,
  tiktokHandle,
  setTiktokHandle,
  instagramHandle,
  setInstagramHandle,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
}: {
  youtubeHandle?: string;
  setYoutubeHandle?: (youtubeHandle: string) => void;
  tiktokHandle?: string;
  setTiktokHandle?: (tiktokHandle: string) => void;
  instagramHandle?: string;
  setInstagramHandle?: (instagramHandle: string) => void;
  firstName?: string;
  setFirstName?: (firstName: string) => void;
  lastName?: string;
  setLastName?: (lastName: string) => void;
  email?: string;
  setEmail?: (email: string) => void;
}) => {
  const [emailError, setEmailError] = useState("");

  return (
    <>
      <Divider my={16} />
      <Text fw="500">Social Platform Details</Text>
      <Flex gap="xs" align="flex-start">
        <TextInput
          leftSection={<IconAt size={16} />}
          placeholder="mrbeast"
          value={youtubeHandle}
          onChange={(event) => setYoutubeHandle(event.currentTarget.value)}
          rightSectionPointerEvents="all"
          mt="md"
          label="YouTube Handle"
          rightSection={
            <Tooltip label="View Channel" style={{ display: youtubeHandle ? undefined : "none" }}>
              <ActionIcon
                key="youtube-actionIcon"
                variant="subtle"
                color="gray"
                component="a"
                href={`https://www.youtube.com/@${youtubeHandle}`}
                target="_blank"
                size={32}
                style={{ display: youtubeHandle ? undefined : "none" }}>
                <IconExternalLink
                  color="gray"
                  key="youtube-link"
                  style={{
                    width: rem(18),
                    height: rem(18),
                    display: youtubeHandle ? undefined : "none",
                  }}
                  stroke={1.5}
                />
              </ActionIcon>
            </Tooltip>
          }
        />
        <TextInput
          leftSection={<IconAt size={16} />}
          placeholder="mrbeast"
          value={tiktokHandle}
          onChange={(event) => setTiktokHandle(event.currentTarget.value)}
          rightSectionPointerEvents="all"
          mt="md"
          label="TikTok Handle"
          rightSection={
            <Tooltip label="View Profile" style={{ display: tiktokHandle ? undefined : "none" }}>
              <ActionIcon
                key="tiktok-actionIcon"
                variant="subtle"
                color="gray"
                component="a"
                href={`https://www.tiktok.com/@${tiktokHandle}`}
                target="_blank"
                size={32}
                style={{ display: tiktokHandle ? undefined : "none" }}>
                <IconExternalLink
                  color="gray"
                  key="tiktok-link"
                  style={{
                    width: rem(18),
                    height: rem(18),
                    display: tiktokHandle ? undefined : "none",
                  }}
                  stroke={1.5}
                />
              </ActionIcon>
            </Tooltip>
          }
        />
        <TextInput
          leftSection={<IconAt size={16} />}
          placeholder="mrbeast"
          value={instagramHandle}
          onChange={(event) => setInstagramHandle(event.currentTarget.value)}
          rightSectionPointerEvents="all"
          mt="md"
          label="Instagram Handle"
          rightSection={
            <Tooltip label="View Profile" style={{ display: instagramHandle ? undefined : "none" }}>
              <ActionIcon
                key="instagram-actionIcon"
                variant="subtle"
                color="gray"
                component="a"
                href={`https://www.instagram.com/${instagramHandle}`}
                target="_blank"
                size={32}
                style={{ display: instagramHandle ? undefined : "none" }}>
                <IconExternalLink
                  color="gray"
                  key="instagram-link"
                  style={{
                    width: rem(18),
                    height: rem(18),
                    display: instagramHandle ? undefined : "none",
                  }}
                  stroke={1.5}
                />
              </ActionIcon>
            </Tooltip>
          }
        />
      </Flex>
      <Spacer height={12} />
      <Divider my={16} />
      <Text fw="500">Creator Details</Text>
      <Flex direction="column" gap="xs" align="flex-start">
        <Flex direction="row" align="center" gap="xl">
          <TextInput
            placeholder="First Name"
            value={firstName}
            onChange={(event) => setFirstName(event.currentTarget.value)}
            rightSectionPointerEvents="all"
            mt="md"
            label="First Name"
            rightSection={
              <CloseButton
                aria-label="Clear input"
                onClick={() => setFirstName("")}
                style={{ display: setFirstName ? undefined : "none" }}
              />
            }
          />
          <TextInput
            placeholder="Last Name"
            value={lastName}
            onChange={(event) => setLastName(event.currentTarget.value)}
            rightSectionPointerEvents="all"
            mt="md"
            label="Last Name"
            rightSection={
              <CloseButton
                aria-label="Clear input"
                onClick={() => setLastName("")}
                style={{ display: setLastName ? undefined : "none" }}
              />
            }
          />
        </Flex>
        <TextInput
          value={email}
          onChange={(event) => {
            const emailValue = event.currentTarget.value;
            setEmail(emailValue);
            if (emailValue !== "" && !/^\S+@\S+$/.test(emailValue)) {
              setEmailError("Please enter a valid email address.");
            } else {
              setEmailError("");
            }
          }}
          rightSectionPointerEvents="all"
          mt="md"
          label="Email"
          rightSection={
            <CloseButton
              aria-label="Clear input"
              onClick={() => setEmail("")}
              style={{ display: setEmail ? undefined : "none" }}
            />
          }
          error={emailError !== "" ? emailError : null}
        />
      </Flex>
      <Spacer height={12} />
      <Divider my={16} />
    </>
  );
};

const AddCreatorDetailsToCampaign = ({
  user,
  campaignId,
  youtubeHandle,
  setYoutubeHandle,
  tiktokHandle,
  setTiktokHandle,
  instagramHandle,
  setInstagramHandle,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  archetypeList = [],
  adGroupList = [],
  currAdGroup,
  currArchetype,
  hidden = true,
  close,
}: {
  user: User;
  campaignId?: number;
  youtubeHandle?: string;
  setYoutubeHandle?: (youtubeHandle: string) => void;
  tiktokHandle?: string;
  setTiktokHandle?: (tiktokHandle: string) => void;
  instagramHandle?: string;
  setInstagramHandle?: (instagramHandle: string) => void;
  firstName?: string;
  setFirstName?: (firstName: string) => void;
  lastName?: string;
  setLastName?: (lastName: string) => void;
  email?: string;
  setEmail?: (email: string) => void;
  archetypeList?: CampaignArchetypeInfo[];
  adGroupList?: CampaignAdGroupInfo[];
  currAdGroup?: string;
  currArchetype?: string;
  hidden?: boolean;
  close?: () => void;
}) => {
  if (hidden) {
    return null;
  }

  const [addCreatorLoading, setAddCreatorLoading] = useState(false);

  return (
    <>
      <BaseAddCreatorDetails
        youtubeHandle={youtubeHandle}
        setYoutubeHandle={setYoutubeHandle}
        tiktokHandle={tiktokHandle}
        setTiktokHandle={setTiktokHandle}
        instagramHandle={instagramHandle}
        setInstagramHandle={setInstagramHandle}
        firstName={firstName}
        setFirstName={setFirstName}
        lastName={lastName}
        setLastName={setLastName}
        email={email}
        setEmail={setEmail}
      />
      <CampaignDetailsSelectorWithSubmit
        user={user}
        addCreatorLoading={addCreatorLoading}
        setAddCreatorLoading={setAddCreatorLoading}
        youtubeHandle={youtubeHandle}
        tiktokHandle={tiktokHandle}
        instagramHandle={instagramHandle}
        firstName={firstName}
        lastName={lastName}
        email={email}
        campaignId={campaignId}
        archetypeList={archetypeList}
        adGroupList={adGroupList}
        currAdGroup={currAdGroup}
        currArchetype={currArchetype}
        close={close}
      />
    </>
  );
};

const AddCreatorDetailsToCreatorSet = ({
  youtubeHandle,
  setYoutubeHandle,
  tiktokHandle,
  setTiktokHandle,
  instagramHandle,
  setInstagramHandle,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  creatorSetId,
  creatorSetName,
  hidden = true,
  existingCreatorSetState,
  handleImport,
  close,
  isStaff,
}: {
  youtubeHandle?: string;
  setYoutubeHandle?: (youtubeHandle: string) => void;
  tiktokHandle?: string;
  setTiktokHandle?: (tiktokHandle: string) => void;
  instagramHandle?: string;
  setInstagramHandle?: (instagramHandle: string) => void;
  firstName?: string;
  setFirstName?: (firstName: string) => void;
  lastName?: string;
  setLastName?: (lastName: string) => void;
  email?: string;
  setEmail?: (email: string) => void;
  creatorSetId: number;
  creatorSetName: string;
  hidden?: boolean;
  existingCreatorSetState: CreatorSetEntryState;
  handleImport: (
    creatorDetails: CreatorDetails,
    selectedCreatorSetId: number,
    creatorSetState: CreatorSetEntryState,
  ) => void;
  close?: () => void;
  isStaff: boolean;
}) => {
  if (hidden) {
    return null;
  }

  const isSaved = existingCreatorSetState === CreatorSetEntryState.SAVED;
  const isActivated = existingCreatorSetState === CreatorSetEntryState.ACCEPTED;

  const [recommendationLoading, setRecommendationLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [activateLoading, setActivateLoading] = useState(false);

  const handleAddToCreatorSet = (
    creatorSetState: CreatorSetEntryState,
    setLoading: (value: boolean) => void,
  ) => {
    setLoading(true);
    addCreatorToCreatorSet({
      youtubeHandle,
      instagramHandle,
      tiktokHandle,
      firstName,
      lastName,
      email,
      creatorSetId,
      creatorSetState,
    })
      .then((response) => {
        if (response == null || !response.success) {
          showFailureNotification({ message: `Error adding creator to ${creatorSetName}.` });
        } else {
          handleImport(
            response.creator_details,
            response.creator_set_entry.creator_set_id,
            response.creator_set_entry.state,
          );
          showSuccessNotification({ message: `Successfully added creator to ${creatorSetName}!` });
          close();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <BaseAddCreatorDetails
        youtubeHandle={youtubeHandle}
        setYoutubeHandle={setYoutubeHandle}
        tiktokHandle={tiktokHandle}
        setTiktokHandle={setTiktokHandle}
        instagramHandle={instagramHandle}
        setInstagramHandle={setInstagramHandle}
        firstName={firstName}
        setFirstName={setFirstName}
        lastName={lastName}
        setLastName={setLastName}
        email={email}
        setEmail={setEmail}
      />
      <Group justify="right">
        {isStaff && (
          <Button
            loading={recommendationLoading}
            disabled={isSaved || isActivated}
            onClick={() =>
              handleAddToCreatorSet(CreatorSetEntryState.NEEDS_REVIEW, setRecommendationLoading)
            }
            variant="default">
            Add Recommendation (Admin)
          </Button>
        )}
        {!isActivated && (
          <Button
            leftSection={isSaved ? <IconBookmarks size="1rem" /> : <IconBookmarkPlus size="1rem" />}
            loading={saveLoading}
            disabled={isSaved}
            onClick={() => handleAddToCreatorSet(CreatorSetEntryState.SAVED, setSaveLoading)}
            variant="outline">
            {isSaved ? "Saved" : "Save"}
          </Button>
        )}
        <Button
          leftSection={isActivated ? <IconCheck size="1rem" /> : <IconPlus size="1rem" />}
          loading={activateLoading}
          disabled={isActivated}
          onClick={() => handleAddToCreatorSet(CreatorSetEntryState.ACCEPTED, setActivateLoading)}>
          {isActivated ? "Activated" : "Activate"}
        </Button>
      </Group>
    </>
  );
};

export const AddCreatorModalToCampaign = ({
  user,
  campaignId,
}: {
  user: User;
  campaignId?: number;
}) => {
  const [opened, { open, close }] = useDisclosure(false);
  const [hidden, setHidden] = useState(true);
  const [linkUrl, setLinkUrl] = useInputState("");
  const [youtubeHandle, setYoutubeHandle] = useInputState("");
  const [tiktokHandle, setTiktokHandle] = useInputState("");
  const [instagramHandle, setInstagramHandle] = useInputState("");
  const [firstName, setFirstName] = useInputState("");
  const [lastName, setLastName] = useInputState("");
  const [email, setEmail] = useInputState("");
  const [archetypeList, setArchetypeList] = useState<CampaignArchetypeInfo[]>([]);
  const [adGroupList, setAdGroupList] = useState<CampaignAdGroupInfo[]>([]);
  const [currArchetype, setCurrArchetype] = useState("");
  const [currAdGroup, setCurrAdGroup] = useState("");

  const [buttonLoading, setButtonLoading] = useState(false);

  useEffect(() => {
    if (!opened) {
      setLinkUrl("");
      setYoutubeHandle("");
      setTiktokHandle("");
      setInstagramHandle("");
      setFirstName("");
      setLastName("");
      setEmail("");
      setArchetypeList([]);
      setAdGroupList([]);
      setCurrArchetype("");
      setCurrAdGroup("");
      setHidden(true);
    }
  }, [opened]);

  return (
    <>
      <Notifications />
      <Modal
        size="lg"
        opened={opened}
        onClose={() => {
          close();
          setHidden(true);
        }}
        title={
          <Text fw="600" size="lg">
            Add Creator
          </Text>
        }>
        <Flex direction="column" gap="sm" justify="center" align="center">
          <TextInput
            placeholder="https://www.instagram.com/eleanor_the_pug"
            label="Profile URL"
            description="Enter the profile URL of the creator to automatically import the creator details."
            value={linkUrl}
            onChange={(event) => setLinkUrl(event.currentTarget.value)}
            size="sm"
            styles={{
              root: {
                minWidth: "80%",
              },
            }}
          />
          <Flex direction="row" align="center" gap="sm">
            <Button
              loading={buttonLoading}
              onClick={() => {
                setButtonLoading(true);
                setHidden(true);
                fetchCreatorInfoFromLink(linkUrl, campaignId)
                  .then((data) => {
                    const { creator, ad_groups: adGroups, archetypes } = data;
                    if (creator != null) {
                      if (creator.youtube_handle != null) {
                        setYoutubeHandle(creator.youtube_handle);
                      }
                      if (creator.tiktok_handle != null) {
                        setTiktokHandle(creator.tiktok_handle);
                      }
                      if (creator.instagram_handle != null) {
                        setInstagramHandle(creator.instagram_handle);
                      }
                      if (creator.first_name != null) {
                        setFirstName(creator.first_name);
                      }
                      if (creator.last_name != null) {
                        setLastName(creator.last_name);
                      }
                      if (creator.email != null) {
                        setEmail(creator.email);
                      }
                      if (adGroups != null) {
                        setAdGroupList(adGroups);
                        if (creator.ad_group_id != null) {
                          setCurrAdGroup(
                            adGroups.find((adGroup) => adGroup.id === creator.ad_group_id)?.name ||
                              "",
                          );
                        }
                      }
                      if (archetypes != null) {
                        setArchetypeList(archetypes);
                        if (creator.archetype_id != null) {
                          setCurrArchetype(
                            archetypes.find((archetype) => archetype.id === creator.archetype_id)
                              ?.name || "",
                          );
                        }
                      }
                      setHidden(false);
                    }
                  })
                  .finally(() => {
                    setButtonLoading(false);
                  });
              }}>
              Import
            </Button>
          </Flex>
        </Flex>
        <AddCreatorDetailsToCampaign
          user={user}
          hidden={hidden}
          youtubeHandle={youtubeHandle}
          setYoutubeHandle={setYoutubeHandle}
          tiktokHandle={tiktokHandle}
          setTiktokHandle={setTiktokHandle}
          instagramHandle={instagramHandle}
          setInstagramHandle={setInstagramHandle}
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          email={email}
          setEmail={setEmail}
          adGroupList={adGroupList}
          archetypeList={archetypeList}
          campaignId={campaignId}
          currArchetype={currArchetype}
          currAdGroup={currAdGroup}
          close={close}
        />
      </Modal>
      <Button onClick={open}>Manually Add Creator</Button>
    </>
  );
};

const CreatorSetSelector = ({
  creatorSetOptions,
  setCreatorSetId,
  open,
}: {
  creatorSetOptions: Record<string, string>[];
  setCreatorSetId: (creatorSetId: number) => void;
  open: () => void;
}) => {
  const handleSelect = (creatorSetId: number) => {
    setCreatorSetId(creatorSetId);
    open();
  };

  return (
    <Menu>
      <Menu.Target>
        <Button leftSection={<IconPlus size="1rem" />}>Import Creator</Button>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Label>Import Creator</Menu.Label>
        {creatorSetOptions.map((option) => (
          <Menu.Item
            key={option.value}
            onClick={() => handleSelect(parseInt(option.value, 10))}
            leftSection={<IconPlus size="1rem" />}>
            Add to {option.label}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};

export const AddCreatorModalToCreatorSet = ({
  creatorSetId,
  creatorSetOptions,
  handleAddOrUpdateCreatorSetEntry,
  isStaff,
}: {
  creatorSetId: number;
  creatorSetOptions: Record<string, string>[];
  handleAddOrUpdateCreatorSetEntry: (
    creatorDetails: CreatorDetails,
    selectedCreatorSetId: number,
    creatorSetState: CreatorSetEntryState,
  ) => void;
  isStaff: boolean;
}) => {
  const [opened, { open, close }] = useDisclosure(false);
  const [hidden, setHidden] = useState(true);
  const [linkUrl, setLinkUrl] = useInputState("");
  const [youtubeHandle, setYoutubeHandle] = useInputState("");
  const [tiktokHandle, setTiktokHandle] = useInputState("");
  const [instagramHandle, setInstagramHandle] = useInputState("");
  const [firstName, setFirstName] = useInputState("");
  const [lastName, setLastName] = useInputState("");
  const [email, setEmail] = useInputState("");
  const [existingCreatorSetState, setExistingCreatorSetState] = useState(null);

  const [buttonLoading, setButtonLoading] = useState(false);

  const [selectedCreatorSetId, setSelectedCreatorSetId] = useState(creatorSetId);
  const creatorSetName = creatorSetOptions.find(
    (option) => option.value === selectedCreatorSetId?.toString(),
  )?.label;

  useEffect(() => {
    if (!opened) {
      setLinkUrl("");
      setYoutubeHandle("");
      setTiktokHandle("");
      setInstagramHandle("");
      setFirstName("");
      setLastName("");
      setEmail("");
      setExistingCreatorSetState(null);
      setHidden(true);
    }
  }, [opened]);

  return (
    <>
      <Notifications />
      <Modal
        size="lg"
        opened={opened}
        onClose={() => {
          close();
          setHidden(true);
        }}
        title={
          <Text fw="600" size="xl">
            Add Creator to {creatorSetName}
          </Text>
        }>
        <Flex align="flex-end" gap="sm" justify="center">
          <TextInput
            placeholder="https://www.instagram.com/eleanor_the_pug"
            label="Profile URL"
            description="Enter the profile URL of the creator to automatically import the creator details."
            value={linkUrl}
            onChange={(event) => setLinkUrl(event.currentTarget.value)}
            size="sm"
            styles={{
              root: {
                minWidth: "80%",
              },
            }}
          />
          <Button
            loading={buttonLoading}
            onClick={() => {
              setButtonLoading(true);
              setHidden(true);
              fetchCreatorInfoFromLink(linkUrl, null, creatorSetId)
                .then((data) => {
                  const { creator, creator_set_state: creatorSetState } = data;
                  if (creator != null) {
                    if (creator.youtube_handle != null) {
                      setYoutubeHandle(creator.youtube_handle);
                    }
                    if (creator.tiktok_handle != null) {
                      setTiktokHandle(creator.tiktok_handle);
                    }
                    if (creator.instagram_handle != null) {
                      setInstagramHandle(creator.instagram_handle);
                    }
                    if (creator.first_name != null) {
                      setFirstName(creator.first_name);
                    }
                    if (creator.last_name != null) {
                      setLastName(creator.last_name);
                    }
                    if (creator.email != null) {
                      setEmail(creator.email);
                    }
                    if (creatorSetState != null && creatorSetState.state != null) {
                      if (
                        [CreatorSetEntryState.ACCEPTED, CreatorSetEntryState.SAVED].includes(
                          creatorSetState.state,
                        )
                      ) {
                        setExistingCreatorSetState(creatorSetState.state);
                      }
                    }
                    setHidden(false);
                  }
                })
                .finally(() => {
                  setButtonLoading(false);
                });
            }}>
            Import
          </Button>
        </Flex>
        <AddCreatorDetailsToCreatorSet
          hidden={hidden}
          youtubeHandle={youtubeHandle}
          setYoutubeHandle={setYoutubeHandle}
          tiktokHandle={tiktokHandle}
          setTiktokHandle={setTiktokHandle}
          instagramHandle={instagramHandle}
          setInstagramHandle={setInstagramHandle}
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          email={email}
          setEmail={setEmail}
          creatorSetId={selectedCreatorSetId}
          creatorSetName={creatorSetName}
          existingCreatorSetState={existingCreatorSetState}
          handleImport={handleAddOrUpdateCreatorSetEntry}
          close={close}
          isStaff={isStaff}
        />
      </Modal>
      {Number.isNaN(creatorSetId) ? (
        <CreatorSetSelector
          creatorSetOptions={creatorSetOptions}
          setCreatorSetId={setSelectedCreatorSetId}
          open={open}
        />
      ) : (
        <Button leftSection={<IconPlus size="1rem" />} onClick={open}>
          Import Creator
        </Button>
      )}
    </>
  );
};
