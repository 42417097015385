import React, { useEffect, useState } from "react";

import { useParams, useSearchParams } from "react-router-dom";

import { useCampaignAppContext } from "campaigns/CampaignAppShell";
import { fetchCreatorSetById } from "campaigns/api/fetchCreatorSets";
import { CreatorSetSettings } from "components/creator_sets/CreatorSetSettings";
import { CreatorSet } from "components/discovery/Datamodels";

export const CreatorSetBrandSettings = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { creatorSetId } = useParams();

  const [creatorSet, setCreatorSet] = useState<CreatorSet | null>(null);

  useEffect(() => {
    const abortController = new AbortController();
    if (creatorSetId) {
      // update the creatorSet to the one in the URL
      fetchCreatorSetById(parseInt(creatorSetId, 10), abortController).then((fetchedCreatorSet) => {
        setCreatorSet(fetchedCreatorSet);
      });
    }
    return () => {
      abortController.abort();
    };
  }, [creatorSetId]);

  return <CreatorSetSettings creatorSet={creatorSet} />;
};

export default CreatorSetBrandSettings;
