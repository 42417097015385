// API calls for manipulating user backend data
import { ChangeEvent } from "react";
import { API_URL } from "configs/Configs";
import { User } from "models/User";
import { User as FirebaseUser } from "firebase/auth";
import { ErrorInfo, createRequest, handleResult } from "utils/ApiUtils";

const USER_API_URL = `${API_URL}/api/models/users/`;

export const updateUserInfo = async (user: User, firebaseToken: string) => {
  const requestUrl = `${USER_API_URL}${user.key}/`;
  // Instead of passing the entire user object to update, we only want to support updating a subset of fields.
  const updates = {
    key: user.key,
    username: user.username,
    banner_url: user.banner_url,
    avatar_url: user.avatar_url,
    name: user.name,
  };
  const request = new Request(requestUrl.toString(), {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
    body: JSON.stringify(updates),
  });
  return handleResult(request);
};

export const updateUserImage = async (firebaseToken: string, image: File, image_type: string) => {
  const requestUrl = `${USER_API_URL}update_${image_type}/`;
  const formData = new FormData();
  formData.append("file", image);
  const request = new Request(requestUrl.toString(), {
    method: "POST",
    headers: {
      // TODO (leon): figure out the right content type for this
      // "Content-Type": "multipart/form-data",
      // "Content-Type": "image/png",
      // "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
    body: formData,
  });
  const response = await handleResult(request);
  return response;
};

export const handleImageUpload = async (
  requestUser: FirebaseUser,
  event: ChangeEvent<HTMLInputElement>,
  setImageSrc: (imageSrc: string) => void,
  setErrorMessage: (errorMessage: string) => void,
  updateUser: (newProps: Partial<User>) => void,
  imageType: string,
) => {
  const file = event.target.files[0];
  if (file) {
    const firebaseToken = await requestUser.getIdToken();
    try {
      const response = await updateUserImage(firebaseToken, file, imageType);
      if (response) {
        if (response.avatar_url) {
          setImageSrc(response.avatar_url);
        } else if (response.banner_url) {
          setImageSrc(response.banner_url);
        }

        if (updateUser && response.avatar_url && imageType === "avatar") {
          updateUser({ avatar_url: response.avatar_url });
        } else if (updateUser && response.banner_url && imageType === "banner") {
          updateUser({ banner_url: response.banner_url });
        }
      } else {
        setErrorMessage("Failed to update Profile Picture. Please refresh page and try again.");
      }
    } catch (err) {
      const errorInfo: ErrorInfo = JSON.parse(err.message);
      setErrorMessage(errorInfo.result);
    }
  }
};

export const getAllUsers = async (firebaseToken: string) => {
  const request = createRequest({ url: USER_API_URL, firebaseToken });
  return handleResult(request);
};

// gets information about the authenticated user
// TODO (leon): change this to be it's own separate method.
export const getSelf = async (firebaseToken: string) => {
  const requestUrl = `${USER_API_URL}self/`;
  const request = createRequest({ url: requestUrl.toString(), firebaseToken });
  const response = await handleResult(request);
  return response;
};

// returns undefined if user with this email does not exist
// TODO (leon): change this to be a separate retrieve method.
export const getByEmail = async (firebaseToken: string, email: string) => {
  const requestUrl = `${USER_API_URL}?email=${email}`;
  const request = createRequest({ url: requestUrl.toString(), firebaseToken });
  const { results } = await handleResult(request);
  return results[0];
};

export const getByUsername = async (firebaseToken: string, username: string) => {
  const requestURL = new URL(`${USER_API_URL}by_username/`);
  requestURL.searchParams.append("username", username);
  const request = createRequest({ url: requestURL.toString(), firebaseToken });
  const user: User = await handleResult(request);
  return user;
};

export const getByUsernameOrEmail = async (firebaseToken: string, key: string) => {
  const requestURL = new URL(`${USER_API_URL}lookup/`);
  requestURL.searchParams.append("key", key);
  const request = createRequest({ url: requestURL.toString(), firebaseToken });
  const user: User = await handleResult(request);
  return user;
};

// throws a Unauthorized User error if user is not an admin
export const isAdminUser = async (user: FirebaseUser) => {
  const firebaseToken = await user.getIdToken();
  const request = new Request(`${API_URL}/brannan/is_admin`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
  });
  try {
    const response = await fetch(request);
    return response.ok;
  } catch (error) {
    return false;
  }
};

export const isBrandUser = async (user: FirebaseUser) => {
  const firebaseToken = await user.getIdToken();
  const request = new Request(`${API_URL}/brannan/is_brand`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
  });
  try {
    const response = await fetch(request);
    return response.ok;
  } catch (error) {
    return false;
  }
};

export const getAndSetIsAdminUser = async (
  user: FirebaseUser,
  setIsAdmin: (isAdmin: boolean) => void,
) => {
  const isAdmin = await isAdminUser(user);
  if (isAdmin) {
    setIsAdmin(true);
  } else {
    setIsAdmin(false);
  }
};

export const isOpsUser = async (user: FirebaseUser) => {
  const firebaseToken = await user.getIdToken();
  const request = new Request(`${API_URL}/brannan/is_collab_user`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
  });
  try {
    const response = await fetch(request);
    return response.ok;
  } catch (error) {
    return false;
  }
};

export const isVipFeatureAccessUser = (email: string) => {
  const lowerEmail = email.toLowerCase();
  if (lowerEmail.includes("lexie") && lowerEmail.includes("notion.com")) {
    return true;
  }
  if (lowerEmail === "ayami.nakanishi@whatnot.com") {
    return true;
  } else if (lowerEmail.includes("@1stcollab.com")) {
    return true;
  }
  return false;
};
