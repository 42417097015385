import React, { useEffect, useState } from "react";

import {
  ActionIcon,
  Avatar,
  Badge,
  Card,
  Group,
  Menu,
  Modal,
  rem,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";

import { User } from "models/User";
import { IconCheck, IconEdit, IconExternalLink, IconMenu2, IconTrash } from "@tabler/icons-react";
import { OpsTask } from "models/OpsTask";
import { deleteOpsTask, updateOpsTask } from "admin/api/opsApi";
import { useAdminAppContext } from "admin/app/AdminAppShell";
import { OpsEditTaskCard } from "admin/app/ops/OpsEditTaskCard";

const ProfileDetails = ({ currTask }: { currTask: OpsTask }) => {
  const getInitials = (name: string) => {
    if (!name) return "";
    const names = name.split(" ");
    return names[0].charAt(0) + names[names.length - 1].charAt(0);
  };
  return (
    <Group>
      {currTask?.assigned_user_details?.avatar_url ? (
        <Avatar
          w={40}
          h={40}
          src={currTask?.assigned_user_details?.avatar_url}
          style={{ flex: "none" }}
        />
      ) : (
        <Avatar w={40} h={40} style={{ flex: "none" }}>
          {getInitials(currTask?.assigned_user_details?.name)}
        </Avatar>
      )}
      <Text fw={500}>{currTask?.assigned_user_details?.name || "Unassigned"}</Text>
    </Group>
  );
};

export const OpsTaskCard = ({
  opsTask,
  showProfile = true,
}: {
  opsTask: OpsTask;
  showProfile?: boolean;
}) => {
  const firebaseUser = useAdminAppContext()?.user;
  const [opened, { open, close }] = useDisclosure(false);

  const [currTask, setCurrTask] = useState<OpsTask | null>(opsTask);

  useEffect(() => {
    setCurrTask(opsTask);
  }, [opsTask]);

  if (!currTask) return null;

  const markTaskComplete = () => {
    updateOpsTask(firebaseUser, currTask.id, { completed: true }).then((task) => {
      if (task) {
        setCurrTask(task);
      }
    });
  };

  const openDeleteConfirmationModal = () =>
    modals.openConfirmModal({
      title: "Please confirm task deletion",
      centered: true,
      children: <Text size="sm">Are you sure you want to delete this task?</Text>,
      labels: { confirm: "Delete Task", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onConfirm: () => {
        deleteOpsTask(firebaseUser, currTask.id);
        setCurrTask(null);
      },
    });

  return (
    <Stack w="80%">
      <Modal
        size="auto"
        opened={opened}
        onClose={close}
        title={<Text fw="600">Edit Ops Task</Text>}>
        <Stack gap={24}>
          <OpsEditTaskCard opsTask={currTask} setTask={setCurrTask} close={close} />
        </Stack>
      </Modal>
      <Card shadow="xs" padding="md" radius="md" withBorder>
        <Card.Section inheritPadding withBorder py="sm">
          <Stack>
            <Group justify="space-between">
              <Group>
                <Title order={4}>{currTask?.name}</Title>
                {currTask?.completed && (
                  <Badge variant="filled" color="green">
                    Completed
                  </Badge>
                )}
                {currTask?.due_date && (
                  <Badge variant="filled" color="red">
                    Due: {new Date(currTask?.due_date).toLocaleDateString()}
                  </Badge>
                )}
              </Group>
              <Group gap={8}>
                {showProfile && <ProfileDetails currTask={currTask} />}
                <Menu width={160} withinPortal>
                  <Menu.Target>
                    <ActionIcon variant="subtle" color="gray" component="button" size="sm">
                      <IconMenu2 size="sm" />
                    </ActionIcon>
                  </Menu.Target>
                  <Menu.Dropdown>
                    {/* <Menu.Item
                    leftSection={
                      <IconUser style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
                    }>
                    Assign Task
                  </Menu.Item> */}
                    <Menu.Item
                      leftSection={
                        <IconEdit style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
                      }
                      onClick={open}>
                      Edit
                    </Menu.Item>
                    <Menu.Divider />
                    <Menu.Item
                      leftSection={
                        <IconCheck
                          style={{ width: rem(16), height: rem(16) }}
                          color="green"
                          stroke={1.5}
                        />
                      }
                      onClick={markTaskComplete}>
                      Mark Complete
                    </Menu.Item>
                    <Menu.Item
                      leftSection={
                        <IconTrash
                          style={{ width: rem(16), height: rem(16) }}
                          color="red"
                          stroke={1.5}
                        />
                      }
                      onClick={openDeleteConfirmationModal}>
                      Delete
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              </Group>
            </Group>
          </Stack>
        </Card.Section>
        <Card.Section inheritPadding withBorder py="sm">
          <Stack>
            <Group justify="space-between">
              <Text fw={500}>Task Details</Text>
            </Group>
            <Group gap="xs">
              <Badge variant="light" color="cyan">{`Type: ${currTask?.type}`}</Badge>
              {currTask?.campaign && (
                <Badge
                  key={currTask?.campaign.id}
                  variant="light"
                  rightSection={
                    <ActionIcon
                      variant="subtle"
                      component="a"
                      size="xs"
                      href={`/admin/brands?brandId=${currTask?.campaign.brand_id}&campaignId=${opsTask?.campaign.id}`}>
                      <IconExternalLink size="xs" />
                    </ActionIcon>
                  }>
                  {currTask?.campaign.title}
                </Badge>
              )}
              {currTask?.creator_set_details && (
                <Badge
                  key={currTask?.creator_set_details.id}
                  variant="light"
                  rightSection={
                    <ActionIcon
                      variant="subtle"
                      component="a"
                      size="xs"
                      href={`/admin/creator_sets?creatorSetId=${currTask?.creator_set_details.id}`}>
                      <IconExternalLink size="xs" />
                    </ActionIcon>
                  }>
                  {`Creator Set: ${currTask?.creator_set_details.name}`}
                </Badge>
              )}
            </Group>
            <Stack gap={4}>
              <Text fw={300}>Description</Text>
              <Text c="dimmed">{currTask?.description || "None"}</Text>
            </Stack>
            <Group gap={4}>
              <Text fw={300}>Count: </Text>
              <Badge variant="light" color={currTask?.value ? "dark" : "gray"}>
                {currTask?.value || "None"}
              </Badge>
            </Group>
          </Stack>
        </Card.Section>
      </Card>
    </Stack>
  );
};

export default OpsTaskCard;
