import React from "react";

import { Alert, List, Text } from "@mantine/core";

import { IconAlertCircle } from "@tabler/icons-react";

import {
  contractAccepted,
  ContractStatus,
  SUPPORTED_FORMATS_TO_LABELS,
} from "components/contracts/common/Common";

import { SupportedFormat } from "models/Common";

import { toLongDateString, toLongTimeDateString } from "utils/DateUtils";

import { ContractDeliverableStatus } from "components/contracts/models/Deliverable";

export type ActionItemProps = {
  deliverableId: string;
  actionRequired: boolean;
  status: ContractDeliverableStatus;
  format: SupportedFormat;
  nextDueDate: Date;
};

type ActionItemDescription = {
  id: string;
  description: React.ReactNode;
};

function WrappedAlert({ actionItems }: { actionItems: ActionItemDescription[] }) {
  const filteredActionItems = actionItems.filter(
    (item) =>
      item.description !== null && item.description !== undefined && item.description !== "",
  );

  if (filteredActionItems.length === 0) {
    return null;
  }

  return (
    <Alert
      mt="sm"
      radius="md"
      icon={<IconAlertCircle size="1rem" />}
      title="Action Required"
      color="red">
      {filteredActionItems.length > 1 ? (
        <List>
          {filteredActionItems.map((actionItem) => (
            <List.Item key={`${actionItem.id}-actionItem`}>{actionItem.description}</List.Item>
          ))}
        </List>
      ) : (
        filteredActionItems[0].description
      )}
    </Alert>
  );
}

function getActionItemDescription({
  status,
  format,
  nextDueDate,
}: {
  status: ContractDeliverableStatus;
  format: SupportedFormat;
  nextDueDate: Date;
}) {
  let actionItemDescription = null;

  switch (status) {
    case ContractDeliverableStatus.WAITING_FOR_SCRIPT:
      actionItemDescription = "Submit your Concept";
      break;
    case ContractDeliverableStatus.SCRIPT_REVISIONS_REQUESTED:
      actionItemDescription = "Submit revisions to your Concept";
      break;
    case ContractDeliverableStatus.SCRIPT_APPROVED:
    case ContractDeliverableStatus.WAITING_FOR_VIDEO_DRAFT:
      actionItemDescription = "Submit your Video";
      break;
    case ContractDeliverableStatus.VIDEO_DRAFT_REVISIONS_REQUESTED:
      actionItemDescription = "Submit revisions to your Video";
      break;
    case ContractDeliverableStatus.VIDEO_DRAFT_APPROVED:
    case ContractDeliverableStatus.WAITING_FOR_LIVE_CONTENT:
      actionItemDescription = "Submit your Live Content";
      break;
    case ContractDeliverableStatus.LIVE_CONTENT_REVISIONS_REQUESTED:
      actionItemDescription = "Submit revisions to your Live Content";
      break;
    default:
      return null;
  }

  if (actionItemDescription === null) {
    return null;
  }

  return (
    <Text size="sm">
      <Text span fw="500">
        {SUPPORTED_FORMATS_TO_LABELS[format]}
      </Text>{" "}
      - {actionItemDescription} for approval by{" "}
      <Text span td="underline">
        {toLongDateString(nextDueDate)}
      </Text>
      .
    </Text>
  );
}

export default function ActionItems({
  contractId,
  contractStatus,
  expirationDate,
  hasPaymentDetails,
  paymentDetailsComplete,
  allActionItemProps,
}: {
  contractId: string;
  contractStatus: ContractStatus;
  expirationDate: Date;
  hasPaymentDetails: boolean;
  paymentDetailsComplete: boolean;
  allActionItemProps: ActionItemProps[];
}) {
  const actionItems: ActionItemDescription[] = [];

  if (contractStatus === ContractStatus.CREATED || contractStatus === ContractStatus.PENDING) {
    return (
      <WrappedAlert
        actionItems={[
          {
            id: contractId,
            description: (
              <Text size="sm">
                Please review and sign the contract by{" "}
                <Text span fw="500">
                  {toLongTimeDateString(expirationDate)}
                </Text>{" "}
                to begin the brand partnership.
              </Text>
            ),
          },
        ]}
      />
    );
  } else if (contractAccepted(contractStatus) && !hasPaymentDetails) {
    actionItems.push({
      id: contractId,
      description: (
        <Text size="sm">
          You will not be able to receive payment until you add your payment details.
        </Text>
      ),
    });
  } else if (contractAccepted(contractStatus) && hasPaymentDetails && !paymentDetailsComplete) {
    actionItems.push({
      id: contractId,
      description: (
        <Text size="sm">
          You will not be able to receive payment until you finishing submitting your payment details.
        </Text>
      ),
    });
  }

  allActionItemProps.forEach((actionItemProps) => {
    if (actionItemProps.actionRequired) {
      actionItems.push({
        id: actionItemProps.deliverableId,
        description: getActionItemDescription({
          status: actionItemProps.status,
          format: actionItemProps.format,
          nextDueDate: actionItemProps.nextDueDate,
        }),
      } as ActionItemDescription);
    }
  });

  return <WrappedAlert actionItems={actionItems} />;
}
