import React from "react";
import { Anchor, Flex, List, Text } from "@mantine/core";

const TodoListItem = ({
  title,
  ctaText,
  onClick,
}: {
  title: string;
  ctaText: string;
  onClick: () => void;
}) => (
  <List.Item>
    <Flex>
      <Text
        style={{
          fontSize: "14px",
          fontWeight: "400",
          lineHeight: "155%",
        }}>
        {title}&nbsp;
      </Text>
      <Anchor
        style={{
          fontSize: "14px",
          fontWeight: "400",
          lineHeight: "155%",
        }}
        onClick={onClick}>
        {ctaText}
      </Anchor>
    </Flex>
  </List.Item>
);

export default TodoListItem;
