import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import { getOpsTask } from "admin/api/opsApi";
import { OpsTaskCard } from "admin/app/ops/OpsTaskCard";

export const OpsTaskDetails = () => {
  // parse task id from url
  const { taskId } = useParams();

  const [task, setTask] = useState(null);

  useEffect(() => {
    const abortController = new AbortController();
    if (taskId) {
      const taskIdNumber = parseInt(taskId, 10);
      // if taskId is different from the current task.id
      if (taskIdNumber && task?.id !== taskIdNumber) {
        getOpsTask(taskIdNumber, abortController).then((taskResp) => {
          if (taskResp?.id) {
            setTask(taskResp);
            console.log(taskResp);
          } else {
            setTask(null);
          }
        });
      }
    }
    return () => {
      abortController.abort();
    };
  }, [taskId]);

  return <OpsTaskCard opsTask={task} />;
};

export default OpsTaskDetails;
