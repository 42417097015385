import React from "react";
import { Center, Container, Flex, Loader } from "@mantine/core";

import { CampaignMetrics } from "components/campaign/dashboard/CampaignMetrics";
import { useCampaignAppContext } from "campaigns/CampaignAppShell";
import CampaignTodoList from "campaigns/main/campaignPage/CampaignTodoList";
import CampaignOverview from "campaigns/main/campaignPage/CampaignOverview";
import { ReferralLinkType } from "models/Campaign";

export const CampaignResults = () => {
  const { selectedCampaign, selectedAdGroup } = useCampaignAppContext();

  // alert("foobar");
  if (!selectedCampaign?.hash_id) {
    return (
      <Container fluid h="100vh">
        <Center h="100%">
          <Loader color="blue" />
        </Center>
      </Container>
    );
  }

  return (
    <Flex direction="column" gap={12}>
      <CampaignTodoList campaign={selectedCampaign} />
      {/* <CampaignCreatorProgress campaign={selectedCampaign} /> */}
      <CampaignOverview
        campaign={selectedCampaign}
        adGroup={selectedAdGroup}
        tracksClicks={
          (selectedCampaign.referral_link_type ?? ReferralLinkType.NONE) !== ReferralLinkType.NONE
        }
      />
      <CampaignMetrics
        campaignId={selectedCampaign?.hash_id}
        adGroupId={selectedAdGroup != null ? selectedAdGroup.id : null}
        showMetrics
        campaignTracksClicks={
          (selectedCampaign.referral_link_type ?? ReferralLinkType.NONE) !== ReferralLinkType.NONE
        }
      />
    </Flex>
  );
};

export default CampaignResults;
