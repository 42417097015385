import React, { useEffect, useState } from "react";

import { useSearchParams } from "react-router-dom";

import { Flex, Group, Stack } from "@mantine/core";

import { useAdminAppContext } from "admin/app/AdminAppShell";
import { CampaignParameterSettings } from "components/campaignParameters/CampaignParameterSettings";
import { CampaignParametersV2 } from "models/Campaign";
import { fetchCampaignParameterById } from "campaigns/api/fetchCampaignParameters";
import CampaignParameterSelector from "components/campaignParameters/CampaignParameterSelector";

export const AdminCampaignParameterSettings = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { campaignParameters } = useAdminAppContext();

  const [campaignParameterId, setCampaignParameterId] = useState<number | null>(null);
  const [campaignParameter, setCampaignParameter] = useState<CampaignParametersV2 | null>(null);

  useEffect(() => {
    // Whenever the selectedCreatorSetId changes, we should update the campaignParameter
    const abortController = new AbortController();
    if (campaignParameterId != null && campaignParameter?.id !== campaignParameterId) {
      fetchCampaignParameterById(campaignParameterId, abortController).then(
        (fetchedCampaignParameter) => {
          setCampaignParameter(fetchedCampaignParameter);
        },
      );
    }
    // if the campaignParameterId has been cleared, we should clear the campaignParameter
    if (campaignParameterId == null && campaignParameter != null) {
      setCampaignParameterId(null);
    }
    return () => {
      abortController.abort();
    };
  }, [campaignParameterId]);

  return (
    <Stack gap={16}>
      <header
        style={{
          height: "rem(56px)",
          marginBottom: "rem(120px)",
          // backgroundColor: "var(--mantine-color-body)",
          borderBottom:
            "rem(1px) solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4))",
          paddingLeft: "var(--mantine-spacing-md)",
          paddingRight: "var(--mantine-spacing-md)",
        }}>
        <Flex justify="flex-end" align="center" h="rem(56px)">
          <Group>
            <CampaignParameterSelector
              campaignParameters={campaignParameters}
              selectedCampaignParameterId={campaignParameterId}
              setSelectedCampaignParameterId={setCampaignParameterId}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
          </Group>
        </Flex>
      </header>
      {campaignParameter != null && (
        <CampaignParameterSettings campaignParameter={campaignParameter} />
      )}
    </Stack>
  );
};

export default AdminCampaignParameterSettings;
