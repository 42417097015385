enum AuthLoading {
  NOT_LOADING = 1,
  LOADING = 2,
  FINISHED = 3,
}

export const CSRF_COOKIE_NAME = "csrftoken";

export enum AuthPlatform {
  EMAIL,
  GOOGLE,
}

export default AuthLoading;
