import React, { useEffect, useState } from "react";

import { Loader, Paper, Text, Timeline } from "@mantine/core";

import { getContractHistory } from "components/contracts/common/Api";
import ContractHistoryTimelineItem from "components/contracts/history/ContractHistoryTimelineItem";

interface ChangeRecord {
  name: string;
  action: string;
  action_type: number;
  changes: Record<string, string[]>;
  date: string;
}

const AdminContractHistoryTimelineEntry = ({ entry }: { entry: ChangeRecord }) => {
  return (
    <ContractHistoryTimelineItem
      user={entry.name}
      actionType={entry.action_type}
      action={entry.action}
      changes={entry.changes}
      date={entry.date}
    />
  );
};

const AdminContractHistoryTimeline = ({ contractHistory }: { contractHistory: ChangeRecord[] }) => {
  if (!(contractHistory?.length > 0)) {
    return <Text>No history available</Text>;
  }
  return (
    <Timeline
      active={contractHistory?.length > 0 ? contractHistory.length - 1 : -1}
      color="blue"
      bulletSize={36}
      lineWidth={2}>
      {contractHistory.map((entry) => (
        <AdminContractHistoryTimelineEntry
          key={`${entry.date}-${entry.action_type}`}
          entry={entry}
        />
      ))}
    </Timeline>
  );
};

export const AdminContractHistory = ({ contractId }: { contractId: string }) => {
  const [contractHistory, setContractHistory] = useState<ChangeRecord[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const abortController = new AbortController();
    if (contractId) {
      setLoading(true);
      getContractHistory(contractId, abortController)
        .then((resp) => {
          if (resp?.results?.length > 0) {
            setContractHistory(resp.results);
          }
        })
        .finally(() => setLoading(false));
    }
    return () => {
      abortController.abort();
    };
  }, [contractId]);

  return (
    <Paper p="md">
      {loading ? <Loader /> : <AdminContractHistoryTimeline contractHistory={contractHistory} />}
    </Paper>
  );
};

export default AdminContractHistory;
