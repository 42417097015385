import React, { useEffect, useState } from "react";

import {
  Button,
  Center,
  Group,
  LoadingOverlay,
  Paper,
  rem,
  SegmentedControl,
  Select,
  Stack,
  Table,
  Text,
  Title,
  UnstyledButton,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import {
  IconChevronDown,
  IconChevronUp,
  IconExternalLink,
  IconSelector,
} from "@tabler/icons-react";
import { fetchGMVDataForBrand } from "components/contracts/common/Api";
import {
  getUTCYearMonth,
  initializeYearMonth,
  MonthAccountingData,
} from "components/contracts/dashboard/BudgetSummary";
import { deserializeGMVData, getFormattedCurrency } from "components/contracts/dashboard/Utils";
import ViewPaymentsModal from "components/contracts/dashboard/ViewPaymentsModal";
import { GMVData } from "components/contracts/models/GMVData";
import { Payment } from "components/contracts/models/Payment";
import { fromISODateString, toFormattedMonthYearString, toISODateString } from "utils/DateUtils";

interface RowData {
  campaign: string;
  month: Date;
  amtPending?: number;
  amtInProd?: number;
  amtComplete?: number;
  platformFees?: number;
  total?: number;
  allocated?: number;
  payments?: Payment[];
}

interface ThProps {
  children: React.ReactNode;
  reversed: boolean;
  sorted: boolean;
  onSort(): void;
}

function Th({ children, reversed, sorted, onSort }: ThProps) {
  let Icon: React.ElementType;
  if (sorted) {
    if (reversed) {
      Icon = IconChevronUp;
    } else {
      Icon = IconChevronDown;
    }
  } else {
    Icon = IconSelector;
  }

  return (
    <Table.Th>
      <UnstyledButton onClick={onSort}>
        <Group justify="space-between" wrap="nowrap">
          <Text fw={500} fz="sm">
            {children}
          </Text>
          <Center>
            <Icon style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
          </Center>
        </Group>
      </UnstyledButton>
    </Table.Th>
  );
}

function sortData(data: RowData[], payload: { sortBy: keyof RowData | null; reversed: boolean }) {
  const { sortBy } = payload;

  return [...data].sort((a, b) => {
    let compareResult = 0;
    const aVal = a[sortBy];
    const bVal = b[sortBy];

    if (typeof aVal === "number" && typeof bVal === "number") {
      compareResult = (aVal as number) - (bVal as number);
    } else if (aVal instanceof Date && bVal instanceof Date) {
      compareResult = (aVal as Date).getTime() - (bVal as Date).getTime();
    } else {
      compareResult = aVal.toString().localeCompare(bVal.toString());
    }
    return payload.reversed ? compareResult : -compareResult;
  });
}

const TableSortRow = ({ row }: { row: RowData }) => {
  const [opened, { open, close }] = useDisclosure(false);
  return (
    <Table.Tr key={`${row.campaign}-${row.month}`}>
      <Table.Td>{row.campaign}</Table.Td>
      <Table.Td>{toFormattedMonthYearString(row.month)}</Table.Td>
      <Table.Td>{getFormattedCurrency(row.amtPending || 0)}</Table.Td>
      <Table.Td>{getFormattedCurrency(row.amtInProd || 0)}</Table.Td>
      <Table.Td>{getFormattedCurrency(row.amtComplete || 0)}</Table.Td>
      <Table.Td>{getFormattedCurrency(row.platformFees || 0)}</Table.Td>
      <Table.Td>{getFormattedCurrency(row.total || 0)}</Table.Td>
      {/* <Table.Td>{getFormattedCurrency(row.allocated || 0)}</Table.Td> */}
      <Table.Td>
        <ViewPaymentsModal
          payments={row.payments}
          opened={opened}
          close={close}
          campaignName={row.campaign}
          showBrandName={false}
          forBrandView
        />
        <Button
          leftSection={<IconExternalLink size="0.7rem" />}
          size="compact-sm"
          variant="transparent"
          onClick={open}
          disabled={row.payments?.length === 0}>
          View {row.payments?.length} Payment{row.payments?.length === 1 ? "" : "s"}
        </Button>
      </Table.Td>
    </Table.Tr>
  );
};

function TableSort({ rowData }: { rowData: RowData[] }) {
  const [sortedData, setSortedData] = useState(rowData);
  const [sortBy, setSortBy] = useState<keyof RowData | null>(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);

  const setSorting = (field: keyof RowData) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
    setSortedData(sortData(rowData, { sortBy: field, reversed }));
  };

  useEffect(() => {
    // Clear the sort by (even though we set it below)
    setSortBy(null);
    setReverseSortDirection(false);
    // Always impose a default sorting by month
    setSortedData(sortData(rowData, { sortBy: "month", reversed: false }));
    setSortBy("month");
  }, [rowData]);

  const rows = sortedData.map((row: RowData) => (
    <TableSortRow key={`${row.campaign}-${row.month}`} row={row} />
  ));

  return (
    <Table.ScrollContainer minWidth={500}>
      <Table horizontalSpacing="md" verticalSpacing="xs" miw={700} layout="fixed" stickyHeader>
        <Table.Tbody>
          <Table.Tr>
            <Th
              sorted={sortBy === "campaign"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("campaign")}>
              Campaign
            </Th>
            <Th
              sorted={sortBy === "month"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("month")}>
              Month
            </Th>
            <Th
              sorted={sortBy === "amtPending"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("amtPending")}>
              Pending Signature
            </Th>
            <Th
              sorted={sortBy === "amtInProd"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("amtInProd")}>
              In Production
            </Th>
            <Th
              sorted={sortBy === "amtComplete"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("amtComplete")}>
              Complete
            </Th>
            <Th
              sorted={sortBy === "platformFees"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("platformFees")}>
              Platform Fees
            </Th>
            <Th
              sorted={sortBy === "total"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("total")}>
              Total
            </Th>
            {
              // TODO(chris): Reenable this when allocated is ready.
            }
            {/* <Th
              sorted={sortBy === "allocated"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("allocated")}>
              Allocated Budget
            </Th> */}
            <Table.Th />
          </Table.Tr>
        </Table.Tbody>
        <Table.Tbody>{rows}</Table.Tbody>
      </Table>
    </Table.ScrollContainer>
  );
}

const ContractsBudgetTab = () => {
  const [selectedCampaign, setSelectedCampaign] = useState<string | null>("All");
  const [selectedMonth, setSelectedMonth] = useState<string | null>("All");
  const [campaignIdToNameMap, setCampaignIdToNameMap] = useState<Record<string, string>>({});
  // Will be used as the basis for applying filters.
  const [allGMVData, setAllGMVData] = useState<Record<string, GMVData>>({});
  const [filteredGMVData, setFilteredGMVData] = useState<Record<string, GMVData>>({});
  const [aggregateAccountingData, setAggregateAccountingData] = useState<
    Record<string, Record<string, MonthAccountingData>>
  >({});
  const [summaryAccountingData, setSummaryAccountingData] = useState<
    Record<string, MonthAccountingData>
  >({});
  const [tableSortRowData, setTableSortRowData] = useState<RowData[]>([]);

  const [segmentState, setSegmentState] = useState<string>("summary");

  const [isLoading, { open: startLoading, close: endLoading }] = useDisclosure(false);

  const availableCampaigns = ["All"];
  const availableMonths = ["All"];

  useEffect(() => {
    startLoading();
    fetchGMVDataForBrand()
      .then((response) => {
        const { success, gmvData, campaignIdToName } = response;
        if (success) {
          setCampaignIdToNameMap(campaignIdToName);

          const deserializedGMVData = deserializeGMVData(gmvData);
          setAllGMVData(deserializedGMVData);
          // On initial load, we have no filters applied.
          // TODO(chris): perhaps a more limited "filter" (like last 12 months) would be a good default
          setFilteredGMVData(deserializedGMVData);
        }
      })
      .catch(() => {})
      .finally(() => endLoading());
  }, []);

  useEffect(() => {
    // TODO(chris): apply the selected filters to allGMVData to produce filteredGMVData
  }, [selectedCampaign, selectedMonth]);

  useEffect(() => {
    const monthlyAccountingDataByCampaign = {} as Record<
      string,
      Record<string, MonthAccountingData>
    >;

    let monthlyAccountingDataSummary = {} as Record<string, MonthAccountingData>;

    Object.entries(filteredGMVData).forEach(([campaignId, gmvData]) => {
      let monthlyAccountingData = {} as Record<string, MonthAccountingData>;

      // Contracts Pending Signature
      if (gmvData.pendingSignatureEntries) {
        gmvData.pendingSignatureEntries.forEach((entry) => {
          const yearMonth = getUTCYearMonth(fromISODateString(entry[1]));
          monthlyAccountingData = initializeYearMonth({ yearMonth, monthlyAccountingData });
          monthlyAccountingDataSummary = initializeYearMonth({
            yearMonth,
            monthlyAccountingData: monthlyAccountingDataSummary,
          });

          monthlyAccountingData[yearMonth].pendingSignature += entry[0];
          monthlyAccountingData[yearMonth].total += entry[0];

          monthlyAccountingDataSummary[yearMonth].pendingSignature += entry[0];
          monthlyAccountingDataSummary[yearMonth].total += entry[0];
        });
      }

      // Contracts In Progress
      if (gmvData.inProgressEntries) {
        gmvData.inProgressEntries.forEach((entry) => {
          const yearMonth = getUTCYearMonth(fromISODateString(entry[1]));
          monthlyAccountingData = initializeYearMonth({ yearMonth, monthlyAccountingData });
          monthlyAccountingDataSummary = initializeYearMonth({
            yearMonth,
            monthlyAccountingData: monthlyAccountingDataSummary,
          });

          monthlyAccountingData[yearMonth].inProgress += entry[0];
          monthlyAccountingData[yearMonth].total += entry[0];

          monthlyAccountingDataSummary[yearMonth].inProgress += entry[0];
          monthlyAccountingDataSummary[yearMonth].total += entry[0];
        });
      }

      // Contracts In Progress Overdue
      if (gmvData.inProgressOverdueEntries) {
        gmvData.inProgressOverdueEntries.forEach((entry) => {
          const yearMonth = getUTCYearMonth(fromISODateString(entry[1]));
          monthlyAccountingData = initializeYearMonth({ yearMonth, monthlyAccountingData });
          monthlyAccountingDataSummary = initializeYearMonth({
            yearMonth,
            monthlyAccountingData: monthlyAccountingDataSummary,
          });

          monthlyAccountingData[yearMonth].inProgressOverdue += entry[0];
          monthlyAccountingData[yearMonth].total += entry[0];

          monthlyAccountingDataSummary[yearMonth].inProgressOverdue += entry[0];
          monthlyAccountingDataSummary[yearMonth].total += entry[0];
        });
      }

      // Contract Completed
      if (gmvData.completeEntries) {
        gmvData.completeEntries.forEach((entry) => {
          const yearMonth = getUTCYearMonth(fromISODateString(entry[1]));
          monthlyAccountingData = initializeYearMonth({ yearMonth, monthlyAccountingData });
          monthlyAccountingDataSummary = initializeYearMonth({
            yearMonth,
            monthlyAccountingData: monthlyAccountingDataSummary,
          });

          monthlyAccountingData[yearMonth].complete += entry[0];
          monthlyAccountingData[yearMonth].total += entry[0];

          monthlyAccountingDataSummary[yearMonth].complete += entry[0];
          monthlyAccountingDataSummary[yearMonth].total += entry[0];
        });
      }

      // Realized Take Rate
      if (gmvData.realizedTakeEntries) {
        gmvData.realizedTakeEntries.forEach((entry) => {
          const yearMonth = getUTCYearMonth(fromISODateString(entry[1]));
          monthlyAccountingData = initializeYearMonth({ yearMonth, monthlyAccountingData });
          monthlyAccountingDataSummary = initializeYearMonth({
            yearMonth,
            monthlyAccountingData: monthlyAccountingDataSummary,
          });

          monthlyAccountingData[yearMonth].realizedTake += entry[0];
          monthlyAccountingDataSummary[yearMonth].realizedTake += entry[0];
        });
      }

      // Automatic Payments
      if (gmvData.automaticPayments) {
        gmvData.automaticPayments.forEach((payment) => {
          const yearMonth = toISODateString(payment.createdAt).substring(0, 7);
          monthlyAccountingData = initializeYearMonth({ yearMonth, monthlyAccountingData });
          monthlyAccountingDataSummary = initializeYearMonth({
            yearMonth,
            monthlyAccountingData: monthlyAccountingDataSummary,
          });

          monthlyAccountingData[yearMonth].paid += payment.amount;
          monthlyAccountingData[yearMonth].paidThroughStripe += payment.amount;
          monthlyAccountingData[yearMonth].payments.push(payment);

          monthlyAccountingDataSummary[yearMonth].paid += payment.amount;
          monthlyAccountingDataSummary[yearMonth].paidThroughStripe += payment.amount;
          monthlyAccountingDataSummary[yearMonth].payments.push(payment);
        });
      }

      // Manual Payments
      if (gmvData.manualPayments) {
        gmvData.manualPayments.forEach((payment) => {
          const yearMonth = toISODateString(payment.createdAt).substring(0, 7);
          monthlyAccountingData = initializeYearMonth({ yearMonth, monthlyAccountingData });
          monthlyAccountingDataSummary = initializeYearMonth({
            yearMonth,
            monthlyAccountingData: monthlyAccountingDataSummary,
          });

          monthlyAccountingData[yearMonth].paid += payment.amount;
          monthlyAccountingData[yearMonth].paidManually += payment.amount;
          monthlyAccountingData[yearMonth].payments.push(payment);

          monthlyAccountingDataSummary[yearMonth].paid += payment.amount;
          monthlyAccountingDataSummary[yearMonth].paidManually += payment.amount;
          monthlyAccountingDataSummary[yearMonth].payments.push(payment);
        });
      }

      // Off Platform Payments
      if (gmvData.offPlatformPayments) {
        gmvData.offPlatformPayments.forEach((payment) => {
          const yearMonth = toISODateString(payment.createdAt).substring(0, 7);
          monthlyAccountingData = initializeYearMonth({ yearMonth, monthlyAccountingData });
          monthlyAccountingDataSummary = initializeYearMonth({
            yearMonth,
            monthlyAccountingData: monthlyAccountingDataSummary,
          });

          monthlyAccountingData[yearMonth].paid += payment.amount;
          monthlyAccountingData[yearMonth].paidOffPlatform += payment.amount;
          monthlyAccountingData[yearMonth].payments.push(payment);

          monthlyAccountingDataSummary[yearMonth].paid += payment.amount;
          monthlyAccountingDataSummary[yearMonth].paidOffPlatform += payment.amount;
          monthlyAccountingDataSummary[yearMonth].payments.push(payment);
        });
      }
      monthlyAccountingDataByCampaign[campaignId] = monthlyAccountingData;
    });

    setAggregateAccountingData(monthlyAccountingDataByCampaign);
    setSummaryAccountingData(monthlyAccountingDataSummary);
  }, [filteredGMVData]);

  useEffect(() => {
    // Transform the structured aggregation data into row data for the table
    const rowDatas: RowData[] = [];
    if (segmentState === "summary") {
      Object.entries(summaryAccountingData).forEach(([yearMonth, accountingData]) => {
        const rowData: RowData = {
          campaign: "All",
          month: new Date(yearMonth),
          amtPending: accountingData.pendingSignature,
          amtInProd: accountingData.inProgress,
          amtComplete: accountingData.complete,
          platformFees: accountingData.realizedTake,
          total: accountingData.total,
          // TODO(chris): allocated
          payments: accountingData.payments,
        };
        rowDatas.push(rowData);
      });
    } else {
      Object.entries(aggregateAccountingData).forEach(([campaignId, byMonth]) => {
        const campaignName = campaignIdToNameMap[campaignId];
        Object.entries(byMonth).forEach(([yearMonth, accountingData]) => {
          const rowData: RowData = {
            campaign: campaignName,
            month: new Date(yearMonth),
            amtPending: accountingData.pendingSignature,
            amtInProd: accountingData.inProgress,
            amtComplete: accountingData.complete,
            platformFees: accountingData.realizedTake,
            total: accountingData.total,
            // TODO(chris): allocated
            payments: accountingData.payments,
          };
          rowDatas.push(rowData);
        });
      });
    }
    setTableSortRowData(rowDatas);
  }, [summaryAccountingData, aggregateAccountingData, segmentState]);

  return (
    <>
      <LoadingOverlay visible={isLoading} />
      <Stack>
        <Group justify="center" grow>
          <Group justify="flex-start">
            <SegmentedControl
              data={[
                { label: "Summary", value: "summary" },
                { label: "By Campaign", value: "by_campaign" },
              ]}
              value={segmentState}
              onChange={setSegmentState}
            />
          </Group>
          {segmentState !== "summary" && (
            <Group justify="flex-end">
              <Select
                label="Campaign"
                data={availableCampaigns}
                value={selectedCampaign}
                onChange={setSelectedCampaign}
              />
              <Select
                label="Month"
                data={availableMonths}
                value={selectedMonth}
                onChange={setSelectedMonth}
              />
            </Group>
          )}
        </Group>
        <Paper>
          <Stack>
            <Title size="h3">Campaign Spend</Title>
            <TableSort rowData={tableSortRowData} />
          </Stack>
        </Paper>
      </Stack>
    </>
  );
};

export default ContractsBudgetTab;
