import React, { useEffect, useState } from "react";
import {
  ActionIcon,
  Anchor,
  Card,
  CopyButton,
  Divider,
  Group,
  Highlight,
  Loader,
  Modal,
  Space,
  Stack,
  Text,
  Tooltip,
  rem,
} from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import {
  IconAddressBook,
  IconAlertCircle,
  IconBrandInstagram,
  IconBrandTiktok,
  IconBrandYoutubeFilled,
  IconCalendarClock,
  IconCheck,
  IconCopy,
  IconExternalLink,
  IconMail,
  IconPencilQuestion,
} from "@tabler/icons-react";

import { useAppSelector } from "reduxStore/hooks";

import ImportedCreatorDetails from "components/creator/ImportedCreatorDetails";
import { InternalCardSubtitle } from "components/creator/InternalCardTitle";
import {
  fetchCreatorEnrichmentPossibleValues,
  CreatorEnrichmentPossibleValues,
} from "creators/api/creatorDebugApiUtils";
import { fromISODateString } from "utils/DateUtils";
import { truncateText } from "utils/TextUtils";

const BASE_PLATFORM_URL: Record<string, string> = {
  youtube: "https://www.youtube.com/@",
  tiktok: "https://www.tiktok.com/@",
  instagram: "https://www.instagram.com/",
};

const POSSIBLE_HANDLES_BY_PLATFORM: Record<string, string> = {
  youtube: "possible_youtube_handles",
  tiktok: "possible_tiktok_handles",
  instagram: "possible_instagram_handles",
};

const POSSIBLE_LINKS_BY_PLATFORM: Record<string, string> = {
  youtube: "possible_youtube_links",
  tiktok: "possible_tiktok_links",
  instagram: "possible_instagram_links",
};

function getLinkWithProtocol(link: string) {
  // if it doesn't have a protocol, add https
  if (!/^https?:\/\//i.test(link)) {
    return `https://${link}`;
  }
  return link;
}

const ActionAnchor = ({
  copiableText,
  platform,
  externalLink,
}: {
  copiableText: string;
  platform?: string;
  externalLink?: string;
}) => {
  const [visited, setVisited] = useState(false);
  console.log("copiableText", copiableText);

  return (
    <Group gap="xs">
      <Text fw={500} size="sm">
        {copiableText.length > 0 && copiableText}
      </Text>
      <Group gap={2}>
        {copiableText.length > 0 && (
          <CopyButton value={copiableText} timeout={2000}>
            {({ copied, copy }) => {
              let copyText = "";
              if (visited || platform === "email" || platform === "name") {
                copyText = copied ? "Copied" : "Copy";
              } else {
                copyText = "Please click on link to confirm before copying";
              }
              return (
                <Tooltip label={copyText} withArrow position="right">
                  <ActionIcon
                    disabled={platform !== "email" ? !visited : false}
                    color={copied ? "teal" : "gray"}
                    variant="subtle"
                    onClick={copy}>
                    {copied ? (
                      <IconCheck style={{ width: rem(16) }} />
                    ) : (
                      <IconCopy style={{ width: rem(16) }} />
                    )}
                  </ActionIcon>
                </Tooltip>
              );
            }}
          </CopyButton>
        )}
        {platform !== "email" && (
          <ActionIcon
            color="gray"
            component="a"
            href={externalLink}
            target="_blank"
            variant="subtle"
            onClick={() => {
              setVisited(true);
            }}>
            <IconExternalLink color="gray" style={{ width: rem(16) }} />
          </ActionIcon>
        )}
      </Group>
    </Group>
  );
};

const EnrichmentInfo = ({
  platform,
  possibleValues,
}: {
  platform?: string;
  possibleValues: CreatorEnrichmentPossibleValues;
}) => {
  let possibleLinksKey = null;
  let possibleHandlesKey = null;
  let possibleHandleArr = null;
  let possibleLinksArr = null;
  let platformLinks = null;
  let platformIcon = null;
  let variantColor = "";

  if (platform !== "email") {
    possibleHandlesKey = POSSIBLE_HANDLES_BY_PLATFORM[
      platform
    ] as keyof CreatorEnrichmentPossibleValues;
    possibleLinksKey = POSSIBLE_LINKS_BY_PLATFORM[
      platform
    ] as keyof CreatorEnrichmentPossibleValues;

    switch (platform) {
      case "tiktok":
        variantColor = "var(--mantine-color-cyan-4";
        platformIcon = <IconBrandTiktok color={variantColor} size={20} />;
        break;
      case "youtube":
        variantColor = "var(--mantine-color-red-6";
        platformIcon = <IconBrandYoutubeFilled color={variantColor} size={20} />;
        break;
      case "instagram":
        variantColor = "var(--mantine-color-grape-6";
        platformIcon = <IconBrandInstagram color={variantColor} size={20} />;
        break;
      default:
        platformIcon = null;
        variantColor = "blue";
    }
  } else {
    possibleHandlesKey = "possible_emails" as keyof CreatorEnrichmentPossibleValues;
    possibleLinksKey = "possible_bio_links" as keyof CreatorEnrichmentPossibleValues;
    platformIcon = <IconMail size={18} color="var(--mantine-color-gray-7" />;
  }

  possibleHandleArr = possibleValues && (possibleValues[possibleHandlesKey] as string[]);
  possibleLinksArr = possibleValues && (possibleValues[possibleLinksKey] as string[]);

  platformLinks = (
    <>
      <Text size="sm" c="gray.7" fw={500}>
        Links
      </Text>
      {possibleLinksArr && possibleLinksArr.length > 0 ? (
        possibleLinksArr.map((possibleLink) => (
          <Anchor
            size="sm"
            key={`${platform}-link-${possibleLink}`}
            href={getLinkWithProtocol(possibleLink)}
            target="_blank">
            {truncateText(possibleLink, 40)}
          </Anchor>
        ))
      ) : (
        <Text size="sm" c="dimmed">
          Unknown
        </Text>
      )}
    </>
  );

  return (
    <Stack
      align="flex-start"
      justify="space-between"
      gap={possibleHandleArr?.length > 0 ? 3 : "xs"}>
      <Group gap="xs">
        <Text size="sm" c="gray.7" fw={500}>
          {platform.charAt(0).toUpperCase() + platform.slice(1)} Info
        </Text>
        {platformIcon}
      </Group>
      {possibleHandleArr && possibleHandleArr?.length > 0 ? (
        possibleHandleArr.map((possibleHandle) => {
          let platformHandle = "";
          let char = "";
          if (platform !== "email") {
            [char, platformHandle] = possibleHandle.split("@");
          } else {
            platformHandle = possibleHandle;
          }

          return (
            <ActionAnchor
              key={`${platform}-handle-${possibleHandle}`}
              copiableText={possibleHandle}
              platform={platform}
              externalLink={
                platform === "email" ? null : `${BASE_PLATFORM_URL[platform]}${platformHandle}`
              }
            />
          );
        })
      ) : (
        <Text size="sm" c="dimmed">
          Unknown
        </Text>
      )}
      {possibleHandleArr ? <Space h="xs" /> : <Space h="lg" />}
      {platform !== "email" && platformLinks}
    </Stack>
  );
};

const CreatorEnrichmentPossibleValuesDebug = ({ baseCreatorId }: { baseCreatorId: number }) => {
  const [possibleValues, setPossibleValues] = useState<CreatorEnrichmentPossibleValues | null>(
    null,
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const abortController = new AbortController();
    if (baseCreatorId) {
      setLoading(true);
      fetchCreatorEnrichmentPossibleValues(baseCreatorId, abortController)
        .then((response) => {
          if (response?.data) {
            setPossibleValues(response.data);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [baseCreatorId]);

  if (loading) {
    return <Loader />;
  }
  return (
    <Stack>
      <Group>
        <Text size="lg" fw={700}>
          Candidate Enrichment Details (Auto-Generated)
        </Text>
        {possibleValues?.last_enrichment_update ? (
          <Tooltip
            label={`Last enriched on ${fromISODateString(
              possibleValues.last_enrichment_update,
            ).toLocaleDateString()}`}>
            <IconCalendarClock size="1.5rem" color="green" />
          </Tooltip>
        ) : (
          <Tooltip label="Missing enrichment data">
            <IconAlertCircle size="1.5rem" color="red" />
          </Tooltip>
        )}
      </Group>
      <Highlight highlight="Please do not copy without double-checking! These values may be wrong">
        Please do not copy without double-checking! These values may be wrong.
      </Highlight>

      <Card p={20} withBorder>
        <Stack>
          <InternalCardSubtitle
            title="Possible Platform Info"
            icon={<IconPencilQuestion size={20} />}
          />
          <Group align="flex-start" justify="space-between">
            <EnrichmentInfo possibleValues={possibleValues} platform="youtube" />
            <EnrichmentInfo possibleValues={possibleValues} platform="tiktok" />
            <EnrichmentInfo possibleValues={possibleValues} platform="instagram" />
          </Group>
        </Stack>
      </Card>
      <Card p={20} withBorder>
        <Stack>
          <InternalCardSubtitle
            title="Possible Contact and Link Info"
            icon={<IconAddressBook size={20} />}
          />
          <Stack>
            <Group align="flex-start" justify="space-between">
              <Stack align="flex-start" justify="space-between" gap="xs">
                <Group gap="xs">
                  <Text size="sm" c="gray.7" fw={500}>
                    First Name
                  </Text>
                </Group>
                {possibleValues?.possible_first_names &&
                possibleValues?.possible_first_names?.length > 0 ? (
                  possibleValues?.possible_first_names.map((possibleName) => (
                    <Text key={possibleName} size="sm">
                      {possibleName}
                    </Text>
                  ))
                ) : (
                  <Text size="sm" c="dimmed">
                    Unknown
                  </Text>
                )}
              </Stack>
              <Stack align="flex-start" justify="space-between" gap="xs">
                <Group gap="xs">
                  <Text size="sm" c="gray.7" fw={500}>
                    Last Name
                  </Text>
                </Group>
                {possibleValues?.possible_last_names &&
                possibleValues?.possible_last_names?.length > 0 ? (
                  possibleValues?.possible_last_names.map((possibleName) => (
                    <Text key={possibleName} size="sm">
                      {possibleName}
                    </Text>
                  ))
                ) : (
                  <Text size="sm" c="dimmed">
                    Unknown
                  </Text>
                )}
              </Stack>
              <EnrichmentInfo possibleValues={possibleValues} platform="email" />
            </Group>
            <Stack gap="xs">
              <Text size="sm" c="gray.7" fw={500}>
                Links
              </Text>
              {possibleValues?.possible_bio_links ? (
                possibleValues?.possible_bio_links.map((possibleLink) => (
                  <Anchor
                    size="sm"
                    key={`bio-link-${possibleLink}`}
                    href={getLinkWithProtocol(possibleLink)}
                    target="_blank">
                    {truncateText(possibleLink, 40)}
                  </Anchor>
                ))
              ) : (
                <Text size="sm" c="dimmed">
                  Unknown
                </Text>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Card>
    </Stack>
  );
};

const CreatorMergeModal = ({
  baseCreatorId,
  youtubeHandle,
  tiktokHandle,
  instagramHandle,
  firstName,
  lastName,
  email,
  opened,
  close,
  couldNotFindYoutube,
  couldNotFindTiktok,
  couldNotFindInstagram,
  couldNotFindFirstName,
  couldNotFindEmail,
}: {
  baseCreatorId: string | number;
  youtubeHandle?: string;
  tiktokHandle?: string;
  instagramHandle?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  opened: boolean;
  close: () => void;
  couldNotFindYoutube: boolean;
  couldNotFindTiktok: boolean;
  couldNotFindInstagram: boolean;
  couldNotFindFirstName: boolean;
  couldNotFindEmail: boolean;
}) => {
  const currentUser = useAppSelector((state) => state.me.user);
  const isStaff = currentUser?.is_staff || false;
  const BASE_DETAILS_TITLE = "Showing Existing Creator Details";

  const BASE_DETAILS_SUBTITLE = (
    <Highlight color="green" highlight="green">
      Showing details of base creator. Fields changed from base will be highlighted in green.
    </Highlight>
  );

  return (
    <>
      <Notifications />
      <Modal opened={opened} onClose={close} withCloseButton={false} size="auto">
        <Stack gap="md">
          <Group mt={5} align="stretch" justify="center" grow>
            <ImportedCreatorDetails
              baseCreatorId={baseCreatorId}
              close={close}
              title={BASE_DETAILS_TITLE}
              subtitle={BASE_DETAILS_SUBTITLE}
              hidden={false}
              youtubeHandle={youtubeHandle}
              tiktokHandle={tiktokHandle}
              instagramHandle={instagramHandle}
              firstName={firstName}
              lastName={lastName}
              email={email}
              couldNotFindYoutube={couldNotFindYoutube}
              couldNotFindTiktok={couldNotFindTiktok}
              couldNotFindInstagram={couldNotFindInstagram}
              couldNotFindFirstName={couldNotFindFirstName}
              couldNotFindEmail={couldNotFindEmail}
            />
          </Group>
          <Divider />
          <CreatorEnrichmentPossibleValuesDebug baseCreatorId={baseCreatorId as number} />
        </Stack>
      </Modal>
    </>
  );
};

export default CreatorMergeModal;
