import React, { useEffect, useRef } from "react";

import {
  Text,
  Button,
  Modal,
  TagsInput,
  Tooltip,
  Stack,
  Group,
  ThemeIcon,
  Flex,
} from "@mantine/core";

import { IconInfoCircle, IconMail } from "@tabler/icons-react";

import { validateEmailList } from "utils/ValidationUtils";
import {
  sendEmailAfterSigningContract,
  updateAdditionalEmails,
} from "components/contracts/common/Api";
import { useDisclosure } from "@mantine/hooks";
import {
  showFailureNotification,
  showSuccessNotification,
} from "components/common/Notifications";

function EmailNotificationSetting({
  additionalEmails,
  setAdditionalEmails,
  handleSave,
  handleCancel,
  cancelButton,
}: {
  additionalEmails: string[];
  cancelButton: string;
  setAdditionalEmails: (value: string[]) => void;
  handleSave: () => void;
  handleCancel: () => void;
}) {
  const handleEmailChange = (emails: string[]) => {
    setAdditionalEmails(emails);
  };
  const initialEmailsRef = useRef(additionalEmails);
  return (
    <div>
      <Stack gap="sm">
        <TagsInput
          label=""
          w={350}
          placeholder={additionalEmails.length === 0 ? "Optional" : null}
          value={additionalEmails}
          onChange={handleEmailChange}
          error={
            !validateEmailList(additionalEmails)
              ? "Invalid Email Addresses was added, please remove it."
              : null
          }
          splitChars={[",", " "]}
        />
        <Group justify="right">
          <Button variant="default" onClick={handleCancel}>
            {cancelButton}
          </Button>
          <Button
            type="button"
            color="blue"
            onClick={handleSave}
            disabled={
              !validateEmailList(additionalEmails) ||
              JSON.stringify(additionalEmails) === JSON.stringify(initialEmailsRef.current)
            }>
            Save
          </Button>
        </Group>
      </Stack>
    </div>
  );
}

export default function EmailNotificationButton({
  contractId,
  additionalEmails,
  setAddtionalEmails,
  defaultEmail,
  justSigned,
  setJustSigned,
}: {
  contractId: string;
  additionalEmails: string[];
  setAddtionalEmails: (value: string[]) => void;
  defaultEmail: string;
  justSigned: boolean;
  setJustSigned: (justSigned: boolean) => void;
}) {
  const [opened, { open, close }] = useDisclosure();
  const cancelButton = justSigned ? "Skip" : "Cancel";
  const handleSaveEmailNotification = () => {
    updateAdditionalEmails(contractId, additionalEmails).then((response) => {
      if (response.success) {
        showSuccessNotification({ message: "Email Notifications Saved." });
      } else {
        showFailureNotification({ message: "Email Notifications failed to save." });
      }

      close();
      // Save Email notification settings
      if (justSigned) {
        setJustSigned(false);
        sendEmailAfterSigningContract(contractId).then(() => {
          window.location.reload();
        });
      }
    });
  };

  const handleCancel = () => {
    close();
    if (justSigned) {
      setJustSigned(false);
      sendEmailAfterSigningContract(contractId).then(() => {
        window.location.reload();
      });
    }
  };

  useEffect(() => {
    if (justSigned) {
      open();
    }
  });
  const toolTip = (
    <Tooltip label="Notifications are always sent to the email address used to sign this agreement.">
      <ThemeIcon variant="subtle" size="xs">
        <IconInfoCircle />
      </ThemeIcon>
    </Tooltip>
  );
  return (
    <>
      <Modal
        closeOnClickOutside={!justSigned}
        opened={opened}
        onClose={handleCancel}
        title={
          <Text size="lg" fw="600">
            Email Notification Settings
          </Text>
        }
        transitionProps={{ transition: "fade", duration: 200 }}>
        <Stack gap="sm">
          <Flex align="center" justify="flex-start">
            <b>Default Email Address:</b>&nbsp;
            {defaultEmail}&nbsp;
            {toolTip}
          </Flex>
          <Text>
            Add any additional email addresses to receive reminders and notifications for this
            partnership
          </Text>
          <EmailNotificationSetting
            additionalEmails={additionalEmails}
            setAdditionalEmails={setAddtionalEmails}
            handleSave={handleSaveEmailNotification}
            handleCancel={handleCancel}
            cancelButton={cancelButton}
          />
        </Stack>
      </Modal>
      <Tooltip label="Email Notification Settings">
        <Button style={{ padding: "0 10px" }} onClick={open} variant="outline">
          <IconMail size="1.5rem" />
        </Button>
      </Tooltip>
    </>
  );
}
