import React from "react";

import { Card, Group, Stack, Text } from "@mantine/core";

import { CreatorSet } from "components/discovery/Datamodels";
import { OpsTask } from "models/OpsTask";
import { OpsCreatorSetTasksTable } from "admin/app/ops/OpsCreatorSetTasksTable";

export const OpsCreatorSetTasks = ({
  creatorSet,
  opsTasks,
}: {
  creatorSet: CreatorSet;
  opsTasks: OpsTask[];
}) => {
  // filter tasks to only the creator set
  const creatorSetTasks = opsTasks.filter(
    (task) => task?.creator_set_details?.id === creatorSet.id,
  );

  return (
    <Stack w="100%">
      <Card shadow="xs" padding="md" radius="md" withBorder>
        <Card.Section inheritPadding withBorder py="sm">
          <Group justify="space-between">
            <Text fw={500}>{creatorSet.name}</Text>
          </Group>
        </Card.Section>
        <Card.Section inheritPadding withBorder py="sm">
          <OpsCreatorSetTasksTable
            key={creatorSet?.id}
            creatorSet={creatorSet}
            opsTasks={creatorSetTasks}
          />
        </Card.Section>
      </Card>
    </Stack>
  );
};

export default OpsCreatorSetTasks;
