import React from "react";

import { Badge, Box, Card, Group, Stack, Text } from "@mantine/core";

import { ContentStatus } from "components/contracts/common/Common";
import Script from "components/contracts/models/Script";

import { SupportedPlatform, SUPPORTED_PLATFORMS_TO_DISPLAY_NAMES } from "models/Common";

import PlatformLogo from "components/contracts/common/PlatformLogo";
import ScriptContent from "components/contracts/common/ScriptContent";

import { toShortDateString, toLongDateString } from "utils/DateUtils";

function ReviewStatusBadge({
  isCreator,
  status,
  reviewDate,
}: {
  isCreator: boolean;
  status: ContentStatus;
  reviewDate?: Date;
}) {
  if (status === ContentStatus.APPROVED) {
    return (
      <Badge color="teal" variant="light">
        Approved{reviewDate ? ` on ${toShortDateString(reviewDate)}` : ""}
      </Badge>
    );
  } else if (status === ContentStatus.REVISIONS_REQUESTED) {
    return (
      <Badge color={isCreator ? "red" : "yellow"} variant="light">
        Revision Requested{reviewDate ? ` on ${toShortDateString(reviewDate)}` : ""}
      </Badge>
    );
  } else if (status === ContentStatus.PENDING_REVIEW) {
    return (
      <Badge color={isCreator ? "yellow" : "red"} variant="light">
        {isCreator ? "Under Review" : "Review Required"}
      </Badge>
    );
  }

  // This code should be unreachable.
  return null;
}

export default function ScriptCard({
  brandName,
  platform,
  script,
  isCreator,
}: {
  brandName: string;
  platform: SupportedPlatform;
  script: Script;
  isCreator: boolean;
}) {
  return (
    <Card shadow="xs" radius="sm" withBorder>
      <Stack>
        <Group justify="space-between" align="flex-start">
          <Group justify="left" align="center">
            <PlatformLogo platform={platform} />
            <Box>
              <Text>
                <Text fw="500">
                  {SUPPORTED_PLATFORMS_TO_DISPLAY_NAMES[platform]} Concept for {brandName}{" "}
                  Partnership
                </Text>
                <Text size="sm" c="dimmed">
                  Version #{script.version} - Submitted on {toLongDateString(script.submissionDate)}
                </Text>
              </Text>
            </Box>
          </Group>
          <ReviewStatusBadge
            isCreator={isCreator}
            status={script.status}
            reviewDate={script.reviewDate}
          />
        </Group>
        <ScriptContent script={script} />
      </Stack>
    </Card>
  );
}
