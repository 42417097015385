import React, { useState } from "react";

import { Badge, Flex, Tabs, Text } from "@mantine/core";

import {
  IconBookmarks,
  IconCircleCheck,
  IconEyeOff,
  IconListSearch,
} from "@tabler/icons-react";

import {
  ActiveTab,
  CreatorSetEntryStates,
  LoggingMetadata,
  SearchMethod,
} from "components/discovery/search/Common";
import { SortBy } from "components/discovery/search/SortSelector";
import SearchResults from "components/discovery/search/SearchResults";

export default function TabbedSearchResults({
  sessionId,
  queryId,
  unhiddenCreatorIds,
  savedCreatorIds,
  activatedCreatorIds,
  hiddenCreatorIds,
  loadingResults,
  submittedQuery,
  selectedSearchMethod,
  creatorSetOptions,
  creatorSetEntryStates,
  hiddenCreatorIdsSet,
  sortBy,
  handleAddCreatorSet,
  setRateLimitExceeded,
  setLimitedUsage,
  setNumSearchesRemaining,
  setCreatorSetEntryStates,
  addActivatedCreatorId,
  addSavedCreatorId,
  removeSavedCreatorId,
  hideCreatorId,
  unhideCreatorId,
  fetchRelatedCreators,
}: {
  sessionId: string;
  queryId: string;
  unhiddenCreatorIds: number[];
  savedCreatorIds: number[];
  activatedCreatorIds: number[];
  hiddenCreatorIds: number[];
  loadingResults: boolean;
  submittedQuery: string;
  selectedSearchMethod: SearchMethod;
  creatorSetOptions: Record<string, string>[];
  creatorSetEntryStates: CreatorSetEntryStates;
  hiddenCreatorIdsSet: Set<number>;
  sortBy: SortBy;
  handleAddCreatorSet: (creatorSetId: number, creatorSetName: string) => void;
  setRateLimitExceeded: (value: boolean) => void;
  setLimitedUsage: (value: boolean) => void;
  setNumSearchesRemaining: (value: number) => void;
  setCreatorSetEntryStates: (value: CreatorSetEntryStates) => void;
  addActivatedCreatorId: (value: number) => void;
  addSavedCreatorId: (value: number) => void;
  removeSavedCreatorId: (value: number) => void;
  hideCreatorId: (
    value: number,
    setLoading: (loading: boolean) => void,
    loggingMetadata?: LoggingMetadata,
  ) => void;
  unhideCreatorId: (
    value: number,
    setLoading: (loading: boolean) => void,
    loggingMetadata?: LoggingMetadata,
  ) => void;
  fetchRelatedCreators: (value: string) => void;
}) {
  // const theme = useMantineTheme();
  const [activeTab, setActiveTab] = useState<ActiveTab>(ActiveTab.RESULTS);
  // const [opened, { open, close }] = useDisclosure();

  // const dispatch = useAppDispatch();

  return (
    <Tabs
      variant="outline"
      value={activeTab}
      onChange={(value: string) => {
        setActiveTab(value as ActiveTab);
      }}>
      <Flex justify="space-between">
        <Tabs.List mb="md" w="100%">
          <Tabs.Tab value={ActiveTab.RESULTS} leftSection={<IconListSearch size="1rem" />}>
            <Text fw="500">Results</Text>
          </Tabs.Tab>
          <Tabs.Tab
            value={ActiveTab.SAVED}
            leftSection={<IconBookmarks size="1rem" />}
            disabled={savedCreatorIds.length === 0}>
            <Flex gap="xs" align="center">
              <Text fw="500">Saved</Text>
              <Badge
                size="xs"
                radius="xl"
                circle={savedCreatorIds.length < 10}
                color={savedCreatorIds.length > 0 ? "red" : "gray"}>
                {savedCreatorIds.length}
              </Badge>
            </Flex>
          </Tabs.Tab>
          <Tabs.Tab
            value={ActiveTab.ACTIVATED}
            leftSection={<IconCircleCheck size="1rem" />}
            disabled={activatedCreatorIds.length === 0}>
            <Flex gap="xs" align="center">
              <Text fw="500">Activated</Text>
              <Badge
                size="xs"
                radius="xl"
                circle={activatedCreatorIds.length < 10}
                color={activatedCreatorIds.length > 0 ? "red" : "gray"}>
                {activatedCreatorIds.length}
              </Badge>
            </Flex>
          </Tabs.Tab>
          <Tabs.Tab
            value={ActiveTab.HIDDEN}
            leftSection={<IconEyeOff size="1rem" />}
            disabled={hiddenCreatorIds.length === 0}>
            <Flex gap="xs" align="center">
              <Text fw="500">Hidden</Text>
              <Badge
                size="xs"
                radius="xl"
                circle={hiddenCreatorIds.length < 10}
                color={hiddenCreatorIds.length > 0 ? "red" : "gray"}>
                {hiddenCreatorIds.length}
              </Badge>
            </Flex>
          </Tabs.Tab>
        </Tabs.List>
        {/* <Box
          style={{
            borderBottom: `1px solid ${theme.colors.gray[3]}`,
          }}
          mb="md"
          pr="xs">
          <AddCreatorSetModal
            handleAddNewCreatorSet={(creatorSetItem: CreatorSetItem) => {
              dispatch(addNewCreatorSetToState({ creatorSet: creatorSetItem }));
              handleAddCreatorSet(creatorSetItem.id, creatorSetItem.name);
            }}
            opened={opened}
            close={close}
          />
          <Button onClick={open} leftSection={<IconPlus size="1rem" />}>
            New Creator Set
          </Button>
        </Box> */}
      </Flex>
      <Tabs.Panel value={ActiveTab.RESULTS}>
        <SearchResults
          isActive={activeTab === ActiveTab.RESULTS.valueOf()}
          sessionId={sessionId}
          queryId={queryId}
          creatorIds={unhiddenCreatorIds}
          loadingResults={loadingResults}
          submittedQuery={submittedQuery}
          selectedSearchMethod={selectedSearchMethod}
          creatorSetOptions={creatorSetOptions}
          creatorSetEntryStates={creatorSetEntryStates}
          hiddenCreatorIdsSet={hiddenCreatorIdsSet}
          sortBy={sortBy}
          activeTab={activeTab}
          handleAddCreatorSet={handleAddCreatorSet}
          setRateLimitExceeded={setRateLimitExceeded}
          setLimitedUsage={setLimitedUsage}
          setNumSearchesRemaining={setNumSearchesRemaining}
          setCreatorSetEntryStates={setCreatorSetEntryStates}
          addActivatedCreatorId={addActivatedCreatorId}
          addSavedCreatorId={addSavedCreatorId}
          removeSavedCreatorId={removeSavedCreatorId}
          hideCreatorId={hideCreatorId}
          unhideCreatorId={unhideCreatorId}
          fetchRelatedCreators={fetchRelatedCreators}
          hideCreators
        />
      </Tabs.Panel>
      <Tabs.Panel value={ActiveTab.SAVED}>
        <SearchResults
          isActive={activeTab === ActiveTab.SAVED.valueOf()}
          sessionId={sessionId}
          queryId={queryId}
          creatorIds={savedCreatorIds}
          loadingResults={loadingResults}
          submittedQuery={submittedQuery}
          selectedSearchMethod={selectedSearchMethod}
          creatorSetOptions={creatorSetOptions}
          creatorSetEntryStates={creatorSetEntryStates}
          hiddenCreatorIdsSet={hiddenCreatorIdsSet}
          sortBy={sortBy}
          activeTab={activeTab}
          handleAddCreatorSet={handleAddCreatorSet}
          setRateLimitExceeded={setRateLimitExceeded}
          setLimitedUsage={setLimitedUsage}
          setNumSearchesRemaining={setNumSearchesRemaining}
          setCreatorSetEntryStates={setCreatorSetEntryStates}
          addActivatedCreatorId={addActivatedCreatorId}
          addSavedCreatorId={addSavedCreatorId}
          removeSavedCreatorId={removeSavedCreatorId}
          hideCreatorId={hideCreatorId}
          unhideCreatorId={unhideCreatorId}
          fetchRelatedCreators={fetchRelatedCreators}
        />
      </Tabs.Panel>
      <Tabs.Panel value={ActiveTab.ACTIVATED}>
        <SearchResults
          isActive={activeTab === ActiveTab.ACTIVATED.valueOf()}
          sessionId={sessionId}
          queryId={queryId}
          creatorIds={activatedCreatorIds}
          loadingResults={loadingResults}
          submittedQuery={submittedQuery}
          selectedSearchMethod={selectedSearchMethod}
          creatorSetOptions={creatorSetOptions}
          creatorSetEntryStates={creatorSetEntryStates}
          hiddenCreatorIdsSet={hiddenCreatorIdsSet}
          sortBy={sortBy}
          activeTab={activeTab}
          handleAddCreatorSet={handleAddCreatorSet}
          setRateLimitExceeded={setRateLimitExceeded}
          setLimitedUsage={setLimitedUsage}
          setNumSearchesRemaining={setNumSearchesRemaining}
          setCreatorSetEntryStates={setCreatorSetEntryStates}
          addActivatedCreatorId={addActivatedCreatorId}
          addSavedCreatorId={addSavedCreatorId}
          removeSavedCreatorId={removeSavedCreatorId}
          hideCreatorId={hideCreatorId}
          unhideCreatorId={unhideCreatorId}
          fetchRelatedCreators={fetchRelatedCreators}
        />
      </Tabs.Panel>
      <Tabs.Panel value={ActiveTab.HIDDEN}>
        <SearchResults
          isActive={activeTab === ActiveTab.HIDDEN.valueOf()}
          sessionId={sessionId}
          queryId={queryId}
          creatorIds={hiddenCreatorIds}
          loadingResults={loadingResults}
          submittedQuery={submittedQuery}
          selectedSearchMethod={selectedSearchMethod}
          creatorSetOptions={creatorSetOptions}
          creatorSetEntryStates={creatorSetEntryStates}
          hiddenCreatorIdsSet={hiddenCreatorIdsSet}
          sortBy={sortBy}
          activeTab={activeTab}
          handleAddCreatorSet={handleAddCreatorSet}
          setRateLimitExceeded={setRateLimitExceeded}
          setLimitedUsage={setLimitedUsage}
          setNumSearchesRemaining={setNumSearchesRemaining}
          setCreatorSetEntryStates={setCreatorSetEntryStates}
          addActivatedCreatorId={addActivatedCreatorId}
          addSavedCreatorId={addSavedCreatorId}
          removeSavedCreatorId={removeSavedCreatorId}
          hideCreatorId={hideCreatorId}
          unhideCreatorId={unhideCreatorId}
          fetchRelatedCreators={fetchRelatedCreators}
        />
      </Tabs.Panel>
    </Tabs>
  );
}
