import React, { useEffect, useState } from "react";
import { Campaign } from "models/Campaign";
import { getAndSetCampaigns } from "components/campaign/CampaignUtils";
import { useAdminAppContext } from "admin/app/AdminAppShell";
import SortableTable, { SortableTableColumnProps } from "components/SortableTable";
import CampaignRow from "components/campaign/dashboard/CampaignRow";

export default function CampaignsList() {
  const { user } = useAdminAppContext();
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);

  useEffect(() => {
    const abortController = new AbortController();
    getAndSetCampaigns(user, setCampaigns, abortController);
    return () => {
      abortController.abort();
    };
  }, []);

  type ColumnFields = "brand_name" | "title" | "id" | "date_created" | "is_active" | "budget";

  const columns: SortableTableColumnProps<Campaign, ColumnFields>[] = [
    { label: "Brand", field: "brand_name", alignLeft: true },
    { label: "Campaign Name", field: "title" },
    { label: "Campaign Id", field: "id" },
    { label: "Created", field: "date_created" },
    { label: "Status", field: "is_active" },
    { label: "Budget", field: "budget", alignLeft: true },
  ];

  return (
    <SortableTable<Campaign, ColumnFields>
      data={campaigns}
      renderRow={(campaign) => <CampaignRow key={campaign.id} user={user} campaign={campaign} />}
      sortableCols={columns}
      regCols={[
        "Budget Type",
        "Brand Campaign Dashboard",
        "Admin Content Review",
        "Internal Review Page",
        "Campaign Settings",
        "CSV Dashboard",
      ]}
    />
  );
}
