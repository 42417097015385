import Deliverable from "components/contracts/models/Deliverable";

import { fromISODateString } from "utils/DateUtils";

import { ContentStatus, UploadStatus } from "components/contracts/common/Common";

export default class DeliverableVideo {
  dateCreated: Date;

  deliverable: Deliverable;

  videoId: string;

  videoDateCreated: Date;

  videoLastUpdated: Date;

  location: string;

  transcodedVideoLocation: string;

  name: string;

  contentStatus: ContentStatus;

  reviewDate: Date;

  uploadStatus: UploadStatus;

  title: string;

  caption: string;

  integrationTimestamp: number;

  constructor({
    dateCreated,
    deliverable,
    videoId,
    videoDateCreated,
    videoLastUpdated,
    location,
    transcodedVideoLocation,
    name,
    contentStatus,
    reviewDate,
    uploadStatus,
    title,
    caption,
    integrationTimestamp,
  }: {
    dateCreated: Date;
    deliverable: Deliverable;
    videoId: string;
    videoDateCreated: Date;
    videoLastUpdated: Date;
    location: string;
    transcodedVideoLocation: string;
    name: string;
    contentStatus: ContentStatus;
    reviewDate: Date;
    uploadStatus: UploadStatus;
    title: string;
    caption: string;
    integrationTimestamp: number;
  }) {
    this.dateCreated = dateCreated;
    this.deliverable = deliverable;
    this.videoId = videoId;
    this.videoDateCreated = videoDateCreated;
    this.videoLastUpdated = videoLastUpdated;
    this.location = location;
    this.transcodedVideoLocation = transcodedVideoLocation;
    this.name = name;
    this.contentStatus = contentStatus;
    this.reviewDate = reviewDate;
    this.uploadStatus = uploadStatus;
    this.title = title;
    this.caption = caption;
    this.integrationTimestamp = integrationTimestamp;
  }

  public static deserialize(response: any): DeliverableVideo {
    const {
      deliverable,
      video,
      status,
      dateCreated,
      reviewDate,
      title,
      caption,
      integrationTimestamp,
    } = response;

    return new DeliverableVideo({
      dateCreated: fromISODateString(dateCreated),
      deliverable: Deliverable.deserialize(deliverable),
      videoId: video.id,
      videoDateCreated: fromISODateString(video.dateCreated),
      videoLastUpdated: fromISODateString(video.lastUpdated),
      location: video.location,
      transcodedVideoLocation: video.transcodedVideoLocation,
      name: video.name,
      contentStatus: status as ContentStatus,
      reviewDate: fromISODateString(reviewDate),
      uploadStatus: video.uploadStatus as UploadStatus,
      title,
      caption,
      integrationTimestamp,
    });
  }
}
