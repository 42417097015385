import { Box, Center, Container, Flex, Image, Stack, Text } from "@mantine/core";
import React from "react";
import quora from "pages/home/v2/assets/images/quora.svg";
import gamma from "pages/home/v2/assets/images/gamma.svg";
import earnbetter from "pages/home/v2/assets/images/earnbetter.svg";
import kikoff from "pages/home/v2/assets/images/kikoff.svg";

import taskade from "pages/home/v2/assets/images/taskade.svg";
import characterai from "pages/home/v2/assets/images/characterai.svg";
import curated from "pages/home/v2/assets/images/curated.svg";
import equals from "pages/home/v2/assets/images/equals.svg";
import framer from "pages/home/v2/assets/images/framer.svg";
import gradient from "pages/home/v2/assets/images/gradient.svg";
import incrediblehealth from "pages/home/v2/assets/images/incrediblehealth.svg";
import output from "pages/home/v2/assets/images/output.svg";
import notion from "pages/home/v2/assets/images/notion.svg";
import kindred from "pages/home/v2/assets/images/kindred.svg";
import locket from "pages/home/v2/assets/images/locket.svg";
import luma from "pages/home/v2/assets/images/luma.svg";

/* eslint-disable @typescript-eslint/no-var-requires */

/* eslint-enable @typescript-eslint/no-var-requires */

const LOGO_TO_ASSET_LIST = new Map<string, any>([
  ["quora", quora],
  ["gamma", gamma],
  ["luma", luma],
  ["earnbetter", earnbetter],
  ["curated", curated],
  ["equals", equals],
  ["framer", framer],
  ["gradient", gradient],
  ["kikoff", kikoff],
  ["incrediblehealth", incrediblehealth],
  ["output", output],
  ["notion", notion],
  ["kindred", kindred],
  ["characterai", characterai],
  ["locket", locket],
  ["taskade", taskade],
]);

const Logos = ({ paddingY = 112 }: { paddingY?: number }) => (
  <Flex w="100%" p={0} bg="white">
    <Container style={{ maxWidth: "1920px" }} p={0}>
      <Box py={paddingY} px={{ base: "24px", md: "32px", lg: "96px" }}>
        <Center>
          <Stack gap="32">
            <Text
              variant="gradient"
              ta="center"
              size="xl"
              fw={700}
              gradient={{ from: "pink", to: "red" }}>
              Trusted by the top brands in the world
            </Text>
            <Flex justify="center" align="center" wrap="wrap" gap={{ base: 24, sm: 48 }}>
              {Object.keys(Object.fromEntries(LOGO_TO_ASSET_LIST)).map((key) => (
                <Box key={key} w={{ base: "120px", sm: "140px" }}>
                  <Image h="48" fit="contain" src={LOGO_TO_ASSET_LIST.get(key)} style={{ filter: 'grayscale(100%)' }} />
                </Box>
              ))}
            </Flex>
          </Stack>
        </Center>
      </Box>
    </Container>
  </Flex>
);

export default Logos;
