import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { ActionIcon, Flex, Tooltip } from "@mantine/core";
import {
  IconArrowNarrowLeft,
  IconBallpen,
  IconChartHistogram,
  IconPhotoVideo,
  IconList,
  IconCheckupList,
  IconListLetters,
  Icon,
  IconProps,
  IconGiftCard,
  IconVideo,
  IconWriting,
  IconUsersGroup,
  IconPencil,
  IconUserCheck,
} from "@tabler/icons-react";
import Spacer from "components/Spacer";
import SecondaryNavBarButton, {
  SecondaryNavBarMainButtonName,
} from "campaigns/navbar/secondary/SecondaryNavBarButton";
import { ContractFeedType, NAV_BAR_WIDTH } from "campaigns/navbar/consts";
import { AdGroupToDoSummary, Campaign } from "models/Campaign";

enum SecondaryNavBarType {
  CAMPAIGN,
  CREATOR_SET,
  CREATOR_SET_VIEW,
  NONE,
}

const getSecondaryNavBarType = (
  campaignId: number | null,
  creatorSetId: number | null,
  pathname: string,
): SecondaryNavBarType => {
  if (campaignId != null) {
    return SecondaryNavBarType.CAMPAIGN;
  }
  if (creatorSetId != null) {
    return SecondaryNavBarType.CREATOR_SET_VIEW;
  }
  if (pathname.includes("creator_sets")) {
    return SecondaryNavBarType.CREATOR_SET;
  }
  return SecondaryNavBarType.NONE;
};

interface SecondaryNavBarButtonProperties {
  name: SecondaryNavBarMainButtonName;
  label: string;
  iconName: React.ForwardRefExoticComponent<Omit<IconProps, "ref"> & React.RefAttributes<Icon>>;
  // by defaut we will always do setSelectedButton(name) before we navigate to this
  navigateTo: string;
  // unless specified here, we will just use default numTodos
  overrideNumTodos?: number;
  children?: SecondaryNavBarButtonProperties[];
}

const getCampaignButtons = (
  campaignId: number,
  adGroupId: number | null,
  contractsTodo: AdGroupToDoSummary,
): SecondaryNavBarButtonProperties[] => {
  const campaignDefaultButtons: SecondaryNavBarButtonProperties[] = [
    {
      name: SecondaryNavBarMainButtonName.RESULTS,
      label: "Results",
      iconName: IconChartHistogram,
      navigateTo: adGroupId === null ? `${campaignId}/` : `${campaignId}/${adGroupId}/`,
    },
    {
      name: SecondaryNavBarMainButtonName.CREATOR_PROGRESS,
      label: "Creator Progress",
      iconName: IconList,
      navigateTo: `${campaignId}/creator_progress`,
    },
    {
      name: SecondaryNavBarMainButtonName.LIVE,
      label: "Live Creators",
      iconName: IconPhotoVideo,
      navigateTo: adGroupId === null ? `${campaignId}/live` : `${campaignId}/${adGroupId}/live`,
    },
    {
      name: SecondaryNavBarMainButtonName.EDIT_CAMPAIGN,
      label: "Settings",
      iconName: IconPencil,
      navigateTo: `${campaignId}/edit`,
    },
  ];
  if (adGroupId !== null) {
    campaignDefaultButtons.push({
      name: SecondaryNavBarMainButtonName.CONTRACTS,
      label: "Contracts",
      iconName: IconWriting,
      overrideNumTodos: contractsTodo ? contractsTodo.total : 0,
      navigateTo: `${campaignId}/${adGroupId}/contracts`,
      children: [
        {
          name: SecondaryNavBarMainButtonName.CONTRACT_APPROVAL,
          label: "Review Contracts",
          iconName: IconGiftCard,
          overrideNumTodos: contractsTodo ? contractsTodo.contract_reviews : 0,
          navigateTo: `${campaignId}/${adGroupId}/contract_approval`,
        },
        {
          name: SecondaryNavBarMainButtonName.CONTRACT_PRODUCT_ACCESS,
          label: "Product Access",
          iconName: IconGiftCard,
          overrideNumTodos: contractsTodo ? contractsTodo.product_access_reviews : 0,
          navigateTo: `${campaignId}/${adGroupId}/contracts?type=${ContractFeedType.ProductAccess}`,
        },
        {
          name: SecondaryNavBarMainButtonName.CONTRACTS_PROMO_CODES_AND_LINKS,
          label: "Codes & Links",
          iconName: IconGiftCard,
          overrideNumTodos: contractsTodo
            ? contractsTodo.promo_codes + contractsTodo.referral_links
            : 0,
          navigateTo: `${campaignId}/${adGroupId}/contracts?type=${ContractFeedType.PromoCodesAndLinks}`,
        },
        {
          name: SecondaryNavBarMainButtonName.CONTRACTS_REVIEW_CONTENT,
          label: "Review Content",
          iconName: IconBallpen,
          overrideNumTodos: contractsTodo
            ? contractsTodo.video_reviews +
              contractsTodo.script_reviews +
              contractsTodo.unread_messages
            : 0,
          navigateTo: `${campaignId}/${adGroupId}/contracts?type=${ContractFeedType.ReviewContent}`,
        },
        {
          name: SecondaryNavBarMainButtonName.CONTRACTS_LIVE_VERIFICATION,
          label: "Live Verification",
          iconName: IconVideo,
          overrideNumTodos: contractsTodo ? contractsTodo.live_verification : 0,
          navigateTo: `${campaignId}/${adGroupId}/contracts?type=live_verification`,
        },
      ],
    });
  }
  return campaignDefaultButtons;
};

const getCreatorSetButtons = (): SecondaryNavBarButtonProperties[] => [
  {
    name: SecondaryNavBarMainButtonName.ALL_CREATORS,
    label: "All Creators",
    iconName: IconUsersGroup,
    navigateTo: "creator_sets/all",
  },
  {
    name: SecondaryNavBarMainButtonName.MANAGE_CREATORSETS,
    label: "Manage",
    iconName: IconListLetters,
    navigateTo: "creator_sets/manage",
  },
  {
    name: SecondaryNavBarMainButtonName.EXISTING_PARTNERS,
    label: "Existing Partners",
    iconName: IconUserCheck,
    navigateTo: "creator_sets/existing_partners",
  },
];

const getCreatorSetViewButtons = (creatorSetId: number): SecondaryNavBarButtonProperties[] => [
  {
    name: SecondaryNavBarMainButtonName.REVIEW_CREATORS,
    label: "Recommendations",
    iconName: IconCheckupList,
    navigateTo: `creator_sets/${creatorSetId}/recommended`,
  },
  {
    name: SecondaryNavBarMainButtonName.VIEW_CREATORS,
    label: "View",
    iconName: IconList,
    navigateTo: `creator_sets/${creatorSetId}/view`,
  },
];

const SecondaryNavBar = ({
  onHideNavBar,
  selectedButton,
  setSelectedButton,
  toDoSummary,
  campaign,
}: {
  onHideNavBar: () => void;
  selectedButton: SecondaryNavBarMainButtonName;
  setSelectedButton: (selectedButton: SecondaryNavBarMainButtonName) => void;
  toDoSummary: AdGroupToDoSummary | null;
  campaign: Campaign | null;
}) => {
  // navigator
  const navigate = useNavigate();
  // parse params from url
  const urlParams = useParams();
  const urlCampaignId = urlParams.campaignId ? parseInt(urlParams.campaignId, 10) : null;
  const urlAdGroupId = urlParams.adGroupId != null ? parseInt(urlParams.adGroupId, 10) : null;
  const urlCreatorSetId =
    urlParams.creatorSetId != null ? parseInt(urlParams.creatorSetId, 10) : null;

  const location = useLocation();
  const todoCount = 0;

  const [childItemMap, setChildItemMap] = useState<Map<string, boolean>>(new Map());
  const showPromoAndReferralNav =
    campaign && (campaign.referral_link_type !== 1 || campaign.has_promo_code === true);
  const navbarType = getSecondaryNavBarType(urlCampaignId, urlCreatorSetId, location.pathname);
  let navbarButtons: SecondaryNavBarButtonProperties[] = [];
  switch (navbarType) {
    case SecondaryNavBarType.CAMPAIGN:
      navbarButtons = getCampaignButtons(urlCampaignId, urlAdGroupId, toDoSummary);

      navbarButtons.forEach((button) => {
        if (!showPromoAndReferralNav && button.children) {
          // eslint-disable-next-line no-param-reassign
          button.children = button.children.filter(
            (childButton) =>
              childButton.name !== SecondaryNavBarMainButtonName.CONTRACTS_PROMO_CODES_AND_LINKS,
          );
        }

        if (!campaign?.has_product_access && button.children) {
          // eslint-disable-next-line no-param-reassign
          button.children = button.children.filter(
            (childButton) =>
              childButton.name !== SecondaryNavBarMainButtonName.CONTRACT_PRODUCT_ACCESS,
          );
        }
      });
      break;
    case SecondaryNavBarType.CREATOR_SET:
      navbarButtons = getCreatorSetButtons();
      break;
    case SecondaryNavBarType.CREATOR_SET_VIEW:
      navbarButtons = getCreatorSetViewButtons(urlCreatorSetId);
      break;
    case SecondaryNavBarType.NONE:
    default:
      navbarButtons = [];
  }

  return (
    <Flex
      direction="column"
      bg="var(--mantine-color-blue-6)"
      style={{
        width: NAV_BAR_WIDTH,
        paddingTop: "var(--mantine-spacing-md)", // p={md}
        paddingBottom: "var(--mantine-spacing-md)", // p={md}
        paddingLeft: "var(--mantine-spacing-md)", // p={md}
        paddingRight: "0px",
      }}>
      <Flex h={48} align="center" w="100%" justify="end" pr="var(--mantine-spacing-md)">
        <Tooltip label="Hide secondary nav bar">
          <ActionIcon variant="transparent" size={32} onClick={onHideNavBar}>
            <IconArrowNarrowLeft size={24} color="var(--mantine-color-white)" />
          </ActionIcon>
        </Tooltip>
      </Flex>
      <Spacer height={128} />
      {navbarButtons.map((button) => {
        const showChildren = childItemMap.get(button.label);
        return (
          <div key={`${button.name}-${button.label}`}>
            <SecondaryNavBarButton
              key={`${button.name}-${button.label}`}
              isSelected={selectedButton === button.name}
              text={button.label}
              onButtonClick={() => {
                setSelectedButton(button.name);
                navigate(button.navigateTo);
              }}
              IconName={button.iconName}
              numTodos={button.overrideNumTodos || todoCount}
              hasChildren={button.children !== undefined}
              showChildren={showChildren}
              toggleShowChildren={(text, show) => {
                setChildItemMap(new Map(childItemMap.set(text, show)));
              }}
            />
            {showChildren &&
              button.children &&
              button.children.map((childButton) => (
                <SecondaryNavBarButton
                  key={`${childButton.name}-${childButton.label}`}
                  isSelected={selectedButton === childButton.name}
                  text={childButton.label}
                  onButtonClick={() => {
                    navigate(childButton.navigateTo, {
                      state: { selectedButton: childButton.name },
                    });
                    setSelectedButton(childButton.name);
                  }}
                  IconName={childButton.iconName}
                  numTodos={childButton.overrideNumTodos || todoCount}
                  isChild
                />
              ))}
          </div>
        );
      })}
    </Flex>
  );
};

export default SecondaryNavBar;
