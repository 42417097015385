import React, { useEffect, useState } from "react";
import { useGoogleLogin, CodeResponse, googleLogout } from "@react-oauth/google";
import { useAuthState } from "react-firebase-hooks/auth";
import { Layer } from "gestalt";
import "gestalt/dist/gestalt.css";

import { auth } from "auth/firebaseAuthHelpers";
import { AuthenticationState, SetAuthState } from "onboarding/OnboardingUtils";
import { User } from "firebase/auth";
import { handleResult } from "utils/ApiUtils";
import youtube_auth_icon from "social/google/assets/youtube_icon.svg";
import { AuthButton, AuthRowView } from "social/AuthenticationComponents";
import InstructionModal from "social/google/GoogleInstructionsModal";
import {
  YOUTUBE_CALLBACK_URL,
  deleteYoutubeConnect,
  getYoutubeLoginStatus,
} from "social/google/YoutubeUtils";

interface GoogleLoginSuccessObj {
  googleId: string;
  tokenId: string;
  accessToken: string;
  refreshToken: string;
  profileObj: {
    email: string;
  };
}

interface GoogleLoginFailureObj {
  error: string;
  details: string;
}

const GoogleLoginButton = ({
  user,
  setAuth,
  setShowInstructions,
}: {
  user: User;
  setAuth: SetAuthState;
  setShowInstructions: (showInstructions: boolean) => void;
}) => {
  const onSuccess = async (res: CodeResponse) => {
    const firebaseToken = await user.getIdToken();
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // TODO(andrewliu): should we fix this
        Authorization: `Bearer ${firebaseToken}`,
      },
      body: JSON.stringify({
        code: res.code,
        email: user.email,
      }),
    };
    fetch(YOUTUBE_CALLBACK_URL, requestOptions).then((response) => {
      setAuth(AuthenticationState.AUTHENTICATED);
    });
  };

  return (
    <AuthButton
      icon={youtube_auth_icon}
      text="Connect YouTube"
      onClick={() => setShowInstructions(true)}
    />
  );
};

const LoggedInView = ({ user, setAuth }: { user: User; setAuth: SetAuthState }) => {
  const logout = async () => {
    // TODO(implement logout)
    googleLogout();

    deleteYoutubeConnect().then(() => {
      setAuth(AuthenticationState.NOT_AUTHENTICATED);
    });
  };

  return <AuthButton icon={youtube_auth_icon} text="Disconnect YouTube" onClick={() => logout()} />;
};

const GoogleRow = () => {
  const [user, loading] = useAuthState(auth);
  const [authStep, setAuth] = useState(AuthenticationState.LOADING);
  const [showInstructions, setShowInstructions] = useState(false);

  useEffect(() => {
    if (user && authStep === AuthenticationState.LOADING)
      // TODO: check authentication state and set it appropriately. See FBLogin.tsx for example
      getYoutubeLoginStatus().then((authState) => {
        setAuth(authState);
      });
  }, [loading]);

  return (
    <>
      <AuthRowView
        platform="YouTube"
        authStep={authStep}
        NotAuthView={
          <GoogleLoginButton
            user={user}
            setAuth={setAuth}
            setShowInstructions={setShowInstructions}
          />
        }
        AuthView={<LoggedInView user={user} setAuth={setAuth} />}
      />
      {showInstructions && (
        <Layer>
          <InstructionModal
            userEmail={user.email}
            setAuth={setAuth}
            setShowInstructions={setShowInstructions}
          />
        </Layer>
      )}
    </>
  );
};

export default GoogleRow;
