import React from "react";

import { Button, Flex, Stack, Title } from "@mantine/core";

export interface RecommendedHashtag {
  cooccurrences: number;
  pmi: number;
  related_hashtag: string;
  related_occurrences: number;
  source_hashtag: string;
  source_occurrences: number;
}

const NUM_HASHTAGS = 20;

function RecommendedHashtags({
  hashtags,
  handleSubmit,
}: {
  hashtags: any[];
  handleSubmit: (hashtag: string) => void;
}) {
  return (
    <Flex gap="xs" wrap="wrap">
      {hashtags.map((hashtag) => (
        <Button
          key={hashtag.related_hashtag}
          variant="light"
          size="compact-xs"
          onClick={() => {
            handleSubmit(`#${hashtag.related_hashtag}`);
          }}>
          #{hashtag.related_hashtag}
        </Button>
      ))}
    </Flex>
  );
}

export default function AllRecommendedHashtags({
  allHashtags,
  hashtagsLoading,
  handleSubmit,
}: {
  allHashtags: RecommendedHashtag[];
  hashtagsLoading: boolean;
  handleSubmit: (hashtag: string) => void;
}) {
  if (hashtagsLoading || !allHashtags || allHashtags.length === 0) {
    return null;
  }

  const broadHashtags = allHashtags
    .filter((res: any) => res.related_occurrences >= res.source_occurrences)
    .slice(0, NUM_HASHTAGS);

  const narrowHashtags = allHashtags
    .filter((res: any) => res.related_occurrences < res.source_occurrences)
    .slice(0, NUM_HASHTAGS);

  return (
    <Stack>
      {broadHashtags.length > 0 && (
        <>
          <Title order={5}>Broader Hashtags</Title>
          <RecommendedHashtags hashtags={broadHashtags} handleSubmit={handleSubmit} />
        </>
      )}
      {narrowHashtags.length > 0 && (
        <>
          <Title order={5}>Narrower Hashtags</Title>
          <RecommendedHashtags hashtags={narrowHashtags} handleSubmit={handleSubmit} />
        </>
      )}
    </Stack>
  );
}
