import { Group, Stack, Textarea, Text } from "@mantine/core";
import React, { useEffect, useState } from "react";
import InfoCircleWithTooltip from "campaigns/create/common/InfoCircleWithTooltip";

import { useUncontrolled } from "@mantine/hooks";

const TextareaWithCharacterLimit = ({
  formKey,
  value,
  defaultValue,
  onChange,
  error,
  maxCharacters,
  placeholder,
  title,
  tooltipText,
}: {
  maxCharacters: number;
  placeholder: string;
  title: string;
  tooltipText: string;
  value?: string;
  defaultValue?: string;
  onChange?: (value: string) => void;
  error?: React.ReactNode;
  formKey: React.Key;
}) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [_value, handleChange] = useUncontrolled({
    value,
    defaultValue,
    onChange,
  });

  const [currentNumCharacters, setCurrentNumCharacters] = useState<number>(0);
  const [showCurrentCount, setShowCurrentCount] = useState<boolean>(true);

  useEffect(() => {
    setShowCurrentCount(!error);
  }, [error]);

  return (
    <Stack gap={0}>
      <Textarea
        label={
          <Group
            gap={0}
            style={{
              "--group-gap": "4px",
            }}>
            <Text size="xs" fw={600}>
              {title}
            </Text>
            <InfoCircleWithTooltip
              iconColor="var(--mantine-color-dark-0)"
              tooltipText={tooltipText}
            />
          </Group>
        }
        placeholder={placeholder}
        styles={{
          error: {
            textAlign: "end",
          },
        }}
        key={formKey}
        value={_value}
        onChange={(event) => {
          handleChange(event.currentTarget.value);
          setCurrentNumCharacters(event.currentTarget.value.length);
        }}
        error={error}
      />
      {showCurrentCount ? (
        <Text size="xs" c="var(--mantine-color-gray-6)" style={{ textAlign: "end" }}>
          {maxCharacters - currentNumCharacters} characters left
        </Text>
      ) : null}
    </Stack>
  );
};

export default TextareaWithCharacterLimit;
