import React, { useEffect, useState } from "react";
import { Box, Button, Text, Heading, Spinner, ScrollBoundaryContainer } from "gestalt";
import "gestalt/dist/gestalt.css";
import { User } from "firebase/auth";
import { useOutletContext, useParams } from "react-router-dom";

import { AuthContext } from "auth/AuthContext";
import { User as UserProfile } from "models/User";
import FlatModule from "components/FlatModule";
import Spacer from "components/Spacer";
import LayoutBase from "components/LayoutBase";
import { Helmet } from "react-helmet-async";
import { useUser } from "utils/UserContext";
import { getByUsername } from "utils/UserUtils";
import LoadingScreen from "components/LoadingScreen";
import {
  getSavedYoutubeCreators,
  SavedCreatorsResponse,
  unsaveYoutubeCreator,
} from "components/creator_lists/CreatorListsUtils";
import { CreatorListView, HiddenCreatorEntry } from "components/creator/CreatorListView";
import { CreatorDetails, YoutubeChannelInfo } from "components/discovery/Datamodels";

enum SavedResultsState {
  INIT,
  LOADING,
  SUCCESS,
  ERROR,
  EMPTY,
}

const fetchSavedCreators = async (
  setSavedCreators: (savedCreators: CreatorDetails[]) => void,
  setSaveState: (saveState: SavedResultsState) => void,
  user: User,
  userKey: string,
  listname: string,
) => {
  setSaveState(SavedResultsState.LOADING);
  try {
    const response: SavedCreatorsResponse = await getSavedYoutubeCreators(user, userKey, listname);
    const savedCreators = response.channels;
    if (savedCreators?.length) {
      setSavedCreators(savedCreators);
      setSaveState(SavedResultsState.SUCCESS);
    } else {
      setSaveState(SavedResultsState.EMPTY);
    }
  } catch (error) {
    setSaveState(SavedResultsState.ERROR);
  }
};

const SavedCreatorListEntry = ({
  creator,
  user,
  userKey,
  listname,
  isSelf,
}: {
  creator: CreatorDetails;
  user: User;
  userKey: string;
  listname: string;
  isSelf: boolean;
}) => {
  const [isRemoved, setIsRemoved] = useState(false);
  const removeButton = isSelf ? (
    <Button
      text="Remove"
      onClick={() => {
        setIsRemoved(true);
        unsaveYoutubeCreator(creator.youtube_channel.channel_id, user, userKey, listname);
      }}
    />
  ) : null;

  return isRemoved ? (
    <HiddenCreatorEntry
      undoButton={null}
      text={`Removed ${creator.youtube_channel.handle} from your list.`}
      subtext=""
    />
  ) : (
    <CreatorListView
      user={user}
      key={`entry-${creator.youtube_channel.channel_id}`}
      creatorDetails={creator}
      actions={removeButton}
    />
  );
};

const ResultsTable = ({
  savedCreators,
  user,
  userKey,
  listname,
  isSelf,
}: {
  savedCreators: CreatorDetails[];
  user: User;
  userKey: string;
  listname: string;
  isSelf: boolean;
}) => (
  <ScrollBoundaryContainer>
    <Box>
      <Spacer height={32} />
      {/* <Box justifyContent="end" display="flex">
        <CreatorFilter />
      </Box>
      <Spacer height={16} /> */}
      <Text align="center" color="subtle" size="200">
        All stats are pulled from the YouTube Data API for videos published in the last 28 days.
      </Text>
      <Spacer height={16} />
      {savedCreators.map((creator) => (
        <SavedCreatorListEntry
          key={`entry-${creator.youtube_channel.channel_id}`}
          creator={creator}
          user={user}
          userKey={userKey}
          listname={listname}
          isSelf={isSelf}
        />
      ))}
      <Spacer height={24} />
    </Box>
  </ScrollBoundaryContainer>
);

const LoadingScreenWithText = () => (
  <Box flex="grow" width="100%" minHeight="100%" justifyContent="center">
    <Text align="center" size="200" color="subtle">
      Hang tight, we&apos;re loading your saved creators
    </Text>
    <Spacer height={4} />
    <Text align="center" size="200" color="subtle">
      Since this is a beta experience, this might be a bit slow.
    </Text>
    <Spacer height={32} />
    <Spinner show accessibilityLabel="Loading Screen" />
  </Box>
);

const EmptyResults = ({ email }: { email: string }) => (
  <FlatModule width={600}>
    <Box display="flex" direction="column" alignItems="center" padding={6}>
      <Heading size="400" align="center">
        You haven&apos;t saved any creators yet!
      </Heading>
      <Spacer height={32} />
      <Text color="subtle">
        Head to the{" "}
        <a href="/campaigns/discover" style={{ textDecoration: "none" }}>
          Discover
        </a>{" "}
        page to find creators to save!
      </Text>
    </Box>
  </FlatModule>
);

const ErrorMessage = () => (
  <FlatModule width={600}>
    <Box display="flex" direction="column" alignItems="center" padding={6}>
      <Heading size="400" align="center">
        Something went wrong on our end.
      </Heading>
      <Spacer height={32} />
      <Text color="subtle">We&apos;ve let our product team know.</Text>
      <Text color="subtle">Please refresh the page and try again.</Text>
    </Box>
  </FlatModule>
);

const SavedCreators = ({
  savedState,
  savedCreators,
  user,
  userProfile,
  listname,
  isSelf,
}: {
  savedState: SavedResultsState;
  savedCreators: CreatorDetails[];
  user: User;
  userProfile: UserProfile;
  listname: string;
  isSelf: boolean;
}) => {
  switch (savedState) {
    case SavedResultsState.INIT:
      return null;
    case SavedResultsState.LOADING:
      return <LoadingScreenWithText />;
    case SavedResultsState.SUCCESS:
      return (
        <ResultsTable
          savedCreators={savedCreators}
          user={user}
          userKey={userProfile.key}
          listname={listname}
          isSelf={isSelf}
        />
      );
    case SavedResultsState.EMPTY:
      return <EmptyResults email={user.email} />;
    case SavedResultsState.ERROR:
      return <ErrorMessage />;
    default:
      return null;
  }
};

const fetchViewedProfileData = async (
  requestUser: User,
  username: string,
  setUserProfile: (userProfile: UserProfile) => void,
  setLoaded: (isLoaded: boolean) => void,
) => {
  // remove this later once we require authentication to view a profile
  const firebaseToken = await requestUser?.getIdToken();
  const user = await getByUsername(firebaseToken, username);
  setUserProfile(user);
  setLoaded(true);
};

const ListView = () => {
  const requestUser: User = useOutletContext<AuthContext>()?.user;
  const [viewUserProfile, setViewUserProfile] = useState(null);
  const [userProfile, userLoading] = useUser();
  const { username, listname } = useParams();
  const [isLoaded, setLoaded] = useState(false);

  const [savedCreators, setSavedCreators] = useState(null);
  const [savedState, setSavedState] = useState(SavedResultsState.INIT);
  const isSelf = requestUser && viewUserProfile && viewUserProfile.firebaseUid === requestUser.uid;

  useEffect(() => {
    fetchViewedProfileData(requestUser, username, setViewUserProfile, setLoaded);
  }, [username]);

  const title = viewUserProfile
    ? `${viewUserProfile.name} (${viewUserProfile.username}) - Saved Creators | 1stCollab`
    : "Saved Creators | 1stCollab";
  const description = viewUserProfile
    ? `See the creators saved by ${viewUserProfile.name} (${viewUserProfile.username}). Only on 1stCollab`
    : "View saved creators. Only on 1stCollab.";

  useEffect(() => {
    if (!userLoading && userProfile) {
      fetchSavedCreators(setSavedCreators, setSavedState, requestUser, username, listname);
    }
  }, [userLoading]);

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="title" property="og:title" content={title} />
        <meta name="description" property="og:description" content={description} />
      </Helmet>
      <LayoutBase>
        {userLoading ? (
          <LoadingScreen />
        ) : (
          <Box direction="column" display="flex" alignItems="center">
            <SavedCreators
              savedState={savedState}
              savedCreators={savedCreators}
              user={requestUser}
              userProfile={userProfile}
              listname={listname}
              isSelf={isSelf}
            />
          </Box>
        )}
      </LayoutBase>
    </>
  );
};

export default ListView;
