import React from "react";
import { ActionIcon } from "@mantine/core";
import { Icon, IconProps } from "@tabler/icons-react";
import BasicNavButton from "campaigns/navbar/BasicNavButton";

export enum RootButtonName {
  HOME,
  CREATORS,
  CAMPAIGNS,
  CONTENT_LIBRARY,
  CREATIVE_BRIEFS,
  DISCOVER,
  CONTRACTS_OVERVIEW,
}

const RootButton = ({
  isSelected,
  text,
  onButtonClick,
  LeftIconName, // maps to React name in tabler icons
  rightSection,
}: {
  isSelected: boolean;
  text: string;
  onButtonClick: () => void;
  LeftIconName?: React.ForwardRefExoticComponent<
    Omit<IconProps, "ref"> & React.RefAttributes<Icon>
  >;
  rightSection?: React.ReactNode;
}) => (
  <BasicNavButton
    isSelected={isSelected}
    leftSection={
      LeftIconName ? (
        <ActionIcon
          variant="filled"
          component="div"
          color={isSelected ? "white" : "var(--mantine-color-blue-0"}
          size={26}>
          <LeftIconName height={14} width={14} color="var(--mantine-color-blue-6)" />
        </ActionIcon>
      ) : null
    }
    rightSection={rightSection}
    onButtonClick={onButtonClick}
    text={text}
  />
);

export default RootButton;
