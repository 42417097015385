import React from "react";

import { Badge } from "@mantine/core";

import { PaymentStatus } from "components/contracts/common/Common";

export default function PaymentStatusBadge({
  paymentStatus,
  readyForPayment,
}: {
  paymentStatus: PaymentStatus;
  readyForPayment: boolean;
}) {
  switch (paymentStatus) {
    case PaymentStatus.UNPAID:
      if (readyForPayment) {
        return (
          <Badge color="red" variant="light">
            Unpaid
          </Badge>
        );
      }
      return (
        <Badge color="gray" variant="light">
          Unpaid
        </Badge>
      );
    case PaymentStatus.PARTIALLY_PAID:
      return (
        <Badge color="yellow" variant="light">
          Partially Paid
        </Badge>
      );
    case PaymentStatus.FULLY_PAID:
      return (
        <Badge color="green" variant="light">
          Fully Paid
        </Badge>
      );
    default:
      return null;
  }
}
