import React, { ReactNode } from "react";
import { Box, Text, Table, Heading } from "gestalt";
import "gestalt/dist/gestalt.css";
import Spacer from "components/Spacer";
import { Helmet } from "react-helmet-async";

const Section = ({
  header,
  content,
}: {
  header: string;
  content: { question: string; answer: ReactNode }[];
}) => (
  <>
    <Heading size="500">{header}</Heading>
    <Box width="70%" paddingY={4}>
      <Table accessibilityLabel="FAQs">
        <Table.Body>
          {content.map((entry) => (
            <Table.RowExpandable
              key={entry.question}
              id={entry.question}
              accessibilityExpandLabel="Expand"
              accessibilityCollapseLabel="Collapse"
              hoverStyle="none"
              expandedContents={<Text align="start">{entry.answer}</Text>}>
              <Table.Cell>
                <Text align="start" weight="bold">
                  {entry.question}
                </Text>
              </Table.Cell>
            </Table.RowExpandable>
          ))}
        </Table.Body>
      </Table>
      <Spacer height={24} />
    </Box>
  </>
);

const FAQs = () => {
  const generalFAQs = [
    {
      question: "What is 1stCollab?",
      answer: (
        <Text align="start">
          We&apos;re an influencer marketing platform that helps both brands and creators with
          influencer marketing campaigns. For brands we&apos;re the fastest way for brands to scale
          up and improve performance for their campaigns. For creators, we&apos;re the easiest way
          to find and join campaigns that are relevant for your audience.
        </Text>
      ),
    },
    {
      question: "What if my question isn't here?",
      answer: (
        <Text align="start">
          Feel free to drop us a line any time at{" "}
          <a href="mailto:hello@1stcollab.com">hello@1stcollab.com</a>.
        </Text>
      ),
    },
    {
      question: "Can I schedule a call?",
      answer: (
        <Text align="start">
          Yes we’d love to hear from you. Feel free to schedule a call by sending an email to{" "}
          <a href="mailto:hello@1stcollab.com">hello@1stcollab.com.</a>
        </Text>
      ),
    },
  ];

  const creatorFAQs = [
    {
      question: "What do I need to do for a 1stCollab campaign?",
      answer: (
        <Text align="start">
          When we reach out to you, it means a brand has already approved you to join a campaign and
          wants to partner with you. From there, we&apos;ll finalize a rate. Afterwards, you&apos;ll
          upload content and we&apos;ll pay you one week after your content goes live.
          <br />
          <br />
          To be considered for future 1stCollab brand campaigns, just sign up for an account!
        </Text>
      ),
    },
    {
      question: "Do I really get paid after one week?",
      answer: (
        <Text align="start">
          Yep! No more waiting weeks or months to get paid. We want to send you money as soon as
          you&apos;ve done the work.
        </Text>
      ),
    },
    {
      question: "How much can I earn on 1stCollab?",
      answer: (
        <Text align="start">
          You&apos;ll have the opportunity to earn significantly more than you would working on a
          traditional brand deal. We&apos;ll pay a fixed fee upfront, but also add a bonus depending
          on how your content performs. If your content goes viral, you&apos;ll be in for a huge
          payday!
        </Text>
      ),
    },
    {
      question: "How do I get paid?",
      answer: (
        <Text align="start">
          Once you go into contract for the campaign, we&apos;ll ask for your payment information
          and send you a payment through PayPal or Stripe one week after your content goes live.
        </Text>
      ),
    },
    {
      question: "How do I work with more brands on 1stCollab?",
      answer: (
        <Text align="start">
          We&apos;re constantly onboarding new brands. Once you join 1stCollab, we&apos;ll give you
          the option to hear about other relevant campaigns to join. By being a creator on
          1stCollab, we&apos;ll help make sure you have a steady stream of relevant brand deals
          always available.
        </Text>
      ),
    },
  ];

  const brandFAQs = [
    {
      question: "How does 1stCollab help me?",
      answer: (
        <Text align="start">
          If you&apos;re new to influencer marketing, we help you run your very first campaign. We
          take care of the entire process from sourcing, to contracting and negotiating, to payment
          and creative review. Let us help make your first campaign a success!
          <br />
          <br />
          If you&apos;re already running influencer marketing campaigns, we help you scale up your
          campaigns and improve performance. We use the latest AI technologies to help you work with
          hundreds of relevant, high-quality influencers simultaneously for your campaigns. Run a
          campaign in minutes and see why our brands have reduced their CPMs and CAC by over 80% by
          using 1stCollab.
        </Text>
      ),
    },
    {
      question: "How do I get started?",
      answer: (
        <Text align="start">
          From just being curious about influencer marketing to having an existing campaign
          you&apos;re trying to scale, we&apos;re here to help at every stage of the campaign
          process. <a href="/signup">Sign up</a> or reach out to us at{" "}
          <a href="hello@1stcollab.com">hello@1stcollab.com</a> and we&apos;ll respond within 24
          hours.
        </Text>
      ),
    },
    {
      question: "How much does it cost?",
      answer: (
        <Text align="start">
          We take a percent cut of your campaign spend. You don&apos;t pay anything unless we
          successfully help you partner with creators. Start with as little as $1000 in campaign
          spend. Reach out to us at <a href="hello@1stcollab.com">hello@1stcollab.com</a> for any
          additional questions about pricing.
        </Text>
      ),
    },
  ];

  const title = "FAQs";
  const description = "Frequently asked questions";

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="title" property="og:title" content={title} />
        <meta name="description" property="og:description" content={description} />
      </Helmet>
      <Box alignItems="center" display="flex" margin={12} direction="column">
        <Section header="General" content={generalFAQs} />
        <Section header="For Creators" content={creatorFAQs} />
        <Section header="For Brands" content={brandFAQs} />
      </Box>
    </>
  );
};

export default FAQs;
