import { API_URL } from "configs/Configs";
import { createRequest, handleResult } from "utils/ApiUtils";

export const SOCIAL_API_BASE = `${API_URL}/social`;

// TODO(andrew): swap from url to profiletype enum so we don't need to pass in the api url
export const getProfilesByKey = async (firebaseToken: string, key: string, url: string) => {
  const requestUrl = new URL(url);
  requestUrl.searchParams.append("user", key);
  const request = createRequest({ url: requestUrl.toString(), firebaseToken });
  const response = await handleResult(request);
  return response;
};

export const getFirstProfileByKey = async (firebaseToken: string, key: string, url: string) => {
  const profiles = await getProfilesByKey(firebaseToken, key, url);
  if (profiles !== null && profiles.length > 0) {
    return profiles[0];
  }
  return null;
};

export const getProfileResponse = async (firebaseToken: string, requestUrl: string) => {
  const request = createRequest({ url: requestUrl.toString(), firebaseToken });
  try {
    const response = await handleResult(request);
    return response;
  } catch (exception) {
    // returns an empty response when there's an exception. media is then set to an empty list vs.
    // just having it set to null, to let the client know that the retrival was already attempted.
    return [];
  }
};
