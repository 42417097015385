import React from "react";

import { Table } from "@mantine/core";

import { Brand } from "models/Brand";
import { OpsTask } from "models/OpsTask";
import { OpsTaskTableRow } from "admin/app/ops/OpsTaskTableRow";

export const OpsBrandTasksTable = ({ brand, opsTasks }: { brand: Brand; opsTasks: OpsTask[] }) => {
  // filter tasks by brand
  const brandTasks = opsTasks.filter((task) => task?.creator_set_details?.brand_id === brand?.id);

  return (
    <Table horizontalSpacing="md" verticalSpacing="md" highlightOnHover withTableBorder>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Task Name</Table.Th>
          <Table.Th>Assignee</Table.Th>
          <Table.Th>Completed</Table.Th>
          <Table.Th>Creator Set</Table.Th>
          <Table.Th>Count</Table.Th>
          <Table.Th>Difficulty</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {brandTasks.map((task) => (
          <OpsTaskTableRow key={task.id} opsTask={task} showAssignee />
        ))}
      </Table.Tbody>
    </Table>
  );
};

export default OpsBrandTasksTable;
