import { User as FirebaseUser } from "firebase/auth";

import { createRequestWithFirebaseToken, handleResult } from "utils/ApiUtils";
import { API_URL } from "configs/Configs";

import { CreativeBrief } from "models/CreativeBrief";

export const CREATIVE_BRIEF_URL = `${API_URL}/api/models/creative_brief`;

export interface CreativeBriefSettingsResponse {
  results: CreativeBrief[];
  count: number;
  next?: number | null;
  previous?: number | null;
}

export const fetchCreativeBriefsByHashId = async (
  user: FirebaseUser,
  campaignHashId: number,
  abortController: AbortController,
): Promise<CreativeBriefSettingsResponse> => {
  const firebaseToken = await user.getIdToken();

  const requestUrl = new URL(CREATIVE_BRIEF_URL);
  requestUrl.searchParams.append("campaignId", campaignHashId.toString());

  const request = new Request(requestUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
  });
  const response: CreativeBriefSettingsResponse | null = await handleResult(
    request,
    abortController,
  );
  return response;
};

export const fetchCreativeBriefs = async (
  abortController: AbortController,
): Promise<CreativeBriefSettingsResponse> => {
  const requestUrl = new URL(CREATIVE_BRIEF_URL);
  const request = await createRequestWithFirebaseToken({ url: requestUrl });
  const response: CreativeBriefSettingsResponse | null = await handleResult(
    request,
    abortController,
  );
  return response;
};

export const fetchCreativeBriefsByBrandId = async (
  brandId: number,
  abortController: AbortController,
): Promise<CreativeBriefSettingsResponse> => {
  const requestUrl = new URL(CREATIVE_BRIEF_URL);
  requestUrl.searchParams.append("brandId", brandId.toString());

  const request = await createRequestWithFirebaseToken({ url: requestUrl });
  const response: CreativeBriefSettingsResponse | null = await handleResult(
    request,
    abortController,
  );
  return response;
};

export const createCreativeBriefByBrandId = async (
  user: FirebaseUser,
  brandId: number,
  creativeBriefName: string,
  abortController: AbortController,
): Promise<CreativeBrief> => {
  const firebaseToken = await user.getIdToken();

  const requestUrl = new URL(`${CREATIVE_BRIEF_URL}/`);
  requestUrl.searchParams.append("brand_id", brandId.toString());

  const requestBody = {
    brand_id: brandId.toString(),
    name: creativeBriefName,
  };

  const request = new Request(requestUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
    body: JSON.stringify(requestBody),
    signal: abortController.signal,
  });
  const response: CreativeBrief | null = await handleResult(request, abortController);
  return response;
};

export const createCreativeBriefByHashId = async (
  user: FirebaseUser,
  campaignHashId: number,
  creativeBriefName: string,
  abortController: AbortController,
): Promise<CreativeBrief> => {
  const firebaseToken = await user.getIdToken();

  const requestUrl = new URL(`${CREATIVE_BRIEF_URL}/`);
  requestUrl.searchParams.append("campaignId", campaignHashId.toString());

  const requestBody = {
    campaignId: campaignHashId.toString(),
    name: creativeBriefName,
  };

  const request = new Request(requestUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
    body: JSON.stringify(requestBody),
    signal: abortController.signal,
  });
  const response: CreativeBrief | null = await handleResult(request, abortController);
  return response;
};

export const createCreativeBriefForBrand = async (
  brandId: number,
  creationParams: { [key: string]: any },
  abortController: AbortController,
): Promise<CreativeBrief> => {
  const requestUrl = new URL(`${CREATIVE_BRIEF_URL}/`);
  requestUrl.searchParams.append("brand_id", brandId.toString());

  const requestBody = {
    brand_id: brandId.toString(),
    ...creationParams,
  };
  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify(requestBody),
  });
  const response: CreativeBrief | null = await handleResult(request, abortController);
  return response;
};

export const updateCreativeBriefById = async (
  creativeBriefId: number,
  updates: { [key: string]: any },
): Promise<CreativeBrief> => {
  const updateBlob: { [key: string]: any } = {
    id: creativeBriefId,
    ...updates,
  };

  const request = await createRequestWithFirebaseToken({
    url: `${CREATIVE_BRIEF_URL}/${creativeBriefId}/`,
    method: "PATCH",
    body: JSON.stringify(updateBlob),
  });

  const result = await handleResult(request);
  return result;
};

export const updateCreativeBriefByHashId = async (
  firebaseUser: FirebaseUser,
  campaignHashId: number,
  creativeBriefId: number,
  updates: { [key: string]: any },
): Promise<CreativeBrief> => {
  const firebaseToken = await firebaseUser.getIdToken();
  // convert update to raw fields
  const translatedUpdates: { [key: string]: any } = {
    id: creativeBriefId,
    campaignId: campaignHashId,
  };
  Object.entries(updates).forEach(([key, value]) => {
    if (key === "name") {
      translatedUpdates.name = value;
    } else if (key === "status") {
      translatedUpdates.status = value;
    }
  });
  const request = new Request(`${CREATIVE_BRIEF_URL}/${creativeBriefId}/`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
    body: JSON.stringify(translatedUpdates),
  });

  const response = await handleResult(request);
  return response;
};

export default null;
