import React from "react";
import { Badge, Flex, Group, Indicator } from "@mantine/core";

import CreatorAvatar from "components/contracts/common/CreatorAvatar";
import { toShortDateString } from "utils/DateUtils";
import ContractHeaderInfo from "components/contracts/review/ContractHeaderInfo";

function ContractReviewStatusBadge({
  verificationRequired,
  reviewRequired,
  waitingForRevision,
  waitingForContent,
  approvedToGoLive,
  isLive,
  latestLiveDate,
}: {
  verificationRequired: boolean;
  reviewRequired: boolean;
  waitingForRevision: boolean;
  waitingForContent: boolean;
  approvedToGoLive: boolean;
  isLive: boolean;
  latestLiveDate: Date;
}) {
  if (verificationRequired && reviewRequired) {
    return (
      <Badge color="red" variant="light">
        Actions Required
      </Badge>
    );
  }

  if (verificationRequired) {
    return (
      <Badge color="red" variant="light">
        Verification Required
      </Badge>
    );
  }

  if (reviewRequired) {
    return (
      <Badge color="red" variant="light">
        Review Required
      </Badge>
    );
  }

  if (waitingForRevision) {
    return (
      <Badge color="yellow" variant="light">
        Waiting for Revisions
      </Badge>
    );
  }

  if (waitingForContent) {
    return (
      <Badge color="yellow" variant="light">
        Waiting for Content
      </Badge>
    );
  }

  if (approvedToGoLive) {
    return (
      <Badge color="teal" variant="light">
        Approved to Go Live
      </Badge>
    );
  }

  if (isLive) {
    return (
      <Badge color="blue" variant="light">
        Content Live{latestLiveDate ? ` on ${toShortDateString(latestLiveDate)}` : ""}
      </Badge>
    );
  }
}

export default function ContractReviewHeader({
  contractHeaderInfo,
  totalNumUnreadMessages,
  verificationRequired,
  reviewRequired,
  waitingForRevision,
  waitingForContent,
  approvedToGoLive,
  isLive,
  liveDate,
  open,
}: {
  contractHeaderInfo: ContractHeaderInfo;
  totalNumUnreadMessages: number;
  verificationRequired: boolean;
  reviewRequired: boolean;
  waitingForRevision: boolean;
  waitingForContent: boolean;
  approvedToGoLive: boolean;
  isLive: boolean;
  liveDate: Date;
  open?: () => void;
}) {
  return (
    <Group justify="space-between">
      <Indicator
        disabled={!totalNumUnreadMessages}
        position="top-start"
        color="red"
        label={totalNumUnreadMessages}
        size="1rem">
        <CreatorAvatar
          creatorId={contractHeaderInfo.creatorId}
          displayName={`${contractHeaderInfo.signatureFirstName} ${contractHeaderInfo.signatureLastName}`}
          avatarUrl={contractHeaderInfo.avatarUrl}
          contractAmount={contractHeaderInfo.contractAmount}
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            e.stopPropagation();
            open();
          }}
        />
      </Indicator>
      <Flex justify="right" align="center" gap="xs">
        <ContractReviewStatusBadge
          verificationRequired={verificationRequired}
          reviewRequired={reviewRequired}
          waitingForRevision={waitingForRevision}
          waitingForContent={waitingForContent}
          approvedToGoLive={approvedToGoLive}
          isLive={isLive}
          latestLiveDate={liveDate}
        />
      </Flex>
    </Group>
  );
}
