import React from "react";
import { Table, Text } from "gestalt";
import "gestalt/dist/gestalt.css";
import { useMediaQuery } from "react-responsive";

import FacebookRow from "social/fb/FBLogin";
import GoogleRow from "social/google/GoogleLogin";
import TiktokRow from "social/tiktok/TiktokLogin";
import { SMALL_SCREEN_WIDTH } from "utils/DeviceTypeUtils";

const TableHeader = () => {
  const bigScreen = useMediaQuery({ query: `(min-width: ${SMALL_SCREEN_WIDTH}px)` });

  return (
    <Table.Header>
      <Table.Row>
        {bigScreen && (
          <Table.HeaderCell>
            <Text weight="bold">Status</Text>
          </Table.HeaderCell>
        )}
        <Table.HeaderCell>
          <Text weight="bold">Authenticate</Text>
        </Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
};

const SocialAuthentication = () => (
  <Table accessibilityLabel="Social Platforms">
    <TableHeader />
    <Table.Body>
      <GoogleRow />
      <TiktokRow />
      <FacebookRow />
    </Table.Body>
  </Table>
);

export default SocialAuthentication;
