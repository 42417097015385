import {
  Box,
  Button,
  Center,
  Divider,
  Group,
  LoadingOverlay,
  PasswordInput,
  Select,
  Stack,
  Text,
  TextInput,
  Title,
  UnstyledButton,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import Spacer from "components/Spacer";
import React, { useEffect, useState } from "react";
import PasswordResetModal from "auth/PasswordResetModal";
import { BrandIcons } from "components/BrandIcons";
import {
  ErrorStates,
  getErrorMessage,
  loginWrapper,
  registerWithEmailAndPasswordWrapper,
} from "auth/AuthErrors";
import AuthLoading, { AuthPlatform } from "auth/AuthUtils";
import { useUser } from "utils/UserContext";
import {
  ONBOARDING_MEMBERSHIP_OPTIONS,
  getLabelForMembershipType,
  validateEmailForMembershipResult,
} from "onboarding/v2/OnboardingMembershipStep";
import { MembershipTypes, User } from "models/User";
import { useGoogleLogin } from "@react-oauth/google";
import {
  GoogleAuthProvider,
  fetchSignInMethodsForEmail,
  signInWithCredential,
} from "firebase/auth";
import { auth } from "auth/firebaseAuthHelpers";
import { createRequest, handleResult } from "utils/ApiUtils";

export const UNAUTH_MEMBERSHIP_TYPE = "unauthMemType";

interface AuthError {
  platform: AuthPlatform;
  error: ErrorStates;
  googleErrorString?: string | null;
}

const AuthForm = ({
  apiError,
  isLogin,
  membershipType,
  onForgotPasswordClicked,
  setApiErrorErrorState,
  setLoading,
}: {
  apiError: AuthError;
  isLogin: boolean;
  membershipType: MembershipTypes | null;
  onForgotPasswordClicked: () => void;
  setApiErrorErrorState: (errorState: ErrorStates) => void;
  setLoading: (loadingState: AuthLoading) => void;
}) => {
  const [, , updateUser] = useUser();
  const form = useForm({
    initialValues: {
      fullName: "",
      email: "",
      password: "",
    },

    validate: {
      fullName: (value) => (isLogin || value ? null : "Please enter your full name"),
      email: (value) => {
        if (isLogin) {
          return validateEmailForMembershipResult(value, null);
        }
        return validateEmailForMembershipResult(value, membershipType);
      },
      password: (value) => (value ? null : "Please enter your password"),
    },

    onValuesChange: () => {
      setApiErrorErrorState(ErrorStates.NONE); // reset error state when values change
    },
  });

  const emailApiErrorMessage =
    apiError.platform === AuthPlatform.EMAIL ? getErrorMessage(apiError.error) : null;

  const buttonCTA = isLogin ? "Login" : "Sign up";
  return (
    <form
      onSubmit={form.onSubmit(({ fullName, email, password }) => {
        setLoading(AuthLoading.LOADING);
        if (isLogin) {
          loginWrapper(email, password, setApiErrorErrorState, setLoading);
        } else {
          registerWithEmailAndPasswordWrapper(
            fullName,
            email,
            password,
            setApiErrorErrorState,
            updateUser,
            setLoading,
          );
        }
      })}>
      {isLogin ? null : (
        <>
          <TextInput
            size="lg"
            label="Full name"
            placeholder="Full name"
            {...form.getInputProps("fullName")}
          />
          <Spacer height={16} />
        </>
      )}
      <TextInput size="lg" label="Email" placeholder="Email" {...form.getInputProps("email")} />
      <Spacer height={16} />
      <PasswordInput
        size="lg"
        label="Password"
        placeholder="Password"
        {...form.getInputProps("password")}
      />
      {isLogin ? (
        <>
          <Spacer height={16} />
          <UnstyledButton onClick={onForgotPasswordClicked}>
            <Text size="sm" py={8}>
              Forgot Password?
            </Text>
          </UnstyledButton>
        </>
      ) : null}

      <Spacer height={24} />
      <Button w="100%" type="submit" size="lg">
        {buttonCTA}
      </Button>
      {emailApiErrorMessage ? (
        <Text c="red" size="sm">
          {emailApiErrorMessage}
        </Text>
      ) : null}
    </form>
  );
};

const NewAuth = ({
  isLogin,
  setLoading,
  showLoadingUI,
}: {
  isLogin: boolean;
  setLoading: (loadingState: AuthLoading) => void;
  showLoadingUI: boolean;
}) => {
  const canShowMembershipStep = true;
  const [showMembershipStep, setShowMembershipStep] = useState<boolean>(
    canShowMembershipStep && !isLogin,
  );
  const [membershipType, setMembershipType] = useState<string | null>(null);
  const [showPasswordReset, setShowPasswordReset] = useState<boolean>(false);
  const [apiError, setApiError] = useState<AuthError>({
    platform: AuthPlatform.EMAIL,
    error: ErrorStates.NONE,
    googleErrorString: null,
  });

  useEffect(() => {
    setMembershipType(null);
    setApiError({
      platform: AuthPlatform.EMAIL,
      error: ErrorStates.NONE,
    });
    setShowMembershipStep(canShowMembershipStep && !isLogin);
  }, [isLogin]);

  useEffect(() => {
    localStorage.setItem(UNAUTH_MEMBERSHIP_TYPE, membershipType ?? "");
  }, [membershipType]);

  const googleApiErrorMessage =
    apiError.platform === AuthPlatform.GOOGLE
      ? apiError.googleErrorString ?? getErrorMessage(apiError.error)
      : null;

  const title = isLogin ? "Login" : "Join 1stCollab";
  const loadingOverlay = isLogin ? "Logging in..." : "Signing up...";
  const googleButtonCTA = isLogin ? "Login with Google" : "Sign up with Google";

  const googleLogin = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      // after logging into google, get access token for email validation
      const request = createRequest({
        url: `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`,
        firebaseToken: codeResponse.access_token,
      });

      const googleProfileInfo = await handleResult(request);
      if (!googleProfileInfo || !googleProfileInfo.email) {
        setApiError({
          platform: AuthPlatform.GOOGLE,
          error: ErrorStates.USER_NOT_FOUND,
        });
        setLoading(AuthLoading.NOT_LOADING);
        return;
      }

      // validate email with our rules
      const emailValidationResult = validateEmailForMembershipResult(
        googleProfileInfo.email,
        membershipType ? Number(membershipType) : null,
      );

      if (!isLogin && emailValidationResult !== null) {
        setApiError({
          platform: AuthPlatform.GOOGLE,
          error: ErrorStates.UNKNOWN,
        });
        setLoading(AuthLoading.NOT_LOADING);
        return;
      }

      // if user already has account, let them log in otherwise force them to go through sign up flow
      if (isLogin) {
        const hasSignedIn = await fetchSignInMethodsForEmail(auth, googleProfileInfo.email);
        if (hasSignedIn.length === 0) {
          setApiError({
            platform: AuthPlatform.GOOGLE,
            error: ErrorStates.USER_NOT_FOUND,
          });
          setLoading(AuthLoading.NOT_LOADING);
          return;
        }
      }

      const credential = GoogleAuthProvider.credential(null, codeResponse.access_token);
      signInWithCredential(auth, credential).then(async (response) => {
        setApiError({
          platform: AuthPlatform.GOOGLE,
          error: ErrorStates.NONE,
        });
        setLoading(AuthLoading.FINISHED);
      });
    },
    onError: (errorResponse) => {
      setApiError({
        platform: AuthPlatform.GOOGLE,
        googleErrorString: errorResponse?.error_description,
        error: ErrorStates.UNKNOWN,
      });
      setLoading(AuthLoading.NOT_LOADING);
    },
    onNonOAuthError: (anotherError) => {
      switch (anotherError.type) {
        case "popup_failed_to_open":
          setApiError({
            platform: AuthPlatform.GOOGLE,
            error: ErrorStates.POPUP_FAILED_TO_OPEN,
          });
          break;
        case "popup_closed":
          setApiError({
            platform: AuthPlatform.GOOGLE,
            error: ErrorStates.POPUP_CLOSED,
          });
          break;
        default:
          setApiError({
            platform: AuthPlatform.GOOGLE,
            error: ErrorStates.UNKNOWN,
          });
          break;
      }
      setLoading(AuthLoading.NOT_LOADING);
    },
    flow: "implicit",
  });
  return (
    <Center
      w="100%"
      h="calc(100vh - 60px - 32px)" /* subtract height of navbar + top and bottom padding (16px each) */
    >
      <Stack maw="475px" align="center" gap={0} style={{ height: "100%" }} w="100%">
        <Title size="h1" order={2}>
          {title}
        </Title>
        <Spacer height={16} />
        <Text size="md" fw={500} style={{ textAlign: "center" }}>
          The best way to find, create, and manage influencer campaigns.
        </Text>
        <Spacer height={24} />
        <Box pos="relative" w="100%">
          <LoadingOverlay visible={showLoadingUI} loaderProps={{ children: loadingOverlay }} />
          <Box w="100%">
            {showMembershipStep ? (
              <>
                <Select
                  size="lg"
                  label="Organization / Role"
                  placeholder="Please select one"
                  value={membershipType?.toString()}
                  description="Which of the following best describes you or your organization?"
                  data={ONBOARDING_MEMBERSHIP_OPTIONS.map((membership) => {
                    return {
                      label: getLabelForMembershipType(membership),
                      value: membership.toString(),
                    };
                  })}
                  allowDeselect={false}
                  onChange={(_value, option) => {
                    setMembershipType(_value);
                  }}
                />
                <Spacer height={16} />
                <Button
                  w="100%"
                  size="lg"
                  variant="filled"
                  disabled={membershipType === null}
                  onClick={() => setShowMembershipStep(false)}>
                  Next
                </Button>
              </>
            ) : (
              <>
                <AuthForm
                  isLogin={isLogin}
                  membershipType={membershipType ? Number(membershipType) : null}
                  apiError={apiError}
                  onForgotPasswordClicked={() => {
                    setShowPasswordReset(true);
                  }}
                  setApiErrorErrorState={(errorState) => {
                    setApiError({
                      platform: AuthPlatform.EMAIL,
                      error: errorState,
                    });
                  }}
                  setLoading={setLoading}
                />
                <Spacer height={16} />
                <Divider />
                <Spacer height={16} />
                <Button
                  w="100%"
                  size="lg"
                  variant="light"
                  color="gray"
                  styles={{
                    label: {
                      color: "var(--mantine-color-dark-6)",
                    },
                  }}
                  onClick={() => {
                    setApiError({
                      platform: AuthPlatform.GOOGLE,
                      error: ErrorStates.NONE,
                    });
                    setLoading(AuthLoading.LOADING);
                    googleLogin();
                  }}>
                  <Group gap={8}>
                    <BrandIcons platform="googleColor" size="l" />
                    {googleButtonCTA}
                  </Group>
                </Button>
                {googleApiErrorMessage ? (
                  <Text c="red" size="sm">
                    {googleApiErrorMessage}
                  </Text>
                ) : null}
              </>
            )}
            {isLogin ? null : (
              <>
                <Spacer height={24} />
                <Text size="sm" py={8}>
                  By continuing you agree to 1stCollab&#39;s{" "}
                  <a href="/terms-of-service" target="blank">
                    Terms of Service
                  </a>{" "}
                  and acknowledge you&#39;ve read our{" "}
                  <a href="/privacy-policy" target="blank">
                    Privacy Policy
                  </a>
                </Text>
              </>
            )}
          </Box>
        </Box>
      </Stack>
      {showPasswordReset ? (
        <PasswordResetModal
          setShowModal={setShowPasswordReset}
          zIndex={200} /* --mantine-z-index-modal – value 200 */
        />
      ) : null}
    </Center>
  );
};

export default NewAuth;
