import { User as FirebaseUser, getAuth } from "firebase/auth";
import { handleResult } from "utils/ApiUtils";

import { API_URL } from "configs/Configs";

import { BrandHashtag } from "models/BrandHashtag";

const GET_BRAND_HASHTAG_URL = `${API_URL}/api/ops/models/brand_hashtag`;

export const getBrandHashtags = async (
  setBrandHashtags: (brandHashtags: BrandHashtag[]) => void,
  query?: string,
  shouldFilterLabeled = false,
  includeHidden = true,
  shouldFilterSponsored = false,
  abortController?: AbortController,
) => {
  const firebaseToken = await getAuth().currentUser?.getIdToken();
  const requestUrl = new URL(`${GET_BRAND_HASHTAG_URL}/`);
  if (query) {
    requestUrl.searchParams.append("query", query);
  }
  if (shouldFilterLabeled) {
    requestUrl.searchParams.append("filter_labeled", "true");
  }
  if (includeHidden) {
    requestUrl.searchParams.append("show_hidden", "true");
  }
  if (shouldFilterSponsored) {
    requestUrl.searchParams.append("filter_sponsored", "true");
  }
  const request = new Request(requestUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
  });
  const response = await handleResult(request, abortController);
  if (response?.results && response?.results?.length > 0) {
    setBrandHashtags(response.results);
  }
  return response;
};

export const updateBrandHashtag = async (hashtag: BrandHashtag) => {
  const firebaseToken = await getAuth().currentUser?.getIdToken();
  const requestUrl = new URL(`${GET_BRAND_HASHTAG_URL}/${hashtag.id}/`);
  const request = new Request(requestUrl, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
    body: JSON.stringify({ ...hashtag, pk: hashtag.id }),
  });
  const response = await handleResult(request);
  return response;
};

export default null;
