import React, { useEffect, useState } from "react";
import { Button, Table, Text } from "gestalt";
import "gestalt/dist/gestalt.css";

import { API_URL } from "configs/Configs";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "auth/firebaseAuthHelpers";

const TIKTOK_CALLBACK_URL = `${API_URL}/authentication/tiktok/`;

const TiktokRedirectRow = () => {
  const [user, loading] = useAuthState(auth);

  const [data, updateData] = useState();
  useEffect(() => {
    const saveCredentials = async () => {
      const firebaseToken = await user.getIdToken();
      // extract code from url and send to backend
      const currentUrl = window.location.href;
      const { searchParams } = new URL(currentUrl);
      const code = searchParams.get("code");
      if (code) {
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${firebaseToken}`,
          },
          body: JSON.stringify({
            code,
            email: user.email,
          }),
        };
        const response = await fetch(TIKTOK_CALLBACK_URL, requestOptions);
        window.close();
      }
    };
    if (!loading && user !== null) {
      saveCredentials();
    }
  }, [loading]);
  return <Text>Thank you for authenticating! The window will automatically close.</Text>;
};

export default TiktokRedirectRow;
