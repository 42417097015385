import { CampaignAdGroupMin } from "models/CampaignAdGroup";
import { Campaign } from "models/Campaign";

export interface Opportunity {
  id: number;
  campaign_id: number;
  hash_id: number;
  creator_id: number;
  ad_group: CampaignAdGroupMin;
  max_offer_cap: number;
  max_offer_action: number;
  active_state: OpportunityActiveState;
  sender_first_name: string;
  brand_name?: string;
  brand_id?: number;
  email_funnel_state?: number;
}

export interface OpportunityResponse {
  results: Opportunity[];
  count: number;
  next?: number | null;
  previous?: number | null;
}

export enum OpportunityActiveState {
  UNKNOWN = 0,
  INACTIVE = 1,
  ACTIVE = 2,
  COMPLETE = 3,
  NOT_IN_CAMPAIGN = 4,
}

export const OPPORTUNITY_STATES = {
  [OpportunityActiveState.UNKNOWN]: "Unknown",
  [OpportunityActiveState.INACTIVE]: "Inactive",
  [OpportunityActiveState.ACTIVE]: "Active",
  [OpportunityActiveState.COMPLETE]: "Complete",
  [OpportunityActiveState.NOT_IN_CAMPAIGN]: "Not in Campaign",
};

export const OPPORTUNITY_COLORS = {
  0: "gray",
  1: "gray",
  2: "blue",
  3: "green",
  4: "gray",
};

export default Opportunity;
