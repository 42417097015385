import React, { useEffect, useState } from "react";
import { Datapoint, Flex, Text } from "gestalt";
import "gestalt/dist/gestalt.css";

import { getInstagramStats, InstagramStatsResponse } from "social/fb/InstagramUtils";
import { getAbbreviatedNumber } from "utils/AnalyticsUtils";

enum LoadingState {
  LOADING = 1,
  SUCCESS = 2,
  ERROR = 3,
  EMPTY = 4, // no results returned
}

interface SetStats {
  (stats: InstagramStatsResponse): void;
}

interface SetLoadingState {
  (state: LoadingState): void;
}

const fetchAndSetStats = async (
  setStats: SetStats,
  setLoading: SetLoadingState,
  username: string,
  platform: string,
  format: number,
  showSponsored: boolean,
) => {
  const results = await getInstagramStats(username, platform, format, showSponsored);
  setStats(results);
  setLoading(LoadingState.SUCCESS);
};

const toPriceString = (lower_price: number, upper_price: number) =>
  `$${getAbbreviatedNumber(lower_price)} - $${getAbbreviatedNumber(upper_price)}`;

const StatsView = ({ stats }: { stats: InstagramStatsResponse }) => (
  <Flex flex="grow" justifyContent="center" gap={12}>
    <Datapoint title="Number of Posts" value={getAbbreviatedNumber(stats.num_media)} />
    <Datapoint title="Median Likes" value={getAbbreviatedNumber(stats.likes_median)} />
    <Datapoint title="Median Comments" value={getAbbreviatedNumber(stats.comments_median)} />
    <Datapoint
      title="Suggested Price"
      value={toPriceString(stats.lower_price, stats.upper_price)}
    />
  </Flex>
);

const InstagramStatsView = ({
  username,
  platform,
  format,
  showSponsored,
}: {
  username: string;
  platform: string;
  format: number;
  showSponsored: boolean;
}) => {
  const [stats, setStats] = useState(null);
  const [loadingState, setLoading] = useState(LoadingState.LOADING);

  useEffect(() => {
    fetchAndSetStats(setStats, setLoading, username, platform, format, showSponsored);
  }, [platform, format, showSponsored, username]);

  if (loadingState === LoadingState.LOADING) {
    return (
      <Flex flex="grow" justifyContent="center">
        <Text>Loading</Text>;
      </Flex>
    );
  }
  return <StatsView stats={stats} />;
};

export default InstagramStatsView;
