import { User } from "models/User";

export enum OpsTaskType {
  SOURCING = "sourcing",
}

export type OpsTask = {
  id: number;
  name: string;
  description: string;
  value: string;
  type: OpsTaskType;
  brand: { id: number; name: string };
  campaign: { id: number; title: string; brand_id: number };
  ad_group: { id: number; name: string };
  creator_set_details: {
    id: number;
    name: string;
    status: number;
    sourcing_difficulty: number;
    brand_id: number;
  };
  assigned_user_details: { key: string; name: string; avatar_url: string } | User;
  estimated_workload: number;
  due_date: string;
  status: string;
  created_at: string;
  updated_at: string;
  completed: boolean;
  completed_at: string;
  actual_workload: number;
};

export function cloneOpsTask(task: OpsTask): OpsTask {
  return {
    ...task,
    brand: task?.brand && { ...task.brand },
    campaign: task?.campaign && { ...task.campaign },
    ad_group: task?.ad_group && { ...task.ad_group },
    creator_set_details: task?.creator_set_details && { ...task.creator_set_details },
    assigned_user_details: task?.assigned_user_details && { ...task.assigned_user_details },
  };
}

export type OpsTaskResponse = {
  results: OpsTask[];
  count: number;
  next?: string;
  previous?: string;
};

export type OpsSourcingPlan = {
  dt: string;
  raw_data: string;
};

export type CreatorSetSourcingPlan = {
  creator_set_id: number;
  needs_internal_review: number;
  needs_brand_review: number;
  allocated: Record<string, number>;
  unallocated: number;
  priority: number;
  difficulty: number;
};

export type SourcingAllocation = {
  results: CreatorSetSourcingPlan[];
};

export default null;
