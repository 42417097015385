import React from "react";
import { Divider, Paper, Space } from "@mantine/core";
import "gestalt/dist/gestalt.css";

import AdminCreateCampaign from "admin/AdminCreateCampaign";
import { useAdminAppContext } from "admin/app/AdminAppShell";
import { useAppSelector } from "reduxStore/hooks";
import CampaignsList from "components/campaign/dashboard/CampaignList";

export default function AdminCampaigns() {
  const { user } = useAdminAppContext();
  const userProfile = useAppSelector((state) => state.me.user);

  return (
    <>
      {userProfile.is_staff && (
        <>
          <AdminCreateCampaign user={user} />
          <Space h={30} />
          <Divider />
          <Space h={30} />
        </>
      )}
      <Paper>
        <CampaignsList />
      </Paper>
    </>
  );
}
