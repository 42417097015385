import React from "react";
import { Text } from "gestalt";
import "gestalt/dist/gestalt.css";

const PrivacyPolicy = () => (
  <>
    <Text weight="bold">Privacy policy</Text>
    <Text weight="bold">Last updated October 21, 2022</Text>
    <br />
    <Text>
      1stCollab Inc. (“1stCollab”, “Company”), and any present or future affiliated or related
      application, website or company (collectively “1stCollab”, “us”, or “we”), is committed to the
      privacy of those individuals and businesses who visit our website (“1stcollab.com”, “website”,
      “Site”). This Privacy Policy tells you what information we collect and how we use it. We may
      change this privacy policy in the future, at our sole discretion. By using or accessing this
      Site, or any application, product, website, tool, and/or service from 1stCollab, you
      understand and accept this privacy policy.
    </Text>
    <br />
    <Text weight="bold">Information we collect</Text>
    <Text>
      When using the Site, to utilize some of the services and/or features, you may be asked to
      provide, or you may choose to upload, certain personally identifiable information about
      yourself (“Personal Information”) such as your email address, name, phone number, year of
      birth, social media profiles, interests, or other information. We ask that you not send us,
      and you do not disclose, any sensitive personal data (e.g., social security numbers,
      information related to racial or ethnic origin, political opinions, religion or other beliefs,
      health, biometrics or genetic characteristics, criminal background or trade union membership).
      If you do, you consent to our processing and use of such sensitive personal data in accordance
      with this Policy.
    </Text>
    <br />
    <Text>
      1stCollab utilizes YouTube API Services to provide up-to-date statistics and data about
      YouTube creators. By using any part of the 1stCollab services or product, including but not
      limited to the creator search feature, media kits, or campaign features, you also agree to the
      Google Privacy Policy, available&nbsp;
      <a href="http://www.google.com/policies/privacy">here</a>. We access and collect statistics
      from YouTube, including but not limited to your subscriber count, video views, likes,
      comments, thumbnails; if you authorize 1stCollab to access your YouTube account, we may also
      access additional data, such as audience demographics data. This data is used to improve the
      features provided by 1stCollab (for example, but not limited to, displaying it on your
      profile, improving personalization, etc.). You can revoke 1stCollab&apos;s access to this data
      at any time via the Google security settings page, found&nbsp;
      <a href="https://security.google.com/settings/security/permissions">here</a>. You can also
      contact 1stCollab with questions or complaints about our privacy practices at
      hello@1stcollab.com.
    </Text>
    <br />
    <Text>
      This privacy policy does not apply to the privacy practices of third parties—including but not
      limited to Facebook, YouTube, Twitter, or Instagram—and including but not limited to any third
      party websites, services, applications, online resources to which this Site may link or
      otherwise reference (collectively “Third Party Services”) that you may access through
      1stCollab. For example, 1stCollab utilizes the Instagram API, and you are agreeing to be bound
      by the Instagram Terms of Service, Privacy Policy, and all other applicable policies from
      Instagram. We take no responsibility for the content or privacy practices of any Third Party
      Service.
    </Text>
    <br />
    <Text>
      Our login and profile creation process allows you to link Third Party Services to your
      1stCollab account—for example, by connecting your account to a Third Party Service such as
      Instagram, Facebook, Twitter or YouTube. This allows us to automatically collect information
      from your social profiles, to save you time and ensure a smooth experience with 1stCollab. By
      authorizing us to access your Third Party Services accounts, you understand that we may obtain
      certain information from those accounts. You also authorize us to obtain and use, at our
      discretion, certain information from your Third Party Services account, such as but not
      limited to your name, email address, created media, engagement data, and more. We are not
      responsible for the public information you choose to keep accessible in your social media
      profiles.
    </Text>
    <br />
    <Text>
      In addition to social data, we may also collect non-personally identifiable information, such
      as but not limited to IP host address, pages viewed, browser types, and other data, and may
      aggregate any Personal Information collected, in a manner which does not identify any one
      person. We also may collect navigational information, such as links clicked. For example, we
      might use Google Analytics to better understand and improve 1stCollab. We do not share your
      personal information with anyone, however it may be aggregated for research, presentation, or
      demonstration purposes.
    </Text>
    <br />
    <Text>
      We also use cookies to keep track of your activity on the Site and enhance your experience on
      the Site, and to save your account information and password. If you wish to disable these
      cookies, the “help” portion of the toolbar on most browsers will tell you how. If you do not
      accept our cookies, however, you may experience some inconvenience in your use of 1stCollab.
      For example, you might need to log in every time you open 1stCollab.
    </Text>
    <br />
    <Text>
      We also use “clickstream data”, a trail of electronic information left at each web site you
      visit, and which can be collected and stored by a web site’s server. This can tell us the type
      of computer and browsing software you use and the address of the web site from which you came
      to 1stCollab from. We may collect and use this data to anonymously determine how visitors use
      our website, and how we may better improve our website for visitors. Any collection or use of
      clickstream data will be anonymous and aggregate, and will not intentionally contain any
      Personal Information.
    </Text>
    <br />
    <Text>
      Finally, we may also use pixel tags (also known as web beacons) on 1stCollab to track the
      actions of users on our sites and applications. Pixel tags measure the success of our
      marketing campaigns and compile statistics about usage of the Services. For example, a
      Facebook pixel can help us better understand how an Instagram ad campaign is performing.
    </Text>
    <br />
    <Text weight="bold">What we do with that information</Text>
    <Text>
      We use your Personal Information for the purpose for which you have provided it, which may
      include but is not limited to: operating, maintaining, and improving 1stCollab; managing your
      account; communicating with you; providing customer service; connecting you with other users
      for brand deals; sending you information such as updates, alerts, and support; and, with your
      consent, sending you marketing emails. You may opt-out of receiving such information at any
      time; marketing emails tell you how to “opt-out.” Please note, even if you opt out of
      receiving marketing emails, we may still send you non-marketing emails, such as emails about
      your account with us (if you have one), and as we believe necessary or appropriate (a) to
      comply with applicable laws; (b) to comply with lawful requests and legal process, including
      to respond to requests from public and government authorities; (c) to enforce our privacy
      policy; and (d) to protect our rights, privacy, safety or property, and/or that of you or
      others.
    </Text>
    <br />
    <Text>
      We may share this information with service providers who perform services on our behalf, such
      as processing information requests, displaying stored data you access, to assist us in
      marketing, to conduct audits, etc. We may also share your Personal Information with our
      clients, affiliates, or other third parties, for the purpose of, for example but not limited
      to, enhancing our Site’s functionality, providing associated products and services, and/or
      matching an influencer and a brand for a possible brand collab. Those companies will be
      permitted to obtain only the personal information they need to provide the service they
      provide, will be required to maintain the confidentiality of the information, and will be
      prohibited from using it for any other purpose.
    </Text>
    <br />
    <Text>
      We do not solicit your email address or any other personal information to any brands for
      marketing purposes. The personal information we provide to brands and other affiliates is to
      connect brands and influencers to discuss brand deals together. They are not to market
      directly to you at any time. Please let us know immediately if you experience any unwanted
      direct marketing from a brand you interacted with on 1stCollab. We disclaim all responsibility
      and liability should another user (brand or influencer) contact you directly by your posting
      of Personal Information on 1stCollab.
    </Text>
    <br />
    <Text>
      Under certain circumstances, we may need to contact specific or all users in order to make an
      important announcement about 1stCollab. Users are not able to unsubscribe, or “opt-in/opt-out”
      of these announcements because they may contain very important information regarding their
      accounts. We reserve the right to contact members to verify and correct account information or
      to supply any relevant information that is required for normal use of 1stCollab.
    </Text>
    <br />
    <Text>
      We will never rent or sell your Personal Information to any third party without your consent
      for each purpose. Please note that we reserve the right to and may disclose Personal
      Information about you in response to (a) requests from local, provincial/state or federal law
      enforcement officials; (b) any judicial, administrative or similar proceeding or order, such
      as subpoena; (c) if required by law; or (d) to investigate suspected fraud, harassment,
      physical threats, or other violations of any law, rule or regulation, the Site rules or
      policies, or the rights of third parties or to investigate any suspected conduct which we deem
      improper. Please also note that we do reserve the right to transfer your personally
      identifiable information and other information to our successors in business and purchasers of
      site assets. Information obtained about the Site may be amalgamated with and used by us in
      conjunction with information obtained through sources other than the Site, including both
      offline and online sources.
    </Text>
    <br />
    <Text weight="bold">Security</Text>
    <Text>
      We follow generally accepted industry standards to help protect your personal information.
      That said, no method of transmission over the internet, mobile technology, or method of
      electronic storage is completely secure. Therefore, while we endeavor to maintain physical,
      electronic, and procedural safeguards to protect the confidentiality of the information that
      we collect online, we cannot guarantee its absolute security. We cannot ensure or warrant the
      security of any information you transmit to us or any information provided online, and you do
      so at your own risk. You must accept all risks associated with any data transmission,
      including the risk that their personal information may be intercepted in transit. You are
      responsible to keep your password secure, both for 1stCollab and for connected third-party
      accounts. Please be careful and responsible when you are online. If you have reason to believe
      that your interaction with us is no longer secure, please immediately notify us by contacting
      us at hello@1stcollab.com.
    </Text>
    <br />
    <Text weight="bold">Managing your information</Text>
    <Text>
      If you have an Account, you may access and update some of your information through your
      Account settings. If you have connected your Account to a Third Party Service you can change
      your settings and remove permissions for the Third Party Service by changing your Account
      settings. You are responsible for keeping your personal information up-to-date. To delete your
      account, you may contact us at hello@1stcollab.com. You can also ask us to correct inaccurate
      or incomplete personal information about you that you cannot update yourself within your
      Account.
    </Text>
    <br />
    <Text>
      We generally retain your personal information for as long as is necessary for the performance
      of the contract between you and us and to comply with our legal obligations.
    </Text>
    <br />
    <Text weight="bold">Children’s policy</Text>
    <Text>
      You must be 18 years or older to use 1stCollab. 1stCollab is not intended for users under the
      age of 18, and we do not knowingly collect Personal Information from those under the age of
      18. In the event that we learn that a user under the age of 13 has provided us with personally
      identifiable information, we will use all reasonable efforts to identify and delete such
      information from our database.
    </Text>
    <br />
    <Text weight="bold">International usage</Text>
    <Text>
      This website is owned by 1stCollab and may be accessed in the United States and abroad. If you
      are located outside of the United States, please note that the information you provide to us
      may be transferred to the United States. By using the Service, application and/or website, you
      consent to such transfer. We will take reasonable steps to ensure that your data is treated
      securely and in accordance with this privacy policy.
    </Text>
    <br />
    <Text weight="bold">California Privacy Rights</Text>
    <Text>
      California law permits users who are California residents to request and obtain from us once a
      year, free of charge, a list of the third parties to whom we have disclosed their personal
      information (if any) for their direct marketing purposes in the prior calendar year, as well
      as the type of personal information disclosed to those third parties. Please send such
      requests to hello@1stcollab.com.
    </Text>
    <br />
    <Text weight="bold">Third party links</Text>
    <Text>
      1stCollab may contain links to other websites. Other websites may also reference or link to
      our site. These “other” domains are not controlled by us. We do not endorse, screen or
      approve, and are not responsible for the privacy practices or content of such other web sites.
    </Text>
    <br />
    <Text weight="bold">Changes to this policy</Text>
    <Text>
      We reserve the right to change this Privacy Policy at any time and without prior notice. When
      we make such changes, we will post them here, and revise the “last updated” date. If there are
      material changes to this statement, we will notify you by prominently posting a notice on our
      website, or by sending you an email. Your continued access of the Site after such changes are
      made demonstrates your acceptance of those changes.
    </Text>
  </>
);

export default PrivacyPolicy;
